import {
  CatalogResource,
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum as AttributePayloadModeEnum,
  DynamicAttributeOption,
  ParameterisedResourceArguments,
} from "@incident-io/api";
import { CatalogEntryBadge, isPrimitive } from "@incident-shared/attribute";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  SearchableDropdown,
  SearchableDropdownEntry,
} from "@incident-ui/SearchableDropdown/SearchableDropdown";
import React from "react";

import { CatalogTypeInfo } from "./AddDerivedAttributesPopover";

export const AddDynamicAttributesPopover = ({
  integrationLabel,
  schema,
  onAddAttribute,
  resources,
  dynamicAttributes,
  disabled = false,
}: {
  integrationLabel?: string;
  schema: CatalogTypeAttributePayload[];
  onAddAttribute: (attr: CatalogTypeAttributePayload) => void;
  resources: CatalogResource[];
  dynamicAttributes: DynamicAttributeOption[];
  disabled?: boolean;
}): React.ReactElement | null => {
  const attributeEntries = buildDynamicAttributeMenuEntries({
    schema,
    resources,
    dynamicAttributes,
  });

  return (
    <SearchableDropdown
      onSelectItem={onAddAttribute}
      emptyState={`There are no more available attributes for this type`}
      entries={attributeEntries}
      hideSearchBar={attributeEntries.length < 10}
      renderTriggerButton={({ onClick }) => (
        <Button
          analyticsTrackingId="catalog-schema-add-attribute"
          onClick={onClick}
          theme={ButtonTheme.Naked}
          icon={IconEnum.Add}
          disabled={disabled}
        >{`Import from ${integrationLabel}`}</Button>
      )}
    />
  );
};

const buildDynamicAttributeMenuEntries = ({
  schema,
  resources,
  dynamicAttributes,
}: {
  schema: CatalogTypeAttributePayload[];
  registryType?: string;
  resources: CatalogResource[];
  dynamicAttributes: DynamicAttributeOption[];
}): SearchableDropdownEntry<CatalogTypeAttributePayload>[] => {
  // Don't show any attributes that have already been selected
  const existingFields = schema.map((s) => s.name);
  const unusedDynamicAttributes = dynamicAttributes
    .filter((att) => {
      return existingFields.indexOf(att.label) === -1;
    })
    .map((attr) => {
      const resource = resources.find((r) => r.type === attr.type);

      return {
        item: {
          id: attr.value,
          name: attr.label,
          array: attr.array,
          type: attr.type,
          mode: AttributePayloadModeEnum.Dynamic,
          catalogTypeInfo: parseParameterisedResourceArguments(
            attr.parameterised_resource_arguments,
          ),
        },
        icon: attr.icon as unknown as IconEnum,
        renderFn: () => (
          <CatalogEntryBadge
            icon={attr.icon as unknown as IconEnum}
            label={attr.label}
            color={resource?.color}
            className="cursor-pointer max-w-full"
          />
        ),
        label: attr.label,
        attribute_id: attr.value,
        type_name: attr.type,
      };
    });

  // Sort the dynamic attribute options in-place
  unusedDynamicAttributes.sort((a, b) => {
    // Non-primitive types come first
    if (isPrimitive(a.item.type) && !isPrimitive(b.item.type)) {
      return 1;
    }
    if (!isPrimitive(a.item.type) && isPrimitive(b.item.type)) {
      return -1;
    }
    // Non-primitives can just appear alphabetically
    if (!isPrimitive(a.item.type) && !isPrimitive(b.item.type)) {
      return a.label.localeCompare(b.label);
    }
    // Otherwise, we want to group things by type, and then alphabetically
    if (a.item.type === b.item.type) {
      return a.label.localeCompare(b.label);
    }
    return a.item.type.localeCompare(b.item.type);
  });

  return unusedDynamicAttributes;
};

const parseParameterisedResourceArguments = (
  args?: ParameterisedResourceArguments,
): CatalogTypeInfo | undefined => {
  if (!args) {
    return undefined;
  }

  return {
    name: args.name,
    registryType: args.registry_type,
    parameter: args.parameter,
    // The OpenAPI generator has done something odd here - presumably there's an
    // in-built exists field?
    exists: args._exists,
  };
};

export const serializeParameterisedResourceArguments = (
  args?: CatalogTypeInfo,
): ParameterisedResourceArguments | undefined => {
  if (
    !args ||
    !args.registryType ||
    !args.parameter ||
    args.exists === undefined
  ) {
    return undefined;
  }
  return {
    name: args.name,
    registry_type: args.registryType,
    parameter: args.parameter,
    _exists: args.exists,
  };
};
