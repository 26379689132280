/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface TeamsUpdateRequestBody
 */
export interface TeamsUpdateRequestBody {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof TeamsUpdateRequestBody
     */
    member_attribute_value?: EngineParamBindingPayload;
    /**
     * Name of the team
     * @type {string}
     * @memberof TeamsUpdateRequestBody
     */
    name: string;
}

export function TeamsUpdateRequestBodyFromJSON(json: any): TeamsUpdateRequestBody {
    return TeamsUpdateRequestBodyFromJSONTyped(json, false);
}

export function TeamsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'member_attribute_value': !exists(json, 'member_attribute_value') ? undefined : EngineParamBindingPayloadFromJSON(json['member_attribute_value']),
        'name': json['name'],
    };
}

export function TeamsUpdateRequestBodyToJSON(value?: TeamsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'member_attribute_value': EngineParamBindingPayloadToJSON(value.member_attribute_value),
        'name': value.name,
    };
}

