/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentLearningsItem,
    IncidentLearningsItemFromJSON,
    IncidentLearningsItemFromJSONTyped,
    IncidentLearningsItemToJSON,
} from './IncidentLearningsItem';

/**
 * 
 * @export
 * @interface IncidentLearnings
 */
export interface IncidentLearnings {
    /**
     * Communication practices that helped or could have helped
     * @type {Array<IncidentLearningsItem>}
     * @memberof IncidentLearnings
     */
    communication_protocols: Array<IncidentLearningsItem>;
    /**
     * Steps that helped diagnose the issue
     * @type {Array<IncidentLearningsItem>}
     * @memberof IncidentLearnings
     */
    diagnostic_steps: Array<IncidentLearningsItem>;
    /**
     * Approaches that were tried but didn't work
     * @type {Array<IncidentLearningsItem>}
     * @memberof IncidentLearnings
     */
    failed_approaches: Array<IncidentLearningsItem>;
    /**
     * People with knowledge helpful for similar incidents
     * @type {Array<IncidentLearningsItem>}
     * @memberof IncidentLearnings
     */
    subject_matter_experts: Array<IncidentLearningsItem>;
    /**
     * Technical fixes that could prevent similar incidents
     * @type {Array<IncidentLearningsItem>}
     * @memberof IncidentLearnings
     */
    technical_remediations: Array<IncidentLearningsItem>;
}

export function IncidentLearningsFromJSON(json: any): IncidentLearnings {
    return IncidentLearningsFromJSONTyped(json, false);
}

export function IncidentLearningsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLearnings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communication_protocols': ((json['communication_protocols'] as Array<any>).map(IncidentLearningsItemFromJSON)),
        'diagnostic_steps': ((json['diagnostic_steps'] as Array<any>).map(IncidentLearningsItemFromJSON)),
        'failed_approaches': ((json['failed_approaches'] as Array<any>).map(IncidentLearningsItemFromJSON)),
        'subject_matter_experts': ((json['subject_matter_experts'] as Array<any>).map(IncidentLearningsItemFromJSON)),
        'technical_remediations': ((json['technical_remediations'] as Array<any>).map(IncidentLearningsItemFromJSON)),
    };
}

export function IncidentLearningsToJSON(value?: IncidentLearnings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_protocols': ((value.communication_protocols as Array<any>).map(IncidentLearningsItemToJSON)),
        'diagnostic_steps': ((value.diagnostic_steps as Array<any>).map(IncidentLearningsItemToJSON)),
        'failed_approaches': ((value.failed_approaches as Array<any>).map(IncidentLearningsItemToJSON)),
        'subject_matter_experts': ((value.subject_matter_experts as Array<any>).map(IncidentLearningsItemToJSON)),
        'technical_remediations': ((value.technical_remediations as Array<any>).map(IncidentLearningsItemToJSON)),
    };
}

