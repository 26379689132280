import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
} from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { VerifiedButton } from "./VerifiedButton";

export const GroundTruthInvestigationSection = ({
  relevance,
  tags = [],
  reasoning,
  verifiedAt,
  onSave,
  className,
}: {
  relevance?: string;
  tags?: string[];
  reasoning?: string;
  verifiedAt?: Date;
  onSave?: (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableRelevance, setEditableRelevance] = useState(relevance || "");
  const [editableTags, setEditableTags] = useState(tags);
  const [editableReasoning, setEditableReasoning] = useState(reasoning || "");
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableRelevance(relevance || "");
    setEditableTags(tags);
    setEditableReasoning(reasoning || "");
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      await onSave(
        editableRelevance,
        editableTags,
        editableReasoning,
        isVerified,
      );
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div
      className={tcx(
        "border-t border-stroke first:border-t-0",
        isEditing ? "bg-surface-secondary" : "",
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Investigation relevance</h4>
            <VerifiedButton
              isVerified={isVerified}
              verifiedAt={verifiedAt}
              setIsVerified={setIsVerified}
              isEditing={isEditing}
            />
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            <select
              value={editableRelevance}
              onChange={(e) => setEditableRelevance(e.target.value)}
              className="w-full p-2 text-sm border rounded bg-white"
            >
              <option value="">Select relevance...</option>
              <option value="not_relevant">Not relevant</option>
              <option value="relevant">Relevant</option>
              <option value="critical">Critical</option>
            </select>

            <input
              type="text"
              value={editableTags.join(", ")}
              onChange={(e) =>
                setEditableTags(e.target.value.split(", ").filter(Boolean))
              }
              placeholder="Enter tags (comma separated)"
              className="w-full p-2 text-sm border rounded bg-white"
            />

            <textarea
              value={editableReasoning}
              onChange={(e) => setEditableReasoning(e.target.value)}
              placeholder="Explain why the investigation was or wasn't relevant"
              className="w-full p-2 text-sm border rounded bg-white resize-none"
              rows={3}
            />

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            {relevance ? (
              <>
                <div className="flex items-center gap-2">
                  <Badge
                    theme={
                      relevance === "critical"
                        ? BadgeTheme.Error
                        : relevance === "relevant"
                        ? BadgeTheme.Success
                        : BadgeTheme.Secondary
                    }
                  >
                    {relevance.replace("_", " ")}
                  </Badge>
                </div>
                {tags.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {tags.map((tag) => (
                      <Badge key={tag} theme={BadgeTheme.Secondary}>
                        {tag}
                      </Badge>
                    ))}
                  </div>
                )}
                {reasoning && (
                  <p className="text-sm text-content-secondary">{reasoning}</p>
                )}
              </>
            ) : (
              <p className="text-sm text-content-secondary">
                No investigation relevance has been identified yet.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
