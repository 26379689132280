import {
  ManagedResourceManagedByEnum,
  ManagedResourceResourceTypeEnum,
  Team,
  TeamResource,
  TeamResourceTypeEnum,
  TeamsAddResourceRequestBodyResourceTypeEnum as ResourceType,
  TeamsRemoveResourceRequestBodyResourceTypeEnum,
} from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { partition } from "lodash";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EscalationPathAndSchedulePopover } from "./EscalationPathAndSchedulePopover";

type ExternallyManaged = {
  externallyManaged: boolean;
};

type EscalationPathPlusExternallyManaged = TeamResource & ExternallyManaged;
type SchedulePlusExternallyManaged = TeamResource & ExternallyManaged;

export const TeamResources = ({ team }: { team: Team }) => {
  // Get data needed for typeaheads (catalog types and managed resources)
  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI(
    "catalogListTypes",
    {},
    {
      fallbackData: { catalog_types: [] },
    },
  );

  const {
    data: managedResourcesData,
    isLoading: managedResourcesLoading,
    error: managedResourcesError,
  } = useAPI(
    "managedResourcesList",
    {},
    {
      fallbackData: { managed_resources: [] },
    },
  );

  if (catalogTypesLoading || managedResourcesLoading) {
    return <Loader />;
  }

  if (catalogTypesError || managedResourcesError) {
    return (
      <GenericErrorMessage error={catalogTypesError || managedResourcesError} />
    );
  }

  const [escalationPaths, schedules] = partition(
    team.resources,
    (resource) => resource.type === TeamResourceTypeEnum.EscalationPath,
  );

  // Annotate the escalation paths and schedules with whether they are externally managed
  // with a boolean, so that we can treat them the same way in the UI.
  const ownedEscalationPaths = escalationPaths.map((path) => {
    const meta = managedResourcesData?.managed_resources.find(
      (mr) =>
        mr.resource_type === ManagedResourceResourceTypeEnum.EscalationPath &&
        mr.resource_id === path.id,
    );
    const externallyManaged =
      !!meta && meta.managed_by === ManagedResourceManagedByEnum.Terraform;
    return {
      ...path,
      externallyManaged,
    };
  });

  const ownedSchedules = schedules.map((schedule) => {
    const meta = managedResourcesData?.managed_resources.find(
      (mr) =>
        mr.resource_type === ManagedResourceResourceTypeEnum.Schedule &&
        mr.resource_id === schedule.id,
    );
    const externallyManaged =
      !!meta && meta.managed_by === ManagedResourceManagedByEnum.Terraform;
    return {
      ...schedule,
      externallyManaged,
    };
  });

  return (
    <div className="flex flex-col gap-2 w-full group/team-resources">
      <div className="flex w-full">
        <div className="flex grow text-base-bold">Related to this team</div>
        <div className="flex">
          <EscalationPathAndSchedulePopover
            teamId={team.id}
            catalogTypes={catalogTypesData}
            managedResources={managedResourcesData.managed_resources}
            triggerClassName="invisible group-hover/team-resources:visible"
            triggerIcon={IconEnum.Add}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col justify-end gap-2">
          {ownedEscalationPaths.length === 0 && (
            <div className="flex justify-between items-center">
              <div className="text-sm-med text-content-secondary">
                Escalation paths
              </div>
              <EscalationPathAndSchedulePopover
                teamId={team.id}
                catalogTypes={catalogTypesData}
                managedResources={managedResourcesData.managed_resources}
                loadOnly={TeamResourceTypeEnum.EscalationPath}
                triggerClassName="text-sm-med text-content-tertiary"
                triggerTitle="Add"
              />
            </div>
          )}
          {ownedEscalationPaths.map((escalationPath, i) => {
            return (
              <div
                className="flex justify-between items-center"
                key={escalationPath.id}
              >
                <div className="text-sm-med text-content-secondary">
                  {i === 0 ? "Escalation paths" : ""}
                </div>
                <ResourceBadge
                  teamId={team.id}
                  resource={escalationPath}
                  resourceType={ResourceType.EscalationPath}
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-col justify-end gap-2">
          {ownedSchedules.length === 0 && (
            <div className="flex justify-between items-center">
              <div className="text-sm-med text-content-secondary">
                Schedules
              </div>
              <EscalationPathAndSchedulePopover
                teamId={team.id}
                catalogTypes={catalogTypesData}
                managedResources={managedResourcesData.managed_resources}
                loadOnly={TeamResourceTypeEnum.Schedule}
                triggerClassName="text-sm-med text-content-tertiary"
                triggerTitle="Add"
              />
            </div>
          )}
          {ownedSchedules.map((schedule, i) => {
            return (
              <div
                className="flex justify-between items-center"
                key={schedule.id}
              >
                <div className="text-xs-med text-content-secondary">
                  {i === 0 ? "Schedules" : ""}
                </div>
                <ResourceBadge
                  teamId={team.id}
                  resource={schedule}
                  resourceType={ResourceType.Schedule}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const badgeProperties = (resourceType: ResourceType) => {
  if (resourceType === ResourceType.EscalationPath) {
    const { background, text, hoverBg } = getColorPalette(
      ColorPaletteEnum.Pink,
    );

    return {
      icon: IconEnum.EscalationPath,
      background,
      text,
      hoverBg,
    };
  } else {
    const { background, text, hoverBg } = getColorPalette(
      ColorPaletteEnum.Purple,
    );

    return {
      icon: IconEnum.Calendar,
      background,
      text,
      hoverBg,
    };
  }
};

const ResourceBadge = ({
  teamId,
  resource,
  resourceType,
}:
  | {
      teamId: string;
      resource: EscalationPathPlusExternallyManaged;
      resourceType: ResourceType.EscalationPath;
    }
  | {
      teamId: string;
      resource: SchedulePlusExternallyManaged;
      resourceType: ResourceType.Schedule;
    }) => {
  const showToast = useToast();

  const { background, text, hoverBg, icon } = badgeProperties(resourceType);

  const { trigger: removeResource, isMutating } = useMutationV2(
    async (
      apiClient,
      {
        resourceId,
        resourceType,
        teamId,
      }: {
        resourceId: string;
        resourceType: ResourceType;
        teamId: string;
      },
    ) => {
      await apiClient.teamsRemoveResource({
        id: teamId,
        removeResourceRequestBody: {
          resource_id: resourceId,
          resource_type:
            resourceType as unknown as TeamsRemoveResourceRequestBodyResourceTypeEnum,
        },
      });
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error updating team",
        });
      },
      invalidate: [cacheKey.exactly("teamsShow", teamId)],
    },
  );

  const resourcePath =
    resourceType === ResourceType.EscalationPath
      ? "escalation-paths"
      : "schedules";

  return isMutating ? (
    <Loader />
  ) : (
    <DropdownMenu
      menuClassName="min-w-[230px] group"
      triggerButton={
        <Badge
          theme={BadgeTheme.Unstyled}
          icon={icon}
          className={tcx(
            background,
            text,
            !resource.externallyManaged && hoverBg,
            "cursor-pointer",
            "font-medium flex items-center gap-1 group",
            resource.externallyManaged && "opacity-40",
          )}
        >
          {resource.name}
        </Badge>
      }
      align="start"
    >
      <DropdownMenuItem
        label="View"
        analyticsTrackingId={null}
        to={`/on-call/${resourcePath}/${resource.id}`}
        openInNewTab
      >
        <div className="flex gap-1">
          <Icon id={icon} />
          <span>View</span>
        </div>
      </DropdownMenuItem>
      {/* only resources that aren't externally managed can be removed here */}
      {!resource.externallyManaged && (
        <DropdownMenuItem
          label="Remove"
          analyticsTrackingId={null}
          onSelect={() => {
            removeResource({
              resourceId: resource.id,
              resourceType,
              teamId: teamId,
            });
          }}
        >
          <div className="flex gap-1 text-content-destroy">
            <Icon id={IconEnum.Delete} />
            <span>Remove</span>
          </div>
        </DropdownMenuItem>
      )}
    </DropdownMenu>
  );
};
