/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePathItem,
    CatalogTypeAttributePathItemFromJSON,
    CatalogTypeAttributePathItemFromJSONTyped,
    CatalogTypeAttributePathItemToJSON,
} from './CatalogTypeAttributePathItem';

/**
 * 
 * @export
 * @interface CatalogTypeAttribute
 */
export interface CatalogTypeAttribute {
    /**
     * Whether this attribute is an array
     * @type {boolean}
     * @memberof CatalogTypeAttribute
     */
    array: boolean;
    /**
     * The attribute to use (if this is a backlink)
     * @type {string}
     * @memberof CatalogTypeAttribute
     */
    backlink_attribute?: string;
    /**
     * The ID of this attribute
     * @type {string}
     * @memberof CatalogTypeAttribute
     */
    id: string;
    /**
     * Metadata about this attribute
     * @type {{ [key: string]: string; }}
     * @memberof CatalogTypeAttribute
     */
    metadata?: { [key: string]: string; };
    /**
     * Controls how this attribute is modified
     * @type {string}
     * @memberof CatalogTypeAttribute
     */
    mode: CatalogTypeAttributeModeEnum;
    /**
     * Unique name of this attribute
     * @type {string}
     * @memberof CatalogTypeAttribute
     */
    name: string;
    /**
     * The path to use (if this is a path attribute)
     * @type {Array<CatalogTypeAttributePathItem>}
     * @memberof CatalogTypeAttribute
     */
    path?: Array<CatalogTypeAttributePathItem>;
    /**
     * Catalog type name for this attribute
     * @type {string}
     * @memberof CatalogTypeAttribute
     */
    type: string;
    /**
     * Set to true if this attribute was previously synced from an external source
     * @type {boolean}
     * @memberof CatalogTypeAttribute
     */
    was_previously_synced?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CatalogTypeAttributeModeEnum {
    Empty = '',
    Api = 'api',
    Dashboard = 'dashboard',
    External = 'external',
    Internal = 'internal',
    Dynamic = 'dynamic',
    Backlink = 'backlink',
    Path = 'path'
}

export function CatalogTypeAttributeFromJSON(json: any): CatalogTypeAttribute {
    return CatalogTypeAttributeFromJSONTyped(json, false);
}

export function CatalogTypeAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': json['array'],
        'backlink_attribute': !exists(json, 'backlink_attribute') ? undefined : json['backlink_attribute'],
        'id': json['id'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mode': json['mode'],
        'name': json['name'],
        'path': !exists(json, 'path') ? undefined : ((json['path'] as Array<any>).map(CatalogTypeAttributePathItemFromJSON)),
        'type': json['type'],
        'was_previously_synced': !exists(json, 'was_previously_synced') ? undefined : json['was_previously_synced'],
    };
}

export function CatalogTypeAttributeToJSON(value?: CatalogTypeAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': value.array,
        'backlink_attribute': value.backlink_attribute,
        'id': value.id,
        'metadata': value.metadata,
        'mode': value.mode,
        'name': value.name,
        'path': value.path === undefined ? undefined : ((value.path as Array<any>).map(CatalogTypeAttributePathItemToJSON)),
        'type': value.type,
        'was_previously_synced': value.was_previously_synced,
    };
}

