import { ContentBox } from "../ContentBox";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";

export const UnexpectedError = () => {
  return (
    <ContentBox>
      <div className="p-3 text-center min-h-[150px] flex flex-col items-center content-center justify-center gap-3">
        <ComponentStatusIcon flavour="alarmalade" />

        <p className="text-sm text-content-tertiary dark:text-slate-200 max-w-xs">
          There was a problem showing this part of the page. We&rsquo;re working
          on fixing this.
        </p>
      </div>
    </ContentBox>
  );
};
