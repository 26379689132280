import {
  EvaluationBacktestBacktestTypeEnum,
  EvaluationDataset,
  EvaluationDatasetEntry,
  IncidentFilterFieldsListContextEnum,
  IncidentsListSortByEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "@incident-io/api";
import {
  ExtendedFormFieldValue,
  FiltersContextProvider,
  useInitialiseFilters,
} from "@incident-shared/filters";
import { IncidentsFilterControlSearchBar } from "@incident-shared/filters/IncidentsFilterControlSearchBar";
import {
  IncidentsListContextProvider,
  useGetIncidents,
} from "@incident-shared/incidents";
import {
  EmptyState,
  ErrorMessage,
  IconEnum,
  LoadingModal,
  LoadingWrapper,
} from "@incident-ui";
import { useState } from "react";
import { useAPI } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import { BulkDatasetWith } from "../../legacy/incidents-list/bulk-actions/BulkDatasetCurate";
import { useIncidentsSelections } from "../../legacy/incidents-list/IncidentListPage";
import { IncidentsFloatingBar } from "../../legacy/incidents-list/IncidentsFloatingBar";
import { IncidentsList } from "../../legacy/incidents-list/IncidentsList";
import { BacktestCreateModal } from "../backtests/BacktestCreateModal";
import { DatasetDimensions } from "./DatasetDimensions";
import { DatasetSegments } from "./DatasetSegments";

// Show a list of incidents in a similar style to our incident list page.
export const IncidentDatasetShowPageContent = ({
  dataset,
  entries,
}: {
  dataset: EvaluationDataset;
  entries: EvaluationDatasetEntry[];
}) => {
  const [filters, setFilters] = useState<ExtendedFormFieldValue[]>([]);
  const [debouncedFilters] = useDebounce(filters, 300);

  const {
    loading,
    availableIncidentFilterFields: availableFilterFields,
    error,
  } = useInitialiseFilters(``, IncidentFilterFieldsListContextEnum.Incidents);

  // Remove the dataset filter from the list of available filters, as we want this list to
  // always be filtered by our dataset and for users to be unable to remove that filter.
  const filtersWithoutDataset = availableFilterFields.filter(
    (filter) => filter.filter_id !== "dataset",
  );

  if (error) {
    return <ErrorMessage message={JSON.stringify(error)} />;
  }

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setFilters}
      availableFilterFields={filtersWithoutDataset}
      kind="incident"
      filtersLoading={loading}
    >
      <IncidentsListContextProvider>
        <IncidentsListContent
          dataset={dataset}
          entries={entries}
          filters={filters}
          debouncedFilters={debouncedFilters}
        />
      </IncidentsListContextProvider>
    </FiltersContextProvider>
  );
};

const IncidentsListContent = ({
  dataset,
  entries,
  filters,
  debouncedFilters,
}: {
  dataset: EvaluationDataset;
  entries: EvaluationDatasetEntry[];
  filters: ExtendedFormFieldValue[];
  debouncedFilters: ExtendedFormFieldValue[];
}) => {
  const { data: groupedEntries, isLoading: groupedEntriesLoading } = useAPI(
    "aIStaffGroupDatasetEntriesBySegment",
    {
      id: dataset.id,
    },
    {
      fallbackData: { entries_by_segment: {} },
    },
  );

  const {
    incidents,
    someIncidentsLoaded,
    allIncidentsLoaded,
    totalNumberOfIncidents,
    refetchIncidents,
    loadMore,
    isLoading,
  } = useGetIncidents({
    filters: debouncedFilters,
    fixedFilters: {
      pageSize: 100,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      dataset: {
        one_of: [dataset.id],
      },
      // By default these incidents are excluded in the incident list endpoint, but we want to return
      // any incident in a dataset, regardless of status.
      includeDeclinedCanceled: { one_of: [true] },
      includeMerged: { one_of: [true] },
    },
    eagerLoad: true,
  });

  const {
    selectAllIncidents,
    setSelectAllIncidents,
    selectedIncidentIDs,
    setSelectedIncidentIDs,
  } = useIncidentsSelections(
    incidents.map((inc) => inc.id),
    allIncidentsLoaded,
  );

  return (
    <div className="flex flex-col h-full w-full gap-6">
      <DatasetSegments
        dataset={dataset}
        filters={filters}
        entriesBySegment={
          groupedEntries ? groupedEntries.entries_by_segment : {}
        }
      />
      <IncidentsFilterControlSearchBar
        incidents={incidents}
        totalIncidents={totalNumberOfIncidents ?? 0}
        selectedIncidentIDs={selectedIncidentIDs}
        selectAllIncidents={selectAllIncidents}
        setSelectAllIncidents={setSelectAllIncidents}
        canCustomiseDisplayedInfo={true}
      />

      {entries.length === 0 ? (
        <EmptyState
          content="There are no incidents in this dataset"
          icon={IconEnum.Incident}
        />
      ) : (
        <div className="flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2 overflow-x-auto">
            <IncidentsList
              className={"-mx-8"}
              incidents={incidents}
              incidentsIsLoading={isLoading}
              incidentsLoadMore={loadMore}
              anyIncidentsLoaded={someIncidentsLoaded}
              allIncidentsLoaded={allIncidentsLoaded}
              filters={filters}
              selectedIncidentIDs={selectedIncidentIDs}
              setSelectedIncidentIDs={setSelectedIncidentIDs}
              enableSelection={true}
              incidentTypesEnabled={true}
            />
            <IncidentsFloatingBar
              selectedIncidentIDs={selectedIncidentIDs}
              totalIncidents={totalNumberOfIncidents ?? 0}
              setSelectedIncidentIDs={setSelectedIncidentIDs}
              refetchIncidents={refetchIncidents}
              bulkActionsOverride={[
                {
                  label: "Remove from dataset",
                  icon: IconEnum.InsightsDashboard,
                  name: "dataset_remove",
                  form: BulkDatasetWith({
                    mode: "remove",
                    overrideDatasetID: dataset.id,
                  }),
                },
                {
                  label: "Run a backtest",
                  icon: IconEnum.Test,
                  name: "run_backtest",
                  form: ({ onClose, incidentIDs }) =>
                    groupedEntriesLoading ? (
                      <LoadingModal onClose={onClose} />
                    ) : (
                      <BacktestCreateModal
                        overrideBacktestType={
                          EvaluationBacktestBacktestTypeEnum.Investigation
                        }
                        resourceIDs={incidentIDs}
                        onClose={onClose}
                        segments={
                          groupedEntries
                            ? groupedEntries.entries_by_segment
                            : {}
                        }
                      />
                    ),
                },
              ]}
              includesTriageIncidents={incidents
                .filter((i) => selectedIncidentIDs.includes(i.id))
                .some(
                  (i) =>
                    i.incident_status.category === StatusCategoryEnum.Triage,
                )}
              isAllTriageIncidents={incidents
                .filter((i) => selectedIncidentIDs.includes(i.id))
                .every(
                  (i) =>
                    i.incident_status.category === StatusCategoryEnum.Triage,
                )}
            />
          </div>
          <div className="w-full xl:w-1/2 flex flex-col gap-6">
            {incidents.length > 0 && (
              <DatasetDimensionsWrapper
                incidentIDs={incidents.map((inc) => inc.id)}
                datasetId={dataset.id}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const DatasetDimensionsWrapper = ({
  incidentIDs,
  datasetId,
}: {
  incidentIDs: string[];
  datasetId: string;
}) => {
  const {
    data: { dimensions },
    isLoading,
  } = useAPI(
    "aIStaffCalculateDatasetDimensions",
    {
      id: datasetId,
      entryIds: incidentIDs,
    },
    {
      fallbackData: { dimensions: [] },
    },
  );

  return (
    <LoadingWrapper loading={isLoading}>
      <DatasetDimensions dimensions={dimensions} />
    </LoadingWrapper>
  );
};
