/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecardGrade,
    EvaluationScorecardGradeFromJSON,
    EvaluationScorecardGradeFromJSONTyped,
    EvaluationScorecardGradeToJSON,
} from './EvaluationScorecardGrade';

/**
 * Scorecard measuring something's performance across multiple dimensions (e.g. an investigation or copilot thread)
 * @export
 * @interface EvaluationScorecard
 */
export interface EvaluationScorecard {
    /**
     * ID of the Copilot search
     * @type {string}
     * @memberof EvaluationScorecard
     */
    copilot_search_id?: string;
    /**
     * ID of the Copilot thread
     * @type {string}
     * @memberof EvaluationScorecard
     */
    copilot_thread_id?: string;
    /**
     * When this scorecard was produced
     * @type {Date}
     * @memberof EvaluationScorecard
     */
    graded_at: Date;
    /**
     * Collection of grades for different aspects of the entry
     * @type {Array<EvaluationScorecardGrade>}
     * @memberof EvaluationScorecard
     */
    grades: Array<EvaluationScorecardGrade>;
    /**
     * Unique identifier
     * @type {string}
     * @memberof EvaluationScorecard
     */
    id: string;
    /**
     * ID of the investigation
     * @type {string}
     * @memberof EvaluationScorecard
     */
    investigation_id?: string;
    /**
     * Warnings generated during the grading process
     * @type {Array<string>}
     * @memberof EvaluationScorecard
     */
    warnings: Array<string>;
}

export function EvaluationScorecardFromJSON(json: any): EvaluationScorecard {
    return EvaluationScorecardFromJSONTyped(json, false);
}

export function EvaluationScorecardFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copilot_search_id': !exists(json, 'copilot_search_id') ? undefined : json['copilot_search_id'],
        'copilot_thread_id': !exists(json, 'copilot_thread_id') ? undefined : json['copilot_thread_id'],
        'graded_at': (new Date(json['graded_at'])),
        'grades': ((json['grades'] as Array<any>).map(EvaluationScorecardGradeFromJSON)),
        'id': json['id'],
        'investigation_id': !exists(json, 'investigation_id') ? undefined : json['investigation_id'],
        'warnings': json['warnings'],
    };
}

export function EvaluationScorecardToJSON(value?: EvaluationScorecard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copilot_search_id': value.copilot_search_id,
        'copilot_thread_id': value.copilot_thread_id,
        'graded_at': (value.graded_at.toISOString()),
        'grades': ((value.grades as Array<any>).map(EvaluationScorecardGradeToJSON)),
        'id': value.id,
        'investigation_id': value.investigation_id,
        'warnings': value.warnings,
    };
}

