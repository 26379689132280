/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffSetUserPermissionsRequestBody
 */
export interface StaffSetUserPermissionsRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof StaffSetUserPermissionsRequestBody
     */
    permissions: Array<string>;
}

export function StaffSetUserPermissionsRequestBodyFromJSON(json: any): StaffSetUserPermissionsRequestBody {
    return StaffSetUserPermissionsRequestBodyFromJSONTyped(json, false);
}

export function StaffSetUserPermissionsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSetUserPermissionsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
    };
}

export function StaffSetUserPermissionsRequestBodyToJSON(value?: StaffSetUserPermissionsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
    };
}

