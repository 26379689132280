/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteSeverityBindingPayload
 */
export interface AlertRouteSeverityBindingPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteSeverityBindingPayload
     */
    binding?: EngineParamBindingPayload;
    /**
     * Strategy for merging severity when multiple alerts create/update the same incident
     * @type {string}
     * @memberof AlertRouteSeverityBindingPayload
     */
    merge_strategy: AlertRouteSeverityBindingPayloadMergeStrategyEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertRouteSeverityBindingPayloadMergeStrategyEnum {
    FirstWins = 'first-wins',
    Max = 'max'
}

export function AlertRouteSeverityBindingPayloadFromJSON(json: any): AlertRouteSeverityBindingPayload {
    return AlertRouteSeverityBindingPayloadFromJSONTyped(json, false);
}

export function AlertRouteSeverityBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteSeverityBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': !exists(json, 'binding') ? undefined : EngineParamBindingPayloadFromJSON(json['binding']),
        'merge_strategy': json['merge_strategy'],
    };
}

export function AlertRouteSeverityBindingPayloadToJSON(value?: AlertRouteSeverityBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingPayloadToJSON(value.binding),
        'merge_strategy': value.merge_strategy,
    };
}

