import { CopilotSearch } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Input,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

type SearchesFilterValues = {
  incidentId: string;
  questionType: string;
};

export const SearchesListPage: React.FC = () => {
  return (
    <WorkbenchSubPageWrapper>
      <SearchesList />
    </WorkbenchSubPageWrapper>
  );
};

export const SearchesList = () => {
  const formMethods = useForm<SearchesFilterValues>({
    defaultValues: {},
  });

  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filters = formMethods.watch();

  const {
    data: { searches },
    isLoading,
  } = useAPI(
    "aIStaffListCopilotSearches",
    {
      incidentId: filters.incidentId ?? undefined,
      questionType: filters.questionType ?? undefined,
    },
    { fallbackData: { searches: [] } },
  );

  return (
    <>
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex flex-row items-center gap-4 mb-4">
          <div className="flex-center-y w-full justify-between">
            <div className="flex-center-y gap-4">
              <h2 className="text-lg font-semibold">Searches</h2>
            </div>
            <div className="flex items-center gap-2">
              <div className="relative">
                <Input
                  id="search"
                  placeholder="Search by question..."
                  value={searchTerm}
                  onChange={(e) =>
                    setSearchTerm((e.target as HTMLInputElement).value)
                  }
                  className="w-64"
                />
              </div>
              <Button
                theme={ButtonTheme.Naked}
                onClick={() => setShowFilters(!showFilters)}
                analyticsTrackingId={null}
                icon={IconEnum.Filter}
              >
                <>Filters</>
                <Icon
                  id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                  size={IconSize.Small}
                />
              </Button>
            </div>
          </div>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false} className="mb-4">
            <div className="w-full space-y-2">
              <InputV2
                formMethods={formMethods}
                name="incidentId"
                label="Incident ID"
                placeholder="(e.g. 01FCNDV6P870EA6S7TK1DSYDG0)"
              />
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="questionType"
                placeholder="Select question type"
                options={[
                  { label: "Incident", value: "incident" },
                  { label: "Code Changes", value: "code-changes" },
                ]}
                label={"Question Type"}
                isClearable={true}
              />
            </div>
          </Callout>
        )}
        <LoadingWrapper loading={isLoading}>
          {searches.length === 0 ? (
            <EmptyState
              content={
                searchTerm ? "No matching searches found" : "No searches found"
              }
            />
          ) : (
            <StackedList>
              {searches.map((search) => (
                <SearchRow key={search.id} search={search} />
              ))}
            </StackedList>
          )}
        </LoadingWrapper>
      </Form.Root>
    </>
  );
};

const SearchRow = ({ search }: { search: CopilotSearch }) => {
  const viewHref = `/workbench/searches/${search.id}`;

  const getQuestionTypeBadgeTheme = (type: string): BadgeTheme => {
    switch (type) {
      case "incident":
        return BadgeTheme.Primary;
      case "code-changes":
        return BadgeTheme.Info;
      default:
        return BadgeTheme.Tertiary;
    }
  };

  return (
    <StackedListItem
      key={search.id}
      rowHref={viewHref}
      title={
        <div className="flex items-center gap-2">
          <LocalDateTime
            timestamp={search.cutoff}
            className="font-medium"
            format="dd MMM yy"
          />
          <div className="truncate">{search.question}</div>
        </div>
      }
      accessory={
        <div className="flex items-center gap-2">
          {search.duration_seconds > 0 && (
            <SearchDurationBadge durationSeconds={search.duration_seconds} />
          )}
          {search.cost_cents > 0 && (
            <SearchCostBadge costCents={search.cost_cents} />
          )}
          <Button
            theme={ButtonTheme.Naked}
            icon={IconEnum.ArrowRight}
            href={viewHref}
            analyticsTrackingId={null}
            title=""
            className="justify-end py-4 group-hover:text-slate-900"
          />
        </div>
      }
      description={
        <div className="flex items-center gap-2 mt-2">
          <Badge
            theme={getQuestionTypeBadgeTheme(search.question_type)}
            size={BadgeSize.Small}
          >
            {search.question_type}
          </Badge>
          <div className="text-content-tertiary shrink-0 text-xs">
            {parseCodeChangeSearchAnswer(search.answer).length || 0} item(s)
          </div>
          {search.copilot_thread_id && (
            <Button
              theme={ButtonTheme.Link}
              href={`/workbench/threads/${search.copilot_thread_id}`}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId={null}
              openInNewTab
              size={BadgeSize.ExtraSmall}
            >
              View Copilot thread
            </Button>
          )}
        </div>
      }
    />
  );
};

// Badge components to display duration and cost with color coding
export const SearchDurationBadge = ({
  durationSeconds,
}: {
  durationSeconds: number;
}) => {
  // Define thresholds for duration categories
  // good = <8s, bad is >15s
  let badgeTheme = BadgeTheme.Success;

  if (durationSeconds > 15) {
    badgeTheme = BadgeTheme.Error;
  } else if (durationSeconds > 8) {
    badgeTheme = BadgeTheme.Warning;
  }

  const tooltipContent = (
    <div className="text-xs">
      <div className="flex flex-col gap-1">
        <div>• Good: &lt; 8 seconds</div>
        <div>• Medium: 8-15 seconds</div>
        <div>• Slow: &gt; 15 seconds</div>
      </div>
    </div>
  );

  return (
    <Tooltip content={tooltipContent}>
      <Badge theme={badgeTheme} size={BadgeSize.Small}>
        <div className="flex items-center gap-0.5">
          <Icon id={IconEnum.Timer} size={IconSize.Small} />
          {durationSeconds.toFixed(2)}s
        </div>
      </Badge>
    </Tooltip>
  );
};

export const SearchCostBadge = ({ costCents }: { costCents: number }) => {
  // Define thresholds for cost categories
  // good = <$0.10, bad is >$0.50
  const costDollars = costCents / 100.0;
  let badgeTheme = BadgeTheme.Success;

  if (costDollars > 0.15) {
    badgeTheme = BadgeTheme.Error;
  } else if (costDollars > 0.08) {
    badgeTheme = BadgeTheme.Warning;
  }

  const tooltipContent = (
    <div className="text-xs">
      <div className="flex flex-col gap-1">
        <div>• Low: &lt; $0.08</div>
        <div>• Medium: $0.08-$0.15</div>
        <div>• High: &gt; $0.15</div>
      </div>
    </div>
  );

  return (
    <Tooltip content={tooltipContent}>
      <Badge theme={badgeTheme} size={BadgeSize.Small}>
        <div className="flex items-center gap-0.5">
          <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
          {costDollars.toFixed(2)}
        </div>
      </Badge>
    </Tooltip>
  );
};

interface ExtendedCopilotSearchAnswer {
  permalinks?: string[];
  certain_results?: string[];
}

export const parseCodeChangeSearchAnswer = (
  answer: CopilotSearch["answer"],
): string[] => {
  // Cast the answer to our extended type once
  const typedAnswer = answer as ExtendedCopilotSearchAnswer;
  const results = typedAnswer.permalinks || typedAnswer.certain_results || [];

  return results;
};
