import {
  CatalogResource,
  CatalogType,
  CatalogTypeAttributeModeEnum,
  CatalogTypeAttributePayloadModeEnum as AttributePayloadModeEnum,
  DependentResource,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { getPrimitiveIcon } from "@incident-shared/catalog";
import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { DragHandle } from "@incident-shared/forms/v2/inputs/SortableListV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { IntegrationConfig } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Input,
  Toggle,
  Tooltip,
} from "@incident-ui";
import { InputSize } from "@incident-ui/Input/Input";
import { cloneDeep, groupBy } from "lodash";
import pluralize, { singular } from "pluralize";
import { useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { UseFormReturn } from "react-hook-form";

import { buildDataAttributeOptions } from "./AddDataAttributePopover";
import { BacklinkIsArrayModal } from "./BacklinkIsArrayModal";
import { DerivedAttributeBadge } from "./DerivedAttributeBadge";
import { LockedCell } from "./LockedCell";
import {
  AttributeFormState,
  CatalogSchemaFieldMethods,
  CatalogTypeCreateEditFormState,
  SchemaAttributePath,
} from "./types";

// This is a row representing the Name attribute that all types implicitly have.
// It's a load of no-op disabled inputs, just helping people be aware it's
// there.
export const CatalogTypeSchemaRowName = () => {
  return (
    <CatalogTypeSchemaFakeRow
      id="name"
      label="Name"
      typeName="Text"
      disabledTooltipContent={
        <>
          All catalog entries are required to have a name. This is so they can
          be presented in dropdowns, such as when used to power custom fields.
        </>
      }
    />
  );
};

export const CatalogTypeSchemaRowRank = () => {
  return (
    <CatalogTypeSchemaFakeRow
      id="rank"
      label="Rank"
      typeName="Number"
      disabledTooltipContent={
        <>The rank will determine how the entries are ordered in dropdowns.</>
      }
    />
  );
};

const CatalogTypeSchemaFakeRow = ({
  id,
  label,
  typeName,
  disabledTooltipContent,
}: {
  id: string;
  label: string;
  typeName: string;
  disabledTooltipContent?: React.ReactNode;
}) => {
  return (
    <tr>
      <td className={"!pl-[35px] !pr-[12px]"}>
        <Input
          size={InputSize.Medium}
          disabled={true}
          id={`attribute-${id}-name`}
          value={label}
          className="w-full"
        />
      </td>
      <td>
        <CatalogEntryBadge
          color={ColorPaletteEnum.Slate}
          label={typeName}
          icon={getPrimitiveIcon(typeName)}
          suffix={<Icon id={IconEnum.LockClosed} size={IconSize.Small} />}
        />
      </td>
      <td>
        <Toggle
          id={`attribute-${id}-multi`}
          onToggle={() => null}
          label={""}
          on={false}
          toggleClassName={"!bg-none"}
          disabled={true}
        />
      </td>
      <td>
        <div className={"flex justify-end"}>
          <GatedButton
            theme={ButtonTheme.Tertiary}
            disabledTooltipContent={disabledTooltipContent}
            size={BadgeSize.Medium}
            title=""
            icon={IconEnum.Delete}
            upgradeRequired={false}
            analyticsTrackingId={null}
            disabled
          />
        </div>
      </td>
    </tr>
  );
};

export const CatalogTypeSchemaRow = ({
  index,
  attribute,
  mode,
  formMethods,
  fieldMethods,
  onDelete,
  provided,
  isDragging,
  dependencies,
  resources,
  catalogTypes,
  requiredIntegration,
  path,
  typeManagedExternally,
  isTeamAttribute,
}: {
  index: number;
  attribute: AttributeFormState;
  mode: CatalogTypeAttributeModeEnum;
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState, unknown>;
  fieldMethods: CatalogSchemaFieldMethods;
  onDelete: () => void;
  provided: DraggableProvided;
  isDragging: boolean;
  dependencies: DependentResource[];
  resources: CatalogResource[];
  catalogTypes: CatalogType[];
  requiredIntegration?: IntegrationConfig;
  path: SchemaAttributePath;
  typeManagedExternally: boolean;
  isTeamAttribute: boolean;
}) => {
  const catalogTypeName = formMethods.watch("name");
  const selectedType = formMethods.watch(`${path}.type`);
  const arrayNotSupported = ["Text", "Bool", "Number"].includes(selectedType);
  const [showBacklinkMultiValueModal, setShowBacklinkMultiValueModal] =
    useState(false);

  const isExternal = mode === CatalogTypeAttributeModeEnum.External;
  const isInternal = mode === CatalogTypeAttributeModeEnum.Internal;
  const isBacklink = mode === CatalogTypeAttributeModeEnum.Backlink;
  const isPath = mode === CatalogTypeAttributeModeEnum.Path;
  const isDynamic = mode === CatalogTypeAttributeModeEnum.Dynamic;

  const isDisabled =
    typeManagedExternally ||
    isDynamic ||
    isExternal ||
    isInternal ||
    isTeamAttribute;

  const hasDependentResources = dependencies?.length > 0;

  const canDelete =
    !typeManagedExternally &&
    !hasDependentResources &&
    !isExternal &&
    !isInternal &&
    !isTeamAttribute;

  let cannotModifyTooltip: React.ReactNode | undefined = undefined;

  if (isExternal) {
    cannotModifyTooltip =
      "This field is externally synced and cannot be modified";
  } else if (isInternal) {
    cannotModifyTooltip =
      "This field is managed by incident.io and cannot be modified";
  } else if (isTeamAttribute) {
    cannotModifyTooltip =
      "This field is used to power your teams and cannot be modified";
  } else if (hasDependentResources) {
    const groupedDependencies = groupBy(dependencies, "resource_type");

    cannotModifyTooltip = (
      <DependentResourceList
        requiresDeletionResources={Object.values(groupedDependencies)}
        title={attribute.name}
        whiteText={true}
        verb="modify"
      />
    );
  }

  return (
    <>
      <tr
        className="bg-white"
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <LockedCell isDragging={isDragging}>
          <div className={"flex flex-center-y"}>
            <DragHandle className="mr-2 flex-0" {...provided.dragHandleProps} />
            <InputV2
              insetSuffixNode={
                <IntegrationIndicator
                  mode={mode}
                  requiredIntegration={requiredIntegration}
                />
              }
              size={InputSize.Medium}
              className="w-full"
              name={`${path}.name`}
              disabled={isDisabled}
              formMethods={formMethods}
              placeholder="Attribute name"
              required
            />
          </div>
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          <AttributeTypeBadge
            formMethods={formMethods}
            path={path}
            attribute={attribute}
            cannotModifyTooltip={cannotModifyTooltip}
            isDisabled={isDisabled || hasDependentResources}
            resources={resources}
            catalogTypes={catalogTypes}
          />
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          {isBacklink ? (
            <Toggle
              labelledById={`${path}.array`}
              id={`${path}.array`}
              on={attribute.array}
              onToggle={() => setShowBacklinkMultiValueModal(true)}
            />
          ) : (
            <ToggleV2
              name={`${path}.array`}
              formMethods={formMethods}
              disabled={
                isDisabled ||
                arrayNotSupported ||
                hasDependentResources ||
                isPath ||
                attribute.array
              }
              isDisabledTooltipContent={
                isPath
                  ? "This is derived from the path you've chosen"
                  : arrayNotSupported
                  ? `Multi-valued ${selectedType.toLowerCase()} attributes are not supported`
                  : attribute.array
                  ? "A multi-value attribute cannot be converted to a scalar. Please remove this attribute and create a new one."
                  : cannotModifyTooltip
              }
            />
          )}
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          <div className="w-full flex items-center justify-end">
            <GatedButton
              disabled={!canDelete}
              disabledTooltipContent={cannotModifyTooltip}
              analyticsTrackingId="catalog-schema-delete-attribute"
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Medium}
              icon={IconEnum.Delete}
              onClick={onDelete}
              title="delete"
            />
          </div>
        </LockedCell>
      </tr>
      {showBacklinkMultiValueModal && (
        <BacklinkIsArrayModal
          attribute={attribute}
          onClose={() => setShowBacklinkMultiValueModal(false)}
          onSubmit={async (data) => {
            fieldMethods.update(index, { ...attribute, array: data.array });
            setShowBacklinkMultiValueModal(false);
          }}
          catalogTypeName={catalogTypeName}
        />
      )}
    </>
  );
};

const IntegrationIndicator = ({
  mode,
  requiredIntegration,
}: {
  mode: CatalogTypeAttributeModeEnum;
  requiredIntegration?: IntegrationConfig;
}) => {
  let icon: IconEnum | undefined = undefined;
  let tooltipContent: string | undefined = undefined;

  switch (mode) {
    case CatalogTypeAttributeModeEnum.External:
    case CatalogTypeAttributeModeEnum.Dynamic:
      if (requiredIntegration) {
        icon = requiredIntegration.icon;
        tooltipContent = `This attribute is synced from ${requiredIntegration.label}`;
      } else {
        icon = IconEnum.IncidentFlame;
        tooltipContent = `This attribute is managed by incident.io and cannot be modified`;
      }
      break;
    case CatalogTypeAttributeModeEnum.Internal:
      icon = IconEnum.IncidentFlame;
      tooltipContent = `This attribute is managed by incident.io and cannot be modified`;
  }

  if (icon) {
    return (
      <Tooltip content={tooltipContent}>
        <div>
          <Icon id={icon} size={IconSize.Small} />
        </div>
      </Tooltip>
    );
  }

  return <></>;
};

const AttributeTypeBadge = ({
  formMethods,
  path,
  attribute,
  cannotModifyTooltip,
  isDisabled,
  resources,
  catalogTypes,
}: {
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState, unknown>;
  path: SchemaAttributePath;
  attribute: AttributeFormState;
  cannotModifyTooltip: React.ReactNode;
  isDisabled;
  resources: CatalogResource[];
  catalogTypes: CatalogType[];
}): React.ReactElement => {
  const catalogTypeName = formMethods.watch("name");
  const dataAttributes = formMethods.watch("schema.data_attributes");

  const resource = resources.find(
    (resource) => resource.type === attribute.type,
  );
  if (!resource && !attribute.catalogTypeInfo) {
    return (
      <Badge
        icon={IconEnum.Warning}
        theme={BadgeTheme.Error}
        size={BadgeSize.Medium}
      >
        Unknown type
      </Badge>
    );
  }

  if (
    attribute.mode === AttributePayloadModeEnum.Backlink ||
    attribute.mode === AttributePayloadModeEnum.Path
  ) {
    return (
      <DerivedAttributeTypeIndicator
        attribute={attribute}
        dataAttributes={dataAttributes}
        catalogTypes={catalogTypes}
        catalogTypeName={catalogTypeName}
      />
    );
  }

  const icon = resource
    ? resource.icon || getPrimitiveIcon(resource.type)
    : IconEnum.Box;
  const color = resource
    ? (resource.color as unknown as ColorPaletteEnum) || ColorPaletteEnum.Slate
    : ColorPaletteEnum.Blue;

  const resourceOptions = buildDataAttributeOptions(resources);

  // If you choose a resource type that we don't support arrays for, we lock the
  // multi-value switch and set it to false
  const onChangeAttributeType = (attributeType: string | null | undefined) => {
    if (!attributeType) {
      return;
    }

    const arrayNotSupported = ["Text", "Bool", "Number"].includes(
      attributeType,
    );

    if (arrayNotSupported) {
      formMethods.setValue(`${path}.array`, false);
    }
  };

  return (
    <PopoverSingleSelectV2
      renderTriggerNode={({ onClick }) => (
        <button onClick={onClick}>
          <CatalogEntryBadge
            color={color}
            label={resource ? resource.label : attribute.catalogTypeInfo?.name}
            icon={icon}
            clickable={!isDisabled}
            suffix={
              <Icon
                id={isDisabled ? IconEnum.LockClosed : IconEnum.Edit}
                size={IconSize.Small}
              />
            }
            className="max-w-full"
          />
        </button>
      )}
      onValueChange={(value) => {
        onChangeAttributeType(value);
      }}
      name={`${path}.type`}
      formMethods={formMethods}
      disabled={isDisabled}
      disabledTooltipContent={cannotModifyTooltip}
      required
      options={resourceOptions}
    />
  );
};

const DerivedAttributeTypeIndicator = ({
  attribute,
  catalogTypes,
  dataAttributes,
  catalogTypeName,
}: {
  attribute: AttributeFormState;
  catalogTypes: CatalogType[];
  dataAttributes: AttributeFormState[];
  catalogTypeName: string;
}): React.ReactElement => {
  let suffix: React.ReactNode | undefined = undefined;

  if (attribute.mode === AttributePayloadModeEnum.Backlink) {
    const referencedType = catalogTypes.find(
      (ct) => ct.type_name === attribute.type,
    );
    const attributeName = referencedType?.schema?.attributes?.find(
      (attr) => attr.id === attribute.backlink_attribute,
    )?.name;

    if (referencedType && attributeName) {
      // Return the Customers for which this User is a Salesforce Account Owner
      let tooltipContent = `Return the ${pluralize(
        referencedType.name,
      )} for which this ${catalogTypeName} is a ${singular(attributeName)}`;
      // Return the Modules which reference this Feature.
      const attributeNameMatchesTypeName =
        attributeName === catalogTypeName ||
        pluralize(attributeName) === catalogTypeName ||
        attributeName === pluralize(catalogTypeName);

      if (attributeNameMatchesTypeName) {
        tooltipContent = `Return the ${pluralize(
          referencedType.name,
        )} which reference this ${catalogTypeName}`;
      }

      suffix = (
        <Tooltip
          analyticsTrackingId={null}
          content={tooltipContent}
          side="right"
          buttonClassName="ml-1"
        >
          <div className="cursor-pointer">
            <Icon id={IconEnum.BacklinkSmall} size={IconSize.Small} />
          </div>
        </Tooltip>
      );
    }
  }

  // Mutate the attribute path based on the latest 'data attributes' data.
  let path = cloneDeep(attribute.path);
  if (path) {
    const attributeLabelLookup = {};
    dataAttributes.forEach((attr) => {
      attributeLabelLookup[attr.id] = attr.name;
    });

    path = path.map((pathItem) => ({
      ...pathItem,
      attribute_name:
        attributeLabelLookup[pathItem.attribute_id] || pathItem.attribute_name,
    }));
  }

  return (
    <DerivedAttributeBadge
      attribute={{ ...attribute, path }}
      catalogTypes={catalogTypes}
      suffix={suffix}
      className="max-w-[400px]"
    />
  );
};
