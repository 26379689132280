import {
  EscalationPathsCreateExternalRequestBody,
  ExternalEscalationPath,
  ExternalEscalationPathNativeConfigUnavailableReasonsEnum,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  User,
  UserOptionStateEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Input,
  Link,
  Loader,
  TabSection,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { SelectableTable } from "@incident-ui/Table/SelectableTable";
import { TableCell, TableHeaderCell, TableRow } from "@incident-ui/Table/Table";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import pluralize from "pluralize";
import { ChangeEvent, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import { useIntegrations } from "../../../hooks/useIntegrations";
import { tcx } from "../../../utils/tailwind-classes";
import { getColor } from "../../../utils/twConfig";
import { useRevalidate } from "../../../utils/use-revalidate";
import { assertUnreachable } from "../../../utils/utils";
import { OnCallPromotionConfirmationModal } from "../../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { useOnCallPromotionState } from "../../legacy/on-call/common/useOnCallPromotionState";
import { UnresolvedUsersTooltipContent } from "../../legacy/on-call/schedules/ScheduleImportDrawer";
import { ExternalScheduleBadges } from "./ExternalScheduleBadges";

const capPerImport = 25;

export const EscalationPathsImportDrawer = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const [search, setSearch] = useState("");
  const [selectedPolicies, setSelectedPolicies] = useState<Set<string>>(
    new Set(),
  );

  const [selectedSchedules, setSelectedSchedules] = useState<Set<string>>(
    new Set(),
  );
  const [debouncedSearch] = useDebounce(search, 500);

  const { integrations, integrationsError, integrationsLoading } =
    useIntegrations();

  const installedPagerduty = integrations?.find(
    (i) => i.provider === IntegrationProvider.Pagerduty && i.installed,
  );

  const {
    responses,
    isLoading,
    isFullyLoaded,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "escalationPathsListExternal",
    {
      pageSize: 15,
      search: debouncedSearch,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const showToast = useToast();

  const policies: ExternalEscalationPath[] = responses.flatMap(
    (r) => r.external_escalation_paths,
  );

  const policiesForTab = {
    ready: policies.filter(
      (p) =>
        p.native_config_unavailable_reasons.length === 0 &&
        !p.created_native_escalation_path_id,
    ),
    incompatible: policies.filter(
      (p) =>
        p.native_config_unavailable_reasons.length > 0 &&
        !p.created_native_escalation_path_id,
    ),
    imported: policies.filter((p) => p.created_native_escalation_path_id),
  };

  const alreadyImportedPolicies = policies
    .filter((p) => p.created_native_escalation_path_id)
    .map((p) => p.id);

  const schedulesToCreate = policies
    .filter((p) => selectedPolicies.has(p.id))
    .flatMap((p) => p.referenced_external_schedules || [])
    .filter((s) => s.created_native_schedule_id === undefined)
    .map((s) => s.id);

  const pathsToImport = useMemo(
    () => policies.filter((p) => selectedPolicies.has(p.id)),
    [policies, selectedPolicies],
  );

  const usersToUpgrade = pathsToImport.reduce<Record<string, User>>(
    (acc, policy) => {
      (policy.users_needing_upgrades ?? []).forEach((user) => {
        acc[user.id] = user;
      });
      (policy.referenced_external_schedules ?? []).forEach((schedule) => {
        (schedule.users_needing_upgrades ?? []).forEach((user) => {
          acc[user.id] = user;
        });
      });

      return acc;
    },
    {},
  );

  const { promotionState, setPromotionState, handlePromotion } =
    useOnCallPromotionState<ExternalEscalationPath[]>({
      users: Object.keys(usersToUpgrade),
      userCache: {
        getHydratedUser: (id) => {
          return {
            ...usersToUpgrade[id],
            state: UserOptionStateEnum.Viewer, // The backend's told us these users need upgrades, so we set this.
            label: usersToUpgrade[id].name,
          };
        },
      },
    });

  const revalidateEPs = useRevalidate([
    "escalationPathsList",
    "escalationPathsListExternal",
    "escalationPathsShow",
    "usersTypeahead",
  ]);

  const orgAwareNavigate = useOrgAwareNavigate();

  const { trigger: createPolicies, isMutating: isSaving } = useAPIMutation(
    "escalationPathsListExternal",
    {},
    async (apiClient, data: EscalationPathsCreateExternalRequestBody) => {
      const resp = await apiClient.escalationPathsCreateExternal({
        createExternalRequestBody: data,
      });

      showToast({
        title: `${selectionSummaryText(
          selectedPolicies.size,
          schedulesToCreate.length,
        )} created`,
        theme: ToastTheme.Success,
      });
      revalidateEPs();
      if (resp.escalation_paths.length === 1) {
        orgAwareNavigate(
          `/on-call/escalation-paths/${resp.escalation_paths[0].id}`,
        );
      } else {
        onClose();
      }
    },
    {
      showErrorToast: "Failed to import escalation policies",
    },
  );

  const createPoliciesOrPromoteUsers = async () => {
    const paths = policies.filter((p) => selectedPolicies.has(p.id));

    const { shouldPromote, userIdsToPromote } = await handlePromotion(paths);
    if (shouldPromote) {
      await createPolicies({
        from_path_ids: paths.map((p) => p.id),
        user_ids_to_promote: userIdsToPromote,
      });
    }
  };

  const togglePolicy = (policyId: string, selected: boolean) => {
    setSelectedPolicies((prev) => {
      if (selected) {
        const res = new Set([...prev, policyId]);
        if (res.size === capPerImport) {
          showToast({
            title: `You can only import up to ${capPerImport} escalation policies at a time.`,
          });
        }
        return res;
      } else {
        return new Set([...prev].filter((p) => p !== policyId));
      }
    });
    setSelectedSchedules((prev) => {
      const next = new Set(prev);

      policies
        .find((p) => p.id === policyId)
        ?.referenced_external_schedules?.forEach((s) => {
          if (selected) {
            next.add(s.id);
          } else {
            next.delete(s.id);
          }
        });
      return next;
    });
  };

  const [currentTab, setCurrentTab] = useState("ready");

  if (integrationsError) {
    return <GenericErrorMessage error={integrationsError} />;
  }

  return (
    <Drawer onClose={onClose} width="large">
      <DrawerContents>
        <DrawerTitle
          title="Import escalation policies"
          icon={IconEnum.Download}
          onClose={onClose}
          color={ColorPaletteEnum.Slate200}
          hexColor={getColor("slate", "50")}
          subtitle={
            "Select which escalation policies, and related schedules you want to import. We won’t duplicate schedules that you have already imported, or that appear across multiple policies."
          }
          className={"sticky top-0 z-[50]"}
        />
        {integrationsLoading ? (
          <Loader />
        ) : !installedPagerduty ? (
          <EmptyState />
        ) : (
          <>
            <OnCallPromotionConfirmationModal
              noun="escalation paths"
              state={promotionState}
              onClose={() => setPromotionState(null)}
              onSubmit={(paths, userIdsToPromote) =>
                createPolicies({
                  from_path_ids: paths.map((p) => p.id),
                  user_ids_to_promote: userIdsToPromote,
                })
              }
            />
            <DrawerBody className="flex flex-col p-6 gap-4">
              <Input
                id="search"
                placeholder="Search for policy"
                type={InputType.Search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value ?? "")
                }
                value={search}
                iconName={IconEnum.Search}
                iconProps={{
                  id: IconEnum.Search,
                  className: "text-content-tertiary",
                }}
                className="rounded-lg justify-start items-center gap-2 inline-flex bg-surface-secondary"
              />
              <TabSection
                tabs={[
                  {
                    label: "Ready to import",
                    id: "ready",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["ready"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Incompatible",
                    id: "incompatible",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["incompatible"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Already imported",
                    id: "imported",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["imported"].length}`}
                      </Badge>
                    ),
                  },
                ]}
                value={currentTab}
                onTabChange={(newTab) => {
                  setCurrentTab(newTab);
                }}
                withIndicator
                tabBarClassName="border-b border-stroke mb-4"
              >
                {policiesForTab[currentTab].length > 0 && (
                  <SelectableTable<ExternalEscalationPath>
                    gridTemplateColumns="1fr minmax(0, 1fr) auto"
                    header={
                      <>
                        <TableHeaderCell title="Escalation policy" />
                        <TableHeaderCell title="Schedules" />
                        <TableHeaderCell className={"justify-end"} />
                      </>
                    }
                    wrappedInBox
                    data={policiesForTab[currentTab]}
                    isRowDisabled={(policy) => {
                      // If we've hit the cap, only allow _deselecting_
                      if (selectedPolicies.size >= capPerImport) {
                        return !selectedPolicies.has(policy.id);
                      }
                      return (
                        policy.native_config_unavailable_reasons.length > 0 ||
                        !!policy.created_native_escalation_path_id
                      );
                    }}
                    renderRow={(policy, index, checkbox) => {
                      if (!policy) {
                        return null;
                      }
                      const isDisabled =
                        policy.native_config_unavailable_reasons.length > 0 ||
                        !!policy.created_native_escalation_path_id;

                      return (
                        <TableRow
                          key={index}
                          isLastRow={
                            index === policiesForTab[currentTab].length - 1
                          }
                        >
                          {checkbox}
                          <TableCell
                            key={`${index}-policy`}
                            className={tcx("flex items-center gap-2", {
                              "opacity-40": isDisabled,
                            })}
                          >
                            <Badge
                              icon={IconEnum.Pagerduty}
                              theme={BadgeTheme.Unstyled}
                              size={BadgeSize.Medium}
                              className={
                                "flex flex-row gap-1 bg-[#E6F6EB] items-center text-ellipsis overflow-hidden max-w-[40ch]"
                              }
                            >
                              <span className={"overflow-hidden text-ellipsis"}>
                                {policy.name}
                              </span>
                            </Badge>
                          </TableCell>
                          <TableCell
                            key={`${index}-schedule`}
                            className={tcx(
                              "flex items-center gap-2 flex-wrap",
                              {
                                "opacity-40": isDisabled,
                              },
                              "max-w-[30ch]",
                            )}
                          >
                            <ExternalScheduleBadges
                              schedules={
                                policy.referenced_external_schedules || []
                              }
                            />
                          </TableCell>
                          <TableCell className={"inline-flex justify-end"}>
                            {renderTooltipAndBadge(policy)}
                          </TableCell>
                        </TableRow>
                      );
                    }}
                    selected={Array.from([
                      ...selectedPolicies,
                      ...alreadyImportedPolicies,
                    ])}
                    onSelectChanged={(id, newValue) => {
                      togglePolicy(id, newValue);
                    }}
                    selectAll={
                      selectedPolicies.size > 0 &&
                      _.isEqual(
                        Array.from(selectedPolicies).sort(),
                        policiesForTab[currentTab].map((p) => p.id).sort(),
                      )
                    }
                    onSelectAllChanged={(selected) => {
                      if (!selected) {
                        setSelectedPolicies(new Set());
                        return;
                      }
                      const selectable = policiesForTab[currentTab]
                        .filter(
                          (p) =>
                            p.native_config_unavailable_reasons.length === 0 &&
                            p.created_native_escalation_path_id === undefined,
                        )
                        .map((p) => p.id);

                      if (selectable.length <= capPerImport) {
                        setSelectedPolicies(new Set(selectable));
                        return;
                      }

                      showToast({
                        title: `You can only import up to ${capPerImport} escalation policies at a time.`,
                        description:
                          "Once you've imported these, come back here to import more.",
                      });
                      setSelectedPolicies(
                        new Set(_.take(selectable, capPerImport)),
                      );
                      return;
                    }}
                  />
                )}
                {!isLoading && isFullyLoaded && policies.length === 0 && (
                  <div className="p-4 text-center text-content-secondary">
                    No escalation policies found
                  </div>
                )}
              </TabSection>
              {!isFullyLoaded && (
                <div className={"flex-center"} ref={infiniteScrollRef}>
                  <Loader />
                </div>
              )}
            </DrawerBody>
            <DrawerFooter
              className={
                "flex items-center justify-between gap-2 sticky bottom-0 z-[50] bg-white"
              }
            >
              {policies.length > 0 ? (
                <span className="text-sm font-medium text-content-tertiary">
                  {`${selectionSummaryText(
                    selectedPolicies.size,
                    selectedSchedules.size,
                  )} selected`}
                </span>
              ) : (
                <div className={"grow"} />
              )}
              <div className={"flex gap-2"}>
                <Button
                  theme={ButtonTheme.Secondary}
                  onClick={onClose}
                  analyticsTrackingId="cancel-policy-import"
                >
                  Back
                </Button>
                <Button
                  onClick={() => createPoliciesOrPromoteUsers()}
                  disabled={selectedPolicies.size === 0}
                  analyticsTrackingId="confirm-policy-import"
                  theme={ButtonTheme.Primary}
                  loading={isSaving}
                >
                  Import
                </Button>
              </div>
            </DrawerFooter>
          </>
        )}
      </DrawerContents>
    </Drawer>
  );
};

const selectionSummaryText = (
  selectedPoliciesCount: number,
  selectedSchedulesCount: number,
) => {
  if (selectedPoliciesCount === 0) {
    return "No escalation policies";
  }

  const schedulesText =
    selectedSchedulesCount > 0
      ? `and ${selectedSchedulesCount} ${pluralize(
          "schedule",
          selectedSchedulesCount,
        )} `
      : "";

  return `${selectedPoliciesCount} escalation ${pluralize(
    "policy",
    selectedPoliciesCount,
  )} ${schedulesText}`;
};

const EmptyState = () => {
  const { integrations } = useIntegrations();
  const hasOpsgenie = integrations?.some(
    (i) => i.provider === IntegrationProvider.Opsgenie && i.installed,
  );

  if (hasOpsgenie) {
    return (
      <div className="p-6 space-y-4">
        <span>
          We can only import escalation policies from PagerDuty at the moment.
          You can still import schedules from Opsgenie.
        </span>
        <div className="flex flex-wrap gap-4">
          <Button
            analyticsTrackingId={"configure-pagerduty-for-import"}
            href="/settings/integrations/pagerduty"
            icon={IconEnum.Pagerduty}
          >
            Connect PagerDuty
          </Button>
          <Button
            analyticsTrackingId={"ep-import-not-available-import-schedule"}
            href="/on-call/schedules/import"
            icon={IconEnum.Calendar}
          >
            Import schedules
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={"p-6 space-y-4"}>
      <span>
        To import escalation policies, you need to connect your PagerDuty or
        Opsgenie account.
      </span>
      <div className="flex flex-wrap gap-4">
        <Button
          analyticsTrackingId={`configure-pagerduty-for-import`}
          href="/settings/integrations/pagerduty"
          icon={IconEnum.Pagerduty}
        >
          Connect PagerDuty
        </Button>
        <Button
          analyticsTrackingId={`configure-opsgenie-for-import`}
          href="/settings/integrations/opsgenie"
          icon={IconEnum.Opsgenie}
        >
          Connect Opsgenie
        </Button>
      </div>
    </div>
  );
};

const tooltipTextForScheduleReason = (
  schedule: ExternalSchedule,
): string | undefined => {
  switch (schedule.native_config_unavailable_reason) {
    case ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval:
      return `The ${schedule.name} schedule has an interval that isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture:
      return `The ${schedule.name} schedule ends in the future, which isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers:
      return `The ${schedule.name} schedule has no usable layers, which isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced:
      return `The ${schedule.name} schedule has no configuration synced.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions:
      return `The ${schedule.name} schedule has a restriction that isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedParticipants:
      return `There are team references on ${schedule.name} that isn't supported by incident.io`;
    case undefined:
      return undefined;
    default:
      assertUnreachable(schedule.native_config_unavailable_reason);
      return undefined;
  }
};

const tooltipTextForEPReason = (
  reason: ExternalEscalationPathNativeConfigUnavailableReasonsEnum,
): string | undefined => {
  switch (reason) {
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.TooManyRepeats:
      return "This escalation policy repeats too many times to be supported by incident.io.";
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.UnsupportedFeature:
      return "This escalation policy uses a feature that isn't supported by incident.io.";
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.DirectUsersMissing:
      return "This escalation policy references users that aren't available in incident.io.";
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.MissingScheduleIds:
      return "This escalation policy references schedules that aren't available in incident.io.";
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.ScheduleWithAllUserIdsMissing:
      return "This escalation policy references a schedule that doesn't have any users that could be associated with an incident.io user.";
    case undefined:
      return undefined;
    default:
      assertUnreachable(reason);
      return undefined;
  }
};

const tooltipComponentForReason = (
  reasonText: string | undefined,
): React.ReactNode => {
  if (reasonText === undefined) {
    return null;
  }

  return (
    <span className="text-sm">
      {reasonText} Learn more about importing{" "}
      <Link
        className={"text-sm"}
        openInNewTab
        href={
          "https://help.incident.io/articles/7709430939-importing-schedules-and-escalation-policies-from-pagerduty"
        }
        analyticsTrackingId={"connected-users-helpcenter"}
      >
        here
      </Link>
    </span>
  );
};

const renderTooltipAndBadge = (
  ep: ExternalEscalationPath,
): React.ReactNode | null => {
  // If we've imported this, show the imported badge
  if (ep.created_native_escalation_path_id) {
    return (
      <Badge theme={BadgeTheme.Info}>
        Already imported <Icon id={IconEnum.ExternalLink} />
      </Badge>
    );
  }

  // We'll show a badge warning if there's a schedule with unresolved users,
  // but it's still an allowed operation.
  const hasUnresolvedScheduleUsers = ep.referenced_external_schedules?.some(
    (s) => (s.unresolvable_external_users?.length ?? 0) > 0,
  );

  // Else if this importable, nothing to display
  if (
    ep.native_config_unavailable_reasons.length === 0 &&
    !hasUnresolvedScheduleUsers
  ) {
    return <></>;
  }

  // Otherwise we need to figure out why this isn't importable
  // and show the appropriate badge and tooltip.

  let unavailableDisplayInfo: {
    tooltip: React.ReactNode;
    badge: React.ReactNode;
  } | null = null;

  // If we see any of these, we need to show the unsupported badge
  const incompatibleReasons = [
    ExternalEscalationPathNativeConfigUnavailableReasonsEnum.UnsupportedFeature,
    ExternalEscalationPathNativeConfigUnavailableReasonsEnum.TooManyRepeats,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval,
    ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture,
    ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers,
    ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions,
  ];

  const epUnavailableReason = ep.native_config_unavailable_reasons.find((r) =>
    incompatibleReasons.includes(r),
  );
  const scheduleUnavailableReason = ep.referenced_external_schedules?.find(
    (s) => {
      return (
        s.native_config_unavailable_reason &&
        incompatibleReasons.includes(s.native_config_unavailable_reason)
      );
    },
  );

  // If the EP itself has an unavailable reason, then show that
  if (epUnavailableReason) {
    unavailableDisplayInfo = {
      badge: (
        <Badge theme={BadgeTheme.Error}>
          Incompatible <Icon id={IconEnum.Alert} />
        </Badge>
      ),
      tooltip: tooltipComponentForReason(
        tooltipTextForEPReason(epUnavailableReason),
      ),
    };
  } else if (scheduleUnavailableReason) {
    // Otherwise check if there's a schedule we fundamentally don't support the config of
    unavailableDisplayInfo = {
      badge: (
        <Badge theme={BadgeTheme.Error}>
          Incompatible <Icon id={IconEnum.Alert} />
        </Badge>
      ),
      tooltip: tooltipComponentForReason(
        tooltipTextForScheduleReason(scheduleUnavailableReason),
      ),
    };
  } else {
    // Otherwise, it must be that there are unknown users, show the tooltip for that

    const missingScheduleUsers =
      ep.referenced_external_schedules?.flatMap(
        (s) => s.unresolvable_external_users ?? [],
      ) ?? [];
    const missingUsers = [
      ...ep.unresolvable_directly_referenced_external_users,
      ...missingScheduleUsers,
    ];

    if (missingUsers.length > 0) {
      const areMissingFromSchedulesOnly =
        ep.unresolvable_directly_referenced_external_users.length === 0;

      unavailableDisplayInfo = {
        badge: (
          <Badge
            theme={
              areMissingFromSchedulesOnly
                ? BadgeTheme.Warning
                : BadgeTheme.Error
            }
          >
            {`Missing ${
              missingUsers.length === 0
                ? "users"
                : pluralize("user", missingUsers.length)
            }`}
            <Icon id={IconEnum.Info} />
          </Badge>
        ),
        tooltip: (
          <UnresolvedUsersTooltipContent
            users={missingUsers}
            provider={
              ep.external_provider as ExternalScheduleExternalProviderEnum
            }
            entity={"escalation policy"}
            usersBlockingImport={
              ep.unresolvable_directly_referenced_external_users
            }
          />
        ),
      };
    }
  }

  if (!unavailableDisplayInfo) {
    return null;
  }

  return (
    <Tooltip
      noMaxWidth
      bubbleProps={{
        className: "max-w-[400px]",
      }}
      content={unavailableDisplayInfo.tooltip}
    >
      {/* We need the div for the tooltip hover to work */}
      <div>{unavailableDisplayInfo.badge}</div>
    </Tooltip>
  );
};
