import {
  EvaluationScorecardEvent,
  EvaluationScorecardGrade,
  EvaluationScorecardMetric,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  Tooltip,
} from "@incident-ui";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";

import { CodeViewer } from "../common/CodeViewer";
import {
  ScorecardMetricIdentifier,
  useEvaluationFilterContext,
} from "../common/EvaluationFilterContext";
import { EvaluationGradeEvents } from "../investigations/EvaluationGradeEvents";

type EvaluationScorecardGradesProps = {
  grades: EvaluationScorecardGrade[];
  comparison?: EvaluationScorecardGrade[];
  warnings?: string[];
  className?: string;
  showFilterBar: boolean;
  showGradeNames: boolean;
  compact?: boolean;
  horizontal?: boolean;
  allEvents: EvaluationScorecardEvent[];
};

// Show grades of either a single investigation scorecard or an aggregate.
export const EvaluationScorecardGrades = ({
  grades,
  comparison,
  warnings,
  className,
  allEvents,
  showFilterBar,
  showGradeNames: _showGradeNames,
  compact = false,
  horizontal = false,
}: EvaluationScorecardGradesProps) => {
  const hasEvents = allEvents.length > 0;

  const shouldShowMetric = (m: EvaluationScorecardMetric) =>
    m.is_key_metric || !compact;

  const gradesToShow = grades.filter((g) => g.metrics.some(shouldShowMetric));
  let showGradeNames = _showGradeNames;
  if (gradesToShow.length === 1 && compact) {
    showGradeNames = false;
  }

  return (
    <div className={tcx("relative", className)}>
      {warnings && warnings.length > 0 && (
        <div className="absolute right-4 top-4">
          <Tooltip content={<WarningsContent warnings={warnings} />}>
            <div className="flex items-center gap-1 text-amber-500">
              <Icon id={IconEnum.AlertPriority} />
              <span className="text-sm font-medium">{warnings.length}</span>
            </div>
          </Tooltip>
        </div>
      )}

      {gradesToShow.map((grade, i) => {
        const comparedGrade = comparison?.find((g) => g.name === grade.name);
        return (
          <div
            key={i}
            className={tcx("flex flex-col gap-2 w-full", !compact && "p-4")}
          >
            {showGradeNames && (
              <div className="flex items-center gap-2 border-b border-stroke pb-2">
                <h3 className="text-base-bold font-medium">{grade.name}</h3>
                {grade.metrics.some((m) => m.sample) && (
                  <Badge theme={BadgeTheme.Secondary} className="text-xs">
                    Aggregate of {grade.metrics[0]?.sample?.count || 0} results
                  </Badge>
                )}
              </div>
            )}

            <div className="w-full flex gap-2 items-start">
              <div
                className={tcx(
                  "h-fit",
                  horizontal ? "flex-row gap-6" : "flex-col gap-2",
                  compact ? "flex" : "grid xl:grid-cols-2 gap-4",
                  hasEvents ? "w-3/4" : "w-full",
                )}
              >
                {grade.metrics.filter(shouldShowMetric).map((metric, j) => {
                  const comparedMetric = comparedGrade?.metrics.find(
                    (m) => m.name === metric.name,
                  );
                  return (
                    <InvestigationGradeMetric
                      key={j}
                      metric={metric}
                      grade={grade}
                      comparedMetric={comparedMetric}
                      showDescription={!compact}
                    />
                  );
                })}
              </div>
              {hasEvents && (
                <div className="border-l border-stroke pl-4 w-1/4">
                  <EvaluationGradeEvents
                    grade={grade}
                    showFilterBar={showFilterBar}
                    allEvents={allEvents}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const WarningsContent = ({
  warnings,
  className,
}: {
  warnings: string[];
  className?: string;
}) => {
  if (warnings.length === 0) return null;

  return (
    <div
      className={tcx(
        "p-4 max-w-md bg-slate-800 rounded-lg shadow-lg",
        className,
      )}
    >
      <div className="text-base text-white font-medium mb-3">Warnings</div>
      <ul className="space-y-2">
        {warnings.map((warning, index) => (
          <li key={index} className="text-sm text-slate-400">
            {warning}
          </li>
        ))}
      </ul>
    </div>
  );
};

type GradeMetricProps = {
  metric: EvaluationScorecardMetric;
  comparedMetric?: EvaluationScorecardMetric;
  comparisonFooter?: React.ReactNode;
  grade: EvaluationScorecardGrade;
  className?: string;
  showDescription: boolean;
};

export const InvestigationGradeMetric = ({
  showDescription,
  metric,
  grade,
  comparedMetric,
  comparisonFooter,
  className,
}: GradeMetricProps) => {
  const { name, score, numerator, denominator, description } = metric;
  const metadata = {
    ...(metric.metadata instanceof Object && metric.metadata),
    ...(metric.sample && {
      sample: {
        total: metric.sample.count,
        null_values: metric.sample.null_count,
      },
    }),
  };

  const { setSelectedMetric, selectedMetric, enableSelectingMetrics } =
    useEvaluationFilterContext();
  const onToggleSelectedMetric = (identifier: ScorecardMetricIdentifier) => {
    setSelectedMetric(
      selectedMetric?.gradeName === grade.name &&
        selectedMetric?.metricName === name
        ? undefined
        : identifier,
    );
  };

  const indicator = getGradeIndicator(score);
  const hasRatio = numerator !== undefined && denominator !== undefined;
  const scoreDisplay = _.isUndefined(score)
    ? "N/A"
    : `${(score * 100).toFixed(1)}%`;

  const scoreDiff =
    comparedMetric && comparedMetric.score && score
      ? score - comparedMetric.score
      : undefined;

  return (
    <div className={tcx("flex items-center gap-2", className)}>
      <Icon id={indicator.icon} className={indicator.color} />
      <div className="flex-1">
        <div className="flex items-center gap-2 flex-wrap">
          {enableSelectingMetrics ? (
            <Button
              onClick={() =>
                onToggleSelectedMetric({
                  gradeName: grade.name,
                  metricName: metric.name,
                })
              }
              analyticsTrackingId={null}
              theme={
                selectedMetric?.gradeName === grade.name &&
                selectedMetric?.metricName === name
                  ? ButtonTheme.Primary
                  : ButtonTheme.Secondary
              }
              size={BadgeSize.ExtraSmall}
            >
              {name}
            </Button>
          ) : (
            <span className="font-medium">{name}</span>
          )}

          {metadata ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  size={BadgeSize.ExtraSmall}
                  theme={ButtonTheme.UnstyledPill}
                  analyticsTrackingId={null}
                  className={tcx(
                    indicator.color,
                    indicator.bgColor,
                    "hover:opacity-90",
                    "w-14",
                  )}
                >
                  {scoreDisplay}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className={tcx(
                  "min-w-72 max-w-120 p-4",
                  "bg-white dark:bg-gray-950", // Ensure solid background
                  "border border-border", // Consistent border
                  "shadow-lg", // Stronger shadow
                  "rounded-md", // Consistent rounding
                  "z-50",
                )}
                sideOffset={5}
                collisionPadding={20}
              >
                <h4 className="font-medium text-sm mb-3">Metadata</h4>
                <MetadataContent metadata={metadata} />
              </PopoverContent>
            </Popover>
          ) : (
            <span className={indicator.color}>{scoreDisplay}</span>
          )}

          {hasRatio && (
            <Badge theme={BadgeTheme.Secondary} className="text-xs">
              {numerator} / {denominator}
            </Badge>
          )}

          {scoreDiff !== undefined && (
            <PercentageDiffBadge
              diff={scoreDiff}
              metric={metric}
              comparedMetric={comparedMetric}
              comparisonFooter={comparisonFooter}
            />
          )}
        </div>
        {showDescription && (
          <div className="text-xs text-gray-500 mt-0.5">{description}</div>
        )}
      </div>
    </div>
  );
};

type GradeIndicator = {
  icon: IconEnum;
  color: string;
  bgColor: string;
};

const getGradeIndicator = (score: number | undefined): GradeIndicator => {
  if (score === undefined) {
    return {
      icon: IconEnum.Circle,
      color: "text-gray-400",
      bgColor: "bg-gray-100",
    };
  }

  if (score >= 0.7) {
    return {
      icon: IconEnum.TickCircle,
      color: "text-green-500",
      bgColor: "bg-green-50",
    };
  }

  if (score >= 0.4) {
    return {
      icon: IconEnum.Circle,
      color: "text-amber-500",
      bgColor: "bg-amber-50",
    };
  }

  return {
    icon: IconEnum.Exclamation,
    color: "text-red-500",
    bgColor: "bg-red-50",
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MetadataContent = ({ metadata }: { metadata: Record<string, any> }) => {
  return (
    <div className="grid gap-2">
      {Object.entries(metadata).map(([key, value]) => (
        <div key={key} className="grid grid-cols-2 gap-2">
          <span className="text-xs font-medium text-muted-foreground capitalize">
            {key.replace(/_/g, " ")}:
          </span>
          <span className="text-xs">
            {typeof value === "object"
              ? JSON.stringify(value)
              : value.toString()}
          </span>
        </div>
      ))}
    </div>
  );
};

type PercentageDiffBadgeProps = {
  diff: number;
  className?: string;
  metric: EvaluationScorecardMetric;
  comparedMetric?: EvaluationScorecardMetric;
  comparisonFooter?: React.ReactNode;
};

const PercentageDiffBadge = ({
  diff,
  className,
  metric,
  comparedMetric,
  comparisonFooter,
}: PercentageDiffBadgeProps) => {
  const absValue = Math.abs(diff);
  const formattedValue = (absValue * 100).toFixed(1) + "%";

  // Determine the direction and appearance
  let icon = IconEnum.TrendNeutral;
  let colorClass = "text-gray-500 bg-gray-100";

  if (diff > 0.005) {
    // Using a small threshold to account for floating point issues
    icon = IconEnum.TrendUp;
    colorClass = "text-green-600 bg-green-50";
  } else if (diff < -0.005) {
    icon = IconEnum.TrendDown;
    colorClass = "text-red-600 bg-red-50";
  }

  const badge = (
    <div
      className={tcx(
        "inline-flex items-center gap-1 px-2 py-0.5 rounded text-xs font-medium w-[70px]",
        colorClass,
        className,
      )}
    >
      <Icon id={icon} className="h-3 w-3" />
      <span>{formattedValue}</span>
    </div>
  );

  if (!comparedMetric) {
    return badge;
  }

  // Create comparison tooltip content
  const comparisonIndicator = getGradeIndicator(comparedMetric.score);
  const comparisonScoreDisplay = _.isUndefined(comparedMetric.score)
    ? "N/A"
    : `${(comparedMetric.score * 100).toFixed(1)}%`;
  const hasComparisonRatio =
    comparedMetric.numerator !== undefined &&
    comparedMetric.denominator !== undefined;

  return (
    <Tooltip
      light
      bubbleProps={{ className: "!max-w-[600px] p-4" }}
      content={
        <div className="p-2 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium">Compared with</div>
            <div className="flex items-center gap-2">
              <Icon
                id={comparisonIndicator.icon}
                className={comparisonIndicator.color}
              />
              <span className={comparisonIndicator.color}>
                {comparisonScoreDisplay}
              </span>
              {hasComparisonRatio && (
                <span className="text-xs bg-gray-100 px-2 py-0.5 rounded">
                  {comparedMetric.numerator} / {comparedMetric.denominator}
                </span>
              )}
            </div>
          </div>
          {comparisonFooter}
          <hr />
          {comparedMetric?.metadata && (
            <CodeViewer
              mode="json"
              content={JSON.stringify(metric.metadata)}
              diffWith={JSON.stringify(comparedMetric?.metadata)}
              hideHeader
              showDiffByDefault
            />
          )}
        </div>
      }
    >
      {badge}
    </Tooltip>
  );
};
