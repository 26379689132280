import { PromptHealthReport } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  LoadingWrapper,
} from "@incident-ui";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router";
import remarkGfm from "remark-gfm";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { RunPromptHealthReportDrawer } from "./RunPromptHealthReportDrawer";

export const AIHealthReportPage = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const navigate = useOrgAwareNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data, isLoading: reportLoading } = useAPI(
    "aIStaffShowPromptHealthReport",
    { id: reportId ?? "" },
  );

  const report = data?.health_report;

  const {
    content,
    isLoading: mardownLoading,
    error,
  } = useFetchMarkdown(report?.url);

  const isLoading = reportLoading || mardownLoading;

  if (!reportId) {
    navigate("/workbench/health-reports");
    return null;
  }

  if (error) {
    return <GenericErrorMessage description={error} />;
  }

  return (
    <WorkbenchSubPageWrapper
      backHref="/workbench/health-reports"
      accessory={
        report && (
          <Button
            analyticsTrackingId={null}
            onClick={() => setIsDrawerOpen(true)}
            theme={ButtonTheme.Primary}
          >
            Re-run
          </Button>
        )
      }
    >
      <LoadingWrapper loading={isLoading}>
        {!report ? (
          <EmptyState icon={IconEnum.Filter} content="Report not found" />
        ) : (
          <HealthReportInner report={report} content={content} />
        )}
      </LoadingWrapper>
      {isDrawerOpen && report && (
        <RunPromptHealthReportDrawer
          periodStart={report.period_start}
          periodEnd={report.period_end}
          filterPromptNames={Object.keys(report?.prompts_with_errors ?? {})}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const HealthReportInner = ({
  content,
}: {
  report: PromptHealthReport;
  content: string;
}) => {
  // We do some funky stuff here to add anchor ID's to the headers. This means we'll be able
  // to link to specific sections of the report.
  const components = {
    h1: ({ children, ...props }: { children?: React.ReactNode }) => {
      // Only attempt to parse title/id if it's a string
      if (typeof children === "string") {
        const [title, id] = children.split(/\s*{#/);
        const headingId = id ? id.replace("}", "") : undefined;
        return (
          <h1 id={headingId} {...props}>
            {title}
          </h1>
        );
      }
      // Otherwise render as-is
      return <h1 {...props}>{children}</h1>;
    },
    h2: ({ children, ...props }: { children?: React.ReactNode }) => {
      if (typeof children === "string") {
        const [title, id] = children.split(/\s*{#/);
        const headingId = id ? id.replace("}", "") : undefined;
        return (
          <h2 id={headingId} {...props}>
            {title}
          </h2>
        );
      }
      return <h2 {...props}>{children}</h2>;
    },
    h3: ({ children, ...props }: { children?: React.ReactNode }) => {
      if (typeof children === "string") {
        const [title, id] = children.split(/\s*{#/);
        const headingId = id ? id.replace("}", "") : undefined;
        return (
          <h3 id={headingId} {...props}>
            {title}
          </h3>
        );
      }
      return <h3 {...props}>{children}</h3>;
    },
  };

  return (
    <div className="space-y-4">
      <div className="prose prose-slate max-w-none">
        <ReactMarkdown
          // Format tables nicely
          remarkPlugins={[remarkGfm]}
          // Convert the headers into actual anchors
          components={components}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

interface UseFetchMarkdownResult {
  content: string;
  isLoading: boolean;
  error: string | null;
}

export const useFetchMarkdown = (
  url: string | undefined,
): UseFetchMarkdownResult => {
  const [content, setContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      if (!url) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch report");
        }
        const text = await response.text();
        setContent(text);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to load report");
      } finally {
        setIsLoading(false);
      }
    };

    fetchReport();
  }, [url]);

  return { content, isLoading, error };
};
