import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  Link,
  TabPane,
  TabSection,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { useCallback } from "react";

import { publicApiUrl } from "../../../../../utils/environment";
import { useClipboard } from "../../../../../utils/useClipboard";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceHTTPSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  const onCopyCurl = useCallback(() => {
    copyTextToClipboard(
      [
        "curl \\",
        "  -X POST \\",
        `  -H 'Authorization: Bearer ${alertSourceConfig.secret_token}' \\`,
        `  -H 'Content-Type: application/json' \\`,
        `  ${publicApiUrl()}/v2/alert_events/http/${alertSourceConfig.id} \\`,
        `  -d '{
"title": "My first alert",
"description": "Some additional context",
"deduplication_key": "first-alert",
"status": "firing",
"metadata": {
"team": "core",
"service": "api"
}
}'`,
      ].join("\n"),
    );
  }, [
    alertSourceConfig.id,
    alertSourceConfig.secret_token,
    copyTextToClipboard,
  ]);

  return (
    <div>
      <p className="mb-2">
        To use this source, send a <strong>POST</strong> request using the
        details below, or{" "}
        <Button
          theme={ButtonTheme.Naked}
          className="!text-slate-700 hover:!text-content-primary font-medium"
          onClick={onCopyCurl}
          analyticsTrackingId={"copy-curl"}
        >
          copy the cURL command{" "}
          {hasCopied ? null : <Icon id={IconEnum.Copy} className="inline" />}
        </Button>
        {hasCopied ? (
          <div className={"inline text-green-content"}>
            <code> Copied </code>
            <Icon id={IconEnum.Tick} className="inline" />
          </div>
        ) : null}
        .
      </p>
      <TabSection
        withIndicator
        tabs={[
          { id: "header", label: "Header authentication" },
          { id: "query", label: "Query authentication" },
        ]}
      >
        <TabPane tabId="header">
          <div className={"my-4 flex flex-col gap-2 overflow-hidden"}>
            <SingleLineCodeBlock
              title={"URL"}
              code={`${publicApiUrl()}/v2/alert_events/http/${
                alertSourceConfig.id
              }`}
            />
            <SingleLineCodeBlock
              title={"Headers"}
              code={`{ "Authorization": "Bearer ${alertSourceConfig.secret_token}" }`}
            />
            <CodeBlock
              title={"Body"}
              code={`{
  "title": "My first alert",
  "description": "Some additional context",
  "deduplication_key": "first-alert",
  "status": "firing",
  "metadata": {
    "team": "core",
    "service": "api"
  }
}`}
            />
          </div>
        </TabPane>

        <TabPane tabId="query">
          <div className={"my-4 flex flex-col gap-2 overflow-hidden"}>
            <SingleLineCodeBlock
              title={"URL"}
              code={`${publicApiUrl()}/v2/alert_events/http/${
                alertSourceConfig.id
              }?token=${alertSourceConfig.secret_token}`}
            />
            <CodeBlock
              title={"Body"}
              code={`{
  "title": "My first alert",
  "description": "Some additional context",
  "deduplication_key": "first-alert",
  "status": "firing",
  "metadata": {
    "team": "core",
    "service": "api"
  }
}`}
            />
          </div>
        </TabPane>
      </TabSection>
      <div className="bg-slate-50 border border-slate-100 rounded-2 p-5 flex flex-col gap-2">
        <p>
          You can optionally send additional information in the{" "}
          <strong>metadata</strong> block, such as the affected service, feature
          or environment. These can be used when defining attributes for your
          alerts.
        </p>
        <p>
          You can find more information about each of the fields in our{" "}
          <Link
            analyticsTrackingId={"http-alerts-docs"}
            href={"https://api-docs.incident.io/tag/Alert-Events-V2/"}
          >
            API documentation
          </Link>
          .
        </p>
      </div>
    </div>
  );
};
