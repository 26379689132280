/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IncidentUpdate
 */
export interface IncidentUpdate {
    /**
     * When the update was created
     * @type {Date}
     * @memberof IncidentUpdate
     */
    created_at: Date;
    /**
     * Unique identifier for this incident update
     * @type {string}
     * @memberof IncidentUpdate
     */
    id: string;
    /**
     * The incident this update relates to
     * @type {string}
     * @memberof IncidentUpdate
     */
    incident_id: string;
    /**
     * If the update message was edited, when was it last edited?
     * @type {Date}
     * @memberof IncidentUpdate
     */
    last_edited_at?: Date;
    /**
     * 
     * @type {User}
     * @memberof IncidentUpdate
     */
    last_edited_by_user?: User;
    /**
     * 
     * @type {IncidentSlim}
     * @memberof IncidentUpdate
     */
    merged_into_incident?: IncidentSlim;
    /**
     * The ID of the incident this incident was merged into, if the to state of this update is 'merged'.
     * @type {string}
     * @memberof IncidentUpdate
     */
    merged_into_incident_id?: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof IncidentUpdate
     */
    message?: TextDocument;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof IncidentUpdate
     */
    new_incident_status: IncidentStatus;
    /**
     * 
     * @type {Severity}
     * @memberof IncidentUpdate
     */
    new_severity?: Severity;
    /**
     * When to prompt for the next update
     * @type {number}
     * @memberof IncidentUpdate
     */
    next_update_in_minutes: number;
    /**
     * The organisation this update relates to
     * @type {string}
     * @memberof IncidentUpdate
     */
    organisation_id?: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof IncidentUpdate
     */
    previous_incident_status?: IncidentStatus;
    /**
     * 
     * @type {Severity}
     * @memberof IncidentUpdate
     */
    previous_severity?: Severity;
    /**
     * When to automatically unpause the incident
     * @type {number}
     * @memberof IncidentUpdate
     */
    unpause_in_minutes?: number;
    /**
     * 
     * @type {Actor}
     * @memberof IncidentUpdate
     */
    updater: Actor;
}

export function IncidentUpdateFromJSON(json: any): IncidentUpdate {
    return IncidentUpdateFromJSONTyped(json, false);
}

export function IncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'last_edited_at': !exists(json, 'last_edited_at') ? undefined : (new Date(json['last_edited_at'])),
        'last_edited_by_user': !exists(json, 'last_edited_by_user') ? undefined : UserFromJSON(json['last_edited_by_user']),
        'merged_into_incident': !exists(json, 'merged_into_incident') ? undefined : IncidentSlimFromJSON(json['merged_into_incident']),
        'merged_into_incident_id': !exists(json, 'merged_into_incident_id') ? undefined : json['merged_into_incident_id'],
        'message': !exists(json, 'message') ? undefined : TextDocumentFromJSON(json['message']),
        'new_incident_status': IncidentStatusFromJSON(json['new_incident_status']),
        'new_severity': !exists(json, 'new_severity') ? undefined : SeverityFromJSON(json['new_severity']),
        'next_update_in_minutes': json['next_update_in_minutes'],
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'previous_incident_status': !exists(json, 'previous_incident_status') ? undefined : IncidentStatusFromJSON(json['previous_incident_status']),
        'previous_severity': !exists(json, 'previous_severity') ? undefined : SeverityFromJSON(json['previous_severity']),
        'unpause_in_minutes': !exists(json, 'unpause_in_minutes') ? undefined : json['unpause_in_minutes'],
        'updater': ActorFromJSON(json['updater']),
    };
}

export function IncidentUpdateToJSON(value?: IncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'last_edited_at': value.last_edited_at === undefined ? undefined : (value.last_edited_at.toISOString()),
        'last_edited_by_user': UserToJSON(value.last_edited_by_user),
        'merged_into_incident': IncidentSlimToJSON(value.merged_into_incident),
        'merged_into_incident_id': value.merged_into_incident_id,
        'message': TextDocumentToJSON(value.message),
        'new_incident_status': IncidentStatusToJSON(value.new_incident_status),
        'new_severity': SeverityToJSON(value.new_severity),
        'next_update_in_minutes': value.next_update_in_minutes,
        'organisation_id': value.organisation_id,
        'previous_incident_status': IncidentStatusToJSON(value.previous_incident_status),
        'previous_severity': SeverityToJSON(value.previous_severity),
        'unpause_in_minutes': value.unpause_in_minutes,
        'updater': ActorToJSON(value.updater),
    };
}

