/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffRetryEvaluationBacktestEntriesRequestBody
 */
export interface AIStaffRetryEvaluationBacktestEntriesRequestBody {
    /**
     * Which entries should we retry from scratch?
     * @type {Array<string>}
     * @memberof AIStaffRetryEvaluationBacktestEntriesRequestBody
     */
    entry_ids: Array<string>;
}

export function AIStaffRetryEvaluationBacktestEntriesRequestBodyFromJSON(json: any): AIStaffRetryEvaluationBacktestEntriesRequestBody {
    return AIStaffRetryEvaluationBacktestEntriesRequestBodyFromJSONTyped(json, false);
}

export function AIStaffRetryEvaluationBacktestEntriesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffRetryEvaluationBacktestEntriesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry_ids': json['entry_ids'],
    };
}

export function AIStaffRetryEvaluationBacktestEntriesRequestBodyToJSON(value?: AIStaffRetryEvaluationBacktestEntriesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry_ids': value.entry_ids,
    };
}

