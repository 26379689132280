import { Icon, IconEnum, IconSize, Link, Tooltip } from "@incident-ui";
import React from "react";
import {
  ExternalResource,
  ExternalResourceResourceTypeEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AtlassianStatuspageIncident } from "./AtlassianStatuspageIncident";
import { DatadogMonitorAlert } from "./DatadogMonitorAlert";
import { GitHubPullRequest } from "./GithubPullRequest";
import { GitLabMergeRequest } from "./GitLabMergeRequest";
import { GoogleCalendarEvent } from "./GoogleCalendarEvent";
import { OpsgenieAlert } from "./OpsgenieAlert";
import { PagerDutyIncident } from "./PagerDutyIncident";
import { SentryIssue } from "./SentryIssue";
import { SlackFile } from "./SlackFile";
import { ZendeskTicket } from "./ZendeskTicket";

// We render attachments slightly differently on the timeline from elsewhere.
// This is so the timeline doesn't have 'point-in-time' information, as it
// can conflict with the timeline item (e.g. if we're saying a PD incident
// was triggered, it's a bit weird to show that it's now resolved).
//
// TODO: when we kill the old timeline (because we start showing attachments in the
// activity log), we can remove this. This'll mean that the Attachment is only used on
// the attachments tab and renders all possible tabs.
export enum AttachmentContexts {
  Timeline = "timeline",
  RealTime = "realtime",
}

export type AttachmentBadge = {
  icon: IconEnum;
  text: string;
  // We can optionally show a tooltip on the badge to give more context.
  tooltipContent?: string;
};

export type AttachmentRenderProps = {
  title: string;
  // icon should be the icon that represents the type of attachment e.g. GitHub, PagerDuty, etc.
  icon: IconEnum;
  // safeBadges are suitable to show in all contexts i.e. in both the activity log and on the attachments tab.
  safeBadges?: AttachmentBadge[];
  // unsafebadges are point in time information and should only be shown on the attachments tab.
  // This includes things like the current status of a PR, or the current status of a PD incident.
  // Showing these in the activity log would be misleading as they do not represent the state
  // of the resource at the time.
  unsafeBadges?: AttachmentBadge[];
};

type AttachmentRenderer = (resource: ExternalResource) => AttachmentRenderProps;

export const AttachmentTypeToRenderProps: {
  [key in ExternalResourceResourceTypeEnum]: AttachmentRenderer | null;
} = {
  [ExternalResourceResourceTypeEnum.PagerDutyIncident]: PagerDutyIncident,
  [ExternalResourceResourceTypeEnum.OpsgenieAlert]: OpsgenieAlert,
  [ExternalResourceResourceTypeEnum.GithubPullRequest]: GitHubPullRequest,
  [ExternalResourceResourceTypeEnum.GitlabMergeRequest]: GitLabMergeRequest,
  [ExternalResourceResourceTypeEnum.SentryIssue]: SentryIssue,
  [ExternalResourceResourceTypeEnum.AtlassianStatuspageIncident]:
    AtlassianStatuspageIncident,
  [ExternalResourceResourceTypeEnum.ZendeskTicket]: ZendeskTicket,
  [ExternalResourceResourceTypeEnum.DatadogMonitorAlert]: DatadogMonitorAlert,
  [ExternalResourceResourceTypeEnum.GoogleCalendarEvent]: GoogleCalendarEvent,
  [ExternalResourceResourceTypeEnum.SlackFile]: SlackFile, // TODO: https://linear.app/incident-io/issue/RESP-10960/handle-rendering-slack-file-as-external-resource
  [ExternalResourceResourceTypeEnum.OutlookCalendarEvent]: null,
  [ExternalResourceResourceTypeEnum.JiraIssue]: null,
  [ExternalResourceResourceTypeEnum.Scrubbed]: null,
  [ExternalResourceResourceTypeEnum.StatuspageIncident]: null,
};

export const Attachment = ({
  resource,
  className,
  context,
}: {
  resource: ExternalResource;
  className?: string;
  context: AttachmentContexts;
}): React.ReactElement | null => {
  const renderer = AttachmentTypeToRenderProps[resource.resource_type];
  if (!renderer) {
    return null;
  }

  const { title, icon, safeBadges, unsafeBadges } = renderer(resource);

  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={icon}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            {title}
          </Link>
        </div>
        <div className="flex space-x-2 text-slate-700">
          {safeBadges?.map((badge) => <Badge key={badge.text} {...badge} />)}
          {/* We'll only show the unsafebadges if we're showing all the possible information
          about a resource, which only happens on the attachments tab. This is because the timeline
          is point in time and we don't want to be misleading! */}
          {context === AttachmentContexts.RealTime &&
            unsafeBadges?.map((badge) => <Badge key={badge.text} {...badge} />)}
        </div>
        {resource.image && (
          <a href={resource.permalink} target={"_blank"} rel="noreferrer">
            <img
              className={"!max-w-[300px]"}
              key={resource.image?.id}
              alt=""
              src={resource.image?.url}
            />
          </a>
        )}
      </div>
    </div>
  );
};

const Badge = ({
  icon,
  text,
  tooltipContent,
}: AttachmentBadge): React.ReactElement => {
  let body = <span>{text}</span>;

  if (tooltipContent) {
    body = (
      <Tooltip content={tooltipContent}>
        <div>
          <span>{text}</span>
        </div>
      </Tooltip>
    );
  }

  return (
    <span className="flex-center-y">
      <Icon id={icon} className="text-content-tertiary mr-1" />
      {body}
    </span>
  );
};
