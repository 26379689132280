import { NudgeNudgeTypeEnum } from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import { useAPI } from "src/utils/swr";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "../../ai/AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "../../ai/useAIFeatures";

export const StatusPageDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: suggestConfigResp } = useAPI(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
  );

  return (
    <AIFeatureConfigurationDrawer
      loading={!suggestConfigResp}
      nudgeTypes={[
        NudgeNudgeTypeEnum.CreateStatuspage,
        NudgeNudgeTypeEnum.UpdateStatuspage,
      ]}
      onClose={onClose}
      title={<AIFeatureDrawerTitle {...feature} onClose={onClose} />}
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Smart incident naming"
              description="When an incident's nature changes significantly, we'll suggest new names that better describe what's actually happening."
              icon={IconEnum.Bulb}
            />
            <AIProductMarketingCard
              title="Prevent naming drift"
              description="Get alerts when an incident's scope has evolved beyond its original name, helping teams find and track the right issues."
              icon={IconEnum.Clock}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
