import { get, isEmpty } from "lodash";
import _ from "lodash";
import { useEffect, useRef } from "react";
import {
  DeepPartial,
  FieldNamesMarkedBoolean,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";
import {
  AvailableIncidentFormLifecycleElement,
  AvailableIncidentFormLifecycleElementElementTypeEnum as ElementType,
  CustomFieldFieldModeEnum as FieldModeEnum,
  CustomFieldFieldTypeEnum,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBody,
  IncidentManualEdit,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";
import { useDebounce } from "use-debounce";

import { AllIncidentFormData, SharedIncidentFormData } from "./FormElements";

export const useElementBindings = <TFormData extends SharedIncidentFormData>({
  payload,
  setValue,
  initialValues,
  touchedFields,
  manualEdits,
}: {
  payload: IncidentFormsGetLifecycleElementBindingsRequestBody;
  setValue: UseFormSetValue<TFormData>;
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<TFormData>>>;
  initialValues: Readonly<DeepPartial<TFormData>> | undefined;
  manualEdits: IncidentManualEdit[] | undefined;
}): {
  elementBindings: Array<IncidentFormLifecycleElementBinding>;
  refetch: () => Promise<void>;
  isLoading: boolean;
} => {
  // Debounce the payload which is used as the cache key.
  // The contents of the payload can change as the user interacts with the form,
  // which causes a potentially expensive API to be called excessively.
  const [debounced] = useDebounce(payload, 1000, { equalityFn: _.isEqual });

  const {
    data: elementBindings,
    error,
    mutate,
    isLoading,
  } = useAPI(
    debounced.incident_status_id
      ? "incidentFormsGetLifecycleElementBindings"
      : null,
    { getLifecycleElementBindingsRequestBody: debounced },
    {
      fallbackData: {
        element_bindings: [],
      },
      // We want to keep the data so we don't show nothing when reloading the
      // elements
      keepPreviousData: true,
    },
  );
  if (error) {
    throw error;
  }

  const bindings = elementBindings.element_bindings;

  const { resetDefaultValues } = useSetDefaultValues<TFormData>({
    bindings,
    setValue,
    initialValues,
    touchedFields,
    manualEdits: manualEdits,
  });

  const refetch = async () => {
    await mutate();
    // Once we have the new response, reset the useSetDefaultValues hook so that
    // it applies the new defaults to the form.
    resetDefaultValues();
  };

  return { elementBindings: bindings, refetch, isLoading };
};

const useSetDefaultValues = <TFormData extends SharedIncidentFormData>({
  bindings,
  setValue,
  initialValues,
  touchedFields,
  manualEdits,
}: {
  bindings: IncidentFormLifecycleElementBinding[];
  setValue: UseFormSetValue<TFormData>;
  initialValues: Readonly<DeepPartial<TFormData>> | undefined;
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<TFormData>>>;
  manualEdits: IncidentManualEdit[] | undefined;
}): { resetDefaultValues: () => void } => {
  const appliedDefaultValues = useRef<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    bindings.forEach((binding) => {
      if (binding.default_value == null) {
        return;
      }

      // We have a default value, gotta see if we need to apply it.
      const pathForBinding = getPathForElement(
        binding.element.available_element,
      );

      if (!pathForBinding) return;

      const fieldHasBeenTouched =
        // this is slow to typecheck and not worth it
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        get<any>(touchedFields, pathForBinding as any) ?? false;
      if (fieldHasBeenTouched) return;

      // If a field has an initial value, we'll only update the 'default value' from a binding
      // if it's a derived custom field which hasn't been manually edited.
      let derivedNotManuallyEdited = false;
      const customField = binding.element.available_element.custom_field;
      if (customField) {
        const isDerived = [
          FieldModeEnum.FullyDerived,
          FieldModeEnum.SensibleDefault,
        ].includes(customField.field_mode);

        const hasManualEdit = manualEdits?.some(
          (manualEdit) => manualEdit.custom_field_id === customField.id,
        );

        derivedNotManuallyEdited = isDerived && !hasManualEdit;
      }

      if (!derivedNotManuallyEdited) {
        const hasInitialValue = !isEmpty(get(initialValues, pathForBinding));
        if (hasInitialValue) return;
      }

      // We use this helper to make sure we don't repeatedly 'blat' values into the form, which
      // could be very frustrating for the user.
      const setIfNotAlreadyApplied = (
        value: string | string[] | JSON | boolean | undefined,
      ) => {
        const alreadyApplied =
          appliedDefaultValues.current[binding.element.id] ?? "";
        // sort and join if the value is an array
        let valueToStore = "";
        if (Array.isArray(value)) {
          valueToStore = value.sort().join(",");
        } else if (typeof value === "object") {
          valueToStore = JSON.stringify(value);
        } else if (typeof value === "string") {
          valueToStore = value;
        } else if (typeof value === "boolean") {
          valueToStore = value ? "true" : "false";
        }

        if (alreadyApplied !== valueToStore) {
          setValue(
            pathForBinding as Path<TFormData>,
            value as PathValue<TFormData, Path<TFormData>>,
          );
          appliedDefaultValues.current[binding.element.id] = valueToStore;
        }
      };
      if (binding.default_value) {
        // If it's a summary or message node, we need to parse the value string as json
        // before calling 'setValue' on it.
        if (pathForBinding.includes("text_node")) {
          setIfNotAlreadyApplied(
            binding.default_value.value
              ? JSON.parse(binding.default_value.value)
              : undefined,
          );
        } else if (binding.default_value.array_value) {
          setIfNotAlreadyApplied(binding.default_value.array_value);
        } else {
          if (
            binding.element.available_element.engine_resource_type === "Bool"
          ) {
            // This translates the default string values of 'true' and 'false'
            // into boolean so that the form state is correctly initialised with booleans.
            setIfNotAlreadyApplied(
              typeof binding.default_value.value === "undefined"
                ? undefined
                : binding.default_value.value === "true",
            );
          } else {
            setIfNotAlreadyApplied(binding.default_value.value);
          }
        }
      }
    });
    // If we include `setValue` here we get a 'Type of property 'content'
    // circularly references itself in mapped type' error. This has been
    // raised with react-hook-form here, but doesn't seem to be a priority
    // for anyone to fix (I suspec it's really gnarly)
    // https://github.com/microsoft/TypeScript/issues/37597
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touchedFields,
    bindings,
    initialValues,
    appliedDefaultValues,
    manualEdits,
  ]);

  return {
    resetDefaultValues: () => {
      appliedDefaultValues.current = {};
    },
  };
};

const getPathForElement = (
  availableElement: AvailableIncidentFormLifecycleElement,
): Path<AllIncidentFormData> | null => {
  switch (availableElement.element_type) {
    case ElementType.CustomField:
      return getPathForCustomFieldElement(availableElement);
    case ElementType.IncidentRole:
      return `incident_role_assignments.${availableElement.incident_role?.id}.assignee_id`;
    case ElementType.Name:
      return "name";
    case ElementType.IncidentType:
      return "incident_type_id";
    case ElementType.Timestamp:
      return `incident_timestamp_values.${availableElement.incident_timestamp?.id}`;
    case ElementType.Summary:
      return "summary.text_node";
    case ElementType.Severity:
      return "severity_id";
    case ElementType.Status:
      return "incident_status_id";
    case ElementType.Visibility:
      return "visibility";
    case ElementType.Triage:
      return "active_or_triage";
    case ElementType.UpdateMessage:
      return "message.text_node";
    case ElementType.NextUpdateIn:
      return "next_update_in_minutes";
    case ElementType.IncidentAttachments:
      // Not supported in dashboard yet
      return null;
    case ElementType.SlackChannel:
      return "retrospective_incident_options.slack_channel";
    case ElementType.AnnounceRetroIncident:
      return "retrospective_incident_options.announcements_enabled";
    case ElementType.EnterPostIncidentFlow:
      return "retrospective_incident_options.enter_post_incident_flow";
    case ElementType.Divider:
      return null;
    case ElementType.Text:
      return null;

    default:
      assertUnreachable(availableElement.element_type);
  }
  return null;
};

const getPathForCustomFieldElement = (
  availableElement: AvailableIncidentFormLifecycleElement,
): Path<AllIncidentFormData> | null => {
  if (!availableElement.custom_field) {
    return null;
  }
  switch (availableElement.custom_field.field_type) {
    case CustomFieldFieldTypeEnum.MultiSelect:
      return `custom_field_entries.${availableElement.custom_field?.id}.values`;
    case CustomFieldFieldTypeEnum.SingleSelect:
      return `custom_field_entries.${availableElement.custom_field?.id}.values.0`;
    case CustomFieldFieldTypeEnum.Text:
      return `custom_field_entries.${availableElement.custom_field?.id}.values.0.value_text`;
    case CustomFieldFieldTypeEnum.Link:
      return `custom_field_entries.${availableElement.custom_field?.id}.values.0.value_link`;
    case CustomFieldFieldTypeEnum.Numeric:
      return `custom_field_entries.${availableElement.custom_field?.id}.values.0.value_numeric`;
    default:
      assertUnreachable(availableElement.custom_field.field_type);
  }
  return null;
};
