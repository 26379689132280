/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteEscalationTargetPayload
 */
export interface AlertRouteEscalationTargetPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteEscalationTargetPayload
     */
    escalation_paths?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteEscalationTargetPayload
     */
    users?: EngineParamBindingPayload;
}

export function AlertRouteEscalationTargetPayloadFromJSON(json: any): AlertRouteEscalationTargetPayload {
    return AlertRouteEscalationTargetPayloadFromJSONTyped(json, false);
}

export function AlertRouteEscalationTargetPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteEscalationTargetPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': !exists(json, 'escalation_paths') ? undefined : EngineParamBindingPayloadFromJSON(json['escalation_paths']),
        'users': !exists(json, 'users') ? undefined : EngineParamBindingPayloadFromJSON(json['users']),
    };
}

export function AlertRouteEscalationTargetPayloadToJSON(value?: AlertRouteEscalationTargetPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': EngineParamBindingPayloadToJSON(value.escalation_paths),
        'users': EngineParamBindingPayloadToJSON(value.users),
    };
}

