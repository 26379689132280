import {
  EvaluationBacktest,
  EvaluationBacktestBacktestTypeEnum,
  EvaluationLeaderboard,
  EvaluationLeaderboardEntryPayload,
  EvaluationLeaderboardPayload,
  EvaluationLeaderboardPayloadBacktestTypeEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { LoadingModal, ModalFooter } from "@incident-ui";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

type FormData = {
  backtest_id: string;
  notes: string;
  mode: "create_new" | "choose_existing";
  leaderboard_id?: string;
  leaderboard_name?: string;
  leaderboard_description?: string;
};

export const PinBacktestToLeaderboardModal = ({
  backtest,
  onClose,
}: {
  backtest: EvaluationBacktest;
  onClose: () => void;
}) => {
  // Fetch backtests for the dropdown
  const {
    data: { leaderboards = [] },
    isLoading,
  } = useAPI(
    "aIStaffListEvaluationLeaderboards",
    {},
    {
      fallbackData: { leaderboards: [] },
    },
  );

  if (isLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <PinBacktestToLeaderboardModalContents
      backtest={backtest}
      leaderboards={leaderboards}
      onClose={onClose}
    />
  );
};

const PinBacktestToLeaderboardModalContents = ({
  backtest,
  leaderboards,
  onClose,
}: {
  backtest: EvaluationBacktest;
  leaderboards: EvaluationLeaderboard[];
  onClose: () => void;
}) => {
  // Format leaderboards for the select menu
  const leaderboardOptions = useMemo(() => {
    return leaderboards
      .filter(
        (l) =>
          (l.backtest_type as unknown as EvaluationBacktestBacktestTypeEnum) ===
            backtest.backtest_type &&
          l.investigation_plan_type === backtest.investigation_plan_type,
      )
      .map((leaderboard: EvaluationLeaderboard) => ({
        value: leaderboard.id,
        label: leaderboard.name,
      }));
  }, [leaderboards, backtest]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      backtest_id: backtest.id,
      notes: "",
      mode: leaderboardOptions.length > 0 ? "choose_existing" : "create_new",
    },
  });

  const { trigger: createLeaderboardEntry, isMutating: isCreating } =
    useMutationV2(
      async (apiClient, data: EvaluationLeaderboardEntryPayload) => {
        return await apiClient.aIStaffCreateEvaluationLeaderboardEntry({
          createEvaluationLeaderboardEntryRequestBody: data,
        });
      },
      {
        invalidate: [
          cacheKey.exactly("aIStaffShowEvaluationBacktest", {
            id: backtest.id,
          }),
          cacheKey.all("aIStaffShowEvaluationLeaderboard"),
          cacheKey.all("aIStaffListEvaluationLeaderboards"),
        ],
        showErrorToast: "Failed to pin backtest",
        showSuccessToast: "Entry added to leaderboard",
        onSuccess: () => {
          onClose();
        },
      },
    );

  const { trigger: createLeaderboard } = useMutationV2(
    async (apiClient, data: EvaluationLeaderboardPayload) => {
      return await apiClient.aIStaffCreateEvaluationLeaderboard({
        createEvaluationLeaderboardRequestBody: data,
      });
    },
    {
      invalidate: [cacheKey.all("aIStaffListEvaluationLeaderboards")],
      showErrorToast: "Failed to pin backtest",
      showSuccessToast: "Entry added to leaderboard",
      onSuccess: () => {
        onClose();
      },
    },
  );

  const onSubmit = async (data: FormData) => {
    let leaderboardId = data.leaderboard_id;
    // 1. Do we need to create a new leaderboard?
    if (!data.leaderboard_id) {
      const newLeaderboard = await createLeaderboard({
        name: data.leaderboard_name || "",
        description: data.leaderboard_description,
        backtest_type:
          backtest.backtest_type as unknown as EvaluationLeaderboardPayloadBacktestTypeEnum,
        investigation_plan_type: backtest.investigation_plan_type,
      });
      if (!newLeaderboard) {
        return;
      }
      leaderboardId = newLeaderboard.leaderboard.id;
    }
    const requestBody: EvaluationLeaderboardEntryPayload = {
      leaderboard_id: leaderboardId as string,
      backtest_id: data.backtest_id,
      notes: data.notes,
    };

    createLeaderboardEntry(requestBody);
  };

  const mode = formMethods.watch("mode");

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Add Entry to Leaderboard"
      onClose={onClose}
      analyticsTrackingId="add-leaderboard-entry"
      disableQuickClose
      onSubmit={(data: FormData) => {
        onSubmit(data);
      }}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Add Entry"
          analyticsTrackingId="add-leaderboard-entry-submit"
          saving={isCreating}
        />
      }
    >
      <RadioButtonGroupV2
        srLabel="mode"
        formMethods={formMethods}
        name="mode"
        label="Create a new leaderboard or choose an existing one?"
        horizontal
        boxed
        required
        options={[
          { value: "create_new", label: "Create new leaderboard" },
          { value: "choose_existing", label: "Choose existing leaderboard" },
        ]}
      />
      {mode === "create_new" ? (
        <>
          {" "}
          <InputV2
            formMethods={formMethods}
            name="leaderboard_name"
            label="Leaderboard name"
            required
          />
          <TextareaV2
            formMethods={formMethods}
            name="leaderboard_description"
            label="Leaderboard description"
            required
          />
        </>
      ) : (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="leaderboard_id"
          label="Leaderboard"
          helptext="Select a leaderboard to pin this to."
          required
          options={leaderboardOptions}
        />
      )}

      <TextareaV2
        formMethods={formMethods}
        name="notes"
        label="Notes"
        helptext="Add some notes that explain the changes you've made."
        rows={3}
      />
    </Form.Modal>
  );
};
