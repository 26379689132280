/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StaffCallRoute,
    StaffCallRouteFromJSON,
    StaffCallRouteFromJSONTyped,
    StaffCallRouteToJSON,
} from './StaffCallRoute';

/**
 * 
 * @export
 * @interface StaffConfigureCallRouteResponseBody
 */
export interface StaffConfigureCallRouteResponseBody {
    /**
     * 
     * @type {StaffCallRoute}
     * @memberof StaffConfigureCallRouteResponseBody
     */
    call_route: StaffCallRoute;
}

export function StaffConfigureCallRouteResponseBodyFromJSON(json: any): StaffConfigureCallRouteResponseBody {
    return StaffConfigureCallRouteResponseBodyFromJSONTyped(json, false);
}

export function StaffConfigureCallRouteResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffConfigureCallRouteResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_route': StaffCallRouteFromJSON(json['call_route']),
    };
}

export function StaffConfigureCallRouteResponseBodyToJSON(value?: StaffConfigureCallRouteResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_route': StaffCallRouteToJSON(value.call_route),
    };
}

