/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteCustomFieldBinding,
    AlertRouteCustomFieldBindingFromJSON,
    AlertRouteCustomFieldBindingFromJSONTyped,
    AlertRouteCustomFieldBindingToJSON,
} from './AlertRouteCustomFieldBinding';
import {
    AlertRouteSeverityBinding,
    AlertRouteSeverityBindingFromJSON,
    AlertRouteSeverityBindingFromJSONTyped,
    AlertRouteSeverityBindingToJSON,
} from './AlertRouteSeverityBinding';
import {
    AlertRouteTemplateBinding,
    AlertRouteTemplateBindingFromJSON,
    AlertRouteTemplateBindingFromJSONTyped,
    AlertRouteTemplateBindingToJSON,
} from './AlertRouteTemplateBinding';
import {
    AutoGeneratedTemplateBinding,
    AutoGeneratedTemplateBindingFromJSON,
    AutoGeneratedTemplateBindingFromJSONTyped,
    AutoGeneratedTemplateBindingToJSON,
} from './AutoGeneratedTemplateBinding';

/**
 * 
 * @export
 * @interface AlertRouteIncidentTemplate
 */
export interface AlertRouteIncidentTemplate {
    /**
     * Custom fields configuration
     * @type {Array<AlertRouteCustomFieldBinding>}
     * @memberof AlertRouteIncidentTemplate
     */
    custom_fields?: Array<AlertRouteCustomFieldBinding>;
    /**
     * 
     * @type {AlertRouteTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    incident_mode?: AlertRouteTemplateBinding;
    /**
     * 
     * @type {AlertRouteTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    incident_type?: AlertRouteTemplateBinding;
    /**
     * 
     * @type {AutoGeneratedTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    name?: AutoGeneratedTemplateBinding;
    /**
     * 
     * @type {AlertRouteSeverityBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    severity?: AlertRouteSeverityBinding;
    /**
     * 
     * @type {AlertRouteTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    start_in_triage?: AlertRouteTemplateBinding;
    /**
     * 
     * @type {AutoGeneratedTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    summary?: AutoGeneratedTemplateBinding;
    /**
     * 
     * @type {AlertRouteTemplateBinding}
     * @memberof AlertRouteIncidentTemplate
     */
    workspace?: AlertRouteTemplateBinding;
}

export function AlertRouteIncidentTemplateFromJSON(json: any): AlertRouteIncidentTemplate {
    return AlertRouteIncidentTemplateFromJSONTyped(json, false);
}

export function AlertRouteIncidentTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteIncidentTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_fields': !exists(json, 'custom_fields') ? undefined : ((json['custom_fields'] as Array<any>).map(AlertRouteCustomFieldBindingFromJSON)),
        'incident_mode': !exists(json, 'incident_mode') ? undefined : AlertRouteTemplateBindingFromJSON(json['incident_mode']),
        'incident_type': !exists(json, 'incident_type') ? undefined : AlertRouteTemplateBindingFromJSON(json['incident_type']),
        'name': !exists(json, 'name') ? undefined : AutoGeneratedTemplateBindingFromJSON(json['name']),
        'severity': !exists(json, 'severity') ? undefined : AlertRouteSeverityBindingFromJSON(json['severity']),
        'start_in_triage': !exists(json, 'start_in_triage') ? undefined : AlertRouteTemplateBindingFromJSON(json['start_in_triage']),
        'summary': !exists(json, 'summary') ? undefined : AutoGeneratedTemplateBindingFromJSON(json['summary']),
        'workspace': !exists(json, 'workspace') ? undefined : AlertRouteTemplateBindingFromJSON(json['workspace']),
    };
}

export function AlertRouteIncidentTemplateToJSON(value?: AlertRouteIncidentTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_fields': value.custom_fields === undefined ? undefined : ((value.custom_fields as Array<any>).map(AlertRouteCustomFieldBindingToJSON)),
        'incident_mode': AlertRouteTemplateBindingToJSON(value.incident_mode),
        'incident_type': AlertRouteTemplateBindingToJSON(value.incident_type),
        'name': AutoGeneratedTemplateBindingToJSON(value.name),
        'severity': AlertRouteSeverityBindingToJSON(value.severity),
        'start_in_triage': AlertRouteTemplateBindingToJSON(value.start_in_triage),
        'summary': AutoGeneratedTemplateBindingToJSON(value.summary),
        'workspace': AlertRouteTemplateBindingToJSON(value.workspace),
    };
}

