/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffUpdateEvaluationBacktestRequestBody
 */
export interface AIStaffUpdateEvaluationBacktestRequestBody {
    /**
     * ID of another backtest to compare against
     * @type {string}
     * @memberof AIStaffUpdateEvaluationBacktestRequestBody
     */
    compare_with_backtest_id?: string;
    /**
     * Overrides for the model configuration (with a key:value map of prompt -> model)
     * @type {{ [key: string]: string; }}
     * @memberof AIStaffUpdateEvaluationBacktestRequestBody
     */
    model_overrides: { [key: string]: string; };
    /**
     * Optional notes about the rationale for this backtest
     * @type {string}
     * @memberof AIStaffUpdateEvaluationBacktestRequestBody
     */
    notes?: string;
    /**
     * Overrides for the investigation tuneables to use for this backtest
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof AIStaffUpdateEvaluationBacktestRequestBody
     */
    tuneables_overrides: { [key: string]: { [key: string]: string; }; };
}

export function AIStaffUpdateEvaluationBacktestRequestBodyFromJSON(json: any): AIStaffUpdateEvaluationBacktestRequestBody {
    return AIStaffUpdateEvaluationBacktestRequestBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateEvaluationBacktestRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateEvaluationBacktestRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compare_with_backtest_id': !exists(json, 'compare_with_backtest_id') ? undefined : json['compare_with_backtest_id'],
        'model_overrides': json['model_overrides'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'tuneables_overrides': json['tuneables_overrides'],
    };
}

export function AIStaffUpdateEvaluationBacktestRequestBodyToJSON(value?: AIStaffUpdateEvaluationBacktestRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compare_with_backtest_id': value.compare_with_backtest_id,
        'model_overrides': value.model_overrides,
        'notes': value.notes,
        'tuneables_overrides': value.tuneables_overrides,
    };
}

