/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormsCreateFormRequestBody
 */
export interface IncidentFormsCreateFormRequestBody {
    /**
     * If we should copy from an existing incident form, which one?
     * @type {string}
     * @memberof IncidentFormsCreateFormRequestBody
     */
    copy_from_form_id?: string;
    /**
     * The type of the incident form
     * @type {string}
     * @memberof IncidentFormsCreateFormRequestBody
     */
    form_type: IncidentFormsCreateFormRequestBodyFormTypeEnum;
    /**
     * The incident ID associated with this form
     * @type {string}
     * @memberof IncidentFormsCreateFormRequestBody
     */
    incident_type_id: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormsCreateFormRequestBodyFormTypeEnum {
    Declare = 'declare',
    Accept = 'accept',
    Update = 'update',
    Resolve = 'resolve',
    Retrospective = 'retrospective',
    CustomFields = 'custom-fields',
    Escalate = 'escalate'
}

export function IncidentFormsCreateFormRequestBodyFromJSON(json: any): IncidentFormsCreateFormRequestBody {
    return IncidentFormsCreateFormRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsCreateFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsCreateFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copy_from_form_id': !exists(json, 'copy_from_form_id') ? undefined : json['copy_from_form_id'],
        'form_type': json['form_type'],
        'incident_type_id': json['incident_type_id'],
    };
}

export function IncidentFormsCreateFormRequestBodyToJSON(value?: IncidentFormsCreateFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copy_from_form_id': value.copy_from_form_id,
        'form_type': value.form_type,
        'incident_type_id': value.incident_type_id,
    };
}

