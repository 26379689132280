import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useAPI } from "src/utils/swr";

export const PromptNamesSelect = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
  isMulti,
  label,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
  isMulti?: boolean;
  label?: string;
}) => {
  const {
    data: { prompts },
  } = useAPI(
    "aIStaffListPromptNames",
    {},
    {
      fallbackData: { prompts: [] },
    },
  );

  const options =
    prompts.map((prompt) => ({
      label: prompt,
      value: prompt,
    })) ?? [];

  if (isMulti) {
    return (
      <StaticMultiSelectV2
        formMethods={formMethods}
        name={name as unknown as Path<FormType>}
        placeholder="(e.g. PromptAlertName)"
        options={options}
        label={label}
        helptext={helptext}
        isClearable={true}
      />
    );
  }

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      placeholder="(e.g. PromptAlertName)"
      options={options}
      label={label}
      helptext={helptext}
      isClearable={true}
    />
  );
};
