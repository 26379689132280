import {
  IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SetupPageWrapper } from "@incident-shared/layout/SetupPageWrapper/SetupPageWrapper";
import { useAuthRedirectPath } from "@incident-shared/utils/useAuthRedirectPath";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Spinner,
} from "@incident-ui";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import { capitalize } from "lodash";
import React from "react";
import { MSTeamsInstallFailure } from "src/components/msteams/InstallFailure";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { InstallationStepID } from "../useSetupSteps";

export const SetupInstallMsTeams = (): React.ReactElement => {
  const { identity } = useIdentity();
  const queryParams = useQueryParams();

  const error = queryParams.get("error");

  if (queryParams.get("pending") === "true") {
    return <PendingMSTeamsInstall />;
  }

  const firstName = identity.user_name
    ? capitalize(identity.user_name.split(" ")[0])
    : "there";

  return (
    <SetupPageWrapper
      currentStepID={InstallationStepID.Install}
      footer={
        <>
          We only ask for Microsoft Teams permissions we need to do our job.
          <br />
          See our{" "}
          <Button
            href="https://help.incident.io/collections/1827313954-security-faqs"
            theme={ButtonTheme.Unstyled}
            className="text-white"
            openInNewTab
            analyticsTrackingId={"install-security-faqs"}
          >
            Security FAQs
          </Button>{" "}
          for more detail.
        </>
      }
    >
      <div className="flex flex-col gap-5">
        <div className="text-2xl-bold">Hey {firstName}</div>
        <div className="text-5xl-bold">Let’s add incident.io to Teams</div>
      </div>
      <div className="w-[460px] mx-auto text-left flex flex-col gap-3">
        {error && (
          <Callout theme={CalloutTheme.Danger}>
            {SETUP_ERROR_MESSAGES[error] || (
              <>Something went wrong. Please try again.</>
            )}
          </Callout>
        )}
        <GrantConsent />
        <InstallBot />
      </div>
    </SetupPageWrapper>
  );
};

export const PendingMSTeamsInstall = (): React.ReactElement => {
  useAPI("identitySelf", undefined, {
    refreshInterval: 1000,
  });

  return (
    <>
      <SetupPageWrapper
        title={"Installing our app to Teams..."}
        currentStepID={InstallationStepID.Install}
      >
        This can take a couple of minutes. Don&rsquo;t navigate away!
        <Spinner className="mx-auto" theme={SpinnerTheme.Dark} large />
      </SetupPageWrapper>
    </>
  );
};

const SETUP_ERROR_MESSAGES: Record<string, JSX.Element> = {
  missing_required_roles: (
    <>
      Microsoft didn’t return us the roles we required. Has a Microsoft admin{" "}
      <a
        href="https://help.incident.io/articles/9863086198-microsoft's-%22admin-consent%22-flow"
        className="underline"
      >
        consented to the permissions
      </a>
      ?
    </>
  ),
  device_authentication_required: (
    <>
      Device authentication is required, please{" "}
      <a
        href="https://learn.microsoft.com/en-us/entra/msal/dotnet/advanced/exceptions/device-authentication-errors"
        className="underline"
      >
        see this Microsoft article
      </a>
      .
    </>
  ),
};

const GrantConsent = () => {
  const { identity } = useIdentity();
  const { path: redirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_install",
    `/${identity.organisation_slug}/setup/msteams/install`,
  );
  const disabled = identity.ms_teams_info?.missing_token_scopes?.length === 0;

  return (
    <div
      className={tcx(
        "rounded-3 p-6",
        disabled ? "bg-slate-700 text-slate-200" : "bg-slate-800 text-white",
      )}
    >
      <div className="block text-sm font-base space-y-4 text-left mb-4">
        {!disabled && <MSTeamsInstallFailure />}
        <div className="space-y-2 p-2 text-m">
          <p>
            Now you&rsquo;re signed in, there&rsquo;s two parts to get our
            Microsoft Teams bot installed. First, you need to grant consent for
            us to see teams, channels and users in your account.
          </p>
          <p className="!mb-1">
            This won&rsquo;t give us permission to create a team or install our
            Microsoft Teams bot yet.
          </p>
        </div>
      </div>
      <GatedButton
        href={redirectUrl}
        analyticsTrackingId={"install-teams-app"}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={false}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Microsoft}
        disabled={disabled}
        disabledTooltipContent={
          "You've already connected your Microsoft account"
        }
        spanClassName="w-full"
        className="w-full"
      >
        Connect your Microsoft account {disabled && "✅"}
      </GatedButton>
    </div>
  );
};

const InstallBot = () => {
  const { identity } = useIdentity();
  const { path: redirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_auth_short_lived_token",
    `/${identity.organisation_slug}/setup/msteams/install`,
    IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum.Install,
  );

  const enabled = identity.ms_teams_info?.missing_token_scopes?.length === 0;

  if (!identity.ms_teams_info?.auto_install_available) {
    return (
      <div
        className={tcx(
          "rounded-3 p-6",
          !enabled ? "bg-slate-700 text-slate-200" : "bg-slate-800 text-white",
        )}
      >
        <div className="block text-sm font-base space-y-4 text-left">
          <div className="space-y-2 p-2 text-m">
            <p>
              You&rsquo;ll now need to install our Teams App. This currently
              requires taking a few manual steps inside Microsoft Teams:
            </p>
            <p className="!mb-1">This will:</p>
            <>
              <ol className="list-decimal pl-5 space-y-1 mb-2">
                <li>
                  <div className="flex flex-wrap items-center gap-1">
                    Download our{" "}
                    <GatedButton
                      href={`/auth/microsoft_teams_app_download?organisation_id=${identity.organisation_id}`}
                      analyticsTrackingId={"ms-teams-manifest-download"}
                      theme={ButtonTheme.Link}
                      disabled={!enabled}
                      disabledTooltipContent={
                        "You must connect your Microsoft account first"
                      }
                      className="inline"
                    >
                      Microsoft Teams App file
                    </GatedButton>
                  </div>
                </li>
                <li>
                  Create an <code>Incidents</code> team where incidents will be
                  managed
                </li>
                <li>
                  <div className="flex flex-wrap items-center gap-1">
                    Install our app to that Team, following the{" "}
                    <GatedButton
                      analyticsTrackingId={
                        "ms-teams-manual-install-instructions"
                      }
                      openInNewTab
                      disabled={!enabled}
                      disabledTooltipContent={
                        "You must connect your Microsoft account first"
                      }
                      href="https://help.incident.io/articles/9735234193-manually-installing-incident.io-for-microsoft-teams"
                      theme={ButtonTheme.Link}
                      className="inline"
                    >
                      instructions here
                    </GatedButton>
                  </div>
                </li>
              </ol>
            </>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={tcx(
        "rounded-3 p-6",
        !enabled ? "bg-slate-700 text-slate-200" : "bg-slate-800 text-white",
      )}
    >
      <div className="block text-sm font-base space-y-4 text-left mb-4">
        {enabled && <MSTeamsInstallFailure />}
        <div className="space-y-2 p-2 text-m">
          <p>
            Now we can see who is in your account, we can create a team for
            managing incidents and add our bot to it.
          </p>
          <p className="!mb-1">This will:</p>
          <>
            <ol className="list-decimal pl-5 space-y-1 mb-2">
              <li>
                Create an <code>Incidents</code> team where incidents will be
                managed
              </li>
              <li>
                Add the <code>@incident</code> bot to that team, which you can
                tag to interact with incidents
              </li>
              <li>
                Give the incident bot access to the Incidents team, and nothing
                else in your account
              </li>
            </ol>
          </>
        </div>
      </div>
      <GatedButton
        href={redirectUrl}
        analyticsTrackingId={"install-teams-app"}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={false}
        icon={IconEnum.Microsoft}
        theme={ButtonTheme.Secondary}
        disabled={!enabled}
        disabledTooltipContent={"You must connect your Microsoft account first"}
        spanClassName="w-full"
        className="w-full"
      >
        Install to Microsoft Teams
      </GatedButton>
    </div>
  );
};
