/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReplicaPayload,
    ScheduleReplicaPayloadFromJSON,
    ScheduleReplicaPayloadFromJSONTyped,
    ScheduleReplicaPayloadToJSON,
} from './ScheduleReplicaPayload';

/**
 * 
 * @export
 * @interface SchedulesSetReplicasRequestBody
 */
export interface SchedulesSetReplicasRequestBody {
    /**
     * The replicas to set for this schedule
     * @type {Array<ScheduleReplicaPayload>}
     * @memberof SchedulesSetReplicasRequestBody
     */
    replicas: Array<ScheduleReplicaPayload>;
}

export function SchedulesSetReplicasRequestBodyFromJSON(json: any): SchedulesSetReplicasRequestBody {
    return SchedulesSetReplicasRequestBodyFromJSONTyped(json, false);
}

export function SchedulesSetReplicasRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesSetReplicasRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'replicas': ((json['replicas'] as Array<any>).map(ScheduleReplicaPayloadFromJSON)),
    };
}

export function SchedulesSetReplicasRequestBodyToJSON(value?: SchedulesSetReplicasRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'replicas': ((value.replicas as Array<any>).map(ScheduleReplicaPayloadToJSON)),
    };
}

