import { EvaluationBacktestBacktestTypeEnum } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";

export const backtestTypeLabel = (
  backtestType: EvaluationBacktestBacktestTypeEnum,
  planType?: string | null,
) => {
  let label = configForBacktestType(backtestType)?.label;
  if (!label) {
    return "";
  }
  if (
    planType &&
    backtestType === EvaluationBacktestBacktestTypeEnum.Investigation
  ) {
    label += ` (${planType})`;
  }
  return label;
};

export const configForBacktestType = (
  t: EvaluationBacktestBacktestTypeEnum,
) => {
  return BACKTEST_TYPE_CONFIGS.find((config) => config.value === t);
};

export const BACKTEST_TYPE_CONFIGS: BacktestTypeConfig[] = [
  {
    value: EvaluationBacktestBacktestTypeEnum.Investigation,
    label: "Investigation",
    icon: IconEnum.Bulb,
    color: ColorPaletteEnum.Green,
  },
  {
    value: EvaluationBacktestBacktestTypeEnum.CopilotThread,
    label: "Copilot thread",
    icon: IconEnum.Robot,
    color: ColorPaletteEnum.Purple,
  },
  {
    value: EvaluationBacktestBacktestTypeEnum.SearchCodeChanges,
    label: "Code change search",
    icon: IconEnum.GitBranch,
    color: ColorPaletteEnum.Blue,
  },
  {
    value: EvaluationBacktestBacktestTypeEnum.SearchIncidents,
    label: "Incidents search",
    icon: IconEnum.Incident,
    color: ColorPaletteEnum.Blue,
  },
];

interface BacktestTypeConfig {
  value: EvaluationBacktestBacktestTypeEnum;
  label: string;
  icon: IconEnum;
  color: ColorPaletteEnum;
}
