import {
  CatalogCreateTypeRequestBody,
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum,
  CatalogTypeModeEnum,
  CatalogTypeSchemaPayload,
  CatalogUpdateTypeRequestBody,
  CatalogUpdateTypeRequestBodyCategoriesEnum,
  CatalogUpdateTypeRequestBodyColorEnum,
  CatalogUpdateTypeRequestBodyIconEnum,
  CatalogUpdateTypeResponseBody,
  DependentResource,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { IntegrationConfig } from "@incident-shared/integrations/IntegrationConfig";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { partition } from "lodash";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { serializeParameterisedResourceArguments } from "./AddDynamicAttributesPopover";
import { CatalogTypeCreateEditForm } from "./CatalogTypeCreateEditForm";
import { CatalogTypeSchemaDrawer } from "./CatalogTypeSchemaDrawer";
import {
  AttributeFormState,
  CatalogTypeCreateEditFormState,
  CatalogTypeCreateEditSchemaFormState,
  CatalogTypeInfo,
  isDerivedAttribute,
} from "./types";

export const CatalogTypeCreateEditFormWrapper = ({
  mode,
  initialData,
  requiredIntegration,
  supportsDynamicAttributes,
  dependentResources,
  attributeDependencies,
  catalogTypeMode,
  isManagedExternally = false,
}: CreateEditFormProps<CatalogType> & {
  requiredIntegration?: IntegrationConfig;
  supportsDynamicAttributes: boolean;
  dependentResources: DependentResource[];
  attributeDependencies?: { [key: string]: DependentResource[] };
  catalogTypeMode: CatalogTypeModeEnum;
  isManagedExternally?: boolean;
}) => {
  const [selectedDynamicAttribute, setSelectedDynamicAttribute] =
    useState<CatalogTypeInfo>();

  const navigate = useOrgAwareNavigate();

  const refetchEntries = useRevalidate(["catalogListEntries"]);

  const formMethods = useForm<CatalogTypeCreateEditFormState>({
    defaultValues: buildDefaultValues(initialData),
  });
  const { append: appendParameterisedTypeSchema } = useFieldArray({
    control: formMethods.control,
    name: "parameterised_type_schemas",
  });

  const dataAttributeFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "schema.data_attributes",
    keyName: "key",
  });

  const mutation = useAPIMutation(
    "catalogShowType",
    { id: initialData?.id || "", includeAttributeDependentResources: true },
    async (client, payload: CatalogTypeCreateEditFormState) => {
      if (mode === Mode.Edit) {
        const { catalog_type } = await client.catalogUpdateType({
          id: initialData.id,
          updateTypeRequestBody: marshallFormStateToPayload(payload),
        });
        return {
          catalog_type,
          dependent_resources: dependentResources,
          supports_dynamic_attributes: supportsDynamicAttributes,
        };
      } else {
        const { catalog_type } = await client.catalogCreateType({
          createTypeRequestBody:
            // The create and update type payloads are the same, so we can use update for both
            marshallFormStateToPayload(
              payload,
            ) as unknown as CatalogCreateTypeRequestBody,
        });
        return {
          catalog_type,
          dependent_resources: [],
          supports_dynamic_attributes: supportsDynamicAttributes,
        };
      }
    },
    {
      onSuccess: (resp: CatalogUpdateTypeResponseBody) => {
        refetchEntries();
        navigate(`/catalog/${slugForCatalogType(resp.catalog_type)}`);
      },
      setError: formMethods.setError,
    },
  );

  // We have to do this to make TypeScript happy - something about
  // the way the props are passed in to this component and then in
  // to another one is making it unhappy. And me unhappy.
  const createEditFormProps: CreateEditFormProps<CatalogType> =
    mode === Mode.Edit
      ? {
          mode,
          initialData,
        }
      : { mode, initialData: undefined };

  const catalogTypeName = formMethods.watch("name");

  const { fields: attributes, remove: removeAttribute } =
    dataAttributeFieldMethods;

  const handleCancelDrawer = () => {
    setSelectedDynamicAttribute(undefined);
    // We also need to remove the relevant attribute from the schema
    const attributeIdx = attributes.findIndex((attr) => {
      return (
        attr.catalogTypeInfo?.parameter ===
          selectedDynamicAttribute?.parameter &&
        attr.catalogTypeInfo?.registryType ===
          selectedDynamicAttribute?.registryType
      );
    });
    if (attributeIdx >= 0) {
      removeAttribute(attributeIdx);
    }
  };

  const handleNewParameterisedType = (data: CatalogTypeCreateEditFormState) => {
    appendParameterisedTypeSchema({
      schema: marshallSchemaToPayload(data.schema),
      parameterised_resource_arguments: serializeParameterisedResourceArguments(
        selectedDynamicAttribute,
      ),
    });
    setSelectedDynamicAttribute(undefined);
  };

  return (
    <>
      <CatalogTypeSchemaDrawer
        onClose={handleCancelDrawer}
        parentTypeName={catalogTypeName}
        onSubmit={handleNewParameterisedType}
        selectedDynamicAttribute={selectedDynamicAttribute}
        // The required integration of a new dynamic parameterised type's
        // attributes must be the same as its parent type
        requiredIntegration={requiredIntegration}
      />
      <CatalogTypeCreateEditForm
        handleSubmit={mutation.trigger}
        formMethods={formMethods}
        saving={mutation.isMutating}
        genericError={mutation.genericError}
        catalogTypeMode={catalogTypeMode}
        attributeDependencies={attributeDependencies}
        requiredIntegration={requiredIntegration}
        onAddAttributeCallback={(attributeOption: AttributeFormState) => {
          if (
            attributeOption.catalogTypeInfo &&
            !attributeOption.catalogTypeInfo.exists
          ) {
            setSelectedDynamicAttribute(attributeOption.catalogTypeInfo);
          }
        }}
        isManagedExternally={isManagedExternally}
        {...createEditFormProps}
      />
    </>
  );
};

const marshallFormStateToPayload = (
  formState: CatalogTypeCreateEditFormState,
): CatalogUpdateTypeRequestBody => {
  return {
    ...formState,
    schema: marshallSchemaToPayload(formState.schema),
  };
};

const marshallSchemaToPayload = (
  schema: CatalogTypeCreateEditSchemaFormState,
): CatalogTypeSchemaPayload => {
  const newSchema = {
    version: schema.version,
    attributes: [],
  } as CatalogTypeSchemaPayload;

  const dataAttributes = schema.data_attributes.map(marshallAttribute);
  const derivedAttributes = schema.derived_attributes.map(marshallAttribute);

  newSchema.attributes = [...dataAttributes, ...derivedAttributes];

  return newSchema;
};

const marshallAttribute = (
  attr: AttributeFormState,
): CatalogTypeAttributePayload => {
  return {
    id: attr.id,
    name: attr.name,
    type: attr.type,
    array: attr.array,
    mode: attr.mode,
    backlink_attribute: attr.backlink_attribute,
    path: attr.path,
    was_previously_synced: attr.was_previously_synced,
    parameterised_resource_arguments: serializeParameterisedResourceArguments(
      attr.catalogTypeInfo,
    ),
  };
};

const buildDefaultValues = (initialData?: CatalogType) => {
  if (!initialData) {
    return {
      ranked: false,
      name: "",
      icon: CatalogUpdateTypeRequestBodyIconEnum.Box,
      color: CatalogUpdateTypeRequestBodyColorEnum.Green,
      // This initialises the schema editor with an empty row
      schema: {
        data_attributes: [],
        derived_attributes: [],
        version: 1,
      },
      categories: [],
      use_name_as_identifier: true,
    };
  }

  const [derivedAttributes, dataAttributes] = partition(
    initialData.schema.attributes,
    (f) => !!f.mode && isDerivedAttribute(f.mode),
  );

  const marshallAttributes = (
    attribute: CatalogTypeAttribute,
  ): AttributeFormState => {
    return {
      ...attribute,
      mode: attribute.mode as unknown as CatalogTypeAttributePayloadModeEnum,
    };
  };

  return {
    ranked: initialData.ranked,
    name: initialData.name,
    description: initialData.description,
    schema: {
      data_attributes: dataAttributes.map(marshallAttributes),
      derived_attributes: derivedAttributes.map(marshallAttributes),
      version: initialData.schema.version,
    },
    icon: initialData.icon as unknown as CatalogUpdateTypeRequestBodyIconEnum,
    color:
      initialData.color as unknown as CatalogUpdateTypeRequestBodyColorEnum,
    categories:
      initialData.categories as unknown as CatalogUpdateTypeRequestBodyCategoriesEnum[],
    use_name_as_identifier: initialData.use_name_as_identifier,
  };
};
