import cx from "classnames";

export const DegradedPerformanceIcon = ({ className }: { className?: string; }): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx("w-[16px] h-[16px] mr-2 text-icon-degraded", className)}
  >
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M15.189 10.557L9.764 1.161C9.344 0.434 8.592 0 7.752 0C6.912 0 6.16 0.434 5.74 1.161L0.315 10.557C-0.105 11.284 -0.105 12.152 0.315 12.879C0.735 13.607 1.487 14.041 2.327 14.041H13.177C14.017 14.041 14.769 13.607 15.189 12.879C15.609 12.152 15.609 11.284 15.189 10.557ZM7.002 4.541C7.002 4.127 7.338 3.791 7.752 3.791C8.166 3.791 8.502 4.127 8.502 4.541V8.041C8.502 8.455 8.166 8.791 7.752 8.791C7.338 8.791 7.002 8.455 7.002 8.041V4.541ZM7.752 11.61C7.2 11.61 6.752 11.161 6.752 10.61C6.752 10.059 7.2 9.61 7.752 9.61C8.304 9.61 8.752 10.059 8.752 10.61C8.752 11.161 8.304 11.61 7.752 11.61Z"
    fill="currentColor"
  />
  </svg>
);
