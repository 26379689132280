import { usePylon } from "@bolasim/react-use-pylon";
import { GoogleWorkspaceCalendar } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Link,
  Loader,
  LoadingModal,
  OrgAwareLink,
  TabModal,
  TabModalPane,
} from "@incident-ui";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  DebriefPlaceholder,
  DebriefSettings,
  Identity,
  Incident,
  IntegrationSettings,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useDebriefName } from "src/utils/postmortem-name";
import { useAPI } from "src/utils/swr";

import { useClipboard } from "../../../../utils/useClipboard";
import { usePostIncidentModalContext } from "../postincidentflow/modals/PostIncidentActionModals";
import { ScheduleDebriefInPlaceholderModal } from "./ScheduleDebriefInPlaceholderModal";

export enum ScheduleDebriefMode {
  // In default mode, we'll explain we're going to push them to a pre-filled GCal event.
  Default = "default",
  // In placeholder mode, we'll explain that we're going to create the event for them
  // in one of their placeholders.
  Placeholder = "placeholder",
}

export const ScheduleDebriefModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();
  const { identity } = useIdentity();

  const { data: settingsResp, isLoading: loadingSettings } = useAPI(
    "debriefsShowSettingsForIncident",
    {
      incidentId: incident.id,
    },
  );

  const { data: placeholdersResp, isLoading: loadingPlaceholders } = useAPI(
    "debriefsListPlaceholders",
    undefined,
  );
  const { integrations, integrationsLoading } = useIntegrations();

  if (
    loadingSettings ||
    loadingPlaceholders ||
    integrationsLoading ||
    !integrations
  ) {
    return (
      <LoadingModal
        isOpen={true}
        title={`Schedule ${debriefNameLower}`}
        onClose={() => null}
      />
    );
  }

  if (!placeholdersResp) {
    return (
      <GenericErrorMessage description="We couldn't load your placeholders." />
    );
  }

  if (!settingsResp) {
    return (
      <GenericErrorMessage description="We couldn't load your Debrief settings." />
    );
  }

  return (
    <ScheduleDebriefModalInner
      integrations={integrations}
      incident={incident}
      debriefSettings={settingsResp.settings}
      placeholders={placeholdersResp.placeholders}
      onClose={onClose}
      identity={identity}
    />
  );
};

const ScheduleDebriefModalInner = ({
  integrations,
  incident,
  debriefSettings,
  placeholders,
  onClose,
  identity,
}: {
  integrations: IntegrationSettings[];
  incident: Incident;
  debriefSettings: DebriefSettings;
  placeholders: DebriefPlaceholder[];
  onClose: () => void;
  identity: Identity;
}): React.ReactElement => {
  const [mode, setMode] = useState<ScheduleDebriefMode>(
    identity.feature_gates.debrief_placeholders && placeholders.length > 0
      ? ScheduleDebriefMode.Placeholder
      : ScheduleDebriefMode.Default,
  );

  if (mode === ScheduleDebriefMode.Placeholder) {
    return (
      <ScheduleDebriefInPlaceholderModal
        incident={incident}
        debriefSettings={debriefSettings}
        placeholders={placeholders}
        onClose={onClose}
        setMode={setMode}
      />
    );
  } else {
    return (
      <DefaultScheduleDebriefModal
        integrations={integrations}
        incident={incident}
        debriefSettings={debriefSettings}
        onClose={onClose}
      />
    );
  }
};

const DefaultScheduleDebriefModal = ({
  integrations,
  incident,
  debriefSettings,
  onClose,
}: {
  integrations: IntegrationSettings[];
  incident: Incident;
  debriefSettings: DebriefSettings;
  onClose: () => void;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();

  const calendarProviderIntegrations = useCalendarProviders(integrations);
  const defaultTab = useDefaultTab(calendarProviderIntegrations);

  return (
    <TabModal
      analyticsTrackingId="schedule-a-debrief"
      isOpen
      onClose={onClose}
      title={`Schedule ${debriefNameLower}`}
      suppressInitialAnimation={true}
      defaultTab={defaultTab}
      tabs={[
        ...calendarProviderIntegrations.map((integration) => ({
          id: integration.provider,
          label: integration.provider_name,
        })),
        {
          id: "other",
          label: "Other",
        },
      ]}
    >
      <>
        {calendarProviderIntegrations.map((integration) => (
          <ScheduleDebriefTab
            key={integration.provider}
            integration={integration}
            debriefSettings={debriefSettings}
            incident={incident}
          />
        ))}
        <OtherTab />
      </>
    </TabModal>
  );
};

const ScheduleButton = ({
  icon,
  openIn,
  className,
  provider,
  incidentID,
}: {
  icon: IconEnum | undefined;
  openIn: string;
  className: string | undefined;
  provider: string;
  incidentID: string;
}): React.ReactElement => {
  const { data: urlResp, isLoading: loadingUrl } = useAPI(
    "debriefsGenerateCalendarURL",
    {
      incidentId: incidentID,
      provider: provider,
    },
  );

  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Secondary}
      onClick={() => window.open(urlResp?.provider_url, "_blank")}
      className={className}
      icon={icon}
      disabled={!urlResp || loadingUrl}
    >
      Open in {openIn}
    </Button>
  );
};

const OtherTab = (): React.ReactElement => {
  return (
    <TabModalPane
      tabId="other"
      className="flex flex-col gap-4 text-sm text-slate-800 !pt-0"
    >
      <UseAnotherCalendarButton />
    </TabModalPane>
  );
};

const CopyToClipboardButton = ({
  value,
}: {
  value: string;
}): React.ReactElement => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  return (
    <Button
      icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
      analyticsTrackingId="copy-calendar-id"
      type="button"
      title="Copy to clipboard"
      iconProps={{
        className: hasCopied ? "text-green-content" : "text-slate-700",
        size: IconSize.Large,
      }}
      theme={ButtonTheme.Secondary}
      onClick={() => copyTextToClipboard(value)}
      className={"mt-2 !font-normal !transition-none"}
    >
      Copy calendar ID
    </Button>
  );
};

const GoToCalendarProviderInstructions = ({
  debriefNameWithArticleLower,
  calendarProvider,
}: {
  debriefNameWithArticleLower: string;
  calendarProvider: string;
}): React.ReactElement => {
  return (
    <span>
      We&apos;ll help you schedule {debriefNameWithArticleLower} with{" "}
      {calendarProvider}.
    </span>
  );
};

const UseAnotherCalendarButton = (): React.ReactElement => {
  return (
    <span className="mt-2">
      <span>
        {
          "We don't support other calendar providers currently. If you use a different calendar, please "
        }
      </span>
      <Button
        analyticsTrackingId="use-another-calendar-link"
        theme={ButtonTheme.Link}
        href={`mailto:help@incident.io?subject=${encodeURIComponent(
          "Calendar integrations",
        )}`}
        openInNewTab
      >
        let us know
      </Button>
    </span>
  );
};

const AutoAttachInstructions = ({
  incident,
  integration,
  calendarShouldCreateIn,
}: {
  incident: Incident;
  integration: IntegrationSettings;
  calendarShouldCreateIn: GoogleWorkspaceCalendar | undefined;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();
  const { showKnowledgeBaseArticle } = usePylon();
  const connectedUser = useConnectedUser(integration.provider);
  const { setShowingModal } = usePostIncidentModalContext();
  const navigate = useOrgAwareNavigate();
  const location = useLocation();
  const initialDebriefIds = useRef<Set<string>>();

  // While this is visible, poll the debriefs meeting list quite a lot, so that
  // when we get the webhook from Google Calendar, this page updates right away
  const { data } = useAPI(
    "debriefsListIncidentDebriefs",
    { incidentId: incident.id },
    { refreshInterval: 1000 },
  );

  // Keep track of the debriefs we've seen so far - this means we can check if a new one
  // has been created!
  useEffect(() => {
    if (!initialDebriefIds.current && data?.incident_debriefs) {
      initialDebriefIds.current = new Set(
        data.incident_debriefs.map((d) => d.id),
      );
    }
  }, [data?.incident_debriefs]);

  // If we see a new one, we'll close the modal and naviagte to the debriefs drawer
  useEffect(() => {
    const newDebrief = (data?.incident_debriefs ?? []).find(
      (d) => !initialDebriefIds.current?.has(d.id),
    );

    if (newDebrief) {
      setShowingModal(null); // Close the modal first
      navigate(
        {
          pathname: `/incidents/${incident.id}/debriefs`,
          search: location.search,
        },
        // we need this to avoid affecting the back button in the incident details page
        // we don't want to add a new entry to the history stack
        // similar to `useNavigateToModal`
        { replace: true },
      );
    }
  }, [
    data?.incident_debriefs,
    navigate,
    incident.id,
    location.search,
    setShowingModal,
    initialDebriefIds,
  ]);

  if (!connectedUser) {
    return <Loader />;
  }

  if (integration.reconnection_reason !== "") {
    return (
      <Callout theme={CalloutTheme.Warning}>
        There&apos;s a problem with your connection to{" "}
        {integration.provider_name}, which means we won&apos;t be able to
        automatically attach this event to your incident.{" "}
        <OrgAwareLink to="/settings/integrations" className="underline">
          Reconnect on the Integrations page
        </OrgAwareLink>
        .
      </Callout>
    );
  }

  return (
    <>
      <span>
        {/* This component only ever follows the blurb about adding to GCal, so we need a leading space. */}{" "}
        Just be sure to keep{" "}
        <span className="font-semibold">{`INC-${incident.external_id}`}</span>{" "}
        and the word <span className="font-semibold">{debriefNameLower}</span>{" "}
        in the title to have it linked automatically.
      </span>
      {/* This section is specific to GCal, whoever passes by this in the future should
      consider making this nicer, potentially a component being passed in rather than defined
      within this component */}
      {calendarShouldCreateIn ? (
        <Callout theme={CalloutTheme.Info}>
          <div>
            Your configuration requires that your debriefs are added to the{" "}
            <span className="font-semibold">{calendarShouldCreateIn.name}</span>{" "}
            calendar. If you can&apos;t see it as an option, copy the Calendar
            ID and{" "}
            <Button
              onClick={() => showKnowledgeBaseArticle("2389152723")}
              theme={ButtonTheme.Link}
              analyticsTrackingId={`gcal-setup-more-information`}
            >
              follow these steps
            </Button>
            .
          </div>
          <CopyToClipboardButton value={calendarShouldCreateIn.calendar_id} />
        </Callout>
      ) : (
        <Callout theme={CalloutTheme.Info}>
          Make sure that the connected user (
          <span className="font-semibold">{connectedUser.email}</span>) stays on
          the list!
        </Callout>
      )}
    </>
  );
};

const InstallCalendarProviderCallout = ({
  integration,
}: {
  integration: IntegrationSettings;
}) => {
  return (
    <Callout theme={CalloutTheme.Info} iconOverride={IconEnum.New}>
      It&apos;s now possible to link this event to your incident, so everyone
      knows when it is! Head to the{" "}
      <Link
        to={`/settings/integrations/${integration.provider}`}
        analyticsTrackingId={`schedule-debrief-${_.kebabCase(
          integration.provider,
        )}-integration-link`}
      >
        {integration.provider_name} integration
      </Link>{" "}
      to get set up.
    </Callout>
  );
};

const useDefaultTab = (calendarProviderIntegrations: IntegrationSettings[]) => {
  const installedCalendarProviders = calendarProviderIntegrations.filter(
    (integration) => integration.installed,
  );

  if (installedCalendarProviders.length === 0) {
    return IntegrationProvider.GoogleCalendar;
  } else if (installedCalendarProviders.length === 1) {
    return installedCalendarProviders[0].provider;
  } else {
    // TODO: https://linear.app/incident-io/issue/RESP-9973/figure-out-how-to-handle-both-gcal-and-outlook-being-installed
    return IntegrationProvider.GoogleCalendar;
  }
};

const useCalendarProviders = (integrations: IntegrationSettings[]) => {
  const supportedCalendarProviders = [
    IntegrationProvider.GoogleCalendar,
    IntegrationProvider.OutlookCalendar,
  ];
  return integrations
    .filter((integration) =>
      supportedCalendarProviders.includes(integration.provider),
    )
    .sort((a, b) => {
      // installed integrations should be first
      if (a.installed && !b.installed) {
        return -1;
      }
      if (!a.installed && b.installed) {
        return 1;
      }
      // if both installed or uninstalled, then order by provider name
      return a.provider_name.localeCompare(b.provider_name);
    });
};

const ScheduleDebriefTab = ({
  integration,
  debriefSettings,
  incident,
}: {
  integration: IntegrationSettings;
  debriefSettings: DebriefSettings;
  incident: Incident;
}) => {
  const { debriefNameWithArticleLower } = useDebriefName();
  const calendarShouldCreateIn = useTargetGoogleCalendarCalendar(
    integration,
    debriefSettings,
  );

  return (
    <TabModalPane
      tabId={integration.provider}
      className="flex flex-col gap-4 text-sm text-slate-800"
    >
      {/* show a CTA to install provider */}
      {!integration.installed &&
        // when feature flag is off, lets not suggest to install outlook calendar
        integration.provider === IntegrationProvider.OutlookCalendar && (
          <InstallCalendarProviderCallout integration={integration} />
        )}
      <GoToCalendarProviderInstructions
        debriefNameWithArticleLower={debriefNameWithArticleLower}
        calendarProvider={integration.provider_name}
      />
      {/* GCal specific right now, could be MS Teams too actually */}
      {integration.installed && (
        <AutoAttachInstructions
          integration={integration}
          incident={incident}
          calendarShouldCreateIn={calendarShouldCreateIn}
        />
      )}

      <ScheduleButton
        icon={providerLogo(integration.provider)}
        openIn={integration.provider_name}
        className="self-center"
        provider={integration.provider}
        incidentID={incident.id}
      />
    </TabModalPane>
  );
};

const providerLogo = (provider: IntegrationProvider): IconEnum | undefined => {
  switch (provider) {
    case IntegrationProvider.GoogleCalendar:
      return IconEnum.GoogleCalendar;
    case IntegrationProvider.OutlookCalendar:
      return IconEnum.Outlook;
    default:
      return undefined;
  }
};

const useTargetGoogleCalendarCalendar = (
  integration: IntegrationSettings,
  debriefSettings: DebriefSettings,
): GoogleWorkspaceCalendar | undefined => {
  const { data } = useAPI(
    integration.provider === IntegrationProvider.GoogleCalendar &&
      integration.installed &&
      debriefSettings.should_use_calendar_id
      ? "integrationsGoogleWorkspaceGetCalendars"
      : null,
    {},
    {
      fallbackData: { calendars: [] },
    },
  );

  if (!data) {
    return undefined;
  }

  return data.calendars.find(
    (calendar) => calendar.calendar_id === debriefSettings.calendar_id,
  );
};

const useConnectedUser = (provider: IntegrationProvider) => {
  const { data: outlookConfigResp } = useAPI(
    "integrationsOutlookCalendarGetConfig",
    {},
  );
  const { data: googleConfigResp } = useAPI(
    "integrationsGoogleWorkspaceGetConfig",
    {},
  );

  return provider === IntegrationProvider.OutlookCalendar
    ? outlookConfigResp?.config.connected_user
    : googleConfigResp?.config.connecting_user;
};
