import {
  EscalationPathNodeTypeEnum as NodeTypes,
  EscalationPathTargetScheduleModeEnum,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
  Identity,
  Schedule,
  UserOptionStateEnum,
  WeekdayIntervalWeekdayEnum,
} from "@incident-io/api";
import { ulid } from "ulid";

import { escalationPathLevelToTimeToAckConfig } from "./marshall";
import {
  EscalationPathConditionType,
  EscalationPathFormData,
  EscalationPathTargetFormData,
  EscalationPathTargetSelectionMode,
} from "./types";

// generateDefaultFormData generates a default form data object for creating a new escalation path.
export const generateDefaultFormData = (
  identity: Identity,
  highestPriorityId: string,
  // when specified, changes the L1 node to target the schedule with this ID
  forSchedule?: Schedule,
): EscalationPathFormData => {
  const NODE_BRANCH_ID = ulid(),
    NODE_BRANCH_HIGH_ID = ulid(),
    NODE_BRANCH_LOW_ID = ulid(),
    NODE_BRANCH_HIGH_REPEAT_ID = ulid();

  const defaultTargets: EscalationPathTargetFormData[] = forSchedule
    ? [
        {
          type: EscalationPathTargetTypeEnum.Schedule,
          value: forSchedule.id,
          label: forSchedule.name,
          schedule_mode: EscalationPathTargetScheduleModeEnum.CurrentlyOnCall,
        },
      ]
    : [
        {
          type: EscalationPathTargetTypeEnum.User,
          state: identity.user_state as unknown as UserOptionStateEnum,
          value: identity.user_id,
          label: identity.user_name,
        },
      ];

  // Default escalation path that:
  // - Branches on priority so:
  //   - High priority incidents are escalated to the user, with high-urgency
  //   - Then repeat node 3 times
  // - And other branch:
  //   - Escalates to the user, with low-urgency
  return {
    id: ulid(),
    name: "",
    firstNodeId: NODE_BRANCH_ID,
    nodes: {
      [NODE_BRANCH_ID]: {
        id: NODE_BRANCH_ID,
        data: {
          type: NodeTypes.IfElse,
          if_else: {
            conditionType: EscalationPathConditionType.Priority,
            priorityIds: [highestPriorityId],
            thenNodeId: NODE_BRANCH_HIGH_ID,
            elseNodeId: NODE_BRANCH_LOW_ID,
          },
        },
      },
      [NODE_BRANCH_HIGH_ID]: {
        id: NODE_BRANCH_HIGH_ID,
        data: {
          type: NodeTypes.Level,
          nextNodeId: NODE_BRANCH_HIGH_REPEAT_ID,
          level: {
            targets: defaultTargets,
            targetSelectionMode: EscalationPathTargetSelectionMode.AllAtOnce,
            urgency: EscalationPathTargetUrgencyEnum.High,
            ...escalationPathLevelToTimeToAckConfig(undefined),
          },
        },
      },
      [NODE_BRANCH_HIGH_REPEAT_ID]: {
        id: NODE_BRANCH_HIGH_REPEAT_ID,
        data: {
          type: NodeTypes.Repeat,
          repeat: {
            repeat_times: "3",
            to_node: NODE_BRANCH_ID,
          },
        },
      },
      [NODE_BRANCH_LOW_ID]: {
        id: NODE_BRANCH_LOW_ID,
        data: {
          type: NodeTypes.Level,
          level: {
            targets: defaultTargets,
            targetSelectionMode: EscalationPathTargetSelectionMode.AllAtOnce,
            urgency: EscalationPathTargetUrgencyEnum.Low,
            ...escalationPathLevelToTimeToAckConfig(undefined),
          },
        },
      },
    },
    working_hours: getWorkingHoursDefaults(),
    team_ids: [],
  };
};

export const getWorkingHoursDefaults = () => ({
  config_id: "default",
  weekdays: {
    [WeekdayIntervalWeekdayEnum.Monday]: true,
    [WeekdayIntervalWeekdayEnum.Tuesday]: true,
    [WeekdayIntervalWeekdayEnum.Wednesday]: true,
    [WeekdayIntervalWeekdayEnum.Thursday]: true,
    [WeekdayIntervalWeekdayEnum.Friday]: true,
  },
  start_time: "09:00",
  end_time: "17:00",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});
