import { AppliedFiltersInline } from "@incident-shared/filters/AppliedFilters";
import {
  Button,
  ButtonTheme,
  ContentBox,
  DropdownMenu,
  DropdownMenuItem,
  Heading,
  IconEnum,
  Txt,
} from "@incident-ui";
import { compact } from "lodash";
import React, { useState } from "react";
import {
  Incident,
  PostIncidentTaskSlim,
  RemindersCreateRequestBodyReminderTypeEnum,
} from "src/contexts/ClientContext";
import { usePrimaryCommsPlatformFeatures } from "src/hooks/usePrimaryCommsPlatform";
import { tcx } from "src/utils/tailwind-classes";

import { CreateOrUpdateSavedViewButtonModal } from "../../saved-views/CreateOrUpdateSavedViewButtonModal";
import { SavedViewHeading } from "../../saved-views/SavedViewHeading";
import { SendRemindersModal } from "../common/SendRemindersModal";
import { BulkUpdateModal, PostIncidentBulkAction } from "./BulkUpdateModal";

export const PostIncidentListHeader = ({
  incidents,
  totalNumberOfIncidents,
  selectedTasks,
  refetchIncidents,
  setSelectedTasks,
}: {
  incidents: Incident[];
  totalNumberOfIncidents: number | null;
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (tasks: PostIncidentTaskSlim[]) => void;
  refetchIncidents: () => Promise<void>;
}): React.ReactElement => {
  const [bulkAction, setBulkAction] = useState<PostIncidentBulkAction>();
  const [sendReminders, setSendReminders] = useState(false);
  const { platformHasReminders } = usePrimaryCommsPlatformFeatures();

  const markAsCompleted = async () => {
    setBulkAction("mark-as-completed");
  };

  const markAsSkipped = async () => {
    setBulkAction("mark-as-skipped");
  };

  const assign = async () => {
    setBulkAction("assign");
  };

  return (
    <>
      <IntroSection />
      <div className="w-full text-content-primary font-semibold bg-white z-30 mb-4">
        <div className="flex flex-wrap items-center gap-4 justify-between z-30">
          <SavedViewHeading pageName="Select a view" compact={true} />
          <div className="flex flex-nowrap justify-end items-center gap-4 flex-center-y">
            <div className="flex gap-2">
              {selectedTasks.length > 0 && (
                <DropdownMenu
                  triggerButton={
                    <Button
                      analyticsTrackingId="post-incident-tasks-bulk-actions-button"
                      className="px-2 py-1.5 rounded-2 !font-medium shadow-sm mr-auto md:mr-0"
                    >
                      Apply bulk actions
                    </Button>
                  }
                >
                  {platformHasReminders && (
                    <DropdownMenuItem
                      analyticsTrackingId="post-incident-tasks-send-reminders"
                      onSelect={() => setSendReminders(true)}
                      icon={IconEnum.SpeechImportant}
                      iconProps={{ className: "!w-5 !h-5 -ml-1" }}
                      label={"Send reminders"}
                    />
                  )}
                  <DropdownMenuItem
                    analyticsTrackingId="post-incident-tasks-assign"
                    onSelect={() => assign()}
                    icon={IconEnum.User}
                    iconProps={{ className: "!w-5 !h-5 -ml-1" }}
                    label={"Assign"}
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="post-incident-tasks-mark-as-completed"
                    onSelect={() => markAsCompleted()}
                    icon={IconEnum.Tick}
                    iconProps={{
                      className:
                        "!w-5 !h-5 p-0.5 -ml-1 bg-green-surface text-green-content rounded-full",
                    }}
                    label={"Mark as completed"}
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="post-incident-tasks-mark-as-not-doing"
                    onSelect={() => markAsSkipped()}
                    icon={IconEnum.Close}
                    iconProps={{
                      className:
                        "!w-5 !h-5 p-0.5 -ml-1 bg-surface-tertiary text-slate-600 rounded-full",
                    }}
                    label={"Mark as skipped"}
                  />
                </DropdownMenu>
              )}
              <CreateOrUpdateSavedViewButtonModal />
            </div>
          </div>
        </div>
      </div>
      <AppliedFiltersInline
        totalNumberOfItems={totalNumberOfIncidents}
        itemsLabel={"incident"}
        analyticsTrackingId={"post-incident-add-filter"}
      />
      {bulkAction && (
        <BulkUpdateModal
          incidents={incidents}
          action={bulkAction}
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
          refetchIncidents={refetchIncidents}
          onClose={() => setBulkAction(undefined)}
        />
      )}
      {sendReminders && (
        <SendRemindersModal
          selectedResourceIDs={selectedTasks.map((t) => t.id)}
          numResourcesToIgnore={countTasksToIgnore(
            compact(
              incidents.flatMap((i) => i.post_incident_tasks).filter((x) => x),
            ),
            selectedTasks.map((t) => t.id),
          )}
          resourceType={
            RemindersCreateRequestBodyReminderTypeEnum.PostIncidentTask
          }
          onClose={async () => {
            setSendReminders(false);
            await refetchIncidents();
            // clear our selected tasks
            setSelectedTasks([]);
          }}
          onCancel={() => setSendReminders(false)}
        />
      )}
    </>
  );
};

const countTasksToIgnore = (
  allTasks: PostIncidentTaskSlim[],
  selectedTaskIDs: string[],
) => {
  const tasksToRemindAbout = allTasks.filter((task) => {
    if (!selectedTaskIDs.includes(task.id)) {
      return false;
    }
    // Only remind people about tasks that are outstanding
    return task.completed_at === undefined;
  });

  return selectedTaskIDs.length - tasksToRemindAbout.length;
};

const IntroSection = () => {
  return (
    <ContentBox
      overrideBackground
      className={tcx(
        "overflow-hidden",
        "mb-6 px-2 sm:px-0 rounded-[6px] flex items-start lg:flex-row md:flex-col bg-surface-secondary border-stroke !shadow-none",
      )}
    >
      <div className="p-6 flex justify-between w-full mb-1">
        <div className="max-w-[80%] h-full flex flex-col justify-between">
          <div className="block lg:flex lg:flex-between">
            <div className={"mt-2"}>
              <Heading level={1} size="medium" className="mb-2">
                <div className="flex items-center gap-2">
                  Action your incidents in the post-incident flow
                </div>
              </Heading>
              <Txt className="max-w-3xl">
                Here you&apos;ll find resolved incidents which still have
                remaining tasks to complete before they can be closed.
              </Txt>
            </div>
          </div>
        </div>
        <div className="shrink-0 flex flex-col justify-center">
          <Button
            analyticsTrackingId="post-inc-list-header-configure"
            theme={ButtonTheme.Secondary}
            className="!py-2.5 grow-0"
            openInNewTab={true}
            icon={IconEnum.Cog}
            href="/settings/post-incident-flow"
          >
            Configure
          </Button>
        </div>
      </div>
    </ContentBox>
  );
};
