import { Route, Routes } from "react-router";

import { BackfillShowPage } from "./BackfillShowPage";
import { BackfillsListPage } from "./BackfillsListPage";

export const BackfillsRoute = () => {
  return (
    <Routes>
      <Route path="/:spanId" element={<BackfillShowPage />} />
      <Route path="*" element={<BackfillsListPage />} />
    </Routes>
  );
};
