import {
  AlertRouteSlim,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  EngineScope,
  ScopeNameEnum,
} from "@incident-io/api";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Prompt } from "@incident-shared/utils/Prompt";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  LocalRelativeDateTime,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import {
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  useFormContext,
} from "react-hook-form";

import { useAPI } from "../../../../utils/swr";
import { tcx } from "../../../../utils/tailwind-classes";
import { AlertSourceTypeIcon } from "../../common/AlertSourceTypeConfigs";
import { AlertsTable } from "../../common/AlertsTable";
import { useAlertResources } from "../../common/useAlertResources";
import AlertVisual from "../../images/alert-source-options.png";
import { AlertRouteConditionsEditor } from "../common/AlertRouteConditionsEditor";
import { ChooseAlertSourcesFormData } from "../common/types";
import {
  getOverlappingAlertRoutes,
  OverlappingAlertRoutesList,
} from "./OverlappingAlertRoutesList";

export const ChooseSourcesDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  alertRouteId,
  alertRoutes,
}: {
  onSubmit: (data: ChooseAlertSourcesFormData) => void;
  onClose: () => void;
  initialData?: ChooseAlertSourcesFormData;
  scope: EngineScope;
  alertRouteId?: string;
  alertRoutes: AlertRouteSlim[];
}) => {
  const formMethods = useForm<ChooseAlertSourcesFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const { isDirty } = formMethods.formState;

  formMethods.register("alertSources", {
    validate: (v) => {
      if (!Object.values(v).some((v) => v.selected)) {
        return "Must select at least one alert source";
      }

      return undefined;
    },
  });

  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const alertSources = formMethods.watch("alertSources");

  const { data: previewAlerts, isLoading: previewAlertsLoading } = useAPI(
    "alertRoutesPreviewFindMatchingAlerts",
    {
      previewFindMatchingAlertsRequestBody: {
        expressions: expressionMethods.fields.map(expressionToPayload),
        condition_groups: [],
        alert_sources: Object.entries(alertSources)
          .filter(([_, value]) => value.selected)
          .map(([id, value]) => ({
            alert_source_id: id,
            condition_groups: conditionGroupsToGroupPayloads(
              value.condition_groups || [],
            ),
          })),
      },
    },
  );

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    configsResp,
    schemaResponse,
    resourcesListResp,
  } = useAlertResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (
    resourcesLoading ||
    !schemaResponse ||
    !resourcesListResp ||
    !configsResp
  ) {
    return (
      <Drawer width="full" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="full" onClose={onClose}>
        <div className="flex flex-col h-full">
          <DrawerContents className="overflow-hidden">
            <DrawerTitle
              title="Choose alert sources"
              onClose={onClose}
              icon={IconEnum.Bolt}
              color={ColorPaletteEnum.Blue}
              compact
              theme={DrawerTitleTheme.Bordered}
            />

            <DrawerBody className="p-0 overflow-y-hidden">
              <Form.Root
                id="alert-route-choose-sources"
                fullHeight
                formMethods={formMethods}
                onSubmit={onSubmit}
              >
                <Prompt
                  when={formMethods.formState.isDirty}
                  message={
                    "Your changes have not been saved. Are you sure you want to navigate away?"
                  }
                />
                <div className={"flex flex-col lg:flex-row h-full grow"}>
                  {/* Left Panel */}
                  <div className="flex flex-col p-6 gap-6 lg:w-[50%] overflow-y-auto">
                    <div>
                      Choose where your alerts should come from. You can add
                      filters per source here, or across all selected sources in
                      the next step.
                    </div>
                    {configsResp.alert_source_configs.length === 0 ? (
                      <CreateNewAlertSourceCard hasExistingSources={false} />
                    ) : (
                      <>
                        {" "}
                        <Form.InputWrapper
                          name="alertSources"
                          label="Your alert sources"
                        >
                          <StackedList className="mt-1">
                            {/* Construct a list of attributes, keeping only the latest 3 */}
                            {configsResp.alert_source_configs.map((config) => {
                              return (
                                <AlertSourceRow
                                  key={config.id}
                                  config={config}
                                  scope={scopeWithExpressions}
                                  expressionMethods={expressionMethods}
                                  alertRouteId={alertRouteId}
                                  alertRoutes={alertRoutes}
                                />
                              );
                            })}
                          </StackedList>
                        </Form.InputWrapper>
                        <CreateNewAlertSourceCard hasExistingSources />
                      </>
                    )}
                  </div>

                  {/* Right panel */}
                  <div
                    className={
                      "flex flex-col p-6 bg-slate-50 border-l border-stroke w-full lg:w-[50%] overflow-auto"
                    }
                  >
                    <div className="text-sm-med text-content-tertiary mb-2">
                      Recent alerts{" "}
                    </div>
                    {previewAlertsLoading ? (
                      <Loader />
                    ) : (
                      <div
                        className={tcx(
                          previewAlerts?.alerts &&
                            previewAlerts?.alerts.length > 0
                            ? "mb-24"
                            : "h-full",
                        )}
                      >
                        <AlertsTable
                          schema={schemaResponse.alert_schema}
                          resources={resourcesListResp.resources}
                          alertSourceConfigs={configsResp.alert_source_configs}
                          alerts={previewAlerts?.alerts ?? []}
                          allEntriesLoaded={true}
                          enableSelection={false}
                          wrappedInBox
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Form.Root>
            </DrawerBody>
            <DrawerFooter className="flex gap-2 justify-end">
              <Button onClick={onClose} analyticsTrackingId={null}>
                Back
              </Button>
              <GatedButton
                form="alert-route-choose-sources"
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                type="submit"
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-routes-edit-sources-save"
              >
                Apply
              </GatedButton>
            </DrawerFooter>
          </DrawerContents>
        </div>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};

const AlertSourceRow = ({
  config,
  scope,
  expressionMethods,
  alertRouteId,
  alertRoutes,
}: {
  config: AlertSourceConfig;
  scope: EngineScope;
  expressionMethods: UseFieldArrayReturn<
    ChooseAlertSourcesFormData,
    "expressions",
    "key"
  >;
  alertRouteId?: string;
  alertRoutes: AlertRouteSlim[];
}) => {
  const formMethods = useFormContext<ChooseAlertSourcesFormData>();
  const sourceType = ALERT_SOURCE_TYPE_CONFIGS[config.source_type];

  const conditionGroups = formMethods.watch(
    `alertSources.${config.id}.condition_groups`,
  );
  const isSelected = formMethods.watch(`alertSources.${config.id}.selected`);

  const overlappingAlertRoutes = getOverlappingAlertRoutes({
    alertRoutes,
    alertRouteId,
    alertSourceIds: [config.id],
  });

  const checkboxId = `alertSources.${config.id}.selected` as const;

  return (
    <StackedListItem
      key={config.id}
      labelFor={checkboxId}
      title={
        <div className="min-w-0">
          <div className="flex space-x-1">
            <div className="text-ellipsis overflow-hidden text-sm-bold">
              {config.name}
            </div>
            <Tooltip content={config.alert_source.docstring} />
          </div>
          <div className="text-xs text-content-tertiary">
            {config.alert_last_fired_at ? (
              <>
                Last fired{" "}
                <LocalRelativeDateTime
                  date={config.alert_last_fired_at}
                  className="text-xs text-content-tertiary hover:!no-underline"
                />
              </>
            ) : (
              "Never fired"
            )}
          </div>
        </div>
      }
      iconNode={
        <div
          className={tcx("rounded-2 p-3")}
          style={
            sourceType.hexColor
              ? {
                  // Our hex color with opacity 10
                  backgroundColor: `${sourceType.hexColor}10`,
                }
              : undefined
          }
        >
          <AlertSourceTypeIcon
            sourceType={
              config.source_type as unknown as AlertSourceSourceTypeEnum
            }
            size={IconSize.Medium}
          />
        </div>
      }
      accessory={
        <CheckboxV2
          formMethods={formMethods}
          checkboxClassName="!w-5 !h-5"
          name={checkboxId}
        />
      }
      // This makes the checkbox treat the whole item as its hover area
      className="group/check"
      footerAccessory={
        isSelected && (
          <div className={"flex flex-col gap-4"}>
            {overlappingAlertRoutes.length > 0 && (
              <Callout theme={CalloutTheme.Warning}>
                This source is also connected to{" "}
                <OverlappingAlertRoutesList
                  overlappingAlertRoutes={overlappingAlertRoutes}
                />
                . Connecting to another route here may result in duplicate
                incidents or escalations.
              </Callout>
            )}

            <AlertRouteConditionsEditor
              name={`alertSources.${config.id}.condition_groups`}
              conditionGroups={conditionGroups || []}
              scope={scope}
              introText={`Receive all alerts from ${config.name}`}
              formMethods={formMethods}
              expressions={expressionMethods.fields}
              allowFilteringByExpression
            />
          </div>
        )
      }
    />
  );
};

const CreateNewAlertSourceCard = ({
  hasExistingSources,
}: {
  hasExistingSources: boolean;
}) => {
  return (
    <div className="flex flex-col grow">
      <div className="bg-slate-50 rounded-xl flex flex-col sm:flex-row justify-between">
        <div className="sm:self-center m-10 mr-4 font-semibold text-lg max-w-[268px] min-w-[200px]">
          <div className="">
            {hasExistingSources
              ? "Can't see your alert source?"
              : "No connected alert sources"}
          </div>
          <div className="mb-6 text-sm-normal text-content-secondary">
            Connect an alert source to start automatically creating incidents
            within incident.io
          </div>
          <Button
            href="/alerts/sources/create?from=alert-routes"
            analyticsTrackingId="alert-routes-create-new-alert-source"
            theme={
              hasExistingSources ? ButtonTheme.Secondary : ButtonTheme.Primary
            }
          >
            Connect alert source
          </Button>
        </div>
        <img
          className="p-4 pb-0 max-w-[50%] hidden sm:block self-end"
          src={AlertVisual}
        />
      </div>
    </div>
  );
};
