import {
  EvaluationBacktestBacktestTypeEnum,
  EvaluationLeaderboardPayload,
  EvaluationLeaderboardPayloadBacktestTypeEnum,
  InvestigationPlanTypeEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useForm } from "react-hook-form";
import { cacheKey, useMutationV2 } from "src/utils/swr";

import { BACKTEST_TYPE_CONFIGS, backtestTypeLabel } from "../backtests/utils";

type FormData = {
  name: string;
  backtest_type: EvaluationBacktestBacktestTypeEnum;
  investigation_plan_type?: InvestigationPlanTypeEnum;
  description?: string;
};

export const LeaderboardCreateModal = ({
  overrideBacktestType,
  onClose,
}: {
  overrideBacktestType?: EvaluationBacktestBacktestTypeEnum;
  onClose: () => void;
}) => {
  const navigate = useOrgAwareNavigate();

  const investigationPlans = Object.values(InvestigationPlanTypeEnum);

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: "",
      backtest_type:
        overrideBacktestType ||
        EvaluationBacktestBacktestTypeEnum.Investigation,
      investigation_plan_type: undefined,
      description: "",
    },
  });

  // Get the current backtest type for conditional rendering
  const currentBacktestType = formMethods.watch("backtest_type");
  const isInvestigation =
    currentBacktestType === EvaluationBacktestBacktestTypeEnum.Investigation;

  const { trigger: createLeaderboard, isMutating: isCreating } = useMutationV2(
    async (apiClient, data: EvaluationLeaderboardPayload) => {
      return await apiClient.aIStaffCreateEvaluationLeaderboard({
        createEvaluationLeaderboardRequestBody: data,
      });
    },
    {
      invalidate: [cacheKey.all("aIStaffListEvaluationLeaderboards")],
      showErrorToast: "Failed to create leaderboard",
      showSuccessToast: "Leaderboard created",
      onSuccess: (data) => {
        navigate(`/workbench/leaderboards/${data.leaderboard.id}`);
      },
    },
  );

  const onSubmit = async (data: FormData) => {
    const requestBody: EvaluationLeaderboardPayload = {
      name: data.name,
      backtest_type:
        data.backtest_type as unknown as EvaluationLeaderboardPayloadBacktestTypeEnum,
      investigation_plan_type: isInvestigation
        ? data.investigation_plan_type
        : undefined,
      description: data.description,
    };

    return createLeaderboard(requestBody);
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title={`Create ${backtestTypeLabel(currentBacktestType)} Leaderboard`}
      onClose={onClose}
      analyticsTrackingId="create-leaderboard"
      disableQuickClose
      onSubmit={(data: FormData) => {
        onSubmit(data);
      }}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={`Create leaderboard`}
          analyticsTrackingId="create-leaderboard-submit"
          saving={isCreating}
        />
      }
    >
      <InputV2
        type={InputType.Text}
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext="Give this leaderboard a descriptive name."
        required
      />

      <StaticSingleSelectV2
        formMethods={formMethods}
        name="backtest_type"
        label="Backtest type"
        disabled={!!overrideBacktestType}
        helptext={
          "Choose what type of backtests this leaderboard will compare."
        }
        options={BACKTEST_TYPE_CONFIGS.map((config) => ({
          label: config.label,
          value: config.value,
          icon: config.icon,
        }))}
      />

      {/* Only show investigation plan for investigation backtests */}
      {isInvestigation && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="investigation_plan_type"
          label="Investigation plan"
          helptext={
            "Choose what type of investigation plan this leaderboard will compare."
          }
          options={investigationPlans.map((p) => ({
            label: p,
            value: p,
          }))}
        />
      )}

      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="Optional description for this leaderboard."
      />
    </Form.Modal>
  );
};
