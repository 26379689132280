import { EvaluationDataset } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
} from "@incident-ui";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { DatasetCreateModal } from "./DatasetCreateModal";

export const DatasetsListPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useAPI(
    "aIStaffListEvaluationDatasets",
    {},
    {
      fallbackData: { datasets: [] },
    },
  );
  const datasetCount = data.datasets.length;

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          onClick={() => setIsModalOpen(true)}
          analyticsTrackingId={null}
          icon={IconEnum.Database}
          theme={ButtonTheme.Primary}
        >
          Create a dataset
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {datasetCount === 0 ? (
          <ContentBox>
            <EmptyState
              title="No datasets"
              content="Create a dataset to that can be used to run backtests"
              icon={IconEnum.Database}
              cta={
                <Button
                  onClick={() => setIsModalOpen(true)}
                  analyticsTrackingId={null}
                  icon={IconEnum.Database}
                >
                  Create a dataset
                </Button>
              }
            />
          </ContentBox>
        ) : (
          <StackedList>
            {data.datasets.map((dataset) => (
              <DatasetRow key={dataset.id} dataset={dataset} />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
      {isModalOpen ? (
        <DatasetCreateModal onClose={() => setIsModalOpen(false)} />
      ) : null}
    </WorkbenchSubPageWrapper>
  );
};

const DatasetRow = ({ dataset }: { dataset: EvaluationDataset }) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/workbench/datasets/${dataset.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-4 py-4">
          <LocalDateTime
            timestamp={dataset.created_at}
            className="font-medium"
          />
          <div className="font-medium">{dataset.name}</div>
          <Badge theme={BadgeTheme.Info} size={BadgeSize.Small}>
            {dataset.dataset_type}
          </Badge>
        </div>
        <div className="grow" />
        <div className="flex items-center justify-end gap-4">
          <div className="flex items-center gap-0.5">{dataset.description}</div>
        </div>
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={`/workbench/datasets/${dataset.id}`}
          analyticsTrackingId={null}
          title="View dataset"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
