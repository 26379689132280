import { AppliedFiltersInline } from "@incident-shared/filters/AppliedFilters";
import { CustomDisplay } from "@incident-shared/filters/CustomDisplay";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";
import { useEnabledExportIssueTrackers } from "@incident-shared/issue-trackers/useEnabledExportIssueTrackers";
import { Checkbox, IconSize } from "@incident-ui";
import { InputSize } from "@incident-ui/Input/Input";
import React, { useMemo } from "react";
import { useIncidentsListContext } from "src/components/@shared/incidents";
import {
  PopoverSelectOption,
  PopoverSelectOptionGroup,
} from "src/components/@ui/PopoverSelect/types";
import { Incident, IncidentRoleRoleTypeEnum } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useFiltersContext } from "./FiltersContext";
import { SearchBar } from "./SearchBar";

// IncidentsFilterControlSearchBar controls the search bar and incident type filter that are rendered
// just below the list of applied filters.
export const IncidentsFilterControlSearchBar = ({
  incidents,
  totalIncidents,
  selectedIncidentIDs,
  selectAllIncidents: selectAll,
  setSelectAllIncidents: setSelectAll,
  canCustomiseDisplayedInfo = true,
}: {
  incidents: Incident[];
  totalIncidents: number;
  selectedIncidentIDs: string[];
  selectAllIncidents: boolean;
  setSelectAllIncidents: (newValue: boolean) => void;
  canCustomiseDisplayedInfo?: boolean;
}): React.ReactElement | null => {
  const {
    filters,
    addFilter,
    editFilter,
    deleteFilter,
    availableFilterFields,
  } = useFiltersContext();

  return (
    <div className="flex gap-2 justify-between text-sm items-top grow">
      <div className="flex flex-row items-top gap-2 grow">
        {canCustomiseDisplayedInfo && (
          <div className={"h-7 items-center flex"}>
            <Checkbox
              // The ml-3 here aligns the checkbox with the checkbox in the incidents list!
              className="!text-content-primary ml-3 cursor-pointer"
              id="select-all"
              onChange={() =>
                setSelectAll(selectedIncidentIDs.length !== incidents.length)
              }
              checked={
                (incidents.length > 0 &&
                  selectedIncidentIDs.length === incidents.length) ||
                selectAll
              }
            />
          </div>
        )}
        <AppliedFiltersInline
          totalNumberOfItems={totalIncidents}
          itemsLabel={"incident"}
          analyticsTrackingId={"incident-filter-control-add-filter"}
        />
      </div>
      <SearchBar
        id="search_incidents"
        placeholder="Search"
        availableFilterFields={availableFilterFields}
        appliedFilters={filters}
        onEditFilter={editFilter}
        onDeleteFilter={deleteFilter}
        onAddFilter={addFilter}
        className={"bg-surface-secondary rounded border-none"}
        inputSize={InputSize.Medium}
        iconProps={{
          size: IconSize.Small,
          className: "text-content-tertiary",
        }}
      />
      {canCustomiseDisplayedInfo && <IncidentsCustomDisplay />}
    </div>
  );
};

export interface FieldInfo {
  type: "role" | "custom_field" | "other";
  id: string;
}

const IncidentsCustomDisplay = () => {
  const {
    data: { incident_roles: roles },
    isLoading: loadingRoles,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const {
    data: { custom_fields: customFields },
    isLoading: loadingFields,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const { fieldsToDisplay, setFieldsToDisplay } = useIncidentsListContext();

  const { installedTrackers } = useEnabledExportIssueTrackers(
    IssueTemplateContextEnum.IncidentTicket,
  );
  const hasIncidentTickets = installedTrackers.length > 0;

  // group by role, custom field etc
  const columnOptions = useMemo(() => {
    const groups: PopoverSelectOptionGroup<PopoverSelectOption>[] = [];

    const generalOptions: PopoverSelectOption[] = [
      {
        value: "other:mode",
        label: "Mode",
      },
    ];

    // Add incident ticket ID if available
    if (hasIncidentTickets) {
      generalOptions.push({
        value: "other:external_ref",
        label: "Incident ticket ID",
      });
    }

    groups.push({
      label: "",
      options: generalOptions,
    });

    // Add custom fields group
    if (!loadingFields && customFields?.length > 0) {
      groups.push({
        label: "Custom fields",
        options: customFields.map((field) => ({
          value: `custom_field:${field.id}`,
          label: field.name,
        })),
      });
    }

    // Add roles group (excluding Lead, this is always shown)
    if (!loadingRoles && roles?.length > 0) {
      const roleOptions = roles
        .filter((role) => role.role_type !== IncidentRoleRoleTypeEnum.Lead)
        .map((role) => ({
          value: `role:${role.id}`,
          label: role.name,
        }));

      if (roleOptions.length > 0) {
        groups.push({
          label: "Roles",
          options: roleOptions,
        });
      }
    }

    return groups;
  }, [roles, customFields, loadingRoles, loadingFields, hasIncidentTickets]);

  // Get selected values as an array of strings like "role:123"
  const selectedValues = useMemo(
    () => fieldsToDisplay.map((field) => `${field.type}:${field.id}`),
    [fieldsToDisplay],
  );

  // Handle selection changes
  const handleSelectionChange = (newValues: string[]) => {
    // Convert back to FieldInfo objects
    const newFields = newValues.map((value) => {
      const [type, id] = value.split(":");
      return {
        id,
        type: type as "role" | "custom_field" | "other",
      };
    });

    setFieldsToDisplay(newFields);
  };

  return (
    <CustomDisplay
      availableOptions={columnOptions}
      selectedValues={selectedValues}
      onSelectionChange={handleSelectionChange}
      analyticsTrackingId="incidents-list.customise-displayed-info"
    />
  );
};
