import { EvaluationLeaderboardEntry } from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { EvaluationFilterContextProvider } from "../common/EvaluationFilterContext";
import { EvaluationAggregateScorecardController } from "../investigations/EvaluationAggregateScorecard";
import { LeaderboardUpdateModal } from "./LeaderboardUpdateModal";

export const LeaderboardsShowPage = () => {
  const { id: id } = useParams<{ id: string }>();
  const [showEditLeaderboardModal, setShowEditLeaderboardModal] =
    useState(false);

  // This would be replaced with the actual API endpoint once implemented
  const { data, isLoading } = useAPI("aIStaffShowEvaluationLeaderboard", {
    id: id || "",
  });
  const leaderboard = data?.leaderboard;
  const entries = data?.entries || [];

  // Sort entries by backtest_created_at
  const sorted = entries.sort((a, b) => {
    return (
      new Date(b.backtest_created_at).getTime() -
      new Date(a.backtest_created_at).getTime()
    );
  });

  if (!id) {
    return <NotFoundPage />;
  }

  return (
    <WorkbenchSubPageWrapper
      backHref="/workbench/leaderboards"
      title={leaderboard?.name}
      subtitle={leaderboard?.description}
      accessory={
        <div className="flex gap-2">
          <Button
            analyticsTrackingId={null}
            onClick={() => setShowEditLeaderboardModal(true)}
            theme={ButtonTheme.Secondary}
          >
            Edit leaderboard
          </Button>
        </div>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {isEmpty(entries) ? (
          <EmptyState content="No entries in this leaderboard yet" />
        ) : (
          <EvaluationFilterContextProvider enableSelectingMetrics={false}>
            <StackedList>
              {sorted.map((entry) => (
                <LeaderboardEntryRow entry={entry} key={entry.id} />
              ))}
            </StackedList>
          </EvaluationFilterContextProvider>
        )}
      </LoadingWrapper>
      {leaderboard && showEditLeaderboardModal && (
        <LeaderboardUpdateModal
          leaderboardId={id}
          leaderboard={leaderboard}
          onClose={() => setShowEditLeaderboardModal(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const LeaderboardEntryRow = ({
  entry,
}: {
  entry: EvaluationLeaderboardEntry;
}) => {
  return (
    <StackedListItem
      rowHref={`/workbench/backtests/${entry.backtest_id}?from_leaderboard=${entry.leaderboard_id}`}
      key={entry.id}
      title={
        <div className="flex items-center gap-2">
          <Avatar
            url={entry.creator_user.avatar_url}
            name={entry.creator_user.name}
          />
          <LocalDateTime timestamp={entry.backtest_created_at} />
        </div>
      }
      badgeNode={
        entry.notes ? (
          <div className="text-content-tertiary font-normal">{entry.notes}</div>
        ) : undefined
      }
      accessory={
        <Icon
          id={IconEnum.ChevronRight}
          className="text-content-tertiary"
          size={IconSize.Medium}
        />
      }
      description={
        <EvaluationAggregateScorecardController
          scorecardIDs={entry.scorecard_ids}
          allEntries={[]}
          compact
          horizontal
          className="bg-transparent pt-4"
          showFilterBar={false}
        />
      }
    />
  );
};
