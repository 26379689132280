import { PanelPanelTypeEnum, PanelVariable } from "@incident-io/api";
import {
  FilterPopover,
  useFiltersContext,
  useGetContextForFilter,
} from "@incident-shared/filters";
import { AppliedFilter } from "@incident-shared/filters/AppliedFilter";
import { calculatePreviousPeriod } from "@incident-shared/insights/calculatePreviousPeriod";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Markdown,
  Tooltip,
} from "@incident-ui";
import { format, parseISO } from "date-fns";
import { uniq } from "lodash";
import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import {
  EditPanelTextModal,
  EditPanelTitleAndDescriptionForm,
} from "../create-edit/EditPanelTextModal";
import { usePanelsFormState } from "../create-edit/PanelsFormStateContext";
import { PanelFiltersContextProvider } from "./PanelFiltersContextProvider";
import { PanelVariableInputs } from "./PanelVariables";
import { DashboardContext } from "./types";
import {
  DashboardViewMode,
  EditDashboardFormData,
  useInsightsContext,
} from "./useInsightsContext";

export const PanelWrapper = ({
  title,
  description,
  variables,
  panelIdx,
  trends,
  children,
  noTitleAndDescription,
  dashboardContext,
}: {
  title: string;
  description: string;
  variables: PanelVariable[];
  panelIdx: number;
  trends: React.ReactNode;
  children: React.ReactNode;
  noTitleAndDescription?: boolean;
  dashboardContext: DashboardContext;
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollIntoView, setScrollIntoView] = React.useState(false);

  useEffect(() => {
    // We scroll async via useEffect to allow the panel to first
    // reorder and rerender before scrolling
    if (scrollIntoView) {
      scrollRef.current?.scrollIntoView();
      setScrollIntoView(false);
    }
  }, [scrollIntoView]);

  const [showEditPanelTextModal, setShowEditPanelTextModal] =
    React.useState(false);

  const formMethods = useFormContext<EditDashboardFormData>();
  const { viewMode } = useInsightsContext();
  const { filters, deleteFilter } = useFiltersContext();

  const {
    isLoading: contextLoading,
    error: contextError,
    getContextForFilter,
  } = useGetContextForFilter();

  const panel = formMethods.watch(`panels.${panelIdx}`);

  const onSubmitEditPanelTextModal = (
    form: EditPanelTitleAndDescriptionForm,
  ) => {
    if (panel.explo_dashboard) {
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard.title`,
        form.title,
      );
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard.description`,
        form.description,
      );
    } else if (panel.explo_dashboard_with_drilldown) {
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard_with_drilldown.title`,
        form.title,
      );
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard_with_drilldown.description`,
        form.description,
      );
    }

    setShowEditPanelTextModal(false);
  };

  const { panels, move, remove } = usePanelsFormState();

  if (contextLoading || !getContextForFilter) {
    return <Loader />;
  }
  if (contextError) {
    return <GenericErrorMessage error={contextError} />;
  }

  const isFirst = panelIdx === 0;
  const isLast = panelIdx === panels.length - 1;
  const isOnly = panels.length === 1;
  const currentPanel = panels[panelIdx];
  const isTextPanel = currentPanel.panel_type === PanelPanelTypeEnum.Text;

  const removeNonApplicableFilters = () => {
    // When we remove a panel, if it was the last panel using a particular filter context,
    // we need to remove those filters
    const filterContexts = uniq(
      panels.flatMap((panel) => {
        // We have to filter the current panel out of the list of panels because
        // it's still in there as we haven't re-rendered this component yet
        // with the new state, so the panels array still contains it
        if (panel.id !== currentPanel.id) {
          return panel.filter_contexts || [];
        }
        return [];
      }),
    );

    filters.forEach((filter) => {
      const context = getContextForFilter(filter.filter_id);
      if (!filterContexts.includes(context)) {
        deleteFilter(filter.filter_id);
      }
    });
  };

  return (
    <PanelFiltersContextProvider panelIdx={panelIdx}>
      <div className="flex flex-col gap-5" ref={scrollRef}>
        {/* Header section */}
        {viewMode === DashboardViewMode.EditDashboard && (
          <>
            <div className="flex justify-between gap-2">
              <div className="flex-wrap space-y-1 overflow-auto">
                <div className="flex gap-2">
                  {!isTextPanel && <AddFilterButton />}
                  <div className="space-y-2">
                    <AppliedFilters />
                    <PanelVariableInputs
                      variables={variables}
                      panelIdx={panelIdx}
                      editable={true}
                      dashboardContext={dashboardContext}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-1.5">
                <Button
                  analyticsTrackingId={"insights-v3-move-panel-up"}
                  theme={ButtonTheme.Tertiary}
                  size={BadgeSize.Medium}
                  disabled={isOnly || isFirst}
                  onClick={() => {
                    move(panelIdx, panelIdx - 1);
                    setScrollIntoView(true);
                  }}
                >
                  <Icon id={IconEnum.ChevronUp} size={IconSize.Small} />
                </Button>
                <Button
                  analyticsTrackingId={"insights-v3-move-panel-down"}
                  theme={ButtonTheme.Tertiary}
                  size={BadgeSize.Medium}
                  disabled={isOnly || isLast}
                  onClick={() => {
                    move(panelIdx, panelIdx + 1);
                    setScrollIntoView(true);
                  }}
                >
                  <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
                </Button>
                <Button
                  analyticsTrackingId={"insights-v3-remove-panel"}
                  theme={ButtonTheme.Tertiary}
                  size={BadgeSize.Medium}
                  onClick={() => {
                    remove(panelIdx);
                    removeNonApplicableFilters();
                  }}
                >
                  <Icon id={IconEnum.Delete} size={IconSize.Small} />
                </Button>
              </div>
            </div>
            <div className="border-b border-dotted border-b-slate-100 mx-[-20px]" />
          </>
        )}
        {!noTitleAndDescription && (
          <div className="flex items-start gap-4">
            <div className="w-1/2">
              <div className="flex flex-col gap-2">
                <div className="flex  gap-2">
                  <Heading
                    level={2}
                    size="small"
                    className="flex gap-2 text-base-bold"
                  >
                    {title}
                    {viewMode === DashboardViewMode.EditDashboard && (
                      <Button
                        icon={IconEnum.Edit}
                        onClick={() => setShowEditPanelTextModal(true)}
                        title="Edit details"
                        analyticsTrackingId={null}
                        theme={ButtonTheme.Naked}
                      />
                    )}
                    {viewMode === DashboardViewMode.View && (
                      <PanelFiltersTooltip
                        hasDashboardFilters={filters.length > 0}
                      />
                    )}
                  </Heading>
                </div>
                <p className="mb-4 text-slate-700 text-sm max-w-96">
                  <Markdown className="flex flex-col gap-2">
                    {description}
                  </Markdown>
                </p>
                {(viewMode === DashboardViewMode.EditFiltersAndVariables ||
                  viewMode === DashboardViewMode.View) && (
                  <PanelVariableInputs
                    variables={variables}
                    panelIdx={panelIdx}
                    editable={
                      viewMode === DashboardViewMode.EditFiltersAndVariables
                    }
                    dashboardContext={dashboardContext}
                  />
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="grid grid-cols-3 gap-4" dir="rtl">
                {trends}
              </div>
            </div>
          </div>
        )}
        {children}
        {showEditPanelTextModal && (
          <EditPanelTextModal
            initialData={{ title, description }}
            onClose={() => setShowEditPanelTextModal(false)}
            onSubmit={onSubmitEditPanelTextModal}
          />
        )}
      </div>
    </PanelFiltersContextProvider>
  );
};

export const ComparisonModeHeading = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  return (
    <div className="flex flex-row gap-4 pt-4 w-full sticky top-0 bg-gradient-to-b from-surface-primary via-surface-primary via-75% to-90%">
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Tertiary}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(prevStartDate, prevEndDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Compare}
        />
      </div>
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Info}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(startDate, endDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Calendar}
        />
      </div>
    </div>
  );
};

// This parser expects from and to to be in ISO format with a timezone
export const parseDateRange = (from: string, to: string) => {
  // Parse the dates using parseISO
  const fromDate = parseISO(from);
  const toDate = parseISO(to);

  // Format the dates using format
  const dateFmtOptions = "d MMM yy";

  const fromDateString = format(fromDate, dateFmtOptions);
  const toDateString = format(toDate, dateFmtOptions);

  return `${fromDateString} - ${toDateString}`;
};

const PanelFiltersTooltip = ({
  hasDashboardFilters: hasDashbordFilters,
}: {
  hasDashboardFilters: boolean;
}) => {
  const { filters, availableFilterFields, filtersLoading } =
    useFiltersContext();

  if (filters.length === 0) {
    return null;
  }

  return (
    <Tooltip
      delayDuration={300}
      light
      side="bottom"
      noMaxWidth
      bubbleProps={{ className: "md:max-w-[600px] sm:max-w-[90vw]" }}
      content={
        !filtersLoading && (
          <div className="flex flex-col gap-2">
            <div className="text-xs-med">
              {hasDashbordFilters
                ? "Additionally filtered by:"
                : "Filtered by:"}
            </div>
            <div className="flex flex-col gap-1">
              {filters.map((filterObj) => {
                return (
                  <AppliedFilter
                    filterObj={filterObj}
                    key={filterObj.key}
                    availableFilterFields={availableFilterFields}
                    badgeTheme="slate"
                    readonly
                    noTooltip
                  />
                );
              })}
            </div>
          </div>
        )
      }
    >
      <Badge theme={BadgeTheme.Tertiary} icon={IconEnum.Filter}>
        {filters.length}
      </Badge>
    </Tooltip>
  );
};

const AddFilterButton = () => {
  return (
    <FilterPopover
      renderTriggerButton={({ onClick }) => (
        <Button
          analyticsTrackingId={"add-panel-filter-button"}
          onClick={() => onClick()}
          theme={ButtonTheme.Tertiary}
          size={BadgeSize.Medium}
          icon={IconEnum.FilterBars}
        >
          Add filter
        </Button>
      )}
    />
  );
};

const AppliedFilters = () => {
  const { filters, availableFilterFields, deleteFilter } = useFiltersContext();
  if (filters.length === 0) {
    return null;
  }
  return (
    <div className="flex gap-1.5 flex-wrap overflow-auto">
      {filters.map((filter) => {
        return (
          <AppliedFilter
            filterObj={filter}
            key={filter.key}
            availableFilterFields={availableFilterFields}
            badgeTheme="slate"
            onDeleteFilter={deleteFilter}
          />
        );
      })}
    </div>
  );
};
