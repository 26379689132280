/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteSlim,
    AlertRouteSlimFromJSON,
    AlertRouteSlimFromJSONTyped,
    AlertRouteSlimToJSON,
} from './AlertRouteSlim';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';

/**
 * 
 * @export
 * @interface AlertRoutesListAlertRoutesResponseBody
 */
export interface AlertRoutesListAlertRoutesResponseBody {
    /**
     * 
     * @type {Array<AlertRouteSlim>}
     * @memberof AlertRoutesListAlertRoutesResponseBody
     */
    alert_routes: Array<AlertRouteSlim>;
    /**
     * 
     * @type {PaginationMeta}
     * @memberof AlertRoutesListAlertRoutesResponseBody
     */
    pagination_meta: PaginationMeta;
}

export function AlertRoutesListAlertRoutesResponseBodyFromJSON(json: any): AlertRoutesListAlertRoutesResponseBody {
    return AlertRoutesListAlertRoutesResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesListAlertRoutesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesListAlertRoutesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_routes': ((json['alert_routes'] as Array<any>).map(AlertRouteSlimFromJSON)),
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
    };
}

export function AlertRoutesListAlertRoutesResponseBodyToJSON(value?: AlertRoutesListAlertRoutesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_routes': ((value.alert_routes as Array<any>).map(AlertRouteSlimToJSON)),
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
    };
}

