import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import { snakeCase } from "lodash";

export const displayCost = (
  costInCents: number,
  toFixedOverride?: number,
): string => {
  return "$" + (costInCents / 100).toFixed(toFixedOverride || 2);
};

export const TraceLink = ({ traceID }: { traceID?: string }) => {
  return (
    <div className="flex items-center gap-0.5">
      <Icon id={IconEnum.GoogleCloud} size={IconSize.Small} />
      <Link
        analyticsTrackingId={null}
        openInNewTab
        href={`https://console.cloud.google.com/traces/legacy-list?project=incident-io-production&tid=${traceID}`}
      >
        trace
      </Link>
    </div>
  );
};

/**
 * Creates a filepath-safe string while preserving ULIDs in their original positions.
 * ULIDs are 26-character strings containing only uppercase letters and numbers.
 * @param input The string to convert
 * @returns A snake_case string with preserved ULIDs in their original positions
 */
export const snakeCasePreserveULID = (input: string): string => {
  // ULID regex: 26 characters of uppercase letters and numbers
  const ulidRegex = /[0-9A-Z]{26}/g;

  // Find all ULIDs in the string and replace with a special token that won't be affected by snake_case
  const ulids: string[] = [];
  const processedString = input.replace(ulidRegex, (match) => {
    ulids.push(match);
    // Use a token that won't be modified by snake_case
    return "ulidplaceholder";
  });

  // Apply snake case
  let result = snakeCase(processedString);

  // Replace each placeholder with its corresponding ULID
  ulids.forEach((ulid) => {
    result = result.replace("ulidplaceholder", ulid);
  });

  return result;
};
