/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeSubscription
 */
export interface StripeSubscription {
    /**
     * When it was created
     * @type {string}
     * @memberof StripeSubscription
     */
    created_at: string;
    /**
     * When it ended, or will end
     * @type {string}
     * @memberof StripeSubscription
     */
    ended_at?: string;
    /**
     * Stripe subscription ID
     * @type {string}
     * @memberof StripeSubscription
     */
    id: string;
    /**
     * When it started, or is due to start
     * @type {string}
     * @memberof StripeSubscription
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscription
     */
    status: string;
}

export function StripeSubscriptionFromJSON(json: any): StripeSubscription {
    return StripeSubscriptionFromJSONTyped(json, false);
}

export function StripeSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': json['created_at'],
        'ended_at': !exists(json, 'ended_at') ? undefined : json['ended_at'],
        'id': json['id'],
        'start_date': json['start_date'],
        'status': json['status'],
    };
}

export function StripeSubscriptionToJSON(value?: StripeSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': value.created_at,
        'ended_at': value.ended_at,
        'id': value.id,
        'start_date': value.start_date,
        'status': value.status,
    };
}

