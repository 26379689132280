import { AlertRouteSlim, EngineScope, ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { AlertSourceConfigBadge } from "../../common/AlertSourceConfigBadge";
import { useAlertResources } from "../../common/useAlertResources";
import { AlertRouteFormSection } from "../common/AlertRouteFormSection";
import {
  AlertRouteFormData,
  ChooseAlertSourcesFormData,
} from "../common/types";
import { ChooseSourcesDrawer } from "./ChooseSourcesDrawer";
import { getSelectedSources } from "./OverlappingAlertRoutesList";

export const AlertRouteChooseSourcesSection = ({
  scope,
  alertRoutes,
  alertRouteId,
}: {
  scope: EngineScope;
  alertRouteId?: string;
  alertRoutes: AlertRouteSlim[];
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: ChooseAlertSourcesFormData;
  }>({
    isEditing: false,
  });

  const onSubmit = (data: ChooseAlertSourcesFormData) => {
    formMethods.setValue<"alertSources">("alertSources", data.alertSources, {
      shouldDirty: true,
    });
    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });
    setEditingContext({ isEditing: false });
  };

  const alertSources = formMethods.watch("alertSources");
  const selectedSources = getSelectedSources(alertSources);
  const hasSelectedSources = selectedSources.length > 0;

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    configsResp,
  } = useAlertResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  return (
    <>
      {editingContext.isEditing && (
        <ChooseSourcesDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          alertRouteId={alertRouteId}
          alertRoutes={alertRoutes}
        />
      )}
      <AlertRouteFormSection
        title={
          hasSelectedSources ? "Receive alerts from:" : "Choose alert sources"
        }
        icon={IconEnum.Bolt}
        color={ColorPaletteEnum.Blue}
        isCurrentFirstTimeStep={!hasSelectedSources}
        firstTimeContent={
          <>
            Choose where your alerts should come from. You can add filters per
            source here, or across all selected sources in the next step.
          </>
        }
        accessory={
          hasSelectedSources ? (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              theme={ButtonTheme.Naked}
              onClick={() =>
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                })
              }
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit alert sources"
              analyticsTrackingId="alert-routes-edit-sources"
            />
          ) : (
            <GatedButton
              size={BadgeSize.Medium}
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              onClick={() =>
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                })
              }
              analyticsTrackingId="alert-routes-edit-sources"
            >
              View sources
            </GatedButton>
          )
        }
      >
        {hasSelectedSources && (
          <div className="flex items-center gap-2 flex-wrap p-2 rounded-lg border border-stroke-primary">
            {selectedSources.map(({ id: sourceId, condition_groups }) => {
              const sourceConfig = configsResp?.alert_source_configs.find(
                (config) => config.id === sourceId,
              );
              if (!sourceConfig) {
                return null;
              }

              return (
                <AlertSourceConfigBadge
                  key={sourceId}
                  sourceConfig={sourceConfig}
                  hasFilters={condition_groups?.length > 0}
                />
              );
            })}
          </div>
        )}
      </AlertRouteFormSection>
    </>
  );
};
