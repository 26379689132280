import {
  AlertSource,
  AlertSourceConfig,
  GrafanaConfig,
  IntegrationSettings,
  TelemetryDashboard,
  TelemetryDashboardProviderEnum,
  TelemetryDeselectDashboardRequestBodyProviderEnum,
  TelemetryFetchDashboardDetailsRequestBodyProviderEnum,
  TelemetrySelectDashboardRequestBodyProviderEnum,
} from "@incident-io/api";
import {
  ConfigureDrawerProps,
  INTEGRATION_CONFIGS,
} from "@incident-shared/integrations";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Input,
  Spinner,
  StackedList,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useMemo, useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AlertSourceConfigRow } from "../AlertSourceConfigDrawer";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const GrafanaConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data, isLoading, error } = useAPI(
    "integrationsGrafanaShow",
    undefined,
  );

  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  if (error || integrationsError || sourcesError || sourceConfigsError) {
    return <GenericErrorMessage error={error} />;
  }

  if (
    !data ||
    isLoading ||
    sourceConfigsLoading ||
    integrationsLoading ||
    sourcesLoading ||
    !integrations
  ) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      <ConfigInner
        config={data.grafana_config}
        alertSourceConfigs={alertSourceConfigs}
        alertSource={alertSources.find((s) => s.source_type === "grafana")}
        integrations={integrations}
      />
    </GenericConfigureDrawerContents>
  );
};

type ConfigInnerProps = {
  config?: GrafanaConfig;
  alertSourceConfigs: AlertSourceConfig[];
  alertSource?: AlertSource;
  integrations: IntegrationSettings[];
};

const ConfigInner = ({
  config,
  alertSourceConfigs,
  alertSource,
  integrations,
}: ConfigInnerProps) => {
  const ourSources = alertSourceConfigs.filter(
    (s) => s.source_type === "grafana",
  );

  const { telemetryDashboardConfiguration } = useFlags();

  return (
    <div className="flex flex-col gap-6">
      <div>
        {config && (
          <Callout theme={CalloutTheme.Info}>
            You are connected to the{" "}
            <span className="font-semibold">{config.api_url}</span> Grafana
            instance.
          </Callout>
        )}
      </div>
      <div>
        <div className="text-base-bold text-content-primary">Alert sources</div>
        {ourSources.length > 0 ? (
          <div className="flex mt-3 flex-col rounded-2 border border-stroke divide-y divide-slate-100">
            {ourSources.map((s) => (
              <AlertSourceConfigRow
                key={s.id}
                alertSourceConfig={s}
                alertSource={alertSource}
                integrations={integrations}
              />
            ))}
          </div>
        ) : (
          <EmptyState
            icon={IconEnum.Alert}
            content={
              "No alert sources have been configured for this integration yet."
            }
            className="mt-3"
            cta={
              <Button
                analyticsTrackingId={null}
                href="/alerts/sources/create?source_type=grafana"
              >
                Create an alert source
              </Button>
            }
          />
        )}
      </div>
      <div>
        {telemetryDashboardConfiguration ? (
          <TelemetryDashboardSelector />
        ) : null}
      </div>
    </div>
  );
};

const TelemetryDashboardSelector = () => {
  const { data: dashboardsData, isLoading: dashboardsLoading } = useAPI(
    "telemetryList",
    {
      provider: TelemetryDashboardProviderEnum.Grafana,
      selectedOnly: true,
    },
  );

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const showToast = useToast();

  const [fetchedDashboard, setFetchedDashboard] = useState<
    | {
        found: false;
      }
    | {
        found: true;
        dashboard: TelemetryDashboard;
      }
  >({ found: false });

  const { trigger: fetchDashboards, isMutating: fetchingDashboards } =
    useAPIMutation(
      "telemetryList",
      {
        provider: TelemetryDashboardProviderEnum.Grafana,
        selectedOnly: true,
      },
      async (apiClient, { url }: { url: string }) => {
        const newDash = await apiClient.telemetryFetchDashboardDetails({
          fetchDashboardDetailsRequestBody: {
            dashboard_url: url,
            provider:
              TelemetryFetchDashboardDetailsRequestBodyProviderEnum.Grafana,
          },
        });
        if (newDash && newDash.telemetry_dashboard) {
          setFetchedDashboard({
            found: true,
            dashboard: newDash.telemetry_dashboard,
          });
        } else {
          setFetchedDashboard({ found: false });
        }
      },
      {
        onError: (error) => {
          const respError = error as unknown as Response;
          if (respError.status === 404) {
            setFetchedDashboard({ found: false });
          }
        },
      },
    );

  const { trigger: selectDashboard, isMutating: isSelectingDashboard } =
    useAPIMutation(
      "telemetryList",
      { provider: TelemetryDashboardProviderEnum.Grafana, selectedOnly: true },
      async (apiClient, { id }: { id: string }) => {
        await apiClient.telemetrySelectDashboard({
          selectDashboardRequestBody: {
            dashboard_id: id,
            provider: TelemetrySelectDashboardRequestBodyProviderEnum.Grafana,
          },
        });
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          setInputValue("");
          setFetchedDashboard({ found: false });
          showToast({
            title: "Dashboard added",
            description:
              "You'll now be able to use @incident to query this dashboard in your incidents.",
            theme: ToastTheme.Success,
          });
        },
      },
    );

  const debouncedFetch = useMemo(
    () =>
      _.debounce((url: string) => {
        if (url.length > 3) {
          fetchDashboards({ url });
        }
      }, 500),
    [fetchDashboards],
  );

  const onAddClick = (dashboardId: string) => {
    selectDashboard({ id: dashboardId });
  };

  const isFetchingDashboards = fetchingDashboards && inputValue !== "";

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-content-primary text-base-bold">
            Connected dashboards
          </p>
          <p className="text-content-secondary text-xs-normal">
            Pick the Grafana dashboards you use to monitor your systems.
            We&rsquo;ll use these to provide AI-powered insights during
            incidents.
          </p>
        </div>
        <div className="flex flex-col justify-end pb-1">
          <Popover
            align="end"
            className="w-80 p-2"
            onInteractOutside={() => setIsOpen(false)}
            trigger={
              <Button
                theme={ButtonTheme.Primary}
                disabled={dashboardsLoading}
                onClick={() => setIsOpen(true)}
                analyticsTrackingId={"telemetry_dashboard_add"}
                size={BadgeSize.Small}
              >
                Add dashboard
              </Button>
            }
            open={isOpen}
            onOpenChange={(open) => {
              if (!open) {
                setInputValue("");
                setFetchedDashboard({ found: false });
              }
              setIsOpen(open);
            }}
          >
            <div className="flex flex-col gap-1 p-1 overflow-y-auto">
              <Input
                id="case-name"
                value={inputValue}
                placeholder="Dashboard URL"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  setInputValue(newValue);
                  debouncedFetch(newValue);
                }}
                insetSuffixNode={
                  isFetchingDashboards ? (
                    <Spinner />
                  ) : fetchedDashboard?.found ? (
                    <Badge
                      icon={IconEnum.Checkmark}
                      size={BadgeSize.Small}
                      theme={BadgeTheme.Success}
                      iconClassName="text-green-700"
                    />
                  ) : undefined
                }
              />
              <p className="text-slate-400 text-xs-med">
                Paste the Grafana dashboard link above.
              </p>
              {fetchedDashboard?.found && (
                <div className="flex flex-col">
                  <div className="flex flex-col gap-2 bg-surface-secondary p-3 rounded-2">
                    <div>
                      {fetchedDashboard.dashboard?.folder && (
                        <p className="bg-surface-tertiary text-content-primary text-xs-med px-1 rounded-1 w-max">
                          {fetchedDashboard.dashboard?.folder.replaceAll(
                            "/",
                            "→",
                          )}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-xs-bold text-content-primary">
                        {fetchedDashboard.dashboard?.name}
                      </p>
                      <p className="text-xs-med text-content-secondary">
                        {fetchedDashboard.dashboard?.description ||
                          "No description"}
                      </p>
                    </div>
                  </div>
                  <Button
                    onClick={() => onAddClick(fetchedDashboard.dashboard.id)}
                    loading={isSelectingDashboard || isFetchingDashboards}
                    analyticsTrackingId={"telemetry_dashboard_add"}
                    theme={ButtonTheme.Primary}
                    className="max-h-8 text-xs-med"
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
          </Popover>
        </div>
      </div>
      <StackedList>
        {dashboardsData?.telemetry_dashboards
          ?.sort((x, y) => {
            const xFolderAndName = x.folder ? `${x.folder} ${x.name}` : x.name;
            const yFolderAndName = y.folder ? `${y.folder} ${y.name}` : y.name;
            return xFolderAndName.localeCompare(yFolderAndName);
          })
          .map((d) => (
            <TelemetryDashboardRow key={d.id} telemetryDashboard={d} />
          ))}
        {!dashboardsData?.telemetry_dashboards ||
        dashboardsData?.telemetry_dashboards.length === 0 ? (
          <TelemetryDashboardEmptyState />
        ) : null}
      </StackedList>
    </div>
  );
};

const TelemetryDashboardRow = ({
  telemetryDashboard: telemetryDashboard,
}: {
  telemetryDashboard: TelemetryDashboard;
}) => {
  const showToast = useToast();

  const integrationConfigs = INTEGRATION_CONFIGS;
  const grafanaIntegrationConfig = integrationConfigs["grafana"];

  const { trigger: deselectDashboard } = useAPIMutation(
    "telemetryList",
    { provider: TelemetryDashboardProviderEnum.Grafana, selectedOnly: true },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.telemetryDeselectDashboard({
        deselectDashboardRequestBody: {
          dashboard_id: id,
          provider: TelemetryDeselectDashboardRequestBodyProviderEnum.Grafana,
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Dashboard removed",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast({
          title: "Failed to remove dashboard",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const firstCharUpper = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <DeletionConfirmationModal
        title={`Deselect ${firstCharUpper(
          telemetryDashboard.provider,
        )} dashboard`}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deselectDashboard({ id: telemetryDashboard.id });
          setDeleteModalOpen(false);
        }}
        analyticsTrackingId="deselect-dashboard"
        resourceTitle={"Dashboard"}
        deleteConfirmationContent={
          <>
            Are you sure you want to deselect{" "}
            <span className="font-bold">{telemetryDashboard.name}</span>?
          </>
        }
      />
      <div className="flex flex-row gap-3 px-4 py-3 justify-between w-full">
        <div className="flex flex-row gap-3">
          <Badge
            icon={IconEnum.Dashboard}
            size={BadgeSize.Medium}
            theme={BadgeTheme.Unstyled}
            iconStyle={{
              color: grafanaIntegrationConfig.colourConfig?.primaryColour,
            }}
            style={{
              background:
                grafanaIntegrationConfig.colourConfig?.secondaryColour,
            }}
          />
          <div className="flex-col">
            <div className="flex flex-row">
              <p className="text-sm-bold">{telemetryDashboard.name}</p>
              {telemetryDashboard.folder && (
                <p className="text-xs-med bg-surface-secondary border border-surface-secondary rounded px-1 ml-2 text-center content-center">
                  {telemetryDashboard.folder.replaceAll("/", "→")}
                </p>
              )}
            </div>
            <p className="text-xs-med text-content-tertiary">
              {telemetryDashboard.description
                ? telemetryDashboard.description
                : "No description"}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <Tooltip
            bubbleProps={{ className: "w-full" }}
            content="Deselect dashboard"
          >
            <Button
              icon={IconEnum.Delete}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModalOpen(true);
              }}
              title="Deselect dashboard"
              className="flex items-center justify-center w-7 h-7 bg-surface-secondary hover:bg-surface-tertiary rounded-1"
              size={BadgeSize.Medium}
              theme={ButtonTheme.Unstyled}
              analyticsTrackingId="deselect-dashboard"
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

const TelemetryDashboardEmptyState = () => {
  return (
    <EmptyState
      content={"No dashboards have been connected, add them above."}
    />
  );
};
