import { User } from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useClipboard } from "src/utils/useClipboard";

export const Value = {
  IDValue: ({
    value,
    explanation,
    href,
  }: {
    value: string;
    explanation?: string;
    href?: string;
  }) => {
    const { copyTextToClipboard } = useClipboard();
    const showToast = useToast();

    return (
      <div className="flex gap-2 items-center">
        {href ? (
          <Link
            href={href}
            analyticsTrackingId={"staff-room-id-link"}
            className="font-mono text-blue-500 no-underline hover:underline"
            openInNewTab
          >
            {value}
          </Link>
        ) : (
          <span className="font-mono">{value}</span>
        )}
        <Button
          theme={ButtonTheme.Naked}
          title="Copy to clipboard"
          analyticsTrackingId={"staff-room-id-link-copied"}
          icon={IconEnum.Copy}
          iconProps={{ size: IconSize.Small }}
          className="hover:text-content-primary text-content-tertiary"
          onClick={() => {
            copyTextToClipboard(value);
            showToast({
              title: "Copied to clipboard",
              theme: ToastTheme.Success,
            });
          }}
        />
        {explanation && (
          <Tooltip analyticsTrackingId={null} content={explanation} />
        )}
      </div>
    );
  },
  StringValue: ({ value }: { value: string }) => {
    return <span>{value}</span>;
  },
  DateTimeValue: ({ value, format }: { value: Date; format?: string }) => {
    return <LocalDateTime timestamp={value} format={format} />;
  },
  ListValue: ({ value }: { value: string[] }) => {
    return <span>{value.join(", ")}</span>;
  },
  NumericValue: ({ value }: { value: number }) => {
    return <span>{value.toLocaleString()}</span>;
  },
  DollarValue: ({ value }: { value: number }) => {
    return <span>${value.toLocaleString()}</span>;
  },
  UserValue: ({ value }: { value: User }) => {
    return (
      <div className="flex gap-1.5 items-center">
        <Avatar
          size={IconSize.Small}
          url={value.avatar_url}
          name={value.name}
        />
        <div>{value.name}</div>
      </div>
    );
  },
};
