/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WorkflowsCreateWorkflowRequestBody,
    WorkflowsCreateWorkflowRequestBodyFromJSON,
    WorkflowsCreateWorkflowRequestBodyToJSON,
    WorkflowsCreateWorkflowResponseBody,
    WorkflowsCreateWorkflowResponseBodyFromJSON,
    WorkflowsCreateWorkflowResponseBodyToJSON,
    WorkflowsCreateWorkflowRunRequestBody,
    WorkflowsCreateWorkflowRunRequestBodyFromJSON,
    WorkflowsCreateWorkflowRunRequestBodyToJSON,
    WorkflowsCreateWorkflowRunResponseBody,
    WorkflowsCreateWorkflowRunResponseBodyFromJSON,
    WorkflowsCreateWorkflowRunResponseBodyToJSON,
    WorkflowsDisableWorkflowResponseBody,
    WorkflowsDisableWorkflowResponseBodyFromJSON,
    WorkflowsDisableWorkflowResponseBodyToJSON,
    WorkflowsEnableWorkflowResponseBody,
    WorkflowsEnableWorkflowResponseBodyFromJSON,
    WorkflowsEnableWorkflowResponseBodyToJSON,
    WorkflowsEnqueueManualWorkflowRequestBody,
    WorkflowsEnqueueManualWorkflowRequestBodyFromJSON,
    WorkflowsEnqueueManualWorkflowRequestBodyToJSON,
    WorkflowsGenerateTerraformForWorkflowRequestBody,
    WorkflowsGenerateTerraformForWorkflowRequestBodyFromJSON,
    WorkflowsGenerateTerraformForWorkflowRequestBodyToJSON,
    WorkflowsGenerateTerraformForWorkflowResponseBody,
    WorkflowsGenerateTerraformForWorkflowResponseBodyFromJSON,
    WorkflowsGenerateTerraformForWorkflowResponseBodyToJSON,
    WorkflowsListStepsResponseBody,
    WorkflowsListStepsResponseBodyFromJSON,
    WorkflowsListStepsResponseBodyToJSON,
    WorkflowsListTriggersResponseBody,
    WorkflowsListTriggersResponseBodyFromJSON,
    WorkflowsListTriggersResponseBodyToJSON,
    WorkflowsListWorkflowRunsResponseBody,
    WorkflowsListWorkflowRunsResponseBodyFromJSON,
    WorkflowsListWorkflowRunsResponseBodyToJSON,
    WorkflowsListWorkflowTemplatesResponseBody,
    WorkflowsListWorkflowTemplatesResponseBodyFromJSON,
    WorkflowsListWorkflowTemplatesResponseBodyToJSON,
    WorkflowsListWorkflowsResponseBody,
    WorkflowsListWorkflowsResponseBodyFromJSON,
    WorkflowsListWorkflowsResponseBodyToJSON,
    WorkflowsShowStepResponseBody,
    WorkflowsShowStepResponseBodyFromJSON,
    WorkflowsShowStepResponseBodyToJSON,
    WorkflowsShowTriggerResponseBody,
    WorkflowsShowTriggerResponseBodyFromJSON,
    WorkflowsShowTriggerResponseBodyToJSON,
    WorkflowsShowWorkflowResponseBody,
    WorkflowsShowWorkflowResponseBodyFromJSON,
    WorkflowsShowWorkflowResponseBodyToJSON,
    WorkflowsShowWorkflowRunResponseBody,
    WorkflowsShowWorkflowRunResponseBodyFromJSON,
    WorkflowsShowWorkflowRunResponseBodyToJSON,
    WorkflowsShowWorkflowTemplateResponseBody,
    WorkflowsShowWorkflowTemplateResponseBodyFromJSON,
    WorkflowsShowWorkflowTemplateResponseBodyToJSON,
    WorkflowsUpdateWorkflowFolderRequestBody,
    WorkflowsUpdateWorkflowFolderRequestBodyFromJSON,
    WorkflowsUpdateWorkflowFolderRequestBodyToJSON,
    WorkflowsUpdateWorkflowFolderResponseBody,
    WorkflowsUpdateWorkflowFolderResponseBodyFromJSON,
    WorkflowsUpdateWorkflowFolderResponseBodyToJSON,
    WorkflowsUpdateWorkflowRequestBody,
    WorkflowsUpdateWorkflowRequestBodyFromJSON,
    WorkflowsUpdateWorkflowRequestBodyToJSON,
    WorkflowsUpdateWorkflowResponseBody,
    WorkflowsUpdateWorkflowResponseBodyFromJSON,
    WorkflowsUpdateWorkflowResponseBodyToJSON,
    WorkflowsValidateStepRequestBody,
    WorkflowsValidateStepRequestBodyFromJSON,
    WorkflowsValidateStepRequestBodyToJSON,
} from '../models';

export interface WorkflowsCreateWorkflowRequest {
    createWorkflowRequestBody: WorkflowsCreateWorkflowRequestBody;
}

export interface WorkflowsCreateWorkflowRunRequest {
    createWorkflowRunRequestBody: WorkflowsCreateWorkflowRunRequestBody;
}

export interface WorkflowsDestroyWorkflowRequest {
    id: string;
}

export interface WorkflowsDisableWorkflowRequest {
    id: string;
}

export interface WorkflowsEnableWorkflowRequest {
    id: string;
}

export interface WorkflowsEnqueueManualWorkflowRequest {
    enqueueManualWorkflowRequestBody: WorkflowsEnqueueManualWorkflowRequestBody;
}

export interface WorkflowsGenerateTerraformForWorkflowRequest {
    generateTerraformForWorkflowRequestBody: WorkflowsGenerateTerraformForWorkflowRequestBody;
}

export interface WorkflowsListWorkflowRunsRequest {
    workflowId: string;
}

export interface WorkflowsListWorkflowsRequest {
    incidentType?: string;
}

export interface WorkflowsShowStepRequest {
    trigger: string;
    name: string;
}

export interface WorkflowsShowTriggerRequest {
    name: string;
}

export interface WorkflowsShowWorkflowRequest {
    id: string;
    skipStepUpgrades?: boolean;
}

export interface WorkflowsShowWorkflowRunRequest {
    id: string;
}

export interface WorkflowsShowWorkflowTemplateRequest {
    name: string;
}

export interface WorkflowsUpdateWorkflowRequest {
    id: string;
    updateWorkflowRequestBody: WorkflowsUpdateWorkflowRequestBody;
}

export interface WorkflowsUpdateWorkflowFolderRequest {
    id: string;
    updateWorkflowFolderRequestBody: WorkflowsUpdateWorkflowFolderRequestBody;
}

export interface WorkflowsValidateStepRequest {
    validateStepRequestBody: WorkflowsValidateStepRequestBody;
}

/**
 * 
 */
export class WorkflowsApi extends runtime.BaseAPI {

    /**
     * Create a workflow
     * CreateWorkflow Workflows
     */
    async workflowsCreateWorkflowRaw(requestParameters: WorkflowsCreateWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsCreateWorkflowResponseBody>> {
        if (requestParameters.createWorkflowRequestBody === null || requestParameters.createWorkflowRequestBody === undefined) {
            throw new runtime.RequiredError('createWorkflowRequestBody','Required parameter requestParameters.createWorkflowRequestBody was null or undefined when calling workflowsCreateWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsCreateWorkflowRequestBodyToJSON(requestParameters.createWorkflowRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsCreateWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a workflow
     * CreateWorkflow Workflows
     */
    async workflowsCreateWorkflow(requestParameters: WorkflowsCreateWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsCreateWorkflowResponseBody> {
        const response = await this.workflowsCreateWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a workflow run for the purpose of testing
     * CreateWorkflowRun Workflows
     */
    async workflowsCreateWorkflowRunRaw(requestParameters: WorkflowsCreateWorkflowRunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsCreateWorkflowRunResponseBody>> {
        if (requestParameters.createWorkflowRunRequestBody === null || requestParameters.createWorkflowRunRequestBody === undefined) {
            throw new runtime.RequiredError('createWorkflowRunRequestBody','Required parameter requestParameters.createWorkflowRunRequestBody was null or undefined when calling workflowsCreateWorkflowRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/workflow_runs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsCreateWorkflowRunRequestBodyToJSON(requestParameters.createWorkflowRunRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsCreateWorkflowRunResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a workflow run for the purpose of testing
     * CreateWorkflowRun Workflows
     */
    async workflowsCreateWorkflowRun(requestParameters: WorkflowsCreateWorkflowRunRequest, initOverrides?: RequestInit): Promise<WorkflowsCreateWorkflowRunResponseBody> {
        const response = await this.workflowsCreateWorkflowRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a workflow
     * DestroyWorkflow Workflows
     */
    async workflowsDestroyWorkflowRaw(requestParameters: WorkflowsDestroyWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsDestroyWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a workflow
     * DestroyWorkflow Workflows
     */
    async workflowsDestroyWorkflow(requestParameters: WorkflowsDestroyWorkflowRequest, initOverrides?: RequestInit): Promise<void> {
        await this.workflowsDestroyWorkflowRaw(requestParameters, initOverrides);
    }

    /**
     * Diables a workflow for processing
     * DisableWorkflow Workflows
     */
    async workflowsDisableWorkflowRaw(requestParameters: WorkflowsDisableWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsDisableWorkflowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsDisableWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/{id}/actions/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsDisableWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Diables a workflow for processing
     * DisableWorkflow Workflows
     */
    async workflowsDisableWorkflow(requestParameters: WorkflowsDisableWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsDisableWorkflowResponseBody> {
        const response = await this.workflowsDisableWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enables a workflow for processing
     * EnableWorkflow Workflows
     */
    async workflowsEnableWorkflowRaw(requestParameters: WorkflowsEnableWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsEnableWorkflowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsEnableWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/{id}/actions/enable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsEnableWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enables a workflow for processing
     * EnableWorkflow Workflows
     */
    async workflowsEnableWorkflow(requestParameters: WorkflowsEnableWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsEnableWorkflowResponseBody> {
        const response = await this.workflowsEnableWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a manual workflow against an incident
     * EnqueueManualWorkflow Workflows
     */
    async workflowsEnqueueManualWorkflowRaw(requestParameters: WorkflowsEnqueueManualWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.enqueueManualWorkflowRequestBody === null || requestParameters.enqueueManualWorkflowRequestBody === undefined) {
            throw new runtime.RequiredError('enqueueManualWorkflowRequestBody','Required parameter requestParameters.enqueueManualWorkflowRequestBody was null or undefined when calling workflowsEnqueueManualWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/manual_workflow_runs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsEnqueueManualWorkflowRequestBodyToJSON(requestParameters.enqueueManualWorkflowRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Run a manual workflow against an incident
     * EnqueueManualWorkflow Workflows
     */
    async workflowsEnqueueManualWorkflow(requestParameters: WorkflowsEnqueueManualWorkflowRequest, initOverrides?: RequestInit): Promise<void> {
        await this.workflowsEnqueueManualWorkflowRaw(requestParameters, initOverrides);
    }

    /**
     * Generates a Terraform code snippet describing given workflow payload
     * GenerateTerraformForWorkflow Workflows
     */
    async workflowsGenerateTerraformForWorkflowRaw(requestParameters: WorkflowsGenerateTerraformForWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsGenerateTerraformForWorkflowResponseBody>> {
        if (requestParameters.generateTerraformForWorkflowRequestBody === null || requestParameters.generateTerraformForWorkflowRequestBody === undefined) {
            throw new runtime.RequiredError('generateTerraformForWorkflowRequestBody','Required parameter requestParameters.generateTerraformForWorkflowRequestBody was null or undefined when calling workflowsGenerateTerraformForWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/actions/generate_terraform`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsGenerateTerraformForWorkflowRequestBodyToJSON(requestParameters.generateTerraformForWorkflowRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsGenerateTerraformForWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generates a Terraform code snippet describing given workflow payload
     * GenerateTerraformForWorkflow Workflows
     */
    async workflowsGenerateTerraformForWorkflow(requestParameters: WorkflowsGenerateTerraformForWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsGenerateTerraformForWorkflowResponseBody> {
        const response = await this.workflowsGenerateTerraformForWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available steps
     * ListSteps Workflows
     */
    async workflowsListStepsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsListStepsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/steps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsListStepsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available steps
     * ListSteps Workflows
     */
    async workflowsListSteps(initOverrides?: RequestInit): Promise<WorkflowsListStepsResponseBody> {
        const response = await this.workflowsListStepsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all available triggers
     * ListTriggers Workflows
     */
    async workflowsListTriggersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsListTriggersResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/triggers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsListTriggersResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available triggers
     * ListTriggers Workflows
     */
    async workflowsListTriggers(initOverrides?: RequestInit): Promise<WorkflowsListTriggersResponseBody> {
        const response = await this.workflowsListTriggersRaw(initOverrides);
        return await response.value();
    }

    /**
     * List workflow runs by workflow ID
     * ListWorkflowRuns Workflows
     */
    async workflowsListWorkflowRunsRaw(requestParameters: WorkflowsListWorkflowRunsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsListWorkflowRunsResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling workflowsListWorkflowRuns.');
        }

        const queryParameters: any = {};

        if (requestParameters.workflowId !== undefined) {
            queryParameters['workflow_id'] = requestParameters.workflowId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/workflow_runs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsListWorkflowRunsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List workflow runs by workflow ID
     * ListWorkflowRuns Workflows
     */
    async workflowsListWorkflowRuns(requestParameters: WorkflowsListWorkflowRunsRequest, initOverrides?: RequestInit): Promise<WorkflowsListWorkflowRunsResponseBody> {
        const response = await this.workflowsListWorkflowRunsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available workflow templates
     * ListWorkflowTemplates Workflows
     */
    async workflowsListWorkflowTemplatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsListWorkflowTemplatesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/workflow_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsListWorkflowTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available workflow templates
     * ListWorkflowTemplates Workflows
     */
    async workflowsListWorkflowTemplates(initOverrides?: RequestInit): Promise<WorkflowsListWorkflowTemplatesResponseBody> {
        const response = await this.workflowsListWorkflowTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all workflows
     * ListWorkflows Workflows
     */
    async workflowsListWorkflowsRaw(requestParameters: WorkflowsListWorkflowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsListWorkflowsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentType !== undefined) {
            queryParameters['incident_type'] = requestParameters.incidentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsListWorkflowsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all workflows
     * ListWorkflows Workflows
     */
    async workflowsListWorkflows(requestParameters: WorkflowsListWorkflowsRequest, initOverrides?: RequestInit): Promise<WorkflowsListWorkflowsResponseBody> {
        const response = await this.workflowsListWorkflowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a specific step, by the name of the step
     * ShowStep Workflows
     */
    async workflowsShowStepRaw(requestParameters: WorkflowsShowStepRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsShowStepResponseBody>> {
        if (requestParameters.trigger === null || requestParameters.trigger === undefined) {
            throw new runtime.RequiredError('trigger','Required parameter requestParameters.trigger was null or undefined when calling workflowsShowStep.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling workflowsShowStep.');
        }

        const queryParameters: any = {};

        if (requestParameters.trigger !== undefined) {
            queryParameters['trigger'] = requestParameters.trigger;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/steps/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsShowStepResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets a specific step, by the name of the step
     * ShowStep Workflows
     */
    async workflowsShowStep(requestParameters: WorkflowsShowStepRequest, initOverrides?: RequestInit): Promise<WorkflowsShowStepResponseBody> {
        const response = await this.workflowsShowStepRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a specific trigger, by the name of the trigger
     * ShowTrigger Workflows
     */
    async workflowsShowTriggerRaw(requestParameters: WorkflowsShowTriggerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsShowTriggerResponseBody>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling workflowsShowTrigger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/triggers/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsShowTriggerResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets a specific trigger, by the name of the trigger
     * ShowTrigger Workflows
     */
    async workflowsShowTrigger(requestParameters: WorkflowsShowTriggerRequest, initOverrides?: RequestInit): Promise<WorkflowsShowTriggerResponseBody> {
        const response = await this.workflowsShowTriggerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a workflow by ID
     * ShowWorkflow Workflows
     */
    async workflowsShowWorkflowRaw(requestParameters: WorkflowsShowWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsShowWorkflowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsShowWorkflow.');
        }

        const queryParameters: any = {};

        if (requestParameters.skipStepUpgrades !== undefined) {
            queryParameters['skip_step_upgrades'] = requestParameters.skipStepUpgrades;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsShowWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a workflow by ID
     * ShowWorkflow Workflows
     */
    async workflowsShowWorkflow(requestParameters: WorkflowsShowWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsShowWorkflowResponseBody> {
        const response = await this.workflowsShowWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a workflow run by ID
     * ShowWorkflowRun Workflows
     */
    async workflowsShowWorkflowRunRaw(requestParameters: WorkflowsShowWorkflowRunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsShowWorkflowRunResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsShowWorkflowRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/workflow_runs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsShowWorkflowRunResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a workflow run by ID
     * ShowWorkflowRun Workflows
     */
    async workflowsShowWorkflowRun(requestParameters: WorkflowsShowWorkflowRunRequest, initOverrides?: RequestInit): Promise<WorkflowsShowWorkflowRunResponseBody> {
        const response = await this.workflowsShowWorkflowRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a workflow template
     * ShowWorkflowTemplate Workflows
     */
    async workflowsShowWorkflowTemplateRaw(requestParameters: WorkflowsShowWorkflowTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsShowWorkflowTemplateResponseBody>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling workflowsShowWorkflowTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workflows/workflow_templates/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsShowWorkflowTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a workflow template
     * ShowWorkflowTemplate Workflows
     */
    async workflowsShowWorkflowTemplate(requestParameters: WorkflowsShowWorkflowTemplateRequest, initOverrides?: RequestInit): Promise<WorkflowsShowWorkflowTemplateResponseBody> {
        const response = await this.workflowsShowWorkflowTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a workflow
     * UpdateWorkflow Workflows
     */
    async workflowsUpdateWorkflowRaw(requestParameters: WorkflowsUpdateWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsUpdateWorkflowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsUpdateWorkflow.');
        }

        if (requestParameters.updateWorkflowRequestBody === null || requestParameters.updateWorkflowRequestBody === undefined) {
            throw new runtime.RequiredError('updateWorkflowRequestBody','Required parameter requestParameters.updateWorkflowRequestBody was null or undefined when calling workflowsUpdateWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsUpdateWorkflowRequestBodyToJSON(requestParameters.updateWorkflowRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsUpdateWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a workflow
     * UpdateWorkflow Workflows
     */
    async workflowsUpdateWorkflow(requestParameters: WorkflowsUpdateWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowsUpdateWorkflowResponseBody> {
        const response = await this.workflowsUpdateWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Moves the workflow to the given folder
     * UpdateWorkflowFolder Workflows
     */
    async workflowsUpdateWorkflowFolderRaw(requestParameters: WorkflowsUpdateWorkflowFolderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowsUpdateWorkflowFolderResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workflowsUpdateWorkflowFolder.');
        }

        if (requestParameters.updateWorkflowFolderRequestBody === null || requestParameters.updateWorkflowFolderRequestBody === undefined) {
            throw new runtime.RequiredError('updateWorkflowFolderRequestBody','Required parameter requestParameters.updateWorkflowFolderRequestBody was null or undefined when calling workflowsUpdateWorkflowFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/{id}/actions/folder`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsUpdateWorkflowFolderRequestBodyToJSON(requestParameters.updateWorkflowFolderRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowsUpdateWorkflowFolderResponseBodyFromJSON(jsonValue));
    }

    /**
     * Moves the workflow to the given folder
     * UpdateWorkflowFolder Workflows
     */
    async workflowsUpdateWorkflowFolder(requestParameters: WorkflowsUpdateWorkflowFolderRequest, initOverrides?: RequestInit): Promise<WorkflowsUpdateWorkflowFolderResponseBody> {
        const response = await this.workflowsUpdateWorkflowFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validates a workflow step configuration
     * ValidateStep Workflows
     */
    async workflowsValidateStepRaw(requestParameters: WorkflowsValidateStepRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.validateStepRequestBody === null || requestParameters.validateStepRequestBody === undefined) {
            throw new runtime.RequiredError('validateStepRequestBody','Required parameter requestParameters.validateStepRequestBody was null or undefined when calling workflowsValidateStep.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workflows/steps/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowsValidateStepRequestBodyToJSON(requestParameters.validateStepRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validates a workflow step configuration
     * ValidateStep Workflows
     */
    async workflowsValidateStep(requestParameters: WorkflowsValidateStepRequest, initOverrides?: RequestInit): Promise<void> {
        await this.workflowsValidateStepRaw(requestParameters, initOverrides);
    }

}
