import {
  AlertAttribute,
  CatalogResource,
  CatalogType,
  CustomField,
  CustomFieldFieldTypeEnum,
  TeamsConvertRequestBodyMembersSourceOfTruthEnum,
} from "@incident-io/api";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import {
  PopoverSelectOption,
  PopoverSelectOptions,
} from "@incident-ui/PopoverSelect";
import { UseFormReturn } from "react-hook-form";

import { AttributeButton } from "../../../catalog/wizards/CatalogWizardAddAttributesStep";
import { SourceOfTruth } from "../../../catalog/wizards/team-wizard/TeamWizardChooseSourceOfTruthStep";
import { TeamsGetStartedFormData } from "./TeamsGetStartedDrawer";

export type CatalogTeamOption = PopoverSelectOption & {
  resource: CatalogResource;
};

/**
 * Create default form values, prioritizing team catalog types with member and escalation path attributes
 */
export const makeDefaults = (
  teamCatalogTypes: CatalogType[],
  customFields: CustomField[],
  alertAttributes: AlertAttribute[],
  hasOnCall: boolean,
  hasResponse: boolean,
) => {
  // Find the first team catalog type that has both members and escalation path attributes
  const teamTypeWithBoth = teamCatalogTypes.find(
    (type) =>
      type.schema.attributes.some(
        (attr) => attr.type === "User" && attr.array,
      ) &&
      type.schema.attributes.some((attr) => attr.type === "EscalationPath"),
  );

  // If we find a type with both attributes, prioritize it
  if (teamTypeWithBoth) {
    return createFormData(
      teamTypeWithBoth,
      customFields,
      alertAttributes,
      hasOnCall,
      hasResponse,
    );
  }

  // Next, find a team catalog type that has just members attributes
  const teamTypeWithMembers = teamCatalogTypes.find((type) =>
    type.schema.attributes.some((attr) => attr.type === "User" && attr.array),
  );

  if (teamTypeWithMembers) {
    return createFormData(
      teamTypeWithMembers,
      customFields,
      alertAttributes,
      hasOnCall,
      hasResponse,
    );
  }

  // Next, find a team catalog type that has just escalation path attributes
  const teamTypeWithEscalationPaths = teamCatalogTypes.find((type) =>
    type.schema.attributes.some((attr) => attr.type === "EscalationPath"),
  );

  if (teamTypeWithEscalationPaths) {
    return createFormData(
      teamTypeWithEscalationPaths,
      customFields,
      alertAttributes,
      hasOnCall,
      hasResponse,
    );
  }

  // Fallback to first team catalog type with neither attribute
  return {
    catalog_type_id: teamCatalogTypes.length > 0 ? teamCatalogTypes[0].id : "",
    members_create_new: true,
    escalation_paths_create_new: hasOnCall,
    escalation_paths_attribute_id: undefined,
  };
};

const createFormData = (
  teamType: CatalogType,
  customFields: CustomField[],
  alertAttributes: AlertAttribute[],
  hasOnCall: boolean,
  hasReponse: boolean,
): TeamsGetStartedFormData => {
  const membersAttributeId =
    teamType.schema.attributes.find(
      (attr) => attr.type === "User" && attr.array,
    )?.id || "";

  // Get the custom fields matching the team type, order them with multi-selects first
  // then choose the first one
  const customField = customFields
    .filter((field) => field.catalog_type_id === teamType.id)
    .sort(
      (a, b) =>
        (a.field_type === CustomFieldFieldTypeEnum.MultiSelect ? 0 : 1) -
        (b.field_type === CustomFieldFieldTypeEnum.MultiSelect ? 0 : 1),
    )[0];

  // Get alert attributes matching the team type, order them with multi-selects first then
  // choose the first one
  const alertAttribute = alertAttributes
    .filter((attr) => attr.type === `CatalogEntry["${teamType.id}"]`)
    .sort((a, b) => (a.array ? 0 : 1) - (b.array ? 0 : 1))[0];

  if (!hasOnCall) {
    return {
      catalog_type_id: teamType.id,
      members_create_new: !membersAttributeId,
      members_attribute_id: membersAttributeId,
      custom_field_id: customField?.id,
      custom_field_create_new: !customField,
      alert_attribute_id: alertAttribute?.id,
      alert_attribute_create_new: !alertAttribute,

      // Never set these if you don't have on-call!
      escalation_paths_create_new: false,
      escalation_paths_attribute_id: undefined,
    };
  }

  const escalationPathAttributeId =
    teamType.schema.attributes.find((attr) => attr.type === "EscalationPath")
      ?.id || "";

  if (!hasReponse) {
    return {
      catalog_type_id: teamType.id,
      members_create_new: !membersAttributeId,
      members_attribute_id: membersAttributeId,
      escalation_paths_create_new: !escalationPathAttributeId,
      escalation_paths_attribute_id: escalationPathAttributeId,
      alert_attribute_id: alertAttribute?.id,
      alert_attribute_create_new: !alertAttribute,

      // Never set these if you don't have response!
      custom_field_id: undefined,
      custom_field_create_new: false,
    };
  }

  return {
    catalog_type_id: teamType.id,
    members_create_new: !membersAttributeId,
    members_attribute_id: membersAttributeId,
    escalation_paths_create_new: !escalationPathAttributeId,
    escalation_paths_attribute_id: escalationPathAttributeId,
    custom_field_id: customField?.id,
    custom_field_create_new: !customField,
    alert_attribute_id: alertAttribute?.id,
    alert_attribute_create_new: !alertAttribute,
  };
};

/**
 * Builds attribute buttons for team member management options
 */
export const buildPossibleMemberAttributeButtons = (
  userSourceOfTruths: SourceOfTruth[],
  formMethods: UseFormReturn<TeamsGetStartedFormData>,
  setSelectedNewAttributeLabel: (label: string) => void,
): AttributeButton[] => {
  const buttons: AttributeButton[] = [];

  // Add buttons for each integrated source of truth
  userSourceOfTruths.forEach((truth) => {
    if (
      truth.disabled ||
      !truth.integration_installed ||
      truth.enterprise_upsell
    ) {
      return;
    }
    const integrationConfig = IntegrationConfigFor(truth.integration_provider);
    buttons.push({
      type: truth.resource_type,
      label: truth.resource_type_label,
      icon: integrationConfig.icon,
      onClickHandler: () => {
        formMethods.setValue("members_attribute_id", undefined);
        formMethods.setValue(
          "members_source_of_truth",
          truth.resource_type as TeamsConvertRequestBodyMembersSourceOfTruthEnum,
        );
        setSelectedNewAttributeLabel(truth.resource_type_label);
      },
      existing: false,
    });
  });

  // Add "Manage in incident.io" option
  buttons.push({
    type: "User",
    label: "Manage in incident.io",
    icon: IconEnum.UserAdd,
    color: ColorPaletteEnum.Slate,
    onClickHandler: () => {
      formMethods.setValue("members_attribute_id", undefined);
      formMethods.setValue(
        "members_source_of_truth",
        TeamsConvertRequestBodyMembersSourceOfTruthEnum.User,
      );
      setSelectedNewAttributeLabel("Manage in incident.io");
    },
    existing: false,
  });

  return buttons;
};

/**
 * Builds team type options for the select dropdown
 */
export const buildTeamTypeOptions = (
  catalogTypes: CatalogType[],
  resources: CatalogResource[],
): PopoverSelectOptions<CatalogTeamOption> => {
  // CatalogTypes to resource map
  const catalogTypeToResourceMap = resources.reduce(
    (acc, resource) => {
      acc[resource.type] = resource;
      return acc;
    },
    {} as Record<string, CatalogResource>,
  );
  return catalogTypes.map((catalogType) => {
    return {
      label: catalogType.name,
      value: catalogType.id,
      resource: catalogTypeToResourceMap[catalogType.type_name],
    };
  });
};
