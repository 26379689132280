import "react-phone-number-input/style.css";

import {
  OnCallNotificationMethod,
  OnCallNotificationMethodPhoneNumberStatusEnum,
  OnCallNotificationMethodV2MethodTypeEnum,
  OnCallNotificationMethodV2StatusEnum,
} from "@incident-io/api";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { parsePhoneNumber } from "libphonenumber-js";
import { useMemo, useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { Identity } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { useRevalidate } from "../../../utils/use-revalidate";
import { AddContactMethodModal } from "./AddContactMethodModal";
import { ContactMethod } from "./ContactMethod";
import { InstallAppModal } from "./InstallAppModal";
import { OnCallNotificationBox } from "./OnCallNotificationsBox";
import { Method } from "./OnCallNotificationsPage";
import { RemoveMobileAppModal } from "./OnCallNotificationsRemoveMobileAppModal";
import { TestContactMethodModal } from "./OnCallTestNotificationRuleModal";
import { VerifyNotificationMethodModal } from "./OnCallVerifyNotificationRuleModal";

export const MethodUnavailableReasons = {
  ["not-installed"]: "Not installed",
  ["push-notifications-not-enabled"]: "Push notifications disabled",
  ["has-no-email"]: "No email",
  ["phone-number-blocked"]: "Phone number blocked",
};

export type MethodDisplayItem = {
  methodId: string | undefined;
  type: OnCallNotificationMethodV2MethodTypeEnum;
  title: string;
  value: string;
  icon: IconEnum;
  unavailable_reason?: keyof typeof MethodUnavailableReasons;
  lastUsedAt?: Date;
  mobileKeyID?: string;
};

export const makeMethodDisplayItem = (
  identity: Identity | null,
  method: Method,
): MethodDisplayItem => {
  switch (method.method_type) {
    case OnCallNotificationMethodV2MethodTypeEnum.App:
      const mobileKey = method.app;
      return {
        methodId: undefined, // You can't delete the "app" method, but you can delete individual mobile keys
        type: OnCallNotificationMethodV2MethodTypeEnum.App,
        title: "Push",
        value: mobileKey?.device_name ?? "",
        unavailable_reason: !mobileKey
          ? "not-installed"
          : !mobileKey?.push_notifications_enabled
          ? "push-notifications-not-enabled"
          : undefined,
        icon: IconEnum.Vibrate,
        lastUsedAt: mobileKey?.last_used_at,
        mobileKeyID: mobileKey?.id,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Email:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Email,
        methodId: method.email_method?.email_address_is_user
          ? undefined
          : method.email_method?.id, // You can't delete the default "email" method, we make it for you and use your email from your user object
        title: "Email",
        value: method.email ?? "",
        unavailable_reason: !identity?.user_email ? "has-no-email" : undefined,
        icon: IconEnum.Email,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Phone:
      const phoneData: OnCallNotificationMethod | undefined = method.phone;
      if (!phoneData) {
        throw new Error("Phone method is missing phone data");
      }
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Phone,
        methodId: phoneData.id,
        title: "Phone",
        value:
          parsePhoneNumber(
            phoneData.phone_number ?? "",
          ).formatInternational() || "",
        unavailable_reason:
          phoneData.phone_number != null &&
          phoneData.phone_number_status ===
            OnCallNotificationMethodPhoneNumberStatusEnum.Blocked
            ? "phone-number-blocked"
            : undefined,
        icon: IconEnum.Call,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Slack:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Slack,
        methodId: undefined, // You can't remove Slack manually
        title: "Slack",
        value: identity?.user_name ?? "",
        icon: IconEnum.Slack,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams,
        methodId: undefined, // You can't remove Teams manually
        title: "Teams",
        value: identity?.user_name ?? "",
        icon: IconEnum.MicrosoftTeams,
      };
    default:
      assertUnreachable(method.method_type);
      return {
        methodId: undefined,
        type: method.method_type,
        title: "",
        value: "",
        icon: IconEnum.Email,
      };
  }
};

export const ContactMethods = ({
  methods,
  showAddMethodModal,
  setShowAddMethodModal,
}: {
  methods: Method[];
  showAddMethodModal: { show: boolean; allowEmail: boolean };
  setShowAddMethodModal: ({
    show,
    allowEmail,
  }: {
    show: boolean;
    allowEmail: boolean;
  }) => void;
}) => {
  const { featureAdditionalEscalationEmails } = useFlags();
  const [showVerifyMethodModal, setShowVerifyMethodModal] =
    useState<OnCallNotificationMethod | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<
    false | string
  >(false);
  const [showRemoveMobileAppModal, setShowRemoveMobileAppModal] = useState<
    false | string
  >(false);
  const [showInstallAppModal, setShowInstallAppModal] = useState(false);

  const [testModalMethod, setTestModalMethod] = useState<
    MethodDisplayItem | undefined
  >(undefined);

  const revalidateNotificationMethods = useRevalidate([
    "onCallNotificationsListMethodsV2",
    "onCallOnboardingCurrentOnboardingState",
  ]);

  const { trigger: onDelete, isMutating: isDestroying } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (apiClient, req: { id: string }) => {
      await apiClient.onCallNotificationsDestroyMethod(req);
    },
    {
      onSuccess: () => setShowConfirmDeleteModal(false),
    },
  );

  const allMethods = useMemo(() => {
    // Create a dummy app method if none exists
    const hasAppInstalled = methods.some(
      (m) => m.method_type === OnCallNotificationMethodV2MethodTypeEnum.App,
    );

    return hasAppInstalled
      ? [...methods] // Make a copy of these so we don't mutate the original, which makes the UI show weirdly
      : [
          ...methods,
          {
            method_type: OnCallNotificationMethodV2MethodTypeEnum.App,
            status: OnCallNotificationMethodV2StatusEnum.Verified,
          },
        ];
  }, [methods]);

  return (
    <OnCallNotificationBox
      title={"Contact methods"}
      description={"Tell us how you'd like to be reached."}
      className={"items-start"}
      button={
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Add}
          analyticsTrackingId={"add-on-call-notification-method"}
          onClick={() =>
            setShowAddMethodModal({
              show: true,
              // if the user has the feature flag, allow them to add an email method
              allowEmail: featureAdditionalEscalationEmails,
            })
          }
        >
          Add method
        </Button>
      }
    >
      <div
        className={tcx(
          "w-full flex bg-white flex-col border border-stroke rounded-2",
          {
            "border-0": allMethods.length === 0,
          },
        )}
      >
        {allMethods.map((m) => (
          <ContactMethod
            key={m.method_type}
            method={m}
            onVerify={(method) => setShowVerifyMethodModal(method)}
            onDelete={(methodId) => setShowConfirmDeleteModal(methodId)}
            onRemoveMobileApp={(mobileKeyId) =>
              setShowRemoveMobileAppModal(mobileKeyId)
            }
            onTest={(method) => setTestModalMethod(method)}
            onInstall={() => setShowInstallAppModal(true)}
          />
        ))}
      </div>
      {showAddMethodModal.show && (
        <AddContactMethodModal
          onClose={() =>
            setShowAddMethodModal({
              show: false,
              allowEmail: false,
            })
          }
          allowEmail={showAddMethodModal.allowEmail}
          showVerificationModal={(method) => {
            setShowAddMethodModal({
              show: false,
              allowEmail: false,
            });
            setShowVerifyMethodModal(method);
          }}
        />
      )}
      {showVerifyMethodModal && showVerifyMethodModal.id && (
        <VerifyNotificationMethodModal
          onClose={() => setShowVerifyMethodModal(null)}
          method={showVerifyMethodModal as unknown as OnCallNotificationMethod}
        />
      )}
      {showConfirmDeleteModal && (
        <DeletionConfirmationModal
          resourceTitle={"Notification method"}
          onDelete={async () => {
            await onDelete({ id: showConfirmDeleteModal });
            revalidateNotificationMethods();
          }}
          isDeleting={isDestroying}
          isOpen={true}
          onClose={() => {
            revalidateNotificationMethods();
            setShowConfirmDeleteModal(false);
          }}
          title="Remove method"
          deleteConfirmationContent={
            "Are you sure you want to remove this notification method?"
          }
          analyticsTrackingId="delete-notification-rule"
        />
      )}
      {testModalMethod && (
        <TestContactMethodModal
          onClose={() => setTestModalMethod(undefined)}
          selectedMethod={testModalMethod}
        />
      )}
      {showRemoveMobileAppModal && (
        <RemoveMobileAppModal
          onClose={() => {
            revalidateNotificationMethods();
            setShowRemoveMobileAppModal(false);
          }}
          mobileKeyID={showRemoveMobileAppModal}
        />
      )}
      {showInstallAppModal && (
        <InstallAppModal onClose={() => setShowInstallAppModal(false)} />
      )}
    </OnCallNotificationBox>
  );
};
