/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelemetryDeselectDashboardRequestBody
 */
export interface TelemetryDeselectDashboardRequestBody {
    /**
     * The ID of the dashboard to deselect
     * @type {string}
     * @memberof TelemetryDeselectDashboardRequestBody
     */
    dashboard_id: string;
    /**
     * The provider of the dashboard
     * @type {string}
     * @memberof TelemetryDeselectDashboardRequestBody
     */
    provider: TelemetryDeselectDashboardRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum TelemetryDeselectDashboardRequestBodyProviderEnum {
    Grafana = 'grafana'
}

export function TelemetryDeselectDashboardRequestBodyFromJSON(json: any): TelemetryDeselectDashboardRequestBody {
    return TelemetryDeselectDashboardRequestBodyFromJSONTyped(json, false);
}

export function TelemetryDeselectDashboardRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryDeselectDashboardRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard_id': json['dashboard_id'],
        'provider': json['provider'],
    };
}

export function TelemetryDeselectDashboardRequestBodyToJSON(value?: TelemetryDeselectDashboardRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard_id': value.dashboard_id,
        'provider': value.provider,
    };
}

