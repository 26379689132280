import { Route, Routes } from "react-router-dom";

import { SearchGroundTruthsListPage } from "./SearchGroundTruthsListPage";
import { SearchGroundTruthsShowPage } from "./SearchGroundTruthsShowPage";

export const SearchGroundTruthsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchGroundTruthsListPage />} />
      <Route path="/:id" element={<SearchGroundTruthsShowPage />} />
    </Routes>
  );
};
