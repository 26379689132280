/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppleAppSiteBundleReference
 */
export interface AppleAppSiteBundleReference {
    /**
     * A list of bundle IDs
     * @type {Array<string>}
     * @memberof AppleAppSiteBundleReference
     */
    apps: Array<string>;
}

export function AppleAppSiteBundleReferenceFromJSON(json: any): AppleAppSiteBundleReference {
    return AppleAppSiteBundleReferenceFromJSONTyped(json, false);
}

export function AppleAppSiteBundleReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppleAppSiteBundleReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': json['apps'],
    };
}

export function AppleAppSiteBundleReferenceToJSON(value?: AppleAppSiteBundleReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': value.apps,
    };
}

