import {
  AIStaffCreateIncidentGroundTruthRequestBody,
  AIStaffUpdateIncidentGroundTruthRequestBody,
  IncidentGroundTruth,
  IncidentGroundTruthCodeChange,
  IncidentGroundTruthRelatedIncident,
  IncidentGroundTruthRelatedIncidentPayload,
  IncidentGroundTruthRelatedMessage,
  IncidentGroundTruthTelemetryDashboard,
} from "@incident-io/api";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { GroundTruthCodeChangesSection } from "./GroundTruthCodeChangeSection";
import { GroundTruthInvestigationSection } from "./GroundTruthInvestigationSection";
import { GroundTruthRelatedIncidentsSection } from "./GroundTruthRelatedIncidentsSection";
import { GroundTruthRelatedMessagesSection } from "./GroundTruthRelatedMessagesSection";
import { GroundTruthTelemetryDashboardsSection } from "./GroundTruthTelemetryDashboardsSection";

// Helper function to convert Date to ISO string or undefined
const formatVerifiedAt = (date?: Date): string | undefined => {
  return date ? date.toISOString() : undefined;
};

// Function to convert related incidents to payload format
const marshalRelatedIncidentToPayload = (
  incident: IncidentGroundTruthRelatedIncident,
): IncidentGroundTruthRelatedIncidentPayload => {
  return {
    incident_id: incident.incident.id,
    relevance: incident.relevance,
    reason: incident.reason,
  };
};

// Now let's update our buildUpdatePayload function
const buildUpdatePayload = (
  gt: IncidentGroundTruth,
): AIStaffUpdateIncidentGroundTruthRequestBody => {
  return {
    causing_code_changes: gt.causing_code_changes,
    causing_code_changes_verified_at: formatVerifiedAt(
      gt.causing_code_changes_verified_at,
    ),

    investigation_relevance: gt.investigation_relevance,
    investigation_relevance_reasoning: gt.investigation_relevance_reasoning,
    investigation_relevance_tags: gt.investigation_relevance_tags,
    investigation_relevance_verified_at: formatVerifiedAt(
      gt.investigation_relevance_verified_at,
    ),

    related_incidents: gt.related_incidents
      ? gt.related_incidents.map(marshalRelatedIncidentToPayload)
      : undefined,
    related_incidents_next_steps: gt.related_incidents_next_steps,
    related_incidents_verified_at: formatVerifiedAt(
      gt.related_incidents_verified_at,
    ),

    related_messages: gt.related_messages,
    related_messages_verified_at: formatVerifiedAt(
      gt.related_messages_verified_at,
    ),

    related_telemetry_dashboards: gt.related_telemetry_dashboards,
    related_telemetry_dashboards_verified_at: formatVerifiedAt(
      gt.related_telemetry_dashboards_verified_at,
    ),
  };
};

export const useGroundTruth = (
  incidentId: string | null,
  includeMetadata?: boolean,
) => {
  const toast = useToast();

  // Queries
  const { data, isLoading: isLoadingGroundTruths } = useAPI(
    "aIStaffListIncidentGroundTruths",
    {
      incidentId: incidentId || "",
      includeMetadata: includeMetadata || false,
    },
    {
      fallbackData: { ground_truths: [] },
    },
  );
  const groundTruth = data?.ground_truths[0] as IncidentGroundTruth;

  // Mutations
  const { trigger: generateGroundTruth, isMutating: isCreating } =
    useMutationV2(
      async (apiClient, data: AIStaffCreateIncidentGroundTruthRequestBody) => {
        await apiClient.aIStaffCreateIncidentGroundTruth({
          createIncidentGroundTruthRequestBody: data,
        });
        // Return void to trigger a refetch
        return;
      },
      {
        invalidate: [
          cacheKey.exactly("aIStaffListIncidentGroundTruths", {
            incidentId: incidentId || "",
            includeMetadata: includeMetadata || false,
          }),
        ],

        onError: () => {
          toast({
            title: "Failed to generate ground truth",
            theme: ToastTheme.Error,
          });
        },
      },
    );

  const { trigger: updateGroundTruth, isMutating: isUpdating } = useMutationV2(
    async (apiClient, data: AIStaffUpdateIncidentGroundTruthRequestBody) => {
      await apiClient.aIStaffUpdateIncidentGroundTruth({
        id: groundTruth.id as string,
        updateIncidentGroundTruthRequestBody: data,
      });
      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffListIncidentGroundTruths", {
          incidentId: incidentId || "",
          includeMetadata: includeMetadata || false,
        }),
      ],

      onError: () => {
        toast({
          title: "Failed to update ground truth",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  // Handlers
  const handleGenerate = () => {
    if (!incidentId) return;

    generateGroundTruth({
      incident_id: incidentId,
      force: true, // always bypass the cache
    });
  };

  const handleSaveCodeChanges = async (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      ...buildUpdatePayload(groundTruth),
      causing_code_changes: changes,
      causing_code_changes_verified_at: isVerified
        ? new Date().toISOString()
        : "",
    });
  };

  const handleSaveInvestigation = async (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      ...buildUpdatePayload(groundTruth),
      investigation_relevance: relevance,
      investigation_relevance_tags: tags,
      investigation_relevance_reasoning: reasoning,
      investigation_relevance_verified_at: isVerified
        ? new Date().toISOString()
        : "",
    });
  };

  const handleSaveRelatedMessages = async (
    messages: IncidentGroundTruthRelatedMessage[],
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      ...buildUpdatePayload(groundTruth),
      related_messages: messages,
      related_messages_verified_at: isVerified ? new Date().toISOString() : "",
    });
  };

  const handleSaveRelatedIncidents = async (
    relatedIncidents: IncidentGroundTruthRelatedIncidentPayload[],
    nextSteps: string,
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      ...buildUpdatePayload(groundTruth),
      related_incidents: relatedIncidents,
      related_incidents_next_steps: nextSteps,
      related_incidents_verified_at: isVerified ? new Date().toISOString() : "",
    });
  };

  const handleSaveTelemetryDashboards = async (
    dashboards: IncidentGroundTruthTelemetryDashboard[],
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      ...buildUpdatePayload(groundTruth),
      related_telemetry_dashboards: dashboards,
      related_telemetry_dashboards_verified_at: isVerified
        ? new Date().toISOString()
        : "",
    });
  };

  // We should add more of these as we add more ground truths.
  let requiresVerification = false;
  if (groundTruth) {
    requiresVerification = !groundTruth.causing_code_changes_verified_at;
  }

  return {
    groundTruth,
    requiresVerification,
    isLoading: isLoadingGroundTruths || isCreating || isUpdating,
    handleGenerate,
    handleSaveCodeChanges,
    handleSaveInvestigation,
    handleSaveRelatedMessages,
    handleSaveRelatedIncidents,
    handleSaveTelemetryDashboards,
  };
};

interface GroundTruthSectionControllerProps {
  incidentId: string;
  includeMetadata?: boolean;
  className?: string;
}

export const GroundTruthSectionController = ({
  incidentId,
  includeMetadata,
  className,
}: GroundTruthSectionControllerProps) => {
  const {
    groundTruth,
    isLoading,
    handleGenerate,
    handleSaveCodeChanges,
    handleSaveInvestigation,
    handleSaveRelatedMessages,
    handleSaveRelatedIncidents,
    handleSaveTelemetryDashboards,
  } = useGroundTruth(incidentId, includeMetadata);

  return (
    <GroundTruthSection
      groundTruth={groundTruth}
      className={className}
      onGenerateGroundTruth={handleGenerate}
      onSaveCodeChanges={handleSaveCodeChanges}
      onSaveInvestigation={handleSaveInvestigation}
      onSaveRelatedMessages={handleSaveRelatedMessages}
      onSaveRelatedIncidents={handleSaveRelatedIncidents}
      onSaveTelemetryDashboards={handleSaveTelemetryDashboards}
      isGenerating={isLoading}
    />
  );
};

export type GroundTruthSectionProps = {
  groundTruth?: IncidentGroundTruth;
  className?: string;
  onGenerateGroundTruth?: () => void;
  onSaveCodeChanges?: (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => Promise<void>;
  onSaveInvestigation?: (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => Promise<void>;
  onSaveRelatedMessages?: (
    messages: IncidentGroundTruthRelatedMessage[],
    isVerified: boolean,
  ) => Promise<void>;
  onSaveRelatedIncidents?: (
    relatedIncidents: IncidentGroundTruthRelatedIncidentPayload[],
    nextSteps: string,
    isVerified: boolean,
  ) => Promise<void>;
  onSaveTelemetryDashboards?: (
    dashboards: IncidentGroundTruthTelemetryDashboard[],
    isVerified: boolean,
  ) => Promise<void>;
  isGenerating?: boolean;
};

// Main Component
export const GroundTruthSection = ({
  groundTruth,
  className,
  onGenerateGroundTruth,
  onSaveCodeChanges,
  onSaveInvestigation,
  onSaveRelatedMessages,
  onSaveRelatedIncidents,
  onSaveTelemetryDashboards,
  isGenerating,
}: GroundTruthSectionProps) => {
  if (!groundTruth) {
    return (
      <div className={className}>
        <ContentBox>
          <EmptyState
            title="No ground truth available"
            content="Generate ground truth to help understand what happened during this incident"
            icon={IconEnum.MagicWand}
            cta={
              <Button
                onClick={onGenerateGroundTruth}
                disabled={isGenerating}
                analyticsTrackingId={null}
                icon={IconEnum.MagicWand}
                loading={isGenerating}
              >
                {isGenerating ? "Generating..." : "Generate ground truth"}
              </Button>
            }
          />
        </ContentBox>
      </div>
    );
  }

  return (
    <div className={className}>
      <ContentBox>
        <div className="relative">
          <div className="flex justify-between items-center p-4 border-b border-stroke">
            <div className="text-sm text-content-secondary">
              Updated <LocalRelativeDateTime date={groundTruth.updated_at} />
            </div>
            <Button
              onClick={onGenerateGroundTruth}
              disabled={isGenerating}
              analyticsTrackingId={null}
              icon={IconEnum.MagicWand}
              loading={isGenerating}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isGenerating ? "Generating..." : "Refresh"}
            </Button>
          </div>

          <GroundTruthInvestigationSection
            relevance={groundTruth.investigation_relevance}
            tags={groundTruth.investigation_relevance_tags}
            reasoning={groundTruth.investigation_relevance_reasoning}
            verifiedAt={groundTruth.investigation_relevance_verified_at}
            onSave={onSaveInvestigation}
            className={"-mt-px"}
          />

          <GroundTruthCodeChangesSection
            changes={groundTruth.causing_code_changes}
            verifiedAt={groundTruth.causing_code_changes_verified_at}
            onSave={onSaveCodeChanges}
            className={"-mt-px"}
          />

          <GroundTruthRelatedMessagesSection
            messages={groundTruth.related_messages}
            verifiedAt={groundTruth.related_messages_verified_at}
            onSave={onSaveRelatedMessages}
            className={"-mt-px"}
          />

          <GroundTruthRelatedIncidentsSection
            relatedIncidents={groundTruth.related_incidents}
            nextSteps={groundTruth.related_incidents_next_steps}
            verifiedAt={groundTruth.related_incidents_verified_at}
            onSave={onSaveRelatedIncidents}
            className={"-mt-px"}
          />

          <GroundTruthTelemetryDashboardsSection
            relatedDashboards={groundTruth.related_telemetry_dashboards}
            verifiedAt={groundTruth.related_telemetry_dashboards_verified_at}
            onSave={onSaveTelemetryDashboards}
            className={"-mt-px"}
          />
        </div>
      </ContentBox>
    </div>
  );
};
