"use client";

import {
  StatusPageContentIncident,
  StatusPageContentStatusSummaryWorstComponentStatusEnum,
} from "@incident-io/api";
import cx from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";

import { getMaintenanceImpactWindow } from "../../helpers";
import { formatTimestampLocale, hasSameDay } from "../../time-helpers";
import { useUIContext } from "../../UIContext";
import { useTranslations } from "../../use-translations";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { InfoIcon } from "../Icons/InfoIcon";
import { useParseTime } from "../TimeContext";
import { Tooltip } from "../Tooltip";
import {
  incidentLinkClassNames,
  IncidentLinkContents,
} from "../Tooltip/DayOfIncidentsTooltipContents";

export const TimePicker = ({
  startAt,
  endAt,
  setEndAt,
  dataAvailableSince,
  now,
  maintenances,
  showPicker,
  translationsNamespace,
  isHistory,
}: {
  startAt: DateTime;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  now: DateTime;
  maintenances?: Array<StatusPageContentIncident>;
  showPicker: boolean;
  translationsNamespace: "SystemStatus" | "IncidentHistory";
  isHistory?: boolean;
}): React.ReactElement => {
  const t = useTranslations(translationsNamespace);

  const nowMonth = formatTimestampLocale(now, "month");
  const localMonthStart = formatTimestampLocale(startAt, "month");
  const localMonthEnd = formatTimestampLocale(endAt, "month");
  const { parse } = useParseTime();

  const upcomingMaintenances = maintenances?.filter((maintenance) => {
    const [start, end] = getMaintenanceImpactWindow(
      maintenance.component_impacts,
    );
    if (!start) {
      return false;
    }
    const windowStart = parse(start);
    const windowEnd = end && parse(end);
    if (!windowStart || (windowEnd && windowEnd < now)) {
      return false;
    }
    return true;
  });

  const { IncidentLink } = useUIContext();

  const canGoBack = dataAvailableSince < startAt;
  const canGoForward = localMonthEnd !== nowMonth;

  const chevronClasses = (enabled) =>
    cx(
      "w-4 h-4 font-semibold",
      enabled ? "cursor-pointer transition" : "cursor-not-allowed",
      enabled ? "text-slate-400 hover:text-slate-500" : "!text-slate-100",
      enabled
        ? "dark:text-slate-500 dark:hover:text-slate-300"
        : "dark:!text-slate-700",
    );

  return (
    <div className="flex md:items-center justify-between md:flex-row flex-col md:gap-2 gap-4 items-start">
      <div className={"flex items-center space-x-4"}>
        <h2
          className={cx("text-content-primary dark:text-slate-50", {
            "text-base-bold": isHistory,
          })}
        >
          {t("title")}
        </h2>
        {showPicker && (
          <div
            className={cx(
              "hidden md:flex items-center text-sm font-normal space-x-1 mt-[1px]",
              "text-slate-500",
            )}
          >
            <div
              onClick={
                canGoBack
                  ? () => setEndAt(endAt.minus({ days: 90 }))
                  : undefined
              }
            >
              <ChevronIcon
                flavour="left"
                className={chevronClasses(canGoBack)}
              />
            </div>

            <div
              className="select-none flex justify-center whitespace-nowrap text-slate-400 dark:text-slate-500"
              suppressHydrationWarning
            >
              {localMonthStart}
              <span className="px-1">-</span>
              {localMonthEnd}
            </div>

            <div
              onClick={
                canGoForward
                  ? () => setEndAt(endAt.plus({ days: 90 }))
                  : undefined
              }
            >
              <ChevronIcon
                flavour="right"
                className={chevronClasses(canGoForward)}
              />
            </div>
          </div>
        )}
      </div>
      {upcomingMaintenances && upcomingMaintenances.length > 0 && (
        <Tooltip
          className={"w-full md:w-auto"}
          content={
            <div className="text-sm text-content-tertiary max-h-[500px] overflow-auto">
              {_.chain(upcomingMaintenances)
                .map((maintenance) => {
                  const [windowStart, windowEnd] = getMaintenanceImpactWindow(
                    maintenance.component_impacts,
                  );

                  return {
                    maintenance,
                    windowStart,
                    windowEnd,
                  };
                })
                .sortBy(({ windowStart }) => windowStart)
                .map(({ maintenance, windowStart, windowEnd }) => {
                  let intervalStr: string;

                  if (windowStart && windowEnd) {
                    const startDateTime = parse(windowStart);
                    const endDateTime = parse(windowEnd);

                    const startFormatted = formatTimestampLocale(
                      startDateTime,
                      "intervalFull",
                    );

                    // Include the day for the end date if the dates are not on the same day
                    const isSameDay = hasSameDay(startDateTime, endDateTime);
                    const endFormatted = formatTimestampLocale(
                      endDateTime,
                      isSameDay ? "intervalShort" : "intervalFull",
                    );

                    intervalStr = `${startFormatted} — ${endFormatted}`;
                  } else if (windowStart) {
                    intervalStr = formatTimestampLocale(parse(windowStart));
                  } else {
                    return null;
                  }

                  return (
                    <div className="flex flex-col gap-1" key={maintenance.id}>
                      <div
                        className="whitespace-nowrap text-left p-1 text-content-tertiary"
                        suppressHydrationWarning
                      >
                        {intervalStr}
                      </div>
                      <IncidentLink
                        className={incidentLinkClassNames(
                          StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance,
                        )}
                        incident={{
                          name: maintenance.name,
                          incident_id: maintenance.id,
                        }}
                        analyticsTrackingId="sp-calendar-incident-link"
                      >
                        <IncidentLinkContents
                          name={maintenance.name}
                          status={
                            StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance
                          }
                        />
                      </IncidentLink>
                    </div>
                  );
                })
                .value()}
            </div>
          }
        >
          <div
            className={cx(
              "flex flex-row items-center gap-1 h-full p-1.5 cursor-default",
              // Because this box is taller than the text it makes the overall
              // content box heading grow a bit. Negative margin prevents that
              // from happening.
              "-my-1.5",
              "text-sm font-normal",
              "rounded-lg border",
              "transition",
              "text-slate-700 border-stroke hover:bg-surface-secondary/50",
              "dark:text-slate-100 dark:hover:bg-[rgba(41,51,65,0.57)] dark:border-slate-700",
            )}
          >
            <InfoIcon className="flex-none text-content-tertiary dark:text-slate-500" />
            {t("upcoming_maintenance_scheduled")}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
