import {
  StaffSetUserPermissionsRequestBody,
  UserWithStaffPermissions,
} from "@incident-io/api";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

export const EditPermissionsModal = ({
  user,
  onClose,
}: {
  user: UserWithStaffPermissions;
  onClose: () => void;
}) => {
  const formMethods = useForm<StaffSetUserPermissionsRequestBody>({
    defaultValues: {
      permissions: user.permissions.map(({ id }) => id),
    },
  });

  const { trigger, isMutating, genericError } = useMutationV2(
    async (apiClient, data: StaffSetUserPermissionsRequestBody) =>
      await apiClient.staffSetUserPermissions({
        userId: user.id,
        setUserPermissionsRequestBody: data,
      }),
    {
      invalidate: [cacheKey.exactly("staffListUsers", undefined)],
      onSuccess: onClose,
    },
  );

  const { data: permissionsData } = useAPI("staffListStaffPermissions", {});

  const permissionOptions = permissionsData?.permissions.map((permission) => ({
    value: permission.id,
    label: permission.name,
  }));

  return (
    <Form.Modal
      formMethods={formMethods}
      onClose={onClose}
      genericError={genericError}
      title={`Staff permissions for ${user.name}`}
      analyticsTrackingId={null}
      onSubmit={trigger}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <StaticMultiSelectV2
        formMethods={formMethods}
        name="permissions"
        placeholder="Select permissions..."
        options={permissionOptions ?? []}
      />
    </Form.Modal>
  );
};
