/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationUserTargetPayload,
    EscalationUserTargetPayloadFromJSON,
    EscalationUserTargetPayloadFromJSONTyped,
    EscalationUserTargetPayloadToJSON,
} from './EscalationUserTargetPayload';

/**
 * 
 * @export
 * @interface IncidentFormsPreviewEscalateFormRequestBody
 */
export interface IncidentFormsPreviewEscalateFormRequestBody {
    /**
     * The bindings of any catalog type backed elements, keyed by the form element's ID
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    catalog_element_bindings?: { [key: string]: Array<string>; };
    /**
     * The bindings for this escalation element
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    element_bindings?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    escalation_paths?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    priority?: string;
    /**
     * PagerDuty service used if you need to escalate to a particular PagerDuty user(s)
     * @type {string}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    user_pagerduty_service?: string;
    /**
     * 
     * @type {Array<EscalationUserTargetPayload>}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    users?: Array<EscalationUserTargetPayload>;
}

export function IncidentFormsPreviewEscalateFormRequestBodyFromJSON(json: any): IncidentFormsPreviewEscalateFormRequestBody {
    return IncidentFormsPreviewEscalateFormRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsPreviewEscalateFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsPreviewEscalateFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_element_bindings': !exists(json, 'catalog_element_bindings') ? undefined : json['catalog_element_bindings'],
        'element_bindings': !exists(json, 'element_bindings') ? undefined : json['element_bindings'],
        'escalation_paths': !exists(json, 'escalation_paths') ? undefined : json['escalation_paths'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'user_pagerduty_service': !exists(json, 'user_pagerduty_service') ? undefined : json['user_pagerduty_service'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(EscalationUserTargetPayloadFromJSON)),
    };
}

export function IncidentFormsPreviewEscalateFormRequestBodyToJSON(value?: IncidentFormsPreviewEscalateFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_element_bindings': value.catalog_element_bindings,
        'element_bindings': value.element_bindings,
        'escalation_paths': value.escalation_paths,
        'priority': value.priority,
        'user_pagerduty_service': value.user_pagerduty_service,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(EscalationUserTargetPayloadToJSON)),
    };
}

