import {
  AIStaffCreateSearchGroundTruthRequestBody,
  AIStaffListAISpansTypeEnum,
  AIStaffListEvaluationNotesResourceTypeEnum,
  CopilotSearch,
  SearchGroundTruth,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  LocalDateTime,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { sub } from "date-fns";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useIdentity } from "src/contexts/IdentityContext";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";
import { useClipboard } from "src/utils/useClipboard";

import { AISpanTrace } from "../ai-requests/AISpanTrace";
import { CodeViewer } from "../common/CodeViewer";
import { LabeledValue } from "../common/LabeledValue";
import { EvaluationNotesWidget } from "../evaluation-notes/EvaluationNotesWidget";
import { EvaluationScorecardSectionController } from "../scorecards/EvaluationScorecardSection";
import {
  parseCodeChangeSearchAnswer,
  SearchCostBadge,
  SearchDurationBadge,
} from "./SearchesListPage";

export const SearchShowPage = () => {
  const { id } = useParams<{ id: string }>() as { id: string };

  const { data, isLoading } = useAPI("aIStaffShowCopilotSearch", {
    id,
  });

  const search = data?.search;
  const groundTruth = data?.search_ground_truth;

  if (isLoading) {
    return <FullPageLoader />;
  }
  if (!search) {
    return <EmptyState content="Search not found" className="m-6" />;
  }

  return (
    <SearchGroundTruthsShowPageContents
      search={search}
      groundTruth={groundTruth}
    />
  );
};

export const SearchGroundTruthsShowPageContents = ({
  search,
  groundTruth,
}: {
  search: CopilotSearch;
  groundTruth?: SearchGroundTruth;
}) => {
  // State for showing/hiding working details
  const [showWorkingDetails, setShowWorkingDetails] = useState(false);
  const { identity } = useIdentity();

  const results = parseCodeChangeSearchAnswer(search.answer);

  const getQuestionTypeBadgeTheme = (type: string): BadgeTheme => {
    switch (type) {
      case "incident":
        return BadgeTheme.Primary;
      case "code-changes":
        return BadgeTheme.Info;
      default:
        return BadgeTheme.Tertiary;
    }
  };

  const {
    data: { spans },
    isLoading,
  } = useAPI(
    "aIStaffListAISpans",
    {
      resourceId: search.id,
      type: AIStaffListAISpansTypeEnum.Search,
      childSpanDepth: 50,
    },
    {
      fallbackData: { spans: [] },
    },
  );

  const navigate = useOrgAwareNavigate();

  // Create a mutation to create a ground truth
  const { trigger: createGroundTruth, isMutating } = useMutationV2(
    // Then how to mutate it
    async (apiClient, data: AIStaffCreateSearchGroundTruthRequestBody) => {
      const response = await apiClient.aIStaffCreateSearchGroundTruth({
        createSearchGroundTruthRequestBody: data,
      });

      // Navigate to the ground truth page
      navigate(`/workbench/searches/ground-truths/${response.ground_truth.id}`);
    },
    {
      invalidate: [cacheKey.exactly("aIStaffListSearchGroundTruths", {})],

      showErrorToast: "Could not create ground truth",
    },
  );

  // Helper function to extract results from search answer
  const getSearchResults = (search: CopilotSearch) => {
    // Check if we're using the new structure with 'results'
    if (
      search.answer &&
      "results" in search.answer &&
      Array.isArray(search.answer.results)
    ) {
      return search.answer.results;
    }

    // Fallback to the old structure with 'certain_results'
    if (
      search.answer &&
      "certain_results" in search.answer &&
      Array.isArray(search.answer.certain_results)
    ) {
      return search.answer.certain_results;
    }

    // If neither is available, return an empty array
    return [];
  };

  // Handler to create a ground truth from the search
  const handleCreateGroundTruth = () => {
    // Get results using the helper function
    const answer = getSearchResults(search);

    createGroundTruth({
      question: search.question,
      question_type: search.question_type,
      cutoff: search.cutoff,
      answer: answer,
    });
  };

  return (
    <WorkbenchSubPageWrapper
      title={search?.question || "Search"}
      backHref="/workbench/searches"
      loading={isLoading}
      accessory={
        <div className="flex gap-2">
          {search.copilot_thread_id && (
            <Button
              theme={ButtonTheme.Secondary}
              href={`/workbench/threads/${search.copilot_thread_id}`}
              analyticsTrackingId={null}
              icon={IconEnum.Robot}
              openInNewTab
            >
              View Copilot thread
            </Button>
          )}
          {!groundTruth && (
            <Button
              theme={ButtonTheme.Primary}
              onClick={handleCreateGroundTruth}
              disabled={isMutating}
              loading={isMutating}
              analyticsTrackingId={null}
            >
              Create ground truth
            </Button>
          )}
        </div>
      }
    >
      <EvaluationNotesWidget
        resourceId={search.id}
        resourceType={AIStaffListEvaluationNotesResourceTypeEnum.CopilotSearch}
        parentId={groundTruth?.id}
        parentResourceType={
          AIStaffListEvaluationNotesResourceTypeEnum.SearchGroundTruth
        }
      />
      <div className="flex flex-col gap-6">
        <div className="flex gap-6">
          <ContentBox className="p-4 grow">
            <div className="flex flex-col gap-4">
              <LabeledValue label="Question" value={search.question} />
              <LabeledValue
                label="Question type"
                value={
                  <Badge
                    theme={getQuestionTypeBadgeTheme(search.question_type)}
                  >
                    {search.question_type}
                  </Badge>
                }
              />
              <LabeledValue
                label="Created at"
                value={<LocalDateTime timestamp={search.created_at} />}
              />
              <LabeledValue
                label="Cutoff"
                value={<LocalDateTime timestamp={search.cutoff} />}
              />
              <LabeledValue
                label="Duration"
                value={
                  <SearchDurationBadge
                    durationSeconds={search.duration_seconds}
                  />
                }
              />
              <LabeledValue
                label="Cost"
                value={<SearchCostBadge costCents={search.cost_cents} />}
              />
            </div>
          </ContentBox>

          {/* Display the search answer */}
          <ContentBox className="p-4 grow flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">Answers</h3>
              <Badge theme={BadgeTheme.Info}>
                {results?.length || 0} item
                {(results?.length || 0) !== 1 ? "s" : ""}
              </Badge>
            </div>
            {search.error_message && (
              <Callout
                theme={CalloutTheme.Danger}
                title="Error running search"
                subtitle={search.error_message}
              />
            )}
            {results && (results.length || 0) > 0 ? (
              <div className="flex flex-col gap-4">
                {results.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <h4 className="font-medium">Results</h4>
                    {results.map((item, index) => (
                      <SearchCodeChangeItem
                        key={index}
                        item={item}
                        shouldTickList={groundTruth?.answer}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <EmptyState content="No search results found" />
            )}

            {groundTruth && (
              <>
                <hr />
                <div className="flex items-center gap-2 justify-between">
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-semibold">Ground truth</h3>
                    <Badge theme={BadgeTheme.Info}>
                      {groundTruth.answer?.length || 0} item
                      {groundTruth.answer?.length !== 1 ? "s" : ""}
                    </Badge>
                  </div>
                  <Button
                    theme={ButtonTheme.Ghost}
                    analyticsTrackingId={null}
                    href={`/workbench/searches/ground-truths/${groundTruth.id}`}
                    icon={IconEnum.ExternalLink}
                    openInNewTab
                    title=""
                  >
                    View ground truth{" "}
                  </Button>
                </div>
                {groundTruth.answer && groundTruth.answer.length > 0 ? (
                  <div className="flex flex-col gap-2">
                    {groundTruth.answer.map((item, index) => (
                      <SearchCodeChangeItem
                        key={index}
                        item={item}
                        shouldTickList={results}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyState content="Expected no results" />
                )}
              </>
            )}
          </ContentBox>
        </div>

        {groundTruth && (
          <EvaluationScorecardSectionController copilotSearchId={search.id} />
        )}

        {/* Display the search working details with collapsible content */}
        {search.working && (
          <ContentBox className="px-4 py-3">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Workings</h3>
                <Button
                  theme={ButtonTheme.Ghost}
                  icon={
                    showWorkingDetails ? IconEnum.Collapse : IconEnum.Expand
                  }
                  analyticsTrackingId={null}
                  onClick={() => setShowWorkingDetails(!showWorkingDetails)}
                >
                  {showWorkingDetails ? "Hide details" : "Show details"}
                </Button>
              </div>

              {/* Display a preview when collapsed */}
              {!showWorkingDetails && (
                <div className="flex flex-col gap-4">
                  <div className="text-content-secondary">
                    <p>
                      This search processed{" "}
                      {search.working.longlists?.reduce(
                        (count, list) => count + list.candidates.length,
                        0,
                      ) || 0}{" "}
                      initial candidates with{" "}
                      {search.working.shortlist?.length || 0} items shortlisted.
                      {search.duration_seconds !== undefined && (
                        <> Took {search.duration_seconds.toFixed(2)} seconds.</>
                      )}
                      {search.cost_cents !== undefined && (
                        <> Cost ${(search.cost_cents / 100).toFixed(4)}.</>
                      )}
                    </p>
                  </div>

                  {/* Always show query parameters and SQL query even when collapsed */}
                  <div className="grid grid-cols-2 gap-4">
                    {search.working.query_params && (
                      <CodeViewer
                        title="Query parameters"
                        mode="json"
                        content={search.working.query_params}
                      />
                    )}
                    {search.working.query_params && (
                      <CodeViewer
                        title="Generated SQL query"
                        mode="text"
                        content={generateSQLQuery(
                          search,
                          identity.organisation_id,
                        )}
                      />
                    )}
                  </div>
                </div>
              )}

              {/* Expandable content */}
              {showWorkingDetails && (
                <div className="flex flex-col gap-4">
                  {/* Longlists */}
                  {search.working.longlists &&
                    search.working.longlists.length > 0 && (
                      <div className="flex flex-col gap-2">
                        <h4 className="text-md font-medium">
                          Initial Candidate Lists
                        </h4>
                        {search.working.longlists.map((longlist, index) => (
                          <div
                            key={index}
                            className="flex flex-col gap-1 ml-2 border-l-2 border-slate-200 pl-3"
                          >
                            <h5 className="font-medium">
                              {longlist.name}{" "}
                              <span className="text-content-secondary">
                                ({longlist.candidates.length} items)
                              </span>
                            </h5>
                            <div className="ml-2">
                              {longlist.candidates
                                .slice(0, 5)
                                .map((candidate, i) => (
                                  <div
                                    key={i}
                                    className="text-content-secondary text-sm"
                                  >
                                    {candidate}
                                  </div>
                                ))}
                              {longlist.candidates.length > 5 && (
                                <div className="text-content-secondary text-sm italic">
                                  ...and {longlist.candidates.length - 5} more
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                  {/* Shortlist */}
                  {search.working.shortlist &&
                    search.working.shortlist.length > 0 && (
                      <div className="flex flex-col gap-2">
                        <h4 className="text-md font-medium">
                          Shortlisted Items
                        </h4>
                        <div className="grid grid-cols-1 gap-2">
                          {search.working.shortlist.map((item, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-2 p-2 border border-gray-200 rounded"
                            >
                              <Badge
                                theme={
                                  item.relevance_rank <= 1
                                    ? BadgeTheme.Success
                                    : item.relevance_rank <= 3
                                    ? BadgeTheme.Info
                                    : BadgeTheme.Secondary
                                }
                              >
                                {item.relevance}
                              </Badge>
                              <div className="flex-grow">{item.reference}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </ContentBox>
        )}
      </div>
      <AISpanTrace spans={spans} />
    </WorkbenchSubPageWrapper>
  );
};

const generateSQLQuery = (search: CopilotSearch, orgId: string): string => {
  if (!search.working?.query_params) {
    return "-- No query parameters available";
  }

  try {
    // Parse the query parameters
    const params = JSON.parse(search.working.query_params);

    // Extract relevant parameters with safe defaults
    const keywords = params.keywords || [];
    const keywordsStr = keywords.join(" ");
    const repos = params.repos || [];
    const state = params.state || "";

    // Format date for SQL query
    const formatDate = (dateString: string): string => {
      try {
        return new Date(dateString).toISOString();
      } catch (error) {
        console.error(`Error formatting date ${dateString}:`, error);
        return dateString;
      }
    };

    const fromDate = params.merged_between?.from
      ? formatDate(params.merged_between.from)
      : undefined;
    const toDate = params.merged_between?.to
      ? formatDate(params.merged_between.to)
      : undefined;
    const cutoff = search.cutoff ? search.cutoff.toISOString() : undefined;

    const similarity = `similarity(generated_summary, '${keywordsStr}')`;

    // Build the SQL query
    let query = `SELECT ${similarity}, title, description, generated_summary FROM code_changes
WHERE organisation_id = '${orgId}'`;

    if (fromDate) {
      query += `\n  AND merged_at >= '${fromDate}'`;
    }

    if (toDate) {
      query += `\n  AND merged_at <= '${toDate}'`;
    }

    if (!fromDate && !toDate && !params.query_all_history) {
      // We'll apply our default -> 6 months and 1 month.
      const defaultFromDate = sub(new Date(), { months: 6 }).toISOString();
      query += `\n  AND merged_at >= '${defaultFromDate}'`;
    }

    if (cutoff) {
      query += `\n  AND created_at <= '${cutoff}'`;
    }

    // Add repo filter if repos are specified
    if (repos.length > 0) {
      const reposQuoted = repos.map((repo) => `'${repo}'`).join(", ");
      query += `\n  AND repo IN (${reposQuoted})`;
    }

    // Add state filter if state is specified
    if (state) {
      switch (state.toLowerCase()) {
        case "merged":
          query += `\n  AND merged_at IS NOT NULL`;
          break;
        case "closed":
          query += `\n  AND closed_at IS NOT NULL`;
          break;
        case "open":
          query += `\n  AND closed_at IS NULL AND merged_at IS NULL`;
          break;
      }
    }

    query += `\nORDER BY similarity(generated_summary, '${keywordsStr}') DESC
LIMIT 25;`;

    return query;
  } catch (error) {
    return `-- Error generating SQL: ${error}`;
  }
};

export const SearchCodeChangeItem = ({
  item,
  shouldTickList,
}: {
  item: string;
  shouldTickList?: string[];
}) => {
  const isTicked = shouldTickList?.includes(item);
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div className="flex gap-2 items-center">
      {isTicked ? (
        <Icon id={IconEnum.TickCircle} className="text-green-content" />
      ) : (
        <Icon id={IconEnum.CloseCircle} className="text-content-tertiary" />
      )}
      <span>{item}</span>
      <div className="flex items-center gap-1">
        <Button
          theme={ButtonTheme.Ghost}
          analyticsTrackingId={null}
          onClick={() => copyTextToClipboard(item)}
          icon={hasCopied ? IconEnum.Tick : IconEnum.Copy}
          title={hasCopied ? "Copied!" : ""}
        />
        <Button
          theme={ButtonTheme.Link}
          analyticsTrackingId={null}
          href={item}
          icon={IconEnum.ExternalLink}
          openInNewTab
          title="Open in new tab"
        />
      </div>
    </div>
  );
};
