import { StaffShowOrganisationResponseBody } from "@incident-io/api";

import { InfoTable } from "../common/InfoTable";
import { Value } from "../common/Value";

export const UserCountsPanel = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <InfoTable
      rows={[
        {
          id: "viewers",
          name: "Viewers",
          value: <Value.NumericValue value={data.viewer_count} />,
        },
        {
          id: "responders",
          name: "Responders",
          value: <Value.NumericValue value={data.responder_count} />,
        },
        {
          id: "on_call_responders",
          name: "On-call responders",
          value: <Value.NumericValue value={data.on_call_count} />,
        },
      ]}
    />
  );
};
