import {
  AIStaffShowEvaluationBacktestResponseBody,
  EvaluationBacktest,
  EvaluationBacktestBacktestTypeEnum,
  EvaluationBacktestEntry,
  EvaluationBacktestEntryStateEnum,
  InvestigationPlanTypeEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
  StackedListItem,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { groupBy, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { cacheKey, useMutationV2 } from "src/utils/swr";

import {
  EvaluationFilterBar,
  EvaluationFilterContextProvider,
  ScorecardMetricIdentifier,
  useEvaluationFilterContext,
  useFilterEntries as useFilterBacktestEntries,
} from "../common/EvaluationFilterContext";
import { EvaluationAggregateScorecardController } from "../investigations/EvaluationAggregateScorecard";
import { InvestigationGradeMetric } from "../scorecards/EvaluationScorecardGrades";
import { BacktestCreateModal } from "./BacktestCreateModal";

export const BacktestEntriesList = ({
  backtest,
  entries,
  comparedWithData,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  comparedWithData: AIStaffShowEvaluationBacktestResponseBody | undefined;
}) => {
  const tags = entries.map((e) => e.tags).flat();
  const uniqueTags = Array.from(new Set(tags)).sort();

  return (
    <EvaluationFilterContextProvider
      enableSelectingMetrics={true}
      availableTags={uniqueTags}
    >
      <BacktestEntriesListContent
        backtest={backtest}
        entries={entries}
        comparedWithData={comparedWithData}
      />
    </EvaluationFilterContextProvider>
  );
};

// Component for the state filter buttons
const StateFilterButtons = ({
  entriesByState,
  selectedState,
  setSelectedState,
}: {
  entriesByState: Record<
    EvaluationBacktestEntryStateEnum,
    EvaluationBacktestEntry[]
  >;
  selectedState: EvaluationBacktestEntryStateEnum | null;
  setSelectedState: (state: EvaluationBacktestEntryStateEnum | null) => void;
}) => {
  const stateConfig: Record<
    EvaluationBacktestEntryStateEnum,
    { theme: BadgeTheme; icon: IconEnum; label: string }
  > = {
    pending: {
      theme: BadgeTheme.Tertiary,
      icon: IconEnum.DottedCircle,
      label: "Pending",
    },
    preparing: {
      theme: BadgeTheme.Secondary,
      icon: IconEnum.Loader,
      label: "Running",
    },
    prepared: {
      theme: BadgeTheme.Secondary,
      icon: IconEnum.Loader,
      label: "Running",
    },
    scoring: {
      theme: BadgeTheme.Secondary,
      icon: IconEnum.Loader,
      label: "Scoring",
    },
    scored: {
      theme: BadgeTheme.Success,
      icon: IconEnum.Tick,
      label: "Scored",
    },
    errored: {
      theme: BadgeTheme.Error,
      icon: IconEnum.Warning,
      label: "Errored",
    },
  };

  return (
    <div className="flex flex-wrap gap-2">
      <Button
        theme={
          selectedState == null ? ButtonTheme.Primary : ButtonTheme.Secondary
        }
        analyticsTrackingId={null}
        onClick={() => setSelectedState(null)}
        size={BadgeSize.Small}
      >
        All ({Object.values(entriesByState).flat().length})
      </Button>
      {(Object.keys(stateConfig) as EvaluationBacktestEntryStateEnum[]).map(
        (state) => {
          const count = entriesByState[state]?.length || 0;
          if (count === 0) return null;

          const config = stateConfig[state];

          return (
            <Button
              key={state}
              theme={
                selectedState === state
                  ? ButtonTheme.Primary
                  : ButtonTheme.Secondary
              }
              analyticsTrackingId={null}
              onClick={() => setSelectedState(state)}
              icon={config.icon}
              size={BadgeSize.Small}
            >
              {config.label} ({count})
            </Button>
          );
        },
      )}
    </div>
  );
};

// Sort direction enum
enum SortDirection {
  None = "none",
  Ascending = "ascending",
  Descending = "descending",
}

// Sorting component with 3-state buttons
const SortControls = ({
  scoreDirection,
  setScoreDirection,
  comparisonDirection,
  setComparisonDirection,
  selectedMetric,
  hasComparison,
}: {
  scoreDirection: SortDirection;
  setScoreDirection: (direction: SortDirection) => void;
  comparisonDirection: SortDirection;
  setComparisonDirection: (direction: SortDirection) => void;
  selectedMetric: ScorecardMetricIdentifier | undefined;
  hasComparison: boolean;
}) => {
  // Function to cycle through the three states
  const cycleDirection = (currentDirection: SortDirection) => {
    switch (currentDirection) {
      case SortDirection.None:
        return SortDirection.Ascending;
      case SortDirection.Ascending:
        return SortDirection.Descending;
      case SortDirection.Descending:
        return SortDirection.None;
      default:
        return SortDirection.None;
    }
  };

  // Handle score button click
  const handleScoreClick = () => {
    // If we're clicking the score button, reset the comparison button
    if (scoreDirection === SortDirection.None) {
      setComparisonDirection(SortDirection.None);
    }
    setScoreDirection(cycleDirection(scoreDirection));
  };

  // Handle comparison button click
  const handleComparisonClick = () => {
    // If we're clicking the comparison button, reset the score button
    if (comparisonDirection === SortDirection.None) {
      setScoreDirection(SortDirection.None);
    }
    setComparisonDirection(cycleDirection(comparisonDirection));
  };

  // Get button icon based on sort direction
  const getButtonIcon = (direction: SortDirection) => {
    switch (direction) {
      case SortDirection.Ascending:
        return IconEnum.ArrowCircleUp;
      case SortDirection.Descending:
        return IconEnum.ArrowCircleDown;
      default:
        return undefined;
    }
  };

  // Get button theme based on sort direction
  const getButtonTheme = (direction: SortDirection) => {
    return direction === SortDirection.None
      ? ButtonTheme.Secondary
      : ButtonTheme.Primary;
  };

  return (
    <div className="flex items-center gap-4">
      <div className="text-sm font-medium">Sort by:</div>
      <div className="flex items-center gap-2">
        <Button
          theme={getButtonTheme(scoreDirection)}
          analyticsTrackingId={null}
          onClick={handleScoreClick}
          size={BadgeSize.Small}
          icon={getButtonIcon(scoreDirection)}
          iconPosition="right"
        >
          {selectedMetric?.metricName}
        </Button>
        {hasComparison && (
          <Button
            theme={getButtonTheme(comparisonDirection)}
            analyticsTrackingId={null}
            onClick={handleComparisonClick}
            size={BadgeSize.Small}
            icon={getButtonIcon(comparisonDirection)}
            iconPosition="right"
          >
            Trend
          </Button>
        )}
      </div>
    </div>
  );
};

const BacktestEntriesListContent = ({
  backtest,
  entries,
  comparedWithData,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  comparedWithData: AIStaffShowEvaluationBacktestResponseBody | undefined;
}) => {
  const tags = entries.map((e) => e.tags).flat();
  const uniqueTags = Array.from(new Set(tags)).sort();

  const { selectedMetric } = useEvaluationFilterContext();
  const filterEntries = useFilterBacktestEntries();

  // State filter
  const [selectedState, setSelectedState] =
    useState<EvaluationBacktestEntryStateEnum | null>(null);

  // Sorting state - using the new 3-state approach
  const [scoreDirection, setScoreDirection] = useState<SortDirection>(
    SortDirection.Ascending,
  );
  const [comparisonDirection, setComparisonDirection] = useState<SortDirection>(
    SortDirection.None,
  );

  // Group entries by state
  const entriesByState = useMemo(() => {
    return groupBy(entries, (e) => e.state) as Record<
      EvaluationBacktestEntryStateEnum,
      EvaluationBacktestEntry[]
    >;
  }, [entries]);

  // Apply filters
  const entriesAfterTagFilter = filterEntries(entries);
  const filteredEntries = useMemo(() => {
    if (selectedState == null) {
      return entriesAfterTagFilter;
    }
    return entriesAfterTagFilter.filter((e) => e.state === selectedState);
  }, [entriesAfterTagFilter, selectedState]);

  const scorecardIDs = filteredEntries
    .map((e) => e.evaluation_scorecard?.id)
    .filter(Boolean)
    .sort();

  // Figure out which entries from our comparison data we want to show.
  const comparisonEntries = useMemo(
    () => comparedWithData?.entries || [],
    [comparedWithData],
  );

  // Extract sorting logic to a separate function for clarity
  const getEntryWithSortValue = (
    entry: EvaluationBacktestEntry,
    selectedMetric: ScorecardMetricIdentifier | undefined,
    comparisonEntries: EvaluationBacktestEntry[],
    scoreDirection: SortDirection,
    comparisonDirection: SortDirection,
  ): { entry: EvaluationBacktestEntry; value: number | string | undefined } => {
    // Base case if no metric selected
    if (!selectedMetric) {
      return { entry, value: entry.id };
    }

    // Find the matching comparison entry if it exists
    const comparedEntry = comparisonEntries.find(
      (e) => comparableBacktestEntry(e) === comparableBacktestEntry(entry),
    );

    // Get metrics from both entries
    const { metric, comparedMetric } = getGradeForMetric(
      entry,
      selectedMetric,
      comparedEntry,
    );

    let value: number | string | undefined;

    // Determine value based on which sort button is active
    if (scoreDirection !== SortDirection.None) {
      // Sort by score
      value = metric ? metric.score : -9999;
    } else if (comparisonDirection !== SortDirection.None) {
      // Sort by comparison (trend)
      if (metric?.score && comparedMetric?.score) {
        // Calculate difference between current and compared metric
        value = metric.score - comparedMetric.score;
      } else if (metric) {
        // If we only have current metric, use that
        value = metric.score;
      } else {
        // No metrics available
        value = -9999;
      }
    } else {
      // Fallback to ID sort
      value = entry.id;
    }

    return { entry, value };
  };

  // Sort entries based on selected sort direction
  const sortedEntries = useMemo(() => {
    // If no sort direction is active, return unsorted entries
    if (
      scoreDirection === SortDirection.None &&
      comparisonDirection === SortDirection.None
    ) {
      return filteredEntries;
    }

    // Map entries to objects with sort values
    const compareValues = filteredEntries.map((entry) =>
      getEntryWithSortValue(
        entry,
        selectedMetric,
        comparisonEntries,
        scoreDirection,
        comparisonDirection,
      ),
    );

    // Sort the entries
    const sorted = sortBy(compareValues, "value");

    // Return entries in the correct order based on active sort direction
    const isAscending =
      scoreDirection === SortDirection.Ascending ||
      comparisonDirection === SortDirection.Ascending;

    return isAscending
      ? sorted.map((item) => item.entry)
      : sorted.reverse().map((item) => item.entry);
  }, [
    filteredEntries,
    selectedMetric,
    scoreDirection,
    comparisonDirection,
    comparisonEntries,
  ]);
  const matchingComparisonScorecardIDs = comparisonEntries
    .map((e) => e.evaluation_scorecard?.id)
    .filter(Boolean)
    .sort();

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const toggleEntry = (entryID: string) => {
    if (selectedEntries?.includes(entryID)) {
      setSelectedEntries(selectedEntries.filter((e) => e !== entryID));
    } else {
      setSelectedEntries([...selectedEntries, entryID]);
    }
  };
  const selectedSegments = {};
  uniqueTags.forEach((tag) => {
    selectedSegments[tag] = sortedEntries
      .filter((e) => e.tags.includes(tag))
      .map((e) => e.id);
  });

  const toast = useToast();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { trigger: regradeEntries, isMutating: regrading } = useMutationV2(
    async (apiClient, data: { entry_ids: string[] }) => {
      await apiClient.aIStaffRegradeEvaluationBacktestEntries({
        id: backtest.id,
        regradeEvaluationBacktestEntriesRequestBody: data,
      });

      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationBacktest", { id: backtest.id }),
      ],

      onSuccess: () => {
        setSelectedEntries([]); // clear selection

        toast({
          title: "Regraded entries",
          theme: ToastTheme.Success,
        });
      },

      onError: (error) => {
        toast({
          title: "Failed to regrade investigations",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    },
  );

  const onRegrade = () => {
    regradeEntries({
      entry_ids: selectedEntries,
    });
  };

  const { trigger: retryEntries, isMutating: retrying } = useMutationV2(
    async (apiClient, data: { entry_ids: string[] }) => {
      await apiClient.aIStaffRetryEvaluationBacktestEntries({
        id: backtest.id,
        retryEvaluationBacktestEntriesRequestBody: data,
      });

      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationBacktest", { id: backtest.id }),
      ],

      onSuccess: () => {
        setSelectedEntries([]); // clear selection

        toast({
          title: "Retrying entries",
          theme: ToastTheme.Success,
        });
      },

      onError: (error) => {
        toast({
          title: "Failed to retry investigations",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    },
  );

  const onRetry = () => {
    retryEntries({
      entry_ids: selectedEntries,
    });
  };

  return (
    <>
      <EvaluationFilterBar />
      <StateFilterButtons
        entriesByState={entriesByState}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />

      <EvaluationAggregateScorecardController
        scorecardIDs={scorecardIDs as string[]}
        compareWithScorecardIDs={matchingComparisonScorecardIDs as string[]}
        allEntries={entries}
        showFilterBar={false}
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <Button
              icon={
                sortedEntries.length > 0 &&
                selectedEntries.length === sortedEntries.length
                  ? IconEnum.FakeCheckboxChecked
                  : IconEnum.FakeCheckbox
              }
              className={"hover:bg-surface-tertiary"}
              analyticsTrackingId={null}
              theme={ButtonTheme.Ghost}
              onClick={() =>
                setSelectedEntries(
                  selectedEntries.length === sortedEntries.length
                    ? []
                    : sortedEntries.map((e) => e.id),
                )
              }
            >
              Select all
            </Button>
            {selectedMetric && (
              <div className="text-xs text-content-secondary">
                <SortControls
                  scoreDirection={scoreDirection}
                  setScoreDirection={setScoreDirection}
                  comparisonDirection={comparisonDirection}
                  setComparisonDirection={setComparisonDirection}
                  selectedMetric={selectedMetric}
                  hasComparison={comparedWithData != null}
                />
              </div>
            )}
          </div>
          <DropdownMenu
            align="end"
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="backtest-bulk-action"
                loading={regrading || retrying}
              >
                Apply bulk actions
              </Button>
            }
            disabled={selectedEntries.length === 0}
          >
            <DropdownMenuItem
              label="Create new backtest"
              icon={IconEnum.Test}
              onSelect={() => setShowCreateModal(true)}
              analyticsTrackingId={null}
            />
            <DropdownMenuItem
              label="Retry entries"
              icon={IconEnum.ArrowSpin}
              onSelect={onRetry}
              analyticsTrackingId={null}
            />
            <DropdownMenuItem
              label="Regrade entries"
              icon={IconEnum.Action}
              onSelect={onRegrade}
              analyticsTrackingId={null}
            />
          </DropdownMenu>
        </div>
        <StackedList>
          {sortedEntries.map((entry) => {
            const comparedEntry = comparisonEntries.find(
              (e) =>
                comparableBacktestEntry(e) === comparableBacktestEntry(entry),
            );
            return (
              <BacktestEntryListRow
                key={entry.id}
                entry={entry}
                comparedEntry={comparedEntry}
                selectedMetric={selectedMetric}
                isSelected={selectedEntries.includes(entry.id)}
                onToggleSelect={toggleEntry}
              />
            );
          })}
        </StackedList>
      </div>
      {showCreateModal && (
        <BacktestCreateModal
          defaultPlanType={
            backtest.investigation_plan_type as unknown as InvestigationPlanTypeEnum
          }
          defaultCompareWithBacktestID={backtest.id}
          onClose={() => setShowCreateModal(false)}
          overrideBacktestType={
            backtest.backtest_type as unknown as EvaluationBacktestBacktestTypeEnum
          }
          resourceIDs={sortedEntries
            .filter((e) => selectedEntries.includes(e.id))
            .map((e) => e.incident_id || e.search_ground_truth_id || "")}
          segments={selectedSegments}
        />
      )}
    </>
  );
};

const comparableBacktestEntry = (entry: EvaluationBacktestEntry) => {
  return [entry.incident_id, entry.search_ground_truth_id]
    .filter(Boolean)
    .join("-");
};

const BacktestEntryListRow = ({
  entry,
  comparedEntry,
  selectedMetric,
  isSelected,
  onToggleSelect,
}: {
  entry: EvaluationBacktestEntry;
  comparedEntry?: EvaluationBacktestEntry;
  selectedMetric?: ScorecardMetricIdentifier;
  isSelected: boolean;
  onToggleSelect: (entryId: string) => void;
}) => {
  return (
    <StackedListItem
      key={entry.id}
      className="pl-1"
      iconNode={
        <Button
          icon={
            isSelected ? IconEnum.FakeCheckboxChecked : IconEnum.FakeCheckbox
          }
          className="px-2 rounded hover:bg-surface-tertiary py-5 z-50"
          title=""
          analyticsTrackingId={null}
          theme={ButtonTheme.Unstyled}
          onClick={() => onToggleSelect(entry.id)}
        />
      }
      title={
        <div className="flex items-center gap-2">
          {entry.reference && (
            <span className="font-semibold">{entry.reference}</span>
          )}
          <span className="font-normal max-w-md truncate">{entry.title}</span>
        </div>
      }
      badgeNode={
        <div className="flex items-center gap-2">
          {entry.tags.map((tag) => (
            <Badge
              theme={BadgeTheme.Info}
              key={tag}
              size={BadgeSize.ExtraSmall}
            >
              {tag}
            </Badge>
          ))}
        </div>
      }
      accessory={
        <BacktestEntryScore
          entry={entry}
          selectedMetric={selectedMetric}
          comparedEntry={comparedEntry}
        />
      }
      description={
        <div className="flex items-center gap-2 mt-2">
          <BacktestEntryStatusBadge entry={entry} />
          <BacktestEntryLinks entry={entry} />
          {entry.investigation_cost_cents ? (
            <div className="flex items-center justify-end gap-0.5">
              <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
              {(entry.investigation_cost_cents / 100.0).toFixed(2)}
            </div>
          ) : null}
        </div>
      }
    />
  );
};

const BacktestEntryLinks = ({ entry }: { entry: EvaluationBacktestEntry }) => {
  return (
    <div className="flex items-center gap-2 text-content-tertiary">
      {entry.incident_id && (
        <Button
          theme={ButtonTheme.Link}
          href={`/incidents/${entry.incident_id}`}
          icon={IconEnum.ExternalLink}
          analyticsTrackingId={null}
          openInNewTab
          size={BadgeSize.ExtraSmall}
        >
          {entry.reference}
        </Button>
      )}
      {entry.investigation_id && (
        <Button
          theme={ButtonTheme.Link}
          href={`/workbench/investigations/${entry.investigation_id}`}
          icon={IconEnum.ExternalLink}
          analyticsTrackingId={null}
          openInNewTab
          size={BadgeSize.ExtraSmall}
        >
          Investigation
        </Button>
      )}
      {entry.copilot_search_id && (
        <Button
          theme={ButtonTheme.Link}
          href={`/workbench/searches/${entry.copilot_search_id}`}
          icon={IconEnum.ExternalLink}
          analyticsTrackingId={null}
          openInNewTab
          size={BadgeSize.ExtraSmall}
        >
          Search
        </Button>
      )}
    </div>
  );
};

const BacktestEntryScore = ({
  entry,
  selectedMetric,
  comparedEntry,
}: {
  entry: EvaluationBacktestEntry;
  selectedMetric?: ScorecardMetricIdentifier;
  comparedEntry?: EvaluationBacktestEntry;
}) => {
  if (!selectedMetric) {
    return null;
  }

  const { grade, metric, comparedMetric } = getGradeForMetric(
    entry,
    selectedMetric,
    comparedEntry,
  );
  if (grade && metric) {
    return (
      <InvestigationGradeMetric
        grade={grade}
        metric={metric}
        comparedMetric={comparedMetric}
        comparisonFooter={
          comparedEntry ? (
            <BacktestEntryLinks entry={comparedEntry} />
          ) : undefined
        }
        className="p-0"
        showDescription={false}
      />
    );
  }

  return "—";
};

const getGradeForMetric = (
  entry: EvaluationBacktestEntry,
  selectedMetric: ScorecardMetricIdentifier,
  comparedEntry?: EvaluationBacktestEntry,
) => {
  const grade = entry.evaluation_scorecard?.grades?.find(
    (g) => g.name === selectedMetric.gradeName,
  );
  const metric = grade?.metrics?.find(
    (m) => m.name === selectedMetric.metricName,
  );

  const comparedMetric = comparedEntry?.evaluation_scorecard?.grades
    ?.find((g) => g.name === selectedMetric.gradeName)
    ?.metrics?.find((m) => m.name === selectedMetric.metricName);

  return { grade, metric, comparedMetric };
};

const BacktestEntryStatusBadge = ({
  entry,
}: {
  entry: EvaluationBacktestEntry;
}) => {
  let theme = BadgeTheme.Info;
  let icon = IconEnum.QuestionMark;
  let text = "Unknown";
  let tooltipContent: React.ReactNode;

  switch (entry.state) {
    case EvaluationBacktestEntryStateEnum.Pending:
      theme = BadgeTheme.Tertiary;
      icon = IconEnum.DottedCircle;
      text = "Pending";
      break;
    case EvaluationBacktestEntryStateEnum.Prepared:
    case EvaluationBacktestEntryStateEnum.Preparing:
      theme = BadgeTheme.Secondary;
      icon = IconEnum.Loader;
      text = "Running";
      break;
    case EvaluationBacktestEntryStateEnum.Errored:
      theme = BadgeTheme.Error;
      icon = IconEnum.Warning;
      text = "Errored";
      tooltipContent = entry.errors?.join(", ");
      break;
    case EvaluationBacktestEntryStateEnum.Scored:
      theme = BadgeTheme.Success;
      text = "Scored";
      icon = IconEnum.Tick;
      break;
  }
  return (
    <Tooltip content={tooltipContent}>
      <Badge theme={theme} icon={icon} size={BadgeSize.ExtraSmall}>
        {text}
      </Badge>
    </Tooltip>
  );
};
