import { TextNode } from "@incident-io/api";
import cx from "classnames";
import { orderBy } from "lodash";
import { Duration } from "luxon";
import { Fragment } from "react";

import {
  DurationEnum,
  translatedFormatDurationShort,
} from "../../time-helpers";
import { useTranslations } from "../../use-translations";
import { ContentBox } from "../ContentBox";
import { IncidentStatusIcon } from "../Icons/IncidentStatusIcon";
import { LocalDateTime } from "../LocalDateTime/LocalDateTime";
import { TemplatedText } from "../TemplatedText/TemplatedText";
import { useParseTime } from "../TimeContext";
import { Tooltip } from "../Tooltip";

type Update = {
  id: string;
  status_colour: "red" | "green" | "grey";
  status_name: string;
  message: TextNode | null;
  published_at: string;
};

type Props = {
  topExtra: React.ReactNode | null;
  updates: Update[];
  hasFurtherUpdates?: boolean;
  accessory?: React.ReactElement;
};

export const IncidentUpdates = ({
  updates: unorderedUpdates,
  topExtra,
  hasFurtherUpdates,
  accessory,
}: Props) => {
  const t = useTranslations("IncidentUpdates");
  const { parse } = useParseTime();

  const updates = orderBy(unorderedUpdates, "published_at", "desc");

  // The "Further updates will appear here" text is pretty useless
  // apart from if rendering an accessory next to it, e.g. "Request an update".
  // So only show if an accessory is provided.
  const showFurtherUpdatesAccessory = hasFurtherUpdates && accessory;

  return (
    <ContentBox
      title={
        <div className="flex items-center text-content-primary dark:text-slate-50 text-base font-medium">
          {t("updates_heading")}
        </div>
      }
      padded={false}
    >
      <div className="py-4 pr-4 pl-2" id="updates">
        {topExtra != null && (
          <div className="flex space-x-2">
            <ThreadedTimeline status="grey" />

            {topExtra}
          </div>
        )}
        {showFurtherUpdatesAccessory && (
          <div className="flex justify-between items-start">
            <div className="flex space-x-2 grow">
              <ThreadedTimeline status="grey" />

              <div className="text-sm mb-6 flex flex-col md:flex-row gap-2 grow justify-between">
                <p className="text-content-tertiary">{t("further_updates")}</p>
                {accessory}
              </div>
            </div>
          </div>
        )}
        {updates.map((update, idx) => {
          const isLast = updates.length === idx + 1;

          return (
            <Fragment key={update.id}>
              <div className="flex space-x-2" id={update.id}>
                <ThreadedTimeline
                  status={update.status_colour}
                  isBottomOfList={idx + 1 === updates.length}
                />

                <div className={cx("text-sm", !isLast && "mb-6")}>
                  <p className="font-medium">{update.status_name}</p>

                  <div className="my-3">
                    {update.message != null && (
                      <TemplatedText
                        value={update.message}
                        className="leading-normal"
                      />
                    )}
                  </div>

                  <div className="text-content-tertiary">
                    <Tooltip
                      content={parse(update.published_at).offsetNameLong}
                    >
                      <LocalDateTime
                        className={
                          "notranslate text-content-secondary dark:text-slate-500"
                        }
                        timestamp={parse(update.published_at)}
                      />
                      <TimeBetweenUpdates
                        timeAbove={updates[idx - 1]?.published_at}
                        timeBelow={update.published_at}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </ContentBox>
  );
};

// When the time between updates it less than this, we won't show a "Xh
// earlier..." helper
const SHORT_GAP = Duration.fromObject({ minutes: 10 });
const TimeBetweenUpdates = ({
  timeAbove: timeAboveD,
  timeBelow: timeBelowD,
}: {
  timeAbove?: string;
  timeBelow?: string;
}) => {
  const t = useTranslations("IncidentUpdates");
  const { parse } = useParseTime();

  if (!timeAboveD || !timeBelowD) {
    return null;
  }

  const timeAbove = parse(timeAboveD);
  const timeBelow = parse(timeBelowD);

  // The list is reverse-chronological, so the time above will be > time below
  const diff = timeAbove.diff(timeBelow);
  if (diff < SHORT_GAP) {
    return null;
  }

  return (
    <span
      className={cx(
        "text-sm pl-1",
        "text-content-tertiary dark:text-slate-600",
      )}
      dangerouslySetInnerHTML={{
        __html: `(${t.markup("duration_earlier", {
          duration: translatedFormatDurationShort(
            timeBelow,
            timeAbove,
            (k, opt) => {
              return t.markup(`durations.${k}`, {
                notranslate: (chunks) => `<var duration>${chunks}</var>`,
                count: opt?.count,
              });
            },
            {
              minInterval: DurationEnum.minutes,
              significantFigures: 1,
            },
          ),
        })})`,
      }}
    ></span>
  );
};

// ThreadedTimeline renders a dot and vertical line below it, to build a timeline
// of updates.
const ThreadedTimeline = ({
  status,
  isBottomOfList = false,
}: {
  status: "red" | "green" | "grey";
  isBottomOfList?: boolean;
}): React.ReactElement => {
  return (
    <div className="flex flex-col items-center w-4 flex-none">
      {/* Add some whitespace between the end of the previous line and the new dot */}
      <div className={cx("w-px h-[7px] flex-none", "border-transparent")} />

      {/* left dot */}
      {status === "green" && !isBottomOfList ? (
        // glowy dot if this is the first update in the list
        <>
          <div className="hidden dark:block">
            <IncidentStatusIcon
              className="w-4 h-4 -my-1.5 flex-none"
              flavour="ok"
            />
          </div>
          <div
            className={cx(
              "w-1.5 h-1.5 rounded-full flex-none dark:hidden",
              colourForStatus("green"),
            )}
          />
        </>
      ) : (
        // otherwise just a normal dot
        <div
          className={cx(
            "w-1.5 h-1.5 rounded-full flex-none",
            colourForStatus(status),
          )}
        />
      )}

      {/* Unless this is at the bottom of the list, continue the border down to reach the next update */}
      {/* Add some top margin to create whitespace between the dot and the line (which sits below) */}
      {!isBottomOfList && (
        <div
          className={cx("w-px grow mt-[7px]", borderForStatus(status))}
        ></div>
      )}
    </div>
  );
};

const colourForStatus = (status: "red" | "green" | "grey"): string => {
  if (status === "grey") return "bg-slate-300 dark:bg-slate-500";

  if (status === "green") return "bg-[#24C19A] dark:bg-[#1FA382]";

  return "bg-[#F87171] dark:bg-[#EF4444]";
};

const BORDER_RED = "bg-[#F87171] dark:bg-[#EF4444]";
const BORDER_GREEN = "bg-[#24C19A] dark:bg-[#1FA382]";
const BORDER_GREY = "bg-surface-secondary dark:bg-slate-900";

const borderForStatus = (status: "red" | "green" | "grey"): string => {
  if (status === "grey") return BORDER_GREY;

  if (status === "green") return BORDER_GREEN;

  return BORDER_RED;
};
