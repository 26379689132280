/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';
import {
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';

/**
 * 
 * @export
 * @interface TeamsListResponseBody
 */
export interface TeamsListResponseBody {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof TeamsListResponseBody
     */
    pagination_meta: PaginationMeta;
    /**
     * A list of teams
     * @type {Array<Team>}
     * @memberof TeamsListResponseBody
     */
    teams: Array<Team>;
}

export function TeamsListResponseBodyFromJSON(json: any): TeamsListResponseBody {
    return TeamsListResponseBodyFromJSONTyped(json, false);
}

export function TeamsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
        'teams': ((json['teams'] as Array<any>).map(TeamFromJSON)),
    };
}

export function TeamsListResponseBodyToJSON(value?: TeamsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
        'teams': ((value.teams as Array<any>).map(TeamToJSON)),
    };
}

