import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum, ModalFooter } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const RenameOrganisation = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.RenameOrganisation,
  );

  return (
    <>
      <div className="flex gap-2">
        <div className="font-medium text-lg text-content-primary">
          {data.organisation_name}
        </div>
        <GatedButton
          analyticsTrackingId={null}
          onClick={() => setIsOpen(true)}
          title="Rename organisation"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Edit}
          {...disabledProps}
        />
      </div>
      {isOpen && (
        <RenameOrganisationModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const RenameOrganisationModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ organisation_name: string }>({
    defaultValues: {
      organisation_name: data.organisation_name,
    },
  });

  const { trigger, isMutating, genericError } = useMutationV2(
    async (apiClient, formData) => {
      await apiClient.staffRenameOrganisation({
        organisationSlug: data.organisation_slug,
        renameOrganisationRequestBody: formData,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      genericError={genericError}
      title={`Rename ${data.organisation_name}`}
      onClose={onClose}
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Rename"
          confirmButtonType="submit"
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="organisation_name"
        label="Organisation name"
        helptext="This will be visible to the customer in the organisation switcher."
      />
    </Form.Modal>
  );
};
