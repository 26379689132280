/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface NudgesFixedConditionsRequestBody
 */
export interface NudgesFixedConditionsRequestBody {
    /**
     * What type of nudge is this
     * @type {string}
     * @memberof NudgesFixedConditionsRequestBody
     */
    nudge_type: NudgesFixedConditionsRequestBodyNudgeTypeEnum;
    /**
     * Any param bindings used to define the nudge in more detail
     * @type {Array<EngineParamBindingPayload>}
     * @memberof NudgesFixedConditionsRequestBody
     */
    param_bindings: Array<EngineParamBindingPayload>;
}

/**
* @export
* @enum {string}
*/
export enum NudgesFixedConditionsRequestBodyNudgeTypeEnum {
    Inactivity = 'inactivity',
    SendUpdate = 'send-update',
    Summary = 'summary',
    Triage = 'triage',
    CreateStatuspage = 'create-statuspage',
    UpdateStatuspage = 'update-statuspage',
    CustomField = 'custom-field',
    AssignRole = 'assign-role',
    IncidentTimestamp = 'incident-timestamp',
    CreateStream = 'create-stream',
    RunWorkflows = 'run-workflows',
    Escalate = 'escalate'
}

export function NudgesFixedConditionsRequestBodyFromJSON(json: any): NudgesFixedConditionsRequestBody {
    return NudgesFixedConditionsRequestBodyFromJSONTyped(json, false);
}

export function NudgesFixedConditionsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesFixedConditionsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nudge_type': json['nudge_type'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingPayloadFromJSON)),
    };
}

export function NudgesFixedConditionsRequestBodyToJSON(value?: NudgesFixedConditionsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nudge_type': value.nudge_type,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingPayloadToJSON)),
    };
}

