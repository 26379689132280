import { ScopeNameEnum, TeamsGetSettingsResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useOutlet } from "react-router";
import bannerGraphic from "src/components/settings/banners/banner-teams.png";

import { useAPI } from "../../../../utils/swr";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { TeamsList } from "./TeamsList";

export const TeamsListPage = (): React.ReactElement => {
  const drawer = useOutlet();

  const {
    data: teamSettingsData,
    isLoading: teamSettingsLoading,
    error: teamSettingsError,
  } = useAPI("teamsGetSettings", undefined);

  if (teamSettingsLoading) {
    return <Loader />;
  }

  if (teamSettingsError) {
    return <GenericErrorMessage error={teamSettingsError} />;
  }

  const hasTeamTypeConfigured = !!teamSettingsData?.team_settings.catalog_type;

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsSubPageWrapper
        wrapperClassName={"h-full"}
        accessory={<AddTeamButton teamSettingsData={teamSettingsData} />}
      >
        <SettingsHeading
          // TODO: https://linear.app/incident-io/issue/ONC-5592/write-a-help-centre-article
          articleId={"4629305061"}
          title="Teams"
          subtitle="Teams allow you to see what is relevant to you in your dashboard and simplify your configuration."
          graphic={<img src={bannerGraphic} className="h-40" />}
        />
        <div className={"h-full flex flex-col"}>
          {hasTeamTypeConfigured ? <TeamsList /> : <TeamsEmptyState />}
        </div>
      </SettingsSubPageWrapper>
    </>
  );
};

const TeamsEmptyState = () => {
  const navigate = useOrgAwareNavigate();

  const {
    data: catalogConfigData,
    isLoading: catalogConfigLoading,
    error: catalogConfigError,
  } = useAPI("catalogGetCatalogConfig", {});

  if (catalogConfigLoading) {
    return <Loader />;
  }

  if (catalogConfigError) {
    return <GenericErrorMessage error={catalogConfigError} />;
  }

  const needsTeamCatalogType =
    catalogConfigData?.config.team_types.length === 0;

  return (
    <EmptyState
      icon={IconEnum.Team}
      title={"Add your teams in incident.io"}
      content={
        <>
          Create your teams using an existing catalog type, and instantly unlock
          your tailored dashboard and other on-call features.
        </>
      }
      className={"h-full"}
      cta={
        <div>
          <Button
            analyticsTrackingId={"teams-get-started"}
            onClick={
              needsTeamCatalogType
                ? () => navigate("/catalog/team-wizard")
                : () => navigate("/settings/teams/get-started")
            }
            theme={ButtonTheme.Primary}
            openInNewTab={needsTeamCatalogType}
          >
            Get started
          </Button>
          <div className={"grow"} />
        </div>
      }
    />
  );
};

const AddTeamButton = ({
  teamSettingsData,
}: {
  teamSettingsData?: TeamsGetSettingsResponseBody;
}) => {
  const navigate = useOrgAwareNavigate();

  // If the team type has not been configured yet, don't show the button
  if (!teamSettingsData?.team_settings.catalog_type) {
    return null;
  }

  // If the catalog type is managed in code, disable the button and explain why
  const sourceRepoUrl =
    teamSettingsData.team_settings.catalog_type.source_repo_url;

  return (
    <>
      <GatedButton
        onClick={() => navigate("/settings/teams/create")}
        requiredScope={ScopeNameEnum.CatalogEntriesCreate}
        analyticsTrackingId={"teams-create"}
        icon={IconEnum.Add}
        theme={ButtonTheme.Primary}
        disabled={!!sourceRepoUrl}
        disabledTooltipContent={`Your team list is managed in code (${sourceRepoUrl}). You can only add a team there!`}
      >
        Create team
      </GatedButton>
    </>
  );
};
