import {
  AlertRoute,
  AlertRouteAlertSourcePayload,
  AlertRouteChannelTarget,
  AlertRouteCustomFieldBinding,
  AlertRouteCustomFieldBindingPayloadMergeStrategyEnum,
  AlertRouteEscalationTargetPayload,
  AlertRouteIncidentTemplatePayload,
  AlertRoutesCreateAlertRouteRequestBody,
  AlertRouteSeverityBindingPayloadMergeStrategyEnum,
  AlertRouteSlim,
  CatalogType,
  ConditionGroup,
  ConditionSubjectIconEnum,
  CustomField,
  CustomFieldFieldTypeEnum,
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
  Resource,
} from "@incident-io/api";
import { isEmptyBinding } from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionDeletionUsages } from "@incident-shared/engine/expressions/ExpressionsEditor";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";

import { CommsPlatform } from "../../../../hooks/usePrimaryCommsPlatform";
import { purgeEmpty } from "../../../../utils/utils";
import { containsExpressionReference } from "../../../legacy/workflows/common/utils";
import { resourceTypeForCustomField } from "../create-incident/CreateIncidentSection";

export type AlertRouteFormData = {
  enabled: boolean;
  is_private: boolean;
  version?: number;
} & AlertRouteTitleFormData &
  ChooseAlertSourcesFormData &
  FilterFormData &
  CreateIncidentsFormData &
  EscalateFormData &
  ChannelMessagesFormData;

export type ChooseAlertSourcesFormData = {
  alertSources: {
    [id: string]: {
      condition_groups: ConditionGroup[];
      selected: boolean;
    };
  };
  expressions: ExpressionFormData[];
};

export type FilterFormData = {
  hasEverConfiguredFilters: boolean;
  conditionGroups: Array<ConditionGroup>;
  expressions: ExpressionFormData[];
};

export type CustomFieldBindingWithAutoSet = {
  binding: EngineParamBindingPayload;
  merge_strategy: AlertRouteCustomFieldBindingPayloadMergeStrategyEnum;
  was_automatically_set?: boolean;
};

// Replace the old custom_fields type with our array type
export type IncidentTemplateFormData = Omit<
  AlertRouteIncidentTemplatePayload,
  "custom_fields"
> & {
  custom_fields?: Record<string, CustomFieldBindingWithAutoSet>;
};

export type CreateIncidentsFormData = {
  hasEverConfiguredIncidents: boolean;
  enableIncidents?: boolean;
  incidentConditionGroups: Array<ConditionGroup>;

  // grouping content
  enableGrouping?: boolean;
  groupingKeys: Array<{ key: string; label: string }>;
  groupingWindow: string;
  deferTime: number;

  template?: IncidentTemplateFormData;

  autoDeclineEnabled: boolean;
  expressions: ExpressionFormData[];
};

export type EscalateBindingTarget = "escalation_paths" | "users";
export type EscalateBindingType = "dynamic" | "static";
export type EscalationBindingFormType = {
  mode: EscalateBindingType;
  target: EscalateBindingTarget;
  binding?: EngineParamBindingPayload;
};

export type EscalateFormData = {
  hasEverConfiguredEscalations: boolean;
  enableEscalations: boolean;
  escalationBindings: EscalationBindingFormType[];
  autoCancelEscalations: boolean;
  expressions: ExpressionFormData[];
};

export type ChannelMessagesFormData = {
  hasEverConfiguredAlertChannelMessages: boolean;
  enableAlertChannelMessages: boolean;
  expressions: ExpressionFormData[];
  channelConfigs: (
    | {
        primary_comms_platform: "slack";
        condition_groups: ConditionGroup[];
        slack_targets: AlertRouteChannelTarget;
      }
    | {
        primary_comms_platform: "msteams";
        condition_groups: ConditionGroup[];
        ms_teams_targets: AlertRouteChannelTarget;
      }
  )[];
};

export type AlertRouteTitleFormData = {
  name: string;
};

export type AlertRouteEngineFormProps = {
  resources: Resource[];
  scope: EngineScope;
  array: boolean;
  required: boolean;
  showPlaceholder: boolean;
  disabled?: boolean;
  mode: "variables_and_expressions" | "variables_only";
};

const convertCustomFieldsToFormState = (
  customFields?: AlertRouteCustomFieldBinding[],
): Record<string, CustomFieldBindingWithAutoSet> => {
  if (!customFields) {
    return {};
  }

  return customFields.reduce((acc, customField) => {
    return {
      ...acc,
      [customField.custom_field_id]: {
        binding: customField.binding,
        merge_strategy:
          String(customField.merge_strategy) === "append"
            ? AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.Append
            : String(customField.merge_strategy) === "last-wins"
            ? AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.LastWins
            : AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.FirstWins,
        was_automatically_set: true,
      },
    };
  }, {});
};

/**
 * Helper function to find severity binding from expressions
 *
 * This checks if any expression has a return type of IncidentSeverity and
 * if so, creates a binding with a reference to that expression.
 */
const findSeverityBindingFromExpressions = (
  expressions: ExpressionFormData[],
  existingBinding?: EngineParamBindingPayload,
): EngineParamBindingPayload | undefined => {
  // If there's an existing binding with a value or array_value, use it
  if (
    existingBinding &&
    (existingBinding.value || existingBinding.array_value)
  ) {
    return existingBinding;
  }

  // Look for a severity expression
  const severityExpression = expressions.find(
    (exp) => exp.returns?.type === "IncidentSeverity" && !exp.returns.array,
  );

  // If we found a severity expression, create a binding
  if (severityExpression) {
    const binding = {
      value: {
        reference: `expressions["${severityExpression.reference}"]`,
        label: severityExpression.label,
      },
    };
    return binding;
  }

  return existingBinding;
};

export const parseAlertRoute = (
  alertRoute: AlertRoute,
  initialSourceID: string | null,
  primaryCommsPlatform: CommsPlatform,
): AlertRouteFormData => {
  const alertSources = {};

  alertRoute.alert_sources.forEach((source) => {
    alertSources[source.alert_source_id] = {
      condition_groups: source.condition_groups,
      selected: true,
    };
  });

  if (initialSourceID) {
    alertSources[initialSourceID] = {
      condition_groups: [],
      selected: true,
    };
  }

  const escalationBindings = alertRoute.escalation_config.escalation_targets
    .map((binding): EscalationBindingFormType | null => {
      const getMode = (e: EngineParamBinding): EscalateBindingType => {
        // If there are references (e.g. expressions or variables) then show 'dynamic',
        // otherwise show 'static'
        return [e.value, ...(e.array_value ?? [])].some((b) => !!b?.reference)
          ? "dynamic"
          : "static";
      };

      if (binding.escalation_paths) {
        return {
          binding: binding.escalation_paths,
          mode: getMode(binding.escalation_paths),
          target: "escalation_paths",
        };
      } else if (binding.users) {
        return {
          binding: binding.users,
          mode: getMode(binding.users),
          target: "users",
        };
      }

      return null;
    })
    .filter(Boolean) as EscalationBindingFormType[];

  return {
    name: alertRoute.name,
    enabled: alertRoute.enabled,
    is_private: alertRoute.is_private,
    version: alertRoute.version,
    hasEverConfiguredEscalations: true,
    hasEverConfiguredFilters: true,
    hasEverConfiguredIncidents: true,
    hasEverConfiguredAlertChannelMessages: true,
    // If this is 0, it means the grouping is disabled, so it gets set to the default
    // in case the user wants to enable it. It will be reset to 0 if disabled on submit.
    groupingWindow:
      alertRoute.incident_config.grouping_window_seconds > 0
        ? alertRoute.incident_config.grouping_window_seconds.toString()
        : "1800",
    groupingKeys: alertRoute.incident_config.grouping_keys.flatMap((x) => {
      return { key: x.reference, label: x.engine_info?.label || "" };
    }),
    enableGrouping: alertRoute.incident_config.grouping_window_seconds > 0,
    conditionGroups: alertRoute.condition_groups,
    deferTime: alertRoute.incident_config.defer_time_seconds / 60,
    // response body is a superset of request body, this is fine
    template: {
      ...(alertRoute.incident_template as unknown as AlertRouteIncidentTemplatePayload),
      custom_fields:
        alertRoute.incident_template?.custom_fields &&
        alertRoute.incident_template?.custom_fields?.length > 0
          ? convertCustomFieldsToFormState(
              alertRoute.incident_template?.custom_fields,
            )
          : undefined,
      start_in_triage: alertRoute?.incident_template?.start_in_triage ?? {
        binding: {
          value: {
            literal: "true",
          },
        },
      },
      severity: {
        binding: findSeverityBindingFromExpressions(
          alertRoute.expressions || [],
          alertRoute.incident_template?.severity?.binding,
        ),
        merge_strategy: safeSeverityMergeStrategy(
          alertRoute.incident_template?.severity?.merge_strategy ||
            "first-wins",
        ),
      },
    },
    expressions: alertRoute.expressions || [],
    autoDeclineEnabled: alertRoute.incident_config.auto_decline_enabled,
    enableIncidents: alertRoute.incident_config.enabled,
    incidentConditionGroups: alertRoute.incident_config.condition_groups,
    // Right now, the frontend only ever shows one escalation binding
    escalationBindings,
    alertSources,
    enableEscalations:
      alertRoute.escalation_config.escalation_targets.length > 0,
    autoCancelEscalations: alertRoute.escalation_config.auto_cancel_escalations,
    enableAlertChannelMessages: !!alertRoute.channel_config,
    channelConfigs:
      alertRoute.channel_config && alertRoute.channel_config.length > 0
        ? alertRoute.channel_config.map((config) => {
            if (
              config.slack_targets &&
              !isEmptyBinding(config.slack_targets.binding)
            ) {
              return {
                condition_groups: config.condition_groups || [],
                primary_comms_platform: "slack",
                slack_targets: config.slack_targets,
              };
            } else if (
              config.ms_teams_targets &&
              !isEmptyBinding(config.ms_teams_targets.binding)
            ) {
              return {
                condition_groups: config.condition_groups || [],
                primary_comms_platform: "msteams",
                ms_teams_targets: config.ms_teams_targets,
              };
            }
            // Fallback to default based on primary comms platform
            return primaryCommsPlatform === "slack"
              ? {
                  condition_groups: [],
                  primary_comms_platform: "slack",
                  slack_targets: {
                    binding: { array_value: [] },
                    channel_visibility: "public",
                  },
                }
              : {
                  condition_groups: [],
                  primary_comms_platform: "msteams",
                  ms_teams_targets: {
                    binding: { array_value: [] },
                    channel_visibility: "public",
                  },
                };
          })
        : primaryCommsPlatform === "slack"
        ? [
            {
              condition_groups: [],
              primary_comms_platform: "slack",
              slack_targets: {
                binding: {
                  array_value: [],
                },
                channel_visibility: "public",
              },
            },
          ]
        : [
            {
              condition_groups: [],
              primary_comms_platform: "msteams",
              ms_teams_targets: {
                binding: {
                  array_value: [],
                },
                channel_visibility: "public",
              },
            },
          ],
  };
};

export const safeConvertMergeStrategy = (
  strategy: string | undefined,
): AlertRouteCustomFieldBindingPayloadMergeStrategyEnum => {
  // Default to first-wins if undefined or invalid
  if (!strategy) {
    return AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.FirstWins;
  }

  // Use string comparison to avoid enum type issues
  switch (strategy) {
    case "append":
      return AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.Append;
    case "last-wins":
      return AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.LastWins;
    default:
      return AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.FirstWins;
  }
};

/**
 * Convert between string severity merge strategy values and enum types
 *
 * This function safely converts between string severity merge strategy values and the
 * AlertRouteSeverityBindingPayloadMergeStrategyEnum enum used in the API.
 */
export const safeSeverityMergeStrategy = (
  severityPriority: string | undefined,
): AlertRouteSeverityBindingPayloadMergeStrategyEnum => {
  if (severityPriority === "max") {
    return AlertRouteSeverityBindingPayloadMergeStrategyEnum.Max;
  }

  return AlertRouteSeverityBindingPayloadMergeStrategyEnum.FirstWins;
};

export const parseFormData = (
  formData: AlertRouteFormData,
  customFields: CustomField[],
): AlertRoutesCreateAlertRouteRequestBody => {
  const conditionGroupsString = JSON.stringify(formData.conditionGroups);
  const incidentConditionsString = JSON.stringify(
    formData.incidentConditionGroups,
  );
  const formIncidentTemplate = parseIncidentTemplateData(
    purgeEmpty(formData.template),
  );
  if (formIncidentTemplate) {
    if (formIncidentTemplate.name) {
      formIncidentTemplate.name.autogenerated =
        formData.template?.name?.autogenerated || false;
    }
    if (formIncidentTemplate.summary) {
      formIncidentTemplate.summary.autogenerated =
        formData.template?.summary?.autogenerated || false;
    }
  }

  const baseFormData = {
    alert_sources: alertSourcesToPayload(formData.alertSources),
    condition_groups: conditionGroupsToGroupPayloads(
      formData.conditionGroups || [],
    ),
    enabled: formData.enabled,
    expressions: formData.expressions
      ?.filter((e) => {
        // Remove any expressions that don't have any usages
        const usageTypes = getAlertRouteExpressionUsages(
          e,
          formData,
          customFields,
          formData.escalationBindings,
        );

        // Or if the expression is used in a condition, it should be included
        if (
          conditionGroupsString.includes(e.reference) ||
          incidentConditionsString.includes(e.reference)
        ) {
          return true;
        }

        const sourceContainsExpression = Object.values(
          formData.alertSources,
        ).some((source) => {
          const sourceConditionString = JSON.stringify(
            source.condition_groups || [],
          );
          return sourceConditionString.includes(e.reference);
        });

        if (sourceContainsExpression) {
          return true;
        }

        return usageTypes.some((u) => u.usages.length > 0);
      })
      .map(expressionToPayload),
    name: formData.name,
    is_private: formData.is_private,
    incident_template: {
      ...formIncidentTemplate,
    },
    escalation_config: {
      auto_cancel_escalations: formData.autoCancelEscalations,
      escalation_targets: [],
    },
    incident_config: {
      auto_decline_enabled: formData.autoDeclineEnabled,
      condition_groups: formData.enableIncidents
        ? conditionGroupsToGroupPayloads(formData.incidentConditionGroups || [])
        : [],
      defer_time_seconds: formData.deferTime * 60,
      enabled: formData.enableIncidents || false,
      grouping_window_seconds: formData.enableGrouping
        ? parseInt(formData.groupingWindow)
        : 0,
      grouping_keys: formData.enableGrouping
        ? formData.groupingKeys.map((x) => {
            return { reference: x.key };
          })
        : [],
    },
  };

  let parsedFormData: AlertRoutesCreateAlertRouteRequestBody;
  if (formData.version !== undefined) {
    parsedFormData = {
      ...baseFormData,
      version: formData.version + 1,
    };
  } else {
    parsedFormData = {
      ...baseFormData,
      version: 0,
    };
  }
  if (formData.enableEscalations) {
    parsedFormData.escalation_config.escalation_targets =
      formData.escalationBindings.map(
        (b): AlertRouteEscalationTargetPayload => {
          if (b.target === "escalation_paths") {
            return {
              escalation_paths: b.binding,
            };
          }

          return {
            users: b.binding,
          };
        },
      );
  }

  if (formData.enableAlertChannelMessages) {
    parsedFormData.channel_config = formData.channelConfigs.map(
      (channelBinding) => {
        return channelBinding.primary_comms_platform === "slack"
          ? {
              condition_groups: conditionGroupsToGroupPayloads(
                channelBinding.condition_groups || [],
              ),
              slack_targets: channelBinding.slack_targets,
            }
          : {
              condition_groups: conditionGroupsToGroupPayloads(
                channelBinding.condition_groups || [],
              ),
              ms_teams_targets: channelBinding.ms_teams_targets,
            };
      },
    );
  }
  return parsedFormData;
};

export const alertSourcesToPayload = (
  alertSources: ChooseAlertSourcesFormData["alertSources"],
): AlertRouteAlertSourcePayload[] => {
  return Object.entries(alertSources)
    .filter(([_, value]) => value.selected)
    .map(([id, value]) => ({
      alert_source_id: id,
      condition_groups: conditionGroupsToGroupPayloads(
        value.condition_groups || [],
      ),
    }));
};

export const parseIncidentTemplateData = (
  template?: IncidentTemplateFormData,
  aiConfig?: {
    name_autogenerate: boolean;
    summary_autogenerate: boolean;
  },
): AlertRouteIncidentTemplatePayload | undefined => {
  if (!template) {
    return undefined;
  }

  if (template.severity && template.severity.binding === undefined) {
    template = {
      ...template,
      severity: {
        ...template.severity,
        binding: undefined,
      },
    };
  }

  const processedTemplate: AlertRouteIncidentTemplatePayload = {
    name: {
      binding:
        template.name &&
        template.name.binding &&
        isEmptyBinding(template.name.binding)
          ? undefined
          : template.name?.binding,
      autogenerated:
        aiConfig?.name_autogenerate || template.name?.autogenerated || false,
    },
    summary: {
      binding:
        template.summary &&
        template.summary.binding &&
        isEmptyBinding(template.summary.binding)
          ? undefined
          : template.summary?.binding,
      autogenerated:
        aiConfig?.summary_autogenerate ||
        template.summary?.autogenerated ||
        false,
    },
    incident_type:
      template.incident_type &&
      template.incident_type.binding &&
      isEmptyBinding(template.incident_type.binding)
        ? undefined
        : template.incident_type,
    incident_mode:
      template.incident_mode &&
      template.incident_mode.binding &&
      isEmptyBinding(template.incident_mode.binding)
        ? undefined
        : template.incident_mode,
    workspace:
      template.workspace &&
      template.workspace.binding &&
      isEmptyBinding(template.workspace.binding)
        ? undefined
        : template.workspace,
    start_in_triage: template.start_in_triage,
    severity: template.severity
      ? {
          binding: template.severity.binding
            ? isEmptyBinding(template.severity.binding)
              ? undefined
              : {
                  value: template.severity.binding.value,
                  array_value: template.severity.binding.array_value,
                }
            : undefined,
          merge_strategy: template.severity.merge_strategy,
        }
      : undefined,
  };

  if (template.custom_fields) {
    const validCustomFields = Object.entries(template.custom_fields).filter(
      ([_, cf]) => !isEmptyBinding(cf.binding),
    );

    if (validCustomFields.length > 0) {
      processedTemplate.custom_fields = validCustomFields.map(([id, cf]) => ({
        custom_field_id: id,
        binding: cf.binding,
        merge_strategy: cf.merge_strategy,
      }));
    }
  }

  return processedTemplate;
};

export enum AlertRouteCreationSource {
  StatusPages = "status-pages",
}

export const findApplicableCustomFields = ({
  customFields,
  catalogTypes,
  scope,
}: {
  customFields: CustomField[];
  catalogTypes: CatalogType[];
  scope: EngineScope;
}) => {
  const defaultCustomFields: Record<string, CustomFieldBindingWithAutoSet> = {};

  for (const customField of customFields) {
    const resourceType = resourceTypeForCustomField(customField, catalogTypes);
    const applicableRefs = scope.references.filter(
      (r) => r.type === resourceType,
    );

    if (customField.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
      const ref = applicableRefs.find((r) => r.array);
      if (ref) {
        defaultCustomFields[customField.id] = {
          custom_field_id: customField.id,
          binding: {
            array_value: [
              {
                reference: ref.key,
                // The frontend actually supports using this as a label too
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label: ref.label,
              },
            ],
          },
          merge_strategy:
            AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.Append,
          was_automatically_set: true,
        };
      }
    } else {
      const ref = applicableRefs.find((r) => !r.array);
      if (ref) {
        defaultCustomFields[customField.id] = {
          custom_field_id: customField.id,
          binding: {
            value: {
              reference: ref.key,
              // The frontend actually supports using this as a label too
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label: ref.label,
            },
          },
          merge_strategy:
            AlertRouteCustomFieldBindingPayloadMergeStrategyEnum.FirstWins,
          was_automatically_set: true,
        };
      }
    }
  }

  return defaultCustomFields;
};

export const getDefaultAlertRouteValues = (
  scope: EngineScope,
  customFields: CustomField[],
  initialSourceID: string | null,
  existingAlertRoutes: AlertRouteSlim[],
  catalogTypes: CatalogType[],
  source: AlertRouteCreationSource | undefined,
  aiEnabled: boolean,
  primaryCommsPlatform: CommsPlatform,
): AlertRouteFormData => {
  const defaultCustomFields = findApplicableCustomFields({
    customFields: customFields,
    catalogTypes: catalogTypes,
    scope: scope,
  });

  for (const customField of Object.keys(defaultCustomFields)) {
    defaultCustomFields[customField].was_automatically_set = true;
  }

  let defaults: AlertRouteFormData = {
    name:
      existingAlertRoutes.length > 0
        ? `Alert route ${existingAlertRoutes.length + 1}`
        : "Default alert route",
    enabled: true,
    is_private: false,
    hasEverConfiguredEscalations: false,
    hasEverConfiguredFilters: false,
    hasEverConfiguredIncidents: false,
    incidentConditionGroups: [],
    groupingKeys: [],
    conditionGroups: [],
    groupingWindow: "1800",
    enableGrouping: true,
    expressions: [],
    deferTime: 2,
    template: {
      name: {
        binding: defaultIncidentTemplateName,
        autogenerated: aiEnabled,
      },
      severity: {
        merge_strategy:
          AlertRouteSeverityBindingPayloadMergeStrategyEnum.FirstWins,
      },
      start_in_triage: {
        binding: {
          value: {
            literal: "true",
          },
        },
      },
      custom_fields: defaultCustomFields,
    },
    escalationBindings: [
      {
        mode: "dynamic",
        target: "escalation_paths",
      },
    ],
    autoDeclineEnabled: false,
    alertSources: initialSourceID
      ? { [initialSourceID]: { selected: true, condition_groups: [] } }
      : {},
    enableEscalations: false,
    autoCancelEscalations: false,
    hasEverConfiguredAlertChannelMessages: false,
    enableAlertChannelMessages: false,
    channelConfigs:
      primaryCommsPlatform === "slack"
        ? [
            {
              condition_groups: [],
              primary_comms_platform: "slack",
              slack_targets: {
                binding: {
                  array_value: [],
                },
                channel_visibility: "public",
              },
            },
          ]
        : [
            {
              condition_groups: [],
              primary_comms_platform: "msteams",
              ms_teams_targets: {
                binding: {
                  array_value: [],
                },
                // Teams only supports public channels
                channel_visibility: "public",
              },
            },
          ],
  };

  // this is a lot of hard coding - we decided it wasn't worth any of the effort or data wrangling
  // it would take to not hard code it, we think this is pretty static and safe so it's fine
  const statusPageDefaults: Partial<AlertRouteFormData> = {
    name: "High status page views",
    conditionGroups: [
      {
        conditions: [
          {
            operation: {
              label: "is one of",
              value: "one_of",
            },
            params: [
              {
                array: true,
                description: "",
                infer_reference: false,
                label: "set",
                name: "set",
                optional: false,
                type: "AlertSourceType",
              },
            ],
            param_bindings: [
              {
                array_value: [
                  {
                    label: "Status Page Views",
                    value: "status_page_views",
                    literal: "status_page_views",
                    sort_key: "Status Page Views",
                  },
                ],
              },
            ],
            subject: {
              label: "Alert → Source type",
              icon: "alert" as unknown as ConditionSubjectIconEnum,
              reference: "alert.source_type",
            },
          },
        ],
      },
    ],
    // 3 hours - status pages are probably spiking for a while after an issue as everyone processes their notifs
    groupingWindow: "10800",
  };

  if (source === AlertRouteCreationSource.StatusPages) {
    defaults = { ...defaults, ...statusPageDefaults };
  }

  return defaults;
};

const defaultIncidentTemplateName: EngineParamBinding = {
  value: {
    label: "title",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              attrs: {
                label: "Alert -> Title",
                missing: false,
                name: "alert.title",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key: "title",
  },
};

export const getAlertRouteExpressionUsages = (
  expression: ExpressionFormData,
  formData: AlertRouteFormData,
  customFields: CustomField[],
  escalationBindings: EscalationBindingFormType[],
): ExpressionDeletionUsages => {
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);
  const expressionReference = makeExpressionReference(expression);
  // In order to find usages, we're going to just stringify each section
  // of the form and look for our expression reference in the JSON.
  const usedInIncidentType =
    formData.template?.incident_type &&
    containsExpressionRef(formData.template.incident_type);
  const usedInSeverity =
    formData.template?.severity &&
    containsExpressionRef(formData.template.severity);
  const usedInStartInTriage =
    formData.template?.start_in_triage &&
    containsExpressionRef(formData.template.start_in_triage);
  const customFieldUses =
    formData.template?.custom_fields &&
    Object.entries(formData.template.custom_fields).filter(
      ([_, customField]) =>
        customField.binding?.value?.reference === expressionReference ||
        customField.binding?.array_value?.some(
          (val) => val.reference === expressionReference,
        ),
    );

  const usedInIncidentMode =
    formData.template?.incident_mode &&
    containsExpressionRef(formData.template.incident_mode);

  const escalationUses = escalationBindings.some(
    (e) =>
      e?.binding?.value?.reference === expressionReference ||
      e?.binding?.array_value?.some(
        (val) => val.reference === expressionReference,
      ),
  );

  const usedInName =
    formData.template?.name && containsExpressionRef(formData.template.name);
  const usedInSummary =
    formData.template?.summary &&
    containsExpressionRef(formData.template.summary);

  const usedInWorkspace =
    formData.template?.workspace &&
    containsExpressionRef(formData.template.workspace);

  const usedInPrioritySeverity =
    formData.template?.severity &&
    containsExpressionRef(formData.template.severity.binding);

  const useInChannelConfig = formData.channelConfigs.some((config) => {
    if (config.primary_comms_platform === "slack") {
      return containsExpressionRef(config.slack_targets);
    } else {
      return containsExpressionRef(config.ms_teams_targets);
    }
  });

  const usages: string[] = [];
  if (usedInIncidentType) {
    usages.push("Incident type");
  }
  if (usedInIncidentMode) {
    usages.push("Incident mode");
  }
  if (usedInStartInTriage) {
    usages.push("Start in triage");
  }
  if (usedInSeverity) {
    usages.push("Severity");
  }
  if (usedInName) {
    usages.push("Name");
  }
  if (usedInSummary) {
    usages.push("Summary");
  }
  if (usedInWorkspace) {
    usages.push("Workspace");
  }
  if (usedInPrioritySeverity) {
    usages.push("Priority Severity");
  }
  if (customFieldUses) {
    usages.push(
      ...customFieldUses.map((customField, index) => {
        const field = customFields.find((cf) => cf.id === customField[0]);
        return field?.name ?? `Custom field ${index}`;
      }),
    );
  }

  if (useInChannelConfig) {
    usages.push("Channel config");
  }

  const escalationUsages: string[] = [];
  if (escalationUses) {
    escalationUsages.push("Who should we escalate to?");
  }

  const channelUsages: string[] = [];
  if (useInChannelConfig) {
    channelUsages.push("Alert channel destination");
  }

  return [
    { label: "Incident fields", usages: usages },
    { label: "Escalation fields", usages: escalationUsages },
    { label: "Channel fields", usages: channelUsages },
  ];
};
