import { StaffPermission, UserWithStaffPermissions } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Avatar,
  ButtonTheme,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useState } from "react";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";

import { EditPermissionsModal } from "./EditPermissionsModal";

export const StaffUserRow = ({ user }: { user: UserWithStaffPermissions }) => {
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.GrantStaffPermission,
  );
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <tr>
        <td className={"flex flex-row items-center gap-2"}>
          <Avatar size={IconSize.Large} url={user.avatar_url} />
          <div>
            <div className={"flex flex-row flex-center-y"}>
              <Txt grey bold>
                {user.name}
              </Txt>
            </div>
            <div className={"text-xs text-content-tertiary"}>{user.email}</div>
          </div>
        </td>
        <td>
          <UserPermissions permissions={user.permissions} />
        </td>
        <td>
          <GatedButton
            className={"ml-3"}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Edit}
            title="Change permissions"
            analyticsTrackingId={null}
            onClick={() => setIsEditing(true)}
            {...disabledProps}
          />
        </td>
      </tr>
      {isEditing && (
        <EditPermissionsModal user={user} onClose={() => setIsEditing(false)} />
      )}
    </>
  );
};

const UserPermissions = ({
  permissions,
}: {
  permissions: StaffPermission[];
}) => {
  return (
    <div className={"flex flex-wrap gap-1"}>
      {permissions.map((permission) => {
        return (
          <Txt
            key={permission.id}
            className={
              "px-2 py-1 bg-alarmalade-100 rounded text-alarmalade-600 text-2xs"
            }
            grey
          >
            {permission.name}
            <Tooltip content={permission.description} />
          </Txt>
        );
      })}
    </div>
  );
};
