/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruthCodeChange,
    IncidentGroundTruthCodeChangeFromJSON,
    IncidentGroundTruthCodeChangeFromJSONTyped,
    IncidentGroundTruthCodeChangeToJSON,
} from './IncidentGroundTruthCodeChange';
import {
    IncidentGroundTruthRelatedIncidentPayload,
    IncidentGroundTruthRelatedIncidentPayloadFromJSON,
    IncidentGroundTruthRelatedIncidentPayloadFromJSONTyped,
    IncidentGroundTruthRelatedIncidentPayloadToJSON,
} from './IncidentGroundTruthRelatedIncidentPayload';
import {
    IncidentGroundTruthRelatedMessage,
    IncidentGroundTruthRelatedMessageFromJSON,
    IncidentGroundTruthRelatedMessageFromJSONTyped,
    IncidentGroundTruthRelatedMessageToJSON,
} from './IncidentGroundTruthRelatedMessage';
import {
    IncidentGroundTruthTelemetryDashboard,
    IncidentGroundTruthTelemetryDashboardFromJSON,
    IncidentGroundTruthTelemetryDashboardFromJSONTyped,
    IncidentGroundTruthTelemetryDashboardToJSON,
} from './IncidentGroundTruthTelemetryDashboard';

/**
 * 
 * @export
 * @interface AIStaffUpdateIncidentGroundTruthRequestBody
 */
export interface AIStaffUpdateIncidentGroundTruthRequestBody {
    /**
     * List of code changes that caused this incident
     * @type {Array<IncidentGroundTruthCodeChange>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes?: Array<IncidentGroundTruthCodeChange>;
    /**
     * When code changes were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes_verified_at?: string;
    /**
     * Whether the investigation findings were useful to responders ('not_relevant', 'relevant', 'critical')
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance?: string;
    /**
     * Explanation of why the investigation was or wasn't useful
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_reasoning?: string;
    /**
     * Tags describing how the investigation was useful (e.g. 'code-change', 'telemetry')
     * @type {Array<string>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_tags?: Array<string>;
    /**
     * When investigation relevance was human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_verified_at?: string;
    /**
     * List of incidents that were related to this incident
     * @type {Array<IncidentGroundTruthRelatedIncidentPayload>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_incidents?: Array<IncidentGroundTruthRelatedIncidentPayload>;
    /**
     * Next steps to take in relation to this incident
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_incidents_next_steps?: string;
    /**
     * When related incidents were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_incidents_verified_at?: string;
    /**
     * List of messages that were helpful to resolving this incident
     * @type {Array<IncidentGroundTruthRelatedMessage>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_messages?: Array<IncidentGroundTruthRelatedMessage>;
    /**
     * When related messages were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_messages_verified_at?: string;
    /**
     * List of telemetry dashboards that were relevant to this incident
     * @type {Array<IncidentGroundTruthTelemetryDashboard>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_telemetry_dashboards?: Array<IncidentGroundTruthTelemetryDashboard>;
    /**
     * When telemetry dashboards were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    related_telemetry_dashboards_verified_at?: string;
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyFromJSON(json: any): AIStaffUpdateIncidentGroundTruthRequestBody {
    return AIStaffUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateIncidentGroundTruthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causing_code_changes': !exists(json, 'causing_code_changes') ? undefined : ((json['causing_code_changes'] as Array<any>).map(IncidentGroundTruthCodeChangeFromJSON)),
        'causing_code_changes_verified_at': !exists(json, 'causing_code_changes_verified_at') ? undefined : json['causing_code_changes_verified_at'],
        'investigation_relevance': !exists(json, 'investigation_relevance') ? undefined : json['investigation_relevance'],
        'investigation_relevance_reasoning': !exists(json, 'investigation_relevance_reasoning') ? undefined : json['investigation_relevance_reasoning'],
        'investigation_relevance_tags': !exists(json, 'investigation_relevance_tags') ? undefined : json['investigation_relevance_tags'],
        'investigation_relevance_verified_at': !exists(json, 'investigation_relevance_verified_at') ? undefined : json['investigation_relevance_verified_at'],
        'related_incidents': !exists(json, 'related_incidents') ? undefined : ((json['related_incidents'] as Array<any>).map(IncidentGroundTruthRelatedIncidentPayloadFromJSON)),
        'related_incidents_next_steps': !exists(json, 'related_incidents_next_steps') ? undefined : json['related_incidents_next_steps'],
        'related_incidents_verified_at': !exists(json, 'related_incidents_verified_at') ? undefined : json['related_incidents_verified_at'],
        'related_messages': !exists(json, 'related_messages') ? undefined : ((json['related_messages'] as Array<any>).map(IncidentGroundTruthRelatedMessageFromJSON)),
        'related_messages_verified_at': !exists(json, 'related_messages_verified_at') ? undefined : json['related_messages_verified_at'],
        'related_telemetry_dashboards': !exists(json, 'related_telemetry_dashboards') ? undefined : ((json['related_telemetry_dashboards'] as Array<any>).map(IncidentGroundTruthTelemetryDashboardFromJSON)),
        'related_telemetry_dashboards_verified_at': !exists(json, 'related_telemetry_dashboards_verified_at') ? undefined : json['related_telemetry_dashboards_verified_at'],
    };
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyToJSON(value?: AIStaffUpdateIncidentGroundTruthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causing_code_changes': value.causing_code_changes === undefined ? undefined : ((value.causing_code_changes as Array<any>).map(IncidentGroundTruthCodeChangeToJSON)),
        'causing_code_changes_verified_at': value.causing_code_changes_verified_at,
        'investigation_relevance': value.investigation_relevance,
        'investigation_relevance_reasoning': value.investigation_relevance_reasoning,
        'investigation_relevance_tags': value.investigation_relevance_tags,
        'investigation_relevance_verified_at': value.investigation_relevance_verified_at,
        'related_incidents': value.related_incidents === undefined ? undefined : ((value.related_incidents as Array<any>).map(IncidentGroundTruthRelatedIncidentPayloadToJSON)),
        'related_incidents_next_steps': value.related_incidents_next_steps,
        'related_incidents_verified_at': value.related_incidents_verified_at,
        'related_messages': value.related_messages === undefined ? undefined : ((value.related_messages as Array<any>).map(IncidentGroundTruthRelatedMessageToJSON)),
        'related_messages_verified_at': value.related_messages_verified_at,
        'related_telemetry_dashboards': value.related_telemetry_dashboards === undefined ? undefined : ((value.related_telemetry_dashboards as Array<any>).map(IncidentGroundTruthTelemetryDashboardToJSON)),
        'related_telemetry_dashboards_verified_at': value.related_telemetry_dashboards_verified_at,
    };
}

