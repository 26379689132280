import { SelectOption } from "@incident-io/api";
import {
  ExtendedFormFieldValue,
  filtersToListParams,
} from "@incident-shared/filters";
import { TeamsFilter } from "@incident-shared/teams/TeamsFilter";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  PopoverSingleSelect,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import {
  Identity,
  IncidentsListRequest,
  IncidentsListSortByEnum,
  IncidentType,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { v4 as uuid } from "uuid";

import { useTeamSettings } from "../../settings/teams/common/useTeamSettings";
import { IncidentsKanbanBoard } from "../incidents-list/IncidentsKanban";
import {
  FilterByWorkspaceDropdown,
  PresetOption,
  SingleWorkspaceOption,
} from "./FilterByWorkspaceDropdown";
import { HomeHeading } from "./HomeHeading";
import kanbanIllustration from "./kanban-illustration.svg";

const MAX_PER_COLUMN = 4; // this avoids the kanban board getting too tall
const MAX_ACTIVE_INCIDENTS = 99; // If the org has more than 99 active incidents, we'll call this out instead

const useSlackTeamConfigs = () => {
  const { data } = useAPI("slackTeamConfigsList", undefined);
  return data?.slack_team_configs;
};

export const HomeKanban = ({ fullHeight }: { fullHeight: boolean }) => {
  const slackTeamConfigs = useSlackTeamConfigs();

  const { data: incidentTypesData } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  if (!slackTeamConfigs || !incidentTypesData) {
    return <LoadingBar className="h-24" />;
  }

  return (
    <HomeKanbanInner
      fullHeight={fullHeight}
      slackTeamConfigs={slackTeamConfigs}
      incidentTypes={incidentTypesData.incident_types}
    />
  );
};

const getSlackTeamIDsFromOption = (
  identity: Identity,
  slackTeamConfigs: SlackTeamConfig[],
  selectedOption: PresetOption | SingleWorkspaceOption,
): string[] | undefined => {
  switch (selectedOption.type) {
    case "all_workspaces":
      return [];
    case "my_workspaces":
      return getMySlackTeamIDs(identity, slackTeamConfigs);
    case "single_workspace":
      return [selectedOption.slack_team_id];
    default:
      throw new Error(
        `meant to be unreachable, unknown option ${selectedOption}`,
      );
  }
};

const getMySlackTeamIDs = (
  identity: Identity,
  slackTeamConfigs: SlackTeamConfig[],
) =>
  slackTeamConfigs
    .filter(
      (config) => identity.slack_info?.team_ids?.includes(config.slack_team_id),
    )
    .map((config) => config.slack_team_id);

const HomeKanbanInner = ({
  fullHeight,
  slackTeamConfigs,
  incidentTypes,
}: {
  fullHeight: boolean;
  slackTeamConfigs: SlackTeamConfig[];
  incidentTypes: IncidentType[];
}) => {
  const { identity } = useIdentity();
  const { featureNativeTeams } = useFlags();
  const { hasTeamsCustomFieldConfigured } = useTeamSettings();
  const mySlackTeamIDs = getMySlackTeamIDs(identity, slackTeamConfigs);

  const pollingIntervalInSeconds = identity?.organisation_is_demo ? 5 : 10;

  const [selectedSlackTeam, setSelectedSlackTeam] = useState<
    PresetOption | SingleWorkspaceOption
  >({
    type:
      mySlackTeamIDs && mySlackTeamIDs.length >= 1
        ? "my_workspaces"
        : "all_workspaces",
  });

  const [selectedIncidentType, setSelectedIncidentType] = useState<
    { id: string } | "ALL"
  >("ALL");

  const [selectedTeams, setSelectedTeams] = useState<SelectOption[]>([]);

  // Annoyingly we have to build the filters up by hand. When we redesign the homepage, we should
  // grab theme from the filter context like we do elsewhere (which could allow saved views)!
  let filters: ExtendedFormFieldValue[] = [
    {
      key: uuid(),
      field_key: "status_category",
      field_id: "status_category",
      filter_id: "status_category",
      operator: "one_of",
      multiple_options_value: ["active", "paused", "triage"],
    },
  ];

  if (selectedIncidentType !== "ALL") {
    filters = [
      ...filters,
      {
        key: uuid(),
        field_key: "incident_type",
        field_id: "incident_type",
        filter_id: "incident_type",
        operator: "one_of",
        multiple_options_value: [selectedIncidentType.id],
      },
    ];
  }

  if (selectedTeams.length > 0) {
    filters = [
      ...filters,
      {
        key: "teamone_of",
        field_key: "team",
        field_id: "team",
        filter_id: "team",
        operator: "one_of",
        multiple_options_value: selectedTeams.map((team) => team.value),
      },
    ];
  }

  const slackTeamIDs = getSlackTeamIDsFromOption(
    identity,
    slackTeamConfigs,
    selectedSlackTeam,
  );
  if (slackTeamIDs && slackTeamIDs.length > 0) {
    filters = [
      ...filters,
      {
        key: uuid(),
        field_key: "slack_team_id",
        field_id: "slack_team_id",
        filter_id: "slack_team_id",
        operator: "one_of",
        multiple_options_value: slackTeamIDs,
      },
    ];
  }

  const hasAppliedFilter =
    selectedIncidentType !== "ALL" ||
    selectedSlackTeam.type === "single_workspace" ||
    selectedTeams.length > 0;

  // We can't use useGetIncidents like other components do, because it involves a useAPIInfinite,
  // which doesn't support a refresh interval. A refresh interval is more important to us here
  // because we want the homepage to get updated quickly when incidents are updated. So instead,
  // we'll call out that there are a lot of incidents and that they should use the incident list
  // page instead.
  const queryFilters: IncidentsListRequest = filtersToListParams(filters);

  const baseRequest = {
    ...queryFilters,
    ...{
      pageSize: MAX_ACTIVE_INCIDENTS,
      sortBy: IncidentsListSortByEnum.NewestFirst,
    },
  };

  const {
    data: resp,
    isLoading,
    error,
  } = useAPI("incidentsList", baseRequest, {
    refreshInterval: pollingIntervalInSeconds * 1000,
  });
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const incidentTypeOptions = [
    { label: "All incident types", value: "ALL", sort_key: "aaa" },
  ].concat(
    incidentTypes.map((type) => ({
      label: type.name,
      value: type.id,
      sort_key: type.name,
    })),
  );

  return (
    <div className="space-y-4">
      <HomeHeading
        title="Active incidents"
        resourceCount={
          resp
            ? {
                showing: resp.incidents.length,
                showingMax: resp.incidents.length === MAX_ACTIVE_INCIDENTS,
              }
            : undefined
        }
        seeAllLink={{
          href: `/incidents?display_layout=kanban&status_category[one_of]=active&status_category[one_of]=triage&status_category[one_of]=paused`,
          analyticsTrackingId: "internal-homepage-view-all-incidents",
          title: "View all incidents",
        }}
        accessory={
          featureNativeTeams ? null : (
            <HomeIncidentFilters
              slackTeamConfigs={slackTeamConfigs}
              mySlackTeamIDs={mySlackTeamIDs}
              selectedSlackTeam={selectedSlackTeam}
              setSelectedSlackTeam={setSelectedSlackTeam}
              incidentTypeOptions={incidentTypeOptions}
              selectedIncidentType={selectedIncidentType}
              setSelectedIncidentType={setSelectedIncidentType}
              isLoading={isLoading}
            />
          )
        }
      />
      {featureNativeTeams && (
        <div className={"px-4 md:px-8 flex justify-between"}>
          <TeamsFilter
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            disabled={!hasTeamsCustomFieldConfigured}
            disabledTooltipContent={
              hasTeamsCustomFieldConfigured
                ? undefined
                : "Configure your teams in settings to unlock your tailored dashboard"
            }
            defaultToCurrentUsersTeams
          />
          <HomeIncidentFilters
            slackTeamConfigs={slackTeamConfigs}
            mySlackTeamIDs={mySlackTeamIDs}
            selectedSlackTeam={selectedSlackTeam}
            setSelectedSlackTeam={setSelectedSlackTeam}
            incidentTypeOptions={incidentTypeOptions}
            selectedIncidentType={selectedIncidentType}
            setSelectedIncidentType={setSelectedIncidentType}
            isLoading={isLoading}
          />
        </div>
      )}

      <AnimatePresence presenceAffectsLayout>
        {isLoading || resp === undefined ? (
          <LoadingBar className="h-36 py-[240px] mx-4 md:mx-8" />
        ) : resp.incidents.length === 0 ? (
          <EmptyState
            className="mx-4 md:mx-8 bg-gradient-to-b from-blue-100 from-25% via-50% via-transparent border-0 py-[80px] w-auto"
            illustration={<img src={kanbanIllustration} />}
            title={
              hasAppliedFilter ? "No matching incidents" : "No active incidents"
            }
            content={
              hasAppliedFilter ? (
                <>
                  We couldn&rsquo;t find any incidents that match your filters
                </>
              ) : (
                <>
                  Good news, you don&rsquo;t have any active incidents &mdash;
                  when you do, they&rsquo;ll appear here
                </>
              )
            }
            cta={
              <Button
                icon={IconEnum.ExternalLink}
                theme={ButtonTheme.Tertiary}
                href="/incidents"
                analyticsTrackingId="internal-homepage-view-all-incidents"
              >
                View all incidents
              </Button>
            }
          />
        ) : (
          <IncidentsKanbanBoard
            incidents={resp.incidents}
            statuses={resp.available_statuses}
            isLoading={isLoading}
            maxPerColumn={MAX_PER_COLUMN}
            fullHeight={fullHeight}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const HomeIncidentFilters = ({
  slackTeamConfigs,
  mySlackTeamIDs,
  selectedSlackTeam,
  setSelectedSlackTeam,
  incidentTypeOptions,
  selectedIncidentType,
  setSelectedIncidentType,
  isLoading,
}: {
  slackTeamConfigs: SlackTeamConfig[];
  mySlackTeamIDs: string[];
  selectedSlackTeam: PresetOption | SingleWorkspaceOption;
  setSelectedSlackTeam: (
    newSelectedOption: PresetOption | SingleWorkspaceOption,
  ) => void;
  incidentTypeOptions: SelectOption[];
  selectedIncidentType: { id: string } | "ALL";
  setSelectedIncidentType: (newSelectedOption: { id: string } | "ALL") => void;
  isLoading: boolean;
}) => {
  const incidentTypeButtonLabel =
    selectedIncidentType === "ALL"
      ? "Any incident type"
      : incidentTypeOptions.find(
          (option) => option.value === selectedIncidentType.id,
        )?.label ?? "Incident type";
  return (
    <div className="flex gap-2">
      {slackTeamConfigs && slackTeamConfigs.length > 1 && (
        <FilterByWorkspaceDropdown
          mySlackTeamIDs={mySlackTeamIDs}
          disabled={isLoading}
          selectedOption={selectedSlackTeam}
          slackTeamConfigs={slackTeamConfigs}
          onSelectSlackTeam={setSelectedSlackTeam}
        />
      )}
      {incidentTypeOptions.length > 1 && (
        <PopoverSingleSelect
          placeholder="Filter by incident type"
          options={incidentTypeOptions}
          value={
            selectedIncidentType === "ALL" ? "ALL" : selectedIncidentType.id
          }
          renderTriggerNode={({ onClick }) => (
            <Button
              theme={ButtonTheme.Tertiary}
              analyticsTrackingId={"home-incident-type-filter"}
              icon={IconEnum.IncidentType}
              iconProps={{
                size: IconSize.Medium,
              }}
              onClick={onClick}
              size={BadgeSize.Medium}
            >
              <span>{incidentTypeButtonLabel}</span>
              <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
            </Button>
          )}
          onChange={(val) =>
            setSelectedIncidentType(!val || val === "ALL" ? "ALL" : { id: val })
          }
          align="end"
        />
      )}
    </div>
  );
};
