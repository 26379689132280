import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useNavigate } from "react-router";

import { useIdentity } from "../../contexts/IdentityContext";

export const EscalationPathCreateImportButton = ({
  allowImport,
}: {
  allowImport: boolean;
}) => {
  const { hasScope } = useIdentity();
  const navigate = useNavigate();
  const canCreatePaths = hasScope(ScopeNameEnum.EscalationPathsCreate);

  const buttonProps = {
    create: {
      title: "Create path",
      analyticsTrackingId: "escalation-paths-create",
      onClick: () => navigate("escalation-paths/create"),
      requiredScope: ScopeNameEnum.EscalationPathsCreate,
    },
    import: {
      title: "Import paths",
      analyticsTrackingId: "escalation-paths-import",
      onClick: () => navigate("escalation-paths/import"),
      requiredScope: ScopeNameEnum.EscalationPathsCreate,
    },
  };

  if (!allowImport) {
    return (
      <GatedButton
        analyticsTrackingId={buttonProps.create.analyticsTrackingId}
        // className="ml-auto mr-auto !rounded-r-none"
        theme={ButtonTheme.Secondary}
        onClick={buttonProps.create.onClick}
        requiredScope={buttonProps.create.requiredScope}
      >
        Create path
      </GatedButton>
    );
  }

  return (
    <div className="flex flex-center">
      <GatedButton
        analyticsTrackingId="escalation-paths-create"
        className="ml-auto mr-auto !rounded-r-none border-r-0"
        theme={ButtonTheme.Secondary}
        onClick={() => navigate("escalation-paths/create")}
        requiredScope={ScopeNameEnum.EscalationPathsCreate}
      >
        Create path
      </GatedButton>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Secondary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="escalation-paths-create-or-import-dropdown"
            icon={IconEnum.ChevronDown}
            iconProps={{
              size: IconSize.Medium,
            }}
            title=""
          />
        }
        align={"end"}
      >
        <DropdownMenuItem
          analyticsTrackingId={buttonProps.create.analyticsTrackingId}
          onSelect={buttonProps.create.onClick}
          label={buttonProps.create.title}
          icon={IconEnum.Add}
          disabled={!canCreatePaths}
          tooltipContent={
            !canCreatePaths
              ? "You do not have permission to create escalation paths."
              : undefined
          }
        />
        <DropdownMenuItem
          analyticsTrackingId={buttonProps.import.analyticsTrackingId}
          onSelect={buttonProps.import.onClick}
          icon={IconEnum.Download}
          label={buttonProps.import.title}
          disabled={!canCreatePaths}
          tooltipContent={
            !canCreatePaths
              ? "You do not have permission to import escalation paths."
              : undefined
          }
        />
      </DropdownMenu>
    </div>
  );
};
