import {
  Schedule,
  SchedulesCreateFeedRequestBodyFeedTypeEnum,
} from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
  Input,
  TabModal,
  TabModalPane,
  Txt,
} from "@incident-ui";
import { GenericErrorMessage } from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export const ScheduleCalendarFeedModal = ({
  onCancel,
  feedType = SchedulesCreateFeedRequestBodyFeedTypeEnum.Filtered,
  schedule,
}: {
  onCancel: () => void;
  feedType?: SchedulesCreateFeedRequestBodyFeedTypeEnum;
  schedule?: Schedule;
}) => {
  const { identity, isImpersonating } = useIdentity();
  const [syncAllUsers, setSyncAllUsers] = useState(false);

  const { data, isLoading, error } = useAPI(
    // As this call creates a feed, in the target org, with the currently
    // logged in user. We don't wish to call it when impersonating.
    isImpersonating ? null : "schedulesCreateFeed",
    {
      createFeedRequestBody: {
        feed_type: feedType,
        schedule_ids: schedule ? [schedule.id] : undefined,
        include_all_users: syncAllUsers,
      },
    },
  );

  const { data: schedulesForUserData, isLoading: schedulesForUserLoading } =
    useAPI(
      identity ? "schedulesListForUser" : null,
      {
        user: identity?.user_id || "",
      },
      {
        fallbackData: {
          schedules: [],
          users: [],
          next_shifts: [],
          escalation_path_references: {},
        },
      },
    );

  if (error) {
    return <GenericErrorMessage />;
  }

  const userNotOnSchedules =
    !schedulesForUserLoading && schedulesForUserData?.schedules.length === 0;
  const noScheduleAndUserNotOnSchedules = !schedule && userNotOnSchedules;

  return (
    <TabModal
      isOpen
      title="Sync calendar"
      analyticsTrackingId="schedule-calendar-feed-modal"
      onClose={onCancel}
      tabs={[
        {
          id: "sync-personal",
          label: "Your shifts",
        },
        {
          id: "sync-all",
          label: "All shifts",
        },
      ]}
      onTabChange={(tabId) => setSyncAllUsers(tabId === "sync-all")}
    >
      {noScheduleAndUserNotOnSchedules && (
        <Callout theme={CalloutTheme.Warning} className="mt-2">
          You are not a member of any schedules.
        </Callout>
      )}
      <TabModalPane tabId="sync-personal" className="!p-0">
        <CalendarFeedModalContent
          allUsers={false}
          schedule={schedule}
          feedUrl={data?.feed_url ?? ""}
          loading={isLoading}
          disabled={noScheduleAndUserNotOnSchedules}
        />
      </TabModalPane>
      <TabModalPane tabId="sync-all" className="!p-0">
        <CalendarFeedModalContent
          allUsers={true}
          schedule={schedule}
          feedUrl={data?.feed_url ?? ""}
          loading={isLoading}
          disabled={noScheduleAndUserNotOnSchedules}
        />
      </TabModalPane>
    </TabModal>
  );
};

const CalendarFeedModalContent = ({
  allUsers,
  loading,
  feedUrl,
  schedule,
  disabled,
}: {
  allUsers: boolean;
  loading: boolean;
  feedUrl: string;
  schedule?: Schedule;
  disabled: boolean;
}): React.ReactElement => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <Txt className="space-y-4" grey>
      <p className="mt-4">
        Use the link below to subscribe to {allUsers ? "all" : "your"} shifts
        for{" "}
        <Txt bold inline>
          {schedule ? schedule.name : "all schedules you are a member of"}
        </Txt>{" "}
        in your preferred calendar tool. Once connected all rota changes and
        overrides will be automatically updated.
      </p>
      <Callout theme={CalloutTheme.Info} className="!p-2">
        Most calendar providers only sync once per day, meaning schedules
        changes may take a while to be reflected.
      </Callout>
      <div className="flex-center-y space-x-2">
        <div className="flex-center-y flex-grow">
          <Input
            id="copyable-url"
            readOnly
            value={feedUrl}
            disabled
            className={tcx("!rounded-r-none !cursor-auto", {
              "!bg-white": !disabled,
            })}
          />
          <div className="w-[42px] h-[40px] border !border-l-0 rounded-r-lg flex-center border-stroke">
            <Button
              loading={loading}
              theme={ButtonTheme.Naked}
              icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
              analyticsTrackingId="copy-token"
              type="button"
              title="Copy to clipboard"
              className="!transition-none"
              iconProps={{
                className: hasCopied ? "!text-green-content" : "",
                size: IconSize.Medium,
              }}
              disabled={disabled}
              onClick={() => copyTextToClipboard(feedUrl)}
            />
          </div>
        </div>
        <Button
          title="google-calendar"
          analyticsTrackingId={"calendar-feed-modal-google-calendar"}
          href={
            disabled
              ? undefined
              : `https://calendar.google.com/calendar/r?cid=${feedUrl.replace(
                  "https://",
                  "webcal://",
                )}`
          }
          openInNewTab
          icon={IconEnum.GoogleCalendar}
          iconProps={{ size: IconSize.Large }}
          disabled={disabled}
        />
        <Button
          title="outlook-calendar"
          analyticsTrackingId={"calendar-feed-modal-outlook-calendar"}
          href={
            disabled
              ? undefined
              : "https://outlook.live.com/calendar/0/addcalendar"
          }
          openInNewTab
          icon={IconEnum.Outlook}
          disabled={disabled}
        />
      </div>
    </Txt>
  );
};
