/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsIntercomGetConfigResponseBody,
    IntegrationsIntercomGetConfigResponseBodyFromJSON,
    IntegrationsIntercomGetConfigResponseBodyToJSON,
    IntegrationsIntercomSetStatusPageRequestBody,
    IntegrationsIntercomSetStatusPageRequestBodyFromJSON,
    IntegrationsIntercomSetStatusPageRequestBodyToJSON,
} from '../models';

export interface IntegrationsIntercomHandleInstallRequest {
    fromSource?: IntegrationsIntercomHandleInstallFromSourceEnum;
    organisationId?: string;
}

export interface IntegrationsIntercomHandleRedirectRequest {
    code?: string;
    state?: string;
}

export interface IntegrationsIntercomSetStatusPageRequest {
    setStatusPageRequestBody: IntegrationsIntercomSetStatusPageRequestBody;
}

/**
 * 
 */
export class IntegrationsIntercomApi extends runtime.BaseAPI {

    /**
     * Get the organisation\'s Intercom config
     * GetConfig Integrations - Intercom
     */
    async integrationsIntercomGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsIntercomGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/intercom/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsIntercomGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the organisation\'s Intercom config
     * GetConfig Integrations - Intercom
     */
    async integrationsIntercomGetConfig(initOverrides?: RequestInit): Promise<IntegrationsIntercomGetConfigResponseBody> {
        const response = await this.integrationsIntercomGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Intercom installation by redirecting to the Intercom install endpoint
     * HandleInstall Integrations - Intercom
     */
    async integrationsIntercomHandleInstallRaw(requestParameters: IntegrationsIntercomHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.fromSource !== undefined) {
            queryParameters['from_source'] = requestParameters.fromSource;
        }

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/intercom_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Intercom installation by redirecting to the Intercom install endpoint
     * HandleInstall Integrations - Intercom
     */
    async integrationsIntercomHandleInstall(requestParameters: IntegrationsIntercomHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsIntercomHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Intercom redirect
     * HandleRedirect Integrations - Intercom
     */
    async integrationsIntercomHandleRedirectRaw(requestParameters: IntegrationsIntercomHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/intercom`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Intercom redirect
     * HandleRedirect Integrations - Intercom
     */
    async integrationsIntercomHandleRedirect(requestParameters: IntegrationsIntercomHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsIntercomHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Intercom OAuth token
     * HandleUninstall Integrations - Intercom
     */
    async integrationsIntercomHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/intercom`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Intercom OAuth token
     * HandleUninstall Integrations - Intercom
     */
    async integrationsIntercomHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsIntercomHandleUninstallRaw(initOverrides);
    }

    /**
     * Set the status page to display in Intercom
     * SetStatusPage Integrations - Intercom
     */
    async integrationsIntercomSetStatusPageRaw(requestParameters: IntegrationsIntercomSetStatusPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setStatusPageRequestBody === null || requestParameters.setStatusPageRequestBody === undefined) {
            throw new runtime.RequiredError('setStatusPageRequestBody','Required parameter requestParameters.setStatusPageRequestBody was null or undefined when calling integrationsIntercomSetStatusPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/intercom/actions/set_status_page`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsIntercomSetStatusPageRequestBodyToJSON(requestParameters.setStatusPageRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the status page to display in Intercom
     * SetStatusPage Integrations - Intercom
     */
    async integrationsIntercomSetStatusPage(requestParameters: IntegrationsIntercomSetStatusPageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsIntercomSetStatusPageRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IntegrationsIntercomHandleInstallFromSourceEnum {
    Settings = 'settings',
    Intercom = 'intercom'
}
