import { IncidentActivityLogEntry } from "@incident-io/api";
import { EnrichmentWarnings } from "@incident-shared/timeline/EnrichmentWarnings";
import { TimelineDateGroup } from "@incident-shared/timeline/Timeline";
import {
  EmptyState,
  Heading,
  IconEnum,
  InlineErrorMessage,
  Input,
  LoadingBar,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { ErrorBoundary } from "@sentry/react";
import { Searcher } from "fast-fuzzy";
import _ from "lodash";
import { ChangeEvent, useState } from "react";

import { useAPI, useAPIMutation } from "../../utils/swr";
import { useIncident } from "../legacy/incident/hooks";
import {
  ActivityLogItemMode,
  ActivityLogItemUIWrapper,
} from "./ActivityLogItemUI";

export enum SortOrder {
  NewestFirst = "newest_first",
  OldestFirst = "oldest_first",
}

export const ActivityLogPanelInEditor = ({
  incidentId,
  sortOrder,
}: {
  incidentId: string | null;
  sortOrder: SortOrder;
}): React.ReactElement => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: incidentTimelineItems, isLoading: timelineItemsLoading } =
    useAPI("incidentTimelineListTimelineItems", {
      incidentId: incidentId ?? "",
      timezone: localTimezone,
    });

  const showToast = useToast();

  const { trigger: onVisibilityChange } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incidentId ?? "",
      timezone: localTimezone,
    },
    async (
      apiClient,
      { itemId, incidentId }: { itemId: string; incidentId: string },
    ) => {
      await apiClient.incidentTimelineCreateTimelineItem({
        incidentId: incidentId,
        createTimelineItemRequestBody: {
          activity_log_id: itemId,
        },
      });
    },
    {
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `We weren't able to update the visibility of this activity log item`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const onShow = ({
    itemId,
    incidentId,
  }: {
    itemId: string;
    incidentId: string;
  }) => {
    onVisibilityChange({ itemId, incidentId });
  };

  if (timelineItemsLoading) {
    return <LoadingBar className="mt-5 h-28" />;
  }

  const activityLogIDsOnTimeline =
    incidentTimelineItems?.timeline_items.flatMap((group) =>
      group.items
        .filter((item) => item.timeline_item)
        .flatMap(
          (item) =>
            item.timeline_item?.evidence
              ?.filter((e) => e.incident_activity_log_id)
              .map((e) => e.incident_activity_log_id),
        ),
    ) ?? [];

  return (
    <div className="space-y-4 grow">
      <div className="space-y-1">
        <Heading level={2} size="small">
          Curate your timeline with events from your activity log
        </Heading>
        <div className="text-sm text-content-secondary">
          Everything that happened during this incident is listed below. Hover
          over any item to add it to the preview on the right, or hit &apos;Add
          custom event&apos; for events that happened in external systems. When
          ready, press &apos;Done&apos;.
        </div>
      </div>
      <ActivityLogInnerWrapper
        incidentId={incidentId}
        sortOrder={sortOrder}
        activityLogIDsOnTimeline={activityLogIDsOnTimeline}
        onShow={onShow}
        mode={ActivityLogItemMode.ActivityLogInEditMode}
      />
    </div>
  );
};

type ActivityLogInnerProps = {
  incidentId: string | null;
  sortOrder: SortOrder;
  mode: ActivityLogItemMode;
  activityLogIDsOnTimeline?: (string | undefined)[];
  onShow?: ({
    itemId,
    incidentId,
  }: {
    itemId: string;
    incidentId: string;
  }) => void;
};

const ActivityLogInnerWrapper = (
  props: ActivityLogInnerProps,
): React.ReactElement => {
  return (
    <ErrorBoundary
      fallback={
        <InlineErrorMessage description="We couldn't load the activity log for this incident." />
      }
    >
      <ActivityLogInner {...props} />
    </ErrorBoundary>
  );
};

export const ActivityLogInner = (props: ActivityLogInnerProps) => {
  const { incidentId, sortOrder, mode, activityLogIDsOnTimeline, onShow } =
    props;

  const [search, setSearch] = useState("");

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { incident } = useIncident(incidentId);
  const { data: activityLogItemResponse, isLoading: activityLogItemsLoading } =
    useAPI("incidentTimelineListActivityLog", {
      incidentId: incidentId ?? "",
      timezone: localTimezone,
    });

  const activityLogItems = activityLogItemResponse?.activities ?? [];

  if (activityLogItemsLoading || !incident) {
    return <LoadingBar className="mt-5 h-28" />;
  }

  if (_.isEmpty(activityLogItems)) {
    return (
      <EmptyState
        content="There hasn't been an activity yet."
        icon={IconEnum.Activity}
      />
    );
  }

  const flatItems = activityLogItems.flatMap((group) =>
    group.items
      .filter((item) => !!item.activity_log)
      .map((item) => item.activity_log as IncidentActivityLogEntry),
  );

  const searcher = new Searcher(flatItems, {
    keySelector: (item) => JSON.stringify(item.content),
    threshold: 0.8,
  });

  const searchedItems = search ? searcher.search(search) : flatItems;
  const items = sortActivityLogItems(searchedItems, sortOrder);

  // now we've searched, we have to rebuild our activity log with the
  // date groupings intact
  const filteredItemsGrouped = activityLogItems
    .map((group) => {
      const filteredItems = group.items.filter(
        (item) =>
          item.activity_log && searchedItems.includes(item.activity_log),
      );

      return {
        ...group,
        items: filteredItems,
      };
    })
    .filter((group) => group.items.length > 0);

  return (
    <>
      <div className="flex gap-2">
        <Input
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          id="activity-log-search"
          iconName={IconEnum.Search}
          placeholder="Search activity log"
        />
      </div>
      <EnrichmentWarnings incident={incident} items={items} />
      {filteredItemsGrouped.length === 0 && (
        <EmptyState content="No events found" icon={IconEnum.Activity} />
      )}
      <ul className="space-y-2">
        {filteredItemsGrouped.map((group) => {
          return (
            <TimelineDateGroup
              key={group.date.toString()}
              date={group.date}
              className="mb-4 mt-5"
              childrenClassName="space-y-3"
            >
              {group.items.map((element) => {
                const activityLog = element.activity_log;

                // We don't really care about the time gaps in this view, so lets
                // just ignore them.
                if (!activityLog) {
                  return null;
                }

                const isDisabledOption =
                  mode === ActivityLogItemMode.ActivityLogInEditMode &&
                  activityLogIDsOnTimeline &&
                  activityLogIDsOnTimeline.includes(activityLog.id);

                return (
                  <ActivityLogItemUIWrapper
                    mode={mode}
                    key={activityLog.id}
                    onShow={onShow}
                    item={activityLog}
                    isDisabledOption={isDisabledOption}
                    className="p-1 -ml-1"
                    showFullTitle={true}
                  />
                );
              })}
            </TimelineDateGroup>
          );
        })}
      </ul>
    </>
  );
};

export const sortActivityLogItems = (
  elements: IncidentActivityLogEntry[],
  sortOrder: SortOrder,
): IncidentActivityLogEntry[] => {
  if (elements.length === 0) {
    return elements;
  }

  let sortedElements = _.sortBy(elements, "occurred_at");

  if (sortOrder === SortOrder.NewestFirst) {
    sortedElements = sortedElements.reverse();
  }

  return sortedElements;
};
