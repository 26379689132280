import { Route, Routes } from "react-router";

import { LeaderboardsListPage } from "./LeaderboardsListPage";
import { LeaderboardsShowPage } from "./LeaderboardsShowPage";

export const LeaderboardsRoute = () => {
  return (
    <Routes>
      <Route path="/:id" element={<LeaderboardsShowPage />} />
      <Route path="*" element={<LeaderboardsListPage />} />
    </Routes>
  );
};
