import {
  CatalogEntry,
  CatalogResource,
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeAttributeModeEnum,
  EngineParamBinding,
  PartialEntryPayload,
  Resource,
} from "@incident-io/api";
import { CatalogEntryBadge, isPrimitive } from "@incident-shared/attribute";
import { EngineFormElement, isEmptyBinding } from "@incident-shared/engine";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  LoadingBar,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { formatDuration } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { useParams, useSearchParams } from "react-router-dom";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useClient } from "src/contexts/ClientContext";
import {
  AIConfigEnabledFeaturesEnum,
  useAIFeatureForOrg,
} from "src/hooks/useAI";
import { useAllResources } from "src/hooks/useResources";
import { useMutationV2 } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { stringToHash } from "src/utils/utils";

export const CatalogEntriesBulkEditDrawer = ({
  catalogResources,
  catalogType,
  entriesFullyLoaded,
  refetchEntries,
  entries,
  loadMoreRef,
  backHref,
}: {
  catalogResources: CatalogResource[];
  attribute?: CatalogTypeAttribute;
  catalogType: CatalogType;
  entriesFullyLoaded: boolean;
  refetchEntries: () => void;
  entries: CatalogEntry[];
  loadMoreRef: UseInfiniteScrollHookRefCallback;
  backHref: string;
}) => {
  const { attributeId } = useParams<{ attributeId: string }>();
  const [searchParams] = useSearchParams();
  const autoFill = searchParams.get("autofill") === "true";
  const attribute = catalogType.schema.attributes.find(
    (a) => a.id === attributeId,
  );

  const {
    resources: engineResources,
    resourcesLoading: engineResourcesLoading,
    resourcesError: engineResourcesError,
  } = useAllResources();

  const loading = engineResourcesLoading || !entriesFullyLoaded;
  const error = engineResourcesError;
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(backHref);

  return (
    <Drawer onClose={onClose} width="medium" warnWhenDirty>
      {attribute && (
        <DrawerContents className="font-normal">
          <DrawerTitle
            icon={IconEnum.Edit}
            onClose={onClose}
            compact
            title={`Edit values for ${attribute?.name}`}
          />
          {error && <GenericErrorMessage error={error} />}
          {loading ? (
            <>
              <div ref={loadMoreRef} />
              <LoadingTable attribute={attribute} catalogType={catalogType} />
            </>
          ) : (
            <CatalogEntriesBulkEditForm
              attribute={attribute}
              catalogType={catalogType}
              onClose={onClose}
              resources={engineResources}
              catalogResources={catalogResources}
              entries={entries}
              refetchEntries={refetchEntries}
              autoFill={autoFill}
            />
          )}
        </DrawerContents>
      )}
    </Drawer>
  );
};

const LOADING_STATE_WIDTHS = [
  "[&_input]:!w-[100px]",
  "[&_input]:!w-[130px]",
  "[&_input]:!w-[150px]",
  "[&_input]:!w-[170px]",
  "[&_input]:!w-[200px]",
];

type BulkEditFormState = {
  [key: string]: EngineParamBinding;
};

const CatalogEntriesBulkEditForm = ({
  attribute,
  catalogType,
  onClose,
  resources,
  catalogResources,
  entries,
  refetchEntries,
  autoFill,
}: {
  attribute: CatalogTypeAttribute;
  catalogType: CatalogType;
  onClose: () => void;
  resources: Resource[];
  catalogResources: CatalogResource[];
  entries: CatalogEntry[];
  refetchEntries: () => void;
  autoFill: boolean;
}) => {
  const defaultValues = {};
  const showToast = useToast();

  entries.forEach((entry) => {
    const attributeValue = entry.attribute_values[attribute.id];
    if (isEmptyBinding(attributeValue)) {
      return;
    }

    defaultValues[entry.id] = entry.attribute_values[attribute.id];
  });
  const formMethods = useForm<BulkEditFormState>({ defaultValues });
  const { formState, setValue } = formMethods;
  const dirtyFields = formState.dirtyFields;
  const touchedFields = formState.touchedFields;

  const apiClient = useClient();
  const analytics = useAnalytics();
  const canAutoFill = useCanAutoFillAttribute();

  const emptyEntries = entries.filter((e) =>
    isEmptyBinding(e.attribute_values[attribute.id]),
  );

  const [autoFillState, setAutofillState] = useState<AutofillInfo>({
    available: canAutoFill(attribute) && emptyEntries.length > 0,
    state: "ready",
    autoFilledIds: [],
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError: genericError,
  } = useMutationV2(
    async (apiClient, data: BulkEditFormState) => {
      const payloads: PartialEntryPayload[] = [];
      if (!dirtyFields) {
        return;
      }

      let countSuggestionsAccepted = 0;
      Object.keys(dirtyFields).forEach((entryId) => {
        // If a field isn't 'touched', that means it was autofilled and we should tell the backend that.
        const autofilled =
          !Object.keys(touchedFields).includes(entryId) &&
          autoFillState.autoFilledIds.includes(entryId);
        if (autofilled) {
          countSuggestionsAccepted++;
        }
        payloads.push({
          entry_id: entryId,
          attribute_values: {
            [attribute.id]: data[entryId],
          },
          from_auto_suggest: autofilled,
        });
      });

      analytics?.track("catalog.bulk-update-attribute-values", {
        countSuggestions: autoFillState?.countAutoFilled,
        countSuggestionsAccepted: countSuggestionsAccepted,
        countEntries: entries.length,
      });

      await apiClient.catalogBulkUpdateAttributeValues({
        bulkUpdateAttributeValuesRequestBody: {
          catalog_type_id: catalogType.id,
          partial_entry_payloads: payloads,
        },
      });

      // Show the toast here (instead of in onSuccess) so we have access to
      // the various entry counts.
      const countUpdated = Object.keys(formState.dirtyFields).length;
      const updatedEntries =
        countUpdated === 1 ? "1 value" : `${countUpdated} values`;
      if (countSuggestionsAccepted > 0) {
        showToast({
          theme: ToastTheme.Success,
          iconOverride: IconEnum.MagicWand,
          title: `${updatedEntries} updated for ${attribute.name}`,
          toastSide: ToastSideEnum.Bottom,
          description: `AI suggestions saved you ${formatDuration({
            // 2s per entry seems fair?
            seconds: countSuggestionsAccepted * 2,
          })}`,
        });
      } else {
        showToast({
          theme: ToastTheme.Success,
          title: `${updatedEntries} updated in ${attribute.name}`,
          toastSide: ToastSideEnum.Bottom,
        });
      }
    },
    {
      onSuccess: () => {
        refetchEntries();
        onClose();
      },
      invalidate: [],
    },
  );

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const onAutoFill = useCallback(async () => {
    try {
      setAutofillState({ ...autoFillState, state: "loading-suggestions" });

      const response = await apiClient.catalogSuggestMissingAttributeValues({
        suggestMissingAttributeValuesRequestBody: {
          attribute_id: attribute.id,
          catalog_type_id: catalogType.id,
          entry_ids: emptyEntries.map((e) => e.id),
        },
      });

      const autoFilledIds: string[] = [];
      response.suggestions.forEach((suggestion) => {
        if (suggestion.suggested_value) {
          setValue(
            suggestion.entry_id,
            suggestion.suggested_value,
            // This means we can tell which fields were set via AI, and which were updated
            // by a human.
            { shouldDirty: true, shouldTouch: false },
          );
          autoFilledIds.push(suggestion.entry_id);
        }
      });
      setAutofillState({
        ...autoFillState,
        state: "completed",
        countAutoFilled: response.suggestions.length,
        autoFilledIds,
      });
    } catch (error) {
      showToast({
        theme: ToastTheme.Error,
        title: "Failed to auto-fill",
        description: "Please try again later",
        toastSide: ToastSideEnum.Bottom,
      });
      return;
    }
  }, [
    setAutofillState,
    apiClient,
    emptyEntries,
    showToast,
    setValue,
    autoFillState,
    attribute.id,
    catalogType.id,
  ]);

  // If the user has clicked 'auto fill' we want to start auto-filling immediately
  useEffect(() => {
    if (autoFillState.state === "ready" && autoFill) {
      onAutoFill();
    }
  }, [autoFill, onAutoFill, autoFillState]);

  const catalogResource = catalogResources.find(
    (resource) => resource.type === attribute.type,
  );
  if (!catalogResource) {
    return <GenericErrorMessage />;
  }

  return (
    <>
      <DrawerBody className="overflow-y-auto">
        <AutoFillCallout {...autoFillState} onAutoFill={onAutoFill} />
        <Form.Root
          formMethods={formMethods}
          onSubmit={onSubmit}
          genericError={genericError}
          saving={saving}
          id="bulk-edit-form"
        >
          <Table
            data={entries}
            gridTemplateColumns="repeat(2, 1fr)"
            header={
              <>
                <TableHeaderCell title={catalogType.name} />
                <TableHeaderCell title={attribute.name} />
              </>
            }
            renderRow={(entry, index) => {
              const isLoadingAutoFill =
                autoFillState.state === "loading-suggestions" &&
                isEmptyBinding(formMethods.watch(entry.id));

              const hasBeenAutoFilled = autoFillState.autoFilledIds.includes(
                entry.id,
              );
              return (
                <TableRow isLastRow={index === entries.length - 1} key={index}>
                  <TableCell key={`${index}-entry`}>
                    <CatalogEntryBadge
                      label={entry.name}
                      icon={catalogType.icon}
                      color={catalogType.color}
                    />
                  </TableCell>
                  <TableCell key={`${index}-select`}>
                    <EngineFormElement
                      className={tcx(
                        "w-full",
                        hasBeenAutoFilled &&
                          tcx(
                            "border rounded-2",
                            getColorPalette(
                              catalogResource.color || ColorPaletteEnum.Blue,
                            ).contentBorder,
                          ),
                        // Hack to make our 'loading state' badges
                        isLoadingAutoFill
                          ? tcx(
                              "[&_input]:!bg-ai-rainbow [&_input]:!animate-pulse [&_input]:!rounded-1 [&_input]:!h-6 [&_input]:!-my-1",
                              LOADING_STATE_WIDTHS[
                                stringToHash(entry.id) %
                                  LOADING_STATE_WIDTHS.length
                              ],
                            )
                          : "",
                      )}
                      name={entry.id}
                      resourceType={catalogResource.config.type}
                      resources={resources}
                      array={attribute.array}
                      required={false}
                      mode="plain_input"
                      optionIconOverride={
                        hasBeenAutoFilled ? IconEnum.MagicWand : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            }}
          />
        </Form.Root>
      </DrawerBody>
      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={() => onCloseWithWarn(isDirty)}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          type="submit"
          form="bulk-edit-form"
          disabled={!isDirty}
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

const LoadingTable = ({
  catalogType,
  attribute,
}: {
  catalogType: CatalogType;
  attribute: CatalogTypeAttribute;
}) => {
  const data = [1, 2, 3, 4, 5, 6].map((i) => ({ id: i }));
  return (
    <DrawerBody>
      <Table
        data={data}
        gridTemplateColumns="repeat(2, 1fr)"
        header={
          <>
            <TableHeaderCell title={catalogType.name} />
            <TableHeaderCell title={attribute.name} />
          </>
        }
        renderRow={(_, index) => (
          <TableRow isLastRow={index === data.length - 1}>
            <TableCell key={`${index}-entry`}>
              <LoadingBar />
            </TableCell>
            <TableCell key={`${index}-select`}>
              <LoadingBar />
            </TableCell>
          </TableRow>
        )}
      />
    </DrawerBody>
  );
};

type AutofillInfo = {
  state: AutofillState;
  countAutoFilled?: number;
  available: boolean;
  autoFilledIds: string[];
};
type AutofillState = "loading-suggestions" | "ready" | "completed";

const AutoFillCallout = ({
  state,
  onAutoFill,
  countAutoFilled,
  available,
}: {
  onAutoFill: () => void;
} & AutofillInfo) => {
  if (!available) {
    return null;
  }

  let title = "Suggest missing values";
  if (state === "loading-suggestions") {
    title = "Generating suggestions...";
  }
  if (state === "completed") {
    if (countAutoFilled === 0) {
      title = "We couldn't find any matching entries";
    } else if (countAutoFilled === 1) {
      title = "We’ve suggested 1 entry";
    } else {
      title = `We’ve suggested ${countAutoFilled} entries`;
    }
  }

  return (
    <Callout
      theme={CalloutTheme.AIRainbow}
      showIcon={false}
      title={title}
      subtitle="You can accept, update, or reject any suggested values"
      className="border-[1px] border-color-purple-100"
      cta={
        state !== "completed" && (
          <Button
            analyticsTrackingId="catalog-auto-fill"
            onClick={onAutoFill}
            icon={IconEnum.MagicWand}
            loading={state === "loading-suggestions"}
          >
            Suggest
          </Button>
        )
      }
    />
  );
};

export const useCanAutoFillAttribute = () => {
  const canUseAI = useAIFeatureForOrg();
  const canUseCatalogSuggestions = canUseAI(
    AIConfigEnabledFeaturesEnum.Default,
  );

  return (attribute: CatalogTypeAttribute): boolean => {
    if (!canUseCatalogSuggestions) {
      return false;
    }

    if (attribute.array) {
      return false;
    }

    if (attribute.mode !== CatalogTypeAttributeModeEnum.Dashboard) {
      return false;
    }

    if (isPrimitive(attribute.type)) {
      return false;
    }

    return true;
  };
};
