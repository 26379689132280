/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface AlertRouteSeverityBinding
 */
export interface AlertRouteSeverityBinding {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertRouteSeverityBinding
     */
    binding?: EngineParamBinding;
    /**
     * Strategy for merging severity when multiple alerts create/update the same incident
     * @type {string}
     * @memberof AlertRouteSeverityBinding
     */
    merge_strategy: AlertRouteSeverityBindingMergeStrategyEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertRouteSeverityBindingMergeStrategyEnum {
    FirstWins = 'first-wins',
    Max = 'max'
}

export function AlertRouteSeverityBindingFromJSON(json: any): AlertRouteSeverityBinding {
    return AlertRouteSeverityBindingFromJSONTyped(json, false);
}

export function AlertRouteSeverityBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteSeverityBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': !exists(json, 'binding') ? undefined : EngineParamBindingFromJSON(json['binding']),
        'merge_strategy': json['merge_strategy'],
    };
}

export function AlertRouteSeverityBindingToJSON(value?: AlertRouteSeverityBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingToJSON(value.binding),
        'merge_strategy': value.merge_strategy,
    };
}

