/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AndroidAssetLinks,
    AndroidAssetLinksFromJSON,
    AndroidAssetLinksToJSON,
    MobileAppleAppSiteAssociationResponseBody,
    MobileAppleAppSiteAssociationResponseBodyFromJSON,
    MobileAppleAppSiteAssociationResponseBodyToJSON,
    MobileCreateKeyRequestBody,
    MobileCreateKeyRequestBodyFromJSON,
    MobileCreateKeyRequestBodyToJSON,
    MobileCreateKeyResponseBody,
    MobileCreateKeyResponseBodyFromJSON,
    MobileCreateKeyResponseBodyToJSON,
    MobileListKeysResponseBody,
    MobileListKeysResponseBodyFromJSON,
    MobileListKeysResponseBodyToJSON,
    MobileSetNotificationTokensRequestBody,
    MobileSetNotificationTokensRequestBodyFromJSON,
    MobileSetNotificationTokensRequestBodyToJSON,
    MobileShowCurrentKeyResponseBody,
    MobileShowCurrentKeyResponseBodyFromJSON,
    MobileShowCurrentKeyResponseBodyToJSON,
} from '../models';

export interface MobileCreateKeyRequest {
    createKeyRequestBody: MobileCreateKeyRequestBody;
}

export interface MobileDestroyMobileAppInstanceRequest {
    id: string;
}

export interface MobileHandleMicrosoftRedirectRequest {
    returnPath: string;
    failureReason?: MobileHandleMicrosoftRedirectFailureReasonEnum;
}

export interface MobileHandleSAMLRedirectRequest {
    returnPath: string;
    failureReason?: MobileHandleSAMLRedirectFailureReasonEnum;
}

export interface MobileHandleSlackRedirectRequest {
    returnPath: string;
    failureReason?: MobileHandleSlackRedirectFailureReasonEnum;
}

export interface MobileSetNotificationTokensRequest {
    setNotificationTokensRequestBody: MobileSetNotificationTokensRequestBody;
}

/**
 * 
 */
export class MobileApi extends runtime.BaseAPI {

    /**
     * Asset links file for universal links on Android
     * AndroidAssetLinks Mobile
     */
    async mobileAndroidAssetLinksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AndroidAssetLinks>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/.well-known/assetlinks.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AndroidAssetLinksFromJSON));
    }

    /**
     * Asset links file for universal links on Android
     * AndroidAssetLinks Mobile
     */
    async mobileAndroidAssetLinks(initOverrides?: RequestInit): Promise<Array<AndroidAssetLinks>> {
        const response = await this.mobileAndroidAssetLinksRaw(initOverrides);
        return await response.value();
    }

    /**
     * App site association file for universal links on iOS
     * AppleAppSiteAssociation Mobile
     */
    async mobileAppleAppSiteAssociationRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<MobileAppleAppSiteAssociationResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/.well-known/apple-app-site-association`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAppleAppSiteAssociationResponseBodyFromJSON(jsonValue));
    }

    /**
     * App site association file for universal links on iOS
     * AppleAppSiteAssociation Mobile
     */
    async mobileAppleAppSiteAssociation(initOverrides?: RequestInit): Promise<MobileAppleAppSiteAssociationResponseBody> {
        const response = await this.mobileAppleAppSiteAssociationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Exchange the JWT provided by the Slack redirect for a mobile key that can be used to make requests.
     * CreateKey Mobile
     */
    async mobileCreateKeyRaw(requestParameters: MobileCreateKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MobileCreateKeyResponseBody>> {
        if (requestParameters.createKeyRequestBody === null || requestParameters.createKeyRequestBody === undefined) {
            throw new runtime.RequiredError('createKeyRequestBody','Required parameter requestParameters.createKeyRequestBody was null or undefined when calling mobileCreateKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mobile_keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobileCreateKeyRequestBodyToJSON(requestParameters.createKeyRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileCreateKeyResponseBodyFromJSON(jsonValue));
    }

    /**
     * Exchange the JWT provided by the Slack redirect for a mobile key that can be used to make requests.
     * CreateKey Mobile
     */
    async mobileCreateKey(requestParameters: MobileCreateKeyRequest, initOverrides?: RequestInit): Promise<MobileCreateKeyResponseBody> {
        const response = await this.mobileCreateKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Destroy the mobile key for the current user.
     * DestroyKey Mobile
     */
    async mobileDestroyKeyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mobile_keys/self`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy the mobile key for the current user.
     * DestroyKey Mobile
     */
    async mobileDestroyKey(initOverrides?: RequestInit): Promise<void> {
        await this.mobileDestroyKeyRaw(initOverrides);
    }

    /**
     * Destroys a mobile app instance, signing the user out of the mobile app.
     * DestroyMobileAppInstance Mobile
     */
    async mobileDestroyMobileAppInstanceRaw(requestParameters: MobileDestroyMobileAppInstanceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDestroyMobileAppInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mobile_keys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroys a mobile app instance, signing the user out of the mobile app.
     * DestroyMobileAppInstance Mobile
     */
    async mobileDestroyMobileAppInstance(requestParameters: MobileDestroyMobileAppInstanceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mobileDestroyMobileAppInstanceRaw(requestParameters, initOverrides);
    }

    /**
     * Returns the vcard with contact numbers for responders
     * GetResponderVcard Mobile
     */
    async mobileGetResponderVcardRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mobile/responder_vcard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns the vcard with contact numbers for responders
     * GetResponderVcard Mobile
     */
    async mobileGetResponderVcard(initOverrides?: RequestInit): Promise<void> {
        await this.mobileGetResponderVcardRaw(initOverrides);
    }

    /**
     * Handle Microsoft redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleMicrosoftRedirect Mobile
     */
    async mobileHandleMicrosoftRedirectRaw(requestParameters: MobileHandleMicrosoftRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.returnPath === null || requestParameters.returnPath === undefined) {
            throw new runtime.RequiredError('returnPath','Required parameter requestParameters.returnPath was null or undefined when calling mobileHandleMicrosoftRedirect.');
        }

        const queryParameters: any = {};

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.failureReason !== undefined) {
            queryParameters['failure_reason'] = requestParameters.failureReason;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft/mobile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleMicrosoftRedirect Mobile
     */
    async mobileHandleMicrosoftRedirect(requestParameters: MobileHandleMicrosoftRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mobileHandleMicrosoftRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle SAML redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleSAMLRedirect Mobile
     */
    async mobileHandleSAMLRedirectRaw(requestParameters: MobileHandleSAMLRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.returnPath === null || requestParameters.returnPath === undefined) {
            throw new runtime.RequiredError('returnPath','Required parameter requestParameters.returnPath was null or undefined when calling mobileHandleSAMLRedirect.');
        }

        const queryParameters: any = {};

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.failureReason !== undefined) {
            queryParameters['failure_reason'] = requestParameters.failureReason;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/saml/mobile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle SAML redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleSAMLRedirect Mobile
     */
    async mobileHandleSAMLRedirect(requestParameters: MobileHandleSAMLRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mobileHandleSAMLRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Slack redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleSlackRedirect Mobile
     */
    async mobileHandleSlackRedirectRaw(requestParameters: MobileHandleSlackRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.returnPath === null || requestParameters.returnPath === undefined) {
            throw new runtime.RequiredError('returnPath','Required parameter requestParameters.returnPath was null or undefined when calling mobileHandleSlackRedirect.');
        }

        const queryParameters: any = {};

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.failureReason !== undefined) {
            queryParameters['failure_reason'] = requestParameters.failureReason;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack/mobile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Slack redirect for mobile apps by redirecting to the return path but with a JWT.
     * HandleSlackRedirect Mobile
     */
    async mobileHandleSlackRedirect(requestParameters: MobileHandleSlackRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mobileHandleSlackRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * List all mobile keys for this user.
     * ListKeys Mobile
     */
    async mobileListKeysRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<MobileListKeysResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mobile_keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileListKeysResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all mobile keys for this user.
     * ListKeys Mobile
     */
    async mobileListKeys(initOverrides?: RequestInit): Promise<MobileListKeysResponseBody> {
        const response = await this.mobileListKeysRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the notification tokens specific to a mobile device so that we can send push notifications to it. We also use this to track what permissions the user has granted us.
     * SetNotificationTokens Mobile
     */
    async mobileSetNotificationTokensRaw(requestParameters: MobileSetNotificationTokensRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setNotificationTokensRequestBody === null || requestParameters.setNotificationTokensRequestBody === undefined) {
            throw new runtime.RequiredError('setNotificationTokensRequestBody','Required parameter requestParameters.setNotificationTokensRequestBody was null or undefined when calling mobileSetNotificationTokens.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mobile_keys/self/actions/set_notification_tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobileSetNotificationTokensRequestBodyToJSON(requestParameters.setNotificationTokensRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the notification tokens specific to a mobile device so that we can send push notifications to it. We also use this to track what permissions the user has granted us.
     * SetNotificationTokens Mobile
     */
    async mobileSetNotificationTokens(requestParameters: MobileSetNotificationTokensRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mobileSetNotificationTokensRaw(requestParameters, initOverrides);
    }

    /**
     * Get the mobile key currently in use.
     * ShowCurrentKey Mobile
     */
    async mobileShowCurrentKeyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<MobileShowCurrentKeyResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mobile_keys/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileShowCurrentKeyResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the mobile key currently in use.
     * ShowCurrentKey Mobile
     */
    async mobileShowCurrentKey(initOverrides?: RequestInit): Promise<MobileShowCurrentKeyResponseBody> {
        const response = await this.mobileShowCurrentKeyRaw(initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum MobileHandleMicrosoftRedirectFailureReasonEnum {
    Success = 'success',
    SlackLoginFailed = 'slack_login_failed',
    SlackLoginInvalidState = 'slack_login_invalid_state',
    UserCancelled = 'user_cancelled',
    TokenExchangeFailed = 'token_exchange_failed',
    NotYetInstalledMobile = 'not_yet_installed_mobile',
    UnknownOrganisation = 'unknown_organisation'
}
/**
    * @export
    * @enum {string}
    */
export enum MobileHandleSAMLRedirectFailureReasonEnum {
    Success = 'success',
    SlackLoginFailed = 'slack_login_failed',
    SlackLoginInvalidState = 'slack_login_invalid_state',
    UserCancelled = 'user_cancelled',
    TokenExchangeFailed = 'token_exchange_failed',
    NotYetInstalledMobile = 'not_yet_installed_mobile',
    UnknownOrganisation = 'unknown_organisation'
}
/**
    * @export
    * @enum {string}
    */
export enum MobileHandleSlackRedirectFailureReasonEnum {
    Success = 'success',
    SlackLoginFailed = 'slack_login_failed',
    SlackLoginInvalidState = 'slack_login_invalid_state',
    UserCancelled = 'user_cancelled',
    TokenExchangeFailed = 'token_exchange_failed',
    NotYetInstalledMobile = 'not_yet_installed_mobile',
    UnknownOrganisation = 'unknown_organisation'
}
