import { PromptHealthReport } from "@incident-io/api";
import {
  Avatar,
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";

import { RunPromptHealthReportDrawer } from "./RunPromptHealthReportDrawer";

export const AIHealthReportsListPage = () => {
  const { data, isLoading } = useAPI(
    "aIStaffListPromptHealthReports",
    {},
    {
      fallbackData: { health_reports: [] },
    },
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          theme={ButtonTheme.Primary}
          onClick={() => setIsDrawerOpen(true)}
          analyticsTrackingId={null}
        >
          Run report
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {data?.health_reports.length === 0 ? (
          <EmptyState icon={IconEnum.Filter} content="No reports yet" />
        ) : (
          <StackedList>
            {data?.health_reports.map((healthReport) => (
              <HealthReportRender key={healthReport.id} report={healthReport} />
            ))}
          </StackedList>
        )}
        {isDrawerOpen && (
          <RunPromptHealthReportDrawer onClose={() => setIsDrawerOpen(false)} />
        )}
      </LoadingWrapper>
    </WorkbenchSubPageWrapper>
  );
};

const HealthReportRender = ({ report }: { report: PromptHealthReport }) => {
  const numPromptsWithErrors = Object.keys(report.prompts_with_errors).length;

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/workbench/health-reports/${report.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        {/* Left block */}
        <div className="gap-6 py-4 flex-center-y">
          <div className="font-semibold">
            {formatTimestampLocale({
              timestamp: report.created_at,
              dateStyle: "medium",
              timeStyle: "short",
              addWeekday: true,
            })}
          </div>
          {report.user ? (
            <div className="gap-2 flex-center-y">
              Manually generated
              <Tooltip content={report.user.name}>
                <Avatar
                  url={report.user.avatar_url}
                  name={report.user.name}
                  noTitle
                />
              </Tooltip>
            </div>
          ) : (
            <div>Generated by daily report</div>
          )}
        </div>
        {/* Right block */}
        <div className="flex-center-y gap-2">
          {numPromptsWithErrors > 0 ? (
            <Badge theme={BadgeTheme.Error}>
              {numPromptsWithErrors}/{report.num_prompts} prompts have errors
            </Badge>
          ) : (
            <Badge theme={BadgeTheme.Success}>
              All {report.num_prompts} prompts passed
            </Badge>
          )}
          <div className="flex items-center justify-end gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(report.overall_cost_cents / 100.0).toFixed(2)}
          </div>
          <Button
            theme={ButtonTheme.Naked}
            icon={IconEnum.ArrowRight}
            href={`/workbench/health-reports/${report.id}`}
            analyticsTrackingId={null}
            title="View report"
            className="justify-end py-4 group-hover:text-slate-900"
          />
        </div>
      </div>
    </Button>
  );
};
