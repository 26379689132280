import {
  Identity,
  OnCallNotificationRule,
  OnCallNotificationRuleNotificationTypeEnum,
  OnCallNotificationRuleRuleTypeEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import {
  Button,
  ButtonTheme,
  IconEnum,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import {
  escalationNotificationText,
  shiftChangeNotificationText,
} from "./helpers";
import { AddRuleModal } from "./OnCallAddNotificationRuleModal";
import { EditRuleModal } from "./OnCallEditNotificationRuleModal";
import {
  OnCallNoNotificationCallout,
  OnCallNotificationConfigurationInvalidCallout,
  ShortestEscalationPath,
} from "./OnCallNotificationConfigurationInvalidCallout";
import { OnCallNotificationBox } from "./OnCallNotificationsBox";
import { Method, RuleTypeDisplayItem } from "./OnCallNotificationsPage";

export const NotificationRulesForType = ({
  type,
  rules,
  displayItem,
  methods,
  shortestEscalationPath,
  loading,
}: {
  type: OnCallNotificationRuleRuleTypeEnum;
  displayItem: RuleTypeDisplayItem;
  rules: OnCallNotificationRule[];
  methods: Method[];
  shortestEscalationPath?: ShortestEscalationPath;
  loading: boolean;
}) => {
  const { identity } = useIdentity();
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [showEditRuleModal, setShowEditRuleModal] = useState<string | false>(
    false,
  );

  const currentSelectedRule = rules.find((r) => r.id === showEditRuleModal);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<
    false | string
  >(false);
  const { trigger: onDelete, isMutating: isDestroying } = useAPIMutation(
    "onCallNotificationsListRules",
    undefined,
    async (apiClient, req: { id: string }) => {
      await apiClient.onCallNotificationsDestroyRule(req);
    },
    {
      onSuccess: () => setShowConfirmDeleteModal(false),
    },
  );

  const isViolationForRuleType =
    shortestEscalationPath?.seconds && rules.length > 0
      ? _.chain(rules)
          .map((r) => r.delay_seconds)
          .min()
          .value() > shortestEscalationPath.seconds
      : false;

  const lowestNotificationTime = _.chain(rules)
    .filter(
      (r) => r.rule_type !== OnCallNotificationRuleRuleTypeEnum.ShiftChanges,
    )
    .map((r) => r.delay_seconds)
    .min()
    .value();

  const shouldShowInvalidConfigBanner =
    !loading &&
    shortestEscalationPath &&
    isViolationForRuleType &&
    lowestNotificationTime > shortestEscalationPath.seconds;

  const shouldShowNoConfigBanner =
    !loading &&
    type !== OnCallNotificationRuleRuleTypeEnum.ShiftChanges &&
    rules.length === 0;

  // Group 'rules' by Immediately -> After 1 min -> After 3 min -> After 5 min ....
  const groupedRules = _.groupBy(rules, (rule) =>
    type === OnCallNotificationRuleRuleTypeEnum.ShiftChanges
      ? rule.notice_period_seconds
      : rule.delay_seconds,
  );
  const delays = _.keys(groupedRules);

  const ListOfRules = () => (
    <>
      {delays.map((delay) => {
        return (
          <>
            <StackedList className={"w-full text-sm"}>
              {groupedRules[delay].map((r) => {
                const destinationLabel = getRuleDestinationLabel(identity, r);
                return (
                  <StackedListItem
                    key={r.id}
                    title={
                      <span className="font-normal">
                        <NotifyMeDetails type={type} r={r} />
                        via{" "}
                        <span className={"text-content-primary font-semibold"}>
                          {
                            getNotificationTypeLabel(r.notification_type)
                              .sentence
                          }
                        </span>{" "}
                        {destinationLabel && (
                          <>
                            at{" "}
                            <span
                              className={"text-content-primary font-semibold"}
                            >
                              {destinationLabel}
                            </span>
                          </>
                        )}
                      </span>
                    }
                    accessory={
                      <div className={"flex items-center gap-2"}>
                        <Button
                          analyticsTrackingId={"edit-notification-rule"}
                          theme={ButtonTheme.Naked}
                          icon={IconEnum.Edit}
                          title={"Edit"}
                          onClick={() => setShowEditRuleModal(r.id)}
                        />
                        {/* We don't allow to delete the last notification rule */}
                        {(type ===
                          OnCallNotificationRuleRuleTypeEnum.ShiftChanges ||
                          rules.length > 1) && (
                          <Button
                            theme={ButtonTheme.Naked}
                            icon={IconEnum.Delete}
                            title={"Delete"}
                            analyticsTrackingId={
                              "delete-on-call-notification-rule"
                            }
                            onClick={() => setShowConfirmDeleteModal(r.id)}
                          />
                        )}
                      </div>
                    }
                  />
                );
              })}
            </StackedList>
            <div className={"h-[24px] w-[1px] bg-surface-tertiary"} />
          </>
        );
      })}
    </>
  );

  return (
    <OnCallNotificationBox
      title={displayItem.title}
      description={displayItem.description}
      className={"items-center"}
    >
      {shouldShowInvalidConfigBanner ? (
        <div className="mb-2">
          <OnCallNotificationConfigurationInvalidCallout
            shortestEscalationPath={shortestEscalationPath}
            currentLowestNotificationTime={lowestNotificationTime}
            mode={"page"}
          />
        </div>
      ) : null}
      {shouldShowNoConfigBanner ? (
        <div className="mb-2">
          <OnCallNoNotificationCallout type={type} />
        </div>
      ) : null}
      {rules.length > 0 && <ListOfRules />}
      <Button
        icon={IconEnum.Add}
        analyticsTrackingId={"add-on-call-notification-method"}
        onClick={() => setShowAddRuleModal(true)}
        title={`Add ${rules.length > 0 ? "another" : "a"} rule`}
      />
      {showAddRuleModal && (
        <AddRuleModal
          onClose={() => setShowAddRuleModal(false)}
          methods={methods}
          ruleType={type}
        />
      )}
      {showConfirmDeleteModal && (
        <DeletionConfirmationModal
          resourceTitle={"Notification rule"}
          onDelete={() => {
            onDelete({ id: showConfirmDeleteModal });
          }}
          isDeleting={isDestroying}
          isOpen={true}
          onClose={() => setShowConfirmDeleteModal(false)}
          title="Remove rule"
          deleteConfirmationContent={
            "Are you sure you want to remove this notification rule?"
          }
          analyticsTrackingId="delete-notification-rule"
        />
      )}
      {showEditRuleModal && currentSelectedRule && (
        <EditRuleModal
          onClose={() => setShowEditRuleModal(false)}
          currentSelectedRule={currentSelectedRule}
        />
      )}
    </OnCallNotificationBox>
  );
};

// Provides a label for notification type in either sentence or title case.
const getNotificationTypeLabel = (
  notificationType: OnCallNotificationRuleNotificationTypeEnum,
): { sentence: string; title: string } => {
  switch (notificationType) {
    case OnCallNotificationRuleNotificationTypeEnum.App:
      return { sentence: "app", title: "App" };
    case OnCallNotificationRuleNotificationTypeEnum.Email:
      return { sentence: "email", title: "Email" };
    case OnCallNotificationRuleNotificationTypeEnum.Phone:
      return { sentence: "phone", title: "Phone" };
    case OnCallNotificationRuleNotificationTypeEnum.Sms:
      return { sentence: "SMS", title: "SMS" };
    case OnCallNotificationRuleNotificationTypeEnum.Slack:
      return { sentence: "Slack", title: "Slack" };
    case OnCallNotificationRuleNotificationTypeEnum.MicrosoftTeams:
      return { sentence: "Microsoft Teams", title: "Microsoft Teams" };
    case OnCallNotificationRuleNotificationTypeEnum.SlackChannel:
      return { sentence: "Slack channel", title: "Slack Channel" };
    case OnCallNotificationRuleNotificationTypeEnum.LiveCall:
      // This shouldn't be displayed anywhere in the API.
      return { sentence: "live call", title: "Live Call" };
    default:
      assertUnreachable(notificationType);
  }

  return { sentence: "", title: "" };
};

// Used for the "<label>" in "Notify me <delay> via <method> at <label>".
const getRuleDestinationLabel = (
  identity: Identity | null,
  rule: OnCallNotificationRule,
): string | undefined => {
  switch (rule.notification_type) {
    // These notification types don't need a destination qualifier.
    case OnCallNotificationRuleNotificationTypeEnum.App:
    case OnCallNotificationRuleNotificationTypeEnum.Slack:
      return undefined;

    // These are useful to provide a qualifier for.
    case OnCallNotificationRuleNotificationTypeEnum.Email:
      return (rule.method?.email_address || identity?.user_email) ?? "";
    case OnCallNotificationRuleNotificationTypeEnum.Sms:
    case OnCallNotificationRuleNotificationTypeEnum.Phone:
      return rule.method.phone_number ?? "";
  }

  return undefined;
};

const NotifyMeDetails = ({
  type,
  r,
}: {
  type: OnCallNotificationRuleRuleTypeEnum;
  r: OnCallNotificationRule;
}) => {
  return (
    <>
      <span className={"text-content-primary font-semibold"}>
        {type === OnCallNotificationRuleRuleTypeEnum.ShiftChanges
          ? `${shiftChangeNotificationText(r.notice_period_seconds as number)}` // notice_period_seconds is not null for shift changes
          : `${escalationNotificationText(r.delay_seconds)}`}
      </span>{" "}
    </>
  );
};
