import { getCatalogTypeaheadOptions } from "@incident-shared/catalog";
import { Badge, BadgeTheme } from "@incident-ui";
import {
  isSelectOption,
  isSelectOptionGroup,
  SelectOption,
  SelectOptionGroup,
  SelectOptionOrGroup,
} from "@incident-ui/Select/types";

import {
  CatalogListTypesResponseBody,
  ClientType,
  ManagedResource,
  TeamsAddResourceRequestBodyResourceTypeEnum,
} from "../../../../contexts/ClientContext";

const buildOption = (
  data: SelectOption,
  managedResources: ManagedResource[],
  resourceType: TeamsAddResourceRequestBodyResourceTypeEnum,
) => {
  // Find matching managed resource
  const managedResource = managedResources.find(
    (resource) => resource.resource_id === data.value,
  );

  return {
    label: data.label,
    value: data.value,
    sort_key: data.sort_key || data.label.toString(),
    badge: managedResource ? (
      <Badge theme={BadgeTheme.Tertiary}>Externally managed</Badge>
    ) : undefined,
    disabled: !!managedResource,
    type: resourceType,
  };
};

const extractOptions = (
  options: SelectOptionOrGroup[],
  managedResources: ManagedResource[],
  resourceType: TeamsAddResourceRequestBodyResourceTypeEnum,
): SelectOption[] => {
  const result: SelectOption[] = [];

  options.forEach((option) => {
    if (isSelectOption(option)) {
      result.push(buildOption(option, managedResources, resourceType));
    } else if (isSelectOptionGroup(option)) {
      // Handle nested options
      option.options.forEach((nestedOption) => {
        result.push(buildOption(nestedOption, managedResources, resourceType));
      });
    }
  });

  return result;
};

export const loadSchedules = (
  apiClient: ClientType,
  catalogTypes: CatalogListTypesResponseBody,
  managedResources: ManagedResource[],
) => {
  const scheduleCatalogType = catalogTypes.catalog_types.find(
    (x) => x.type_name === "Schedule",
  );

  const getScheduleOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: scheduleCatalogType?.id || "",
  });

  return async (inputValue: string): Promise<SelectOptionGroup[]> => {
    const options = await getScheduleOptions(inputValue);

    // Extract and process all schedule options
    const scheduleOptions = extractOptions(
      options,
      managedResources,
      TeamsAddResourceRequestBodyResourceTypeEnum.Schedule,
    );

    // Group the options into a SelectOptionGroup
    return [
      {
        label: "Schedules",
        options: scheduleOptions,
      },
    ];
  };
};

export const loadEscalationPaths = (
  apiClient: ClientType,
  catalogTypes: CatalogListTypesResponseBody,
  managedResources: ManagedResource[],
) => {
  const escalationPathCatalogType = catalogTypes.catalog_types.find(
    (x) => x.type_name === "EscalationPath",
  );

  const getEscalationPathOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: escalationPathCatalogType?.id || "",
  });

  return async (inputValue: string): Promise<SelectOptionGroup[]> => {
    const options = await getEscalationPathOptions(inputValue);

    // Extract and process all escalation path options
    const escalationPathOptions = extractOptions(
      options,
      managedResources,
      TeamsAddResourceRequestBodyResourceTypeEnum.EscalationPath,
    );

    // Group the options into a SelectOptionGroup
    return [
      {
        label: "Escalation paths",
        options: escalationPathOptions,
      },
    ];
  };
};
