/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StripeSubscription,
    StripeSubscriptionFromJSON,
    StripeSubscriptionFromJSONTyped,
    StripeSubscriptionToJSON,
} from './StripeSubscription';

/**
 * 
 * @export
 * @interface StripeDetails
 */
export interface StripeDetails {
    /**
     * Stripe customer ID
     * @type {string}
     * @memberof StripeDetails
     */
    customer_id: string;
    /**
     * Scheduled subscription ID, if set
     * @type {string}
     * @memberof StripeDetails
     */
    scheduled_subscription_id?: string;
    /**
     * Date the scheduled subscription should start
     * @type {Date}
     * @memberof StripeDetails
     */
    scheduled_subscription_start_date?: Date;
    /**
     * Stripe subscription ID
     * @type {string}
     * @memberof StripeDetails
     */
    subscription_id?: string;
    /**
     * 
     * @type {Array<StripeSubscription>}
     * @memberof StripeDetails
     */
    subscriptions: Array<StripeSubscription>;
}

export function StripeDetailsFromJSON(json: any): StripeDetails {
    return StripeDetailsFromJSONTyped(json, false);
}

export function StripeDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer_id': json['customer_id'],
        'scheduled_subscription_id': !exists(json, 'scheduled_subscription_id') ? undefined : json['scheduled_subscription_id'],
        'scheduled_subscription_start_date': !exists(json, 'scheduled_subscription_start_date') ? undefined : (new Date(json['scheduled_subscription_start_date'])),
        'subscription_id': !exists(json, 'subscription_id') ? undefined : json['subscription_id'],
        'subscriptions': ((json['subscriptions'] as Array<any>).map(StripeSubscriptionFromJSON)),
    };
}

export function StripeDetailsToJSON(value?: StripeDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer_id': value.customer_id,
        'scheduled_subscription_id': value.scheduled_subscription_id,
        'scheduled_subscription_start_date': value.scheduled_subscription_start_date === undefined ? undefined : (value.scheduled_subscription_start_date.toISOString()),
        'subscription_id': value.subscription_id,
        'subscriptions': ((value.subscriptions as Array<any>).map(StripeSubscriptionToJSON)),
    };
}

