/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface IncidentFormsGetLifecycleElementBindingsRequestBody
 */
export interface IncidentFormsGetLifecycleElementBindingsRequestBody {
    /**
     * Calculate required fields based on an incident with these fields
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    custom_field_entries: Array<CustomFieldEntryPayload>;
    /**
     * The type of this incident form
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    incident_form_type: IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum;
    /**
     * The ID of the incident (if there is one)
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    incident_id?: string;
    /**
     * Calculate and derive fields based on an incident with these roles assigned
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    incident_role_assignments: Array<IncidentRoleAssignmentPayload>;
    /**
     * The ID of the incident status this incident is about to be in
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    incident_status_id: string;
    /**
     * The ID of this incident type this incident is configured to use
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    incident_type_id?: string;
    /**
     * The mode of this incident. Not currently used, but it helps avoid contention when rendering the live and test incident modals from the web by having unique swr keys
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    mode?: string;
    /**
     * The ID of the current severity of this incident
     * @type {string}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    severity_id?: string;
    /**
     * Whether to include all elements, or only those that should be shown based on their configuration. This is used to power the preview in the configuration screen.
     * @type {boolean}
     * @memberof IncidentFormsGetLifecycleElementBindingsRequestBody
     */
    show_all_elements_override: boolean;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum {
    Declare = 'declare',
    Accept = 'accept',
    Update = 'update',
    Resolve = 'resolve',
    Retrospective = 'retrospective',
    CustomFields = 'custom-fields',
    Escalate = 'escalate'
}

export function IncidentFormsGetLifecycleElementBindingsRequestBodyFromJSON(json: any): IncidentFormsGetLifecycleElementBindingsRequestBody {
    return IncidentFormsGetLifecycleElementBindingsRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsGetLifecycleElementBindingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsGetLifecycleElementBindingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'incident_form_type': json['incident_form_type'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'incident_role_assignments': ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'incident_status_id': json['incident_status_id'],
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'severity_id': !exists(json, 'severity_id') ? undefined : json['severity_id'],
        'show_all_elements_override': json['show_all_elements_override'],
    };
}

export function IncidentFormsGetLifecycleElementBindingsRequestBodyToJSON(value?: IncidentFormsGetLifecycleElementBindingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'incident_form_type': value.incident_form_type,
        'incident_id': value.incident_id,
        'incident_role_assignments': ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'incident_status_id': value.incident_status_id,
        'incident_type_id': value.incident_type_id,
        'mode': value.mode,
        'severity_id': value.severity_id,
        'show_all_elements_override': value.show_all_elements_override,
    };
}

