import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useAPI } from "src/utils/swr";

export const PromptToolSelect = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
  label,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
  label?: string;
}) => {
  const {
    data: { tools },
  } = useAPI(
    "aIStaffListTools",
    {},
    {
      fallbackData: { tools: [] },
    },
  );

  const options =
    tools.map((prompt) => ({
      label: prompt,
      value: prompt,
    })) ?? [];

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      placeholder="(e.g. ToolInvestigationGetArtifiacts)"
      options={options}
      label={label}
      helptext={helptext}
      isClearable={true}
    />
  );
};
