/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamResource
 */
export interface TeamResource {
    /**
     * ID of the resource
     * @type {string}
     * @memberof TeamResource
     */
    id: string;
    /**
     * Name of the resource
     * @type {string}
     * @memberof TeamResource
     */
    name: string;
    /**
     * Type of the resource
     * @type {string}
     * @memberof TeamResource
     */
    type: TeamResourceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum TeamResourceTypeEnum {
    EscalationPath = 'escalation_path',
    Schedule = 'schedule'
}

export function TeamResourceFromJSON(json: any): TeamResource {
    return TeamResourceFromJSONTyped(json, false);
}

export function TeamResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
    };
}

export function TeamResourceToJSON(value?: TeamResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
    };
}

