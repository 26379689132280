import {
  Context,
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from "react";

type RenderableIncident = {
  name: string;
  incident_id: string;
};

export type IncidentLinkProps = {
  incident: RenderableIncident;
  children: React.ReactNode;
  className: string;
  analyticsTrackingId: string | null;
};

export type InternalLinkProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
  analyticsTrackingId: string | null;
};

export const UIContext = createContext<{
  IncidentLink: (props: IncidentLinkProps) => ReactElement;
  onError: (e: Error) => void;
  InternalLink: (props: InternalLinkProps) => ReactElement;
}>({
  IncidentLink: () => {
    throw new Error("Unreachable: expected UIContext to be set.");
  },
  onError: console.error,
  InternalLink: () => {
    throw new Error("Unreachable: expected UIContext to be set.");
  },
});

export const useUIContext = () => useContext(UIContext);

type ContextType<T> = T extends Context<infer X> ? X : never;

export const UIProvider = ({
  IncidentLink,
  InternalLink,
  onError,
  children,
}: ContextType<typeof UIContext> & {
  children: ReactNode;
}) => (
  <UIContext.Provider
    value={{
      onError,
      IncidentLink,
      InternalLink,
    }}
  >
    {children}
  </UIContext.Provider>
);
