/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReplicaUserStatus
 */
export interface ScheduleReplicaUserStatus {
    /**
     * External user ID if resolved
     * @type {string}
     * @memberof ScheduleReplicaUserStatus
     */
    external_user_id?: string;
    /**
     * The internal user ID
     * @type {string}
     * @memberof ScheduleReplicaUserStatus
     */
    user_id: string;
}

export function ScheduleReplicaUserStatusFromJSON(json: any): ScheduleReplicaUserStatus {
    return ScheduleReplicaUserStatusFromJSONTyped(json, false);
}

export function ScheduleReplicaUserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplicaUserStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_user_id': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'user_id': json['user_id'],
    };
}

export function ScheduleReplicaUserStatusToJSON(value?: ScheduleReplicaUserStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user_id': value.external_user_id,
        'user_id': value.user_id,
    };
}

