import { FriendlyDuration } from "@incident-shared/durations/helpers";
import {
  bindingToPayload,
  EngineFormElement,
  isEmptyBinding,
} from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import {
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_MINUTE,
} from "@incident-shared/forms/constants";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { DurationInputV2 } from "@incident-shared/forms/v2/inputs/DurationInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  ErrorMessage,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { InputType } from "@incident-ui/Input/Input";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import styles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import {
  AvailableNudge,
  AvailableNudgeNudgeTypeEnum,
  EngineScope,
  ErrorResponse,
  Nudge,
  NudgeIconEnum,
  NudgeNudgeTypeEnum,
  NudgesBuildScopeNudgeTypeEnum,
  NudgesCreateRequestBody,
  NudgesCreateRequestBodyNudgeTypeEnum,
  NudgesFixedConditionsRequestBodyNudgeTypeEnum,
  NudgesUpdateRequestBody,
  NudgeWorkflowButton,
  Resource,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useSWRConfig } from "swr";

import { NudgePreview } from "./NudgePreview";
import { WorkflowButtonsEditor } from "./WorkflowButtonsEditor";

export type NudgeCreateEditFormData = Omit<
  Nudge,
  | "id"
  | "disabled_at"
  | "enabled"
  | "params"
  | "created_at"
  | "updated_at"
  | "maximum_nudge_run_count"
  | "preview"
  | "intelligent_mode"
  | "fixed_conditions"
> & {
  delay: number;
  smart_scheduling: boolean;
  // maximum_nudge_count should be a string in the form, but an integer in the
  // API.
  maximum_nudge_run_count?: string | undefined;
  repeat: number;
};

type NudgesCreateEditFormProps = CreateEditFormProps<Nudge> & {
  nudgeType: NudgeNudgeTypeEnum;
  renameToChannelSuggestion: boolean;
  onClose: () => void;
};

const useNudgeScope = ({ type }: { type: NudgeNudgeTypeEnum }) => {
  const { data: scopeResult, isLoading } = useAPI("nudgesBuildScope", {
    nudgeType: type as unknown as NudgesBuildScopeNudgeTypeEnum,
  });

  return {
    scope: (scopeResult?.scope ?? {
      scope: { references: [], aliases: {} },
    }) as unknown as EngineScope,
    scopeLoading: isLoading,
  };
};
export const NudgesCreateEditForm = (
  props: NudgesCreateEditFormProps,
): React.ReactElement => {
  const { resources, resourcesLoading } = useAllResources();

  const { scope, scopeLoading } = useNudgeScope({
    type: props.nudgeType,
  });

  const {
    data: { available_nudges: availableNudges },
    isLoading: availableNudgesLoading,
  } = useAPI("nudgesListAvailableNudges", undefined, {
    fallbackData: { available_nudges: [] },
  });

  const isLoading = resourcesLoading || availableNudgesLoading || scopeLoading;

  return (
    <Drawer warnWhenDirty width="large" onClose={props.onClose}>
      {isLoading ? (
        <DrawerContentsLoading />
      ) : (
        <NudgesCreateEditFormInner
          {...props}
          scope={scope}
          availableNudges={availableNudges}
          resources={resources}
        />
      )}
    </Drawer>
  );
};

const NudgesCreateEditFormInner = ({
  mode,
  initialData,
  nudgeType,
  scope,
  availableNudges,
  onClose,
  resources,
  renameToChannelSuggestion,
}: NudgesCreateEditFormProps & {
  scope: EngineScope;
  availableNudges: AvailableNudge[];
  resources: Resource[];
  onClose: () => void;
}): React.ReactElement => {
  const isEditing = mode === Mode.Edit;
  const hasNudge = initialData !== undefined;
  const noun = renameToChannelSuggestion ? "channel suggestion" : "nudge";
  const nounCap = renameToChannelSuggestion ? "Channel suggestion" : "Nudge";

  const availableNudge = availableNudges.find((availableNudge) => {
    return (
      (availableNudge.nudge_type as unknown as NudgeNudgeTypeEnum) === nudgeType
    );
  });

  if (availableNudge === undefined) {
    // We should never hit this!
    throw new Error(`Could not find nudge with name ${nudgeType}`);
  }

  const defaultValues = hasNudge
    ? fromShowToFormState(initialData)
    : fromShowToFormState({
        ...availableNudge.default_values,
        intelligent_mode: true,
        nudge_type: nudgeType,
        icon: availableNudge.icon as unknown as NudgeIconEnum,
      });

  const showToast = useToast();

  const formMethods = useForm<NudgeCreateEditFormData>({
    defaultValues: defaultValues,
  });

  const { setError, watch } = formMethods;

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const [
    delay,
    repeat,
    messageContent,
    maximumNudgeCount,
    smartSchedulingEnabled,
    paramBindings,
    workflowButtons,
  ] = watch([
    "delay",
    "repeat",
    "message_content",
    "maximum_nudge_run_count",
    "smart_scheduling",
    "param_bindings",
    "workflow_buttons",
  ]);

  const { mutate: setSWRData } = useSWRConfig();

  const { trigger: createNudgeSubmit, isMutating: isCreatingNudge } =
    useAPIMutation(
      "nudgesListNudges",
      undefined,
      async (apiClient, data: NudgeCreateEditFormData) => {
        await apiClient.nudgesCreate({
          createRequestBody: formStateToRequestBody(
            data,
          ) as NudgesCreateRequestBody,
        });
      },
      {
        setError,
        onSuccess: () => {
          showToast({
            theme: ToastTheme.Success,
            title: nounCap + " created",
            toastSide: ToastSideEnum.Bottom,
          });
          onClose();
        },
        showErrorToast: "Could not create nudge",
      },
    );

  const { trigger: updateNudgeSubmit, isMutating: isUpdatingNudge } =
    useAPIMutation(
      initialData ? "nudgesShow" : "nudgesListNudges",
      initialData
        ? {
            id: initialData.id,
          }
        : undefined,
      async (apiClient, data: NudgeCreateEditFormData) => {
        if (initialData === undefined) {
          throw new Error("Missing nudge");
        }

        return apiClient.nudgesUpdate({
          id: initialData.id,
          updateRequestBody: formStateToRequestBody(
            data,
          ) as NudgesUpdateRequestBody,
        });
      },
      {
        setError,
        onSuccess: (data) => {
          const dataNudgeShow = data as unknown as Nudge;
          showToast({
            theme: ToastTheme.Success,
            title: nounCap + " updated",
            toastSide: ToastSideEnum.Bottom,
          });
          setSWRData(["nudgesShow", { id: dataNudgeShow.id }], dataNudgeShow);
          onClose();
        },
        showErrorToast: "Could not save " + nounCap,
      },
    );

  let pageTitle = isEditing ? "Edit " + noun : "Create new " + noun;
  if (availableNudge) {
    pageTitle = `${pageTitle}: ${availableNudge.label}`;
  }

  const {
    data: { conditions: fixedConditions },
    error: fixedConditionsError,
  } = useAPI(
    "nudgesFixedConditions",
    {
      fixedConditionsRequestBody: {
        nudge_type:
          nudgeType as unknown as NudgesFixedConditionsRequestBodyNudgeTypeEnum,
        param_bindings: _.cloneDeep(paramBindings),
      },
    },
    { fallbackData: { conditions: [] } },
  );

  const isMutating = isCreatingNudge || isUpdatingNudge;

  const allRequiredParamsSet = availableNudge.params?.every((param, idx) => {
    if (param.optional) {
      return true;
    }
    const binding = paramBindings?.[idx];
    if (!binding) {
      return false;
    }
    return !isEmptyBinding(binding);
  });

  return (
    <div className="flex flex-col h-full !overflow-hidden">
      <DrawerTitle
        title={pageTitle}
        onClose={onClose}
        icon={IconEnum.CustomField}
        color={ColorPaletteEnum.Blue}
        compact
        invertIcon={false}
        theme={DrawerTitleTheme.Bordered}
      />
      <DrawerContents>
        <DrawerBody className="flex flex-row min-h-0 overflow-y-hidden h-full !p-0 !gap-0">
          {/* Left hand, input side of the form */}
          <div
            className={tcx(
              "!h-full overflow-y-hidden flex flex-col flex-[1] border-r border-stroke",
              styles.hideScrollbar,
            )}
          >
            <Form.Root
              id="nudge-create-edit"
              onSubmit={isEditing ? updateNudgeSubmit : createNudgeSubmit}
              formMethods={formMethods}
              saving={isMutating}
              outerClassName="h-full overflow-auto p-6"
              innerClassName="pb-6"
            >
              <div className="flex flex-col gap-6">
                {availableNudge.params?.length > 0 && (
                  <>
                    {availableNudge.params.map((param, i) => (
                      <EngineFormElement
                        key={i}
                        name={`param_bindings.${i}`}
                        resources={resources}
                        resourceType={param.type}
                        array={param.array}
                        label={param.label}
                        description={param.description}
                        required={!param.optional}
                        showPlaceholder
                        mode="plain_input"
                      />
                    ))}
                    <hr />
                  </>
                )}
                <InputV2
                  formMethods={formMethods}
                  name="name"
                  label="Name"
                  required="Please enter a name"
                  data-testid="name-input"
                  placeholder={
                    "e.g. Prompt responder to assign a leader within 15 minutes"
                  }
                />
                {allRequiredParamsSet && (
                  <>
                    <ConditionGroupsEditorV2
                      label="Conditions"
                      formMethods={formMethods}
                      name={"condition_groups"}
                      conditionLabel={"condition"}
                      scope={scope}
                      fixedConditionGroups={
                        fixedConditions ? [{ conditions: fixedConditions }] : []
                      }
                      fixedConditionExplainText="This is a fixed condition that cannot be edited"
                      emptyIntroSentence={`This ${noun} will run on all incidents`}
                      populatedIntroSentence={`This ${noun} will run on incidents where:`}
                    />
                    {(fixedConditionsError as ErrorResponse | undefined) &&
                      fixedConditionsError?.errors.map((e, idx) => (
                        <ErrorMessage
                          key={idx}
                          message={e.message}
                          className="mt-2"
                        />
                      ))}
                    <EngineFormElement
                      name={"message_content"}
                      label={"Prompt"}
                      description="This will be sent as a message to the incident channel"
                      required={true}
                      mode={"variables_only"}
                      array={false}
                      scope={scope}
                      resources={resources}
                      resourceType={'TemplatedText["mrkdwn"]'}
                    />
                    {availableNudge.nudge_type ===
                      AvailableNudgeNudgeTypeEnum.RunWorkflows && (
                      <WorkflowButtonsEditor />
                    )}
                    <hr />
                    <div className="flex flex-col gap-2">
                      <div className="font-medium text-content-primary text-sm">
                        Scheduling
                      </div>
                      <Callout theme={CalloutTheme.Plain} showIcon={false}>
                        Sending messages too frequently could distract
                        responders from other important tasks.
                      </Callout>
                    </div>
                    <div>
                      <DurationInputV2
                        name="delay"
                        formMethods={formMethods}
                        label="Initial delay"
                        helptext={``}
                        inputClassName="max-w-[300px]"
                        className="mb-2"
                        includeSeconds={false}
                      />
                      <Form.Helptext>
                        After the conditions are met, we&apos;ll wait{" "}
                        <FriendlyDuration seconds={delay} /> before we send the
                        first {noun}.
                      </Form.Helptext>
                      {delay > SECONDS_IN_A_DAY * 7 && (
                        <ErrorMessage
                          className="mt-2"
                          message="The maximum delay is a week. Please enter a smaller value."
                        />
                      )}
                    </div>
                    <div>
                      <InputV2
                        formMethods={formMethods}
                        name="maximum_nudge_run_count"
                        label="Attempts"
                        inputClassName="max-w-[200px]"
                        type={InputType.Number}
                        className="mb-2"
                      />
                      {maximumNudgeCount ? (
                        <Form.Helptext className="mb-0">
                          {`After ${maximumNudgeCount} attempt${
                            maximumNudgeCount === "1" ? "" : "s"
                          }, we'll stop sending the nudge.`}
                        </Form.Helptext>
                      ) : (
                        <Form.Helptext className="mb-0">
                          The {noun} will keep being sent until it&apos;s
                          actioned.
                        </Form.Helptext>
                      )}
                    </div>
                    {maximumNudgeCount !== "1" && (
                      <div>
                        <DurationInputV2
                          name="repeat"
                          formMethods={formMethods}
                          label="Repeat interval"
                          inputClassName="max-w-[300px]"
                          className="mb-2"
                          includeSeconds={false}
                          disabled={maximumNudgeCount === "1"}
                        />
                        <Form.Helptext>
                          If no action has been taken, we&apos;ll send another{" "}
                          {noun} every <FriendlyDuration seconds={repeat} />.
                        </Form.Helptext>
                        {repeat < SECONDS_IN_A_MINUTE * 5 && (
                          <ErrorMessage message="The minimum repeat interval is five minutes. Please enter a larger value." />
                        )}
                        {repeat > SECONDS_IN_A_DAY * 7 && (
                          <ErrorMessage message="The maximum repeat interval is a week. Please enter a smaller value." />
                        )}
                      </div>
                    )}
                    <ContentBox className="p-3 flex-col gap-2">
                      <ToggleV2
                        formMethods={formMethods}
                        name="smart_scheduling"
                        label="Smart scheduling"
                        toggleLabelClassName="w-full"
                        align="left"
                        className="w-full"
                      />
                      <Form.Helptext>
                        Smart scheduling reduces channel noise by timing
                        reminders based on incident activity. {nounCap}s may be
                        delayed if another was recently sent.
                      </Form.Helptext>
                    </ContentBox>
                    {!smartSchedulingEnabled && (
                      <Callout theme={CalloutTheme.Warning}>
                        Disabling this means that {noun}s are likely to cause
                        more noise within incident channels.
                      </Callout>
                    )}
                  </>
                )}
              </div>
            </Form.Root>
          </div>

          {/* Right hand, preview side of the form */}
          <div
            className={
              "flex flex-col items-stretch flex-[1] p-6 bg-surface-secondary overflow-auto gap-3"
            }
          >
            <div className="text-sm-bold">Preview</div>
            <NudgePreview
              messageContent={messageContent}
              nudgeType={nudgeType}
              paramBindings={paramBindings.map(bindingToPayload)}
              workflowButtons={workflowButtons || []}
            />
          </div>
        </DrawerBody>
        <DrawerFooter className="flex justify-end gap-2">
          <Button
            analyticsTrackingId={null}
            onClick={() => onCloseWithWarn(isDirty)}
            theme={ButtonTheme.Secondary}
          >
            Cancel
          </Button>
          <Button
            form="nudge-create-edit"
            type="submit"
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            loading={isMutating}
          >
            {isEditing ? "Save" : "Create"}
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </div>
  );
};

const formStateToRequestBody = (
  data: NudgeCreateEditFormData,
): NudgesCreateRequestBody | NudgesUpdateRequestBody => {
  const maxNudgeCount =
    data.maximum_nudge_run_count !== undefined &&
    data.maximum_nudge_run_count !== ""
      ? parseInt(data.maximum_nudge_run_count, 10)
      : undefined;

  return {
    name: data.name,
    nudge_type:
      data.nudge_type as unknown as NudgesCreateRequestBodyNudgeTypeEnum,
    condition_groups: conditionGroupsToGroupPayloads(data.condition_groups),
    message_content: data.message_content,
    intelligent_mode: data.smart_scheduling,
    delay_for_seconds: data.delay,
    repeat_after_seconds: data.repeat,
    maximum_nudge_run_count: maxNudgeCount,
    param_bindings: data.param_bindings,
    workflow_buttons: data.workflow_buttons,
  };
};

const fromShowToFormState = (
  data:
    | Omit<Nudge, "id" | "params" | "enabled" | "preview" | "fixed_conditions">
    | undefined,
): NudgeCreateEditFormData => {
  if (data === undefined) {
    throw new Error("Missing nudge");
  }
  const maxNudgeCount =
    data.maximum_nudge_run_count !== undefined
      ? data.maximum_nudge_run_count.toString(10)
      : undefined;

  let defaultWorkflowButtons: NudgeWorkflowButton[] =
    data.workflow_buttons || [];

  if (
    data.nudge_type === NudgeNudgeTypeEnum.RunWorkflows &&
    isEmpty(defaultWorkflowButtons)
  ) {
    defaultWorkflowButtons = [
      {
        emoji: "robot_face",
        text: "Run workflow",
        workflow_id: "",
      },
    ];
  }

  return {
    name: data.name,
    nudge_type: data.nudge_type,
    icon: data.icon,
    condition_groups: data.condition_groups,
    message_content: data.message_content,
    smart_scheduling: data.intelligent_mode,
    delay_for_seconds: data.delay_for_seconds,
    delay: data.delay_for_seconds,
    repeat_after_seconds: data.repeat_after_seconds,
    repeat: data.repeat_after_seconds,
    maximum_nudge_run_count: maxNudgeCount,
    param_bindings: data.param_bindings || [],
    workflow_buttons: defaultWorkflowButtons,
  };
};
