import { StaffRoomTable } from "./StaffRoomTable";

type Row = {
  id: string;
  name: React.ReactNode;
  value: React.ReactNode | undefined;
};

type Props = {
  title?: string;
  rows: Row[];
  widthClass?: string;
};

export const InfoTable = ({ title, rows, widthClass = "w-1/3" }: Props) => {
  const filtered = rows.filter((r) => r.value !== undefined);

  if (filtered.length === 0) {
    return null;
  }

  return (
    <StaffRoomTable
      title={title}
      data={filtered}
      variant="compact"
      widths={[widthClass, ""]}
      renderRow={(r: Row, idx: number) => {
        return (
          <StaffRoomTable.Row key={idx}>
            <StaffRoomTable.Cell className={"text-right text-content-tertiary"}>
              {r.name}
            </StaffRoomTable.Cell>
            <StaffRoomTable.Cell>{r.value}</StaffRoomTable.Cell>
          </StaffRoomTable.Row>
        );
      }}
    />
  );
};
