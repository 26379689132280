import {
  FormField,
  InsightsFilter,
  InsightsFilterOperatorEnum,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { useTeamSettings } from "../../settings/teams/common/useTeamSettings";
import { useUserTeams } from "../../settings/teams/common/useUserTeams";

export const useDefaultTeamsInsightFilter = (
  availableFilterFields: FormField[],
): InsightsFilter[] => {
  const { featureNativeTeams } = useFlags();
  const { userTeams } = useUserTeams();
  const { teamSettings, hasTeamsAlertAttributeConfigured } = useTeamSettings();

  return useMemo((): InsightsFilter[] => {
    if (
      !availableFilterFields ||
      !featureNativeTeams ||
      !hasTeamsAlertAttributeConfigured
    ) {
      return [];
    }

    const matchingField = availableFilterFields.find(
      (field) => field.field_id === teamSettings?.alert_attribute_id,
    );

    const defaultTeams = userTeams?.length > 0 ? userTeams : ["all_teams"];

    if (matchingField) {
      return [
        {
          ...matchingField,
          field_key: matchingField.key,
          field_id: matchingField.field_id ?? "",
          is_disabled: false,
          operator: InsightsFilterOperatorEnum.OneOf,
          values: defaultTeams,
        },
      ];
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilterFields]);
};

export const useDefaultTeamsFilter = (
  availableFilterFields: FormField[],
): ExtendedFormFieldValue[] => {
  const { featureNativeTeams } = useFlags();
  const { userTeams, loading } = useUserTeams();
  const { teamSettings, hasTeamsAlertAttributeConfigured } = useTeamSettings();

  return useMemo((): ExtendedFormFieldValue[] => {
    if (
      !availableFilterFields ||
      !featureNativeTeams ||
      !hasTeamsAlertAttributeConfigured ||
      loading
    ) {
      return [];
    }

    const matchingField = availableFilterFields.find(
      (field) => field.field_id === teamSettings?.alert_attribute_id,
    );

    const defaultTeams = userTeams?.length > 0 ? userTeams : ["all_teams"];

    if (matchingField) {
      return [
        {
          ...matchingField,
          field_key: matchingField.key,
          field_id: matchingField.field_id ?? "",
          filter_id: matchingField.field_id ?? matchingField.key,
          is_disabled: false,
          operator: InsightsFilterOperatorEnum.OneOf,
          multiple_options_value: defaultTeams,
        },
      ];
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilterFields]);
};
