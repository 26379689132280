import { AIRequest } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useOutlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";
import { useClipboard } from "src/utils/useClipboard";

import { PromptNamesSelect } from "../common/PromptNamesSelect";
import { PromptToolSelect } from "../common/PromptToolSelect";

type CopilotRequestFilterValues = {
  prompt: string;
  trace: string;
  interaction: string;
  thread: string;
  calledTool: string;
};

export const AIRequestsListPage = () => {
  const formMethods = useForm<CopilotRequestFilterValues>({
    defaultValues: {},
  });
  const drawer = useOutlet();

  const [showFilters, setShowFilters] = useState(true);

  const filters = formMethods.watch();
  const [searchParams] = useSearchParams();

  const searchParamsTraceID =
    searchParams.get("trace_id") != null
      ? (searchParams.get("trace_id") as string)
      : undefined;

  const searchParamsPrompt =
    searchParams.get("prompt") != null
      ? (searchParams.get("prompt") as string)
      : undefined;

  const traceID = filters.trace
    ? filters.trace
    : searchParamsTraceID
    ? searchParamsTraceID
    : undefined;

  const prompt = filters.prompt
    ? filters.prompt
    : searchParamsPrompt
    ? searchParamsPrompt
    : undefined;

  const {
    data: { requests },
    isLoading,
  } = useAPI(
    "aIStaffListAIRequests",
    {
      traceId: traceID,
      prompt,
      copilotInteractionId: filters.interaction ?? undefined,
      calledTool: filters.calledTool ?? undefined,
    },
    {
      fallbackData: { requests: [] },
    },
  );

  return (
    <WorkbenchSubPageWrapper>
      <AnimatePresence>{drawer}</AnimatePresence>
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex-center-y w-full justify-between">
          <h2 className="text-lg font-semibold">Recent requests</h2>
          <Button
            theme={ButtonTheme.Naked}
            onClick={() => setShowFilters(!showFilters)}
            analyticsTrackingId={null}
            icon={IconEnum.Filter}
          >
            <>Filters</>
            <Icon
              id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
              size={IconSize.Small}
            />
          </Button>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false}>
            <div className="w-full space-y-2">
              <PromptNamesSelect
                formMethods={formMethods}
                name="prompt"
                label="Prompt"
              />
              <PromptToolSelect
                formMethods={formMethods}
                name="calledTool"
                label="Called Tools"
              />
            </div>
          </Callout>
        )}
        <LoadingWrapper loading={isLoading}>
          {requests.length === 0 ? (
            <EmptyState content="Prompt was not ran when handling the last 100 requests" />
          ) : (
            <>
              <StackedList>
                {requests.map((req) => (
                  <AIEvalRow key={req.id} request={req} />
                ))}
              </StackedList>
            </>
          )}
        </LoadingWrapper>
      </Form.Root>
    </WorkbenchSubPageWrapper>
  );
};

const AIEvalRow = ({ request }: { request: AIRequest }) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const { setValue, watch } = useFormContext<CopilotRequestFilterValues>();

  const filters = watch();

  const filteredByPrompt = !!filters.prompt;
  const filteredByTrace = !!filters.trace;

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      href={`/workbench/requests/${request.id}`}
      className="flex flex-col gap-4 px-4 hover:bg-surface-secondary"
    >
      <div className="flex flex-between gap-2 w-full flex-wrap overflow-hidden">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={request.created_at}
            className="font-medium"
          />
          {!filteredByPrompt && (
            <div className="flex gap-0.5 font-mono">
              {request.prompt}
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Filter}
                analyticsTrackingId={null}
                type="button"
                title="Filter"
                onClick={() => setValue<"prompt">("prompt", request.prompt)}
                iconProps={{ className: "size-4" }}
                disabled={filteredByPrompt}
              />
              <Button
                theme={ButtonTheme.Naked}
                icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
                analyticsTrackingId={null}
                type="button"
                title="Copy to clipboard"
                onClick={() => copyTextToClipboard(request.prompt)}
                iconProps={{ className: "size-4" }}
              />
            </div>
          )}
          {!filteredByTrace && (
            <div className="flex gap-0.5 font-mono">
              {request.trace_id}
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Filter}
                analyticsTrackingId={null}
                type="button"
                title="Filter"
                onClick={() => setValue<"trace">("trace", request.trace_id)}
                iconProps={{ className: "size-4" }}
                disabled={filteredByTrace}
              />
              <Button
                theme={ButtonTheme.Naked}
                icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
                analyticsTrackingId={null}
                type="button"
                title="Copy to clipboard"
                onClick={() => copyTextToClipboard(request.trace_id)}
                iconProps={{ className: "size-4" }}
              />
            </div>
          )}
          {request.incident_external_id && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={"/incidents/" + request.incident_external_id}
            >
              INC-{request.incident_external_id}
            </Button>
          )}
        </div>
        <div className="grow" />
        {!!request.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {request.duration_seconds.toFixed(2)}s
          </div>
        )}
        {!!request.cost_cents && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(request.cost_cents / 100.0).toFixed(2)}
          </div>
        )}
      </div>
    </Button>
  );
};
