/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentGroundTruthCodeChange
 */
export interface IncidentGroundTruthCodeChange {
    /**
     * Explanation of why this code change is related to the incident
     * @type {string}
     * @memberof IncidentGroundTruthCodeChange
     */
    detail: string;
    /**
     * A JSON object containing metadata about the code change
     * @type {string}
     * @memberof IncidentGroundTruthCodeChange
     */
    metadata?: string;
    /**
     * URL to the code change
     * @type {string}
     * @memberof IncidentGroundTruthCodeChange
     */
    permalink: string;
}

export function IncidentGroundTruthCodeChangeFromJSON(json: any): IncidentGroundTruthCodeChange {
    return IncidentGroundTruthCodeChangeFromJSONTyped(json, false);
}

export function IncidentGroundTruthCodeChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruthCodeChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detail': json['detail'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'permalink': json['permalink'],
    };
}

export function IncidentGroundTruthCodeChangeToJSON(value?: IncidentGroundTruthCodeChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detail': value.detail,
        'metadata': value.metadata,
        'permalink': value.permalink,
    };
}

