import {
  AIStaffUpdateSearchGroundTruthRequestBody,
  SearchGroundTruth,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  MultiTextInput,
  TextEditorRow,
} from "@incident-shared/forms/v2/inputs/MultiTextInput";
import { Button, ButtonTheme, ContentBox, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { cacheKey, useMutationV2 } from "src/utils/swr";

type FormData = {
  items: TextEditorRow[];
  question: string;
  cutoff: Date;
};

/**
 * A form for editing search ground truths
 */
export const SearchGroundTruthsEditForm = ({
  groundTruth,
  onCancel,
  onSuccess,
}: {
  groundTruth: SearchGroundTruth;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const showToast = useToast();

  const formMethods = useForm<FormData>({
    defaultValues: {
      items:
        groundTruth?.answer.map((ea) => ({
          key: ea,
          value: ea,
        })) || [],
      question: groundTruth.question,
      cutoff: groundTruth.cutoff,
    },
  });

  const { trigger: updateGroundTruth, isMutating: saving } = useMutationV2(
    async (apiClient, data: AIStaffUpdateSearchGroundTruthRequestBody) => {
      await apiClient.aIStaffUpdateSearchGroundTruth({
        id: groundTruth.id,
        updateSearchGroundTruthRequestBody: data,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowSearchGroundTruth", {
          id: groundTruth.id,
        }),
      ],
      showErrorToast: "Updating ground truth",

      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Ground truth updated",
        });
        onSuccess();
      },
    },
  );

  const onSubmit = (data: FormData) => {
    return updateGroundTruth({
      answer: data.items.map((ea) => ea.value),
      question: data.question,
      cutoff: data.cutoff,
      verified_at: groundTruth?.verified_at
        ? new Date(groundTruth.verified_at)
        : undefined,
    });
  };

  return (
    <Form.Root formMethods={formMethods} onSubmit={onSubmit}>
      <ContentBox className="p-4 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <InputV2
            formMethods={formMethods}
            name="question"
            label="Question"
            required={true}
          />

          <DateTimeInputV2
            formMethods={formMethods}
            name="cutoff"
            label="Cutoff"
            required={true}
          />
        </div>
        <hr />
        <MultiTextInput
          name="items"
          disabled={saving}
          label="Answers"
          placeholder="answers"
          required={false}
        />

        <div className="flex justify-end gap-2 mt-2">
          <Button
            theme={ButtonTheme.Secondary}
            onClick={onCancel}
            analyticsTrackingId="cancel-edit-search-ground-truth"
          >
            Cancel
          </Button>
          <Button
            theme={ButtonTheme.Primary}
            type="submit"
            loading={saving}
            analyticsTrackingId="save-search-ground-truth"
          >
            Save Changes
          </Button>
        </div>
      </ContentBox>
    </Form.Root>
  );
};
