import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  SearchBar,
  Spinner,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { EmptyState } from "@incident-ui/EmptyState/EmptyState";
import { PopoverSingleSelect } from "@incident-ui/PopoverSelect";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { isOnCallUser } from "src/components/settings/users/users/utils";
import {
  EscalationUserTarget,
  EscalationUserTargetProviderEnum,
  ScopeNameEnum,
  UserPreferenceManualEscalationProviderOptionProviderEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import { useOptimisticAutoSave } from "../../../../../hooks/useOptimisticAutoSave";
import {
  escalationProviderConfig,
  formatProviderName,
} from "./escalationProviderConfig";

export const EscalateUserProvidersDrawer = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const [rawSearch, setSearch] = useState("");
  const [search] = useDebounce(rawSearch, 300);

  const { data, error, isLoading } = useAPI(
    "incidentFormsListEscalateUsers",
    {
      includeOptions: true,
      search,
    },
    {
      keepPreviousData: true,
    },
  );

  if (error) {
    return (
      <Drawer
        onClose={onClose}
        width={"medium"}
        className={"!overflow-y-hidden"}
      >
        <DrawerTitle title="Error" onClose={onClose} />
        <DrawerContents>
          <DrawerBody>
            <GenericErrorMessage error={error} />
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    );
  }

  return (
    <Drawer onClose={onClose} width={"medium"} className={"!overflow-y-hidden"}>
      {isLoading && !data ? (
        <DrawerContentsLoading />
      ) : (
        <EscalateUserProvidersDrawerContent
          users={data?.users || []}
          onClose={onClose}
          search={rawSearch}
          setSearch={setSearch}
          isLoading={isLoading}
        />
      )}
    </Drawer>
  );
};

const EscalateUserProvidersDrawerContent = ({
  users,
  onClose,
  search,
  setSearch,
  isLoading,
}: {
  users: EscalationUserTarget[];
  onClose: () => void;
  search: string;
  setSearch: (search: string) => void;
  isLoading: boolean;
}): React.ReactElement => {
  const isSearching = search.length > 0;

  return (
    <>
      <DrawerTitle
        title="Paging Providers"
        onClose={onClose}
        subtitle={
          "Configure which paging provider is used when users are manually escalated to. Note that this only applies to manual escalations where the user is explicitly chosen."
        }
      />
      <DrawerContents>
        <DrawerBody>
          <div className="flex flex-col gap-4 overflow-y-hidden">
            <SearchBar
              className="w-full"
              placeholder="Search users"
              value={search}
              onChange={setSearch}
              iconProps={{ color: ColorPaletteEnum.Slate }}
            />
            {isLoading ? (
              <div className={"w-full flex justify-center"}>
                <Spinner />
              </div>
            ) : (
              <Table
                data={users}
                className={"overflow-y-auto"}
                gridTemplateColumns="1fr 1fr"
                header={
                  <>
                    <TableHeaderCell className={"!pl-0"}>Name</TableHeaderCell>
                    <TableHeaderCell
                      className={"flex flex-row justify-end !pr-0"}
                    >
                      Provider
                    </TableHeaderCell>
                  </>
                }
                renderRow={(user: EscalationUserTarget, idx: number) => (
                  <UserProviderRow
                    key={user.id}
                    user={user}
                    isLastRow={idx === users.length - 1}
                  />
                )}
                emptyStateRow={
                  <EmptyState
                    className="border-none"
                    content={
                      isSearching ? (
                        <>No users found matching &quot;{search}&quot;</>
                      ) : (
                        <>No users available</>
                      )
                    }
                  />
                }
              />
            )}
          </div>
        </DrawerBody>
      </DrawerContents>
    </>
  );
};

const UserProviderRow = ({
  user,
  isLastRow,
}: {
  user: EscalationUserTarget;
  isLastRow: boolean;
}) => {
  const showToast = useToast();
  const { identity, hasScope } = useIdentity();
  const { hasOnCall: orgHasOnCall } = useProductAccess();

  // Check if user link is missing
  const ifMissingUserLink = (provider: string): string | undefined => {
    const option = user.available_options?.find(
      (opt) => opt.provider === provider,
    );
    return option && !option.id
      ? "This user does not have a linked " +
          formatProviderName(provider) +
          " account"
      : undefined;
  };

  const nativeUserOption = user.available_options?.find(
    (opt) =>
      opt.provider ===
      UserPreferenceManualEscalationProviderOptionProviderEnum.Native,
  );

  const options = (user.available_options || []).map((option) => {
    const config = escalationProviderConfig[option.provider];
    const isNative =
      option.provider ===
      UserPreferenceManualEscalationProviderOptionProviderEnum.Native;
    const isDisabled = isNative
      ? !orgHasOnCall || !isOnCallUser(identity?.user_state)
      : !!ifMissingUserLink(option.provider);

    const disabledReason = isNative
      ? !orgHasOnCall
        ? "Organization does not have on-call enabled"
        : !isOnCallUser(identity?.user_state)
        ? "User doesn't have on-call enabled"
        : undefined
      : ifMissingUserLink(option.provider);

    return {
      label: formatProviderName(option.provider),
      value: option.provider,
      disabled: isDisabled,
      tooltipContent: disabledReason,
      icon: config ? (
        <IconBadge
          size={IconSize.Small}
          icon={config.icon}
          color={config.color}
        />
      ) : null,
    };
  });

  const { trigger: updateUserProvider } = useMutationV2(
    async (
      apiClient,
      {
        provider,
      }: {
        provider: EscalationUserTargetProviderEnum;
      },
    ) => {
      if (!nativeUserOption) {
        throw new Error(
          "We somehow allowed a user to update a preferred provider for a non-native user, aborting this as we might inadvertently change the current user's settings",
        );
      }
      await apiClient.userPreferencesUpdateManualEscalationProviderPreferences({
        updateManualEscalationProviderPreferencesRequestBody: {
          user_id: nativeUserOption?.id,
          // These are the same enum type
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          preferred_escalation_provider: provider,
        },
      });
    },
    {
      invalidate: [cacheKey.all("incidentFormsListEscalateUsers")],
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Successfully updated user provider preference",
        });
      },
      showErrorToast: "Failed to update user provider preference",
    },
  );

  // Utilising optimistic auto save so that we get faster feedback than revalidating
  // the whole users list
  const { state, setState, saving } = useOptimisticAutoSave({
    initialState: user.provider,
    saveState: async (provider) => {
      await updateUserProvider({ provider });
    },
  });

  const availableOptions = options.filter((option) => !option.disabled);

  return (
    <TableRow isLastRow={isLastRow}>
      <TableCell>
        <div className="flex items-center gap-2">
          <Avatar name={user.name} url={user.avatar_url} />
          <span className={"line-clamp-1"}>{user.name}</span>
        </div>
      </TableCell>
      <TableCell className={"flex flex-row justify-end items-center"}>
        <PopoverSingleSelect
          isLoading={saving}
          disabled={
            availableOptions.length <= 1 ||
            !nativeUserOption ||
            !hasScope(ScopeNameEnum.UsersPreferredPagingProviderEdit)
          }
          tooltipContent={
            !hasScope(ScopeNameEnum.UsersPreferredPagingProviderEdit)
              ? "You do not have permission to edit this user's preferred paging provider"
              : availableOptions.length === 1
              ? availableOptions[0].value ===
                UserPreferenceManualEscalationProviderOptionProviderEnum.Native
                ? "This user only has an incident.io account, you can link any external accounts for them within Catalog."
                : `This ${availableOptions[0].label} account is not linked to an incident.io user, or the user doesn't have a paid on-call seat.`
              : undefined
          }
          options={options.map(
            ({ label, value, disabled, tooltipContent }) => ({
              label,
              value,
              disabled,
              description: tooltipContent,
            }),
          )}
          value={state}
          onChange={(option) => {
            setState(option as EscalationUserTargetProviderEnum);
          }}
          renderTriggerNode={({ onClick, selectedOption }) => (
            <Button
              size={BadgeSize.Medium}
              theme={ButtonTheme.Secondary}
              analyticsTrackingId={null}
              onClick={onClick}
            >
              <div className="w-full flex justify-start items-center gap-1">
                <span className={"flex-1 text-start"}>
                  {selectedOption?.label ?? "Select provider"}
                </span>
                <Icon
                  id={IconEnum.Expand}
                  className="!text-slate-600 !fill-slate-600"
                />
              </div>
            </Button>
          )}
        />
      </TableCell>
    </TableRow>
  );
};
