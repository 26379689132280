import {
  CatalogResource,
  CatalogTypeAttribute,
  Resource,
  Team,
  TeamSettings,
  TeamsUpdateRequestBody,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router";

import { useAPI, useAPIMutation } from "../../../../utils/swr";
import { useTeamsRevalidate } from "../convert/hooks";

type FormData = TeamsUpdateRequestBody;

export const TeamsEditDrawer = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/teams/${id}`);

  const { data: teamData, error: teamError } = useAPI("teamsShow", {
    id: id ?? "",
  });

  const { data: teamSettingsData, error: teamSettingsError } = useAPI(
    "teamsGetSettings",
    undefined,
  );

  const { data: resourcesData, error: resourcesError } = useAPI(
    "catalogListResources",
    undefined,
  );

  if (teamError || teamSettingsError || resourcesError) {
    return (
      <Drawer onClose={onClose} width={"small"}>
        <DrawerTitle title="Error" onClose={onClose} />
        <DrawerContents>
          <DrawerBody>
            <GenericErrorMessage error={teamSettingsError || resourcesError} />
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    );
  }

  if (!teamData || !teamSettingsData || !resourcesData) {
    return (
      <Drawer onClose={onClose} width={"small"}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  return (
    <TeamsEditDrawerLoaded
      team={teamData.team}
      teamSettings={teamSettingsData.team_settings}
      resources={resourcesData.resources}
      onClose={onClose}
    />
  );
};

const TeamsEditDrawerLoaded = ({
  team,
  teamSettings,
  resources,
  onClose,
}: {
  team: Team;
  teamSettings: TeamSettings;
  resources: CatalogResource[];
  onClose: () => void;
}) => {
  const revalidateTeams = useTeamsRevalidate();
  const showToast = useToast();

  // If the team is synced from a group already e.g. Slack User Group, Linear Team, etc.
  // pre-populate the form with the group and allow them to change which group the team is synced from
  const derivedMembersAttribute =
    teamSettings.catalog_type?.schema.attributes.find(
      (attribute) =>
        attribute.id === teamSettings.members_derived_from_attribute_id,
    );
  const teamMembersDerivedFrom =
    team.catalog_entry.attribute_values[derivedMembersAttribute?.id ?? ""];

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: team.name,
      member_attribute_value: teamMembersDerivedFrom,
    },
  });

  const { trigger, isMutating } = useAPIMutation(
    "teamsList",
    {},
    async (apiClient, body: FormData) => {
      await apiClient.teamsUpdate({ id: team.id, updateRequestBody: body });
    },
    {
      onSuccess: () => {
        // We need to explicitly refetch because the team list uses infinite scroll
        revalidateTeams();
        onClose();
        showToast({
          title: "Team updated",
          theme: ToastTheme.Success,
        });
      },
      showErrorToast: "Team update failed",
    },
  );

  return (
    <Drawer onClose={onClose} width={"small"}>
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.Team}
          color={ColorPaletteEnum.Slate}
          title="Edit team"
          onClose={onClose}
          compact
        />
        <DrawerBody className="flex grow">
          <TeamsEditForm
            formMethods={formMethods}
            trigger={trigger}
            isMutating={isMutating}
            derivedMembersAttribute={derivedMembersAttribute}
            teamMembersDerivedFrom={!!derivedMembersAttribute}
            resources={resources}
          />
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button
            type={"button"}
            onClick={onClose}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={"cancel-edit-team"}
          >
            Back
          </Button>
          <Button
            type="submit"
            form="edit-team"
            analyticsTrackingId={"edit-team"}
            theme={ButtonTheme.Primary}
            loading={isMutating}
            disabled={!formMethods.formState.isValid}
          >
            Save changes
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const TeamsEditForm = ({
  trigger,
  formMethods,
  isMutating,
  derivedMembersAttribute,
  teamMembersDerivedFrom,
  resources,
}: {
  trigger: (data: FormData) => void;
  formMethods: UseFormReturn<FormData>;
  isMutating: boolean;
  derivedMembersAttribute?: CatalogTypeAttribute;
  teamMembersDerivedFrom?: boolean;
  resources: CatalogResource[];
}) => {
  const engineResources: Resource[] = resources.map((res) => res.config);
  const resource = resources.find(
    (res) => res.type === derivedMembersAttribute?.type,
  );

  const canSetMembersFromAttribute =
    !!derivedMembersAttribute && !!resource && teamMembersDerivedFrom;

  return (
    <Form.Root
      id="edit-team"
      onSubmit={trigger}
      formMethods={formMethods}
      saving={isMutating}
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required={true}
        autoFocus={true}
        placeholder="Choose a descriptive name for this team"
      />
      {canSetMembersFromAttribute && (
        <EngineFormElement
          mode="plain_input"
          key={derivedMembersAttribute.id}
          name={"member_attribute_value"}
          label={derivedMembersAttribute.name}
          resources={engineResources}
          required={true}
          array={derivedMembersAttribute.array}
          resourceType={resource.config.type}
          className="w-full"
          showPlaceholder
        />
      )}
    </Form.Root>
  );
};
