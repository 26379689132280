/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TelemetryDashboard,
    TelemetryDashboardFromJSON,
    TelemetryDashboardFromJSONTyped,
    TelemetryDashboardToJSON,
} from './TelemetryDashboard';

/**
 * 
 * @export
 * @interface TelemetryFetchDashboardDetailsResponseBody
 */
export interface TelemetryFetchDashboardDetailsResponseBody {
    /**
     * 
     * @type {TelemetryDashboard}
     * @memberof TelemetryFetchDashboardDetailsResponseBody
     */
    telemetry_dashboard?: TelemetryDashboard;
}

export function TelemetryFetchDashboardDetailsResponseBodyFromJSON(json: any): TelemetryFetchDashboardDetailsResponseBody {
    return TelemetryFetchDashboardDetailsResponseBodyFromJSONTyped(json, false);
}

export function TelemetryFetchDashboardDetailsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryFetchDashboardDetailsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'telemetry_dashboard': !exists(json, 'telemetry_dashboard') ? undefined : TelemetryDashboardFromJSON(json['telemetry_dashboard']),
    };
}

export function TelemetryFetchDashboardDetailsResponseBodyToJSON(value?: TelemetryFetchDashboardDetailsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'telemetry_dashboard': TelemetryDashboardToJSON(value.telemetry_dashboard),
    };
}

