import {
  AIStaffListAISpansTypeEnum,
  EvaluationScorecard,
} from "@incident-io/api";
import { Loader } from "@incident-ui";
import { useAPI } from "src/utils/swr";

import { AISpanTrace } from "../ai-requests/AISpanTrace";

export const EvaluationScorecardTrace = ({
  scorecard,
}: {
  scorecard: EvaluationScorecard;
}) => {
  const { data: scorecardSpanData, isLoading: scorecardSpanDataLoading } =
    useAPI(
      "aIStaffListAISpans",
      {
        resourceId: scorecard.id,
        type: AIStaffListAISpansTypeEnum.Scorecard,
        childSpanDepth: 50,
      },
      {
        fallbackData: { spans: [] },
      },
    );

  if (scorecardSpanDataLoading) {
    return <Loader />;
  }

  if (scorecardSpanData?.spans) {
    return <AISpanTrace spans={scorecardSpanData?.spans} />;
  }
  return null;
};
