/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Definition of a dimension that can be measured against events
 * @export
 * @interface EvaluationScorecardDimension
 */
export interface EvaluationScorecardDimension {
    /**
     * Description of what this dimension measures
     * @type {string}
     * @memberof EvaluationScorecardDimension
     */
    description: string;
    /**
     * If type is string, the allowed values for this dimension
     * @type {Array<string>}
     * @memberof EvaluationScorecardDimension
     */
    _enum?: Array<string>;
    /**
     * Unique identifier for this dimension
     * @type {string}
     * @memberof EvaluationScorecardDimension
     */
    id: string;
    /**
     * Human readable name of the dimension
     * @type {string}
     * @memberof EvaluationScorecardDimension
     */
    name: string;
    /**
     * Type of the dimension value
     * @type {string}
     * @memberof EvaluationScorecardDimension
     */
    type: EvaluationScorecardDimensionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationScorecardDimensionTypeEnum {
    Int = 'int',
    Number = 'number',
    String = 'string',
    Duration = 'duration',
    Time = 'time'
}

export function EvaluationScorecardDimensionFromJSON(json: any): EvaluationScorecardDimension {
    return EvaluationScorecardDimensionFromJSONTyped(json, false);
}

export function EvaluationScorecardDimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecardDimension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        '_enum': !exists(json, 'enum') ? undefined : json['enum'],
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
    };
}

export function EvaluationScorecardDimensionToJSON(value?: EvaluationScorecardDimension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'enum': value._enum,
        'id': value.id,
        'name': value.name,
        'type': value.type,
    };
}

