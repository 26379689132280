/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentSlugsForIntercomWorkspaceResponseBody
 */
export interface StatusPageContentSlugsForIntercomWorkspaceResponseBody {
    /**
     * Status page slug
     * @type {string}
     * @memberof StatusPageContentSlugsForIntercomWorkspaceResponseBody
     */
    slug?: string;
}

export function StatusPageContentSlugsForIntercomWorkspaceResponseBodyFromJSON(json: any): StatusPageContentSlugsForIntercomWorkspaceResponseBody {
    return StatusPageContentSlugsForIntercomWorkspaceResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentSlugsForIntercomWorkspaceResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentSlugsForIntercomWorkspaceResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
    };
}

export function StatusPageContentSlugsForIntercomWorkspaceResponseBodyToJSON(value?: StatusPageContentSlugsForIntercomWorkspaceResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
    };
}

