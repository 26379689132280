import { AlertRouteSlim, AlertSourceConfig } from "@incident-io/api";
import _ from "lodash";
import { Node } from "reactflow";

import { AlertsConfigurationNodeType } from "../AlertsConfigurationNode";
import { groupSourcesAndRoutes } from "./groupSourcesAndRoutes";

export const NODE_HEIGHT = 72;
export const PADDING = 24;
export const SPACING = 12;
export const GROUP_SPACING = 24;

export const drawNodes = ({
  alertSources,
  alertRoutes,
  containerWidth,
  hasNoAlertRoutes,
  hasComplexSetup,
}: {
  alertSources: AlertSourceConfig[];
  alertRoutes: AlertRouteSlim[];
  containerWidth: number;
  hasNoAlertRoutes: boolean;
  hasComplexSetup: boolean;
}) => {
  const NODE_WIDTH = containerWidth / 3;

  // Keyed by ID, so we can minimise updates
  const nodes: Node<null, AlertsConfigurationNodeType>[] = [];

  const { groupedAlertSources, groupedAlertRoutes } = groupSourcesAndRoutes(
    alertSources,
    alertRoutes,
  );

  // Align the sources on the left hand side of the screen
  let sourceY = PADDING;

  for (const group of groupedAlertSources) {
    for (const source of group) {
      nodes.push({
        id: source.id,
        type: AlertsConfigurationNodeType.AlertSource,
        data: null,
        position: { x: PADDING, y: sourceY },
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });
      let additionalHeight = NODE_HEIGHT + SPACING;

      // If we have over one reference, then we should offset by our number of references
      // on the other side.
      const filteredReferenceCount = _.intersection(
        source.alert_route_ids,
        alertRoutes.map((route) => route.id),
      ).length;
      if (!hasComplexSetup && filteredReferenceCount > 1) {
        additionalHeight +=
          NODE_HEIGHT * (filteredReferenceCount - 1) +
          SPACING * (filteredReferenceCount - 1);
      }

      sourceY += additionalHeight;
    }

    sourceY += GROUP_SPACING;
  }

  // Align the routes on the right hand side of the screen
  let routeY = PADDING;

  for (const group of groupedAlertRoutes) {
    for (const route of group) {
      nodes.push({
        id: route.id,
        type: AlertsConfigurationNodeType.AlertRoute,
        data: null,
        position: { x: NODE_WIDTH * 2 - PADDING, y: routeY },
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });
      let additionalHeight = NODE_HEIGHT + SPACING;

      // If we have over one reference, then we should offset by our number of references
      // on the other side.
      const filteredReferenceCount = _.intersection(
        route.alert_sources.map((source) => source.alert_source_id),
        alertSources.map((source) => source.id),
      ).length;
      if (!hasComplexSetup && filteredReferenceCount > 1) {
        additionalHeight +=
          NODE_HEIGHT * (filteredReferenceCount - 1) +
          SPACING * (filteredReferenceCount - 1);
      }

      routeY += additionalHeight;
    }

    routeY += GROUP_SPACING;
  }

  if (hasNoAlertRoutes) {
    // Add an empty state node
    nodes.push({
      id: `no-alert-routes`,
      type: AlertsConfigurationNodeType.NoAlertRoutes,
      data: null,
      position: { x: NODE_WIDTH * 2 - PADDING, y: routeY },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      },
    });
  }

  return {
    nodes: Object.values(nodes),
    height: Math.max(sourceY, routeY, window.innerHeight),
  };
};
