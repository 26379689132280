import { StaffCallRoute } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  Loader,
  Txt,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { parsePhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { StaffRoomOperationProps } from "../Operation";

const PhoneNumber = ({ phoneNumber }: { phoneNumber?: string }) => {
  const parsedNumber = phoneNumber ? parsePhoneNumber(phoneNumber) : null;
  if (!parsedNumber) {
    return <span>{phoneNumber}</span>;
  }

  return <>{parsedNumber.formatInternational()}</>;
};

const CallRoutesList = ({
  routes,
  onConfigureRoute,
}: {
  routes: StaffCallRoute[];
  onConfigureRoute: (id: string) => void;
}) => {
  const pendingRoutes = routes.filter((r) => r.current_state === "pending");
  const activeRoutes = routes.filter((r) => r.current_state === "active");

  return (
    <div className="space-y-6">
      {pendingRoutes.length > 0 && (
        <div>
          <span className="text-content-primary font-semibold mb-2">
            Pending Routes
          </span>
          <Table<StaffCallRoute>
            gridTemplateColumns="1fr 200px auto"
            header={
              <>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell />
              </>
            }
            data={pendingRoutes}
            renderRow={(route, index) => (
              <TableRow
                key={route.id}
                isLastRow={index === pendingRoutes.length - 1}
              >
                <TableCell className="text-content-primary font-medium">
                  {route.name}
                </TableCell>
                <TableCell>
                  <Badge theme={BadgeTheme.Tertiary} className="-my-0.5">
                    Pending
                  </Badge>
                </TableCell>
                <TableCell className="justify-end">
                  <GatedButton
                    disabled={!route.requested_country_code}
                    disabledTooltipContent="A non-standard country code was requested for this route. Please configure it manually."
                    theme={ButtonTheme.Secondary}
                    onClick={() => onConfigureRoute(route.id)}
                    analyticsTrackingId="configure-call-route"
                  >
                    Configure
                  </GatedButton>
                </TableCell>
              </TableRow>
            )}
          />
        </div>
      )}

      {activeRoutes.length > 0 && (
        <div>
          <span className="text-content-primary font-semibold mb-2">
            Active Routes
          </span>
          <Table<StaffCallRoute>
            gridTemplateColumns="1fr 200px auto"
            header={
              <>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Number</TableHeaderCell>
                <TableHeaderCell />
              </>
            }
            data={activeRoutes}
            renderRow={(route, index) => (
              <TableRow
                key={route.id}
                isLastRow={index === activeRoutes.length - 1}
              >
                <TableCell className="text-content-primary font-medium">
                  {route.name}
                </TableCell>
                <TableCell>
                  <PhoneNumber phoneNumber={route.phone_number} />
                </TableCell>
                <TableCell className="justify-end">
                  <Button
                    icon={IconEnum.ExternalLink}
                    iconProps={{ size: IconSize.Small }}
                    theme={ButtonTheme.Tertiary}
                    href={`https://console.twilio.com/us1/develop/phone-numbers/manage/incoming/${route.twilio_phone_number_id}/configure`}
                    openInNewTab
                    analyticsTrackingId="view-twilio-number"
                  >
                    View in Twilio
                  </Button>
                </TableCell>
              </TableRow>
            )}
          />
        </div>
      )}

      {routes.length === 0 && (
        <Txt className="text-content-secondary">No call routes found.</Txt>
      )}
    </div>
  );
};

const ConfigureCallRoute = ({
  organisationSlug,
  route,
  onBack,
}: {
  organisationSlug: string;
  route: {
    id: string;
    name: string;
  };
  onBack: () => void;
}) => {
  const formMethods = useForm<{ locality: string }>({
    defaultValues: {
      locality: "",
    },
  });

  const { data: availableNumbers, isLoading: loadingNumbers } = useAPI(
    "staffListAvailablePhoneNumbers",
    {
      organisationSlug,
      callRouteId: route.id,
      localitySearch: formMethods.watch("locality"),
    },
    {
      fallbackData: { available_phone_numbers: [] },
    },
  );

  const { trigger: configureNumber, isMutating: isConfiguring } = useMutationV2(
    async (
      apiClient,
      data: {
        organisationSlug: string;
        callRouteId: string;
        phone_number: string;
      },
    ) => {
      await apiClient.staffConfigureCallRoute({
        organisationSlug: data.organisationSlug,
        callRouteId: data.callRouteId,
        configureCallRouteRequestBody: {
          phone_number: data.phone_number,
        },
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffListCallRoutes", { organisationSlug }),
      ],

      onSuccess: () => {
        onBack();
      },
    },
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <Txt className="font-semibold mb-1">{route.name}</Txt>
          <Txt className="text-content-secondary text-sm">
            Select a phone number to configure this route
          </Txt>
        </div>
        <Button
          theme={ButtonTheme.Secondary}
          onClick={onBack}
          analyticsTrackingId="back-to-routes"
        >
          Back to routes
        </Button>
      </div>

      <Form.Root
        formMethods={formMethods}
        onSubmit={() => void 0}
        submitOnCmdEnter={false}
      >
        <InputV2
          formMethods={formMethods}
          name="locality"
          label="Search by locality"
          placeholder="e.g. London, New York"
          helptext="Enter a city or region to find available numbers in that area"
        />
      </Form.Root>

      {loadingNumbers ? (
        <Loader />
      ) : (
        <div>
          <Table
            gridTemplateColumns="1fr 300px auto"
            header={
              <>
                <TableHeaderCell>Number</TableHeaderCell>
                <TableHeaderCell>Locality</TableHeaderCell>
                <TableHeaderCell />
              </>
            }
            data={availableNumbers?.available_phone_numbers || []}
            renderRow={(number, index) => (
              <TableRow
                key={number.phone_number}
                isLastRow={
                  index ===
                  (availableNumbers?.available_phone_numbers?.length || 0) - 1
                }
              >
                <TableCell className="text-content-primary font-medium">
                  <PhoneNumber phoneNumber={number.phone_number} />
                </TableCell>
                <TableCell>{number.locality ?? "N/A"}</TableCell>
                <TableCell className="justify-end">
                  <Button
                    theme={ButtonTheme.Secondary}
                    onClick={() =>
                      configureNumber({
                        organisationSlug,
                        callRouteId: route.id,
                        phone_number: number.phone_number,
                      })
                    }
                    loading={isConfiguring}
                    disabled={isConfiguring}
                    analyticsTrackingId="configure-number"
                  >
                    Purchase
                  </Button>
                </TableCell>
              </TableRow>
            )}
          />

          {(availableNumbers?.available_phone_numbers || []).length === 0 && (
            <Txt className="text-content-secondary">
              No phone numbers found. Try searching for a different locality.
            </Txt>
          )}
        </div>
      )}
    </div>
  );
};

export const ConfigureCallRoutesForm = (props: StaffRoomOperationProps) => {
  const { data, isLoading } = useAPI(
    "staffListCallRoutes",
    {
      organisationSlug: props.organisation.organisation_slug,
    },
    {
      fallbackData: { call_routes: [] },
    },
  );
  const [callRouteToConfigure, setCallRouteToConfigure] = useState<
    string | null
  >(null);

  if (isLoading) {
    return <Loader />;
  }

  const routes = data?.call_routes || [];

  // If we don't have a call route to configure yet, show step 1, which is selecting a route
  if (!callRouteToConfigure) {
    return (
      <CallRoutesList
        routes={routes}
        onConfigureRoute={setCallRouteToConfigure}
      />
    );
  }

  const selectedRoute = routes.find((r) => r.id === callRouteToConfigure);
  if (!selectedRoute) {
    return null;
  }

  return (
    <ConfigureCallRoute
      organisationSlug={props.organisation.organisation_slug}
      route={selectedRoute}
      onBack={() => setCallRouteToConfigure(null)}
    />
  );
};
