import {
  CatalogEntry,
  CatalogTypeAttribute,
  CatalogTypeAttributeModeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { isEmptyBinding } from "@incident-shared/engine";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GatedDropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { TableHeaderCell } from "@incident-ui/Table/Table";
import { forwardRef, MutableRefObject, useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";

import { useCanAddCustomCatalogType } from "../common/useCanAddCustomCatalogType";
import { useCanAutoFillAttribute } from "./CatalogEntriesBulkEditDrawer";
import { LastSyncedAt } from "./CatalogEntryListPage";

export enum DropDownStateEnum {
  Inactive = "inactive",
  Open = "open",
  Working = "working",
  Success = "success",
  Error = "error",
}

const DROPDOWN_FADE_OUT_MS = 1500;

export const CatalogEntryListColumnHeader = forwardRef<
  HTMLDivElement,
  {
    attribute: CatalogTypeAttribute;
    onBulkEditAttr: (shouldAutoFill: boolean) => void;
    onPromoteAttr: () => void;
    setDropdown: MutableRefObject<(state: DropDownStateEnum) => void>;
    lastSyncedAt?: Date;
    hasOverMaxEntriesForBulkEdit: boolean;
    entries: CatalogEntry[];
    sourceRepoUrl?: string;
  }
>(
  (
    {
      attribute,
      onBulkEditAttr,
      onPromoteAttr,
      setDropdown,
      lastSyncedAt,
      hasOverMaxEntriesForBulkEdit,
      entries,
    },
    ref,
  ) => {
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>();
    const [activeTooltip, setActiveTooltip] = useState<string>();
    const [dropdownState, setDropdownState] = useState<DropDownStateEnum>(
      DropDownStateEnum.Inactive,
    );

    const {
      upgradeRequired,
      loading: canAddLoading,
      error: canAddError,
    } = useCanAddCustomCatalogType();
    const { hasScope } = useIdentity();
    const canAutoFill = useCanAutoFillAttribute();

    let dropdownIcon;

    switch (dropdownState) {
      case DropDownStateEnum.Success:
        dropdownIcon = IconEnum.Tick;
        break;
      case DropDownStateEnum.Open:
        dropdownIcon = IconEnum.ChevronDown;
        break;
      case DropDownStateEnum.Working:
        dropdownIcon = IconEnum.Loader;
        break;
      case DropDownStateEnum.Error:
        dropdownIcon = IconEnum.Exclamation;
        break;
      default:
        dropdownIcon = IconEnum.DotsVerticalNopad;
    }

    const isPromotable =
      !attribute.array &&
      (attribute.type === "String" ||
        attribute.type === "Text" ||
        attribute.type === "Number") &&
      attribute.mode === CatalogTypeAttributeModeEnum.Dashboard;

    const isBulkEditable =
      attribute.mode === CatalogTypeAttributeModeEnum.Dashboard &&
      !hasOverMaxEntriesForBulkEdit;

    const isSyncedAttribute = [
      CatalogTypeAttributeModeEnum.External,
      CatalogTypeAttributeModeEnum.Dynamic,
    ].includes(attribute.mode);

    const isBacklink = attribute.mode === CatalogTypeAttributeModeEnum.Backlink;

    const countEmptyEntries = entries.filter((entry) =>
      isEmptyBinding(entry.attribute_values[attribute.id]),
    ).length;

    const percentEntriesEmpty =
      entries.length === 0 ? 0 : countEmptyEntries / entries.length;

    const shouldPromoteAutoFill =
      isBulkEditable &&
      canAutoFill(attribute) &&
      percentEntriesEmpty > 0.3 &&
      hasScope(ScopeNameEnum.CatalogEntriesEdit);

    if (canAddError) {
      return <GenericErrorMessage error={canAddError} />;
    }

    return (
      <TableHeaderCell
        className="sticky top-0 bg-white"
        ref={ref}
        title={
          <div className="flex-center-y group w-full gap-1">
            <span>{attribute.name}</span>
            {isBacklink && <Icon id={IconEnum.BacklinkSmall} />}
            {isSyncedAttribute && (
              <Tooltip content={<LastSyncedAt lastSyncedAt={lastSyncedAt} />}>
                <span>
                  <Icon id={IconEnum.Cloud} />
                </span>
              </Tooltip>
            )}
            {attribute.was_previously_synced && (
              <Tooltip content={<>Sync broken</>}>
                <span>
                  <Icon id={IconEnum.CloudBroken} />
                </span>
              </Tooltip>
            )}
            {shouldPromoteAutoFill && (
              <Tooltip content="Suggest missing values">
                <Button
                  title=""
                  analyticsTrackingId="catalog-auto-fill"
                  size={BadgeSize.Medium}
                  theme={ButtonTheme.Naked}
                  icon={IconEnum.MagicWand}
                  onClick={() => onBulkEditAttr(true)}
                />
              </Tooltip>
            )}
            <Tooltip
              openStateOverride={activeTooltip === attribute.id}
              light
              content={
                <div className="flex-center-y">
                  <span className="text-xs">
                    {dropdownState === DropDownStateEnum.Success
                      ? "Done!"
                      : "Column actions"}
                  </span>
                </div>
              }
            >
              <DropdownMenu
                onOpenChange={(isOpen) => {
                  if (isOpen) {
                    setDropdownState(DropDownStateEnum.Open);
                  } else {
                    if (dropdownState === DropDownStateEnum.Open) {
                      setDropdownState(DropDownStateEnum.Inactive);
                    }
                  }
                }}
                triggerButton={
                  <Button
                    title="Column actions"
                    onMouseEnter={() => {
                      if (dropdownState === DropDownStateEnum.Open) {
                        return;
                      }
                      setHoverTimeout(
                        setTimeout(() => setActiveTooltip(attribute.id), 300),
                      );
                    }}
                    loading={canAddLoading}
                    onMouseLeave={() => {
                      setActiveTooltip(undefined);
                      if (hoverTimeout) {
                        clearTimeout(hoverTimeout);
                        setHoverTimeout(undefined);
                      }
                      if (dropdownState === DropDownStateEnum.Open) {
                        return;
                      }
                      setDropdownState(DropDownStateEnum.Inactive);
                    }}
                    theme={ButtonTheme.Naked}
                    analyticsTrackingId={`catalog-drop-down-${attribute.id}`}
                    className={
                      dropdownState === DropDownStateEnum.Inactive
                        ? "!no-underline -my-1 invisible group-hover:visible"
                        : "!no-underline -my-1 outline-none"
                    }
                    iconProps={{
                      size: IconSize.Small,
                      className:
                        "text-content-tertiary hover:text-content-primary",
                    }}
                    icon={dropdownIcon}
                  />
                }
                menuClassName={`catalog-entry-dropdown-${attribute.id}`}
                align="start"
              >
                <DropdownMenuItem
                  onSelect={() => {
                    setActiveTooltip(attribute.id);
                    setDropdownState(DropDownStateEnum.Working);
                    navigator.clipboard.writeText(attribute.id).then(() => {
                      setDropdownState(DropDownStateEnum.Success);
                      setTimeout(() => {
                        setActiveTooltip(undefined);
                        setDropdownState(DropDownStateEnum.Inactive);
                      }, DROPDOWN_FADE_OUT_MS);
                    });
                  }}
                  analyticsTrackingId={`catalog-entry-dropdown-${attribute.id}-copy-id`}
                  label={`catalog-entry-dropdown-${attribute.id}-copy-id`}
                  key={`catalog-entry-dropdown-${attribute.id}-copy-id`}
                  icon={IconEnum.Copy}
                >
                  Copy Attribute ID
                </DropdownMenuItem>
                {isBulkEditable && (
                  <GatedDropdownMenuItem
                    onSelect={() => onBulkEditAttr(false)}
                    analyticsTrackingId={`catalog-entry-dropdown-${attribute.id}-bulk-edit`}
                    label={`catalog-entry-dropdown-${attribute.id}-bulk-edit`}
                    key={`catalog-entry-dropdown-${attribute.id}-bulk-edit`}
                    icon={IconEnum.Edit}
                    requiredScope={ScopeNameEnum.CatalogEntriesEdit}
                  >
                    Edit values
                  </GatedDropdownMenuItem>
                )}
                {isPromotable && !upgradeRequired && (
                  <GatedDropdownMenuItem
                    onSelect={() => {
                      setActiveTooltip(attribute.id);
                      setDropdownState(DropDownStateEnum.Working);
                      setDropdown.current = setDropdownState;
                      onPromoteAttr();
                    }}
                    analyticsTrackingId={`catalog-entry-dropdown-${attribute.id}-promote`}
                    label={`catalog-entry-dropdown-${attribute.id}-promote`}
                    key={`catalog-entry-dropdown-${attribute.id}-promote`}
                    icon={IconEnum.ArrowCircleUp}
                    requiredScope={ScopeNameEnum.CatalogTypesCreate}
                  >
                    Promote to Type
                  </GatedDropdownMenuItem>
                )}
              </DropdownMenu>
            </Tooltip>
          </div>
        }
        key={`${attribute.id}-header`}
      />
    );
  },
);

CatalogEntryListColumnHeader.displayName = "CatalogEntryListColumnHeader";
