import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Callout.module.scss";

export type CalloutProps = {
  theme: CalloutTheme;
  showIcon?: boolean;
  iconOverride?: IconEnum;
  className?: string;
  iconClassName?: string;
  cta?: React.ReactNode;
  secondaryCta?: React.ReactNode;
  ctaPosition?: "right" | "bottom";
} & (
  | {
      children: React.ReactNode;
      title?: never;
      subtitle?: never;
    }
  | {
      title: React.ReactNode;
      subtitle?: React.ReactNode;
      children?: never;
    }
);

export function Callout({
  children,
  title,
  subtitle,
  theme: themeKey,
  showIcon = true,
  iconOverride,
  className,
  iconClassName,
  cta,
  secondaryCta,
  ctaPosition = "right",
}: CalloutProps): React.ReactElement {
  const theme = ThemeFor[themeKey];
  const icon = iconOverride ? iconOverride : theme.icon;

  const CTAs = () => (
    <div className="flex gap-4 items-center">
      {cta}
      {secondaryCta}
    </div>
  );

  return (
    <div
      className={tcx(
        "flex items-center",
        "w-full text-sm rounded-2",
        "p-4 gap-3",
        theme.className,
        className,
      )}
    >
      {showIcon && (
        <Icon
          size={IconSize.Medium}
          id={icon}
          className={tcx(
            { "self-start": !!subtitle },
            theme.iconClassName,
            iconClassName,
          )}
        />
      )}
      {children ? (
        <div className="inline grow">{children}</div>
      ) : (
        <div className="flex flex-col gap-4 items-start">
          <div className="flex flex-col gap-0.5 text-sm">
            <div className="font-medium">{title}</div>
            {subtitle && <div>{subtitle}</div>}
          </div>
          {ctaPosition === "bottom" && <CTAs />}
        </div>
      )}
      {ctaPosition === "right" && (
        <>
          <div className="grow" />
          <CTAs />
        </>
      )}
    </div>
  );
}

export enum CalloutTheme {
  Success = "success",
  Danger = "danger",
  Plain = "plain",
  Info = "info",
  Warning = "warning",
  AIRainbow = "ai-rainbow",
  Scribe = "scribe",
  Unstyled = "unstyled",
}

type ThemeConfig = {
  className: string;
  iconClassName: string;
  icon: IconEnum;
};

const ThemeFor: { [key in CalloutTheme]: ThemeConfig } = {
  [CalloutTheme.Plain]: {
    className: "bg-surface-secondary text-content-primary",
    icon: IconEnum.Info,
    iconClassName: "text-content-tertiary",
  },
  [CalloutTheme.Info]: {
    className: "bg-blue-surface text-blue-800",
    icon: IconEnum.Info,
    iconClassName: "text-blue-500",
  },
  [CalloutTheme.Warning]: {
    className: "bg-amber-surface text-amber-800",
    icon: IconEnum.Warning,
    iconClassName: "text-alarmalade-600",
  },
  [CalloutTheme.Danger]: {
    className: "bg-red-surface text-red-800",
    icon: IconEnum.Warning,
    iconClassName: "text-red-500",
  },
  [CalloutTheme.Success]: {
    className: "bg-green-surface text-green-800",
    icon: IconEnum.Success,
    iconClassName: "text-green-500",
  },
  [CalloutTheme.AIRainbow]: {
    className: styles.AIrainbow,
    icon: IconEnum.SparklesColoured,
    iconClassName: "",
  },
  [CalloutTheme.Scribe]: {
    className: scribeStyles.background,
    icon: IconEnum.Scribe,
    iconClassName: "",
  },
  [CalloutTheme.Unstyled]: {
    className: "",
    icon: IconEnum.Info,
    iconClassName: "",
  },
};
