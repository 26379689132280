"use client";

import { StatusPageContentStatusSummaryWorstComponentStatusEnum as ComponentStatusEnum } from "@incident-io/api";
import cx from "classnames";
import { DateTime, Interval } from "luxon";
import React from "react";

import { assertUnreachable } from "../../helpers";
import { hasSameDay } from "../../time-helpers";
import { useTranslations } from "../../use-translations";
import { ContentBox } from "../ContentBox";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";
import { Spinner } from "../Spinner/Spinner";
import { useNow, useParseTime } from "../TimeContext";
import { Tooltip } from "../Tooltip";
import { DayOfIncidentsTooltipContents } from "../Tooltip/DayOfIncidentsTooltipContents";
import styles from "./Calendar.module.scss";

enum DayType {
  Normal = "normal",
  NotInMonth = "not_in_month",
  NotInRange = "not_in_range",
}

type Day = {
  type: DayType;
  date: DateTime;
  events: Event[];
};

export type Event = {
  date: DateTime;
  name: string;
  incident_id: string;
  status: ComponentStatusEnum;
};

export const Calendar = ({
  events: allEvents,
  calendarDate,
  dataAvailableSince,
  loading,
  setCalendarDate,
  worstImpactForDay,
}: {
  events: Event[];
  calendarDate: DateTime;
  dataAvailableSince: DateTime;
  loading: boolean;
  setCalendarDate: (newDate: DateTime) => void;
  worstImpactForDay: (events: Event[]) => ComponentStatusEnum | undefined;
}): React.ReactElement => {
  const t = useTranslations("IncidentCalendar");
  const startOfToday = useNow(10 * 60 * 1000, "day");

  const { dateFromObject } = useParseTime();

  const startDate = dateFromObject({
    year: calendarDate.year,
    month: calendarDate.month,
    day: 1,
  });
  const endDate = startDate.plus({ month: 1 });

  const isCurrentMonth = startOfToday >= startDate && startOfToday < endDate;

  const days: Day[] = [];
  let monthHasEvents = false;

  // Filter down to just events within this month to reduce the amount of work
  // we have to do
  const interval = Interval.fromDateTimes(startDate, endDate);
  const events = allEvents.filter(({ date }) => interval.contains(date));

  for (
    let firstDayOfMonth = startDate;
    firstDayOfMonth < endDate;
    firstDayOfMonth = firstDayOfMonth.plus({ month: 1 })
  ) {
    // start at the beginning of the WEEK at the start of the month.
    const startOfThisWeek = firstDayOfMonth.startOf("week");
    const endOfThisMonth = firstDayOfMonth.endOf("month");
    const endOfTheWeekAtTheEndOfThisMonth = endOfThisMonth.endOf("week");

    for (
      let day = startOfThisWeek;
      !(day > endOfTheWeekAtTheEndOfThisMonth);
      day = day.plus({ day: 1 })
    ) {
      let type: DayType = DayType.NotInRange;
      if (day.month !== firstDayOfMonth.month) {
        type = DayType.NotInMonth;
      } else if (!(day < startDate) && !(day > endDate)) {
        type = DayType.Normal;
      }

      const dayEvents = events.filter((e) => hasSameDay(day, e.date));
      if (dayEvents.length > 0) {
        monthHasEvents = true;
      }

      // We need to compare if the two days match, we aren't interested in
      // timestamps
      days.push({
        type,
        events: dayEvents,
        date: day,
      });
    }
  }

  return (
    <ContentBox
      title={
        <MonthPicker
          now={startOfToday}
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          dataAvailableSince={dataAvailableSince}
        />
      }
      padded={false}
    >
      <div className={"grid grid-cols-1 gap-y-16 text-slate-600 text-center"}>
        <section className={cx("text-center", "relative w-full")}>
          {loading ? (
            <div
              className={cx(
                "bg-cal-empty dark:bg-cal-empty-dark",
                "flex",
                "items-center",
                "absolute",
                "w-full",
                "h-full",
                "rounded-b-[7px]",
              )}
            >
              <div
                className={cx(
                  "z-10 mx-auto p-2 rounded-[8px] border text-sm",
                  "border-stroke bg-white text-content-tertiary",
                  "dark:border-slate-700 dark:bg-slate-950 dark:text-content-tertiary",
                )}
              >
                <Spinner />
              </div>
            </div>
          ) : (
            !monthHasEvents && !isCurrentMonth && (
              <div
                className={cx(
                  "bg-cal-empty dark:bg-cal-empty-dark",
                  "flex",
                  "items-center",
                  "absolute",
                  "w-full",
                  "h-full",
                  "rounded-b-[7px]",
                )}
              >
                <div
                  className={cx(
                    "z-10 mx-auto px-2 py-1.5 rounded-[8px] border text-sm flex items-center",
                    "border-stroke bg-white text-content-tertiary shadow-sm",
                    "dark:border-slate-700 dark:bg-slate-900 dark:text-content-tertiary dark:shadow-none",
                  )}
                >
                  <ComponentStatusIcon flavour="ok" /> {t("no_incidents")}
                </div>
              </div>
            )
          )}

          {/* This is the first row contianing localised chars for Mon-Sun */}
          <div className="grid grid-cols-7 py-3 text-xs font-medium text-slate-400 dark:text-slate-500 bg-white dark:bg-global border border-x-transparent border-t-transparent border-b-slate-50 dark:border-b-slate-800">
            <div
              dangerouslySetInnerHTML={{
                // We need to use dangerouslySetInnerHTML here to preserve the tags
                // on the `var` attribute, as React will otherwise strip it out
                __html: `${t("weekdays.monday")}<var monday></var>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.tuesday")}<var tuesday>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.wednesday")}<var wednesday>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.thursday")}<var thursday>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.friday")}<var friday>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.saturday")}<var saturday>`,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t("weekdays.sunday")}<var sunday>`,
              }}
            ></div>
          </div>
          <div className={styles.container}>
            {days.map((day, idx) => {
              const currentDate = hasSameDay(day.date, startOfToday);

              return (
                <div
                  key={idx}
                  className={cx(
                    "bg-white dark:bg-global",
                    // Clip off the bottom-right corner of the last box
                    idx === days.length - 1 && "rounded-br-[7px]",
                    // ...and the bottom-left corner of the 7th-to-last box
                    idx === days.length - 7 && "rounded-bl-[7px]",
                  )}
                >
                  <div
                    className={cx(
                      "mb-[-1px] transition py-1 flex items-center justify-center",
                      // Mark today's date with a shiny background that
                      // blends with the parent bg-global.
                      day.type !== DayType.NotInMonth &&
                        currentDate &&
                        styles.today,
                      // We need to recheck if this day is sat in one of the corners
                      // And round appropriately (as above)
                      idx === days.length - 7 && "rounded-bl-[7px]",
                      idx === days.length - 1 && "rounded-br-[7px]",
                    )}
                  >
                    {day.type !== DayType.NotInMonth ? (
                      <Tooltip
                        className={cx(
                          "transition flex items-center justify-center w-8 h-8",
                        )}
                        content={
                          <DayOfIncidentsTooltipContents
                            date={day.date}
                            hasData
                            incidents={day.events}
                          />
                        }
                      >
                        <button
                          type="button"
                          disabled={day.type === DayType.NotInRange}
                          className={cx(
                            "transition group cursor-default",
                            "block w-6 h-6 rounded-full",
                            "bg-transparent",
                            classNameFromDayType(
                              day.type,
                              worstImpactForDay(day.events),
                            ),
                            // There is a slight difference between an operational day with no
                            // incidents vs an operational day with incidents.
                            // The operational scss styling allows for a grey border to be
                            // shown, but we only want to show it if there is an incident on this
                            // day. Otherwise we'll leave the day unstyled.
                            day.events.length > 0 && "border-[1.5px]",
                          )}
                        >
                          <time dateTime={day.date.toISO().split("T")[0]}>
                            {day.date.day}
                          </time>
                        </button>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </ContentBox>
  );
};

const classNameFromDayType = (
  dayType: DayType,
  worstStatus?: ComponentStatusEnum,
) => {
  if (dayType === DayType.NotInMonth) {
    return "cursor-default invisible";
  }
  if (dayType === DayType.NotInRange) {
    return "text-slate-300 dark:text-slate-500 cursor-default";
  }

  switch (worstStatus) {
    case undefined:
    case ComponentStatusEnum.Operational:
      return styles.operational;

    case ComponentStatusEnum.UnderMaintenance:
      return styles.underMaintenance;

    case ComponentStatusEnum.DegradedPerformance:
      return styles.degradedPerformance;

    case ComponentStatusEnum.PartialOutage:
      return styles.partialOutage;

    case ComponentStatusEnum.FullOutage:
      return styles.fullOutage;

    default:
      return assertUnreachable(worstStatus);
  }
};

const MonthPicker = ({
  calendarDate,
  setCalendarDate,
  dataAvailableSince,
  now,
}: {
  calendarDate: DateTime;
  setCalendarDate: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  now: DateTime;
}): React.ReactElement => {
  const t = useTranslations("IncidentCalendar");

  const chevronClasses = (enabled: boolean) =>
    cx(
      "w-4 h-4 0 font-semibold",
      enabled ? "cursor-pointer transition" : "cursor-not-allowed",
      enabled ? "text-slate-400 hover:text-slate-500" : "!text-slate-100",
      enabled
        ? "dark:text-slate-500 dark:hover:text-slate-300"
        : "dark:!text-slate-700",
    );

  const canGoBack = !calendarDate.hasSame(dataAvailableSince, "month");
  const canGoForward = !calendarDate.hasSame(now, "month");

  return (
    <div className="flex items-center space-x-4">
      <h2 className="text-content-primary dark:text-slate-50">{t("title")}</h2>
      <div className="flex items-center space-x-1 text-slate-400 dark:text-slate-500 text-sm font-normal select-none mt-[1px] whitespace-nowrap">
        <div
          onClick={
            canGoBack
              ? () => setCalendarDate(calendarDate.minus({ month: 1 }))
              : undefined
          }
        >
          <ChevronIcon flavour="left" className={chevronClasses(canGoBack)} />
        </div>
        <span>
          {calendarDate.toLocaleString({
            month: "short",
            year: "numeric",
          })}
        </span>

        <div
          onClick={
            canGoForward
              ? () => setCalendarDate(calendarDate.plus({ month: 1 }))
              : undefined
          }
        >
          <ChevronIcon
            className={chevronClasses(canGoForward)}
            flavour="right"
          />
        </div>
      </div>
    </div>
  );
};
