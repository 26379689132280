import {
  EvaluationBacktestBacktestTypeEnum,
  EvaluationLeaderboard,
} from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Card,
  EmptyState,
  IconBadge,
  IconSize,
  LoadingWrapper,
  OrgAwareLink,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { backtestTypeLabel, configForBacktestType } from "../backtests/utils";
import { EvaluationFilterContextProvider } from "../common/EvaluationFilterContext";
import { EvaluationAggregateScorecardController } from "../investigations/EvaluationAggregateScorecard";
import { LeaderboardCreateModal } from "./LeaderboardCreateModal";

export const LeaderboardsListPage = () => {
  // This would be replaced with the actual API endpoint once implemented
  const {
    data: { leaderboards },
    isLoading,
  } = useAPI(
    "aIStaffListEvaluationLeaderboards",
    {},
    {
      // Mocked data until API is implemented
      fallbackData: { leaderboards: [] },
    },
  );

  const [showCreateLeaderboardModal, setShowCreateLeaderboardModal] =
    useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowCreateLeaderboardModal(true)}
          theme={ButtonTheme.Primary}
        >
          Create leaderboard
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {isEmpty(leaderboards) ? (
          <EmptyState content="No leaderboards created yet" />
        ) : (
          <EvaluationFilterContextProvider enableSelectingMetrics={false}>
            <LeaderboardCards leaderboards={leaderboards} />
          </EvaluationFilterContextProvider>
        )}
      </LoadingWrapper>
      {showCreateLeaderboardModal && (
        <LeaderboardCreateModal
          onClose={() => setShowCreateLeaderboardModal(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const LeaderboardCards = ({
  leaderboards,
}: {
  leaderboards: EvaluationLeaderboard[];
}) => {
  // Group by backtest_type + investigation_plan_type
  const labelled = leaderboards.map((leaderboard) => ({
    label: backtestTypeLabel(
      leaderboard.backtest_type as unknown as EvaluationBacktestBacktestTypeEnum,
      leaderboard.investigation_plan_type,
    ),
    leaderboard,
  }));
  const grouped = labelled.reduce(
    (acc, { label, leaderboard }) => {
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push(leaderboard);
      return acc;
    },
    {} as Record<string, EvaluationLeaderboard[]>,
  );

  return (
    <div className="flex flex-col gap-8">
      {Object.entries(grouped).map(([label, leaderboards]) => {
        const config = configForBacktestType(
          leaderboards[0]
            .backtest_type as unknown as EvaluationBacktestBacktestTypeEnum,
        );
        if (!config) {
          return null;
        }
        return (
          <div key={label} className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <IconBadge
                size={IconSize.Small}
                color={config.color}
                icon={config.icon}
              />
              <h3 className="text-lg font-semibold">{label}</h3>
            </div>
            <div className="grid grid-cols-3 gap-4 w-full">
              {leaderboards.map((leaderboard) => (
                <LeaderboardCard
                  leaderboard={leaderboard}
                  key={leaderboard.id}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const LeaderboardCard = ({
  leaderboard,
}: {
  leaderboard: EvaluationLeaderboard;
}) => {
  return (
    <OrgAwareLink to={`/workbench/leaderboards/${leaderboard.id}`}>
      <Card
        title={leaderboard.name}
        clickable
        compact
        iconOverride={<></>}
        description={
          leaderboard.most_recent_entry ? (
            <>
              <hr className="mt-2 mb-4" />
              <EvaluationAggregateScorecardController
                scorecardIDs={leaderboard.most_recent_entry?.scorecard_ids}
                allEntries={[]}
                compact
                showFilterBar={false}
              />
            </>
          ) : (
            <EmptyState content="Leaderboard has no backtests" />
          )
        }
      />
    </OrgAwareLink>
  );
};
