import {
  AlertsCreateDraftSourceConfigRequestBodySourceTypeEnum,
  AlertsCreateDraftSourceConfigResponseBody,
  AlertsListSourcesResponseBody,
  AlertSource,
  AlertSourceClassificationEnum,
  AlertSourceSourceTypeEnum,
  EligibilityInformationIneligibleTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ErrorMessage, Loader } from "@incident-ui";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  RadioButton,
  Spinner,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { Searcher } from "fast-fuzzy";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useMutationV2 } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-debounce";

import { AlertSourceTypeIconBadge } from "../common/AlertSourceTypeConfigs";
import {
  AlertSourceCreateWizardSteps,
  AlertSourceStepEnum,
} from "./AlertSourceCreateWizardSteps";
import { AlertSourceGradientBox } from "./AlertSourceSplitLayout";

export type FormData = {
  name: string;
  source_type: string;
  search_term: string;
};

// Pick the type of alert source you wish to create before we send you into the
// configuration form.
export const AlertSourceChooseSourcePage = ({
  setSourceType,
}: {
  setSourceType: (sourceType: string) => void;
}) => {
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();

  const defaultSourceType = queryParams.get("source_type");

  const formMethods = useForm<FormData>({
    defaultValues: {
      source_type: defaultSourceType || undefined,
      search_term: defaultSourceType || undefined,
    },
  });

  const { data: alertSourcesResponse, isLoading: alertSourcesIsLoading } =
    useAPI("alertsListSources", undefined);

  const selectAlertSource = (source: AlertSource) => {
    formMethods.clearErrors("name");
    formMethods.clearErrors("source_type");

    // This handler is also called when clicking the alert source card, so only
    // reset the name if we are changing the source type (we don't want to overwrite
    // the user's current input).
    if (
      formMethods.getValues<"source_type">("source_type") !== source.source_type
    ) {
      formMethods.setValue<"name">("name", `${source.name} alerts`);
      formMethods.setValue<"source_type">("source_type", source.source_type);
    }
    setSourceType(source.source_type);
  };

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useMutationV2(
    async (apiClient, request: FormData) => {
      const resp = await apiClient.alertsCreateDraftSourceConfig({
        createDraftSourceConfigRequestBody: {
          source_type:
            request.source_type as AlertsCreateDraftSourceConfigRequestBodySourceTypeEnum,
          name: request.name,
        },
      });
      return { resp };
    },
    {
      invalidate: [],
      onSuccess: ({
        resp,
      }: {
        resp: AlertsCreateDraftSourceConfigResponseBody;
      }) => {
        const fromQueryParam = queryParams.get("from");
        const fromQueryString = fromQueryParam ? `&from=${fromQueryParam}` : "";

        return navigate(
          `/alerts/sources/create?step=connect&id=${resp.alert_source_config.id}${fromQueryString}`,
        );
      },
      setError: formMethods.setError,
    },
  );

  if (!alertSourcesResponse) {
    return <Spinner />;
  }

  const selectedSourceType = formMethods.watch("source_type");
  const selectedSource = alertSourcesResponse.alert_sources?.find(
    (x) => x.source_type === selectedSourceType,
  );
  if (selectedSourceType && !selectedSource) {
    throw new Error(
      `Unreachable: could not find source type ${selectedSourceType}`,
    );
  }

  if (alertSourcesIsLoading) {
    return <Loader className={"h-full"} />;
  }

  return (
    <Form.Root
      outerClassName="h-full"
      innerClassName="h-full"
      formMethods={formMethods}
      onSubmit={onSubmit}
      saving={saving}
    >
      <AlertSourceGradientBox
        className={"p-6"}
        sourceTypeConfig={
          selectedSourceType
            ? ALERT_SOURCE_TYPE_CONFIGS[selectedSourceType]
            : undefined
        }
      >
        <div className={"flex flex-col w-full max-w-[640px] mx-auto"}>
          <AlertSourceCreateWizardSteps
            step={AlertSourceStepEnum.Choose}
            sourceType={selectedSourceType as AlertSourceSourceTypeEnum}
            title={"Where do your alerts come from?"}
            description={
              "Select which alerting tool you wish to receive alerts from and give it a name."
            }
          />
        </div>
      </AlertSourceGradientBox>
      <div className={"flex flex-col w-full max-w-[640px] mx-auto"}>
        <div className={"flex flex-col gap-4"}>
          {/* Search through sources */}
          <InputV2
            autoFocus
            formMethods={formMethods}
            name={"search_term"}
            type={InputType.Search}
            iconName={IconEnum.Search}
            iconProps={{ className: "mr-2" }}
            className="w-full rounded"
            inputClassName="shadow-sm"
            placeholder="Search for your source"
          />
          <AlertSourceSelection
            alertSources={alertSourcesResponse}
            onClick={selectAlertSource}
            selectedSource={selectedSourceType}
            formMethods={formMethods}
          />
          <div className="pb-8 w-full">
            <CantSeeYourAlertSource
              alertSources={alertSourcesResponse.alert_sources}
              selectAlertSource={(source) => {
                formMethods.setValue("search_term", "http");
                selectAlertSource(source);
              }}
            />
          </div>
        </div>
      </div>
      <ErrorMessage message={genericError} />
    </Form.Root>
  );
};

// Provide a fallback "You can do this with HTTP" banner at the bottom of the
// list of alert sources in case people can't see what they're looking for.
const CantSeeYourAlertSource = ({
  alertSources,
  selectAlertSource,
}: {
  alertSources: AlertSource[];
  selectAlertSource: (source: AlertSource) => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 bg-slate-50 border rounded-2 border-neutral-100 justify-between p-4">
      <div>
        <div className={"text-content-primary-bold"}>
          Can&apos;t see your alert source?
        </div>
        <div className={"text-content-secondary"}>
          Connect your alert source via HTTP or request we add an alert source
          to this list
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          analyticsTrackingId={"alert-sources.speak-to-us"}
          onClick={() => {
            const httpSource = alertSources.find(
              (a) => a.source_type === AlertSourceSourceTypeEnum.Http,
            );
            if (!httpSource) {
              throw new Error("no http source");
            }

            selectAlertSource(httpSource);
            document.getElementById("source-http")?.scrollIntoView({
              behavior: "smooth",
            });
          }}
          theme={ButtonTheme.Primary}
        >
          Connect via HTTP
        </Button>
        <Button
          analyticsTrackingId={"alert-sources.speak-to-us"}
          onClick={() => {
            window.open(
              "mailto:support@incident.io?subject=Request%20alert%20source",
              "_blank",
            );
          }}
        >
          Request alert source
        </Button>
      </div>
    </div>
  );
};

// Provide a list of all alert sources so people can select them. This calls the
// onClick handler with the selected alert source when it has been selected, and
// the Continue button inside of the alert sources will trigger a form
// submittion.
const AlertSourceSelection = ({
  alertSources,
  onClick,
  className,
  selectedSource,
  formMethods,
}: {
  alertSources: AlertsListSourcesResponseBody;
  onClick: (source: AlertSource) => void;
  className?: string;
  selectedSource?: string;
  formMethods: UseFormReturn<FormData>;
}) => {
  const analytics = useAnalytics();
  const { expelAlertSource } = useFlags();

  const [selection, searchTerm] = formMethods.watch([
    "source_type",
    "search_term",
  ]);

  // TODO: Feature flagging Expel for now but this needs removing
  let sources = alertSources.alert_sources.filter(
    (as) => expelAlertSource || as.source_type !== "expel",
  );

  const searcher = new Searcher(sources, {
    keySelector: (s) => s.name,
    threshold: 0.8,
  });

  if (searchTerm && searchTerm !== "") {
    sources = searcher.search(searchTerm);
  }

  const { setValue, getValues } = formMethods;

  // When the user selects a source type, we preset the name field
  useEffect(() => {
    if (selection) {
      const selectedSource = sources.find((x) => x.source_type === selection);
      if (!selectedSource) {
        // Unselect the alert source if it's not in the list anymore (filtered out by search)
        setValue<"source_type">("source_type", "");
        setValue<"name">("name", "");
      }
    }
  }, [sources, setValue, getValues, selection]);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 250);

  useEffect(() => {
    if (searchTerm && debouncedSearchTerm === searchTerm) {
      analytics?.track(`alert-sources.searched`, {
        search_term: debouncedSearchTerm,
      });
    }
  }, [analytics, debouncedSearchTerm, searchTerm]);

  const alertSourceConfigs = _.orderBy(sources || [], ["name"], "asc");

  return (
    <div className="flex flex-col space-y-2 w-full">
      <StackedList>
        {alertSourceConfigs
          .filter(
            (alertSource) =>
              alertSource.classification !==
              AlertSourceClassificationEnum.Native,
          )
          .map((source) => (
            <AlertSourceListItem
              selectedSource={selectedSource}
              key={source.source_type}
              alertSource={source}
              onClick={() => onClick(source)}
              className={className}
              formMethods={formMethods}
            />
          ))}
      </StackedList>
    </div>
  );
};

const AlertSourceListItem = ({
  alertSource,
  onClick,
  selectedSource,
  formMethods,
}: {
  alertSource: AlertSource;
  onClick: () => void;
  selectedSource?: string;
  className?: string;
  formMethods: UseFormReturn<FormData>;
}) => {
  const navigate = useOrgAwareNavigate();

  const isSelected = selectedSource === alertSource.source_type;
  const isEligible = alertSource.eligibility
    ? alertSource.eligibility.is_eligible
    : true;
  const needsSetup =
    alertSource.eligibility?.ineligible_type ===
    EligibilityInformationIneligibleTypeEnum.NeedsSetup;
  const isUnique = alertSource.max_instances === 1;

  return (
    <div
      onClick={() => {
        if (isEligible || needsSetup) {
          onClick();
        }
      }}
      className={tcx(
        isEligible || needsSetup ? "cursor-pointer" : "cursor-not-allowed",
      )}
    >
      <StackedListItem
        key={alertSource.source_type}
        title={alertSource.name}
        className={"p-3"}
        noPadding
        iconNode={
          <div>
            <div id={`source-${alertSource.source_type}`} />
            <AlertSourceTypeIconBadge
              sourceType={alertSource.source_type as AlertSourceSourceTypeEnum}
            />
          </div>
        }
        accessory={
          <AlertSourceCardIndicators
            isSelected={isSelected}
            isEligible={isEligible}
            needsSetup={needsSetup}
          />
        }
        footerAccessory={
          selectedSource === alertSource.source_type ? (
            <div className={"flex flex-col gap-4"}>
              <div className={"flex text-content-secondary"}>
                {alertSource.docstring}
              </div>
              {/* if this is unique, and we don't already have one - show the continue button */}
              {isUnique && !needsSetup && (
                <div className="flex justify-end">
                  <AlertSourceContinueButton />
                </div>
              )}
              {(!isUnique || needsSetup) && (
                <div
                  className={tcx(
                    "w-full p-3 space-y-2 rounded-2",
                    needsSetup ? "bg-amber-100" : "bg-slate-50",
                  )}
                >
                  {!isUnique && !needsSetup && (
                    <div>
                      <div className={"text-sm-bold mb-2"}>
                        Please give this source a unique name
                      </div>

                      <div className="flex space-x-2">
                        <div className="grow max">
                          <InputV2 formMethods={formMethods} name="name" />
                        </div>
                        <div className="flex-none">
                          <AlertSourceContinueButton />
                        </div>
                      </div>
                    </div>
                  )}
                  {needsSetup ? (
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="text-sm-bold text-amber-800 mb-1">
                          This source type requires additional configuration
                        </div>
                        <div className="text-sm-normal text-amber-800">
                          Please install the integration to receive alerts from
                          this source type
                        </div>
                      </div>
                      <Button
                        analyticsTrackingId={null}
                        onClick={() =>
                          navigate(
                            `/settings/integrations/${alertSource.source_type}`,
                          )
                        }
                        openInNewTab
                      >
                        Install
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : null
        }
      />
    </div>
  );
};

// Gated button that controls continuing into the alert source configuration.
const AlertSourceContinueButton = () => {
  const { hasScope } = useIdentity();

  const hasPermission =
    hasScope(ScopeNameEnum.AlertSourceUpdate) &&
    hasScope(ScopeNameEnum.AlertSourceCreate);

  return (
    <GatedButton
      type={"submit"}
      key={"create"}
      analyticsTrackingId="alert-source-create-continue"
      className="pt-2.5 pb-2.5"
      theme={ButtonTheme.Primary}
      requiredScope={ScopeNameEnum.AlertSourceCreate}
      disabledTooltipContent={
        !hasPermission
          ? "You do not have permission to do this"
          : "Select an alert source"
      }
    >
      Continue
    </GatedButton>
  );
};

// Shim around a radio button to give the graphic of selecting an alert source
// from the list.
const AlertSourceCardIndicators = ({
  isEligible,
  isSelected,
  needsSetup,
}: {
  isEligible: boolean;
  isSelected: boolean;
  needsSetup?: boolean;
}) => {
  return (
    <>
      {/* --- Checkbox --- */}
      {isEligible || needsSetup ? (
        <>
          <RadioButton
            id={""}
            label={null}
            value={isSelected ? "true" : "false"}
            checked={isSelected}
            onChange={() => {
              /* do nothing */
            }}
          />
        </>
      ) : (
        <Badge
          theme={BadgeTheme.Tertiary}
          className={"mr-1 ml-2 !font-normal text-xs !bg-surface-tertiary"}
        >
          Already created
        </Badge>
      )}
    </>
  );
};
