import { ListEditorValue } from "@incident-shared/forms/v1/ListEditor";
import {
  ListEditorV2,
  SlackChannelFormValue,
  SlackChannelsEditorV2,
} from "@incident-shared/forms/v2/editors";
import { FormInputWrapper } from "@incident-shared/forms/v2/helpers";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputType } from "@incident-ui/Input/Input";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ReportChannel,
  ReportChannelTypeEnum,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { v4 as uuidv4 } from "uuid";

export type RecipientsFormData = {
  slackChannels: SlackChannelFormValue[];
  slackChannelsEnabled: boolean;
  emails: ListEditorValue<string>[];
  emailsEnabled: boolean;
};

export interface FormWithRecipients {
  recipients: RecipientsFormData;
}

export const useDefaultRecipientsFromChannels = (
  reportChannels: ReportChannel[] | undefined,
): FormWithRecipients["recipients"] => {
  if (!reportChannels) {
    return {
      emails: [getDefaultEmailRow()],
      slackChannels: [],
      slackChannelsEnabled: false,
      emailsEnabled: false,
    };
  }

  const slackChannels = reportChannels
    ?.filter((channel) => channel.type === ReportChannelTypeEnum.SlackChannel)
    .map(
      (channel): SlackChannelFormValue => ({
        value: channel.id,
        label: channel.label,
        is_private: channel.is_private,
      }),
    );

  const emails =
    reportChannels
      ?.filter((channel) => channel.type === ReportChannelTypeEnum.Email)
      .map((channel, i) => ({
        id: channel.id,
        value: channel.label,
        sort_key: i,
      })) ?? [];

  return {
    slackChannels: slackChannels ?? [],
    slackChannelsEnabled: slackChannels?.length > 0,
    emails: emails.length > 0 ? emails : [getDefaultEmailRow()],
    emailsEnabled: emails.length > 0,
  };
};

export const recipientsToChannels = (
  recipients: RecipientsFormData,
): ReportChannel[] => {
  const channels: ReportChannel[] = [];

  if (recipients.slackChannelsEnabled) {
    channels.push(
      ...recipients.slackChannels.map((slackChannel) => ({
        type: ReportChannelTypeEnum.SlackChannel,
        id: slackChannel.value,
        label: slackChannel.label,
        is_private: slackChannel.is_private,
      })),
    );
  }

  if (recipients.emailsEnabled) {
    channels.push(
      ...recipients.emails
        .filter(({ value: email }) => email !== "")
        .map(({ value: email }) => ({
          type: ReportChannelTypeEnum.Email,
          id: email,
          label: email,
          is_private: false,
        })),
    );
  }

  return channels;
};

export const RecipientsEditor = <TFormType extends FormWithRecipients>({
  formMethods: _formMethods,
  supportedChannelTypes,
}: {
  formMethods: UseFormReturn<TFormType>;

  supportedChannelTypes?: ReportChannelTypeEnum[];
}) => {
  const formMethods =
    _formMethods as unknown as UseFormReturn<FormWithRecipients>; // To avoid compilation errors

  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const slackChannelsSupported =
    primaryCommsPlatform === CommsPlatform.Slack &&
    (supportedChannelTypes?.includes(ReportChannelTypeEnum.SlackChannel) ??
      true);

  const isSlackChannelSelected: boolean = formMethods.watch(
    "recipients.slackChannelsEnabled",
  );
  const isEmailSelected: boolean = formMethods.watch(
    "recipients.emailsEnabled",
  );

  useEffect(() => {
    if (!slackChannelsSupported && slackChannelsSupported) {
      formMethods.setValue(
        "recipients.slackChannelsEnabled",
        !slackChannelsSupported,
      );
    }
    if (!slackChannelsSupported) {
      formMethods.setValue("recipients.emailsEnabled", true);
    }
  }, [slackChannelsSupported, formMethods]);

  return (
    <FormInputWrapper
      label="How would you like to receive this report?"
      name="recipients"
    >
      {slackChannelsSupported && (
        <div className="min-w-lg max-w-lg mr-4 grow">
          <div className="flex flex-row items-center mb-2">
            <CheckboxV2
              className={"mr-2"}
              formMethods={formMethods}
              name="recipients.slackChannelsEnabled"
            />
            <Form.Label htmlFor="slack_channel">
              To a Slack channel(s)
            </Form.Label>
          </div>

          <div className="ml-6">
            <SlackChannelsEditorV2
              formMethods={formMethods}
              name={"recipients.slackChannels"}
              disabled={!isSlackChannelSelected}
              hideWarning={true}
            />
          </div>
        </div>
      )}
      <div className="mt-4 min-w-lg max-w-lg mr-4 grow">
        <div className="flex flex-row items-center mb-2">
          {slackChannelsSupported && ( // no need to show checkbox if slack is not supported
            <CheckboxV2
              className="mr-2"
              formMethods={formMethods}
              name={"recipients.emailsEnabled"}
            />
          )}
          <Form.Label htmlFor={"email"}>By email(s)</Form.Label>
        </div>
        <ListEditorV2
          formMethods={formMethods}
          name={"recipients.emails"}
          className={slackChannelsSupported ? "ml-6" : undefined}
          allowEdit={isEmailSelected}
          getDefaultRow={getDefaultEmailRow}
          rowPlaceholder="e.g. example@email.com"
          onClearErrors={() => {
            formMethods.clearErrors("recipients.emails");
            formMethods.clearErrors("recipients");
          }}
          addNewText="Add another email"
          inputType={InputType.Email}
          allowReordering={false}
        />
      </div>
    </FormInputWrapper>
  );
};

const getDefaultEmailRow = () => ({
  id: uuidv4(),
  value: "",
  sort_key: 10000,
});
