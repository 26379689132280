import {
  AlertSource,
  AlertSourceClassificationEnum,
  AlertSourceConfig,
  IntegrationSettings,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  ConfigureDrawerProps,
  getBrokenIntegrations,
  getBrokenIntegrationsLookup,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  StackedList,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { DeletionConfirmationModal } from "../../DeletionConfirmationModal";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "./IntegrationDrawer";

export const AlertSourceDrawer = (props: ConfigureDrawerProps) => {
  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  if (
    sourceConfigsLoading ||
    integrationsLoading ||
    sourcesLoading ||
    !integrations
  ) {
    return <IntegrationDrawerContentLoader />;
  }
  if (sourceConfigsError || integrationsError || sourcesError) {
    return <GenericErrorMessage error={sourceConfigsError} />;
  }

  const thisAlertSource = alertSources.find(
    (as) => as.source_type === props.integration.provider,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      <div className="flex flex-col gap-3">
        <div className="text-base-bold text-content-primary">Alert sources</div>
        <div className="flex flex-col rounded-2">
          <StackedList>
            {alertSourceConfigs
              .filter(
                (s) => (s.source_type as string) === props.integration.provider,
              )
              .map((s) => (
                <AlertSourceConfigRow
                  key={s.id}
                  alertSourceConfig={s}
                  alertSource={thisAlertSource}
                  integrations={integrations}
                />
              ))}
          </StackedList>
        </div>
      </div>
    </GenericConfigureDrawerContents>
  );
};

export function AlertSourceConfigRow({
  alertSourceConfig,
  alertSource,
  integrations,
}: {
  alertSourceConfig: AlertSourceConfig;
  alertSource?: AlertSource;
  integrations: IntegrationSettings[];
}): React.ReactElement {
  const showToast = useToast();

  const { trigger: onDelete } = useAPIMutation(
    "alertsListSourceConfigs",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.alertsDestroySourceConfig({ id: id });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertSourceConfig.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertSourceConfig.name}`,
        });
      },
    },
  );

  const brokenIntegrations = getBrokenIntegrations(
    alertSource?.required_integrations ?? [],
    getBrokenIntegrationsLookup(integrations),
  ).map((x) =>
    IntegrationConfigFor(x as unknown as IntegrationSettingsProviderEnum),
  );

  const integrationConfig = IntegrationConfigFor(
    alertSourceConfig.source_type as unknown as IntegrationSettingsProviderEnum,
  );

  const alertSourceForBrokenIntegration = brokenIntegrations.length > 0;

  return (
    <div className={"px-4 py-4 flex flex-row justify-between"}>
      <div className="flex flex-row gap-3 h-full items-center">
        <Badge
          icon={IconEnum.Alert}
          size={BadgeSize.Medium}
          theme={BadgeTheme.Unstyled}
          style={{
            background:
              integrationConfig.colourConfig?.secondaryColour !== undefined
                ? integrationConfig.colourConfig.secondaryColour
                : // surface-secondary
                  "#F7F7F8",
          }}
          iconStyle={{
            color:
              integrationConfig.colourConfig?.primaryColour !== undefined
                ? integrationConfig.colourConfig.primaryColour
                : integrationConfig.hexColor,
          }}
        />
        <p className="text-sm-bold">{alertSourceConfig.name}</p>
      </div>
      <AlertSourceConfigOverflowMenu
        alertSource={alertSource}
        alertSourceConfig={alertSourceConfig}
        onDelete={() => onDelete({ id: alertSourceConfig.id })}
        hasBrokenIntegration={alertSourceForBrokenIntegration}
      />
    </div>
  );
}

const AlertSourceConfigOverflowMenu = ({
  alertSource,
  alertSourceConfig,
  onDelete,
}: {
  alertSource?: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  onDelete: () => void;
  hasBrokenIntegration?: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.AlertSourceUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertSourceDestroy);
  const isDeletableSource =
    alertSource?.classification !== AlertSourceClassificationEnum.Native;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert source"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={[
          {
            resource_type: "AlertSourceConfig",
            id: alertSourceConfig.id,
          },
        ]}
        onDelete={onDelete}
        analyticsTrackingId="delete-settings-item"
        resourceTitle={alertSourceConfig.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertSourceConfig.name}</span>?
          </>
        }
      />
      <div className="flex flex-row gap-4">
        <Tooltip
          content="Edit alert source"
          bubbleProps={{ className: "w-full" }}
        >
          <Button
            icon={IconEnum.Edit}
            href={`/alerts/sources/${alertSourceConfig.id}/edit`}
            openInNewTab={true}
            className="flex items-center justify-center w-7 h-7 bg-surface-secondary hover:bg-surface-tertiary rounded-1"
            size={BadgeSize.Small}
            title="Edit alert source"
            disabled={!canEdit}
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId="alert-source-config-edit"
          />
        </Tooltip>
        <Tooltip
          content="Delete alert source"
          bubbleProps={{ className: "w-full" }}
        >
          <Button
            icon={IconEnum.Delete}
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteModal(true);
            }}
            title="Delete alert source"
            className="flex items-center justify-center w-7 h-7 bg-surface-secondary hover:bg-surface-tertiary rounded-1"
            disabled={!hasDeletionPermissions || !isDeletableSource}
            size={BadgeSize.Small}
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId="alert-source-config-delete"
          />
        </Tooltip>
      </div>
    </>
  );
};
