import type { SVGProps } from "react";
import * as React from "react";
const SvgFilterBars = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor">
      <path d="M11.333 7.334H4.667a.667.667 0 0 0 0 1.333h6.666a.667.667 0 0 0 0-1.333ZM13.556 3.111H2.445a.667.667 0 0 0 0 1.334h11.11a.667.667 0 0 0 0-1.334ZM8.889 11.556H7.11a.667.667 0 0 0 0 1.333H8.89a.667.667 0 0 0 0-1.333Z" />
    </g>
  </svg>
);
export default SvgFilterBars;
