import { differenceInSeconds } from "date-fns";
import { useState } from "react";
import { DurationEnum, formatDurationInSeconds } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { useInterval } from "usehooks-ts";

export const useLatestIncidentCall = (incidentId: string) => {
  const {
    data: callData,
    isLoading: callIsLoading,
    error: callError,
  } = useAPI("incidentCallsGetForLatestForIncident", {
    incidentId,
  });

  return {
    callData,
    isLoading: callIsLoading,
    error: callError,
  };
};

export const useHowLongAgoFromDate = (inputDate: Date) => {
  const [now, setNow] = useState(new Date());
  useInterval(() => setNow(new Date()), 15000);

  const diff = differenceInSeconds(now, inputDate);
  const durationString = formatDurationInSeconds(diff, 1, DurationEnum.minutes);
  return diff === 0
    ? "now"
    : durationString
    ? `${durationString} ago`
    : "less than a minute ago";
};
