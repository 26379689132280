import {
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageContentIncidentTypeEnum as IncidentTypeEnum,
} from "@incident-io/api";
import cx from "classnames";
import _ from "lodash";
import { useState } from "react";

import { useUIContext } from "../../UIContext";
import { useTranslations } from "../../use-translations";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";
import { Tooltip } from "../Tooltip";
import styles from "./ComponentBadge.module.scss";
import { Incident } from "./HeadsUp";

// Badges for each affected component of an incident.
export const ComponentBadge = ({
  name,
  status,
  isHighlighted = false,
  className,
  incidents,
}: {
  name: string;
  status: ComponentStatusEnum;
  isHighlighted?: boolean;
  className?: string;
  incidents: Incident[];
}): React.ReactElement | null => {
  const t = useTranslations("HeadsUp");

  const [hovering, setHovering] = useState(false);

  if (status === ComponentStatusEnum.Operational) {
    return null;
  }

  const groupedIncidents = _.groupBy(incidents, "type");

  return (
    <Tooltip
      content={
        <div
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className="space-y-2"
        >
          <IncidentList
            title={t("related_incidents")}
            incidents={groupedIncidents[IncidentTypeEnum.Incident]}
          />
          <IncidentList
            title={t("related_maintenances")}
            incidents={groupedIncidents[IncidentTypeEnum.Maintenance]}
          />
        </div>
      }
    >
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className={cx(
          "flex items-center",
          "!rounded", // 4px
          "border",
          "px-2 py-0.5",
          "hover:cursor-pointer",
          "text-sm font-medium",
          "text-content-primary dark:text-slate-100",
          "transition",
          "border-transparent",
          isHighlighted || hovering ? styles.highlighted : null,
          getBadgeColor(status),
          className,
        )}
      >
        <span className="md:whitespace-nowrap break-normal">{name}</span>
      </div>
    </Tooltip>
  );
};

const IncidentList = ({
  title,
  incidents,
}: {
  title: string;
  incidents: Incident[];
}) => {
  const { IncidentLink } = useUIContext();
  if (!incidents) {
    return null;
  }

  return (
    <div>
      <div
        className={cx(
          "uppercase font-medium text-[11px] p-1 tracking-widest",
          "text-content-tertiary",
        )}
      >
        {title}
      </div>
      <div>
        {incidents.map(({ id, name, currentWorstStatus }) => (
          <IncidentLink
            key={id}
            className={cx(
              "text-sm flex items-center font-medium group rounded transition cursor-pointer",
              "py-1 px-2",
              "text-content-primary hover:bg-surface-secondary",
              "dark:text-slate-100  dark:hover:bg-surface-invert/50",
            )}
            incident={{ incident_id: id, name }}
            analyticsTrackingId={null}
          >
            <ComponentStatusIcon componentStatus={currentWorstStatus} />

            <span className="text-left grow">{name}</span>
          </IncidentLink>
        ))}
      </div>
    </div>
  );
};

const getBadgeColor = (status: ComponentStatusEnum) => {
  switch (status) {
    case ComponentStatusEnum.DegradedPerformance:
      return styles.degradedPerformance;
    case ComponentStatusEnum.PartialOutage:
      return styles.partialOutage;
    case ComponentStatusEnum.FullOutage:
      return styles.fullOutage;
    case ComponentStatusEnum.UnderMaintenance:
      return styles.underMaintenance;
    case ComponentStatusEnum.Operational:
      return null;
    default:
      return undefined;
  }
};
