import { Investigation } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
  StackedList,
} from "@incident-ui";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

export const WorkbenchInvestigationsListPage = () => {
  const { data, isLoading } = useAPI("aIListInvestigations", {});

  return (
    <WorkbenchSubPageWrapper loading={isLoading}>
      <StackedList>
        {data?.investigations.map((investigation) => (
          <InvestigationRow
            key={investigation.id}
            investigation={investigation}
          />
        ))}
      </StackedList>
    </WorkbenchSubPageWrapper>
  );
};

const InvestigationRow = ({
  investigation,
}: {
  investigation: Investigation;
}) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/workbench/investigations/${investigation.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={investigation.created_at}
            className="font-medium"
          />
          {investigation.incident_external_id && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={"/incidents/" + investigation.incident_external_id}
            >
              INC-{investigation.incident_external_id}
            </Button>
          )}
          {investigation.incident_name}
        </div>
        <div className="grow" />
        <div className="flex items-center justify-end gap-4">
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Clock} size={IconSize.Small} />
            {investigation.runtime_seconds
              ? `${investigation.runtime_seconds.toFixed(0)}s`
              : "—"}
          </div>
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />
            {investigation.total_cost_in_cents
              ? `$${(investigation.total_cost_in_cents / 100.0).toFixed(2)}`
              : "—"}
          </div>
        </div>
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={`/workbench/investigations/${investigation.id}`}
          analyticsTrackingId={null}
          title="View investigation"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
