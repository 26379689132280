import { ConditionBadge } from "@incident-shared/engine/conditions";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { partition } from "lodash";
import pluralize from "pluralize";
import React from "react";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
  FilterPopover,
  isViewableFor,
  useFiltersContext,
} from "src/components/@shared/filters";
import { useSavedViews } from "src/components/saved-views/SavedViewContext";
import { tcx } from "src/utils/tailwind-classes";

import { AppliedFilter, TeamAppliedFilter } from "./AppliedFilter";
import { ALERT_ID, INCIDENT_ID } from "./constants";
import styles from "./Filters.module.scss";

interface AppliedFiltersBaseProps {
  availableFilterFields: AvailableFilter[];
  appliedFilters: ExtendedFormFieldValue[];
  readonly?: boolean;
  badgeTheme?: "white" | "slate";
  mini?: boolean;
  isTeamFilter?: (f: ExtendedFormFieldValue) => boolean;
}

interface EditableAppliedFiltersProps extends AppliedFiltersBaseProps {
  onDeleteFilter: (key: string) => void;
  readonly?: false;
}

interface ReadonlyAppliedFiltersProps extends AppliedFiltersBaseProps {
  readonly: true;
  onDeleteFilter?: never;
}

export const AppliedFilters = ({
  availableFilterFields,
  appliedFilters,
  readonly,
  onDeleteFilter,
  badgeTheme,
  mini,
  isTeamFilter,
}:
  | EditableAppliedFiltersProps
  | ReadonlyAppliedFiltersProps): React.ReactElement | null => {
  const [teamFilter, otherFilters] = isTeamFilter
    ? partition(appliedFilters, isTeamFilter)
    : [[], appliedFilters];

  return (
    <>
      {/*  On small screens we render the applied filters as a dropdown to be more compact  */}
      <FilterPopover
        renderTriggerButton={({ onClick }) => {
          return (
            <div className={tcx(styles.compactAppliedFilters)}>
              {teamFilter.length > 0 && isTeamFilter ? (
                <TeamAppliedFilter
                  filterObj={teamFilter[0]}
                  availableFilterFields={availableFilterFields}
                  onSelectDropdownItem={(item) => onClick(item)}
                  badgeTheme={badgeTheme}
                  mini={mini}
                  readonly={readonly}
                  isTeamFilter={isTeamFilter}
                />
              ) : null}
              {otherFilters.length > 0 ? (
                <DropdownMenu
                  side="bottom"
                  triggerButton={
                    <Button
                      icon={IconEnum.FilterBars}
                      analyticsTrackingId="null"
                      theme={ButtonTheme.Secondary}
                    >
                      {otherFilters.length} selected
                    </Button>
                  }
                >
                  {otherFilters.map((filterObj) => {
                    return (
                      <AppliedFilter
                        filterObj={filterObj}
                        key={filterObj.key}
                        availableFilterFields={availableFilterFields}
                        onSelectDropdownItem={(item) => onClick(item)}
                        badgeTheme={badgeTheme}
                        mini={mini}
                        asDropdownItem
                        {...(readonly
                          ? { readonly: true }
                          : {
                              onDeleteFilter: onDeleteFilter,
                            })}
                        isTeamFilter={isTeamFilter}
                      />
                    );
                  })}
                </DropdownMenu>
              ) : null}
            </div>
          );
        }}
      />
      <div
        className={tcx(
          styles.normalAppliedFilters,
          "flex-wrap gap-y-2 gap-x-2 min-w-0",
        )}
      >
        {teamFilter.length > 0 && isTeamFilter ? (
          <TeamAppliedFilter
            filterObj={teamFilter[0]}
            availableFilterFields={availableFilterFields}
            badgeTheme={badgeTheme}
            mini={mini}
            isTeamFilter={isTeamFilter}
          />
        ) : null}
        {otherFilters.map((filterObj) => {
          return (
            <AppliedFilter
              filterObj={filterObj}
              key={filterObj.key}
              availableFilterFields={availableFilterFields}
              badgeTheme={badgeTheme}
              mini={mini}
              {...(readonly
                ? { readonly: true }
                : {
                    onDeleteFilter: onDeleteFilter,
                  })}
              isTeamFilter={isTeamFilter}
            />
          );
        })}
      </div>
    </>
  );
};

export const AppliedFiltersInline = ({
  totalNumberOfItems,
  itemsLabel,
  analyticsTrackingId,
  badgeTheme = "slate",
  hideDefaultPopover,
  className,
}: {
  totalNumberOfItems: number | null;
  itemsLabel: string | null;
  analyticsTrackingId?: string;
  badgeTheme?: "white" | "slate";
  hideDefaultPopover?: boolean;
  className?: string;
}) => {
  const {
    filters,
    deleteFilter,
    clearFilters,
    availableFilterFields,
    filtersLoading,
    kind,
    isTeamFilter,
  } = useFiltersContext();
  const { viewIsPreset, setSelectedSavedViewID } = useSavedViews();

  const filterBarFilters = filters.filter(isViewableFor(kind));

  const idFilter = filters.find((f) =>
    [ALERT_ID, INCIDENT_ID].includes(f.field_key),
  );
  const filteredIDs = idFilter
    ? idFilter.multiple_options_value ?? [idFilter.single_option_value]
    : [];

  const hasViewableFilters = filterBarFilters.length > 0;
  const onlyTeamFilterApplied =
    isTeamFilter &&
    !viewIsPreset &&
    filterBarFilters.length === 1 &&
    isTeamFilter(filterBarFilters[0]);

  return hasViewableFilters || idFilter ? (
    <div
      className={tcx(
        "flex flex-wrap justify-start items-center gap-2",
        className,
      )}
    >
      <div className="items-center min-w-0">
        {!filtersLoading && (
          <AppliedFilters
            availableFilterFields={availableFilterFields}
            appliedFilters={filterBarFilters}
            onDeleteFilter={deleteFilter}
            badgeTheme={badgeTheme}
            isTeamFilter={isTeamFilter}
          />
        )}
        {idFilter && (
          <ConditionBadge
            subject={"Filtered to"}
            operation={`${filteredIDs.length}`}
            criteria={`${kind}${filteredIDs.length === 1 ? "" : "s"}`}
            theme={badgeTheme}
            onDelete={() => {
              deleteFilter(idFilter.field_key);
            }}
          />
        )}
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        <FilterPopover
          alignPopover={"start"}
          renderTriggerButton={({ onClick }) => (
            <Button
              theme={ButtonTheme.Tertiary}
              onClick={() => onClick()}
              analyticsTrackingId={analyticsTrackingId || null}
              size={BadgeSize.Medium}
              icon={IconEnum.FilterBars}
              iconProps={{
                size: IconSize.Small,
              }}
              title={""}
            />
          )}
        />
        {totalNumberOfItems != null && !onlyTeamFilterApplied && (
          <div className={"px-2 hidden sm:block"}>
            <div className="w-px h-4 bg-surface-tertiary" />
          </div>
        )}
        <div className="flex flex-row gap-3 items-center">
          {totalNumberOfItems != null && (
            <span className="text-sm font-medium text-content-secondary">
              {totalNumberOfItems}{" "}
              {pluralize(itemsLabel || "", totalNumberOfItems)}
            </span>
          )}
          {!onlyTeamFilterApplied && (
            <Button
              theme={ButtonTheme.Naked}
              onClick={() => {
                // If the view is a preset, just exit the view rather than updating it
                if (viewIsPreset) {
                  setSelectedSavedViewID(null);
                } else {
                  clearFilters();
                }
              }}
              analyticsTrackingId={null}
              className="justify-self-stretch text-content-primary text-sm font-medium"
            >
              Clear
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : !hideDefaultPopover ? (
    <FilterPopover
      alignPopover={"start"}
      renderTriggerButton={({ onClick }) => (
        <Button
          theme={ButtonTheme.Tertiary}
          onClick={() => onClick()}
          analyticsTrackingId={analyticsTrackingId || null}
          size={BadgeSize.Medium}
          icon={IconEnum.FilterBars}
          iconProps={{
            size: IconSize.Small,
          }}
        >
          Add filter
        </Button>
      )}
    />
  ) : null;
};

export const AppliedFiltersInlineReadOnly = ({
  totalNumberOfItems,
  itemsLabel,
  badgeTheme = "slate",
  className,
}: {
  totalNumberOfItems: number | null;
  itemsLabel: string | null;
  badgeTheme?: "white" | "slate";
  className?: string;
}) => {
  const { filters, availableFilterFields, filtersLoading, kind, isTeamFilter } =
    useFiltersContext();

  const filterBarFilters = filters.filter(isViewableFor(kind));

  const idFilter = filters.find((f) =>
    [ALERT_ID, INCIDENT_ID].includes(f.field_key),
  );
  const filteredIDs = idFilter
    ? idFilter.multiple_options_value ?? [idFilter.single_option_value]
    : [];

  const hasViewableFilters = filterBarFilters.length > 0;
  return hasViewableFilters || idFilter ? (
    <div
      className={tcx(
        "flex flex-wrap justify-start items-center gap-2",
        className,
      )}
    >
      <div className="items-center min-w-0">
        {!filtersLoading && (
          <AppliedFilters
            availableFilterFields={availableFilterFields}
            appliedFilters={filterBarFilters}
            badgeTheme={badgeTheme}
            readonly={true}
            isTeamFilter={isTeamFilter}
          />
        )}
        {idFilter && (
          <ConditionBadge
            subject={"Filtered to"}
            operation={`${filteredIDs.length}`}
            criteria={`${kind}${filteredIDs.length === 1 ? "" : "s"}`}
            theme={badgeTheme}
          />
        )}
      </div>
      {totalNumberOfItems != null && (
        <div className="flex items-center gap-2 flex-wrap">
          <div className={"px-2 hidden sm:block"}>
            <div className="w-px h-4 bg-surface-tertiary" />
          </div>
          <div className="flex flex-row gap-3 items-center">
            <span className="text-sm font-medium text-content-secondary">
              {totalNumberOfItems}{" "}
              {pluralize(itemsLabel || "", totalNumberOfItems)}
            </span>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
