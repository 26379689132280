import {
  AIStaffResumeEvaluationBacktestRequestBody,
  AIStaffShowEvaluationBacktestResponseBody,
  EvaluationBacktest,
  EvaluationBacktestBacktestTypeEnum,
  EvaluationBacktestEntry,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Banner,
  BannerTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  ContentBox,
  IconEnum,
  ModalFooter,
  Spinner,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { min, sum } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useAPIRefetch, useMutationV2 } from "src/utils/swr";

import { LabeledValue } from "../common/LabeledValue";
import { TuneableParameters } from "../investigations/TuneableParameterEditorV2";
import { PinBacktestToLeaderboardModal } from "../leaderboards/PinBacktestToLeaderboardModal";
import { BacktestEntriesList } from "./BacktestEntriesList";
import { BacktestUpdateModal } from "./BacktestUpdateModal";

export const BacktestsShowPage = () => {
  const { backtestId } = useParams<{ backtestId: string }>();
  const { from_leaderboard } = useParams<{ from_leaderboard: string }>();
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);

  const {
    data,
    isLoading: isLoadingBacktest,
    isValidating: isRefetching,
  } = useAPI(
    "aIStaffShowEvaluationBacktest",
    {
      id: backtestId || "",
    },
    {
      revalidateOnFocus: true,
      // Poll continually while the investigation is active.
      // Use a function that receives the current data to determine the interval
      refreshInterval: (currentData) => {
        if (
          currentData?.backtest?.state === "pending" ||
          currentData?.backtest?.state === "running"
        ) {
          return 3000;
        }
        return 0;
      },
    },
  );

  const { data: comparedWithData } = useAPI(
    data?.backtest?.compare_with_backtest_id
      ? "aIStaffShowEvaluationBacktest"
      : null,
    {
      id: data?.backtest?.compare_with_backtest_id || "",
    },
  );

  const refetchBacktest = useAPIRefetch("aIStaffShowEvaluationBacktest", {
    id: backtestId || "",
  });

  const backtest = data?.backtest;
  const entries = data?.entries || [];

  return (
    <>
      {backtest && showResumeModal && (
        <BacktestResumeModal
          entries={entries}
          backtest={backtest}
          onClose={() => setShowResumeModal(false)}
        />
      )}
      {backtest && showPauseModal && (
        <BacktestPauseModal
          entries={entries}
          backtest={backtest}
          onClose={() => setShowPauseModal(false)}
        />
      )}
      {backtest && showEditModal && (
        <BacktestUpdateModal
          backtest={backtest}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {backtest && showPinModal && (
        <PinBacktestToLeaderboardModal
          backtest={backtest}
          onClose={() => setShowPinModal(false)}
        />
      )}
      <WorkbenchSubPageWrapper
        title="View backtest"
        loading={isLoadingBacktest}
        backHref={
          from_leaderboard
            ? `/workbench/leaderboards/${from_leaderboard}`
            : "/workbench/backtests"
        }
        accessory={
          <div className={"flex flex-center gap-2"}>
            <Button
              theme={ButtonTheme.Secondary}
              analyticsTrackingId={null}
              onClick={refetchBacktest}
              icon={IconEnum.Refresh}
              loading={isRefetching}
            >
              Refresh Data
            </Button>
            <Button
              theme={ButtonTheme.Secondary}
              analyticsTrackingId="pin-backtest"
              onClick={() => setShowPinModal(true)}
              icon={IconEnum.Pin}
            >
              Pin
            </Button>
            <Button
              theme={ButtonTheme.Secondary}
              analyticsTrackingId="edit-backtest"
              onClick={() => setShowEditModal(true)}
              icon={IconEnum.Edit}
            >
              Edit
            </Button>

            {backtest?.state === "paused" && (
              <Button
                analyticsTrackingId="backtest-resume"
                theme={ButtonTheme.Primary}
                icon={IconEnum.PlayOverlay}
                onClick={() => setShowResumeModal(true)}
              >
                Resume
              </Button>
            )}
            {backtest?.state === "running" && (
              <Button
                analyticsTrackingId="backtest-pause"
                theme={ButtonTheme.Primary}
                icon={IconEnum.Pause}
                onClick={() => setShowPauseModal(true)}
              >
                Pause
              </Button>
            )}
          </div>
        }
        banner={
          backtest ? (
            <BacktestRunningBanner backtest={backtest} entries={entries} />
          ) : null
        }
      >
        {backtest && (
          <BacktestsShowPageContents
            backtest={backtest}
            entries={entries}
            comparedWithData={comparedWithData}
          />
        )}
      </WorkbenchSubPageWrapper>
    </>
  );
};

const BacktestsShowPageContents = ({
  backtest,
  entries,
  comparedWithData,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  comparedWithData: AIStaffShowEvaluationBacktestResponseBody | undefined;
}) => {
  const formatValue = (value) => {
    if (!value) return "–";
    if (value instanceof Date) return value.toLocaleString();

    return value.toString();
  };

  const totalCost = sum(entries.map((e) => e.investigation_cost_cents || 0));

  return (
    <div className="flex flex-col gap-6">
      <ContentBox className="p-4 flex gap-4">
        <div className="flex flex-col gap-2">
          <LabeledValue
            label="ID"
            value={<SingleLineCodeBlock code={backtest.id} />}
          />
          <LabeledValue
            label="Backtest type"
            value={
              <Badge theme={BadgeTheme.Info} size={BadgeSize.Small}>
                {backtest.backtest_type}
              </Badge>
            }
          />
          <LabeledValue
            label="Dataset ID"
            value={
              backtest.dataset_id ? (
                <SingleLineCodeBlock code={backtest.dataset_id} />
              ) : (
                "–"
              )
            }
          />
          <LabeledValue
            label="State"
            value={<BacktestShowState backtest={backtest} />}
          />
          <LabeledValue
            label="Total cost"
            value={`$${(totalCost / 100.0).toFixed(2)}`}
          />
          {backtest.investigation_plan_type && (
            <LabeledValue
              label="Investigation plan type"
              value={formatValue(backtest.investigation_plan_type)}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <LabeledValue label="Credit" value={formatValue(backtest.credit)} />
          <LabeledValue label="Notes" value={formatValue(backtest.notes)} />
          <LabeledValue
            label="Created"
            value={formatValue(backtest.created_at)}
          />
          <LabeledValue
            label="Model overrides"
            value={<ModelOverrides modelOverrides={backtest.model_overrides} />}
          />
          {backtest.backtest_type ===
            EvaluationBacktestBacktestTypeEnum.Investigation && (
            <LabeledValue
              label="Tuneables Overrides"
              value={
                <TuneableParameters parameters={backtest.tuneables_overrides} />
              }
            />
          )}
          {backtest.compare_with_backtest_id && (
            <LabeledValue
              label="Compared with"
              value={
                <Button
                  analyticsTrackingId={null}
                  size={BadgeSize.ExtraSmall}
                  href={`/workbench/backtests/${backtest.compare_with_backtest_id}`}
                  openInNewTab
                  icon={IconEnum.ExternalLink}
                >
                  {comparedWithData?.backtest?.created_at
                    ? new Date(
                        comparedWithData.backtest.created_at,
                      ).toLocaleString()
                    : backtest.compare_with_backtest_id}
                </Button>
              }
            />
          )}
          <LabeledValue
            label="Errors"
            value={<BacktestShowErrors entries={entries} />}
          />
        </div>
      </ContentBox>
      <BacktestEntriesList
        backtest={backtest}
        entries={entries}
        comparedWithData={comparedWithData}
      />
    </div>
  );
};

export const BacktestShowState = ({
  backtest,
}: {
  backtest: EvaluationBacktest;
}) => {
  let badgeTheme = BadgeTheme.Info;

  switch (backtest.state) {
    case "pending":
      badgeTheme = BadgeTheme.Tertiary;
      break;
    case "complete":
      badgeTheme = BadgeTheme.Success;
      break;
    case "paused":
      badgeTheme = BadgeTheme.Warning;
      break;
    case "error":
      badgeTheme = BadgeTheme.Error;
      break;
  }

  return (
    <Badge theme={badgeTheme} size={BadgeSize.ExtraSmall}>
      {backtest.state}
    </Badge>
  );
};

export const BacktestShowErrors = ({
  entries,
}: {
  entries: EvaluationBacktestEntry[];
}) => {
  const errors = entries.flatMap((e) => e.errors || []);

  if (errors.length === 0) {
    return <Badge theme={BadgeTheme.Success}>No errors</Badge>;
  }

  return (
    <Tooltip
      content={
        <div className="flex flex-col gap-2">
          {errors.map((e, idx) => (
            <div key={idx} className="">
              {e}
            </div>
          ))}
        </div>
      }
    >
      <Badge theme={BadgeTheme.Warning} icon={IconEnum.Warning}>
        {errors.length} errors
      </Badge>
    </Tooltip>
  );
};

const BacktestResumeModal = ({
  backtest,
  entries,
  onClose,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  onClose: () => void;
}) => {
  const toast = useToast();

  const outstanding = entries.filter(
    (e) => e.evaluation_scorecard == null,
  ).length;

  const formMethods = useForm<AIStaffResumeEvaluationBacktestRequestBody>({
    defaultValues: {
      credit: min([10, outstanding]),
    },
  });

  const { trigger: resumeBacktest, isMutating: saving } = useMutationV2(
    async (
      apiClient,
      requestBody: AIStaffResumeEvaluationBacktestRequestBody,
    ) => {
      await apiClient.aIStaffResumeEvaluationBacktest({
        id: backtest.id,
        resumeEvaluationBacktestRequestBody: {
          ...requestBody,
          credit: Number(requestBody.credit),
        },
      });
      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationBacktest", { id: backtest.id }),
      ],

      onSuccess: () => {
        onClose();
        toast({
          title: "Resumed backtest",
          description: `Processing ${formMethods.getValues().credit} entries`,
          theme: ToastTheme.Success,
        });
      },

      onError: () => {
        toast({
          title: "Failed to resume backtest",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const handleResume = (
    requestBody: AIStaffResumeEvaluationBacktestRequestBody,
  ) => {
    resumeBacktest(requestBody);
  };

  return (
    <Form.Modal
      onClose={onClose}
      title="Resume backtest"
      analyticsTrackingId="resume-backtest"
      formMethods={formMethods}
      onSubmit={handleResume}
      footer={
        <ModalFooter
          confirmButtonText="Resume"
          saving={saving}
          disabled={!outstanding}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <Callout theme={CalloutTheme.Info}>
        There are <span className="text-sm-bold">{outstanding}</span> out of{" "}
        <span className="text-sm-bold">{entries.length}</span> entries left to
        process.
      </Callout>
      {!!outstanding && (
        <InputV2
          type={InputType.Number}
          required
          autoFocus
          name="credit"
          formMethods={formMethods}
          label="Credit"
          helptext={
            "How many entries should be processed before pausing and asking a human to review."
          }
        />
      )}
    </Form.Modal>
  );
};

const BacktestPauseModal = ({
  backtest,
  entries,
  onClose,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  onClose: () => void;
}) => {
  const toast = useToast();

  const outstanding = entries.filter(
    (e) => e.evaluation_scorecard == null,
  ).length;

  const { trigger: pauseBacktest, isMutating: saving } = useMutationV2(
    async (apiClient) => {
      await apiClient.aIStaffPauseEvaluationBacktest({
        id: backtest.id,
      });
      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationBacktest", { id: backtest.id }),
      ],

      onSuccess: () => {
        onClose();
        toast({
          title: "Paused backtest",
          theme: ToastTheme.Success,
        });
      },

      onError: () => {
        toast({
          title: "Failed to pause backtest",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <ConfirmationDialog
      title="Pause backtest"
      confirmButtonText="Pause"
      onCancel={onClose}
      isOpen
      onConfirm={() => pauseBacktest({})}
      saving={saving}
    >
      <div className="flex flex-col gap-4">
        <div className="text-sm">
          <p>
            There are <span className="text-sm-bold">{outstanding}</span> out of{" "}
            <span className="text-sm-bold">{entries.length}</span> entries left
            to process.
          </p>
          <p>{`This will transition the backtest to 'paused'. Any in-flight work will still be completed.`}</p>
        </div>
      </div>
    </ConfirmationDialog>
  );
};

const BacktestRunningBanner = ({
  backtest,
  entries,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
}) => {
  const { total, processed } = getBacktestEntryCounts(entries);
  if (backtest.state === "running") {
    return (
      <Banner
        theme={BannerTheme.Info}
        className="bg-blue-surface text-blue-800"
        iconNode={<Spinner className="text-yellow-content" />}
      >
        This backtest is currently running.{" "}
        <span className="font-semibold">{processed}</span> of{" "}
        <span className="font-semibold">{total}</span> entries have been
        processed so far
      </Banner>
    );
  }

  if (backtest.state === "paused") {
    return (
      <Banner theme={BannerTheme.Warning}>
        This backtest is currently paused.{" "}
        <span className="font-semibold">{processed}</span> of{" "}
        <span className="font-semibold">{total}</span> entries have been
        processed so far
      </Banner>
    );
  }

  return null;
};

const ModelOverrides = ({
  modelOverrides,
}: {
  modelOverrides: { [key: string]: string };
}) => {
  if (!modelOverrides || Object.keys(modelOverrides).length === 0) {
    return <>–</>;
  }

  const overrideItems = Object.entries(modelOverrides).map(
    ([prompt, model]) => (
      <div key={prompt} className="flex flex-col">
        <span className="text-xs text-content-tertiary">{prompt}</span>
        <span className="text-sm font-medium">{model}</span>
      </div>
    ),
  );

  return <div className="flex flex-col gap-2">{overrideItems}</div>;
};

export const getBacktestEntryCounts = (entries: EvaluationBacktestEntry[]) => {
  return {
    total: entries.length,
    processed: entries.filter((e) => e.evaluation_scorecard != null).length,
  };
};
