import { User } from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import Markdown from "react-markdown";
import { useIdentity } from "src/contexts/IdentityContext";

import { ThreadedCopilotInteraction } from "./CopilotThreadPage";

export const CopilotChatContext = ({
  messages,
  onlyShowOriginalMessage,
}: {
  messages: ThreadedCopilotInteraction[];
  onlyShowOriginalMessage?: boolean;
}) => {
  return (
    <div className="h-full flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto min-h-0">
        <div className="flex flex-col space-y-4">
          {onlyShowOriginalMessage ? (
            <ThreadContainer
              message={messages
                .filter((msg) => msg.isOriginalMessage)
                .find((msg) => msg.isOriginalMessage)}
            />
          ) : (
            messages.map((message) => (
              <ThreadContainer key={message.id} message={message} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const ThreadContainer = ({
  message,
}: {
  message?: ThreadedCopilotInteraction;
}) => {
  const [showReplies, setShowReplies] = useState(true);

  if (!message) {
    return null;
  }

  const hasReplies = message.replies && message.replies.length > 0;

  return (
    <div className="flex flex-col space-y-2">
      <Message message={message} />
      {hasReplies && (
        <div className="pl-12">
          <button
            onClick={() => setShowReplies(!showReplies)}
            className="text-xs text-slate-500 hover:text-slate-700 flex items-center gap-1 mb-2"
          >
            <Icon
              id={showReplies ? IconEnum.ChevronDown : IconEnum.ChevronRight}
            />
            {message.replies?.length} replies
          </button>
          {showReplies && (
            <div className="border-l-2 border-slate-200 pl-4 space-y-4">
              {message.replies?.map((reply) => (
                <Message key={reply.id} message={reply} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Message = ({ message }: { message: ThreadedCopilotInteraction }) => {
  const { identity } = useIdentity();

  const icmMessagesQuery = (date: Date) =>
    `select * from incident_channel_messages where date_trunc('second', slack_message_sent_at) = '${date.toISOString()}' and organisation_id = '${
      identity.organisation_id
    }'`;

  return (
    <div className="flex gap-3 group">
      <Avatar user={message.user} />
      <div className="flex-1 min-w-0 space-y-1">
        <div className="flex items-center gap-2">
          <span className="font-medium text-sm">
            {message.is_copilot
              ? "Copilot"
              : message.user
              ? message.user.name
              : "Unknown user"}
          </span>
          <Tooltip content={icmMessagesQuery(message.sent_at)}>
            <span className="text-xs text-slate-500">
              {formatDistanceToNow(message.sent_at, { addSuffix: true })}
            </span>
          </Tooltip>
          {message.isOriginalMessage && (
            <Tooltip content="This is the message that triggered this interaction.">
              <div className="flex items-center">
                <Icon
                  id={IconEnum.Message}
                  className="text-slate-400"
                  size={IconSize.Small}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="mt-1 text-sm break-words">
          <Markdown>{message.content}</Markdown>
        </div>
        <div className="space-x-1">
          {message.reactions.length > 0 &&
            message.reactions.map((reaction) => (
              <Badge key={reaction} theme={BadgeTheme.Tertiary}>
                :{reaction}:
              </Badge>
            ))}
        </div>
      </div>
    </div>
  );
};

const Avatar = ({ user }: { user?: User & { avatar_url?: string } }) => {
  const isBot = !user;

  if (isBot) {
    return (
      <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 bg-blue-100">
        <Icon id={IconEnum.Robot} className="text-blue-600" />
      </div>
    );
  }

  if (user.avatar_url) {
    return (
      <img
        src={user.avatar_url}
        alt={`${user.name}'s avatar`}
        className="w-8 h-8 rounded-full flex-shrink-0 object-cover"
      />
    );
  }

  return (
    <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 bg-slate-100">
      <Icon id={IconEnum.User} className="text-slate-600" />
    </div>
  );
};
