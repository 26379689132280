"use client";

import {
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncidentLink,
  StatusPageContentStatusSummaryWorstComponentStatusEnum,
} from "@incident-io/api";
import {
  hasSameDay,
  STATUS_SEVERITY,
  toDateString,
} from "@incident-io/status-page-ui";
import cx from "classnames";
import { scaleBand } from "d3";
import _, { clamp } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useMemoCompare } from "../../use-memo-compare";
import { useResize } from "../../use-resize";
import { TooltipBubble } from "../Tooltip";
import { DayOfIncidentsTooltipContents } from "../Tooltip/DayOfIncidentsTooltipContents";
import { Day } from "./helpers";
import styles from "./UptimeChart.module.scss";

type UptimeChartProps = {
  days: Array<Day>;
  dataAvailableSince: DateTime | null;
  incidentLinks: Array<StatusPageContentIncidentLink>;
  width: number;
  height: number;
};

export const UptimeChartDefaults: Omit<
  UptimeChartProps,
  "incidentLinks" | "days" | "dataAvailableSince"
> = {
  width: 668, // width of the chart, in pixels
  height: 16, // height of a day, in pixels
};

// This renders an uptime chart of coloured bars showing when this component was
// impacted and how.
//
// It uses React to render an initial version of the chart while in the server,
// as getting d3 to render in Vercel's edge is something I can't figure out how
// to work. The compromise is an initial render of the bars alone – ignoring
// interactivity like tooltips, because that's irrelevant for non-JS – and allow
// d3 to take over after hydration.
export const UptimeChart = ({
  days,
  dataAvailableSince: dataAvailableSinceExact,
  incidentLinks,
  width,
  height,
}: UptimeChartProps): React.ReactElement => {
  const [mounted, setMounted] = useState(false);

  const container = useRef<HTMLDivElement>(null);

  // This is only used on the client to render the tooltips
  const containerWidth = useResize(container).width ?? 700;

  const getX = useMemo(() => buildXScaler({ width, days }), [width, days]);
  // We render the SVG with a constant-size viewbox (width), but on smaller
  // screens its container gets scaled down (containerWidth).
  //
  // Because the tooltip is absolute-positioned relative to the container
  // rather than the SVG viewbox, we need to use a different scaler here.
  const svgGetX = useMemo(
    () => buildXScaler({ width: containerWidth, days }),
    [containerWidth, days],
  );

  const [hoveringDate, setHoveringDate] = useState<{
    day: Day;
    active: boolean;
  }>({ active: false, day: days[0] });
  // This lets us keep rendering the tooltip in the right location while it fades out
  const [tooltipLeft, setTooltipLeft] = useState<number>(0);

  // If data became available at any time during a day, we treat the whole day
  // as "having data", otherwise things get confusing.
  const dataAvailableSince = useMemoCompare(
    dataAvailableSinceExact ? dataAvailableSinceExact.startOf("day") : null,
    (prev, next) =>
      prev != null && next != null ? prev.equals(next) : prev === next,
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const x = svgGetX(hoveringDate.day);
    // The tooltip itself is 256px wide, so ideally the `left` is the x-128 (to
    // center the tooltip over the blob). However, we never want the tooltip to
    // leave the container, so clamp the left between 0 (the left edge) and the
    // container width - 256 (which puts it on the right edge)
    setTooltipLeft(clamp(x - 128, 0, containerWidth - 256));
  }, [containerWidth, svgGetX, hoveringDate]);

  const incidents = Object.entries(
    _.groupBy(hoveringDate.day.impacts, "status_page_incident_id"),
  ).flatMap(([incident_id, impacts]) => {
    const link = incidentLinks.find((link) => link.id === incident_id);
    if (!link) return [];

    const worstStatus =
      _.maxBy(
        impacts.map(({ status }) => status),
        (status) => STATUS_SEVERITY[status],
      ) || StatusPageContentComponentImpactStatusEnum.FullOutage;

    return [
      {
        incident_id,
        name: link.name,
        status:
          worstStatus as unknown as StatusPageContentStatusSummaryWorstComponentStatusEnum,
      },
    ];
  });

  let scrollY = 0;
  let body: HTMLElement | undefined;
  if (typeof window !== "undefined") {
    scrollY = window.scrollY;
  }
  if (typeof document !== "undefined") body = document.body;

  const hasData = (date: DateTime) => {
    if (!dataAvailableSince) return true;

    return date >= dataAvailableSince;
  };

  return (
    <div
      className={"text-slate-500"}
      ref={container}
      onMouseLeave={() =>
        setHoveringDate((prev) => Object.assign({}, prev, { active: false }))
      }
    >
      <>
        {mounted &&
          body &&
          createPortal(
            <TooltipBubble
              // HACK HACK HACK
              centerOfTarget={
                tooltipLeft +
                128 +
                (container.current?.getBoundingClientRect().left || 0)
              }
              active={hoveringDate.active}
              // top matches the height
              containerClassName="!w-[256px] top-[15px]"
              yOffset={
                (container.current?.getBoundingClientRect().top || 0) +
                scrollY +
                15
              }
            >
              <DayOfIncidentsTooltipContents
                date={hoveringDate.day.date}
                hasData={
                  dataAvailableSince == null ||
                  hoveringDate.day.date >= dataAvailableSince
                }
                incidents={incidents}
              />
            </TooltipBubble>,
            body,
          )}

        <svg
          width={"100%"}
          height={height}
          viewBox={[0, 0, width, height].join(" ")}
          className="mb-1"
        >
          {days.map((day) => (
            <rect
              key={toDateString(day.date)}
              x={getX(day)}
              y={0}
              width={5}
              height={height}
              rx={1}
              ry={1}
              onMouseOver={() => setHoveringDate({ active: true, day })}
              className={cx(
                "transition",
                hasData(day.date)
                  ? getPillClass(day.severity)
                  : styles.pillNoData,
                // When this date is showing a tooltip, keep it in the
                // hover-colour
                hoveringDate.active &&
                  hasSameDay(hoveringDate.day.date, day.date) &&
                  styles.active,
              )}
            />
          ))}
        </svg>
      </>
    </div>
  );
};

// Use d3 to decide placement of rectangles across the chart.
const buildXScaler = ({
  width,
  days,
}: {
  width: number;
  days: Day[];
}): ((day: Day) => number) => {
  const scaleX = scaleBand()
    .domain(days.map((day) => day.date.toString()))
    .range([0, width]);

  return (day: Day): number => {
    const x = scaleX(day.date.toString());
    if (!x) {
      return 0;
    }

    return x;
  };
};

const getPillClass = (
  status: StatusPageContentComponentImpactStatusEnum,
): string => {
  switch (status) {
    case StatusPageContentComponentImpactStatusEnum.DegradedPerformance:
      return styles.pillDegradedPerformance;
    case StatusPageContentComponentImpactStatusEnum.PartialOutage:
      return styles.pillPartialOutage;
    case StatusPageContentComponentImpactStatusEnum.FullOutage:
      return styles.pillFullOutage;
    case StatusPageContentComponentImpactStatusEnum.UnderMaintenance:
      return styles.pillUnderMaintenance;
    default:
      return styles.pillOperational;
  }
};
