/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianWidgetStatusIncident,
    AtlassianWidgetStatusIncidentFromJSON,
    AtlassianWidgetStatusIncidentFromJSONTyped,
    AtlassianWidgetStatusIncidentToJSON,
} from './AtlassianWidgetStatusIncident';
import {
    AtlassianWidgetStatusPage,
    AtlassianWidgetStatusPageFromJSON,
    AtlassianWidgetStatusPageFromJSONTyped,
    AtlassianWidgetStatusPageToJSON,
} from './AtlassianWidgetStatusPage';

/**
 * 
 * @export
 * @interface StatusPageContentAtlassianWidgetIncidentsResponseBody
 */
export interface StatusPageContentAtlassianWidgetIncidentsResponseBody {
    /**
     * List of incidents, both active and recently resolved
     * @type {Array<AtlassianWidgetStatusIncident>}
     * @memberof StatusPageContentAtlassianWidgetIncidentsResponseBody
     */
    incidents: Array<AtlassianWidgetStatusIncident>;
    /**
     * 
     * @type {AtlassianWidgetStatusPage}
     * @memberof StatusPageContentAtlassianWidgetIncidentsResponseBody
     */
    page: AtlassianWidgetStatusPage;
}

export function StatusPageContentAtlassianWidgetIncidentsResponseBodyFromJSON(json: any): StatusPageContentAtlassianWidgetIncidentsResponseBody {
    return StatusPageContentAtlassianWidgetIncidentsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentAtlassianWidgetIncidentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentAtlassianWidgetIncidentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidents': ((json['incidents'] as Array<any>).map(AtlassianWidgetStatusIncidentFromJSON)),
        'page': AtlassianWidgetStatusPageFromJSON(json['page']),
    };
}

export function StatusPageContentAtlassianWidgetIncidentsResponseBodyToJSON(value?: StatusPageContentAtlassianWidgetIncidentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidents': ((value.incidents as Array<any>).map(AtlassianWidgetStatusIncidentToJSON)),
        'page': AtlassianWidgetStatusPageToJSON(value.page),
    };
}

