/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReplicaSource
 */
export interface ScheduleReplicaSource {
    /**
     * The layer within the rotation to replicate
     * @type {string}
     * @memberof ScheduleReplicaSource
     */
    layer_id: string;
    /**
     * The rotation within the schedule to replicate
     * @type {string}
     * @memberof ScheduleReplicaSource
     */
    rotation_id: string;
}

export function ScheduleReplicaSourceFromJSON(json: any): ScheduleReplicaSource {
    return ScheduleReplicaSourceFromJSONTyped(json, false);
}

export function ScheduleReplicaSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplicaSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'layer_id': json['layer_id'],
        'rotation_id': json['rotation_id'],
    };
}

export function ScheduleReplicaSourceToJSON(value?: ScheduleReplicaSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'layer_id': value.layer_id,
        'rotation_id': value.rotation_id,
    };
}

