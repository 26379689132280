import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceAzureMonitorSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>Go to the Azure portal and open the Azure Monitor service.</p>
      <p>
        Click on <strong>Alerts</strong> and then <strong>Action Groups</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Click on <strong>Create</strong> and fill in the details as follows:
          </>
        }
        blocks={[
          {
            title: "Action Group Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "Display Name",
            code: `${alertSourceConfig.name.replaceAll(" ", "_").slice(0, 11)}`,
          },
        ]}
      />
      <p>
        Click on <strong>Actions</strong> and then <strong>Add action</strong>
      </p>
      <div className="overflow-hidden pb-2">
        <SetupInfoSingleLineBlocks
          intro={
            <>
              Select <strong>Webhook</strong> as the action type and fill in the
              details as follows:
            </>
          }
          blocks={[
            {
              title: "Action Type",
              code: `Webhook`,
            },
            {
              title: "URI",
              code: `${publicApiUrl()}/v2/alert_events/azure_monitor/${
                alertSourceConfig.id
              }`,
            },
            {
              title: "Enable common alert schema",
              code: `Yes`,
            },
          ]}
        />
        <p>
          You can also send us additional metadata by appending query parameters
          to the URI:
          <SingleLineCodeBlock
            className={"my-2"}
            code={`?team=engineering&service=api`}
          />
        </p>
      </div>
      <p>Then create your action group in Azure.</p>
      <p>
        You can test the webhook by clicking on the <strong>Test</strong> button
        when viewing the action group in the Azure portal.
      </p>
      <p>
        Then, create your action group and assign it to Alert Rules in Azure,
        you&rsquo;ll be able to see alerts in incident.io.
      </p>
    </SetupInfoNumberedList>
  );
};
