import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconBadge,
  IconEnum,
  IconSize,
  OrgAwareLink,
} from "@incident-ui";
import { Card, CardProps } from "@incident-ui/Card/Card";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const AIFeatureCard = ({
  disabled,
  icon,
  to,
  ...cardProps
}: {
  disabled?: boolean;
  icon: IconEnum;
  to: string;
} & CardProps): React.ReactElement => {
  return (
    <OrgAwareLink to={to}>
      <Card
        clickable
        {...cardProps}
        headerAccessory={
          disabled ? (
            <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Small}>
              Disabled
            </Badge>
          ) : undefined
        }
        iconOverride={
          <IconBadge
            size={IconSize.Medium}
            icon={icon}
            color={
              disabled ? ColorPaletteEnum.SlateOnWhite : ColorPaletteEnum.Red
            }
            className={tcx(
              disabled
                ? "text-content-tertiary svg-no-gradient"
                : "text-alarmalade-500 border-none",
            )}
          />
        }
      />
    </OrgAwareLink>
  );
};

export const AIFeatureCardGrid = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">{children}</div>
  );
};

export const AIPrivacyButton = () => {
  return (
    <Button
      theme={ButtonTheme.Tertiary}
      analyticsTrackingId="ai-privacy-guidance"
      openInNewTab
      href="https://trust.incident.io/resources?s=yyxepsc6m1tkxjigedpl&name=ai-privacy-guidance"
    >
      AI Privacy Guidance
    </Button>
  );
};
