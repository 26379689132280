export const Logo = ({
  className,
}: {
  className: string;
}): React.ReactElement => {
  return (
    <>
      <svg
        width="82"
        height="20"
        viewBox="0 0 82 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16185 11.3994V13.3061C1.87817 12.7611 0.183594 10.752 0.183594 8.35688C0.183594 6.97598 0.767914 5.88044 1.66158 4.80574C2.40263 3.91456 4.23245 1.5545 4.80843 0.252207C4.94827 -0.063957 5.3048 -0.0517392 5.45855 0.11814C5.95039 0.661568 6.5566 1.84843 6.75488 3.1947C6.79528 3.469 6.81191 3.69297 6.82512 3.87089C6.85347 4.25269 6.86607 4.42243 7.06403 4.42243C7.37506 4.42243 7.57853 3.95083 7.62325 3.46404C7.65191 3.15213 7.96134 3.04773 8.17473 3.15213C9.20099 3.65424 10.2482 6.04429 10.5014 7.21716C10.5808 7.58496 10.6326 7.96505 10.6326 8.35688C10.6326 10.7466 8.9456 12.7521 6.66961 13.3025V11.3994H4.16185ZM5.4081 10.6394C6.21067 10.6394 6.86127 10.0236 6.86127 9.26392C6.86127 8.0523 5.9679 7.18649 5.52276 6.92331C5.49269 6.90553 5.47766 6.89664 5.43675 6.89775C5.4068 6.89856 5.36261 6.9173 5.34122 6.93825C5.31202 6.96686 5.30427 6.99943 5.28879 7.06456C5.20989 7.39645 4.96149 7.63309 4.70008 7.88212C4.33985 8.22529 3.95493 8.592 3.95493 9.26392C3.95493 10.0236 4.60553 10.6394 5.4081 10.6394Z" fill="currentColor"/>
        <path d="M13.1168 11.1836C12.0055 11.1836 11.1637 11.5876 10.7091 12.2105V11.4193H8.18359V19.8367H10.7091V15.2408C10.7091 14.0456 11.3489 13.5068 12.2749 13.5068C13.083 13.5068 13.7397 13.995 13.7397 15.0387V19.8367H16.2652V14.6684C16.2652 12.3957 14.8172 11.1836 13.1168 11.1836Z" fill="currentColor"/>
        <path d="M21.6139 19.9999C23.2595 19.9999 24.6868 19.1483 25.409 17.8459L23.2092 16.5936C22.9405 17.178 22.3192 17.5286 21.5803 17.5286C20.4889 17.5286 19.6829 16.7439 19.6829 15.5918C19.6829 14.4396 20.4889 13.6549 21.5803 13.6549C22.3192 13.6549 22.9237 14.0054 23.2092 14.5899L25.409 13.3209C24.6868 12.0352 23.2428 11.1836 21.6139 11.1836C19.0616 11.1836 17.1641 13.1038 17.1641 15.5918C17.1641 18.0797 19.0616 19.9999 21.6139 19.9999Z" fill="currentColor"/>
        <path d="M27.3266 10.6979C28.1487 10.6979 28.8368 10.0091 28.8368 9.18579C28.8368 8.36268 28.1487 7.67383 27.3266 7.67383C26.5043 7.67383 25.8164 8.36268 25.8164 9.18579C25.8164 10.0091 26.5043 10.6979 27.3266 10.6979ZM26.0681 19.8371H28.5851V11.4371H26.0681V19.8371Z" fill="currentColor"/>
        <path d="M36.2103 8.08203V12.205C35.6596 11.5708 34.8417 11.1868 33.7234 11.1868C31.537 11.1868 29.7344 13.1064 29.7344 15.5936C29.7344 18.0807 31.537 20.0004 33.7234 20.0004C34.8417 20.0004 35.6596 19.6165 36.2103 18.9821V19.7667H38.714V8.08203H36.2103ZM34.2242 17.6301C33.0725 17.6301 32.238 16.8455 32.238 15.5936C32.238 14.3417 33.0725 13.5571 34.2242 13.5571C35.3758 13.5571 36.2103 14.3417 36.2103 15.5936C36.2103 16.8455 35.3758 17.6301 34.2242 17.6301Z" fill="currentColor"/>
        <path d="M42.4462 16.5936H48.4093C48.4765 16.2763 48.5101 15.9425 48.5101 15.5918C48.5101 13.0704 46.696 11.1836 44.2436 11.1836C41.5895 11.1836 39.7754 13.1038 39.7754 15.5918C39.7754 18.0797 41.5558 19.9999 44.4283 19.9999C46.0241 19.9999 47.2671 19.4155 48.0902 18.2801L46.0744 17.1279C45.7385 17.4952 45.1506 17.7624 44.4619 17.7624C43.5381 17.7624 42.7654 17.4618 42.4462 16.5936ZM42.3958 14.7235C42.631 13.8719 43.2692 13.4044 44.2267 13.4044C44.9826 13.4044 45.7385 13.755 46.0072 14.7235H42.3958Z" fill="currentColor"/>
        <path d="M54.5054 11.1836C53.3942 11.1836 52.5524 11.5876 52.0978 12.2105V11.4193H49.5723V19.8367H52.0978V15.2408C52.0978 14.0456 52.7376 13.5068 53.6636 13.5068C54.4717 13.5068 55.1284 13.995 55.1284 15.0387V19.8367H57.6539V14.6684C57.6539 12.3957 56.2059 11.1836 54.5054 11.1836Z" fill="currentColor"/>
        <path d="M63.9377 13.815V11.4048H62.2207V9.06152L59.7204 9.81477V11.4048H58.3867V13.815H59.7204V16.7775C59.7204 19.1208 60.6706 20.1083 63.9377 19.7735V17.4972C62.8375 17.5642 62.2207 17.4972 62.2207 16.7775V13.815H63.9377Z" fill="currentColor"/>
        <path d="M66.5919 20.0001C67.4632 20.0001 68.1837 19.2796 68.1837 18.4082C68.1837 17.537 67.4632 16.8164 66.5919 16.8164C65.7205 16.8164 65 17.537 65 18.4082C65 19.2796 65.7205 20.0001 66.5919 20.0001Z" fill="currentColor"/>
        <path d="M70.5923 10.6979C71.4144 10.6979 72.1024 10.0091 72.1024 9.18579C72.1024 8.36268 71.4144 7.67383 70.5923 7.67383C69.77 7.67383 69.082 8.36268 69.082 9.18579C69.082 10.0091 69.77 10.6979 70.5923 10.6979ZM69.3337 19.8371H71.8508V11.4371H69.3337V19.8371Z" fill="currentColor"/>
        <path d="M77.3669 19.9999C79.8348 19.9999 81.8159 18.0797 81.8159 15.5918C81.8159 13.1038 79.8348 11.1836 77.3669 11.1836C74.899 11.1836 72.918 13.1038 72.918 15.5918C72.918 18.0797 74.899 19.9999 77.3669 19.9999ZM77.3669 17.562C76.2757 17.562 75.4363 16.7773 75.4363 15.5918C75.4363 14.4062 76.2757 13.6215 77.3669 13.6215C78.4582 13.6215 79.2976 14.4062 79.2976 15.5918C79.2976 16.7773 78.4582 17.562 77.3669 17.562Z" fill="currentColor"/>
        <path d="M4.10156 19.8367H6.63217V13.3877C6.63217 13.3877 4.10156 13.8404 4.10156 16.2553V19.8367Z" fill="currentColor"/>
      </svg>
    </>
  );
};
