/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntry,
    CatalogEntryFromJSON,
    CatalogEntryFromJSONTyped,
    CatalogEntryToJSON,
} from './CatalogEntry';
import {
    TeamResource,
    TeamResourceFromJSON,
    TeamResourceFromJSONTyped,
    TeamResourceToJSON,
} from './TeamResource';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {CatalogEntry}
     * @memberof Team
     */
    catalog_entry: CatalogEntry;
    /**
     * Description of the team
     * @type {string}
     * @memberof Team
     */
    description?: string;
    /**
     * Unique ID of the team
     * @type {string}
     * @memberof Team
     */
    id: string;
    /**
     * Members of the team
     * @type {Array<User>}
     * @memberof Team
     */
    members: Array<User>;
    /**
     * Name of the team
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * Resources associated with the team
     * @type {Array<TeamResource>}
     * @memberof Team
     */
    resources: Array<TeamResource>;
}

export function TeamFromJSON(json: any): Team {
    return TeamFromJSONTyped(json, false);
}

export function TeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Team {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry': CatalogEntryFromJSON(json['catalog_entry']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'members': ((json['members'] as Array<any>).map(UserFromJSON)),
        'name': json['name'],
        'resources': ((json['resources'] as Array<any>).map(TeamResourceFromJSON)),
    };
}

export function TeamToJSON(value?: Team | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry': CatalogEntryToJSON(value.catalog_entry),
        'description': value.description,
        'id': value.id,
        'members': ((value.members as Array<any>).map(UserToJSON)),
        'name': value.name,
        'resources': ((value.resources as Array<any>).map(TeamResourceToJSON)),
    };
}

