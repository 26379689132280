/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteChannelTarget,
    AlertRouteChannelTargetFromJSON,
    AlertRouteChannelTargetFromJSONTyped,
    AlertRouteChannelTargetToJSON,
} from './AlertRouteChannelTarget';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface AlertRouteChannelConfig
 */
export interface AlertRouteChannelConfig {
    /**
     * The conditions that must be met for this channel config to be used
     * @type {Array<ConditionGroup>}
     * @memberof AlertRouteChannelConfig
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * 
     * @type {AlertRouteChannelTarget}
     * @memberof AlertRouteChannelConfig
     */
    ms_teams_targets?: AlertRouteChannelTarget;
    /**
     * 
     * @type {AlertRouteChannelTarget}
     * @memberof AlertRouteChannelConfig
     */
    slack_targets?: AlertRouteChannelTarget;
}

export function AlertRouteChannelConfigFromJSON(json: any): AlertRouteChannelConfig {
    return AlertRouteChannelConfigFromJSONTyped(json, false);
}

export function AlertRouteChannelConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteChannelConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'ms_teams_targets': !exists(json, 'ms_teams_targets') ? undefined : AlertRouteChannelTargetFromJSON(json['ms_teams_targets']),
        'slack_targets': !exists(json, 'slack_targets') ? undefined : AlertRouteChannelTargetFromJSON(json['slack_targets']),
    };
}

export function AlertRouteChannelConfigToJSON(value?: AlertRouteChannelConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'ms_teams_targets': AlertRouteChannelTargetToJSON(value.ms_teams_targets),
        'slack_targets': AlertRouteChannelTargetToJSON(value.slack_targets),
    };
}

