export enum JsonNodeType {
  // Flat is a special case where we have a single
  // value rather than a key-value pair.
  Flat = "flat",
  Object = "object",
  Array = "array",
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Null = "null",
  Undefined = "undefined",
}

export type JSONPrimitive = string | number | boolean | null | undefined;

// A JsonTreeNode is a representation of a key-value pair in a JSON object.
// In the case of objects/arrays, the children contain its descendants.
export type JsonTreeNode = {
  // Type is the type of the value
  type: JsonNodeType;
  // Key is the key of the key-value pair. In the case of arrays
  // this is "<index>" (eg "0")
  key: string;
  // Path is the path to the node from the root, e.g. `root.foo.bar[0]`
  path: string;
  // Value is the value of the key-value pair.
  // If the value is an object or an array, `value` is null
  // and `children` contains the contents of the object or array.
  value: JSONPrimitive;
  children: JsonTreeNode[];
  // Expanded is only used for objects and arrays. It indicates whether
  // the object or array is expanded or collapsed in the UI, and controls
  // whether the node's children are included when flattening the tree.
  isExpanded?: boolean;
};

// JsonNode is a flattened representation of a JsonTreeNode
// Each node renders a single line in the UI
// These do not have children, and have their depth in the
// tree attached to them.
export type JsonNode = Omit<JsonTreeNode, "children"> & {
  // Depth is the depth of the node in the tree:
  // eg root has depth 0, root.child.grandChild has depth 2.
  depth: number;

  // numChildren is the number of immediate children the node has.
  numChildren: number;
};

const MAX_JSON_LENGTH = 20 * 1024; // 20 kB

// getListOfJsonNodes takes a javascript object, builds a tree
// from it and then flattens that tree for rendering.
//
// We're _always_ going to want to flatten this tree immediately after creating it. Why do we make it then?
// Doing this in two steps makes handling expanding/collapsing nodes easier. We always construct the full tree,
// but then when flattening it, we only traverse the children of expanded nodes.
export const getListOfJsonNodes = (
  jsonStr: string,
  toggledNodes: Set<string>,
  defaultExpanded: boolean,
): JsonNode[] | "too-large" => {
  if (jsonStr.length > MAX_JSON_LENGTH) {
    return "too-large";
  }

  const jsonObj = JSON.parse(jsonStr);
  // If our payload is a single primitive, we want to create a single node
  // that renders it correctly.
  if (typeof jsonObj !== "object" || jsonObj == null) {
    return [
      {
        type: JsonNodeType.Flat,
        key: "",
        path: "$",
        value: jsonObj as JSONPrimitive,
        numChildren: 0,
        depth: 0,
      },
    ];
  }

  // Otherwise, we proceed with building the tree
  const tree = createJsonTree(jsonObj, toggledNodes, defaultExpanded);
  const flattenedTree = flattenJsonTree(tree);
  // The slice removes the root node, which is a convenience node that
  // we don't want to render. We'll manually render the opening/closing
  // brackets in the JSONPreviewLines component.
  return flattenedTree.slice(1);
};

// createJsonTree takes a javascript object and builds a tree of JsonNodes, whose parent
// is a single dummy "root" node.
// The root is only there to make constructing and walking the tree convenient:
// taking one node and recursively looking at its children is easier/cleaner than doing that for a list of nodes in order.
export const createJsonTree = (
  jsonObj: object,
  toggledNodes: Set<string>,
  defaultExpanded: boolean,
): JsonTreeNode => {
  return {
    key: "root",
    type: JsonNodeType.Object,
    value: null,
    isExpanded: true, // you can't collapse the root node
    path: "$",
    children: Object.entries(jsonObj).map(([key, value]): JsonTreeNode => {
      const itemPath = getPath(key, "");
      const children = getChildren(
        value,
        itemPath,
        toggledNodes,
        defaultExpanded,
      );
      return {
        key,
        type: getJSONValueType(value),
        value: getValue(value),
        children,
        path: itemPath,
        isExpanded: getExpandedState(
          defaultExpanded,
          toggledNodes,
          itemPath,
          children.length > 0,
        ),
      };
    }),
  };
};

// FlattenJsonTree takes a root node and recursively traverses the tree, building
// a flat list of nodes in the order which they should be rendered ("depth-first").
export const flattenJsonTree = (rootNode): JsonNode[] => {
  const traverse = (node, depth = 0, nodeList = [] as JsonNode[]) => {
    const { key, value, type, children, isExpanded, path } = node;
    const numChildren = children.length;
    // Here we simply pull the key, value, and type from the node, and
    // add the depth based on how far down the tree we are.
    nodeList.push({ key, value, type, depth, isExpanded, path, numChildren });
    // If our current node is expanded and has children, we want to
    // (recursively) add those children to the list before continuing to the next
    // node at this depth.
    if (children.length && isExpanded) {
      children.forEach((child) => traverse(child, depth + 1, nodeList));
    }
    return nodeList;
  };
  const flattenedTree = traverse(rootNode);
  return flattenedTree;
};

export const getJSONValueType = (value: unknown): JsonNodeType => {
  if (typeof value === "string") {
    return JsonNodeType.String;
  } else if (typeof value === "number") {
    return JsonNodeType.Number;
  } else if (typeof value === "boolean") {
    return JsonNodeType.Boolean;
    // We only want to treat null and undefined separately
    // eslint-disable-next-line eqeqeq
  } else if (value === null) {
    return JsonNodeType.Null;
  } else if (value === undefined) {
    return JsonNodeType.Undefined;
  } else if (Array.isArray(value)) {
    return JsonNodeType.Array;
  } else {
    return JsonNodeType.Object;
  }
};

const getValue = (value: unknown): JSONPrimitive => {
  if (isPrimitive(getJSONValueType(value))) {
    return value as JSONPrimitive;
  } else {
    return null;
  }
};

// getChildren takes a "value" (primitive, array, or object) and returns
// an array of JsonNodes containing the value's children. Child nodes' own
// children will be populated recursively.
const getChildren = (
  value: unknown,
  parentPath: string,
  toggledNodes: Set<string>,
  defaultExpanded: boolean,
): JsonTreeNode[] => {
  const valueType = getJSONValueType(value);
  // If we have a primitive, we don't have any children
  if (isPrimitive(getJSONValueType(value))) {
    return [];
    // If we've got an array, we want a node for each element, with the key
    // just being the index of that element.
  } else if (valueType === JsonNodeType.Array) {
    return (value as Array<unknown>).map(
      (item: unknown, index: number): JsonTreeNode => {
        const itemPath = `${parentPath}[${index}]`;
        const children = getChildren(
          item,
          itemPath,
          toggledNodes,
          defaultExpanded,
        );
        return {
          key: index.toString(),
          type: getJSONValueType(item),
          value: getValue(item),
          path: itemPath,
          children,
          isExpanded: getExpandedState(
            defaultExpanded,
            toggledNodes,
            itemPath,
            children.length > 0,
          ),
        };
      },
    );
  } else {
    // If we've got an object, we want a node for each entry in the
    // object
    return Object.entries(value as object).map(([key, value]): JsonTreeNode => {
      const itemPath = getPath(parentPath, key);
      const children = getChildren(
        value,
        itemPath,
        toggledNodes,
        defaultExpanded,
      );
      return {
        key,
        type: getJSONValueType(value),
        value: getValue(value),
        path: getPath(parentPath, key),
        children,
        isExpanded: getExpandedState(
          defaultExpanded,
          toggledNodes,
          itemPath,
          children.length > 0,
        ),
      };
    });
  }
};

export const getPath = (parentPath: string, path: string): string => {
  // If the field name contains any characters other than underscores or
  // alphanumeric characters, we want to use bracket notation to handle it
  // correctly
  const regex = /[^a-zA-Z_]/;

  if (parentPath && !path) {
    return parentPath.match(regex) ? `$["${parentPath}"]` : `$.${parentPath}`;
  }

  return path.match(regex)
    ? `${parentPath}["${path}"]`
    : `${parentPath}.${path}`;
};

export const isPrimitive = (type: JsonNodeType): boolean => {
  return [
    JsonNodeType.String,
    JsonNodeType.Number,
    JsonNodeType.Boolean,
    JsonNodeType.Null,
    JsonNodeType.Undefined,
  ].includes(type);
};

const getExpandedState = (
  defaultExpanded: boolean,
  toggledNodes: Set<string>,
  path: string,
  hasChildren,
): boolean => {
  return hasChildren
    ? defaultExpanded
      ? !toggledNodes.has(path)
      : toggledNodes.has(path)
    : false;
};
