/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TelemetryDeselectDashboardRequestBody,
    TelemetryDeselectDashboardRequestBodyFromJSON,
    TelemetryDeselectDashboardRequestBodyToJSON,
    TelemetryDeselectDashboardResponseBody,
    TelemetryDeselectDashboardResponseBodyFromJSON,
    TelemetryDeselectDashboardResponseBodyToJSON,
    TelemetryFetchDashboardDetailsRequestBody,
    TelemetryFetchDashboardDetailsRequestBodyFromJSON,
    TelemetryFetchDashboardDetailsRequestBodyToJSON,
    TelemetryFetchDashboardDetailsResponseBody,
    TelemetryFetchDashboardDetailsResponseBodyFromJSON,
    TelemetryFetchDashboardDetailsResponseBodyToJSON,
    TelemetryListResponseBody,
    TelemetryListResponseBodyFromJSON,
    TelemetryListResponseBodyToJSON,
    TelemetrySelectDashboardRequestBody,
    TelemetrySelectDashboardRequestBodyFromJSON,
    TelemetrySelectDashboardRequestBodyToJSON,
    TelemetrySelectDashboardResponseBody,
    TelemetrySelectDashboardResponseBodyFromJSON,
    TelemetrySelectDashboardResponseBodyToJSON,
} from '../models';

export interface TelemetryDeselectDashboardRequest {
    deselectDashboardRequestBody: TelemetryDeselectDashboardRequestBody;
}

export interface TelemetryFetchDashboardDetailsRequest {
    fetchDashboardDetailsRequestBody: TelemetryFetchDashboardDetailsRequestBody;
}

export interface TelemetryListRequest {
    provider?: string;
    selectedOnly?: boolean;
}

export interface TelemetrySelectDashboardRequest {
    selectDashboardRequestBody: TelemetrySelectDashboardRequestBody;
}

/**
 * 
 */
export class TelemetryApi extends runtime.BaseAPI {

    /**
     * Deselect a dashboard from being displayed as the core dashboard
     * DeselectDashboard Telemetry
     */
    async telemetryDeselectDashboardRaw(requestParameters: TelemetryDeselectDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TelemetryDeselectDashboardResponseBody>> {
        if (requestParameters.deselectDashboardRequestBody === null || requestParameters.deselectDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('deselectDashboardRequestBody','Required parameter requestParameters.deselectDashboardRequestBody was null or undefined when calling telemetryDeselectDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/telemetry_dashboards/actions/deselect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelemetryDeselectDashboardRequestBodyToJSON(requestParameters.deselectDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelemetryDeselectDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Deselect a dashboard from being displayed as the core dashboard
     * DeselectDashboard Telemetry
     */
    async telemetryDeselectDashboard(requestParameters: TelemetryDeselectDashboardRequest, initOverrides?: RequestInit): Promise<TelemetryDeselectDashboardResponseBody> {
        const response = await this.telemetryDeselectDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the details of a telemetry dashboard, and persist those details if they are not already stored
     * FetchDashboardDetails Telemetry
     */
    async telemetryFetchDashboardDetailsRaw(requestParameters: TelemetryFetchDashboardDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TelemetryFetchDashboardDetailsResponseBody>> {
        if (requestParameters.fetchDashboardDetailsRequestBody === null || requestParameters.fetchDashboardDetailsRequestBody === undefined) {
            throw new runtime.RequiredError('fetchDashboardDetailsRequestBody','Required parameter requestParameters.fetchDashboardDetailsRequestBody was null or undefined when calling telemetryFetchDashboardDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/telemetry_dashboards/actions/fetch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelemetryFetchDashboardDetailsRequestBodyToJSON(requestParameters.fetchDashboardDetailsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelemetryFetchDashboardDetailsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Fetch the details of a telemetry dashboard, and persist those details if they are not already stored
     * FetchDashboardDetails Telemetry
     */
    async telemetryFetchDashboardDetails(requestParameters: TelemetryFetchDashboardDetailsRequest, initOverrides?: RequestInit): Promise<TelemetryFetchDashboardDetailsResponseBody> {
        const response = await this.telemetryFetchDashboardDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show telemetry integration information
     * List Telemetry
     */
    async telemetryListRaw(requestParameters: TelemetryListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TelemetryListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        if (requestParameters.selectedOnly !== undefined) {
            queryParameters['selected_only'] = requestParameters.selectedOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/telemetry_dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelemetryListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show telemetry integration information
     * List Telemetry
     */
    async telemetryList(requestParameters: TelemetryListRequest, initOverrides?: RequestInit): Promise<TelemetryListResponseBody> {
        const response = await this.telemetryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Select a dashboard to be displayed as the core dashboard
     * SelectDashboard Telemetry
     */
    async telemetrySelectDashboardRaw(requestParameters: TelemetrySelectDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TelemetrySelectDashboardResponseBody>> {
        if (requestParameters.selectDashboardRequestBody === null || requestParameters.selectDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('selectDashboardRequestBody','Required parameter requestParameters.selectDashboardRequestBody was null or undefined when calling telemetrySelectDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/telemetry_dashboards/actions/select`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelemetrySelectDashboardRequestBodyToJSON(requestParameters.selectDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelemetrySelectDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Select a dashboard to be displayed as the core dashboard
     * SelectDashboard Telemetry
     */
    async telemetrySelectDashboard(requestParameters: TelemetrySelectDashboardRequest, initOverrides?: RequestInit): Promise<TelemetrySelectDashboardResponseBody> {
        const response = await this.telemetrySelectDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
