/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelemetryDashboard
 */
export interface TelemetryDashboard {
    /**
     * The internal identifier for the dashboard at the provider
     * @type {string}
     * @memberof TelemetryDashboard
     */
    dashboard_provider_id: string;
    /**
     * The description of the dashboard
     * @type {string}
     * @memberof TelemetryDashboard
     */
    description?: string;
    /**
     * The folder the dashboard is stored in
     * @type {string}
     * @memberof TelemetryDashboard
     */
    folder?: string;
    /**
     * The internal identifier for the dashboard
     * @type {string}
     * @memberof TelemetryDashboard
     */
    id: string;
    /**
     * The name of the dashboard
     * @type {string}
     * @memberof TelemetryDashboard
     */
    name: string;
    /**
     * The provider of the dashboard
     * @type {string}
     * @memberof TelemetryDashboard
     */
    provider: TelemetryDashboardProviderEnum;
    /**
     * The URL for the dashboard
     * @type {string}
     * @memberof TelemetryDashboard
     */
    url: string;
}

/**
* @export
* @enum {string}
*/
export enum TelemetryDashboardProviderEnum {
    Grafana = 'grafana'
}

export function TelemetryDashboardFromJSON(json: any): TelemetryDashboard {
    return TelemetryDashboardFromJSONTyped(json, false);
}

export function TelemetryDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryDashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard_provider_id': json['dashboard_provider_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'id': json['id'],
        'name': json['name'],
        'provider': json['provider'],
        'url': json['url'],
    };
}

export function TelemetryDashboardToJSON(value?: TelemetryDashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard_provider_id': value.dashboard_provider_id,
        'description': value.description,
        'folder': value.folder,
        'id': value.id,
        'name': value.name,
        'provider': value.provider,
        'url': value.url,
    };
}

