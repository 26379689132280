import { IncidentGroundTruthCodeChange } from "@incident-io/api";
import { BadgeSize, Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { CopyButton } from "@incident-ui/CopyButton/CopyButton";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { GroundTruthMetadata } from "./GroundTruthMetadata";
import { VerifiedButton } from "./VerifiedButton";

type EditableCodeChange = IncidentGroundTruthCodeChange & { id: string };

export const GroundTruthCodeChangesSection = ({
  changes = [],
  verifiedAt,
  onSave,
  className,
}: {
  changes?: IncidentGroundTruthCodeChange[];
  verifiedAt?: Date;
  onSave?: (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableChanges, setEditableChanges] = useState<EditableCodeChange[]>(
    [],
  );
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableChanges(
      changes.map((change, idx) => ({ ...change, id: `change-${idx}` })),
    );
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditableChanges([]);
    setIsVerified(!!verifiedAt);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      // Strip out the temporary ids before saving
      const cleanChanges = editableChanges.map(
        ({ id: _, ...change }) => change,
      );
      await onSave(cleanChanges, isVerified);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const addNewChange = () => {
    setEditableChanges([
      ...editableChanges,
      { id: `new-${Date.now()}`, permalink: "", detail: "" },
    ]);
  };

  const updateChange = (
    id: string,
    field: keyof IncidentGroundTruthCodeChange,
    value: string,
  ) => {
    setEditableChanges((changes) =>
      changes.map((change) =>
        change.id === id ? { ...change, [field]: value } : change,
      ),
    );
  };

  const removeChange = (id: string) => {
    setEditableChanges((changes) =>
      changes.filter((change) => change.id !== id),
    );
  };

  const isEmpty = !changes?.length;

  return (
    <div
      className={tcx(
        "border-t border-stroke first:border-t-0",
        isEditing ? "bg-surface-secondary" : "",
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Causing code changes</h4>
            <VerifiedButton
              isVerified={isVerified}
              verifiedAt={verifiedAt}
              setIsVerified={setIsVerified}
              isEditing={isEditing}
            />
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            {editableChanges.map((change) => (
              <EditableCodeChangeItem
                key={change.id}
                change={change}
                onUpdate={(field, value) =>
                  updateChange(change.id, field, value)
                }
                onRemove={() => removeChange(change.id)}
              />
            ))}

            <div className="flex gap-2 mt-4">
              <Button
                onClick={addNewChange}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
              >
                Add code change
              </Button>
            </div>

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : isEmpty ? (
          <p className="text-sm text-content-secondary">
            No code changes have been identified yet.
          </p>
        ) : (
          <div className="space-y-4">
            {changes.map((change, index) => (
              <CodeChangeItem key={index} codeChange={change} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CodeChangeItem = ({
  codeChange,
}: {
  codeChange: IncidentGroundTruthCodeChange;
}) => {
  return (
    <div className="flex items-start gap-2">
      <Icon
        id={IconEnum.GitBranch}
        className="mt-1 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <a
            href={codeChange.permalink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-content-primary hover:underline break-all"
          >
            {codeChange.permalink}
          </a>
          <CopyButton value={codeChange.permalink} />
        </div>
        <p className="text-sm text-content-secondary mt-1">
          {codeChange.detail}
        </p>
        <GroundTruthMetadata metadata={codeChange.metadata} />
      </div>
    </div>
  );
};

const EditableCodeChangeItem = ({
  change,
  onUpdate,
  onRemove,
}: {
  change: EditableCodeChange;
  onUpdate: (field: keyof IncidentGroundTruthCodeChange, value: string) => void;
  onRemove: () => void;
}) => {
  return (
    <div className="flex items-start gap-2 p-3 rounded border border-stroke bg-white">
      <Icon
        id={IconEnum.GitBranch}
        className="mt-2 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0 space-y-2">
        <input
          type="url"
          value={change.permalink}
          onChange={(e) => onUpdate("permalink", e.target.value)}
          placeholder="Enter code change URL"
          className="w-full p-2 text-sm border rounded bg-white"
        />
        <textarea
          value={change.detail}
          onChange={(e) => onUpdate("detail", e.target.value)}
          placeholder="Describe the code change"
          className="w-full p-2 text-sm border rounded bg-white resize-none"
          rows={2}
        />
      </div>
      <Button
        onClick={onRemove}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={IconEnum.Close}
        analyticsTrackingId={null}
        className="text-content-tertiary hover:text-content-primary"
      >
        Remove
      </Button>
    </div>
  );
};
