/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIModel,
    AIModelFromJSON,
    AIModelFromJSONTyped,
    AIModelToJSON,
} from './AIModel';

/**
 * 
 * @export
 * @interface AIStaffListModelsResponseBody
 */
export interface AIStaffListModelsResponseBody {
    /**
     * 
     * @type {Array<AIModel>}
     * @memberof AIStaffListModelsResponseBody
     */
    models: Array<AIModel>;
}

export function AIStaffListModelsResponseBodyFromJSON(json: any): AIStaffListModelsResponseBody {
    return AIStaffListModelsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListModelsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListModelsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'models': ((json['models'] as Array<any>).map(AIModelFromJSON)),
    };
}

export function AIStaffListModelsResponseBodyToJSON(value?: AIStaffListModelsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'models': ((value.models as Array<any>).map(AIModelToJSON)),
    };
}

