import { ScheduleExternalProviderEnum } from "@incident-io/api";
import { useAPI, useAPIInfinite } from "src/utils/swr";

export const useOnCallEntityCount = () => {
  // --- Fetch escalations, escalation paths and schedules
  const {
    data: escalationPoliciesResp,
    isLoading: escalationPoliciesIsLoading,
    error: escalationPoliciesError,
  } = useAPI(
    "escalationPathsList",
    { pageSize: 1 },
    { revalidateOnMount: true },
  );

  const {
    responses: escalationsResp,
    isLoading: escalationsIsLoading,
    error: escalationsError,
  } = useAPIInfinite(
    "escalationsList",
    { pageSize: 1 },
    {
      revalidateOnMount: true,
    },
  );

  const {
    data: schedulesResp,
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI(
    "schedulesList",
    {
      pageSize: 1,
    },
    { revalidateOnMount: true },
  );

  // --- Count all the things
  const escalationPolicyCount =
    escalationPoliciesResp?.pagination_meta.total_record_count ?? 0;

  const escalations = escalationsResp.flatMap(({ escalations }) => escalations);
  const escalationCount = escalations.length;

  const scheduleCount =
    schedulesResp?.schedules.filter(
      (s) => s.external_provider === ScheduleExternalProviderEnum.Native,
    ).length ?? 0;

  // --- Amalgamate errors, loading and counts
  const error = escalationPoliciesError || escalationsError || schedulesError;

  const isLoading =
    escalationPoliciesIsLoading ||
    escalationsIsLoading ||
    schedulesIsLoading ||
    !schedulesResp ||
    !escalationsResp ||
    !escalationPoliciesResp;

  const total = escalationPolicyCount + escalationCount + scheduleCount;

  return {
    error,
    isLoading,
    data: total,
  };
};
