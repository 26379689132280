import type { SVGProps } from "react";
import * as React from "react";
const SvgCodeBlock = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.79 1.679a.8.8 0 0 1 1.131 0l2.4 2.4a.8.8 0 0 1 0 1.131l-2.4 2.4A.8.8 0 1 1 14.79 6.48l1.834-1.835L14.79 2.81a.8.8 0 0 1 0-1.131ZM2.556 5.444a2.933 2.933 0 0 1 2.933-2.933h1.969a.8.8 0 1 1 0 1.6h-1.97c-.736 0-1.332.597-1.332 1.333v9.067c0 .736.596 1.333 1.333 1.333h9.067c.736 0 1.333-.597 1.333-1.333V9.342a.8.8 0 0 1 1.6 0v5.17a2.933 2.933 0 0 1-2.933 2.932H5.489a2.933 2.933 0 0 1-2.933-2.933V5.444ZM12.72 2.81A.8.8 0 0 0 11.59 1.68l-2.4 2.4a.8.8 0 0 0 0 1.131l2.4 2.4A.8.8 0 0 0 12.72 6.48l-1.834-1.835L12.72 2.81Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCodeBlock;
