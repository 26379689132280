import { Mode } from "@incident-shared/forms/v2/formsv2";
import { Loader, Modal } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { AnnouncementRuleCreateEditModal } from "./create-edit/AnnouncementRuleCreateEditModal";

export const AnnouncementRuleEditModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const { id: ruleId } = useParams();

  const {
    data: ruleData,
    isLoading: ruleLoading,
    error: ruleError,
  } = useAPI("announcementRulesShow", { id: ruleId as string });

  const error = ruleError;
  if (ruleError) {
    throw error;
  }

  if (ruleLoading) {
    return (
      <Modal
        isOpen
        analyticsTrackingId={null}
        title=""
        disableQuickClose
        onClose={onClose}
      >
        <Loader />
      </Modal>
    );
  }

  if (!ruleData) {
    return <ErrorModal onClose={onClose} />;
  }

  return (
    <AnnouncementRuleCreateEditModal
      initialData={ruleData.rule}
      mode={Mode.Edit}
      onClose={onClose}
    />
  );
};

export const AnnouncementRuleCreateModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  return (
    <AnnouncementRuleCreateEditModal mode={Mode.Create} onClose={onClose} />
  );
};
