import {
  EscalationPathNodeTypeEnum,
  EscalationPathTargetScheduleModeEnum,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
  UserOptionStateEnum,
} from "@incident-io/api";
import { SelectOption } from "@incident-ui/Select/types";

// Escalation path form data type. The nodes should be updated by ReactFlow using
// callbacks, rather than the form data being updated directly and passed into ReactFlow.
export type EscalationPathFormData = {
  id: string;
  name: string;
  firstNodeId: string;
  nodes: Record<string, PathNode>;
  working_hours?: {
    config_id: string;
    weekdays: {
      [key: string]: boolean;
    };
    start_time: string;
    end_time: string;
    timezone: string;
  };
  team_ids: string[];
};

// In the form state itself, we ban the ReactFlowNodeCustomType options, since those are render-only.
export type PathNode = ReactFlowNodeType & {
  data: { type: EscalationPathNodeTypeEnum };
};

// PathEdge is the data that is passed to the custom edge component by ReactFlow when it is
// rendered.
export type PathEdge = {
  label: string | null;
  addButton:
    | "none"
    | "after-source"
    | "before-target"
    | "then-after-source"
    | "else-after-source";
  skipRender?: boolean;
};

// A standard "notify" node in the escalation path - this is where users choose
// who to page.
export type NodeLevel = {
  targets: EscalationPathTargetFormData[];
  urgency: EscalationPathTargetUrgencyEnum;
  targetSelectionMode: EscalationPathTargetSelectionMode;
  targetRotateAfterMinutes?: number;
} & EscalationPathTimeToAckConfig;

// A standard "notify channel" node in the escalation path - this is where you
// can choose to page one or more channels.
export type NodeNotifyChannel = {
  targets: EscalationPathTargetFormData[];
} & EscalationPathTimeToAckConfig;

// You can either repeat from the very start, or from any condition node in the path
// from the first node to the repeat node.
export type NodeRepeat = {
  repeat_times: string;
  to_node: string;
};

// A condition node: if the condition is met, go to the "then" node, otherwise go to the "else" node.
// A missing then or else node ID means that the path ends there.
export type NodeIfElse = {
  thenNodeId: string | undefined;
  elseNodeId: string | undefined;
} & EscalationPathConditionConfig;

export type EscalationPathConditionConfig = {
  conditionType?: EscalationPathConditionType;
  priorityIds?: string[];
};

export enum EscalationPathConditionType {
  WorkingHoursActive = "working_hours_active",
  Priority = "priority",
}

// ReactFlowNodeType is a superset of the form type, which supports display-only
// nodes (the start node and terminus nodes).
export interface ReactFlowNodeType {
  id: string;
  data: { type: EscalationPathNodeTypeEnum | ReactFlowNodeCustomType } & (
    | {
        type: ReactFlowNodeCustomType.Start;
        nextNodeId: string;
        level?: never;
        notify_channel?: never;
        repeat?: never;
        if_else?: never;
      }
    | {
        type: ReactFlowNodeCustomType.DoNothing;
        nextNodeId?: never;
        level?: never;
        notify_channel?: never;
        repeat?: never;
        if_else?: never;
      }
    | {
        type: ReactFlowNodeCustomType.Terminus;
        nextNodeId?: never;
        level?: never;
        notify_channel?: never;
        repeat?: never;
        if_else?: never;
      }
    | {
        type: EscalationPathNodeTypeEnum.Level;
        nextNodeId?: string;
        level: NodeLevel;
        notify_channel?: never;
        repeat?: never;
        if_else?: never;
      }
    | {
        type: EscalationPathNodeTypeEnum.NotifyChannel;
        nextNodeId?: string;
        level?: never;
        notify_channel: NodeNotifyChannel;
        repeat?: never;
        if_else?: never;
      }
    | {
        type: EscalationPathNodeTypeEnum.Repeat;
        nextNodeId?: never;
        level?: never;
        notify_channel?: never;
        repeat: NodeRepeat;
        if_else?: never;
      }
    | {
        type: EscalationPathNodeTypeEnum.IfElse;
        nextNodeId?: never;
        level?: never;
        notify_channel?: never;
        repeat?: never;
        if_else: NodeIfElse;
      }
  );
}

export type ReactFlowDataType = ReactFlowNodeType["data"];

export enum ReactFlowNodeCustomType {
  Start = "start",
  Terminus = "terminus",
  DoNothing = "do_nothing",
}

export enum EscalationPathTargetSelectionMode {
  AllAtOnce = "all_at_once",
  RoundRobin = "round_robin",
}

// All possible options for time to ack in a node
export enum EscalationPathTimeToAckOption {
  WorkingHoursActive = "working_hours_active",
  WorkingHoursInactive = "working_hours_inactive",
  MinutesZero = "minutes_zero", // This will only be allowed on channel nodes.
  MinutesFive = "minutes_five",
  MinutesTen = "minutes_ten",
  MinutesFifteen = "minutes_fifteen",
  MinutesCustom = "minutes_custom",
}

export type EscalationPathTimeToAckConfig = {
  time_to_ack_option: EscalationPathTimeToAckOption;
  time_to_ack_custom_minutes?: number;
};

export type EscalationPathTargetFormData =
  | EscalationPathUserTargetFormData
  | EscalationPathScheduleTargetFormData
  | EscalationPathSlackChannelTargetFormData;

export type EscalationPathUserTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.User;
  state: UserOptionStateEnum;

  // Required for other target types
  schedule_mode?: never;
  selected_rota_id?: never;
};

export type EscalationPathScheduleTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.Schedule;
  schedule_mode?: EscalationPathTargetScheduleModeEnum;
  selected_rota_id?: string;

  // Required for other target types
  state?: never;
};

export type EscalationPathSlackChannelTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.SlackChannel;

  // Required for other target types
  schedule_mode?: never;
  selected_rota_id?: never;
  state?: never;
};

export type EscalationPathCatalogBindingData = {
  [catalog_type_id: string]: string[];
};
