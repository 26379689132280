/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody
 */
export interface UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody {
    /**
     * The user's preferred escalation provider
     * @type {string}
     * @memberof UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody
     */
    preferred_escalation_provider: UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyPreferredEscalationProviderEnum;
    /**
     * The user's preference to update, defaults to the authenticated user's ID
     * @type {string}
     * @memberof UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody
     */
    user_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyPreferredEscalationProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyFromJSON(json: any): UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody {
    return UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyFromJSONTyped(json, false);
}

export function UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferred_escalation_provider': json['preferred_escalation_provider'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyToJSON(value?: UserPreferencesUpdateManualEscalationProviderPreferencesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferred_escalation_provider': value.preferred_escalation_provider,
        'user_id': value.user_id,
    };
}

