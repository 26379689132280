/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecard,
    EvaluationScorecardFromJSON,
    EvaluationScorecardFromJSONTyped,
    EvaluationScorecardToJSON,
} from './EvaluationScorecard';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotThread
 */
export interface CopilotThread {
    /**
     * The classification of the thread
     * @type {string}
     * @memberof CopilotThread
     */
    classification?: CopilotThreadClassificationEnum;
    /**
     * When the thread was created.
     * @type {Date}
     * @memberof CopilotThread
     */
    created_at: Date;
    /**
     * 
     * @type {User}
     * @memberof CopilotThread
     */
    from_user?: User;
    /**
     * unique ID of the thread
     * @type {string}
     * @memberof CopilotThread
     */
    id: string;
    /**
     * The incident this thread is related to
     * @type {number}
     * @memberof CopilotThread
     */
    incident_external_id: number;
    /**
     * The ID of the incident this thread is related to
     * @type {string}
     * @memberof CopilotThread
     */
    incident_id: string;
    /**
     * Total cost of all interactions in the thread
     * @type {number}
     * @memberof CopilotThread
     */
    overall_cost_cents: number;
    /**
     * The one-word score for this thread (if available)
     * @type {string}
     * @memberof CopilotThread
     */
    overall_score?: string;
    /**
     * The request types for this thread
     * @type {Array<string>}
     * @memberof CopilotThread
     */
    request_types?: Array<string>;
    /**
     * 
     * @type {EvaluationScorecard}
     * @memberof CopilotThread
     */
    scorecard?: EvaluationScorecard;
    /**
     * The Slack channel ID this thread is in
     * @type {string}
     * @memberof CopilotThread
     */
    slack_channel_id: string;
    /**
     * The initial message that triggered the copilot interaction
     * @type {string}
     * @memberof CopilotThread
     */
    source_message: string;
    /**
     * The timestamp of the thread in Slack
     * @type {string}
     * @memberof CopilotThread
     */
    thread_ts: string;
}

/**
* @export
* @enum {string}
*/
export enum CopilotThreadClassificationEnum {
    Question = 'question',
    Action = 'action',
    Both = 'both',
    Unsure = 'unsure'
}

export function CopilotThreadFromJSON(json: any): CopilotThread {
    return CopilotThreadFromJSONTyped(json, false);
}

export function CopilotThreadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotThread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'created_at': (new Date(json['created_at'])),
        'from_user': !exists(json, 'from_user') ? undefined : UserFromJSON(json['from_user']),
        'id': json['id'],
        'incident_external_id': json['incident_external_id'],
        'incident_id': json['incident_id'],
        'overall_cost_cents': json['overall_cost_cents'],
        'overall_score': !exists(json, 'overall_score') ? undefined : json['overall_score'],
        'request_types': !exists(json, 'request_types') ? undefined : json['request_types'],
        'scorecard': !exists(json, 'scorecard') ? undefined : EvaluationScorecardFromJSON(json['scorecard']),
        'slack_channel_id': json['slack_channel_id'],
        'source_message': json['source_message'],
        'thread_ts': json['thread_ts'],
    };
}

export function CopilotThreadToJSON(value?: CopilotThread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classification': value.classification,
        'created_at': (value.created_at.toISOString()),
        'from_user': UserToJSON(value.from_user),
        'id': value.id,
        'incident_external_id': value.incident_external_id,
        'incident_id': value.incident_id,
        'overall_cost_cents': value.overall_cost_cents,
        'overall_score': value.overall_score,
        'request_types': value.request_types,
        'scorecard': EvaluationScorecardToJSON(value.scorecard),
        'slack_channel_id': value.slack_channel_id,
        'source_message': value.source_message,
        'thread_ts': value.thread_ts,
    };
}

