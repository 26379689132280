/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSourcePayload,
    AlertRouteAlertSourcePayloadFromJSON,
    AlertRouteAlertSourcePayloadFromJSONTyped,
    AlertRouteAlertSourcePayloadToJSON,
} from './AlertRouteAlertSourcePayload';
import {
    AlertRouteChannelConfigPayload,
    AlertRouteChannelConfigPayloadFromJSON,
    AlertRouteChannelConfigPayloadFromJSONTyped,
    AlertRouteChannelConfigPayloadToJSON,
} from './AlertRouteChannelConfigPayload';
import {
    AlertRouteEscalationConfigPayload,
    AlertRouteEscalationConfigPayloadFromJSON,
    AlertRouteEscalationConfigPayloadFromJSONTyped,
    AlertRouteEscalationConfigPayloadToJSON,
} from './AlertRouteEscalationConfigPayload';
import {
    AlertRouteIncidentConfigPayload,
    AlertRouteIncidentConfigPayloadFromJSON,
    AlertRouteIncidentConfigPayloadFromJSONTyped,
    AlertRouteIncidentConfigPayloadToJSON,
} from './AlertRouteIncidentConfigPayload';
import {
    AlertRouteIncidentTemplatePayload,
    AlertRouteIncidentTemplatePayloadFromJSON,
    AlertRouteIncidentTemplatePayloadFromJSONTyped,
    AlertRouteIncidentTemplatePayloadToJSON,
} from './AlertRouteIncidentTemplatePayload';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertRoutesCreateAlertRouteRequestBody
 */
export interface AlertRoutesCreateAlertRouteRequestBody {
    /**
     * Which alert sources should this alert route match?
     * @type {Array<AlertRouteAlertSourcePayload>}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    alert_sources: Array<AlertRouteAlertSourcePayload>;
    /**
     * The channel configuration for this alert route
     * @type {Array<AlertRouteChannelConfigPayload>}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    channel_config?: Array<AlertRouteChannelConfigPayload>;
    /**
     * What condition groups must be true for this alert route to fire?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    enabled: boolean;
    /**
     * 
     * @type {AlertRouteEscalationConfigPayload}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    escalation_config: AlertRouteEscalationConfigPayload;
    /**
     * The expressions used in this template
     * @type {Array<ExpressionPayload>}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {AlertRouteIncidentConfigPayload}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    incident_config: AlertRouteIncidentConfigPayload;
    /**
     * 
     * @type {AlertRouteIncidentTemplatePayload}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    incident_template?: AlertRouteIncidentTemplatePayload;
    /**
     * Whether this alert route is private. Private alert routes will only create private incidents from alerts.
     * @type {boolean}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    is_private: boolean;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    name: string;
    /**
     * The version of this alert route config
     * @type {number}
     * @memberof AlertRoutesCreateAlertRouteRequestBody
     */
    version: number;
}

export function AlertRoutesCreateAlertRouteRequestBodyFromJSON(json: any): AlertRoutesCreateAlertRouteRequestBody {
    return AlertRoutesCreateAlertRouteRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesCreateAlertRouteRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesCreateAlertRouteRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourcePayloadFromJSON)),
        'channel_config': !exists(json, 'channel_config') ? undefined : ((json['channel_config'] as Array<any>).map(AlertRouteChannelConfigPayloadFromJSON)),
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'enabled': json['enabled'],
        'escalation_config': AlertRouteEscalationConfigPayloadFromJSON(json['escalation_config']),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'incident_config': AlertRouteIncidentConfigPayloadFromJSON(json['incident_config']),
        'incident_template': !exists(json, 'incident_template') ? undefined : AlertRouteIncidentTemplatePayloadFromJSON(json['incident_template']),
        'is_private': json['is_private'],
        'name': json['name'],
        'version': json['version'],
    };
}

export function AlertRoutesCreateAlertRouteRequestBodyToJSON(value?: AlertRoutesCreateAlertRouteRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourcePayloadToJSON)),
        'channel_config': value.channel_config === undefined ? undefined : ((value.channel_config as Array<any>).map(AlertRouteChannelConfigPayloadToJSON)),
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'enabled': value.enabled,
        'escalation_config': AlertRouteEscalationConfigPayloadToJSON(value.escalation_config),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'incident_config': AlertRouteIncidentConfigPayloadToJSON(value.incident_config),
        'incident_template': AlertRouteIncidentTemplatePayloadToJSON(value.incident_template),
        'is_private': value.is_private,
        'name': value.name,
        'version': value.version,
    };
}

