import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
} from "@incident-ui";
import { PopoverDropdownMenu } from "@incident-ui/PopoverDropdownMenu/PopoverDropdownMenu";
import {
  FieldValues,
  Path,
  useController,
  UseFormReturn,
} from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { InputElementProps, parseProps } from "../../@shared/forms/v2/formsv2";
import { FormInputWrapper } from "../../@shared/forms/v2/helpers";

// Represents a model override entry with prompt name and model
export type ModelOverrideEntry = {
  promptName: string;
  modelName: string;
};

// The form value is a map of prompt names to model names
export type ModelOverridesFormValue = Record<string, string>;

type ModelOverrideEditorProps = {
  className?: string;
};

export const PromptModelOverrideEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, ModelOverrideEditorProps>,
): React.ReactElement => {
  const { name, rules, wrapperProps } = parseProps(props);
  const {
    field: { ref: _ref, value, onChange },
  } = useController({
    name,
    rules,
  });

  // Load available prompts
  const {
    data: { prompts },
  } = useAPI(
    "aIStaffListPromptNames",
    {},
    {
      fallbackData: { prompts: [] },
    },
  );

  // Load available models
  const {
    data: { models },
    isLoading: isLoadingModels,
  } = useAPI(
    "aIStaffListModels",
    {},
    {
      fallbackData: { models: [] },
    },
  );

  // Prepare prompt options for select
  const promptOptions = prompts.map((prompt) => ({
    label: prompt,
    value: prompt,
    icon: IconEnum.Sparkles,
  }));

  // Prepare model options for select
  const modelOptions = models.map((model) => ({
    label: model.name,
    value: model.name,
  }));

  const onDeletePrompt = (prompt: string) => {
    const newValue = { ...value };
    delete newValue[prompt];
    onChange(newValue);
  };

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <div className="flex flex-col gap-4">
        {Object.keys(value).map((prompt) => (
          <div key={prompt} className="flex items-center gap-2">
            <div className="w-1/2 flex-grow">
              <Badge
                theme={BadgeTheme.Tertiary}
                size={BadgeSize.Small}
                className="truncate max-w-full"
              >
                {prompt}
              </Badge>
            </div>
            <div className="w-1/2 flex-grow">
              <StaticSingleSelectV2
                formMethods={
                  props.formMethods as unknown as UseFormReturn<FormData>
                }
                name={`${name}.${prompt}` as unknown as Path<FormData>}
                placeholder="Select model"
                options={modelOptions}
                isLoading={isLoadingModels}
              />
            </div>
            <Button
              title=""
              analyticsTrackingId="remove-model-override"
              theme={ButtonTheme.Naked}
              onClick={() => onDeletePrompt(prompt)}
              icon={IconEnum.Delete}
            />
          </div>
        ))}

        <PopoverDropdownMenu
          options={promptOptions}
          elementName="prompt"
          onSelect={(prompt) => {
            onChange({ ...value, [prompt.value]: "" });
          }}
          triggerButton={
            <Button
              analyticsTrackingId="add-model-override"
              theme={ButtonTheme.Primary}
              icon={IconEnum.Add}
              size={BadgeSize.Small}
              className="w-fit"
            >
              Add override
            </Button>
          }
        />
      </div>
    </FormInputWrapper>
  );
};
