import { IncidentCallSessionWithSummary } from "@incident-io/api";
import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { EmptyState, Heading } from "@incident-ui";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { stringToHash } from "src/utils/utils";
import { usePrevious } from "use-hooks";

// NOTE: don't unintentionally remount this component, as it will cause the
// animation to play again.
// we should only animate when the summary has changed
export const CallSummary = ({
  callSessionWithTranscript,
  isFetchingSummary,
  animateOnMount,
}: {
  callSessionWithTranscript: IncidentCallSessionWithSummary;
  isFetchingSummary: boolean;
  animateOnMount: boolean;
}) => {
  if (!callSessionWithTranscript.summary) {
    return (
      <div className="pt-6">
        <EmptyState
          className="border-0"
          title={"No summary available"}
          content={
            <>
              Looks like we don&apos;t have a call transcript for this incident
              call. If transcription is enabled, incident.io will automatically
              summarise it for you.
            </>
          }
        />
      </div>
    );
  }

  return (
    <div
      key={stringToHash(callSessionWithTranscript.summary.markdown)}
      className={animateOnMount ? scribeStyles.textGradient : undefined}
    >
      <Heading
        level={2}
        size="medium"
        className="shrink-0 flex items-center gap-1"
      >
        Summary
        <ScribeLoader
          isFetching={isFetchingSummary}
          animateOnMount={animateOnMount}
        />
      </Heading>
      <TemplatedTextDisplay
        className="py-4"
        value={callSessionWithTranscript.summary.text_node}
        style={TemplatedTextDisplayStyle.Compact}
      />
    </div>
  );
};

const useAnimationVisibility = (
  isFetching: boolean,
  animateOnMount: boolean,
) => {
  const [animationCompleted, setAnimationCompleted] = useState(
    isFetching || !animateOnMount,
  );
  const isFetchingPrevious = usePrevious(isFetching);

  useEffect(() => {
    if (isFetching && !isFetchingPrevious) {
      setAnimationCompleted(false);
    }
  }, [isFetching, isFetchingPrevious, setAnimationCompleted]);

  return {
    loop: isFetching, // we use this as a trigger to start the animation
    fadeIn: isFetching || !animationCompleted, // we then fade in when the animation starts (which is when the fetching transitions from false to true)
    fadeOut: animationCompleted, // we then fade out whe lottie completes the animation (as notified by the onComplete callback)
    onAnimationComplete: () => setAnimationCompleted(true),
  };
};

const ScribeLoader = ({
  isFetching,
  animateOnMount,
}: {
  isFetching: boolean;
  animateOnMount: boolean;
}) => {
  const { loop, fadeIn, fadeOut, onAnimationComplete } = useAnimationVisibility(
    isFetching,
    animateOnMount,
  );
  return (
    <Lottie
      style={{
        opacity: fadeIn ? 1 : fadeOut ? 0 : 1,
        transition: "opacity 0.3s",
      }}
      animationData={require("./scribe-lottie.json")}
      loop={loop}
      autoplay
      onComplete={onAnimationComplete}
    />
  );
};
