/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AutoGeneratedTemplateBindingPayload
 */
export interface AutoGeneratedTemplateBindingPayload {
    /**
     * Whether this attribute is autogenerated using AI or not
     * @type {boolean}
     * @memberof AutoGeneratedTemplateBindingPayload
     */
    autogenerated?: boolean;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AutoGeneratedTemplateBindingPayload
     */
    binding?: EngineParamBindingPayload;
}

export function AutoGeneratedTemplateBindingPayloadFromJSON(json: any): AutoGeneratedTemplateBindingPayload {
    return AutoGeneratedTemplateBindingPayloadFromJSONTyped(json, false);
}

export function AutoGeneratedTemplateBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoGeneratedTemplateBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autogenerated': !exists(json, 'autogenerated') ? undefined : json['autogenerated'],
        'binding': !exists(json, 'binding') ? undefined : EngineParamBindingPayloadFromJSON(json['binding']),
    };
}

export function AutoGeneratedTemplateBindingPayloadToJSON(value?: AutoGeneratedTemplateBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autogenerated': value.autogenerated,
        'binding': EngineParamBindingPayloadToJSON(value.binding),
    };
}

