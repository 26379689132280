import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Button,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  Link,
} from "@incident-ui";
import React, { useState } from "react";
import {
  DemoCommand,
  DemoRunCommandRequestBody,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useMutationV2 } from "src/utils/swr";

import { CreateFollowUpSuggestionsForm } from "./CreateFollowUpSuggestionsForm";
import { CreateScribeCallTranscriptForm } from "./CreateScribeCallTranscriptForm";
import { ImportStatuspageForm } from "./ImportStatusPageForm";

// This allows us to deliberately throw an error in a couple of different ways to help
// validate our tooling (primarily sentry + PagerDuty alerting)
export const DemoMagicRoute = (): React.ReactElement => {
  const { identity } = useIdentity();
  const {
    data: { commands },
  } = useAPI("demoListCommands", undefined, { fallbackData: { commands: [] } });

  const [logs, setLogs] = useState<React.ReactNode[]>([]);
  const appendLogs = (lines: React.ReactNode[]) => {
    setLogs((prev) => [...prev, ...lines]);
  };

  if (!identity?.organisation_is_demo) {
    return <></>;
  }

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      icon={IconEnum.Sparkles}
      title="Demo Magic ✨🪄🧙"
    >
      <div className="w-full flex flex-col items-center gap-4">
        <Callout
          theme={CalloutTheme.Plain}
          showIcon
          iconOverride={IconEnum.Book}
        >
          <strong>Setting up demo Catalog data?</strong> Use{" "}
          <Link
            openInNewTab
            analyticsTrackingId={null}
            href="https://www.notion.so/incidentio/Configuring-Demo-Catalog-Data-805a570335614a53ad107ba74911969c?pvs=4"
          >
            this guide
          </Link>{" "}
          to get started.
        </Callout>

        <Callout theme={CalloutTheme.Info}>
          These buttons will run commands that reset your account to some known
          good states. You should only really use these to reconfigure your
          account between demos.
        </Callout>

        <ContentBox className="bg-surface-secondary w-full p-4 flex flex-col gap-4">
          {commands.map((command) => (
            <DemoCommandComponent
              key={command.id}
              command={command}
              appendLogs={appendLogs}
            />
          ))}
        </ContentBox>

        {logs.length > 0 && (
          <div className="rounded border border-stroke bg-surface-secondary">
            <p className="font-medium">Logs</p>
            {logs.map((value, idx) => (
              <p key={idx} className="text-sm">
                {value}
              </p>
            ))}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

const DemoCommandComponent = ({
  command,
  appendLogs,
}: {
  command: DemoCommand;
  appendLogs: (lines: React.ReactNode[]) => void;
}): React.ReactElement => {
  const [confirmDialogOpen, setConfirmationOpen] = useState(false);
  const {
    trigger,
    isMutating: loading,
    genericError,
  } = useMutationV2(
    async (apiClient, body: DemoRunCommandRequestBody) => {
      const res = await apiClient.demoRunCommand({
        id: command.id,
        runCommandRequestBody: body,
      });
      appendLogs(res.logs || []);
    },
    { onSuccess: () => setConfirmationOpen(false), invalidate: [] },
  );

  const CustomFormComponent: FormComponent | undefined = Forms[command.id];

  return (
    <>
      {genericError && <GenericErrorMessage />}
      <div className="font-bold font-l flex flex-row gap-2 items-center justify-items-center">
        {command.name}{" "}
        <Button
          analyticsTrackingId={`demo-${command.name}`}
          disabled={loading}
          loading={loading}
          onClick={() => setConfirmationOpen(true)}
        >
          Go! 💥
        </Button>
        {CustomFormComponent ? (
          confirmDialogOpen && (
            <CustomFormComponent
              onClose={() => setConfirmationOpen(false)}
              onSubmit={(body) => trigger(body)}
              appendLogs={appendLogs}
            />
          )
        ) : (
          <ConfirmationDialog
            title={"Are you sure?"}
            analyticsTrackingId="run-command-confirm"
            isOpen={confirmDialogOpen}
            onCancel={() => setConfirmationOpen(false)}
            onConfirm={() => trigger({})}
          >
            <p className="font-medium"> {command.name}</p>
            {command.description && <p> {command.description} </p>}
          </ConfirmationDialog>
        )}
      </div>
    </>
  );
};

enum CustomForms {
  ImportStatuspage = "import-statuspage",
  FollowUpSuggestions = "follow-up-suggestions",
  ScribeCallTranscript = "create-scribe-call-transcript",
}

export type FormProps = {
  onClose: () => void;
  onSubmit: (body: DemoRunCommandRequestBody) => void;
  appendLogs: (lines: React.ReactNode[]) => void;
};

type FormComponent = (props: FormProps) => React.ReactElement;

const Forms: { [key in CustomForms]: FormComponent } = {
  "import-statuspage": ImportStatuspageForm,
  "follow-up-suggestions": CreateFollowUpSuggestionsForm,
  "create-scribe-call-transcript": CreateScribeCallTranscriptForm,
};
