/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationMethod
 */
export interface OnCallNotificationMethod {
    /**
     * If an email method, the address it applies to
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    email_address?: string;
    /**
     * If the email address is the one on the user's account
     * @type {boolean}
     * @memberof OnCallNotificationMethod
     */
    email_address_is_user?: boolean;
    /**
     * The phone number which the user can expect to receive notifications from, based on our switchboard
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    expected_switchboard_sender?: string;
    /**
     * Unique identifier for this on-call notification method
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    id: string;
    /**
     * The type of notification method
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    method_type: OnCallNotificationMethodMethodTypeEnum;
    /**
     * If a phone method, the number it applies to
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    phone_number?: string;
    /**
     * Current status of the associated phone number, if present
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    phone_number_status?: OnCallNotificationMethodPhoneNumberStatusEnum;
    /**
     * How we verify the phone number, either sms or call
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    phone_verification_type?: OnCallNotificationMethodPhoneVerificationTypeEnum;
    /**
     * Current state of this method
     * @type {string}
     * @memberof OnCallNotificationMethod
     */
    status: OnCallNotificationMethodStatusEnum;
    /**
     * If present, when this method was verified
     * @type {Date}
     * @memberof OnCallNotificationMethod
     */
    verifed_at?: Date;
    /**
     * For unverified methods, the next time verification can be attempted
     * @type {Date}
     * @memberof OnCallNotificationMethod
     */
    verification_available_at?: Date;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodMethodTypeEnum {
    App = 'app',
    Email = 'email',
    MicrosoftTeams = 'microsoft_teams',
    Phone = 'phone',
    Slack = 'slack'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodPhoneNumberStatusEnum {
    Pending = 'pending',
    Sent = 'sent',
    Verified = 'verified',
    Blocked = 'blocked'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodPhoneVerificationTypeEnum {
    Call = 'call',
    Sms = 'sms'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodStatusEnum {
    Created = 'created',
    PendingVerification = 'pending_verification',
    Verified = 'verified'
}

export function OnCallNotificationMethodFromJSON(json: any): OnCallNotificationMethod {
    return OnCallNotificationMethodFromJSONTyped(json, false);
}

export function OnCallNotificationMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'email_address_is_user': !exists(json, 'email_address_is_user') ? undefined : json['email_address_is_user'],
        'expected_switchboard_sender': !exists(json, 'expected_switchboard_sender') ? undefined : json['expected_switchboard_sender'],
        'id': json['id'],
        'method_type': json['method_type'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'phone_number_status': !exists(json, 'phone_number_status') ? undefined : json['phone_number_status'],
        'phone_verification_type': !exists(json, 'phone_verification_type') ? undefined : json['phone_verification_type'],
        'status': json['status'],
        'verifed_at': !exists(json, 'verifed_at') ? undefined : (new Date(json['verifed_at'])),
        'verification_available_at': !exists(json, 'verification_available_at') ? undefined : (new Date(json['verification_available_at'])),
    };
}

export function OnCallNotificationMethodToJSON(value?: OnCallNotificationMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_address': value.email_address,
        'email_address_is_user': value.email_address_is_user,
        'expected_switchboard_sender': value.expected_switchboard_sender,
        'id': value.id,
        'method_type': value.method_type,
        'phone_number': value.phone_number,
        'phone_number_status': value.phone_number_status,
        'phone_verification_type': value.phone_verification_type,
        'status': value.status,
        'verifed_at': value.verifed_at === undefined ? undefined : (value.verifed_at.toISOString()),
        'verification_available_at': value.verification_available_at === undefined ? undefined : (value.verification_available_at.toISOString()),
    };
}

