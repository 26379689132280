/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffRerunProcessorResponseBody
 */
export interface AIStaffRerunProcessorResponseBody {
    /**
     * ID of the created span for this processor run
     * @type {string}
     * @memberof AIStaffRerunProcessorResponseBody
     */
    span_id: string;
}

export function AIStaffRerunProcessorResponseBodyFromJSON(json: any): AIStaffRerunProcessorResponseBody {
    return AIStaffRerunProcessorResponseBodyFromJSONTyped(json, false);
}

export function AIStaffRerunProcessorResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffRerunProcessorResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'span_id': json['span_id'],
    };
}

export function AIStaffRerunProcessorResponseBodyToJSON(value?: AIStaffRerunProcessorResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'span_id': value.span_id,
    };
}

