import { Tab, TabPane, TabSection } from "@incident-ui";

import { BootstrapCustomerChannel } from "./BootstrapCustomerChannel";
import { GenerateSetupLink } from "./GenerateSetupLink";
import { StaffPermissionsListPage } from "./StaffPermissionsListPage";
import { StaffPermissionsPage } from "./StaffPermissionsPage";

export const LandingPage = () => {
  return (
    <TabSection tabs={tabs} tabBarClassName="mb-4" withIndicator>
      <TabPane tabId="utilities" className="space-y-4">
        <BootstrapCustomerChannel />
        <GenerateSetupLink />
      </TabPane>
      <TabPane tabId="users" className="space-y-4">
        <StaffPermissionsPage />
      </TabPane>
      <TabPane tabId="permissions" className="space-y-4">
        <StaffPermissionsListPage />
      </TabPane>
    </TabSection>
  );
};

const tabs: Tab[] = [
  {
    label: "Utilities",
    id: "utilities",
  },
  {
    label: "Users",
    id: "users",
  },
  {
    label: "Permissions",
    id: "permissions",
  },
];
