import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";
import { ArrayPath, FieldValues, Path, useFieldArray } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { InputElementProps, parseProps } from "../../@shared/forms/v2/formsv2";
import { FormInputWrapper } from "../../@shared/forms/v2/helpers";

export const tuneablesToPayload = (
  val: TuneablesOverrideFormValue,
): TuneablesOverrideValue => {
  // Convert tuneables array to the expected nested structure
  const tuneablesOverrides: Record<string, Record<string, string>> = {};

  if (val) {
    val.forEach((tuneable) => {
      if (tuneable.check && tuneable.parameter && tuneable.value) {
        if (!tuneablesOverrides[tuneable.check]) {
          tuneablesOverrides[tuneable.check] = {};
        }
        tuneablesOverrides[tuneable.check][tuneable.parameter] = tuneable.value;
      }
    });
  }

  return tuneablesOverrides;
};

export const payloadToTuneables = (
  payload: TuneablesOverrideValue,
): TuneablesOverrideFormValue => {
  const result: TuneableParameter[] = [];

  // Iterate through each check
  Object.entries(payload).forEach(([checkName, parameters]) => {
    // Iterate through each parameter in the check
    Object.entries(parameters).forEach(([paramName, value]) => {
      result.push({
        check: checkName,
        parameter: paramName,
        value: value,
      });
    });
  });

  return result;
};

export type TuneablesOverrideValue = Record<string, Record<string, string>>;

export type TuneablesOverrideFormValue = TuneableParameter[];
// A tuneable parameter with check name, parameter name, and value
export type TuneableParameter = {
  check: string;
  parameter: string;
  value: string;
};

// Component for displaying tuneable parameters in a user-friendly way
export const TuneableParameters = ({
  parameters,
}: {
  parameters: TuneablesOverrideValue | null | undefined;
}) => {
  if (!parameters || Object.keys(parameters).length === 0) {
    return <>–</>;
  }

  return (
    <div className="flex flex-col gap-2">
      {Object.entries(parameters).map(([checkName, params]) =>
        Object.entries(params).map(([paramName, value], idx) => (
          <div
            key={`${checkName}-${paramName}-${idx}`}
            className="flex flex-col"
          >
            <div className="flex gap-1">
              <span className="text-xs text-content-tertiary">{checkName}</span>
              <span className="text-xs text-content-tertiary">→</span>
              <span className="text-xs text-content-tertiary">{paramName}</span>
            </div>
            <span className="text-sm font-medium">{value}</span>
          </div>
        )),
      )}
    </div>
  );
};

type TuneableParameterEditorProps = {
  className?: string;
  label?: string;
  description?: string;
};

export const TuneableParameterEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TuneableParameterEditorProps>,
): React.ReactElement => {
  const { name, inputProps, wrapperProps } = parseProps(props);

  const {
    data: { checks },
  } = useAPI(
    "aIStaffListInvestigationChecks",
    {},
    {
      fallbackData: { checks: [] },
    },
  );

  const checkOptions =
    checks.map((prompt) => ({
      label: prompt,
      value: prompt,
    })) ?? [];

  const { fields, append, remove } = useFieldArray({
    name: name as unknown as ArrayPath<TFormType>,
    control: props.formMethods.control,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      label={inputProps.label ?? "Tuneable parameters"}
      helptext="Override default tuneable parameters for investigation checks."
    >
      <div className="flex flex-col gap-4">
        {fields.length > 0 && (
          <div className="space-y-2">
            {fields.map((field, index) => (
              <div key={field.id} className="flex items-start gap-2">
                <div className="flex-1">
                  <StaticSingleSelectV2
                    formMethods={props.formMethods}
                    name={
                      `${name}.${index}.check` as unknown as Path<TFormType>
                    }
                    options={checkOptions}
                    label="Check"
                    placeholder="CheckCausingCodeChanges"
                    required
                  />
                </div>
                <div className="flex-1">
                  <InputV2
                    formMethods={props.formMethods}
                    name={
                      `${name}.${index}.parameter` as unknown as Path<TFormType>
                    }
                    label="Parameter"
                    placeholder="longlist_recent_size"
                    required
                  />
                </div>
                <div className="flex-1">
                  <InputV2
                    formMethods={props.formMethods}
                    name={
                      `${name}.${index}.value` as unknown as Path<TFormType>
                    }
                    label="Value"
                    placeholder="100"
                    required
                  />
                </div>
                <div className="flex items-end pb-2">
                  <Button
                    title="Remove parameter"
                    analyticsTrackingId="remove-tuneable-parameter"
                    theme={ButtonTheme.Naked}
                    onClick={() => remove(index)}
                    icon={IconEnum.Delete}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <Button
          analyticsTrackingId="add-tuneable-parameter"
          theme={ButtonTheme.Primary}
          icon={IconEnum.Add}
          size={BadgeSize.Small}
          className="w-fit"
          //   @ts-expect-error - it's not worth fixing this right now!
          onClick={() => append({ check: "", parameter: "", value: "" })}
          type="button"
        >
          Add parameter
        </Button>
      </div>
    </FormInputWrapper>
  );
};
