import { useAPI } from "src/utils/swr";

export const useInsightsLastSync: () => Date | undefined = () => {
  const { data, isLoading, error } = useAPI(
    "insightsGetLastSync",
    {},
    {
      refreshInterval: 30000,
    },
  );

  if (isLoading || error) {
    return undefined;
  }

  return data?.last_sync_at;
};
