// A reusable component for the floating action drawer
import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useContextualItems } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import { AnimatePresence, motion } from "framer-motion";
import pluralize from "pluralize";
import { useState } from "react";

import { useIdentity } from "../../../contexts/IdentityContext";
import { BulkAction, useBulkActions } from "./bulk-actions/useBulkActions";

export const IncidentsFloatingBar = ({
  selectedIncidentIDs,
  totalIncidents,
  setSelectedIncidentIDs,
  bulkActionsOverride,
  includesTriageIncidents = false,
  isAllTriageIncidents = false,
  refetchIncidents,
}: {
  selectedIncidentIDs: string[];
  totalIncidents: number;
  setSelectedIncidentIDs: (ids: string[]) => void;
  bulkActionsOverride?: BulkAction[];
  includesTriageIncidents: boolean;
  isAllTriageIncidents: boolean;
  refetchIncidents?: () => Promise<void>;
}) => {
  const { hasScope } = useIdentity();
  const defaultBulkActions = useBulkActions();
  const bulkActions = bulkActionsOverride ?? defaultBulkActions;
  const userCanBulkUpdateIncidents = hasScope(
    ScopeNameEnum.IncidentsBulkUpdate,
  );
  const [selectedAction, setSelectedAction] = useState<BulkAction | null>(null);

  const incidentsCount = selectedIncidentIDs.length;

  const cmdkTitle =
    incidentsCount > 0 && userCanBulkUpdateIncidents
      ? `${incidentsCount} selected ${pluralize("incident", incidentsCount)}`
      : "";
  const cmdkItems =
    selectedIncidentIDs.length > 0 && userCanBulkUpdateIncidents
      ? bulkActions.map((bulkAction) => ({
          label: bulkAction.label,
          analyticsId: `bulk_action_${bulkAction.name}`,
          key: `bulk_action_${bulkAction.name}`,
          icon: bulkAction.icon,
          onSelect: () => {
            if (bulkAction.form) {
              setSelectedAction(bulkAction);
            }
          },
        }))
      : [];
  useContextualItems(cmdkTitle, cmdkItems);

  const actionDisabledContent = (action: BulkAction) => {
    if (action.name === "set_incident_status" && includesTriageIncidents) {
      return "Your selection includes triage incidents, which must be accepted before setting a status";
    }
    if (action.name === "decline_incidents" && !isAllTriageIncidents) {
      return "Your selection includes non-triage incidents, which cannot be declined";
    }
    return null;
  };

  return (
    <>
      <AnimatePresence>
        {incidentsCount > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3, type: "spring", bounce: 0.2 }}
            className="absolute left-0 right-0 w-fit m-auto bottom-6 z-50"
          >
            <div className="bg-content-primary rounded-lg shadow-xl self-stretch flex items-center divide-x divide-solid divide-slate-700">
              <div className="text-sm font-medium px-4 py-1">
                <span className="text-content-invert">{incidentsCount} </span>
                <span className={"text-content-tertiary"}>
                  {" "}
                  / {totalIncidents} selected
                </span>
              </div>

              <div className={"p-1"}>
                <DropdownMenu
                  align="start"
                  side={"top"}
                  sideOffset={4}
                  triggerButton={
                    <GatedButton
                      icon={IconEnum.LayersBulk}
                      theme={ButtonTheme.Ghost}
                      className={
                        "hover:bg-surface-invert active:bg-surface-invert text-content-invert inline-flex items-center shadow-none"
                      }
                      iconProps={{ className: "text-content-invert" }}
                      analyticsTrackingId={"incidents-bulk-actions"}
                      requiredScope={ScopeNameEnum.IncidentsBulkUpdate}
                    >
                      Bulk action
                    </GatedButton>
                  }
                >
                  {bulkActions.map((action) => (
                    <DropdownMenuItem
                      key={action.name}
                      label={action.label}
                      icon={action.icon}
                      disabled={
                        !userCanBulkUpdateIncidents ||
                        !!actionDisabledContent(action)
                      }
                      tooltipContent={actionDisabledContent(action)}
                      iconProps={{
                        className:
                          "text-content-tertiary group-hover:text-slate-700",
                        size: IconSize.Large,
                      }}
                      onSelect={() => {
                        setSelectedAction(action);
                      }}
                      analyticsTrackingId={`incidents-bulk-action-${action.name}`}
                      tooltipSide="left"
                    />
                  ))}
                </DropdownMenu>
              </div>

              <div className="h-full w-px bg-gray-300 opacity-30"></div>

              <div className={"p-1"}>
                <Button
                  theme={ButtonTheme.Ghost}
                  className={
                    "hover:bg-surface-invert active:bg-surface-invert text-content-invert inline-flex items-center shadow-none"
                  }
                  size={BadgeSize.Large}
                  icon={IconEnum.Close}
                  analyticsTrackingId="incidents-deselect-all"
                  onClick={() => setSelectedIncidentIDs([])}
                >
                  Deselect all
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {selectedAction && selectedAction.form && (
        <selectedAction.form
          incidentIDs={selectedIncidentIDs}
          onSubmit={() => {
            setSelectedAction(null);
            if (refetchIncidents) refetchIncidents();
          }}
          onClose={() => {
            setSelectedAction(null);
          }}
        />
      )}
    </>
  );
};
