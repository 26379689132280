import { Button, ButtonTheme } from "@incident-ui";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { isDevelopment } from "../../utils/environment";
import AppIcon from "../alerts/images/orange-app-icon.png";

interface JWTPayload {
  exp?: number;
}

// Parse JWT without validation
const parseJWT = (token: string): JWTPayload | null => {
  try {
    const base64Url = token.split(".")[1];
    if (!base64Url) return null;

    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
};

// Check if JWT is expired
const isJWTExpired = (token: string): boolean => {
  try {
    const payload = parseJWT(token);
    if (!payload || !payload.exp) return false;

    // exp is in seconds, Date.now() is in milliseconds
    return payload.exp * 1000 < Date.now();
  } catch (error) {
    console.error("Error checking JWT expiration:", error);
    return false;
  }
};

export const DeepLinkRedirect = (): React.ReactElement => {
  const [queryParams] = useSearchParams();

  const jwt = queryParams.get("jwt");
  const failureReason = queryParams.get("failure_reason");

  const baseURL = isDevelopment() ? `incident-io-dev://` : `incident-io://`;
  const isExpired = useMemo(() => (jwt ? isJWTExpired(jwt) : false), [jwt]);

  // If you're testing this locally, you might want to disable our proxying localhost so that your phone
  // can load the JS bundle properly, e.g. this code in setupProxy.js:
  //   app.use("/static/js/bundle.js", function (req, res, next) {
  //     const forwarded = req.headers["x-forwarded-host"] ?? "";
  //     const port = process.env.PORT ?? 3000;
  //     if (
  //       req.hostname === "host.docker.internal" ||
  //       forwarded.includes("ngrok.io")
  //     ) {
  //       res.redirect(301, `http://127.0.0.1:${port}/static/js/bundle.js`);
  //     } else {
  //       next();
  //     }
  //   });

  return (
    <div className="flex flex-col h-full min-h-full items-center justify-center text-center bg-white gap-2">
      <img
        className="max-h-[72px] max-w-[72px]"
        src={AppIcon}
        alt="The icon of incident.io's On-call app"
      />
      <div className={"flex flex-col gap-2"}>
        {jwt && !isExpired ? (
          <>
            <h2 className="font-medium text-content-primary text-lg mt-2">
              Login to incident.io
            </h2>

            <Button
              theme={ButtonTheme.Primary}
              analyticsTrackingId={"deep-link-redirect"}
              href={`${baseURL}mobile_app/jwt?jwt=${jwt}`}
              openInNewTab
            >
              Get back into the app
            </Button>
          </>
        ) : (
          <>
            <h2 className="font-medium text-red-content text-lg mt-2">
              {isExpired
                ? "Login link expired"
                : `Failed to login${
                    failureReason ? `: ${failureReason}` : " for unknown reason"
                  }`}
            </h2>

            <Button
              theme={ButtonTheme.Primary}
              analyticsTrackingId={"deep-link-redirect"}
              href={`${baseURL}mobile_app/jwt${
                failureReason ? `?failure_reason=${failureReason}` : ""
              }`}
              openInNewTab
            >
              Return to the app
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
