import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

export interface BoxPlotValue {
  value: number;
  label: string;
  fill: string;
}

interface BoxPlotProps {
  values: BoxPlotValue[];
  formatter?: (value: number) => string;
  onSelectionChange?: (filters: { start: number; end: number } | null) => void;
  selection?: { start: number; end: number };
  showSelectionOverlay?: boolean;
  scatterVertically: boolean;
}

export const BoxPlot: React.FC<BoxPlotProps> = ({
  values,
  formatter = (v) => v.toString(),
  onSelectionChange,
  selection,
  scatterVertically,
  showSelectionOverlay,
}) => {
  const [chartDimensions, setChartDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  // Calculate chart dimensions on mount
  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setChartDimensions({ width, height });
    });

    resizeObserver.observe(containerRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  const data = React.useMemo(() => {
    return values.map((value) => {
      const hash = distinctHash(value.value);
      const y = scatterVertically ? hashToRange(hash, 90) + 20 : 60;
      return { x: value.value, y, fill: value.fill };
    });
  }, [values, scatterVertically]);

  const stats = React.useMemo(
    () => calculateBoxPlotStats(values.map((x) => x.value)),
    [values],
  );

  const CustomScatterTooltip = ({
    active,
    payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: TooltipProps<any, string>) => {
    if (!active || !payload || !payload[0]) return null;
    const currentValue = payload[0].payload.x;
    return (
      <div className="border border-stroke cursor-auto text-[10px] p-2 rounded-md transition bg-white text-content-primary shadow-md">
        {formatter(currentValue)}
      </div>
    );
  };

  const dataSpread = stats.xMax - stats.xMin;
  const lineWidth = dataSpread / 500;
  const effectiveWidth = chartDimensions.width - 40;

  const inverseXScale = useCallback(
    (pixelX: number) => {
      // Account for the left margin
      const adjustedX = pixelX - X_MARGIN;
      // Get the percentage of the width
      const percent = adjustedX / effectiveWidth;
      // Then multiply by spread and add minimum to get actual value
      return stats.xMin + percent * dataSpread;
    },
    [dataSpread, effectiveWidth, stats.xMin],
  );

  const showBoxPlot = data.length > 4;
  return (
    <div ref={containerRef} className="w-full h-10 relative">
      {showBoxPlot && (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="absolute top-0 left-0 opacity-30"
        >
          <ComposedChart
            margin={{ top: 0, right: X_MARGIN, bottom: 0, left: X_MARGIN }}
            data={data}
          >
            <XAxis
              type="number"
              dataKey="x"
              domain={[stats.xMin, stats.xMax]}
              tickFormatter={formatter}
              hide
            />
            <YAxis type="number" dataKey="y" domain={[0, 120]} hide />

            {/* Horizontal center line */}
            <ReferenceArea
              x1={stats.min}
              x2={stats.max}
              y1={69}
              y2={71}
              fill="#29292F"
              fillOpacity={1}
            />

            {/* The Box */}
            <ReferenceArea
              x1={stats.p25 - lineWidth}
              x2={stats.p75 - lineWidth}
              y1={60}
              y2={80}
              fill="#B3BEFF"
              fillOpacity={1}
              stroke="#29292F"
              strokeWidth={1}
            />

            {/* The vertical lines */}
            {Object.entries(stats)
              .filter(([key]) =>
                ["max", "min", "p75", "p50", "p25"].includes(key),
              )
              .map(([key, value]) => (
                <ReferenceArea
                  key={key}
                  id={key}
                  x1={Math.max(value - lineWidth, 0)}
                  x2={value}
                  y1={0}
                  y2={100}
                  fill="#29292F"
                  fillOpacity={1}
                />
              ))}
          </ComposedChart>
        </ResponsiveContainer>
      )}

      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          margin={{ top: 0, right: X_MARGIN, bottom: -15, left: X_MARGIN }}
          data={data}
        >
          <XAxis
            type="number"
            dataKey="x"
            domain={[stats.xMin, stats.xMax]}
            tickFormatter={formatter}
            className="text-[10px]"
          />
          <YAxis type="number" dataKey="y" domain={[0, 100]} hide />
          <Scatter
            data={data}
            fill="#3B82F6"
            line={false}
            dataKey="x"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            shape={(props: any) => {
              const { cx, cy } = props;
              return <circle cx={cx} cy={cy} r={4} fill={props.payload.fill} />;
            }}
          />
          <Tooltip content={CustomScatterTooltip} />
        </ComposedChart>
      </ResponsiveContainer>

      {effectiveWidth > 0 && !!onSelectionChange && (
        <RangeSelector
          inverseXScale={inverseXScale}
          minX={stats.xMin}
          maxX={stats.xMax}
          onSelectionChange={onSelectionChange}
        />
      )}

      {!!selection && showSelectionOverlay && (
        <SelectionOverlay
          selection={selection}
          xMin={stats.xMin}
          dataSpread={dataSpread}
        />
      )}
    </div>
  );
};

const X_MARGIN = 10;

const RangeSelector: React.FC<{
  inverseXScale: (value: number) => number;
  minX: number;
  maxX: number;
  onSelectionChange: (filters: { start: number; end: number } | null) => void;
}> = ({ inverseXScale, minX, maxX, onSelectionChange }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getRelativeX = useCallback(
    (clientX: number) => {
      if (!containerRef.current) return 0;
      const rect = containerRef.current.getBoundingClientRect();
      return clientX - rect.left;
    },
    [containerRef],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      const x = getRelativeX(e.clientX);
      setIsDragging(true);
      setSelectionStart(x);
      setSelectionEnd(x);
    },
    [getRelativeX],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;
      const x = getRelativeX(e.clientX);
      setSelectionEnd(x);
    },
    [isDragging, getRelativeX],
  );

  const handleMouseUp = useCallback(() => {
    if (!isDragging || !selectionStart || !selectionEnd) {
      setIsDragging(false);
      return;
    }

    const startValue = inverseXScale(selectionStart);
    const endValue = inverseXScale(selectionEnd);

    const clampedStart = Math.max(minX, Math.min(startValue, maxX));
    const clampedEnd = Math.max(minX, Math.min(endValue, maxX));

    onSelectionChange({
      start: Math.min(clampedStart, clampedEnd),
      end: Math.max(clampedStart, clampedEnd),
    });

    setIsDragging(false);
    setSelectionStart(null);
    setSelectionEnd(null);
  }, [
    isDragging,
    selectionStart,
    selectionEnd,
    inverseXScale,
    minX,
    maxX,
    onSelectionChange,
  ]);

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false);
    setSelectionStart(null);
    setSelectionEnd(null);
  }, []);

  return (
    <div
      ref={containerRef}
      className="absolute inset-0 cursor-crosshair"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {isDragging && selectionStart != null && selectionEnd != null && (
        <div
          className="absolute bg-blue-700 bg-opacity-20 border border-blue-700 pointer-events-none"
          style={{
            left: Math.min(selectionStart, selectionEnd) + X_MARGIN,
            width: Math.abs(selectionEnd - selectionStart),
            top: 0,
            height: "100%",
          }}
        />
      )}
    </div>
  );
};

const SelectionOverlay: React.FC<{
  selection: { start: number; end: number } | null;
  xMin: number;
  dataSpread: number;
}> = ({ selection, xMin, dataSpread }) => {
  if (!selection) {
    return null;
  }

  return (
    <div className="absolute z-2 inset-0 pointer-events-none mx-5">
      {/* Left overlay */}
      <div
        className="absolute h-full bg-slate-200 bg-opacity-70"
        style={{
          left: 0,
          width: `${((selection.start - xMin) / dataSpread) * 100}%`,
        }}
      />
      {/* Selection overlay */}
      <div
        className="absolute h-full border border-blue-700"
        style={{
          left: `${((selection.start - xMin) / dataSpread) * 100}%`,
          width: `${((selection.end - selection.start) / dataSpread) * 100}%`,
        }}
      />
      {/* Right overlay */}
      <div
        className="absolute h-full bg-slate-200 bg-opacity-70"
        style={{
          left: `${((selection.end - xMin) / dataSpread) * 100}%`,
          right: 0,
        }}
      />
    </div>
  );
};

export const calculateBoxPlotStats = (values: number[]) => {
  const sorted = [...values].sort((a, b) => a - b);
  const spread = sorted[sorted.length - 1] - sorted[0];
  const fivePercent = spread / 20;

  const minValue: number = sorted[0];
  const maxValue: number = sorted[sorted.length - 1];

  return {
    xMin: Math.floor(minValue - fivePercent),
    min: minValue,
    p25: sorted[Math.floor(sorted.length * 0.25)],
    p50: sorted[Math.floor(sorted.length * 0.5)],
    p75: sorted[Math.floor(sorted.length * 0.75)],
    max: maxValue,
    xMax: Math.ceil(maxValue + fivePercent),
  };
};

const distinctHash = (input: number) => {
  const str = input.toString().padStart(8, "0");
  const LARGE_PRIME = 2147483647;
  let hash = 5381;

  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) + hash) ^ str.charCodeAt(i);
    hash = ((hash << i % 16) | (hash >>> (32 - (i % 16)))) & 0xffffffff;
    hash = Math.imul(hash, LARGE_PRIME) & 0xffffffff;
  }

  return Math.abs(hash);
};

const hashToRange = (hash: number, max: number) => {
  const PHI = 0.61803398875;
  return Math.floor((hash * PHI) % max);
};
