import { EvaluationDatasetDimension } from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui/Badge/Badge";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

import { DatasetCollapsibleSection } from "./DatasetCollapsibleSection";

export interface DatasetDimensionsProps {
  dimensions: EvaluationDatasetDimension[];
  className?: string;
}

// Show summary metrics for a dataset including coverage of ground truths and if they are
// verified.
export const DatasetDimensions = ({
  dimensions,
  className,
}: DatasetDimensionsProps) => {
  const total = dimensions.length > 0 ? dimensions[0].total : 0;

  return (
    <DatasetCollapsibleSection
      title="Dimensions"
      icon={IconEnum.InsightsDashboard}
      accessory={
        <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
          {total} entries
        </Badge>
      }
      content={dimensions.map((dimension, index) => (
        <DimensionMetric key={index} dimension={dimension} total={total} />
      ))}
      isEmpty={dimensions.length === 0}
      emptyStateText="No dimensions available"
      className={className}
    />
  );
};

interface DimensionMetricProps {
  dimension: EvaluationDatasetDimension;
  total: number;
}

const DimensionMetric = ({ dimension, total }: DimensionMetricProps) => {
  const hasValuePercentage = (dimension.has_value_count / total) * 100;
  const verifiedPercentage = (dimension.verified_count / total) * 100;

  // Get indicator based on percentage of incidents that have a value
  const coverageIndicator = getDimensionIndicator(hasValuePercentage);

  return (
    <div className="p-4">
      <div className="flex items-start gap-2">
        <Icon
          id={coverageIndicator.icon}
          className={tcx("mt-1 shrink-0", coverageIndicator.color)}
        />
        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-2 flex-wrap">
            <span className="font-medium">{dimension.name}</span>
            <div className="flex gap-2">
              <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
                {dimension.has_value_count} / {total}
              </Badge>
              {dimension.has_value_count > 0 && (
                <Badge
                  theme={
                    verifiedPercentage === 100
                      ? BadgeTheme.Success
                      : BadgeTheme.Warning
                  }
                  size={BadgeSize.Small}
                >
                  {dimension.verified_count} verified
                </Badge>
              )}
            </div>
          </div>
          <div className="text-xs text-content-secondary mt-0.5">
            {dimension.description}
          </div>
          {dimension.has_value_count > 0 && (
            <div className="mt-2 flex gap-2">
              <ProgressBar
                percentage={hasValuePercentage}
                label="Has value"
                color={coverageIndicator.barColor}
              />
              <ProgressBar
                percentage={verifiedPercentage}
                label="Verified"
                color="bg-green-500"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ProgressBarProps {
  percentage: number;
  label: string;
  color: string;
}

const ProgressBar = ({ percentage, label, color }: ProgressBarProps) => (
  <div className="flex-1">
    <div className="text-xs text-content-secondary mb-1">
      {label} ({percentage.toFixed(1)}%)
    </div>
    <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
      <div
        className={tcx("h-full rounded-full transition-all", color)}
        style={{ width: `${Math.max(0, Math.min(100, percentage))}%` }}
      />
    </div>
  </div>
);

interface DimensionIndicator {
  icon: IconEnum;
  color: string;
  barColor: string;
}

const getDimensionIndicator = (percentage: number): DimensionIndicator => {
  if (percentage >= 70) {
    return {
      icon: IconEnum.TickCircle,
      color: "text-green-500",
      barColor: "bg-green-500",
    };
  }

  if (percentage >= 40) {
    return {
      icon: IconEnum.Circle,
      color: "text-amber-500",
      barColor: "bg-amber-500",
    };
  }

  return {
    icon: IconEnum.WarningCircle,
    color: "text-red-500",
    barColor: "bg-red-500",
  };
};
