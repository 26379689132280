import {
  FieldPathByValue,
  FieldValues,
  Path,
  UseFormReturn,
} from "react-hook-form";

import { FormInputWrapperProps } from "./helpers";
import { buildRules, FormRules, Rules } from "./rules";

type InputPropsWithoutControls<TInputProps> = Omit<
  TInputProps,
  "id" | "onChange" | "onBlur" | "value" | "ref"
>;

type WrapperPropsWithoutChildren<TFormType extends FieldValues> = Omit<
  FormInputWrapperProps<TFormType>,
  "children"
> & { name: Path<TFormType> };

export type InputElementProps<
  TFormType extends FieldValues,
  TInputProps,
  TValueType = unknown, // TValueType defaults to any if not specified.
> = InputPropsWithoutControls<TInputProps> &
  WrapperPropsWithoutChildren<TFormType> &
  FormRules & {
    name: FieldPathByValue<TFormType, TValueType>;
    formMethods: UseFormReturn<TFormType>;
  } & { inputClassName?: string };

export const parseProps = <TFormType extends FieldValues, TInputProps>(
  props: InputElementProps<TFormType, TInputProps>,
): {
  inputProps: InputPropsWithoutControls<TInputProps>;
  wrapperProps: WrapperPropsWithoutChildren<TFormType>;
  name: Path<TFormType>;
  rules: Rules;
} => {
  const {
    name,
    // Wrapper props
    label,
    labelAccessory,
    helptext,
    contextText,
    className,
    showErrorAboveComponent = false,
    labelClassName,
    labelWrapperClassName,
    inputWrapperClassName,
    contextTextClassName,
    helptextClassName,
    inputClassName,
    errorClassName,
    prefixNode,
    suffixNode,
    hideErrors,
    disableGrowInput,
    disabledTooltipContent,
    disabled,
    // RuleProps
    required,
    rules,
    // We pass this in for type-checking, rather than because it's useful
    formMethods: _formMethods,

    ...inputProps
  } = props;

  return {
    name,
    rules: buildRules(required, rules),
    wrapperProps: {
      name,
      label,
      labelAccessory,
      helptext,
      contextText,
      className,
      required,
      showErrorAboveComponent,
      labelClassName,
      labelWrapperClassName,
      inputWrapperClassName,
      contextTextClassName,
      helptextClassName,
      errorClassName,
      prefixNode,
      suffixNode,
      hideErrors,
      disableGrowInput,
      disabledTooltipContent,
      disabled,
    },
    // @ts-expect-error whatever remains must be the inputProps, but I can't yet work out
    // how to tell TS that. I think there's probably a better way of doing this!
    inputProps: { ...inputProps, className: inputClassName, disabled },
  };
};

export enum Mode {
  Create = "create",
  Edit = "edit",
}

export type CreateEditFormProps<T> =
  | {
      mode: Mode.Create;
      initialData?: never;
    }
  | {
      mode: Mode.Edit;
      initialData: T;
    };

export const isEditMode = (mode: Mode): boolean => {
  return mode === Mode.Edit;
};

export const isCreateMode = (mode: Mode): boolean => {
  return mode === Mode.Create;
};
