import { Prompt } from "@incident-shared/utils/Prompt";
import { Button, ButtonTheme, ContentBox } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect, useState } from "react";
import {
  DecisionFlowsCreateRequestBody as CreateReqBody,
  DecisionFlowsUpdateRequestBody as UpdateReqBody,
  DecisionTree as Tree,
  DependentResource,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { EditNodeModal } from "./modals/EditNodeModal";
import { EditMetadataModal } from "./modals/MetadataModals";
import { EditDecisionFlowRenderer } from "./Renderer";

export enum Modes {
  Create = "create",
  Edit = "edit",
}

const validate = (
  decisionFlow: CreateReqBody | UpdateReqBody,
): string | null => {
  if (!decisionFlow.tree.root.name || !decisionFlow.tree.root.prompt) {
    return "Please configure the root node";
  }

  let e: string | null = null;

  decisionFlow.tree.nodes.forEach((node) => {
    if (!node.name || !node.prompt) {
      e = "Please configure all nodes before saving";
      return;
    }
  });

  return e;
};

export const EditDecisionFlowController = <Mode extends Modes>({
  isDirty,
  setIsDirty,
  decisionFlowId,
  decisionFlow: initialDecisionFlow,
  dependentResources,
  onClear,
  onCreated,
}: {
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  decisionFlowId: Mode extends Modes.Create ? null : string;
  decisionFlow: Mode extends Modes.Create ? CreateReqBody : UpdateReqBody;
  dependentResources: DependentResource[];
} & (Mode extends Modes.Create
  ? { onClear?: never; onCreated: (flowId: string) => void }
  : { onClear: () => void; onCreated?: never })): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  // To avoid a nested form (which has some scary accidental double submit behaviour),
  // we are managing the top level state manually, and then using useForm for each of the modals.
  // We may change our minds if this ends up being nasty.
  const [decisionFlow, setDecisionFlow] =
    useState<Mode extends Modes.Create ? CreateReqBody : UpdateReqBody>(
      initialDecisionFlow,
    );

  const [editingNodeId, setEditingNodeId] = useState<number | null>(null);
  const [editingMetadata, setEditingMetadata] = useState<boolean>(false);
  const [err, setError] = useState<string | null>(null);

  // if the decisionFlow from our parent changes, we should change our one too
  useEffect(() => {
    setDecisionFlow(initialDecisionFlow);
  }, [initialDecisionFlow]);

  const onUpdateMetadata = (data: CreateReqBody | UpdateReqBody) => {
    setDecisionFlow((current) => ({
      ...current,
      ...data,
    }));
    if (!isDirty) {
      setIsDirty(true);
    }
    setError(null);
    setEditingMetadata(false);
  };

  const onUpdateTree = (tree: Tree) => {
    setDecisionFlow((current) => ({
      ...current,
      tree: tree,
    }));
    if (!isDirty) {
      setIsDirty(true);
    }
    setError(null);
    setEditingNodeId(null);
  };

  let modal: React.ReactNode = null;
  if (editingMetadata) {
    modal = (
      <EditMetadataModal
        decisionFlow={decisionFlow}
        onClose={() => setEditingMetadata(false)}
        onSave={onUpdateMetadata}
      />
    );
    // be careful - this could maybe be 0!
  } else if (editingNodeId != null) {
    modal = (
      <EditNodeModal
        editingNodeId={editingNodeId}
        decisionTree={decisionFlow.tree}
        onClose={() => setEditingNodeId(null)}
        onSave={onUpdateTree}
      />
    );
  }

  // Set up the saving.
  const { trigger: triggerSave, isMutating: saving } = useAPIMutation(
    "decisionFlowsList",
    undefined,
    async (
      apiClient,
      data: Mode extends Modes.Create ? CreateReqBody : UpdateReqBody,
    ) => {
      if (decisionFlowId != null) {
        await apiClient.decisionFlowsUpdate({
          id: decisionFlowId,
          updateRequestBody: data,
        });
      } else {
        const res = await apiClient.decisionFlowsCreate({
          createRequestBody: data,
        });
        onCreated && onCreated(res.decision_flow.id);
      }
    },
    {
      onSuccess: () => {
        setIsDirty(false);
      },
      setError: (_, err) => {
        // This will be called several times, but it's the easiest way to handle the
        // error somewhere that we're not using react-hook-form.
        err.message && setError(err.message);
      },
    },
  );

  const onSave = () => {
    // Reset the error at the start
    setError(null);
    const e = validate(decisionFlow);
    if (e) {
      setError(e);
      return;
    }
    triggerSave(decisionFlow);
  };

  // Set up the deleting
  const showToast = useToast();
  const { trigger: triggerDelete } = useAPIMutation(
    "decisionFlowsList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.decisionFlowsDestroy({ id });
    },
    {
      onSuccess: () => {
        onClear && onClear();
        showToast({
          title: "Decision flow deleted",
          theme: ToastTheme.Success,
        });
      },
    },
  );

  return (
    <>
      {modal}
      <ContentBox className="mb-24 p-6">
        <EditDecisionFlowRenderer
          decisionFlow={decisionFlow}
          onEditMetadata={() => setEditingMetadata(true)}
          onEditNode={setEditingNodeId}
          dependentResources={dependentResources}
          onDelete={
            decisionFlowId == null
              ? undefined
              : () => triggerDelete({ id: decisionFlowId })
          }
        />
        <Prompt
          when={isDirty}
          message="Your changes are unsaved, are you sure you want to navigate away?"
        />
        <div className="flex items-center justify-end gap-3 mt-3">
          <div className="text-sm text-red-600 break-words">{err}</div>
          <Button
            theme={ButtonTheme.Primary}
            loading={saving}
            disabled={!isDirty || !canEditSettings}
            onClick={onSave}
            type="button"
            analyticsTrackingId="save-decision-flow"
          >
            Save changes
          </Button>
        </div>
      </ContentBox>
    </>
  );
};
