/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianWidgetStatusComponent,
    AtlassianWidgetStatusComponentFromJSON,
    AtlassianWidgetStatusComponentFromJSONTyped,
    AtlassianWidgetStatusComponentToJSON,
} from './AtlassianWidgetStatusComponent';

/**
 * 
 * @export
 * @interface StatusPageContentAtlassianWidgetComponentsResponseBody
 */
export interface StatusPageContentAtlassianWidgetComponentsResponseBody {
    /**
     * List of components and their current status
     * @type {Array<AtlassianWidgetStatusComponent>}
     * @memberof StatusPageContentAtlassianWidgetComponentsResponseBody
     */
    components: Array<AtlassianWidgetStatusComponent>;
}

export function StatusPageContentAtlassianWidgetComponentsResponseBodyFromJSON(json: any): StatusPageContentAtlassianWidgetComponentsResponseBody {
    return StatusPageContentAtlassianWidgetComponentsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentAtlassianWidgetComponentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentAtlassianWidgetComponentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(AtlassianWidgetStatusComponentFromJSON)),
    };
}

export function StatusPageContentAtlassianWidgetComponentsResponseBodyToJSON(value?: StatusPageContentAtlassianWidgetComponentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(AtlassianWidgetStatusComponentToJSON)),
    };
}

