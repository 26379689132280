import { InterpolatedRef } from "@incident-shared/forms/v1/TemplatedText";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { DeprecatedTextEditorV2 } from "@incident-shared/forms/v2/inputs/DeprecatedTextEditorV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import {
  isSelectOption,
  isSelectOptionGroup,
  SelectOptionOrGroup,
} from "@incident-ui/Select/types";
import { compact } from "lodash";
import React from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Format } from "src/components/@shared/forms/v1/TemplatedText/formats";
import { DynamicSingleSelectWithObjV2 } from "src/components/@shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "src/components/@shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectWithObjV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectWithObjV2";
import { TemplatedTextInputAsStringV2 } from "src/components/@shared/forms/v2/inputs/TemplatedTextInputV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  EngineScope,
  Reference,
  ReferenceIconEnum,
  Resource,
  ResourceFieldConfigTypeEnum as FormFieldType,
} from "src/contexts/ClientContext";
import { EnrichedScope, enrichScope, filterScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable, sendToSentry } from "src/utils/utils";

import { BoundParamTypeaheadFn, ParamBindingOptionOrGroup } from "../helpers";
import { EngineDurationInputV2 } from "./EngineDurationInput";
import { EngineSlackChannelEditor } from "./EngineSlackChannelEditor";
import { InputOrVariable } from "./InputOrVariable";
import { ParamFormElementProps } from "./MultiValueEngineFormElement";

export const SingleValueEngineFormElement = <FormType extends FieldValues>({
  name,
  formFieldType,
  label,
  labelNode,
  labelAccessory,
  helptext,
  placeholder,
  scopeAndIsAlert,
  resources,
  resource,
  required,
  loadTypeaheadOptions,
  disabled,
  disabledTooltipContent,
  includeExpressions,
  includeVariables,
  includeStatic,
  expressionLabelOverride,
  suffixNode,
  headerNode,
  className,
  onDeleteExpression,
  onEditExpression,
  onClickVariableButton,
  optionIconOverride,
}: ParamFormElementProps<FormType> & {
  formFieldType: FormFieldType;
  isAlertElement?: boolean;
}): React.ReactElement | null => {
  const preventIncludeVariable = [
    FormFieldType.TemplatedTextEditorInput,
    FormFieldType.RichTextEditorInput,
  ].includes(formFieldType);

  return (
    <Form.InputWrapper
      label={labelNode || label}
      labelAccessory={labelAccessory}
      helptext={helptext}
      name={name}
      className={className}
      suffixNode={suffixNode}
      required={required}
      disabled={disabled}
      disabledTooltipContent={disabledTooltipContent}
    >
      <InputOrVariable
        name={name}
        formFieldType={formFieldType}
        label={label}
        scopeAndIsAlert={scopeAndIsAlert}
        resource={resource}
        required={required}
        disabled={disabled}
        array={false}
        includeExpressions={includeExpressions && !preventIncludeVariable}
        includeStatic={includeStatic}
        onEditExpression={onEditExpression}
        onDeleteExpression={onDeleteExpression}
        includeVariables={includeVariables && !preventIncludeVariable}
        expressionLabelOverride={expressionLabelOverride}
        onClickVariableButton={onClickVariableButton}
        renderChildren={(renderLightningButton) => (
          <SingleValueElement
            formFieldType={formFieldType}
            name={name}
            scope={scopeAndIsAlert.scope}
            placeholder={placeholder}
            loadTypeaheadOptions={loadTypeaheadOptions}
            disabled={disabled}
            required={required}
            resources={resources}
            resource={resource}
            includeVariables={includeVariables}
            includeExpressions={includeExpressions}
            renderLightningButton={renderLightningButton}
            optionIconOverride={optionIconOverride}
            headerNode={headerNode}
          />
        )}
      />
    </Form.InputWrapper>
  );
};

// This should match format.go in the backend
type TemplatedTextResourceType = `TemplatedText["${
  | "plain_single_line"
  | "plain_multi_line"
  | "mrkdwn"
  | "rich"
  | "slack_rich_text"
  | "basic"
  | "jira"}"]`;

const SingleValueElement = <FormType extends FieldValues>({
  formFieldType,
  name,
  placeholder,
  loadTypeaheadOptions,
  disabled,
  scope,
  resources,
  resource,
  includeVariables,
  includeExpressions,
  className,
  required,
  renderLightningButton,
  optionIconOverride,
  headerNode,
}: {
  formFieldType: FormFieldType;
  name: Path<FormType>;
  placeholder: string;
  loadTypeaheadOptions: BoundParamTypeaheadFn;
  disabled?: boolean;
  scope: EngineScope;
  resources: Resource[];
  resource: Resource;
  includeVariables: boolean;
  includeExpressions: boolean;
  className?: string;
  required?: boolean;
  optionIconOverride?: IconEnum;
  renderLightningButton: () => React.ReactNode;
  headerNode?: React.ReactNode;
}): React.ReactElement | null => {
  const formMethods = useFormContext<FormType>();

  const sharedProps = {
    placeholder,
    disabled,
    formMethods,
    className: tcx(className || "", "grow"),
    isClearable: !required,
    clearable: !required,
  };
  const literalName = `${name}.literal` as Path<FormType>;
  const labelName = `${name}.label` as Path<FormType>;

  switch (formFieldType) {
    case FormFieldType.TextInput:
      return (
        <InputV2
          {...sharedProps}
          name={literalName}
          onValueChange={(val) => {
            formMethods.setValue(
              labelName,
              val as PathValue<FormType, Path<FormType>>,
            );
          }}
          type={InputType.Text}
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.TextNumberInput:
      return (
        <InputV2
          {...sharedProps}
          name={literalName}
          type={InputType.Number}
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.TextAreaInput:
      return (
        <TextareaV2
          {...sharedProps}
          name={literalName}
          suffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.Checkbox:
      return (
        <RadioButtonGroupV2
          {...sharedProps}
          srLabel=""
          boxed
          horizontal
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          name={literalName}
          suffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.RichTextEditorInput:
      return (
        <DeprecatedTextEditorV2<FormType>
          formMethods={formMethods}
          name={literalName}
          placeholder={placeholder}
          // In general, most things are normal, boring markdown. SlackMrkdwn is
          // kinda a special case.
          format={"markdown"}
          disabled={disabled}
          className={tcx("w-full", className)}
        />
      );
    case FormFieldType.TemplatedTextEditorInput:
      const inputProps = formatFor(resource.type as TemplatedTextResourceType);
      return (
        <TemplatedTextInputAsStringV2
          {...sharedProps}
          name={literalName}
          format={inputProps.format}
          includeVariables={includeVariables}
          includeExpressions={includeExpressions}
          scope={scope}
          resources={resources}
          disabled={disabled}
          multiLine={inputProps.multiLine}
          headerNode={headerNode}
          placeholder={placeholder}
        />
      );
    case FormFieldType.DateInput:
      return (
        <InputV2
          {...sharedProps}
          name={literalName}
          type={InputType.Date}
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.DateTimeInput:
      return (
        <DateTimeInputV2
          {...sharedProps}
          name={literalName}
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.DurationInput:
      return (
        <EngineDurationInputV2
          {...sharedProps}
          name={name}
          suffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.SingleStaticSelect:
      return (
        <StaticSingleSelectWithObjV2
          {...sharedProps}
          options={selectOptionsToParamBindingOptions(resource)}
          name={name}
          optionColor={
            resource.field_config.color as unknown as ColorPaletteEnum
          }
          optionIcon={optionIconOverride || resource.field_config.icon}
          hideErrors
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.SingleExternalSelect:
    case FormFieldType.TextInputWithAutocomplete:
    case FormFieldType.SingleDynamicSelect:
      const loadOptions = async (inputValue: string) => {
        const opts = await loadTypeaheadOptions(inputValue);
        return selectOptionsToParamBindingOptions(resource, opts);
      };
      return (
        <DynamicSingleSelectWithObjV2
          {...sharedProps}
          optionColor={
            resource.field_config.color as unknown as ColorPaletteEnum
          }
          optionIcon={optionIconOverride || resource.field_config.icon}
          loadOptions={loadOptions}
          name={name}
          hideErrors
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.SlackChannelInput:
      return (
        <EngineSlackChannelEditor
          {...sharedProps}
          name={name}
          resource={resource}
          insetSuffixNode={renderLightningButton()}
        />
      );
    case FormFieldType.None:
      throw sendToSentry("unreachable: cannot render form field type none.", {
        resource,
      });
    default:
      assertUnreachable(formFieldType);
  }
  return null;
};

type InputProps = {
  format: Format;
  multiLine: boolean;
};

const formatFor = (resourceType: TemplatedTextResourceType): InputProps => {
  switch (resourceType) {
    case 'TemplatedText["plain_single_line"]':
      return { format: "plain", multiLine: false };
    case 'TemplatedText["plain_multi_line"]':
      return { format: "plain", multiLine: true };
    case 'TemplatedText["rich"]':
      return { format: "rich", multiLine: true };
    case 'TemplatedText["basic"]':
      return { format: "basic", multiLine: true };
    case 'TemplatedText["mrkdwn"]':
      return { format: "mrkdwn", multiLine: true };
    case 'TemplatedText["slack_rich_text"]':
      return { format: "slack_rich_text", multiLine: true };
    case 'TemplatedText["jira"]':
      return { format: "jira", multiLine: true };
    default:
      assertUnreachable(resourceType);
      // Fallback to a reasonable default
      return { format: "plain", multiLine: false };
  }
};

export const getVariableScope = (
  scope: EngineScope,
  resources: Resource[],
): EnrichedScope<InterpolatedRef> => {
  const variableScope = enrichScope<InterpolatedRef>(
    scope,
    (reference: Reference) => {
      const resource = resources.find((r) => r.type === reference.type);
      return {
        ...reference,
        icon: (reference.icon ||
          resource?.field_config.icon) as unknown as ReferenceIconEnum,
        can_be_interpolated: resource?.can_be_interpolated || false,
      };
    },
  );
  return filterScope(variableScope, (ref) => ref.can_be_interpolated);
};

export const selectOptionsToParamBindingOptions = (
  resource: Resource,
  options?: SelectOptionOrGroup[],
): ParamBindingOptionOrGroup[] => {
  if (options === undefined) {
    options = [...(resource.options || []), ...(resource.option_groups || [])];
  }

  return compact(
    options.map((opt) => {
      if (isSelectOption(opt)) {
        return {
          label: opt.label,
          value: opt.value,
          literal: opt.value,
          image_url: opt.image_url,
          is_image_slack_icon: resource.type === "SlackTeam",
          sort_key: opt.sort_key || "",
        };
      }

      if (isSelectOptionGroup(opt)) {
        return {
          label: opt.label,
          options: opt.options.map((opt) => ({
            label: opt.label,
            value: opt.value,
            literal: opt.value,
            image_url: opt.image_url,
            is_image_slack_icon: resource.type === "SlackTeam",
            sort_key: opt.sort_key || "",
          })),
        };
      }

      return null;
    }),
  );
};
