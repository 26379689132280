/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SequenceDetails,
    SequenceDetailsFromJSON,
    SequenceDetailsFromJSONTyped,
    SequenceDetailsToJSON,
} from './SequenceDetails';
import {
    StripeDetails,
    StripeDetailsFromJSON,
    StripeDetailsFromJSONTyped,
    StripeDetailsToJSON,
} from './StripeDetails';

/**
 * 
 * @export
 * @interface StaffShowBillingDetailsResponseBody
 */
export interface StaffShowBillingDetailsResponseBody {
    /**
     * 
     * @type {SequenceDetails}
     * @memberof StaffShowBillingDetailsResponseBody
     */
    sequence_details?: SequenceDetails;
    /**
     * 
     * @type {StripeDetails}
     * @memberof StaffShowBillingDetailsResponseBody
     */
    stripe_details?: StripeDetails;
}

export function StaffShowBillingDetailsResponseBodyFromJSON(json: any): StaffShowBillingDetailsResponseBody {
    return StaffShowBillingDetailsResponseBodyFromJSONTyped(json, false);
}

export function StaffShowBillingDetailsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffShowBillingDetailsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sequence_details': !exists(json, 'sequence_details') ? undefined : SequenceDetailsFromJSON(json['sequence_details']),
        'stripe_details': !exists(json, 'stripe_details') ? undefined : StripeDetailsFromJSON(json['stripe_details']),
    };
}

export function StaffShowBillingDetailsResponseBodyToJSON(value?: StaffShowBillingDetailsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sequence_details': SequenceDetailsToJSON(value.sequence_details),
        'stripe_details': StripeDetailsToJSON(value.stripe_details),
    };
}

