/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationBacktest,
    EvaluationBacktestFromJSON,
    EvaluationBacktestFromJSONTyped,
    EvaluationBacktestToJSON,
} from './EvaluationBacktest';

/**
 * 
 * @export
 * @interface AIStaffUpdateEvaluationBacktestResponseBody
 */
export interface AIStaffUpdateEvaluationBacktestResponseBody {
    /**
     * 
     * @type {EvaluationBacktest}
     * @memberof AIStaffUpdateEvaluationBacktestResponseBody
     */
    backtest: EvaluationBacktest;
}

export function AIStaffUpdateEvaluationBacktestResponseBodyFromJSON(json: any): AIStaffUpdateEvaluationBacktestResponseBody {
    return AIStaffUpdateEvaluationBacktestResponseBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateEvaluationBacktestResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateEvaluationBacktestResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest': EvaluationBacktestFromJSON(json['backtest']),
    };
}

export function AIStaffUpdateEvaluationBacktestResponseBodyToJSON(value?: AIStaffUpdateEvaluationBacktestResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest': EvaluationBacktestToJSON(value.backtest),
    };
}

