import {
  FollowUpPriority,
  Identity,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
  Settings,
  SettingsUpdateFollowUpPriorityRequiredRequestBody,
} from "@incident-io/api";
import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import { NoPermissionMessage } from "@incident-shared/gates/gates";
import {
  BadgeTheme,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { sortBy } from "lodash";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSortableList } from "../SettingsSortableList";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { UpsellNotice } from "../UpsellNotice";
export const PrioritiesViewSection = (): React.ReactElement => {
  const { hasScope, identity } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { settings } = useSettings();

  const { data, isLoading, error } = useAPI(
    "followUpsListPriorityOptions",
    undefined,
  );
  const followUpPriorityOptions = sortBy(
    data?.priority_options || [],
    (field) => field.rank,
  );

  if (isLoading || !settings || !identity) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const upgradeRequiredMessage = !identity.feature_gates
    .follow_up_priorities_customisable ? (
    <UpgradeRequiredMessage
      featureName={"customising follow-up priorities"}
      gate={{ type: "boolean" }}
    />
  ) : undefined;

  return (
    <>
      {!identity.feature_gates.follow_up_priorities ? (
        <UpsellNotice
          analyticsId={"follow-up-priorities"}
          title={"Priorities"}
          planName={"Pro"}
          description={
            "Use priorities with follow-ups to assign the importance of a task relative to others within the same incident."
          }
          learnMoreURL={
            "https://incident.io/changelog/2023-01-19-follow-up-priorities"
          }
        />
      ) : (
        <div>
          <SettingsSubHeading
            title={"Priorities"}
            accessory={
              <GatedButton
                theme={ButtonTheme.Secondary}
                href="priorities/create"
                analyticsTrackingId="add-follow-up-priority"
                icon={IconEnum.Add}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                upgradeRequired={
                  !identity.feature_gates.follow_up_priorities_customisable
                }
                upgradeRequiredProps={{
                  gate: { type: "boolean" },
                  featureName: "customising follow-up priorities",
                }}
              >
                Add new priority
              </GatedButton>
            }
            explanation={`Use priorities with follow-ups to assign the importance of a task relative to
                  others within the same incident.`}
          />
          <div className="flex flex-col space-y-4">
            <PriorityRequiredSettingSection
              identity={identity}
              settings={settings}
              canEditSettings={canEditSettings}
              upgradeRequiredMessage={upgradeRequiredMessage}
            />
            <PrioritiesList
              identity={identity}
              followUpPriorityOptions={followUpPriorityOptions}
              canEditSettings={canEditSettings}
              upgradeRequiredMessage={upgradeRequiredMessage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const PriorityRequiredSettingSection = ({
  identity,
  settings,
  canEditSettings,
  upgradeRequiredMessage,
}: {
  identity: Identity;
  settings: Settings;
  canEditSettings: boolean;
  upgradeRequiredMessage: React.ReactElement | undefined;
}): React.ReactElement => {
  const { trigger: saveState } = useAPIMutation(
    "settingsShow",
    undefined,
    async (
      apiClient,
      data: SettingsUpdateFollowUpPriorityRequiredRequestBody,
    ) => {
      await apiClient.settingsUpdateFollowUpPriorityRequired({
        updateFollowUpPriorityRequiredRequestBody: data,
      });
    },
  );

  const { setState, state, hasSaved, saving } =
    useOptimisticAutoSave<SettingsUpdateFollowUpPriorityRequiredRequestBody>({
      initialState: settings.misc,
      saveState: async (
        data: SettingsUpdateFollowUpPriorityRequiredRequestBody,
      ) => {
        await saveState(data);
      },
    });

  return (
    <>
      <ContentBox className={"p-6 flex flex-row justify-between"}>
        <GatedToggle
          id="follow_up_priority_required"
          disabled={
            !canEditSettings ||
            !identity.feature_gates.follow_up_priorities_customisable
          }
          disabledTooltipContent={
            !identity.feature_gates.follow_up_priorities_customisable ? (
              upgradeRequiredMessage
            ) : !canEditSettings ? (
              <>{NoPermissionMessage}</>
            ) : undefined
          }
          align="left"
          label="Require follow-ups to have a priority"
          on={state.follow_up_priority_required}
          onToggle={() =>
            setState({
              ...state,
              follow_up_priority_required: !state.follow_up_priority_required,
            })
          }
        />
        <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
      </ContentBox>
    </>
  );
};

export const PrioritiesList = ({
  identity,
  followUpPriorityOptions,
  canEditSettings,
  upgradeRequiredMessage,
}: {
  identity: Identity;
  followUpPriorityOptions: FollowUpPriority[];
  canEditSettings: boolean;
  upgradeRequiredMessage: React.ReactElement | undefined;
}): React.ReactElement => {
  const { trigger: updateRanks, isMutating: updatingRanks } = useAPIMutation(
    "followUpsListPriorityOptions",
    undefined,
    async (apiClient, updatedFields: FollowUpPriority[]) => {
      const rank_updates = followUpPriorityOptions.map((field) => {
        let rank = field.rank;
        const updated = updatedFields.find((x) => x.id === field.id);
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: field.id,
          rank,
        };
      });
      const res = await apiClient.followUpsUpdatePriorityOptionRanks({
        updatePriorityOptionRanksRequestBody: { rank_updates },
      });
      return res;
    },
  );

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "followUpsListPriorityOptions",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.followUpsDestroyPriorityOption({ id });
    },
  );

  const saving = updatingRanks || deleting;

  if (followUpPriorityOptions.length === 0) {
    return (
      <EmptyState
        icon={IconEnum.Warning}
        content="You haven't added any priorities yet."
      />
    );
  }

  return (
    <>
      <PrioritySyncCallout />
      <SettingsSortableList
        canEdit={canEditSettings}
        updateItemRanks={updateRanks}
        saving={saving}
        items={_.sortBy(followUpPriorityOptions, (field) => field.rank)}
        getGatedContent={() => upgradeRequiredMessage}
        renderItem={(followUpPriorityOption) => (
          <FollowUpPriorityRow
            followUpPriorityOption={followUpPriorityOption}
            onDelete={() => onDelete({ id: followUpPriorityOption.id })}
            disabled={!identity.feature_gates.follow_up_priorities_customisable}
          />
        )}
      />
    </>
  );
};

const PrioritySyncCallout = () => {
  const { settings } = useSettings();
  const { integrations } = useIntegrations();
  const trackersWithPriorities = (integrations ?? [])
    .filter(
      (integration) =>
        integration.installed &&
        [
          IntegrationSettingsProviderEnum.ClickUp,
          IntegrationSettingsProviderEnum.Jira,
          IntegrationSettingsProviderEnum.JiraServer,
          IntegrationSettingsProviderEnum.Linear,
        ].includes(integration.provider),
    )
    .map((integration) => integration.provider_name);

  const syncEnabled = settings?.misc?.follow_up_priority_sync;
  const showToast = useToast();

  const { trigger, isMutating } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient) => {
      await apiClient.followUpsEnableFollowUpPrioritySync();
    },
    {
      onSuccess: () =>
        showToast({
          title: "Priority sync enabled!",
        }),
    },
  );

  if (trackersWithPriorities.length === 0) {
    return null;
  }

  if (syncEnabled) {
    return (
      <Callout
        theme={CalloutTheme.Info}
        title={"Syncing priorities"}
        subtitle={
          <>
            When you set the priority on a follow-up exported to{" "}
            {joinSpansWithCommasAndConnectorWord(trackersWithPriorities, "or")},
            we will update the priority on the follow-up if its name matches one
            of the priorities you&rsquo;ve set here.
          </>
        }
      />
    );
  }

  return (
    <Callout
      theme={CalloutTheme.Info}
      showIcon
      iconOverride={IconEnum.Sync}
      cta={
        <GatedButton
          theme={ButtonTheme.Tertiary}
          onClick={() => trigger({})}
          analyticsTrackingId="enable-priority-sync"
          loading={isMutating}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Enable
        </GatedButton>
      }
    >
      <div className="font-medium mb-1.5">Syncing priorities</div>
      <p className="mb-1.5">
        You can now choose to have priorities on exported follow-ups managed
        directly in{" "}
        {joinSpansWithCommasAndConnectorWord(trackersWithPriorities, "or")}, so
        long as your priority names match those you&rsquo;ve set here.
      </p>
      <p>
        Note that this may change the priority for previous follow-ups, if the
        priority doesn&rsquo;t match between incident.io and your issue tracker,
        and so could create new policy violations.
      </p>
    </Callout>
  );
};

const FollowUpPriorityRow = ({
  followUpPriorityOption,
  onDelete,
  disabled,
}: {
  followUpPriorityOption: FollowUpPriority;
  onDelete: () => Promise<unknown>;
  disabled: boolean;
}): React.ReactElement => {
  return (
    <SettingsListItem
      title={followUpPriorityOption.name}
      disabled={disabled}
      description={followUpPriorityOption.description}
      badgeProps={
        followUpPriorityOption.is_default
          ? {
              theme: BadgeTheme.Tertiary,
              label: "Default",
            }
          : undefined
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        edit: {
          editHref: `/settings/follow-ups/priorities/${followUpPriorityOption.id}/edit`,
        },
        delete: {
          onDelete,
          isGatedText: followUpPriorityOption.is_default
            ? "You can't delete the default priority"
            : undefined,
          deleteConfirmationTitle: "Delete follow-up priority",
          deleteConfirmationContent: (
            <>
              Are you sure you wish to delete the{" "}
              <span className="font-bold">{followUpPriorityOption.name}</span>{" "}
              priority?
            </>
          ),
          resourceTitle: followUpPriorityOption.name,
          fetchDependentResources: [
            {
              resource_type: "IncidentFollowUpPriorityOption",
              id: followUpPriorityOption.id,
            },
          ],
        },
      }}
    />
  );
};
