/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface AlertRouteChannelTarget
 */
export interface AlertRouteChannelTarget {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertRouteChannelTarget
     */
    binding: EngineParamBinding;
    /**
     * The visibility of the channel
     * @type {string}
     * @memberof AlertRouteChannelTarget
     */
    channel_visibility: string;
}

export function AlertRouteChannelTargetFromJSON(json: any): AlertRouteChannelTarget {
    return AlertRouteChannelTargetFromJSONTyped(json, false);
}

export function AlertRouteChannelTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteChannelTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': EngineParamBindingFromJSON(json['binding']),
        'channel_visibility': json['channel_visibility'],
    };
}

export function AlertRouteChannelTargetToJSON(value?: AlertRouteChannelTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingToJSON(value.binding),
        'channel_visibility': value.channel_visibility,
    };
}

