import { AlertRouteSlim, AlertSourceConfig } from "@incident-io/api";
import _ from "lodash";

// groupSourcesAndRoutes takes a list of alert sources and alert routes and groups them based on what
// references they have and how many they have.
export const groupSourcesAndRoutes = (
  alertSources: AlertSourceConfig[],
  alertRoutes: AlertRouteSlim[],
) => {
  const groupedAlertRoutes = _.groupBy(alertRoutes, (route) => {
    return _.sortBy(route.alert_sources, (a) => a)
      .map((a) => a.alert_source_id)
      .join(",");
  });

  let sortedGroupedAlertRoutes = _.orderBy(
    Object.values(groupedAlertRoutes),
    (group) => {
      if (group.length > 0 && group[0].alert_sources.length === 0) {
        return 0;
      }
      return group[0].alert_sources.length;
    },
    ["desc"],
  );

  sortedGroupedAlertRoutes = sortedGroupedAlertRoutes.map((group) => {
    return _.orderBy(group, [(route) => route.name], ["asc"]);
  });

  const seenAlertSources = new Map<string, boolean>();
  const sortedGroupedAlertSources: AlertSourceConfig[][] = [];

  sortedGroupedAlertRoutes.forEach((group) => {
    const newGroup: AlertSourceConfig[] = [];
    // We group alert sources according to the way we've grouped alert routes.
    for (const route of group) {
      const associatedSources = route.alert_sources
        .map((route) => {
          return alertSources.find(
            (source) => source.id === route.alert_source_id,
          );
        })
        .filter((source) => source !== undefined);

      associatedSources.forEach((source) => {
        if (!seenAlertSources.has(source.id)) {
          newGroup.push(source);
          seenAlertSources.set(source.id, true);
        }
      });
    }

    if (newGroup.length > 0) {
      sortedGroupedAlertSources.push(orderAlertSources(newGroup));
    }
  });

  // Add unreferenced alert sources
  const unreferencedAlertSources: AlertSourceConfig[] = orderAlertSources(
    alertSources.filter(
      (alertSource) =>
        alertSource.alert_route_ids.length === 0 ||
        !seenAlertSources.has(alertSource.id),
    ),
  );
  sortedGroupedAlertSources.push(unreferencedAlertSources);

  return {
    groupedAlertSources: sortedGroupedAlertSources,
    groupedAlertRoutes: sortedGroupedAlertRoutes,
  };
};

export const orderAlertSources = (alertSources: AlertSourceConfig[]) => {
  return _.orderBy(
    alertSources,
    [
      (source) => source.alert_last_fired_at || new Date(0),
      (source) => source.name,
    ],
    ["desc", "asc"],
  );
};
