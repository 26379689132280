/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationUserTargetPayload,
    EscalationUserTargetPayloadFromJSON,
    EscalationUserTargetPayloadFromJSONTyped,
    EscalationUserTargetPayloadToJSON,
} from './EscalationUserTargetPayload';

/**
 * 
 * @export
 * @interface IncidentFormsSubmitEscalateFormRequestBody
 */
export interface IncidentFormsSubmitEscalateFormRequestBody {
    /**
     * The bindings of any catalog type backed elements, keyed by the form element's ID
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    catalog_element_bindings?: { [key: string]: Array<string>; };
    /**
     * User provided description of the escalation
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    description?: string;
    /**
     * The bindings for this escalation element
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    element_bindings?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    escalation_paths?: Array<string>;
    /**
     * ID of the escalation form we're submitting
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    idempotency_key: string;
    /**
     * ID of the incident we're submitting this escalation element for
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    incident_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    priority?: string;
    /**
     * Title of the escalation form we're submitting
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    title: string;
    /**
     * PagerDuty service used if you need to escalate to a particular PagerDuty user(s)
     * @type {string}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    user_pagerduty_service?: string;
    /**
     * 
     * @type {Array<EscalationUserTargetPayload>}
     * @memberof IncidentFormsSubmitEscalateFormRequestBody
     */
    users?: Array<EscalationUserTargetPayload>;
}

export function IncidentFormsSubmitEscalateFormRequestBodyFromJSON(json: any): IncidentFormsSubmitEscalateFormRequestBody {
    return IncidentFormsSubmitEscalateFormRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsSubmitEscalateFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsSubmitEscalateFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_element_bindings': !exists(json, 'catalog_element_bindings') ? undefined : json['catalog_element_bindings'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'element_bindings': !exists(json, 'element_bindings') ? undefined : json['element_bindings'],
        'escalation_paths': !exists(json, 'escalation_paths') ? undefined : json['escalation_paths'],
        'idempotency_key': json['idempotency_key'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'title': json['title'],
        'user_pagerduty_service': !exists(json, 'user_pagerduty_service') ? undefined : json['user_pagerduty_service'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(EscalationUserTargetPayloadFromJSON)),
    };
}

export function IncidentFormsSubmitEscalateFormRequestBodyToJSON(value?: IncidentFormsSubmitEscalateFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_element_bindings': value.catalog_element_bindings,
        'description': value.description,
        'element_bindings': value.element_bindings,
        'escalation_paths': value.escalation_paths,
        'idempotency_key': value.idempotency_key,
        'incident_id': value.incident_id,
        'priority': value.priority,
        'title': value.title,
        'user_pagerduty_service': value.user_pagerduty_service,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(EscalationUserTargetPayloadToJSON)),
    };
}

