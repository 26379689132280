/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    NudgeWorkflowButton,
    NudgeWorkflowButtonFromJSON,
    NudgeWorkflowButtonFromJSONTyped,
    NudgeWorkflowButtonToJSON,
} from './NudgeWorkflowButton';

/**
 * 
 * @export
 * @interface NudgesUpdateRequestBody
 */
export interface NudgesUpdateRequestBody {
    /**
     * What conditions must be met for this nudge to run?
     * @type {Array<ConditionGroupPayload>}
     * @memberof NudgesUpdateRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * How long after the conditions apply should this nudge run for the first time?
     * @type {number}
     * @memberof NudgesUpdateRequestBody
     */
    delay_for_seconds: number;
    /**
     * Whether the nudge should run in intelligent mode or not
     * @type {boolean}
     * @memberof NudgesUpdateRequestBody
     */
    intelligent_mode: boolean;
    /**
     * What is the maximum number of times this nudge should run?
     * @type {number}
     * @memberof NudgesUpdateRequestBody
     */
    maximum_nudge_run_count?: number;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof NudgesUpdateRequestBody
     */
    message_content: EngineParamBindingPayload;
    /**
     * Human readable the nudge
     * @type {string}
     * @memberof NudgesUpdateRequestBody
     */
    name: string;
    /**
     * What type of nudge is this
     * @type {string}
     * @memberof NudgesUpdateRequestBody
     */
    nudge_type: NudgesUpdateRequestBodyNudgeTypeEnum;
    /**
     * Any param bindings used to define the nudge in more detail
     * @type {Array<EngineParamBindingPayload>}
     * @memberof NudgesUpdateRequestBody
     */
    param_bindings: Array<EngineParamBindingPayload>;
    /**
     * How long after the nudge runs should it run again?
     * @type {number}
     * @memberof NudgesUpdateRequestBody
     */
    repeat_after_seconds: number;
    /**
     * The buttons to include on this nudge, if it's a workflow nudge
     * @type {Array<NudgeWorkflowButton>}
     * @memberof NudgesUpdateRequestBody
     */
    workflow_buttons?: Array<NudgeWorkflowButton>;
}

/**
* @export
* @enum {string}
*/
export enum NudgesUpdateRequestBodyNudgeTypeEnum {
    Inactivity = 'inactivity',
    SendUpdate = 'send-update',
    Summary = 'summary',
    Triage = 'triage',
    CreateStatuspage = 'create-statuspage',
    UpdateStatuspage = 'update-statuspage',
    CustomField = 'custom-field',
    AssignRole = 'assign-role',
    IncidentTimestamp = 'incident-timestamp',
    CreateStream = 'create-stream',
    RunWorkflows = 'run-workflows',
    Escalate = 'escalate'
}

export function NudgesUpdateRequestBodyFromJSON(json: any): NudgesUpdateRequestBody {
    return NudgesUpdateRequestBodyFromJSONTyped(json, false);
}

export function NudgesUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'delay_for_seconds': json['delay_for_seconds'],
        'intelligent_mode': json['intelligent_mode'],
        'maximum_nudge_run_count': !exists(json, 'maximum_nudge_run_count') ? undefined : json['maximum_nudge_run_count'],
        'message_content': EngineParamBindingPayloadFromJSON(json['message_content']),
        'name': json['name'],
        'nudge_type': json['nudge_type'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingPayloadFromJSON)),
        'repeat_after_seconds': json['repeat_after_seconds'],
        'workflow_buttons': !exists(json, 'workflow_buttons') ? undefined : ((json['workflow_buttons'] as Array<any>).map(NudgeWorkflowButtonFromJSON)),
    };
}

export function NudgesUpdateRequestBodyToJSON(value?: NudgesUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'delay_for_seconds': value.delay_for_seconds,
        'intelligent_mode': value.intelligent_mode,
        'maximum_nudge_run_count': value.maximum_nudge_run_count,
        'message_content': EngineParamBindingPayloadToJSON(value.message_content),
        'name': value.name,
        'nudge_type': value.nudge_type,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingPayloadToJSON)),
        'repeat_after_seconds': value.repeat_after_seconds,
        'workflow_buttons': value.workflow_buttons === undefined ? undefined : ((value.workflow_buttons as Array<any>).map(NudgeWorkflowButtonToJSON)),
    };
}

