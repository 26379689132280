import { AlertRouteSlim, ConditionGroup } from "@incident-io/api";
import { OrgAwareLink } from "@incident-shared/org-aware";

import { joinSpansWithCommasAndConnectorWord } from "../../../../utils/utils";
import { ChooseAlertSourcesFormData } from "../common/types";

export const getOverlappingAlertRoutes = ({
  alertRoutes,
  alertSourceIds,
  alertRouteId,
}: {
  alertRoutes: AlertRouteSlim[];
  alertSourceIds: string[];
  alertRouteId?: string;
}) => {
  const otherRoutes = alertRoutes.filter((route) => route.id !== alertRouteId);

  return otherRoutes.filter((route) =>
    alertSourceIds.some((sourceId) =>
      route.alert_sources.some((source) => source.alert_source_id === sourceId),
    ),
  );
};

export const getSelectedSources = (
  alertSourcesFormData: ChooseAlertSourcesFormData["alertSources"] | undefined,
): Array<{
  condition_groups: ConditionGroup[];
  selected: boolean;
  id: string;
}> => {
  if (!alertSourcesFormData) {
    return [];
  }

  return Object.keys(alertSourcesFormData)
    .filter((sourceId) => alertSourcesFormData[sourceId].selected)
    .map((sourceId) => ({
      id: sourceId,
      ...alertSourcesFormData[sourceId],
    }));
};

export const OverlappingAlertRoutesList = ({
  overlappingAlertRoutes,
}: {
  overlappingAlertRoutes: AlertRouteSlim[];
}) => {
  return (
    <>
      {joinSpansWithCommasAndConnectorWord(
        overlappingAlertRoutes.map((route) => (
          <OrgAwareLink
            key={route.name}
            className={"font-semibold hover:underline"}
            to={`/alerts/routes/${route.id}/edit`}
            target={"_blank"}
          >
            {route.name}
          </OrgAwareLink>
        )),
        "and",
        {
          showMaxItems: 3,
        },
      )}
    </>
  );
};
