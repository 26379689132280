/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtlassianWidgetStatusIncidentUpdate
 */
export interface AtlassianWidgetStatusIncidentUpdate {
    /**
     * Content of the update message
     * @type {string}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    body: string;
    /**
     * Time the update was created
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    created_at: Date;
    /**
     * Time the update should be displayed
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    display_at: Date;
    /**
     * Unique identifier of the update
     * @type {string}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    id: string;
    /**
     * ID of the incident this update belongs to
     * @type {string}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    incident_id: string;
    /**
     * Status of the incident at the time of the update
     * @type {string}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    status: string;
    /**
     * Time the update was last modified
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncidentUpdate
     */
    updated_at: Date;
}

export function AtlassianWidgetStatusIncidentUpdateFromJSON(json: any): AtlassianWidgetStatusIncidentUpdate {
    return AtlassianWidgetStatusIncidentUpdateFromJSONTyped(json, false);
}

export function AtlassianWidgetStatusIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianWidgetStatusIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'created_at': (new Date(json['created_at'])),
        'display_at': (new Date(json['display_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'status': json['status'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AtlassianWidgetStatusIncidentUpdateToJSON(value?: AtlassianWidgetStatusIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'created_at': (value.created_at.toISOString()),
        'display_at': (value.display_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'status': value.status,
        'updated_at': (value.updated_at.toISOString()),
    };
}

