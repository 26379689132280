import {
  AlertAttribute,
  CatalogResource,
  CatalogType,
  CatalogTypeAttributeModeEnum,
  CatalogTypeModeEnum,
  CustomField,
  ScopeNameEnum,
  TeamsConvertRequestBodyMembersSourceOfTruthEnum,
  TeamsGenerateAttributesConfigRequestBodyMembersSourceOfTruthEnum,
} from "@incident-io/api";
import { CatalogResourceOption } from "@incident-shared/catalog";
import { CatalogEntryBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import { Form } from "@incident-shared/forms";
import { FormLabel } from "@incident-shared/forms/v2/helpers";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { FrontEndIconEnum } from "@incident-ui/Icon/Icon";
import { PopoverSelectOptions } from "@incident-ui/PopoverSelect";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useMutationV2 } from "src/utils/swr";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useIntegrations } from "../../../../hooks/useIntegrations";
import { useProductAccess } from "../../../../hooks/useProductAccess";
import { useAPI, useAPIMutation } from "../../../../utils/swr";
import { tcx } from "../../../../utils/tailwind-classes";
import { AttributeButton } from "../../../catalog/wizards/CatalogWizardAddAttributesStep";
import {
  buildSourceOfTruthList,
  SourceOfTruth,
} from "../../../catalog/wizards/team-wizard/TeamWizardChooseSourceOfTruthStep";
import { useScimAndSamlProviders } from "../../integrations/list/scim-and-saml/SCIMAndSamlIntegrationDrawer";
import { useTeamsRevalidate } from "./hooks";
import {
  buildPossibleMemberAttributeButtons,
  buildTeamTypeOptions,
  CatalogTeamOption,
  makeDefaults,
} from "./utils";

export type TeamsGetStartedDrawerProps = {
  onClose: () => void;
};

export type TeamsGetStartedFormData = {
  catalog_type_id?: string;
  members_attribute_id?: string;
  members_source_of_truth?: TeamsConvertRequestBodyMembersSourceOfTruthEnum;
  escalation_paths_attribute_id?: string;
  custom_field_id?: string;
  alert_attribute_id?: string;

  // Frontend-only fields
  members_create_new: boolean;
  escalation_paths_create_new: boolean;
  custom_field_create_new: boolean;
  alert_attribute_create_new: boolean;
};

export const TeamsGetStartedDrawer = ({
  onClose,
}: TeamsGetStartedDrawerProps): React.ReactElement => {
  const identity = useIdentity();
  const { integrations } = useIntegrations();

  const {
    data: catalogConfigData,
    isLoading: catalogConfigLoading,
    error: catalogConfigError,
  } = useAPI("catalogGetCatalogConfig", {});

  const {
    data: catalogResourcesData,
    isLoading: catalogResourcesLoading,
    error: catalogResourcesError,
  } = useAPI("catalogListResources", undefined);

  const { data: teamResourcesResponse, error: teamResourcesError } = useAPI(
    "catalogListTeamSourceOfTruthResources",
    undefined,
  );

  const { teamsState, scimProvider: installedScimProvider } =
    useScimAndSamlProviders();

  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const resources = useMemo(
    () =>
      (catalogResourcesData?.resources || []).filter(
        (r) =>
          catalogConfigData?.config.team_types.some(
            (teamType) => teamType.type_name === r.type,
          ),
      ),
    [catalogResourcesData, catalogConfigData],
  );

  const {
    data: customFieldsData,
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", {});

  const {
    data: alertSchemaResponse,
    isLoading: alertSchemaIsLoading,
    error: alertSchemaError,
  } = useAPI("alertsShowSchema", undefined);

  const error =
    catalogConfigError ||
    catalogResourcesError ||
    catalogTypesError ||
    teamResourcesError ||
    customFieldsError ||
    alertSchemaError;
  const isLoading =
    catalogConfigLoading ||
    catalogResourcesLoading ||
    catalogTypesLoading ||
    customFieldsLoading ||
    alertSchemaIsLoading;

  if (isLoading || catalogConfigData?.config.team_types.length === 0) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  // Map catalog config types to actual catalog types
  const teamCatalogTypes = _.compact(
    catalogConfigData?.config.team_types.map((teamType) => {
      return catalogTypesData?.catalog_types.find(
        (catalogType) => catalogType.id === teamType.catalog_type_id,
      );
    }),
  );

  const userSourceOfTruths: SourceOfTruth[] = buildSourceOfTruthList(
    teamResourcesResponse?.resources || [],
    identity,
    integrations,
    installedScimProvider,
    teamsState,
  );

  // Find all our catalog types that have a members attribute we can derive membership from, but ignore our existing
  // Team types.
  const userCatalogTypes =
    catalogTypesData?.catalog_types.filter(
      (type) =>
        type.mode !== CatalogTypeModeEnum.Internal &&
        !teamCatalogTypes.find((existing) => existing.id === type.id) &&
        type.schema.attributes.some(
          (attr) => attr.type === "User" && attr.array,
        ),
    ) || [];

  const customFields = customFieldsData?.custom_fields || [];

  const alertAttributes = alertSchemaResponse?.alert_schema.attributes || [];

  return (
    <TeamsGetStartedDrawerContents
      teamCatalogTypes={teamCatalogTypes}
      userCatalogTypes={userCatalogTypes}
      userSourceOfTruths={userSourceOfTruths}
      resources={resources}
      customFields={customFields}
      alertAttributes={alertAttributes}
      onClose={onClose}
    />
  );
};

type TeamsGetStartedDrawerContentsProps = {
  teamCatalogTypes: CatalogType[];
  userCatalogTypes: CatalogType[];
  userSourceOfTruths: SourceOfTruth[];
  resources: CatalogResource[];
  customFields: CustomField[];
  alertAttributes: AlertAttribute[];
  onClose: () => void;
};

const TeamsGetStartedDrawerContents = ({
  teamCatalogTypes,
  userSourceOfTruths,
  resources,
  customFields,
  alertAttributes,
  onClose,
}: TeamsGetStartedDrawerContentsProps): React.ReactElement => {
  const { hasOnCall, hasResponse } = useProductAccess();
  const showToast = useToast();
  const [selectedTeamType, setSelectedTeamType] = useState<CatalogType>(
    teamCatalogTypes[0],
  );
  const [generatedConfig, setGeneratedConfig] = useState<string | undefined>();
  const [selectedNewAttributeLabel, setSelectedNewAttributeLabel] =
    useState<string>("");

  const formMethods = useForm<TeamsGetStartedFormData>({
    defaultValues: makeDefaults(
      teamCatalogTypes,
      customFields,
      alertAttributes,
      hasOnCall,
      hasResponse,
    ),
  });

  const [
    selectedTeamTypeId,
    createNewMembersAttribute,
    createNewEscalationPathsAttribute,
    escalationPathsAttributeId,
    membersAttributeId,
    createNewCustomField,
    createNewAlertAttribute,
  ] = formMethods.watch([
    "catalog_type_id",
    "members_create_new",
    "escalation_paths_create_new",
    "escalation_paths_attribute_id",
    "members_attribute_id",
    "custom_field_create_new",
    "alert_attribute_create_new",
  ]);

  const revalidate = useTeamsRevalidate();

  const teamOptions = buildTeamTypeOptions(teamCatalogTypes, resources);
  const memberOptions = useMemo(() => {
    return (
      teamCatalogTypes
        .find((type) => type.id === selectedTeamTypeId)
        ?.schema.attributes.filter((attr) => attr.type === "User" && attr.array)
        .map((attr) => {
          return {
            label: attr.name,
            value: attr.id,
            resource: resources.find(
              (resource) => resource.type === "User",
            ) as CatalogResource,
          };
        }) || []
    );
  }, [selectedTeamTypeId, teamCatalogTypes, resources]);

  const escalationPathOptions = useMemo(() => {
    return (
      teamCatalogTypes
        .find((type) => type.id === selectedTeamTypeId)
        ?.schema.attributes.filter((attr) => attr.type === "EscalationPath")
        .map((attr) => {
          return {
            label: attr.name,
            value: attr.id,
            resource: resources.find(
              (resource) => resource.type === "EscalationPath",
            ) as CatalogResource,
          };
        }) || []
    );
  }, [selectedTeamTypeId, teamCatalogTypes, resources]);

  const customFieldOptions = useMemo(() => {
    return customFields
      .filter((field) => field.catalog_type_id === selectedTeamTypeId)
      .map((field) => {
        return {
          label: field.name,
          value: field.id,
        };
      });
  }, [selectedTeamTypeId, customFields]);

  const alertAttributeOptions = useMemo(() => {
    return alertAttributes
      .filter((field) => field.type === `CatalogEntry["${selectedTeamTypeId}"]`)
      .map((field) => {
        return {
          label: field.name,
          value: field.id,
        };
      });
  }, [selectedTeamTypeId, alertAttributes]);

  // Only show the custom field select if the org has response,
  // and they have more than one eligible custom field to choose from.
  const showCustomFieldSelect =
    selectedTeamTypeId && hasResponse && customFieldOptions.length > 1;

  // Only show the alert attribute option if they have more than one eligible alert attribute to choose from.
  const showAlertAttributeSelect =
    selectedTeamTypeId && alertAttributeOptions.length > 1;

  const attributeButtons = buildPossibleMemberAttributeButtons(
    userSourceOfTruths,
    formMethods,
    setSelectedNewAttributeLabel,
  );

  const isExternalTeamType = !!selectedTeamType.source_repo_url;
  const externalTeamTypeIsReady = useMemo(() => {
    const mode = selectedTeamType.schema.attributes.find(
      (attr) => attr.id === escalationPathsAttributeId,
    )?.mode;
    return (
      !!selectedTeamType.source_repo_url &&
      membersAttributeId &&
      selectedTeamType.schema.attributes.find(
        (attr) => attr.id === membersAttributeId,
      ) &&
      escalationPathsAttributeId &&
      (mode === CatalogTypeAttributeModeEnum.Dashboard ||
        mode === CatalogTypeAttributeModeEnum.Internal)
    );
  }, [
    escalationPathsAttributeId,
    membersAttributeId,
    selectedTeamType.schema.attributes,
    selectedTeamType.source_repo_url,
  ]);

  const { trigger: generateConfig, isMutating: isGeneratingConfig } =
    useMutationV2(
      async (apiClient, formData: TeamsGetStartedFormData) => {
        const resp = await apiClient.teamsGenerateAttributesConfig({
          generateAttributesConfigRequestBody: {
            catalog_type_id: formData.catalog_type_id as string,
            members_attribute_id: formData.members_attribute_id,
            members_source_of_truth:
              formData.members_source_of_truth as unknown as TeamsGenerateAttributesConfigRequestBodyMembersSourceOfTruthEnum,
            escalation_paths_attribute_id:
              formData.escalation_paths_attribute_id,
          },
        });
        setGeneratedConfig(resp.attributes_config);
      },
      {
        invalidate: [],
      },
    );

  const { trigger: onSubmit, isMutating } = useAPIMutation(
    "teamsList",
    {},
    async (apiClient, formData: TeamsGetStartedFormData) => {
      await apiClient.teamsConvert({
        convertRequestBody: {
          catalog_type_id: formData.catalog_type_id as string,
          members_attribute_id: formData.members_attribute_id,
          members_source_of_truth: formData.members_source_of_truth,
          escalation_paths_attribute_id: formData.escalation_paths_attribute_id,
          custom_field_id: formData.custom_field_id,
          alert_attribute_id: formData.alert_attribute_id,
        },
      });
    },
    {
      onSuccess: () => {
        revalidate();
        showToast({
          theme: ToastTheme.Success,
          title: "Your teams are ready to be used!",
        });
        onClose();
      },
      showErrorToast: "Failed to import your teams",
    },
  );

  return (
    <Drawer width="medium" onClose={onClose}>
      <div className="flex flex-col h-full !overflow-hidden">
        <DrawerTitle
          icon={IconEnum.Team}
          title={"Add your teams to incident.io"}
          color={ColorPaletteEnum.Slate}
          theme={DrawerTitleTheme.Default}
          subtitle={
            "Create your teams using an existing catalog type, and instantly unlock your tailored dashboard and other on-call features."
          }
          onClose={onClose}
        />
        <DrawerContents>
          <DrawerBody className="flex flex-col min-h-0 overflow-y-auto h-full p-6 gap-6">
            <Form.Root onSubmit={onSubmit} formMethods={formMethods}>
              <div className={"h-full grow flex flex-col gap-6"}>
                <TeamTypeSelector
                  formMethods={formMethods}
                  teamOptions={teamOptions}
                  teamCatalogTypes={teamCatalogTypes}
                  setSelectedTeamType={setSelectedTeamType}
                />
                {selectedTeamTypeId && (
                  <div className={"flex flex-col gap-1"}>
                    <FormLabel htmlFor={"members_attribute_create_new"}>
                      How do you want to manage your team members?
                    </FormLabel>
                    <div
                      className={
                        "bg-surface-secondary rounded-lg flex flex-col"
                      }
                    >
                      <BooleanRadioButtonGroupV2
                        falseOption={{
                          label: "Use an existing list of members",
                          isDisabled: !memberOptions.length,
                          isDisabledTooltipContent:
                            "We couldn't find an existing list of team members. Please select an option below.",
                        }}
                        trueOption={{
                          label: "Create a new list of members",
                        }}
                        boxed
                        srLabel="How do you want to manage your team members?"
                        name={"members_create_new"}
                        formMethods={formMethods}
                        onValueChange={(value) => {
                          if (value) {
                            formMethods.setValue(
                              "members_attribute_id",
                              undefined,
                            );
                          } else {
                            formMethods.setValue(
                              "members_source_of_truth",
                              undefined,
                            );
                          }
                        }}
                      />
                      <div className={"p-3"}>
                        {createNewMembersAttribute ? (
                          <TeamMemberSourceOfTruthSelection
                            attributeButtons={attributeButtons}
                            selectedNewAttributeLabel={
                              selectedNewAttributeLabel
                            }
                          />
                        ) : (
                          <TeamMemberAttributeSelection
                            formMethods={formMethods}
                            memberOptions={memberOptions}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {selectedTeamTypeId && hasOnCall && (
                  <div className={"flex flex-col gap-1"}>
                    <FormLabel htmlFor={"escalation_paths_create_new"}>
                      How do you want to manage your team&rsquo;s escalation
                      paths?
                    </FormLabel>
                    <div
                      className={
                        "bg-surface-secondary rounded-lg flex flex-col"
                      }
                    >
                      <BooleanRadioButtonGroupV2
                        falseOption={{
                          label: "Use an existing list of escalation paths",
                          isDisabled: !escalationPathOptions.length,
                          isDisabledTooltipContent:
                            "We couldn't find an existing list of escalation paths. Please select an option below.",
                        }}
                        trueOption={{
                          label: "Create a new list",
                        }}
                        boxed
                        srLabel="How do you want to manage your team's escalation paths?"
                        name={"escalation_paths_create_new"}
                        formMethods={formMethods}
                        onValueChange={(value) => {
                          if (value) {
                            formMethods.setValue(
                              "escalation_paths_attribute_id",
                              undefined,
                            );
                          }
                        }}
                      />
                      <div className={"p-3"}>
                        {createNewEscalationPathsAttribute ? (
                          <div className="text-text-secondary">
                            We&rsquo;ll create a new escalation paths attribute.
                          </div>
                        ) : (
                          <EscalationPathAttributeSelection
                            formMethods={formMethods}
                            escalationPathOptions={escalationPathOptions}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showCustomFieldSelect && (
                  <div className={"flex flex-col gap-1"}>
                    <FormLabel htmlFor={"custom_field_create_new"}>
                      How do you want to tag your incidents with affected teams?
                    </FormLabel>
                    <div
                      className={
                        "bg-surface-secondary rounded-lg flex flex-col"
                      }
                    >
                      <BooleanRadioButtonGroupV2
                        falseOption={{
                          label: "Use an existing custom field",
                          isDisabled: !customFieldOptions.length,
                          isDisabledTooltipContent:
                            "We couldn't find a custom field to tag your incidents with. Please select an option below.",
                        }}
                        trueOption={{
                          label: "Create a new custom field",
                        }}
                        boxed
                        srLabel="How do you want to tag your incidents with affected teams?"
                        name={"custom_field_create_new"}
                        formMethods={formMethods}
                        onValueChange={(value) => {
                          if (value) {
                            formMethods.setValue("custom_field_id", undefined);
                          }
                        }}
                      />
                      <div className={"p-3"}>
                        {createNewCustomField ? (
                          <div className="text-text-secondary">
                            We&rsquo;ll create a new custom field for your team
                            type.
                          </div>
                        ) : (
                          <CustomFieldSelection
                            formMethods={formMethods}
                            customFieldOptions={customFieldOptions}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showAlertAttributeSelect && (
                  <div className={"flex flex-col gap-1"}>
                    <FormLabel htmlFor={"alert_attribute_create_new"}>
                      How do you want to tag your alerts with an owning team?
                    </FormLabel>
                    <div
                      className={
                        "bg-surface-secondary rounded-lg flex flex-col"
                      }
                    >
                      <BooleanRadioButtonGroupV2
                        falseOption={{
                          label: "Use an existing alert attribute",
                          isDisabled: !alertAttributeOptions.length,
                          isDisabledTooltipContent:
                            "We couldn't find a matching alert attribute. Please select an option below.",
                        }}
                        trueOption={{
                          label: "Create a new alert attribute",
                        }}
                        boxed
                        srLabel="How do you want to tag your alerts with an owning team?"
                        name={"alert_attribute_create_new"}
                        formMethods={formMethods}
                        onValueChange={(value) => {
                          if (value) {
                            formMethods.setValue(
                              "alert_attribute_id",
                              undefined,
                            );
                          }
                        }}
                      />
                      <div className={"p-3"}>
                        {createNewAlertAttribute ? (
                          <div className="text-text-secondary">
                            We&rsquo;ll create a new alert attribute to tag
                            alerts with your teams.
                          </div>
                        ) : (
                          <AlertAttributeSelection
                            formMethods={formMethods}
                            alertAttributeOptions={alertAttributeOptions}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isExternalTeamType && !externalTeamTypeIsReady && (
                  <Callout
                    theme={CalloutTheme.Warning}
                    iconClassName={"self-start"}
                  >
                    <div className={"flex flex-col gap-2"}>
                      <span className={"font-semibold"}>
                        You need to add the following changes to your external
                        config before you can use it natively.
                      </span>
                      <div>
                        <Button
                          analyticsTrackingId={""}
                          onClick={() => generateConfig(formMethods.watch())}
                        >
                          Generate the config
                        </Button>
                      </div>
                      {generatedConfig && (
                        <CodeBlock
                          hideHeader
                          hideLineNumbers
                          code={generatedConfig}
                        />
                      )}
                    </div>
                  </Callout>
                )}
              </div>
            </Form.Root>
            <div className={"grow"} />
            <Callout
              iconOverride={IconEnum.Announcement}
              iconClassName={"self-start"}
              theme={CalloutTheme.Plain}
            >
              <div>
                Creating teams will give you access to more features, but won’t
                remove any data in your Catalog.
              </div>
            </Callout>
          </DrawerBody>
          <DrawerFooter>
            <div className="flex gap-2">
              <div className={"grow"} />
              <Button
                analyticsTrackingId={""}
                theme={ButtonTheme.Secondary}
                onClick={onClose}
              >
                Cancel
              </Button>
              <GatedButton
                type={"submit"}
                analyticsTrackingId="convert-to-team"
                theme={ButtonTheme.Primary}
                requiredScope={ScopeNameEnum.CatalogTypesCreate}
                disabled={
                  (isExternalTeamType &&
                    (!externalTeamTypeIsReady || isGeneratingConfig)) ||
                  isMutating
                }
                disabledTooltipContent={
                  isExternalTeamType && !externalTeamTypeIsReady
                    ? isGeneratingConfig
                      ? "Generating attribute configuration..."
                      : "You manage your Team externally, so you need to make changes before you can use it natively."
                    : undefined
                }
                onClick={formMethods.handleSubmit(onSubmit)}
              >
                Continue
              </GatedButton>
            </div>
          </DrawerFooter>
        </DrawerContents>
      </div>
    </Drawer>
  );
};

const TeamMemberAttributeSelection = ({
  formMethods,
  memberOptions,
}: {
  formMethods: UseFormReturn<TeamsGetStartedFormData>;
  memberOptions: CatalogTeamOption[];
}): React.ReactElement => {
  return (
    <PopoverSingleSelectV2
      name={`members_attribute_id`}
      placeholder={"Select an attribute to manage members from"}
      renderSelected={(option: CatalogResourceOption) => {
        return (
          <CatalogEntryBadge
            color={ColorPaletteEnum.Blue}
            label={option.label}
            icon={IconEnum.Users}
          />
        );
      }}
      formMethods={formMethods}
      required
      options={memberOptions}
      fullWidth
    />
  );
};

const EscalationPathAttributeSelection = ({
  formMethods,
  escalationPathOptions,
}: {
  formMethods: UseFormReturn<TeamsGetStartedFormData>;
  escalationPathOptions: CatalogTeamOption[];
}): React.ReactElement => {
  return (
    <PopoverSingleSelectV2
      name={`escalation_paths_attribute_id`}
      placeholder={"Select an attribute to manage escalation paths from"}
      renderSelected={(option: CatalogResourceOption) => {
        return (
          <CatalogEntryBadge
            color={option.resource?.color || ColorPaletteEnum.Indigo}
            label={option.label}
            icon={
              (option.resource?.icon as unknown as FrontEndIconEnum) ||
              IconEnum.EscalationPath
            }
          />
        );
      }}
      formMethods={formMethods}
      required
      options={escalationPathOptions}
      fullWidth
    />
  );
};

/**
 * Component for selecting a source of truth for team members
 */
const TeamMemberSourceOfTruthSelection = ({
  attributeButtons,
  selectedNewAttributeLabel,
}: {
  attributeButtons: AttributeButton[];
  selectedNewAttributeLabel: string;
}): React.ReactElement => {
  return (
    <div className={"flex flex-col gap-2"}>
      <span className={"font-semibold"}>How do you manage your teams?</span>
      <div className={"inline-flex gap-2"}>
        {attributeButtons.map((b) => {
          const isSelectedAttribute = b.label === selectedNewAttributeLabel;
          return (
            <Button
              analyticsTrackingId={""}
              key={b.type}
              icon={isSelectedAttribute ? IconEnum.Tick : b.icon}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Secondary}
              onClick={b.onClickHandler}
              className={tcx(
                "border border-dashed hover:border-solid border-stroke-hover white text-primary-500 shadow-none",
                {
                  "border border-solid": isSelectedAttribute,
                },
              )}
              iconProps={{
                className: isSelectedAttribute
                  ? "text-green-500"
                  : b.color
                  ? getColorPalette(b.color).text
                  : "",
              }}
            >
              {b.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Component for selecting a team type from catalog
 */
const TeamTypeSelector = ({
  formMethods,
  teamOptions,
  teamCatalogTypes,
  setSelectedTeamType,
}: {
  formMethods: UseFormReturn<TeamsGetStartedFormData>;
  teamOptions: PopoverSelectOptions<CatalogTeamOption>;
  teamCatalogTypes: CatalogType[];
  setSelectedTeamType: React.Dispatch<React.SetStateAction<CatalogType>>;
}): React.ReactElement => {
  const handleValueChange = (value: string | null | void) => {
    const catalogType = teamCatalogTypes.find((type) => type.id === value);

    if (catalogType) {
      setSelectedTeamType(catalogType);

      // Find the first attribute with type 'User'
      const userAttribute = catalogType.schema.attributes.find(
        (attr) => attr.type === "User" && attr.array,
      );

      // Find the first attribute with type 'EscalationPath'
      const escalationPathAttribute = catalogType.schema.attributes.find(
        (attr) => attr.type === "EscalationPath" && attr.array,
      );

      // Set members attribute
      if (userAttribute) {
        formMethods.setValue("members_attribute_id", userAttribute.id);
        formMethods.setValue("members_create_new", false);
      } else {
        formMethods.setValue("members_attribute_id", undefined);
        formMethods.setValue("members_create_new", true);
      }

      // Set escalation path attribute
      if (escalationPathAttribute) {
        formMethods.setValue(
          "escalation_paths_attribute_id",
          escalationPathAttribute.id,
        );
        formMethods.setValue("escalation_paths_create_new", false);
      } else {
        formMethods.setValue("escalation_paths_attribute_id", undefined);
        formMethods.setValue("escalation_paths_create_new", true);
      }
    }
  };

  return (
    <PopoverSingleSelectV2
      label={"Which Catalog type represents your teams?"}
      renderSelected={(option: CatalogResourceOption) => {
        return (
          <CatalogEntryBadge
            color={option.resource.color as unknown as ColorPaletteEnum}
            label={option.label}
            icon={option.resource.icon as unknown as IconEnum}
          />
        );
      }}
      onValueChange={handleValueChange}
      name={`catalog_type_id`}
      formMethods={formMethods}
      required
      options={teamOptions}
      fullWidth
    />
  );
};

const CustomFieldSelection = ({
  formMethods,
  customFieldOptions,
}: {
  formMethods: UseFormReturn<TeamsGetStartedFormData>;
  customFieldOptions: PopoverSelectOptions<{ label: string; value: string }>;
}): React.ReactElement => {
  return (
    <PopoverSingleSelectV2
      name={`custom_field_id`}
      placeholder={"Select a custom field to filter incidents with"}
      renderSelected={(option: { label: string }) => {
        return (
          <CatalogEntryBadge
            color={ColorPaletteEnum.Blue}
            label={option.label}
            icon={IconEnum.CustomField}
          />
        );
      }}
      formMethods={formMethods}
      required
      options={customFieldOptions}
      fullWidth
    />
  );
};

const AlertAttributeSelection = ({
  formMethods,
  alertAttributeOptions,
}: {
  formMethods: UseFormReturn<TeamsGetStartedFormData>;
  alertAttributeOptions: PopoverSelectOptions<{ label: string; value: string }>;
}): React.ReactElement => {
  return (
    <PopoverSingleSelectV2
      name={`alert_attribute_id`}
      placeholder={"Select an alert attribute to tag alerts with"}
      renderSelected={(option: { label: string }) => {
        return (
          <CatalogEntryBadge
            color={ColorPaletteEnum.Cyan}
            label={option.label}
            icon={IconEnum.Alert}
          />
        );
      }}
      formMethods={formMethods}
      required
      options={alertAttributeOptions}
      fullWidth
    />
  );
};
