import * as Tabs from "@radix-ui/react-tabs";
import cx from "classnames";

import { Modal, ModalProps } from "./Modal";
import styles from "./TabModal.module.scss";

export type Tab<IdEnum extends string = string> = {
  label: string | React.ReactFragment;
  id: IdEnum;
  icon?: React.FunctionComponent<{ className?: string }>;
};

type TabProps<IdEnum extends string = string> = {
  /**
   *  The TabPane components, passed in as children
   */
  children?: React.ReactNode;
  /**
   * An array of tabs with a `label` (human readable) and an `id` (unique)
   */
  tabs: Tab<IdEnum>[];
  /**
   * Optional callback to execute when changing tab
   */
  onTabChange?: (tabId: IdEnum) => void;
};

export const TabModal = <IdEnum extends string = string>({
  children,
  tabs,
  onTabChange,
  ...modalProps
}: ModalProps & TabProps<IdEnum>): React.ReactElement => {
  const onValueChange = (tabId: string) => onTabChange?.(tabId as IdEnum);

  return (
    <Modal {...modalProps}>
      <Tabs.Root
        onValueChange={onValueChange}
        defaultValue={tabs[0].id}
        className="flex flex-col grow"
      >
        <Tabs.List
          className={cx(
            "flex flex-wrap border-b",
            "border-stroke",
            "dark:border-slate-700",
          )}
        >
          {tabs.map((t) => {
            return (
              <div
                className={cx("flex !mr-0 relative", styles.tabWrapper)}
                key={t.id}
              >
                <Tabs.Trigger
                  className={cx(
                    styles.tab,
                    "px-3 py-2 font-medium text-sm transition space-x-2",
                  )}
                  value={t.id}
                >
                  {t.icon && <t.icon className={styles.icon} />}
                  <p>{t.label}</p>
                </Tabs.Trigger>
              </div>
            );
          })}
        </Tabs.List>
        {children}
      </Tabs.Root>
    </Modal>
  );
};
