/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteTemplateBindingPayload
 */
export interface AlertRouteTemplateBindingPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteTemplateBindingPayload
     */
    binding?: EngineParamBindingPayload;
}

export function AlertRouteTemplateBindingPayloadFromJSON(json: any): AlertRouteTemplateBindingPayload {
    return AlertRouteTemplateBindingPayloadFromJSONTyped(json, false);
}

export function AlertRouteTemplateBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteTemplateBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': !exists(json, 'binding') ? undefined : EngineParamBindingPayloadFromJSON(json['binding']),
    };
}

export function AlertRouteTemplateBindingPayloadToJSON(value?: AlertRouteTemplateBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingPayloadToJSON(value.binding),
    };
}

