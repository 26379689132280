import { Badge, BadgeSize, BadgeTheme } from "@incident-ui/Badge/Badge";
import { isSelectOptionGroup } from "@incident-ui/Select/types";
import { Searcher, sortKind } from "fast-fuzzy";

import { PopoverSelectOptions } from "./PopoverSelectOptions";
import { PopoverSelectWrapper } from "./PopoverSelectWrapper";
import {
  MultiRenderSelectedFn,
  PopoverMultiSelectProps,
  PopoverSelectOption,
  SelectOptionWithGroup,
} from "./types";
import {
  isGroupedOptions,
  isSearchableWithDefault,
  useAsyncOptions,
  useManageSelectState,
} from "./utils";

export const PopoverMultiSelect = <
  TSync extends boolean,
  TObject extends boolean,
  TOption extends PopoverSelectOption = PopoverSelectOption,
>({
  object,
  align = "start",
  icon,
  options: suppliedOptions,
  onChange,
  renderSelected,
  loadOptions,
  isLoading: suppliedLoading,
  value,
  hydrateOptions,
  placeholder,
  isSearchable,
  isClearable,
  inlineDescription,
  keySelector,
  noOptionsMessage,
  renderTriggerNode,
  ...rest
}: PopoverMultiSelectProps<TSync, TObject, TOption>) => {
  const { openControl, search, setSearch } = useManageSelectState();

  const {
    options: asyncOptions,
    isLoading: loadingAync,
    hydratedValues: asyncValues,
  } = useAsyncOptions({
    search,
    loadOptions,
    value,
    hydrateOptions,
  });

  const isLoading = suppliedLoading ?? loadingAync;

  const options = suppliedOptions ?? asyncOptions ?? [];

  const selectedValue = extractSelectedValue({ value, object });

  const flattenedOptions = isGroupedOptions(options)
    ? (options.flatMap((group) =>
        group.options.map((opt) => ({ ...opt, group: group.label })),
      ) as SelectOptionWithGroup<TOption>[])
    : (options as SelectOptionWithGroup<TOption>[]);

  const selectedOptions =
    asyncValues ??
    flattenedOptions.filter((opt) => selectedValue?.includes(opt.value));

  const searcher = new Searcher(flattenedOptions, {
    keySelector: keySelector ?? ((s) => s.label),
    threshold: 0.8,
    sortBy: sortKind.bestMatch,
  });

  let filteredOptions = flattenedOptions;

  if (search) {
    filteredOptions = searcher.search(search);
  }

  const onToggleOption = (clickedValue: string) => {
    if (object) {
      const chosenOption = flattenedOptions.find(
        (opt) => opt.value === clickedValue,
      );
      if (!chosenOption) {
        return;
      }

      const existingValues = value?.map((v) => v.value) || [];

      if (!value) {
        onChange([chosenOption]);
      } else if (existingValues.includes(clickedValue)) {
        onChange(value.filter((v) => v.value !== chosenOption.value));
      } else {
        onChange([...value, chosenOption]);
      }
    } else {
      if (!value) {
        onChange([clickedValue]);
      } else if (value && value.includes(clickedValue)) {
        onChange(value.filter((v) => v !== clickedValue));
      } else {
        onChange([...value, clickedValue]);
      }
    }
  };

  const isValueSelected = Array.isArray(value) ? value.length > 0 : !!value;

  return (
    <PopoverSelectWrapper
      align={align}
      icon={icon}
      search={search}
      setSearch={setSearch}
      {...openControl}
      selectedValue={
        <SelectedValue
          value={selectedValue}
          renderSelected={renderSelected}
          onClick={onToggleOption}
          options={flattenedOptions}
          placeholder={placeholder}
        />
      }
      triggerNode={
        renderTriggerNode
          ? renderTriggerNode({
              selectedOptions,
              onClick: () => openControl.setIsOpen(true),
              isLoadingOptions: isLoading,
            })
          : undefined
      }
      isClearable={isClearable}
      isSearchable={isSearchableWithDefault({ isSearchable, flattenedOptions })}
      isValueSelected={isValueSelected}
      onClear={() => onChange([])}
      isLoading={isLoading}
      {...rest}
    >
      <PopoverSelectOptions
        options={filteredOptions}
        isMulti={true}
        onClickOption={onToggleOption}
        value={selectedValue}
        inlineDescription={inlineDescription}
        search={search}
        allowAdding={rest.allowAdding}
        onCreateOption={rest.onCreateOption}
        noOptionsMessage={noOptionsMessage}
        popoverItemClassName={rest.popoverItemClassName}
      />
    </PopoverSelectWrapper>
  );
};

const SelectedValue = <TOption extends PopoverSelectOption>({
  value,
  renderSelected,
  onClick,
  options,
  placeholder,
}: {
  value: string[];
  renderSelected?: MultiRenderSelectedFn<TOption>;
  onClick: (value: string) => void;
  options: SelectOptionWithGroup<TOption>[];
  placeholder?: string;
}) => {
  const isValueSelected = value && value.length > 0;

  const valueOptionMap = options.reduce<Record<string, TOption>>(
    (acc, option) => {
      if (isSelectOptionGroup(option)) {
        option.options.forEach((opt) => {
          acc[opt.value] = opt as TOption;
        });
      } else {
        acc[option.value] = option;
      }

      return acc;
    },
    {},
  );

  const valueOptions = value
    .map((val) => valueOptionMap[val])
    // Exclude any values that we couldn't find in our list, as they are (presumably) no
    // longer valid, and having [null] in your form state causes issues.
    .filter((val) => val);

  const wrapperClasses = "flex items-center grow gap-1 flex-wrap";

  if (!isValueSelected) {
    return (
      <div className={wrapperClasses}>
        <p className="text-sm text-content-tertiary">
          {placeholder ?? "Select..."}
        </p>
      </div>
    );
  }

  if (renderSelected) {
    return <div className={wrapperClasses}>{renderSelected(valueOptions)}</div>;
  }

  return (
    <div className={wrapperClasses}>
      {valueOptions.map((opt) => (
        <Badge
          key={opt.value}
          theme={BadgeTheme.Primary}
          size={BadgeSize.Small}
          onClose={(e) => {
            e?.stopPropagation();
            onClick(opt.value);
          }}
        >
          {opt.label}
        </Badge>
      ))}
    </div>
  );
};

const extractSelectedValue = <
  TSync extends boolean,
  TObject extends boolean,
  TOption extends PopoverSelectOption,
>({
  value,
  object,
}: Pick<
  PopoverMultiSelectProps<TSync, TObject, TOption>,
  "value" | "object"
>): string[] => {
  if (object) {
    return Array.isArray(value) ? value.map((v) => v.value) : [];
  }

  return (value as string[]) ?? [];
};
