/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamsAddResourceRequestBody
 */
export interface TeamsAddResourceRequestBody {
    /**
     * ID of the resource to add to the team
     * @type {string}
     * @memberof TeamsAddResourceRequestBody
     */
    resource_id: string;
    /**
     * Type of resource to add to the team
     * @type {string}
     * @memberof TeamsAddResourceRequestBody
     */
    resource_type: TeamsAddResourceRequestBodyResourceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum TeamsAddResourceRequestBodyResourceTypeEnum {
    EscalationPath = 'escalation_path',
    Schedule = 'schedule'
}

export function TeamsAddResourceRequestBodyFromJSON(json: any): TeamsAddResourceRequestBody {
    return TeamsAddResourceRequestBodyFromJSONTyped(json, false);
}

export function TeamsAddResourceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsAddResourceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
    };
}

export function TeamsAddResourceRequestBodyToJSON(value?: TeamsAddResourceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
    };
}

