import {
  SchedulesCreateFeedRequestBodyFeedTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Gate } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  GatedDropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { SearchProvider } from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useOutlet } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { EmptyState } from "../EmptyState";
import { useOnCallEntityCount } from "../utils";
import EmptyStateImg from "./images/schedules-empty-state.png";
import { ScheduleCalendarFeedModal } from "./ScheduleCalendarFeedModal";
import { SchedulesTable } from "./SchedulesTable";

export const SchedulesListPage = () => {
  const outlet = useOutlet();

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const { identity } = useIdentity();

  const { hasOnCall } = useProductAccess();

  const {
    totalNumberOfSchedules,
    schedulesIsLoading,
    schedulesError,
    scheduleCreateUpgradeRequired,
    createScheduleGate,
    schedulesGateFeatureName,
  } = useSchedulesListPageHeaderProps();

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  if (schedulesError || entityCountError) {
    return <GenericErrorMessage error={schedulesError || entityCountError} />;
  }

  if (schedulesIsLoading || entityCountLoading || !identity) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user
  // back to the get started page (unless they're trying to create something!)
  if (entityCount === 0 && !outlet) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  if (totalNumberOfSchedules === 0) {
    return (
      <>
        <Helmet title={"Schedules - incident.io"} />
        <EmptyState
          copy="Ensure the right people are on call at all times with simple but powerful scheduling that supports even the most complex use cases."
          imageSrc={EmptyStateImg}
          title="No schedules created"
          buttons={[
            {
              children: "Create schedule",
              href: "/on-call/schedules/create",
              analyticsTrackingId: "on-call-create-schedule",
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
            {
              children: "Import schedules",
              href: "/on-call/schedules/import",
              analyticsTrackingId: "on-call-import-schedule",
              theme: ButtonTheme.Secondary,
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
          ]}
        />
        <AnimatePresence>{outlet}</AnimatePresence>
      </>
    );
  }

  return (
    <SearchProvider>
      <Helmet title={"Schedules - incident.io"} />
      <div className={"flex flex-col gap-10"}>
        <div className={"pb-5"}>
          <SchedulesTable />
        </div>
      </div>

      <AnimatePresence>{outlet}</AnimatePresence>
    </SearchProvider>
  );
};

export const SchedulesListButtons = ({
  scheduleCreateUpgradeRequired,
  createScheduleGate,
  schedulesGateFeatureName,
}: {
  scheduleCreateUpgradeRequired: boolean;
  createScheduleGate: Gate;
  schedulesGateFeatureName: string;
}) => {
  const [calendarFeedModalOpen, setCalendarFeedModalOpen] = useState(false);
  return (
    <>
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Calendar}
        onClick={() => setCalendarFeedModalOpen(!calendarFeedModalOpen)}
      >
        <span>Sync calendar</span>
      </Button>
      <ScheduleCreateImportButton
        scheduleCreateUpgradeRequired={scheduleCreateUpgradeRequired}
        createScheduleGate={createScheduleGate}
        schedulesGateFeatureName={schedulesGateFeatureName}
      />

      {calendarFeedModalOpen && (
        <ScheduleCalendarFeedModal
          feedType={SchedulesCreateFeedRequestBodyFeedTypeEnum.All}
          onCancel={() => setCalendarFeedModalOpen(false)}
        />
      )}
    </>
  );
};

const ScheduleCreateImportButton = ({
  scheduleCreateUpgradeRequired,
  createScheduleGate,
  schedulesGateFeatureName,
}: {
  scheduleCreateUpgradeRequired: boolean;
  createScheduleGate: Gate;
  schedulesGateFeatureName: string;
}) => {
  const navigate = useNavigate();

  const buttonProps = {
    create: {
      title: "Create schedule",
      analyticsTrackingId: "schedules-create",
      onClick: () => navigate("schedules/create"),
      requiredScope: ScopeNameEnum.SchedulesCreate,
    },
    import: {
      title: "Import schedule",
      analyticsTrackingId: "schedules-import",
      onClick: () => navigate("schedules/import"),
      requiredScope: ScopeNameEnum.SchedulesCreate,
    },
  };

  return (
    <div className="flex flex-center">
      <GatedButton
        analyticsTrackingId="schedules-create"
        className="ml-auto mr-auto !rounded-r-none border-r-0"
        theme={ButtonTheme.Secondary}
        onClick={() => navigate("schedules/create")}
        requiredScope={ScopeNameEnum.SchedulesCreate}
        upgradeRequired={scheduleCreateUpgradeRequired}
        upgradeRequiredProps={{
          featureName: schedulesGateFeatureName,
          gate: createScheduleGate,
        }}
      >
        Create schedule
      </GatedButton>
      <DropdownMenu
        triggerButton={
          <GatedButton
            theme={ButtonTheme.Secondary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="schedules-create-or-import-dropdown"
            icon={IconEnum.ChevronDown}
            iconProps={{
              size: IconSize.Medium,
            }}
            title=""
            requiredScope={ScopeNameEnum.SchedulesCreate}
            upgradeRequired={scheduleCreateUpgradeRequired}
            upgradeRequiredProps={{
              featureName: schedulesGateFeatureName,
              gate: createScheduleGate,
            }}
          />
        }
        align={"end"}
      >
        <GatedDropdownMenuItem
          analyticsTrackingId={buttonProps.create.analyticsTrackingId}
          onSelect={buttonProps.create.onClick}
          label={buttonProps.create.title}
          icon={IconEnum.Add}
          requiredScope={ScopeNameEnum.SchedulesCreate}
        />
        <GatedDropdownMenuItem
          analyticsTrackingId={buttonProps.import.analyticsTrackingId}
          onSelect={buttonProps.import.onClick}
          icon={IconEnum.Download}
          label={buttonProps.import.title}
          requiredScope={ScopeNameEnum.SchedulesCreate}
        />
      </DropdownMenu>
    </div>
  );
};

export const useSchedulesListPageHeaderProps = () => {
  const { identity } = useIdentity();

  const {
    data: { pagination_meta: paginationMeta },
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI(
    "schedulesList",
    {
      // We're just using this for the count
      pageSize: 1,
    },
    {
      fallbackData: {
        schedules: [],
        users: [],
        escalation_path_references: {},
        pagination_meta: {
          total_record_count: 0,
          page_size: 1,
        },
      },
    },
  );

  const { total_record_count: totalNumberOfSchedules } = paginationMeta ?? {};

  const scheduleCreateUpgradeRequired = numericGateLimitReached(
    identity.feature_gates.on_call_schedules_count,
    totalNumberOfSchedules ?? 0,
  );

  const createScheduleGate: Gate = {
    type: "numeric",
    value: identity.feature_gates.on_call_schedules_count,
    featureNameSingular: "on call",
  };
  const schedulesGateFeatureName = `On-call schedules`;

  return {
    totalNumberOfSchedules,
    schedulesIsLoading,
    schedulesError,
    scheduleCreateUpgradeRequired,
    createScheduleGate,
    schedulesGateFeatureName,
  };
};
