import {
  StatusPageContentIncidentLinkStatusEnum,
  StatusPageContentIncidentUpdateToStatusEnum,
} from "@incident-io/api";

type IncidentStatusIconProps = {
  className?: string;
  flavour?: "ok" | "alarmalade";
  status?:
    | StatusPageContentIncidentUpdateToStatusEnum
    | StatusPageContentIncidentLinkStatusEnum
    | "ok";
};

// Contextual icon depending on the state:
// - ok: green glowy dot
// - alarmalade: alarmalade glowy dot
export const IncidentStatusIcon = ({
  flavour,
  status,
  className,
}: IncidentStatusIconProps): React.ReactElement => {
  if (status) {
    switch (status) {
      case "ok":
      case StatusPageContentIncidentUpdateToStatusEnum.Resolved:
      case StatusPageContentIncidentLinkStatusEnum.Resolved:
      case StatusPageContentIncidentUpdateToStatusEnum.MaintenanceComplete:
      case StatusPageContentIncidentLinkStatusEnum.MaintenanceComplete:
        flavour = "ok";
        break;
      default:
        flavour = "alarmalade";
        break;
    }
  }

  if (flavour === "ok") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        fill="currentColor"
        viewBox="0 0 6 6"
      >
        <circle cx="3" cy="3" r="3" fill="#24C19A" />
      </svg>
    );
  }
  if (flavour === "alarmalade") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        fill="currentColor"
        viewBox="0 0 6 6"
      >
        <circle cx="3" cy="3" r="3" fill="#F87171" />
      </svg>
    );
  }

  throw new Error("unreachable");
};
