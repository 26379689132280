import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { FormInputWrapper } from "@incident-shared/forms/v2/helpers";
import { Button, IconEnum } from "@incident-ui";
import { TextNode } from "src/contexts/ClientContext";

export const IncidentAttachmentsElement = ({
  description,
  required,
}: {
  description?: TextNode;
  required?: boolean;
}) => {
  return (
    <FormInputWrapper
      name={"incident_attachments"}
      label="Incident Attachments"
      required={required}
    >
      <Button icon={IconEnum.FilePlus} analyticsTrackingId="upload-file-button">
        Upload File
      </Button>
      {description && (
        <TemplatedTextDisplay
          style={TemplatedTextDisplayStyle.Compact}
          value={description}
          className="mt-2 text-xs text-slate-700"
        />
      )}
    </FormInputWrapper>
  );
};
