import {
  CatalogEntry,
  EscalationPathSlim,
  IntegrationSettingsProviderEnum,
  Schedule,
  SchedulesListReplicasResponseBody,
  SchedulesShowResponseBody,
  SchedulesUpdateNameRequestBody,
  ScopeNameEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import { isDashboard } from "@incident-shared/management-meta/utils";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ScheduleEntryComponent } from "@incident-shared/schedules/ScheduleOverview/ScheduleEntryComponent";
import { ScheduleOverviewV2 } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  ModalFooter,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutlet, useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../../hooks/usePrimaryCommsPlatform";
import { joinSpansWithCommasAndConnectorWord } from "../../../../utils/utils";
import { hasPagerDuty } from "../../on-call-legacy/ReportGeneratorChooseSchedules";
import { ScheduleReplicaDrawer } from "./replicas/ScheduleReplicaDrawer";
import { ScheduleConstantToTerraformDrawer } from "./schedule-create-edit/ScheduleCopyToTerraform";
import {
  ScheduleCreateEditDrawer,
  ScheduleIntegrationDrawer,
} from "./schedule-create-edit/ScheduleCreateEditDrawer";
import { ScheduleCalendarFeedModal } from "./ScheduleCalendarFeedModal";
import { ScheduleRestoreDrawer } from "./ScheduleRestoreDrawer";
import { SyncToSlackModal } from "./SyncToSlackUserGroupModal";

export const SchedulesViewPage = () => {
  const drawer = useOutlet();
  const { id: scheduleId } = useParams<{ id: string }>();
  const { hasScope } = useIdentity();
  const commsPlatform = usePrimaryCommsPlatform();
  const navigate = useOrgAwareNavigate();

  // Modals
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [calendarFeedModalOpen, setCalendarFeedModalOpen] = useState(false);
  const [syncToSlackModalOpen, setSyncToSlackModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Drawers
  const [showLinkToLinearDrawer, setShowLinkToLinearDrawer] = useState(false);
  const [showScheduleDrawer, setShowScheduleDrawer] = useState(false);
  const [showRestoreDrawer, setShowRestoreDrawer] = useState(false);
  const [showTerraformDrawer, setShowTerraformDrawer] = useState(false);
  const [showReplicaDrawer, setShowReplicaDrawer] = useState(false);

  const { mirrorSchedules } = useFlags();

  const {
    data: scheduleResp,
    isLoading: scheduleIsLoading,
    error: scheduleError,
  } = useAPI(scheduleId ? "schedulesShow" : null, {
    id: scheduleId ?? "",
  });

  const escalationPathsForSchedule = scheduleResp?.escalation_paths ?? [];

  const { data: typeData } = useAPI(
    "catalogListTypes",
    {},
    { fallbackData: { catalog_types: [] } },
  );

  const { data: replicasResponse } = useAPI(
    scheduleId ? "schedulesListReplicas" : null,
    {
      scheduleId: scheduleId ?? "",
    },
  );

  const hasReplicas = (replicasResponse?.schedule_replicas ?? []).length > 0;
  const unlinkedUsers = hasReplicas
    ? replicasResponse?.user_links.filter((link) => !link.external_user_id) ??
      []
    : [];
  const pluralisedUsers = `${pluralize("user", unlinkedUsers.length)}`;

  const slackUserGroupTypeID = typeData?.catalog_types.find(
    (ct) => ct.registry_type === "SlackUserGroup",
  )?.id;

  const mirroredSlackUserGroupId =
    scheduleResp?.schedule.mirrored_slack_user_group_id;
  const { data: slackUserGroupCatalogEntryData } = useAPI(
    slackUserGroupTypeID && mirroredSlackUserGroupId
      ? "catalogListEntries"
      : null,
    {
      catalogTypeId: slackUserGroupTypeID as string,
      pageSize: 250,
      search: mirroredSlackUserGroupId,
      includeReferences: true,
    },
  );

  const slackUserGroupEntry: CatalogEntry | undefined =
    slackUserGroupCatalogEntryData?.catalog_entries.find(
      (entry) => entry.external_id === mirroredSlackUserGroupId,
    );
  const mirroredSlackUserGroupName = mirroredSlackUserGroupId
    ? // If the ID is set, even if the catalog lookup fails, use the ID rather than undefined
      slackUserGroupEntry?.attribute_values["handle"]?.value?.literal ??
      slackUserGroupEntry?.name ??
      mirroredSlackUserGroupId
    : undefined;

  const {
    data: { schedule_versions },
    isLoading: versionsLoading,
  } = useAPI(
    scheduleId ? "schedulesListVersions" : null,
    {
      scheduleId: scheduleId ?? "",
    },
    {
      fallbackData: { schedule_versions: [] },
    },
  );

  if (scheduleError) {
    if (scheduleError.status === 404) {
      return <NotFoundPage />;
    }
    return <GenericErrorMessage error={scheduleError} />;
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      icon={IconEnum.Calendar}
      loading={scheduleIsLoading}
      overflowY={false}
      crumbs={[
        { title: "On-call", to: `/on-call` },
        { title: "Schedules", to: "/on-call/schedules" },
      ]}
      backHref="/on-call/schedules"
      title={scheduleResp?.schedule.name ?? ""}
      onEditTitle={() => setRenameModalOpen(true)}
      noPadding
      accessory={
        <div className={"flex flex-center gap-2"}>
          <ManagementMetaBadge
            management={scheduleResp?.management_meta}
            resourceName="schedule"
          />
          <DropdownMenu
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                type="button"
                analyticsTrackingId="schedule-dropdown-menu-click"
                icon={IconEnum.DotsVerticalNopad}
                title="Schedule dropdown menu options"
              />
            }
            align={"end"}
          >
            <DropdownMenuGroup>
              <DropdownMenuItem
                analyticsTrackingId={null}
                onSelect={() => setCalendarFeedModalOpen(true)}
                label="Sync to calendar"
                icon={IconEnum.CalendarPlus}
                disabled={!hasScope(ScopeNameEnum.SchedulesFeed)}
                tooltipContent={
                  !hasScope(ScopeNameEnum.SchedulesFeed) &&
                  "You do not have permission to sync calendars to your feed."
                }
              />
              {!versionsLoading && schedule_versions.length > 1 && (
                <DropdownMenuItem
                  analyticsTrackingId={null}
                  onSelect={() => setShowRestoreDrawer(true)}
                  label="Restore previous versions"
                  icon={IconEnum.ClockRotate}
                  disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
                  tooltipContent={
                    !hasScope(ScopeNameEnum.SchedulesUpdate) &&
                    "You do not have permission to restore previous schedule versions."
                  }
                />
              )}
              <DropdownMenuItem
                analyticsTrackingId={null}
                onSelect={() => navigate(`/catalog/Schedule/${scheduleId}`)}
                label="View in catalog"
                icon={IconEnum.Book}
              />
            </DropdownMenuGroup>
            <DropdownMenuGroup>
              {isDashboard(scheduleResp?.management_meta) && (
                <DropdownMenuItem
                  analyticsTrackingId={null}
                  icon={IconEnum.Terraform}
                  onSelect={() => setShowTerraformDrawer(true)}
                  label="Manage in Terraform"
                >
                  Manage in Terraform
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                analyticsTrackingId={"schedules-link-linear"}
                label={"Link to Linear"}
                icon={IconEnum.Linear}
                onSelect={() => {
                  setShowLinkToLinearDrawer(true);
                }}
              >
                Link to Linear
              </DropdownMenuItem>
              {mirrorSchedules && (
                <DropdownMenuItem
                  analyticsTrackingId={null}
                  icon={IconEnum.Pagerduty}
                  onSelect={() => setShowReplicaDrawer(true)}
                  label="Mirror to PagerDuty"
                >
                  <div className={"flex flex-row justify-between"}>
                    <span>Mirror in PagerDuty</span>
                    <Badge
                      theme={BadgeTheme.Info}
                      size={BadgeSize.ExtraSmall}
                      className="ml-4"
                    >
                      Beta <Icon size={IconSize.XS} id={IconEnum.Test} />
                    </Badge>
                  </div>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
            <DropdownMenuGroup>
              <DropdownMenuItem
                analyticsTrackingId={null}
                onSelect={() => setDeleteModalOpen(true)}
                icon={IconEnum.Delete}
                iconProps={{
                  className: "!text-red-500",
                }}
                label="Delete"
                disabled={!hasScope(ScopeNameEnum.SchedulesDestroy)}
                tooltipContent={
                  !hasScope(ScopeNameEnum.SchedulesDestroy) &&
                  "You do not have permission to delete schedules."
                }
              >
                <span className="text-red-500">Delete</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenu>
          <GatedButton
            analyticsTrackingId={null}
            onClick={() => setShowScheduleDrawer(true)}
            theme={ButtonTheme.Secondary}
            disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
            disabledTooltipContent={
              !hasScope(ScopeNameEnum.SchedulesUpdate) &&
              "You do not have permission to update schedules."
            }
          >
            Edit
          </GatedButton>
          <GatedButton
            analyticsTrackingId={null}
            onClick={() => {
              navigate(`/on-call/schedules/${scheduleId}/overrides/create`);
            }}
            theme={ButtonTheme.Primary}
            disabled={!hasScope(ScopeNameEnum.ScheduleOverridesCreate)}
            disabledTooltipContent={
              !hasScope(ScopeNameEnum.ScheduleOverridesCreate) &&
              "You do not have permission to create overrides."
            }
          >
            Create override
          </GatedButton>
        </div>
      }
      banner={
        <>
          {scheduleResp && (
            <HeaderBanner
              className={tcx(
                "flex flex-col gap-4 lg:flex-row lg:justify-between items-start lg:items-center lg:min-h-16 bg-surface-primary border-b border-stroke-secondary",
              )}
            >
              <div className="flex-center-y gap-2">
                <OnCallNow scheduleResponse={scheduleResp} />
              </div>

              <div className="flex-center-y gap-2">
                <ScheduleReplicatingBadge
                  setShowReplicaDrawer={setShowReplicaDrawer}
                  replicasResponse={replicasResponse}
                />
                {commsPlatform === CommsPlatform.Slack && (
                  <SlackGroupBadge
                    slackGroup={mirroredSlackUserGroupName}
                    setSyncToSlackModalOpen={setSyncToSlackModalOpen}
                  />
                )}
                <ScheduleEscalationPathBadge
                  escalationPathsForSchedule={escalationPathsForSchedule}
                  scheduleID={scheduleResp.schedule.id}
                />
              </div>
            </HeaderBanner>
          )}
        </>
      }
    >
      {/* This means any sub-routes (e.g. create/edit override) get a fully-animated drawer */}
      <AnimatePresence>{drawer}</AnimatePresence>

      {unlinkedUsers.length > 0 && (
        <div className={"mx-8 "}>
          <Callout
            theme={CalloutTheme.Danger}
            iconOverride={IconEnum.User}
            title={
              <span>
                {joinSpansWithCommasAndConnectorWord(
                  unlinkedUsers
                    .filter((u) => u.name)
                    .map((u) => (
                      <span key={u.id} className={"text-sm-med"}>
                        {u.name}
                      </span>
                    )),
                )}{" "}
                couldn&apos;t be related to{" "}
                {unlinkedUsers.length === 1 ? "a " : ""}PagerDuty{" "}
                {pluralisedUsers} and will be replaced by the fallback user when
                mirroring this schedule to PagerDuty
              </span>
            }
            cta={
              <Button
                analyticsTrackingId={"open-catalog"}
                theme={ButtonTheme.Naked}
                className={"text-content-primary text-sm-med"}
                href={"/catalog/User"}
              >
                Open catalog
              </Button>
            }
            secondaryCta={
              <Button
                analyticsTrackingId={"view-replica-options"}
                onClick={() => setShowReplicaDrawer(true)}
              >
                Mirroring options
              </Button>
            }
          />
        </div>
      )}
      {scheduleResp && <ScheduleOverviewV2 schedule={scheduleResp.schedule} />}
      {renameModalOpen && scheduleResp && (
        <UpdateNameModal
          schedule={scheduleResp.schedule}
          onClose={() => setRenameModalOpen(false)}
        />
      )}
      {calendarFeedModalOpen && scheduleResp && (
        <ScheduleCalendarFeedModal
          onCancel={() => setCalendarFeedModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      {syncToSlackModalOpen && scheduleResp && (
        <SyncToSlackModal
          onClose={() => setSyncToSlackModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      {deleteModalOpen && scheduleResp && (
        <ConfirmDeleteScheduleModal
          onConfirm={() => navigate("/on-call/schedules")}
          onCancel={() => setDeleteModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      <AnimatePresence>
        {showScheduleDrawer && (
          <ScheduleCreateEditDrawer
            onClose={() => {
              setShowScheduleDrawer(false);
            }}
            editId={scheduleId}
          />
        )}
      </AnimatePresence>
      {showRestoreDrawer && scheduleResp && (
        <ScheduleRestoreDrawer
          schedule={scheduleResp.schedule}
          isOpen={showRestoreDrawer}
          onClose={() => setShowRestoreDrawer(false)}
        />
      )}
      {showTerraformDrawer && scheduleResp && (
        <ScheduleConstantToTerraformDrawer
          isOpen={showTerraformDrawer}
          onClose={() => setShowTerraformDrawer(false)}
          resourceID={scheduleId}
          schedule={scheduleResp.schedule}
          managementMeta={scheduleResp.management_meta}
        />
      )}
      {showReplicaDrawer && scheduleResp && (
        <ScheduleReplicaDrawer
          isOpen={showReplicaDrawer}
          onClose={() => setShowReplicaDrawer(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      {showLinkToLinearDrawer && (
        <ScheduleIntegrationDrawer
          integration={IntegrationSettingsProviderEnum.Linear}
          onClose={() => {
            setShowLinkToLinearDrawer(false);
          }}
        />
      )}
    </PageWrapper>
  );
};

const OnCallNow = ({
  scheduleResponse,
}: {
  scheduleResponse: SchedulesShowResponseBody;
}) => {
  const currentShifts = scheduleResponse.schedule.current_shifts ?? [];
  const currentUsers =
    currentShifts?.map((e) => {
      return scheduleResponse.users.find((u) => u.id === e.user_id);
    }) ?? [];

  const isExplicitNobody = currentShifts.every((u) => u.user_id === "NOBODY");
  return (
    <div className={"flex flex-row flex-wrap items-center gap-3"}>
      <span className={"text-sm text-slate-600"}>On call now:</span>
      {currentUsers.length > 0 && !isExplicitNobody ? (
        currentUsers.map((user) => {
          if (!user) {
            return null;
          }

          return <ScheduleEntryComponent key={user.id} user={user} />;
        })
      ) : (
        <ScheduleEntryComponent userId={"NOBODY"} />
      )}
    </div>
  );
};

const ScheduleReplicatingBadge = ({
  setShowReplicaDrawer,
  replicasResponse,
}: {
  replicasResponse: SchedulesListReplicasResponseBody | undefined;
  setShowReplicaDrawer: (open: boolean) => void;
}) => {
  const { data: integrationData } = useAPI("integrationsList", undefined);

  if (!replicasResponse || replicasResponse.schedule_replicas.length === 0) {
    return null;
  }

  if (!hasPagerDuty(integrationData)) {
    return null;
  }

  return (
    <div
      className={
        "flex flex-row items-center gap-1 hover:cursor-pointer align-middle hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5"
      }
      onClick={() => setShowReplicaDrawer(true)}
    >
      <Icon id={IconEnum.Pagerduty} size={IconSize.Small} />
      <span className={"text-sm monospace whitespace-nowrap"}>
        Mirrored to PagerDuty
      </span>
    </div>
  );
};

const SlackGroupBadge = ({
  slackGroup,
  setSyncToSlackModalOpen,
}: {
  slackGroup?: string;
  setSyncToSlackModalOpen: (open: boolean) => void;
}) => {
  return (
    <div
      className={
        "flex flex-row items-center gap-1 hover:cursor-pointer align-middle hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5"
      }
      onClick={() => setSyncToSlackModalOpen(true)}
    >
      {slackGroup ? (
        <Icon id={IconEnum.Slack} size={IconSize.Small} />
      ) : (
        <Icon id={IconEnum.SlackGreyscale} className="text-content-tertiary" />
      )}
      {slackGroup ? (
        <Txt>
          <span className={"text-sm monospace whitespace-nowrap"}>
            @{slackGroup}
          </span>
        </Txt>
      ) : (
        <Txt lightGrey>
          <span className={"text-sm whitespace-nowrap"}>
            Connect Slack group
          </span>
        </Txt>
      )}
    </div>
  );
};

export const ScheduleEscalationPathBadge = ({
  escalationPathsForSchedule,
  scheduleID,
}: {
  escalationPathsForSchedule: EscalationPathSlim[];
  scheduleID: string;
}) => {
  const navigate = useOrgAwareNavigate();

  return escalationPathsForSchedule?.length === 1 ? (
    <OrgAwareLink
      to={`/on-call/escalation-paths/${escalationPathsForSchedule[0].id}`}
      className="align-middle items-center"
    >
      <Badge
        theme={BadgeTheme.Secondary}
        icon={IconEnum.EscalationPath}
        iconClassName={"text-brand"}
        className={"hover:cursor-pointer"}
        size={BadgeSize.Medium}
      >
        {escalationPathsForSchedule?.[0].name}
      </Badge>
    </OrgAwareLink>
  ) : escalationPathsForSchedule?.length > 1 ? (
    <div className={"flex flex-row items-center gap-1"}>
      <DropdownMenu
        scroll
        align={"end"}
        triggerButton={
          <Badge
            theme={BadgeTheme.Secondary}
            icon={IconEnum.EscalationPath}
            iconClassName={"text-brand"}
            className={"hover:cursor-pointer"}
            size={BadgeSize.Medium}
          >
            {escalationPathsForSchedule?.length} escalation paths
          </Badge>
        }
      >
        {escalationPathsForSchedule.map((ep) => (
          <DropdownMenuItem
            key={ep.id}
            label={ep.name}
            onSelect={() => {
              navigate(`/on-call/escalation-paths/${ep.id}`);
            }}
            analyticsTrackingId={null}
          >
            <Badge
              theme={BadgeTheme.Unstyled}
              icon={IconEnum.EscalationPath}
              iconClassName={"text-brand"}
              className={"hover:cursor-pointer p-0"}
              size={BadgeSize.Medium}
            >
              {ep.name}
            </Badge>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </div>
  ) : (
    <OrgAwareLink
      to={`/on-call/escalation-paths/create?schedule_id=${scheduleID}`}
      className="align-middle"
    >
      <Badge
        theme={BadgeTheme.Tertiary}
        icon={IconEnum.EscalationPath}
        iconClassName={"text-content-tertiary"}
        className={"hover:cursor-pointer"}
        size={BadgeSize.Medium}
      >
        No escalation path
      </Badge>
    </OrgAwareLink>
  );
};

export function UpdateNameModal({
  schedule,
  onClose,
}: {
  schedule: Schedule;
  onClose: () => void;
}): React.ReactElement {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.SchedulesUpdate);

  const formMethods = useForm<SchedulesUpdateNameRequestBody>({
    defaultValues: {
      name: schedule.name,
    },
  });

  const refreshSchedulesList = useRevalidate(["schedulesList"]);
  const refreshSchedulesShowResponseBody = useRevalidate(["schedulesShow"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "schedulesShow",
    { id: schedule.id },
    async (apiClient, args: SchedulesUpdateNameRequestBody) => {
      await apiClient.schedulesUpdateName({
        id: schedule.id,
        updateNameRequestBody: {
          ...args,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        refreshSchedulesList();
        refreshSchedulesShowResponseBody();
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename schedule"
      analyticsTrackingId="rename-schedule"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
          disabled={!canEdit}
          disabledTooltipContent={"You do not have permission to do this."}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
}

export const ConfirmDeleteScheduleModal = ({
  onConfirm,
  onCancel,
  schedule,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  schedule: Schedule;
}) => {
  const refetchSchedules = useRevalidate(["schedulesList"]);

  const {
    trigger: onSubmit,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "schedulesList",
    {},
    async (apiClient, { id }: { id: string }) => {
      await apiClient.schedulesDestroy({
        id,
      });
      await refetchSchedules();
    },
    {
      onSuccess: () => {
        onConfirm();
      },
    },
  );

  const onDelete = () => {
    onSubmit({ id: schedule.id });
  };

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <DeletionConfirmationModal
      resourceTitle={schedule.name}
      onDelete={onDelete}
      isDeleting={isDestroying}
      isOpen={true}
      onClose={onCancel}
      title="Delete schedule"
      deleteConfirmationContent={
        <>
          Are you sure you want to delete the &lsquo;{schedule.name}&rsquo;
          schedule?
        </>
      }
      analyticsTrackingId="delete-schedule"
      fetchDependentResources={[
        {
          resource_type: `CatalogEntry["Schedule"]`,
          id: schedule.id,
        },
      ]}
    />
  );
};
