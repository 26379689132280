import { IncidentUpdatesRequestRequestBody } from "@incident-io/api";
import { Modal, Textarea } from "@incident-io/status-page-ui";
import { ErrorMessage } from "@incident-ui";
import { useForm } from "react-hook-form";
import { useMutationV2 } from "src/utils/swr";

export const RequestUpdateModal = ({
  incidentId,
  onClose,
  setUpdateRequested,
}: {
  onClose: () => void;
  incidentId: string;
  setUpdateRequested: (updateRequested: boolean) => void;
}): React.ReactElement => {
  const { handleSubmit, setError, register } =
    useForm<IncidentUpdatesRequestRequestBody>();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError: genericError,
  } = useMutationV2(
    async (apiClient, data) => {
      await apiClient.incidentUpdatesRequest({
        requestRequestBody: {
          incident_id: incidentId,
          message: data?.message,
        },
      });
      setUpdateRequested(true);
      onClose();
    },
    { setError, invalidate: [] },
  );

  return (
    <Modal
      as="form"
      isOpen
      title="Request an update"
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText="Send"
      onClose={onClose}
      loading={saving}
      analyticsTrackingId={"internal-sp-request-update"}
    >
      <div className="space-y-4">
        <div className="text-sm">
          We&apos;ll send a message to the incident channel requesting an update
          on your behalf.
        </div>
        <Textarea
          {...register("message")}
          id="message"
          placeholder=""
          label="Message (optional)"
        />
        <ErrorMessage message={genericError} />
      </div>
    </Modal>
  );
};
