import {
  ExploDashboardInfo,
  ExploDashboardWithDrilldownInfo,
  PanelPanelTypeEnum,
} from "@incident-io/api";
import { ContentBox } from "@incident-ui";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryParams } from "src/utils/query-params";

import { ExploDashboardPanel } from "./ExploDashboardPanel";
import { ExploDashboardWithDrilldownPanel } from "./ExploDashboardWithDrilldownPanel";
import { TextPanel } from "./TextPanel";
import { DashboardContext } from "./types";
import { EditDashboardFormData, PanelFormData } from "./useInsightsContext";

const checkIfScrollToMatchesPanel = (
  scrollTo: string | null,
  panel: PanelFormData,
): boolean => {
  if (!scrollTo) return false;

  return (
    scrollTo === panel.name ||
    scrollTo === panel.explo_dashboard?.explo_dashboard_id ||
    scrollTo ===
      panel.explo_dashboard_with_drilldown?.top_half_explo_dashboard_id ||
    scrollTo ===
      panel.explo_dashboard_with_drilldown?.drilldown_explo_dashboard_id
  );
};

export const InsightsPanel = ({
  panel,
  panelIdx,
  dashboardContext,
}: {
  panel: PanelFormData;
  panelIdx: number;
  dashboardContext: DashboardContext;
}) => {
  const self = useRef<HTMLDivElement | null>(null);
  const queryParams = useQueryParams();
  const scrollTo = queryParams.get("scroll_to");

  const scrollToMatchesPanel = checkIfScrollToMatchesPanel(scrollTo, panel);

  // Wait for dashboard to load before attempting to scroll
  // When the dashboards have no height (before it's loaded), all
  // dashboards fit on the initial page, and so it appears to not scroll
  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      // Check origin of message before reading
      if (e.origin !== "https://app.explo.co") return;

      // we only care about dashboardLoaded events
      if (e.data.event !== "dashboardLoaded") return;

      // is the event for the correct explo dashboard?
      const dashboardMatches =
        e.data.detail.dashboardId ===
          panel.explo_dashboard?.explo_dashboard_id ||
        e.data.detail.dashboardId ===
          panel.explo_dashboard_with_drilldown?.top_half_explo_dashboard_id ||
        e.data.detail.dashboardId ===
          panel.explo_dashboard_with_drilldown?.drilldown_explo_dashboard_id;

      if (!dashboardMatches) return;

      // scroll if the dashboard has a height greater than 0, and it matches our query param
      if (
        e.data.detail.dashboardHeight > 0 &&
        scrollToMatchesPanel &&
        self.current
      ) {
        self.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    window.addEventListener("message", messageListener);
    return () => window.removeEventListener("message", messageListener);
  }, [self, panel, scrollToMatchesPanel, queryParams]);

  return (
    <ContentBox ref={self} className="bg-white p-5 flex flex-col gap-5">
      <InsightsPanelContent
        panel={panel}
        panelIdx={panelIdx}
        dashboardContext={dashboardContext}
      />
    </ContentBox>
  );
};

const InsightsPanelContent = ({
  panel,
  panelIdx,
  dashboardContext,
}: {
  panel: PanelFormData;
  panelIdx: number;
  dashboardContext: DashboardContext;
}) => {
  const formMethods = useFormContext<EditDashboardFormData>();
  const variableData = formMethods.watch(`panels.${panelIdx}.variables_data`);

  switch (panel.panel_type) {
    case PanelPanelTypeEnum.ExploDashboard:
      return (
        <ExploDashboardPanel
          exploDashboardInfo={panel.explo_dashboard as ExploDashboardInfo}
          panelName={panel.name}
          panelIdx={panelIdx}
          panelFilterContexts={panel.filter_contexts || []}
          variableData={variableData}
          variables={panel.variables}
          dashboardContext={dashboardContext}
        />
      );
    case PanelPanelTypeEnum.ExploDashboardWithDrilldown:
      return (
        <ExploDashboardWithDrilldownPanel
          drilldownInfo={
            panel.explo_dashboard_with_drilldown as ExploDashboardWithDrilldownInfo
          }
          panelIdx={panelIdx}
          variableData={variableData}
          panelName={panel.name}
          variables={panel.variables}
          dashboardContext={dashboardContext}
        />
      );
    case PanelPanelTypeEnum.Text:
      return (
        <TextPanel panelIdx={panelIdx} dashboardContext={dashboardContext} />
      );
    default:
      return <div>Unsupported panel type</div>;
  }
};
