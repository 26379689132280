/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsCreateMethodRequestBody
 */
export interface OnCallNotificationsCreateMethodRequestBody {
    /**
     * If an email method, the address it applies to
     * @type {string}
     * @memberof OnCallNotificationsCreateMethodRequestBody
     */
    email_address?: string;
    /**
     * The type of notification method
     * @type {string}
     * @memberof OnCallNotificationsCreateMethodRequestBody
     */
    method_type: OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum;
    /**
     * If a phone method, the number it applies to
     * @type {string}
     * @memberof OnCallNotificationsCreateMethodRequestBody
     */
    phone_number?: string;
    /**
     * How we verify the phone number, either sms or call
     * @type {string}
     * @memberof OnCallNotificationsCreateMethodRequestBody
     */
    phone_verification_type?: OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum {
    App = 'app',
    Email = 'email',
    MicrosoftTeams = 'microsoft_teams',
    Phone = 'phone',
    Slack = 'slack'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum {
    Call = 'call',
    Sms = 'sms'
}

export function OnCallNotificationsCreateMethodRequestBodyFromJSON(json: any): OnCallNotificationsCreateMethodRequestBody {
    return OnCallNotificationsCreateMethodRequestBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsCreateMethodRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsCreateMethodRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'method_type': json['method_type'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'phone_verification_type': !exists(json, 'phone_verification_type') ? undefined : json['phone_verification_type'],
    };
}

export function OnCallNotificationsCreateMethodRequestBodyToJSON(value?: OnCallNotificationsCreateMethodRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_address': value.email_address,
        'method_type': value.method_type,
        'phone_number': value.phone_number,
        'phone_verification_type': value.phone_verification_type,
    };
}

