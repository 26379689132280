import {
  CoreDashboard,
  CoreDashboardFilterContextsEnum,
  EligibilityInformation,
} from "@incident-io/api";
import {
  AppliedFiltersInline,
  AvailableFilter,
  enrichAvailableFilterFields,
  FilterPopover,
  useInitialiseInsightsFilters,
} from "@incident-shared/filters";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  FullPageEmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { format, isToday } from "date-fns";
import { useState } from "react";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { useInsightsLastSync } from "../../../../../hooks/useInsightsLastSync";
import { tcx } from "../../../../../utils/tailwind-classes";
import graphic from "../common/empty-state-dashboard-graphic.svg";
import { InsightsDatePicker } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import { panelToFormData } from "../common/marshall";
import { DashboardContext } from "../common/types";
import { useIsSampleData } from "../common/useIsSampleData";
import { InsightsShowDashboardProvider } from "./InsightsShowDashboardContext";

export const InsightsCoreDashboardRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { id } = useParams<{ id: string }>() as { id: string };
  const urlParams = useQueryParams();

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCoreDashboard", {
    id,
  });

  const {
    insightsV3FilterFields,
    loading: filtersLoading,
    error: filtersError,
  } = useInitialiseInsightsFilters(urlParams.toString());

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights" />;
  }

  const error = filtersError || dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const loading = dashboardLoading || filtersLoading;
  if (loading || !dashboardData) {
    return <FullPageLoader />;
  }

  return (
    <InsightsCoreDashboardPage
      dashboard={dashboardData.dashboard}
      filterFields={insightsV3FilterFields}
    />
  );
};

const InsightsCoreDashboardPage = ({
  dashboard,
  filterFields,
}: {
  dashboard: CoreDashboard;
  filterFields: Record<CoreDashboardFilterContextsEnum, AvailableFilter[]>;
}) => {
  const { isSampleData } = useIsSampleData();
  const lastSyncAt = useInsightsLastSync();

  // Currently, all dashboards have at most one filter context, so we can just take the first one.
  const enrichedFields =
    dashboard.filter_contexts.length > 0
      ? enrichAvailableFilterFields(filterFields[dashboard.filter_contexts[0]])
      : [];

  return (
    <InsightsShowDashboardProvider
      filterFields={enrichedFields}
      dashboard={dashboard}
    >
      <PageWrapper
        width={PageWidth.Wide}
        icon={dashboard.icon}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        titleAccessory={
          lastSyncAt ? <SyncBadge timestamp={lastSyncAt} /> : undefined
        }
        className={
          dashboard.eligibility.is_eligible ? "bg-surface-secondary" : ""
        }
        crumbs={[
          {
            title: "Insights",
            to: "/insights",
          },
        ]}
        backHref="/insights"
        noPadding
        accessory={
          <div className="flex items-center gap-2">
            {isSampleData ? (
              <Badge theme={BadgeTheme.Warning} size={BadgeSize.ExtraSmall}>
                Sample data
                <Tooltip
                  side={"bottom"}
                  content={
                    <>
                      We&apos;re showing anonymised sample data to demonstrate
                      this feature
                    </>
                  }
                >
                  <div>
                    <Icon
                      id={IconEnum.Info}
                      className={"cursor-pointer"}
                      size={IconSize.Medium}
                    />
                  </div>
                </Tooltip>
              </Badge>
            ) : undefined}
            <InsightsDatePicker />
            <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <Button
                  onClick={() => onClick()}
                  analyticsTrackingId={"insights-core-dashboard-filter"}
                  icon={IconEnum.FilterBars}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                >
                  Add filter
                </Button>
              )}
            />
          </div>
        }
        banner={
          <AppliedFiltersInline
            totalNumberOfItems={null}
            itemsLabel={null}
            hideDefaultPopover={true}
            className={" bg-white px-6 py-4"}
          />
        }
      >
        {dashboard.eligibility.is_eligible ? (
          <div className="flex flex-col grow gap-6 p-6">
            {dashboard.panels.map((panel, idx) => (
              <InsightsPanel
                key={idx}
                panelIdx={idx}
                panel={panelToFormData(panel)}
                dashboardContext={DashboardContext.Core}
              />
            ))}
          </div>
        ) : (
          <InsightsDashboardEmptyState eligibility={dashboard.eligibility} />
        )}
      </PageWrapper>
    </InsightsShowDashboardProvider>
  );
};

const InsightsDashboardEmptyState = ({
  eligibility,
}: {
  eligibility: EligibilityInformation;
}) => {
  return (
    <div className="flex flex-col grow p-6 bg-white">
      <FullPageEmptyState
        graphic={<img src={graphic} className="my-6" />}
        title={eligibility.ineligible_explanation_title || ""}
        subtitle={eligibility.ineligible_explanation_text}
        gradientClasses={"from-[#F7F7F8] to-[#FFFFFF]"}
        cards={<></>}
        cta={
          eligibility.ineligible_call_to_action_path ? (
            <Button
              href={eligibility.ineligible_call_to_action_path}
              analyticsTrackingId="insights-empty-state-cta"
              theme={ButtonTheme.Primary}
            >
              {eligibility.ineligible_call_to_action_text}
            </Button>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

const LastSyncAt = ({ lastSyncAt }: { lastSyncAt: Date }) => {
  return (
    <span className={"text-content-secondary"}>
      Synced{" "}
      {isToday(lastSyncAt)
        ? `at ${format(lastSyncAt, "HH:mm")}`
        : `on ${format(lastSyncAt, "EEE, d MMM HH:mm")}`}
    </span>
  );
};

const SyncBadge = ({ timestamp }: { timestamp: Date }) => {
  const [showTimestamp, setShowTimestamp] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowTimestamp(true)}
      onMouseLeave={() => setShowTimestamp(false)}
    >
      <Badge
        theme={BadgeTheme.Tertiary}
        size={BadgeSize.Small}
        icon={IconEnum.Cloud}
        iconClassName={"text-content-secondary"}
        className={tcx("transition-all duration-400 overflow-hidden", "pr-1")}
      >
        <div
          className={tcx(
            "h-full flex items-center",
            "transition-all duration-400 overflow-hidden whitespace-nowrap",
            showTimestamp
              ? "opacity-100 translate-x-0 pr-1"
              : "opacity-0 -translate-x-2",
          )}
        >
          {showTimestamp && <LastSyncAt lastSyncAt={timestamp} />}
        </div>
      </Badge>
    </div>
  );
};
