import { UserOption } from "@incident-io/api";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import { useAPI } from "../../../../../utils/swr";

export type UserCacheReturn = {
  hydrating: boolean;
  getHydratedUser: (value?: string | undefined) => UserOption | undefined;
  cacheHydratedUsers: (users: UserOption[]) => void;
  removeFromCache: (users: string[]) => void;
};

export const useHydratedUserCache = (idList: string[]): UserCacheReturn => {
  const [hydratedValuesCache, setHydratedValuesCache] = useState<
    Map<string, UserOption>
  >(new Map());

  const idsTofetch = _.uniq(idList)
    .filter((id) => !hydratedValuesCache.has(id) && id !== "NOBODY")
    .sort();

  const { data: users, isLoading: hydrating } = useAPI(
    idsTofetch.length === 0 ? null : "usersTypeahead",
    {
      idList: idsTofetch,
    },
  );

  const cacheHydratedUsers = useCallback((newUsers: UserOption[]) => {
    setHydratedValuesCache((currentCache) => {
      const updatedCache = new Map(currentCache);

      // Only add new or updated users
      newUsers.forEach((user) => {
        const existingUser = updatedCache.get(user.value);
        if (!existingUser || !_.isEqual(existingUser, user)) {
          updatedCache.set(user.value, user);
        }
      });

      // Only update state if cache has actually changed
      return _.isEqual(currentCache, updatedCache)
        ? currentCache
        : updatedCache;
    });
  }, []);

  // Update cache when new user data is received
  useEffect(() => {
    if (users?.options) {
      cacheHydratedUsers(users.options);
    }
  }, [users, cacheHydratedUsers]);

  const getHydratedUser = useCallback(
    (value?: string): UserOption | undefined => {
      if (!value) return undefined;
      return hydratedValuesCache.get(value);
    },
    [hydratedValuesCache],
  );

  const removeFromCache = useCallback((ids: string[]) => {
    setHydratedValuesCache((currentCache) => {
      const updatedCache = new Map(currentCache);
      ids.forEach((id) => updatedCache.delete(id));
      return updatedCache;
    });
  }, []);

  return {
    hydrating,
    getHydratedUser,
    cacheHydratedUsers,
    removeFromCache,
  };
};
