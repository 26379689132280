import { IncidentLearningsItem } from "@incident-io/api";
import { LocalDateTime } from "@incident-ui";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { ContentBox } from "@incident-ui/ContentBox/ContentBox";
import { EmptyState } from "@incident-ui/EmptyState/EmptyState";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { Markdown } from "@incident-ui/Markdown/Markdown";
import React, { ReactNode } from "react";
import { useAPI } from "src/utils/swr";

export const IncidentFacts = ({
  incidentId,
  className,
}: {
  incidentId: string;
  className?: string;
}) => {
  const [expandedSections, setExpandedSections] = React.useState<string[]>([
    "symptoms",
    "tags",
    "learnings",
  ]);

  const toggleSection = (section: string) => {
    setExpandedSections((prev) =>
      prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section],
    );
  };

  const { data, isLoading, error } = useAPI("aIStaffShowIncidentFact", {
    incidentId: incidentId || "",
  });

  const incidentFact = data?.fact;

  if (isLoading) {
    return (
      <ContentBox className={className}>
        <div className="flex justify-center items-center h-24">
          <Icon
            id={IconEnum.Loader}
            className="animate-spin text-content-secondary"
          />
        </div>
      </ContentBox>
    );
  }

  if (error || !incidentFact) {
    return (
      <ContentBox className={className}>
        <EmptyState
          title="No incident facts available"
          content="We couldn't find any incident facts for this incident."
          icon={IconEnum.Warning}
        />
      </ContentBox>
    );
  }

  return (
    <ContentBox className={className}>
      {/* Symptoms Section */}
      {incidentFact.symptoms && (
        <FactSection
          title="Symptoms"
          isExpanded={expandedSections.includes("symptoms")}
          onToggle={() => toggleSection("symptoms")}
          timestamp={incidentFact.symptoms_cache_until}
          isVisible={!!incidentFact.symptoms}
          isLast={false}
        >
          <Markdown>{incidentFact.symptoms}</Markdown>
        </FactSection>
      )}

      {/* Tags Section */}
      <FactSection
        title="Tags"
        isExpanded={expandedSections.includes("tags")}
        onToggle={() => toggleSection("tags")}
        timestamp={incidentFact.tags_cache_until}
        isVisible={!!(incidentFact.tags && incidentFact.tags.length > 0)}
        isLast={false}
      >
        <div className="flex flex-wrap gap-2">
          {incidentFact.tags?.map((tag, index) => (
            <Badge key={index} theme={BadgeTheme.Secondary}>
              {tag}
            </Badge>
          ))}
        </div>
      </FactSection>

      {/* Learnings Section */}
      <FactSection
        title="Learnings"
        isExpanded={expandedSections.includes("learnings")}
        onToggle={() => toggleSection("learnings")}
        timestamp={incidentFact.learnings_cache_until}
        isVisible={!!incidentFact.learnings}
        isLast={true}
      >
        <>
          {/* Communication Protocols */}
          {renderLearningSection(
            "Communication Protocols",
            incidentFact.learnings?.communication_protocols,
          )}

          {/* Diagnostic Steps */}
          {renderLearningSection(
            "Diagnostic Steps",
            incidentFact.learnings?.diagnostic_steps,
          )}

          {/* Failed Approaches */}
          {renderLearningSection(
            "Failed Approaches",
            incidentFact.learnings?.failed_approaches,
          )}

          {/* Subject Matter Experts */}
          {renderLearningSection(
            "Subject Matter Experts",
            incidentFact.learnings?.subject_matter_experts,
          )}

          {/* Technical Remediations */}
          {renderLearningSection(
            "Technical Remediations",
            incidentFact.learnings?.technical_remediations,
          )}
        </>
      </FactSection>
    </ContentBox>
  );
};

// Reusable section component for fact sections
const FactSection = ({
  title,
  isExpanded,
  onToggle,
  timestamp,
  children,
  isVisible,
  isLast,
}: {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  timestamp?: Date;
  children: ReactNode;
  isVisible: boolean;
  isLast: boolean;
}) => {
  if (!isVisible) return null;

  return (
    <div className={`p-4 ${!isLast ? "border-b border-stroke" : ""}`}>
      <div className="flex justify-between items-center mb-2">
        <div className="text-lg font-medium">{title}</div>
        <div className="flex items-center gap-2">
          {timestamp && (
            <div className="text-xs text-content-secondary">
              Generated at{" "}
              <LocalDateTime className="text-xs" timestamp={timestamp} />
            </div>
          )}
          <SectionToggle expanded={isExpanded} onToggle={onToggle} />
        </div>
      </div>
      {isExpanded && <div className="text-sm pt-4">{children}</div>}
    </div>
  );
};

const renderLearningSection = (
  title: string,
  items?: IncidentLearningsItem[],
) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className="mb-4">
      <h4 className="text-sm font-medium mb-2">{title}</h4>
      <div className="space-y-3">
        {items.map((item, index) => (
          <LearningItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

// Section toggle component for use in multiple places
const SectionToggle = ({
  expanded,
  onToggle,
}: {
  expanded: boolean;
  onToggle: () => void;
}) => (
  <Button
    title={expanded ? "Collapse" : "Expand"}
    icon={expanded ? IconEnum.Collapse : IconEnum.Expand}
    theme={ButtonTheme.Tertiary}
    size={BadgeSize.Small}
    onClick={(e) => {
      e.stopPropagation();
      onToggle();
    }}
    analyticsTrackingId={null}
  />
);

const LearningItem = ({ item }: { item: IncidentLearningsItem }) => {
  return (
    <div className="border border-stroke rounded-md">
      <div className="p-3 flex justify-between items-start cursor-pointer">
        <div className="font-medium text-sm">{item.description}</div>
        <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Info}>
          Confidence: {item.confidence}
        </Badge>
      </div>

      <div className="p-3 border-t border-stroke flex flex-col gap-2">
        {item.detail && (
          <div>
            <div className="text-xs font-medium mb-1">Details</div>
            <div className="text-xs whitespace-pre-wrap">{item.detail}</div>
          </div>
        )}

        {item.evidence && (
          <div>
            <div className="text-xs font-medium mb-1">Evidence</div>
            <div className="text-xs whitespace-pre-wrap">{item.evidence}</div>
          </div>
        )}
      </div>
    </div>
  );
};
