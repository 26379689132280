import { IntegrationsGrafanaInstallRequestBody } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import { ModalFooter, OrgAwareLink } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

type FormType = IntegrationsGrafanaInstallRequestBody;

export const ConnectToGrafanaModal = ({
  onClose,
  isReconnect,
}: {
  onClose: () => void;
  integration: IntegrationsListObject;
  isReconnect?: boolean;
}): React.ReactElement | null => {
  const formMethods = useForm<FormType>({});

  const { trigger, isMutating } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormType) => {
      await apiClient.integrationsGrafanaInstall({
        installRequestBody: {
          api_key: data.api_key,
          api_url: data.api_url,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      title={"Connect to Grafana"}
      analyticsTrackingId="connect-integration"
      analyticsTrackingMetadata={{ integration: "git_lab" }}
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonText={isReconnect ? "Reconnect" : "Connect"}
          confirmButtonType="submit"
        />
      }
    >
      <Form.Helptext className="flex flex-col gap-1">
        <p>
          Connect to Grafana to add context during incidents from Grafana
          alerts.
        </p>
        <p>
          If you want to add a Grafana alert source, you can do so in the{" "}
          <OrgAwareLink
            className="text-content-primary underline"
            to={{
              pathname: "/alerts/sources/create",
              search: "?source_type=grafana",
            }}
          >
            alert source settings
          </OrgAwareLink>
          .
        </p>
      </Form.Helptext>

      <InputV2
        formMethods={formMethods}
        name="api_url"
        label="API URL"
        placeholder="acme.grafana.net"
      />
      <InputV2
        formMethods={formMethods}
        name="api_key"
        label="API Key"
        type={InputType.Password}
        placeholder="glsa_1234"
        helptext={
          <>
            This must be a non-expiring token that has permissions to view and
            modify dashboards and folders. We won&rsquo;t modify dashboards, but
            due to Grafana&rsquo;s API permissions, we need this level of
            access.
          </>
        }
      />
    </Form.Modal>
  );
};
