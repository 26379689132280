/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPreferenceManualEscalationProviderOption,
    UserPreferenceManualEscalationProviderOptionFromJSON,
    UserPreferenceManualEscalationProviderOptionFromJSONTyped,
    UserPreferenceManualEscalationProviderOptionToJSON,
} from './UserPreferenceManualEscalationProviderOption';

/**
 * 
 * @export
 * @interface EscalationUserTarget
 */
export interface EscalationUserTarget {
    /**
     * The other options available for this user, only returned when include_options is set
     * @type {Array<UserPreferenceManualEscalationProviderOption>}
     * @memberof EscalationUserTarget
     */
    available_options?: Array<UserPreferenceManualEscalationProviderOption>;
    /**
     * The URL of the avatar for the user
     * @type {string}
     * @memberof EscalationUserTarget
     */
    avatar_url?: string;
    /**
     * ID of the user in the provider, e.g. the incident.io user ID or the ID in the external system
     * @type {string}
     * @memberof EscalationUserTarget
     */
    id: string;
    /**
     * The name of the user in the provider, e.g. the incident.io user name or the name in the external system
     * @type {string}
     * @memberof EscalationUserTarget
     */
    name: string;
    /**
     * The provider of the user, e.g. incident.io or PagerDuty
     * @type {string}
     * @memberof EscalationUserTarget
     */
    provider: EscalationUserTargetProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationUserTargetProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function EscalationUserTargetFromJSON(json: any): EscalationUserTarget {
    return EscalationUserTargetFromJSONTyped(json, false);
}

export function EscalationUserTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationUserTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_options': !exists(json, 'available_options') ? undefined : ((json['available_options'] as Array<any>).map(UserPreferenceManualEscalationProviderOptionFromJSON)),
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'id': json['id'],
        'name': json['name'],
        'provider': json['provider'],
    };
}

export function EscalationUserTargetToJSON(value?: EscalationUserTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_options': value.available_options === undefined ? undefined : ((value.available_options as Array<any>).map(UserPreferenceManualEscalationProviderOptionToJSON)),
        'avatar_url': value.avatar_url,
        'id': value.id,
        'name': value.name,
        'provider': value.provider,
    };
}

