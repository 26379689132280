import { AISpan, AIStaffListAISpansTypeEnum } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  EmptyState,
  IconEnum,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
} from "@incident-ui";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { RunBackfillDrawer } from "./RunBackfillDrawer";

export const BackfillsListPage = () => {
  const { data, isLoading } = useAPI(
    "aIStaffListAISpans",
    {
      type: AIStaffListAISpansTypeEnum.Backfill,
    },
    {
      fallbackData: { spans: [] },
    },
  );

  const [showBackfillDrawer, setShowBackfillDrawer] = useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          theme={ButtonTheme.Primary}
          analyticsTrackingId={null}
          onClick={() => setShowBackfillDrawer(true)}
        >
          Run backfill
        </Button>
      }
    >
      {showBackfillDrawer && (
        <RunBackfillDrawer onClose={() => setShowBackfillDrawer(false)} />
      )}
      <LoadingWrapper loading={isLoading}>
        {!data?.spans || data.spans.length === 0 ? (
          <EmptyState content="No backfills found" />
        ) : (
          <StackedList>
            {data.spans.map((span) => (
              <BackfillRow key={span.id} span={span} />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
    </WorkbenchSubPageWrapper>
  );
};

const BackfillRow = ({ span }: { span: AISpan }) => {
  const viewHref = `/workbench/backfills/${span.id}`;

  // Parse the input data which contains backfill configuration
  const inputData = span.input as {
    Processor?: string;
    RunOptions?: {
      BatchSize?: number;
      RunInline?: boolean;
      Concurrency?: number | null;
    };
    ResourceOptions?: {
      ResourceID?: string | null;
      LimitPerOrg?: number;
      NotProcessedSince?: string | null;
      ResourceCutoffDays?: number;
      RunForAllOrganisations?: boolean;
    };
  };

  const resourceId = inputData?.ResourceOptions?.ResourceID || span.resource_id;
  const limitPerOrg = inputData?.ResourceOptions?.LimitPerOrg;
  const cutoffDays = inputData?.ResourceOptions?.ResourceCutoffDays;
  const notProcessedSince = inputData?.ResourceOptions?.NotProcessedSince;

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={viewHref}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime timestamp={span.created_at} className="font-medium" />
          <div className="max-w-[20vw] truncate">{span.name}</div>
          {resourceId && (
            <div className="max-w-[20vw] truncate mono text-content-tertiary">
              {resourceId}
            </div>
          )}
          {limitPerOrg !== undefined && (
            <div className="text-content-secondary">
              <span className="text-content-tertiary">Limit:</span>{" "}
              {limitPerOrg}
            </div>
          )}
          {cutoffDays !== undefined && (
            <div className="text-content-secondary">
              <span className="text-content-tertiary">Cutoff:</span>{" "}
              {cutoffDays} days
            </div>
          )}
          {notProcessedSince && (
            <div className="text-content-secondary">
              <span className="text-content-tertiary">
                Not processed since:
              </span>{" "}
              {notProcessedSince}
            </div>
          )}
        </div>
        <div className="grow" />
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={viewHref}
          analyticsTrackingId={null}
          title="View backfill"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
