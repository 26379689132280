/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffCreateSearchGroundTruthRequestBody
 */
export interface AIStaffCreateSearchGroundTruthRequestBody {
    /**
     * The items that our search should find
     * @type {Array<string>}
     * @memberof AIStaffCreateSearchGroundTruthRequestBody
     */
    answer: Array<string>;
    /**
     * Timestamp to limit the search to
     * @type {Date}
     * @memberof AIStaffCreateSearchGroundTruthRequestBody
     */
    cutoff: Date;
    /**
     * Search query question
     * @type {string}
     * @memberof AIStaffCreateSearchGroundTruthRequestBody
     */
    question: string;
    /**
     * Type of question (code_changes, etc)
     * @type {string}
     * @memberof AIStaffCreateSearchGroundTruthRequestBody
     */
    question_type: string;
    /**
     * When the ground truth was verified by a human
     * @type {Date}
     * @memberof AIStaffCreateSearchGroundTruthRequestBody
     */
    verified_at?: Date;
}

export function AIStaffCreateSearchGroundTruthRequestBodyFromJSON(json: any): AIStaffCreateSearchGroundTruthRequestBody {
    return AIStaffCreateSearchGroundTruthRequestBodyFromJSONTyped(json, false);
}

export function AIStaffCreateSearchGroundTruthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateSearchGroundTruthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': json['answer'],
        'cutoff': (new Date(json['cutoff'])),
        'question': json['question'],
        'question_type': json['question_type'],
        'verified_at': !exists(json, 'verified_at') ? undefined : (new Date(json['verified_at'])),
    };
}

export function AIStaffCreateSearchGroundTruthRequestBodyToJSON(value?: AIStaffCreateSearchGroundTruthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': value.answer,
        'cutoff': (value.cutoff.toISOString()),
        'question': value.question,
        'question_type': value.question_type,
        'verified_at': value.verified_at === undefined ? undefined : (value.verified_at.toISOString()),
    };
}

