import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { useParams } from "react-router-dom";
import {
  CatalogShowTypeResponseBody,
  IntegrationSettingsProviderEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { CatalogTypeCreateEditFormWrapper } from "../catalog-type-form/CatalogTypeCreateEditFormWrapper";
import { CatalogTypeExternallyManagedBanner } from "./CatalogTypeExternallyManagedBanner";

export const CatalogTypeEditPage = () => {
  const { id } = useParams() as { id: string };

  const {
    data: typeData,
    isLoading: typeLoading,
    error: typeError,
  } = useAPI("catalogShowType", {
    id: id,
    includeAttributeDependentResources: true,
  });

  // Catalog resources are used to type our attributes. To avoid a flash of the form,
  // we load these here before mounting the page.
  const { isLoading: resourcesLoading, error: resourcesError } = useAPI(
    "catalogListResources",
    undefined,
  );

  if (!typeData || typeLoading || resourcesLoading) {
    return <Loader />;
  }

  if (resourcesError || typeError) {
    return <GenericErrorMessage error={resourcesError || typeError} />;
  }

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      icon={typeData.catalog_type.icon}
      color={typeData.catalog_type.color as unknown as ColorPaletteEnum}
      title={`Edit ${typeData.catalog_type.name}`}
      backHref={`/catalog/${slugForCatalogType(typeData.catalog_type)}`}
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
      banner={
        typeData?.catalog_type?.source_repo_url && (
          <CatalogTypeExternallyManagedBanner
            catalogType={typeData.catalog_type}
            sourceRepoURL={typeData.catalog_type.source_repo_url}
          />
        )
      }
    >
      <CatalogTypeEditPageInner catalogTypeResponse={typeData} />
    </PageWrapper>
  );
};

// We need to only call useForm once we've loaded the catalog type, so that we can set
// the default values correctly.
const CatalogTypeEditPageInner = ({
  catalogTypeResponse,
}: {
  catalogTypeResponse: CatalogShowTypeResponseBody;
}) => {
  const catalogType = catalogTypeResponse.catalog_type;
  const requiredIntegration = IntegrationConfigFor(
    catalogType
      .required_integrations?.[0] as unknown as IntegrationSettingsProviderEnum,
  );

  return (
    <CatalogTypeCreateEditFormWrapper
      mode={Mode.Edit}
      initialData={catalogType}
      requiredIntegration={requiredIntegration}
      supportsDynamicAttributes={
        catalogTypeResponse.supports_dynamic_attributes
      }
      dependentResources={catalogTypeResponse.dependent_resources}
      catalogTypeMode={catalogType.mode}
      isManagedExternally={!!catalogType.source_repo_url}
      attributeDependencies={catalogTypeResponse.attribute_dependent_resources}
    />
  );
};
