/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationPathTargetV2
 */
export interface EscalationPathTargetV2 {
    /**
     * Uniquely identifies an entity of this type
     * @type {string}
     * @memberof EscalationPathTargetV2
     */
    id: string;
    /**
     * Only set for schedule targets, and either currently_on_call, all_users or all_users_for_rota and specifies which users to fetch from the schedule
     * @type {string}
     * @memberof EscalationPathTargetV2
     */
    schedule_mode?: EscalationPathTargetV2ScheduleModeEnum;
    /**
     * Controls what type of entity this target identifies, such as Schedule or User
     * @type {string}
     * @memberof EscalationPathTargetV2
     */
    type: EscalationPathTargetV2TypeEnum;
    /**
     * The urgency of this escalation path target
     * @type {string}
     * @memberof EscalationPathTargetV2
     */
    urgency: EscalationPathTargetV2UrgencyEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathTargetV2ScheduleModeEnum {
    CurrentlyOnCall = 'currently_on_call',
    AllUsersForRota = 'all_users_for_rota',
    AllUsers = 'all_users',
    Empty = ''
}/**
* @export
* @enum {string}
*/
export enum EscalationPathTargetV2TypeEnum {
    Schedule = 'schedule',
    User = 'user',
    SlackChannel = 'slack_channel'
}/**
* @export
* @enum {string}
*/
export enum EscalationPathTargetV2UrgencyEnum {
    High = 'high',
    Low = 'low'
}

export function EscalationPathTargetV2FromJSON(json: any): EscalationPathTargetV2 {
    return EscalationPathTargetV2FromJSONTyped(json, false);
}

export function EscalationPathTargetV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathTargetV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'schedule_mode': !exists(json, 'schedule_mode') ? undefined : json['schedule_mode'],
        'type': json['type'],
        'urgency': json['urgency'],
    };
}

export function EscalationPathTargetV2ToJSON(value?: EscalationPathTargetV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schedule_mode': value.schedule_mode,
        'type': value.type,
        'urgency': value.urgency,
    };
}

