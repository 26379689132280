/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface CatalogUpdateEntryRequestBody
 */
export interface CatalogUpdateEntryRequestBody {
    /**
     * Optional aliases that can be used to reference this entry
     * @type {Array<string>}
     * @memberof CatalogUpdateEntryRequestBody
     */
    aliases?: Array<string>;
    /**
     * Values of this entry
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof CatalogUpdateEntryRequestBody
     */
    attribute_values: { [key: string]: EngineParamBindingPayload; };
    /**
     * An optional alternative ID for this entry, which is ensured to be unique for the type
     * @type {string}
     * @memberof CatalogUpdateEntryRequestBody
     */
    external_id?: string;
    /**
     * Name is the human readable name of this entry
     * @type {string}
     * @memberof CatalogUpdateEntryRequestBody
     */
    name: string;
    /**
     * When catalog type is ranked, this is used to help order things
     * @type {number}
     * @memberof CatalogUpdateEntryRequestBody
     */
    rank?: number;
    /**
     * If provided, only update these attribute_values keys. If not provided, update all attribute values.
     * If you specify an attribute key that's not in your payload, the associated attribute value will be cleared.
     * @type {Array<string>}
     * @memberof CatalogUpdateEntryRequestBody
     */
    update_attributes?: Array<string>;
}

export function CatalogUpdateEntryRequestBodyFromJSON(json: any): CatalogUpdateEntryRequestBody {
    return CatalogUpdateEntryRequestBodyFromJSONTyped(json, false);
}

export function CatalogUpdateEntryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogUpdateEntryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'attribute_values': (mapValues(json['attribute_values'], EngineParamBindingPayloadFromJSON)),
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'name': json['name'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'update_attributes': !exists(json, 'update_attributes') ? undefined : json['update_attributes'],
    };
}

export function CatalogUpdateEntryRequestBodyToJSON(value?: CatalogUpdateEntryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': value.aliases,
        'attribute_values': (mapValues(value.attribute_values, EngineParamBindingPayloadToJSON)),
        'external_id': value.external_id,
        'name': value.name,
        'rank': value.rank,
        'update_attributes': value.update_attributes,
    };
}

