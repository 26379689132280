/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSourceSlim,
    AlertRouteAlertSourceSlimFromJSON,
    AlertRouteAlertSourceSlimFromJSONTyped,
    AlertRouteAlertSourceSlimToJSON,
} from './AlertRouteAlertSourceSlim';

/**
 * 
 * @export
 * @interface AlertRouteSlim
 */
export interface AlertRouteSlim {
    /**
     * Which alert sources should this alert route match?
     * @type {Array<AlertRouteAlertSourceSlim>}
     * @memberof AlertRouteSlim
     */
    alert_sources: Array<AlertRouteAlertSourceSlim>;
    /**
     * When did this alert route last create an escalation?
     * @type {Date}
     * @memberof AlertRouteSlim
     */
    approx_last_escalation_created_at?: Date;
    /**
     * When did this alert route last create an incident?
     * @type {Date}
     * @memberof AlertRouteSlim
     */
    approx_last_incident_created_at?: Date;
    /**
     * Whether channel config is enabled for this alert route
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    channel_enabled: boolean;
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    enabled: boolean;
    /**
     * Whether escalation is enabled for this alert route
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    escalation_enabled: boolean;
    /**
     * Unique identifier for this alert route config
     * @type {string}
     * @memberof AlertRouteSlim
     */
    id: string;
    /**
     * Whether incident creation is enabled for this alert route
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    incident_enabled: boolean;
    /**
     * Whether this alert route is private. Private alert routes will only create private incidents from alerts.
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    is_private?: boolean;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRouteSlim
     */
    name: string;
    /**
     * The version of this alert route config
     * @type {number}
     * @memberof AlertRouteSlim
     */
    version: number;
}

export function AlertRouteSlimFromJSON(json: any): AlertRouteSlim {
    return AlertRouteSlimFromJSONTyped(json, false);
}

export function AlertRouteSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourceSlimFromJSON)),
        'approx_last_escalation_created_at': !exists(json, 'approx_last_escalation_created_at') ? undefined : (new Date(json['approx_last_escalation_created_at'])),
        'approx_last_incident_created_at': !exists(json, 'approx_last_incident_created_at') ? undefined : (new Date(json['approx_last_incident_created_at'])),
        'channel_enabled': json['channel_enabled'],
        'enabled': json['enabled'],
        'escalation_enabled': json['escalation_enabled'],
        'id': json['id'],
        'incident_enabled': json['incident_enabled'],
        'is_private': !exists(json, 'is_private') ? undefined : json['is_private'],
        'name': json['name'],
        'version': json['version'],
    };
}

export function AlertRouteSlimToJSON(value?: AlertRouteSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourceSlimToJSON)),
        'approx_last_escalation_created_at': value.approx_last_escalation_created_at === undefined ? undefined : (value.approx_last_escalation_created_at.toISOString()),
        'approx_last_incident_created_at': value.approx_last_incident_created_at === undefined ? undefined : (value.approx_last_incident_created_at.toISOString()),
        'channel_enabled': value.channel_enabled,
        'enabled': value.enabled,
        'escalation_enabled': value.escalation_enabled,
        'id': value.id,
        'incident_enabled': value.incident_enabled,
        'is_private': value.is_private,
        'name': value.name,
        'version': value.version,
    };
}

