/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogType,
    CatalogTypeFromJSON,
    CatalogTypeFromJSONTyped,
    CatalogTypeToJSON,
} from './CatalogType';

/**
 * 
 * @export
 * @interface TeamSettings
 */
export interface TeamSettings {
    /**
     * ID of the existing alert attribute that will be used to filter alerts by team
     * @type {string}
     * @memberof TeamSettings
     */
    alert_attribute_id?: string;
    /**
     * 
     * @type {CatalogType}
     * @memberof TeamSettings
     */
    catalog_type?: CatalogType;
    /**
     * ID of the existing custom field that will be used to filter incidents by team
     * @type {string}
     * @memberof TeamSettings
     */
    custom_field_id?: string;
    /**
     * What attribute is used to store escalation paths?
     * @type {string}
     * @memberof TeamSettings
     */
    escalation_paths_attribute_id?: string;
    /**
     * What attribute is used to store team members?
     * @type {string}
     * @memberof TeamSettings
     */
    members_attribute_id?: string;
    /**
     * What attribute is the members attribute derived from?
     * @type {string}
     * @memberof TeamSettings
     */
    members_derived_from_attribute_id?: string;
}

export function TeamSettingsFromJSON(json: any): TeamSettings {
    return TeamSettingsFromJSONTyped(json, false);
}

export function TeamSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_attribute_id': !exists(json, 'alert_attribute_id') ? undefined : json['alert_attribute_id'],
        'catalog_type': !exists(json, 'catalog_type') ? undefined : CatalogTypeFromJSON(json['catalog_type']),
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'escalation_paths_attribute_id': !exists(json, 'escalation_paths_attribute_id') ? undefined : json['escalation_paths_attribute_id'],
        'members_attribute_id': !exists(json, 'members_attribute_id') ? undefined : json['members_attribute_id'],
        'members_derived_from_attribute_id': !exists(json, 'members_derived_from_attribute_id') ? undefined : json['members_derived_from_attribute_id'],
    };
}

export function TeamSettingsToJSON(value?: TeamSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_attribute_id': value.alert_attribute_id,
        'catalog_type': CatalogTypeToJSON(value.catalog_type),
        'custom_field_id': value.custom_field_id,
        'escalation_paths_attribute_id': value.escalation_paths_attribute_id,
        'members_attribute_id': value.members_attribute_id,
        'members_derived_from_attribute_id': value.members_derived_from_attribute_id,
    };
}

