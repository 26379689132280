import { AutoArchiveCancelledMergedDeclinedIncidentsSettings } from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Icon, IconEnum, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Identity, ScopeNameEnum, Settings } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useSettings } from "src/hooks/useSettings";
import { ScopesNeededReinstallMessage } from "src/routes/legacy/ReinstallSlackRoute";
import { useAPIMutation } from "src/utils/swr";

import { AddToSlackButton } from "../../slack/AddToSlackButton";
import { SettingsSection } from "../SettingsSection";

type FormType = {
  enabled: boolean;
  config?: {
    override_delay_minutes: string;
  };
};

// Makes the conversion from the API settings into our form type
const toFormType = (
  body: AutoArchiveCancelledMergedDeclinedIncidentsSettings,
): FormType => ({
  enabled: body.enabled,
  config: {
    override_delay_minutes:
      body.config?.override_delay_minutes.toString() as string,
  },
});

export const AutomationOverrideAutoArchiveDeclinedIncidentsEditForm =
  (): React.ReactElement | null => {
    const { settings } = useSettings();
    const { identity } = useIdentity();

    if (!settings || !identity) {
      return null;
    }

    return (
      <AutomationOverrideAutoArchiveDeclinedIncidentsEditFormInner
        settings={settings}
        identity={identity}
      />
    );
  };

const AutomationOverrideAutoArchiveDeclinedIncidentsEditFormInner = ({
  settings,
  identity,
}: {
  identity: Identity;
  settings: Settings;
}) => {
  const showToast = useToast();

  const { hasScope } = useIdentity();
  const commsPlatform = usePrimaryCommsPlatform();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const formMethods = useForm<FormType>({
    defaultValues: toFormType(
      settings.misc.auto_archive_cancelled_merged_declined_incidents,
    ),
  });

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) => {
      const dataToSubmit: AutoArchiveCancelledMergedDeclinedIncidentsSettings =
        {
          enabled: data.enabled,
        };
      if (data.config) {
        dataToSubmit.config = {
          override_delay_minutes: parseInt(data.config.override_delay_minutes),
        };
      }

      // Get fresh settings after update
      const { settings } =
        await apiClient.settingsUpdateAutoArchiveCancelledMergedDeclinedIncidents(
          {
            updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody: {
              auto_archive_cancelled_merged_declined_incidents: dataToSubmit,
            },
          },
        );
      formMethods.reset(
        toFormType(
          settings.misc.auto_archive_cancelled_merged_declined_incidents,
        ),
      );
    },
    {
      onSuccess: () => showToast(SharedToasts.SETTINGS_SAVED),
      setError: formMethods.setError,
    },
  );

  const slackInfo = identity.slack_info;
  const missingRequiredScopes =
    slackInfo?.missing_token_scopes.filter(
      ({ scope }) => scope === "channels:join",
    ) ?? [];

  return (
    <SettingsSection
      mutation={mutation}
      formMethods={formMethods}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      enabledPath="enabled"
      title={
        commsPlatform === CommsPlatform.Slack
          ? "Override auto-archive delay for cancelled, merged, and declined incidents"
          : "Override auto-delete delay for cancelled, merged, and declined incidents"
      }
      explanation={
        commsPlatform === CommsPlatform.Slack ? (
          <AutoArchiveExplanation />
        ) : (
          <AutoDeleteExplanation />
        )
      }
    >
      {slackInfo && missingRequiredScopes.length > 0 ? (
        <div className="rounded-[6px] bg-blue-surface text-content-primary p-4 text-sm flex">
          <Icon id={IconEnum.Warning} className="mr-2 flex-shrink-0" />
          <div>
            <ScopesNeededReinstallMessage
              feature={"Auto-archiving"}
              missingTokenScopes={missingRequiredScopes}
            />
            <AddToSlackButton
              url={slackInfo.install_url}
              returnPath={"settings/security"}
            />
          </div>
        </div>
      ) : (
        <StaticSingleSelectV2<FormType>
          formMethods={formMethods}
          className="mt-4"
          label={
            commsPlatform === CommsPlatform.Slack
              ? "Auto-archive cancelled or declined incident channels after:"
              : "Auto-delete cancelled or declined incident channels after:"
          }
          required
          name={"config.override_delay_minutes"}
          options={AUTO_ARCHIVE_OPTIONS}
          placeholder="Select delay..."
          isClearable={false}
          disabled={!canEditSettings}
        />
      )}
    </SettingsSection>
  );
};

const AUTO_ARCHIVE_OPTIONS = [
  {
    label: "20 minutes",
    sort_key: "2",
    value: "20",
  },
  {
    label: "1 hour",
    sort_key: "3",
    value: "60",
  },
  {
    label: "1 day",
    sort_key: "4",
    value: "1440",
  },
  {
    label: "2 days",
    sort_key: "5",
    value: "2880",
  },
  {
    label: "7 days",
    sort_key: "5",
    value: "10080",
  },
  {
    label: "28 days",
    sort_key: "5",
    value: "40320",
  },
];

const AutoArchiveExplanation = () => {
  return (
    <>
      Without this, we&apos;ll default to archiving cancelled, declined, and
      merged incident channels after 5 minutes. This setting can be used to
      override that time period.
    </>
  );
};

const AutoDeleteExplanation = () => {
  return (
    <>
      Without this, we&apos;ll default to deleting cancelled, declined, and
      merged incident channels after 5 minutes. This setting can be used to
      override that time period. Microsoft users may have the permission to
      restore channels within Microsoft Teams before they are permanently
      deleted.
    </>
  );
};
