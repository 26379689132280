import { IntegrationSettingsProviderEnum as IntegrationProvider } from "@incident-io/api";
import {
  PageWidth,
  PageWrapper,
} from "@incident-shared/layout/PageWrapper/PageWrapper";
import {
  SecondaryNavHorizontal,
  SecondaryNavHorizontalItemType,
} from "@incident-shared/layout/SecondaryNavHorizontal";
import { SecondaryPageWrapper } from "@incident-shared/layout/SecondaryPageWrapper";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";

import { useIntegrations } from "../../../hooks/useIntegrations";
import { EscalationPathCreateImportButton } from "../../escalation-paths/EscalationPathCreateImportButton";
import { EscalationDrawer } from "../../escalations/EscalateDrawer";
import { IncidentCreateModal } from "../incident/IncidentCreateModal";
import { EscalateButton } from "./EscalateButton";
import {
  SchedulesListButtons,
  useSchedulesListPageHeaderProps,
} from "./schedules/SchedulesListPage";
import { useOnCallEntityCount } from "./utils";

export const OnCallPageWrapper = ({
  showGetStarted,
}: {
  showGetStarted: boolean;
}) => {
  const onCallPages: SecondaryNavHorizontalItemType[] = [
    {
      label: "Escalations",
      slug: "escalations",
    },
    {
      label: "Escalation paths",
      slug: "escalation-paths",
    },
    {
      label: "Schedules",
      slug: "schedules",
    },
    {
      label: "Call routes",
      slug: "call-routes",
    },
  ];

  const { featureDisablePayOnCallPayCalculatorTab } = useFlags();

  const [showEscalateModal, setShowEscalateModal] = useState(false);
  const [showDeclareIncidentModal, setShowDeclareIncidentModal] =
    useState(false);
  const [escalationIDForIncidentDeclare, setEscalationIDForIncidentDeclare] =
    useState<string | undefined>(undefined);

  const getStartedPages: SecondaryNavHorizontalItemType[] = [
    {
      label: "Get started",
      slug: "get-started/main",
    },
  ];

  const { identity } = useIdentity();
  const { hasOnCall } = useProductAccess();

  // We need to fetch our on call entity counts because we only show the on-call pay tab
  // if the user has created at least one escalation path or schedule.
  const {
    data: onCallEntityCount,
    isLoading: onCallEntityLoading,
    error: onCallEntityError,
  } = useOnCallEntityCount();

  if (!identity || onCallEntityLoading) {
    return <Loader />;
  }

  if (onCallEntityError) {
    return <GenericErrorMessage error={onCallEntityError} />;
  }

  // If you've got the on-call calculator flag but you're limited to no
  // responders (i.e. you don't have access to On-call), then you're someone
  // who already used the calculator, and we want to let you carry on.
  const isExistingPayCalculatorUser =
    identity.feature_gates.on_call_calculator &&
    identity.feature_gates.on_call_responders_count === 0;

  // Otherwise, if you have access to On-call and you've created entities,
  // then we want to show you the tab. We don't check the gate here, but that
  // is OK: the tab content itself has a gated button and so people without
  // the gate see a nice upsell.
  const hasOnCallAccessAndHasEntities = hasOnCall && onCallEntityCount >= 1;

  // Either of those groups above get to see the calculator.
  const showPayCalculator =
    (isExistingPayCalculatorUser || hasOnCallAccessAndHasEntities) &&
    !featureDisablePayOnCallPayCalculatorTab;

  if (showPayCalculator) {
    onCallPages.push({
      label: "Pay calculator",
      slug: "pay-calculator",
    });
    getStartedPages.push({
      label: "Pay calculator",
      slug: "pay-calculator",
    });
  }

  // We'll show the get started items if it's explicitly specified, _or_ if
  // there are no on-call entities.
  const items =
    showGetStarted || !hasOnCallAccessAndHasEntities
      ? getStartedPages
      : onCallPages;

  return (
    <PageWrapper
      title="On-call"
      icon={IconEnum.OnCall}
      width={PageWidth.Full}
      accessory={
        hasOnCallAccessAndHasEntities ? (
          <OnCallPageAccessory setShowEscalateModal={setShowEscalateModal} />
        ) : null
      }
      headerClassName={"border-0"}
      overflowY={false}
      noPadding
    >
      <div className="flex flex-col h-full">
        {items.length > 1 && (
          <div className={"bg-white sticky top-0 z-20"}>
            <SecondaryNavHorizontal
              items={items}
              pathPrefix={"on-call"}
              className={"px-8"}
            />
          </div>
        )}
        <SecondaryPageWrapper className="h-full overflow-y-auto" withPadding>
          <Outlet />
        </SecondaryPageWrapper>
      </div>
      {showEscalateModal ? (
        <EscalationDrawer
          onClose={() => setShowEscalateModal(false)}
          onDeclareIncident={(escalationID) => {
            setShowDeclareIncidentModal(true);
            setEscalationIDForIncidentDeclare(escalationID);
          }}
          shouldWarnWhenDirty={true}
        />
      ) : null}
      {showDeclareIncidentModal && (
        <IncidentCreateModal
          escalationId={escalationIDForIncidentDeclare}
          onClose={() => {
            setShowDeclareIncidentModal(false);
            setEscalationIDForIncidentDeclare(undefined);
          }}
        />
      )}
    </PageWrapper>
  );
};

const OnCallPageAccessory = ({
  setShowEscalateModal,
}: {
  setShowEscalateModal: (show: boolean) => void;
}) => {
  const { pathname } = useLocation();
  const { integrations } = useIntegrations();

  const installedPD =
    integrations?.find(
      (x) => x.provider === IntegrationProvider.Pagerduty && x.installed,
    )?.installed || false;

  const {
    schedulesIsLoading,
    schedulesError,
    scheduleCreateUpgradeRequired,
    createScheduleGate,
    schedulesGateFeatureName,
  } = useSchedulesListPageHeaderProps();

  if (pathname.includes("escalation-paths")) {
    return <EscalationPathCreateImportButton allowImport={installedPD} />;
  }

  if (pathname.includes("schedules")) {
    if (schedulesIsLoading) {
      return <Loader />;
    }

    if (schedulesError) {
      // If it errors just don't show the buttons instead of failing to show the page
      return null;
    }

    return (
      <SchedulesListButtons
        scheduleCreateUpgradeRequired={scheduleCreateUpgradeRequired}
        createScheduleGate={createScheduleGate}
        schedulesGateFeatureName={schedulesGateFeatureName}
      />
    );
  }

  return (
    <div className="flex-center-y gap-2 flex-wrap">
      <EscalateButton onClick={() => setShowEscalateModal(true)} />
    </div>
  );
};
