import {
  EmbeddedCatalogEntry,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  SchedulesCreateExternalRequestBody,
  User,
  UserOptionStateEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Input,
  Link,
  Loader,
  TabSection,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { SelectableTable } from "@incident-ui/Table/SelectableTable";
import { TableCell, TableHeaderCell, TableRow } from "@incident-ui/Table/Table";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import pluralize from "pluralize";
import { ChangeEvent, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";
import { useRevalidate } from "src/utils/use-revalidate";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";
import { useDebounce } from "use-debounce";

import { OnCallPromotionConfirmationModal } from "../common/OnCallPromotionConfirmationModal";
import { useOnCallPromotionState } from "../common/useOnCallPromotionState";
type Tab = "ready" | "incompatible" | "imported";

const capPerImport = 50;

export const ScheduleImportDrawer = ({ onClose }: { onClose: () => void }) => {
  const navigate = useOrgAwareNavigate();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedSchedules, setSelectedSchedules] = useState<Set<string>>(
    new Set(),
  );
  const [currentTab, setCurrentTab] = useState<Tab>("ready");
  const showToast = useToast();

  const {
    responses,
    isLoading,
    isFullyLoaded,
    loadMore: onLoadMore,
    error,
  } = useAPIInfinite(
    "schedulesListExternal",
    {
      includeNativeConfigPayload: true,
      pageSize: 15,
      search: debouncedSearch,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    rootMargin: "0px 0px 100px 0px",
  });

  const { integrations, integrationsError, integrationsLoading } =
    useIntegrations();

  const installedEscalator = integrations?.find(
    (x) =>
      (x.provider === IntegrationProvider.Opsgenie ||
        x.provider === IntegrationProvider.Pagerduty) &&
      x.installed,
  );

  const schedules = responses.flatMap((r) => r.external_schedules);

  const schedulesForTab: { [key in Tab]: ExternalSchedule[] } = {
    ready: schedules.filter(
      (s) =>
        !s.native_config_unavailable_reason && !s.created_native_schedule_id,
    ),
    incompatible: schedules.filter(
      (s) =>
        s.native_config_unavailable_reason && !s.created_native_schedule_id,
    ),
    imported: schedules.filter((s) => s.created_native_schedule_id),
  };

  const alreadyImportedSchedules = schedules
    .filter((s) => s.created_native_schedule_id)
    .map((s) => s.id);

  const schedulesToImport = useMemo(
    () => schedules.filter((s) => selectedSchedules.has(s.id)),
    [schedules, selectedSchedules],
  );

  const usersToUpgrade = schedulesToImport.reduce<Record<string, User>>(
    (acc, schedule) => {
      (schedule.users_needing_upgrades ?? []).forEach((user) => {
        acc[user.id] = user;
      });
      return acc;
    },
    {},
  );

  const { promotionState, setPromotionState, handlePromotion } =
    useOnCallPromotionState<string[]>({
      users: Object.keys(usersToUpgrade),
      userCache: {
        getHydratedUser: (id) => {
          return {
            ...usersToUpgrade[id],
            state: UserOptionStateEnum.Viewer, // The backend's told us these users need upgrades, so we set this.
            label: usersToUpgrade[id].name,
          };
        },
      },
    });

  const revalidateSchedules = useRevalidate([
    "schedulesList",
    "schedulesListExternal",
    "schedulesShow",
    "usersTypeahead",
  ]);

  const { trigger: createSchedules, isMutating: isSaving } = useAPIMutation(
    "schedulesListExternal",
    {},
    async (apiClient, data: SchedulesCreateExternalRequestBody) => {
      const resp = await apiClient.schedulesCreateExternal({
        createExternalRequestBody: data,
      });

      showToast({
        title: `${selectionSummaryText(selectedSchedules.size)} created`,
        theme: ToastTheme.Success,
      });
      revalidateSchedules();

      if (resp.schedules.length === 1) {
        navigate(`/on-call/schedules/${resp.schedules[0].id}`);
      } else {
        onClose();
      }
    },
    {
      showErrorToast: "Failed to import schedules",
    },
  );

  const createSchedulesOrPromoteUsers = async () => {
    const scheduleIds = schedulesToImport.map((s) => s.id);

    const { shouldPromote, userIdsToPromote } =
      await handlePromotion(scheduleIds);
    if (shouldPromote) {
      await createSchedules({
        schedule_ids: scheduleIds,
        user_ids_to_promote: userIdsToPromote,
      });
    }
  };

  if (error || integrationsError) {
    return <GenericErrorMessage error={error || integrationsError} />;
  }

  return (
    <Drawer onClose={onClose} width="large">
      <DrawerContents>
        <DrawerTitle
          title="Import schedules"
          icon={IconEnum.Download}
          onClose={onClose}
          color={ColorPaletteEnum.Slate200}
          hexColor={getColor("slate", "50")}
          subtitle="Select which schedules you want to import."
          className={"sticky top-0 z-[50]"}
        />
        {integrationsLoading ? (
          <Loader />
        ) : !installedEscalator ? (
          <InstallEscalatorCTA />
        ) : (
          <>
            <OnCallPromotionConfirmationModal
              noun="schedules"
              state={promotionState}
              onClose={() => setPromotionState(null)}
              onSubmit={(schedules, userIdsToPromote) =>
                createSchedules({
                  schedule_ids: schedules,
                  user_ids_to_promote: userIdsToPromote,
                })
              }
            />
            <DrawerBody className="flex flex-col p-6 gap-4">
              <Input
                id="search"
                placeholder="Search for a schedule"
                type={InputType.Search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value ?? "")
                }
                value={search}
                iconName={IconEnum.Search}
                iconProps={{
                  id: IconEnum.Search,
                  className: "text-content-tertiary",
                }}
                className="rounded-lg justify-start items-center gap-2 inline-flex bg-surface-secondary"
              />
              <TabSection
                tabs={[
                  {
                    label: "Ready to import",
                    id: "ready",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${schedulesForTab["ready"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Incompatible",
                    id: "incompatible",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${schedulesForTab["incompatible"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Already imported",
                    id: "imported",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${schedulesForTab["imported"].length}`}
                      </Badge>
                    ),
                  },
                ]}
                value={currentTab}
                onTabChange={(newTab) => {
                  setCurrentTab(newTab as Tab);
                }}
                withIndicator
                tabBarClassName="border-b border-stroke mb-4"
              >
                {schedulesForTab[currentTab].length > 0 ? (
                  <SelectableTable<ExternalSchedule>
                    gridTemplateColumns="1fr minmax(0, 1fr) auto"
                    header={
                      <>
                        <TableHeaderCell title="Schedule" />
                        <TableHeaderCell title="Provider" />
                        <TableHeaderCell className={"justify-end"} />
                      </>
                    }
                    wrappedInBox
                    data={schedulesForTab[currentTab]}
                    isRowDisabled={(schedule) => {
                      // If we've hit the cap, only allow _deselecting_ schedules
                      if (selectedSchedules.size >= capPerImport) {
                        return !selectedSchedules.has(schedule.id);
                      }

                      return (
                        !!schedule.native_config_unavailable_reason ||
                        !!schedule.created_native_schedule_id
                      );
                    }}
                    renderRow={(schedule, index, checkbox) => {
                      if (!schedule) {
                        return null;
                      }
                      const isDisabled =
                        !!schedule.native_config_unavailable_reason ||
                        !!schedule.created_native_schedule_id;

                      return (
                        <TableRow
                          key={index}
                          isLastRow={
                            index === schedulesForTab[currentTab].length - 1
                          }
                        >
                          {checkbox}
                          <TableCell
                            key={`${index}-schedule`}
                            className={tcx("flex items-center gap-2", {
                              "opacity-40": isDisabled,
                            })}
                          >
                            <Badge
                              icon={IconEnum.Calendar}
                              theme={BadgeTheme.Tertiary}
                              size={BadgeSize.Medium}
                              className={
                                "flex flex-row gap-1 items-center text-ellipsis overflow-hidden max-w-[40ch]"
                              }
                            >
                              <span className={"overflow-hidden text-ellipsis"}>
                                {schedule.name}
                              </span>
                            </Badge>
                          </TableCell>
                          <TableCell
                            key={`${index}-provider`}
                            className={tcx("flex items-center", {
                              "opacity-40": isDisabled,
                            })}
                          >
                            <Badge
                              icon={
                                schedule.external_provider ===
                                ExternalScheduleExternalProviderEnum.Pagerduty
                                  ? IconEnum.Pagerduty
                                  : IconEnum.Opsgenie
                              }
                              theme={BadgeTheme.Unstyled}
                              size={BadgeSize.Medium}
                              className={
                                "flex flex-row gap-1 bg-[#E6F6EB] items-center text-ellipsis overflow-hidden"
                              }
                            >
                              {schedule.external_provider ===
                              ExternalScheduleExternalProviderEnum.Pagerduty
                                ? "PagerDuty"
                                : "Opsgenie"}
                            </Badge>
                          </TableCell>
                          <TableCell className={"inline-flex justify-end"}>
                            <TooltipAndBadge schedule={schedule} />
                          </TableCell>
                        </TableRow>
                      );
                    }}
                    selected={Array.from([
                      ...selectedSchedules,
                      ...alreadyImportedSchedules,
                    ])}
                    onSelectChanged={(id, newValue) => {
                      setSelectedSchedules((prev) => {
                        if (newValue) {
                          const res = new Set([...prev, id]);
                          if (res.size === capPerImport) {
                            showToast({
                              title: `You can only import ${capPerImport} schedules at once`,
                              theme: ToastTheme.Info,
                            });
                          }
                          return res;
                        } else {
                          return new Set([...prev].filter((x) => x !== id));
                        }
                      });
                    }}
                    selectAll={
                      selectedSchedules.size > 0 &&
                      _.isEqual(
                        Array.from(selectedSchedules).sort(),
                        schedulesForTab[currentTab].map((s) => s.id).sort(),
                      )
                    }
                    onSelectAllChanged={(selected) => {
                      if (!selected) {
                        setSelectedSchedules(new Set());
                        return;
                      }

                      const selectable = schedulesForTab[currentTab]
                        .filter(
                          (s) =>
                            !s.native_config_unavailable_reason &&
                            s.created_native_schedule_id === undefined,
                        )
                        .map((s) => s.id);

                      if (selectable.length <= capPerImport) {
                        setSelectedSchedules(new Set(selectable));
                        return;
                      }

                      showToast({
                        title: `Only the first ${capPerImport} schedules have been selected`,
                        description:
                          "Once you've imported these schedules, come back here to import more.",
                        theme: ToastTheme.Info,
                      });
                      setSelectedSchedules(
                        new Set(_.take(selectable, capPerImport)),
                      );
                      return;
                    }}
                  />
                ) : isFullyLoaded ? (
                  <EmptyState
                    className="p-4 text-center"
                    content={
                      debouncedSearch === ""
                        ? emptyMessageForTab[currentTab]
                        : "No matching schedules"
                    }
                  />
                ) : null}
              </TabSection>
              {!isFullyLoaded && (
                <div className={"flex-center"} ref={infiniteScrollRef}>
                  <Loader />
                </div>
              )}
            </DrawerBody>
            <DrawerFooter
              className={
                "flex items-center justify-between gap-2 sticky bottom-0 z-[50] bg-white"
              }
            >
              {schedules.length > 0 ? (
                <span className="text-sm font-medium text-content-tertiary">
                  {`${selectionSummaryText(selectedSchedules.size)} selected`}
                </span>
              ) : (
                <div className={"grow"} />
              )}
              <div className={"flex gap-2"}>
                <Button
                  theme={ButtonTheme.Secondary}
                  onClick={onClose}
                  analyticsTrackingId="cancel-schedule-import"
                >
                  Back
                </Button>
                <Button
                  onClick={() => createSchedulesOrPromoteUsers()}
                  disabled={selectedSchedules.size === 0}
                  analyticsTrackingId="confirm-schedule-import"
                  theme={ButtonTheme.Primary}
                  loading={isSaving}
                >
                  Import
                </Button>
              </div>
            </DrawerFooter>
          </>
        )}
      </DrawerContents>
    </Drawer>
  );
};

const selectionSummaryText = (selectedSchedulesCount: number) => {
  if (selectedSchedulesCount === 0) {
    return "No schedules";
  }

  return `${selectedSchedulesCount} ${pluralize(
    "schedule",
    selectedSchedulesCount,
  )}`;
};

const emptyMessageForTab: { [key in Tab]: React.ReactNode } = {
  ready: "All your schedules have already been imported 🎉",
  incompatible: "All your schedules are compatible with incident.io",
  imported: "You haven't imported any schedules yet",
};

const InstallEscalatorCTA = () => {
  return (
    <div className={"p-6 space-y-4"}>
      <p>To import schedules you need to connect PagerDuty or Opsgenie.</p>
      <div className="flex flex-wrap gap-4">
        <Button
          analyticsTrackingId={`configure-pagerduty-for-import`}
          href="/settings/integrations/pagerduty"
          icon={IconEnum.Pagerduty}
        >
          Connect PagerDuty
        </Button>
        <Button
          analyticsTrackingId={`configure-opsgenie-for-import`}
          href="/settings/integrations/opsgenie"
          icon={IconEnum.Opsgenie}
        >
          Connect Opsgenie
        </Button>
      </div>
    </div>
  );
};

const TooltipAndBadge = ({
  schedule,
}: {
  schedule: ExternalSchedule;
}): React.ReactNode | null => {
  // If we've imported this, show the imported badge
  if (schedule.created_native_schedule_id) {
    return (
      <Button
        href={`/on-call/schedules/${schedule.created_native_schedule_id}`}
        theme={ButtonTheme.UnstyledPill}
        size={BadgeSize.Small}
        icon={IconEnum.ExternalLink}
        iconPosition="right"
        analyticsTrackingId="view-imported-schedule"
        className="bg-[#DBEAFE] text-[#3B82F6]"
      >
        Already imported
      </Button>
    );
  }

  // Do we have missing users?
  const unresolvableUsers = schedule.unresolvable_external_users ?? [];
  const missingUserCount = unresolvableUsers.length || 0;
  const hasMissingUsers = missingUserCount > 0;

  // Is there an unavailable reason?
  const unavailableInfo = schedule.native_config_unavailable_reason
    ? UnavailableInfo[schedule.native_config_unavailable_reason]
    : undefined;

  // If no issues, return null
  if (!unavailableInfo && !hasMissingUsers) {
    return null;
  }

  // Create badge label
  const hasMissingUsersLabel = `Missing ${
    missingUserCount === 0 ? "users" : pluralize("user", missingUserCount)
  }`;

  return (
    <Tooltip
      noMaxWidth
      bubbleProps={{
        className: "max-w-[400px]",
      }}
      content={
        hasMissingUsers && schedule.unresolvable_external_users ? (
          <UnresolvedUsersTooltipContent
            users={schedule.unresolvable_external_users}
            provider={schedule.external_provider}
            entity={"schedule"}
          />
        ) : (
          unavailableInfo?.tooltip ?? ""
        )
      }
    >
      {/* We need the div for the tooltip hover to work */}
      <div>
        <Badge
          theme={unavailableInfo ? BadgeTheme.Error : BadgeTheme.Warning}
          className="flex"
        >
          {hasMissingUsers ? hasMissingUsersLabel : unavailableInfo?.badge}
          <Icon
            size={IconSize.Small}
            className={tcx("ml-1")}
            id={IconEnum.Info}
          />
        </Badge>
      </div>
    </Tooltip>
  );
};

const UnavailableInfo: Record<
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  {
    tooltip: string;
    badge: string;
  }
> = {
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval]: {
    tooltip: `This schedule has a recurrence interval that isn't yet supported by incident.io.`,
    badge: `Unsupported interval`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions]: {
    tooltip: `This schedule has restrictions that aren't yet supported by incident.io.`,
    badge: `Unsupported restrictions`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture]: {
    tooltip: `This schedule has rotations due to end in the future, which isn't supported.`,
    badge: `Ends in future`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers]: {
    tooltip: `This schedule has no usable layers.`,
    badge: `No usable layers`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced]: {
    tooltip: `We've not yet been able to pull the rotation configuration for this schedule - please check back later.`,
    badge: `No configuration synced`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedParticipants]: {
    tooltip: `This schedule has participants that aren't yet supported by incident.io, such as a team or escalation.`,
    badge: `Unsupported participants`,
  },
};

export const UnresolvedUsersTooltipContent = ({
  users,
  provider,
  entity,
  usersBlockingImport: _usersBlockingImport,
}: {
  users: EmbeddedCatalogEntry[];
  provider: ExternalScheduleExternalProviderEnum;
  entity: "escalation policy" | "schedule";
  usersBlockingImport?: EmbeddedCatalogEntry[];
}) => {
  const providerLabel =
    provider === ExternalScheduleExternalProviderEnum.Pagerduty
      ? "PagerDuty"
      : "Opsgenie";

  const instructionsLink = (
    <Link
      openInNewTab
      href={
        "https://help.incident.io/articles/6563490557-adding-connected-users-to-catalog"
      }
      analyticsTrackingId={"connected-users-helpcenter"}
    >
      instructions
    </Link>
  );

  const usersBlockingImport = _usersBlockingImport ?? [];

  return (
    <div className="flex flex-col p-1 text-md">
      <div className="text-sm flex flex-col w-full gap-2 mb-2">
        <span>
          These users in {providerLabel} could not be linked to an incident.io
          account:
        </span>
        <UnresolvedUsersList users={users} provider={provider} />
        {usersBlockingImport.length === 0 ? (
          <span>
            You can still import this {entity}, but these users will be replaced
            by &apos;no one&apos; entries. Alternatively, you can manually link
            them using these {instructionsLink}.
          </span>
        ) : (
          <span>
            {joinSpansWithCommasAndConnectorWord(
              usersBlockingImport.map((u) => `'${u.name}'`),
            )}{" "}
            {usersBlockingImport.length === 1 ? "is" : "are"} referenced on the
            escalation policy directly, you will need to link them to an
            incident.io account before importing this escalation policy. You can
            do this using these {instructionsLink}.
          </span>
        )}
      </div>
    </div>
  );
};

export const UnresolvedUsersList = ({
  users,
  provider,
}: {
  users?: EmbeddedCatalogEntry[];
  provider: ExternalScheduleExternalProviderEnum;
}) => {
  const catalogTypeName =
    provider === ExternalScheduleExternalProviderEnum.Pagerduty
      ? "PagerDutyUser"
      : "OpsgenieUser";

  return (
    <ul className="my-1">
      {_.uniqBy(users ?? [], (u) => u.id).map((u, idx) => {
        return (
          <li key={idx} className="flex gap-1">
            &bull;
            <Button
              theme={ButtonTheme.Link}
              href={`/catalog/${catalogTypeName}/${u.id}`}
              openInNewTab
              analyticsTrackingId={"unresolved-external-users"}
            >
              {u.name}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
