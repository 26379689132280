/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathTargetV2,
    EscalationPathTargetV2FromJSON,
    EscalationPathTargetV2FromJSONTyped,
    EscalationPathTargetV2ToJSON,
} from './EscalationPathTargetV2';

/**
 * 
 * @export
 * @interface EscalationPathNodeNotifyChannelV2
 */
export interface EscalationPathNodeNotifyChannelV2 {
    /**
     * The targets (Slack channels) for this level
     * @type {Array<EscalationPathTargetV2>}
     * @memberof EscalationPathNodeNotifyChannelV2
     */
    targets: Array<EscalationPathTargetV2>;
    /**
     * If the time to ack is relative to a time window, this defines whether we move when the window is active or inactive
     * @type {string}
     * @memberof EscalationPathNodeNotifyChannelV2
     */
    time_to_ack_interval_condition?: EscalationPathNodeNotifyChannelV2TimeToAckIntervalConditionEnum;
    /**
     * How long should we wait for this level to acknowledge before moving on to the next node in the path?
     * @type {number}
     * @memberof EscalationPathNodeNotifyChannelV2
     */
    time_to_ack_seconds?: number;
    /**
     * If the time to ack is relative to a time window, this identifies which window it is relative to
     * @type {string}
     * @memberof EscalationPathNodeNotifyChannelV2
     */
    time_to_ack_weekday_interval_config_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathNodeNotifyChannelV2TimeToAckIntervalConditionEnum {
    Active = 'active',
    Inactive = 'inactive'
}

export function EscalationPathNodeNotifyChannelV2FromJSON(json: any): EscalationPathNodeNotifyChannelV2 {
    return EscalationPathNodeNotifyChannelV2FromJSONTyped(json, false);
}

export function EscalationPathNodeNotifyChannelV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeNotifyChannelV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targets': ((json['targets'] as Array<any>).map(EscalationPathTargetV2FromJSON)),
        'time_to_ack_interval_condition': !exists(json, 'time_to_ack_interval_condition') ? undefined : json['time_to_ack_interval_condition'],
        'time_to_ack_seconds': !exists(json, 'time_to_ack_seconds') ? undefined : json['time_to_ack_seconds'],
        'time_to_ack_weekday_interval_config_id': !exists(json, 'time_to_ack_weekday_interval_config_id') ? undefined : json['time_to_ack_weekday_interval_config_id'],
    };
}

export function EscalationPathNodeNotifyChannelV2ToJSON(value?: EscalationPathNodeNotifyChannelV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targets': ((value.targets as Array<any>).map(EscalationPathTargetV2ToJSON)),
        'time_to_ack_interval_condition': value.time_to_ack_interval_condition,
        'time_to_ack_seconds': value.time_to_ack_seconds,
        'time_to_ack_weekday_interval_config_id': value.time_to_ack_weekday_interval_config_id,
    };
}

