import { ContentBox, GenericErrorMessage, Txt } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import * as microsoftTeams from "@microsoft/teams-js";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { assertUnreachable } from "src/utils/utils";

enum ConfigurationState {
  Loading,
  Redirecting,
  Failure,
}

export const LoginMsTeamsTabRoute = (): React.ReactElement => {
  const navigate = useNavigate();

  const [configState, setConfigState] = useState<ConfigurationState>(
    ConfigurationState.Loading,
  );

  const getAuthTokenAndProcess = useCallback(async () => {
    try {
      const context = await microsoftTeams.app.getContext();
      const token = await microsoftTeams.authentication.getAuthToken();

      const params = new URLSearchParams({
        token,
        tenant_id: context.user?.tenant?.id ?? "",
        channel_id: context.channel?.id || "",
      });

      if (context.chat?.id) {
        params.set("chat_id", context.chat.id);
      }

      if (context.page.subPageId) {
        params.set("sub_entity_id", context.page.subPageId);
      }

      const location = "/auth/microsoft_tab_login?" + params.toString();
      const response = await fetch(location, {
        method: "GET",
        credentials: "same-origin",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.redirected || !response.url) {
        throw new Error("Expected redirect was not received");
      }

      const url = new URL(response.url);
      if (!url.pathname) {
        throw new Error("Could not parse the url for navigation");
      }

      // eslint-disable-next-line no-console
      console.log("Success!");
      await microsoftTeams.app.notifySuccess();
      navigate(url.pathname + url.search);
    } catch (error) {
      console.error("Failed to get token:", error);
    }
  }, [navigate]);

  useEffect(() => {
    const initializeTeams = async () => {
      try {
        // eslint-disable-next-line no-console
        console.log("Initializing Teams app");
        await microsoftTeams.app.initialize();

        // Registering a load handler (and the unload handler below) means our app will
        // be cached in the Teams desktop app. There are conflicting reports (and not
        // much information) on how this works, and whether it works in the "newer" Teams
        // desktop app (which is being rolled out, and will be mandatory by mid-2025), but
        // the documentation makes this look like the blessed path to initialize our app.
        // See: https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/app-caching
        microsoftTeams.teamsCore.registerOnLoadHandler(() => {
          // eslint-disable-next-line no-console
          console.log("Calling onLoad handler");
          getAuthTokenAndProcess();
        });

        // See above: we don't do anything here, but registering this handler apparently
        // makes app caching work.
        microsoftTeams.teamsCore.registerBeforeUnloadHandler(
          (readyToUnload) => {
            // eslint-disable-next-line no-console
            console.log("Calling beforeOnload handler");
            readyToUnload();
            return true;
          },
        );

        await microsoftTeams.app.notifySuccess();
      } catch (error) {
        console.error("Error initializing Teams app:", error);
        setConfigState(ConfigurationState.Failure);
      }
    };

    initializeTeams().then(async () => {
      await getAuthTokenAndProcess();
    });
  }, [getAuthTokenAndProcess]);

  switch (configState) {
    case ConfigurationState.Loading:
      return (
        <FullPageLoader
          subtitle={
            <Txt className="text-content-tertiary">Authenticating...</Txt>
          }
        />
      );
    case ConfigurationState.Redirecting:
      return <FullPageLoader subtitle={<Txt>Loading...</Txt>} />;
    case ConfigurationState.Failure:
      return (
        <ContentBox className="p-4">
          <GenericErrorMessage description="This page can only be loaded from within Microsoft Teams." />
        </ContentBox>
      );
    default:
      assertUnreachable(configState);
      return <></>;
  }
};
