import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  AccordionTriggerText,
} from "@incident-ui";

import { CodeViewer } from "../common/CodeViewer";

export const GroundTruthMetadata = ({ metadata }: { metadata?: string }) => {
  return (
    <>
      {metadata && (
        <AccordionProvider type="single" collapsible>
          <Accordion
            id="message-metadata"
            header={
              <div className="flex-center-y">
                <AccordionTriggerText text="View metadata" />
                <AccordionTriggerButton className="text-content-tertiary" />{" "}
              </div>
            }
          >
            <CodeViewer mode={"json"} content={metadata || "{}"} />
          </Accordion>
        </AccordionProvider>
      )}
    </>
  );
};
