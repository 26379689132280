import type { SVGProps } from "react";
import * as React from "react";
const SvgAssistantAiGradient = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#assistant-ai-gradient_svg__a)"
      fillRule="evenodd"
      d="M3.047 9.186a6.968 6.968 0 0 0 .882 4.288c.208.39-.083 1.752-.737 2.406a.656.656 0 0 0 .43 1.12c.068.003.14.004.21.004 1.002 0 2.218-.356 3.01-.762A6.994 6.994 0 0 0 10 17c3.86 0 7-3.14 7-7a7.008 7.008 0 0 0-7.749-6.96 2.25 2.25 0 0 1-.496 3.69h-.001l-1.327.663-.664 1.329a2.25 2.25 0 0 1-3.716.464Zm2.374-7.805.888 1.776.872.436.904.452a.749.749 0 0 1 0 1.342l-1.776.888-.888 1.776a.75.75 0 0 1-1.342 0l-.462-.924-.426-.852-1.776-.888a.749.749 0 0 1 0-1.342l1.776-.888.888-1.776c.254-.508 1.088-.508 1.342 0Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="assistant-ai-gradient_svg__a"
        x1={1}
        x2={20.301}
        y1={17.004}
        y2={8.429}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgAssistantAiGradient;
