/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AndroidAssetLinkTarget
 */
export interface AndroidAssetLinkTarget {
    /**
     * The namespace of the asset link
     * @type {string}
     * @memberof AndroidAssetLinkTarget
     */
    namespace: string;
    /**
     * The package name of the asset link
     * @type {string}
     * @memberof AndroidAssetLinkTarget
     */
    package_name: string;
    /**
     * The SHA256 fingerprints of the asset link
     * @type {Array<string>}
     * @memberof AndroidAssetLinkTarget
     */
    sha256_cert_fingerprints: Array<string>;
}

export function AndroidAssetLinkTargetFromJSON(json: any): AndroidAssetLinkTarget {
    return AndroidAssetLinkTargetFromJSONTyped(json, false);
}

export function AndroidAssetLinkTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): AndroidAssetLinkTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'namespace': json['namespace'],
        'package_name': json['package_name'],
        'sha256_cert_fingerprints': json['sha256_cert_fingerprints'],
    };
}

export function AndroidAssetLinkTargetToJSON(value?: AndroidAssetLinkTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'namespace': value.namespace,
        'package_name': value.package_name,
        'sha256_cert_fingerprints': value.sha256_cert_fingerprints,
    };
}

