import {
  EvaluationBacktestEntry,
  EvaluationScorecardDimensionTypeEnum,
  EvaluationScorecardEvent,
} from "@incident-io/api";
import { ConditionBadge } from "@incident-shared/engine/conditions";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  PopoverMultiSelect,
} from "@incident-ui";
import { isEmpty } from "lodash";
import {
  createContext,
  ForwardedRef,
  forwardRef,
  useContext,
  useState,
} from "react";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

export type GradeEventFilter = {
  dimensionId: string;
  dimensionName: string;
  dimensionType: EvaluationScorecardDimensionTypeEnum;
  filter_value: GradeEventFilterValue;
};

export type GradeEventFilters = {
  [gradeName: string]: GradeEventFilter[];
};

export type GradeEventFilterValue = {
  one_of?: string[];
  between?: { start: number; end: number };
};

export type ScorecardMetricIdentifier = {
  gradeName: string;
  metricName: string;
};

export type ScorecardDimensionIdentifier = {
  gradeName: string;
  dimensionId: string;
  dimensionName: string;
};

export type EvaluationFilterContextType = {
  gradeFilters: GradeEventFilters;
  setGradeFilters: (f: GradeEventFilters) => void;
  selectedFilterDimension?: ScorecardDimensionIdentifier;
  setSelectedFilterDimension: (
    d: ScorecardDimensionIdentifier | undefined,
  ) => void;
  selectedMetric?: ScorecardMetricIdentifier;
  setSelectedMetric: (m: ScorecardMetricIdentifier | undefined) => void;
  filteredTags: string[];
  setFilteredTags: (t: string[]) => void;
  availableTags: string[];
  isFiltered: boolean;
  enableSelectingMetrics: boolean;
};

export const EvaluationFilterContext =
  createContext<EvaluationFilterContextType | null>(null);

export const EvaluationFilterContextProvider = ({
  enableSelectingMetrics,
  availableTags = [],
  children,
}: {
  enableSelectingMetrics: boolean;
  availableTags?: string[];
  children: React.ReactElement;
}) => {
  // These filter the available data
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [gradeFilters, setGradeFilters] = useState<GradeEventFilters>({});

  const gradeFiltersIsEmpty = Object.values(gradeFilters).every(
    gradeEventFiltersAreEmpty,
  );

  const isFiltered = filteredTags.length > 0 || !gradeFiltersIsEmpty;

  // These highlight parts of the UI
  const [selectedFilterDimension, setSelectedFilterDimension] = useState<
    ScorecardDimensionIdentifier | undefined
  >();
  const [selectedMetric, setSelectedMetric] = useState<
    ScorecardMetricIdentifier | undefined
  >();

  return (
    <EvaluationFilterContext.Provider
      value={{
        gradeFilters,
        setGradeFilters,
        selectedFilterDimension,
        setSelectedFilterDimension,
        selectedMetric,
        availableTags,
        setSelectedMetric,
        filteredTags,
        setFilteredTags,
        isFiltered,
        enableSelectingMetrics,
      }}
    >
      {children}
    </EvaluationFilterContext.Provider>
  );
};

export const useEvaluationFilterContext = (): EvaluationFilterContextType => {
  const context = useContext(EvaluationFilterContext);
  if (context) {
    return context;
  }

  throw new Error(
    "useEvaluationFilterContext hook must be used in a child of EvaluationFilterContextProvider",
  );
};

/**
 * Checks if a GradeEventFilterValue is empty
 * A filter is considered empty if:
 * - it's undefined/null
 * - both one_of and between are undefined/empty
 * - one_of is an empty array
 * - between has undefined/null start/end values
 */
export const gradeEventFilterIsEmpty = (
  filter?: GradeEventFilterValue,
): boolean => {
  if (!filter) return true;

  const oneOfEmpty = !filter.one_of || isEmpty(filter.one_of);
  const betweenEmpty =
    !filter.between ||
    filter.between.start == null ||
    filter.between.end == null;

  return oneOfEmpty && betweenEmpty;
};

/**
 * Checks if all filters in a GradeEventFilter object are empty
 * Returns true if:
 * - the filter object is undefined/null
 * - the filter object is empty ({})
 * - all individual dimension filters are empty
 */
export const gradeEventFiltersAreEmpty = (
  filters?: GradeEventFilter[],
): boolean => {
  if (!filters) return true;
  if (isEmpty(filters)) return true;

  return filters.flatMap((f) => f.filter_value).every(gradeEventFilterIsEmpty);
};

export const filterGradeEvents = (
  events: EvaluationScorecardEvent[],
  filters: GradeEventFilter[],
): EvaluationScorecardEvent[] => {
  if (gradeEventFiltersAreEmpty(filters)) return events;

  return events.filter((event) => {
    return filters.every((filter) => {
      let value = event.values[filter.dimensionId];
      if (!value) {
        value = { string: "unknown" };
      }

      const filterValue = filter.filter_value;

      if (filterValue.one_of) {
        return filterValue.one_of.includes(
          value.string ||
            value._int?.toString() ||
            value.number?.toString() ||
            "",
        );
      }

      if (filterValue.between) {
        const eventValue =
          value._int || value.number || value.duration_seconds || 0;
        return (
          eventValue >= filterValue.between.start &&
          eventValue <= filterValue.between.end
        );
      }

      return false;
    });
  });
};

export const EvaluationFilterBar = () => {
  const {
    gradeFilters,
    setGradeFilters,
    isFiltered,
    selectedFilterDimension,
    setSelectedFilterDimension,
    setFilteredTags,
    availableTags,
    filteredTags,
  } = useEvaluationFilterContext();

  if (!isFiltered && availableTags.length === 0) {
    return null;
  }

  const filters = Object.entries(gradeFilters).flatMap(([gradeName, filters]) =>
    filters.map((filter) => ({ ...filter, gradeName })),
  );

  const tagsNotYetFiltered = availableTags.filter(
    (tag) => !filteredTags.includes(tag),
  );

  const onRemoveFilter = ({
    dimensionId,
    gradeName,
  }: ScorecardDimensionIdentifier) => {
    const filtersForGrade = gradeFilters[gradeName];
    if (!filtersForGrade) return;

    const updatedFilters = filtersForGrade.filter(
      (f) => f.dimensionId !== dimensionId,
    );

    setGradeFilters({
      ...gradeFilters,
      [gradeName]: updatedFilters,
    });
  };

  const onClearFilters = () => {
    setGradeFilters({});
    setFilteredTags([]);
  };

  return (
    <div className="flex items-center gap-1 text-xs flex-wrap bg-surface-secondary rounded-2 p-2">
      {availableTags.length > 0 && (
        <PopoverMultiSelect
          object={false}
          value={filteredTags}
          onChange={setFilteredTags}
          options={availableTags.map((tag) => ({ label: tag, value: tag }))}
          popoverItemClassName="text-xs p-1 gap-1"
          renderTriggerNode={
            filteredTags.length > 0
              ? ({ onClick }) => (
                  <FilterBadge
                    dimensionLabel="Tags"
                    icon={IconEnum.Tag}
                    filter={{ one_of: filteredTags }}
                    onRemove={() => setFilteredTags([])}
                    onClick={onClick}
                  />
                )
              : ({ onClick }) => (
                  <Button
                    analyticsTrackingId={null}
                    theme={ButtonTheme.Secondary}
                    size={BadgeSize.ExtraSmall}
                    disabled={tagsNotYetFiltered.length === 0}
                    title=""
                    onClick={onClick}
                    icon={IconEnum.Tag}
                  >
                    {isFiltered ? undefined : "Filter by tag"}
                  </Button>
                )
          }
        />
      )}
      {filters.map(
        ({
          dimensionId,
          dimensionName,
          dimensionType,
          gradeName,
          filter_value,
        }) => {
          const isSelected =
            dimensionId === selectedFilterDimension?.dimensionId &&
            gradeName === selectedFilterDimension?.gradeName;
          return (
            <FilterBadge
              key={dimensionId}
              dimensionLabel={`${gradeName}: ${dimensionName}`}
              icon={iconForDimension(dimensionType)}
              filter={filter_value}
              formatter={
                dimensionType === "duration"
                  ? formatScorecardDuration
                  : undefined
              }
              onRemove={() =>
                onRemoveFilter({ dimensionId, gradeName, dimensionName })
              }
              onClick={() =>
                setSelectedFilterDimension(
                  isSelected
                    ? undefined
                    : {
                        dimensionId: dimensionId,
                        dimensionName: dimensionName,
                        gradeName: gradeName,
                      },
                )
              }
            />
          );
        },
      )}
      {isFiltered && (
        <Button
          analyticsTrackingId={null}
          onClick={onClearFilters}
          theme={ButtonTheme.Naked}
          size={BadgeSize.ExtraSmall}
          className="text-[10px] text-content-tertiary"
        >
          Clear all filters
        </Button>
      )}
    </div>
  );
};

const FilterBadge = forwardRef(
  (
    {
      dimensionLabel,
      filter,
      onClick,
      onRemove,
      icon,
      formatter,
    }: {
      dimensionLabel: string;
      filter: GradeEventFilterValue;
      onClick?: () => void;
      icon?: IconEnum;
      onRemove: () => void;
      formatter?: (value: number) => string;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const presentBetween = ({ start, end }: { start: number; end: number }) => {
      if (formatter) {
        return `${formatter(start)} - ${formatter(end)}`;
      }
      return `${start.toFixed(1)} - ${end.toFixed(1)}`;
    };

    let criteria: React.ReactNode = <></>;
    if (filter.one_of) {
      criteria = joinSpansWithCommasAndConnectorWord(filter.one_of, "or");
    } else if (filter.between) {
      criteria = <>{presentBetween(filter.between)}</>;
    }

    return (
      <div className="flex items-center" ref={ref}>
        <ConditionBadge
          mini
          icon={icon}
          theme="white"
          onClick={onClick}
          subject={dimensionLabel}
          operation={filter.one_of ? "is one of" : "is between"}
          criteria={criteria}
          className="rounded-r-none text-[10px]"
        />
        <Button
          icon={IconEnum.Close}
          theme={ButtonTheme.Secondary}
          size={BadgeSize.ExtraSmall}
          analyticsTrackingId={null}
          title=""
          className="border-none shadow-none hover:bg-surface-tertiary rounded-l-none"
          onClick={onRemove}
        />
      </div>
    );
  },
);
FilterBadge.displayName = "FilterBadge";

export const iconForDimension = (
  dimensionType: EvaluationScorecardDimensionTypeEnum,
) => {
  switch (dimensionType) {
    case EvaluationScorecardDimensionTypeEnum.Int:
    case EvaluationScorecardDimensionTypeEnum.Number:
      return IconEnum.NumberInput;
    case EvaluationScorecardDimensionTypeEnum.Duration:
      return IconEnum.Clock;
    case EvaluationScorecardDimensionTypeEnum.String:
      return IconEnum.Text;
    default:
      return IconEnum.QuestionMark;
  }
};

export const formatScorecardDuration = (
  seconds: number,
  precision = 1,
): string => {
  // Handle negative durations by adding a minus prefix
  const isNegative = seconds < 0;
  const absoluteSeconds = Math.abs(seconds);

  // Define the units and their seconds
  const units = [
    { unit: "d", seconds: 86400 },
    { unit: "h", seconds: 3600 },
    { unit: "m", seconds: 60 },
    { unit: "s", seconds: 1 },
  ];

  let remainingSeconds = absoluteSeconds;
  const parts: string[] = [];

  // Calculate each unit's value
  for (const { unit, seconds: unitSeconds } of units) {
    if (remainingSeconds >= unitSeconds) {
      const value = Math.floor(remainingSeconds / unitSeconds);
      parts.push(`${value}${unit}`);
      remainingSeconds %= unitSeconds;
    }
  }

  // Handle special cases
  if (parts.length === 0) {
    parts.push("0s");
  }

  // Take only the number of parts specified by precision
  const formatted = parts.slice(0, precision).join(" ");
  return isNegative ? `-${formatted}` : formatted;
};

type EvaluationFilters = Partial<
  Pick<EvaluationFilterContextType, "gradeFilters" | "filteredTags">
>;

export const useFilterEntries = (): ((
  entries: EvaluationBacktestEntry[],
  overrides?: EvaluationFilters,
) => EvaluationBacktestEntry[]) => {
  const { gradeFilters: _gradeFilters, filteredTags: _filteredTags } =
    useEvaluationFilterContext();

  return (
    entries: EvaluationBacktestEntry[],
    overrides?: EvaluationFilters,
  ): EvaluationBacktestEntry[] => {
    const gradeFilters = overrides?.gradeFilters || _gradeFilters;
    const filteredTags = overrides?.filteredTags || _filteredTags;
    return entries.filter((entry) => {
      if (filteredTags.length > 0) {
        const matchesTags = entry.tags.some((tag) =>
          filteredTags.includes(tag),
        );
        if (!matchesTags) {
          return false;
        }
      }

      for (const [gradeName, filters] of Object.entries(gradeFilters)) {
        if (gradeEventFiltersAreEmpty(filters)) {
          continue;
        }

        const gradeEvents = entry.evaluation_scorecard?.grades.find(
          (g) => g.name === gradeName,
        )?.events;
        if (!gradeEvents) {
          return false;
        }

        const filteredEvents = filterGradeEvents(gradeEvents, filters);
        if (filteredEvents.length === 0) {
          return false;
        }
      }

      // You have passed all our filters, you are a winner!
      return true;
    });
  };
};
