import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogType,
  IncidentFormEscalationElement,
  IncidentFormsCreateEscalationElementRequestBody,
} from "@incident-io/api";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";

import { CreateEditElementForm } from "./IncidentEscalateFormElementCreateEditModal";

export const marshallFormData = (
  formData: CreateEditElementForm,
  elementType,
  formId,
): IncidentFormsCreateEscalationElementRequestBody => {
  const req = {
    catalog_type_id: formData.catalogTypeId,
    description: formData.description,
    element_type: elementType,
    incident_form_id: formId,
  } as IncidentFormsCreateEscalationElementRequestBody;
  if (formData.defaultValue) {
    req.default_value = {
      value: {
        literal: formData.defaultValue,
      },
    };
  }
  if (
    elementType ===
    AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
  ) {
    const navigateExpressionData = formData?.navigation_expression;
    if (navigateExpressionData) {
      const navigatePayload = expressionToPayload(navigateExpressionData);
      req.navigation_expression = {
        ...navigatePayload,
        reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}`,
        root_reference: "subject",
        label: `escalationElementExpression-${formId}-${formData.catalogTypeId}`,
      };
    }

    if (formData.pagerduty_service_navigation_expression) {
      const pagerdutyServiceExpression = expressionToPayload(
        formData.pagerduty_service_navigation_expression,
      );
      req.pagerduty_service_navigation_expression = {
        ...pagerdutyServiceExpression,
        reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}-pagerduty`,
        root_reference: "subject",
        label: `escalationElementExpression-${formId}-${formData.catalogTypeId}-pagerduty`,
      };
    }
    if (formData.opsgenie_team_navigation_expression) {
      const opsgenieTeamExpression = expressionToPayload(
        formData.opsgenie_team_navigation_expression,
      );
      req.opsgenie_team_navigation_expression = {
        ...opsgenieTeamExpression,
        reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}-opsgenie`,
        root_reference: "subject",
        label: `escalationElementExpression-${formId}-${formData.catalogTypeId}-opsgenie`,
      };
    }
  }
  return req;
};

export const formElementToDefaults = (
  initialData: IncidentFormEscalationElement,
  catalogType?: CatalogType,
): CreateEditElementForm => {
  let defaults = {
    description: initialData?.description,
    defaultValue:
      initialData.default_value?.array_value &&
      initialData.default_value?.array_value.length > 0
        ? initialData.default_value?.array_value[0].literal
        : initialData.default_value?.value?.literal ?? "",
  } as CreateEditElementForm;

  if (
    initialData?.available_element.element_type ===
    AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
  ) {
    defaults = {
      ...defaults,
      catalogTypeId: catalogType?.id,
      navigation_expression: initialData.navigation_expression,
      opsgenie_team_navigation_expression:
        initialData.opsgenie_team_navigation_expression,
      pagerduty_service_navigation_expression:
        initialData.pagerduty_service_navigation_expression,
    };
  }
  return defaults;
};
