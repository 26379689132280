/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationLeaderboardEntry,
    EvaluationLeaderboardEntryFromJSON,
    EvaluationLeaderboardEntryFromJSONTyped,
    EvaluationLeaderboardEntryToJSON,
} from './EvaluationLeaderboardEntry';

/**
 * 
 * @export
 * @interface EvaluationLeaderboard
 */
export interface EvaluationLeaderboard {
    /**
     * When the leaderboard was archived, if it was
     * @type {Date}
     * @memberof EvaluationLeaderboard
     */
    archived_at?: Date;
    /**
     * Type of backtest this leaderboard compares
     * @type {string}
     * @memberof EvaluationLeaderboard
     */
    backtest_type: EvaluationLeaderboardBacktestTypeEnum;
    /**
     * When the leaderboard was created
     * @type {Date}
     * @memberof EvaluationLeaderboard
     */
    created_at: Date;
    /**
     * Description for this leaderboard
     * @type {string}
     * @memberof EvaluationLeaderboard
     */
    description?: string;
    /**
     * Unique identifier of the leaderboard
     * @type {string}
     * @memberof EvaluationLeaderboard
     */
    id: string;
    /**
     * Optional type of investigation plan this leaderboard compares
     * @type {string}
     * @memberof EvaluationLeaderboard
     */
    investigation_plan_type?: string;
    /**
     * 
     * @type {EvaluationLeaderboardEntry}
     * @memberof EvaluationLeaderboard
     */
    most_recent_entry?: EvaluationLeaderboardEntry;
    /**
     * Display name for this leaderboard
     * @type {string}
     * @memberof EvaluationLeaderboard
     */
    name: string;
    /**
     * When the leaderboard was last updated
     * @type {Date}
     * @memberof EvaluationLeaderboard
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationLeaderboardBacktestTypeEnum {
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    SearchCodeChanges = 'search_code_changes',
    SearchIncidents = 'search_incidents'
}

export function EvaluationLeaderboardFromJSON(json: any): EvaluationLeaderboard {
    return EvaluationLeaderboardFromJSONTyped(json, false);
}

export function EvaluationLeaderboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationLeaderboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'backtest_type': json['backtest_type'],
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'investigation_plan_type': !exists(json, 'investigation_plan_type') ? undefined : json['investigation_plan_type'],
        'most_recent_entry': !exists(json, 'most_recent_entry') ? undefined : EvaluationLeaderboardEntryFromJSON(json['most_recent_entry']),
        'name': json['name'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationLeaderboardToJSON(value?: EvaluationLeaderboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'backtest_type': value.backtest_type,
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'investigation_plan_type': value.investigation_plan_type,
        'most_recent_entry': EvaluationLeaderboardEntryToJSON(value.most_recent_entry),
        'name': value.name,
        'updated_at': (value.updated_at.toISOString()),
    };
}

