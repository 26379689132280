/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetrospectiveIncidentOptions
 */
export interface RetrospectiveIncidentOptions {
    /**
     * Should this retrospective incident be announced?
     * @type {boolean}
     * @memberof RetrospectiveIncidentOptions
     */
    announcements_enabled: boolean;
    /**
     * Should the incident enter the post-incident flow straight away, or start in a closed state?
     * @type {boolean}
     * @memberof RetrospectiveIncidentOptions
     */
    enter_post_incident_flow?: boolean;
    /**
     * The external ID (e.g. the 123 in INC-123) to assign to the incident. This can be useful when importing incidents. If you want to use this field, you'll need to talk to us first.
     * @type {number}
     * @memberof RetrospectiveIncidentOptions
     */
    external_id?: number;
    /**
     * The URL of the postmortem, if there is one
     * @type {string}
     * @memberof RetrospectiveIncidentOptions
     */
    postmortem_document_url?: string;
    /**
     * Pass the ID of a Slack channel to attach the incident to an existing channel, rather than creating a new one
     * @type {string}
     * @memberof RetrospectiveIncidentOptions
     */
    slack_channel_id?: string;
    /**
     * If you are using Slack Enterprise Grid, this must be passed along with the channel ID to attach the incident to an existing channel. If you are not using Enterprise Grid, this field is optional.
     * @type {string}
     * @memberof RetrospectiveIncidentOptions
     */
    slack_team_id?: string;
}

export function RetrospectiveIncidentOptionsFromJSON(json: any): RetrospectiveIncidentOptions {
    return RetrospectiveIncidentOptionsFromJSONTyped(json, false);
}

export function RetrospectiveIncidentOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetrospectiveIncidentOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'announcements_enabled': json['announcements_enabled'],
        'enter_post_incident_flow': !exists(json, 'enter_post_incident_flow') ? undefined : json['enter_post_incident_flow'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'postmortem_document_url': !exists(json, 'postmortem_document_url') ? undefined : json['postmortem_document_url'],
        'slack_channel_id': !exists(json, 'slack_channel_id') ? undefined : json['slack_channel_id'],
        'slack_team_id': !exists(json, 'slack_team_id') ? undefined : json['slack_team_id'],
    };
}

export function RetrospectiveIncidentOptionsToJSON(value?: RetrospectiveIncidentOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'announcements_enabled': value.announcements_enabled,
        'enter_post_incident_flow': value.enter_post_incident_flow,
        'external_id': value.external_id,
        'postmortem_document_url': value.postmortem_document_url,
        'slack_channel_id': value.slack_channel_id,
        'slack_team_id': value.slack_team_id,
    };
}

