/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathRoundRobinConfigV2,
    EscalationPathRoundRobinConfigV2FromJSON,
    EscalationPathRoundRobinConfigV2FromJSONTyped,
    EscalationPathRoundRobinConfigV2ToJSON,
} from './EscalationPathRoundRobinConfigV2';
import {
    EscalationPathTargetV2,
    EscalationPathTargetV2FromJSON,
    EscalationPathTargetV2FromJSONTyped,
    EscalationPathTargetV2ToJSON,
} from './EscalationPathTargetV2';

/**
 * 
 * @export
 * @interface EscalationPathNodeLevelV2
 */
export interface EscalationPathNodeLevelV2 {
    /**
     * 
     * @type {EscalationPathRoundRobinConfigV2}
     * @memberof EscalationPathNodeLevelV2
     */
    round_robin_config?: EscalationPathRoundRobinConfigV2;
    /**
     * The targets (users or schedules) for this level
     * @type {Array<EscalationPathTargetV2>}
     * @memberof EscalationPathNodeLevelV2
     */
    targets: Array<EscalationPathTargetV2>;
    /**
     * If the time to ack is relative to a time window, this defines whether we move when the window is active or inactive
     * @type {string}
     * @memberof EscalationPathNodeLevelV2
     */
    time_to_ack_interval_condition?: EscalationPathNodeLevelV2TimeToAckIntervalConditionEnum;
    /**
     * How long should we wait for this level to acknowledge before proceeding to the next node in the path?
     * @type {number}
     * @memberof EscalationPathNodeLevelV2
     */
    time_to_ack_seconds?: number;
    /**
     * If the time to ack is relative to a time window, this identifies which window it is relative to
     * @type {string}
     * @memberof EscalationPathNodeLevelV2
     */
    time_to_ack_weekday_interval_config_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathNodeLevelV2TimeToAckIntervalConditionEnum {
    Active = 'active',
    Inactive = 'inactive'
}

export function EscalationPathNodeLevelV2FromJSON(json: any): EscalationPathNodeLevelV2 {
    return EscalationPathNodeLevelV2FromJSONTyped(json, false);
}

export function EscalationPathNodeLevelV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeLevelV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'round_robin_config': !exists(json, 'round_robin_config') ? undefined : EscalationPathRoundRobinConfigV2FromJSON(json['round_robin_config']),
        'targets': ((json['targets'] as Array<any>).map(EscalationPathTargetV2FromJSON)),
        'time_to_ack_interval_condition': !exists(json, 'time_to_ack_interval_condition') ? undefined : json['time_to_ack_interval_condition'],
        'time_to_ack_seconds': !exists(json, 'time_to_ack_seconds') ? undefined : json['time_to_ack_seconds'],
        'time_to_ack_weekday_interval_config_id': !exists(json, 'time_to_ack_weekday_interval_config_id') ? undefined : json['time_to_ack_weekday_interval_config_id'],
    };
}

export function EscalationPathNodeLevelV2ToJSON(value?: EscalationPathNodeLevelV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'round_robin_config': EscalationPathRoundRobinConfigV2ToJSON(value.round_robin_config),
        'targets': ((value.targets as Array<any>).map(EscalationPathTargetV2ToJSON)),
        'time_to_ack_interval_condition': value.time_to_ack_interval_condition,
        'time_to_ack_seconds': value.time_to_ack_seconds,
        'time_to_ack_weekday_interval_config_id': value.time_to_ack_weekday_interval_config_id,
    };
}

