import { IconEnum } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useCallback } from "react";

import { useClient } from "../../../../../contexts/ClientContext";

export const useExternalScheduleTypeahead = () => {
  const apiClient = useClient();

  const loadOptions = useCallback(
    async (query: string): Promise<SelectOption[]> => {
      const { external_schedules } = await apiClient.schedulesListExternal({
        search: query,
      });
      return external_schedules.map((s) => ({
        label: s.name,
        value: s.external_provider_id,
        icon: IconEnum.Pagerduty,
      }));
    },
    [apiClient],
  );

  const hydrateOptions = useCallback(
    async (idList: string | string[]): Promise<SelectOption[]> => {
      const idListArray = typeof idList === "string" ? [idList] : idList;

      const { external_schedules } = await apiClient.schedulesListExternal({
        externalIds: idListArray,
      });
      return external_schedules.map((s) => ({
        label: s.name,
        value: s.external_provider_id,
        icon: IconEnum.Pagerduty,
      }));
    },
    [apiClient],
  );

  return { loadOptions, hydrateOptions };
};
