import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { TeamsGetStartedDrawer } from "./convert/TeamsGetStartedDrawer";
import { TeamsCreateDrawer } from "./create/TeamsCreateDrawer";
import { TeamsEditDrawer } from "./edit/TeamsEditDrawer";
import { TeamsListPage } from "./list/TeamsListPage";
import { TeamViewDrawer } from "./view/TeamViewDrawer";

export const TeamsRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/*" element={<TeamsListPage />}>
        <Route path="get-started" element={<TeamsGetStartedRoute />} />
        <Route path="create" element={<TeamsCreateDrawer />} />
        <Route path=":id/*" element={<TeamViewDrawer />}>
          <Route path="edit" element={<TeamsEditDrawer />} />
        </Route>
      </Route>
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};

const TeamsGetStartedRoute = () => {
  const navigate = useOrgAwareNavigate();

  const onClose = () => navigate("/settings/teams");

  return <TeamsGetStartedDrawer onClose={onClose} />;
};
