import type { SVGProps } from "react";
import * as React from "react";
const SvgQuoteAi = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 4a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H3Zm0 5a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H3Zm0 5a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H3Zm13.309-3.093 1.776.888a.749.749 0 0 1 0 1.342l-1.776.888-.888 1.776a.75.75 0 0 1-1.342 0l-.888-1.776-1.776-.888a.749.749 0 0 1 0-1.342l1.776-.888.888-1.776c.254-.508 1.088-.508 1.342 0l.888 1.776Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuoteAi;
