import { ContentBox } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

type WorkbenchSectionProps = {
  title: string;
  accessory?: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  className?: string;
};

export const WorkbenchSection = ({
  title,
  accessory,
  children,
  contentClassName,
  className = "",
}: WorkbenchSectionProps): React.ReactElement => {
  return (
    <ContentBox className={className}>
      <div className="p-4 border-b border-gray-200">
        <div className="flex w-full justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          {accessory}
        </div>
      </div>
      <div className={tcx("overflow-y-auto p-4", contentClassName)}>
        {children}
      </div>
    </ContentBox>
  );
};
