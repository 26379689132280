import {
  CatalogTypeAttribute,
  Team,
  TeamSettings,
  User,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  SearchBar,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Searcher } from "fast-fuzzy";
import { AnimatePresence } from "framer-motion";
import React, { useMemo, useState } from "react";
import { useOutlet, useParams } from "react-router";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import {
  determineSyncIcon,
  determineSyncName,
} from "../../../catalog/common/SourceRepoBadge";
import { useTeamsRevalidate } from "../convert/hooks";
import { TeamsDeleteModal } from "../edit/TeamsDeleteModal";
import { TeamResources } from "./TeamResources";

export const TeamViewDrawer = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const navigate = useOrgAwareNavigate();
  const revalidate = useTeamsRevalidate();
  const isEditing = location.pathname.split("/").includes("edit");
  const stackedDrawer = useOutlet();

  const onClose = () => {
    revalidate();
    navigate("/settings/teams");
  };

  const { data: teamData, error: teamError } = useAPI("teamsShow", {
    id: id ?? "",
  });

  const { data: teamSettingsData, error: teamSettingsError } = useAPI(
    "teamsGetSettings",
    undefined,
  );

  if (teamError || teamSettingsError) {
    return (
      <Drawer onClose={onClose} width={"medium"}>
        <DrawerTitle title="Error" onClose={onClose} />
        <DrawerContents>
          <DrawerBody>
            <GenericErrorMessage error={teamError} />
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    );
  }

  const team = teamData?.team;

  return (
    <Drawer onClose={onClose} width={"full"} isInBackground={isEditing}>
      {!teamData || !teamSettingsData ? (
        <DrawerContentsLoading />
      ) : (
        <>
          <AnimatePresence>{stackedDrawer}</AnimatePresence>
          <TeamViewDrawerLoaded
            onClose={onClose}
            team={team as Team}
            teamSettings={teamSettingsData.team_settings}
          />
        </>
      )}
    </Drawer>
  );
};

const TeamViewDrawerLoaded = ({
  team,
  teamSettings,
  onClose,
}: {
  team: Team;
  teamSettings: TeamSettings;
  onClose: () => void;
}) => {
  const [isDeletingTeamModalOpen, setIsDeletingTeamModalOpen] = useState(false);

  const [rawSearch, setSearch] = useState("");
  const [search] = useDebounce(rawSearch, 300);

  const searcher = useMemo(
    () => new Searcher(team.members, { keySelector: (user) => user.name }),
    [team.members],
  );

  const isSearching = search.length > 0;
  const filteredResults = isSearching ? searcher.search(search) : team.members;

  // Only allow editing the team members if the team is not externally managed
  const sourceRepoURL = teamSettings.catalog_type?.source_repo_url;
  const externallyManaged = !!sourceRepoURL;

  // If the team is synced from a group already e.g. Slack User Group, Linear Team, etc.
  // don't allow editing the team members
  const derivedMembersAttribute =
    teamSettings.catalog_type?.schema.attributes.find(
      (attribute) =>
        attribute.id === teamSettings.members_derived_from_attribute_id,
    );
  const teamMembersDerivedFrom =
    !!teamSettings.members_derived_from_attribute_id;

  return (
    <div className="h-full overflow-hidden flex flex-col">
      {externallyManaged && (
        <div className="pt-1 px-1 w-full">
          <Callout
            theme={CalloutTheme.Info}
            className="w-full !rounded-1"
            iconOverride={determineSyncIcon(sourceRepoURL)}
            iconClassName={"ml-2"}
          >
            <span>
              You manage this team{" "}
              <a
                href={sourceRepoURL}
                target={"_blank"}
                rel="noreferrer"
                className={"underline"}
              >
                {determineSyncName(sourceRepoURL)}
              </a>
              , so some attributes are not editable from your dashboard.
            </span>
          </Callout>
        </div>
      )}
      <DrawerTitle
        icon={IconEnum.Team}
        color={ColorPaletteEnum.Slate}
        title={team.name}
        subtitle={team.description}
        onClose={onClose}
        secondaryAccessory={
          <>
            <Tooltip content="View in catalog">
              <Button
                analyticsTrackingId={null}
                size={BadgeSize.Medium}
                icon={IconEnum.Book}
                title=""
                href={`/catalog/${teamSettings?.catalog_type?.id}/${team.id}`}
              />
            </Tooltip>

            {!externallyManaged && (
              <>
                <Button
                  analyticsTrackingId={"team-edit"}
                  theme={ButtonTheme.Secondary}
                  size={BadgeSize.Medium}
                  icon={IconEnum.Edit}
                  href="edit"
                  title={"Edit team"}
                />
                <Button
                  analyticsTrackingId={"team-delete"}
                  theme={ButtonTheme.Secondary}
                  size={BadgeSize.Medium}
                  icon={IconEnum.Delete}
                  onClick={() => setIsDeletingTeamModalOpen(true)}
                  title={"Delete team"}
                />
              </>
            )}
          </>
        }
      />
      <DrawerContents>
        <DrawerBody>
          <div className="flex w-full divide-x divide-solid divide-stroke-primary grow overflow-hidden">
            <div className="flex flex-col gap-4 grow pr-6 overflow-hidden">
              <div className="flex gap-2 flex-shrink-0">
                <SearchBar
                  className="w-full"
                  inputClassName="bg-surface-secondary border-0"
                  placeholder="Search members"
                  value={rawSearch}
                  onChange={(value) => setSearch(value)}
                  iconProps={{ color: ColorPaletteEnum.Slate }}
                />
                {!externallyManaged && (
                  <AddMemberPopover
                    team={team}
                    derivedMembersAttribute={derivedMembersAttribute}
                    managedViaAttribute={teamMembersDerivedFrom}
                  />
                )}
              </div>
              <div className="overflow-auto flex-grow">
                <Table
                  data={filteredResults}
                  gridTemplateColumns={"1fr 1fr auto"}
                  header={
                    <>
                      <TableHeaderCell>Member name</TableHeaderCell>
                      <TableHeaderCell>Email</TableHeaderCell>
                      <TableHeaderCell />
                    </>
                  }
                  renderRow={(user: User, idx: number) => (
                    <TeamMemberRow
                      teamId={team.id}
                      key={user.id}
                      user={user}
                      isLastRow={idx === filteredResults.length - 1}
                      externallyManaged={externallyManaged}
                      derivedMembersAttribute={derivedMembersAttribute}
                      managedViaAttribute={teamMembersDerivedFrom}
                    />
                  )}
                  emptyStateRow={
                    <EmptyState
                      className="border-none"
                      content={
                        isSearching ? (
                          <>No matches found</>
                        ) : (
                          <>This team doesn&rsquo;t have any members yet</>
                        )
                      }
                    />
                  }
                />
              </div>
            </div>
            <div className="flex pl-6 w-[380px] overflow-auto">
              <TeamResources team={team} />
            </div>
          </div>
        </DrawerBody>
      </DrawerContents>
      {isDeletingTeamModalOpen && (
        <TeamsDeleteModal
          team={team}
          onClose={() => {
            setIsDeletingTeamModalOpen(false);
          }}
          onDelete={() => {
            setIsDeletingTeamModalOpen(false);
            onClose();
          }}
        />
      )}
    </div>
  );
};

const TeamMemberRow = ({
  teamId,
  user,
  isLastRow,
  externallyManaged,
  derivedMembersAttribute,
  managedViaAttribute,
}: {
  teamId: string;
  user: User;
  isLastRow: boolean;
  externallyManaged: boolean;
  derivedMembersAttribute?: CatalogTypeAttribute;
  managedViaAttribute: boolean;
}) => {
  const showToast = useToast();
  const revalidate = useTeamsRevalidate();

  const { trigger: removeMember, isMutating } = useAPIMutation(
    "teamsShow",
    { id: teamId },
    async (apiClient, { user_id }) => {
      await apiClient.teamsRemoveMember({
        id: teamId,
        removeMemberRequestBody: {
          user_id,
        },
      });
    },
    {
      onSuccess: () => {
        revalidate();
        showToast({
          theme: ToastTheme.Success,
          title: "Successfully removed member from team",
        });
      },
      showErrorToast: "Failed to remove member from team",
    },
  );

  return (
    <TableRow isLastRow={isLastRow}>
      <TableCell>
        {user.avatar_url && <Avatar url={user.avatar_url} />} {user.name}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <DropdownMenu
          triggerIcon={IconEnum.DotsVertical}
          triggerButtonTheme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          screenReaderText="Options"
          menuClassName={"text-color-tertiary rounded-1"}
        >
          <DropdownMenuItem
            disabled={isMutating || externallyManaged || managedViaAttribute}
            tooltipContent={
              externallyManaged
                ? "This team cannot be edited via the web UI"
                : derivedMembersAttribute && managedViaAttribute
                ? `This team's membership is managed via ${derivedMembersAttribute.name}`
                : undefined
            }
            label="Remove"
            analyticsTrackingId={"team-member-remove"}
            onSelect={() => removeMember({ user_id: user.id })}
          />
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
};

const AddMemberPopover = ({
  team,
  derivedMembersAttribute,
  managedViaAttribute,
}: {
  team: Team;
  derivedMembersAttribute?: CatalogTypeAttribute;
  managedViaAttribute: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 250);
  const {
    data: { options: userOptions },
  } = useAPI(
    "usersTypeahead",
    {
      query: debouncedSearch,
    },
    {
      fallbackData: {
        options: [],
      },
    },
  );

  // Remove existing members from the list of options
  const filteredUserOptions = userOptions.filter((option) => {
    return !team.members.some((member) => member.id === option.value);
  });

  const showToast = useToast();

  const { trigger: addMember, isMutating } = useAPIMutation(
    "teamsShow",
    { id: team.id },
    async (apiClient, { user_id }) => {
      await apiClient.teamsAddMember({
        id: team.id,
        addMemberRequestBody: {
          user_id,
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Successfully added member to team",
        });
      },
      showErrorToast: "Failed to add member to team",
    },
  );

  return (
    <DropdownMenu
      triggerButton={
        <Button
          analyticsTrackingId={"team-add-member"}
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Add}
        >
          Add member
        </Button>
      }
      search={search}
      setSearch={setSearch}
      disabled={isMutating || managedViaAttribute}
      tooltipContent={
        derivedMembersAttribute && managedViaAttribute
          ? `This team's membership is managed via ${derivedMembersAttribute.name}. Edit the ${derivedMembersAttribute.name} to manage membership.`
          : undefined
      }
    >
      {filteredUserOptions.map((option) => {
        return (
          <DropdownMenuItem
            analyticsTrackingId={option.value}
            key={option.value}
            label={option.label}
            onSelect={() => addMember({ user_id: option.value })}
          >
            <>
              <Avatar
                url={option.image_url}
                name={option.label}
                size={IconSize.Small}
              />
              <div>{option.label}</div>
            </>
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );
};
