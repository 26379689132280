import { useFlags } from "launchdarkly-react-client-sdk";

import { useAPI } from "../../../../utils/swr";

export const useTeamSettings = () => {
  const { featureNativeTeams } = useFlags();

  const {
    data: teamSettingsData,
    isLoading,
    error,
  } = useAPI("teamsGetSettings", undefined);

  // Show teams configuration stuff if you have the feature, we're not loading, and you've set up!
  const hasTeamsConfigured =
    featureNativeTeams &&
    !isLoading &&
    !!teamSettingsData?.team_settings.catalog_type;

  const hasTeamsAlertAttributeConfigured =
    hasTeamsConfigured && !!teamSettingsData?.team_settings.alert_attribute_id;

  const hasTeamsCustomFieldConfigured =
    hasTeamsConfigured && !!teamSettingsData?.team_settings.custom_field_id;

  return {
    teamSettings: teamSettingsData?.team_settings,
    hasTeamsConfigured,
    hasTeamsAlertAttributeConfigured,
    hasTeamsCustomFieldConfigured,
    error,
    loading: isLoading,
  };
};
