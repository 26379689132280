/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFilterFields
 */
export interface IncidentFilterFields {
    /**
     * Filter on incident created at timestamp. The accepted operators are 'gte', 'lte' and 'date_range'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    created_at?: { [key: string]: Array<string>; };
    /**
     * Filter on an incident custom field. Custom field ID should be sent, followed by the operator and values. Accepted operator will depend on the custom field type.
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof IncidentFilterFields
     */
    custom_field?: { [key: string]: { [key: string]: Array<string>; }; };
    /**
     * Filter incidents by their inclusion in datasets. The accepted operators are 'one_of' or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    dataset?: { [key: string]: Array<string>; };
    /**
     * Filter for full text search of an incident. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    full_text_search?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident has any identified code changes that caused the incident. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_causing_code_changes_any?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident's causing code changes have been verified by a human. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_causing_code_changes_verified?: { [key: string]: Array<string>; };
    /**
     * Filter incidents by their investigation relevance. The accepted operator is 'one_of'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_investigation_relevance?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident has any investigation relevance assessment. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_investigation_relevance_any?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident's investigation relevance has been verified by a human. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_investigation_relevance_verified?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident has any related incidents. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_related_incidents_any?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident's related incidents have been verified by a human. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_related_incidents_verified?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident has any related messages. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_related_messages_any?: { [key: string]: Array<string>; };
    /**
     * Filter on whether an incident's related messages have been verified by a human. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    ground_truth_related_messages_verified?: { [key: string]: Array<string>; };
    /**
     * Filter on whether incident has a debrief attached. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    has_debrief?: { [key: string]: Array<string>; };
    /**
     * Filter on outstanding follow-ups. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    has_outstanding_follow_ups?: { [key: string]: Array<string>; };
    /**
     * Filter on whether incident has a post-mortem attached. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    has_postmortem?: { [key: string]: Array<string>; };
    /**
     * Filter on if an incident has a status page link. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    has_status_page?: { [key: string]: Array<string>; };
    /**
     * Filter on incident ID. The accepted operators are 'one_of', or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    id?: { [key: string]: Array<string>; };
    /**
     * Filter on an incident role. Role ID should be sent, followed by the operator and values. The accepted operators are 'one_of', 'is_blank'.
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof IncidentFilterFields
     */
    incident_role?: { [key: string]: { [key: string]: Array<string>; }; };
    /**
     * Filter on incident timestamps. The accepted operators are 'is_set', 'within', 'gte', 'lte', 'between'.
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof IncidentFilterFields
     */
    incident_timestamp?: { [key: string]: { [key: string]: Array<string>; }; };
    /**
     * Filter on incident type. The accepted operators are 'one_of, or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    incident_type?: { [key: string]: Array<string>; };
    /**
     * Filter on whether to include declined and canceled incidents. The accepted operator is 'is'. By default, declined and cancelled incidents are not returned
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    include_declined_canceled?: { [key: string]: Array<string>; };
    /**
     * Filter on whether to include merged incidents. The accepted operator is 'is'. By default, merged incidents are not returned
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    include_merged?: { [key: string]: Array<string>; };
    /**
     * Filter on whether to include private incidents. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    include_private?: { [key: string]: Array<string>; };
    /**
     * Filter on incident mode. The accepted operator is 'one_of'.  If this is not provided, this value defaults to `{"one_of": ["standard", "retrospective"] }`, meaning that test and tutorial incidents are not included.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    mode?: { [key: string]: Array<string>; };
    /**
     * Filter on if an incident was opted out of the post-incident flow. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    opted_out_of_post_incident_flow?: { [key: string]: Array<string>; };
    /**
     * Filter on incident participants. The accepted operators are 'one_of'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    participants?: { [key: string]: Array<string>; };
    /**
     * Filter on the status of an attached post-mortem. The accepted operators are 'one_of', or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    postmortem_status?: { [key: string]: Array<string>; };
    /**
     * Filter for the reference of the incident. The accepted operator is 'is'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    reference?: { [key: string]: Array<string>; };
    /**
     * Filter on incident severity. The accepted operators are 'one_of', 'not_in', 'gte', 'lte'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    severity?: { [key: string]: Array<string>; };
    /**
     * Filter on slack team an incident is created in. The accepted operators are 'one_of', or 'not_in'
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    slack_team_id?: { [key: string]: Array<string>; };
    /**
     * Filter on incident source. The accepted operators are 'one_of', or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    source?: { [key: string]: Array<string>; };
    /**
     * Filter on incident status. The accepted operators are 'one_of', or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    status?: { [key: string]: Array<string>; };
    /**
     * Filter on the category of the incidents status. The accepted operators are 'one_of', or 'not_in'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    status_category?: { [key: string]: Array<string>; };
    /**
     * Filter incidents by the teams they are tagged with. The accepted operators are 'one_of', 'all_of', 'is_set', 'is_not'
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    team?: { [key: string]: Array<string>; };
    /**
     * Filter on incident updated at timestamp. The accepted operators are 'gte', 'lte' and 'date_range'.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFilterFields
     */
    updated_at?: { [key: string]: Array<string>; };
}

export function IncidentFilterFieldsFromJSON(json: any): IncidentFilterFields {
    return IncidentFilterFieldsFromJSONTyped(json, false);
}

export function IncidentFilterFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFilterFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'custom_field': !exists(json, 'custom_field') ? undefined : json['custom_field'],
        'dataset': !exists(json, 'dataset') ? undefined : json['dataset'],
        'full_text_search': !exists(json, 'full_text_search') ? undefined : json['full_text_search'],
        'ground_truth_causing_code_changes_any': !exists(json, 'ground_truth_causing_code_changes_any') ? undefined : json['ground_truth_causing_code_changes_any'],
        'ground_truth_causing_code_changes_verified': !exists(json, 'ground_truth_causing_code_changes_verified') ? undefined : json['ground_truth_causing_code_changes_verified'],
        'ground_truth_investigation_relevance': !exists(json, 'ground_truth_investigation_relevance') ? undefined : json['ground_truth_investigation_relevance'],
        'ground_truth_investigation_relevance_any': !exists(json, 'ground_truth_investigation_relevance_any') ? undefined : json['ground_truth_investigation_relevance_any'],
        'ground_truth_investigation_relevance_verified': !exists(json, 'ground_truth_investigation_relevance_verified') ? undefined : json['ground_truth_investigation_relevance_verified'],
        'ground_truth_related_incidents_any': !exists(json, 'ground_truth_related_incidents_any') ? undefined : json['ground_truth_related_incidents_any'],
        'ground_truth_related_incidents_verified': !exists(json, 'ground_truth_related_incidents_verified') ? undefined : json['ground_truth_related_incidents_verified'],
        'ground_truth_related_messages_any': !exists(json, 'ground_truth_related_messages_any') ? undefined : json['ground_truth_related_messages_any'],
        'ground_truth_related_messages_verified': !exists(json, 'ground_truth_related_messages_verified') ? undefined : json['ground_truth_related_messages_verified'],
        'has_debrief': !exists(json, 'has_debrief') ? undefined : json['has_debrief'],
        'has_outstanding_follow_ups': !exists(json, 'has_outstanding_follow_ups') ? undefined : json['has_outstanding_follow_ups'],
        'has_postmortem': !exists(json, 'has_postmortem') ? undefined : json['has_postmortem'],
        'has_status_page': !exists(json, 'has_status_page') ? undefined : json['has_status_page'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'incident_role': !exists(json, 'incident_role') ? undefined : json['incident_role'],
        'incident_timestamp': !exists(json, 'incident_timestamp') ? undefined : json['incident_timestamp'],
        'incident_type': !exists(json, 'incident_type') ? undefined : json['incident_type'],
        'include_declined_canceled': !exists(json, 'include_declined_canceled') ? undefined : json['include_declined_canceled'],
        'include_merged': !exists(json, 'include_merged') ? undefined : json['include_merged'],
        'include_private': !exists(json, 'include_private') ? undefined : json['include_private'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'opted_out_of_post_incident_flow': !exists(json, 'opted_out_of_post_incident_flow') ? undefined : json['opted_out_of_post_incident_flow'],
        'participants': !exists(json, 'participants') ? undefined : json['participants'],
        'postmortem_status': !exists(json, 'postmortem_status') ? undefined : json['postmortem_status'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'slack_team_id': !exists(json, 'slack_team_id') ? undefined : json['slack_team_id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'status_category': !exists(json, 'status_category') ? undefined : json['status_category'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function IncidentFilterFieldsToJSON(value?: IncidentFilterFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': value.created_at,
        'custom_field': value.custom_field,
        'dataset': value.dataset,
        'full_text_search': value.full_text_search,
        'ground_truth_causing_code_changes_any': value.ground_truth_causing_code_changes_any,
        'ground_truth_causing_code_changes_verified': value.ground_truth_causing_code_changes_verified,
        'ground_truth_investigation_relevance': value.ground_truth_investigation_relevance,
        'ground_truth_investigation_relevance_any': value.ground_truth_investigation_relevance_any,
        'ground_truth_investigation_relevance_verified': value.ground_truth_investigation_relevance_verified,
        'ground_truth_related_incidents_any': value.ground_truth_related_incidents_any,
        'ground_truth_related_incidents_verified': value.ground_truth_related_incidents_verified,
        'ground_truth_related_messages_any': value.ground_truth_related_messages_any,
        'ground_truth_related_messages_verified': value.ground_truth_related_messages_verified,
        'has_debrief': value.has_debrief,
        'has_outstanding_follow_ups': value.has_outstanding_follow_ups,
        'has_postmortem': value.has_postmortem,
        'has_status_page': value.has_status_page,
        'id': value.id,
        'incident_role': value.incident_role,
        'incident_timestamp': value.incident_timestamp,
        'incident_type': value.incident_type,
        'include_declined_canceled': value.include_declined_canceled,
        'include_merged': value.include_merged,
        'include_private': value.include_private,
        'mode': value.mode,
        'opted_out_of_post_incident_flow': value.opted_out_of_post_incident_flow,
        'participants': value.participants,
        'postmortem_status': value.postmortem_status,
        'reference': value.reference,
        'severity': value.severity,
        'slack_team_id': value.slack_team_id,
        'source': value.source,
        'status': value.status,
        'status_category': value.status_category,
        'team': value.team,
        'updated_at': value.updated_at,
    };
}

