/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    GroupingKey,
    GroupingKeyFromJSON,
    GroupingKeyFromJSONTyped,
    GroupingKeyToJSON,
} from './GroupingKey';

/**
 * Configuration for incident creation from alerts
 * @export
 * @interface AlertRouteIncidentConfigPayload
 */
export interface AlertRouteIncidentConfigPayload {
    /**
     * Should triage incidents be declined when alerts are resolved?
     * @type {boolean}
     * @memberof AlertRouteIncidentConfigPayload
     */
    auto_decline_enabled: boolean;
    /**
     * What condition groups must be true for this alert route to create an incident?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRouteIncidentConfigPayload
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * How long should the escalation defer time be?
     * @type {number}
     * @memberof AlertRouteIncidentConfigPayload
     */
    defer_time_seconds: number;
    /**
     * Whether incident creation is enabled for this alert route
     * @type {boolean}
     * @memberof AlertRouteIncidentConfigPayload
     */
    enabled: boolean;
    /**
     * Which attributes should this alert route use to group alerts?
     * @type {Array<GroupingKey>}
     * @memberof AlertRouteIncidentConfigPayload
     */
    grouping_keys: Array<GroupingKey>;
    /**
     * How large should the grouping window be?
     * @type {number}
     * @memberof AlertRouteIncidentConfigPayload
     */
    grouping_window_seconds: number;
}

export function AlertRouteIncidentConfigPayloadFromJSON(json: any): AlertRouteIncidentConfigPayload {
    return AlertRouteIncidentConfigPayloadFromJSONTyped(json, false);
}

export function AlertRouteIncidentConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteIncidentConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_decline_enabled': json['auto_decline_enabled'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'defer_time_seconds': json['defer_time_seconds'],
        'enabled': json['enabled'],
        'grouping_keys': ((json['grouping_keys'] as Array<any>).map(GroupingKeyFromJSON)),
        'grouping_window_seconds': json['grouping_window_seconds'],
    };
}

export function AlertRouteIncidentConfigPayloadToJSON(value?: AlertRouteIncidentConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_decline_enabled': value.auto_decline_enabled,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'defer_time_seconds': value.defer_time_seconds,
        'enabled': value.enabled,
        'grouping_keys': ((value.grouping_keys as Array<any>).map(GroupingKeyToJSON)),
        'grouping_window_seconds': value.grouping_window_seconds,
    };
}

