/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface AIStaffUpdateEvaluationNoteRequestBody
 */
export interface AIStaffUpdateEvaluationNoteRequestBody {
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof AIStaffUpdateEvaluationNoteRequestBody
     */
    content: TextDocumentPayload;
    /**
     * ID of the resource this note is attached to
     * @type {string}
     * @memberof AIStaffUpdateEvaluationNoteRequestBody
     */
    resource_id: string;
    /**
     * Type of resource this note is attached to
     * @type {string}
     * @memberof AIStaffUpdateEvaluationNoteRequestBody
     */
    resource_type: AIStaffUpdateEvaluationNoteRequestBodyResourceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AIStaffUpdateEvaluationNoteRequestBodyResourceTypeEnum {
    Alert = 'alert',
    Incident = 'incident',
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    CopilotSearch = 'copilot_search',
    SearchGroundTruth = 'search_ground_truth',
    Suggestion = 'suggestion'
}

export function AIStaffUpdateEvaluationNoteRequestBodyFromJSON(json: any): AIStaffUpdateEvaluationNoteRequestBody {
    return AIStaffUpdateEvaluationNoteRequestBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateEvaluationNoteRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateEvaluationNoteRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': TextDocumentPayloadFromJSON(json['content']),
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
    };
}

export function AIStaffUpdateEvaluationNoteRequestBodyToJSON(value?: AIStaffUpdateEvaluationNoteRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextDocumentPayloadToJSON(value.content),
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
    };
}

