"use client";

import * as RadixPopover from "@radix-ui/react-popover";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { ANIMATION_MODIFIER } from "../../helpers";
import { useResize } from "../../use-resize";

const ARROW_WIDTH = 12;
const ARROW_HEIGHT = 6;

const Arrow = ({ mode }: { mode: "mobile" | "desktop" }) => {
  // we use a custom arrow because Radix's arrow is a plain triangle, which results in an
  // ugly border between the box and arrow, rather than them merging together. We still
  // rely on `RadixTooltip.Arrow` as a renderless component to provide positioning and
  // rotation, which will allow us to support placing the tooltip on a different side.
  return mode === "desktop" ? (
    <RadixTooltip.Arrow asChild>
      <svg
        className={cx(
          "mt-[-1px] z-10 stroke-slate-100 dark:stroke-[#4f5c6c]",
          "text-white",
          "dark:text-[#14171C]",
        )}
        width={ARROW_WIDTH}
        height={ARROW_HEIGHT}
        viewBox="0 0 12 6"
      >
        <polyline
          points="0,0 6,6 12,0"
          strokeWidth={1}
          fill="currentColor"
          className="bg-white dark:bg-slate-900"
        />
      </svg>
    </RadixTooltip.Arrow>
  ) : (
    <RadixPopover.Arrow asChild>
      <svg
        className={cx(
          "mt-[-1px] z-10 stroke-slate-100 dark:stroke-[#4f5c6c]",
          "text-white",
          "dark:text-[#14171C]",
        )}
        width={ARROW_WIDTH}
        height={ARROW_HEIGHT}
        viewBox="0 0 12 6"
      >
        <polyline
          points="0,0 6,6 12,0"
          strokeWidth={1}
          fill="currentColor"
          className="bg-white dark:bg-[#14171C]"
        />
      </svg>
    </RadixPopover.Arrow>
  );
};

const DesktopTooltip = ({
  content,
  children,
  className,
  body,
  withArrow = false,
  open,
  setOpen,
  debugOpen = false,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  body: HTMLElement;
  withArrow?: boolean;
  containerClassName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  debugOpen?: boolean;
}) => {
  // We delay opening the tooltip by 100ms to prevent the UI looking
  // jittery (by default, it'd flicker when you move the mouse across)
  const tooltipDelay = 100;

  return (
    <RadixTooltip.Provider delayDuration={tooltipDelay}>
      <RadixTooltip.Root open={open || debugOpen} onOpenChange={setOpen}>
        <RadixTooltip.Trigger asChild onClick={(e) => e.preventDefault()}>
          <div className={className}>{children}</div>
        </RadixTooltip.Trigger>
        <AnimatePresence>
          {open && (
            <RadixTooltip.Portal forceMount container={body}>
              <RadixTooltip.Content
                sideOffset={3}
                side={"bottom"}
                onPointerDownOutside={(e) => e.preventDefault()}
              >
                <motion.div
                  transition={{
                    duration: 0.2 * ANIMATION_MODIFIER,
                    type: "tween",
                    ease: "easeOut",
                  }}
                  // If this is using AnimatePresence, enter and exit from hidden
                  initial="hidden"
                  exit="hidden"
                  // What the different states are (hidden fades to 0%, then hides the
                  // element altogether so it can't be clicked on; active immediately makes it
                  // visible then fades up to full opacity).
                  variants={{
                    active: {
                      opacity: 1,
                      translateY: 0,
                      scale: 1,
                      visibility: "visible",
                      pointerEvents: "auto",
                    },
                    hidden: {
                      opacity: 0,
                      translateY: -2,
                      scale: 0.95,
                      pointerEvents: "none",
                      transitionEnd: { visibility: "hidden" },
                    },
                  }}
                  // What the current state should be
                  animate={open || debugOpen ? "active" : "hidden"}
                >
                  <div
                    className={cx(
                      "p-1 border cursor-auto text-sm rounded-md w-full transition",
                      "bg-white border-stroke text-content-primary shadow-sm",
                      "dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 dark:shadow-none",
                      "max-w-lg",
                    )}
                  >
                    {content}
                    {withArrow && <Arrow mode="desktop" />}
                  </div>
                </motion.div>
              </RadixTooltip.Content>
            </RadixTooltip.Portal>
          )}
        </AnimatePresence>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

const MobileTooltip = ({
  content,
  children,
  className,
  body,
  withArrow = false,
  open,
  setOpen,
  debugOpen = false,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  body: HTMLElement;
  withArrow?: boolean;
  containerClassName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  debugOpen?: boolean;
}): React.ReactElement => {
  return (
    <RadixPopover.Root open={open || debugOpen} onOpenChange={setOpen}>
      <RadixPopover.Trigger asChild>
        <div className={className}>{children}</div>
      </RadixPopover.Trigger>
      <AnimatePresence>
        {open && (
          <RadixPopover.Portal forceMount container={body}>
            <RadixPopover.Content sideOffset={3} side={"bottom"}>
              <motion.div
                transition={{
                  duration: 0.2 * ANIMATION_MODIFIER,
                  type: "tween",
                  ease: "easeOut",
                }}
                // If this is using AnimatePresence, enter and exit from hidden
                initial="hidden"
                exit="hidden"
                // What the different states are (hidden fades to 0%, then hides the
                // element altogether so it can't be clicked on; active immediately makes it
                // visible then fades up to full opacity).
                variants={{
                  active: {
                    opacity: 1,
                    translateY: 0,
                    scale: 1,
                    visibility: "visible",
                    pointerEvents: "auto",
                  },
                  hidden: {
                    opacity: 0,
                    translateY: -2,
                    scale: 0.95,
                    pointerEvents: "none",
                    transitionEnd: { visibility: "hidden" },
                  },
                }}
                // What the current state should be
                animate={open || debugOpen ? "active" : "hidden"}
              >
                <div
                  className={cx(
                    "p-1 border cursor-auto text-sm rounded-md w-full transition",
                    "bg-white border-stroke text-content-primary shadow-sm",
                    "dark:bg-[#14171C] dark:border-slate-600 dark:text-slate-300 dark:shadow-none",
                    "max-w-lg !outline-0 !outline-none",
                  )}
                >
                  {content}
                  {withArrow && <Arrow mode="mobile" />}
                </div>
              </motion.div>
            </RadixPopover.Content>
          </RadixPopover.Portal>
        )}
      </AnimatePresence>
    </RadixPopover.Root>
  );
};

export const Tooltip = ({
  content,
  children,
  className,
  withArrow = false,
  // debugOpen will keep the tooltip open permanently, allowing you to inspect it. Useful for styling.
  debugOpen = false,
}: {
  content: React.ReactNode;
  children?: React.ReactNode;
  withArrow?: boolean;
  className?: string;
  containerClassName?: string;
  debugOpen?: boolean;
}): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState<HTMLElement | null>(null);
  const [hasMouse, setHasMouse] = useState(false);

  useEffect(() => {
    setBody(document.body);
    setHasMouse(window.matchMedia("(hover: hover)").matches);
  }, [setBody, setHasMouse]);

  return body ? (
    hasMouse ? (
      <DesktopTooltip
        content={content}
        body={body}
        open={open}
        setOpen={setOpen}
        debugOpen={debugOpen}
        withArrow={withArrow}
        className={className}
      >
        {children}
      </DesktopTooltip>
    ) : (
      <MobileTooltip
        content={content}
        body={body}
        open={open}
        setOpen={setOpen}
        debugOpen={debugOpen}
        withArrow={withArrow}
        className={className}
      >
        {children}
      </MobileTooltip>
    )
  ) : (
    <div className={className}>{children}</div>
  );
};

const PADDING = 10; // pixels
export type TooltipBubbleProps = {
  withArrow?: boolean;
  containerClassName?: string;
  children: React.ReactNode;
  active: boolean;
  yOffset?: number;
  centerOfTarget?: number;
};

export const TooltipBubble = ({
  active,
  withArrow = false,
  centerOfTarget,
  children,
  containerClassName,
  yOffset,
}: TooltipBubbleProps) => {
  // Measure the size of the bubble for positioning
  const ref = useRef<HTMLDivElement>(null);
  const { width: tooltipWidth, windowWidth } = useResize(ref);

  let tooltipLeft: number | undefined;
  let tooltipRight: number | undefined;
  let arrowLeft: number | undefined;
  if (centerOfTarget && tooltipWidth && windowWidth) {
    // We've been told where to put the center of the tooltip, but we need to
    // convert that to a left-offset.
    const perfectLeft = centerOfTarget - tooltipWidth / 2;
    if (perfectLeft < PADDING) {
      tooltipLeft = PADDING;
      tooltipRight = undefined;
    } else if (perfectLeft + tooltipWidth > windowWidth - PADDING) {
      // We want to be positioned up against the right edge of the screen.
      // Doing this with the `left` property causes the tooltip to get
      // squished, so instead we set `right`.
      tooltipLeft = undefined;
      tooltipRight = PADDING;
    } else {
      tooltipLeft = perfectLeft;
      tooltipRight = undefined;
    }

    if (tooltipLeft) {
      // Position the arrow relative to the tooltip left, then adjust by the
      // width of the arrow.
      arrowLeft = centerOfTarget - tooltipLeft - ARROW_WIDTH / 2;
    } else if (tooltipRight) {
      // In this case we can figure out a fake 'tooltipLeft'
      const impliedTooltipLeft = windowWidth - tooltipRight - tooltipWidth;
      arrowLeft = centerOfTarget - impliedTooltipLeft - ARROW_WIDTH / 2;
    }
  }

  return (
    <motion.div
      ref={ref}
      // Styles for the tooltip itself (non-animated)
      className={cx(
        "absolute z-[90] max-w-[60vw] sm:max-w-[300px]",
        // When there's an arrow, we want the arrow to cover a section of the
        // top-border on the bubble.
        withArrow ? "pt-[7px]" : "pt-1",
        containerClassName,
      )}
      style={{
        left: tooltipLeft,
        right: tooltipRight,
        top: yOffset,
      }}
      // How to do transitions
      transition={{
        duration: 0.2 * ANIMATION_MODIFIER,
        type: "tween",
        ease: "easeOut",
      }}
      // If this is using AnimatePresence, enter and exit from hidden
      initial={"hidden"}
      exit={"hidden"}
      // What the different states are (hidden fades to 0%, then hides the
      // element altogether so it can't be clicked on; active immediately makes it
      // visible then fades up to full opacity).
      variants={{
        active: {
          opacity: 1,
          translateY: 0,
          scale: 1,
          visibility: "visible",
          pointerEvents: "auto",
        },
        hidden: {
          opacity: 0,
          translateY: -2,
          scale: 0.95,
          pointerEvents: "none",
          transitionEnd: { visibility: "hidden" },
        },
      }}
      // What the current state should be
      animate={active ? "active" : "hidden"}
    >
      {withArrow && (
        <svg
          className={cx(
            "absolute z-10 top-[2px] stroke-slate-100 dark:stroke-[#4f5c6c]",
            "text-white",
            "dark:text-[#14171C]",
          )}
          width={ARROW_WIDTH}
          height={ARROW_HEIGHT}
          style={{ left: arrowLeft }}
          viewBox="0 0 12 6"
        >
          <polyline
            points="0,6 6,0 12,6"
            strokeWidth={1}
            fill="currentColor"
            className="bg-white dark:bg-slate-900"
          />
        </svg>
      )}
      <div
        className={cx(
          "p-1 border cursor-auto text-sm rounded-md w-full transition",
          "bg-white border-stroke text-content-primary shadow-sm",
          "dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 dark:shadow-none",
        )}
      >
        {children}
      </div>
    </motion.div>
  );
};
