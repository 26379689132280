import { Button } from "@incident-ui/Button/Button";
import {
  GenericErrorMessage,
  GenericErrorMessageProps,
} from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { Modal, ModalContent, ModalFooter } from "@incident-ui/Modal";
import { captureException } from "@sentry/react";
import { useEffect } from "react";

export function ErrorModal({
  onClose,
  title = "Something went wrong.",
  analyticsTrackingId = null,
  error,
  ...rest
}: {
  onClose: () => void;
  title?: string;
  analyticsTrackingId?: string | null;
} & GenericErrorMessageProps): React.ReactElement {
  useEffect(() => {
    if (error) {
      // We only capture the exception here, it's logged inside GenericErrorMessage
      captureException(error);
    }
  });

  return (
    <Modal
      onClose={onClose}
      title={title}
      isOpen
      disableQuickClose
      analyticsTrackingId={analyticsTrackingId}
    >
      <ModalContent>
        <GenericErrorMessage hideHeading error={error} {...rest} />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} analyticsTrackingId={null}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
