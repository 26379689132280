/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MicrosoftTeamsReceiveLifecycleNotificationResponseBody,
    MicrosoftTeamsReceiveLifecycleNotificationResponseBodyFromJSON,
    MicrosoftTeamsReceiveLifecycleNotificationResponseBodyToJSON,
} from '../models';

export interface MicrosoftTeamsReceiveLifecycleNotificationRequest {
    validationToken?: string;
}

export interface MicrosoftTeamsReceiveMessageRequest {
    anything?: string;
}

export interface MicrosoftTeamsReceiveNotificationRequest {
    validationToken?: string;
}

/**
 * 
 */
export class MicrosoftTeamsApi extends runtime.BaseAPI {

    /**
     * Receive a lifecycle notification from the Teams change notification service
     * ReceiveLifecycleNotification Microsoft Teams
     */
    async microsoftTeamsReceiveLifecycleNotificationRaw(requestParameters: MicrosoftTeamsReceiveLifecycleNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/microsoft-teams/lifecycle-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Receive a lifecycle notification from the Teams change notification service
     * ReceiveLifecycleNotification Microsoft Teams
     */
    async microsoftTeamsReceiveLifecycleNotification(requestParameters: MicrosoftTeamsReceiveLifecycleNotificationRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.microsoftTeamsReceiveLifecycleNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Receive a message from the Teams messaging endpoint
     * ReceiveMessage Microsoft Teams
     */
    async microsoftTeamsReceiveMessageRaw(requestParameters: MicrosoftTeamsReceiveMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.anything !== undefined) {
            queryParameters['anything'] = requestParameters.anything;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/microsoft-teams/messaging`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a message from the Teams messaging endpoint
     * ReceiveMessage Microsoft Teams
     */
    async microsoftTeamsReceiveMessage(requestParameters: MicrosoftTeamsReceiveMessageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.microsoftTeamsReceiveMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Receive a notification from the Teams change notification service
     * ReceiveNotification Microsoft Teams
     */
    async microsoftTeamsReceiveNotificationRaw(requestParameters: MicrosoftTeamsReceiveNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/microsoft-teams/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a notification from the Teams change notification service
     * ReceiveNotification Microsoft Teams
     */
    async microsoftTeamsReceiveNotification(requestParameters: MicrosoftTeamsReceiveNotificationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.microsoftTeamsReceiveNotificationRaw(requestParameters, initOverrides);
    }

}
