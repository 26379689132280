import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  GenericErrorMessage,
  IconEnum,
  Loader,
  OrgAwareLink,
} from "@incident-ui";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { useAPI } from "src/utils/swr";
export const SlackCanvasPreviewPage = () => {
  const { data, isLoading } = useAPI("slackCanvasPreviewsList", {});

  if (isLoading) {
    return <Loader />;
  }

  if (!data?.templates) {
    return <GenericErrorMessage />;
  }

  // We don't yet have multiple categories, so we're just grouping by theme
  const themes = uniq(data.templates.map((t) => t.theme));
  const templatesByTheme = groupBy(data.templates, (t) => t.theme);

  return (
    <PageWrapper
      title="Slack Canvas Previews"
      width={PageWidth.Narrow}
      icon={IconEnum.Message}
    >
      <div className="h-full p-6 space-y-3">
        {themes.map((theme) => (
          <div key={theme}>
            <h2>{theme}</h2>
            <ul>
              {templatesByTheme[theme || ""].map((t) => (
                <li className="ml-3" key={t.name}>
                  <OrgAwareLink
                    className="hover:underline"
                    analyticsTrackingId=""
                    to={`/slack-canvas-previews/${t.name}`}
                  >
                    <div>{t.readableName}</div>
                  </OrgAwareLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </PageWrapper>
  );
};
