import {
  Badge,
  BadgeSize,
  BadgeTheme,
  GenericErrorMessage,
  Heading,
  LoadingBar,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { Form } from "src/components/@shared/forms";
import { StaffPermission } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const StaffPermissionsListPage = () => {
  const {
    data: { permissions },
    isLoading,
    error,
  } = useAPI("staffListStaffPermissions", undefined, {
    fallbackData: { permissions: [] },
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingBar className="h-32" />;
  }

  return (
    <>
      <div>
        <Heading level={3}>Permissions</Heading>
        <Form.Helptext>
          This is a list of all the permissions we have, along with their
          description, and the roles that usually have it. The permissions are
          not automatically assigned to the displayed roles: this is just here
          for reference.
        </Form.Helptext>
      </div>
      <Table
        wrappedInBox
        data={permissions}
        gridTemplateColumns="auto 1fr"
        header={
          <>
            <TableHeaderCell title="Permission" />
            <TableHeaderCell title="Roles" />
          </>
        }
        renderRow={(permission: StaffPermission, index) => {
          return (
            <TableRow isLastRow={index === permissions.length - 1} key={index}>
              <TableCell key={`${index}-name`} className="py-3 items-start">
                <div className="flex flex-col gap-0.5">
                  <div>{permission.name}</div>
                  {permission.description && (
                    <div className="text-xs text-content-tertiary">
                      {permission.description}
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell
                key={`${index}-name`}
                className="py-3 items-start gap-1"
              >
                {permission.roles.map((role) => (
                  <Badge
                    key={role}
                    theme={BadgeTheme.Secondary}
                    size={BadgeSize.Small}
                  >
                    {role}
                  </Badge>
                ))}
              </TableCell>
            </TableRow>
          );
        }}
      />
    </>
  );
};
