import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum,
  AICreateAssistantResponseFeedbackRequestBodyReasonsEnum,
  ClientType,
} from "src/contexts/ClientContext";
import { useMutationV2 } from "src/utils/swr";

type FeedbackFormData = {
  question_id: string;
  feedback: string;
  feedback_type: AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum;
  calculation_incorrect: boolean;
  graph_incorrect: boolean;
  unhelpful: boolean;
  clueless: boolean;
  too_slow: boolean;
  other: boolean;
};

export const NegativeFeedbackModal = ({
  questionId,
  onClose,
  onFeedbackGiven,
}: {
  questionId: string;
  onClose: () => void;
  onFeedbackGiven: () => void;
}): React.ReactElement => {
  const { trigger: onSubmit, genericError: genericError } = useMutationV2(
    async (apiClient, feedbackFormData: FeedbackFormData) => {
      // Build the reasons based on the checkboxes.
      const reasons: AICreateAssistantResponseFeedbackRequestBodyReasonsEnum[] =
        [];
      if (feedbackFormData.calculation_incorrect) {
        reasons.push(
          AICreateAssistantResponseFeedbackRequestBodyReasonsEnum.ThisCalculationIsIncorrect,
        );
      }
      if (feedbackFormData.graph_incorrect) {
        reasons.push(
          AICreateAssistantResponseFeedbackRequestBodyReasonsEnum.ThisGraphIsIncorrect,
        );
      }
      if (feedbackFormData.unhelpful) {
        reasons.push(
          AICreateAssistantResponseFeedbackRequestBodyReasonsEnum.ThisResponseIsntHelpful,
        );
      }
      if (feedbackFormData.clueless) {
        reasons.push(
          AICreateAssistantResponseFeedbackRequestBodyReasonsEnum.IDontKnowWhatThisMeans,
        );
      }
      if (feedbackFormData.too_slow) {
        reasons.push(
          AICreateAssistantResponseFeedbackRequestBodyReasonsEnum.ThisResponseIsTooSlow,
        );
      }

      await apiClient.aICreateAssistantResponseFeedback({
        createAssistantResponseFeedbackRequestBody: {
          question_id: feedbackFormData.question_id,
          feedback: feedbackFormData.feedback,
          feedback_type: feedbackFormData.feedback_type,
          reasons: reasons,
        },
      });
    },
    {
      onSuccess: () => {
        onFeedbackGiven();
      },
      invalidate: [],
    },
  );

  const formMethods = useForm<FeedbackFormData>({
    defaultValues: {
      question_id: questionId,
      feedback: "",
      feedback_type:
        AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum.Negative,
      calculation_incorrect: false,
      graph_incorrect: false,
      unhelpful: false,
      clueless: false,
      too_slow: false,
      other: false,
    },
  });

  const reasons = formMethods.watch([
    "calculation_incorrect",
    "graph_incorrect",
    "unhelpful",
    "clueless",
    "too_slow",
    "other",
  ]);
  const atLeastOneReasonSelected = reasons.some((reason) => reason);

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      onClose={onClose}
      contentClassName="space-y-2"
      analyticsTrackingId="give-assistant-feedback"
      title={"Provide feedback"}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText="Send"
        />
      }
    >
      <CheckboxV2
        formMethods={formMethods}
        name="calculation_incorrect"
        label={"This calculation is incorrect"}
      />
      <CheckboxV2
        formMethods={formMethods}
        name="graph_incorrect"
        label={"This graph is incorrect"}
      />
      <CheckboxV2
        formMethods={formMethods}
        name="unhelpful"
        label={"This response isn't helpful"}
      />
      <CheckboxV2
        formMethods={formMethods}
        name="clueless"
        label={"I don't know what this means"}
      />
      <CheckboxV2
        formMethods={formMethods}
        name="too_slow"
        label={"The response is too slow"}
      />
      <CheckboxV2 formMethods={formMethods} name="other" label={"Other"} />
      {atLeastOneReasonSelected && (
        <TextareaV2
          formMethods={formMethods}
          name="feedback"
          type={InputType.Text}
          placeholder="What was the issue with the response? How could it be improved?"
          autoFocus
        />
      )}
    </Form.Modal>
  );
};

export async function sendPositiveFeedback(
  apiClient: ClientType,
  question_id: string,
): Promise<void> {
  try {
    await apiClient.aICreateAssistantResponseFeedback({
      createAssistantResponseFeedbackRequestBody: {
        question_id: question_id,
        feedback_type:
          AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum.Positive,
      },
    });
  } catch (e) {
    // PINC-1577: We should handle error better.
    console.error(e);
  }
}
