import { CallRoute } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { FormInputWrapper } from "@incident-shared/forms/v2/helpers";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/react";
import { parsePhoneNumber } from "libphonenumber-js";
import { compact } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import {
  CallRoutingUpdateRequestBody,
  EscalationPathNodePayload,
  EscalationPathNodePayloadTypeEnum,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
  UserOptionStateEnum,
} from "../../contexts/ClientContext";
import { useAPI, useAPIMutation } from "../../utils/swr";
import { escalationPathFormDataTargetsToPayload } from "../escalation-paths/common/marshall";
import { useHydratedTargets } from "../escalation-paths/common/options";
import {
  EscalationPathTargetFormData,
  EscalationPathUserTargetFormData,
} from "../escalation-paths/common/types";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { isOnCallUser } from "../settings/users/users/utils";
import { PathEditor } from "./PathEditor";

export type UpdateConfigFormData = Pick<
  CallRoutingUpdateRequestBody,
  "name" | "user_ids_to_promote"
> & {
  levels: { id: string; targets: EscalationPathTargetFormData[] }[];
  phone_number?: string; // phone_number is only used for display purposes
};

export const CallRoutingEditRoute = () => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/on-call/call-routes");
  // Grab the ID from the route
  const { id } = useParams<{ id?: string }>();

  if (!id) {
    return <OrgAwareNavigate to="/on-call/call-routes" />;
  }

  return <CallRouteEditDrawer onClose={onClose} id={id} />;
};

const CallRouteEditDrawer = ({
  id,
  onClose,
}: {
  id: string;
  onClose: () => void;
}) => {
  const {
    data,
    isLoading,
    error: listError,
  } = useAPI("callRoutingList", undefined, {
    fallbackData: { call_routes: [] },
  });

  const configs = data.call_routes;
  const config = configs.find((config) => config.id === id);
  const { targets, targetsLoading, targetsError, revalidateTargets } =
    useHydratedTargets(config?.path);

  if (isLoading || targetsLoading) {
    return <DrawerContentsLoading />;
  }

  if (!config || config.path.length === 0) {
    return <GenericErrorMessage error={new Error("Config not found")} />;
  }

  if (listError) {
    return <GenericErrorMessage error={listError} />;
  }

  if (targetsError) {
    return <GenericErrorMessage error={targetsError} />;
  }

  const formData: UpdateConfigFormData = {
    name: config.name,
    levels: compact(
      config.path.map((node) => {
        if (!node.level) {
          captureException(
            new Error("Call route node is not level: that's dodgy"),
          );
          return null;
        }

        const levelTargets = compact(
          node.level.targets.map(
            (target): EscalationPathTargetFormData | null => {
              const hydratedTarget = targets.find((t) => t.value === target.id);
              if (!hydratedTarget) {
                return null;
              }

              switch (hydratedTarget.type) {
                case EscalationPathTargetTypeEnum.SlackChannel:
                  // Banned!
                  return null;
                case EscalationPathTargetTypeEnum.User:
                  return {
                    ...hydratedTarget,
                    state:
                      hydratedTarget.state as unknown as UserOptionStateEnum,
                    type: EscalationPathTargetTypeEnum.User,
                  };
                case EscalationPathTargetTypeEnum.Schedule:
                  return {
                    ...hydratedTarget,
                    type: EscalationPathTargetTypeEnum.Schedule,
                    schedule_mode: target.schedule_mode,
                    selected_rota_id: target.selected_rota_id,
                  };
                default:
                  return null;
              }
            },
          ),
        );

        return { id: node.id, targets: levelTargets };
      }),
    ),
  };

  return (
    <Drawer onClose={onClose} width={"medium"}>
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.IncomingPhoneCall}
          color={ColorPaletteEnum.Slate}
          title="Edit call route"
          onClose={onClose}
        />
        <CallRouteEditForm
          config={config}
          initialFormData={formData}
          revalidateTargets={revalidateTargets}
          onClose={onClose}
        />
      </DrawerContents>
    </Drawer>
  );
};

const CallRouteEditForm = ({
  onClose,
  config,
  initialFormData,
  revalidateTargets,
}: {
  onClose: () => void;
  config: CallRoute;
  initialFormData: UpdateConfigFormData;
  revalidateTargets: () => Promise<void>;
}) => {
  const formMethods = useForm<UpdateConfigFormData>({
    defaultValues: {
      ...initialFormData,
      phone_number: config.phone_number
        ? parsePhoneNumber(config.phone_number)?.formatInternational()
        : undefined,
    },
  });
  const [promotionState, setPromotionState] =
    useState<PromotionState<UpdateConfigFormData>>(null);

  const {
    trigger: submit,
    isMutating: isPending,
    genericError,
  } = useAPIMutation(
    "callRoutingList",
    undefined,
    async (client, data: UpdateConfigFormData) => {
      await client.callRoutingUpdate({
        id: config.id,
        updateRequestBody: {
          ...data,
          path: data.levels.map(
            (level): EscalationPathNodePayload => ({
              id: level.id,
              type: EscalationPathNodePayloadTypeEnum.Level,
              level: {
                targets: level.targets.map((target) =>
                  escalationPathFormDataTargetsToPayload(
                    EscalationPathTargetUrgencyEnum.High,
                    target,
                  ),
                ),
                // These are fixed for call routes
                round_robin_config: {
                  rotate_after_seconds: 60,
                  enabled: true,
                },
                time_to_ack_seconds: 300,
              },
            }),
          ),
        },
      });

      // If users were promoted, we need to invalidate the SWR cache for the
      // user list so that the new on-call user state is fetched.
      if (data.user_ids_to_promote && data.user_ids_to_promote?.length > 0) {
        await revalidateTargets();
      }
    },
    {
      onSuccess: async () => {
        onClose();
      },
      setError: formMethods.setError,
    },
  );

  const saveOrPromoteUsers = async (data: UpdateConfigFormData) => {
    const usersToPromote = data.levels.flatMap((node) =>
      (node.targets || []).filter(
        (target) => target.type === "user" && !isOnCallUser(target.state),
      ),
    ) as EscalationPathUserTargetFormData[];

    if (usersToPromote.length > 0) {
      setPromotionState({
        formData: data,
        usersToPromote,
      });
      return;
    }

    await submit(data);
  };

  return (
    <>
      <OnCallPromotionConfirmationModal
        noun="call route"
        state={promotionState}
        onClose={() => setPromotionState(null)}
        onSubmit={(formData, userIdsToPromote) =>
          submit({
            ...formData,
            user_ids_to_promote: userIdsToPromote,
          })
        }
      />
      <DrawerBody className="flex-1 overflow-y-auto">
        <Form.Root
          onSubmit={saveOrPromoteUsers}
          id="edit-call-route-form"
          formMethods={formMethods}
          genericError={genericError}
          submitOnCmdEnter
          innerClassName="space-y-6"
        >
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            required
            helptext="For internal use only, this won't be shown to your customers"
          />
          {config.current_state === "active" ? (
            <InputV2
              name={"phone_number"}
              formMethods={formMethods}
              label={"Phone number"}
              disabled
              helptext="This is the number your customers will call to reach the people on this path."
            />
          ) : (
            <div>
              <Form.Label className="block" htmlFor={""}>
                Phone number
              </Form.Label>
              <Badge theme={BadgeTheme.Tertiary} className="mt-1">
                Pending
                <Tooltip
                  content={
                    <>
                      We&rsquo;re working on activating a phone number for you.
                      We&rsquo;ll be in touch once it&rsquo;s ready!
                    </>
                  }
                />
              </Badge>
            </div>
          )}
          <FormInputWrapper<UpdateConfigFormData>
            name="levels"
            label="Routing"
            helptext={
              <>When someone calls this number, where should we route to?</>
            }
          >
            <PathEditor formMethods={formMethods} />
          </FormInputWrapper>
        </Form.Root>
      </DrawerBody>

      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          disabled={isPending}
          loading={isPending}
          form="edit-call-route-form"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};
