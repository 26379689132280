import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Avatar,
  BadgeSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import { TableCell, TableRow } from "@incident-ui/Table/Table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import {
  IdentityUserBaseRoleSlugEnum,
  RBACRole,
  ScopeNameEnum,
  SeatDescriptionTypeEnum,
  UserWithRoles,
  UserWithRolesStateEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { UserSeatDropdown } from "./UserSeatDropdown";
import { isOnCallUser } from "./utils";

type Props = {
  user: UserWithRoles;
  actorRole: IdentityUserBaseRoleSlugEnum;
  availableRoles: RBACRole[];
  onSelect?: () => void;
  scimEnabled: boolean | undefined;
  scimDirectoryName: string | undefined;
  searchRoleSlugsTerm: string[];
  isLastRow: boolean;
  refetchUsers: () => Promise<void>;
};

// UserRoles lists the roles for a user, and truncates the list if there are too many.
const UserRoles = ({
  baseRole,
  customRoles,
  searchRoles,
}: {
  baseRole: RBACRole;
  customRoles: RBACRole[];
  searchRoles: string[];
}) => {
  let sortedByName = sortBy(customRoles, "name").reverse();

  // If we are searching for specific roles, bring them to the front and make them bold
  const customRoleMatches = sortedByName.filter((n) =>
    searchRoles.includes(n.slug),
  );
  if (searchRoles.length) {
    sortedByName = [
      ...customRoleMatches,
      ...sortedByName.filter((n) => !searchRoles.includes(n.slug)),
    ];
  }

  const baseRoleMatches = searchRoles.includes(baseRole.slug);

  // Convert the roles to their display names - we need to do this because a "User" role may be
  // displayed as either a "Responder" or "Viewer", depending on the seat type.
  const displayNames = sortedByName.map((r) => r.name);

  const baseRoleBold = baseRoleMatches ? "font-medium" : "";
  const firstCustomRoleBold = customRoleMatches.length > 0 ? "font-medium" : "";

  const [first, ...others] = displayNames;

  return (
    <div>
      <span className={tcx("capitalize", baseRoleBold)}>{baseRole.name}</span>
      {!!first && (
        <span className={tcx("capitalize", firstCustomRoleBold)}>
          , {first}{" "}
        </span>
      )}
      {!!others.length && (
        <span className="text-slate-600">
          and{" "}
          <Tooltip content={others.join(", ")}>
            <span>{others.length} more</span>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export const UserRow = ({
  user,
  onSelect,
  scimEnabled,
  scimDirectoryName,
  actorRole,
  availableRoles,
  searchRoleSlugsTerm,
  isLastRow,
  refetchUsers,
}: Props) => {
  const { hasScope } = useIdentity();
  const { onCallOnlyBillingSeats } = useFlags();

  // Disable editing the role for users with higher rank than the actor
  const rankFromSlug = (slug: IdentityUserBaseRoleSlugEnum) =>
    availableRoles.find((r) => r.slug === slug)?.rank ?? 0;
  const actorRoleRank = rankFromSlug(actorRole);
  const userHasHigherRank = user.base_role.rank > actorRoleRank;

  const canEditSeat = hasScope(ScopeNameEnum.BillingSettingsEdit);
  const canEditRole =
    hasScope(ScopeNameEnum.BaseRbacRolesEdit) && !userHasHigherRank;

  const canEdit = canEditSeat || canEditRole;

  return (
    <TableRow isLastRow={isLastRow}>
      <TableCell className={"flex flex-row items-center gap-2"}>
        <Avatar
          size={IconSize.Large}
          className={"!w-7 !h-7"}
          url={user.avatar_url}
          name={user.name}
        />
        <div>
          <div className={"flex flex-row flex-center-y"}>
            <Txt grey bold>
              {user.name}
            </Txt>
          </div>
          <div className={"text-xs text-content-tertiary"}>
            {user.email}{" "}
            {scimEnabled && !user.scim_user_id && (
              <Tooltip
                content={`This user was created via Slack or SAML and we've been unable to link them to an account in ${scimDirectoryName} using SCIM.`}
              >
                <span>
                  · Unlinked{" "}
                  <Icon
                    className={"inline text-content-tertiary"}
                    id={IconEnum.Info}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </TableCell>
      <TableCell>
        {onCallOnlyBillingSeats ? (
          <UserSeatDropdown
            refetchUsers={refetchUsers}
            user={user}
            seat={SeatDescriptionTypeEnum.OnCall}
          />
        ) : (
          isOnCallUser(user.state) && <Icon id={IconEnum.Tick} />
        )}
      </TableCell>
      <TableCell>
        {onCallOnlyBillingSeats ? (
          <UserSeatDropdown
            refetchUsers={refetchUsers}
            user={user}
            seat={SeatDescriptionTypeEnum.Responder}
          />
        ) : (
          <span
            className={"text-content-tertiary font-semibold text-xs uppercase"}
          >
            {user.state === UserWithRolesStateEnum.Viewer
              ? "viewer"
              : "responder"}
          </span>
        )}
      </TableCell>
      {/*</TableCell>*/}
      <TableCell>
        <UserRoles
          baseRole={user.base_role}
          customRoles={user.custom_roles}
          searchRoles={searchRoleSlugsTerm}
        />
      </TableCell>
      <TableCell className={"flex justify-end"}>
        {onSelect && (
          <GatedButton
            theme={ButtonTheme.Tertiary}
            icon={IconEnum.Edit}
            size={BadgeSize.Medium}
            title="Change permissions"
            iconProps={{ size: IconSize.Medium }}
            analyticsTrackingId="edit-user"
            onClick={() => onSelect()}
            disabled={!canEdit || (scimEnabled && onCallOnlyBillingSeats)}
            disabledTooltipContent={
              scimEnabled
                ? "Your organisation is using SCIM to configure roles."
                : "You don't have permission to edit this user"
            }
          />
        )}
      </TableCell>
    </TableRow>
  );
};
