// For when you don't have the right auth scopes and need to reinstall.

import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { useAuthRedirectPath } from "@incident-shared/utils/useAuthRedirectPath";
import {
  ContentBox,
  GenericErrorMessage,
  Heading,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import React from "react";
import { MSTeamsInstallFailure } from "src/components/msteams/InstallFailure";
import { useAPI } from "src/utils/swr";

export const ReinstallMsTeamsRoute = (): React.ReactElement => {
  return (
    <PageWrapper
      icon={IconEnum.MicrosoftTeams}
      color={ColorPaletteEnum.Slate}
      title="Reinstall incident.io"
      width={PageWidth.Narrow}
    >
      <Heading
        level={1}
        className="text-center mt-8 text-xl mb-4 font-medium text-content-primary"
      >
        Reinstall incident.io to Microsoft Teams
      </Heading>

      <ReinstallMSTeams />
    </PageWrapper>
  );
};

const ReinstallMSTeams = () => {
  const { path: redirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_install",
    "/~/reinstall-msteams",
  );

  const { path: upgradeRedirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_auth_short_lived_token",
    "/~/reinstall-msteams",
    "teams_bot_upgrade",
  );

  const { data, error } = useAPI("integrationsMicrosoftGetConfig", undefined);
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!data) {
    return <LoadingBar className="h-32" />;
  }

  const needsUpgrade = data.team_installs.some(
    (install) => install.needs_upgrade,
  );

  return (
    <>
      <ContentBox className="space-y-4 p-6 my-4">
        <MSTeamsInstallFailure />
        <GatedButton
          href={redirectUrl}
          analyticsTrackingId={"install-teams-app"}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          upgradeRequired={false}
          icon={IconEnum.Microsoft}
          className="mx-auto text-center"
        >
          Grant new permissions in Microsoft
        </GatedButton>
      </ContentBox>

      <ContentBox className="space-y-4 p-6 my-4">
        <GatedButton
          href={upgradeRedirectUrl}
          analyticsTrackingId={"upgrade-teams-app"}
          disabled={!needsUpgrade}
          disabledTooltipContent="You are already on the latest version."
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          upgradeRequired={false}
          icon={IconEnum.Microsoft}
          className="mx-auto text-center"
        >
          Upgrade Microsoft Teams App
        </GatedButton>
      </ContentBox>
    </>
  );
};
