import {
  SelectOption,
  TypeaheadsListTypeaheadTypeEnum,
} from "@incident-io/api";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  PopoverMultiSelect,
} from "@incident-ui";
import { useEffect, useState } from "react";
import { useUserTeams } from "src/components/settings/teams/common/useUserTeams";
import { useClient } from "src/contexts/ClientContext";
import {
  paramsAsObj,
  useQueryParams,
  useSafeUpdateQueryString,
} from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

export const TeamsFilter = ({
  selectedTeams,
  setSelectedTeams,
  disabled,
  disabledTooltipContent,
  defaultToCurrentUsersTeams,
}: {
  selectedTeams: SelectOption[];
  setSelectedTeams: (teams: SelectOption[]) => void;
  disabled?: boolean;
  disabledTooltipContent?: string;
  defaultToCurrentUsersTeams?: boolean;
}) => {
  const apiClient = useClient();
  const { userTeams } = useUserTeams();

  const updateQueryString = useSafeUpdateQueryString();
  const queryParams = useQueryParams();
  const params = paramsAsObj(queryParams);

  const [hasSetInitialFilters, setHasSetInitialFilters] = useState(false);

  const { data, isLoading, error } = useAPI("typeaheadsList", {
    typeaheadType: TypeaheadsListTypeaheadTypeEnum.Team,
  });

  // Initialize from URL or user's teams if needed
  useEffect(() => {
    if (selectedTeams.length === 0 && data && !hasSetInitialFilters) {
      const teamsParam = params["team_ids"] || null;

      const teamIDsToSelectOptions = (teamIDs: string[]) => {
        return teamIDs.map((id) => ({
          label: data.options.find((opt) => opt.value === id)?.label || "",
          value: id,
          sort_key: id,
        }));
      };

      if (teamsParam) {
        // If there are team IDs in the URL, set them as selected teams
        setSelectedTeams(teamIDsToSelectOptions(teamsParam.split(",")));
        setHasSetInitialFilters(true);
      } else if (
        !disabled &&
        defaultToCurrentUsersTeams &&
        userTeams.length > 0
      ) {
        // Otherwise, if the user has teams, set them as selected teams
        setSelectedTeams(teamIDsToSelectOptions(userTeams));
        setHasSetInitialFilters(true);
      }
    }
  }, [
    data,
    params,
    disabled,
    selectedTeams,
    setSelectedTeams,
    defaultToCurrentUsersTeams,
    userTeams,
    hasSetInitialFilters,
    setHasSetInitialFilters,
  ]);

  const onChange = (newSelectedTeams: SelectOption[]) => {
    setSelectedTeams(newSelectedTeams);

    // Update URL query params
    const newParams = new URLSearchParams(queryParams);
    const serialized = newSelectedTeams.map((team) => team.value).join(",");
    if (!serialized) {
      newParams.delete("team_ids");
    } else {
      newParams.set("team_ids", serialized);
    }
    updateQueryString(newParams.toString());
  };

  const { buttonLabel, tooltipContent } = generateButtonText({ selectedTeams });

  if (error) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <PopoverMultiSelect
      object={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loadOptions={getTypeaheadOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.Team,
      )}
      hydrateOptions={hydrateInitialSelectOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.Team,
      )}
      isSearchable
      icon={IconEnum.Team}
      onChange={onChange}
      value={selectedTeams}
      tooltipContent={disabledTooltipContent ?? tooltipContent}
      disabled={disabled}
      renderTriggerNode={({ onClick }) => (
        <Button
          analyticsTrackingId={"teams-filter-trigger"}
          theme={ButtonTheme.Tertiary}
          size={BadgeSize.Medium}
          icon={IconEnum.Team}
          className="text-sm-normal"
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      )}
    />
  );
};

const generateButtonText = ({
  selectedTeams,
}: {
  selectedTeams: SelectOption[];
}): {
  buttonLabel: string;
  tooltipContent?: string;
} => {
  if (selectedTeams.length === 0) {
    return { buttonLabel: "All teams", tooltipContent: undefined };
  }

  if (selectedTeams.length === 1) {
    return {
      buttonLabel: selectedTeams[0].label,
      tooltipContent: undefined,
    };
  }

  if (selectedTeams.length === 2) {
    return {
      buttonLabel: `${selectedTeams[0].label}, ${selectedTeams[1].label}`,
      tooltipContent: undefined,
    };
  }

  return {
    buttonLabel: `${selectedTeams.length} teams`,
    tooltipContent: selectedTeams.map((team) => team.label).join(", "),
  };
};
