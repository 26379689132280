import {
  AIStaffUpdateEvaluationBacktestRequestBody,
  EvaluationBacktest,
  EvaluationBacktestBacktestTypeEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import {
  payloadToTuneables,
  TuneableParameterEditorV2,
  TuneablesOverrideFormValue,
  tuneablesToPayload,
} from "../investigations/TuneableParameterEditorV2";
import {
  ModelOverridesFormValue,
  PromptModelOverrideEditorV2,
} from "./PromptModelOverrideEditorV2";

type FormData = {
  notes: string;
  model_overrides: ModelOverridesFormValue;
  tuneables_overrides: TuneablesOverrideFormValue;
  compare_backtest_id?: string;
};

export const BacktestUpdateModal = ({
  backtest,
  onClose,
}: {
  backtest: EvaluationBacktest;
  onClose: () => void;
}) => {
  // Fetch all backtests to allow selecting one for comparison
  const { data: backtestsData, isLoading: isBacktestsLoading } = useAPI(
    "aIStaffListEvaluationBacktests",
    {},
    {
      fallbackData: { backtests: [] },
    },
  );

  // Filter backtests by the current backtest type for comparison
  const filteredBacktests = useMemo(() => {
    if (!backtestsData?.backtests) {
      return [];
    }

    // Filter to only show backtests with the same type, and exclude the current backtest
    return backtestsData.backtests.filter(
      (bt) =>
        bt.backtest_type === backtest.backtest_type && bt.id !== backtest.id,
    );
  }, [backtestsData?.backtests, backtest.backtest_type, backtest.id]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      notes: backtest.notes || "",
      model_overrides: backtest.model_overrides || {},
      tuneables_overrides: payloadToTuneables(
        backtest.tuneables_overrides || {},
      ),
      compare_backtest_id: backtest.compare_with_backtest_id,
    },
  });

  const showToast = useToast();

  const { trigger: updateBacktest, isMutating: isUpdating } = useMutationV2(
    async (apiClient, data: AIStaffUpdateEvaluationBacktestRequestBody) => {
      await apiClient.aIStaffUpdateEvaluationBacktest({
        id: backtest.id,
        updateEvaluationBacktestRequestBody: data,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationBacktest", { id: backtest.id }),
      ],
      onSuccess: () => {
        showToast({
          title: "Backtest updated",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: (error) => {
        showToast({
          title: "Failed to update backtest",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    },
  );

  const onSubmit = async (data: FormData) => {
    const requestBody: AIStaffUpdateEvaluationBacktestRequestBody = {
      notes: data.notes,
      model_overrides: data.model_overrides,
      tuneables_overrides: tuneablesToPayload(data.tuneables_overrides),
      compare_with_backtest_id: data.compare_backtest_id,
    };

    return updateBacktest(requestBody);
  };

  const isInvestigation =
    backtest.backtest_type === EvaluationBacktestBacktestTypeEnum.Investigation;

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Update backtest"
      onClose={onClose}
      analyticsTrackingId="update-backtest"
      disableQuickClose
      onSubmit={(data: FormData) => {
        onSubmit(data);
      }}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={"Update backtest"}
          analyticsTrackingId="update-backtest-submit"
          saving={isUpdating}
        />
      }
    >
      <TextareaV2
        formMethods={formMethods}
        name="notes"
        label="Notes"
        helptext="Any notes you want to attach to this backtest."
      />

      {filteredBacktests.length > 0 && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="compare_backtest_id"
          label="Compare with backtest"
          helptext="Optionally select another backtest with the same type to compare results."
          isLoading={isBacktestsLoading}
          options={[
            { label: "None", value: "" },
            ...filteredBacktests.map((bt) => ({
              label: `${new Date(bt.created_at).toLocaleString()} (${
                bt.notes || "No notes"
              })`,
              value: bt.id,
            })),
          ]}
        />
      )}

      <PromptModelOverrideEditorV2<FormData>
        formMethods={formMethods}
        name="model_overrides"
        label="Model Overrides"
        helptext="Configure which AI models to use for specific prompts in this backtest."
      />
      {isInvestigation && (
        <TuneableParameterEditorV2
          formMethods={formMethods}
          name="tuneables_overrides"
        />
      )}
    </Form.Modal>
  );
};
