import { User } from "@incident-io/api";
import { Avatar, IconSize } from "@incident-ui";

import { StaffRoomOperation, StaffRoomOperationProps } from "../Operation";

export const ViewSlackOwnersOperation: StaffRoomOperation = {
  id: "view-slack-owners",
  title: "View Slack owners",
  emoji: "💬",
  description: "See who the owners of this customer's Slack workspace are.",
  drawerWidth: "medium",
  Component: ({ organisation }: StaffRoomOperationProps) => {
    return (
      <div className="flex flex-col gap-2">
        {(organisation.slack_owners ?? []).map((user, i) => {
          return <SlackUser key={`user-${i}`} user={user} />;
        })}
      </div>
    );
  },
};

const SlackUser = ({ user }: { user: User }) => {
  return (
    <div className="flex gap-1 items-center">
      <Avatar size={IconSize.Large} name={user.name} url={user.avatar_url} />
      <div>{user.name}</div>
      <div>({user.email})</div>
    </div>
  );
};
