import { Incident, IncidentVisibilityEnum } from "@incident-io/api";
import { FollowUpCreateModal } from "@incident-shared/follow-ups";
import { BadgeSize, Button, IconEnum, LoadingBar } from "@incident-ui";
import { useState } from "react";
import { useQueryParams } from "src/utils/query-params";

import { SectionWrapper } from "../body/SectionWrapper";
import { FollowUpsList } from "../followups/FollowUpsList";
import { useFollowUps, useSuggestedFollowUps } from "../hooks";

type PostmortemFollowupsProps = {
  id: string;
  incident: Incident;
  topLevelHeader?: boolean;
};

export const PostmortemFollowups = ({
  id,
  incident,
  topLevelHeader,
}: PostmortemFollowupsProps) => {
  const urlParams = useQueryParams();

  const { followUps, isLoading } = useFollowUps(incident.id);
  const { suggestedFollowUps, isLoading: isLoadingSuggestions } =
    useSuggestedFollowUps(incident.id);

  const [showCreateFollowUpModal, setShowCreateFollowUpModal] = useState(false);

  const editingFollowUpID = urlParams.get("follow_up_id") ?? null;
  const editingFollowUp = followUps.find(
    (followUp) => followUp.id === editingFollowUpID,
  );

  const showFollowUps = followUps.length > 0 || suggestedFollowUps.length > 0;

  return (
    <SectionWrapper
      sectionName="Follow-ups"
      id={id}
      topLevelHeader={topLevelHeader}
      headerAccessory={
        <Button
          icon={IconEnum.Add}
          analyticsTrackingId="new-follow-up"
          onClick={() => setShowCreateFollowUpModal(true)}
          size={BadgeSize.Medium}
        >
          Add follow-up
        </Button>
      }
    >
      {isLoading || isLoadingSuggestions || !incident ? (
        <LoadingBar className="mt-4 h-18" />
      ) : (
        showFollowUps && (
          <FollowUpsList
            incident={incident}
            editingFollowUp={editingFollowUp}
            followUps={followUps}
            followUpSuggestions={suggestedFollowUps}
          />
        )
      )}
      {showCreateFollowUpModal && (
        <FollowUpCreateModal
          incidentId={incident.id}
          onClose={() => setShowCreateFollowUpModal(false)}
          isPrivateIncident={
            incident.visibility === IncidentVisibilityEnum.Private
          }
        />
      )}
    </SectionWrapper>
  );
};
