import { StaffPermissionIdEnum as StaffPermissionEnum } from "@incident-io/api";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export { StaffPermissionEnum };

export const useCheckStaffPermissions = (
  permissionID?: StaffPermissionEnum,
  alwaysDisabled?: boolean,
): { disabled?: boolean; disabledTooltipContent?: React.ReactNode } => {
  const { identity } = useIdentity();
  const {
    data: { permissions: allPermissions },
  } = useAPI(
    "staffListStaffPermissions",
    {},
    { fallbackData: { permissions: [] } },
  );

  if (!identity || !permissionID) {
    return {};
  }

  const permissions = identity.staff_permissions || [];

  if (permissions.map(({ id }) => id).includes(permissionID)) {
    return alwaysDisabled ? { disabled: true } : {};
  }

  const permission = allPermissions.find(({ id }) => id === permissionID);

  return {
    disabled: true,
    disabledTooltipContent: `You need the '${
      permission?.name ?? permissionID
    }' permission to do this`,
  };
};
