import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ConfirmationDialog } from "@incident-ui";
import { useState } from "react";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const MakeDemoButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { trigger } = useMutationV2(
    async (apiClient) => {
      await apiClient.staffMakeDemo({
        organisationSlug: data.organisation_slug,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],
    },
  );

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.MarkSandboxAndDemo,
  );

  let disabledReason: React.ReactNode | undefined = undefined;
  if (disabledProps.disabled) {
    disabledReason = disabledProps.disabledTooltipContent;
  } else if (data.stripe_subscription_id !== undefined) {
    disabledReason = "Organisation is paying";
  } else if (data.is_sandbox) {
    disabledReason = "Organisation is already a sandbox";
  } else if (data.is_demo) {
    disabledReason = "Organisation is already a demo org";
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => {
          setConfirmOpen(true);
        }}
        disabled={disabledReason !== undefined}
        disabledTooltipContent={disabledReason}
      >
        🪄 Make Demo
      </GatedButton>
      {confirmOpen && (
        <ConfirmationDialog
          analyticsTrackingId={null}
          isOpen
          title={`Make ${data.organisation_name} a demo organisation`}
          onConfirm={async () => {
            await trigger({});
            setConfirmOpen(false);
          }}
          onCancel={() => setConfirmOpen(false)}
        >
          Are you sure you want to make this organisation a demo org?
        </ConfirmationDialog>
      )}
    </>
  );
};
