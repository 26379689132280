/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AICheckResult
 */
export interface AICheckResult {
    /**
     * Optional numerical grade for the check
     * @type {number}
     * @memberof AICheckResult
     */
    grade?: number;
    /**
     * Name of the check that was run
     * @type {string}
     * @memberof AICheckResult
     */
    name: string;
    /**
     * Whether the check passed
     * @type {boolean}
     * @memberof AICheckResult
     */
    pass: boolean;
    /**
     * Explanation of why the check passed or failed
     * @type {string}
     * @memberof AICheckResult
     */
    reasoning: string;
}

export function AICheckResultFromJSON(json: any): AICheckResult {
    return AICheckResultFromJSONTyped(json, false);
}

export function AICheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AICheckResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'name': json['name'],
        'pass': json['pass'],
        'reasoning': json['reasoning'],
    };
}

export function AICheckResultToJSON(value?: AICheckResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grade': value.grade,
        'name': value.name,
        'pass': value.pass,
        'reasoning': value.reasoning,
    };
}

