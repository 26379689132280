import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconSize,
} from "@incident-ui";
import { sortBy } from "lodash";
import { useAPI } from "src/utils/swr";

export const InternalStatusPageLinkButton = (): React.ReactElement | null => {
  const { data, isLoading } = useAPI("internalStatusPageListPages", undefined);
  const navigate = useOrgAwareNavigate();

  if (isLoading || !data || data?.internal_status_pages?.length === 0) {
    return null;
  }

  if (data.internal_status_pages.length === 1) {
    return (
      <Button
        href={"/status"}
        theme={ButtonTheme.Secondary}
        analyticsTrackingId={"visit-isp-from-dashboard"}
      >
        View internal status
      </Button>
    );
  }

  const internalStatusPages = sortBy(data.internal_status_pages, "name");

  return (
    <DropdownMenu
      triggerButton={
        <Button
          theme={ButtonTheme.Secondary}
          analyticsTrackingId="open-dashboard-isp-dropdown"
        >
          View internal status
        </Button>
      }
    >
      {internalStatusPages.map((page) => (
        <DropdownMenuItem
          icon={
            page.has_ongoing_incident
              ? COMPONENT_STATUS_CONFIG.partial_outage.icon
              : COMPONENT_STATUS_CONFIG.operational.icon
          }
          iconProps={{
            className: page.has_ongoing_incident
              ? COMPONENT_STATUS_CONFIG.partial_outage.colour
              : COMPONENT_STATUS_CONFIG.operational.colour,
            size: IconSize.Medium,
          }}
          key={page.id}
          onSelect={() => {
            navigate(`/status/${page.subpath}`);
          }}
          analyticsTrackingId={"visit-isp-from-dashboard-dropdown"}
          label={page.name}
        />
      ))}
    </DropdownMenu>
  );
};
