import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { Button, GenericErrorMessage, LoadingBar } from "@incident-ui";
import { useNavigate, useParams } from "react-router";
import { DisableOnCallButton } from "src/components/staff-room/DisableOnCallButton";
import { useAPI } from "src/utils/swr";

import { ArchiveButton } from "./ArchiveButton";
import { AttachSubscriptionButton } from "./AttachSubscriptionForm";
import { BlockArchivingButton } from "./BlockArchivingButton";
import { Section } from "./common/Section";
import { DisableButton } from "./DisableButton";
import { DisableResponseButton } from "./DisableResponseButton";
import { EnableOnCallButton } from "./EnableOnCallButton";
import { EnableResponseButton } from "./EnableResponseButton";
import { GrantOwnerButton } from "./GrantOwnerButton";
import { LinksDropdown } from "./LinksDropdown";
import { MakeDemoButton } from "./MakeDemoButton";
import { MakeSandboxButton } from "./MakeSandboxButton";
import { ViewSubscriptionDetailsOperation } from "./operations/billing/ViewSubscriptionDetailsOperation";
import { ConfigureCallRouteOperation } from "./operations/call-routes/ConfigureCallRouteOperation";
import { UpdateFeatureGatesOperation } from "./operations/feature-gates/UpdateFeatureGatesOperation";
import { Operation } from "./operations/Operation";
import { ViewSlackOwnersOperation } from "./operations/owners/ViewSlackOwnersOperation";
import { ImportStatusPageSubscribers } from "./operations/status-page-subscribers/ImportStatusPageSubscribers";
import { EnterprisePanel } from "./panels/EnterprisePanel";
import { OverviewPanel } from "./panels/OverviewPanel";
import { PlanPanel } from "./panels/PlanPanel";
import { UserCountsPanel } from "./panels/UserCountsPanel";
import { PlanSettingsButton } from "./PlanSettingsForm";
import { RenameOrganisation } from "./RenameOrganisation";
import { TrialManagementButton } from "./TrialManagement";

export const OrganisationPage = () => {
  const { targetSlug } = useParams();
  if (!targetSlug) {
    throw new Error("No organisation slug provided");
  }

  const { data, error } = useAPI("staffShowOrganisation", {
    organisationSlug: targetSlug,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!data) {
    return <LoadingBar className="h-16" />;
  }

  return (
    <>
      <Header data={data} />
      <div className="flex flex-col gap-8 md:flex-row w-full mt-6">
        <div className="md:grow">
          <div className="flex flex-col gap-4">
            <Section title="Feature access">
              <Operation op={UpdateFeatureGatesOperation} organisation={data} />
              <EnableOnCallButton data={data} />
              <EnableResponseButton data={data} />
            </Section>
            <Section title="Subscription and billing">
              <PlanSettingsButton data={data} />
              <AttachSubscriptionButton data={data} />
              <Operation
                op={ViewSubscriptionDetailsOperation}
                organisation={data}
              />
            </Section>
            <Section title="Status pages">
              <Operation op={ImportStatusPageSubscribers} organisation={data} />
            </Section>
            <Section title="On-call">
              <Operation op={ConfigureCallRouteOperation} organisation={data} />
            </Section>
            <Section title="Sandbox, demo and trials">
              <MakeSandboxButton data={data} />
              <MakeDemoButton data={data} />
              <TrialManagementButton data={data} />
            </Section>
            <Section title="Owners">
              <GrantOwnerButton data={data} />
              <Operation op={ViewSlackOwnersOperation} organisation={data} />
            </Section>
            <Section
              title="Auto-archiving"
              description="Organisations are automatically archived after 1 year of inactivity, or 60 days if they never create an incident or ack an escalation."
            >
              <AutoArchivingDetails data={data} />
            </Section>
            <Section title="Danger zone" className="bg-red-100">
              <DisableButton data={data} />
              <ArchiveButton data={data} />
              <DisableOnCallButton data={data} />
              <DisableResponseButton data={data} />
            </Section>
          </div>
        </div>
        <div className="md:w-[500px] relative">
          <div className="flex flex-col gap-4 sticky top-6">
            <OverviewPanel data={data} />
            <PlanPanel data={data} />
            <UserCountsPanel data={data} />
            <EnterprisePanel data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

const Header = ({ data }: { data: StaffShowOrganisationResponseBody }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/${data.organisation_slug}/dashboard`);
  };

  return (
    <div className="flex items-center gap-2">
      <div className="grow">
        <RenameOrganisation data={data} />
      </div>
      <LinksDropdown data={data} />
      <Button onClick={handleOnClick} analyticsTrackingId={null}>
        🫣 Impersonate
      </Button>
    </div>
  );
};

const AutoArchivingDetails = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <>
      {data.archive_info?.dont_warn_until && (
        <div>
          This org will not be archived until after{" "}
          {new Date(data.archive_info.dont_warn_until).toLocaleDateString()}
        </div>
      )}
      {data.archive_info?.pre_archive_warning_sent_at && (
        <div>
          A pre-archive warning was sent at{" "}
          {new Date(
            data.archive_info.pre_archive_warning_sent_at,
          ).toLocaleString()}
        </div>
      )}
      <BlockArchivingButton data={data} />
    </>
  );
};
