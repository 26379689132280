import { Callout, CalloutTheme, ModalFooter, Txt } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  FollowUp,
  FollowUpsUpdateRequestBodyStatusEnum,
} from "src/contexts/ClientContext";
import { useMutationV2 } from "src/utils/swr";

// We have no formData here, but utilise the same react-hook-form methods as elsewhere, just make the form data {}
type FormData = Record<string, never>;

export const BulkStateChangeModal = ({
  selectedFollowUpIDs: followUpIDs,
  followUps,
  targetStatus,
  onClose,
  onCancel,
}: {
  selectedFollowUpIDs: string[];
  followUps: FollowUp[];
  targetStatus: FollowUpsUpdateRequestBodyStatusEnum;
  onClose: () => void;
  onCancel: () => void;
}): React.ReactElement => {
  const selectedFollowUps = followUps.filter((followUp) => {
    if (!followUpIDs.includes(followUp.id)) {
      return false;
    }
    // Only assign follow-ups that aren't exported
    return followUp.external_issue_reference === undefined;
  });

  // We ignore the ones that are exported.
  const numFollowUpsToIgnore = followUpIDs.length - selectedFollowUps.length;

  const formMethods = useForm<FormData>();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError: genericError,
  } = useMutationV2(
    async (apiClient, _: FormData) => {
      return Promise.all(
        selectedFollowUps.map((followUp) => {
          return apiClient.followUpsUpdate({
            id: followUp.id,
            updateRequestBody: {
              ...followUp,
              assignee_id: followUp.assignee?.id,
              status: targetStatus,
            },
          });
        }),
      );
    },
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Could not update all follow-ups",
      invalidate: [],
    },
  );

  let statusLabel;

  switch (targetStatus) {
    case FollowUpsUpdateRequestBodyStatusEnum.Outstanding:
      statusLabel = "open";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.Deleted:
      statusLabel = "deleted";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.Completed:
      statusLabel = "completed";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.NotDoing:
      statusLabel = "not doing";
      break;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={`Mark follow-ups as ${statusLabel}`}
      analyticsTrackingId="follow-ups-assign"
      onClose={onCancel}
      footer={
        <ModalFooter
          hideConfirmButton={selectedFollowUps.length === 0}
          confirmButtonText="Save"
          saving={saving}
          onClose={onCancel}
          confirmButtonType="submit"
        />
      }
    >
      {selectedFollowUps.length === 0 ? (
        <div className="space-y-4">
          <Txt>
            You have selected {numFollowUpsToIgnore} follow-up
            {numFollowUpsToIgnore === 1 ? "" : "s"} that have been exported. To
            change the status of those follow-ups, you&apos;ll need to edit them
            directly in your issue tracker.
          </Txt>
        </div>
      ) : (
        <div className="space-y-4">
          {/* Ignoring 1 follow-up */}
          {numFollowUpsToIgnore === 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected 1 follow-up that is has been exported.
              We&apos;ll only update unexported follow-ups.
            </Callout>
          )}
          {/* Ignoring multiple follow-ups */}
          {numFollowUpsToIgnore > 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected {numFollowUpsToIgnore} follow-ups that have
              been exported. We&apos;ll only update unexported follow-ups.
            </Callout>
          )}
          <Txt>
            We&apos;ll change the status of these {selectedFollowUps.length}{" "}
            follow-up
            {selectedFollowUps.length === 1 ? "" : "s"} to {statusLabel}
          </Txt>
        </div>
      )}
    </Form.Modal>
  );
};
