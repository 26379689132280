/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentAtlassianWidgetStatusResponseBodyStatus
 */
export interface StatusPageContentAtlassianWidgetStatusResponseBodyStatus {
    /**
     * Human-readable description of the current status
     * @type {string}
     * @memberof StatusPageContentAtlassianWidgetStatusResponseBodyStatus
     */
    description: string;
    /**
     * Current status indicator level
     * @type {string}
     * @memberof StatusPageContentAtlassianWidgetStatusResponseBodyStatus
     */
    indicator: StatusPageContentAtlassianWidgetStatusResponseBodyStatusIndicatorEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentAtlassianWidgetStatusResponseBodyStatusIndicatorEnum {
    None = 'none',
    Minor = 'minor',
    Major = 'major',
    Critical = 'critical'
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSON(json: any): StatusPageContentAtlassianWidgetStatusResponseBodyStatus {
    return StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSONTyped(json, false);
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentAtlassianWidgetStatusResponseBodyStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'indicator': json['indicator'],
    };
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyStatusToJSON(value?: StatusPageContentAtlassianWidgetStatusResponseBodyStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'indicator': value.indicator,
    };
}

