/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormFieldOperator,
    FormFieldOperatorFromJSON,
    FormFieldOperatorFromJSONTyped,
    FormFieldOperatorToJSON,
} from './FormFieldOperator';

/**
 * 
 * @export
 * @interface FormField
 */
export interface FormField {
    /**
     * String telling the UI which dropdown category to use for grouping fields within the filter modal.
     * @type {string}
     * @memberof FormField
     */
    category?: FormFieldCategoryEnum;
    /**
     * Non-zero integer telling the UI which the order rank for the filter's category to use for grouping fields within the filter modal.
     * @type {number}
     * @memberof FormField
     */
    category_order_rank: number;
    /**
     * Unique ID of the sub field, for fields such as roles or custom fields, where there are multiple unique fields.
     * @type {string}
     * @memberof FormField
     */
    field_id?: string;
    /**
     * String telling the UI what icon to use for this field
     * @type {string}
     * @memberof FormField
     */
    icon: FormFieldIconEnum;
    /**
     * Unique identifier for the filter field
     * @type {string}
     * @memberof FormField
     */
    key: string;
    /**
     * Human readable label for the filter field
     * @type {string}
     * @memberof FormField
     */
    label: string;
    /**
     * 
     * @type {Array<FormFieldOperator>}
     * @memberof FormField
     */
    operators: Array<FormFieldOperator>;
    /**
     * Whether or not this filter field is visible in the UI filter picker
     * @type {boolean}
     * @memberof FormField
     */
    show_in_ui: boolean;
    /**
     * The ID that should be used when doing typeahead lookups for this field
     * @type {string}
     * @memberof FormField
     */
    typeahead_lookup_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum FormFieldCategoryEnum {
    FollowUps = 'follow_ups',
    PostIncidentTasks = 'post_incident_tasks',
    Categorization = 'categorization',
    Timestamps = 'timestamps',
    Participants = 'participants',
    CustomFields = 'custom_fields',
    PostIncident = 'post_incident',
    Alerts = 'alerts',
    OnCall = 'on_call',
    GroundTruth = 'ground_truth',
    Other = 'other'
}/**
* @export
* @enum {string}
*/
export enum FormFieldIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentStatusDeclined = 'incident-status-declined',
    IncidentStatusMerged = 'incident-status-merged',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Sparkles = 'sparkles',
    SparklesAiGradient = 'sparkles-ai-gradient',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Team = 'team',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function FormFieldFromJSON(json: any): FormField {
    return FormFieldFromJSONTyped(json, false);
}

export function FormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'category_order_rank': json['category_order_rank'],
        'field_id': !exists(json, 'field_id') ? undefined : json['field_id'],
        'icon': json['icon'],
        'key': json['key'],
        'label': json['label'],
        'operators': ((json['operators'] as Array<any>).map(FormFieldOperatorFromJSON)),
        'show_in_ui': json['show_in_ui'],
        'typeahead_lookup_id': !exists(json, 'typeahead_lookup_id') ? undefined : json['typeahead_lookup_id'],
    };
}

export function FormFieldToJSON(value?: FormField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'category_order_rank': value.category_order_rank,
        'field_id': value.field_id,
        'icon': value.icon,
        'key': value.key,
        'label': value.label,
        'operators': ((value.operators as Array<any>).map(FormFieldOperatorToJSON)),
        'show_in_ui': value.show_in_ui,
        'typeahead_lookup_id': value.typeahead_lookup_id,
    };
}

