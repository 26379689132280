/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotSearch,
    CopilotSearchFromJSON,
    CopilotSearchFromJSONTyped,
    CopilotSearchToJSON,
} from './CopilotSearch';

/**
 * 
 * @export
 * @interface AIStaffListCopilotSearchesResponseBody
 */
export interface AIStaffListCopilotSearchesResponseBody {
    /**
     * List of search ground truths
     * @type {Array<CopilotSearch>}
     * @memberof AIStaffListCopilotSearchesResponseBody
     */
    searches: Array<CopilotSearch>;
}

export function AIStaffListCopilotSearchesResponseBodyFromJSON(json: any): AIStaffListCopilotSearchesResponseBody {
    return AIStaffListCopilotSearchesResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListCopilotSearchesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListCopilotSearchesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searches': ((json['searches'] as Array<any>).map(CopilotSearchFromJSON)),
    };
}

export function AIStaffListCopilotSearchesResponseBodyToJSON(value?: AIStaffListCopilotSearchesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searches': ((value.searches as Array<any>).map(CopilotSearchToJSON)),
    };
}

