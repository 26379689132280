import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useOutlet } from "react-router";
import graphic from "src/components/settings/banners/banner-ai.svg";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI } from "src/utils/swr";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { AIFeatureCard, AIFeatureCardGrid } from "./AIFeatureCard";
import { useAIFeatures } from "./useAIFeatures";

export const AIPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const canUseAI = identity?.feature_gates.ai_features;
  const drawer = useOutlet();

  return (
    <SettingsSubPageWrapper>
      <AnimatePresence>{drawer}</AnimatePresence>

      <SettingsHeading
        title="Activate AI features"
        subtitle="Enable AI to automate incident processes and allow responders to focus on critical tasks."
        planNameMustUpgradeTo="Team"
        requiresUpgrade={!identity?.feature_gates.ai_features}
        articleId={"9223930229"}
        graphic={<img src={graphic} className="h-40" />}
      />
      {canUseAI ? <AIPageInner /> : <></>}
    </SettingsSubPageWrapper>
  );
};

const AIPageInner = (): React.ReactElement => {
  const {
    data: configResp,
    isLoading: configLoading,
    error: configError,
  } = useAPI("aIShowConfig", undefined);

  const { settings } = useSettings();
  const { identity } = useIdentity();

  const {
    features,
    loading: featuresLoading,
    error: featuresError,
  } = useAIFeatures();

  if (
    !configResp ||
    configLoading ||
    !settings ||
    !identity ||
    featuresLoading
  ) {
    return <Loader />;
  }

  if (configError || featuresError) {
    return (
      <ContentBox className="p-4">
        <GenericErrorMessage error={configError} />
      </ContentBox>
    );
  }

  const cfg = configResp.config;

  return (
    <>
      {!cfg.openai_subprocessor_enabled && (
        <Callout theme={CalloutTheme.Danger}>
          You have opted out of all AI features. If you&apos;d like to change
          this decision, please{" "}
          <Button
            analyticsTrackingId={"openai-consent"}
            theme={ButtonTheme.Link}
            className="underline"
            href="mailto:support@incident.io"
          >
            talk to us
          </Button>
          .
        </Callout>
      )}

      <AIFeatureCardGrid>
        {features.map((feature) => (
          <AIFeatureCard
            key={feature.slug}
            to={feature.slug}
            disabled={!feature.enabled || !cfg.openai_subprocessor_enabled}
            title={feature.title}
            description={feature.subtitle}
            icon={feature.icon}
          />
        ))}
      </AIFeatureCardGrid>
    </>
  );
};
