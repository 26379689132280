/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPreferenceManualEscalationProviderOption,
    UserPreferenceManualEscalationProviderOptionFromJSON,
    UserPreferenceManualEscalationProviderOptionFromJSONTyped,
    UserPreferenceManualEscalationProviderOptionToJSON,
} from './UserPreferenceManualEscalationProviderOption';

/**
 * 
 * @export
 * @interface UserPreferencesListManualEscalationProviderOptionsResponseBody
 */
export interface UserPreferencesListManualEscalationProviderOptionsResponseBody {
    /**
     * 
     * @type {Array<UserPreferenceManualEscalationProviderOption>}
     * @memberof UserPreferencesListManualEscalationProviderOptionsResponseBody
     */
    escalation_providers: Array<UserPreferenceManualEscalationProviderOption>;
}

export function UserPreferencesListManualEscalationProviderOptionsResponseBodyFromJSON(json: any): UserPreferencesListManualEscalationProviderOptionsResponseBody {
    return UserPreferencesListManualEscalationProviderOptionsResponseBodyFromJSONTyped(json, false);
}

export function UserPreferencesListManualEscalationProviderOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesListManualEscalationProviderOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_providers': ((json['escalation_providers'] as Array<any>).map(UserPreferenceManualEscalationProviderOptionFromJSON)),
    };
}

export function UserPreferencesListManualEscalationProviderOptionsResponseBodyToJSON(value?: UserPreferencesListManualEscalationProviderOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_providers': ((value.escalation_providers as Array<any>).map(UserPreferenceManualEscalationProviderOptionToJSON)),
    };
}

