/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface GroupingKey
 */
export interface GroupingKey {
    /**
     * 
     * @type {Reference}
     * @memberof GroupingKey
     */
    engine_info?: Reference;
    /**
     * A reference to a property of the alert to group on
     * @type {string}
     * @memberof GroupingKey
     */
    reference: string;
}

export function GroupingKeyFromJSON(json: any): GroupingKey {
    return GroupingKeyFromJSONTyped(json, false);
}

export function GroupingKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupingKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'engine_info': !exists(json, 'engine_info') ? undefined : ReferenceFromJSON(json['engine_info']),
        'reference': json['reference'],
    };
}

export function GroupingKeyToJSON(value?: GroupingKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'engine_info': ReferenceToJSON(value.engine_info),
        'reference': value.reference,
    };
}

