/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecardDimension,
    EvaluationScorecardDimensionFromJSON,
    EvaluationScorecardDimensionFromJSONTyped,
    EvaluationScorecardDimensionToJSON,
} from './EvaluationScorecardDimension';
import {
    EvaluationScorecardEvent,
    EvaluationScorecardEventFromJSON,
    EvaluationScorecardEventFromJSONTyped,
    EvaluationScorecardEventToJSON,
} from './EvaluationScorecardEvent';
import {
    EvaluationScorecardMetric,
    EvaluationScorecardMetricFromJSON,
    EvaluationScorecardMetricFromJSONTyped,
    EvaluationScorecardMetricToJSON,
} from './EvaluationScorecardMetric';

/**
 * Grade for a specific aspect of the entry
 * @export
 * @interface EvaluationScorecardGrade
 */
export interface EvaluationScorecardGrade {
    /**
     * Dimensions that we track against the events
     * @type {Array<EvaluationScorecardDimension>}
     * @memberof EvaluationScorecardGrade
     */
    dimensions: Array<EvaluationScorecardDimension>;
    /**
     * Individual events that were tracked for this grade
     * @type {Array<EvaluationScorecardEvent>}
     * @memberof EvaluationScorecardGrade
     */
    events: Array<EvaluationScorecardEvent>;
    /**
     * What grader powered this result
     * @type {string}
     * @memberof EvaluationScorecardGrade
     */
    grade_type: string;
    /**
     * Individual metric scores for this grade
     * @type {Array<EvaluationScorecardMetric>}
     * @memberof EvaluationScorecardGrade
     */
    metrics: Array<EvaluationScorecardMetric>;
    /**
     * Name of the grade
     * @type {string}
     * @memberof EvaluationScorecardGrade
     */
    name: string;
}

export function EvaluationScorecardGradeFromJSON(json: any): EvaluationScorecardGrade {
    return EvaluationScorecardGradeFromJSONTyped(json, false);
}

export function EvaluationScorecardGradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecardGrade {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimensions': ((json['dimensions'] as Array<any>).map(EvaluationScorecardDimensionFromJSON)),
        'events': ((json['events'] as Array<any>).map(EvaluationScorecardEventFromJSON)),
        'grade_type': json['grade_type'],
        'metrics': ((json['metrics'] as Array<any>).map(EvaluationScorecardMetricFromJSON)),
        'name': json['name'],
    };
}

export function EvaluationScorecardGradeToJSON(value?: EvaluationScorecardGrade | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimensions': ((value.dimensions as Array<any>).map(EvaluationScorecardDimensionToJSON)),
        'events': ((value.events as Array<any>).map(EvaluationScorecardEventToJSON)),
        'grade_type': value.grade_type,
        'metrics': ((value.metrics as Array<any>).map(EvaluationScorecardMetricToJSON)),
        'name': value.name,
    };
}

