import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import {
  Button,
  Callout,
  CalloutTheme,
  Loader,
  LoadingWrapper,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { useForm } from "react-hook-form";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";

import {
  AlertSourceConfig,
  AlertSourceSplunkOptions,
  AlertsShowSourceConfigResponseBody,
  Expression,
} from "../../../../../contexts/ClientContext";
import { publicApiUrl } from "../../../../../utils/environment";
import { useAPI, useMutationV2 } from "../../../../../utils/swr";
import { stripInvalidBindings } from "../../stripInvalidBindings";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

type SplunkFormValues = AlertSourceSplunkOptions;

export const AlertSourceSplunkSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const {
    data: sourceConfigData,
    isLoading,
    mutate: refreshSourceConfig,
  } = useAPI("alertsShowSourceConfig", {
    id: alertSourceConfig.id,
  });

  if (isLoading || !sourceConfigData) {
    return <Loader />;
  }

  return (
    <SplunkSetupInfoInner
      alertSourceConfig={sourceConfigData.alert_source_config}
      refreshSourceConfig={refreshSourceConfig}
    />
  );
};

const SplunkSetupInfoInner = ({
  alertSourceConfig,
  refreshSourceConfig,
}: {
  alertSourceConfig: AlertSourceConfig;
  refreshSourceConfig: () => Promise<
    AlertsShowSourceConfigResponseBody | undefined
  >;
}) => {
  const formMethods = useForm<SplunkFormValues>({
    defaultValues: {
      deduplication_using_search_name:
        alertSourceConfig.splunk_options?.deduplication_using_search_name ||
        false,
    },
  });

  const { trigger } = useMutationV2(
    async (apiClient, payload) => {
      const result = await apiClient.alertsUpdateSourceConfig({
        id: alertSourceConfig.id,
        updateSourceConfigRequestBody: {
          ...alertSourceConfig,
          template: stripInvalidBindings({
            expressions: alertSourceConfig.template.expressions.map((e) =>
              expressionToPayload(e as unknown as Expression),
            ),
            title: alertSourceConfig.template.title,
            description: alertSourceConfig.template.description,
            attributes: alertSourceConfig.template.attributes,
          }),
          splunk_options: {
            deduplication_using_search_name:
              payload.deduplication_using_search_name,
          },
        },
      });

      refreshSourceConfig();

      return result;
    },
    {
      invalidate: [],
    },
  );

  const { saving, hasSaved, setState } =
    useOptimisticAutoSave<SplunkFormValues>({
      initialState: {
        deduplication_using_search_name:
          alertSourceConfig.splunk_options?.deduplication_using_search_name ||
          false,
      },
      saveState: trigger,
    });

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={(values) => setState(values)}
    >
      <SetupInfoNumberedList>
        <p>
          <Callout theme={CalloutTheme.Info} className="mb-4">
            These instructions relate to <strong>Splunk Cloud</strong> but the
            approach is similar if you&rsquo;re using{" "}
            <strong>Splunk Enterprise</strong>.
          </Callout>
        </p>
        <p>
          Once this source has been saved, we&rsquo;ll automatically start
          receiving Splunk alerts.
        </p>
        <p>
          <div className={"flex flex-col w-full items-start"}>
            <LoadingWrapper loading={saving} large={false} isTransparent>
              <BooleanRadioButtonGroupV2
                formMethods={formMethods}
                name="deduplication_using_search_name"
                label="How should we differentiate between alerts?"
                boxed={true}
                onValueChange={() => {
                  setState(formMethods.getValues());
                }}
                srLabel="How should we differentiate between alerts?"
                labelClassName="text-content-secondary font-normal"
                trueOption={{
                  label: (
                    <p className="text-content-primary">Use the search name</p>
                  ),
                  description:
                    "Alerts will fire once per search name, only re-firing after that alert is resolved.",
                }}
                falseOption={{
                  label: (
                    <p className="text-content-primary">Use the search ID</p>
                  ),
                  description:
                    "Alerts will fire whenever a search alert is triggered, re-firing multiple times for the same search.",
                }}
              />
            </LoadingWrapper>
            <div className="mt-2 ml-2">
              <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
            </div>
          </div>
        </p>
        <div className="space-y-4">
          <p>
            If you don&rsquo;t have an existing alert, create one by navigating
            to the <strong>Search</strong> page, enter a search that you want to
            alert on and click <strong>Save As &rarr; Alert</strong>.
          </p>
          <p>
            If you have an existing alert, navigate to <strong>Alerts</strong>{" "}
            and click <strong>Edit</strong> on the alert you&rsquo;d like to
            connect.
          </p>
        </div>
        <SetupInfoSingleLineBlocks
          intro={
            <>
              Click <strong>Add actions +</strong> at the bottom of the alert
              configuration form, and add a <strong>Webhook</strong> action.
            </>
          }
          blocks={[
            {
              label: (
                <>
                  Enter the following in the <strong>URL</strong> input:
                </>
              ),
              title: "Webhook URL",
              code: `${publicApiUrl()}/v2/alert_events/splunk/${
                alertSourceConfig.id
              }`,
            },
          ]}
        />
        <p>
          Save the alert, trigger it, and the payload will appear on the right.
        </p>
        <div className="space-y-4">
          <p>
            If you&rsquo;re using a custom action to send a webhook,
            you&rsquo;ll need to provide an alert <code>sid</code> and an
            optional <code>metadata</code> object along with an{" "}
            <code>application/json</code> content type header. We&rsquo;ll parse
            the fields from the metadata object and make them available as alert
            attributes.
          </p>
          <CodeBlock
            title={"Custom action payload"}
            code={`{
  "sid": "<sid>",
  "search_name": "<search_name>",${
    formMethods.watch("deduplication_using_search_name")
      ? " // Will be used to determine if an alert is a duplicate"
      : ""
  }
  "metadata": {
    "custom_field": "..."
  }
}`}
          />
        </div>
      </SetupInfoNumberedList>
      <Button
        type="submit"
        analyticsTrackingId={"alert-source-splunk-setup-info-save"}
        disabled={saving}
      >
        Save
      </Button>
    </Form.Root>
  );
};
