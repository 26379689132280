import {
  Escalation,
  EscalationPreview,
  EscalationStatusEnum,
} from "@incident-io/api";
import { EscalationTarget } from "@incident-io/api";
import {
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import _, { capitalize } from "lodash";

import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../legacy/incident/sidebar/AvatarList";
import { ESCALATION_LABEL } from "../legacy/on-call/escalations/helpers";

export const EscalationTransitionIcon = ({
  status,
  size,
}: {
  status: EscalationStatusEnum;
  size: IconSize;
}) => {
  switch (status) {
    case EscalationStatusEnum.Pending:
      return <Icon id={IconEnum.Timer} className="text-blue-500" size={size} />;

    case EscalationStatusEnum.Triggered:
      return (
        <Icon id={IconEnum.Flag} className={"text-blue-500"} size={size} />
      );

    case EscalationStatusEnum.Expired:
      return (
        <Icon
          id={IconEnum.CloseCircle}
          className="text-slate-500"
          size={size}
        />
      );

    case EscalationStatusEnum.Cancelled:
      return (
        <Icon
          id={IconEnum.CloseCircle}
          className="text-slate-500"
          size={size}
        />
      );

    case EscalationStatusEnum.Acked:
      return (
        <Icon id={IconEnum.Checkmark} className="text-green-500" size={size} />
      );

    case EscalationStatusEnum.Resolved:
      return (
        <Icon id={IconEnum.Checkmark} className="text-green-500" size={size} />
      );
    default:
      return <></>;
  }
};

export const EscalationStackedListItem = ({
  escalation,
  hrefTarget,
  description,
  className,
  hrefInTitle,
}: {
  escalation: Pick<
    Escalation | EscalationPreview,
    "id" | "title" | "targets" | "status"
  >;
  hrefTarget?: string;
  description?: React.ReactNode;
  className?: string;
  hrefInTitle?: boolean;
}) => {
  const targets = escalation.targets?.filter(Boolean) ?? [];
  return (
    <StackedListItem
      iconNode={
        <Tooltip content={capitalize(ESCALATION_LABEL[escalation.status])}>
          <div>
            <EscalationTransitionIcon
              status={escalation.status as EscalationStatusEnum}
              size={IconSize.Medium}
            />
          </div>
        </Tooltip>
      }
      title={
        hrefInTitle ? (
          <OrgAwareLink
            to={`/on-call/escalations/${escalation.id}`}
            target={hrefTarget}
            className={"hover:underline"}
          >
            <span className="font-medium">{escalation.title}</span>
          </OrgAwareLink>
        ) : (
          <span className="font-medium">{escalation.title}</span>
        )
      }
      description={description}
      hrefTarget={hrefTarget}
      rowHref={
        hrefInTitle ? undefined : `/on-call/escalations/${escalation.id}`
      }
      className={className}
      accessory={
        <AvatarList
          modalTitle={"Escalated users"}
          maxToShow={3}
          avatarClassName="group-hover:border-surface-secondary"
          users={(
            _.uniqBy(targets, (t) => t.user_id || t.slack_channel_name) ?? []
          ).map(
            (t: EscalationTarget): MaybeUser => ({
              user: {
                id: t.id,
                name: t.slack_channel_name || t.user_name || "",
                avatar_url: t.image_url,
              },
            }),
          )}
          clickableType={AvatarListClickableType.Always}
        />
      }
    />
  );
};
