import { StaffShowOrganisationResponseBody } from "@incident-io/api";

import { InfoTable } from "../common/InfoTable";
import { Value } from "../common/Value";

export const PlanPanel = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <InfoTable
      rows={[
        {
          id: "plan",
          name: "Plan",
          value: <Value.StringValue value={data.billing_setting.plan_name} />,
        },
        {
          id: "trial_end",
          name: "Trial end",
          value: data.trial_end_at && (
            <Value.DateTimeValue value={data.trial_end_at} />
          ),
        },
      ]}
    />
  );
};
