import {
  EscalationPathSlim,
  Schedule,
  ScheduleEntry,
  SchedulesListSortByEnum,
  ScopeNameEnum,
  SelectOption,
  User,
  UsersShowResponseBody,
} from "@incident-io/api";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import { OrgAwareLink, useOrgAwareNavigate } from "@incident-shared/org-aware";
import { TeamBadge } from "@incident-shared/teams/TeamBadge";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import useInfiniteScroll, {
  UseInfiniteScrollHookRefCallback,
} from "react-infinite-scroll-hook";
import { useAPI } from "src/utils/swr";
import { useAPIInfinite } from "src/utils/swr";
import useSWRImmutable from "swr/immutable";
import { useDebounce } from "use-debounce";

import { useClient } from "../../../../contexts/ClientContext";
import { useIdentity } from "../../../../contexts/IdentityContext";
import { CopyDebugID } from "../../../../utils/StaffOverlayProvider";
import { tcx } from "../../../../utils/tailwind-classes";
import { SchedulesListFilter } from "./SchedulesListFilter";
import {
  ConfirmDeleteScheduleModal,
  ScheduleEscalationPathBadge,
} from "./SchedulesViewPage";

type ScheduleContext = "ALL_SCHEDULES" | "MY_SCHEDULES";

export const SchedulesTable = () => {
  const { identity } = useIdentity();
  const [selectedTeams, setSelectedTeams] = useState<SelectOption[]>([]);
  const { value: searchTerm } = useSearchContext();
  const [debouncedSearch] = useDebounce(searchTerm, 500);
  const isFilteringByTeam = selectedTeams.length > 0;
  const {
    responses,
    isLoading: listSchedulesLoading,
    isFullyLoaded,
    error: listSchedulesError,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "schedulesList",
    {
      sortBy: SchedulesListSortByEnum.Name,
      search: debouncedSearch,
      pageSize: 10,
      teamIds: isFilteringByTeam
        ? selectedTeams.map((t) => t.value)
        : undefined,
    },
    {
      revalidateFirstPage: true,
      refreshInterval: 10000, // Reload every 10s
    },
  );

  const { data: schedulesForUser, isLoading: userSchedulesLoading } = useAPI(
    debouncedSearch ? null : "schedulesListForUser",
    {
      user: identity.user_id,
    },
  );

  const schedules = _.sortBy(
    responses.flatMap(({ schedules }) => schedules),
    "name",
  );

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: listSchedulesLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    rootMargin: "0px 0px 100px 0px",
  });

  const isLoadingFirstPage = listSchedulesLoading && responses.length === 0;
  if (isLoadingFirstPage && userSchedulesLoading) {
    return <Loader />;
  }

  if (listSchedulesError) {
    return <GenericErrorMessage error={listSchedulesError} />;
  }

  return (
    <div className={"flex flex-col gap-10"}>
      {(schedulesForUser?.schedules.length ?? 0) > 0 ? (
        <InfiniteScrollingSchedules
          schedules={schedulesForUser?.schedules || []}
          escalationPathsBySchedule={
            schedulesForUser?.escalation_path_references || {}
          }
          users={schedulesForUser?.users || []}
          context={"MY_SCHEDULES"}
          isLoading={userSchedulesLoading}
          isFullyLoaded
          showFilter={false}
        />
      ) : null}
      <InfiniteScrollingSchedules
        schedules={schedules}
        context={"ALL_SCHEDULES"}
        users={responses.flatMap(({ users }) => users)}
        escalationPathsBySchedule={responses.reduce(
          (acc, resp) => ({ ...acc, ...resp.escalation_path_references }),
          {},
        )}
        isLoading={listSchedulesLoading}
        isFullyLoaded={isFullyLoaded}
        infiniteScrollRef={infiniteScrollRef}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        showFilter={true}
      />
      {(searchTerm || isFilteringByTeam) &&
        !listSchedulesLoading &&
        schedules.length === 0 && (
          <tr>
            <td colSpan={5}>
              <div className="flex items-center justify-center h-[32px]">
                <span>No results found</span>
              </div>
            </td>
          </tr>
        )}
    </div>
  );
};

type BaseInfiniteScrollingSchedulesProps = {
  schedules: Schedule[];
  escalationPathsBySchedule: Record<string, EscalationPathSlim[]>;
  users: User[];
  isLoading: boolean;
  context: ScheduleContext;
  isFullyLoaded: boolean;
  infiniteScrollRef?: UseInfiniteScrollHookRefCallback;
};

type FilterableInfiniteScrollingSchedulesProps =
  BaseInfiniteScrollingSchedulesProps & {
    showFilter: true;
    selectedTeams: SelectOption[];
    setSelectedTeams: (teams: SelectOption[]) => void;
  };

type NonFilterableInfiniteScrollingSchedulesProps =
  BaseInfiniteScrollingSchedulesProps & {
    showFilter: false;
    selectedTeams?: never;
    setSelectedTeams?: never;
  };

type InfiniteScrollingSchedulesProps =
  | FilterableInfiniteScrollingSchedulesProps
  | NonFilterableInfiniteScrollingSchedulesProps;

const InfiniteScrollingSchedules = ({
  schedules,
  escalationPathsBySchedule,
  users,
  isLoading,
  isFullyLoaded,
  context,
  infiniteScrollRef,
  selectedTeams,
  setSelectedTeams,
  showFilter,
}: InfiniteScrollingSchedulesProps) => {
  const { featureNativeTeams } = useFlags();

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"text-content-primary font-semibold text-base"}>
        {context === "ALL_SCHEDULES" ? "All schedules" : "My schedules"}
      </div>
      {showFilter && (
        <SchedulesListFilter
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      )}
      <Table
        gridTemplateColumns={
          featureNativeTeams ? "20% 20% 20% 20% 15% 5%" : "25% 25% 25% 20% 5%"
        }
        header={
          <>
            <TableHeaderCell className={"first:pl-0"}>Name</TableHeaderCell>
            <TableHeaderCell>On call now</TableHeaderCell>
            <TableHeaderCell>On call next</TableHeaderCell>
            {featureNativeTeams && <TableHeaderCell>Teams</TableHeaderCell>}
            <TableHeaderCell>Escalation paths</TableHeaderCell>
            <TableHeaderCell className={"justify-end"} />
          </>
        }
        data={schedules}
        renderRow={(schedule, idx) => (
          <ScheduleTableRow
            key={schedule.id}
            schedule={schedule}
            escalationPaths={escalationPathsBySchedule[schedule.id] ?? []}
            users={users}
            isLastRow={idx === schedules.length - 1}
          />
        )}
        infiniteScroll={
          infiniteScrollRef && {
            isFullyLoaded,
            ref: infiniteScrollRef,
            isLoading,
          }
        }
      />
    </div>
  );
};

const UserDisplayPictureGroup = ({ users }: { users: User[] }) => {
  // if there's no users, display nothing
  if (users.length === 0) {
    return null;
  }

  // if there's only one user, display their picture
  if (users.length === 1) {
    return (
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Large}
        className="w-8 h-8"
        title={users[0].name}
      />
    );
  }
  // if there are more than one user, display on the top left and bottom right
  return (
    <div className="relative w-8 h-8">
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Medium}
        title={users[0].name}
        className="absolute top-0 overflow-hidden z-10"
      />
      {/* make the second one have an inherited border */}
      {users.length === 2 ? (
        <Avatar
          url={users[1].avatar_url}
          name={users[1].name}
          size={IconSize.Medium}
          title={users[1].name}
          className="absolute bottom-0 right-0 overflow-hidden outline outline-2 outline-white group-hover:outline-slate-100 z-20"
        />
      ) : (
        <Tooltip side="top" content={users.map((u) => u.name).join(", ")}>
          <div className="absolute bottom-0 right-0 w-5 h-5 rounded-full overflow-hidden outline outline-2 outline-white group-hover:outline-slate-100 z-20 bg-surface-secondary flex items-center justify-center">
            <Txt>+{users.length - 1}</Txt>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const RichOnCallPreview = ({
  users,
  shifts,
  timezone,
  context,
}: {
  users: User[];
  shifts: ScheduleEntry[] | undefined;
  timezone: string;
  context: "current" | "next";
}) => {
  const { identity } = useIdentity();

  const shiftsByUser = _.chain(shifts)
    .filter((shift) => shift.user_id !== "NOBODY")
    .groupBy("user_id")
    .map((shifts, userId) => ({
      user: users.find((u) => u.id === userId),
      shifts,
    }))
    .value();

  const shiftsForAllUsersWithoutNobody = (shifts || []).filter(
    (s) => s.user_id !== "NOBODY",
  );

  const nextShiftSwapDate =
    context === "next"
      ? shiftsForAllUsersWithoutNobody?.sort(
          (a, b) => a.start_at.getTime() - b.start_at.getTime(),
        )?.[0]?.start_at
      : shiftsForAllUsersWithoutNobody?.sort(
          (a, b) => a.end_at.getTime() - b.end_at.getTime(),
        )?.[0]?.end_at;

  const userTimezone = DateTime.local().zoneName;

  return (
    <div className="flex items-center gap-2">
      {/* Display pictures */}
      <UserDisplayPictureGroup
        users={
          shiftsByUser.map((user) => user.user).filter((user) => user) as User[]
        }
      />
      <div className="flex flex-wrap w-fit flex-col">
        <div className={"text-sm font-medium text-content-primary"}>
          {shiftsByUser
            .filter(({ user }) => user && user.id !== "NOBODY")
            .map(({ user }) => {
              if (!user) {
                return null;
              }
              if (user.id === identity?.user_id) {
                return `${user.name} (you)`;
              }
              return user.name;
            })
            .reduce((acc, curr) => {
              if (acc === "") {
                return curr;
              }
              return `${acc}, ${curr}`;
            }, "")}
        </div>
        {nextShiftSwapDate &&
        shiftsByUser.filter((user) => user.user).length > 0 ? (
          <div className={"text-xs font-medium text-content-tertiary"}>
            {context === "current" ? "Until" : "On"}{" "}
            {DateTime.fromJSDate(nextShiftSwapDate)
              .setZone(timezone)
              .toLocaleString({
                weekday: "short",
                day: "numeric",
                month: "short",
                hour: "numeric",
                hourCycle: "h12",
                minute: "numeric",
                timeZoneName: userTimezone !== timezone ? "short" : undefined,
              })}
          </div>
        ) : (
          <div className="text-slate-300">{"\u2014"}</div>
        )}
      </div>
    </div>
  );
};

const ScheduleTableRow = ({
  schedule,
  escalationPaths,
  users,
  isLastRow,
}: {
  schedule: Schedule;
  escalationPaths: EscalationPathSlim[];
  users: User[];
  isLastRow: boolean;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const apiClient = useClient();
  const { featureNativeTeams } = useFlags();

  const [canEditSchedules, canDestroySchedules] = [
    hasScope(ScopeNameEnum.SchedulesUpdate),
    hasScope(ScopeNameEnum.SchedulesDestroy),
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const nextUpUsers = schedule?.next_shifts
    ?.map((shift) => shift.user_id)
    .filter((u) => u !== "NOBODY");

  const { data: nextUserOnCallData, isLoading: nextUserOnCallLoading } =
    useSWRImmutable(
      `next-users-${schedule.id}`,
      async (): Promise<UsersShowResponseBody[]> => {
        const userResponses = await Promise.all(
          (nextUpUsers ?? []).map((u) => apiClient.usersShow({ id: u })),
        );

        return userResponses || [];
      },
    );

  const cellClassName = [
    "group-hover:bg-surface-secondary",
    { "border-stroke-primary": isLastRow },
  ];

  return (
    <TableRow isLastRow={false} className={"group"}>
      <TableCell className={tcx("first:pl-0", ...cellClassName)}>
        {/* Name */}
        <OrgAwareLink
          className="w-full h-full flex items-center gap-2"
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          <span className="flex flex-col">
            <span className="text-sm font-semibold truncate max-w-[30ch]">
              {schedule.name}
            </span>
            <CopyDebugID id={schedule.id} />
          </span>
          <ManagementMetaBadge
            management={schedule.management_meta}
            resourceName="schedule"
          />
        </OrgAwareLink>
      </TableCell>

      {/* On call now */}
      <TableCell className={tcx(...cellClassName)}>
        <OrgAwareLink
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          <div className="flex flex-wrap gap-2 w-fit">
            <RichOnCallPreview
              users={users}
              shifts={schedule.current_shifts}
              timezone={schedule.timezone}
              context={"current"}
            />
          </div>
        </OrgAwareLink>
      </TableCell>

      {/* On call next */}
      <TableCell className={tcx(...cellClassName)}>
        <OrgAwareLink
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          {nextUserOnCallLoading ? (
            <span className="text-slate-300">{"\u2014"}</span>
          ) : nextUserOnCallData ? (
            <RichOnCallPreview
              users={nextUserOnCallData.map((u) => u.user as unknown as User)}
              shifts={schedule?.next_shifts}
              timezone={schedule.timezone}
              context={"next"}
            />
          ) : (
            <span className="text-slate-300">{"\u2014"}</span>
          )}
        </OrgAwareLink>
      </TableCell>

      {/* Teams */}
      {featureNativeTeams && (
        <TableCell className={tcx(...cellClassName)}>
          <OrgAwareLink
            analyticsTrackingId={"schedules-list-view"}
            to={`/on-call/schedules/${schedule.id}`}
          >
            {schedule.team_ids.length > 0 ? (
              schedule.team_ids.map((id) => <TeamBadge key={id} teamId={id} />)
            ) : (
              <span className="text-slate-300">{"\u2014"}</span>
            )}
          </OrgAwareLink>
        </TableCell>
      )}

      {/* Escalation Paths */}
      <TableCell className={tcx(...cellClassName)}>
        {escalationPaths.length > 0 ? (
          <div className="flex flex-wrap gap-2 w-fit">
            <ScheduleEscalationPathBadge
              escalationPathsForSchedule={escalationPaths}
              scheduleID={schedule.id}
            />
          </div>
        ) : (
          <span className="text-slate-300">{"\u2014"}</span>
        )}
      </TableCell>

      {/* Dropdown */}
      <TableCell className={tcx(...cellClassName)}>
        <DropdownMenu
          menuClassName={"w-[120px] mr-5"}
          triggerButton={
            <Button
              theme={ButtonTheme.Unstyled}
              size={BadgeSize.Medium}
              className={tcx(
                "!p-0 hover:bg-surface-secondary text-content-tertiary",
              )}
              icon={IconEnum.DotsVertical}
              iconProps={{
                size: IconSize.XL,
              }}
              title={"Open menu"}
              analyticsTrackingId={"schedules.open-menu"}
            />
          }
          side={"bottom"}
        >
          <DropdownMenuItem
            analyticsTrackingId={"schedules.edit"}
            onSelect={() => navigate(`/on-call/schedules/${schedule.id}/edit`)}
            label="Edit"
            disabled={!canEditSchedules}
            icon={IconEnum.Edit}
            tooltipContent={
              !canEditSchedules &&
              "You do not have permission to edit schedules."
            }
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.duplicate"}
            onSelect={() =>
              navigate(`/on-call/schedules/${schedule.id}/duplicate`)
            }
            label="Duplicate"
            disabled={!canEditSchedules}
            icon={IconEnum.Copy}
            tooltipContent={
              !canEditSchedules &&
              "You do not have permission to edit schedules."
            }
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.view"}
            onSelect={() => navigate(`/on-call/schedules/${schedule.id}`)}
            label="View"
            icon={IconEnum.View}
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.delete"}
            onSelect={() => setDeleteModalOpen(true)}
            label="Delete"
            disabled={!canDestroySchedules}
            tooltipContent={
              !canDestroySchedules &&
              "You do not have permission to delete schedules."
            }
            icon={IconEnum.Delete}
            destructive
          />
        </DropdownMenu>
      </TableCell>

      {deleteModalOpen && (
        <ConfirmDeleteScheduleModal
          onConfirm={() => navigate("/on-call/schedules")}
          onCancel={() => setDeleteModalOpen(false)}
          schedule={schedule}
        />
      )}
    </TableRow>
  );
};
