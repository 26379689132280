import {
  StaffEnableOnCallRequestBody,
  StaffShowOrganisationResponseBody,
} from "@incident-io/api";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ModalFooter } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { getProductAccess } from "src/hooks/useProductAccess";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const EnableOnCallButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.EnableOnCallForOrganisation,
  );

  const { hasOnCall } = getProductAccess(data);
  if (hasOnCall) {
    disabledProps = {
      disabled: true,
      disabledTooltipContent: "Org already has On-call enabled",
    };
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        {...disabledProps}
      >
        📟 Enable On-call
      </GatedButton>

      {isOpen && (
        <EnableOnCallModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const EnableOnCallModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const defaultDeadline = new Date();
  defaultDeadline.setDate(defaultDeadline.getDate() + 14);
  defaultDeadline.setHours(defaultDeadline.getHours(), 0, 0, 0);

  const formMethods = useForm<StaffEnableOnCallRequestBody>({
    defaultValues: {
      on_call_soft_deadline: defaultDeadline,
    },
  });

  const { trigger, isMutating, genericError, fieldErrors } = useMutationV2(
    async (apiClient, formData) => {
      await apiClient.staffEnableOnCall({
        organisationSlug: data.organisation_slug,
        enableOnCallRequestBody: formData,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal<StaffEnableOnCallRequestBody>
      title="Enable On-call"
      formMethods={formMethods}
      onSubmit={(data) => {
        // DateTimeInput expects null for an empty state, forms expect undefined
        // this hack is to make both bits happy
        if (data.on_call_soft_deadline == null) {
          trigger({
            ...data,
            on_call_soft_deadline: undefined,
          });
        } else {
          trigger(data);
        }
      }}
      saving={isMutating}
      onClose={onClose}
      className="text-sm space-y-2"
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          saving={isMutating}
          confirmButtonText="Do it"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {genericError && <Form.ErrorMessage message={genericError} />}
      {fieldErrors && (
        <Form.ErrorMessage message={Object.values(fieldErrors)?.[0]} />
      )}
      <p>
        This will update the feature gates for {data.organisation_name} to give
        them unlimited on-call seats and the pay calculator:
      </p>
      <hr />
      <DateTimeInputV2
        label="Soft deadline"
        helptext={
          <>
            You can set a soft deadline for when the On-call feature should be
            disabled. This deadline will not be automatically enforced. The
            expiry date will be reported where it can be detected in a dashboard
            and manually disabled.
          </>
        }
        formMethods={formMethods}
        name="on_call_soft_deadline"
        required={false}
        clearable
      />
    </Form.Modal>
  );
};
