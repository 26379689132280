import { InterestingChannel } from "@incident-io/api";
import {
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  PopoverSingleSelect,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { getTypeaheadOptions } from "src/components/status-pages/incidents/view/IncidentLinkCreateModal";
import { useClient } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AIPrivacyButton } from "./AIFeatureCard";
import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "./AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "./useAIFeatures";

export const InvestigationsDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data, isLoading, error } = useAPI(
    "aIListInterestingChannels",
    undefined,
  );

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <AIFeatureConfigurationDrawer
      loading={isLoading}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              {/* For now, you can't enable/disable this. We should add that! */}
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Find related previous incidents"
              description="We will show you incidents that are similar to the one you are currently investigating."
              icon={IconEnum.Incident}
            />
            <AIProductMarketingCard
              title="Find related code changes"
              description="We will show you code changes that could be the root cause of your incident."
              icon={IconEnum.GitBranch}
            />
          </AIProductMarketingCards>
          {data && <InterestingChannelSelector channels={data.channels} />}
        </>
      }
      width="medium"
    />
  );
};

const InterestingChannelSelector = ({
  channels,
}: {
  channels: InterestingChannel[];
}) => {
  const { trigger: deleteChannel, isMutating: deleting } = useAPIMutation(
    "aIListInterestingChannels",
    undefined,
    async (apiClient, data: { id: string }) => {
      await apiClient.aIArchiveInterestingChannel({
        id: data.id,
      });
    },
    {
      showErrorToast: "Removing channel",
    },
  );
  const { trigger: createChannel, isMutating: creating } = useAPIMutation(
    "aIListInterestingChannels",
    undefined,
    async (apiClient, data: { slack_channel_id: string }) => {
      await apiClient.aICreateInterestingChannel({
        createInterestingChannelRequestBody: {
          slack_channel_id: data.slack_channel_id,
        },
      });
    },
    {
      showErrorToast: "Creating channel",
    },
  );
  const apiClient = useClient();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4 justify-between items-center">
        <div className="flex flex-col gap-1">
          <p className="text-content-primary text-base-bold">
            Interesting channels
          </p>
          <p className="text-content-secondary text-xs-normal">
            Pick the Slack channels we&rsquo;ll monitor for messages that might
            be relevant when responding to incidents.
            <br /> For example, you could add an #engineering channel where you
            discuss recent technical changes, or #bugs channel where users
            report issues.
          </p>
        </div>
        <PopoverSingleSelect<false, false>
          align="end"
          value={undefined}
          onChange={(val) =>
            val ? createChannel({ slack_channel_id: val }) : undefined
          }
          renderTriggerNode={({ onClick }) => (
            <Button
              onClick={onClick}
              theme={ButtonTheme.Primary}
              analyticsTrackingId="add-interesting-channel"
              loading={creating}
              icon={IconEnum.Add}
            >
              Add channel
            </Button>
          )}
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
            {},
          )}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
          )}
        />
      </div>

      {channels.length === 0 ? (
        <div className="mt-4">
          <EmptyState
            icon={IconEnum.Slack}
            title="No interesting channels added yet"
            content="Add Slack channels that contain valuable information for investigations. AI will analyze these channels to provide insights during incidents."
          />
        </div>
      ) : (
        <StackedList>
          {channels.map((channel) => (
            <StackedListItem
              key={channel.id}
              title={"#" + channel.name}
              accessory={
                <div className="flex items-center gap-2">
                  <Button
                    theme={ButtonTheme.Tertiary}
                    analyticsTrackingId={null}
                    icon={IconEnum.SlackGreyscale}
                    title="View channel"
                    href={channel.url}
                    openInNewTab
                    size={BadgeSize.Small}
                  />
                  <Button
                    theme={ButtonTheme.Tertiary}
                    analyticsTrackingId={null}
                    icon={IconEnum.Delete}
                    loading={deleting}
                    title="Delete"
                    onClick={() => deleteChannel(channel)}
                    size={BadgeSize.Small}
                  />
                </div>
              }
            />
          ))}
        </StackedList>
      )}
    </div>
  );
};
