/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReplicaSource,
    ScheduleReplicaSourceFromJSON,
    ScheduleReplicaSourceFromJSONTyped,
    ScheduleReplicaSourceToJSON,
} from './ScheduleReplicaSource';

/**
 * 
 * @export
 * @interface ScheduleReplicaPayload
 */
export interface ScheduleReplicaPayload {
    /**
     * The user that will be used in the replica system whenever NOBODY is scheduled
     * @type {string}
     * @memberof ScheduleReplicaPayload
     */
    replica_fallback_user_id: string;
    /**
     * Name of replica external provider, e.g. pagerduty
     * @type {string}
     * @memberof ScheduleReplicaPayload
     */
    replica_provider: ScheduleReplicaPayloadReplicaProviderEnum;
    /**
     * ID of replica schedule in external system
     * @type {string}
     * @memberof ScheduleReplicaPayload
     */
    replica_provider_id: string;
    /**
     * The rotation/layer combinations being replicated
     * @type {Array<ScheduleReplicaSource>}
     * @memberof ScheduleReplicaPayload
     */
    sources: Array<ScheduleReplicaSource>;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleReplicaPayloadReplicaProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function ScheduleReplicaPayloadFromJSON(json: any): ScheduleReplicaPayload {
    return ScheduleReplicaPayloadFromJSONTyped(json, false);
}

export function ScheduleReplicaPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplicaPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'replica_fallback_user_id': json['replica_fallback_user_id'],
        'replica_provider': json['replica_provider'],
        'replica_provider_id': json['replica_provider_id'],
        'sources': ((json['sources'] as Array<any>).map(ScheduleReplicaSourceFromJSON)),
    };
}

export function ScheduleReplicaPayloadToJSON(value?: ScheduleReplicaPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'replica_fallback_user_id': value.replica_fallback_user_id,
        'replica_provider': value.replica_provider,
        'replica_provider_id': value.replica_provider_id,
        'sources': ((value.sources as Array<any>).map(ScheduleReplicaSourceToJSON)),
    };
}

