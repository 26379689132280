import cx from "classnames";
import { ForwardedRef, forwardRef } from "react";

import styles from "./ContentBox.module.scss";

export enum ContentBoxTheme {
  Operational = "operational",
  UnderMaintenance = "underMaintenance",
  PartialOutage = "partialOutage",
  DegradedPerformance = "degradedPerformance",
  FullOutage = "fullOutage",
}

export const ContentBox = forwardRef(
  (
    {
      title,
      children,
      theme,
      padded = true,
    }: {
      title?: React.ReactElement;
      children?: React.ReactNode;
      theme?: ContentBoxTheme;
      padded?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    return (
      <div
        ref={ref}
        className={cx(
          "rounded-lg p-px shadow-sm dark:shadow-none",
          styles[theme ?? "default"],
        )}
      >
        <div className="relative rounded-[7px] bg-white dark:bg-global">
          {title ? (
            <div
              className={cx(
                styles.header,
                "rounded-t-[7px] text-base font-medium px-4 py-3.5",
              )}
            >
              {title}
            </div>
          ) : null}
          {children ? (
            <div
              className={cx(
                "text-content-primary dark:text-slate-100",
                padded && "p-4",
              )}
            >
              {children}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

ContentBox.displayName = "ContentBox";
