/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtlassianWidgetStatusComponent
 */
export interface AtlassianWidgetStatusComponent {
    /**
     * Time the component was created
     * @type {Date}
     * @memberof AtlassianWidgetStatusComponent
     */
    created_at: Date;
    /**
     * Description of the component
     * @type {string}
     * @memberof AtlassianWidgetStatusComponent
     */
    description?: string;
    /**
     * Unique identifier of the component
     * @type {string}
     * @memberof AtlassianWidgetStatusComponent
     */
    id: string;
    /**
     * Name of the component
     * @type {string}
     * @memberof AtlassianWidgetStatusComponent
     */
    name: string;
    /**
     * ID of the status page this component belongs to
     * @type {string}
     * @memberof AtlassianWidgetStatusComponent
     */
    page_id: string;
    /**
     * Position of the component in the list
     * @type {number}
     * @memberof AtlassianWidgetStatusComponent
     */
    position: number;
    /**
     * Current status of the component
     * @type {string}
     * @memberof AtlassianWidgetStatusComponent
     */
    status: AtlassianWidgetStatusComponentStatusEnum;
    /**
     * Time the component was last updated
     * @type {Date}
     * @memberof AtlassianWidgetStatusComponent
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AtlassianWidgetStatusComponentStatusEnum {
    Operational = 'operational',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    MajorOutage = 'major_outage'
}

export function AtlassianWidgetStatusComponentFromJSON(json: any): AtlassianWidgetStatusComponent {
    return AtlassianWidgetStatusComponentFromJSONTyped(json, false);
}

export function AtlassianWidgetStatusComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianWidgetStatusComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'name': json['name'],
        'page_id': json['page_id'],
        'position': json['position'],
        'status': json['status'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AtlassianWidgetStatusComponentToJSON(value?: AtlassianWidgetStatusComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'page_id': value.page_id,
        'position': value.position,
        'status': value.status,
        'updated_at': (value.updated_at.toISOString()),
    };
}

