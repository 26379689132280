import { SlackCanvasPreviewVariant } from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { toSentenceCase } from "../../utils/formatting";

export const SlackCanvasPreviewCanvasPage = () => {
  const { targetName } = useParams();
  if (!targetName) {
    throw new Error("No message name provided");
  }

  const { data, isLoading } = useAPI("slackCanvasPreviewsShow", {
    name: targetName,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!data?.template) {
    return <GenericErrorMessage />;
  }

  return (
    <PageWrapper
      title={data.template.name || ""}
      width={PageWidth.Narrow}
      icon={IconEnum.Message}
      backHref="/slack-canvas-previews"
    >
      <div className="h-full p-6 space-y-3">
        <div>{data.template.description}</div>
        {data.template.variants?.map((v) => (
          <VariantPreview key={v.name} variant={v} />
        ))}
      </div>
    </PageWrapper>
  );
};

const VariantPreview = ({
  variant,
}: {
  variant: SlackCanvasPreviewVariant;
}) => {
  return (
    <>
      <div>{toSentenceCase(variant.name || "unknown")}</div>
      <div className="border p-2 max-w-[450px]">
        {variant.content && (
          <TemplatedTextDisplay
            value={variant.content}
            style={TemplatedTextDisplayStyle.Compact}
            className="!border-0 !p-0"
          />
        )}
      </div>
    </>
  );
};
