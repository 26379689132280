import {
  EvaluationBacktest,
  EvaluationBacktestBacktestTypeEnum,
  InvestigationPlanTypeEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { BacktestCreateModal } from "./BacktestCreateModal";
import { BacktestShowState } from "./BacktestsShowPage";
import { backtestTypeLabel } from "./utils";

// Combined filter buttons component
type FilterCombination = {
  backtestType: string;
  planType: string | null;
  label: string;
  count: number;
};

const CombinedFilterButtons = ({
  backtests,
  selectedBacktestType,
  selectedPlanType,
  setSelectedBacktestType,
  setSelectedPlanType,
}: {
  backtests: EvaluationBacktest[];
  selectedBacktestType: EvaluationBacktestBacktestTypeEnum | null;
  selectedPlanType: InvestigationPlanTypeEnum | null;
  setSelectedBacktestType: (
    type: EvaluationBacktestBacktestTypeEnum | null,
  ) => void;
  setSelectedPlanType: (type: InvestigationPlanTypeEnum | null) => void;
}) => {
  // Get unique combinations of backtest type and investigation plan type
  const combinations = useMemo(() => {
    const combos: Record<string, FilterCombination> = {};

    backtests.forEach((backtest) => {
      const backtestType = backtest.backtest_type || "";
      const planType = backtest.investigation_plan_type || null;

      // Create a key for this combination
      const key = `${backtestType}|${planType || ""}`;

      if (!combos[key]) {
        // Create label based on the combination
        const label = backtestTypeLabel(
          backtestType as unknown as EvaluationBacktestBacktestTypeEnum,
          planType,
        );

        combos[key] = {
          backtestType,
          planType,
          label,
          count: 1,
        };
      } else {
        combos[key].count++;
      }
    });

    // Sort combinations by backtest type and then by plan type
    return Object.values(combos).sort((a, b) => {
      if (a.backtestType !== b.backtestType) {
        return a.backtestType.localeCompare(b.backtestType);
      }

      if (a.planType == null && b.planType != null) return -1;
      if (a.planType != null && b.planType == null) return 1;
      if (a.planType == null && b.planType == null) return 0;

      return (a.planType || "").localeCompare(b.planType || "");
    });
  }, [backtests]);

  // Function to determine if a filter is selected
  const isSelected = (combo: FilterCombination) => {
    if (selectedBacktestType == null && selectedPlanType == null) {
      return false;
    }

    return (
      selectedBacktestType === combo.backtestType &&
      selectedPlanType === combo.planType
    );
  };

  // Function to select a filter
  const selectFilter = (combo: FilterCombination) => {
    setSelectedBacktestType(
      combo.backtestType as EvaluationBacktestBacktestTypeEnum,
    );
    setSelectedPlanType(combo.planType as InvestigationPlanTypeEnum | null);
  };

  // Clear all filters
  const clearFilters = () => {
    setSelectedBacktestType(null);
    setSelectedPlanType(null);
  };

  return (
    <div className="flex flex-wrap gap-2">
      <Button
        theme={
          !selectedBacktestType && !selectedPlanType
            ? ButtonTheme.Primary
            : ButtonTheme.Secondary
        }
        analyticsTrackingId={null}
        onClick={clearFilters}
        size={BadgeSize.Small}
      >
        All ({backtests.length})
      </Button>

      {combinations.map((combo) => (
        <Button
          key={`${combo.backtestType}|${combo.planType || ""}`}
          theme={
            isSelected(combo) ? ButtonTheme.Primary : ButtonTheme.Secondary
          }
          analyticsTrackingId={null}
          onClick={() => selectFilter(combo)}
          size={BadgeSize.Small}
        >
          {combo.label} ({combo.count})
        </Button>
      ))}
    </div>
  );
};

export const BacktestsListPage = () => {
  const {
    data: { backtests },
    isLoading,
  } = useAPI(
    "aIStaffListEvaluationBacktests",
    {},
    {
      fallbackData: { backtests: [] },
    },
  );

  const [showCreateBacktestModal, setShowCreateBacktestModal] = useState(false);

  // State for filters
  const [selectedBacktestType, setSelectedBacktestType] =
    useState<EvaluationBacktestBacktestTypeEnum | null>(null);
  const [selectedPlanType, setSelectedPlanType] =
    useState<InvestigationPlanTypeEnum | null>(null);

  // Filter backtests based on selected types
  const filteredBacktests = useMemo(() => {
    return backtests.filter((backtest) => {
      // Filter by backtest type if set
      if (
        selectedBacktestType &&
        backtest.backtest_type !== selectedBacktestType
      ) {
        return false;
      }

      // Filter by investigation plan type if set
      if (
        selectedPlanType &&
        backtest.investigation_plan_type !== selectedPlanType
      ) {
        return false;
      }

      return true;
    });
  }, [backtests, selectedBacktestType, selectedPlanType]);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowCreateBacktestModal(true)}
          theme={ButtonTheme.Primary}
        >
          Create backtest
        </Button>
      }
    >
      <CombinedFilterButtons
        backtests={backtests}
        selectedBacktestType={selectedBacktestType}
        selectedPlanType={selectedPlanType}
        setSelectedBacktestType={setSelectedBacktestType}
        setSelectedPlanType={setSelectedPlanType}
      />

      <LoadingWrapper loading={isLoading}>
        {isEmpty(filteredBacktests) ? (
          <EmptyState
            content={
              isEmpty(backtests)
                ? "No backtests created yet"
                : "No backtests match the selected filters"
            }
          />
        ) : (
          <StackedList>
            {filteredBacktests.map((backtest) => (
              <BacktestRow backtest={backtest} key={backtest.id} />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
      {showCreateBacktestModal && (
        <BacktestCreateModal
          onClose={() => setShowCreateBacktestModal(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const BacktestRow = ({ backtest }: { backtest: EvaluationBacktest }) => {
  return (
    <StackedListItem
      rowHref={`/workbench/backtests/${backtest.id}`}
      key={backtest.id}
      title={
        <div className="flex items-center gap-2">
          <LocalDateTime
            timestamp={backtest.created_at}
            className="font-semibold"
          />
        </div>
      }
      badgeNode={
        <div className="flex items-center gap-2">
          <Badge theme={BadgeTheme.Secondary}>
            {backtestTypeLabel(
              backtest.backtest_type as unknown as EvaluationBacktestBacktestTypeEnum,
              backtest.investigation_plan_type,
            )}
          </Badge>
          <BacktestShowState backtest={backtest} />
          <div className="text-sm text-gray-500">
            <span className="font-semibold">{backtest.processed_entries}</span>{" "}
            of <span className="font-semibold">{backtest.total_entries}</span>{" "}
            entries processed
          </div>
        </div>
      }
      accessory={
        <Icon
          id={IconEnum.ChevronRight}
          className="text-content-tertiary"
          size={IconSize.Medium}
        />
      }
      description={<div className="pt-2">{backtest.notes}</div>}
    />
  );
};
