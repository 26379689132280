/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleConfigPayload,
    ScheduleConfigPayloadFromJSON,
    ScheduleConfigPayloadFromJSONTyped,
    ScheduleConfigPayloadToJSON,
} from './ScheduleConfigPayload';
import {
    ScheduleHolidaysPublicConfig,
    ScheduleHolidaysPublicConfigFromJSON,
    ScheduleHolidaysPublicConfigFromJSONTyped,
    ScheduleHolidaysPublicConfigToJSON,
} from './ScheduleHolidaysPublicConfig';

/**
 * 
 * @export
 * @interface ScheduleTerraformPayload
 */
export interface ScheduleTerraformPayload {
    /**
     * Annotations that can track metadata about this type
     * @type {{ [key: string]: string; }}
     * @memberof ScheduleTerraformPayload
     */
    annotations?: { [key: string]: string; };
    /**
     * 
     * @type {ScheduleConfigPayload}
     * @memberof ScheduleTerraformPayload
     */
    config: ScheduleConfigPayload;
    /**
     * If set, the schedule description synced from the external provider
     * @type {string}
     * @memberof ScheduleTerraformPayload
     */
    description?: string;
    /**
     * The ID of the external schedule in our schedules table, if this schedule was copied from an external provider
     * @type {string}
     * @memberof ScheduleTerraformPayload
     */
    external_schedule_id?: string;
    /**
     * 
     * @type {ScheduleHolidaysPublicConfig}
     * @memberof ScheduleTerraformPayload
     */
    holidays_public_config?: ScheduleHolidaysPublicConfig;
    /**
     * Unique internal ID of the schedule
     * @type {string}
     * @memberof ScheduleTerraformPayload
     */
    id: string;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof ScheduleTerraformPayload
     */
    name: string;
    /**
     * IDs of teams that own this schedule
     * @type {Array<string>}
     * @memberof ScheduleTerraformPayload
     */
    team_ids?: Array<string>;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof ScheduleTerraformPayload
     */
    timezone: string;
}

export function ScheduleTerraformPayloadFromJSON(json: any): ScheduleTerraformPayload {
    return ScheduleTerraformPayloadFromJSONTyped(json, false);
}

export function ScheduleTerraformPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleTerraformPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'config': ScheduleConfigPayloadFromJSON(json['config']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'external_schedule_id': !exists(json, 'external_schedule_id') ? undefined : json['external_schedule_id'],
        'holidays_public_config': !exists(json, 'holidays_public_config') ? undefined : ScheduleHolidaysPublicConfigFromJSON(json['holidays_public_config']),
        'id': json['id'],
        'name': json['name'],
        'team_ids': !exists(json, 'team_ids') ? undefined : json['team_ids'],
        'timezone': json['timezone'],
    };
}

export function ScheduleTerraformPayloadToJSON(value?: ScheduleTerraformPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'config': ScheduleConfigPayloadToJSON(value.config),
        'description': value.description,
        'external_schedule_id': value.external_schedule_id,
        'holidays_public_config': ScheduleHolidaysPublicConfigToJSON(value.holidays_public_config),
        'id': value.id,
        'name': value.name,
        'team_ids': value.team_ids,
        'timezone': value.timezone,
    };
}

