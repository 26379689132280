import {
  CatalogListTypesResponseBody,
  ManagedResource,
  ManagedResourceManagedByEnum,
  ManagedResourceResourceTypeEnum,
  TeamResourceTypeEnum,
  TeamsAddResourceRequestBodyResourceTypeEnum as ResourceType,
} from "@incident-io/api";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  PopoverSingleSelect,
  ToastTheme,
} from "@incident-ui";
import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";

import { useClient } from "../../../../contexts/ClientContext";
import { cacheKey, useMutationV2 } from "../../../../utils/swr";
import { loadEscalationPaths, loadSchedules } from "./helpers";

type OptionType = SelectOption & {
  type?: ResourceType;
};

export const EscalationPathAndSchedulePopover = ({
  teamId,
  catalogTypes,
  managedResources,
  triggerClassName,
  triggerTitle,
  triggerIcon,
  loadOnly,
}: {
  teamId: string;
  catalogTypes: CatalogListTypesResponseBody;
  managedResources: ManagedResource[];
  triggerClassName?: string;
  triggerTitle?: string;
  triggerIcon?: IconEnum;
  loadOnly?: TeamResourceTypeEnum;
}) => {
  const apiClient = useClient();
  const showToast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const scheduleManagedResources = managedResources.filter(
    (resource) =>
      resource.resource_type === ManagedResourceResourceTypeEnum.Schedule &&
      resource.managed_by === ManagedResourceManagedByEnum.Terraform,
  );
  const escalationPathManagedResources = managedResources.filter(
    (resource) =>
      resource.resource_type ===
        ManagedResourceResourceTypeEnum.EscalationPath &&
      resource.managed_by === ManagedResourceManagedByEnum.Terraform,
  );

  const { trigger: addResource, isMutating } = useMutationV2(
    async (
      apiClient,
      {
        resourceId,
        resourceType,
        teamId,
      }: {
        resourceId: string;
        resourceType: ResourceType;
        teamId: string;
      },
    ) => {
      await apiClient.teamsAddResource({
        id: teamId,
        addResourceRequestBody: {
          resource_id: resourceId,
          resource_type: resourceType,
        },
      });
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error updating team",
        });
      },
      invalidate: [cacheKey.exactly("teamsShow", teamId)],
    },
  );

  async function loadAllOptions(inputValue: string) {
    setIsLoading(true);

    let escalationPathsOptions: SelectOptionGroup[] = [];
    let scheduleOptions: SelectOptionGroup[] = [];
    if (loadOnly === TeamResourceTypeEnum.EscalationPath) {
      escalationPathsOptions = await loadEscalationPaths(
        apiClient,
        catalogTypes,
        escalationPathManagedResources,
      )(inputValue);
    } else if (loadOnly === TeamResourceTypeEnum.Schedule) {
      scheduleOptions = await loadSchedules(
        apiClient,
        catalogTypes,
        scheduleManagedResources,
      )(inputValue);
    } else {
      [escalationPathsOptions, scheduleOptions] = await Promise.all([
        loadEscalationPaths(
          apiClient,
          catalogTypes,
          escalationPathManagedResources,
        )(inputValue),

        loadSchedules(
          apiClient,
          catalogTypes,
          scheduleManagedResources,
        )(inputValue),
      ]);
    }

    setIsLoading(false);

    // Return combined options
    return [...(escalationPathsOptions || []), ...(scheduleOptions || [])];
  }

  return (
    <PopoverSingleSelect<false, true, OptionType>
      popoverClassName={"!min-w-[300px]"}
      loadOptions={async (inputValue) => {
        return await loadAllOptions(inputValue);
      }}
      isLoading={isLoading}
      // This is needed to hydrate any initial selected values
      hydrateOptions={async (_) => {
        // Since we don't have initial values to hydrate, return empty array
        return [];
      }}
      value={undefined}
      onChange={(val) => {
        if (!val || !val?.type) {
          return;
        }

        addResource({
          resourceId: val.value,
          resourceType: val.type,
          teamId,
        });
      }}
      object
      isSearchable
      renderTriggerNode={({ onClick, isLoadingOptions }) => (
        <Button
          className={triggerClassName}
          onClick={onClick}
          analyticsTrackingId={null}
          theme={ButtonTheme.Unstyled}
          size={BadgeSize.Large}
          loading={isMutating || isLoadingOptions}
          icon={triggerIcon}
        >
          {triggerTitle}
        </Button>
      )}
    />
  );
};
