import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ConfirmationDialog } from "@incident-ui";
import { useState } from "react";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { getProductAccess } from "src/hooks/useProductAccess";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const DisableResponseButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.TeardownResponseForOrganisation,
  );

  const { hasResponse } = getProductAccess(data);

  if (!hasResponse) {
    disabledProps = {
      disabled: true,
      disabledTooltipContent: "Org does not have Response enabled",
    };
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        theme={hasResponse ? ButtonTheme.Destroy : ButtonTheme.Secondary}
        {...disabledProps}
      >
        ⚡️ Remove Response configuration
      </GatedButton>

      {isOpen && (
        <DisableResponseModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const DisableResponseModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const { trigger, isMutating, genericError } = useMutationV2(
    async (apiClient) => {
      await apiClient.staffArchiveResponseForOrganisation({
        organisationSlug: data.organisation_slug,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      onSuccess: onClose,
    },
  );

  return (
    <ConfirmationDialog
      isOpen
      analyticsTrackingId={null}
      title="DANGER: Remove Response configuration"
      onCancel={onClose}
      onConfirm={() => trigger({})}
      confirmButtonText="Do it now"
      saving={isMutating}
      className="text-sm space-y-2 text-red-600"
    >
      {genericError && <Form.ErrorMessage message={genericError} />}
      <p className="text-red-600 mb-3">
        This will remove response configuration for this organisation (
        {data.organisation_slug}).
      </p>
      <ul className="space-y-1 list-disc pl-3">
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">workflows</strong> will be deleted
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">nudges</strong> will be deleted
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">follow up configs</strong> will be
          deleted
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">active incident statuses</strong>{" "}
          will be deleted
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">incident timestamps</strong> will be
          deleted
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">post incident flows</strong> will be
          disabled
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">custom fields</strong> will be
          disabled
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">AI config</strong> will be disabled
        </li>
        <li>
          All of the organisation&apos;s{" "}
          <strong className="text-red-600">insight emails</strong> will be
          disabled
        </li>
      </ul>
      <p className="text-red-600 mt-3 text-2xl">
        Are you 100% certain that you want to do this?
      </p>
    </ConfirmationDialog>
  );
};
