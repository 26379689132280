/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathSlim,
    EscalationPathSlimFromJSON,
    EscalationPathSlimFromJSONTyped,
    EscalationPathSlimToJSON,
} from './EscalationPathSlim';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';
import {
    ScheduleEntry,
    ScheduleEntryFromJSON,
    ScheduleEntryFromJSONTyped,
    ScheduleEntryToJSON,
} from './ScheduleEntry';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SchedulesListForUserResponseBody
 */
export interface SchedulesListForUserResponseBody {
    /**
     * Map of schedule IDs to escalation paths which use that schedule
     * @type {{ [key: string]: Array<EscalationPathSlim>; }}
     * @memberof SchedulesListForUserResponseBody
     */
    escalation_path_references: { [key: string]: Array<EscalationPathSlim>; };
    /**
     * Where they are scheduled, these are the closest shifts for this user on each schedule
     * @type {Array<ScheduleEntry>}
     * @memberof SchedulesListForUserResponseBody
     */
    next_shifts: Array<ScheduleEntry>;
    /**
     * Whether this user is on call forever
     * @type {boolean}
     * @memberof SchedulesListForUserResponseBody
     */
    on_call_forever?: boolean;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof SchedulesListForUserResponseBody
     */
    schedules: Array<Schedule>;
    /**
     * Users that are part of these schedules
     * @type {Array<User>}
     * @memberof SchedulesListForUserResponseBody
     */
    users: Array<User>;
}

export function SchedulesListForUserResponseBodyFromJSON(json: any): SchedulesListForUserResponseBody {
    return SchedulesListForUserResponseBodyFromJSONTyped(json, false);
}

export function SchedulesListForUserResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesListForUserResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_path_references': json['escalation_path_references'],
        'next_shifts': ((json['next_shifts'] as Array<any>).map(ScheduleEntryFromJSON)),
        'on_call_forever': !exists(json, 'on_call_forever') ? undefined : json['on_call_forever'],
        'schedules': ((json['schedules'] as Array<any>).map(ScheduleFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function SchedulesListForUserResponseBodyToJSON(value?: SchedulesListForUserResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_path_references': value.escalation_path_references,
        'next_shifts': ((value.next_shifts as Array<any>).map(ScheduleEntryToJSON)),
        'on_call_forever': value.on_call_forever,
        'schedules': ((value.schedules as Array<any>).map(ScheduleToJSON)),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}

