import { SequenceBillingSchedule, StripeSubscription } from "@incident-io/api";
import { Loader, LocalDateTime } from "@incident-ui";
import { useAPI } from "src/utils/swr";

import { InfoTable } from "../../common/InfoTable";
import { StaffRoomTable } from "../../common/StaffRoomTable";
import { Value } from "../../common/Value";
import { StaffRoomOperation, StaffRoomOperationProps } from "../Operation";

export const ViewSubscriptionDetailsOperation: StaffRoomOperation = {
  id: "view-subscription-details",
  title: "Subscription details",
  emoji: "💰",
  description:
    "See information about the customer from both Stripe and Sequence.",
  drawerWidth: "medium",
  Component: ({ organisation }: StaffRoomOperationProps) => {
    const { data, error, isLoading } = useAPI("staffShowBillingDetails", {
      organisationSlug: organisation.organisation_slug,
    });

    if (isLoading || !data) {
      return <Loader />;
    }

    if (error) {
      return <div>Something went wrong.</div>;
    }

    const { stripe_details: stripeDetails, sequence_details: sequenceDetails } =
      data;

    if (!stripeDetails && !sequenceDetails) {
      return (
        <div>
          This organisation hasn&apos;t been linked to Stripe or Sequence.
        </div>
      );
    }

    return (
      <>
        {stripeDetails && (
          <>
            <InfoTable
              title="Stripe"
              widthClass="w-1/3"
              rows={[
                {
                  id: "customer_id",
                  name: "Customer ID",
                  value: (
                    <Value.IDValue
                      value={stripeDetails.customer_id}
                      href={`https://dashboard.stripe.com/customers/${stripeDetails.customer_id}`}
                    />
                  ),
                },
                {
                  id: "subscription_id",
                  name: "Subscription ID",
                  value: stripeDetails.subscription_id && (
                    <Value.IDValue
                      value={stripeDetails.subscription_id}
                      href={`https://dashboard.stripe.com/subscriptions/${stripeDetails.subscription_id}`}
                    />
                  ),
                },
                {
                  id: "scheduled_subscription_id",
                  name: "Scheduled sub. ID",
                  value: stripeDetails.scheduled_subscription_id && (
                    <Value.IDValue
                      value={stripeDetails.scheduled_subscription_id}
                      explanation="This subscription will be made live at the time shown below."
                    />
                  ),
                },
                {
                  id: "scheduled_subscription_id",
                  name: "Scheduled sub. start date",
                  value: stripeDetails.scheduled_subscription_start_date && (
                    <Value.DateTimeValue
                      value={stripeDetails.scheduled_subscription_start_date}
                    />
                  ),
                },
              ]}
            />
            <StaffRoomTable
              title="Subscriptions"
              data={stripeDetails.subscriptions}
              header={
                <>
                  <StaffRoomTable.Header>Subscription</StaffRoomTable.Header>
                  <StaffRoomTable.Header>Start date</StaffRoomTable.Header>
                  <StaffRoomTable.Header>Status</StaffRoomTable.Header>
                </>
              }
              renderRow={(s: StripeSubscription, idx: number) => {
                return (
                  <StaffRoomTable.Row key={idx}>
                    <StaffRoomTable.Cell>
                      <Value.IDValue
                        value={s.id}
                        href={`https://dashboard.stripe.com/subscriptions/${s.id}`}
                      />
                    </StaffRoomTable.Cell>
                    <StaffRoomTable.Cell>
                      <LocalDateTime timestamp={s.start_date} />
                    </StaffRoomTable.Cell>
                    <StaffRoomTable.Cell>
                      <Value.StringValue value={s.status} />
                    </StaffRoomTable.Cell>
                  </StaffRoomTable.Row>
                );
              }}
            />
          </>
        )}
        {sequenceDetails && (
          <>
            <InfoTable
              title="Sequence"
              rows={[
                {
                  id: "customer_id",
                  name: "Customer ID",
                  value: (
                    <Value.IDValue
                      value={sequenceDetails.id}
                      href={`https://dashboard.sequencehq.com/customers/${sequenceDetails.id}`}
                    />
                  ),
                },
                {
                  id: "legal_name",
                  name: "Legal name",
                  value: (
                    <Value.StringValue value={sequenceDetails.legal_name} />
                  ),
                },
              ]}
            />
            <StaffRoomTable
              title="Billing schedules"
              data={sequenceDetails.billing_schedules}
              header={
                <>
                  <StaffRoomTable.Header>ID</StaffRoomTable.Header>
                </>
              }
              renderRow={(s: SequenceBillingSchedule, idx: number) => {
                return (
                  <StaffRoomTable.Row key={idx}>
                    <StaffRoomTable.Cell>
                      <Value.IDValue
                        value={s.id}
                        href={`https://dashboard.sequencehq.com/billing-schedules/${s.id}`}
                      />
                    </StaffRoomTable.Cell>
                  </StaffRoomTable.Row>
                );
              }}
              emptyState={"No billing schedules found."}
            />
          </>
        )}
      </>
    );
  },
};
