/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentLearningsItem
 */
export interface IncidentLearningsItem {
    /**
     * Confidence level in this learning
     * @type {string}
     * @memberof IncidentLearningsItem
     */
    confidence: string;
    /**
     * Brief description of the learning
     * @type {string}
     * @memberof IncidentLearningsItem
     */
    description: string;
    /**
     * Detailed explanation of the learning
     * @type {string}
     * @memberof IncidentLearningsItem
     */
    detail: string;
    /**
     * Evidence supporting this learning
     * @type {string}
     * @memberof IncidentLearningsItem
     */
    evidence: string;
}

export function IncidentLearningsItemFromJSON(json: any): IncidentLearningsItem {
    return IncidentLearningsItemFromJSONTyped(json, false);
}

export function IncidentLearningsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLearningsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confidence': json['confidence'],
        'description': json['description'],
        'detail': json['detail'],
        'evidence': json['evidence'],
    };
}

export function IncidentLearningsItemToJSON(value?: IncidentLearningsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confidence': value.confidence,
        'description': value.description,
        'detail': value.detail,
        'evidence': value.evidence,
    };
}

