import { EngineScope, Resource, ScopeNameEnum } from "@incident-io/api";
import { getVariableScope, isEmptyBinding } from "@incident-shared/engine";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ButtonTheme, IconEnum } from "@incident-ui";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  CommsPlatform,
  prettyPrintCommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../../hooks/usePrimaryCommsPlatform";
import { tcx } from "../../../../utils/tailwind-classes";
import {
  AlertRouteFormSection,
  AlertRouteYesNoButtonGroup,
} from "../common/AlertRouteFormSection";
import { AlertRouteFormData, ChannelMessagesFormData } from "../common/types";
import { AlertChannelMessageDrawer } from "./AlertChannelMessageDrawer";

export const AlertRouteChannelMessagesSection = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: AlertRouteFormData;
  }>({
    isEditing: false,
  });
  const primaryCommsPlatform = usePrimaryCommsPlatform();
  const prettyPrimaryCommsPlatform =
    prettyPrintCommsPlatform(primaryCommsPlatform);

  const onSubmit = (data: ChannelMessagesFormData) => {
    formMethods.setValue<"enableAlertChannelMessages">(
      "enableAlertChannelMessages",
      data.enableAlertChannelMessages,
      {
        shouldDirty: true,
      },
    );

    formMethods.setValue<"expressions">("expressions", data.expressions, {});

    if (data.enableAlertChannelMessages) {
      formMethods.setValue<"channelConfigs">(
        "channelConfigs",
        data.channelConfigs,
        {
          shouldDirty: true,
        },
      );
    } else {
      // When disabling pulse channels, clear any related errors
      formMethods.clearErrors("enableAlertChannelMessages");
      formMethods.setValue<"channelConfigs">("channelConfigs", [], {
        shouldDirty: true,
      });
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredAlertChannelMessages">(
      "hasEverConfiguredAlertChannelMessages",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const hasEverConfiguredIncidents = formMethods.watch(
    "hasEverConfiguredIncidents",
  );
  const hasEverConfiguredAlertChannelMessages = formMethods.watch(
    "hasEverConfiguredAlertChannelMessages",
  );
  const enableAlertChannelMessages = formMethods.watch(
    "enableAlertChannelMessages",
  );
  const channelConfigs = formMethods.watch("channelConfigs");
  const hasChannelConfigs =
    channelConfigs &&
    channelConfigs.length > 0 &&
    channelConfigs.some((binding) =>
      binding.primary_comms_platform === CommsPlatform.Slack
        ? !isEmptyBinding(binding.slack_targets.binding)
        : !isEmptyBinding(binding.ms_teams_targets.binding),
    );

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const variableScope = getVariableScope(scopeWithExpressions, resources);

  return (
    <>
      {editingContext.isEditing && (
        <AlertChannelMessageDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          resources={resources}
        />
      )}
      <ExpressionsMethodsProvider<AlertRouteFormData, "expressions">
        expressionsMethods={expressionMethods}
        allowAllOfACatalogType={false}
        showExpressionNames={false}
      >
        <AlertRouteFormSection
          title={
            !hasEverConfiguredAlertChannelMessages
              ? `Send alerts to ${prettyPrimaryCommsPlatform}`
              : enableAlertChannelMessages
              ? `Send alerts to ${prettyPrimaryCommsPlatform}:`
              : `Don't send alerts to ${prettyPrimaryCommsPlatform}`
          }
          isCurrentFirstTimeStep={
            !hasEverConfiguredAlertChannelMessages && hasEverConfiguredIncidents
          }
          firstTimeContent={
            <>
              Choose {prettyPrimaryCommsPlatform} channels to send your alerts
              to.
            </>
          }
          disabled={
            hasEverConfiguredAlertChannelMessages && !enableAlertChannelMessages
          }
          stacked={true}
          icon={
            enableAlertChannelMessages || !hasEverConfiguredAlertChannelMessages
              ? primaryCommsPlatform === CommsPlatform.Slack
                ? IconEnum.Slack
                : IconEnum.MicrosoftTeams
              : primaryCommsPlatform === CommsPlatform.Slack
              ? IconEnum.SlackGreyscale
              : IconEnum.MicrosoftTeamsGreyscale
          }
          color={ColorPaletteEnum.Slate200}
          accessory={
            !hasEverConfiguredAlertChannelMessages ? (
              <AlertRouteYesNoButtonGroup
                disabled={!hasEverConfiguredIncidents}
                onChange={(shouldSendAlertChannelMessages) => {
                  formMethods.setValue<"enableAlertChannelMessages">(
                    "enableAlertChannelMessages",
                    shouldSendAlertChannelMessages,
                  );
                  if (shouldSendAlertChannelMessages) {
                    setEditingContext({
                      isEditing: true,
                      initialData: formMethods.getValues(),
                    });
                  } else {
                    formMethods.setValue<"hasEverConfiguredAlertChannelMessages">(
                      "hasEverConfiguredAlertChannelMessages",
                      true,
                    );
                  }
                }}
              />
            ) : (
              <GatedButton
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                theme={ButtonTheme.Naked}
                onClick={() => {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                }}
                icon={IconEnum.Edit}
                className="text-content-tertiary"
                title={`Send alerts to ${prettyPrimaryCommsPlatform}`}
                analyticsTrackingId="alert-routes-edit-pulse-channel"
              />
            )
          }
        >
          {hasChannelConfigs && (
            <>
              <div className={"flex flex-col gap-3"}>
                {channelConfigs
                  .filter((channelConfig) =>
                    channelConfig.primary_comms_platform === CommsPlatform.Slack
                      ? !isEmptyBinding(channelConfig.slack_targets.binding)
                      : !isEmptyBinding(channelConfig.ms_teams_targets.binding),
                  )
                  .map((channelConfig, index) => {
                    if (
                      channelConfig.primary_comms_platform ===
                        CommsPlatform.Slack &&
                      isEmptyBinding(channelConfig.slack_targets.binding)
                    ) {
                      return null;
                    }
                    if (
                      channelConfig.primary_comms_platform ===
                        CommsPlatform.MSTeams &&
                      isEmptyBinding(channelConfig.ms_teams_targets.binding)
                    ) {
                      return null;
                    }
                    if (
                      channelConfig.primary_comms_platform ===
                        CommsPlatform.Slack &&
                      channelConfig.slack_targets.channel_visibility ===
                        "private" &&
                      channelConfig.slack_targets.binding.value
                    ) {
                      channelConfig.slack_targets.binding.value.label =
                        channelConfig.slack_targets.binding.value.label
                          .replace("(Archived)", "")
                          .trimEnd();
                    }
                    return (
                      <ConditionGroupsList
                        key={index}
                        groups={channelConfig.condition_groups}
                        then={
                          <div
                            className={tcx(
                              "flex flex-col gap-2 p-3",
                              channelConfig.condition_groups.length > 0 &&
                                "border-t border-dotted border-stroke",
                            )}
                          >
                            <div className="font-mono text-xs-med text-content-tertiary w-full text-left">
                              SEND TO
                            </div>
                            <EngineBinding
                              key={index}
                              binding={
                                channelConfig.primary_comms_platform ===
                                CommsPlatform.Slack
                                  ? channelConfig.slack_targets.binding
                                  : channelConfig.ms_teams_targets.binding
                              }
                              resourceType={
                                channelConfig.primary_comms_platform ===
                                CommsPlatform.Slack
                                  ? channelConfig.slack_targets
                                      .channel_visibility === "public"
                                    ? 'CatalogEntry["SlackChannel"]'
                                    : "SlackChannel" // We show private Slack channel names because we do this in other places and want to remain consistent.
                                  : 'CatalogEntry["MicrosoftTeamsChannel"]'
                              }
                              variableScope={variableScope}
                              className="shadow-none"
                              displayExpressionAs="pill"
                            />
                          </div>
                        }
                      />
                    );
                  })}
              </div>
              <FormErrorMessage
                errors={formMethods.formState.errors}
                name={"channel_bindings"}
              />
            </>
          )}
        </AlertRouteFormSection>
      </ExpressionsMethodsProvider>
    </>
  );
};
