/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecard,
    EvaluationScorecardFromJSON,
    EvaluationScorecardFromJSONTyped,
    EvaluationScorecardToJSON,
} from './EvaluationScorecard';

/**
 * 
 * @export
 * @interface EvaluationBacktestEntry
 */
export interface EvaluationBacktestEntry {
    /**
     * ID of the parent backtest
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    backtest_id: string;
    /**
     * ID of the Copilot search that was created for this entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    copilot_search_id?: string;
    /**
     * When the entry was created
     * @type {Date}
     * @memberof EvaluationBacktestEntry
     */
    created_at: Date;
    /**
     * Errors logged against this backtest entry
     * @type {Array<string>}
     * @memberof EvaluationBacktestEntry
     */
    errors?: Array<string>;
    /**
     * 
     * @type {EvaluationScorecard}
     * @memberof EvaluationBacktestEntry
     */
    evaluation_scorecard?: EvaluationScorecard;
    /**
     * Unique identifier of the backtest entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    id: string;
    /**
     * ID of the incident this entry is based on
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    incident_id?: string;
    /**
     * When the entry was initialised (if it has been)
     * @type {Date}
     * @memberof EvaluationBacktestEntry
     */
    initialised_at?: Date;
    /**
     * Cost of the investigation
     * @type {number}
     * @memberof EvaluationBacktestEntry
     */
    investigation_cost_cents?: number;
    /**
     * Optional ID of the investigation that was created for this entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    investigation_id?: string;
    /**
     * State of the investigation that was created for this entry (if there's an investigation)
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    investigation_state?: EvaluationBacktestEntryInvestigationStateEnum;
    /**
     * When the entry was prepared (if it has been)
     * @type {Date}
     * @memberof EvaluationBacktestEntry
     */
    prepared_at?: Date;
    /**
     * Reference for this entry if applicable
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    reference?: string;
    /**
     * ID of the search ground truth to use for this entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    search_ground_truth_id?: string;
    /**
     * Current state of the entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    state: EvaluationBacktestEntryStateEnum;
    /**
     * Tags used to segment this entry
     * @type {Array<string>}
     * @memberof EvaluationBacktestEntry
     */
    tags: Array<string>;
    /**
     * Title for this entry
     * @type {string}
     * @memberof EvaluationBacktestEntry
     */
    title: string;
    /**
     * When the entry was last updated
     * @type {Date}
     * @memberof EvaluationBacktestEntry
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationBacktestEntryInvestigationStateEnum {
    Pending = 'pending',
    Working = 'working',
    Complete = 'complete',
    Error = 'error'
}/**
* @export
* @enum {string}
*/
export enum EvaluationBacktestEntryStateEnum {
    Pending = 'pending',
    Preparing = 'preparing',
    Prepared = 'prepared',
    Scoring = 'scoring',
    Scored = 'scored',
    Errored = 'errored'
}

export function EvaluationBacktestEntryFromJSON(json: any): EvaluationBacktestEntry {
    return EvaluationBacktestEntryFromJSONTyped(json, false);
}

export function EvaluationBacktestEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationBacktestEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_id': json['backtest_id'],
        'copilot_search_id': !exists(json, 'copilot_search_id') ? undefined : json['copilot_search_id'],
        'created_at': (new Date(json['created_at'])),
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'evaluation_scorecard': !exists(json, 'evaluation_scorecard') ? undefined : EvaluationScorecardFromJSON(json['evaluation_scorecard']),
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'initialised_at': !exists(json, 'initialised_at') ? undefined : (new Date(json['initialised_at'])),
        'investigation_cost_cents': !exists(json, 'investigation_cost_cents') ? undefined : json['investigation_cost_cents'],
        'investigation_id': !exists(json, 'investigation_id') ? undefined : json['investigation_id'],
        'investigation_state': !exists(json, 'investigation_state') ? undefined : json['investigation_state'],
        'prepared_at': !exists(json, 'prepared_at') ? undefined : (new Date(json['prepared_at'])),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'search_ground_truth_id': !exists(json, 'search_ground_truth_id') ? undefined : json['search_ground_truth_id'],
        'state': json['state'],
        'tags': json['tags'],
        'title': json['title'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationBacktestEntryToJSON(value?: EvaluationBacktestEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_id': value.backtest_id,
        'copilot_search_id': value.copilot_search_id,
        'created_at': (value.created_at.toISOString()),
        'errors': value.errors,
        'evaluation_scorecard': EvaluationScorecardToJSON(value.evaluation_scorecard),
        'id': value.id,
        'incident_id': value.incident_id,
        'initialised_at': value.initialised_at === undefined ? undefined : (value.initialised_at.toISOString()),
        'investigation_cost_cents': value.investigation_cost_cents,
        'investigation_id': value.investigation_id,
        'investigation_state': value.investigation_state,
        'prepared_at': value.prepared_at === undefined ? undefined : (value.prepared_at.toISOString()),
        'reference': value.reference,
        'search_ground_truth_id': value.search_ground_truth_id,
        'state': value.state,
        'tags': value.tags,
        'title': value.title,
        'updated_at': (value.updated_at.toISOString()),
    };
}

