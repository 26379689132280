import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import React from "react";
import { Route, Routes } from "react-router";

import { AIPage } from "./AIPage";
import { useAIFeatures } from "./useAIFeatures";

export const AIRoute = (): React.ReactElement => {
  const { features, loading } = useAIFeatures();
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/ai");

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <Routes>
      <Route path="" element={<AIPage />}>
        {features
          .filter((f) => f.Drawer != null)
          .map((feature) => (
            <Route
              key={feature.slug}
              path={feature.slug}
              element={
                <feature.Drawer
                  feature={feature}
                  onClose={onClose}
                  shouldShowNudges={false}
                />
              }
            />
          ))}
      </Route>
    </Routes>
  );
};
