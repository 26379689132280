import { StaffPermissionEnum } from "../../../../hooks/useCheckStaffPermissions";
import { StaffRoomOperation } from "../Operation";
import { ConfigureCallRoutesForm } from "./ConfigureCallRoutesForm";

export const ConfigureCallRouteOperation: StaffRoomOperation = {
  id: "configure-call-route",
  title: "Set up call routes",
  requiredPermission: StaffPermissionEnum.ManageCallRouteNumbers,
  emoji: "📞",
  Component: ConfigureCallRoutesForm,
};
