import { CatalogListTypesResponseBody } from "@incident-io/api";
import { getCatalogTypeaheadOptions } from "@incident-shared/catalog";
import { SelectOptionGroup } from "@incident-ui/Select/types";
import { ClientType } from "src/contexts/ClientContext";

import { buildTargets, groupOptions } from "./options";

export const loadTargets = (
  apiClient: ClientType,
  catalogTypes: CatalogListTypesResponseBody,
) => {
  const scheduleCatalogType = catalogTypes.catalog_types.find(
    (x) => x.type_name === "Schedule",
  );

  const getScheduleOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: scheduleCatalogType?.id || "",
  });

  return async (inputValue: string): Promise<SelectOptionGroup[]> => {
    const [{ options: optionsUsers }, optionsSchedules] = await Promise.all([
      apiClient.usersTypeahead({ query: inputValue }),
      getScheduleOptions(inputValue),
    ]);

    return groupOptions(buildTargets(optionsUsers, optionsSchedules));
  };
};
