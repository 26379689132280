import { AvailablePanel } from "@incident-io/api";
import { GenericErrorMessage } from "@incident-ui";
import _ from "lodash";
import React, { createContext, useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";
import { v4 as uuidV4 } from "uuid";

import { availablePanelToFormData } from "../common/marshall";
import {
  EditDashboardFormData,
  PanelFormData,
} from "../common/useInsightsContext";

type PanelsFormState = {
  panels: (PanelFormData & { key?: string; id?: string })[];
  availablePanels: AvailablePanel[];
  append: (panel: PanelFormData) => void;
  insert: (index: number, panel: PanelFormData) => void;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
};
const PanelsFormStateContext = createContext<PanelsFormState | null>(null);

// This context provider consolidates all usages of useFieldArray for panels
// This is because have separate instances of useFieldArray will cause
// state of sync issues, where calling append on one useFieldArray will not
// update the other useFieldArray
export const PanelsFormStateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: panelsData, error: panelsError } = useAPI(
    "insightsListAvailablePanels",
    {},
  );

  const formMethods = useFormContext<EditDashboardFormData>();
  const fieldArray = useFieldArray({
    control: formMethods.control,
    name: "panels",
  });
  const value: PanelsFormState = {
    panels: fieldArray.fields,
    ...fieldArray,
    availablePanels: panelsData?.panels || [],
  };
  if (panelsError) {
    return <GenericErrorMessage error={panelsError} />;
  }
  return (
    <PanelsFormStateContext.Provider value={value}>
      {children}
    </PanelsFormStateContext.Provider>
  );
};

export const usePanelsFormState = () => {
  const context = useContext(PanelsFormStateContext);
  if (!context) {
    throw new Error(
      "usePanelsFormState must be used within a PanelsFormStateContext",
    );
  }

  const textPanelPrototype = context.availablePanels.find(
    (panel) => panel.panel_type === "text",
  );

  return {
    ...context,
    appendTextPanel: () => {
      textPanelPrototype && context.append(newTextPanel(textPanelPrototype));
    },
    insertTextPanel: (index: number) => {
      textPanelPrototype &&
        context.insert(index, newTextPanel(textPanelPrototype));
    },
  };
};

const newTextPanel = (prototype: AvailablePanel) => {
  const copy = _.cloneDeep(prototype);
  copy.key = uuidV4();
  return availablePanelToFormData(copy);
};
