import { Route, Routes } from "react-router-dom";

import { SearchesListPage } from "./SearchesListPage";
import { SearchShowPage } from "./SearchShowPage";

export const SearchesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchesListPage />} />
      <Route path="/:id" element={<SearchShowPage />} />
    </Routes>
  );
};
