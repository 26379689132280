import { Team } from "@incident-io/api";
import { DisableConfirmationModal } from "@incident-shared/forms/DisableConfirmationModal/DisableConfirmationModal";
import { Callout, CalloutTheme, LoadingModal } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";

import { useAPI, useAPIMutation } from "../../../../utils/swr";
import { CatalogEntryReferences } from "../../../catalog/entry-list/CatalogEntryReferences";
import { useTeamsRevalidate } from "../convert/hooks";

type FormData = { id: string };

export const TeamsDeleteModal = ({
  team,
  onClose,
  onDelete,
}: {
  team: Team;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const revalidateTeams = useTeamsRevalidate();

  const {
    data: relationsData,
    isLoading: relationsLoading,
    error: relationsError,
  } = useAPI(
    "catalogListRelations",
    { id: team.id },
    {
      fallbackData: {
        catalog_relations: [],
      },
    },
  );

  const { trigger: deleteTeam, genericError } = useAPIMutation(
    "teamsList",
    {},
    async (apiClient, { id }: FormData) => {
      await apiClient.teamsDelete({
        id,
      });
    },
    {
      onSuccess: () => {
        revalidateTeams();
        onDelete();
      },
      showErrorToast: "Failed to delete team",
    },
  );

  if (relationsLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (relationsError || !relationsData || genericError) {
    return <ErrorModal onClose={onClose} />;
  }

  const catalogRelations = relationsData.catalog_relations;

  return (
    <DisableConfirmationModal
      onClose={onClose}
      onSubmit={() => deleteTeam({ id: team.id })}
      title={`Delete team`}
      typeToConfirmPhrase={team.name}
      submitText="Delete"
      helpText={
        <p className="text-sm text-pretty text-content-primary font-normal">
          Please type{" "}
          <span className="font-semibold text-primary-500">{team.name}</span>{" "}
          below to confirm.
        </p>
      }
    >
      <div className="flex flex-col gap-2">
        <Callout theme={CalloutTheme.Warning}>
          This action will result in permanent loss of data.
        </Callout>

        {catalogRelations.length > 0 && (
          <>
            <div className="!my-4">
              There are catalog entries that are linked to this team. Deleting
              this team will remove these links:
            </div>
            {catalogRelations.map((catalogRelation) => {
              return catalogRelation.attributes.map((attribute) => {
                return (
                  <div className="!my-2" key={team.name}>
                    <CatalogEntryReferences
                      catalogType={catalogRelation.catalog_type}
                      entries={attribute.entries}
                    />
                  </div>
                );
              });
            })}
          </>
        )}
      </div>
    </DisableConfirmationModal>
  );
};
