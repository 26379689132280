import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { DynamicMultiSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { SelectOption, SelectOptionOrGroup } from "@incident-ui/Select/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFormContext } from "react-hook-form";

import {
  EscalationProviderEnum,
  ExternalEscalationTarget,
  useClient,
} from "../../../contexts/ClientContext";
import { useAPI } from "../../../utils/swr";

export type PagerDutyFormData = {
  title: string;
  provider: EscalationProviderEnum.Pagerduty;
  pagerduty_service_id: string;
  targets: ExternalEscalationTarget[];
};

export const PagerDutyEscalateForm = () => {
  const { hidePdEscalationTargets } = useFlags();
  const formMethods = useFormContext<PagerDutyFormData>();

  // Find the catalog type ID for PD services
  const { data: catalogData, isLoading: loadingCatalogTypes } = useAPI(
    "catalogListTypes",
    {},
  );

  const pdServiceCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "PagerDutyService",
  );
  const pdUserCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "PagerDutyUser",
  );
  const pdEPCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "PagerDutyEscalationPolicy",
  );

  const apiClient = useClient();

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: pdServiceCatalogType?.id ?? "",
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: pdServiceCatalogType?.id ?? "",
  });

  // create a loader and hydrator that combines the PD users and escalation policies
  // into a single list of options. This will be used for the multi-select input
  // for the escalation targets.
  const loadUserOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: pdUserCatalogType?.id ?? "",
  });

  const loadEPOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: pdEPCatalogType?.id ?? "",
  });

  const loadEscalationTargetOptions = async (
    inputValue: string,
  ): Promise<SelectOptionOrGroup[]> => {
    const userOptions = await loadUserOptions(inputValue);
    const epOptions = await loadEPOptions(inputValue);

    return [
      {
        label: "Escalation Policies",
        options: (epOptions as SelectOption[]).map((option) => ({
          ...option,
          id: option.value,
          type: "EscalationPolicy",
        })),
      },
      {
        label: "Users",
        options: (userOptions as SelectOption[]).map((option) => ({
          ...option,
          id: option.value,
          type: "User",
        })),
      },
    ];
  };

  // When the affected service is set, automatically set the EP to be the
  // one referenced by the service. To do that, we'll need to fetch the
  // catalog entry for the service, and find the EP.
  const onServiceChange = async (serviceID: string | null) => {
    if (!serviceID) {
      return;
    }
    const data = await apiClient.catalogFindEntries({
      findEntriesRequestBody: {
        lookups: [
          {
            catalog_type_id: pdServiceCatalogType?.id ?? "",
            lookup_terms: [serviceID ?? ""],
          },
        ],
      },
    });

    const ep =
      data?.results?.[pdServiceCatalogType?.id ?? ""]?.[0]?.attribute_values[
        "escalation-policy"
      ]?.value ?? null;

    if (ep) {
      // If we find the EP, convert it into a valid select option that can be rendered
      // by the multi-select component.
      const policy: SelectOption & ExternalEscalationTarget = {
        label: ep.label,
        value: ep.value ?? "",
        id: ep.value ?? "",
        sort_key: ep.sort_key,
        type: "EscalationPolicy",
      };

      formMethods.setValue("targets", [policy]);
    }
  };

  if (loadingCatalogTypes) {
    return <Loader />;
  }

  if (!pdServiceCatalogType) {
    return (
      <GenericErrorMessage
        error={new Error("no PD service catalog type found")}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <div>
        <div className="text-base-bold">Who do you need?</div>
        <div className="text-xs-med text-content-primary pt-[2px] pb-1">
          {hidePdEscalationTargets
            ? "Pick the service, and we'll notify the people on-call"
            : "Pick the service and the people who should be notified."}
        </div>
      </div>
      <div className="bg-surface-secondary p-4 rounded-3 flex flex-col gap-6 mt-3">
        <DynamicSingleSelectV2
          label="Which service is affected?"
          loadOptions={loadDefaultValueOptions}
          hydrateOptions={hydrateDefaultValueOptions}
          name="pagerduty_service_id"
          formMethods={formMethods}
          onValueChange={onServiceChange}
        />
        {hidePdEscalationTargets ? null : (
          <DynamicMultiSelectWithObjV2
            label="Who should be notified?"
            loadOptions={loadEscalationTargetOptions}
            name="targets"
            formMethods={formMethods}
          />
        )}
      </div>
      <div className="text-base-bold pt-5 pb-1">Why do you need them?</div>
      <div className="text-xs-med text-content-primary pt-[2px] pb-1">
        This will be what they see or hear when we contact them.
      </div>
      <InputV2
        name="title"
        className="pb-3"
        formMethods={formMethods}
        placeholder="Something's wrong with the database"
      />
    </div>
  );
};
