/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UsersCreateRequestBody,
    UsersCreateRequestBodyFromJSON,
    UsersCreateRequestBodyToJSON,
    UsersCreateResponseBody,
    UsersCreateResponseBodyFromJSON,
    UsersCreateResponseBodyToJSON,
    UsersListAvailableSeatsAndRolesResponseBody,
    UsersListAvailableSeatsAndRolesResponseBodyFromJSON,
    UsersListAvailableSeatsAndRolesResponseBodyToJSON,
    UsersListOnCallResourcesResponseBody,
    UsersListOnCallResourcesResponseBodyFromJSON,
    UsersListOnCallResourcesResponseBodyToJSON,
    UsersListResponseBody,
    UsersListResponseBodyFromJSON,
    UsersListResponseBodyToJSON,
    UsersShowResponseBody,
    UsersShowResponseBodyFromJSON,
    UsersShowResponseBodyToJSON,
    UsersTypeaheadResponseBody,
    UsersTypeaheadResponseBodyFromJSON,
    UsersTypeaheadResponseBodyToJSON,
    UsersUpdateSeatAndRoleRequestBody,
    UsersUpdateSeatAndRoleRequestBodyFromJSON,
    UsersUpdateSeatAndRoleRequestBodyToJSON,
    UsersUpdateSeatAndRoleResponseBody,
    UsersUpdateSeatAndRoleResponseBodyFromJSON,
    UsersUpdateSeatAndRoleResponseBodyToJSON,
} from '../models';

export interface UsersCreateRequest {
    createRequestBody: UsersCreateRequestBody;
}

export interface UsersListRequest {
    email?: string;
    slackUserId?: string;
    pageSize?: number;
    after?: string;
    searchNameTerm?: string;
    seatType?: string;
}

export interface UsersListAvailableSeatsAndRolesRequest {
    userId?: string;
    forScim?: boolean;
}

export interface UsersListOnCallResourcesRequest {
    id: string;
}

export interface UsersShowRequest {
    id: string;
}

export interface UsersTypeaheadRequest {
    query?: string;
    idList?: Array<string>;
}

export interface UsersUpdateSeatAndRoleRequest {
    id: string;
    updateSeatAndRoleRequestBody: UsersUpdateSeatAndRoleRequestBody;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Invite a Slack user to use the incident.io dashboard
     * Create Users
     */
    async usersCreateRaw(requestParameters: UsersCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling usersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsersCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Invite a Slack user to use the incident.io dashboard
     * Create Users
     */
    async usersCreate(requestParameters: UsersCreateRequest, initOverrides?: RequestInit): Promise<UsersCreateResponseBody> {
        const response = await this.usersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of available users
     * List Users
     */
    async usersListRaw(requestParameters: UsersListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.slackUserId !== undefined) {
            queryParameters['slack_user_id'] = requestParameters.slackUserId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.searchNameTerm !== undefined) {
            queryParameters['search_name_term'] = requestParameters.searchNameTerm;
        }

        if (requestParameters.seatType !== undefined) {
            queryParameters['seat_type'] = requestParameters.seatType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Return list of available users
     * List Users
     */
    async usersList(requestParameters: UsersListRequest, initOverrides?: RequestInit): Promise<UsersListResponseBody> {
        const response = await this.usersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available seat types and roles for a particular user
     * ListAvailableSeatsAndRoles Users
     */
    async usersListAvailableSeatsAndRolesRaw(requestParameters: UsersListAvailableSeatsAndRolesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersListAvailableSeatsAndRolesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.forScim !== undefined) {
            queryParameters['for_scim'] = requestParameters.forScim;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/available_seats_and_roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListAvailableSeatsAndRolesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the available seat types and roles for a particular user
     * ListAvailableSeatsAndRoles Users
     */
    async usersListAvailableSeatsAndRoles(requestParameters: UsersListAvailableSeatsAndRolesRequest, initOverrides?: RequestInit): Promise<UsersListAvailableSeatsAndRolesResponseBody> {
        const response = await this.usersListAvailableSeatsAndRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches any on-call resources (schedules and escalation paths) that a given user is currently part of.
     * ListOnCallResources Users
     */
    async usersListOnCallResourcesRaw(requestParameters: UsersListOnCallResourcesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersListOnCallResourcesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersListOnCallResources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{id}/on_call_resources`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListOnCallResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Fetches any on-call resources (schedules and escalation paths) that a given user is currently part of.
     * ListOnCallResources Users
     */
    async usersListOnCallResources(requestParameters: UsersListOnCallResourcesRequest, initOverrides?: RequestInit): Promise<UsersListOnCallResourcesResponseBody> {
        const response = await this.usersListOnCallResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details for a specific user
     * Show Users
     */
    async usersShowRaw(requestParameters: UsersShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show details for a specific user
     * Show Users
     */
    async usersShow(requestParameters: UsersShowRequest, initOverrides?: RequestInit): Promise<UsersShowResponseBody> {
        const response = await this.usersShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List options for users
     * Typeahead Users
     */
    async usersTypeaheadRaw(requestParameters: UsersTypeaheadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersTypeaheadResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.idList) {
            queryParameters['id_list'] = requestParameters.idList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/typeahead`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersTypeaheadResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for users
     * Typeahead Users
     */
    async usersTypeahead(requestParameters: UsersTypeaheadRequest, initOverrides?: RequestInit): Promise<UsersTypeaheadResponseBody> {
        const response = await this.usersTypeaheadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a users seat type and/or role
     * UpdateSeatAndRole Users
     */
    async usersUpdateSeatAndRoleRaw(requestParameters: UsersUpdateSeatAndRoleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersUpdateSeatAndRoleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersUpdateSeatAndRole.');
        }

        if (requestParameters.updateSeatAndRoleRequestBody === null || requestParameters.updateSeatAndRoleRequestBody === undefined) {
            throw new runtime.RequiredError('updateSeatAndRoleRequestBody','Required parameter requestParameters.updateSeatAndRoleRequestBody was null or undefined when calling usersUpdateSeatAndRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/{id}/actions/update_seat_and_role`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsersUpdateSeatAndRoleRequestBodyToJSON(requestParameters.updateSeatAndRoleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersUpdateSeatAndRoleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a users seat type and/or role
     * UpdateSeatAndRole Users
     */
    async usersUpdateSeatAndRole(requestParameters: UsersUpdateSeatAndRoleRequest, initOverrides?: RequestInit): Promise<UsersUpdateSeatAndRoleResponseBody> {
        const response = await this.usersUpdateSeatAndRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
