/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TeamsAddMemberRequestBody,
    TeamsAddMemberRequestBodyFromJSON,
    TeamsAddMemberRequestBodyToJSON,
    TeamsAddResourceRequestBody,
    TeamsAddResourceRequestBodyFromJSON,
    TeamsAddResourceRequestBodyToJSON,
    TeamsConvertRequestBody,
    TeamsConvertRequestBodyFromJSON,
    TeamsConvertRequestBodyToJSON,
    TeamsCreateRequestBody,
    TeamsCreateRequestBodyFromJSON,
    TeamsCreateRequestBodyToJSON,
    TeamsCreateResponseBody,
    TeamsCreateResponseBodyFromJSON,
    TeamsCreateResponseBodyToJSON,
    TeamsGenerateAttributesConfigRequestBody,
    TeamsGenerateAttributesConfigRequestBodyFromJSON,
    TeamsGenerateAttributesConfigRequestBodyToJSON,
    TeamsGenerateAttributesConfigResponseBody,
    TeamsGenerateAttributesConfigResponseBodyFromJSON,
    TeamsGenerateAttributesConfigResponseBodyToJSON,
    TeamsGetSettingsResponseBody,
    TeamsGetSettingsResponseBodyFromJSON,
    TeamsGetSettingsResponseBodyToJSON,
    TeamsListMyTeamsResponseBody,
    TeamsListMyTeamsResponseBodyFromJSON,
    TeamsListMyTeamsResponseBodyToJSON,
    TeamsListResponseBody,
    TeamsListResponseBodyFromJSON,
    TeamsListResponseBodyToJSON,
    TeamsRemoveMemberRequestBody,
    TeamsRemoveMemberRequestBodyFromJSON,
    TeamsRemoveMemberRequestBodyToJSON,
    TeamsRemoveResourceRequestBody,
    TeamsRemoveResourceRequestBodyFromJSON,
    TeamsRemoveResourceRequestBodyToJSON,
    TeamsShowResponseBody,
    TeamsShowResponseBodyFromJSON,
    TeamsShowResponseBodyToJSON,
    TeamsUpdateRequestBody,
    TeamsUpdateRequestBodyFromJSON,
    TeamsUpdateRequestBodyToJSON,
    TeamsUpdateResponseBody,
    TeamsUpdateResponseBodyFromJSON,
    TeamsUpdateResponseBodyToJSON,
} from '../models';

export interface TeamsAddMemberRequest {
    id: string;
    addMemberRequestBody: TeamsAddMemberRequestBody;
}

export interface TeamsAddResourceRequest {
    id: string;
    addResourceRequestBody: TeamsAddResourceRequestBody;
}

export interface TeamsConvertRequest {
    convertRequestBody: TeamsConvertRequestBody;
}

export interface TeamsCreateRequest {
    createRequestBody: TeamsCreateRequestBody;
}

export interface TeamsDeleteRequest {
    id: string;
}

export interface TeamsGenerateAttributesConfigRequest {
    generateAttributesConfigRequestBody: TeamsGenerateAttributesConfigRequestBody;
}

export interface TeamsListRequest {
    pageSize?: number;
    after?: string;
}

export interface TeamsRemoveMemberRequest {
    id: string;
    removeMemberRequestBody: TeamsRemoveMemberRequestBody;
}

export interface TeamsRemoveResourceRequest {
    id: string;
    removeResourceRequestBody: TeamsRemoveResourceRequestBody;
}

export interface TeamsShowRequest {
    id: string;
}

export interface TeamsUpdateRequest {
    id: string;
    updateRequestBody: TeamsUpdateRequestBody;
}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     * Add a member to a team
     * AddMember Teams
     */
    async teamsAddMemberRaw(requestParameters: TeamsAddMemberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsAddMember.');
        }

        if (requestParameters.addMemberRequestBody === null || requestParameters.addMemberRequestBody === undefined) {
            throw new runtime.RequiredError('addMemberRequestBody','Required parameter requestParameters.addMemberRequestBody was null or undefined when calling teamsAddMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/{id}/actions/add_member`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsAddMemberRequestBodyToJSON(requestParameters.addMemberRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a member to a team
     * AddMember Teams
     */
    async teamsAddMember(requestParameters: TeamsAddMemberRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsAddMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Add a resource to a team
     * AddResource Teams
     */
    async teamsAddResourceRaw(requestParameters: TeamsAddResourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsAddResource.');
        }

        if (requestParameters.addResourceRequestBody === null || requestParameters.addResourceRequestBody === undefined) {
            throw new runtime.RequiredError('addResourceRequestBody','Required parameter requestParameters.addResourceRequestBody was null or undefined when calling teamsAddResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/{id}/actions/add_resource`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsAddResourceRequestBodyToJSON(requestParameters.addResourceRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a resource to a team
     * AddResource Teams
     */
    async teamsAddResource(requestParameters: TeamsAddResourceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsAddResourceRaw(requestParameters, initOverrides);
    }

    /**
     * Convert a catalog type to a team
     * Convert Teams
     */
    async teamsConvertRaw(requestParameters: TeamsConvertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.convertRequestBody === null || requestParameters.convertRequestBody === undefined) {
            throw new runtime.RequiredError('convertRequestBody','Required parameter requestParameters.convertRequestBody was null or undefined when calling teamsConvert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams_convert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsConvertRequestBodyToJSON(requestParameters.convertRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Convert a catalog type to a team
     * Convert Teams
     */
    async teamsConvert(requestParameters: TeamsConvertRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsConvertRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new team
     * Create Teams
     */
    async teamsCreateRaw(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling teamsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new team
     * Create Teams
     */
    async teamsCreate(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit): Promise<TeamsCreateResponseBody> {
        const response = await this.teamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a team
     * Delete Teams
     */
    async teamsDeleteRaw(requestParameters: TeamsDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a team
     * Delete Teams
     */
    async teamsDelete(requestParameters: TeamsDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Generate a string configuration for Team attributes to be used in the catalog-importer
     * GenerateAttributesConfig Teams
     */
    async teamsGenerateAttributesConfigRaw(requestParameters: TeamsGenerateAttributesConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsGenerateAttributesConfigResponseBody>> {
        if (requestParameters.generateAttributesConfigRequestBody === null || requestParameters.generateAttributesConfigRequestBody === undefined) {
            throw new runtime.RequiredError('generateAttributesConfigRequestBody','Required parameter requestParameters.generateAttributesConfigRequestBody was null or undefined when calling teamsGenerateAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/actions/generate_config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsGenerateAttributesConfigRequestBodyToJSON(requestParameters.generateAttributesConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsGenerateAttributesConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generate a string configuration for Team attributes to be used in the catalog-importer
     * GenerateAttributesConfig Teams
     */
    async teamsGenerateAttributesConfig(requestParameters: TeamsGenerateAttributesConfigRequest, initOverrides?: RequestInit): Promise<TeamsGenerateAttributesConfigResponseBody> {
        const response = await this.teamsGenerateAttributesConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the team settings associated with an organisations
     * GetSettings Teams
     */
    async teamsGetSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsGetSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsGetSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the team settings associated with an organisations
     * GetSettings Teams
     */
    async teamsGetSettings(initOverrides?: RequestInit): Promise<TeamsGetSettingsResponseBody> {
        const response = await this.teamsGetSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all teams in an organisation
     * List Teams
     */
    async teamsListRaw(requestParameters: TeamsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all teams in an organisation
     * List Teams
     */
    async teamsList(requestParameters: TeamsListRequest, initOverrides?: RequestInit): Promise<TeamsListResponseBody> {
        const response = await this.teamsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the teams that the current user is a member of
     * ListMyTeams Teams
     */
    async teamsListMyTeamsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsListMyTeamsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/teams/mine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsListMyTeamsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the teams that the current user is a member of
     * ListMyTeams Teams
     */
    async teamsListMyTeams(initOverrides?: RequestInit): Promise<TeamsListMyTeamsResponseBody> {
        const response = await this.teamsListMyTeamsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove a member from a team
     * RemoveMember Teams
     */
    async teamsRemoveMemberRaw(requestParameters: TeamsRemoveMemberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsRemoveMember.');
        }

        if (requestParameters.removeMemberRequestBody === null || requestParameters.removeMemberRequestBody === undefined) {
            throw new runtime.RequiredError('removeMemberRequestBody','Required parameter requestParameters.removeMemberRequestBody was null or undefined when calling teamsRemoveMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/{id}/actions/remove_member`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsRemoveMemberRequestBodyToJSON(requestParameters.removeMemberRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a member from a team
     * RemoveMember Teams
     */
    async teamsRemoveMember(requestParameters: TeamsRemoveMemberRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsRemoveMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a resource from a team
     * RemoveResource Teams
     */
    async teamsRemoveResourceRaw(requestParameters: TeamsRemoveResourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsRemoveResource.');
        }

        if (requestParameters.removeResourceRequestBody === null || requestParameters.removeResourceRequestBody === undefined) {
            throw new runtime.RequiredError('removeResourceRequestBody','Required parameter requestParameters.removeResourceRequestBody was null or undefined when calling teamsRemoveResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/{id}/actions/remove_resource`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsRemoveResourceRequestBodyToJSON(requestParameters.removeResourceRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a resource from a team
     * RemoveResource Teams
     */
    async teamsRemoveResource(requestParameters: TeamsRemoveResourceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsRemoveResourceRaw(requestParameters, initOverrides);
    }

    /**
     * Show details of a specific team
     * Show Teams
     */
    async teamsShowRaw(requestParameters: TeamsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show details of a specific team
     * Show Teams
     */
    async teamsShow(requestParameters: TeamsShowRequest, initOverrides?: RequestInit): Promise<TeamsShowResponseBody> {
        const response = await this.teamsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a team
     * Update Teams
     */
    async teamsUpdateRaw(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling teamsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a team
     * Update Teams
     */
    async teamsUpdate(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit): Promise<TeamsUpdateResponseBody> {
        const response = await this.teamsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
