import {
  EscalationPathsListSortByEnum,
  EscalationPathStatusForUser,
  ScopeNameEnum,
  SelectOption,
} from "@incident-io/api";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import {
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { Searcher } from "fast-fuzzy";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { Helmet } from "react-helmet";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useOutlet } from "react-router";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIInfinite } from "src/utils/swr";
import { useDebounce } from "use-debounce";
import { useLocalStorage } from "usehooks-ts";

import { useIdentity } from "../../contexts/IdentityContext";
import { EmptyState } from "../legacy/on-call/EmptyState";
import { useOnCallEntityCount } from "../legacy/on-call/utils";
import EmptyStateImg from "./images/escalation-path-empty-state.png";
import { EscalationPathsTable } from "./list/EscalationPathsTable";

export const EscalationPathsPage = () => {
  const {
    data: { pagination_meta: paginationMeta },
    isLoading: escalationPathsIsLoading,
    error: escalationPathsError,
  } = useAPI(
    "escalationPathsList",
    {
      // We're just using this for the count
      pageSize: 1,
    },
    {
      fallbackData: {
        escalation_paths: [],
        first_level_users: {},
        pagination_meta: {
          total_record_count: 0,
          page_size: 1,
        },
      },
    },
  );
  const { total_record_count: totalNumberOfEscalationPaths } =
    paginationMeta ?? {};

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const drawer = useOutlet();

  const { hasOnCall } = useProductAccess();

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  if (escalationPathsError || entityCountError) {
    return (
      <GenericErrorMessage error={escalationPathsError || entityCountError} />
    );
  }

  if (entityCountLoading || escalationPathsIsLoading) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user back to the get started page
  if (entityCount === 0 && !drawer) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  return (
    <SearchProvider>
      {/* This means any sub-routes (e.g. create/edit) get a fully-animated drawer */}
      <AnimatePresence>{drawer}</AnimatePresence>

      <Helmet title={"Escalation paths - incident.io"} />

      {totalNumberOfEscalationPaths === 0 ? (
        <EmptyState
          copy="Configure which schedules and individuals to notify and in what order when an escalation is triggered."
          imageSrc={EmptyStateImg}
          title="No escalation paths created"
          buttons={[
            {
              href: "/on-call/escalation-paths/create",
              analyticsTrackingId: "on-call-create-escalation-path",
              children: "Create a new path",
              isGated: true,
              gatingProps: {
                requiredScope: ScopeNameEnum.EscalationPathsCreate,
                disabledTooltipContent:
                  "You do not have permission to create escalation paths",
              },
            },
            {
              href: "/on-call/escalation-paths/import",
              analyticsTrackingId: "on-call-import-escalation-path",
              children: "Import escalation path",
              isGated: true,
              icon: IconEnum.Pagerduty,
              theme: ButtonTheme.Secondary,
              gatingProps: {
                requiredScope: ScopeNameEnum.EscalationPathsCreate,
                disabledTooltipContent:
                  "You do not have permission to create escalation paths",
              },
            },
          ]}
        />
      ) : (
        <div className={"flex flex-col gap-10 pb-5"}>
          <ConnectToAlertsBanner />
          <SearchProvider>
            <EscalationPathsPageInner />
          </SearchProvider>
        </div>
      )}
    </SearchProvider>
  );
};

const EscalationPathsPageInner = () => {
  const [selectedTeams, setSelectedTeams] = useState<SelectOption[]>([]);
  const { value: searchTerm } = useSearchContext();
  const [debouncedSearch] = useDebounce(searchTerm, 100);
  const isFilteringByTeam = selectedTeams.length > 0;

  const {
    responses: escalationPathsResponses,
    isLoading: escalationPathsIsLoading,
    error: escalationPathsError,
    loadMore,
    isFullyLoaded,
  } = useAPIInfinite("escalationPathsList", {
    sortBy: EscalationPathsListSortByEnum.NameAsc,
    pageSize: 10,
    teamIds: isFilteringByTeam ? selectedTeams.map((t) => t.value) : undefined,
  });

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: escalationPathsIsLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 100px 0px",
  });

  const escalationPaths = escalationPathsResponses.flatMap(
    (r) => r.escalation_paths,
  );

  const { identity } = useIdentity();
  const { data: escalationPathsForUserResp, isLoading: isLoadingUserEps } =
    useAPI("escalationPathsListForUserV3", {
      userId: identity?.user_id,
    });

  const escalationPathStatuses: EscalationPathStatusForUser[] | undefined =
    escalationPathsForUserResp?.escalation_paths;

  const userEscalationPathsFirstLevelUserIDs = _.chain(
    escalationPathsForUserResp?.escalation_paths,
  )
    .groupBy((ep) => ep.escalation_path.id)
    .mapValues((ep: EscalationPathStatusForUser[]) =>
      ep.flatMap((e: EscalationPathStatusForUser): string[] =>
        e.currently_on_call_users.map((u) => u.id),
      ),
    )
    .value();

  const userEscalationPaths =
    escalationPathStatuses?.map((ep) => ep.escalation_path) ?? [];

  if (escalationPathsError) {
    return <GenericErrorMessage error={escalationPathsError} />;
  }

  const searcher = new Searcher(escalationPaths, {
    keySelector: (escalationPath) => escalationPath.name,
  });

  let filteredEscalationPaths = escalationPaths;
  if (debouncedSearch) {
    filteredEscalationPaths = searcher.search(debouncedSearch);
  }

  const managementMeta = escalationPathsResponses.reduce(
    (acc, resp) => ({ ...acc, ...resp.management_meta }),
    {},
  );
  const firstLevelUserIds = escalationPathsResponses.reduce(
    (acc, resp) => ({ ...acc, ...resp.first_level_users }),
    {},
  );

  return (
    <>
      {userEscalationPaths.length > 0 && (
        <EscalationPathsTable
          escalationPaths={userEscalationPaths}
          managementMeta={managementMeta}
          firstLevelUserIDs={userEscalationPathsFirstLevelUserIDs}
          context={"MY_ESCALATION_PATHS"}
          showFilter={false}
          isLoading={isLoadingUserEps}
        />
      )}

      <EscalationPathsTable
        escalationPaths={filteredEscalationPaths ?? []}
        managementMeta={managementMeta}
        firstLevelUserIDs={firstLevelUserIds}
        context={"ALL_ESCALATION_PATHS"}
        showEmptyState
        infiniteScroll={{
          isFullyLoaded,
          ref: infiniteScrollRef,
          isLoading: escalationPathsIsLoading,
        }}
        showFilter={true}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        isLoading={escalationPathsIsLoading}
      />
    </>
  );
};

export const ConnectToAlertsBanner = () => {
  const localStorageKey = "connectToAlertsBannerDismissed";
  const [isDismissed, setDismissed] = useLocalStorage(localStorageKey, false);
  const handleDismiss = () => setDismissed(true);

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", {});

  const doNotShowConnectToAlertsBanner =
    alertRoutesLoading ||
    alertRoutesError ||
    !alertRoutesData ||
    alertRoutesData.alert_routes.some(
      (alertRoute) => alertRoute.escalation_enabled,
    );

  return isDismissed || doNotShowConnectToAlertsBanner ? (
    <></>
  ) : (
    <Callout
      theme={CalloutTheme.Info}
      iconOverride={IconEnum.SplitArrow}
      title="Automate escalations with alert routes"
      subtitle="Escalation paths won't be triggered unless they're connected to an alert source via an alert route."
      cta={
        <Button
          href="/alerts/routes"
          analyticsTrackingId="escalation-paths-go-to-alerts"
          theme={ButtonTheme.Secondary}
          size={BadgeSize.Large}
        >
          View alert routes
        </Button>
      }
      secondaryCta={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          onClick={() => handleDismiss()}
          title="Dismiss banner"
        >
          Dismiss
        </Button>
      }
      ctaPosition="bottom"
    />
  );
};
