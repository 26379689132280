import { ScopeNameEnum } from "@incident-io/api";
import { expressionReferencesId } from "@incident-shared/engine";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import {
  InvalidReasonEnum,
  IssueTemplateContextEnum,
  IssueTrackerProviderEnum,
  UnifiedIssueTemplate,
  useAllIssueTemplates,
} from "@incident-shared/issue-trackers";
import { useEnabledExportIssueTrackers } from "@incident-shared/issue-trackers/useEnabledExportIssueTrackers";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeTheme,
  EmptyState,
  IconEnum,
  LoadingBar,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { orderBy } from "lodash";
import { useClient } from "src/contexts/ClientContext";
import { useAPIRefetch } from "src/utils/swr";

import { ISSUE_TEMPLATE_CONTEXTS } from "./config";
import { useIssueSyncConfig } from "./useIssueSyncConfig";

export const IssueTemplatesList = ({
  context,
}: {
  context: IssueTemplateContextEnum;
}): React.ReactElement => {
  const { installedTrackers, isLoading: trackersLoading } =
    useEnabledExportIssueTrackers(context);
  const { allTemplates, isLoading: templatesLoading } =
    useAllIssueTemplates(context);

  const { syncConfig, syncConfigLoading } = useIssueSyncConfig(context);

  if (trackersLoading || templatesLoading || syncConfigLoading) {
    return <LoadingBar className="h-16" />;
  }

  const { icon, nounPlural } = ISSUE_TEMPLATE_CONTEXTS[context];

  if (allTemplates.length === 0) {
    return (
      <EmptyState
        icon={icon}
        content={`You have not created any ${nounPlural.toLowerCase()} yet.`}
      />
    );
  }

  return (
    <StackedList>
      {orderBy(allTemplates, ["provider", "name"], ["asc", "asc"]).map(
        (template) => {
          const templateInUse = expressionReferencesId(
            syncConfig?.issue_template_expression,
            template.id,
          );

          const hasInstalledProvider = installedTrackers.find(
            (provider) =>
              provider ===
              (template.provider as unknown as IssueTrackerProviderEnum),
          );

          return (
            <IssueTemplateRow
              key={template.id}
              template={template}
              inUse={templateInUse}
              isLastTemplateForAutoExport={
                !!(allTemplates.length === 1 && syncConfig?.enabled)
              }
              disabled={!hasInstalledProvider}
            />
          );
        },
      )}
    </StackedList>
  );
};

const IssueTemplateRow = ({
  template,
  inUse,
  disabled,
  isLastTemplateForAutoExport,
}: {
  template: UnifiedIssueTemplate;
  inUse: boolean;
  disabled: boolean;
  isLastTemplateForAutoExport: boolean;
}): React.ReactElement => {
  const showToast = useToast();
  const apiClient = useClient();

  const revalidate = useAPIRefetch(
    template.version === 2
      ? "issueTrackersV2ListIssueTemplates"
      : "issueTrackerIssueTemplatesList",
    {},
  );
  const onDelete = async () => {
    try {
      if (template.version === 1) {
        await apiClient.issueTrackerIssueTemplatesDestroy({ id: template.id });
      } else {
        await apiClient.issueTrackersV2DestroyIssueTemplate({
          id: template.id,
        });
      }
      await revalidate();
    } catch (e) {
      showToast({
        theme: ToastTheme.Error,
        title: "Something when wrong while deleting the template.",
      });
    }
  };

  const { icon, label: providerLabel } = INTEGRATION_CONFIGS[template.provider];

  const { nounSingular, editHref } = ISSUE_TEMPLATE_CONTEXTS[template.context];

  return (
    <SettingsListItem
      title={template.name}
      badgeProps={
        template.invalid_reason !== InvalidReasonEnum.Empty
          ? {
              theme: BadgeTheme.Error,
              icon: IconEnum.Warning,
            }
          : undefined
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        edit: {
          editHref: editHref(template),
        },
        delete: {
          resourceTitle: template.name,
          isGatedText: isLastTemplateForAutoExport
            ? "You cannot delete your last template while auto-export is enabled"
            : inUse
            ? "Cannot delete a template used in a condition for auto-exporting"
            : undefined,
          deleteConfirmationTitle: `Delete ${nounSingular.toLowerCase()}`,
          deleteConfirmationContent: (
            <>
              Are you sure you wish to delete the{" "}
              <span className="font-bold">{template.name}</span> template?
            </>
          ),
          onDelete,
        },
      }}
      iconColor={ColorPaletteEnum.SlateOnWhite}
      icon={icon}
      disabled={disabled}
      disabledExplanation={
        <>
          To modify this template you need to set up an integration with{" "}
          {providerLabel}.
        </>
      }
    />
  );
};
