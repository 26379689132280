import {
  CatalogResourceIconEnum,
  CatalogTypeIconEnum,
  CatalogUpdateTypeRequestBodyIconEnum,
  FormFieldIconEnum,
  ReferenceIconEnum,
  ResourceFieldConfigIconEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { sendWarningToSentry } from "src/utils/utils";

import * as Icons from "./icons";

export enum FrontEndIconEnum {
  Activity = "activity",
  Add = "add",
  AddAbove = "add-above",
  AddBelow = "add-below",
  AddCircle = "add-circle",
  AddIncidentType = "add-incident-type",
  Alert = "alert",
  AlertPriority = "alert-priority",
  AlertRoute = "alert-route",
  AlignArrowLeft = "align-arrow-left",
  Announcement = "announcement",
  Archive = "archive",
  ArrowCircleDown = "arrow-circle-down",
  ArrowCircleUp = "arrow-circle-up",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowSpin = "arrow-spin",
  ArrowThreeUp = "arrow-three-up",
  Asana = "asana",
  AssistantAIGradient = "assistant-ai-gradient",
  AtlassianStatuspage = "statuspage",
  Team = "team",
  Attachment = "attachment",
  Automation = "automation",
  AzureDevOps = "azure-devops",
  AzureMonitor = "azure-monitor",
  Back = "back",
  BacklinkLarge = "backlinks-large",
  BacklinkSmall = "backlinks-small",
  Backstage = "backstage",
  BarChart = "bar-chart",
  BellRinging = "bell-ringing",
  Billing = "billing",
  Bolt = "bolt",
  Book = "book",
  Bookmark = "bookmark",
  Box = "box",
  Browser = "browser",
  Bug = "bug",
  BugSnag = "bugsnag",
  Buildings = "buildings",
  Bulb = "bulb",
  CalendarClock = "calendar-clock",
  CalendarPlus = "calendar-plus",
  Call = "call",
  CallPlus = "call-plus",
  CaratRight = "carat-right",
  Chart = "chart",
  ChartCrossedOut = "chart-crossed-out",
  Checklist = "checklist",
  Checkly = "checkly",
  Checkmark = "checkmark",
  ChevronDoubleDown = "chevron-double-down",
  ChevronDoubleUp = "chevron-double-up",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUp = "chevron-up",
  Chronosphere = "chronosphere",
  Circle = "circle",
  ClickUp = "click-up",
  Clipboard = "clipboard",
  Clock = "clock",
  ClockRotate = "clock-rotate",
  Close = "close",
  CloseCircle = "close-circle",
  Cloud = "cloud",
  CloudBroken = "cloud-broken",
  CloudWatch = "cloudwatch",
  Cloudflare = "cloudflare",
  Code = "code",
  CodeBlock = "code-block",
  Cog = "cog",
  Collapse = "collapse",
  ComplexList = "complex-list",
  Compare = "compare",
  Components = "components",
  Confluence = "confluence",
  Copy = "copy",
  Cortex = "cortex",
  Cronitor = "cronitor",
  Crowdstrike = "crowdstrike",
  CyberArk = "cyberark",
  Dashboard = "dashboard",
  Database = "database",
  Datadog = "datadog",
  DecisionFlows = "decision-flows",
  Delete2 = "delete2",
  Dice = "dice",
  DotsHorizontal = "dots-horizontal",
  DotsHorizontalNopad = "dots-horizontal-nopad",
  DotsVertical = "dots-vertical",
  DotsVerticalNopad = "dots-vertical-nopad",
  DottedCircle = "dotted-circle",
  Download = "download",
  DownloadCloud = "download-cloud",
  Draggable = "draggable",
  DrawerRight = "sidebar-right",
  Dropbox = "dropbox",
  Dynatrace = "dynatrace",
  Edit = "edit",
  Elastic = "elastic",
  Email = "email",
  EnvelopeCheck = "envelope-check",
  EnvelopeClock = "envelope-clock",
  EscalationPath = "escalation-path",
  Exclamation = "exclamation",
  ExclamationMark = "exclamation-mark",
  Exit = "exit-icon",
  Expand = "expand",
  Expel = "expel",
  Export = "export",
  Expression = "expression",
  ExternalLink = "external-link",
  ExternalLinkCircle = "external-link-circle",
  Eye = "eye",
  FakeCheckbox = "fake-checkbox",
  FakeCheckboxChecked = "fake-checkbox-checked",
  FakeCheckboxCross = "fake-checkbox-cross",
  FakeRadioChecked = "fake-radio-checked",
  FakeRadioUnchecked = "fake-radio-unchecked",
  FastForward = "fast-forward",
  File = "file",
  FileMinus = "file-minus",
  FilePlus = "file-plus",
  Files = "files",
  Filter = "filter",
  FilterBars = "filter-bars",
  Fire = "fire",
  Flag = "flag",
  Folder = "folder",
  FolderXMark = "folder-x-mark",
  FolderNoPadding = "folder-no-padding",
  FolderOpen = "folder-open",
  FollowUpsAIGradient = "follow-ups-ai-gradient",
  Gift = "gift",
  GitBranch = "git-branch",
  GitBranchNew = "git-branch-new",
  Github = "github",
  Gitlab = "gitlab",
  Globe = "globe",
  Google = "google",
  GoogleCalendar = "google-calendar",
  GoogleCloud = "google-cloud",
  GoogleDocs = "google-docs",
  GoogleMeet = "google-meet",
  Grafana = "grafana",
  Hand = "hand",
  Heart = "heart",
  Headphones = "headphones",
  Help = "help-circle",
  Hide = "hide",
  HighAlertPriority = "high-alert-priority",
  History = "history",
  Home = "home",
  Honeycomb = "honeycomb",
  Hourglass = "hourglass",
  Http = "http",
  IncidentFlame = "incident-flame",
  IncidentFlameNoFill = "incident-flame-no-fill",
  IncidentFlameWithFill = "incident-flame-with-fill",
  IncidentStatusActive = "incident-status-active",
  IncidentStatusCancelled = "incident-status-cancelled",
  IncidentStatusClosed = "incident-status-closed",
  IncidentStatusDeclined = "incident-status-declined",
  IncidentStatusEmpty = "incident-status-empty",
  IncidentStatusMerged = "incident-status-merged",
  IncidentStatusPaused = "incident-status-paused",
  IncidentStatusPostIncident = "incident-status-post-incident",
  IncidentStatusTriage = "incident-status-triage",
  IncidentTicket = "incident-ticket",
  IncidentTypeAI = "incident-type-ai",
  IncidentTypeAIGradient = "incident-type-ai-gradient",
  IncomingPhoneCall = "incoming-phone-call",
  IndentationLeft = "indentation-left",
  IndentationRight = "indentation-right",
  Info = "info",
  InputField = "input-field",
  Integrations = "integrations",
  Intercom = "intercom",
  InsightsDashboard = "insights-dashboard",
  Jira = "jira",
  JiraServer = "jira_server",
  JumpCloud = "jumpcloud",
  Kanban = "kanban",
  Key = "key",
  Keyboard = "keyboard",
  Layer = "layer",
  LayersBulk = "layers-bulk",
  Linear = "linear",
  Link = "link",
  LinkAI = "link-ai",
  LinkAIGradient = "link-ai-gradient",
  LinkBreak = "link-break",
  List = "list",
  Loader = "loader",
  LockClosed = "lock-closed",
  LockOpen = "lock-open",
  Loop = "loop",
  LowAlertPriority = "low-alert-priority",
  MagicWand = "magic-wand",
  Maintenance = "maintenance",
  MapIcon = "map",
  Maximize = "maximize",
  Medal1 = "medal-1",
  Medal2 = "medal-2",
  Medal3 = "medal-3",
  MediumAlertPriority = "medium-alert-priority",
  Menu = "menu",
  Merge = "merge",
  MergedArrow = "merged-arrow",
  Microsoft = "microsoft",
  MicrosoftTeams = "microsoft-teams",
  MicrosoftTeamsGreyscale = "microsoft-teams-greyscale",
  Minus = "minus",
  MinusCircle = "minus-circle",
  Money = "money",
  MonteCarlo = "monte-carlo",
  MortarBoard = "mortar-board",
  Mug = "mug",
  MultiSelect = "multi-select",
  Mute = "mute",
  Nagios = "nagios",
  NestedInfo = "nested-info",
  New = "new",
  NewRelic = "new-relic",
  Notification = "notification",
  Notion = "notion",
  Nudge = "nudge",
  NumberBox1Filled = "number-box-1-filled",
  NumberBox2Filled = "number-box-2-filled",
  NumberBox3Filled = "number-box-3-filled",
  NumberOrderedList = "number-ordered-list",
  Numeric = "number-input",
  Okta = "okta",
  OnCall = "on-call",
  OneLogin = "onelogin",
  OpsLevel = "opslevel",
  Opsgenie = "opsgenie",
  Outlook = "outlook",
  Pagerduty = "pagerduty",
  Panther = "panther",
  Pause = "pause",
  PiggyBank = "piggy-bank",
  Pin = "pin",
  PingFederate = "ping-federate",
  Pingdom = "pingdom",
  Phone = "phone",
  PlayOverlay = "play-overlay",
  PRTG = "prtg",
  Priority = "priority",
  Prometheus = "prometheus",
  QuestionMark = "question-mark",
  Quickstart = "quickstart",
  Quote = "quote",
  QuoteAI = "quote-ai",
  QuoteAIGradient = "quote-ai-gradient",
  Rank = "rank",
  Refresh = "refresh",
  Refresh1 = "refresh1",
  Refresh2 = "refresh2",
  RepeatOnce = "repeat-once",
  Resume = "resume",
  Retrospective = "retrospective",
  Rhombus = "rhombus",
  Rippling = "rippling",
  Robot = "robot",
  Runscope = "runscope",
  Salesforce = "salesforce",
  Scim = "scim",
  Scribe = "scribe",
  Send = "send",
  Sentry = "sentry",
  Server = "server",
  ServiceNow = "service-now",
  SeverityCritical = "severity-critical",
  SeverityHigh = "severity-high",
  SeverityLow = "severity-low",
  SeverityMedium = "severity-medium",
  Share = "share",
  Shield = "shield",
  ShieldExclamation = "shield-exclamation",
  Shortcut = "shortcut",
  SidebarLeftHide = "sidebar-left-hide",
  SidebarLeftShow = "sidebar-left-show",
  SingleSelect = "single-select",
  Siren = "siren",
  Slack = "slack",
  SlackGreyscale = "slack-greyscale",
  Sliders = "sliders",
  Sns = "sns",
  SolarWinds = "solarwinds",
  Sort = "sort",
  Sparkles = "sparkles",
  SparklesColoured = "sparkles-coloured",
  SpeechImportant = "speech-important",
  SplitArrow = "split-arrow",
  Splunk = "splunk",
  Star = "star",
  StatusCake = "status-cake",
  StatusChevron = "status-chevron",
  StatusPage = "status-page",
  Stop = "stop",
  Store = "store",
  Subscription = "subscription",
  Success = "success",
  SumoLogic = "sumo-logic",
  SwitchHorizontal = "switch-horizontal",
  Sync = "sync",
  TShirt = "t-shirt",
  Table = "table",
  Terraform = "terraform",
  TerraformStrikethrough = "terraform-strikethrough",
  Text = "text",
  TextAlignLeft = "text-align-left",
  TextBold = "text-bold",
  TextInput = "text-input",
  TextItalic = "text-italic",
  TextStrikeThrough = "text-strikethrough",
  ThumbsDown = "thumbs-down",
  ThumbsUp = "thumbs-up",
  Tick = "check",
  TickCircle = "tick-circle",
  Timer = "timer",
  Timestamp = "timestamp",
  ToggleLeft = "toggle-left",
  ToggleRight = "toggle-right",
  TranscriptAIGradient = "transcript-ai-gradient",
  TrendDown = "trend-down",
  TrendNeutral = "trend-neutral",
  TrendUp = "trend-up",
  Triage = "triage",
  Trophy = "trophy",
  Undo = "undo",
  UnorderedList = "unordered-list",
  Uptime = "uptime",
  UserAdd = "user-add",
  UserAI = "user-ai",
  UserAIGradient = "user-ai-gradient",
  Urgency = "urgency",
  Vanta = "vanta",
  Variable = "variable",
  Vibrate = "vibrate",
  View = "show",
  Warning = "warning",
  WarningCircle = "warning-circle",
  Workflows = "workflows",
  WorkingHours = "working-hours",
  Zapier = "zapier",
  Zendesk = "zendesk",
  Zoom = "zoom",
}

type CatalogIconEnum = CatalogTypeIconEnum | CatalogResourceIconEnum;

type IconEnum =
  | FormFieldIconEnum
  | FrontEndIconEnum
  | CatalogIconEnum
  | ResourceFieldConfigIconEnum
  | ReferenceIconEnum;

const IconEnum = {
  ...FormFieldIconEnum,
  ...FrontEndIconEnum,
};

const iconLookup: {
  [key in IconEnum]: (props: React.ComponentProps<"svg">) => JSX.Element;
} = {
  [IconEnum.Action]: Icons.Checklist,
  [IconEnum.Activity]: Icons.Activity,
  [IconEnum.AddAbove]: Icons.AddAbove,
  [IconEnum.AddBelow]: Icons.AddBelow,
  [IconEnum.AddCircle]: Icons.AddCircle,
  [IconEnum.AddIncidentType]: Icons.AddIncidentType,
  [IconEnum.Add]: Icons.Add,
  [IconEnum.AlertRoute]: Icons.AlertRoute,
  [IconEnum.Alert]: Icons.Alert,
  [IconEnum.AlertPriority]: Icons.AlertPriority,
  [IconEnum.AlignArrowLeft]: Icons.AlignArrowLeft,
  [IconEnum.Announcement]: Icons.Announcement,
  [IconEnum.Archive]: Icons.Archive,
  [IconEnum.ArrowCircleDown]: Icons.ArrowDownCircle,
  [IconEnum.ArrowCircleUp]: Icons.ArrowUpCircle,
  [IconEnum.ArrowLeft]: Icons.ArrowLeft,
  [IconEnum.ArrowRight]: Icons.ArrowRight,
  [IconEnum.ArrowSpin]: Icons.ArrowsSpin,
  [IconEnum.ArrowThreeUp]: Icons.ThreeArrowsUp,
  [IconEnum.Asana]: Icons.Asana,
  [IconEnum.AssistantAIGradient]: Icons.AssistantAiGradient,
  [IconEnum.AtlassianStatuspage]: Icons.AtlassianStatuspage,
  [IconEnum.Attachment]: Icons.Attachment,
  [IconEnum.Automation]: Icons.Automation,
  [IconEnum.AzureDevOps]: Icons.AzureDevops,
  [IconEnum.AzureMonitor]: Icons.AzureMonitor,
  [IconEnum.Back]: Icons.Back,
  [IconEnum.BacklinkLarge]: Icons.BacklinkLarge, // Tom believes that these don't scale nicely, so we use a different icon depending on the size
  [IconEnum.BacklinkSmall]: Icons.BacklinkSmall, // Same as above
  [IconEnum.Backstage]: Icons.Backstage,
  [IconEnum.BarChart]: Icons.BarChart,
  [IconEnum.BellRinging]: Icons.BellRinging,
  [IconEnum.Billing]: Icons.Billing,
  [IconEnum.Bolt]: Icons.Bolt,
  [IconEnum.Book]: Icons.Book,
  [IconEnum.Bookmark]: Icons.Bookmark,
  [IconEnum.Box]: Icons.Box,
  [IconEnum.Briefcase]: Icons.Briefcase,
  [IconEnum.Browser]: Icons.Browser,
  [IconEnum.BugSnag]: Icons.Bugsnag,
  [IconEnum.Bug]: Icons.Bug,
  [IconEnum.Buildings]: Icons.SlackTeam,
  [IconEnum.Bulb]: Icons.Bulb,
  [IconEnum.Calendar]: Icons.Calendar,
  [IconEnum.CalendarClock]: Icons.CalendarClock,
  [IconEnum.CalendarPlus]: Icons.CalendarPlus,
  [IconEnum.CallPlus]: Icons.CallPlus,
  [IconEnum.Call]: Icons.Call,
  [IconEnum.PRTG]: Icons.Prtg,
  [IconEnum.CaratRight]: Icons.CaratRight,
  [IconEnum.ChartCrossedOut]: Icons.ChartCrossedOut,
  [IconEnum.Chart]: Icons.Chart,
  [IconEnum.Checklist]: Icons.Checklist,
  [IconEnum.Checkly]: Icons.Checkly,
  [IconEnum.Checkmark]: Icons.Checkmark,
  [IconEnum.ChevronDoubleDown]: Icons.ChevronDoubleDown,
  [IconEnum.ChevronDoubleUp]: Icons.ChevronDoubleUp,
  [IconEnum.ChevronDown]: Icons.ChevronDown,
  [IconEnum.ChevronLeft]: Icons.ChevronLeft,
  [IconEnum.ChevronRight]: Icons.ChevronRight,
  [IconEnum.ChevronUp]: Icons.ChevronUp,
  [IconEnum.Chronosphere]: Icons.Chronosphere,
  [IconEnum.Circle]: Icons.Circle,
  [IconEnum.ClickUp]: Icons.ClickUp,
  [IconEnum.Clipboard]: Icons.Clipboard,
  [IconEnum.ClockRotate]: Icons.ClockRotate,
  [IconEnum.Clock]: Icons.Clock,
  [IconEnum.CloseCircle]: Icons.CloseCircle,
  [IconEnum.Close]: Icons.Close,
  [IconEnum.CloudBroken]: Icons.CloudBroken,
  [IconEnum.CloudWatch]: Icons.Cloudwatch,
  [IconEnum.Cloud]: Icons.Cloud,
  [IconEnum.Cloudflare]: Icons.Cloudflare,
  [IconEnum.CodeBlock]: Icons.CodeBlock,
  [IconEnum.Code]: Icons.Code,
  [IconEnum.Cog]: Icons.Cog,
  [IconEnum.Collapse]: Icons.ChevronDownUp,
  [IconEnum.ComplexList]: Icons.ComplexList,
  [IconEnum.Compare]: Icons.Compare,
  [IconEnum.Components]: Icons.Components,
  [IconEnum.Confluence]: Icons.Confluence,
  [IconEnum.Copy]: Icons.Copy,
  [IconEnum.Cortex]: Icons.Cortex,
  [IconEnum.Cronitor]: Icons.Cronitor,
  [IconEnum.Crowdstrike]: Icons.Crowdstrike,
  [IconEnum.CustomField]: Icons.CustomField,
  [IconEnum.CyberArk]: Icons.Cyberark,
  [IconEnum.Dashboard]: Icons.Dashboard,
  [IconEnum.Database]: Icons.Database,
  [IconEnum.Datadog]: Icons.Datadog,
  [IconEnum.DecisionFlows]: Icons.DecisionFlow,
  [IconEnum.Delete2]: Icons.Delete2,
  [IconEnum.Delete]: Icons.Delete,
  [IconEnum.Dice]: Icons.Dice,
  [IconEnum.Doc]: Icons.Doc,
  [IconEnum.DotsHorizontalNopad]: Icons.DotsHorizontalNopad,
  [IconEnum.DotsHorizontal]: Icons.DotsHorizontal,
  [IconEnum.DotsVerticalNopad]: Icons.DotsVerticalNopad,
  [IconEnum.DotsVertical]: Icons.DotsVertical,
  [IconEnum.DottedCircle]: Icons.DottedCircle,
  [IconEnum.DownloadCloud]: Icons.DownloadCloud,
  [IconEnum.Download]: Icons.Download,
  [IconEnum.Draggable]: Icons.SixDots,
  [IconEnum.DrawerRight]: Icons.RightSidebar,
  [IconEnum.Dropbox]: Icons.Dropbox,
  [IconEnum.Dynatrace]: Icons.Dynatrace,
  [IconEnum.Edit]: Icons.Pencil,
  [IconEnum.Elastic]: Icons.Elastic,
  [IconEnum.Email]: Icons.Email,
  [IconEnum.EnvelopeCheck]: Icons.EnvelopeCheck,
  [IconEnum.EnvelopeClock]: Icons.EnvelopeClock,
  [IconEnum.Escalate]: Icons.ThreeArrowsUp,
  [IconEnum.EscalationPath]: Icons.EscalationPath,
  [IconEnum.ExclamationMark]: Icons.ExclamationMark,
  [IconEnum.Exclamation]: Icons.Exclamation,
  [IconEnum.Exit]: Icons.ExitIcon,
  [IconEnum.Expand]: Icons.ChevronUpDown,
  [IconEnum.Expel]: Icons.Expel,
  [IconEnum.Export]: Icons.Export,
  [IconEnum.Expression]: Icons.Expression,
  [IconEnum.ExternalLinkCircle]: Icons.ExternalLinkCircle,
  [IconEnum.ExternalLink]: Icons.ExternalLink,
  [IconEnum.Eye]: Icons.Eye,
  [IconEnum.FakeCheckboxChecked]: Icons.FakeCheckboxChecked,
  [IconEnum.FakeCheckboxCross]: Icons.FakeCheckboxCross,
  [IconEnum.FakeCheckbox]: Icons.FakeCheckbox,
  [IconEnum.FakeRadioChecked]: Icons.FakeRadioChecked,
  [IconEnum.FakeRadioUnchecked]: Icons.FakeRadioUnchecked,
  [IconEnum.FastForward]: Icons.FastForward,
  [IconEnum.FileMinus]: Icons.FileMinus,
  [IconEnum.FilePlus]: Icons.FilePlus,
  [IconEnum.File]: Icons.File,
  [IconEnum.Files]: Icons.Files,
  [IconEnum.Filter]: Icons.Filter,
  [IconEnum.FilterBars]: Icons.FilterBars,
  [IconEnum.Fire]: Icons.Fire,
  [IconEnum.Flag]: Icons.Flag,
  [IconEnum.FolderNoPadding]: Icons.FolderNoPadding,
  [IconEnum.FolderOpen]: Icons.FolderOpen,
  [IconEnum.Folder]: Icons.FolderWithPadding,
  [IconEnum.FolderXMark]: Icons.FolderXMark,
  [IconEnum.FollowUps]: Icons.FollowUps,
  [IconEnum.FollowUpsAIGradient]: Icons.FollowUpsAiGradient,
  [IconEnum.Gift]: Icons.Gift,
  [IconEnum.GitBranchNew]: Icons.GitBranchNew,
  [IconEnum.GitBranch]: Icons.GitBranch,
  [IconEnum.Github]: Icons.Github,
  [IconEnum.Gitlab]: Icons.Gitlab,
  [IconEnum.Globe]: Icons.Globe,
  [IconEnum.GoogleCalendar]: Icons.GoogleCalendar,
  [IconEnum.GoogleCloud]: Icons.GoogleCloud,
  [IconEnum.GoogleDocs]: Icons.GoogleDocs,
  [IconEnum.GoogleMeet]: Icons.GoogleMeet,
  [IconEnum.Google]: Icons.Google,
  [IconEnum.Grafana]: Icons.Grafana,
  [IconEnum.Hand]: Icons.Hand,
  [IconEnum.Heart]: Icons.Heart,
  [IconEnum.Headphones]: Icons.Headphones,
  [IconEnum.Help]: Icons.HelpCircle,
  [IconEnum.Hide]: Icons.Hide,
  [IconEnum.HighAlertPriority]: Icons.HighAlertPriority,
  [IconEnum.History]: Icons.History,
  [IconEnum.Home]: Icons.Home,
  [IconEnum.Honeycomb]: Icons.Honeycomb,
  [IconEnum.Hourglass]: Icons.Hourglass,
  [IconEnum.Http]: Icons.Http,
  [IconEnum.IncidentFlameNoFill]: Icons.IncidentFlameNoFill,
  [IconEnum.IncidentFlameWithFill]: Icons.IncidentFlameWithFill,
  [IconEnum.IncidentFlame]: Icons.IncidentFlame,
  [IconEnum.IncidentStatusActive]: Icons.IncidentStatusActive,
  [IconEnum.IncidentStatusCancelled]: Icons.IncidentStatusCancelled,
  [IconEnum.IncidentStatusClosed]: Icons.IncidentStatusClosed,
  [IconEnum.IncidentStatusDeclined]: Icons.IncidentStatusDeclined,
  [IconEnum.IncidentStatusEmpty]: Icons.IncidentStatusEmpty,
  [IconEnum.IncidentStatusMerged]: Icons.IncidentStatusMerged,
  [IconEnum.IncidentStatusPaused]: Icons.IncidentStatusPaused,
  [IconEnum.IncidentStatusPostIncident]: Icons.IncidentStatusPostIncident,
  [IconEnum.IncidentStatusTriage]: Icons.IncidentStatusTriage,
  [IconEnum.IncidentTicket]: Icons.IncidentTicket,
  [IconEnum.IncidentType]: Icons.IncidentTypes,
  [IconEnum.IncidentTypeAI]: Icons.IncidentTypeAi,
  [IconEnum.IncidentTypeAIGradient]: Icons.IncidentTypeAiGradient,
  [IconEnum.Incident]: Icons.Incident,
  [IconEnum.IncomingPhoneCall]: Icons.IncomingPhoneCall,
  [IconEnum.IndentationLeft]: Icons.IndentationLeft,
  [IconEnum.IndentationRight]: Icons.IndentationRight,
  [IconEnum.Info]: Icons.Info,
  [IconEnum.InputField]: Icons.InputField,
  [IconEnum.Integrations]: Icons.Integrations,
  [IconEnum.Intercom]: Icons.Intercom,
  [IconEnum.InsightsDashboard]: Icons.InsightsDashboard,
  [IconEnum.JiraServer]: Icons.Jira,
  [IconEnum.Jira]: Icons.Jira,
  [IconEnum.JumpCloud]: Icons.Jumpcloud,
  [IconEnum.Kanban]: Icons.Kanban,
  [IconEnum.Key]: Icons.Key,
  [IconEnum.Keyboard]: Icons.Keyboard,
  [IconEnum.Layer]: Icons.Layer,
  [IconEnum.LayersBulk]: Icons.LayersBulk,
  [IconEnum.LowAlertPriority]: Icons.LowAlertPriority,
  [IconEnum.Linear]: Icons.Linear,
  [IconEnum.Link]: Icons.Link,
  [IconEnum.LinkAI]: Icons.LinkAi,
  [IconEnum.LinkAIGradient]: Icons.LinkAiGradient,
  [IconEnum.LinkBreak]: Icons.LinkBreak,
  [IconEnum.List]: Icons.List,
  [IconEnum.Loader]: Icons.Loader,
  [IconEnum.Lock]: Icons.LockClosed,
  [IconEnum.LockClosed]: Icons.LockClosed,
  [IconEnum.LockOpen]: Icons.LockOpen,
  [IconEnum.Loop]: Icons.Loop,
  [IconEnum.MagicWand]: Icons.MagicWand,
  [IconEnum.Maintenance]: Icons.Wrench,
  [IconEnum.MapIcon]: Icons.MapIcon,
  [IconEnum.Maximize]: Icons.Maximize,
  [IconEnum.Medal1]: Icons.Medal1,
  [IconEnum.Medal2]: Icons.Medal2,
  [IconEnum.Medal3]: Icons.Medal3,
  [IconEnum.MediumAlertPriority]: Icons.MediumAlertPriority,
  [IconEnum.Menu]: Icons.Menu,
  [IconEnum.Merge]: Icons.Merge,
  [IconEnum.MergedArrow]: Icons.MergedArrow,
  [IconEnum.Message]: Icons.Message,
  [IconEnum.Microsoft]: Icons.Microsoft,
  [IconEnum.MicrosoftTeams]: Icons.MicrosoftTeamsLogo,
  [IconEnum.MicrosoftTeamsGreyscale]: Icons.MicrosoftTeamsGreyscale,
  [IconEnum.Minus]: Icons.Minus,
  [IconEnum.MinusCircle]: Icons.MinusCircle,
  [IconEnum.Money]: Icons.Money,
  [IconEnum.MonteCarlo]: Icons.MonteCarlo,
  [IconEnum.MortarBoard]: Icons.MortarBoard,
  [IconEnum.MsTeams]: Icons.MicrosoftTeamsLogo,
  [IconEnum.Mug]: Icons.Mug,
  [IconEnum.MultiSelect]: Icons.MultiSelect,
  [IconEnum.Mute]: Icons.Mute,
  [IconEnum.Nagios]: Icons.Nagios,
  [IconEnum.NestedInfo]: Icons.NestedInfo,
  [IconEnum.New]: Icons.Sparkles,
  [IconEnum.NewRelic]: Icons.NewRelic,
  [IconEnum.Notification]: Icons.Notification,
  [IconEnum.Notion]: Icons.Notion,
  [IconEnum.Nudge]: Icons.FingerPoint,
  [IconEnum.NumberBox1Filled]: Icons.NumberBox1Filled,
  [IconEnum.NumberBox2Filled]: Icons.NumberBox2Filled,
  [IconEnum.NumberBox3Filled]: Icons.NumberBox3Filled,
  [IconEnum.NumberOrderedList]: Icons.NumberOrderedList,
  [IconEnum.Numeric]: Icons.Numeric,
  [IconEnum.Okta]: Icons.Okta,
  [IconEnum.OnCall]: Icons.OnCall,
  [IconEnum.OneLogin]: Icons.Onelogin,
  [IconEnum.Opsgenie]: Icons.Opsgenie,
  [IconEnum.OpsLevel]: Icons.OpsLevel,
  [IconEnum.Outlook]: Icons.Outlook,
  [IconEnum.Pagerduty]: Icons.Pagerduty,
  [IconEnum.Panther]: Icons.PantherLogo,
  [IconEnum.Pause]: Icons.Pause,
  [IconEnum.Phone]: Icons.Phone,
  [IconEnum.PiggyBank]: Icons.PiggyBank,
  [IconEnum.Pin]: Icons.Pin,
  [IconEnum.Pingdom]: Icons.Pingdom,
  [IconEnum.PingFederate]: Icons.PingFederate,
  [IconEnum.PlayOverlay]: Icons.PlayOverlay,
  [IconEnum.Priority]: Icons.Priority,
  [IconEnum.Private]: Icons.LockClosed,
  [IconEnum.Prometheus]: Icons.Prometheus,
  [IconEnum.QuestionMark]: Icons.QuestionMark,
  [IconEnum.Quickstart]: Icons.Quickstart,
  [IconEnum.Quote]: Icons.Quote,
  [IconEnum.QuoteAI]: Icons.QuoteAi,
  [IconEnum.QuoteAIGradient]: Icons.QuoteAiGradient,
  [IconEnum.Rank]: Icons.Rank,
  [IconEnum.Refresh]: Icons.Refresh,
  [IconEnum.Refresh1]: Icons.Refresh1,
  [IconEnum.Refresh2]: Icons.Refresh2,
  [IconEnum.RepeatOnce]: Icons.RepeatOnce,
  [IconEnum.Resume]: Icons.Resume,
  [IconEnum.Retrospective]: Icons.Retrospective,
  [IconEnum.Rhombus]: Icons.Rhombus,
  [IconEnum.Rippling]: Icons.Rippling,
  [IconEnum.Robot]: Icons.Robot,
  [IconEnum.Runscope]: Icons.Runscope,
  [IconEnum.Salesforce]: Icons.Salesforce,
  [IconEnum.Scim]: Icons.Scim,
  [IconEnum.Scribe]: Icons.Scribe,
  [IconEnum.Search]: Icons.Search,
  [IconEnum.Send]: Icons.Send,
  [IconEnum.Sentry]: Icons.Sentry,
  [IconEnum.Server]: Icons.Server,
  [IconEnum.ServiceNow]: Icons.ServiceNow,
  [IconEnum.Severity]: Icons.Severity,
  [IconEnum.SeverityCritical]: Icons.SeverityCritical,
  [IconEnum.SeverityHigh]: Icons.SeverityHigh,
  [IconEnum.SeverityLow]: Icons.SeverityLow,
  [IconEnum.SeverityMedium]: Icons.SeverityMedium,
  [IconEnum.Share]: Icons.Share,
  [IconEnum.Shield]: Icons.Shield,
  [IconEnum.ShieldExclamation]: Icons.ShieldExclamation,
  [IconEnum.Shortcut]: Icons.Shortcut,
  [IconEnum.SidebarLeftHide]: Icons.SidebarLeftHide,
  [IconEnum.SidebarLeftShow]: Icons.SidebarLeftShow,
  [IconEnum.SingleSelect]: Icons.SingleSelect,
  [IconEnum.Siren]: Icons.Siren,
  [IconEnum.Slack]: Icons.Slack,
  [IconEnum.SlackChannel]: Icons.SlackChannel,
  [IconEnum.SlackGreyscale]: Icons.SlackGreyscale,
  [IconEnum.SlackTeam]: Icons.Buildings,
  [IconEnum.Team]: Icons.Team,
  [IconEnum.Sliders]: Icons.Sliders,
  [IconEnum.Sns]: Icons.Sns,
  [IconEnum.SolarWinds]: Icons.Solarwinds,
  [IconEnum.Sort]: Icons.Sort,
  [IconEnum.Sparkles]: Icons.Sparkles3,
  [IconEnum.SparklesColoured]: Icons.Sparkles3Coloured,
  [IconEnum.SparklesAiGradient]: Icons.SparklesAiGradient,
  [IconEnum.SpeechImportant]: Icons.SpeechImportant,
  [IconEnum.SplitArrow]: Icons.SplitArrow,
  [IconEnum.Splunk]: Icons.Splunk,
  [IconEnum.Star]: Icons.Star,
  [IconEnum.Status]: Icons.Status,
  [IconEnum.StatusCake]: Icons.Statuscake,
  [IconEnum.StatusChevron]: Icons.StatusChevron,
  [IconEnum.StatusPage]: Icons.StatusPage,
  [IconEnum.Stop]: Icons.Stop,
  [IconEnum.Store]: Icons.Store,
  [IconEnum.Subscription]: Icons.Subscription,
  [IconEnum.Success]: Icons.Success,
  [IconEnum.SumoLogic]: Icons.SumoLogic,
  [IconEnum.SwitchHorizontal]: Icons.SwitchHorizontal,
  [IconEnum.Sync]: Icons.Sync,
  [IconEnum.Table]: Icons.Table,
  [IconEnum.Tag]: Icons.Tag,
  [IconEnum.Terraform]: Icons.Terraform,
  [IconEnum.TerraformStrikethrough]: Icons.TerraformStrikethrough,
  [IconEnum.Test]: Icons.Test,
  [IconEnum.Text]: Icons.Text,
  [IconEnum.TextAlignLeft]: Icons.TextAlignLeft,
  [IconEnum.TextBold]: Icons.TextBold,
  [IconEnum.TextInput]: Icons.TextInput,
  [IconEnum.TextItalic]: Icons.TextItalic,
  [IconEnum.TextStrikeThrough]: Icons.TextStrikethrough,
  [IconEnum.ThumbsDown]: Icons.ThumbsDown,
  [IconEnum.ThumbsUp]: Icons.ThumbsUp,
  [IconEnum.Tick]: Icons.Check,
  [IconEnum.TickCircle]: Icons.TickCircle,
  [IconEnum.Timer]: Icons.Timer,
  [IconEnum.Timestamp]: Icons.Clock,
  [IconEnum.ToggleLeft]: Icons.ToggleLeft,
  [IconEnum.ToggleRight]: Icons.ToggleRight,
  [IconEnum.TranscriptAIGradient]: Icons.TranscriptAiGradient,
  [IconEnum.TrendDown]: Icons.TrendDown,
  [IconEnum.TrendNeutral]: Icons.TrendNeutral,
  [IconEnum.TrendUp]: Icons.TrendUp,
  [IconEnum.Triage]: Icons.Hourglass,
  [IconEnum.Trophy]: Icons.Trophy,
  [IconEnum.TShirt]: Icons.TShirt,
  [IconEnum.Undo]: Icons.Undo,
  [IconEnum.UnorderedList]: Icons.UnorderedList,
  [IconEnum.Uptime]: Icons.Uptime,
  [IconEnum.Urgency]: Icons.Urgency,
  [IconEnum.User]: Icons.User,
  [IconEnum.UserAdd]: Icons.UserAdd,
  [IconEnum.UserAI]: Icons.UserAi,
  [IconEnum.UserAIGradient]: Icons.UserAiGradient,
  [IconEnum.Users]: Icons.Users,
  [IconEnum.Vanta]: Icons.Vanta,
  [IconEnum.Variable]: Icons.Bolt,
  [IconEnum.Vibrate]: Icons.Vibrate,
  [IconEnum.View]: Icons.Show,
  [IconEnum.Warning]: Icons.WarningTriangle,
  [IconEnum.WarningCircle]: Icons.WarningCircle,
  [IconEnum.Workflows]: Icons.Workflows,
  [IconEnum.WorkingHours]: Icons.BriefcaseClock,
  [IconEnum.Zapier]: Icons.Zapier,
  [IconEnum.Zendesk]: Icons.Zendesk,
  [IconEnum.Zoom]: Icons.Zoom,
};

export enum IconSize {
  XS = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  XL = "xl",
  XXL = "xxl",
}

export const iconSizeToClassNames: { [key in IconSize]: string } = {
  [IconSize.XS]: "w-3 h-3",
  [IconSize.Small]: "w-4 h-4",
  [IconSize.Medium]: "w-5 h-5",
  [IconSize.Large]: "w-6 h-6",
  [IconSize.XL]: "w-8 h-8",
  [IconSize.XXL]: "w-10 h-10",
};

type catalogIcon =
  | CatalogTypeIconEnum
  | CatalogUpdateTypeRequestBodyIconEnum
  | CatalogResourceIconEnum;

export type IconProps = {
  id: IconEnum | catalogIcon;
  altText?: string;
  size?: IconSize;
  className?: string;
} & React.ComponentProps<"svg">;

export const Icon = ({
  id,
  altText,
  size = IconSize.Medium,
  className,
  ...rest
}: IconProps): React.ReactElement | null => {
  const IconComponent = iconLookup[id];
  if (IconComponent != null) {
    return (
      <>
        {altText && <span className="sr-only">{altText}</span>}
        <IconComponent
          {...rest}
          className={tcx("shrink-0", iconSizeToClassNames[size], className)}
        />
      </>
    );
  }

  // We're in the Bad Place: couldn't find the icon we needed.
  sendWarningToSentry(
    `We attempted to render an icon "${id}" that we didn't recognise. We'll have rendered nothing.`,
  );
  return null;
};

export { IconEnum };
