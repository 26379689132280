import {
  BillingSettingPlanNameEnum as PlanNameEnum,
  StaffSetPlanInfoRequestBody,
  StaffSetPlanInfoRequestBodyBillingModeEnum as BillingModeEnum,
  StaffSetPlanInfoRequestBodyBillingModeEnum,
  StaffSetPlanInfoRequestBodyPlanNameEnum,
  StaffShowOrganisationResponseBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  Txt,
} from "@incident-ui";
import { Drawer } from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { SelectOption, SelectOptions } from "@incident-ui/Select/types";
import { AnimatePresence } from "framer-motion";
import { chain } from "lodash";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { cacheKey, useMutationV2 } from "src/utils/swr";

type FormType = {
  plan_name: PlanNameEnum;
  billing_mode: BillingModeEnum;
  committed_minimum_seats: string | null;
  committed_minimum_oncall_addons: string | null;
};

export const PlanSettingsButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button analyticsTrackingId={null} onClick={() => setIsOpen(true)}>
        🎛️ Billing status
      </Button>
      <AnimatePresence>
        {isOpen && (
          <PlanSettingsForm data={data} onClose={() => setIsOpen(false)} />
        )}
      </AnimatePresence>
    </>
  );
};
const currentBillingMode = (data: StaffShowOrganisationResponseBody) => {
  return data.billing_setting.stripe_billing_mode as unknown as BillingModeEnum;
};

const PlanSettingsForm = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<FormType>({
    defaultValues: {
      plan_name: data.billing_setting.plan_name,
      committed_minimum_seats: data.committed_minimum_seats?.toString() ?? null,
      committed_minimum_oncall_addons:
        data.committed_minimum_oncall_addons?.toString() ?? null,
      billing_mode: currentBillingMode(data),
    },
  });

  const { trigger, isMutating, genericError } = useMutationV2(
    async (apiClient, formData: FormType) => {
      const request: StaffSetPlanInfoRequestBody = {
        plan_name:
          formData.plan_name as unknown as StaffSetPlanInfoRequestBodyPlanNameEnum,
        billing_mode:
          formData.billing_mode as unknown as StaffSetPlanInfoRequestBodyBillingModeEnum,
        committed_minimum_seats: formData.committed_minimum_seats
          ? parseInt(formData.committed_minimum_seats)
          : undefined,
        committed_minimum_oncall_addons:
          formData.committed_minimum_oncall_addons
            ? parseInt(formData.committed_minimum_oncall_addons)
            : undefined,
      };

      await apiClient.staffSetPlanInfo({
        organisationSlug: data.organisation_slug,
        setPlanInfoRequestBody: request,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  const [minimumSeats, minimumOncallAddons] = formMethods.watch([
    "committed_minimum_seats",
    "committed_minimum_oncall_addons",
  ]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.AdjustPlan,
    !formMethods.formState.isDirty,
  );

  return (
    <Drawer onClose={onClose} className="p-6 space-y-6" width="medium">
      <Form.Root
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        genericError={genericError}
      >
        <div className="space-y-2">
          <h3 className="font-medium">Plan settings</h3>
          <Callout theme={CalloutTheme.Warning}>
            Take care: this can change how much we charge the customer!
          </Callout>
        </div>

        <StaticSingleSelectV2
          formMethods={formMethods}
          name="plan_name"
          label="Plan name"
          renderDescription="below"
          helptext={
            <>
              This is just for show! To change what the customer is billed,
              you&apos;ll need to change Stripe. To change their access to
              product features, you&apos;ll need to change their feature access.
            </>
          }
          options={planOptions(data.is_paying)}
        />

        <BillingMode isPaying={data.is_paying} formMethods={formMethods} />

        {data.is_paying && (
          <>
            <InputV2
              formMethods={formMethods}
              name="committed_minimum_seats"
              label="Committed minimum seats"
              helptext="The minimum number of Responder seats the customer has committed to paying for, regardless of usage. Usage over this is considered an overage."
              type={InputType.Number}
              rules={{ min: 1 }}
              highlightErrors
              suffixNode={
                minimumSeats !== undefined && (
                  <Button
                    onClick={() =>
                      formMethods.setValue("committed_minimum_seats", "", {
                        shouldDirty: true,
                      })
                    }
                    analyticsTrackingId={null}
                    disabled={!minimumSeats}
                  >
                    No minimum
                  </Button>
                )
              }
            />

            <InputV2
              formMethods={formMethods}
              name="committed_minimum_oncall_addons"
              label="Committed minimum On-call add-ons"
              helptext="The minimum number of On-call addons the customer has agreed to pay for in their contract."
              type={InputType.Number}
              rules={{ min: 1 }}
              suffixNode={
                minimumOncallAddons !== undefined && (
                  <Button
                    onClick={() =>
                      formMethods.setValue(
                        "committed_minimum_oncall_addons",
                        "",
                        { shouldDirty: true },
                      )
                    }
                    analyticsTrackingId={null}
                    disabled={!minimumOncallAddons}
                  >
                    No minimum
                  </Button>
                )
              }
            />
          </>
        )}
        <GatedButton
          analyticsTrackingId={null}
          {...disabledProps}
          onClick={async () => {
            if (await formMethods.trigger()) {
              setConfirmOpen(true);
            }
          }}
        >
          Save
        </GatedButton>
        {confirmOpen && (
          <ConfirmationDialog
            analyticsTrackingId={null}
            isOpen
            title={`Update plan for ${data.organisation_name}`}
            onConfirm={formMethods.handleSubmit(trigger)}
            onCancel={() => setConfirmOpen(false)}
          >
            Are you sure you want to save these changes?
          </ConfirmationDialog>
        )}
      </Form.Root>
    </Drawer>
  );
};

const BillingMode = ({
  isPaying,
  formMethods,
}: {
  isPaying: boolean;
  formMethods: UseFormReturn<FormType>;
}) => {
  if (isPaying) {
    return (
      <RadioButtonGroupV2
        formMethods={formMethods}
        srLabel="Billing mode"
        name="billing_mode"
        label="Billing mode"
        options={payingBillingModeOptions}
      />
    );
  } else {
    return (
      <Txt>
        <Txt className="font-medium text-content-primary mb-1">
          Billing mode
        </Txt>
        This organisation is not paying us (yet!). If that sounds wrong, make
        sure you&apos;ve linked their Stripe subscription!
      </Txt>
    );
  }
};

const PLANS: {
  [key in PlanNameEnum]: Omit<SelectOption, "value"> & {
    groupLabel: string;
    paid: boolean;
  };
} = {
  basic_v2: {
    groupLabel: "2024",
    label: "Basic",
    description: "A free tier for up to 5 users. Includes On-call.",
    sort_key: "1",
    paid: false,
  },
  team_v2: {
    groupLabel: "2024",
    label: "Team",
    description: "A self-serve plan for teams. Includes On-call.",
    sort_key: "2",
    paid: true,
  },
  pro_v2: {
    groupLabel: "2024",
    label: "Pro",
    description: "The updated Pro plan, with On-call.",
    sort_key: "3",
    paid: true,
  },
  enterprise_v2: {
    groupLabel: "2024",
    label: "Enterprise",
    description: "The updated Enterprise plan, with On-call.",
    sort_key: "4",
    paid: true,
  },
  starter_v1: {
    groupLabel: "2022",
    label: "Starter",
    description:
      "A self-serve plan, sold from Feb 2022 to March 2024. Does not include On-call.",
    sort_key: "6",
    paid: true,
  },
  pro_v1: {
    groupLabel: "2022",
    label: "Pro",
    description: "Sold from Feb 2022 to March 2024. Does not include On-call.",
    sort_key: "7",
    paid: true,
  },
  enterprise_v1: {
    groupLabel: "2022",
    label: "Enterprise",
    description: "Sold from Feb 2022 to March 2024. Does not include On-call.",
    sort_key: "8",
    paid: true,
  },
  trial: {
    groupLabel: "Other",
    label: "Trial",
    description:
      "A time-limited trial, generally with at least some Pro features unlocked.",
    sort_key: "9",
    paid: false,
  },
  pro_v2_trial: {
    groupLabel: "2024",
    label: "Trial (Pro)",
    description: "Trial of the Pro plan.",
    sort_key: "11",
    paid: false,
  },
  enterprise_v2_trial: {
    groupLabel: "2024",
    label: "Trial (Enterprise)",
    description: "Trial of the Enterprise plan.",
    sort_key: "12",
    paid: false,
  },
  legacy: {
    groupLabel: "Other",
    label: "Legacy",
    description: "Pre-2022 pricing, aka 'early-adopter pricing'.",
    sort_key: "13",
    paid: true,
  },
};

const planOptions = (isPaying: boolean): SelectOptions =>
  chain(Object.values(PlanNameEnum))
    .map((value) => ({
      value,
      ...PLANS[value],
    }))
    .filter(({ paid }) => paid === isPaying)
    .groupBy("groupLabel")
    .values()
    .map((options) => ({
      label: options[0].groupLabel,
      options,
    }))
    .value();

const payingBillingModeOptions = [
  {
    value: BillingModeEnum.PerSeat,
    label: "Seat-based",
    description:
      "In this mode, usage of responders and on-call addons is sent to Stripe daily. Overages are collected via an attached card, or invoiced manually.",
  },
  {
    value: BillingModeEnum.FlatRate,
    label: "Manual billing",
    description:
      "Usage tracked in-product will not be sent to Stripe. Responder and On-call addon counts are hidden. This is useful for customers who have a custom definition of 'responder', or who are not billed based on seat usage.",
  },
];
