import { SecondaryNavPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { SecondaryNavHorizontalItemType } from "@incident-shared/layout/SecondaryNavHorizontal";
import { IconEnum } from "@incident-ui";
import React from "react";
import { Outlet } from "react-router";

import { useAPI } from "../../utils/swr";

export const UserPreferencesPage = (): React.ReactElement => {
  const { data: escalationProvidersResponse } = useAPI(
    "userPreferencesListManualEscalationProviderOptions",
    undefined,
  );

  const hasAnEscalationProvider = escalationProvidersResponse
    ? escalationProvidersResponse.escalation_providers.length > 0
    : true;

  const userPreferencesPages: SecondaryNavHorizontalItemType[] = [
    hasAnEscalationProvider && {
      label: "On-call notifications",
      slug: "on-call-notifications",
    },
    {
      label: "Incident subscriptions",
      slug: "incident-subscriptions",
    },
    {
      label: "Notifications",
      slug: "notifications",
    },
    {
      label: "Connected accounts",
      slug: "connected-accounts",
    },
  ].filter(Boolean) as SecondaryNavHorizontalItemType[];

  return (
    <SecondaryNavPageWrapper
      title={"Preferences"}
      icon={IconEnum.User}
      navItemGroups={[
        {
          items: userPreferencesPages,
        },
      ]}
      pathPrefix={"user-preferences"}
    >
      <Outlet />
    </SecondaryNavPageWrapper>
  );
};
