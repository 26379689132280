/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentGroundTruthTelemetryDashboard
 */
export interface IncidentGroundTruthTelemetryDashboard {
    /**
     * URL to the dashboard
     * @type {string}
     * @memberof IncidentGroundTruthTelemetryDashboard
     */
    dashboard_url: string;
    /**
     * Explanation of why this dashboard is related to the incident
     * @type {string}
     * @memberof IncidentGroundTruthTelemetryDashboard
     */
    detail: string;
    /**
     * List of metrics on this dashboard relevant to the incident
     * @type {Array<string>}
     * @memberof IncidentGroundTruthTelemetryDashboard
     */
    metrics?: Array<string>;
    /**
     * Provider's ID for the dashboard
     * @type {string}
     * @memberof IncidentGroundTruthTelemetryDashboard
     */
    provider_dashboard_id: string;
}

export function IncidentGroundTruthTelemetryDashboardFromJSON(json: any): IncidentGroundTruthTelemetryDashboard {
    return IncidentGroundTruthTelemetryDashboardFromJSONTyped(json, false);
}

export function IncidentGroundTruthTelemetryDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruthTelemetryDashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard_url': json['dashboard_url'],
        'detail': json['detail'],
        'metrics': !exists(json, 'metrics') ? undefined : json['metrics'],
        'provider_dashboard_id': json['provider_dashboard_id'],
    };
}

export function IncidentGroundTruthTelemetryDashboardToJSON(value?: IncidentGroundTruthTelemetryDashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard_url': value.dashboard_url,
        'detail': value.detail,
        'metrics': value.metrics,
        'provider_dashboard_id': value.provider_dashboard_id,
    };
}

