import { StaffShowOrganisationResponseBody } from "@incident-io/api";

import { InfoTable } from "../common/InfoTable";
import { Value } from "../common/Value";

export const EnterprisePanel = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <InfoTable
      rows={[
        {
          id: "arr",
          name: <abbr title="Annual Recurring Revenue">ARR</abbr>,
          value: data.arr_dollars && (
            <Value.NumericValue value={data.arr_dollars} />
          ),
        },
        {
          id: "ae",
          name: <abbr title="Account Executive">AE</abbr>,
          value: data.account_executive && (
            <Value.UserValue value={data.account_executive} />
          ),
        },
        {
          id: "csm",
          name: <abbr title="Customer Success Manager">CSM</abbr>,
          value: data.customer_success_manager && (
            <Value.UserValue value={data.customer_success_manager} />
          ),
        },
      ]}
    />
  );
};
