import {
  CatalogEntry,
  EngineParamBindingValue,
  FormField,
  InsightsFilter,
  InsightsFilterOperatorEnum,
} from "@incident-io/api";
import { useCurrentUserCatalogEntry } from "@incident-shared/catalog/useUserBacklinks";
import {
  AppliedFiltersInline,
  ExtendedFormFieldValue,
  FiltersContextProvider,
  filterToQueryKeyValues,
} from "@incident-shared/filters";
import { Badge, BadgeTheme, GenericErrorMessage, Loader } from "@incident-ui";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";

import { useAPI } from "../../../utils/swr";
import {
  formFieldValueToInsightsFilter,
  insightsFilterToFormFieldValue,
} from "../../insights/v3/dashboards/common/marshall";
import { useTeamSettings } from "../../settings/teams/common/useTeamSettings";
import { useAlertFilters } from "../alert-details/useAlertFilters";
import { useAlertInsightsDates } from "../alert-details/useAlertInsightsDates";
import { useDefaultTeamsInsightFilter } from "../common/useDefaultTeamsFilter";
import { AlertActivityChart } from "./AlertActivityChart";
import { AlertInsightTables } from "./AlertInsightTables";
import { KeyInsights } from "./KeyInsights";

export type GetNavigateHref = ({
  theme,
  title,
}: {
  theme?: string;
  title?: string;
}) => string;

export const AlertsOverviewPage: React.FC = () => {
  const { userCatalogEntry, isLoading } = useCurrentUserCatalogEntry();

  const {
    data: { fields: availableFilterFields },
    isLoading: filtersLoading,
    error: filtersError,
  } = useAPI("alertsListFilterFields", undefined, {
    fallbackData: { fields: [] },
  });

  if (filtersLoading || isLoading) {
    return <Loader />;
  }

  if (filtersError) {
    return <GenericErrorMessage />;
  }

  return (
    <_AlertsOverviewPage
      userCatalogEntry={userCatalogEntry}
      availableFilterFields={availableFilterFields}
    />
  );
};

export const _AlertsOverviewPage = ({
  userCatalogEntry,
  availableFilterFields,
}: {
  userCatalogEntry: CatalogEntry | undefined;
  availableFilterFields: FormField[];
}) => {
  const { hasTeamsAlertAttributeConfigured, teamSettings } = useTeamSettings();
  const { timezone, startDate, endDate } = useAlertInsightsDates({
    fromStartOfToday: true,
  });
  const teamAlertAttributeId = teamSettings?.alert_attribute_id;
  const isTeamFilter = hasTeamsAlertAttributeConfigured
    ? (f: ExtendedFormFieldValue) => {
        return (
          f.field_key === "attributes" && f.field_id === teamAlertAttributeId
        );
      }
    : undefined;

  const defaultFiltersV1 = useDefaultTeamAlertFilter(
    userCatalogEntry,
    availableFilterFields,
  );
  const defaultFiltersV2 = useDefaultTeamsInsightFilter(availableFilterFields);
  const defaultFilters = hasTeamsAlertAttributeConfigured
    ? defaultFiltersV2
    : defaultFiltersV1;

  const { filters, setFilters, filterFields, filtersLoading, filtersError } =
    useAlertFilters();

  const [hasSetInitialFilters, setHasSetInitialFilters] = React.useState(false);
  useEffect(() => {
    if (!hasSetInitialFilters && defaultFilters.length > 0) {
      setFilters([
        ...filters,
        ...(defaultFilters?.map(insightsFilterToFormFieldValue) ?? []),
      ]);
      setHasSetInitialFilters(true);
    }
  }, [defaultFilters, filters, setFilters, hasSetInitialFilters]);

  const isLoading = filtersLoading;
  const error = filtersError;

  const {
    data: keyInsightsData,
    isLoading: isLoadingKeyInsights,
    error: keyInsightsError,
  } = useAPI("alertsGetInsightsOverviewKeyInsights", {
    getInsightsOverviewKeyInsightsRequestBody: {
      timezone: timezone,
      start_date: startDate,
      end_date: endDate,
      filters: filters.map(formFieldValueToInsightsFilter),
    },
  });

  const getNavigateHref: GetNavigateHref = useCallback(
    ({ theme, title }) => {
      const query: ExtendedFormFieldValue | undefined = title
        ? {
            key: "title",
            filter_id: "title",
            field_key: "title",
            field_id: "title",
            operator: "is",
            string_value: title,
          }
        : theme
        ? {
            key: "themes",
            filter_id: "themes",
            field_key: "themes",
            field_id: "themes",
            operator: "one_of",
            string_value: theme,
          }
        : undefined;

      const allQueryParams = _.flatMap(
        query ? [...filters, query] : filters,
        filterToQueryKeyValues,
      );
      const searchParams = new URLSearchParams();
      for (const { key, value } of allQueryParams) {
        searchParams.append(key, value);
      }
      return `/alerts/recent?${searchParams.toString()}`;
    },
    [filters],
  );

  if (error) {
    return <GenericErrorMessage />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setFilters}
      availableFilterFields={filterFields}
      filtersLoading={isLoading}
      kind={"alert"}
      isTeamFilter={isTeamFilter}
    >
      <div className={"flex flex-col justify-center w-full min-w-0 py-10 px-8"}>
        <div className={"mb-2 flex flex-row justify-between align-center"}>
          <div className="text-2xl-bold text-content-primary inline-flex items-center">
            Alerts overview
            <Badge theme={BadgeTheme.Info} className={"ml-2"}>
              Beta
            </Badge>
          </div>
        </div>
        <div>
          <AppliedFiltersInline
            totalNumberOfItems={null}
            itemsLabel={"alert"}
            analyticsTrackingId={"alerts-overview-add-filter"}
          />
        </div>

        <div className="flex justify-center w-full min-w-0">
          <div className="grow w-full">
            <div className="py-6 flex flex-col gap-10">
              <KeyInsights
                isLoadingKeyInsights={isLoadingKeyInsights}
                keyInsightsData={keyInsightsData}
                keyInsightsError={keyInsightsError}
                getNavigateHref={getNavigateHref}
              />
              <AlertActivityChart
                timezone={timezone}
                startDate={startDate}
                endDate={endDate}
                filters={filters}
              />
              {/* Insights tables */}
              <AlertInsightTables
                timezone={timezone}
                startDate={startDate}
                endDate={endDate}
                filters={filters}
                getNavigateHref={getNavigateHref}
              />
            </div>
          </div>
        </div>
      </div>
    </FiltersContextProvider>
  );
};

export const useDefaultTeamAlertFilter = (
  userCatalogEntry: CatalogEntry | undefined,
  availableFilterFields: FormField[],
): InsightsFilter[] => {
  return useMemo((): InsightsFilter[] => {
    if (!userCatalogEntry || !availableFilterFields) {
      return [];
    }

    for (const [_, value] of Object.entries(
      userCatalogEntry.attribute_values,
    )) {
      const catalogEntryValues: EngineParamBindingValue[] = value.array_value
        ? value.array_value
        : value.value
        ? [value.value]
        : [];
      const catalogTypeID =
        catalogEntryValues[0]?.catalog_entry?.catalog_type_id;
      const matchingField = availableFilterFields.find(
        (field) =>
          field.typeahead_lookup_id &&
          field.typeahead_lookup_id === catalogTypeID,
      );

      // Just take the first one we find.
      // Currently, we're assuming any user backlink is a team.
      // If that turns out to be wrong, we can always try load the
      // catalog config and look for "teamy" types, but seems unnecessary
      if (matchingField) {
        return [
          {
            ...matchingField,
            field_key: matchingField.key,
            field_id: matchingField.field_id ?? "",
            is_disabled: false,
            operator: InsightsFilterOperatorEnum.OneOf,
            values: catalogEntryValues
              .map((value) => value.catalog_entry?.catalog_entry_id)
              .filter(Boolean) as string[],
          },
        ];
      }
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCatalogEntry, availableFilterFields]);
};
