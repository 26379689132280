import { usePylon } from "@bolasim/react-use-pylon";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions/marshall";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { SlackChannelsEditorV2 } from "@incident-shared/forms/v2/editors/SlackChannelsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Link,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AnnouncementRule,
  AnnouncementRuleModeEnum,
  AnnouncementRulesCreateRequestBody,
  AnnouncementRulesCreateRequestBodyModeEnum,
  AnnouncementRulesUpdateRequestBody,
  ConditionGroup,
  EngineParamBinding,
  IncidentsBuildScopeContextEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useIdentity } from "../../../../../contexts/IdentityContext";
import { useIncidentScope } from "../../../../../hooks/useIncidentScope";

const transformFormStateToRequestBody = (
  formState: AnnouncementRuleFormState,
): AnnouncementRulesCreateRequestBody => ({
  name: formState.name,
  slack_channel_ids: formState.slack_channels.map((ch) => ch.value),
  microsoft_teams_channel_ids: formState.microsoft_teams_channel_ids,
  condition_groups: conditionGroupsToGroupPayloads(formState.condition_groups),
  share_updates_to_channel: !!formState.share_updates_to_channel,
  mode: formState.mode as unknown as AnnouncementRulesCreateRequestBodyModeEnum,
});

const extractIncidentTypes = (conditionGroups: ConditionGroup[]) => {
  const types: EngineParamBinding[] = [];

  for (const group of conditionGroups) {
    const groupTypes = group.conditions
      .filter(
        (condition) =>
          condition.operation.value === "one_of" &&
          condition.subject.reference === "incident.type",
      )
      .flatMap((condition) => condition.param_bindings);
    types.push(...groupTypes);
  }

  return types;
};

const getIncidentTypeNames = (
  bindings: EngineParamBinding[],
  privateTypeIDs: string[],
) => {
  return bindings
    .flatMap((binding) =>
      binding.array_value
        ? binding.array_value.map((value) => ({
            name: value.label,
            value: value.value,
          }))
        : [],
    )
    .filter((item) =>
      typeof item.value === "string"
        ? privateTypeIDs.includes(item.value)
        : true,
    )
    .map((item) => item.name);
};

const useSelectedPrivateIncidentTypes = (conditions: ConditionGroup[]) => {
  const {
    data: { incident_types: incidentTypes },
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  const privateIncidentTypeIDs = incidentTypes
    .filter((item) => item.private_incidents_only)
    .map((item) => item.id);

  const selectedIncidentTypes = extractIncidentTypes(conditions);
  const selectedPrivateIncidentTypeNames = getIncidentTypeNames(
    selectedIncidentTypes,
    privateIncidentTypeIDs,
  );

  return selectedPrivateIncidentTypeNames;
};

type AnnouncementRuleFormState = {
  microsoft_teams_channel_ids: string[];
} & AnnouncementRule;

export const AnnouncementRuleCreateEditModal = ({
  mode,
  onClose,
  initialData: existingRule,
}: {
  onClose: () => void;
} & CreateEditFormProps<AnnouncementRule>): React.ReactElement | null => {
  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.AnnouncementRules,
  );
  const { identity } = useIdentity();
  const isTeamsCustomer = identity.ms_teams_info !== undefined;

  const defaultValues: Partial<AnnouncementRuleFormState> =
    mode === Mode.Create
      ? {
          slack_channels: [],
          microsoft_teams_channel_ids: [],
          condition_groups: [],
          mode: AnnouncementRuleModeEnum.IncludeTriage,
        }
      : {
          ...existingRule,
          // The channel selector takes a list of IDs, not the hydrated channel objects
          microsoft_teams_channel_ids:
            existingRule?.microsoft_teams_channels?.map((ch) => ch.value),
          condition_groups: existingRule?.condition_groups,
          mode: existingRule?.mode || AnnouncementRuleModeEnum.IncludeTriage,
        };

  const formMethods = useForm<AnnouncementRuleFormState>({ defaultValues });

  if (mode === Mode.Edit && !existingRule) {
    throw new Error(
      "unreachable: in edit mode, but don't have an existing rule. Something bad has happened.",
    );
  }

  const { mutate: setRuleShow } = useAPI(
    existingRule ? "announcementRulesShow" : null,
    {
      id: existingRule?.id ?? "",
    },
  );

  const conditions = formMethods.watch("condition_groups");
  const incidentTypesWithPrivateByDefault =
    useSelectedPrivateIncidentTypes(conditions);

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "announcementRulesList",
    undefined,
    async (apiClient, data: AnnouncementRuleFormState) => {
      if (mode === Mode.Create) {
        await apiClient.announcementRulesCreate({
          createRequestBody: transformFormStateToRequestBody(data),
        });
      } else if (mode === Mode.Edit) {
        const res = await apiClient.announcementRulesUpdate({
          id: existingRule?.id ?? "",
          updateRequestBody: transformFormStateToRequestBody(
            data,
          ) as unknown as AnnouncementRulesUpdateRequestBody,
        });

        // Put this specific rule in the SWR cache
        setRuleShow(res);
      }
    },
    {
      setError: formMethods.setError,
      onSuccess: () => onClose(),
    },
  );

  if (scopeError) {
    return <ErrorModal onClose={onClose} />;
  }
  if (scopeLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <Form.Modal
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      onClose={onClose}
      analyticsTrackingId={
        mode === Mode.Create
          ? "create-announcement-rule"
          : "edit-announcement-rule"
      }
      title={
        mode === Mode.Create
          ? "Create new Announcement Rule"
          : "Edit Announcement Rule"
      }
      disableQuickClose
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText={mode === Mode.Create ? "Create" : "Save"}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {/* Name */}
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext={
          "We'll show this name in each announcement post, so you can see which rule triggered the post."
        }
        required="Please provide a name"
      />
      <hr className="my-3" />
      {/* Conditions */}
      {incidentTypesWithPrivateByDefault.length > 0 && (
        <Callout theme={CalloutTheme.Warning} className="mt-4">
          Incidents of type{" "}
          {incidentTypesWithPrivateByDefault.map((item, index) => (
            <React.Fragment key={index}>
              <div className="inline font-bold">{item}</div>
              {index < incidentTypesWithPrivateByDefault.length - 1 && ", "}
            </React.Fragment>
          ))}{" "}
          are private by default. Private incidents will never be announced, so
          these incidents will only be announced if they are made public.
        </Callout>
      )}

      <ConditionGroupsEditorV2
        formMethods={formMethods}
        name={"condition_groups"}
        scope={scope}
        entityNameLabel={"announcement rule"}
        subjectsLabel={"incidents"}
      />
      <hr className="my-3" />
      {/* Target Channel */}
      {isTeamsCustomer ? (
        <MicrosoftTeamsChannelSelector formMethods={formMethods} />
      ) : (
        <SlackChannelsEditorV2
          formMethods={formMethods}
          name="slack_channels"
          label="Slack channels"
          helptext={`Which channels do you want to announce these incidents in?`}
          initialValue={defaultValues.slack_channels}
        />
      )}
      {/* Mode (for triage incidents) */}
      {/* We don't gate by triage incidents here as the gate should only apply
      to manually triggered triage incidents (alerts - which is available to
      everyone - creates triage incidents) */}
      <hr className="my-3" />
      <RadioButtonGroupV2
        formMethods={formMethods}
        name="mode"
        label="Should triage incidents be announced?"
        helptext={
          <span>
            <Link
              analyticsTrackingId={"triage-incident-learn-more"}
              href={"https://incident.io/changelog/2023-01-17-triage-incidents"}
            >
              Triage incidents
            </Link>{" "}
            are when there&apos;s potential to be an incident, but it has yet to
            be confirmed.
          </span>
        }
        srLabel="Should triage incidents be announced?"
        required
        options={[
          {
            label: "Yes, and keep all posts regardless of status",
            value: AnnouncementRuleModeEnum.IncludeAll,
          },
          {
            label: "Yes, but remove the post if merged, declined or canceled",
            value: AnnouncementRuleModeEnum.IncludeTriage,
          },
          {
            label: "Yes, but remove the post if canceled",
            value: AnnouncementRuleModeEnum.IncludeDeclinedAndMerged,
          },
          {
            label: "No, do not announce triage incidents",
            value: AnnouncementRuleModeEnum.LiveAndClosed,
          },
        ]}
      />
      {!isTeamsCustomer && (
        <>
          <hr className="my-3" />
          <CheckboxV2
            formMethods={formMethods}
            label="Share incident updates to channel (as well as in a thread)"
            name="share_updates_to_channel"
          />
        </>
      )}
    </Form.Modal>
  );
};

const MicrosoftTeamsChannelSelector = ({
  formMethods,
}: {
  formMethods: UseFormReturn<AnnouncementRuleFormState>;
}) => {
  const apiClient = useClient();
  const loadOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: "MicrosoftTeamsChannel",
  });

  const hydrateOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: "MicrosoftTeamsChannel",
  });

  const { showKnowledgeBaseArticle } = usePylon();

  return (
    <>
      <Callout theme={CalloutTheme.Info} className="my-2">
        <p>
          To announce incidents outside of the Incidents Team, you must install
          incident.io in the Team where you want to make the announcement.
        </p>
        <p>
          Check out the installation steps in our{" "}
          <Button
            onClick={() => showKnowledgeBaseArticle("3924434929")}
            analyticsTrackingId="install-ms-teams-help-center-link"
            theme={ButtonTheme.Link}
            className="underline"
          >
            help center
          </Button>
          .
        </p>
      </Callout>
      <InvalidMicrosoftTeamsChannelWarning formMethods={formMethods} />
      <DynamicMultiSelectV2
        name={"microsoft_teams_channel_ids"}
        formMethods={formMethods}
        loadOptions={loadOptions}
        hydrateOptions={hydrateOptions}
        label="Microsoft Teams channels"
        helptext={`Which channels do you want to announce these incidents in?`}
      />
    </>
  );
};

const InvalidMicrosoftTeamsChannelWarning = ({
  formMethods,
}: {
  formMethods: UseFormReturn<AnnouncementRuleFormState>;
}) => {
  const [initialChannels, selectedChannels] = formMethods.watch([
    "microsoft_teams_channels",
    "microsoft_teams_channel_ids",
  ]);

  if (!initialChannels) {
    // No channels e.g. creating a new rule - nothing to do here
    return null;
  }

  // Warn if any of the initial channels are no longer available because they're archived or deleted
  // But remove the warning if the user has deselected the channel
  const channels = initialChannels.filter((ch) =>
    selectedChannels.includes(ch.value),
  );

  const archivedChannels = channels.filter((v) => v.is_archived);
  const deletedChannels = channels.filter((v) => v.is_deleted);

  if (archivedChannels.length > 0 || deletedChannels.length > 0) {
    return (
      <Callout theme={CalloutTheme.Danger} className="my-2">
        <div className="space-y-2">
          <p>
            Some of the selected channels are no longer available to announce
            to. Please choose another channel.
          </p>
          {archivedChannels.length > 0 && (
            <p>
              The following channels are archived:{" "}
              {archivedChannels.map((v) => v.label).join(", ")}
            </p>
          )}
          {deletedChannels.length > 0 && (
            <p>
              The following channels are deleted:{" "}
              {deletedChannels.map((v) => v.label).join(", ")}
            </p>
          )}
        </div>
      </Callout>
    );
  }

  return null;
};
