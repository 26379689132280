/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
    /**
     * The volume level to use when paging the user via the app, a percentage
     * @type {number}
     * @memberof UserPreferences
     */
    app_volume_override_level?: number;
    /**
     * The sound to play when we page someone on iOS
     * @type {string}
     * @memberof UserPreferences
     */
    ios_pager_sound?: string;
    /**
     * Defines whether the user has opted into receiving the monthly insights emails
     * @type {boolean}
     * @memberof UserPreferences
     */
    monthly_insights_email_enabled: boolean;
    /**
     * Phone number to use for SMS communication
     * @type {string}
     * @memberof UserPreferences
     */
    phone_number?: string;
    /**
     * If a phone number isn't verified yet, the next time verification can be attempted
     * @type {Date}
     * @memberof UserPreferences
     */
    phone_number_next_verification_available_at?: Date;
    /**
     * If present, when the phone number was verified
     * @type {Date}
     * @memberof UserPreferences
     */
    phone_number_verifed_at?: Date;
    /**
     * The preferred manual escalation provider for this user
     * @type {string}
     * @memberof UserPreferences
     */
    preferred_manual_escalation_provider?: UserPreferencesPreferredManualEscalationProviderEnum;
    /**
     * Defines whether to send notification updates via email
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_notifications_via_email: boolean;
    /**
     * Defines whether to send notification updates via Slack
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_notifications_via_slack: boolean;
    /**
     * Whether we send incident subscription updates via app push notification
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_subscriptions_via_app: boolean;
    /**
     * Defines whether to send incident subscription updates via email
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_subscriptions_via_email: boolean;
    /**
     * Defines whether to send incident subscription updates via Slack DMs
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_subscriptions_via_slack_dm: boolean;
    /**
     * Defines whether to send incident subscription updates via SMS
     * @type {boolean}
     * @memberof UserPreferences
     */
    receives_subscriptions_via_sms: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserPreferencesPreferredManualEscalationProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function UserPreferencesFromJSON(json: any): UserPreferences {
    return UserPreferencesFromJSONTyped(json, false);
}

export function UserPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'app_volume_override_level': !exists(json, 'app_volume_override_level') ? undefined : json['app_volume_override_level'],
        'ios_pager_sound': !exists(json, 'ios_pager_sound') ? undefined : json['ios_pager_sound'],
        'monthly_insights_email_enabled': json['monthly_insights_email_enabled'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'phone_number_next_verification_available_at': !exists(json, 'phone_number_next_verification_available_at') ? undefined : (new Date(json['phone_number_next_verification_available_at'])),
        'phone_number_verifed_at': !exists(json, 'phone_number_verifed_at') ? undefined : (new Date(json['phone_number_verifed_at'])),
        'preferred_manual_escalation_provider': !exists(json, 'preferred_manual_escalation_provider') ? undefined : json['preferred_manual_escalation_provider'],
        'receives_notifications_via_email': json['receives_notifications_via_email'],
        'receives_notifications_via_slack': json['receives_notifications_via_slack'],
        'receives_subscriptions_via_app': json['receives_subscriptions_via_app'],
        'receives_subscriptions_via_email': json['receives_subscriptions_via_email'],
        'receives_subscriptions_via_slack_dm': json['receives_subscriptions_via_slack_dm'],
        'receives_subscriptions_via_sms': json['receives_subscriptions_via_sms'],
    };
}

export function UserPreferencesToJSON(value?: UserPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app_volume_override_level': value.app_volume_override_level,
        'ios_pager_sound': value.ios_pager_sound,
        'monthly_insights_email_enabled': value.monthly_insights_email_enabled,
        'phone_number': value.phone_number,
        'phone_number_next_verification_available_at': value.phone_number_next_verification_available_at === undefined ? undefined : (value.phone_number_next_verification_available_at.toISOString()),
        'phone_number_verifed_at': value.phone_number_verifed_at === undefined ? undefined : (value.phone_number_verifed_at.toISOString()),
        'preferred_manual_escalation_provider': value.preferred_manual_escalation_provider,
        'receives_notifications_via_email': value.receives_notifications_via_email,
        'receives_notifications_via_slack': value.receives_notifications_via_slack,
        'receives_subscriptions_via_app': value.receives_subscriptions_via_app,
        'receives_subscriptions_via_email': value.receives_subscriptions_via_email,
        'receives_subscriptions_via_slack_dm': value.receives_subscriptions_via_slack_dm,
        'receives_subscriptions_via_sms': value.receives_subscriptions_via_sms,
    };
}

