import {
  CatalogResource,
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum as AttributePayloadModeEnum,
  DependentResource,
  DependentResourceResourceTypeEnum,
  TeamSettings,
} from "@incident-io/api";
import { IntegrationConfig } from "@incident-shared/integrations";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  LoadingWrapper,
  Tooltip,
} from "@incident-ui";
import { clone, cloneDeep, take } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ulid } from "ulid";

import {
  AddDerivedAttributesPopover,
  buildDerivedAttributeEntries,
} from "./AddDerivedAttributesPopover";
import { AddDynamicAttributesPopover } from "./AddDynamicAttributesPopover";
import {
  CatalogTypeSchemaRow,
  CatalogTypeSchemaRowName,
  CatalogTypeSchemaRowRank,
} from "./CatalogTypeSchemaRow";
import { DerivedAttributeBadge } from "./DerivedAttributeBadge";
import {
  AttributeFormState,
  CatalogTypeCreateEditFormState,
  CatalogTypeInfo,
  SchemaAttributesPath,
} from "./types";
import {
  DerivedAttributeDeps,
  DynamicAttributeDeps,
  useSchemaEditDeps,
} from "./useSchemaEditDeps";

export type CatalogTypeSchemaSectionProps = {
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState>;
  attributeDependencies?: { [key: string]: DependentResource[] };
  requiredIntegration?: IntegrationConfig;
  onAddAttributeCallback?: (attributeOption: AttributeFormState) => void;
  catalogTypeInfo: CatalogTypeInfo;
  hideDerivedAttributes?: boolean;
  typeManagedExternally?: boolean;
};

export const CatalogTypeSchemaSection = ({
  formMethods,
  attributeDependencies,
  requiredIntegration,
  onAddAttributeCallback,
  catalogTypeInfo,
  hideDerivedAttributes = false,
  typeManagedExternally = false,
}: CatalogTypeSchemaSectionProps) => {
  const [dataAttributes, derivedAttributes, ranked] = formMethods.watch([
    "schema.data_attributes",
    "schema.derived_attributes",
    "ranked",
  ]);

  const {
    showFullLoader,
    error,
    derivedDeps,
    dynamicDeps,
    resources,
    catalogTypes,
    teamSettings,
  } = useSchemaEditDeps({
    registryType: catalogTypeInfo.registryType,
    dynamicResourceParameter: catalogTypeInfo.parameter,
    sourceAttributes: [...dataAttributes, ...derivedAttributes],
    typeName: catalogTypeInfo.typeName,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const dependencies = buildAttributeDependencies({
    dataAttributes,
    derivedAttributes,
    dependencies: attributeDependencies,
  });

  return (
    <fieldset>
      <Form.Label htmlFor="icon" required>
        Attributes
      </Form.Label>
      <Form.Helptext>
        Each attribute will become a column in your catalog type
      </Form.Helptext>
      <LoadingWrapper loading={showFullLoader}>
        <div className="bg-surface-secondary p-4 flex flex-col gap-6 rounded-3 mt-3">
          <DataAttributesTable
            onAddAttributeCallback={onAddAttributeCallback}
            formMethods={formMethods}
            attributeDependencies={dependencies}
            catalogTypeInfo={catalogTypeInfo}
            requiredIntegration={requiredIntegration}
            resources={resources}
            catalogTypes={catalogTypes}
            dynamicDeps={dynamicDeps}
            ranked={!!ranked}
            typeManagedExternally={typeManagedExternally}
            teamSettings={teamSettings}
          />
          {!hideDerivedAttributes && (
            <DerivedAttributesTable
              onAddAttributeCallback={onAddAttributeCallback}
              formMethods={formMethods}
              attributeDependencies={dependencies}
              requiredIntegration={requiredIntegration}
              catalogTypes={catalogTypes}
              resources={resources}
              derivedDeps={derivedDeps}
              typeManagedExternally={typeManagedExternally}
              teamSettings={teamSettings}
            />
          )}
        </div>
      </LoadingWrapper>
    </fieldset>
  );
};

const DataAttributesTable = ({
  attributeDependencies,
  catalogTypeInfo,
  formMethods,
  onAddAttributeCallback,
  requiredIntegration,
  resources,
  catalogTypes,
  dynamicDeps,
  ranked,
  typeManagedExternally,
  teamSettings,
}: {
  attributeDependencies?: { [key: string]: DependentResource[] };
  catalogTypeInfo: CatalogTypeInfo;
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState, unknown>;
  onAddAttributeCallback?: (attributeOption: AttributeFormState) => void;
  requiredIntegration?: IntegrationConfig;
  resources: CatalogResource[];
  catalogTypes: CatalogType[];
  dynamicDeps: DynamicAttributeDeps;
  ranked: boolean;
  typeManagedExternally: boolean;
  teamSettings?: TeamSettings;
}) => {
  // We special case 'user' types to not allow you to add your own attributes (for now) - you have too many
  // users to manually manage these sensibly.
  const isUserType = catalogTypeInfo?.registryType === "User";

  const {
    onDragEnd,
    onBeforeDragStart,
    isDragging,
    removeAttribute,
    onAddAttribute,
    fieldMethods,
  } = useMutateAttributes({
    formMethods,
    path: "schema.data_attributes",
    onAddAttributeCallback,
  });

  const attributes = formMethods.watch("schema.data_attributes");

  return (
    <div className="flex flex-col gap-3">
      <LabelAndDescription
        label="Data"
        description={`These attributes can be primitives (e.g. text, numbers), or reference other types in your catalog`}
      />
      <AttributesTable
        attributes={attributes}
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
        fixedFirstRow={
          <>
            <CatalogTypeSchemaRowName />
            {ranked && <CatalogTypeSchemaRowRank />}
          </>
        }
        droppableId="catalogTypeDataAttributes"
        typeColumnLabel={
          <div className="flex-center-y gap-0.5">
            Type
            <Tooltip
              bubbleProps={{ className: "max-w-[330px]" }}
              content={
                <span>
                  The resource type defines what kind of value this attribute
                  represents. It can be a primitive value such as a string or
                  number, or can refer to another catalog type.
                </span>
              }
            />
          </div>
        }
        renderRow={({ provided, field, idx }) => {
          return (
            <CatalogTypeSchemaRow
              index={idx}
              path={`schema.data_attributes.${idx}`}
              attribute={attributes[idx]}
              mode={(field as unknown as CatalogTypeAttribute).mode}
              key={field.id}
              fieldMethods={fieldMethods}
              onDelete={() => removeAttribute(field, idx)}
              formMethods={formMethods}
              provided={provided}
              isDragging={isDragging}
              resources={resources}
              catalogTypes={catalogTypes}
              requiredIntegration={requiredIntegration}
              dependencies={
                attributeDependencies && field.id
                  ? attributeDependencies[field.id]
                  : []
              }
              typeManagedExternally={typeManagedExternally}
              isTeamAttribute={
                teamSettings?.members_attribute_id === field.id ||
                teamSettings?.escalation_paths_attribute_id === field.id
              }
            />
          );
        }}
        footer={
          isUserType ? undefined : (
            <>
              {dynamicDeps.hasAnyDynamicAttributes ? (
                <AddDynamicAttributesPopover
                  onAddAttribute={onAddAttribute}
                  schema={attributes || []}
                  integrationLabel={requiredIntegration?.label}
                  resources={resources}
                  dynamicAttributes={dynamicDeps.dynamicAttributes}
                  disabled={typeManagedExternally}
                />
              ) : undefined}
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Add}
                onClick={() =>
                  onAddAttribute({
                    name: "",
                    type: "String",
                    array: false,
                    mode: AttributePayloadModeEnum.Dashboard,
                  })
                }
                disabled={typeManagedExternally}
                analyticsTrackingId={"catalog-add-attribute"}
              >
                Add attribute
              </Button>
            </>
          )
        }
      />
    </div>
  );
};

const DerivedAttributesTable = ({
  attributeDependencies,
  onAddAttributeCallback,
  formMethods,
  requiredIntegration,
  resources,
  catalogTypes,
  derivedDeps,
  typeManagedExternally,
  teamSettings,
}: {
  attributeDependencies?: { [key: string]: DependentResource[] };
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState, unknown>;
  onAddAttributeCallback?: (attributeOption: AttributeFormState) => void;
  requiredIntegration?: IntegrationConfig;
  resources: CatalogResource[];
  catalogTypes: CatalogType[];
  derivedDeps: DerivedAttributeDeps;
  typeManagedExternally: boolean;
  teamSettings?: TeamSettings;
}) => {
  const catalogTypeName = formMethods.watch("name");

  const {
    onDragEnd,
    onBeforeDragStart,
    isDragging,
    removeAttribute,
    onAddAttribute,
    fieldMethods,
  } = useMutateAttributes({
    formMethods,
    path: "schema.derived_attributes",
    onAddAttributeCallback,
  });

  const attributes = formMethods.watch("schema.derived_attributes");

  // We want to make sure that we don't show the derived attributes section and then insta-hide it
  // when you e.g. delete a backlink. To do that, we decide up-front whether to show derived attributes
  // and then never 'hide' them.
  const [showDerivedAttributesOverride, setShowDerivedAttributesOverride] =
    useState(false);

  const showDerivedAttributes =
    derivedDeps.hasAvailableDerivedAttributes || attributes.length > 0;

  useEffect(() => {
    if (showDerivedAttributes && !showDerivedAttributesOverride) {
      setShowDerivedAttributesOverride(true);
    }
  }, [
    showDerivedAttributes,
    showDerivedAttributesOverride,
    setShowDerivedAttributesOverride,
  ]);

  if (!showDerivedAttributes && !showDerivedAttributesOverride) {
    return null;
  }

  const attributeEntries = buildDerivedAttributeEntries({
    ...derivedDeps,
    catalogTypes,
  });

  return (
    <div className="flex flex-col gap-3">
      <LabelAndDescription
        label="Derived"
        description={`These attributes will be populated based on the data already in your catalog`}
      />
      <AttributesTable
        attributes={attributes}
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
        typeColumnLabel="Path"
        droppableId="catalogTypeDerivedAttributes"
        renderRow={({ provided, field, idx }) => {
          return (
            <CatalogTypeSchemaRow
              index={idx}
              path={`schema.derived_attributes.${idx}`}
              attribute={attributes[idx]}
              mode={(field as unknown as CatalogTypeAttribute).mode}
              key={field.id}
              fieldMethods={fieldMethods}
              onDelete={() => removeAttribute(field, idx)}
              formMethods={formMethods}
              provided={provided}
              isDragging={isDragging}
              resources={resources}
              catalogTypes={catalogTypes}
              requiredIntegration={requiredIntegration}
              dependencies={
                attributeDependencies && field.id
                  ? attributeDependencies[field.id]
                  : []
              }
              typeManagedExternally={typeManagedExternally}
              isTeamAttribute={
                teamSettings?.members_derived_from_attribute_id === field.id
              }
            />
          );
        }}
        footer={
          <AddDerivedAttributesPopover
            onAddAttribute={onAddAttribute}
            catalogTypeName={catalogTypeName}
            attributeEntries={attributeEntries}
            renderTriggerNode={({ onClick, disabled }) => (
              <Button
                onClick={onClick}
                disabled={typeManagedExternally || disabled}
                icon={IconEnum.Add}
                size={BadgeSize.Medium}
                analyticsTrackingId={null}
                theme={ButtonTheme.Naked}
              >
                Add attribute
              </Button>
            )}
          />
        }
        emptyState={
          <EmptyState
            content="To get started, choose an example or select from the full list"
            className="[&>*]:max-w-none"
            cta={
              <div className="flex items-center gap-2 flex-wrap justify-center">
                {take(attributeEntries, 3).map((entry, idx) => (
                  <button onClick={() => onAddAttribute(entry.item)} key={idx}>
                    <DerivedAttributeBadge
                      attribute={entry.item}
                      catalogTypes={catalogTypes}
                      className="cursor-pointer border border-dashed border-slate-200 hover:border-slate-300 bg-transparent text-content-tertiary hover:text-content-secondary max-w-[400px]"
                    />
                  </button>
                ))}
                <AddDerivedAttributesPopover
                  onAddAttribute={onAddAttribute}
                  catalogTypeName={catalogTypeName}
                  attributeEntries={attributeEntries}
                  renderTriggerNode={({ onClick, disabled }) => (
                    <Button
                      onClick={onClick}
                      disabled={typeManagedExternally || disabled}
                      icon={IconEnum.Add}
                      size={BadgeSize.Medium}
                      analyticsTrackingId={null}
                      title="Add attribute"
                    />
                  )}
                />
              </div>
            }
          />
        }
      />
    </div>
  );
};

const LabelAndDescription = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="text-content-primary text-sm-bold"> {label}</div>
      <div className="text-content-secondary text-xs-med"> {description}</div>
    </div>
  );
};

const AttributesTable = ({
  attributes,
  fixedFirstRow,
  footer,
  onBeforeDragStart,
  onDragEnd,
  renderRow,
  emptyState,
  typeColumnLabel,
  droppableId,
}: {
  onBeforeDragStart: () => void;
  onDragEnd: (result: DropResult) => void;
  attributes: AttributeFormState[];
  fixedFirstRow?: React.ReactNode;
  footer: React.ReactNode;
  emptyState?: React.ReactNode;
  typeColumnLabel: React.ReactNode;
  droppableId: string;
  renderRow: (props: {
    provided: DraggableProvided;
    field: AttributeFormState;
    idx: number;
  }) => React.ReactElement;
}) => {
  if (attributes.length === 0 && !!emptyState && !fixedFirstRow) {
    return <>{emptyState}</>;
  }

  return (
    <DeprecatedTable>
      <DeprecatedTableHeaderRow className="bg-white">
        <DeprecatedTableHeaderCell className="w-4/12 !pl-10">
          Name
        </DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell className="w-6/12">
          {typeColumnLabel}
        </DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell className="w-2/12" colSpan={2}>
          <div className="flex-center-y gap-0.5">
            Multi value
            <Tooltip content="Multi-valued attributes represent a list of zero-or-more instances of their resource type." />
          </div>
        </DeprecatedTableHeaderCell>
      </DeprecatedTableHeaderRow>
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId={droppableId}>
          {(provided: DroppableProvided) => {
            return (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {fixedFirstRow}
                {attributes.map((attr, idx) => {
                  return (
                    <Draggable key={attr.id} draggableId={attr.id} index={idx}>
                      {(provided) => renderRow({ provided, field: attr, idx })}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            );
          }}
        </Droppable>
      </DragDropContext>
      {footer && (
        <tfoot className="border-t border-stroke">
          <tr>
            <td colSpan={3}>
              <div className="flex items-center gap-3">{footer}</div>
            </td>
          </tr>
        </tfoot>
      )}
    </DeprecatedTable>
  );
};

const useMutateAttributes = ({
  formMethods,
  path,
  onAddAttributeCallback,
}: {
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState>;
  path: SchemaAttributesPath;
  onAddAttributeCallback?: (attr: AttributeFormState) => void;
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const fieldMethods = useFieldArray({
    control: formMethods.control,
    name: path,
    keyName: "key",
  });

  const parameterisedTypeSchemas = formMethods.watch(
    "parameterised_type_schemas",
  );
  const parameterisedTypeMethods = useFieldArray({
    control: formMethods.control,
    name: "parameterised_type_schemas",
  });

  const [dataAttributes, derivedAttributes] = formMethods.watch([
    "schema.data_attributes",
    "schema.derived_attributes",
  ]);

  const allAttributes = [...dataAttributes, ...derivedAttributes];

  const removeAttribute = (field: AttributeFormState, idx: number) => {
    // Remove any parameterised type schemas that are no longer needed
    const extraSchemaIndex =
      parameterisedTypeSchemas?.findIndex(
        (x) =>
          x.parameterised_resource_arguments?.registry_type ===
            field.catalogTypeInfo?.registryType &&
          x.parameterised_resource_arguments?.parameter ===
            field.catalogTypeInfo?.parameter,
      ) ?? -1;
    if (extraSchemaIndex >= 0) {
      parameterisedTypeMethods.remove(extraSchemaIndex);
    }

    fieldMethods.remove(idx);
  };

  const onAddAttribute = (attr: CatalogTypeAttributePayload) => {
    // First, check if we need to add a suffix to the name so it stays unique
    const existingAttributeNames = allAttributes.map((attr) => attr.name);

    // Only make the name unique if there's actually an attribute name set
    if (attr.name !== "") {
      let uniqueName = attr.name;
      if (existingAttributeNames.includes(uniqueName)) {
        for (let i = 1; i < 10; i++) {
          const candidate = `${uniqueName} (${i})`;
          if (!existingAttributeNames.includes(candidate)) {
            uniqueName = candidate;
            break;
          }
        }
      }
      attr.name = uniqueName;
    }

    // Mint a new ULID to use
    if (attr.id === undefined) {
      attr.id = ulid();
    }

    if (onAddAttributeCallback) {
      onAddAttributeCallback(attr as AttributeFormState);
    }

    fieldMethods.append(attr as AttributeFormState);
  };

  const existingAttributes = formMethods.watch(path);

  // We need to fire just before we start dragging to support the locked cells:
  // see the LockedCell component for more detail.
  const onBeforeDragStart = useCallback(() => setIsDragging(true), []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragging(false);

      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      const attributesClone = clone(existingAttributes) || [];

      // Snip out the element we moved
      const [removed] = attributesClone.splice(fromIndex, 1);

      // Insert it back into the list wherever we dragged it to
      attributesClone.splice(toIndex, 0, removed);

      formMethods.setValue(path, attributesClone);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [existingAttributes],
  );

  return {
    isDragging,
    onBeforeDragStart,
    onDragEnd,
    removeAttribute,
    fieldMethods,
    onAddAttribute,
  };
};

const buildAttributeDependencies = ({
  dataAttributes,
  derivedAttributes,
  dependencies,
}: {
  dataAttributes: AttributeFormState[];
  derivedAttributes: AttributeFormState[];
  dependencies?: { [key: string]: DependentResource[] };
}): { [key: string]: DependentResource[] } => {
  const newDependencies: { [key: string]: DependentResource[] } =
    cloneDeep(dependencies) || {};

  // Add any path attributes to our attributeDependencies
  derivedAttributes.forEach((attr) => {
    const path = attr.path;
    if (path) {
      const rootAttribute = dataAttributes.find(
        (attr) => attr.id === path[0].attribute_id,
      );
      if (rootAttribute) {
        newDependencies[rootAttribute.id] =
          newDependencies[rootAttribute.id] || [];

        // Check it's not already there
        if (!newDependencies[rootAttribute.id].find((x) => x.id === attr.id)) {
          newDependencies[rootAttribute.id].push({
            id: attr.id,
            label: attr.name,
            can_be_auto_deleted: false,
            resource_type: DependentResourceResourceTypeEnum.CatalogType,
            resource_type_label: "Attribute",
          });
        }
      }
    }
  });

  return newDependencies;
};
