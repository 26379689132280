import {
  AvailableBacklinkV2,
  AvailablePathAttribute,
  CatalogResource,
  CatalogType,
  CatalogTypeAttributePayload,
  DynamicAttributeOption,
  ErrorResponse,
  TeamSettings,
} from "@incident-io/api";
import { useState } from "react";
import { useAPI } from "src/utils/swr";

import { useTeamSettings } from "../../settings/teams/common/useTeamSettings";

export type DerivedAttributeDeps = {
  derivedAttributesLoading: boolean;
  paths: AvailablePathAttribute[];
  backlinks: AvailableBacklinkV2[];
  hasAvailableDerivedAttributes: boolean;
};

export type DynamicAttributeDeps = {
  dynamicAttributesLoading: boolean;
  dynamicAttributes: DynamicAttributeOption[];
  hasAnyDynamicAttributes: boolean;
};

// useSchemaEditDeps is used to load (and if needed, refetch) all the data we need
// to render the SchemaSection of the catalog type form. This is so we don't have
// spinner-geddon, or unecessary layout shift.
export const useSchemaEditDeps = ({
  registryType,
  typeName,
  dynamicResourceParameter,
  sourceAttributes,
}: {
  registryType?: string;
  typeName?: string;
  dynamicResourceParameter?: string;
  sourceAttributes: CatalogTypeAttributePayload[];
}): {
  showFullLoader: boolean;
  error?: ErrorResponse;
  derivedDeps: DerivedAttributeDeps;
  dynamicDeps: DynamicAttributeDeps;
  resources: CatalogResource[];
  catalogTypes: CatalogType[];
  teamSettings?: TeamSettings;
} => {
  const [hasLoadedDerived, setHasLoadedDerived] = useState(false);

  const {
    teamSettings,
    loading: teamSettingsLoading,
    error: teamSettingsError,
  } = useTeamSettings();
  const {
    data: { resources },
    isLoading: resourcesLoading,
    error: resourcesError,
  } = useAPI("catalogListResources", undefined, {
    fallbackData: { resources: [] },
  });

  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const {
    data: { additional_attributes: dynamicAttributes },
    isLoading: dynamicAttributesLoading,
    error: dynamicAttributesError,
  } = useAPI(
    registryType ? "catalogListAdditionalAttributes" : null,
    { registryType: registryType || "", dynamicResourceParameter },
    { fallbackData: { additional_attributes: [] } },
  );

  const {
    data: { paths, backlinks },
    isLoading: derivedAttributesLoading,
    error: derivedAttributesError,
  } = useAPI(
    "catalogListDerivedAttributes",
    {
      listDerivedAttributesRequestBody: {
        source_attributes: sourceAttributes,
        source_type: typeName,
      },
    },
    {
      fallbackData: { paths: [], backlinks: [] },
      onSuccess: () => setHasLoadedDerived(true),
    },
  );

  return {
    // showFullLoader controls whether we show a full loader instead of any of
    // the attribute tables. We should only do this when you first load the page.
    showFullLoader:
      dynamicAttributesLoading ||
      resourcesLoading ||
      catalogTypesLoading ||
      !hasLoadedDerived ||
      teamSettingsLoading,
    error:
      dynamicAttributesError ||
      derivedAttributesError ||
      catalogTypesError ||
      resourcesError ||
      teamSettingsError,
    resources,
    catalogTypes,
    teamSettings,
    derivedDeps: {
      derivedAttributesLoading,
      paths,
      backlinks,
      hasAvailableDerivedAttributes: paths.length > 0 || backlinks.length > 0,
    },
    dynamicDeps: {
      dynamicAttributesLoading,
      dynamicAttributes,
      hasAnyDynamicAttributes: dynamicAttributes.length > 0,
    },
  };
};
