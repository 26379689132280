/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AICheckResult,
    AICheckResultFromJSON,
    AICheckResultFromJSONTyped,
    AICheckResultToJSON,
} from './AICheckResult';

/**
 * 
 * @export
 * @interface AIEvalResult
 */
export interface AIEvalResult {
    /**
     * YAML representation of the actual response
     * @type {string}
     * @memberof AIEvalResult
     */
    actual: string;
    /**
     * Total cost in cents for running the evals against this request
     * @type {number}
     * @memberof AIEvalResult
     */
    check_cost_cents: number;
    /**
     * Results of individual evaluation checks
     * @type {Array<AICheckResult>}
     * @memberof AIEvalResult
     */
    check_results: Array<AICheckResult>;
    /**
     * Total cost in cents for the request against this model
     * @type {number}
     * @memberof AIEvalResult
     */
    request_cost_cents: number;
}

export function AIEvalResultFromJSON(json: any): AIEvalResult {
    return AIEvalResultFromJSONTyped(json, false);
}

export function AIEvalResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIEvalResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actual': json['actual'],
        'check_cost_cents': json['check_cost_cents'],
        'check_results': ((json['check_results'] as Array<any>).map(AICheckResultFromJSON)),
        'request_cost_cents': json['request_cost_cents'],
    };
}

export function AIEvalResultToJSON(value?: AIEvalResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actual': value.actual,
        'check_cost_cents': value.check_cost_cents,
        'check_results': ((value.check_results as Array<any>).map(AICheckResultToJSON)),
        'request_cost_cents': value.request_cost_cents,
    };
}

