import "react-phone-number-input/style.css";

import {
  OnCallNotificationMethodV2,
  OnCallNotificationRuleRuleTypeEnum,
  UserPreferenceManualEscalationProviderOption,
  UserPreferenceManualEscalationProviderOptionProviderEnum,
  UserPreferences,
  UserPreferencesPreferredManualEscalationProviderEnum,
  UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyPreferredEscalationProviderEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
  RadioButtonGroup,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useState } from "react";
import { UserPreferencesHeading } from "src/components/user-preferences/common/UserPreferencesHeading";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import { useClient } from "../../../contexts/ClientContext";
import { useProductAccess } from "../../../hooks/useProductAccess";
import { useRevalidate } from "../../../utils/use-revalidate";
import { isOnCallUser } from "../../settings/users/users/utils";
import { ContactMethods } from "./ContactMethods";
import { NotificationRulesForType } from "./NotificationRulesForType";
import { useShortestEscalationPathForUser } from "./OnCallNotificationConfigurationInvalidCallout";
import { OnCallNotificationsAppOnboardingCallout } from "./OnCallNotificationsAppOnboardingCallout";
import { OnCallNotificationBox } from "./OnCallNotificationsBox";
import { OnCallNotificationsPhoneOnboarding } from "./OnCallNotificationsPhoneOnboarding";
import { WrongSeatTypeWarning } from "./WrongSeatTypeWarning";

// Allow methods to be either those we add for all users or those that have been
// explicitly created on their account.
export type Method = OnCallNotificationMethodV2;

export const OnCallNotificationsPage = () => {
  const { identity } = useIdentity();

  const { chooseManualEscalationProvider } = useFlags();
  const { hasOnCall: orgHasOnCall } = useProductAccess();

  const { data: userPreferencesResponse, isLoading: preferencesLoading } =
    useAPI("userPreferencesShow", undefined, {
      keepPreviousData: true, // Don't flicker paging provider preferences when updating
    });

  const { data: escalationProvidersResponse, isLoading: providersLoading } =
    useAPI("userPreferencesListManualEscalationProviderOptions", undefined);

  const {
    data: methodsData,
    error: methodsError,
    isLoading: methodsLoading,
  } = useAPI("onCallNotificationsListMethodsV2", undefined);

  const {
    data: rulesData,
    error: rulesError,
    isLoading: rulesLoading,
  } = useAPI("onCallNotificationsListRules", undefined);

  const { data: onboardingStateData, isLoading: onboardingStateLoading } =
    useAPI("onCallOnboardingCurrentOnboardingState", undefined);

  // Find the escalation path that this user is on with the shortest time to
  // respond.
  const shortestEscalationPath = useShortestEscalationPathForUser(
    identity?.user_id ?? "",
  );

  const userOnboarded =
    (onboardingStateData?.ios?.onboarded ||
      onboardingStateData?.android?.onboarded ||
      onboardingStateData?.web?.onboarded) ??
    false;

  const appOnboarded =
    (onboardingStateData?.ios?.onboarded ||
      onboardingStateData?.android?.onboarded) ??
    false;

  const loading =
    preferencesLoading || methodsLoading || rulesLoading || providersLoading;

  // we should be tolerant of escalation levels not being available
  const error = methodsError || rulesError;

  const [showAddMethodModal, setShowAddMethodModal] = useState({
    show: false,
    allowEmail: true,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const rulesByTypes = _.chain(
    Object.values(OnCallNotificationRuleRuleTypeEnum),
  )
    .map((type) => ({
      type,
      rules: rulesData?.rules.filter((r) => r.rule_type === type) ?? [],
      displayItem: ruleTypeToDisplayItem(
        type as OnCallNotificationRuleRuleTypeEnum,
      ),
    }))
    .sortBy("displayItem.rank")
    .value();

  const allNotificationMethods = methodsData?.methods ?? [];

  const phoneOnboarded =
    onboardingStateData?.web?.tasks.find(
      (t) => t.task === "dashboard_has_verified_phone_number",
    )?.completed ?? false;

  const availableEscalationProviders =
    escalationProvidersResponse?.escalation_providers ?? [];
  if (!loading && availableEscalationProviders.length === 0) {
    return <OrgAwareNavigate replace to={"/user-preferences"} />;
  }

  // We'll only show ONC contact methods and notification preferences if:
  // - Your org has on-call available
  // - You have an on call seat
  // - The 'choose manual escalation provider' flag is set to true. When that flag is false, this screen looks quite empty
  //   if you don't have an on-call seat, so we just show a warning instead.
  const showNotificationDetails = chooseManualEscalationProvider
    ? orgHasOnCall && isOnCallUser(identity.user_state)
    : true;

  return (
    <SecondaryNavSubPageWrapper
      title="On-call notifications"
      icon={IconEnum.OnCall}
    >
      <LoadingWrapper loading={loading} isTransparent>
        <div className={"max-w-[640px]"}>
          {/* Once this flag is enabled, we change the content of this screen based on your seat, so the warning
            won't be required anymore! */}
          {!chooseManualEscalationProvider && <WrongSeatTypeWarning />}
          {!onboardingStateLoading && isOnCallUser(identity.user_state) && (
            <>
              <OnCallNotificationsAppOnboardingCallout
                userAppOnboarded={appOnboarded}
              />
              <OnCallNotificationsPhoneOnboarding
                userOnboarded={userOnboarded}
                phoneOnboarded={phoneOnboarded}
                setShowAddMethodModal={setShowAddMethodModal}
              />
              {!userOnboarded && <FormDivider className="mt-10 mb-6" />}
            </>
          )}
          <UserPreferencesHeading title={"On-call notifications"} />
          <div className={"flex flex-col space-y-4"}>
            {chooseManualEscalationProvider && userPreferencesResponse && (
              <ChooseEscalationProvider
                availableEscalationProviders={availableEscalationProviders}
                userPreferences={userPreferencesResponse?.user_preferences}
              />
            )}

            {showNotificationDetails && (
              <>
                <div className="flex flex-col space-y-4">
                  <ContactMethods
                    methods={allNotificationMethods}
                    showAddMethodModal={showAddMethodModal}
                    setShowAddMethodModal={setShowAddMethodModal}
                  />
                </div>
                <div className={"flex flex-col space-y-6"}>
                  {rulesByTypes.map(({ type, rules, displayItem }) => (
                    <NotificationRulesForType
                      key={type}
                      type={type as OnCallNotificationRuleRuleTypeEnum}
                      rules={rules}
                      displayItem={displayItem}
                      methods={allNotificationMethods}
                      shortestEscalationPath={shortestEscalationPath}
                      loading={loading}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </LoadingWrapper>
    </SecondaryNavSubPageWrapper>
  );
};

export type RuleTypeDisplayItem = {
  title: string;
  description: string;
  rank: number;
};
const ruleTypeToDisplayItem = (
  type: OnCallNotificationRuleRuleTypeEnum,
): RuleTypeDisplayItem => {
  switch (type) {
    case OnCallNotificationRuleRuleTypeEnum.HighUrgency:
      return {
        title: "High-urgency notifications",
        description:
          "Notifications that must reach you, no matter the time of day.",
        rank: 0,
      };
    case OnCallNotificationRuleRuleTypeEnum.LowUrgency:
      return {
        title: "Low-urgency notifications",
        description: "Notifications that don't require immediate action.",
        rank: 1,
      };
    case OnCallNotificationRuleRuleTypeEnum.ShiftChanges:
      return {
        title: "Shift changes",
        description:
          "Tell us how and when to notify you when you are coming on an on call shift.",
        rank: 2,
      };
    default:
      assertUnreachable(type);
      return {
        rank: 0,
        title: "",
        description: "",
      };
  }
};

const ChooseEscalationProvider = ({
  availableEscalationProviders,
  userPreferences,
}: {
  userPreferences: UserPreferences;
  availableEscalationProviders: UserPreferenceManualEscalationProviderOption[];
}) => {
  const { identity } = useIdentity();
  const { hasOnCall: orgHasOnCall } = useProductAccess();

  const apiClient = useClient();
  const revalidate = useRevalidate(["userPreferencesShow"]);
  const {
    setState,
    hasSaved,
    saving,
    state: preferredProvider,
  } = useOptimisticAutoSave<
    UserPreferencesPreferredManualEscalationProviderEnum | undefined
  >({
    initialState: userPreferences.preferred_manual_escalation_provider,
    saveState: async (
      preference:
        | UserPreferencesPreferredManualEscalationProviderEnum
        | undefined,
    ) => {
      await apiClient.userPreferencesUpdateManualEscalationProviderPreferences({
        updateManualEscalationProviderPreferencesRequestBody: {
          preferred_escalation_provider:
            preference as unknown as UserPreferencesUpdateManualEscalationProviderPreferencesRequestBodyPreferredEscalationProviderEnum,
        },
      });
      await revalidate();
    },
  });

  const ifMissingUserLink = (
    p: UserPreferenceManualEscalationProviderOptionProviderEnum,
  ): string | undefined =>
    availableEscalationProviders.some((a) => a.provider === p && !a.id)
      ? "You need to link your account using Catalog"
      : undefined;

  const ConfigForProvider = {
    [UserPreferenceManualEscalationProviderOptionProviderEnum.Native]: {
      icon: IconEnum.IncidentFlame,
      label: "incident.io",
      color: ColorPaletteEnum.Red,
      disabledReason: !orgHasOnCall
        ? "Your organization does not have on-call enabled"
        : !isOnCallUser(identity.user_state)
        ? "You don't have on-call enabled"
        : undefined,
    },
    [UserPreferenceManualEscalationProviderOptionProviderEnum.Pagerduty]: {
      icon: IconEnum.Pagerduty,
      label: "PagerDuty",
      color: ColorPaletteEnum.Green,
      disabledReason: ifMissingUserLink(
        UserPreferenceManualEscalationProviderOptionProviderEnum.Pagerduty,
      ),
    },
    [UserPreferenceManualEscalationProviderOptionProviderEnum.Opsgenie]: {
      icon: IconEnum.Opsgenie,
      label: "Opsgenie",
      color: ColorPaletteEnum.Blue,
      disabledReason: ifMissingUserLink(
        UserPreferenceManualEscalationProviderOptionProviderEnum.Opsgenie,
      ),
    },
    [UserPreferenceManualEscalationProviderOptionProviderEnum.SplunkOnCall]: {
      icon: IconEnum.Splunk,
      label: "Splunk/VictorOps",
      color: ColorPaletteEnum.Green,
      disabledReason: ifMissingUserLink(
        UserPreferenceManualEscalationProviderOptionProviderEnum.SplunkOnCall,
      ),
    },
  };

  return (
    <OnCallNotificationBox
      title={"Paging provider"}
      description={
        "Choose which provider you’d like to be paged by when escalated to directly"
      }
      button={<AutoSavingIndicator saving={saving} hasSaved={hasSaved} />}
    >
      <RadioButtonGroup
        name={"escalationProvider"}
        boxed
        onChange={(e) =>
          setState(e as UserPreferencesPreferredManualEscalationProviderEnum)
        }
        value={preferredProvider}
        options={availableEscalationProviders.map((p) => ({
          value: p.provider,
          label: ConfigForProvider[p.provider].label,
          suffixNode: ConfigForProvider[p.provider].disabledReason ? (
            <div className={"flex flex-row items-center "}>
              <span className="text-sm text-content-tertiary">
                {ConfigForProvider[p.provider].disabledReason}
              </span>
            </div>
          ) : undefined,
          isDisabled: !!ConfigForProvider[p.provider].disabledReason,
          prefixNode: (
            <IconBadge
              size={IconSize.Small}
              icon={ConfigForProvider[p.provider].icon}
              color={ConfigForProvider[p.provider].color}
            />
          ),
        }))}
        srLabel={"Choose a paging provider"}
      />
    </OnCallNotificationBox>
  );
};
