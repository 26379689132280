import { AlertSourceConfig } from "@incident-io/api";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { Icon, IconEnum, IconSize } from "@incident-ui";

import { tcx } from "../../../utils/tailwind-classes";

export const AlertSourceConfigBadge = ({
  sourceConfig,
  hasFilters,
  compact,
}: {
  sourceConfig: AlertSourceConfig;
  hasFilters: boolean;
  compact?: boolean;
}) => {
  const sourceType = sourceConfig.source_type;

  const config = ALERT_SOURCE_TYPE_CONFIGS[sourceType];
  if (!config) {
    return null;
  }
  return (
    <div
      className={tcx(
        "flex items-center gap-1 rounded-1 text-sm-med overflow-hidden",
        compact
          ? "text-xs-med text-content-secondary py-0.5 px-1"
          : "py-1 px-2",
      )}
      style={
        config.hexColor
          ? // Our hex color with opacity 10
            { backgroundColor: `${config.hexColor}10` }
          : undefined
      }
    >
      <Icon
        id={config.icon}
        className={config.className}
        size={compact ? IconSize.Small : IconSize.Medium}
      />
      {sourceConfig.name}
      {/* Not sure how to only show this if filters are applied? */}
      {hasFilters && (
        <Icon
          id={IconEnum.Filter}
          size={IconSize.Small}
          style={{ color: `${config.hexColor}60` }}
        />
      )}
    </div>
  );
};
