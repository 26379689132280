import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Drawer,
  DrawerContents,
  DrawerTitle,
  DrawerWidth,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useCallback, useState } from "react";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";

export type StaffRoomOperationProps = {
  organisation: StaffShowOrganisationResponseBody;
  onClose: () => void;
};

export interface StaffRoomOperation {
  id: string;
  title: string;
  emoji: string;
  description?: string;
  drawerWidth?: DrawerWidth;
  requiredPermission?: StaffPermissionEnum;
  Component: React.ComponentType<StaffRoomOperationProps>;
}

export const Operation = ({
  op,
  organisation,
}: {
  op: StaffRoomOperation;
  organisation: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const { Component } = op;

  const disabledProps = useCheckStaffPermissions(op.requiredPermission);

  return (
    <>
      <GatedButton
        analyticsTrackingId="staff-room-operation-opened"
        analyticsTrackingMetadata={{ id: op.id }}
        onClick={handleOpen}
        {...disabledProps}
      >
        <div className="flex items-center gap-2">
          <div className="mt-0.5">{op.emoji}</div>
          <div>{op.title}</div>
        </div>
      </GatedButton>
      <AnimatePresence>
        {isOpen && (
          <Drawer onClose={handleClose} width={op.drawerWidth ?? "medium"}>
            <DrawerContents>
              <DrawerTitle
                title={op.title}
                subtitle={op.description}
                onClose={handleClose}
                color={ColorPaletteEnum.Orange}
              />
              <div className="m-1">
                <div className="p-5 max-w-full overflow-x-auto flex flex-col gap-y-4">
                  <Component
                    organisation={organisation}
                    onClose={handleClose}
                  />
                </div>
              </div>
            </DrawerContents>
          </Drawer>
        )}
      </AnimatePresence>
    </>
  );
};
