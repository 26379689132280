/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterestingChannel
 */
export interface InterestingChannel {
    /**
     * Unique identifier of the Slack channel in our db table
     * @type {string}
     * @memberof InterestingChannel
     */
    id: string;
    /**
     * Whether this is a private channel
     * @type {boolean}
     * @memberof InterestingChannel
     */
    is_private: boolean;
    /**
     * Name of the Slack channel that is interesting
     * @type {string}
     * @memberof InterestingChannel
     */
    name: string;
    /**
     * ID of the Slack channel
     * @type {string}
     * @memberof InterestingChannel
     */
    slack_channel_id: string;
    /**
     * ID of the Slack team
     * @type {string}
     * @memberof InterestingChannel
     */
    slack_team_id: string;
    /**
     * URL that links to the channel in slack
     * @type {string}
     * @memberof InterestingChannel
     */
    url: string;
}

export function InterestingChannelFromJSON(json: any): InterestingChannel {
    return InterestingChannelFromJSONTyped(json, false);
}

export function InterestingChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterestingChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_private': json['is_private'],
        'name': json['name'],
        'slack_channel_id': json['slack_channel_id'],
        'slack_team_id': json['slack_team_id'],
        'url': json['url'],
    };
}

export function InterestingChannelToJSON(value?: InterestingChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_private': value.is_private,
        'name': value.name,
        'slack_channel_id': value.slack_channel_id,
        'slack_team_id': value.slack_team_id,
        'url': value.url,
    };
}

