import { UserOption } from "@incident-io/api";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { useUniqueColorGenerator } from "@incident-shared/utils/uniqueColorContext";
import {
  Avatar,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  SortableList,
  Tooltip,
  Txt,
} from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { useDebounce } from "use-debounce";

import { useAPI } from "../../../../../utils/swr";
import { tcx } from "../../../../../utils/tailwind-classes";
import { SortableItem } from "../../../../settings/announcements/posts/update/AnnouncementPostEditPage";
import { isOnCallUser } from "../../../../settings/users/users/utils";
import { NOBODY_USER } from "../overrides/UserInput";

export type ScheduleUser = {
  key?: string;
  id: string;
  label: string;
  rank: number;
};

const UserSearch = ({
  search,
  handleSearch,
  handleSubmit,
  onFocus,
  isManagedByTerraform,
}: {
  search: string;
  handleSearch: (s: string) => void;
  handleSubmit: () => void;
  onFocus: () => void;
  isManagedByTerraform?: boolean;
}) => {
  return (
    <>
      {isManagedByTerraform ? (
        <Tooltip
          content={"You cannot edit a schedule that is managed by Terraform"}
          analyticsTrackingId={null}
          bubbleProps={{ className: "!w-80" }}
          side="bottom"
        >
          <div className="usersearch flex-center-y h-[42px] border border-stroke bg-surface-secondary text-slate-600 cursor-not-allowed rounded-2 shadow-sm px-2 py-3">
            <Icon id={IconEnum.Search} className="text-content-tertiary" />
            <input
              type="search"
              id="search"
              value={search}
              onChange={(e) => {
                e.stopPropagation();
                handleSearch(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                // This prevents the keyboard input focusing the menu entries rather than typing
                e.stopPropagation();
                if (e.code === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              className="text-sm font-normal w-full  bg-surface-secondary text-slate-600 cursor-not-allowed border-0 p-0"
              placeholder="Search people"
              autoComplete="off"
              readOnly={isManagedByTerraform}
            />
          </div>
        </Tooltip>
      ) : (
        <div className="usersearch flex-center-y h-[42px] border border-stroke focus-within:border-slate-900 rounded-2 shadow-sm px-2 py-3">
          <Icon id={IconEnum.Search} className="text-content-tertiary" />
          <input
            type="search"
            id="search"
            value={search}
            onFocus={onFocus}
            onChange={(e) => {
              e.stopPropagation();
              handleSearch(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // This prevents the keyboard input focusing the menu entries rather than typing
              e.stopPropagation();
              if (e.code === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            className="text-sm font-normal w-full text-content-primary border-0 p-0"
            placeholder="Search people"
            autoComplete="off"
            readOnly={isManagedByTerraform}
          />
        </div>
      )}
    </>
  );
};

export const ScheduleCreateEditFormUserSelector = ({
  selectedUsers,
  onSelect,
  singleSelect,
  allowNobody = false,
  removeNotOnCallResponders = false,
  cacheHydratedUsers,
  isManagedByTerraform,
}: {
  selectedUsers: Array<UserOption>;
  onSelect: (user: UserOption) => void;
  cacheHydratedUsers: (val: UserOption[]) => void;
  singleSelect?: boolean;
  allowNobody?: boolean;
  removeNotOnCallResponders?: boolean;
  isManagedByTerraform?: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 250);

  const {
    data: { options: unfilteredUserOptions },
    isLoading,
  } = useAPI(
    "usersTypeahead",
    {
      query: debouncedSearch,
    },
    {
      fallbackData: {
        options: [],
      },
    },
  );

  if (!isLoading) {
    cacheHydratedUsers(unfilteredUserOptions);
  }

  const usersIncludingNotOnCall = unfilteredUserOptions;

  // Build a map (userId -> user) of currently selected users that we'll use to display how many times you're currently
  // on the rota
  const selectedUsersMap = _.groupBy(selectedUsers, "value");

  useEffect(() => {
    // If we allow nobody, include it in the cache
    if (allowNobody) {
      cacheHydratedUsers([...unfilteredUserOptions, NOBODY_USER]);
    } else {
      cacheHydratedUsers(unfilteredUserOptions);
    }
  }, [unfilteredUserOptions, allowNobody, cacheHydratedUsers]);

  // Sort users and handle Nobody option
  const users = useMemo(() => {
    let sortedUsers = [...unfilteredUserOptions];

    // Filter for on-call users if needed
    if (removeNotOnCallResponders) {
      sortedUsers = sortedUsers.filter((u) => isOnCallUser(u.state));
    }

    // Add Nobody option if allowed
    if (allowNobody) {
      const lowercaseSearch = search
        .toLowerCase()
        .replaceAll(" ", "")
        .replaceAll("-", "");
      const searchRefersToNobody =
        "nobody".includes(lowercaseSearch) || "noone".includes(lowercaseSearch);
      if (searchRefersToNobody) {
        sortedUsers = [NOBODY_USER, ...sortedUsers];
      }
    }

    return sortedUsers;
  }, [search, unfilteredUserOptions, removeNotOnCallResponders, allowNobody]);

  const [showMenu, setShowMenu] = useState(false);
  const handleSearch = (val: string) => {
    setSearch(val);
  };
  const closeMenu = () => setShowMenu(false);
  const handleReturn = () => {
    if (search && users.length > 0) {
      onSelect(users[0]);
      setSearch("");
    } else if (users.length > 0) {
      onSelect(users[0]);
    }
  };

  const handleSelect = (usr: UserOption) => {
    onSelect(usr);
    setSearch("");
    // If we only allow one user to be selected (i.e. in an override), we want to close the search after selecting.
    if (singleSelect) {
      closeMenu();
    }
  };

  return (
    <div className={"w-full"}>
      <Popover
        open={showMenu && !isManagedByTerraform}
        trigger={
          <UserSearch
            search={search}
            handleSearch={handleSearch}
            handleSubmit={handleReturn}
            onFocus={() => setShowMenu(true)}
            isManagedByTerraform={isManagedByTerraform}
          />
        }
        triggerAsChild={false}
        triggerClassName="w-full"
        align="start"
        onOpenAutoFocus={(e) => e.preventDefault()}
        onInteractOutside={(e: CustomEvent) => {
          // target has an ID if it exists, I can't get TS to acknowledge that
          const target = e?.target as unknown as { id?: string };
          // This keeps the menu open if you're clicking in the search bar
          if (target?.id !== "search") {
            closeMenu();
          }
        }}
        onEscapeKeyDown={closeMenu}
        className="p-1 w-[400px]"
      >
        <div className="w-full">
          {users.length === 0 ? (
            <Txt grey className="border border-transparent p-1">
              No users match your search.
            </Txt>
          ) : (
            <>
              <div className="max-h-[10em] overflow-y-auto">
                {users.map((user) => (
                  <UserDropdownOption
                    key={user.value}
                    user={user}
                    handleSelect={handleSelect}
                    currentlySelectedCount={
                      selectedUsersMap[user.value]?.length ?? 0
                    }
                  />
                ))}
              </div>
              {removeNotOnCallResponders &&
              usersIncludingNotOnCall.length > 0 &&
              users.length < 5 &&
              search !== "" ? (
                <Txt className="p-2 max-w-[300px]" grey>
                  Only users with On-call enabled can cover shifts. Enable
                  On-call for them in{" "}
                  <OrgAwareNavLink
                    to="/settings/users/users"
                    className="underline"
                  >
                    Settings &rarr; Users
                  </OrgAwareNavLink>
                  .
                </Txt>
              ) : (
                <Txt className="p-2" grey>
                  Press <Icon className="inline" id={IconEnum.ArrowRight} />
                  return to select first result
                </Txt>
              )}
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

type UserDropdownOptionProps = {
  user: UserOption;
  handleSelect: (user: UserOption) => void;
  currentlySelectedCount: number;
};

const UserDropdownOption = ({
  user,
  handleSelect,
  currentlySelectedCount,
}: UserDropdownOptionProps) => {
  const ordinalsToFrequencies = (i: number): string => {
    switch (i) {
      case 0:
        return "";
      case 1:
        return "Appears once";
      case 2:
        return "Appears twice";
    }
    return "Appears multiple times";
  };

  return (
    <div
      key={user.value}
      onClick={() => handleSelect(user)}
      className={tcx(
        "flex-center-y rounded border border-transparent p-2 justify-between",
        "text-sm text-content-primary font-normal",
        "hover:border-stroke hover:bg-surface-secondary hover:cursor-pointer",
      )}
    >
      <div className="flex-center-y gap-2">
        <Avatar size={IconSize.Large} url={user.image_url} name={user.label} />
        <Txt>{user.label}</Txt>
      </div>
      <Txt lightGrey>{ordinalsToFrequencies(currentlySelectedCount)}</Txt>
    </div>
  );
};

export const ScheduleCreateEditFormUsersList = ({
  users,
  onRemoveUser,
  onDuplicateUser,
  onReorder,
  isDisabled,
}: {
  users: UserOption[];
  onRemoveUser: (idx: number) => void;
  onDuplicateUser?: (idx: number) => void; // Providing onDuplicateUser conditionally displays the duplicate button
  onReorder?: (newUsers: SortableItem[]) => void;
  isDisabled?: boolean;
}) => {
  return (
    <>
      {users.some((u) => (u.state as string) === "deactivated") && (
        <Callout theme={CalloutTheme.Warning} className="mb-2">
          Some users on this schedule have been deactivated and will not receive
          notifications.
        </Callout>
      )}
      <SortableList
        items={users.map((u, i) => ({ id: u.value, rank: i }))}
        containerTag="div"
        containerProps={{
          className: "flex flex-col space-y-2",
        }}
        sortOrder="asc"
        updateItemRanks={
          onReorder ??
          (() => {
            return;
          })
        }
        isItemDragDisabled={() =>
          onReorder === undefined || isDisabled || false
        }
        droppableID="users"
        renderItem={(props: {
          item: SortableItem;
          index: number;
          draggableProvidedProps: DraggableProvided;
        }) => {
          const { index, draggableProvidedProps } = props;
          // Take common draggable props, which are needed to make
          // the child components draggable.
          const commonDraggableProps = {
            innerRef: draggableProvidedProps.innerRef,
            draggableProps: draggableProvidedProps.draggableProps,
            dragHandleProps: draggableProvidedProps.dragHandleProps,
          };
          return (
            <SelectedUser
              user={users[index]}
              onRemove={() => onRemoveUser(index)}
              onDuplicate={onDuplicateUser && (() => onDuplicateUser(index))}
              isDisabled={isDisabled}
              {...commonDraggableProps}
            />
          );
        }}
      />
    </>
  );
};

const SelectedUser = ({
  user,
  draggableProps,
  dragHandleProps,
  innerRef,
  onRemove,
  onDuplicate,
  isDisabled,
}: {
  user: UserOption;
  onRemove: () => void;
  onDuplicate?: () => void;
  innerRef?: React.LegacyRef<HTMLDivElement>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  isDisabled?: boolean;
}) => {
  const isNobody = user.value === "NOBODY";

  const colorGenerator = useUniqueColorGenerator();
  const { border, background, icon } = isNobody
    ? getColorPalette(ColorPaletteEnum.Slate)
    : colorGenerator(user.value);

  return (
    <div
      key={user.value}
      ref={innerRef}
      {...draggableProps}
      {...dragHandleProps}
      className={tcx(
        "flex-center-y w-full h-[42px] rounded-[6px] p-2 relative justify-between",
        border,
        background,
      )}
    >
      <div className="flex flex-row flex-center-y">
        <Icon
          id={IconEnum.Draggable}
          size={IconSize.XS}
          className={tcx("mr-2", icon)}
        />
        <Txt>{isNobody ? "No one" : user.label}</Txt>
      </div>
      <div className="flex flex-row flex-center-y">
        {onDuplicate && (
          <Button
            title="duplicate"
            theme={ButtonTheme.Naked}
            onClick={onDuplicate}
            className="pl-1 ml-auto"
            iconProps={{ className: tcx(icon, "hover:!text-content-primary") }}
            icon={IconEnum.Copy}
            analyticsTrackingId={null}
            disabled={isDisabled}
          />
        )}
        <Button
          title="remove"
          theme={ButtonTheme.Naked}
          onClick={onRemove}
          className="pl-1 ml-auto"
          iconProps={{ className: tcx(icon, "hover:!text-content-primary") }}
          icon={IconEnum.Close}
          analyticsTrackingId={null}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
