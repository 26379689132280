/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EvaluationLeaderboardEntry
 */
export interface EvaluationLeaderboardEntry {
    /**
     * When the backtest was created (i.e. when the actual scorecard was created)
     * @type {Date}
     * @memberof EvaluationLeaderboardEntry
     */
    backtest_created_at: Date;
    /**
     * ID of the backtest this entry represents
     * @type {string}
     * @memberof EvaluationLeaderboardEntry
     */
    backtest_id: string;
    /**
     * When the entry was created
     * @type {Date}
     * @memberof EvaluationLeaderboardEntry
     */
    created_at: Date;
    /**
     * 
     * @type {User}
     * @memberof EvaluationLeaderboardEntry
     */
    creator_user: User;
    /**
     * Unique identifier of the leaderboard entry
     * @type {string}
     * @memberof EvaluationLeaderboardEntry
     */
    id: string;
    /**
     * ID of the leaderboard this entry belongs to
     * @type {string}
     * @memberof EvaluationLeaderboardEntry
     */
    leaderboard_id: string;
    /**
     * Optional notes about this leaderboard entry
     * @type {string}
     * @memberof EvaluationLeaderboardEntry
     */
    notes: string;
    /**
     * IDs of the scorecards that sit behind this entry
     * @type {Array<string>}
     * @memberof EvaluationLeaderboardEntry
     */
    scorecard_ids: Array<string>;
    /**
     * When the entry was last updated
     * @type {Date}
     * @memberof EvaluationLeaderboardEntry
     */
    updated_at: Date;
}

export function EvaluationLeaderboardEntryFromJSON(json: any): EvaluationLeaderboardEntry {
    return EvaluationLeaderboardEntryFromJSONTyped(json, false);
}

export function EvaluationLeaderboardEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationLeaderboardEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_created_at': (new Date(json['backtest_created_at'])),
        'backtest_id': json['backtest_id'],
        'created_at': (new Date(json['created_at'])),
        'creator_user': UserFromJSON(json['creator_user']),
        'id': json['id'],
        'leaderboard_id': json['leaderboard_id'],
        'notes': json['notes'],
        'scorecard_ids': json['scorecard_ids'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationLeaderboardEntryToJSON(value?: EvaluationLeaderboardEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_created_at': (value.backtest_created_at.toISOString()),
        'backtest_id': value.backtest_id,
        'created_at': (value.created_at.toISOString()),
        'creator_user': UserToJSON(value.creator_user),
        'id': value.id,
        'leaderboard_id': value.leaderboard_id,
        'notes': value.notes,
        'scorecard_ids': value.scorecard_ids,
        'updated_at': (value.updated_at.toISOString()),
    };
}

