import {
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  DeclareFormData,
  FormElements,
  marshallFormElementDataToIncidentForm,
  marshallIncidentRolesToFormData,
  marshallRetrospectiveIncidentOptions,
  marshallTextDocumentPayload,
  RetrospectiveFormData,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Callout, CalloutTheme } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useEffect } from "react";
import {
  FieldNamesMarkedBoolean,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AvailableIncidentFormLifecycleElementElementTypeEnum as FormElementType,
  CustomFieldEntryPayload,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentsCreateRequestBody,
  IncidentsCreateRequestBodyModeEnum,
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { getLocalTimeZone } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";
import { v4 as uuid } from "uuid";

import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "./ActiveIncidentCreateModal";
import { buildIncidentTypeOptions } from "./buildIncidentTypeOptions";
import { buildSlackTeamOptions } from "./buildSlackTeamOptions";
import { IncidentCrudResourceTypes } from "./useIncidentCrudResources";

function formDataToRequestBody(
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  formData: RetrospectiveFormData,
  touchedFields: Partial<
    Readonly<FieldNamesMarkedBoolean<RetrospectiveFormData>>
  >,
): IncidentsCreateRequestBody {
  const incidentFormRequestBody = marshallFormElementDataToIncidentForm(
    ourElementBindings,
    formData,
    touchedFields,
  );

  return {
    ...formData,
    ...incidentFormRequestBody,
    mode: IncidentsCreateRequestBodyModeEnum.Retrospective,
    summary: marshallTextDocumentPayload(formData.summary),
    retrospective_incident_options:
      marshallRetrospectiveIncidentOptions(formData),
  };
}

export const RetrospectiveIncidentForm = ({
  incidentTypes,
  slackTeamConfigs,
  formMethods,
  elementBindings,
  customFieldEntryPayloads,
}: {
  formMethods: UseFormReturn<RetrospectiveFormData>;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
} & IncidentCrudResourceTypes): React.ReactElement => {
  const defaultIncidentType = incidentTypes.find((it) => it.is_default);

  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const slackTeamIds = useIdentity().identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const { watch } = formMethods;
  const [selectedIncidentTypeId, selectedSlackTeamId] = watch([
    "incident_type_id",
    "slack_team_id",
  ]);

  const { selectedIncidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: selectedIncidentTypeId,
    incidentTypes,
    includeNotYetKnownOption: false,
  });

  const { slackTeamOptions } = buildSlackTeamOptions({
    selectedSlackTeamID: selectedSlackTeamId,
    slackTeamConfigs: accessibleSlackTeamConfigs,
  });

  const hasTimestampElement = elementBindings.some(
    (e) =>
      e.element.available_element.element_type === FormElementType.Timestamp,
  );

  return (
    <>
      <Callout theme={CalloutTheme.Info}>
        Retrospective incidents are historical, closed incidents. We&rsquo;ll
        only announce the incident if you opt-in. Some of your workflows may not
        be configured to run on retrospective incidents.
      </Callout>
      {hasTimestampElement && (
        <Form.Helptext>
          <>
            {`All times are in your local time zone: `}
            <strong>{getLocalTimeZone(new Date())}.</strong>
          </>
        </Form.Helptext>
      )}
      {/* When we have a retrospective form type this can be changed */}
      <FormElements
        elementBindings={elementBindings}
        formMethods={formMethods}
        selectedIncidentType={selectedIncidentType}
        incidentFormType={IncidentFormType.Retrospective}
        customFieldEntryPayloads={customFieldEntryPayloads}
      />
      {/* Slack workspace */}
      {slackTeamOptions.length > 1 && (
        <StaticSingleSelectV2<RetrospectiveFormData>
          formMethods={formMethods}
          name="slack_team_id"
          options={slackTeamOptions}
          placeholder="Choose a Slack workspace"
          isClearable={false}
          required
          helptext="Which Slack workspace should the incident channel be created for?"
          label="Slack workspace"
        />
      )}
    </>
  );
};

export const useRetrospectiveIncidentForm = ({
  customFields,
  incidentRoles,
  severities,
  incidentTypes,
  allStatuses,
  slackTeamConfigs,
  onSuccess,
  prefillDefaultValues,
}: IncidentCrudResourceTypes & {
  onSuccess: () => void;
  allStatuses: IncidentStatus[];
  prefillDefaultValues: Partial<DeclareFormData>;
}): {
  formMethods: UseFormReturn<RetrospectiveFormData>;
  genericError: string | undefined;
  saving: boolean;
  onSubmit: SubmitHandler<RetrospectiveFormData>;
  confirmButtonText: string;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
  disabled: boolean;
} => {
  const { enableRetrospectiveIncidentAnnouncementsByDefault } = useFlags();
  const navigate = useOrgAwareNavigate();

  const minSeverityId = _.minBy(severities, "rank")?.id;
  const defaultIncidentType = incidentTypes.find((it) => it.is_default);

  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const slackTeamIds = useIdentity().identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const formMethods = useForm<RetrospectiveFormData>({
    defaultValues: {
      retrospective_incident_options: {
        // Set announcement_enabled to true by default for organisations that request for it
        announcements_enabled:
          enableRetrospectiveIncidentAnnouncementsByDefault,
      },
      severity_id: minSeverityId,
      idempotency_key: uuid(),
      visibility: defaultIncidentType.private_incidents_only
        ? VisibilityEnum.Private
        : VisibilityEnum.Public,
      incident_role_assignments: incidentRoles.reduce(
        (all, role) => ({ ...all, [role.id]: [] }),
        {},
      ),
      incident_type_id: defaultIncidentType.id,
      slack_team_id:
        accessibleSlackTeamConfigs.length === 1
          ? accessibleSlackTeamConfigs[0].slack_team_id
          : undefined,
      ...prefillDefaultValues,
      custom_field_entries: {
        ...customFields.reduce(
          (all, field) => ({
            ...all,
            [field.id]: { values: [], hasBeenManuallyEdited: false },
          }),
          {} as FormCustomFieldEntries,
        ),
        ...prefillDefaultValues.custom_field_entries,
      },
    },
  });
  const { watch, setError, setValue, getValues } = formMethods;
  const [
    selectedSeverityId,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
    selectedIncidentTypeId,
  ] = watch([
    "severity_id",
    "custom_field_entries",
    "incident_role_assignments",
    "incident_type_id",
  ]);

  const { selectedIncidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: selectedIncidentTypeId,
    incidentTypes,
    includeNotYetKnownOption: false,
  });

  // Update the visibility field everytime we change to/from an incident
  // type that requires private incidents.
  useEffect(() => {
    if (selectedIncidentType?.private_incidents_only) {
      setValue<"visibility">("visibility", VisibilityEnum.Private);
    } else {
      setValue<"visibility">("visibility", VisibilityEnum.Public);
    }
  }, [selectedIncidentType, setValue]);

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );
  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const firstClosedStatus = allStatuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Closed,
  )[0];

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Retrospective,
    incident_type_id: selectedIncidentTypeId,
    incident_status_id: firstClosedStatus.id,
    severity_id: selectedSeverityId,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    show_all_elements_override: false,
  };
  const { elementBindings, isLoading: isLoadingElementBindings } =
    useElementBindings<RetrospectiveFormData>({
      payload: elementBindingsPayload,
      setValue,
      initialValues: formMethods.formState.defaultValues,
      touchedFields: formMethods.formState.touchedFields,
      manualEdits: [],
    });
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, formData: RetrospectiveFormData) => {
      const createRequestBody = formDataToRequestBody(
        elementBindings,
        formData,
        formMethods.formState.touchedFields,
      );

      const { incident } = await apiClient.incidentsCreate({
        createRequestBody,
      });
      navigate(`/incidents/${incident.id}/edit-timestamps`);
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onSuccess();
      },
      setError: (path, error) => {
        if (!isCustomFieldError(path)) {
          setError(path, error);
          return;
        }

        const requestData = formDataToRequestBody(
          elementBindings,
          getValues(),
          formMethods.formState.touchedFields,
        );

        setError(
          getCustomFieldErrorPath<RetrospectiveFormData>(
            path,
            requestData.custom_field_entries ?? [],
          ),
          error,
        );
      },
    },
  );

  return {
    elementBindings,
    formMethods,
    onSubmit,
    genericError,
    saving,
    confirmButtonText: "Continue to set timestamps",
    customFieldEntryPayloads: customFieldEntries,
    disabled: isLoadingElementBindings,
  };
};
