import { EscalationsListRequest } from "@incident-io/api";
import {
  AppliedFiltersInline,
  enrichAvailableFilterFields,
  FiltersContextProvider,
  filtersToListParams,
  SearchBar,
  useFiltersContext,
} from "@incident-shared/filters";
import { useStatefulQueryParamFilters } from "@incident-shared/filters/useStatefulQueryParamFilters";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconSize,
  Loader,
} from "@incident-ui";
import { InputSize } from "@incident-ui/Input/Input";
import _ from "lodash";
import { Helmet } from "react-helmet";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIInfinite } from "src/utils/swr";

import { EmptyState as EscalationEmptyState } from "../EmptyState";
import { useOnCallEntityCount } from "../utils";
import { EscalationsTable } from "./EscalationsTable";
import EscalationsEmptyStateImg from "./images/escalations-empty-state.png";

export const EscalationsPage = () => {
  const {
    data: { fields: availableFilterFields },
    isLoading: filtersLoading,
    error: filtersError,
  } = useAPI("escalationsListFilterFields", undefined, {
    fallbackData: { fields: [] },
  });

  const filterFields = enrichAvailableFilterFields(availableFilterFields);

  const isLoading = filtersLoading;
  const error = filtersError;

  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters({
      availableFilterFields: filterFields,
      availableParams: [],
    });
  const { hasOnCall } = useProductAccess();

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  const filters = getSelectedFilters();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <FiltersContextProvider
        filters={filters}
        setFilters={setSelectedFilters}
        kind={"escalations"}
        availableFilterFields={filterFields}
        filtersLoading={isLoading}
      >
        <Helmet title={"Escalations - incident.io"} />
        <EscalationsList />
      </FiltersContextProvider>
    </>
  );
};

export const EscalationsEmptyState = () => (
  <EscalationEmptyState
    copy="Escalations can be triggered automatically by alert routes, or manually via Slack or the dashboard."
    imageSrc={EscalationsEmptyStateImg}
    title="No escalations triggered"
    buttons={[]}
  >
    <div className="flex gap-4">
      <Button
        className="ps-4"
        href="/alerts/routes"
        analyticsTrackingId="on-call-view-alert-rules"
        theme={ButtonTheme.Primary}
      >
        View alert routes
      </Button>
      <Button
        className="ps-4"
        href="https://help.incident.io/articles/2258748042-escalating-incidents#hard-escalations-5"
        target="_blank"
        analyticsTrackingId="manual-escalations-help-article"
        theme={ButtonTheme.Secondary}
      >
        Find out more
      </Button>
    </div>
  </EscalationEmptyState>
);

const EscalationsList = () => {
  const {
    availableFilterFields,
    filters,
    addFilter,
    editFilter,
    deleteFilter,
  } = useFiltersContext();

  const queryFilters: EscalationsListRequest = filtersToListParams(filters);

  const {
    data: { escalations: unfilteredEscalations },
    isLoading: unfilteredEscalationsLoading,
    error: unfilteredEscalationsError,
  } = useAPI(
    "escalationsList",
    {
      pageSize: 50,
    },
    {
      fallbackData: {
        escalations: [],
        pagination_meta: {
          page_size: 50,
          total_record_count: 0,
        },
      },
    },
  );

  const {
    responses,
    isLoading,
    isFullyLoaded,
    error,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "escalationsList",
    {
      pageSize: 50,
      ...queryFilters,
    },
    {
      revalidateFirstPage: true,
      revalidateOnMount: true,
    },
  );

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const escalations = responses.flatMap(({ escalations }) => escalations);

  const sortedEscalations = escalations || [];
  _.sortBy(escalations, (escalation) => {
    return escalation.updated_at;
  });

  if (error || unfilteredEscalationsError || entityCountError) {
    return <GenericErrorMessage error={error} />;
  }

  if (entityCount === 0 && !isLoading && !entityCountLoading) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  if (
    unfilteredEscalations.length === 0 &&
    !unfilteredEscalationsLoading &&
    !isLoading &&
    !entityCountLoading
  ) {
    return <EscalationsEmptyState />;
  }

  return (
    <>
      <div className={"w-full flex justify-between mb-4 gap-2"}>
        <AppliedFiltersInline
          totalNumberOfItems={null}
          itemsLabel={"escalation"}
          analyticsTrackingId={"escalation-list-filter"}
        />
        <SearchBar
          id="search_escalations"
          placeholder="Search escalations"
          className={"bg-surface-secondary rounded border-none"}
          inputSize={InputSize.Medium}
          iconProps={{
            size: IconSize.Small,
            className: "text-content-tertiary",
          }}
          availableFilterFields={availableFilterFields}
          appliedFilters={filters}
          onEditFilter={editFilter}
          onDeleteFilter={deleteFilter}
          onAddFilter={addFilter}
        />
      </div>
      <EscalationsTable
        escalations={sortedEscalations}
        infiniteScrollRef={infiniteScrollRef}
        isLoading={isLoading}
        isFullyLoaded={isFullyLoaded}
      />
    </>
  );
};
