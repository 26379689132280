import type { SVGProps } from "react";
import * as React from "react";
const SvgLayersBulk = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.685 5.423-5.869-3.09a1.74 1.74 0 0 0-1.63 0l-5.87 3.09c-.495.26-.802.769-.802 1.327 0 .558.307 1.067.801 1.327l5.87 3.09a1.745 1.745 0 0 0 1.63 0l5.87-3.09c.494-.26.801-.77.801-1.327 0-.558-.307-1.067-.8-1.327Zm.051 3.827a.75.75 0 0 0-.75.75l-5.87 3.09a.25.25 0 0 1-.232 0L4.014 10a.75.75 0 0 0-1.5 0c0 .559.307 1.067.801 1.327l5.87 3.09a1.745 1.745 0 0 0 1.63 0l5.87-3.09c.494-.26.801-.77.801-1.327a.75.75 0 0 0-.75-.75Zm-.75 4a.75.75 0 0 1 1.5 0c0 .558-.307 1.067-.8 1.327l-5.87 3.09a1.74 1.74 0 0 1-1.632 0l-5.869-3.09a1.495 1.495 0 0 1-.8-1.327.75.75 0 0 1 1.5 0l5.87 3.09a.25.25 0 0 0 .231 0l5.87-3.09Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLayersBulk;
