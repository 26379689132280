import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogEntry,
  EscalationProviderEnum,
  EscalationUserTarget,
  EscalationUserTargetPayload,
  EscalationUserTargetPayloadProviderEnum,
  Incident,
  IncidentFormEscalationElement,
  IncidentFormsPreviewEscalateFormRequestBody,
  IncidentFormsPreviewEscalateFormResponseBody,
  IncidentFormsSubmitEscalateFormRequestBody,
  TypeaheadsListTypeaheadTypeEnum,
} from "@incident-io/api";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Callout, CalloutTheme, Spinner } from "@incident-ui";
import { SelectOption, SelectOptions } from "@incident-ui/Select/types";
import _ from "lodash";
import { ReactElement, useCallback } from "react";
import {
  Path,
  useFormContext,
  UseFormReturn,
  useFormState,
} from "react-hook-form";

import { ClientType, useClient } from "../../../contexts/ClientContext";
import { useAPI } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "../../../utils/utils";
import { asArray, ElementBindings } from "../element-bindings";

export type EscalationUserTargetValue =
  `${EscalationUserTargetPayloadProviderEnum}##${string}`;
export const userTargetToValue = (
  e: EscalationUserTargetPayload | EscalationUserTarget,
): EscalationUserTargetValue => {
  return `${e.provider}##${e.id}`;
};
export const valueToUserTarget = (
  e: EscalationUserTargetValue,
): EscalationUserTargetPayload => {
  const [provider, id] = e.split("##");
  return { provider: provider as EscalationUserTargetPayloadProviderEnum, id };
};

export type NativeFormData = Omit<
  IncidentFormsSubmitEscalateFormRequestBody,
  "catalog_element_bindings" | "element_bindings" | "users"
> & {
  catalog_element_bindings: ElementBindings;
  // As our form components just want a string,
  // we make an aggregated value of the provider and the ID
  // which we unwrap before sending to the API.
  users_with_providers: EscalationUserTargetValue[];
} & {
  declare_incident: boolean;
  provider: EscalationProviderEnum.Native;
};

export const NativeEscalateForm = ({
  elements,
  incident,
}: {
  elements: IncidentFormEscalationElement[];
  incident?: Incident | null;
}): ReactElement => {
  const apiClient = useClient();

  const loadPriorityOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: "AlertPriority",
  });

  const hydratePriorityOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: "AlertPriority",
  });

  const formMethods = useFormContext<NativeFormData>();
  const elementsWithoutPriority = elements.filter(
    (element) =>
      element.available_element.element_type !==
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  const priorityElement = elements.find(
    (element) =>
      element.available_element.element_type ===
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  const noValidSelections = useHasNoValidSelections(formMethods);

  return (
    <div>
      <div className="text-base-bold">Who do you need?</div>
      <div className="text-xs-med text-content-primary pt-[2px] pb-1">
        Pick from any of the options below and we&apos;ll find the right people
        to page
      </div>
      <div className="h-3" />
      <div className="bg-surface-secondary p-4 rounded-3 mb-4">
        {elementsWithoutPriority.map((element, index) => {
          const Component =
            element.available_element.element_type ===
            AvailableIncidentFormEscalationElementElementTypeEnum.User
              ? UserEscalationFormElement
              : CatalogTypeFormElement;

          return (
            <div
              key={element.id}
              className={tcx({
                "pb-6": index !== elementsWithoutPriority.length - 1,
              })}
            >
              <Component element={element} />
            </div>
          );
        })}
      </div>
      {noValidSelections ? (
        <Callout theme={CalloutTheme.Danger}>
          You must select at least one target to escalate to.
        </Callout>
      ) : null}
      <div className="text-base-bold pt-5 pb-1">Why do you need them?</div>
      <div className="text-xs-med text-content-primary pt-[2px] pb-1">
        This will be what they see or hear when we contact them.
      </div>
      <InputV2
        name="title"
        className="pb-3"
        formMethods={formMethods}
        placeholder="Something's wrong with the database"
      />
      {priorityElement ? (
        <DynamicSingleSelectV2
          name={"priority"}
          className={"py-3"}
          loadOptions={loadPriorityOptions}
          hydrateOptions={hydratePriorityOptions}
          formMethods={formMethods}
          label={"Priority"}
          required={false}
          helptext={priorityElement.description}
        />
      ) : null}
      <div className="flex-grow"></div>
      {!incident && (
        <>
          <div className="h-4" />
          <CheckboxRowV2
            name="declare_incident"
            description={undefined}
            className="w-full"
            formMethods={formMethods}
            label="Also declare an incident"
          />
        </>
      )}
      <div className="h-4" />
    </div>
  );
};

const CatalogTypeFormElement = ({
  element,
}: {
  element: IncidentFormEscalationElement;
}) => {
  const apiClient = useClient();

  const formMethods = useFormContext<NativeFormData>();

  const [catalogElementValues, priority, escalationPaths] = formMethods.watch([
    `catalog_element_bindings.${element.id}`,
    "priority",
    "escalation_paths",
  ]);

  const elementType = element.available_element.element_type;

  let hasValues = false;
  let catalogTypeId: string;
  let path: Path<NativeFormData>;
  let previewRequest: IncidentFormsPreviewEscalateFormRequestBody | undefined;
  if (
    elementType ===
    AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
  ) {
    path = `catalog_element_bindings.${element.id}.array_value`;
    catalogTypeId = element.available_element.catalog_type?.id ?? "";
    const arrayValues = asArray(catalogElementValues);
    hasValues = arrayValues.length > 0;
    previewRequest = {
      catalog_element_bindings: {
        [element.id]: arrayValues,
      },
      priority,
    };
  } else {
    path = `escalation_paths`;
    catalogTypeId = "EscalationPath";
    hasValues = (escalationPaths ?? []).length > 0;
    previewRequest = {
      escalation_paths: escalationPaths,
      priority,
    };
  }

  const { data: result, isLoading: loading } = useAPI(
    previewRequest ? "incidentFormsPreviewEscalateForm" : null,
    {
      id: element.incident_form_id,
      previewEscalateFormRequestBody: previewRequest ?? {},
    },
  );

  const pathsPreview = Object.values(result?.for_paths || {});
  const usersPreview = Object.values(result?.for_users || {});
  const preview = pathsPreview.concat(usersPreview);

  const targetsToBePaged = preview
    .map((val) => {
      return val.map((target) => {
        return target.name;
      });
    })
    .flat();
  const uniqueTargetsToBePaged = _.uniq(targetsToBePaged);

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });
  return (
    <>
      <DynamicMultiSelectV2
        name={path}
        label={
          element.available_element.catalog_type?.name ?? "Escalation path"
        }
        required={false}
        loadOptions={loadDefaultValueOptions}
        hydrateOptions={hydrateDefaultValueOptions}
        formMethods={formMethods}
        closeMenuOnSelect={true}
        helptext={element.description}
      />
      {previewRequest && hasValues ? (
        <div className="">
          <ElementPreview
            element={element}
            result={result}
            loading={loading}
            uniqueTargetsToBePaged={uniqueTargetsToBePaged}
          />
        </div>
      ) : null}
    </>
  );
};

const UserEscalationFormElement = ({
  element,
}: {
  element: IncidentFormEscalationElement;
}) => {
  const apiClient = useClient();
  const formMethods = useFormContext<NativeFormData>();

  const users = formMethods.watch("users_with_providers");

  const hasPagerDutyUser = users?.some(
    (u) =>
      valueToUserTarget(u).provider ===
      EscalationUserTargetPayloadProviderEnum.Pagerduty,
  );

  const { loadOptions, hydrateOptions } = useEscalateUsersTypeahead(apiClient);
  return (
    <div className={"flex flex-col gap-4"}>
      <DynamicMultiSelectV2
        label={"Users"}
        helptext={element.description}
        name={`users_with_providers`}
        loadOptions={loadOptions}
        hydrateOptions={hydrateOptions}
        formMethods={formMethods}
      />

      {hasPagerDutyUser && (
        <DynamicSingleSelectV2
          label={"PagerDuty Service"}
          required
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadsListTypeaheadTypeEnum.PagerDutyService,
          )}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadsListTypeaheadTypeEnum.PagerDutyService,
          )}
          name={"user_pagerduty_service"}
          formMethods={formMethods}
        />
      )}
    </div>
  );
};

const ElementPreview = ({
  element,
  result,
  loading,
  uniqueTargetsToBePaged,
}: {
  element: IncidentFormEscalationElement;
  result: IncidentFormsPreviewEscalateFormResponseBody | undefined;
  loading: boolean;
  uniqueTargetsToBePaged: string[];
}) => {
  const messages: React.ReactElement[] = [];

  if (uniqueTargetsToBePaged.length > 0) {
    messages.push(
      <>
        <span className="text-sm-bold">
          {joinSpansWithCommasAndConnectorWord(uniqueTargetsToBePaged)}
        </span>
        <span className="text-sm-normal"> will be notified.</span>
      </>,
    );
  }

  const addServiceMessages = (
    serviceData,
    catalogEntries: CatalogEntry[] | undefined,
    provider: string,
  ) => {
    Object.keys(serviceData).forEach((key) => {
      const catalogEntry = (catalogEntries || []).find(
        (entry) => entry.id === key,
      );
      if (catalogEntry) {
        const serviceName = serviceData[key][0]?.name;
        if (serviceName) {
          if (messages.length > 0) {
            messages.push(<span> </span>);
          }

          messages.push(
            <div className="inline">
              We will escalate to
              <span className="text-sm-bold inline">
                {" "}
                {serviceName}
              </span> in {provider}.{" "}
            </div>,
          );
        }
      }
    });
  };

  addServiceMessages(
    result?.for_pagerduty_service || {},
    result?.catalog_entries,
    "PagerDuty",
  );
  addServiceMessages(
    result?.for_opsgenie_team || {},
    result?.catalog_entries,
    "Opsgenie",
  );

  const thereIsSomeoneToPage =
    uniqueTargetsToBePaged.length > 0 ||
    Object.keys(result?.for_pagerduty_service ?? []).length > 0 ||
    Object.keys(result?.for_opsgenie_team ?? []).length > 0;

  // Build the error messages for cases where either an EP isn't associated with
  // the current type, or for the case where no one is on call.
  const isEP = element?.available_element.element_type === "escalation_policy";
  const maybeCatalogTypeName =
    element?.available_element.catalog_type?.name?.toLowerCase();
  const isMatchingEP =
    maybeCatalogTypeName !== undefined &&
    (Object.keys(result?.for_paths ?? []).length > 0 ||
      Object.keys(result?.for_pagerduty_service ?? []).length > 0 ||
      Object.keys(result?.for_opsgenie_team ?? []).length > 0);
  const isPlural = isEP
    ? Object.keys(result?.for_paths ?? []).length > 1
    : (result?.catalog_entries ?? []).length > 1;

  const message = isEP
    ? `No one is currently on call for ${
        isPlural ? "these escalation paths" : "this escalation path."
      }`
    : isMatchingEP
    ? `No one is currently on call for the escalation paths connected to ${
        isPlural
          ? `these ${maybeCatalogTypeName}s`
          : `this ${maybeCatalogTypeName}`
      }.`
    : `There are no escalation paths connected to ${
        isPlural
          ? `these ${maybeCatalogTypeName}s`
          : `this ${maybeCatalogTypeName}`
      }.`;

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {thereIsSomeoneToPage ? (
            <div className="pt-2">{messages}</div>
          ) : (
            <div className="pt-2 text-red-500">{message}</div>
          )}
        </>
      )}
    </div>
  );
};

export const useHasNoValidSelections = (
  formMethods: UseFormReturn<NativeFormData>,
): boolean => {
  const formState = useFormState(formMethods);

  const [provider, catalogBindings, users, escalationPaths] = formMethods.watch(
    [
      "provider",
      `catalog_element_bindings`,
      "users_with_providers",
      "escalation_paths",
    ],
  );

  if (provider !== EscalationProviderEnum.Native) {
    return false;
  }

  const allCatalogBindingsAreEmpty = Object.values(catalogBindings ?? {}).every(
    (val) => {
      if (!val) {
        return true;
      }

      return asArray(val).length === 0;
    },
  );
  const allUsersAreEmpty = users?.length === 0;
  const allEscalationPathsAreEmpty = escalationPaths?.length === 0;

  const hasNoTarget =
    allCatalogBindingsAreEmpty &&
    allUsersAreEmpty &&
    allEscalationPathsAreEmpty;

  return formState.isDirty && hasNoTarget;
};

export const useEscalateUsersTypeahead = (apiClient: ClientType) => {
  const loadOptions = useCallback(
    async (searchText: string): Promise<SelectOptions> => {
      const escalateUsersResp = await apiClient.incidentFormsListEscalateUsers({
        search: searchText,
      });

      return escalateUsersResp.users.map(
        (u): SelectOption => ({
          value: userTargetToValue(u),
          label: u.name,
          image_url: u.avatar_url,
        }),
      );
    },
    [apiClient],
  );

  const hydrateOptions = useCallback(
    async (ids: string | string[]): Promise<SelectOptions> => {
      const escalateUsersResp = await apiClient.incidentFormsListEscalateUsers({
        ids: ids ? (Array.isArray(ids) ? ids : [ids]) : [],
      });

      return escalateUsersResp.users.map(
        (u): SelectOption => ({
          value: userTargetToValue(u),
          label: u.name,
        }),
      );
    },
    [apiClient],
  );

  return { loadOptions, hydrateOptions };
};
