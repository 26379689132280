import { AlertSchema } from "@incident-io/api";
import { CustomDisplay } from "@incident-shared/filters/CustomDisplay";
import { useMemo } from "react";
import {
  PopoverSelectOption,
  PopoverSelectOptions,
} from "src/components/@ui/PopoverSelect/types";

import { AlertTableColumn, makeColumns } from "./AlertsTable";

const getDefaultVisibleColumns = (
  allOptions: AlertTableColumn[],
): AlertTableColumn[] => {
  return allOptions.filter((col) => {
    // Show the alert source column by default always
    if (col.type === "alert_source") {
      return true;
    }

    // Show the priority column by default always
    if (col.type === "priority") {
      return true;
    }

    // Otherwise show only the catalog powered alert attributes
    return !!col.attribute?.type.startsWith("CatalogEntry");
  });
};

const columnName = (column: AlertTableColumn) => {
  if (column.type === "alert_source") {
    return "Alert source";
  } else if (column.type === "priority") {
    return "Priority";
  }
  return column.attribute?.name ?? "";
};

const getColumnOptions = (
  columns: AlertTableColumn[],
): PopoverSelectOptions<PopoverSelectOption> => {
  const standardColumns: PopoverSelectOption[] = [];
  const attributeColumns: PopoverSelectOption[] = [];

  columns.forEach((col) => {
    // Generate a unique value for the option
    const value =
      col.type === "attribute" ? `attribute:${col.attribute?.id}` : col.type;

    const option = {
      value,
      label: columnName(col),
      // Store original column for reference
      originalColumn: col,
    };

    // Separate attributes from standard columns
    if (col.type === "attribute") {
      attributeColumns.push(option);
    } else {
      standardColumns.push(option);
    }
  });

  return [
    {
      label: "Standard",
      options: standardColumns,
    },
    {
      label: "Attributes",
      options: attributeColumns,
    },
  ];
};

const getColumnFromOption = (
  value: string,
  allColumns: AlertTableColumn[],
): AlertTableColumn | undefined => {
  if (value.startsWith("attribute:")) {
    const attributeId = value.substring("attribute:".length);
    return allColumns.find(
      (col) => col.type === "attribute" && col.attribute?.id === attributeId,
    );
  } else {
    return allColumns.find((col) => col.type === value);
  }
};

export const AlertsCustomDisplay = ({
  schema,
  visibleColumns: visibleColumnsOptional,
  setVisibleColumns,
}: {
  schema: AlertSchema;
  visibleColumns: AlertTableColumn[] | null;
  setVisibleColumns: (shownAttributes: AlertTableColumn[]) => void;
}) => {
  // Safely generate columns from schema
  const allOptions = useMemo(() => {
    if (!schema) return [];
    return makeColumns(schema);
  }, [schema]);

  const visibleColumns = visibleColumnsOptional;

  // Safely generate column options
  const columnOptions = useMemo(() => {
    if (!allOptions || allOptions.length === 0) return [];
    return getColumnOptions(allOptions);
  }, [allOptions]);

  const selectedValues = useMemo(() => {
    if (!visibleColumns || visibleColumns.length === 0) return [];

    return visibleColumns.map((col) =>
      col.type === "attribute" ? `attribute:${col.attribute?.id}` : col.type,
    );
  }, [visibleColumns]);

  const defaultValues = useMemo(() => {
    const defaultCols = getDefaultVisibleColumns(allOptions);
    return defaultCols.map((col) =>
      col.type === "attribute" ? `attribute:${col.attribute?.id}` : col.type,
    );
  }, [allOptions]);

  const handleSelectionChange = (newValues: string[]) => {
    const newColumns = newValues
      .map((value) => getColumnFromOption(value, allOptions))
      .filter(Boolean) as AlertTableColumn[];

    setVisibleColumns(newColumns);
  };

  return (
    <CustomDisplay
      availableOptions={columnOptions}
      selectedValues={selectedValues}
      onSelectionChange={handleSelectionChange}
      persistInUrl={true}
      urlParamName="columns"
      defaultValues={defaultValues}
      analyticsTrackingId={"alerts-list-customise-info"}
      buttonLabel={"Display"}
    />
  );
};
