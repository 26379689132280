import {
  StaffEnableOnCallRequestBody,
  StaffShowOrganisationResponseBody,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ModalFooter } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { getProductAccess } from "src/hooks/useProductAccess";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const EnableResponseButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.EnableResponseForOrganisation,
  );

  const { hasResponse } = getProductAccess(data);

  if (hasResponse) {
    disabledProps = {
      disabled: true,
      disabledTooltipContent: "Org already has Response enabled",
    };
  } else {
    // We currently don't want to support this - so we will always set this to false.
    disabledProps = {
      disabled: true,
      disabledTooltipContent:
        "Enabling response for an organisation without it is not currently supported - please contact us in #team-response",
    };
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        {...disabledProps}
      >
        ⚡️ Enable Response
      </GatedButton>

      {isOpen && (
        <EnableResponseModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const EnableResponseModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm();

  const { trigger, isMutating, genericError, fieldErrors } = useMutationV2(
    async (apiClient) => {
      await apiClient.staffEnableResponse({
        organisationSlug: data.organisation_slug,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal<StaffEnableOnCallRequestBody>
      title="Enable Response"
      formMethods={formMethods}
      onSubmit={(data) => {
        trigger(data);
      }}
      saving={isMutating}
      onClose={onClose}
      className="text-sm space-y-2"
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          saving={isMutating}
          confirmButtonText="Do it"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {genericError && <Form.ErrorMessage message={genericError} />}
      {fieldErrors && <Form.ErrorMessage errors={fieldErrors} />}
      <p>
        This will give {data.organisation_name} access to the response product
      </p>
    </Form.Modal>
  );
};
