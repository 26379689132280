import {
  CallProvidersProvidersEnum,
  IncidentCallExternalProviderEnum,
  IncidentCallSessionWithSummary,
} from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  ErrorMessage,
  GenericErrorMessage,
  IconEnum,
  Link,
  Loader,
  TabPane,
  TabSection,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { stringToHash } from "src/utils/utils";

import { IconForCallProvider } from "../legacy/incident/stacked-lists/CallsStackedList";
import { CallSessionParticipants } from "./CallSessionParticipants";
import { CallSessionSelector } from "./CallSessionSelector";
import { CallSummary } from "./CallSummary";
import { CallTranscript } from "./CallTranscript";
import { CurrentTopicFancy } from "./CurrentTopicFancy";
import { useLatestIncidentCall } from "./hooks";
import { UpsellForScribe } from "./UpsellForScribe";

export const CallNotesDrawer = ({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) => {
  const { streamId, callSessionId } = useParams() as {
    streamId: string;
    callSessionId: string;
  };

  const { identity } = useIdentity();
  const { data: aiConfigData } = useAPI(
    identity ? "aIShowConfig" : null,
    undefined,
  );

  const [deleteCallTranscriptOpen, setDeleteCallTranscriptOpen] =
    useState(false);
  const {
    trigger: onDelete,
    genericError,
    isMutating: isDeleting,
  } = useAPIMutation(
    "incidentCallsListCallSessionsWithSummariesForIncident",
    { incidentId: incidentId },
    async (apiClient) => {
      await apiClient.incidentCallTranscriptsDestroy({
        callSessionId,
      });
    },
    {
      onSuccess: () => {
        setDeleteCallTranscriptOpen(false);
        onClose();
      },
    },
  );

  const notYetEnabled =
    aiConfigData?.config.openai_subprocessor_enabled &&
    !aiConfigData?.config.scribe_enabled;

  const { callData } = useLatestIncidentCall(incidentId);
  const isLegacy = !callSessionId;
  const shouldShowJoinCallButton =
    // In the old version, always show the Join Call button if we have a link
    (isLegacy && callData?.incident_call?.call_url) ||
    // In the new version, only show it if we have a link and it's the active call
    (callData?.active_call_session?.id === callSessionId &&
      callData?.incident_call?.call_url);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Call notes"
          icon={IconEnum.Scribe}
          onClose={onClose}
          color={ColorPaletteEnum.Scribe}
          secondaryAccessory={
            <>
              {shouldShowJoinCallButton && (
                <Button
                  icon={IconForCallProvider(
                    callData.incident_call
                      .external_provider as IncidentCallExternalProviderEnum,
                  )}
                  theme={ButtonTheme.Secondary}
                  size={BadgeSize.Medium}
                  analyticsTrackingId="close-call-notes-drawer"
                  href={callData.incident_call.call_url}
                  openInNewTab
                >
                  Join call
                </Button>
              )}
              {callSessionId && (
                <DropdownMenu
                  triggerButtonTheme={ButtonTheme.Secondary}
                  triggerBadgeSize={BadgeSize.Medium}
                  analyticsTrackingId={"call-notes-more-options"}
                  screenReaderText="More options"
                  triggerIcon={IconEnum.DotsVertical}
                >
                  <DropdownMenuItem
                    icon={IconEnum.Delete}
                    analyticsTrackingId="delete-call-transcript"
                    onSelect={() => setDeleteCallTranscriptOpen(true)}
                    label="Delete notes"
                  />
                </DropdownMenu>
              )}
              <ConfirmationDialog
                onCancel={() => setDeleteCallTranscriptOpen(false)}
                title="Delete call notes for this session"
                isOpen={deleteCallTranscriptOpen}
                onConfirm={() => onDelete({ id: callSessionId })}
                saving={isDeleting}
              >
                <p>
                  This will remove the transcript and all associated notes from
                  the dashboard.
                </p>
                <ErrorMessage message={genericError} />
              </ConfirmationDialog>
            </>
          }
        />
        <DrawerBody className="flex grow p-6 gap-0">
          {notYetEnabled ? (
            <UpsellForScribe />
          ) : (
            <>
              {callSessionId ? (
                <CallNotesForSession callSessionId={callSessionId} />
              ) : (
                <CallNotesForIncident
                  incidentId={incidentId}
                  streamId={streamId}
                />
              )}
            </>
          )}
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const CallNotesForSession = ({ callSessionId }: { callSessionId: string }) => {
  const {
    data: callSessionWithSummary,
    isLoading,
    error,
    isValidating: isFetching,
  } = useAPI("incidentCallsGetCallSessionWithSummary", {
    callSessionId,
  });

  if (isLoading || !callSessionWithSummary) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage />;
  }

  return (
    <CallNotesInner
      callSessions={[callSessionWithSummary]}
      isFetching={isFetching}
    />
  );
};

const CallNotesForIncident = ({
  incidentId,
  streamId,
}: {
  incidentId: string;
  streamId: string;
}) => {
  const {
    data: callSessionsWithSummariesData,
    isLoading: callSessionsWithSummariesIsLoading,
    error: callSessionsWithSummariesError,
    isValidating: callSessionsWithSummariesIsFetching,
  } = useAPI(
    "incidentCallsListCallSessionsWithSummariesForIncident",
    {
      incidentId: streamId ?? incidentId,
    },
    {
      refreshInterval: 15000, // 15 seconds
      revalidateOnFocus: true,
      fallbackData: { call_sessions_with_summaries: [] },
    },
  );

  const callSessionsWithSummaries =
    callSessionsWithSummariesData?.call_sessions_with_summaries;

  if (callSessionsWithSummariesIsLoading) {
    return <Loader />;
  }

  // If it's a 404 we will render empty states below - otherwise show error
  if (
    callSessionsWithSummariesError &&
    ("status" in callSessionsWithSummariesError
      ? callSessionsWithSummariesError?.status !== 404
      : true)
  ) {
    return <GenericErrorMessage />;
  }

  // No call session? Not sure how you got here, but show an empty state
  if (!callSessionsWithSummaries || callSessionsWithSummaries.length === 0) {
    return <NoCallNotes />;
  }

  // Sort our sessions by started_at, and for now pick the latest one
  callSessionsWithSummaries.sort(
    (a, b) =>
      new Date(b.call_session.started_at).getTime() -
      new Date(a.call_session.started_at).getTime(),
  );

  return (
    <CallNotesInner
      callSessions={callSessionsWithSummaries}
      isFetching={callSessionsWithSummariesIsFetching}
    />
  );
};

const CallNotesInner = ({
  callSessions,
  isFetching,
}: {
  callSessions: IncidentCallSessionWithSummary[];
  isFetching: boolean;
}) => {
  const overviewTab = "overview";
  const transcriptTab = "transcript";

  // Track if we've loaded the summary tab before so we avoid animating the
  // gradient again the second time.
  const [shouldAnimateOnMount, setShouldAnimateOnMount] = useState(true);
  // Track the summary - we can then setShouldAnimateOnMount again if it
  // changes which causes it to nicely animate.
  const [previousSummaryHash, setPreviousSummaryHash] = useState(0);

  // We need to manage some state to keep track of the current session
  const [currentSessionCallSessionID, setCurrentSessionCallSessionID] =
    useState<string>(callSessions[0].call_session.id);

  const selectedCallSession = callSessions.find(
    (session) => session.call_session.id === currentSessionCallSessionID,
  );

  if (selectedCallSession?.summary) {
    const hashedSummary = stringToHash(selectedCallSession.summary.markdown);
    if (previousSummaryHash !== hashedSummary) {
      // If we don't match - lets reset the shouldAnimate so we re-add
      // the gradient on the summary changing
      setShouldAnimateOnMount(true);
      setPreviousSummaryHash(hashedSummary); // don't forget to update!
    }
  }

  const {
    responses: transcriptResponses,
    isLoading: transcriptIsLoading,
    isFullyLoaded: transcriptIsFullyLoaded,
    error: transcriptError,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "incidentCallTranscriptsListTranscriptEntriesForCallSession",
    {
      incidentCallSessionId: selectedCallSession?.call_session.id ?? "",
      pageSize: 50,
    },
    {
      refreshInterval: 10000, // 10 seconds
      revalidateAll: true,
      revalidateOnFocus: true,
    },
  );
  const entries = transcriptResponses.flatMap(({ entries }) => entries);

  if (transcriptError || !selectedCallSession) {
    return <GenericErrorMessage />;
  }

  return (
    <>
      <div className="flex flex-start gap-10 self-stretch pb-5">
        <CallSessionSelector
          callSessions={callSessions}
          selectedSession={selectedCallSession.call_session}
          onSelectSession={(sessionId) => {
            setCurrentSessionCallSessionID(sessionId);
          }}
        />
        <CallSessionParticipants
          participants={selectedCallSession.call_session.participants}
        />
      </div>
      {!selectedCallSession.summary && entries.length === 0 ? (
        <NoCallNotes />
      ) : (
        <TabSection
          withIndicator
          defaultTab={overviewTab}
          tabs={[
            {
              id: overviewTab,
              label: "Overview",
            },
            {
              id: transcriptTab,
              label: "Transcript",
            },
          ]}
          tabClassName="!text-sm text-content-tertiary"
          tabBarClassName="border-b border-stroke"
          onTabChange={() => setShouldAnimateOnMount(false)}
        >
          <TabPane tabId={overviewTab}>
            <div className="flex flex-col gap-5 pt-5">
              {selectedCallSession.call_session.current_topic &&
                selectedCallSession.call_session
                  .current_topic_last_updated_at && (
                  <CurrentTopicFancy
                    currentTopic={
                      selectedCallSession.call_session.current_topic
                    }
                    lastUpdatedAt={
                      selectedCallSession.call_session
                        .current_topic_last_updated_at
                    }
                    animateOnMount={shouldAnimateOnMount}
                  />
                )}
              <CallSummary
                isFetchingSummary={isFetching}
                callSessionWithTranscript={selectedCallSession}
                animateOnMount={shouldAnimateOnMount}
              />
            </div>
          </TabPane>
          <TabPane tabId={transcriptTab}>
            <CallTranscript
              callSession={selectedCallSession.call_session}
              entries={entries}
              isFullyLoaded={transcriptIsFullyLoaded}
              isLoading={transcriptIsLoading}
              onLoadMore={onLoadMore}
            />
          </TabPane>
        </TabSection>
      )}
    </>
  );
};

const NoCallNotes = () => {
  const { data } = useAPI("incidentCallSettingsGetCallProviders", {});

  const isZoomInstalled = data?.available_providers?.providers?.some(
    (provider) => provider === CallProvidersProvidersEnum.Zoom,
  );
  const isZoomMissingScope = isZoomInstalled && !data?.has_zoom_recording_scope;

  if (isZoomMissingScope) {
    return (
      <div className="py-8 flex flex-col flex-grow justify-center align-middle self-stretch">
        <EmptyState
          className="border-0"
          title={"No call notes available"}
          icon={IconEnum.Scribe}
          content={
            <>
              We don&apos;t have the necessary permissions to transcribe this
              call. Please reconnect Zoom and try again. If the issue continues,{" "}
              <Link
                openInNewTab
                analyticsTrackingId={null}
                href={
                  "https://help.incident.io/articles/3340727910-scribe%3A-ai-powered-transcription-and-summarisation-for-incident-calls#scribe-is-joining-my-zoom-calls-why-am-i-not-seeing-a-transcript-61"
                }
              >
                refer to our FAQ
              </Link>{" "}
              for further troubleshooting steps.
            </>
          }
        />
      </div>
    );
  }

  return (
    <div className="py-8 flex flex-col flex-grow justify-center align-middle self-stretch">
      <EmptyState
        className="border-0"
        title={"No call notes available"}
        icon={IconEnum.Scribe}
        content={
          <>
            <div className="flex flex-col gap-2">
              <span>
                Looks like we don&apos;t have any call notes for this incident.
                Join the call, and incident.io will automatically transcribe it
                for you.
              </span>
              <span>
                If you are expecting to see notes from this call, please allow a
                few minutes for them to appear. If you&apos;re still not seeing
                notes, please{" "}
                <Link
                  openInNewTab
                  analyticsTrackingId={null}
                  href={
                    "https://help.incident.io/articles/3340727910-scribe%3A-ai-powered-transcription-and-summarisation-for-incident-calls#scribe-is-joining-my-zoom-calls-why-am-i-not-seeing-a-transcript-61"
                  }
                >
                  consult our FAQ
                </Link>{" "}
                for further assistance.
              </span>
            </div>
          </>
        }
      />
    </div>
  );
};
