import {
  AlertsTransitionIncidentAlertRequestBodyStateEnum,
  Escalation,
  Expression,
  IncidentAlert,
} from "@incident-io/api";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { Button, ButtonTheme, IconEnum, LoadingBar } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";

import { useAPI, useAPIMutation, useAPIRefetch } from "../../../../utils/swr";
import { purgeEmpty } from "../../../../utils/utils";
import { AlertRouteIncidentPreview } from "../../../alerts/alert-route-create-edit/common/AlertRouteIncidentPreview";
import {
  IncidentTemplateFormData,
  parseAlertRoute,
  parseIncidentTemplateData,
} from "../../../alerts/alert-route-create-edit/common/types";

type FormData =
  | {
      mode: "create";
    }
  | {
      mode: "connect";
      incident_alert_id: string;
    };

export const EscalationsCreateIncidentDrawer = (): ReactElement => {
  const navigate = useOrgAwareNavigate();
  const { id: escalationId } = useParams<{ id: string }>();
  const onClose = () => navigate(`/on-call/escalations/${escalationId}`);

  if (!escalationId) {
    return <OrgAwareNavigate to="/on-call/escalations" replace />;
  }

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle title="Create or connect incident" onClose={onClose} />
        <EscalationsCreateIncidentDrawerInner
          escalationId={escalationId}
          onClose={onClose}
        />
      </DrawerContents>
    </Drawer>
  );
};

const EscalationsCreateIncidentDrawerInner = ({
  escalationId,
  onClose,
}: {
  escalationId: string;
  onClose: () => void;
}): ReactElement => {
  const { data: escalationData, isLoading: isLoadingEscalation } = useAPI(
    "escalationsShow",
    {
      escalationId,
    },
  );
  const { data: relatedIncidentsData, isLoading: isLoadingRelatedIncidents } =
    useAPI(
      escalationData?.escalation.alert_id ? "alertsListIncidentAlerts" : null,
      {
        alertId: escalationData?.escalation.alert_id,
      },
    );

  const isLoading =
    isLoadingEscalation ||
    isLoadingRelatedIncidents ||
    escalationData === undefined ||
    relatedIncidentsData === undefined;

  if (
    escalationData?.escalation?.alert_id === undefined ||
    escalationData?.escalation?.alert_route_id === undefined
  ) {
    return (
      <DrawerBody>
        <div>
          You can only create an incident from an escalation that came from an
          alert.
        </div>
        <div>
          <Button
            href="/create-incident"
            analyticsTrackingId={null}
            icon={IconEnum.Incident}
            theme={ButtonTheme.Primary}
            className="inline-flex"
          >
            Declare a new incident
          </Button>
        </div>
      </DrawerBody>
    );
  }

  // typescript is unhappy with us without the explicit type, annoyingly
  const escalation = escalationData.escalation as Escalation &
    Required<Pick<Escalation, "alert_id" | "alert_route_id">>;

  return isLoading ? (
    <LoadingBar />
  ) : (
    <CreateAssociateIncidentForm
      escalation={escalation}
      relatedIncidents={relatedIncidentsData.incident_alerts}
      onClose={onClose}
    />
  );
};

const CreateAssociateIncidentForm = ({
  escalation,
  relatedIncidents,
  onClose,
}: {
  escalation: Escalation &
    Required<Pick<Escalation, "alert_id" | "alert_route_id">>;
  relatedIncidents?: IncidentAlert[];
  onClose: () => void;
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      mode:
        relatedIncidents && relatedIncidents.length > 0 ? "connect" : "create",
    },
  });
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const refetchIncident = useAPIRefetch(
    escalation.incident_id ? "incidentsShow" : null,
    {
      id: escalation.incident_id ?? "",
    },
  );
  const mode = formMethods.watch("mode");
  const { trigger, isMutating: isSubmitting } = useAPIMutation(
    "escalationsShow",
    {
      escalationId: escalation.id,
    },
    async (client, payload: FormData) => {
      switch (payload.mode) {
        case "create":
          if (!escalation.alert_route_id || !escalation.alert_id) {
            throw new Error("No alert route ID/alert ID present");
          }

          await client.alertRoutesMarkAllUnrelated({
            alertId: escalation.alert_id,
            markAllUnrelatedRequestBody: {
              alert_route_id: escalation.alert_route_id,
            },
          });
          onClose();
          return;
        case "connect":
          await client.alertsTransitionIncidentAlert({
            id: payload.incident_alert_id,
            transitionIncidentAlertRequestBody: {
              state: AlertsTransitionIncidentAlertRequestBodyStateEnum.Related,
            },
          });
          return;
      }
    },
    {
      onSuccess: () => {
        refetchIncident();
        onClose();
      },
    },
  );

  // ------------------ This section deals with the incident preview ------------------
  const { data: alertData } = useAPI("alertsShowAlert", {
    id: escalation.alert_id,
  });
  const { data: alertRouteData } = useAPI("alertRoutesShowAlertRoute", {
    id: escalation.alert_route_id,
  });
  const { template, expressions } = alertRouteData
    ? parseAlertRoute(alertRouteData.alert_route, null, primaryCommsPlatform)
    : { template: null, expressions: null };
  const selectedIncTypeLiteral =
    template?.incident_type?.binding?.value?.literal;
  const { data: incTypeData } = useAPI(
    selectedIncTypeLiteral ? "incidentTypesShow" : null,
    {
      id: selectedIncTypeLiteral || "",
    },
  );
  const selectedSeverityLiteral =
    template?.severity?.binding?.value?.literal ||
    (template?.severity?.binding?.value?.reference ? undefined : null);
  const { data: severityData } = useAPI(
    selectedSeverityLiteral ? "severitiesShow" : null,
    {
      id: selectedSeverityLiteral || "",
    },
  );

  const selectedIncTypeName = incTypeData?.incident_type.name;
  const selectedSeverityName = severityData?.severity.name;
  const { data: customFieldsData } = useAPI("customFieldsList", {});
  const { data: incidentPreview } = useAPI(
    template ? "alertRoutesPreviewIncident" : null,
    {
      previewIncidentRequestBody: {
        alert_id: escalation.alert_id,
        template: parseIncidentTemplateData(
          purgeEmpty(template as unknown as IncidentTemplateFormData),
        ),
        expressions: expressions
          ? expressions.map((e) =>
              expressionToPayload(e as unknown as Expression),
            )
          : undefined,
      },
    },
  );

  return (
    <>
      <DrawerBody className="flex items-between">
        <Form.Root
          formMethods={formMethods}
          onSubmit={trigger}
          id="create-connect-incident"
        >
          <RadioButtonGroupV2
            boxed
            options={[
              {
                label: "Connect to an existing incident",
                value: "connect",
                isDisabled: !relatedIncidents || relatedIncidents.length === 0,
                isDisabledTooltipContent:
                  "This alert is not pending on any incidents",
              },
              { label: "Create a new incident", value: "create" },
            ]}
            name="mode"
            srLabel="What do you want to do?"
            label="What do you want to do?"
            formMethods={formMethods}
          />
          {mode === "connect" && relatedIncidents ? (
            <RadioButtonGroupV2
              boxed
              options={relatedIncidents.map((incidentAlert) => ({
                label: `${incidentAlert.incident.reference}: ${incidentAlert.incident.name}`,
                value: incidentAlert.id,
              }))}
              name={"incident_alert_id"}
              srLabel={"Which incident do you want to attach this alert to?"}
              label="Which incident do you want to attach this alert to?"
              formMethods={formMethods}
            />
          ) : null}
        </Form.Root>
        {mode === "create" && (
          <AlertRouteIncidentPreview
            alert={alertData?.alert}
            incidentPreview={incidentPreview}
            customFields={customFieldsData?.custom_fields || []}
            customFieldValues={template?.custom_fields}
            alertRouteName={alertRouteData?.alert_route?.name || ""}
            incidentTypeLabel={selectedIncTypeName}
            severityLabel={selectedSeverityName}
          />
        )}
      </DrawerBody>
      <DrawerFooter className="flex gap-2 justify-end">
        <div className="flex-grow"></div>
        <Button onClick={() => onClose()} analyticsTrackingId={null}>
          Cancel
        </Button>
        <Button
          form="create-associate-incident"
          onClick={() => trigger(formMethods.getValues())}
          theme={ButtonTheme.Primary}
          loading={isSubmitting}
          analyticsTrackingId="escalate-form-submit"
        >
          {mode === "create" ? "Create" : "Connect"}
        </Button>
      </DrawerFooter>
    </>
  );
};
