/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppleAppSiteAssociationApplinks,
    AppleAppSiteAssociationApplinksFromJSON,
    AppleAppSiteAssociationApplinksFromJSONTyped,
    AppleAppSiteAssociationApplinksToJSON,
} from './AppleAppSiteAssociationApplinks';
import {
    AppleAppSiteBundleReference,
    AppleAppSiteBundleReferenceFromJSON,
    AppleAppSiteBundleReferenceFromJSONTyped,
    AppleAppSiteBundleReferenceToJSON,
} from './AppleAppSiteBundleReference';

/**
 * 
 * @export
 * @interface MobileAppleAppSiteAssociationResponseBody
 */
export interface MobileAppleAppSiteAssociationResponseBody {
    /**
     * 
     * @type {AppleAppSiteBundleReference}
     * @memberof MobileAppleAppSiteAssociationResponseBody
     */
    activitycontinuation: AppleAppSiteBundleReference;
    /**
     * 
     * @type {AppleAppSiteAssociationApplinks}
     * @memberof MobileAppleAppSiteAssociationResponseBody
     */
    applinks: AppleAppSiteAssociationApplinks;
    /**
     * 
     * @type {AppleAppSiteBundleReference}
     * @memberof MobileAppleAppSiteAssociationResponseBody
     */
    webcredentials: AppleAppSiteBundleReference;
}

export function MobileAppleAppSiteAssociationResponseBodyFromJSON(json: any): MobileAppleAppSiteAssociationResponseBody {
    return MobileAppleAppSiteAssociationResponseBodyFromJSONTyped(json, false);
}

export function MobileAppleAppSiteAssociationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileAppleAppSiteAssociationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activitycontinuation': AppleAppSiteBundleReferenceFromJSON(json['activitycontinuation']),
        'applinks': AppleAppSiteAssociationApplinksFromJSON(json['applinks']),
        'webcredentials': AppleAppSiteBundleReferenceFromJSON(json['webcredentials']),
    };
}

export function MobileAppleAppSiteAssociationResponseBodyToJSON(value?: MobileAppleAppSiteAssociationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activitycontinuation': AppleAppSiteBundleReferenceToJSON(value.activitycontinuation),
        'applinks': AppleAppSiteAssociationApplinksToJSON(value.applinks),
        'webcredentials': AppleAppSiteBundleReferenceToJSON(value.webcredentials),
    };
}

