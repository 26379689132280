import { Avatar } from "@incident-ui/Avatar/Avatar";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { PopoverClose } from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import {
  OnSelectRender,
  PopoverSelectBaseProps,
  PopoverSelectOption,
  PopoverSelectOptionGroup,
  SelectOptionWithGroup,
} from "./types";

export const PopoverSelectOptions = <TOption extends PopoverSelectOption>({
  options,
  isMulti = false,
  onClickOption,
  value,
  inlineDescription,
  allowAdding,
  onCreateOption,
  noOptionsMessage,
  search,
  popoverItemClassName,
}: Pick<
  PopoverSelectBaseProps,
  | "inlineDescription"
  | "allowAdding"
  | "onCreateOption"
  | "noOptionsMessage"
  | "popoverItemClassName"
> & {
  isMulti: boolean;
  value: string | string[];
  onClickOption: (value: string) => void;
  options: SelectOptionWithGroup<TOption>[];
  search?: string;
}) => {
  const searching = search && search.length > 0;
  if (options.length === 0) {
    // we only want to show a create button if the user
    // has actually searched for something
    if (allowAdding && searching) {
      return (
        <PopoverClose asChild>
          <PopoverItem
            icon={IconEnum.Add}
            onClick={() => onCreateOption && onCreateOption(search)}
            className={popoverItemClassName}
          >
            Create &quot;{search}&quot;
          </PopoverItem>
        </PopoverClose>
      );
    }
    return (
      <PopoverItem
        noHover
        className={tcx("text-content-secondary", popoverItemClassName)}
      >
        {noOptionsMessage ? noOptionsMessage : "No items found"}
      </PopoverItem>
    );
  }

  const restructuredGroups = options.reduce<
    PopoverSelectOptionGroup<TOption>[]
  >((acc, option) => {
    const groupLabel = option.group ?? "";
    const group = acc.find((g) => g.label === groupLabel);

    if (group) {
      group.options.push(option);
    } else {
      acc.push({ label: groupLabel as string, options: [option] });
    }

    return acc;
  }, []);

  // If we're in grouped select
  if (restructuredGroups.length > 1 || searching) {
    return (
      <>
        {restructuredGroups.map((group) =>
          group.options.length > 0 ? (
            <PopoverItemGroup key={group.label} label={group.label}>
              {group.options.map((option) => (
                <PopoverSelectOptionItem
                  className={popoverItemClassName}
                  inlineDescription={inlineDescription}
                  key={option.value}
                  isMulti={isMulti}
                  onClick={onClickOption}
                  selectedValue={value}
                  {...option}
                />
              ))}
            </PopoverItemGroup>
          ) : null,
        )}
      </>
    );
  }

  const [emptyGroup] = restructuredGroups;

  return (
    <>
      {emptyGroup.options.map((option) => (
        <PopoverSelectOptionItem
          key={option.value}
          className={popoverItemClassName}
          inlineDescription={inlineDescription}
          isMulti={isMulti}
          onClick={onClickOption}
          selectedValue={value}
          {...option}
        />
      ))}
    </>
  );
};

type SelectOptionProps = {
  label: string;
  value: string;
  selectedValue: string | string[];
  isMulti: boolean;
  onClick: (value: string) => void;
  inlineDescription?: boolean;
  className?: string;
  onSelectRender?: OnSelectRender;
} & Pick<
  PopoverSelectOption,
  | "render"
  | "description"
  | "badge"
  | "icon"
  | "image_url"
  | "iconProps"
  | "disabled"
>;

export const PopoverSelectOptionItem = ({
  label,
  description,
  value,
  onClick,
  isMulti,
  selectedValue,
  render,
  badge,
  icon,
  iconProps,
  inlineDescription,
  image_url: imageUrl,
  onSelectRender,
  className,
  disabled,
}: SelectOptionProps) => {
  const isSelected = Array.isArray(selectedValue)
    ? selectedValue.includes(value)
    : selectedValue === value;

  // This is so we can close the popover when we're in single select mode
  // after an interaction
  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    if (isMulti || !!onSelectRender) {
      return <>{children}</>;
    }
    return <PopoverClose asChild>{children}</PopoverClose>;
  };

  const Description: React.ReactNode = description ? (
    typeof description === "string" ? (
      <span
        className={tcx(
          "text-xs",
          {
            "text-content-secondary": !disabled,
            "text-content-tertiary": disabled,
          },
          !inlineDescription && "block",
        )}
      >
        {description}
      </span>
    ) : (
      description
    )
  ) : undefined;

  if (render) {
    return <Wrapper>{render({ onClick: () => onClick(value) })}</Wrapper>;
  }

  const { className: iconClassName, ...restIconProps } = iconProps ?? {};

  return (
    <Wrapper>
      <PopoverItem
        onClick={() => onClick(value)}
        suffix={badge}
        showContinueChevron={!!onSelectRender}
        icon={
          isMulti
            ? isSelected
              ? IconEnum.FakeCheckboxChecked
              : IconEnum.FakeCheckbox
            : icon
        }
        iconProps={{
          className: tcx("self-start mt-0.5", iconClassName),
          ...restIconProps,
        }}
        prefix={imageUrl && getAvatar({ imageUrl, label })}
        className={className}
        disabled={disabled}
      >
        <div className="w-full text-left">
          {label}
          {Description}
        </div>
      </PopoverItem>
    </Wrapper>
  );
};

export const getAvatar = ({
  imageUrl,
  label,
}: {
  imageUrl: string;
  label: string;
}) => {
  return (
    <Avatar
      className="self-start"
      size={IconSize.Medium}
      name={label}
      url={imageUrl}
    />
  );
};
