import {
  EscalationPath,
  EscalationPathTargetTypeEnum,
  ManagementMeta,
  ScopeNameEnum,
  SelectOption,
} from "@incident-io/api";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import { OrgAwareLink, useOrgAwareNavigate } from "@incident-shared/org-aware";
import { TeamBadge } from "@incident-shared/teams/TeamBadge";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableProps,
  TableRow,
} from "@incident-ui/Table/Table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { CopyDebugID } from "../../../utils/StaffOverlayProvider";
import { tcx } from "../../../utils/tailwind-classes";
import { useRevalidate } from "../../../utils/use-revalidate";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";
import { EscalationPathsFilter } from "./EscalationPathsFilter";

export type EscalationPathsContext =
  | "ALL_ESCALATION_PATHS"
  | "MY_ESCALATION_PATHS";

export const EscalationPathsTable = ({
  firstLevelUserIDs,
  escalationPaths,
  managementMeta,
  showEmptyState,
  context,
  infiniteScroll,
  showFilter,
  selectedTeams,
  setSelectedTeams,
  isLoading,
}: EscalationPathsTableProps) => {
  const { featureNativeTeams } = useFlags();

  return (
    <div className={"flex flex-col gap-6"}>
      <div className={"text-content-primary font-semibold text-base"}>
        {context === "ALL_ESCALATION_PATHS"
          ? "All escalation paths"
          : "My escalation paths"}
      </div>
      {showFilter && (
        <EscalationPathsFilter
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      )}
      <Table
        gridTemplateColumns={
          featureNativeTeams ? "30% 40% 25% 5%" : "45% 50% 5%"
        }
        header={
          <>
            <TableHeaderCell className={"first:pl-0"}>Name</TableHeaderCell>
            <TableHeaderCell>Current responders</TableHeaderCell>
            {featureNativeTeams && <TableHeaderCell>Teams</TableHeaderCell>}
            <TableHeaderCell className={"justify-end"} />
          </>
        }
        data={escalationPaths}
        infiniteScroll={infiniteScroll}
        renderRow={(escalationPath, idx) => (
          <EscalationPathRow
            key={`escalation-path-${escalationPath.id}`}
            escalationPath={escalationPath}
            firstLevelUserIDs={firstLevelUserIDs[escalationPath.id] || []}
            isLastRow={idx === escalationPaths.length - 1}
            managementMeta={(managementMeta ?? {})[escalationPath.id]}
          />
        )}
      />
      {showEmptyState && !isLoading && escalationPaths.length === 0 && (
        <tr>
          <td colSpan={3}>
            <div className="flex items-center justify-center h-[32px]">
              <span>No results found</span>
            </div>
          </td>
        </tr>
      )}
    </div>
  );
};

type BaseEscalationPathsTableProps = {
  showEmptyState?: boolean;
  escalationPaths: EscalationPath[];
  managementMeta: Record<string, ManagementMeta | undefined> | undefined;
  firstLevelUserIDs: Record<string, string[]>;
  context: EscalationPathsContext;
  infiniteScroll?: TableProps<EscalationPath>["infiniteScroll"];
  isLoading: boolean;
};

type FilterableEscalationPathsTableProps = BaseEscalationPathsTableProps & {
  showFilter: true;
  selectedTeams: SelectOption[];
  setSelectedTeams: (teams: SelectOption[]) => void;
};

type NonFilterableEscalationPathsTableProps = BaseEscalationPathsTableProps & {
  showFilter: false;
  selectedTeams?: SelectOption[];
  setSelectedTeams?: (teams: SelectOption[]) => void;
};

export type EscalationPathsTableProps =
  | FilterableEscalationPathsTableProps
  | NonFilterableEscalationPathsTableProps;

const EscalationPathRow = ({
  escalationPath,
  managementMeta,
  firstLevelUserIDs,
  isLastRow,
}: {
  escalationPath: EscalationPath;
  managementMeta: ManagementMeta | undefined;
  firstLevelUserIDs: Array<string>;
  isLastRow: boolean;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { featureNativeTeams } = useFlags();

  const cellClassName = [
    "group-hover:bg-surface-secondary",
    { "border-stroke-primary": isLastRow },
  ];

  const revalidate = useRevalidate([
    "escalationPathsList",
    "escalationPathsListExternal",
  ]);

  return (
    <>
      <TableRow isLastRow={isLastRow} className={"group"}>
        {/* Name */}
        <TableCell className={tcx("first:pl-0", ...cellClassName)}>
          <OrgAwareLink
            to={`/on-call/escalation-paths/${escalationPath.id}`}
            className="w-full h-full flex items-center"
          >
            <span className="text-sm font-semibold block truncate">
              {escalationPath.name}
            </span>
            <CopyDebugID id={escalationPath.id} />
            <ManagementMetaBadge
              management={managementMeta}
              resourceName="escalation path"
              className="ml-2"
            />
          </OrgAwareLink>
        </TableCell>

        {/* Current responders */}
        <TableCell className={tcx(...cellClassName)}>
          <OrgAwareLink
            to={`/on-call/escalation-paths/${escalationPath.id}`}
            className="w-full h-full grow flex items-center flex-wrap gap-2"
          >
            {firstLevelUserIDs.length > 0 ? (
              firstLevelUserIDs.map((id) => (
                <TargetPreview
                  key={id}
                  targetType={EscalationPathTargetTypeEnum.User}
                  targetID={id}
                />
              ))
            ) : (
              <div className="text-slate-300">{"\u2014"}</div>
            )}
          </OrgAwareLink>
        </TableCell>

        {/* Teams */}
        {featureNativeTeams && (
          <TableCell className={tcx(...cellClassName)}>
            <OrgAwareLink
              to={`/on-call/escalation-paths/${escalationPath.id}`}
              className="w-full h-full grow flex items-center flex-wrap gap-2"
            >
              {escalationPath.team_ids.length > 0 ? (
                escalationPath.team_ids.map((id) => (
                  <TeamBadge key={id} teamId={id} />
                ))
              ) : (
                <span className="text-slate-300">{"\u2014"}</span>
              )}
            </OrgAwareLink>
          </TableCell>
        )}

        {/* Dropdown */}
        <TableCell className={tcx("flex justify-end pr-4", ...cellClassName)}>
          <DropdownMenu
            menuClassName={"w-[120px] mr-5"}
            triggerButton={
              <Button
                theme={ButtonTheme.Unstyled}
                size={BadgeSize.Medium}
                className={tcx(
                  "!p-0 hover:bg-surface-secondary text-content-tertiary",
                )}
                icon={IconEnum.DotsVertical}
                iconProps={{
                  size: IconSize.XL,
                }}
                title={"Open menu"}
                analyticsTrackingId={"escalation-paths.open-menu"}
              />
            }
            side={"bottom"}
          >
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => navigate(`${escalationPath.id}/edit`)}
              label="Edit"
              icon={IconEnum.Edit}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() =>
                navigate(
                  `/on-call/escalation-paths/create?clone=${escalationPath.id}`,
                )
              }
              label="Duplicate"
              icon={IconEnum.Copy}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => setDeleteModalOpen(true)}
              label="Delete"
              disabled={!hasScope(ScopeNameEnum.EscalationPathsDestroy)}
              tooltipContent={
                hasScope(ScopeNameEnum.EscalationPathsDestroy)
                  ? undefined
                  : "You do not have permission to delete escalation paths."
              }
              destructive
              icon={IconEnum.Delete}
              iconProps={{ className: "text-red-content" }}
            />
          </DropdownMenu>
        </TableCell>
      </TableRow>
      {deleteModalOpen && escalationPath && (
        <ConfirmDeleteEscalationPolicyModal
          escalationPath={escalationPath}
          onConfirm={() => {
            revalidate();
            navigate("/on-call/escalation-paths");
          }}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

const TargetPreview = ({
  targetType,
  targetID,
}: {
  targetType: EscalationPathTargetTypeEnum;
  targetID: string;
}) => {
  if (targetType === EscalationPathTargetTypeEnum.User) {
    return <TargetPreviewUser targetID={targetID} />;
  }

  throw new Error(`Unexpected TargetPreview.type: ${targetType}`);
};

const TargetPreviewUser = ({ targetID }: { targetID: string }) => {
  const { data: userResp, isLoading } = useAPI("usersShow", {
    id: targetID,
  });

  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Medium}>
      <Avatar
        size={IconSize.Medium}
        url={userResp?.user.avatar_url}
        name={userResp?.user.name}
      />
      {isLoading ? (
        <span>Loading user</span>
      ) : (
        <span className="text-sm font-medium text-content-primary inline-block truncate">
          {userResp?.user.name}
        </span>
      )}
    </Badge>
  );
};

const ConfirmDeleteEscalationPolicyModal = ({
  onConfirm,
  onCancel,
  escalationPath,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  escalationPath: EscalationPath;
}) => {
  const revalidateExternal = useRevalidate(["escalationPathsListExternal"]);
  const revalidateNative = useRevalidate(["escalationPathsList"]);

  const {
    trigger: onSubmit,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "escalationPathsList",
    {},
    async (apiClient, { id }: { id: string }) => {
      await apiClient.escalationPathsDestroy({
        id,
      });
    },
    {
      onSuccess: () => {
        onConfirm();
        revalidateNative();
        revalidateExternal();
      },
    },
  );

  const onDelete = () => {
    onSubmit({ id: escalationPath.id });
  };

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <DeletionConfirmationModal
      resourceTitle={escalationPath.name}
      title={"Delete escalation path"}
      onDelete={onDelete}
      isDeleting={isDestroying}
      isOpen={true}
      onClose={onCancel}
      deleteConfirmationContent={
        "Are you sure you want to delete this escalation path?"
      }
      analyticsTrackingId="delete-escalation-path"
      fetchDependentResources={[
        {
          resource_type: `CatalogEntry["EscalationPath"]`,
          id: escalationPath.id,
        },
      ]}
    />
  );
};
