import { CopilotThread } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Avatar,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { FieldValues, Path, useForm, UseFormReturn } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { PromptNamesSelect } from "../common/PromptNamesSelect";
import { CopilotScoreBadge } from "./CopilotThreadScoring";

type CopilotThreadFilterValues = {
  prompt: string;
  tag: string;
  score: string;
  classification: "question" | "action" | "other";
  traceId: string;
};

export const CopilotThreadsListPage = () => {
  const formMethods = useForm<CopilotThreadFilterValues>({
    defaultValues: {},
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);

  const filters = formMethods.watch();

  const tag = searchParams.get("tag") ?? filters.tag;

  const { data, isLoading } = useAPI(
    "aIStaffListCopilotThreads",
    {
      prompt: filters.prompt ?? undefined,
      score: filters.score ?? undefined,
      classification: filters.classification ?? undefined,
      tag: tag ?? undefined,
      traceId: filters.traceId ?? undefined,
    },
    {
      fallbackData: { threads: [] },
    },
  );

  return (
    <WorkbenchSubPageWrapper>
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex flex-row items-center gap-4">
          <div className="flex-center-y w-full justify-between">
            <div className="flex-center-y gap-4">
              <h2 className="text-lg font-semibold">Recent threads</h2>
              {tag && (
                <div className="flex items-center gap-2 bg-slate-50 rounded-full px-2 py-1 h-8 mono uppercase">
                  <Icon id={IconEnum.Tag} size={IconSize.Small} />
                  <span className="text-xs font-medium">{tag}</span>
                  <Icon
                    id={IconEnum.Delete}
                    size={IconSize.Small}
                    onClick={() => {
                      const sp = { ...searchParams };
                      delete sp["tag"];
                      setSearchParams(sp);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
            <Button
              theme={ButtonTheme.Naked}
              onClick={() => setShowFilters(!showFilters)}
              analyticsTrackingId={null}
              icon={IconEnum.Filter}
            >
              <>Filters</>
              <Icon
                id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                size={IconSize.Small}
              />
            </Button>
          </div>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false}>
            <div className="w-full space-y-2">
              <PromptNamesSelect
                formMethods={formMethods}
                name="prompt"
                label="Prompt"
              />
              <InteractionScoreSelect formMethods={formMethods} name="score" />
              <InteractionClassificationSelect
                formMethods={formMethods}
                name="classification"
                helptext="Filter by classification of the interaction"
              />
              <InteractionTraceIDInput
                formMethods={formMethods}
                name="traceId"
                helptext="Filter by trace ID"
              />
              <InteractionTagInput
                formMethods={formMethods}
                name="tag"
                helptext="Filter by tag"
              />
            </div>
          </Callout>
        )}
        <LoadingWrapper loading={isLoading}>
          {data.threads.length === 0 ? (
            <EmptyState
              title="No threads found"
              content="Prompt was not ran in the last 100 threads"
            />
          ) : (
            <StackedList>
              {data.threads.map((thread) => (
                <CopilotThreadRender key={thread.id} thread={thread} />
              ))}
            </StackedList>
          )}
        </LoadingWrapper>{" "}
      </Form.Root>
    </WorkbenchSubPageWrapper>
  );
};

const CopilotThreadRender = ({ thread }: { thread: CopilotThread }) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex justify-between gap-2 px-4 group overflow-x-hidden"
      href={`/workbench/threads/${thread.id}`}
    >
      <div className="flex gap-4 py-4">
        <LocalDateTime timestamp={thread.created_at} className="font-medium" />
        {thread.incident_external_id && (
          <Button
            theme={ButtonTheme.Naked}
            analyticsTrackingId={null}
            href={"/incidents/" + thread.incident_external_id}
          >
            INC-{thread.incident_external_id}
          </Button>
        )}
        {/* Fix the width so it looks aligned without a user */}
        <div className="w-6 shrink-0">
          {thread.from_user && (
            <Tooltip content={thread.from_user.name}>
              <Avatar
                url={thread.from_user.avatar_url}
                name={thread.from_user.name}
                noTitle
              />
            </Tooltip>
          )}
        </div>
        <div className="max-w-[40vw] truncate">{thread.source_message}</div>
      </div>
      <div className="flex items-center gap-2">
        {!!thread.overall_cost_cents && (
          <div className="flex items-center justify-end gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(thread.overall_cost_cents / 100.0).toFixed(2)}
          </div>
        )}
        {thread.overall_score && (
          <CopilotScoreBadge score={thread.overall_score} />
        )}
        <Icon
          id={IconEnum.ArrowRight}
          className="justify-end shrink-0 text-content-secondary group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};

const InteractionScoreSelect = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
}) => {
  const options = [
    {
      label: "High",
      value: "high",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Low",
      value: "low",
    },
  ];

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      placeholder="(e.g. low)"
      options={options}
      label={"Score"}
      helptext={helptext}
      isClearable={true}
    />
  );
};

const InteractionClassificationSelect = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
}) => {
  const options = [
    {
      label: "Question",
      value: "question",
    },
    {
      label: "Action",
      value: "action",
    },
    {
      label: "Both",
      value: "both",
    },
    {
      label: "Unsure",
      value: "unsure",
    },
  ];
  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      placeholder="(e.g. question)"
      options={options}
      label={"Classification"}
      helptext={helptext}
      isClearable={true}
    />
  );
};

const InteractionTraceIDInput = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
}) => {
  return (
    <InputV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      label={"Trace ID"}
      placeholder="Enter Trace ID"
      helptext={helptext}
    />
  );
};

const InteractionTagInput = <FormType extends FieldValues>({
  formMethods,
  name,
  helptext,
}: {
  formMethods: UseFormReturn<FormType>;
  name: string;
  helptext?: string;
}) => {
  return (
    <InputV2
      formMethods={formMethods}
      name={name as unknown as Path<FormType>}
      label={"Tag"}
      placeholder="Enter tag"
      helptext={helptext}
    />
  );
};
