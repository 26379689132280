/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationLeaderboard,
    EvaluationLeaderboardFromJSON,
    EvaluationLeaderboardFromJSONTyped,
    EvaluationLeaderboardToJSON,
} from './EvaluationLeaderboard';
import {
    EvaluationLeaderboardEntry,
    EvaluationLeaderboardEntryFromJSON,
    EvaluationLeaderboardEntryFromJSONTyped,
    EvaluationLeaderboardEntryToJSON,
} from './EvaluationLeaderboardEntry';

/**
 * 
 * @export
 * @interface AIStaffShowEvaluationLeaderboardResponseBody
 */
export interface AIStaffShowEvaluationLeaderboardResponseBody {
    /**
     * 
     * @type {Array<EvaluationLeaderboardEntry>}
     * @memberof AIStaffShowEvaluationLeaderboardResponseBody
     */
    entries: Array<EvaluationLeaderboardEntry>;
    /**
     * 
     * @type {EvaluationLeaderboard}
     * @memberof AIStaffShowEvaluationLeaderboardResponseBody
     */
    leaderboard: EvaluationLeaderboard;
}

export function AIStaffShowEvaluationLeaderboardResponseBodyFromJSON(json: any): AIStaffShowEvaluationLeaderboardResponseBody {
    return AIStaffShowEvaluationLeaderboardResponseBodyFromJSONTyped(json, false);
}

export function AIStaffShowEvaluationLeaderboardResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffShowEvaluationLeaderboardResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(EvaluationLeaderboardEntryFromJSON)),
        'leaderboard': EvaluationLeaderboardFromJSON(json['leaderboard']),
    };
}

export function AIStaffShowEvaluationLeaderboardResponseBodyToJSON(value?: AIStaffShowEvaluationLeaderboardResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(EvaluationLeaderboardEntryToJSON)),
        'leaderboard': EvaluationLeaderboardToJSON(value.leaderboard),
    };
}

