import {
  CompetitorImportCompetitorNameEnum,
  CompetitorImportPayload,
  CompetitorImportPayloadCompetitorNameEnum,
  CompetitorImportStatusEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { IconEnum, Link, ProgressBar, ToastTheme, Txt } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Route, Routes, useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useMutationV2 } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

export const ImportIncidentHistoryRoute = () => {
  const { hasScope } = useIdentity();

  const canImport = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  if (!canImport) {
    return <OrgAwareNavigate to="/404" />;
  }

  return (
    <PageWrapper
      title="Import incident history from another provider"
      icon={IconEnum.ArrowCircleUp}
      width={PageWidth.Narrow}
    >
      <Routes>
        <Route path=":id" element={<PollStatus />} />
        <Route path="" element={<ImportForm />} />
      </Routes>
    </PageWrapper>
  );
};

const PollStatus = () => {
  const { id } = useParams<{ id: string }>();
  const [hasFinishedImportingSourceData, setHasFinishedImportingSourceData] =
    useState(false);
  const [
    hasFinishedProcessingAllIncidents,
    setHasFinishedProcessingAllIncidents,
  ] = useState(false);
  const [prevIncidentsProcessed, setPrevIncidentsProcessed] = useState(0);
  const [incidentsProcessed, setIncidentsProcessed] = useState(0);
  const [progressStatusText, setProgressStatusText] = useState(
    "Importing data from your provider",
  );
  const {
    data: { competitor_import: data },
  } = useAPI(
    "competitorImportsShow",
    { id: id ?? "" },
    {
      fallbackData: {
        competitor_import: {
          id: id ?? "",
          status: CompetitorImportStatusEnum.Pending,
          subdomain: "",
          competitor_name: CompetitorImportCompetitorNameEnum.Blameless,
        },
      },
      refreshInterval: hasFinishedProcessingAllIncidents ? undefined : 1000,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (hasFinishedImportingSourceData) return;
    if (data.status === CompetitorImportStatusEnum.Failed) {
      setProgressStatusText(
        "We weren't able to complete your import. Our engineers have been informed, and we'll investigate what went wrong.",
      );
    } else if (data.status === CompetitorImportStatusEnum.Completed) {
      setProgressStatusText(
        "Finished importing data from your provider, we are now creating the incidents in our system. This may take some time; incidents will appear in your dashboard as they are imported!",
      );
      setHasFinishedImportingSourceData(true);
    }
  }, [data, hasFinishedImportingSourceData]);

  useEffect(() => {
    if (hasFinishedProcessingAllIncidents) return;
    if (
      data.total_incidents &&
      data.total_incidents > 0 &&
      data.incidents_processed === data.total_incidents
    ) {
      setHasFinishedProcessingAllIncidents(true);
      setProgressStatusText("Import complete!");
    }
    setPrevIncidentsProcessed(incidentsProcessed);
    setIncidentsProcessed(data.incidents_processed || 0);
  }, [data, hasFinishedProcessingAllIncidents, incidentsProcessed]);

  return (
    <div>
      <p>Working on your import...</p>
      <p>Current status: {progressStatusText}</p>

      {hasFinishedImportingSourceData &&
        data.total_incidents !== undefined &&
        data.total_incidents > 0 && (
          <>
            <p>
              {data.incidents_processed} / {data.total_incidents} incidents
              processed.
            </p>
            <div className="mt-3">
              <ProgressBar
                numCompleted={data.incidents_processed ?? 0}
                numTotal={data.total_incidents}
                transitionCompletedFrom={prevIncidentsProcessed}
                animateEmptyBar
              />
            </div>
          </>
        )}
    </div>
  );
};

const ImportForm = () => {
  const formMethods = useForm<CompetitorImportPayload>({
    defaultValues: {
      competitor_name: CompetitorImportPayloadCompetitorNameEnum.Blameless,
    },
  });

  const { enableCompetitorImport } = useFlags();

  const navigate = useOrgAwareNavigate();
  const toast = useToast();
  const { trigger, isMutating: saving } = useMutationV2(
    async (apiClient, data: CompetitorImportPayload) => {
      // The form state can have data from a different competitor in it, which
      // we want to remove from the request body before sending it to the API,
      // otherwise we'll generate an invalid payload.
      const reqBody: CompetitorImportPayload = {
        competitor_name: data.competitor_name,
      };
      if (
        data.competitor_name ===
        CompetitorImportPayloadCompetitorNameEnum.Blameless
      ) {
        reqBody.blameless_options = data.blameless_options;
      } else if (
        data.competitor_name ===
        CompetitorImportPayloadCompetitorNameEnum.Firehydrant
      ) {
        reqBody.firehydrant_options = data.firehydrant_options;
      } else if (
        data.competitor_name ===
        CompetitorImportPayloadCompetitorNameEnum.CoupangJira
      ) {
        reqBody.coupang_jira_options = data.coupang_jira_options;
      } else if (
        data.competitor_name ===
        CompetitorImportPayloadCompetitorNameEnum.Servicenow
      ) {
        reqBody.servicenow_options = data.servicenow_options;
      } else {
        assertUnreachable(data.competitor_name);
      }
      return apiClient.competitorImportsCreate({ createRequestBody: reqBody });
    },
    {
      setError: formMethods.setError,
      onSuccess: ({ competitor_import }) => {
        navigate(`/incident-history-import/${competitor_import.id}`);
      },
      onError: () => {
        toast({
          title: "Failed to import incident history, please try again",
          theme: ToastTheme.Error,
        });
      },
      invalidate: [],
    },
  );

  const providerOptions = [
    {
      value: CompetitorImportPayloadCompetitorNameEnum.Blameless,
      label: "Blameless",
    },
    {
      value: CompetitorImportPayloadCompetitorNameEnum.Firehydrant,
      label: "FireHydrant",
    },
    // Note that Jira and ServiceNow are not available in this UI
  ];

  return (
    <Form.Root formMethods={formMethods} onSubmit={trigger} saving={saving}>
      <StaticSingleSelectV2
        options={providerOptions}
        name="competitor_name"
        formMethods={formMethods}
        label={"Provider"}
        placeholder={"Select a provider"}
        required={true}
      />
      <FormForProvider formMethods={formMethods} />
      <GatedButton
        analyticsTrackingId={"competitor-import-create"}
        type="submit"
        disabled={!enableCompetitorImport}
        disabledTooltipContent="Imports can only be run in collaboration with support. Please contact us for assistance."
      >
        Import
      </GatedButton>
    </Form.Root>
  );
};

const FormForProvider = ({
  formMethods,
}: {
  formMethods: UseFormReturn<CompetitorImportPayload>;
}) => {
  const selectedProvider = formMethods.watch("competitor_name");

  if (
    selectedProvider === CompetitorImportPayloadCompetitorNameEnum.Blameless
  ) {
    return (
      <>
        <InputV2
          formMethods={formMethods}
          name="blameless_options.subdomain"
          label="Blameless subdomain"
          helptext="If your Blameless account is hosted on my-org.blameless.io, enter 'my-org' here."
          insetSuffixNode={<Txt lightGrey>.blameless.io</Txt>}
        />
        <InputV2
          formMethods={formMethods}
          type={InputType.Password}
          name="blameless_options.api_key"
          label="API Key"
          required={true}
          helptext={
            <>
              Instructions for generating an API key can be found in
              Blameless&rsquo;s docs{" "}
              <Link
                analyticsTrackingId={"blameless-api-key-help"}
                href="https://developers.blameless.com/docs/bl-apis/jfvfout9k4kkb-getting-started#prerequisites"
                openInNewTab
              >
                here
              </Link>
              .
            </>
          }
        />
      </>
    );
  }

  if (
    selectedProvider === CompetitorImportPayloadCompetitorNameEnum.Firehydrant
  ) {
    return (
      <InputV2
        formMethods={formMethods}
        type={InputType.Password}
        name="firehydrant_options.api_key"
        label="API Key"
        required={true}
        helptext={
          <>
            Instructions for generating an API key can be found in
            FireHydrant&rsquo;s docs{" "}
            <Link
              analyticsTrackingId={"firehydrant-api-key-help"}
              href="https://docs.firehydrant.com/docs/api-keys#creating-the-api-key"
              openInNewTab
            >
              here
            </Link>
            .
          </>
        }
      />
    );
  }

  if (
    selectedProvider ===
      CompetitorImportPayloadCompetitorNameEnum.CoupangJira ||
    selectedProvider === CompetitorImportPayloadCompetitorNameEnum.Servicenow
  ) {
    return null;
  }

  assertUnreachable(selectedProvider);
  return null;
};
