import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import React, { useEffect, useMemo } from "react";
import { PopoverMultiSelect } from "src/components/@ui/PopoverSelect/PopoverMultiSelect";
import {
  PopoverSelectOption,
  PopoverSelectOptions,
} from "src/components/@ui/PopoverSelect/types";
import {
  paramsAsObj,
  useQueryParams,
  useSafeUpdateQueryString,
} from "src/utils/query-params";

interface CustomDisplayProps {
  availableOptions: PopoverSelectOptions<PopoverSelectOption>;
  selectedValues: string[];
  onSelectionChange: (newValues: string[]) => void;
  /** Whether to persist selection in URL query params */
  persistInUrl?: boolean;
  /** Query parameter name to use if persistInUrl is true */
  urlParamName?: string;
  defaultValues?: string[];
  analyticsTrackingId?: string;
  buttonLabel?: string;
}

const flattenOptions = (
  options: PopoverSelectOptions<PopoverSelectOption>,
): PopoverSelectOption[] => {
  return options.flatMap((item) => ("options" in item ? item.options : item));
};

export const CustomDisplay: React.FC<CustomDisplayProps> = ({
  availableOptions,
  selectedValues,
  onSelectionChange,
  persistInUrl = false,
  urlParamName = "columns",
  defaultValues,
  analyticsTrackingId = null,
  buttonLabel = "Display",
}) => {
  const updateQueryString = useSafeUpdateQueryString();
  const queryParams = useQueryParams();
  const params = paramsAsObj(queryParams);

  // Flatten options for lookup
  const flatOptions = useMemo(
    () => flattenOptions(availableOptions),
    [availableOptions],
  );

  // Initialize from URL if needed
  useEffect(() => {
    if (persistInUrl && selectedValues.length === 0 && flatOptions.length > 0) {
      const optionsParam = params[urlParamName] || null;

      if (optionsParam) {
        const valuesFromUrl = optionsParam.split(",");
        const validValues = valuesFromUrl.filter((value) =>
          flatOptions.some((option) => option.value === value),
        );

        if (validValues.length > 0) {
          onSelectionChange(validValues);
        }
      } else if (defaultValues && defaultValues.length > 0) {
        const validDefaults = defaultValues.filter((value) =>
          flatOptions.some((option) => option.value === value),
        );

        if (validDefaults.length > 0) {
          onSelectionChange(validDefaults);
        }
      }
    }
  }, [
    persistInUrl,
    selectedValues.length,
    flatOptions,
    params,
    urlParamName,
    onSelectionChange,
    defaultValues,
  ]);

  const handleSelectionChange = (newValues: string[]) => {
    onSelectionChange(newValues);

    if (persistInUrl) {
      const newParams = new URLSearchParams(queryParams);
      const serialized = newValues.join(",");
      newParams.set(urlParamName, serialized);
      updateQueryString(newParams.toString());
    }
  };

  return (
    <PopoverMultiSelect
      isSearchable
      options={availableOptions}
      value={selectedValues}
      onChange={handleSelectionChange}
      align={"end"}
      renderTriggerNode={({ onClick }) => (
        <Button
          theme={ButtonTheme.Tertiary}
          analyticsTrackingId={analyticsTrackingId}
          icon={IconEnum.Sliders}
          iconProps={{
            size: IconSize.Medium,
          }}
          onClick={onClick}
          size={BadgeSize.Medium}
        >
          {buttonLabel}
        </Button>
      )}
    />
  );
};
