/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelemetrySelectDashboardRequestBody
 */
export interface TelemetrySelectDashboardRequestBody {
    /**
     * The ID of the dashboard to select
     * @type {string}
     * @memberof TelemetrySelectDashboardRequestBody
     */
    dashboard_id: string;
    /**
     * The provider of the dashboard
     * @type {string}
     * @memberof TelemetrySelectDashboardRequestBody
     */
    provider: TelemetrySelectDashboardRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum TelemetrySelectDashboardRequestBodyProviderEnum {
    Grafana = 'grafana'
}

export function TelemetrySelectDashboardRequestBodyFromJSON(json: any): TelemetrySelectDashboardRequestBody {
    return TelemetrySelectDashboardRequestBodyFromJSONTyped(json, false);
}

export function TelemetrySelectDashboardRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetrySelectDashboardRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard_id': json['dashboard_id'],
        'provider': json['provider'],
    };
}

export function TelemetrySelectDashboardRequestBodyToJSON(value?: TelemetrySelectDashboardRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard_id': value.dashboard_id,
        'provider': value.provider,
    };
}

