/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface AlertRouteTemplateBinding
 */
export interface AlertRouteTemplateBinding {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertRouteTemplateBinding
     */
    binding?: EngineParamBinding;
}

export function AlertRouteTemplateBindingFromJSON(json: any): AlertRouteTemplateBinding {
    return AlertRouteTemplateBindingFromJSONTyped(json, false);
}

export function AlertRouteTemplateBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteTemplateBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': !exists(json, 'binding') ? undefined : EngineParamBindingFromJSON(json['binding']),
    };
}

export function AlertRouteTemplateBindingToJSON(value?: AlertRouteTemplateBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingToJSON(value.binding),
    };
}

