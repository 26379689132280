import { AIConfig, ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum, OrgAwareLink } from "@incident-ui";
import { useHasCallProviderWithScribeSupport } from "src/components/incident-calls/useIsScribeAvailable";
import { GoogleMeetMissingPermissionsForScribe } from "src/components/incident-calls/useShowScribeBanner";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AIPrivacyButton } from "./AIFeatureCard";
import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
  ToggleAIFeatureButton,
} from "./AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "./useAIFeatures";

export const ScribeDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);
  const { identity } = useIdentity();

  const missingGooglePermissionsForScribe =
    GoogleMeetMissingPermissionsForScribe();

  const { isSupportedCallProviderInstalled, isLoading: callConfigLoading } =
    useHasCallProviderWithScribeSupport();

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling scribe",
    },
  );
  const enabled = aiConfigResp?.config.scribe_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp || !aiConfigResp.config || callConfigLoading}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleAIFeatureButton
                enabled={!!enabled}
                disableModalTitle="Disable scribe"
                disableModalContent="This means a scribe will no longer try to join your incident calls"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                disabledTooltipContent={
                  isSupportedCallProviderInstalled ? (
                    <span>
                      Before you can activate Scribe, you need to install either
                      the{" "}
                      <OrgAwareLink
                        className="underline"
                        to="/settings/integrations/google_meet"
                      >
                        Google Meet
                      </OrgAwareLink>{" "}
                      or{" "}
                      <OrgAwareLink
                        className="underline"
                        to="/settings/integrations/zoom"
                      >
                        Zoom
                      </OrgAwareLink>{" "}
                      integration.
                    </span>
                  ) : missingGooglePermissionsForScribe ? (
                    <span>
                      Before you can activate Scribe, you need to{" "}
                      <OrgAwareLink
                        className="underline"
                        to="/settings/integrations/google_meet"
                      >
                        reconnect your video call provider
                      </OrgAwareLink>{" "}
                      to ensure we have the latest permissions.
                    </span>
                  ) : null
                }
                upgradeRequired={
                  identity?.feature_gates.call_transcription_hours_per_month !=
                    null &&
                  identity?.feature_gates.call_transcription_hours_per_month ===
                    0
                }
                upgradeRequiredProps={{
                  featureName: "call transcription",
                  gate: {
                    type: "numeric",
                    value:
                      identity.feature_gates.call_transcription_hours_per_month,
                    featureNameSingular: "call transcription hours",
                  },
                }}
                onEnable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      scribe_enabled: true,
                    },
                  });
                }}
                onDisable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      scribe_enabled: false,
                    },
                  });
                }}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Live transcription"
              description="Ensures no information is lost, so you can focus on resolving the issue."
              // TODO: not gradient!
              icon={IconEnum.TranscriptAIGradient}
            />
            <AIProductMarketingCard
              title="Real-time summaries"
              description="Pull the most important points from the conversation automatically."
              icon={IconEnum.Clock}
            />
            <AIProductMarketingCard
              title="Key moments"
              description="Scribe shares decisions and actions in Slack, so you never miss a detail."
              icon={IconEnum.NumberOrderedList}
            />
            <AIProductMarketingCard
              title="Current topic"
              description="Gets you up to speed, so you know when the call is relevant to you"
              icon={IconEnum.Doc}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
