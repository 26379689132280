/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoArchiveCancelledMergedDeclinedIncidentsSettings,
    AutoArchiveCancelledMergedDeclinedIncidentsSettingsFromJSON,
    AutoArchiveCancelledMergedDeclinedIncidentsSettingsFromJSONTyped,
    AutoArchiveCancelledMergedDeclinedIncidentsSettingsToJSON,
} from './AutoArchiveCancelledMergedDeclinedIncidentsSettings';

/**
 * 
 * @export
 * @interface SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody
 */
export interface SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody {
    /**
     * 
     * @type {AutoArchiveCancelledMergedDeclinedIncidentsSettings}
     * @memberof SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody
     */
    auto_archive_cancelled_merged_declined_incidents?: AutoArchiveCancelledMergedDeclinedIncidentsSettings;
}

export function SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyFromJSON(json: any): SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody {
    return SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_archive_cancelled_merged_declined_incidents': !exists(json, 'auto_archive_cancelled_merged_declined_incidents') ? undefined : AutoArchiveCancelledMergedDeclinedIncidentsSettingsFromJSON(json['auto_archive_cancelled_merged_declined_incidents']),
    };
}

export function SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyToJSON(value?: SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_archive_cancelled_merged_declined_incidents': AutoArchiveCancelledMergedDeclinedIncidentsSettingsToJSON(value.auto_archive_cancelled_merged_declined_incidents),
    };
}

