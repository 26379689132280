/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A value recorded for a dimension
 * @export
 * @interface EvaluationScorecardDimensionValue
 */
export interface EvaluationScorecardDimensionValue {
    /**
     * Duration in seconds, if dimension type is duration
     * @type {number}
     * @memberof EvaluationScorecardDimensionValue
     */
    duration_seconds?: number;
    /**
     * Integer value, if dimension type is int
     * @type {number}
     * @memberof EvaluationScorecardDimensionValue
     */
    _int?: number;
    /**
     * Floating point value, if dimension type is number
     * @type {number}
     * @memberof EvaluationScorecardDimensionValue
     */
    number?: number;
    /**
     * String value, if dimension type is string
     * @type {string}
     * @memberof EvaluationScorecardDimensionValue
     */
    string?: string;
}

export function EvaluationScorecardDimensionValueFromJSON(json: any): EvaluationScorecardDimensionValue {
    return EvaluationScorecardDimensionValueFromJSONTyped(json, false);
}

export function EvaluationScorecardDimensionValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecardDimensionValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration_seconds': !exists(json, 'duration_seconds') ? undefined : json['duration_seconds'],
        '_int': !exists(json, 'int') ? undefined : json['int'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'string': !exists(json, 'string') ? undefined : json['string'],
    };
}

export function EvaluationScorecardDimensionValueToJSON(value?: EvaluationScorecardDimensionValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_seconds': value.duration_seconds,
        'int': value._int,
        'number': value.number,
        'string': value.string,
    };
}

