/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MicrosoftGraphReceiveSubscriptionLifecycleNotificationResponseBody,
    MicrosoftGraphReceiveSubscriptionLifecycleNotificationResponseBodyFromJSON,
    MicrosoftGraphReceiveSubscriptionLifecycleNotificationResponseBodyToJSON,
} from '../models';

export interface MicrosoftGraphReceiveSubscriptionLifecycleNotificationRequest {
    validationToken?: string;
}

export interface MicrosoftGraphReceiveSubscriptionNotificationRequest {
    validationToken?: string;
}

/**
 * 
 */
export class MicrosoftGraphApi extends runtime.BaseAPI {

    /**
     * Receive a lifecycle notification for a subscription
     * ReceiveSubscriptionLifecycleNotification Microsoft Graph
     */
    async microsoftGraphReceiveSubscriptionLifecycleNotificationRaw(requestParameters: MicrosoftGraphReceiveSubscriptionLifecycleNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/microsoft-graph/subscription/lifecycle-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Receive a lifecycle notification for a subscription
     * ReceiveSubscriptionLifecycleNotification Microsoft Graph
     */
    async microsoftGraphReceiveSubscriptionLifecycleNotification(requestParameters: MicrosoftGraphReceiveSubscriptionLifecycleNotificationRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.microsoftGraphReceiveSubscriptionLifecycleNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Receive a notification for a subscription
     * ReceiveSubscriptionNotification Microsoft Graph
     */
    async microsoftGraphReceiveSubscriptionNotificationRaw(requestParameters: MicrosoftGraphReceiveSubscriptionNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/microsoft-graph/subscription/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a notification for a subscription
     * ReceiveSubscriptionNotification Microsoft Graph
     */
    async microsoftGraphReceiveSubscriptionNotification(requestParameters: MicrosoftGraphReceiveSubscriptionNotificationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.microsoftGraphReceiveSubscriptionNotificationRaw(requestParameters, initOverrides);
    }

}
