/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffRerunProcessorRequestBody
 */
export interface AIStaffRerunProcessorRequestBody {
    /**
     * Name of the processor to run
     * @type {string}
     * @memberof AIStaffRerunProcessorRequestBody
     */
    processor: string;
    /**
     * ID of the resource to process
     * @type {string}
     * @memberof AIStaffRerunProcessorRequestBody
     */
    resource_id: string;
}

export function AIStaffRerunProcessorRequestBodyFromJSON(json: any): AIStaffRerunProcessorRequestBody {
    return AIStaffRerunProcessorRequestBodyFromJSONTyped(json, false);
}

export function AIStaffRerunProcessorRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffRerunProcessorRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processor': json['processor'],
        'resource_id': json['resource_id'],
    };
}

export function AIStaffRerunProcessorRequestBodyToJSON(value?: AIStaffRerunProcessorRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processor': value.processor,
        'resource_id': value.resource_id,
    };
}

