/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Properties that describe an AI model
 * @export
 * @interface AIModel
 */
export interface AIModel {
    /**
     * Type of model - whether it performs completions or embeddings
     * @type {string}
     * @memberof AIModel
     */
    model_type: AIModelModelTypeEnum;
    /**
     * Name of the model
     * @type {string}
     * @memberof AIModel
     */
    name: string;
    /**
     * Provider of the model
     * @type {string}
     * @memberof AIModel
     */
    provider: AIModelProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum AIModelModelTypeEnum {
    Completions = 'completions',
    Embeddings = 'embeddings'
}/**
* @export
* @enum {string}
*/
export enum AIModelProviderEnum {
    Openai = 'openai',
    Anthropic = 'anthropic',
    Vertex = 'vertex'
}

export function AIModelFromJSON(json: any): AIModel {
    return AIModelFromJSONTyped(json, false);
}

export function AIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'model_type': json['model_type'],
        'name': json['name'],
        'provider': json['provider'],
    };
}

export function AIModelToJSON(value?: AIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'model_type': value.model_type,
        'name': value.name,
        'provider': value.provider,
    };
}

