/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchGroundTruth
 */
export interface SearchGroundTruth {
    /**
     * The items that our search should find
     * @type {Array<string>}
     * @memberof SearchGroundTruth
     */
    answer: Array<string>;
    /**
     * When the ground truth was created
     * @type {Date}
     * @memberof SearchGroundTruth
     */
    created_at: Date;
    /**
     * Timestamp to limit the search to
     * @type {Date}
     * @memberof SearchGroundTruth
     */
    cutoff: Date;
    /**
     * ID of the ground truth
     * @type {string}
     * @memberof SearchGroundTruth
     */
    id: string;
    /**
     * ID of the incident
     * @type {string}
     * @memberof SearchGroundTruth
     */
    incident_id?: string;
    /**
     * Search query question
     * @type {string}
     * @memberof SearchGroundTruth
     */
    question: string;
    /**
     * Type of question (code_changes, etc)
     * @type {string}
     * @memberof SearchGroundTruth
     */
    question_type: string;
    /**
     * When the ground truth was verified by a human
     * @type {Date}
     * @memberof SearchGroundTruth
     */
    verified_at?: Date;
}

export function SearchGroundTruthFromJSON(json: any): SearchGroundTruth {
    return SearchGroundTruthFromJSONTyped(json, false);
}

export function SearchGroundTruthFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchGroundTruth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': json['answer'],
        'created_at': (new Date(json['created_at'])),
        'cutoff': (new Date(json['cutoff'])),
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'question': json['question'],
        'question_type': json['question_type'],
        'verified_at': !exists(json, 'verified_at') ? undefined : (new Date(json['verified_at'])),
    };
}

export function SearchGroundTruthToJSON(value?: SearchGroundTruth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': value.answer,
        'created_at': (value.created_at.toISOString()),
        'cutoff': (value.cutoff.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'question': value.question,
        'question_type': value.question_type,
        'verified_at': value.verified_at === undefined ? undefined : (value.verified_at.toISOString()),
    };
}

