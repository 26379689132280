import {
  AIStaffListEvaluationNotesResourceTypeEnum,
  CopilotInteraction,
  Identity,
  User,
} from "@incident-io/api";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  Checkbox,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  Tooltip,
} from "@incident-ui";
import { FullPageLoader, Loader } from "@incident-ui/Loader/Loader";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import { useState } from "react";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { AISpanTrace } from "../ai-requests/AISpanTrace";
import { SensitiveInfoBanner } from "../common/SensitivityBanner";
import { TraceLink } from "../common/utils";
import { WorkbenchSection } from "../common/WorkbenchSection";
import { EvaluationNotesWidget } from "../evaluation-notes/EvaluationNotesWidget";
import { CopilotChatContext } from "./CopilotChatContext";
import { CopilotThreadScoring } from "./CopilotThreadScoring";

export const CopilotThreadPage = () => {
  const { threadId } = useParams<{ threadId: string }>();
  const navigate = useOrgAwareNavigate();
  if (!threadId) {
    navigate("/workbench");
    return null;
  }

  return (
    <SecondaryNavSubPageWrapper
      icon={IconEnum.Robot}
      title="Copilot thread"
      backHref="/workbench/threads"
    >
      <ThreadPageContent threadId={threadId} />
    </SecondaryNavSubPageWrapper>
  );
};

export type CopilotChatInteraction = {
  content: string;
  id: string;
  sent_at: Date;
  thread_id: string;
  user?: User;
  reactions: string[];
  is_copilot: boolean;
};

export interface ThreadedCopilotInteraction extends CopilotChatInteraction {
  isOriginalMessage?: boolean;
  replies?: ThreadedCopilotInteraction[];
}

export interface AIStaffShowCopilotInteractionThreadContextResponseBody {
  thread?: Array<CopilotChatInteraction>;
}

export const organizeInteractionsIntoThreads = (
  originalThreadTs: string,
  response: AIStaffShowCopilotInteractionThreadContextResponseBody,
): ThreadedCopilotInteraction[] => {
  if (!response.thread) {
    return [];
  }

  const threadMap = new Map<string, ThreadedCopilotInteraction>();
  const rootMessages: ThreadedCopilotInteraction[] = [];

  // First pass: Initialize all messages
  response.thread.forEach((message) => {
    const threadedMessage: ThreadedCopilotInteraction = {
      ...message,
      isOriginalMessage: message.id === originalThreadTs,
      replies: [],
    };
    threadMap.set(message.id, threadedMessage);
  });

  // Second pass: Organize messages into threads
  response.thread.forEach((message) => {
    const currentMessage = threadMap.get(message.id);
    if (!currentMessage) {
      return;
    }

    if (message.thread_id === message.id) {
      // This is a root message
      rootMessages.push(currentMessage);
    } else {
      // This is a reply
      const parentMessage = threadMap.get(message.thread_id);
      if (parentMessage) {
        if (!parentMessage.replies) {
          parentMessage.replies = [];
        }
        parentMessage.replies.push(currentMessage);
      }
    }
  });

  // Sort root messages and replies by sent_at
  const sortByDate = (
    a: ThreadedCopilotInteraction,
    b: ThreadedCopilotInteraction,
  ) => a.sent_at.getTime() - b.sent_at.getTime();

  rootMessages.sort(sortByDate);
  rootMessages.forEach((message) => {
    message.replies?.sort(sortByDate);
  });

  return rootMessages;
};

const ThreadPageContent = ({ threadId }: { threadId: string }) => {
  const { data: threadData, isLoading: threadLoading } = useAPI(
    "aIStaffShowCopilotThread",
    {
      id: threadId,
    },
  );

  const { identity } = useIdentity();

  const { data: interactionWindowData, error: interactionWindowError } = useAPI(
    "aIStaffShowCopilotInteractionThreadContext",
    {
      id: threadData?.thread?.id || "",
    },
    {
      revalidateIfStale: threadData?.thread?.id !== undefined,
    },
  );

  const [onlyShowOriginalMessage, setOnlyShowOriginalMessage] = useState(false);

  const chatContext = organizeInteractionsIntoThreads(
    threadData?.thread?.thread_ts ?? "",
    interactionWindowData ?? {},
  );

  const generateThreadSlackLink = (
    identity: Identity,
    chatChannel: string,
    ts: string,
  ) => {
    return `https://${
      identity.organisation_slug
    }.slack.com/archives/${chatChannel}/p${ts.replace(".", "")}`;
  };

  if (threadLoading || !threadData?.thread) {
    return <FullPageLoader />;
  }
  const interactions = threadData?.interactions ?? [];
  const thread = threadData.thread;

  return (
    <>
      <EvaluationNotesWidget
        resourceId={threadId}
        resourceType={AIStaffListEvaluationNotesResourceTypeEnum.CopilotThread}
        parentId={thread.incident_id}
      />
      {interactions.length === 0 ? (
        <EmptyState icon={IconEnum.Filter} content="No interactions found" />
      ) : (
        <div className="flex flex-col gap-6">
          <SensitiveInfoBanner />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Scoring Panel */}
            <CopilotThreadScoring thread={thread} />

            <WorkbenchSection
              title="Chat Context"
              contentClassName="max-h-[448px]"
              accessory={
                <div className="flex gap-4">
                  <Button
                    size={BadgeSize.Medium}
                    analyticsTrackingId={"slack-message-link"}
                    href={generateThreadSlackLink(
                      identity,
                      thread.slack_channel_id,
                      thread.thread_ts,
                    )}
                    icon={IconEnum.Slack}
                    openInNewTab
                    iconProps={{ size: IconSize.Small }}
                  >
                    Slack message
                  </Button>
                  <Checkbox
                    id="onlyShowOriginalMessage"
                    label="Hide full interaction"
                    className="text-sm"
                    checked={onlyShowOriginalMessage}
                    onChange={(e) =>
                      setOnlyShowOriginalMessage(e.target.checked)
                    }
                  />
                </div>
              }
            >
              {!interactionWindowError ? (
                <CopilotChatContext
                  messages={chatContext}
                  onlyShowOriginalMessage={onlyShowOriginalMessage}
                />
              ) : (
                <EmptyState
                  icon={IconEnum.Warning}
                  content="You do not have permission to view this chat context"
                />
              )}
            </WorkbenchSection>
          </div>

          {/* List of interactions */}
          <div>
            <ContentBox className="p-4 rounded-b-none border-b-0 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Interactions</h2>
              {thread?.incident_external_id && (
                <div className="flex items-center gap-0.5 shrink-0">
                  <Icon id={IconEnum.IncidentFlame} size={IconSize.Small} />
                  <Link
                    analyticsTrackingId={null}
                    openInNewTab
                    to={`/incidents/${thread.incident_external_id}`}
                  >
                    INC-{thread.incident_external_id}
                  </Link>
                </div>
              )}
            </ContentBox>
            <StackedList>
              {interactions.map((interaction, i) => (
                <InteractionRow
                  className={i === 0 ? "!rounded-t-none" : ""}
                  key={interaction.id}
                  interaction={interaction}
                  isOnlyInteraction={interactions.length === 1}
                />
              ))}
            </StackedList>
          </div>
        </div>
      )}
    </>
  );
};

const InteractionRow = ({
  isOnlyInteraction,
  interaction,
  className,
}: {
  isOnlyInteraction?: boolean;
  interaction: CopilotInteraction;
  className?: string;
}) => {
  const [expanded, setExpanded] = useState(isOnlyInteraction);
  const toggle = () => setExpanded(!expanded);

  const {
    data: { spans },
    isLoading: spanDataLoading,
  } = useAPI(
    "aIStaffListAISpans",
    {
      traceId: interaction.trace_id,
    },
    {
      revalidateIfStale: expanded,
      fallbackData: { spans: [] },
    },
  );

  return (
    <div className={tcx("flex flex-col gap-4 px-4", className)}>
      <Button
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={null}
        className={tcx(
          "flex items-start gap-2 w-full cursor-pointer group py-4",
          expanded && "border-b",
        )}
        onClick={toggle}
      >
        <div className="flex gap-6">
          <LocalDateTime
            timestamp={interaction.created_at}
            className="font-medium"
          />
          <div className="flex">
            <div className="flex flex-col gap-1">
              <div className="flex">
                {/* Fix the width so it looks aligned without a user */}
                {interaction.from_user && (
                  <Tooltip content={interaction.from_user.name}>
                    <Avatar
                      url={interaction.from_user.avatar_url}
                      name={interaction.from_user.name}
                      className="w-6 h-6 mr-1"
                      noTitle
                    />
                  </Tooltip>
                )}
                <div className="whitespace-pre-wrap">
                  {interaction.source_message}
                </div>
              </div>
              {interaction.response_message && (
                <>
                  <hr className="mx-8" />
                  <div className="flex">
                    <Icon
                      id={IconEnum.Robot}
                      className="w-6 h-6 p-1 mr-1 rounded-full bg-slate-50  "
                    />
                    <div className="whitespace-pre-wrap text-left">
                      {interaction.response_message}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="grow" />
        <TraceLink traceID={interaction.trace_id} />
        {!!interaction.latency_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {interaction.latency_seconds.toFixed(2)}s
          </div>
        )}
        {!!interaction.overall_cost_cents && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(interaction.overall_cost_cents / 100.0).toFixed(2)}
          </div>
        )}
        <Icon
          id={expanded ? IconEnum.Collapse : IconEnum.Expand}
          className="text-slate-600 group-hover:text-slate-900 transition"
        />
      </Button>
      {/* Expandable Section */}
      {expanded &&
        (spanDataLoading ? <Loader /> : <AISpanTrace spans={spans} />)}
    </div>
  );
};
