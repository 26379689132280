import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { TemplatedTextDisplayStyle } from "@incident-shared/forms/v1/TemplatedText";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { PrimaryGatedButtonWithDropdown } from "@incident-shared/gates/GatedButton/PrimaryGatedButtonWithDropdown";
import { marshallTextDocumentPayload } from "@incident-shared/incident-forms";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenuItem,
  LoadingWrapper,
} from "@incident-ui";
import { isEmpty, isEqual } from "lodash";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentSummarySuggestion,
  IncidentsUpdateSummaryRequestBody,
  IncidentsUpdateSummaryRequestBodySourceEnum,
  TextDocument,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../../contexts/IdentityContext";

type SummaryEditorProps = {
  alignButtons?: "left" | "right";
  incidentId: string;
  summary?: TextDocument;
  suggestion?: IncidentSummarySuggestion;
  onCancel: () => void;
  onSave: () => void;
};

export const SummaryEditor = ({
  incidentId,
  summary,
  suggestion,
  alignButtons = "left",
  onCancel,
  onSave,
}: SummaryEditorProps) => {
  const formMethods = useForm<IncidentsUpdateSummaryRequestBody>({
    defaultValues: {
      summary: suggestion ? suggestion.suggestion : summary,
      notify_slack_channel: false,
    },
  });

  const { reset, handleSubmit, setError } = formMethods;

  const onSuccess = () => {
    reset();
    onSave();
  };

  const {
    trigger: onSubmitSuggestion,
    isMutating: savingSuggestion,
    genericError: suggestionError,
    fieldErrors: _suggestionFieldErrors, // Destructure this so genericError works correctly
  } = useAPIMutation(
    "incidentsShow",
    { id: incidentId },
    async (apiClient, data: IncidentsUpdateSummaryRequestBody) => {
      if (data.summary && suggestion) {
        await apiClient.incidentsUpdateSummaryFromSuggestion({
          updateSummaryFromSuggestionRequestBody: {
            summary: data.summary,
            notify_channel: data.notify_slack_channel,
            incident_summary_suggestion_id: suggestion.id,
          },
          id: incidentId,
        });
      }
    },
    {
      setError,
      onSuccess,
    },
  );

  const {
    trigger: onSubmitSummary,
    isMutating: savingSummary,
    genericError: summaryError,
    fieldErrors: _summaryFieldErrors, // Destructure this so genericError works correctly
  } = useAPIMutation(
    "incidentsShow",
    { id: incidentId },
    async (apiClient, data: IncidentsUpdateSummaryRequestBody) => {
      if (data.summary) {
        await apiClient.incidentsUpdateSummary({
          updateSummaryRequestBody: {
            summary: marshallTextDocumentPayload(data.summary),
            notify_slack_channel: data.notify_slack_channel,
            source: IncidentsUpdateSummaryRequestBodySourceEnum.Human,
          },
          id: incidentId,
        });
      }
    },
    {
      setError,
      onSuccess: onSuccess,
    },
  );

  const onSubmit = useCallback(
    (data: IncidentsUpdateSummaryRequestBody) => {
      if (suggestion) {
        onSubmitSuggestion(data);
      } else {
        onSubmitSummary(data);
      }
    },
    // We don't include onSubmitSuggestion and onSubmitSummary because the implementation
    // of useAPIMutation changes the identity of trigger, so this saves us re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestion],
  );

  const onSubmitAndNotify = useCallback(
    (data: IncidentsUpdateSummaryRequestBody) => {
      onSubmit({ ...data, notify_slack_channel: true });
    },
    [onSubmit],
  );

  const { identity } = useIdentity();
  const channelType = identity.ms_teams_info === undefined ? "Slack" : "Teams";

  // Only enable the save button if an edit has been made
  const summaryChanged = !isEqual(summary, formMethods.getValues("summary"));
  const summaryEmpty = isEmpty(formMethods.getValues("summary.text_node"));

  const saving = savingSuggestion || savingSummary;

  return (
    <LoadingWrapper loading={saving} isTransparent>
      <Form.Root
        formMethods={formMethods}
        aria-live="polite"
        onSubmit={onSubmit}
        genericError={suggestionError || summaryError}
        // deliberately don't pass 'saving' as we only want the loading wrapper around the templatedText input
      >
        <TemplatedTextInputV2
          formMethods={formMethods}
          name="summary.text_node"
          aria-describedby="summary-heading"
          autoFocus
          includeVariables={false}
          format="slack_rich_text_with_headings"
          style={TemplatedTextDisplayStyle.Naked}
          includeExpressions={false}
          placeholder="e.g. Problem: users have reported that they're unable to login..."
          disabled={saving}
          hideErrors
        />
        <FormErrorMessage
          errors={formMethods.formState.errors}
          name="summary.text_node"
        />
        <div
          className={tcx(
            "flex items-start gap-2",
            alignButtons === "right" && "flex-row-reverse",
          )}
        >
          <PrimaryGatedButtonWithDropdown
            analyticsTrackingId="save-summary"
            type="submit"
            size={BadgeSize.Medium}
            disabled={!summaryChanged || summaryEmpty || saving}
            dropdownItems={
              <DropdownMenuItem
                analyticsTrackingId="save-and-notify-slack"
                onSelect={() => handleSubmit(onSubmitAndNotify)()}
                label={`Save and notify ${channelType}`}
              />
            }
            theme={ButtonTheme.Primary}
          >
            Save
          </PrimaryGatedButtonWithDropdown>
          <Button
            analyticsTrackingId="save-summary"
            type="reset"
            size={BadgeSize.Medium}
            disabled={saving}
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form.Root>
    </LoadingWrapper>
  );
};
