import {
  CatalogUpdateTypeRequestBodyColorEnum,
  CatalogUpdateTypeRequestBodyIconEnum as CatalogIconEnum,
} from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { Form } from "src/components/@shared/forms";
import { tcx } from "src/utils/tailwind-classes";

export const CatalogTypeIconPicker = ({
  onColorPick,
  currentColor,
  onIconPick,
  currentIcon,
  disabled = false,
}: {
  onColorPick: (newColor: CatalogUpdateTypeRequestBodyColorEnum) => void;
  currentColor: CatalogUpdateTypeRequestBodyColorEnum;
  onIconPick: (newIcon: CatalogIconEnum) => void;
  currentIcon: CatalogIconEnum;
  disabled?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Form.Label htmlFor="icon" required>
        Icon
      </Form.Label>
      <div className={"shadow border-stroke border rounded-[6px]"}>
        <div className="bg-surface-secondary rounded-t-md p-2 border-stroke border-b">
          <ColorPicker
            onClick={onColorPick}
            currentColor={currentColor}
            disabled={disabled}
          />
        </div>
        <IconPicker
          onClick={onIconPick}
          currentIcon={currentIcon}
          currentColor={currentColor}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const ColorPicker = ({
  onClick,
  currentColor,
  disabled,
}: {
  onClick: (newColor: CatalogUpdateTypeRequestBodyColorEnum) => void;
  currentColor: string;
  disabled: boolean;
}) => {
  const colors = Object.entries(CatalogUpdateTypeRequestBodyColorEnum);

  return (
    <div className="flex justify-center gap-6">
      {colors.map(([colorName, colorVal]) => {
        const colorClass = getColorPalette(colorVal);
        const isSelected = currentColor === colorVal;
        return (
          <div
            key={colorName}
            className={tcx(
              "p-[2px] rounded-full flex items-center justify-center",
              isSelected ? colorClass.preview : "bg-surface-secondary",
            )}
          >
            <div
              className={tcx(
                colorClass.preview,
                "rounded-full w-5 h-5 flex-center group border-2 border-slate-100",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
              )}
              onClick={disabled ? undefined : () => onClick(colorVal)}
            >
              {isSelected ? (
                <Icon
                  id={IconEnum.Tick}
                  size={IconSize.Small}
                  className={"text-white"}
                />
              ) : (
                <Icon
                  id={IconEnum.Tick}
                  size={IconSize.Small}
                  className={
                    "text-white opacity-50 invisible group-hover:visible"
                  }
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const NATIVE_ONLY_ICONS = [
  CatalogIconEnum.Alert,
  CatalogIconEnum.EscalationPath,
  CatalogIconEnum.StatusPage,
];

const IconPicker = ({
  onClick,
  currentIcon,
  currentColor,
  disabled,
}: {
  onClick: (newIcon: CatalogIconEnum) => void;
  currentIcon: string;
  currentColor: CatalogUpdateTypeRequestBodyColorEnum;
  disabled: boolean;
}) => {
  const icons = Object.values(CatalogIconEnum);
  const selectedPalette = getColorPalette(currentColor);
  const unselectedPalette = getColorPalette(ColorPaletteEnum.Slate);

  return (
    <div className="grid grid-cols-6 lg:grid-cols-12 gap-4 justify-items-center px-8 p-4">
      {icons.map((icon) => {
        if (NATIVE_ONLY_ICONS.includes(icon)) {
          return null;
        }
        const isSelected = currentIcon === icon;
        const palette = isSelected ? selectedPalette : unselectedPalette;
        return (
          <Icon
            key={icon}
            id={icon}
            onClick={disabled ? undefined : () => onClick(icon)}
            size={IconSize.Large}
            className={tcx(
              palette.background,
              palette.border,
              palette.icon,
              "inline hover:border-slate-900 rounded p-0.5",
              disabled ? "cursor-not-allowed" : "cursor-pointer",
            )}
          />
        );
      })}
    </div>
  );
};
