import { AIConfig, ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AIPrivacyButton } from "./AIFeatureCard";
import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
  ToggleAIFeatureButton,
} from "./AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "./useAIFeatures";

export const AssistantDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling assistant",
    },
  );
  const enabled = aiConfigResp?.config.assistant_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp || !aiConfigResp.config}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleAIFeatureButton
                enabled={!!enabled}
                disableModalTitle="Disable assistant"
                disableModalContent="This means users will no longer be able to ask the assistant questions from the dashboard"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      assistant_enabled: true,
                    },
                  });
                }}
                onDisable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      assistant_enabled: false,
                    },
                  });
                }}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Powerful dataset"
              description="With custom fields and timestamps you can filter incidents to your team, or calculate TTX metrics."
              icon={IconEnum.Database}
            />
            <AIProductMarketingCard
              title="Insights tailored to you"
              description="See trends in the incidents you lead and how you contribute, based on your past interactions."
              icon={IconEnum.Chart}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
