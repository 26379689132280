import { ConditionGroup, EngineScope } from "@incident-io/api";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

import { tcx } from "../../../../utils/tailwind-classes";

export const AlertRouteConditionsEditor = <TFormType extends FieldValues>({
  name,
  formMethods,
  expressions,
  scope,
  conditionGroups,
  introText,
  allowFilteringByExpression,
}: {
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  expressions: ExpressionFormData[];
  scope: EngineScope;
  conditionGroups: ConditionGroup[];
  introText: string;
  allowFilteringByExpression: boolean;
}): React.ReactElement => {
  return (
    <div
      className={tcx({
        "flex gap-4 p-3 items-center rounded-2 border border-stroke overflow-hidden justify-between":
          conditionGroups.length === 0,
      })}
    >
      {conditionGroups.length === 0 && (
        <div className="flex items-center gap-2 flex-wrap">
          <Icon
            id={IconEnum.Filter}
            size={IconSize.Large}
            className="text-content-tertiary"
          />
          {introText}
        </div>
      )}

      <ConditionGroupsEditorV2
        formMethods={formMethods}
        name={name}
        scope={scope}
        conditionLabel="filter"
        className={"overflow-hidden"}
        allowAllOfACatalogTypeInQueryExpression={false}
        expressions={expressions}
        allowFilteringByExpression={allowFilteringByExpression}
        populatedIntroSentence={<></>}
        wrapperClassName={"max-w-none"}
        boxless={conditionGroups.length === 0}
        hideIntroSentence
      />
    </div>
  );
};
