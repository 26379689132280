import {
  AIStaffUpdateEvaluationLeaderboardRequestBody,
  EvaluationLeaderboard,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const LeaderboardUpdateModal = ({
  leaderboardId,
  leaderboard,
  onClose,
}: {
  leaderboardId: string;
  leaderboard: EvaluationLeaderboard;
  onClose: () => void;
}) => {
  const formMethods = useForm<AIStaffUpdateEvaluationLeaderboardRequestBody>({
    defaultValues: {
      name: leaderboard.name,
    },
  });

  const { trigger: onSubmit, isMutating: isUpdating } = useMutationV2(
    async (apiClient, data: AIStaffUpdateEvaluationLeaderboardRequestBody) => {
      return await apiClient.aIStaffUpdateEvaluationLeaderboard({
        id: leaderboardId,
        updateEvaluationLeaderboardRequestBody: data,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationLeaderboard", {
          id: leaderboardId,
        }),
        cacheKey.all("aIStaffListEvaluationLeaderboards"),
      ],
      showErrorToast: "Failed to update leaderboard",
      showSuccessToast: "Leaderboard updated successfully",
      setError: formMethods.setError,
      onSuccess: () => {
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Update Leaderboard"
      onClose={onClose}
      analyticsTrackingId="update-leaderboard"
      disableQuickClose
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Update"
          analyticsTrackingId="update-leaderboard-submit"
          saving={isUpdating}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext="Change the name of this leaderboard."
        required
      />
      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="Change the description of this leaderboard."
      />
    </Form.Modal>
  );
};
