import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { GenericErrorMessage, IconEnum, ModalFooter } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  IncidentLifecycle,
  IncidentLifecyclesUpdateNameRequestBody,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { useLifecyclePageDeps } from "../LifecyclePageWrapper";
import { LifecycleEditIncidentTypesSection } from "./LifecycleEditIncidentTypesSection";
import { LifecycleOverview } from "./LifecycleOverview";

export const LifecycleOverviewPage = (): React.ReactElement => {
  const id = useParams<{ id: string }>().id as string;
  const { statuses, timestamps, isLoading } = useLifecyclePageDeps();
  const [showRenameModal, setShowRenameModal] = useState(false);

  const {
    data: { incident_lifecycles: lifecycles },
    isLoading: lifecyclesLoading,
    error: lifecyclesError,
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  const error = lifecyclesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (isLoading || lifecyclesLoading) {
    return <FullPageLoader />;
  }

  const lifecycle = lifecycles.find((x) => x.id === id);
  if (!lifecycle) {
    return <GenericErrorMessage description={"Lifecycle not found"} />;
  }

  // We want to filter out statuses that are not part of this lifecycle
  // However Post-inc flows and core statuses also need to be included
  // they will not have a lifecycle ID set.
  const filteredStatuses = statuses.filter(
    (status) =>
      status.incident_lifecycle_id === undefined ||
      status.incident_lifecycle_id === lifecycle.id,
  );

  return (
    <>
      <SecondaryNavSubPageWrapper
        title={`Configure lifecycle: ${lifecycle.name}`}
        onEditTitle={() => setShowRenameModal(true)}
        backHref={"/settings/lifecycle"}
        icon={IconEnum.Status}
        crumbs={[
          {
            title: "Lifecycle",
            to: "/settings/lifecycle",
          },
        ]}
      >
        <div className="space-y-4">
          <LifecycleEditIncidentTypesSection lifecycle={lifecycle} />
          <LifecycleOverview
            isOnlyLifecycle={false}
            coreStatuses={filteredStatuses}
            timestamps={timestamps}
            lifecycle={lifecycle}
          />
        </div>
      </SecondaryNavSubPageWrapper>
      {showRenameModal && (
        <RenameLifecycleModal
          lifecycle={lifecycle}
          onClose={() => setShowRenameModal(false)}
        />
      )}
    </>
  );
};

const RenameLifecycleModal = ({
  lifecycle,
  onClose,
}: {
  lifecycle: IncidentLifecycle;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<IncidentLifecyclesUpdateNameRequestBody>({
    defaultValues: { name: lifecycle.name },
  });

  const refreshLifecycleList = useRevalidate(["incidentLifecyclesList"]);
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentLifecyclesList",
    undefined,
    async (apiClient, args: IncidentLifecyclesUpdateNameRequestBody) => {
      await apiClient.incidentLifecyclesUpdateName({
        updateNameRequestBody: args,
        id: lifecycle.id,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        refreshLifecycleList();
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename lifecycle"
      analyticsTrackingId="rename-incident-lifecycle"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
};
