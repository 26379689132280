/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageContentAtlassianWidgetStatusResponseBodyPage,
    StatusPageContentAtlassianWidgetStatusResponseBodyPageFromJSON,
    StatusPageContentAtlassianWidgetStatusResponseBodyPageFromJSONTyped,
    StatusPageContentAtlassianWidgetStatusResponseBodyPageToJSON,
} from './StatusPageContentAtlassianWidgetStatusResponseBodyPage';
import {
    StatusPageContentAtlassianWidgetStatusResponseBodyStatus,
    StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSON,
    StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSONTyped,
    StatusPageContentAtlassianWidgetStatusResponseBodyStatusToJSON,
} from './StatusPageContentAtlassianWidgetStatusResponseBodyStatus';

/**
 * 
 * @export
 * @interface StatusPageContentAtlassianWidgetStatusResponseBody
 */
export interface StatusPageContentAtlassianWidgetStatusResponseBody {
    /**
     * 
     * @type {StatusPageContentAtlassianWidgetStatusResponseBodyPage}
     * @memberof StatusPageContentAtlassianWidgetStatusResponseBody
     */
    page: StatusPageContentAtlassianWidgetStatusResponseBodyPage;
    /**
     * 
     * @type {StatusPageContentAtlassianWidgetStatusResponseBodyStatus}
     * @memberof StatusPageContentAtlassianWidgetStatusResponseBody
     */
    status: StatusPageContentAtlassianWidgetStatusResponseBodyStatus;
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyFromJSON(json: any): StatusPageContentAtlassianWidgetStatusResponseBody {
    return StatusPageContentAtlassianWidgetStatusResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentAtlassianWidgetStatusResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': StatusPageContentAtlassianWidgetStatusResponseBodyPageFromJSON(json['page']),
        'status': StatusPageContentAtlassianWidgetStatusResponseBodyStatusFromJSON(json['status']),
    };
}

export function StatusPageContentAtlassianWidgetStatusResponseBodyToJSON(value?: StatusPageContentAtlassianWidgetStatusResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': StatusPageContentAtlassianWidgetStatusResponseBodyPageToJSON(value.page),
        'status': StatusPageContentAtlassianWidgetStatusResponseBodyStatusToJSON(value.status),
    };
}

