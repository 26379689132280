/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsIntercomSetStatusPageRequestBody
 */
export interface IntegrationsIntercomSetStatusPageRequestBody {
    /**
     * The status page to display in Intercom
     * @type {string}
     * @memberof IntegrationsIntercomSetStatusPageRequestBody
     */
    selected_status_page: string;
}

export function IntegrationsIntercomSetStatusPageRequestBodyFromJSON(json: any): IntegrationsIntercomSetStatusPageRequestBody {
    return IntegrationsIntercomSetStatusPageRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsIntercomSetStatusPageRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsIntercomSetStatusPageRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected_status_page': json['selected_status_page'],
    };
}

export function IntegrationsIntercomSetStatusPageRequestBodyToJSON(value?: IntegrationsIntercomSetStatusPageRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected_status_page': value.selected_status_page,
    };
}

