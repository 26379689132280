import { AIConfig, ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AIPrivacyButton } from "../../ai/AIFeatureCard";
import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
  ToggleAIFeatureButton,
} from "../../ai/AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "../../ai/useAIFeatures";

export const SuggestedFollowUpsDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling suggested follow-ups",
    },
  );
  const enabled = aiConfigResp?.config.suggest_followups_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp || !aiConfigResp.config}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleAIFeatureButton
                enabled={!!enabled}
                disableModalTitle="Disable suggested follow-ups"
                disableModalContent="This means you'll no longer see suggested follow-ups in your incident channels or the dashboard"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      suggest_followups_enabled: true,
                    },
                  });
                }}
                onDisable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      suggest_followups_enabled: false,
                    },
                  });
                }}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Suggest follow-ups"
              description="Suggests follow-ups that you might have missed during the incident"
              icon={IconEnum.FollowUps}
            />
            <AIProductMarketingCard
              title="Available in Slack"
              description="We'll send a thread to the incident channel after an incident is resolved"
              icon={IconEnum.SlackGreyscale}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
