import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  ScopeNameEnum,
  WorkflowSlim,
} from "src/contexts/ClientContext";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useMutationV2 } from "src/utils/swr";

export const RunWorkflowModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: { workflows },
    isLoading: workflowsLoading,
    error: workflowsError,
  } = useAPI("workflowsListWorkflows", {}, { fallbackData: { workflows: [] } });

  const params = useQueryParams();

  const defaultWorkflowID = params.get("workflow_id");

  if (workflowsLoading) {
    return <LoadingModal onClose={onClose} />;
  }
  if (workflowsError) {
    return <ErrorModal error={workflowsError} onClose={onClose} />;
  }

  const manualWorkflows = workflows.filter((w) => w.trigger.name === "manual");

  return (
    <IncidentRunWorkflowForm
      onClose={onClose}
      incident={incident}
      manualWorkflows={manualWorkflows}
      defaultWorkflowID={defaultWorkflowID || undefined}
    />
  );
};

type FormData = {
  workflow_id: string;
};

const IncidentRunWorkflowForm = ({
  onClose,
  incident,
  manualWorkflows,
  defaultWorkflowID,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
  manualWorkflows: WorkflowSlim[];
  defaultWorkflowID?: string;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: { workflow_id: defaultWorkflowID },
  });
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();
  const selectedWorkflowID = formMethods.watch("workflow_id");
  const selectedWorkflow = manualWorkflows.find(
    (w) => w.id === selectedWorkflowID,
  );

  const {
    trigger: enqueueWorkflow,
    isMutating: isEnqueueing,
    genericError: genericError,
  } = useMutationV2(
    async (apiClient, { workflow_id }: { workflow_id: string }) => {
      await apiClient.workflowsEnqueueManualWorkflow({
        enqueueManualWorkflowRequestBody: {
          incident_id: incident.id,
          workflow_id,
        },
      });
    },
    {
      showSuccessToast: "Running workflow",
      invalidate: [],
    },
  );

  if (manualWorkflows.length === 0) {
    return (
      <Modal
        onClose={onClose}
        isOpen
        title="Run a workflow"
        analyticsTrackingId="run-manual-workflow"
      >
        <ModalContent>
          <EmptyState
            icon={IconEnum.Workflows}
            title="No workflows"
            content="You haven't created any manually triggered workflow yet."
            cta={
              isWithinTeamsTab ? null : (
                <GatedButton
                  analyticsTrackingId="create-manual-workflow-from-incident"
                  requiredScope={ScopeNameEnum.WorkflowsCreate}
                  href="/workflows/create?trigger=manual"
                  theme={ButtonTheme.Primary}
                >
                  Create manual workflow
                </GatedButton>
              )
            }
          />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Run a workflow"}
      analyticsTrackingId="run-workflow"
      disableQuickClose
      onClose={onClose}
      onSubmit={enqueueWorkflow}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={"Run workflow"}
          analyticsTrackingId="run-workflow-submit"
          saving={isEnqueueing}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="workflow_id"
        label="Workflow"
        options={manualWorkflows.map((w) => ({ label: w.name, value: w.id }))}
      />
      {selectedWorkflow && (
        <div className="bg-surface-secondary rounded-2 p-4 flex flex-col gap-2">
          {selectedWorkflow.steps.map((step, index) => (
            <div key={index} className="flex flow-row gap-1 items-center">
              <Icon
                id={IconEnum.ArrowRight}
                className="text-content-tertiary"
              />
              <span>{step.label}</span>
            </div>
          ))}
        </div>
      )}
    </Form.Modal>
  );
};
