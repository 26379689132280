/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmbeddedCatalogEntry,
    EmbeddedCatalogEntryFromJSON,
    EmbeddedCatalogEntryFromJSONTyped,
    EmbeddedCatalogEntryToJSON,
} from './EmbeddedCatalogEntry';
import {
    ScheduleConfigPayload,
    ScheduleConfigPayloadFromJSON,
    ScheduleConfigPayloadFromJSONTyped,
    ScheduleConfigPayloadToJSON,
} from './ScheduleConfigPayload';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ExternalSchedule
 */
export interface ExternalSchedule {
    /**
     * Configuration in the external provider's format
     * @type {string}
     * @memberof ExternalSchedule
     */
    config?: string;
    /**
     * If we've created a native schedule from this external one, this is the ID of the native schedule
     * @type {string}
     * @memberof ExternalSchedule
     */
    created_native_schedule_id?: string;
    /**
     * ID of the pay config connected with this schedule
     * @type {string}
     * @memberof ExternalSchedule
     */
    default_pay_config_id?: string;
    /**
     * If set, the schedule description synced from the external provider
     * @type {string}
     * @memberof ExternalSchedule
     */
    description?: string;
    /**
     * Name of external provider, e.g. pagerduty
     * @type {string}
     * @memberof ExternalSchedule
     */
    external_provider: ExternalScheduleExternalProviderEnum;
    /**
     * In the external provider, this uniquely identifies the schedule
     * @type {string}
     * @memberof ExternalSchedule
     */
    external_provider_id: string;
    /**
     * Unique ID of this external schedule in incident.io
     * @type {string}
     * @memberof ExternalSchedule
     */
    id: string;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof ExternalSchedule
     */
    name: string;
    /**
     * 
     * @type {ScheduleConfigPayload}
     * @memberof ExternalSchedule
     */
    native_config?: ScheduleConfigPayload;
    /**
     * If we can't create a native schedule from this external one, why not?
     * @type {string}
     * @memberof ExternalSchedule
     */
    native_config_unavailable_reason?: ExternalScheduleNativeConfigUnavailableReasonEnum;
    /**
     * The external-provider IDs of every person on this schedule
     * @type {Array<string>}
     * @memberof ExternalSchedule
     */
    participant_external_ids?: Array<string>;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof ExternalSchedule
     */
    timezone: string;
    /**
     * Users that we couldn't resolve in our system
     * @type {Array<EmbeddedCatalogEntry>}
     * @memberof ExternalSchedule
     */
    unresolvable_external_users?: Array<EmbeddedCatalogEntry>;
    /**
     * Users that need an on-call seat to be placed on this path
     * @type {Array<User>}
     * @memberof ExternalSchedule
     */
    users_needing_upgrades?: Array<User>;
}

/**
* @export
* @enum {string}
*/
export enum ExternalScheduleExternalProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}/**
* @export
* @enum {string}
*/
export enum ExternalScheduleNativeConfigUnavailableReasonEnum {
    UnsupportedRestrictions = 'unsupported_restrictions',
    UnsupportedInterval = 'unsupported_interval',
    EndsInFuture = 'ends_in_future',
    NoUsableLayers = 'no_usable_layers',
    NoConfigSynced = 'no_config_synced',
    UnsupportedParticipants = 'unsupported_participants'
}

export function ExternalScheduleFromJSON(json: any): ExternalSchedule {
    return ExternalScheduleFromJSONTyped(json, false);
}

export function ExternalScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : json['config'],
        'created_native_schedule_id': !exists(json, 'created_native_schedule_id') ? undefined : json['created_native_schedule_id'],
        'default_pay_config_id': !exists(json, 'default_pay_config_id') ? undefined : json['default_pay_config_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'external_provider': json['external_provider'],
        'external_provider_id': json['external_provider_id'],
        'id': json['id'],
        'name': json['name'],
        'native_config': !exists(json, 'native_config') ? undefined : ScheduleConfigPayloadFromJSON(json['native_config']),
        'native_config_unavailable_reason': !exists(json, 'native_config_unavailable_reason') ? undefined : json['native_config_unavailable_reason'],
        'participant_external_ids': !exists(json, 'participant_external_ids') ? undefined : json['participant_external_ids'],
        'timezone': json['timezone'],
        'unresolvable_external_users': !exists(json, 'unresolvable_external_users') ? undefined : ((json['unresolvable_external_users'] as Array<any>).map(EmbeddedCatalogEntryFromJSON)),
        'users_needing_upgrades': !exists(json, 'users_needing_upgrades') ? undefined : ((json['users_needing_upgrades'] as Array<any>).map(UserFromJSON)),
    };
}

export function ExternalScheduleToJSON(value?: ExternalSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': value.config,
        'created_native_schedule_id': value.created_native_schedule_id,
        'default_pay_config_id': value.default_pay_config_id,
        'description': value.description,
        'external_provider': value.external_provider,
        'external_provider_id': value.external_provider_id,
        'id': value.id,
        'name': value.name,
        'native_config': ScheduleConfigPayloadToJSON(value.native_config),
        'native_config_unavailable_reason': value.native_config_unavailable_reason,
        'participant_external_ids': value.participant_external_ids,
        'timezone': value.timezone,
        'unresolvable_external_users': value.unresolvable_external_users === undefined ? undefined : ((value.unresolvable_external_users as Array<any>).map(EmbeddedCatalogEntryToJSON)),
        'users_needing_upgrades': value.users_needing_upgrades === undefined ? undefined : ((value.users_needing_upgrades as Array<any>).map(UserToJSON)),
    };
}

