/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteCustomFieldBindingPayload,
    AlertRouteCustomFieldBindingPayloadFromJSON,
    AlertRouteCustomFieldBindingPayloadFromJSONTyped,
    AlertRouteCustomFieldBindingPayloadToJSON,
} from './AlertRouteCustomFieldBindingPayload';
import {
    AlertRouteSeverityBindingPayload,
    AlertRouteSeverityBindingPayloadFromJSON,
    AlertRouteSeverityBindingPayloadFromJSONTyped,
    AlertRouteSeverityBindingPayloadToJSON,
} from './AlertRouteSeverityBindingPayload';
import {
    AlertRouteTemplateBindingPayload,
    AlertRouteTemplateBindingPayloadFromJSON,
    AlertRouteTemplateBindingPayloadFromJSONTyped,
    AlertRouteTemplateBindingPayloadToJSON,
} from './AlertRouteTemplateBindingPayload';
import {
    AutoGeneratedTemplateBindingPayload,
    AutoGeneratedTemplateBindingPayloadFromJSON,
    AutoGeneratedTemplateBindingPayloadFromJSONTyped,
    AutoGeneratedTemplateBindingPayloadToJSON,
} from './AutoGeneratedTemplateBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteIncidentTemplatePayload
 */
export interface AlertRouteIncidentTemplatePayload {
    /**
     * Custom fields configuration
     * @type {Array<AlertRouteCustomFieldBindingPayload>}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    custom_fields?: Array<AlertRouteCustomFieldBindingPayload>;
    /**
     * 
     * @type {AlertRouteTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    incident_mode?: AlertRouteTemplateBindingPayload;
    /**
     * 
     * @type {AlertRouteTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    incident_type?: AlertRouteTemplateBindingPayload;
    /**
     * 
     * @type {AutoGeneratedTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    name?: AutoGeneratedTemplateBindingPayload;
    /**
     * 
     * @type {AlertRouteSeverityBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    severity?: AlertRouteSeverityBindingPayload;
    /**
     * 
     * @type {AlertRouteTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    start_in_triage?: AlertRouteTemplateBindingPayload;
    /**
     * 
     * @type {AutoGeneratedTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    summary?: AutoGeneratedTemplateBindingPayload;
    /**
     * 
     * @type {AlertRouteTemplateBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    workspace?: AlertRouteTemplateBindingPayload;
}

export function AlertRouteIncidentTemplatePayloadFromJSON(json: any): AlertRouteIncidentTemplatePayload {
    return AlertRouteIncidentTemplatePayloadFromJSONTyped(json, false);
}

export function AlertRouteIncidentTemplatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteIncidentTemplatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_fields': !exists(json, 'custom_fields') ? undefined : ((json['custom_fields'] as Array<any>).map(AlertRouteCustomFieldBindingPayloadFromJSON)),
        'incident_mode': !exists(json, 'incident_mode') ? undefined : AlertRouteTemplateBindingPayloadFromJSON(json['incident_mode']),
        'incident_type': !exists(json, 'incident_type') ? undefined : AlertRouteTemplateBindingPayloadFromJSON(json['incident_type']),
        'name': !exists(json, 'name') ? undefined : AutoGeneratedTemplateBindingPayloadFromJSON(json['name']),
        'severity': !exists(json, 'severity') ? undefined : AlertRouteSeverityBindingPayloadFromJSON(json['severity']),
        'start_in_triage': !exists(json, 'start_in_triage') ? undefined : AlertRouteTemplateBindingPayloadFromJSON(json['start_in_triage']),
        'summary': !exists(json, 'summary') ? undefined : AutoGeneratedTemplateBindingPayloadFromJSON(json['summary']),
        'workspace': !exists(json, 'workspace') ? undefined : AlertRouteTemplateBindingPayloadFromJSON(json['workspace']),
    };
}

export function AlertRouteIncidentTemplatePayloadToJSON(value?: AlertRouteIncidentTemplatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_fields': value.custom_fields === undefined ? undefined : ((value.custom_fields as Array<any>).map(AlertRouteCustomFieldBindingPayloadToJSON)),
        'incident_mode': AlertRouteTemplateBindingPayloadToJSON(value.incident_mode),
        'incident_type': AlertRouteTemplateBindingPayloadToJSON(value.incident_type),
        'name': AutoGeneratedTemplateBindingPayloadToJSON(value.name),
        'severity': AlertRouteSeverityBindingPayloadToJSON(value.severity),
        'start_in_triage': AlertRouteTemplateBindingPayloadToJSON(value.start_in_triage),
        'summary': AutoGeneratedTemplateBindingPayloadToJSON(value.summary),
        'workspace': AlertRouteTemplateBindingPayloadToJSON(value.workspace),
    };
}

