import { EngineParamBindingPayload } from "@incident-io/api";

export const createEngineParamBindingPayload = (
  escalationPathId: string,
  isArray: boolean,
): EngineParamBindingPayload => {
  if (isArray) {
    // It's worth noting that this would overwrite existing any array values, which is why we should
    // disallow linking an escalation path that's already linked to an entry, regardless of the
    // attribute type.
    // Eventually if we loaded in catalog entry information, we could be smarter.
    return {
      array_value: [{ literal: escalationPathId }],
    };
  }
  return {
    value: {
      literal: escalationPathId,
    },
  };
};
