/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotSearchWorking,
    CopilotSearchWorkingFromJSON,
    CopilotSearchWorkingFromJSONTyped,
    CopilotSearchWorkingToJSON,
} from './CopilotSearchWorking';

/**
 * 
 * @export
 * @interface CopilotSearch
 */
export interface CopilotSearch {
    /**
     * The items that our search found
     * @type {object}
     * @memberof CopilotSearch
     */
    answer: object;
    /**
     * ID of the copilot thread (if there is one)
     * @type {string}
     * @memberof CopilotSearch
     */
    copilot_thread_id?: string;
    /**
     * Cost of the search operation in cents
     * @type {number}
     * @memberof CopilotSearch
     */
    cost_cents: number;
    /**
     * When the ground truth was created
     * @type {Date}
     * @memberof CopilotSearch
     */
    created_at: Date;
    /**
     * Timestamp to limit the search to
     * @type {Date}
     * @memberof CopilotSearch
     */
    cutoff: Date;
    /**
     * Duration of the search operation in seconds
     * @type {number}
     * @memberof CopilotSearch
     */
    duration_seconds: number;
    /**
     * Error message if the search failed with an error
     * @type {string}
     * @memberof CopilotSearch
     */
    error_message?: string;
    /**
     * ID of the ground truth
     * @type {string}
     * @memberof CopilotSearch
     */
    id: string;
    /**
     * ID of the incident
     * @type {string}
     * @memberof CopilotSearch
     */
    incident_id?: string;
    /**
     * Search query question
     * @type {string}
     * @memberof CopilotSearch
     */
    question: string;
    /**
     * Type of question (code_changes, etc)
     * @type {string}
     * @memberof CopilotSearch
     */
    question_type: string;
    /**
     * 
     * @type {CopilotSearchWorking}
     * @memberof CopilotSearch
     */
    working: CopilotSearchWorking;
}

export function CopilotSearchFromJSON(json: any): CopilotSearch {
    return CopilotSearchFromJSONTyped(json, false);
}

export function CopilotSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': json['answer'],
        'copilot_thread_id': !exists(json, 'copilot_thread_id') ? undefined : json['copilot_thread_id'],
        'cost_cents': json['cost_cents'],
        'created_at': (new Date(json['created_at'])),
        'cutoff': (new Date(json['cutoff'])),
        'duration_seconds': json['duration_seconds'],
        'error_message': !exists(json, 'error_message') ? undefined : json['error_message'],
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'question': json['question'],
        'question_type': json['question_type'],
        'working': CopilotSearchWorkingFromJSON(json['working']),
    };
}

export function CopilotSearchToJSON(value?: CopilotSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': value.answer,
        'copilot_thread_id': value.copilot_thread_id,
        'cost_cents': value.cost_cents,
        'created_at': (value.created_at.toISOString()),
        'cutoff': (value.cutoff.toISOString()),
        'duration_seconds': value.duration_seconds,
        'error_message': value.error_message,
        'id': value.id,
        'incident_id': value.incident_id,
        'question': value.question,
        'question_type': value.question_type,
        'working': CopilotSearchWorkingToJSON(value.working),
    };
}

