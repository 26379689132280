/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SettingsEnableIncidentTypesResponseBody,
    SettingsEnableIncidentTypesResponseBodyFromJSON,
    SettingsEnableIncidentTypesResponseBodyToJSON,
    SettingsGetSlackEnterpriseSettingsResponseBody,
    SettingsGetSlackEnterpriseSettingsResponseBodyFromJSON,
    SettingsGetSlackEnterpriseSettingsResponseBodyToJSON,
    SettingsShowResponseBody,
    SettingsShowResponseBodyFromJSON,
    SettingsShowResponseBodyToJSON,
    SettingsSupportResponseBody,
    SettingsSupportResponseBodyFromJSON,
    SettingsSupportResponseBodyToJSON,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyFromJSON,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyToJSON,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBody,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBodyFromJSON,
    SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBodyToJSON,
    SettingsUpdateAutoArchiveIncidentsRequestBody,
    SettingsUpdateAutoArchiveIncidentsRequestBodyFromJSON,
    SettingsUpdateAutoArchiveIncidentsRequestBodyToJSON,
    SettingsUpdateAutoArchiveIncidentsResponseBody,
    SettingsUpdateAutoArchiveIncidentsResponseBodyFromJSON,
    SettingsUpdateAutoArchiveIncidentsResponseBodyToJSON,
    SettingsUpdateAutoCloseIncidentsRequestBody,
    SettingsUpdateAutoCloseIncidentsRequestBodyFromJSON,
    SettingsUpdateAutoCloseIncidentsRequestBodyToJSON,
    SettingsUpdateAutoCloseIncidentsResponseBody,
    SettingsUpdateAutoCloseIncidentsResponseBodyFromJSON,
    SettingsUpdateAutoCloseIncidentsResponseBodyToJSON,
    SettingsUpdateAutoJoinSlackRequestBody,
    SettingsUpdateAutoJoinSlackRequestBodyFromJSON,
    SettingsUpdateAutoJoinSlackRequestBodyToJSON,
    SettingsUpdateAutoJoinSlackResponseBody,
    SettingsUpdateAutoJoinSlackResponseBodyFromJSON,
    SettingsUpdateAutoJoinSlackResponseBodyToJSON,
    SettingsUpdateErrorNotificationsChannelRequestBody,
    SettingsUpdateErrorNotificationsChannelRequestBodyFromJSON,
    SettingsUpdateErrorNotificationsChannelRequestBodyToJSON,
    SettingsUpdateErrorNotificationsChannelResponseBody,
    SettingsUpdateErrorNotificationsChannelResponseBodyFromJSON,
    SettingsUpdateErrorNotificationsChannelResponseBodyToJSON,
    SettingsUpdateFollowUpOwnerRequiredRequestBody,
    SettingsUpdateFollowUpOwnerRequiredRequestBodyFromJSON,
    SettingsUpdateFollowUpOwnerRequiredRequestBodyToJSON,
    SettingsUpdateFollowUpOwnerRequiredResponseBody,
    SettingsUpdateFollowUpOwnerRequiredResponseBodyFromJSON,
    SettingsUpdateFollowUpOwnerRequiredResponseBodyToJSON,
    SettingsUpdateFollowUpPriorityRequiredRequestBody,
    SettingsUpdateFollowUpPriorityRequiredRequestBodyFromJSON,
    SettingsUpdateFollowUpPriorityRequiredRequestBodyToJSON,
    SettingsUpdateFollowUpPriorityRequiredResponseBody,
    SettingsUpdateFollowUpPriorityRequiredResponseBodyFromJSON,
    SettingsUpdateFollowUpPriorityRequiredResponseBodyToJSON,
    SettingsUpdateIncidentChannelRequestBody,
    SettingsUpdateIncidentChannelRequestBodyFromJSON,
    SettingsUpdateIncidentChannelRequestBodyToJSON,
    SettingsUpdateIncidentChannelResponseBody,
    SettingsUpdateIncidentChannelResponseBodyFromJSON,
    SettingsUpdateIncidentChannelResponseBodyToJSON,
    SettingsUpdateInitialMessageRequestBody,
    SettingsUpdateInitialMessageRequestBodyFromJSON,
    SettingsUpdateInitialMessageRequestBodyToJSON,
    SettingsUpdateInitialMessageResponseBody,
    SettingsUpdateInitialMessageResponseBodyFromJSON,
    SettingsUpdateInitialMessageResponseBodyToJSON,
    SettingsUpdateManualTriageIncidentsEnabledRequestBody,
    SettingsUpdateManualTriageIncidentsEnabledRequestBodyFromJSON,
    SettingsUpdateManualTriageIncidentsEnabledRequestBodyToJSON,
    SettingsUpdateManualTriageIncidentsEnabledResponseBody,
    SettingsUpdateManualTriageIncidentsEnabledResponseBodyFromJSON,
    SettingsUpdateManualTriageIncidentsEnabledResponseBodyToJSON,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBodyFromJSON,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBodyToJSON,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBody,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBodyFromJSON,
    SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBodyToJSON,
    SettingsUpdatePrivateIncidentsEnabledRequestBody,
    SettingsUpdatePrivateIncidentsEnabledRequestBodyFromJSON,
    SettingsUpdatePrivateIncidentsEnabledRequestBodyToJSON,
    SettingsUpdatePrivateIncidentsEnabledResponseBody,
    SettingsUpdatePrivateIncidentsEnabledResponseBodyFromJSON,
    SettingsUpdatePrivateIncidentsEnabledResponseBodyToJSON,
    SettingsUpdatePrivateStreamsSettingsRequestBody,
    SettingsUpdatePrivateStreamsSettingsRequestBodyFromJSON,
    SettingsUpdatePrivateStreamsSettingsRequestBodyToJSON,
    SettingsUpdatePrivateStreamsSettingsResponseBody,
    SettingsUpdatePrivateStreamsSettingsResponseBodyFromJSON,
    SettingsUpdatePrivateStreamsSettingsResponseBodyToJSON,
    SettingsUpdateShoutoutChannelRequestBody,
    SettingsUpdateShoutoutChannelRequestBodyFromJSON,
    SettingsUpdateShoutoutChannelRequestBodyToJSON,
    SettingsUpdateShoutoutChannelResponseBody,
    SettingsUpdateShoutoutChannelResponseBodyFromJSON,
    SettingsUpdateShoutoutChannelResponseBodyToJSON,
    SettingsUpdateStoreIncidentChannelMessagesRequestBody,
    SettingsUpdateStoreIncidentChannelMessagesRequestBodyFromJSON,
    SettingsUpdateStoreIncidentChannelMessagesRequestBodyToJSON,
    SettingsUpdateStoreIncidentChannelMessagesResponseBody,
    SettingsUpdateStoreIncidentChannelMessagesResponseBodyFromJSON,
    SettingsUpdateStoreIncidentChannelMessagesResponseBodyToJSON,
} from '../models';

export interface SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequest {
    updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody: SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody;
}

export interface SettingsUpdateAutoArchiveIncidentsRequest {
    updateAutoArchiveIncidentsRequestBody: SettingsUpdateAutoArchiveIncidentsRequestBody;
}

export interface SettingsUpdateAutoCloseIncidentsRequest {
    updateAutoCloseIncidentsRequestBody: SettingsUpdateAutoCloseIncidentsRequestBody;
}

export interface SettingsUpdateAutoJoinSlackRequest {
    updateAutoJoinSlackRequestBody: SettingsUpdateAutoJoinSlackRequestBody;
}

export interface SettingsUpdateErrorNotificationsChannelRequest {
    updateErrorNotificationsChannelRequestBody: SettingsUpdateErrorNotificationsChannelRequestBody;
}

export interface SettingsUpdateFollowUpOwnerRequiredRequest {
    updateFollowUpOwnerRequiredRequestBody: SettingsUpdateFollowUpOwnerRequiredRequestBody;
}

export interface SettingsUpdateFollowUpPriorityRequiredRequest {
    updateFollowUpPriorityRequiredRequestBody: SettingsUpdateFollowUpPriorityRequiredRequestBody;
}

export interface SettingsUpdateIncidentChannelRequest {
    updateIncidentChannelRequestBody: SettingsUpdateIncidentChannelRequestBody;
}

export interface SettingsUpdateInitialMessageRequest {
    updateInitialMessageRequestBody: SettingsUpdateInitialMessageRequestBody;
}

export interface SettingsUpdateManualTriageIncidentsEnabledRequest {
    updateManualTriageIncidentsEnabledRequestBody: SettingsUpdateManualTriageIncidentsEnabledRequestBody;
}

export interface SettingsUpdatePostIncidentNotifyOnTaskOverdueRequest {
    updatePostIncidentNotifyOnTaskOverdueRequestBody: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody;
}

export interface SettingsUpdatePrivateIncidentsEnabledRequest {
    updatePrivateIncidentsEnabledRequestBody: SettingsUpdatePrivateIncidentsEnabledRequestBody;
}

export interface SettingsUpdatePrivateStreamsSettingsRequest {
    updatePrivateStreamsSettingsRequestBody: SettingsUpdatePrivateStreamsSettingsRequestBody;
}

export interface SettingsUpdateShoutoutChannelRequest {
    updateShoutoutChannelRequestBody: SettingsUpdateShoutoutChannelRequestBody;
}

export interface SettingsUpdateStoreIncidentChannelMessagesRequest {
    updateStoreIncidentChannelMessagesRequestBody: SettingsUpdateStoreIncidentChannelMessagesRequestBody;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Enable the Incident Types feature
     * EnableIncidentTypes Settings
     */
    async settingsEnableIncidentTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsEnableIncidentTypesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/self/actions/misc/enable_incident_types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsEnableIncidentTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enable the Incident Types feature
     * EnableIncidentTypes Settings
     */
    async settingsEnableIncidentTypes(initOverrides?: RequestInit): Promise<SettingsEnableIncidentTypesResponseBody> {
        const response = await this.settingsEnableIncidentTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Slack Enterprise settings
     * GetSlackEnterpriseSettings Settings
     */
    async settingsGetSlackEnterpriseSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsGetSlackEnterpriseSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/self/actions/misc/slack_enterprise_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsGetSlackEnterpriseSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Slack Enterprise settings
     * GetSlackEnterpriseSettings Settings
     */
    async settingsGetSlackEnterpriseSettings(initOverrides?: RequestInit): Promise<SettingsGetSlackEnterpriseSettingsResponseBody> {
        const response = await this.settingsGetSlackEnterpriseSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get settings
     * Show Settings
     */
    async settingsShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get settings
     * Show Settings
     */
    async settingsShow(initOverrides?: RequestInit): Promise<SettingsShowResponseBody> {
        const response = await this.settingsShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get support details
     * Support Settings
     */
    async settingsSupportRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsSupportResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/support`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsSupportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get support details
     * Support Settings
     */
    async settingsSupport(initOverrides?: RequestInit): Promise<SettingsSupportResponseBody> {
        const response = await this.settingsSupportRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the value used to decide how many minutes to wait before archiving a declined, cancelled or merged incident
     * UpdateAutoArchiveCancelledMergedDeclinedIncidents Settings
     */
    async settingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRaw(requestParameters: SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBody>> {
        if (requestParameters.updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody === null || requestParameters.updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody','Required parameter requestParameters.updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody was null or undefined when calling settingsUpdateAutoArchiveCancelledMergedDeclinedIncidents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/auto_archive_cancelled_merged_declined_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequestBodyToJSON(requestParameters.updateAutoArchiveCancelledMergedDeclinedIncidentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the value used to decide how many minutes to wait before archiving a declined, cancelled or merged incident
     * UpdateAutoArchiveCancelledMergedDeclinedIncidents Settings
     */
    async settingsUpdateAutoArchiveCancelledMergedDeclinedIncidents(requestParameters: SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRequest, initOverrides?: RequestInit): Promise<SettingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsResponseBody> {
        const response = await this.settingsUpdateAutoArchiveCancelledMergedDeclinedIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Auto Archive Incident Settings
     * UpdateAutoArchiveIncidents Settings
     */
    async settingsUpdateAutoArchiveIncidentsRaw(requestParameters: SettingsUpdateAutoArchiveIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateAutoArchiveIncidentsResponseBody>> {
        if (requestParameters.updateAutoArchiveIncidentsRequestBody === null || requestParameters.updateAutoArchiveIncidentsRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoArchiveIncidentsRequestBody','Required parameter requestParameters.updateAutoArchiveIncidentsRequestBody was null or undefined when calling settingsUpdateAutoArchiveIncidents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/auto_archive_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateAutoArchiveIncidentsRequestBodyToJSON(requestParameters.updateAutoArchiveIncidentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateAutoArchiveIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Auto Archive Incident Settings
     * UpdateAutoArchiveIncidents Settings
     */
    async settingsUpdateAutoArchiveIncidents(requestParameters: SettingsUpdateAutoArchiveIncidentsRequest, initOverrides?: RequestInit): Promise<SettingsUpdateAutoArchiveIncidentsResponseBody> {
        const response = await this.settingsUpdateAutoArchiveIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Auto Close Incident Settings
     * UpdateAutoCloseIncidents Settings
     */
    async settingsUpdateAutoCloseIncidentsRaw(requestParameters: SettingsUpdateAutoCloseIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateAutoCloseIncidentsResponseBody>> {
        if (requestParameters.updateAutoCloseIncidentsRequestBody === null || requestParameters.updateAutoCloseIncidentsRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoCloseIncidentsRequestBody','Required parameter requestParameters.updateAutoCloseIncidentsRequestBody was null or undefined when calling settingsUpdateAutoCloseIncidents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/auto_close_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateAutoCloseIncidentsRequestBodyToJSON(requestParameters.updateAutoCloseIncidentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateAutoCloseIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Auto Close Incident Settings
     * UpdateAutoCloseIncidents Settings
     */
    async settingsUpdateAutoCloseIncidents(requestParameters: SettingsUpdateAutoCloseIncidentsRequest, initOverrides?: RequestInit): Promise<SettingsUpdateAutoCloseIncidentsResponseBody> {
        const response = await this.settingsUpdateAutoCloseIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Auto Join Slack Settings
     * UpdateAutoJoinSlack Settings
     */
    async settingsUpdateAutoJoinSlackRaw(requestParameters: SettingsUpdateAutoJoinSlackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateAutoJoinSlackResponseBody>> {
        if (requestParameters.updateAutoJoinSlackRequestBody === null || requestParameters.updateAutoJoinSlackRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoJoinSlackRequestBody','Required parameter requestParameters.updateAutoJoinSlackRequestBody was null or undefined when calling settingsUpdateAutoJoinSlack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/workflows_alpha/auto_join_slack`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateAutoJoinSlackRequestBodyToJSON(requestParameters.updateAutoJoinSlackRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateAutoJoinSlackResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Auto Join Slack Settings
     * UpdateAutoJoinSlack Settings
     */
    async settingsUpdateAutoJoinSlack(requestParameters: SettingsUpdateAutoJoinSlackRequest, initOverrides?: RequestInit): Promise<SettingsUpdateAutoJoinSlackResponseBody> {
        const response = await this.settingsUpdateAutoJoinSlackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update which channel we send error notifications to
     * UpdateErrorNotificationsChannel Settings
     */
    async settingsUpdateErrorNotificationsChannelRaw(requestParameters: SettingsUpdateErrorNotificationsChannelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateErrorNotificationsChannelResponseBody>> {
        if (requestParameters.updateErrorNotificationsChannelRequestBody === null || requestParameters.updateErrorNotificationsChannelRequestBody === undefined) {
            throw new runtime.RequiredError('updateErrorNotificationsChannelRequestBody','Required parameter requestParameters.updateErrorNotificationsChannelRequestBody was null or undefined when calling settingsUpdateErrorNotificationsChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/error_notifications_channel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateErrorNotificationsChannelRequestBodyToJSON(requestParameters.updateErrorNotificationsChannelRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateErrorNotificationsChannelResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update which channel we send error notifications to
     * UpdateErrorNotificationsChannel Settings
     */
    async settingsUpdateErrorNotificationsChannel(requestParameters: SettingsUpdateErrorNotificationsChannelRequest, initOverrides?: RequestInit): Promise<SettingsUpdateErrorNotificationsChannelResponseBody> {
        const response = await this.settingsUpdateErrorNotificationsChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Follow Up Owner required
     * UpdateFollowUpOwnerRequired Settings
     */
    async settingsUpdateFollowUpOwnerRequiredRaw(requestParameters: SettingsUpdateFollowUpOwnerRequiredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateFollowUpOwnerRequiredResponseBody>> {
        if (requestParameters.updateFollowUpOwnerRequiredRequestBody === null || requestParameters.updateFollowUpOwnerRequiredRequestBody === undefined) {
            throw new runtime.RequiredError('updateFollowUpOwnerRequiredRequestBody','Required parameter requestParameters.updateFollowUpOwnerRequiredRequestBody was null or undefined when calling settingsUpdateFollowUpOwnerRequired.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/follow_up_owner_required`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateFollowUpOwnerRequiredRequestBodyToJSON(requestParameters.updateFollowUpOwnerRequiredRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateFollowUpOwnerRequiredResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Follow Up Owner required
     * UpdateFollowUpOwnerRequired Settings
     */
    async settingsUpdateFollowUpOwnerRequired(requestParameters: SettingsUpdateFollowUpOwnerRequiredRequest, initOverrides?: RequestInit): Promise<SettingsUpdateFollowUpOwnerRequiredResponseBody> {
        const response = await this.settingsUpdateFollowUpOwnerRequiredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Follow Up Priority required
     * UpdateFollowUpPriorityRequired Settings
     */
    async settingsUpdateFollowUpPriorityRequiredRaw(requestParameters: SettingsUpdateFollowUpPriorityRequiredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateFollowUpPriorityRequiredResponseBody>> {
        if (requestParameters.updateFollowUpPriorityRequiredRequestBody === null || requestParameters.updateFollowUpPriorityRequiredRequestBody === undefined) {
            throw new runtime.RequiredError('updateFollowUpPriorityRequiredRequestBody','Required parameter requestParameters.updateFollowUpPriorityRequiredRequestBody was null or undefined when calling settingsUpdateFollowUpPriorityRequired.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/follow_up_priority_required`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateFollowUpPriorityRequiredRequestBodyToJSON(requestParameters.updateFollowUpPriorityRequiredRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateFollowUpPriorityRequiredResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Follow Up Priority required
     * UpdateFollowUpPriorityRequired Settings
     */
    async settingsUpdateFollowUpPriorityRequired(requestParameters: SettingsUpdateFollowUpPriorityRequiredRequest, initOverrides?: RequestInit): Promise<SettingsUpdateFollowUpPriorityRequiredResponseBody> {
        const response = await this.settingsUpdateFollowUpPriorityRequiredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Incident Channel Settings
     * UpdateIncidentChannel Settings
     */
    async settingsUpdateIncidentChannelRaw(requestParameters: SettingsUpdateIncidentChannelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateIncidentChannelResponseBody>> {
        if (requestParameters.updateIncidentChannelRequestBody === null || requestParameters.updateIncidentChannelRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentChannelRequestBody','Required parameter requestParameters.updateIncidentChannelRequestBody was null or undefined when calling settingsUpdateIncidentChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/incident_channels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateIncidentChannelRequestBodyToJSON(requestParameters.updateIncidentChannelRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateIncidentChannelResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Incident Channel Settings
     * UpdateIncidentChannel Settings
     */
    async settingsUpdateIncidentChannel(requestParameters: SettingsUpdateIncidentChannelRequest, initOverrides?: RequestInit): Promise<SettingsUpdateIncidentChannelResponseBody> {
        const response = await this.settingsUpdateIncidentChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Initial Message Settings
     * UpdateInitialMessage Settings
     */
    async settingsUpdateInitialMessageRaw(requestParameters: SettingsUpdateInitialMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateInitialMessageResponseBody>> {
        if (requestParameters.updateInitialMessageRequestBody === null || requestParameters.updateInitialMessageRequestBody === undefined) {
            throw new runtime.RequiredError('updateInitialMessageRequestBody','Required parameter requestParameters.updateInitialMessageRequestBody was null or undefined when calling settingsUpdateInitialMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/workflows_alpha/initial_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateInitialMessageRequestBodyToJSON(requestParameters.updateInitialMessageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateInitialMessageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Initial Message Settings
     * UpdateInitialMessage Settings
     */
    async settingsUpdateInitialMessage(requestParameters: SettingsUpdateInitialMessageRequest, initOverrides?: RequestInit): Promise<SettingsUpdateInitialMessageResponseBody> {
        const response = await this.settingsUpdateInitialMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the \'manual triage incidents enabled\' setting
     * UpdateManualTriageIncidentsEnabled Settings
     */
    async settingsUpdateManualTriageIncidentsEnabledRaw(requestParameters: SettingsUpdateManualTriageIncidentsEnabledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateManualTriageIncidentsEnabledResponseBody>> {
        if (requestParameters.updateManualTriageIncidentsEnabledRequestBody === null || requestParameters.updateManualTriageIncidentsEnabledRequestBody === undefined) {
            throw new runtime.RequiredError('updateManualTriageIncidentsEnabledRequestBody','Required parameter requestParameters.updateManualTriageIncidentsEnabledRequestBody was null or undefined when calling settingsUpdateManualTriageIncidentsEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/manual_triage_incidents_enabled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateManualTriageIncidentsEnabledRequestBodyToJSON(requestParameters.updateManualTriageIncidentsEnabledRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateManualTriageIncidentsEnabledResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the \'manual triage incidents enabled\' setting
     * UpdateManualTriageIncidentsEnabled Settings
     */
    async settingsUpdateManualTriageIncidentsEnabled(requestParameters: SettingsUpdateManualTriageIncidentsEnabledRequest, initOverrides?: RequestInit): Promise<SettingsUpdateManualTriageIncidentsEnabledResponseBody> {
        const response = await this.settingsUpdateManualTriageIncidentsEnabledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update whether to notify if a post-incident task is overdue
     * UpdatePostIncidentNotifyOnTaskOverdue Settings
     */
    async settingsUpdatePostIncidentNotifyOnTaskOverdueRaw(requestParameters: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBody>> {
        if (requestParameters.updatePostIncidentNotifyOnTaskOverdueRequestBody === null || requestParameters.updatePostIncidentNotifyOnTaskOverdueRequestBody === undefined) {
            throw new runtime.RequiredError('updatePostIncidentNotifyOnTaskOverdueRequestBody','Required parameter requestParameters.updatePostIncidentNotifyOnTaskOverdueRequestBody was null or undefined when calling settingsUpdatePostIncidentNotifyOnTaskOverdue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/post_incident_notify_on_task_overdue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBodyToJSON(requestParameters.updatePostIncidentNotifyOnTaskOverdueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update whether to notify if a post-incident task is overdue
     * UpdatePostIncidentNotifyOnTaskOverdue Settings
     */
    async settingsUpdatePostIncidentNotifyOnTaskOverdue(requestParameters: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequest, initOverrides?: RequestInit): Promise<SettingsUpdatePostIncidentNotifyOnTaskOverdueResponseBody> {
        const response = await this.settingsUpdatePostIncidentNotifyOnTaskOverdueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Private Incidents Enabled
     * UpdatePrivateIncidentsEnabled Settings
     */
    async settingsUpdatePrivateIncidentsEnabledRaw(requestParameters: SettingsUpdatePrivateIncidentsEnabledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdatePrivateIncidentsEnabledResponseBody>> {
        if (requestParameters.updatePrivateIncidentsEnabledRequestBody === null || requestParameters.updatePrivateIncidentsEnabledRequestBody === undefined) {
            throw new runtime.RequiredError('updatePrivateIncidentsEnabledRequestBody','Required parameter requestParameters.updatePrivateIncidentsEnabledRequestBody was null or undefined when calling settingsUpdatePrivateIncidentsEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/private_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdatePrivateIncidentsEnabledRequestBodyToJSON(requestParameters.updatePrivateIncidentsEnabledRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdatePrivateIncidentsEnabledResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Private Incidents Enabled
     * UpdatePrivateIncidentsEnabled Settings
     */
    async settingsUpdatePrivateIncidentsEnabled(requestParameters: SettingsUpdatePrivateIncidentsEnabledRequest, initOverrides?: RequestInit): Promise<SettingsUpdatePrivateIncidentsEnabledResponseBody> {
        const response = await this.settingsUpdatePrivateIncidentsEnabledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update whether an organisation can create private streams
     * UpdatePrivateStreamsSettings Settings
     */
    async settingsUpdatePrivateStreamsSettingsRaw(requestParameters: SettingsUpdatePrivateStreamsSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdatePrivateStreamsSettingsResponseBody>> {
        if (requestParameters.updatePrivateStreamsSettingsRequestBody === null || requestParameters.updatePrivateStreamsSettingsRequestBody === undefined) {
            throw new runtime.RequiredError('updatePrivateStreamsSettingsRequestBody','Required parameter requestParameters.updatePrivateStreamsSettingsRequestBody was null or undefined when calling settingsUpdatePrivateStreamsSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/private_streams_enabled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdatePrivateStreamsSettingsRequestBodyToJSON(requestParameters.updatePrivateStreamsSettingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdatePrivateStreamsSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update whether an organisation can create private streams
     * UpdatePrivateStreamsSettings Settings
     */
    async settingsUpdatePrivateStreamsSettings(requestParameters: SettingsUpdatePrivateStreamsSettingsRequest, initOverrides?: RequestInit): Promise<SettingsUpdatePrivateStreamsSettingsResponseBody> {
        const response = await this.settingsUpdatePrivateStreamsSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Shoutout Channel Settings
     * UpdateShoutoutChannel Settings
     */
    async settingsUpdateShoutoutChannelRaw(requestParameters: SettingsUpdateShoutoutChannelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateShoutoutChannelResponseBody>> {
        if (requestParameters.updateShoutoutChannelRequestBody === null || requestParameters.updateShoutoutChannelRequestBody === undefined) {
            throw new runtime.RequiredError('updateShoutoutChannelRequestBody','Required parameter requestParameters.updateShoutoutChannelRequestBody was null or undefined when calling settingsUpdateShoutoutChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/shoutout_channel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateShoutoutChannelRequestBodyToJSON(requestParameters.updateShoutoutChannelRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateShoutoutChannelResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Shoutout Channel Settings
     * UpdateShoutoutChannel Settings
     */
    async settingsUpdateShoutoutChannel(requestParameters: SettingsUpdateShoutoutChannelRequest, initOverrides?: RequestInit): Promise<SettingsUpdateShoutoutChannelResponseBody> {
        const response = await this.settingsUpdateShoutoutChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the \'store incident channel messages\' setting
     * UpdateStoreIncidentChannelMessages Settings
     */
    async settingsUpdateStoreIncidentChannelMessagesRaw(requestParameters: SettingsUpdateStoreIncidentChannelMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SettingsUpdateStoreIncidentChannelMessagesResponseBody>> {
        if (requestParameters.updateStoreIncidentChannelMessagesRequestBody === null || requestParameters.updateStoreIncidentChannelMessagesRequestBody === undefined) {
            throw new runtime.RequiredError('updateStoreIncidentChannelMessagesRequestBody','Required parameter requestParameters.updateStoreIncidentChannelMessagesRequestBody was null or undefined when calling settingsUpdateStoreIncidentChannelMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/self/actions/misc/store_incident_channel_messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsUpdateStoreIncidentChannelMessagesRequestBodyToJSON(requestParameters.updateStoreIncidentChannelMessagesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsUpdateStoreIncidentChannelMessagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the \'store incident channel messages\' setting
     * UpdateStoreIncidentChannelMessages Settings
     */
    async settingsUpdateStoreIncidentChannelMessages(requestParameters: SettingsUpdateStoreIncidentChannelMessagesRequest, initOverrides?: RequestInit): Promise<SettingsUpdateStoreIncidentChannelMessagesResponseBody> {
        const response = await this.settingsUpdateStoreIncidentChannelMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
