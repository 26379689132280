import { Logo } from "@incident-io/status-page-ui";
import { useIdentity } from "src/contexts/IdentityContext";

export const Footer = () => {
  const { identity } = useIdentity();

  return (
    <div className="space-y-4 flex flex-col items-center py-4">
      <div className={"flex space-x-1"}>
        <span
          className={
            "flex items-center flex-auto font-normal text-content-tertiary dark:text-slate-500 text-sm text-center"
          }
        >
          Powered by
        </span>
        <a
          href={`https://incident.io/?utm_source=${identity?.organisation_id}&utm_medium=internal-status-pages`}
          target="_blank"
          rel="noreferrer"
        >
          <Logo
            key="logo"
            className={"mt-[-4.5px] text-content-tertiary dark:text-slate-500"}
          />
        </a>
      </div>
    </div>
  );
};
