/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamsGenerateAttributesConfigRequestBody
 */
export interface TeamsGenerateAttributesConfigRequestBody {
    /**
     * ID of the catalog type to convert into a team
     * @type {string}
     * @memberof TeamsGenerateAttributesConfigRequestBody
     */
    catalog_type_id: string;
    /**
     * ID of the existing attribute that will be used to store escalation paths
     * @type {string}
     * @memberof TeamsGenerateAttributesConfigRequestBody
     */
    escalation_paths_attribute_id?: string;
    /**
     * ID of the existing attribute that will be used to store team members
     * @type {string}
     * @memberof TeamsGenerateAttributesConfigRequestBody
     */
    members_attribute_id?: string;
    /**
     * Type of the source of truth for team members
     * @type {string}
     * @memberof TeamsGenerateAttributesConfigRequestBody
     */
    members_source_of_truth?: TeamsGenerateAttributesConfigRequestBodyMembersSourceOfTruthEnum;
}

/**
* @export
* @enum {string}
*/
export enum TeamsGenerateAttributesConfigRequestBodyMembersSourceOfTruthEnum {
    User = 'User',
    SlackUserGroup = 'SlackUserGroup',
    LinearTeam = 'LinearTeam',
    ScimGroup = 'SCIMGroup',
    CortexTeam = 'CortexTeam'
}

export function TeamsGenerateAttributesConfigRequestBodyFromJSON(json: any): TeamsGenerateAttributesConfigRequestBody {
    return TeamsGenerateAttributesConfigRequestBodyFromJSONTyped(json, false);
}

export function TeamsGenerateAttributesConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsGenerateAttributesConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': json['catalog_type_id'],
        'escalation_paths_attribute_id': !exists(json, 'escalation_paths_attribute_id') ? undefined : json['escalation_paths_attribute_id'],
        'members_attribute_id': !exists(json, 'members_attribute_id') ? undefined : json['members_attribute_id'],
        'members_source_of_truth': !exists(json, 'members_source_of_truth') ? undefined : json['members_source_of_truth'],
    };
}

export function TeamsGenerateAttributesConfigRequestBodyToJSON(value?: TeamsGenerateAttributesConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'escalation_paths_attribute_id': value.escalation_paths_attribute_id,
        'members_attribute_id': value.members_attribute_id,
        'members_source_of_truth': value.members_source_of_truth,
    };
}

