import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  ModalFooter,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { CatalogType, ScopeNameEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const CatalogTypeExternallyManagedBanner = ({
  catalogType,
  sourceRepoURL,
}: {
  catalogType: CatalogType;
  sourceRepoURL: string;
}) => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  return (
    <>
      {showDisconnectModal && (
        <CatalogTypeDisconnectModal
          catalogType={catalogType}
          onClose={() => setShowDisconnectModal(false)}
        />
      )}
      <div className="bg-amber-surface">
        <Callout theme={CalloutTheme.Warning} className="max-w-[920px] mx-auto">
          This type is managed{" "}
          <Button
            href={sourceRepoURL}
            openInNewTab
            rel="noreferrer"
            theme={ButtonTheme.Unstyled}
            icon={IconEnum.ExternalLink}
            iconPosition={"right"}
            analyticsTrackingId={null}
            className="underine"
          >
            {`${
              sourceRepoURL.includes("github")
                ? "in GitHub"
                : sourceRepoURL.includes("gitlab")
                ? "in Gitlab"
                : "externally"
            } `}
          </Button>
          , so cannot be edited via the web UI.
          <br />
          If you&rsquo;d like to make a change here, you can manage this{" "}
          <GatedButton
            onClick={() => setShowDisconnectModal(true)}
            analyticsTrackingId={null}
            theme={ButtonTheme.Unstyled}
            className="underline"
            requiredScope={ScopeNameEnum.CatalogTypesEdit}
          >
            in the dashboard
          </GatedButton>{" "}
          instead.
        </Callout>
      </div>
    </>
  );
};

const CatalogTypeDisconnectModal = ({
  catalogType,
  onClose,
}: {
  catalogType: CatalogType;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ confirmed_name: string }>();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "catalogShowType",
    {
      id: catalogType.id,
      includeAttributeDependentResources: true,
    },
    async (apiClient, _data) => {
      await apiClient.catalogManageTypeInDashboard({
        id: catalogType.id,
      });
    },
    {
      onSuccess: onClose,
    },
  );

  const isCatalogImporter =
    !!catalogType.annotations["incident.io/catalog-importer/sync-id"];
  const isTerraform =
    !!catalogType.annotations["incident.io/terraform/version"];

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Are you sure?"}
      analyticsTrackingId={`disconnect-catalog-source-repo`}
      genericError={genericError}
      onClose={onClose}
      onSubmit={onSubmit}
      contentClassName="flex flex-col space-y-6"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          confirmButtonType={"submit"}
          confirmButtonText={"Disconnect"}
          confirmButtonTheme={ButtonTheme.Destroy}
        />
      }
    >
      <div className="flex flex-col gap-3 text-slate-700">
        <p>
          Disconnecting will override this type&rsquo;s connection with{" "}
          {isCatalogImporter
            ? "your catalog-importer configuration"
            : isTerraform
            ? "your Terraform configuration"
            : "the source repository"}
          .
        </p>
        <p>
          Confirm you want to do this by typing the name of the type in the box
          and clicking &lsquo;Disconnect&rsquo;.
        </p>
      </div>
      <InputV2
        formMethods={formMethods}
        name="confirmed_name"
        placeholder={catalogType.name}
        label={`Catalog type name`}
        rules={{
          validate: (value) =>
            value === catalogType.name
              ? undefined
              : `Please input the name of the type`,
        }}
      />
    </Form.Modal>
  );
};
