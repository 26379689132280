/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailablePhoneNumber,
    AvailablePhoneNumberFromJSON,
    AvailablePhoneNumberFromJSONTyped,
    AvailablePhoneNumberToJSON,
} from './AvailablePhoneNumber';

/**
 * 
 * @export
 * @interface StaffListAvailablePhoneNumbersResponseBody
 */
export interface StaffListAvailablePhoneNumbersResponseBody {
    /**
     * The available phone numbers for the call route.
     * @type {Array<AvailablePhoneNumber>}
     * @memberof StaffListAvailablePhoneNumbersResponseBody
     */
    available_phone_numbers: Array<AvailablePhoneNumber>;
}

export function StaffListAvailablePhoneNumbersResponseBodyFromJSON(json: any): StaffListAvailablePhoneNumbersResponseBody {
    return StaffListAvailablePhoneNumbersResponseBodyFromJSONTyped(json, false);
}

export function StaffListAvailablePhoneNumbersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffListAvailablePhoneNumbersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_phone_numbers': ((json['available_phone_numbers'] as Array<any>).map(AvailablePhoneNumberFromJSON)),
    };
}

export function StaffListAvailablePhoneNumbersResponseBodyToJSON(value?: StaffListAvailablePhoneNumbersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_phone_numbers': ((value.available_phone_numbers as Array<any>).map(AvailablePhoneNumberToJSON)),
    };
}

