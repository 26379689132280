/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceSplunkOptions
 */
export interface AlertSourceSplunkOptions {
    /**
     * Whether to use the search name instead of the sid for deduplication
     * @type {boolean}
     * @memberof AlertSourceSplunkOptions
     */
    deduplication_using_search_name?: boolean;
}

export function AlertSourceSplunkOptionsFromJSON(json: any): AlertSourceSplunkOptions {
    return AlertSourceSplunkOptionsFromJSONTyped(json, false);
}

export function AlertSourceSplunkOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceSplunkOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deduplication_using_search_name': !exists(json, 'deduplication_using_search_name') ? undefined : json['deduplication_using_search_name'],
    };
}

export function AlertSourceSplunkOptionsToJSON(value?: AlertSourceSplunkOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deduplication_using_search_name': value.deduplication_using_search_name,
    };
}

