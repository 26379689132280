import { Incident, IncidentVisibilityEnum } from "@incident-io/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export enum AIConfigEnabledFeaturesEnum {
  Assistant = "assistant",
  Default = "default",
  Copilot = "copilot",
  Scribe = "scribe",
  SuggestFollowups = "suggest_followups",
  IncidentSummaries = "incident_summaries",
  IncidentSimilarity = "incident_similarity",
}

// useAIFeatureForIncident is a hook that returns a function that can be used to determine if a
// particular AI feature is enabled for an incident.
export const useAIFeatureForIncident = (incident: Incident) => {
  const canUseForOrg = useAIFeatureForOrg();

  return (featureName: AIConfigEnabledFeaturesEnum) => {
    // Private incidents don't get AI!
    if (incident.visibility === IncidentVisibilityEnum.Private) {
      return false;
    }

    // Check whether the org has the feature enabled
    return canUseForOrg(featureName);
  };
};

// useAIFeatureForOrg is a hook that returns a function that can be used to determine if a
// particular AI feature is enabled for an organisation.
export const useAIFeatureForOrg = () => {
  const { disableAiAssistant } = useFlags();
  const { identity } = useIdentity();
  const { data } = useAPI(
    identity.user_id !== "" ? "aIShowConfig" : null,
    undefined,
  );
  const { data: assistantData } = useAPI(
    identity.user_id !== "" && data?.config.assistant_enabled
      ? "aIShowAIAssistant"
      : null,
    undefined,
  );

  return (featureName: AIConfigEnabledFeaturesEnum) => {
    // Assume no AI if we don't have the config.
    if (!data || !assistantData) {
      return false;
    }

    // Assume no AI if we don't have an identity.
    if (!identity) {
      return false;
    }

    if (!identity.feature_gates.ai_features) {
      // Basic plan doesn't have any AI features
      return false;
    }

    if (featureName === AIConfigEnabledFeaturesEnum.Assistant) {
      if (disableAiAssistant) {
        return false;
      }
      // Pro and Ent have the AI Assistant
      if (!identity.feature_gates.ai_assistant) {
        return false;
      }
      // If the organisation has the feature enabled, and the gate turned on, but doesn't actually
      // have an OpenAI Assistant, then we'll block access.
      if (!assistantData._exists) {
        return false;
      }
    }

    if (data.config.openai_subprocessor_enabled === false) {
      return false;
    }

    switch (featureName) {
      case AIConfigEnabledFeaturesEnum.Assistant:
        return (
          data.config.openai_subprocessor_enabled &&
          data.config.assistant_enabled
        );
      case AIConfigEnabledFeaturesEnum.Copilot:
        return (
          data.config.openai_subprocessor_enabled && data.config.copilot_enabled
        );
      case AIConfigEnabledFeaturesEnum.Scribe:
        return (
          data.config.openai_subprocessor_enabled && data.config.scribe_enabled
        );
      case AIConfigEnabledFeaturesEnum.SuggestFollowups:
        return (
          data.config.openai_subprocessor_enabled &&
          data.config.suggest_followups_enabled
        );
      case AIConfigEnabledFeaturesEnum.IncidentSummaries:
        return (
          data.config.openai_subprocessor_enabled &&
          data.config.incident_summaries_enabled
        );
      case AIConfigEnabledFeaturesEnum.IncidentSimilarity:
        return (
          data.config.openai_subprocessor_enabled &&
          data.config.incident_similarity_enabled
        );
      case AIConfigEnabledFeaturesEnum.Default:
        return data.config.openai_subprocessor_enabled;
      default:
        return false;
    }
  };
};

export const useOrganisationAIAccess = () => {
  const { identity } = useIdentity();
  const { data } = useAPI(identity ? "aIShowConfig" : null, undefined);
  if (!data || !identity) {
    return false;
  }

  return (
    data?.config.openai_subprocessor_enabled &&
    !!identity?.feature_gates.ai_features
  );
};
