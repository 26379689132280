import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Loader,
  Txt,
} from "@incident-ui";
import { Drawer } from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

export const AttachSubscriptionButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.AttachSubscription,
  );

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        {...disabledProps}
      >
        💳 Attach subscription
      </GatedButton>
      <AnimatePresence>
        {isOpen ? (
          <AttachSubscriptionForm
            data={data}
            onClose={() => setIsOpen(false)}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

const AttachSubscriptionForm = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ stripe_subscription_id: string }>();

  const { data: featureGatesData, isLoading } = useAPI("staffGetFeatureGates", {
    organisationSlug: data.organisation_slug,
  });

  const { trigger, isMutating, genericError } = useMutationV2(
    async (apiClient, formData) => {
      await apiClient.staffApplySubscription({
        organisationSlug: data.organisation_slug,
        applySubscriptionRequestBody: formData,
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: data.organisation_slug,
        }),
      ],

      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  if (isLoading || !featureGatesData) {
    return <Loader />;
  }

  return (
    <Drawer onClose={onClose} className="p-6 space-y-6" width="medium">
      <Form.Root
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        genericError={genericError}
      >
        <div className="space-y-2">
          <h3 className="font-medium">Attach subscription</h3>
          <Form.Helptext>
            For more info, see the{" "}
            <Button
              theme={ButtonTheme.Link}
              href="https://www.notion.so/incidentio/Setting-up-subscriptions-in-Stripe-69aba09f3d524fc29b72e04fc0df106b?pvs=4"
              analyticsTrackingId={null}
              openInNewTab
            >
              guide in Notion
            </Button>
            .
          </Form.Helptext>
          {!featureGatesData.gates_match_plan && (
            <Callout theme={CalloutTheme.Warning}>
              This organisation&apos;s feature gates{" "}
              <b className="font-semibold">have been changed</b>, and don&apos;t
              match their{" "}
              <b className="font-semibold">{featureGatesData.plan_name}</b>{" "}
              gates will revert to the gates associated with the subscriptions
              plan, and any changes will need to be re-applied manually.
            </Callout>
          )}
          {data.stripe_subscription_id ? (
            <Callout theme={CalloutTheme.Warning}>
              This will{" "}
              <Txt inline bold>
                not
              </Txt>{" "}
              automatically refund any remaining time on their current
              subscription. Make sure to cancel{" "}
              <a
                href={`https://dashboard.stripe.com/subscriptions/${data.stripe_subscription_id}`}
                target="_blank"
                rel="noreferrer"
              >
                their current subscription
              </a>{" "}
              and issue a refund if necessary.
            </Callout>
          ) : null}

          <InputV2
            formMethods={formMethods}
            label="Stripe subscription ID"
            name="stripe_subscription_id"
            required
            highlightErrors
            rules={{
              pattern: {
                value: /^sub_[a-z0-9_]+$/i,
                message: "Subscription IDs start with sub_ or sub_sched_",
              },
            }}
          />

          <Button
            type="submit"
            analyticsTrackingId={null}
            disabled={!formMethods.formState.isDirty}
          >
            Apply
          </Button>
        </div>
      </Form.Root>
    </Drawer>
  );
};
