/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Investigation
 */
export interface Investigation {
    /**
     * Timestamp when the investigation was created
     * @type {Date}
     * @memberof Investigation
     */
    created_at: Date;
    /**
     * Cutoff point up until we consider data for this investigation
     * @type {Date}
     * @memberof Investigation
     */
    cutoff_at?: Date;
    /**
     * Unique identifier of the investigation
     * @type {string}
     * @memberof Investigation
     */
    id: string;
    /**
     * External identifier of the associated incident
     * @type {number}
     * @memberof Investigation
     */
    incident_external_id: number;
    /**
     * ID of the incident this investigation is associated with
     * @type {string}
     * @memberof Investigation
     */
    incident_id: string;
    /**
     * Name of the associated incident
     * @type {string}
     * @memberof Investigation
     */
    incident_name: string;
    /**
     * Overrides for the model configuration (with a key:value map of prompt -> model)
     * @type {{ [key: string]: string; }}
     * @memberof Investigation
     */
    model_overrides?: { [key: string]: string; };
    /**
     * Whether the investigation is muted
     * @type {boolean}
     * @memberof Investigation
     */
    muted: boolean;
    /**
     * Which plan did this investigation follow
     * @type {string}
     * @memberof Investigation
     */
    plan_type: InvestigationPlanTypeEnum;
    /**
     * Total runtime of the investigation in seconds
     * @type {number}
     * @memberof Investigation
     */
    runtime_seconds: number;
    /**
     * Current state of the investigation
     * @type {string}
     * @memberof Investigation
     */
    state: InvestigationStateEnum;
    /**
     * Summary of the investigation
     * @type {string}
     * @memberof Investigation
     */
    summary?: string;
    /**
     * Total cost of all AI interactions in this investigation
     * @type {number}
     * @memberof Investigation
     */
    total_cost_in_cents: number;
    /**
     * Tuneable parameters applied to this investigation
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof Investigation
     */
    tuneables?: { [key: string]: { [key: string]: string; }; };
    /**
     * Overrides that were applied for this investigation
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof Investigation
     */
    tuneables_overrides?: { [key: string]: { [key: string]: string; }; };
}

/**
* @export
* @enum {string}
*/
export enum InvestigationPlanTypeEnum {
    Empty = '',
    Default = 'default',
    CodeChanges = 'code-changes',
    Messages = 'messages',
    Incidents = 'incidents'
}/**
* @export
* @enum {string}
*/
export enum InvestigationStateEnum {
    Pending = 'pending',
    Working = 'working',
    Complete = 'complete',
    Error = 'error'
}

export function InvestigationFromJSON(json: any): Investigation {
    return InvestigationFromJSONTyped(json, false);
}

export function InvestigationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investigation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'cutoff_at': !exists(json, 'cutoff_at') ? undefined : (new Date(json['cutoff_at'])),
        'id': json['id'],
        'incident_external_id': json['incident_external_id'],
        'incident_id': json['incident_id'],
        'incident_name': json['incident_name'],
        'model_overrides': !exists(json, 'model_overrides') ? undefined : json['model_overrides'],
        'muted': json['muted'],
        'plan_type': json['plan_type'],
        'runtime_seconds': json['runtime_seconds'],
        'state': json['state'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'total_cost_in_cents': json['total_cost_in_cents'],
        'tuneables': !exists(json, 'tuneables') ? undefined : json['tuneables'],
        'tuneables_overrides': !exists(json, 'tuneables_overrides') ? undefined : json['tuneables_overrides'],
    };
}

export function InvestigationToJSON(value?: Investigation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'cutoff_at': value.cutoff_at === undefined ? undefined : (value.cutoff_at.toISOString()),
        'id': value.id,
        'incident_external_id': value.incident_external_id,
        'incident_id': value.incident_id,
        'incident_name': value.incident_name,
        'model_overrides': value.model_overrides,
        'muted': value.muted,
        'plan_type': value.plan_type,
        'runtime_seconds': value.runtime_seconds,
        'state': value.state,
        'summary': value.summary,
        'total_cost_in_cents': value.total_cost_in_cents,
        'tuneables': value.tuneables,
        'tuneables_overrides': value.tuneables_overrides,
    };
}

