/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';

/**
 * 
 * @export
 * @interface TeamsUpdateResponseBody
 */
export interface TeamsUpdateResponseBody {
    /**
     * 
     * @type {Team}
     * @memberof TeamsUpdateResponseBody
     */
    team: Team;
}

export function TeamsUpdateResponseBodyFromJSON(json: any): TeamsUpdateResponseBody {
    return TeamsUpdateResponseBodyFromJSONTyped(json, false);
}

export function TeamsUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team': TeamFromJSON(json['team']),
    };
}

export function TeamsUpdateResponseBodyToJSON(value?: TeamsUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team': TeamToJSON(value.team),
    };
}

