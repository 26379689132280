/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotSearch,
    CopilotSearchFromJSON,
    CopilotSearchFromJSONTyped,
    CopilotSearchToJSON,
} from './CopilotSearch';
import {
    SearchGroundTruth,
    SearchGroundTruthFromJSON,
    SearchGroundTruthFromJSONTyped,
    SearchGroundTruthToJSON,
} from './SearchGroundTruth';

/**
 * 
 * @export
 * @interface AIStaffShowCopilotSearchResponseBody
 */
export interface AIStaffShowCopilotSearchResponseBody {
    /**
     * 
     * @type {CopilotSearch}
     * @memberof AIStaffShowCopilotSearchResponseBody
     */
    search: CopilotSearch;
    /**
     * 
     * @type {SearchGroundTruth}
     * @memberof AIStaffShowCopilotSearchResponseBody
     */
    search_ground_truth?: SearchGroundTruth;
}

export function AIStaffShowCopilotSearchResponseBodyFromJSON(json: any): AIStaffShowCopilotSearchResponseBody {
    return AIStaffShowCopilotSearchResponseBodyFromJSONTyped(json, false);
}

export function AIStaffShowCopilotSearchResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffShowCopilotSearchResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': CopilotSearchFromJSON(json['search']),
        'search_ground_truth': !exists(json, 'search_ground_truth') ? undefined : SearchGroundTruthFromJSON(json['search_ground_truth']),
    };
}

export function AIStaffShowCopilotSearchResponseBodyToJSON(value?: AIStaffShowCopilotSearchResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search': CopilotSearchToJSON(value.search),
        'search_ground_truth': SearchGroundTruthToJSON(value.search_ground_truth),
    };
}

