import {
  AIStaffCreateEvaluationDatasetRequestBodyDatasetTypeEnum,
  EvaluationDatasetDatasetTypeEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { cacheKey, useMutationV2 } from "src/utils/swr";

// Helper function to format enum values for display
const formatEnumValue = (value: string): string => {
  return value
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

type FormData = {
  name: string;
  dataset_type: EvaluationDatasetDatasetTypeEnum;
};

export const DatasetCreateModal = ({ onClose }: { onClose: () => void }) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      dataset_type: EvaluationDatasetDatasetTypeEnum.Incident,
    },
  });
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const { trigger: createDataset, isMutating: isPending } = useMutationV2(
    async (apiClient, data: FormData) => {
      const result = await apiClient.aIStaffCreateEvaluationDataset({
        createEvaluationDatasetRequestBody: {
          name: data.name,
          dataset_type:
            data.dataset_type as unknown as AIStaffCreateEvaluationDatasetRequestBodyDatasetTypeEnum,
        },
      });

      showToast({
        theme: ToastTheme.Success,
        title: "Dataset created successfully",
        toastSide: ToastSideEnum.TopRight,
      });
      navigate(`/workbench/datasets/${result.dataset.id}`);
    },
    {
      invalidate: [cacheKey.all("aIStaffListEvaluationDatasets")],
      showErrorToast: "Failed to create dataset",
    },
  );

  const handleSubmit = (data: FormData) => {
    createDataset(data);
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Create dataset"
      analyticsTrackingId="create-dataset"
      disableQuickClose
      onClose={onClose}
      onSubmit={handleSubmit}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Create dataset"
          analyticsTrackingId="create-dataset-submit"
          saving={isPending}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="Dataset name"
        name="name"
        helptext="Describe what this dataset is for"
        required
        placeholder="Verified code changes"
      />

      <StaticSingleSelectV2
        formMethods={formMethods}
        name="dataset_type"
        label="Dataset type"
        helptext="The type of data this dataset will contain"
        options={Object.values(EvaluationDatasetDatasetTypeEnum).map(
          (type) => ({
            value: type,
            label: formatEnumValue(type),
          }),
        )}
      />
    </Form.Modal>
  );
};
