/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFact,
    IncidentFactFromJSON,
    IncidentFactFromJSONTyped,
    IncidentFactToJSON,
} from './IncidentFact';

/**
 * 
 * @export
 * @interface AIStaffShowIncidentFactResponseBody
 */
export interface AIStaffShowIncidentFactResponseBody {
    /**
     * 
     * @type {IncidentFact}
     * @memberof AIStaffShowIncidentFactResponseBody
     */
    fact: IncidentFact;
}

export function AIStaffShowIncidentFactResponseBodyFromJSON(json: any): AIStaffShowIncidentFactResponseBody {
    return AIStaffShowIncidentFactResponseBodyFromJSONTyped(json, false);
}

export function AIStaffShowIncidentFactResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffShowIncidentFactResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fact': IncidentFactFromJSON(json['fact']),
    };
}

export function AIStaffShowIncidentFactResponseBodyToJSON(value?: AIStaffShowIncidentFactResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fact': IncidentFactToJSON(value.fact),
    };
}

