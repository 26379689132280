import type { SVGProps } from "react";
import * as React from "react";
const SvgMicrosoftTeamsGreyscale = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M8.326 4.847h1.178c.928 0 1.682.752 1.682 1.681v.766a2.418 2.418 0 1 0-2.86-2.447ZM17.628 5.66a1.674 1.674 0 1 1-3.348 0 1.674 1.674 0 0 1 3.348 0Z" />
    <path
      fillRule="evenodd"
      d="M10.186 7.267v-.74a.683.683 0 0 0-.68-.68H2.681A.682.682 0 0 0 2 6.528v6.818c0 .376.305.682.682.682h6.822a.682.682 0 0 0 .682-.682V7.267ZM7.888 8.442H6.524v3.71h-.869v-3.71H4.298v-.72h3.59v.72Z"
      clipRule="evenodd"
    />
    <path d="M6.891 15.028a4.197 4.197 0 0 0 3.667 2.346 4.195 4.195 0 0 0 4.093-4.29V8.793a.7.7 0 0 0-.682-.715h-2.783v5.268c0 .93-.754 1.682-1.682 1.682H6.89ZM15.39 15.143c-.04 0-.08 0-.12-.003.26-.636.397-1.336.381-2.067v-4.27a1.691 1.691 0 0 0-.15-.725h1.792c.39 0 .707.316.707.706v3.762a2.598 2.598 0 0 1-2.598 2.597h-.012Z" />
  </svg>
);
export default SvgMicrosoftTeamsGreyscale;
