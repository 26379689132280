/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemTemplateSectionConfig,
    PostmortemTemplateSectionConfigFromJSON,
    PostmortemTemplateSectionConfigFromJSONTyped,
    PostmortemTemplateSectionConfigToJSON,
} from './PostmortemTemplateSectionConfig';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemTemplateSection
 */
export interface PostmortemTemplateSection {
    /**
     * 
     * @type {PostmortemTemplateSectionConfig}
     * @memberof PostmortemTemplateSection
     */
    config?: PostmortemTemplateSectionConfig;
    /**
     * Unique ID for the section
     * @type {string}
     * @memberof PostmortemTemplateSection
     */
    id: string;
    /**
     * Rank of the section to determine its order
     * @type {number}
     * @memberof PostmortemTemplateSection
     */
    rank: number;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplateSection
     */
    resolved_template?: TextNode;
    /**
     * Type of the section
     * @type {string}
     * @memberof PostmortemTemplateSection
     */
    type: PostmortemTemplateSectionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemTemplateSectionTypeEnum {
    Custom = 'custom',
    Timeline = 'timeline',
    FollowUps = 'follow_ups',
    Summary = 'summary',
    KeyInformation = 'key_information',
    Feedback = 'feedback',
    Header = 'header'
}

export function PostmortemTemplateSectionFromJSON(json: any): PostmortemTemplateSection {
    return PostmortemTemplateSectionFromJSONTyped(json, false);
}

export function PostmortemTemplateSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemTemplateSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : PostmortemTemplateSectionConfigFromJSON(json['config']),
        'id': json['id'],
        'rank': json['rank'],
        'resolved_template': !exists(json, 'resolved_template') ? undefined : TextNodeFromJSON(json['resolved_template']),
        'type': json['type'],
    };
}

export function PostmortemTemplateSectionToJSON(value?: PostmortemTemplateSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': PostmortemTemplateSectionConfigToJSON(value.config),
        'id': value.id,
        'rank': value.rank,
        'resolved_template': TextNodeToJSON(value.resolved_template),
        'type': value.type,
    };
}

