import {
  CatalogTypeCategoriesEnum,
  IntegrationSettings,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
  StackedListItem,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Navigate } from "react-router";
import { useMutationV2 } from "src/utils/swr";

import { useIntegrations } from "../../../../hooks/useIntegrations";
import { useHasTypeOfCategory } from "../useHasTypeOfCategory";
import { WizardLayout } from "../WizardLayout";

export const ServiceWizardChooseSourceOfTruthStep = () => {
  const { integrations } = useIntegrations();

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { trigger: bootstrapExisting } = useMutationV2(
    async (apiClient, { catalogTypeID }: { catalogTypeID: string }) => {
      await apiClient.catalogBootstrapServiceType({
        bootstrapServiceTypeRequestBody: {
          catalog_type_id: catalogTypeID,
        },
      });
      navigate(`/catalog/service-wizard/${catalogTypeID}/add-attributes`);
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error bootstrapping service type",
        });
      },
      invalidate: [],
    },
  );

  const { hasMatchingType, matchingType, typesLoading, typesError, allTypes } =
    useHasTypeOfCategory(CatalogTypeCategoriesEnum.Service);

  const error = typesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const dataReady: boolean = integrations != null && !typesLoading;

  if (hasMatchingType && matchingType) {
    return (
      <Navigate
        to={`/catalog/service-wizard/${matchingType.id}/add-attributes`}
        replace
      />
    );
  }

  const optsForIntegration = (
    integration: IntegrationProvider,
    catalogRegistryType: string,
  ) => {
    const integrationObj = integrations?.find(
      (i) => i.provider === integration,
    );

    const thisCatalogType = allTypes.find(
      (ct) => ct.registry_type === catalogRegistryType,
    );

    if (thisCatalogType) {
      return {
        integration: integrationObj,
        onClick: () => bootstrapExisting({ catalogTypeID: thisCatalogType.id }),
      };
    }

    return {
      integration: integrationObj,
      rowHref: `/catalog/service-wizard/choose-source-of-truth/${integration}`,
    };
  };

  const sourceOfTruthOptions: {
    label: string;
    icon: IconEnum;
    rowHref?: string;
    color?: ColorPaletteEnum;
    onClick?: () => void;
    integration?: IntegrationSettings;
  }[] = [
    {
      label: "Backstage",
      icon: IconEnum.Backstage,
      rowHref: "/catalog/service-wizard/choose-source-of-truth/backstage",
    },
    {
      label: "Cortex",
      icon: IconEnum.Cortex,
      ...optsForIntegration(IntegrationProvider.Cortex, "CortexService"),
    },
    {
      label: "OpsLevel",
      icon: IconEnum.OpsLevel,
      ...optsForIntegration(IntegrationProvider.Opslevel, "OpsLevelService"),
    },
    {
      label: "Custom catalog",
      color: ColorPaletteEnum.Green,
      icon: IconEnum.Server,
      rowHref: "/catalog/service-wizard/choose-source-of-truth/custom-catalog",
    },
  ];

  return (
    <WizardLayout
      category={CatalogTypeCategoriesEnum.Service}
      title="Where do your services live?"
      subtitle="Do you use a Service Catalog to store your list of services?"
      stepID="choose-source-of-truth"
      footer={
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Secondary}
          loading={false}
          disabled={false}
          href="/catalog"
        >
          Exit
        </Button>
      }
    >
      {dataReady ? (
        <div className="flex flex-col gap-6">
          <StackedList>
            {sourceOfTruthOptions.map((opt) => {
              return (
                <StackedListItem
                  key={opt.label}
                  title={opt.label}
                  iconNode={
                    <IconBadge
                      icon={opt.icon}
                      color={ColorPaletteEnum.Slate}
                      size={IconSize.Medium}
                      className={
                        opt.color ? getColorPalette(opt.color)?.text : ""
                      }
                    />
                  }
                  rowHref={opt.rowHref}
                  onClick={opt.onClick}
                  accessory={<ChevronRight />}
                  badgeNode={
                    opt.integration?.installed ? (
                      <Badge theme={BadgeTheme.Success} className="h-5 px-1">
                        Installed
                      </Badge>
                    ) : undefined
                  }
                  className="cursor-pointer"
                />
              );
            })}
          </StackedList>
          <StackedList>
            <StackedListItem
              title="Manage services in incident.io"
              iconNode={
                <IconBadge
                  icon={IconEnum.IncidentFlame}
                  color={ColorPaletteEnum.Slate}
                  size={IconSize.Medium}
                />
              }
              rowHref="/catalog/service-wizard/choose-source-of-truth/manual"
              accessory={<ChevronRight />}
              className={"cursor-pointer"}
            />
          </StackedList>
        </div>
      ) : (
        <Loader />
      )}
    </WizardLayout>
  );
};

const ChevronRight = () => {
  return (
    <Icon
      className={"text-gray-400"}
      id={IconEnum.ChevronRight}
      size={IconSize.Medium}
    />
  );
};
