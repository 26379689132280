import { AIStaffRerunProcessorRequestBody } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  EmptyState,
  IconEnum,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { AISpanTrace } from "../ai-requests/AISpanTrace";

export const ProcessorShowPage = () => {
  const { spanId } = useParams<{ traceId: string; spanId: string }>();
  const navigate = useOrgAwareNavigate();

  const {
    data: { spans },
    isLoading,
  } = useAPI(
    spanId ? "aIStaffListAISpans" : null,
    {
      id: spanId,
      childSpanDepth: 50,
    },
    {
      enabled: !!spanId,
      fallbackData: { spans: [] },
    },
  );

  const { trigger: rerunProcessor, isMutating: isRerunning } = useMutationV2(
    async (apiClient, data: AIStaffRerunProcessorRequestBody) => {
      const resp = await apiClient.aIStaffRerunProcessor({
        rerunProcessorRequestBody: data,
      });
      navigate(`/workbench/processors/${resp.span_id}`);
    },
    {
      invalidate: [cacheKey.all("aIStaffListAISpans")],
      showErrorToast: "Re-running processor",
    },
  );

  const topSpan = spans.find((span) => span.id === spanId);
  if (!topSpan) {
    return (
      <EmptyState
        icon={IconEnum.Filter}
        content="Didn't find top span: something weird has happened"
      />
    );
  }

  if (!spanId) {
    navigate("/workbench");
    return null;
  }

  return (
    <WorkbenchSubPageWrapper
      title={topSpan.name}
      titleAccessory={<LocalDateTime timestamp={topSpan.created_at} />}
      backHref="/workbench/processors"
      accessory={
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Refresh}
          onClick={() =>
            rerunProcessor({
              processor: topSpan.name,
              resource_id: topSpan.resource_id as string,
            })
          }
          loading={isRerunning}
          disabled={isRerunning || !topSpan.resource_id}
          analyticsTrackingId="processor.rerun"
          title="Re-run processor"
        >
          Re-run
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading} className="pb-32">
        {spans.length === 0 ? (
          <EmptyState icon={IconEnum.Filter} content="No spans found" />
        ) : (
          <div className="flex flex-col gap-4">
            {topSpan.resource_id && (
              <SingleLineCodeBlock
                title="Resource ID"
                code={topSpan.resource_id}
                className="w-fit"
              />
            )}
            <AISpanTrace spans={spans} />
          </div>
        )}
      </LoadingWrapper>
    </WorkbenchSubPageWrapper>
  );
};
