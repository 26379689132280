/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationDatasetSegment,
    EvaluationDatasetSegmentFromJSON,
    EvaluationDatasetSegmentFromJSONTyped,
    EvaluationDatasetSegmentToJSON,
} from './EvaluationDatasetSegment';

/**
 * 
 * @export
 * @interface EvaluationDataset
 */
export interface EvaluationDataset {
    /**
     * When this dataset was created
     * @type {Date}
     * @memberof EvaluationDataset
     */
    created_at: Date;
    /**
     * Type of dataset
     * @type {string}
     * @memberof EvaluationDataset
     */
    dataset_type: EvaluationDatasetDatasetTypeEnum;
    /**
     * Description of the dataset's purpose
     * @type {string}
     * @memberof EvaluationDataset
     */
    description?: string;
    /**
     * Unique identifier
     * @type {string}
     * @memberof EvaluationDataset
     */
    id: string;
    /**
     * Human readable name of the dataset
     * @type {string}
     * @memberof EvaluationDataset
     */
    name: string;
    /**
     * ID of the organisation this dataset belongs to
     * @type {string}
     * @memberof EvaluationDataset
     */
    organisation_id: string;
    /**
     * List of named filter groups used to categorize incidents
     * @type {Array<EvaluationDatasetSegment>}
     * @memberof EvaluationDataset
     */
    segments: Array<EvaluationDatasetSegment>;
    /**
     * When this dataset was last updated
     * @type {Date}
     * @memberof EvaluationDataset
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationDatasetDatasetTypeEnum {
    Incident = 'incident',
    SearchCodeChanges = 'search_code_changes',
    SearchIncidents = 'search_incidents'
}

export function EvaluationDatasetFromJSON(json: any): EvaluationDataset {
    return EvaluationDatasetFromJSONTyped(json, false);
}

export function EvaluationDatasetFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationDataset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'dataset_type': json['dataset_type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'segments': ((json['segments'] as Array<any>).map(EvaluationDatasetSegmentFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationDatasetToJSON(value?: EvaluationDataset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'dataset_type': value.dataset_type,
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'segments': ((value.segments as Array<any>).map(EvaluationDatasetSegmentToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

