import type { SVGProps } from "react";
import * as React from "react";
const SvgTeam = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor">
      <path d="M8 8.583a2.139 2.139 0 1 0 0-4.278 2.139 2.139 0 0 0 0 4.278ZM11.61 12.263A3.714 3.714 0 0 0 8 9.361a3.714 3.714 0 0 0-3.609 2.902c-.147.672.27 1.36.952 1.566a9.18 9.18 0 0 0 5.314 0c.682-.206 1.1-.894.952-1.566ZM4.306 5.278a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM5.68 8.795a3.291 3.291 0 0 1-.985-2.35c0-.13.023-.252.038-.377-.103-.006-.204-.012-.316-.015l-.111-.002a3.366 3.366 0 0 0-3.267 2.538c-.174.685.257 1.407.961 1.611a7.5 7.5 0 0 0 1.862.314 4.914 4.914 0 0 1 1.819-1.72ZM11.695 5.278a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM10.32 8.795c.608-.6.986-1.432.986-2.35 0-.13-.023-.252-.038-.377.102-.006.204-.012.316-.015l.11-.002a3.366 3.366 0 0 1 3.268 2.538c.174.685-.258 1.407-.962 1.611a7.5 7.5 0 0 1-1.862.314 4.914 4.914 0 0 0-1.818-1.72Z" />
    </g>
  </svg>
);
export default SvgTeam;
