/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentGroundTruthRelatedMessage
 */
export interface IncidentGroundTruthRelatedMessage {
    /**
     * Explanation of why this message is related to the incident
     * @type {string}
     * @memberof IncidentGroundTruthRelatedMessage
     */
    detail: string;
    /**
     * A JSON object containing metadata about the message
     * @type {string}
     * @memberof IncidentGroundTruthRelatedMessage
     */
    metadata?: string;
    /**
     * URL to the slack message
     * @type {string}
     * @memberof IncidentGroundTruthRelatedMessage
     */
    permalink: string;
}

export function IncidentGroundTruthRelatedMessageFromJSON(json: any): IncidentGroundTruthRelatedMessage {
    return IncidentGroundTruthRelatedMessageFromJSONTyped(json, false);
}

export function IncidentGroundTruthRelatedMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruthRelatedMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detail': json['detail'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'permalink': json['permalink'],
    };
}

export function IncidentGroundTruthRelatedMessageToJSON(value?: IncidentGroundTruthRelatedMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detail': value.detail,
        'metadata': value.metadata,
        'permalink': value.permalink,
    };
}

