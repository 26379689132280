/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';
import {
    ManagementMeta,
    ManagementMetaFromJSON,
    ManagementMetaFromJSONTyped,
    ManagementMetaToJSON,
} from './ManagementMeta';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';

/**
 * 
 * @export
 * @interface EscalationPathsListResponseBody
 */
export interface EscalationPathsListResponseBody {
    /**
     * 
     * @type {Array<EscalationPath>}
     * @memberof EscalationPathsListResponseBody
     */
    escalation_paths: Array<EscalationPath>;
    /**
     * Map of escalation path IDs to arrays of user/schedule IDs on the first level.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof EscalationPathsListResponseBody
     */
    first_level_users: { [key: string]: Array<string>; };
    /**
     * Management meta for the returned escalation paths, if any are managed externally.
     * @type {{ [key: string]: ManagementMeta; }}
     * @memberof EscalationPathsListResponseBody
     */
    management_meta?: { [key: string]: ManagementMeta; };
    /**
     * 
     * @type {PaginationMeta}
     * @memberof EscalationPathsListResponseBody
     */
    pagination_meta: PaginationMeta;
}

export function EscalationPathsListResponseBodyFromJSON(json: any): EscalationPathsListResponseBody {
    return EscalationPathsListResponseBodyFromJSONTyped(json, false);
}

export function EscalationPathsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': ((json['escalation_paths'] as Array<any>).map(EscalationPathFromJSON)),
        'first_level_users': json['first_level_users'],
        'management_meta': !exists(json, 'management_meta') ? undefined : (mapValues(json['management_meta'], ManagementMetaFromJSON)),
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
    };
}

export function EscalationPathsListResponseBodyToJSON(value?: EscalationPathsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': ((value.escalation_paths as Array<any>).map(EscalationPathToJSON)),
        'first_level_users': value.first_level_users,
        'management_meta': value.management_meta === undefined ? undefined : (mapValues(value.management_meta, ManagementMetaToJSON)),
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
    };
}

