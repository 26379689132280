/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferenceManualEscalationProviderOption
 */
export interface UserPreferenceManualEscalationProviderOption {
    /**
     * The ID of the user in that provider, if they have an account with them
     * @type {string}
     * @memberof UserPreferenceManualEscalationProviderOption
     */
    id?: string;
    /**
     * The escalation provider
     * @type {string}
     * @memberof UserPreferenceManualEscalationProviderOption
     */
    provider: UserPreferenceManualEscalationProviderOptionProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum UserPreferenceManualEscalationProviderOptionProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function UserPreferenceManualEscalationProviderOptionFromJSON(json: any): UserPreferenceManualEscalationProviderOption {
    return UserPreferenceManualEscalationProviderOptionFromJSONTyped(json, false);
}

export function UserPreferenceManualEscalationProviderOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferenceManualEscalationProviderOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': json['provider'],
    };
}

export function UserPreferenceManualEscalationProviderOptionToJSON(value?: UserPreferenceManualEscalationProviderOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'provider': value.provider,
    };
}

