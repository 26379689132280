/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruthCodeChange,
    IncidentGroundTruthCodeChangeFromJSON,
    IncidentGroundTruthCodeChangeFromJSONTyped,
    IncidentGroundTruthCodeChangeToJSON,
} from './IncidentGroundTruthCodeChange';
import {
    IncidentGroundTruthRelatedIncident,
    IncidentGroundTruthRelatedIncidentFromJSON,
    IncidentGroundTruthRelatedIncidentFromJSONTyped,
    IncidentGroundTruthRelatedIncidentToJSON,
} from './IncidentGroundTruthRelatedIncident';
import {
    IncidentGroundTruthRelatedMessage,
    IncidentGroundTruthRelatedMessageFromJSON,
    IncidentGroundTruthRelatedMessageFromJSONTyped,
    IncidentGroundTruthRelatedMessageToJSON,
} from './IncidentGroundTruthRelatedMessage';
import {
    IncidentGroundTruthTelemetryDashboard,
    IncidentGroundTruthTelemetryDashboardFromJSON,
    IncidentGroundTruthTelemetryDashboardFromJSONTyped,
    IncidentGroundTruthTelemetryDashboardToJSON,
} from './IncidentGroundTruthTelemetryDashboard';

/**
 * 
 * @export
 * @interface IncidentGroundTruth
 */
export interface IncidentGroundTruth {
    /**
     * List of code changes that caused this incident
     * @type {Array<IncidentGroundTruthCodeChange>}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes?: Array<IncidentGroundTruthCodeChange>;
    /**
     * Timestamp until which code changes are cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes_cache_until?: Date;
    /**
     * When code changes were human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes_verified_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    created_at: Date;
    /**
     * Unique identifier
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    id: string;
    /**
     * ID of the incident this ground truth is associated with
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    incident_id: string;
    /**
     * Whether the investigation findings were useful to responders ('not_relevant', 'relevant', 'critical')
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance?: string;
    /**
     * Timestamp until which investigation relevance is cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_cache_until?: Date;
    /**
     * Explanation of why the investigation was or wasn't useful
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_reasoning?: string;
    /**
     * Tags describing how the investigation was useful (e.g. 'code-change', 'telemetry')
     * @type {Array<string>}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_tags?: Array<string>;
    /**
     * When investigation relevance was human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_verified_at?: Date;
    /**
     * ID of the owning organisation
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    organisation_id: string;
    /**
     * List of incidents that were related to this incident
     * @type {Array<IncidentGroundTruthRelatedIncident>}
     * @memberof IncidentGroundTruth
     */
    related_incidents?: Array<IncidentGroundTruthRelatedIncident>;
    /**
     * Timestamp until which related incidents are cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_incidents_cache_until?: Date;
    /**
     * Next steps to take in relation to this incident
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    related_incidents_next_steps?: string;
    /**
     * When related incidents were human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_incidents_verified_at?: Date;
    /**
     * List of messages that were helpful to resolving this incident
     * @type {Array<IncidentGroundTruthRelatedMessage>}
     * @memberof IncidentGroundTruth
     */
    related_messages?: Array<IncidentGroundTruthRelatedMessage>;
    /**
     * Timestamp until which related messages are cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_messages_cache_until?: Date;
    /**
     * When related messages were human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_messages_verified_at?: Date;
    /**
     * List of telemetry dashboards that were relevant to this incident
     * @type {Array<IncidentGroundTruthTelemetryDashboard>}
     * @memberof IncidentGroundTruth
     */
    related_telemetry_dashboards?: Array<IncidentGroundTruthTelemetryDashboard>;
    /**
     * Timestamp until which telemetry dashboards are cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_telemetry_dashboards_cache_until?: Date;
    /**
     * When telemetry dashboards were human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    related_telemetry_dashboards_verified_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    updated_at: Date;
}

export function IncidentGroundTruthFromJSON(json: any): IncidentGroundTruth {
    return IncidentGroundTruthFromJSONTyped(json, false);
}

export function IncidentGroundTruthFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causing_code_changes': !exists(json, 'causing_code_changes') ? undefined : ((json['causing_code_changes'] as Array<any>).map(IncidentGroundTruthCodeChangeFromJSON)),
        'causing_code_changes_cache_until': !exists(json, 'causing_code_changes_cache_until') ? undefined : (new Date(json['causing_code_changes_cache_until'])),
        'causing_code_changes_verified_at': !exists(json, 'causing_code_changes_verified_at') ? undefined : (new Date(json['causing_code_changes_verified_at'])),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'investigation_relevance': !exists(json, 'investigation_relevance') ? undefined : json['investigation_relevance'],
        'investigation_relevance_cache_until': !exists(json, 'investigation_relevance_cache_until') ? undefined : (new Date(json['investigation_relevance_cache_until'])),
        'investigation_relevance_reasoning': !exists(json, 'investigation_relevance_reasoning') ? undefined : json['investigation_relevance_reasoning'],
        'investigation_relevance_tags': !exists(json, 'investigation_relevance_tags') ? undefined : json['investigation_relevance_tags'],
        'investigation_relevance_verified_at': !exists(json, 'investigation_relevance_verified_at') ? undefined : (new Date(json['investigation_relevance_verified_at'])),
        'organisation_id': json['organisation_id'],
        'related_incidents': !exists(json, 'related_incidents') ? undefined : ((json['related_incidents'] as Array<any>).map(IncidentGroundTruthRelatedIncidentFromJSON)),
        'related_incidents_cache_until': !exists(json, 'related_incidents_cache_until') ? undefined : (new Date(json['related_incidents_cache_until'])),
        'related_incidents_next_steps': !exists(json, 'related_incidents_next_steps') ? undefined : json['related_incidents_next_steps'],
        'related_incidents_verified_at': !exists(json, 'related_incidents_verified_at') ? undefined : (new Date(json['related_incidents_verified_at'])),
        'related_messages': !exists(json, 'related_messages') ? undefined : ((json['related_messages'] as Array<any>).map(IncidentGroundTruthRelatedMessageFromJSON)),
        'related_messages_cache_until': !exists(json, 'related_messages_cache_until') ? undefined : (new Date(json['related_messages_cache_until'])),
        'related_messages_verified_at': !exists(json, 'related_messages_verified_at') ? undefined : (new Date(json['related_messages_verified_at'])),
        'related_telemetry_dashboards': !exists(json, 'related_telemetry_dashboards') ? undefined : ((json['related_telemetry_dashboards'] as Array<any>).map(IncidentGroundTruthTelemetryDashboardFromJSON)),
        'related_telemetry_dashboards_cache_until': !exists(json, 'related_telemetry_dashboards_cache_until') ? undefined : (new Date(json['related_telemetry_dashboards_cache_until'])),
        'related_telemetry_dashboards_verified_at': !exists(json, 'related_telemetry_dashboards_verified_at') ? undefined : (new Date(json['related_telemetry_dashboards_verified_at'])),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentGroundTruthToJSON(value?: IncidentGroundTruth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causing_code_changes': value.causing_code_changes === undefined ? undefined : ((value.causing_code_changes as Array<any>).map(IncidentGroundTruthCodeChangeToJSON)),
        'causing_code_changes_cache_until': value.causing_code_changes_cache_until === undefined ? undefined : (value.causing_code_changes_cache_until.toISOString()),
        'causing_code_changes_verified_at': value.causing_code_changes_verified_at === undefined ? undefined : (value.causing_code_changes_verified_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'investigation_relevance': value.investigation_relevance,
        'investigation_relevance_cache_until': value.investigation_relevance_cache_until === undefined ? undefined : (value.investigation_relevance_cache_until.toISOString()),
        'investigation_relevance_reasoning': value.investigation_relevance_reasoning,
        'investigation_relevance_tags': value.investigation_relevance_tags,
        'investigation_relevance_verified_at': value.investigation_relevance_verified_at === undefined ? undefined : (value.investigation_relevance_verified_at.toISOString()),
        'organisation_id': value.organisation_id,
        'related_incidents': value.related_incidents === undefined ? undefined : ((value.related_incidents as Array<any>).map(IncidentGroundTruthRelatedIncidentToJSON)),
        'related_incidents_cache_until': value.related_incidents_cache_until === undefined ? undefined : (value.related_incidents_cache_until.toISOString()),
        'related_incidents_next_steps': value.related_incidents_next_steps,
        'related_incidents_verified_at': value.related_incidents_verified_at === undefined ? undefined : (value.related_incidents_verified_at.toISOString()),
        'related_messages': value.related_messages === undefined ? undefined : ((value.related_messages as Array<any>).map(IncidentGroundTruthRelatedMessageToJSON)),
        'related_messages_cache_until': value.related_messages_cache_until === undefined ? undefined : (value.related_messages_cache_until.toISOString()),
        'related_messages_verified_at': value.related_messages_verified_at === undefined ? undefined : (value.related_messages_verified_at.toISOString()),
        'related_telemetry_dashboards': value.related_telemetry_dashboards === undefined ? undefined : ((value.related_telemetry_dashboards as Array<any>).map(IncidentGroundTruthTelemetryDashboardToJSON)),
        'related_telemetry_dashboards_cache_until': value.related_telemetry_dashboards_cache_until === undefined ? undefined : (value.related_telemetry_dashboards_cache_until.toISOString()),
        'related_telemetry_dashboards_verified_at': value.related_telemetry_dashboards_verified_at === undefined ? undefined : (value.related_telemetry_dashboards_verified_at.toISOString()),
        'updated_at': (value.updated_at.toISOString()),
    };
}

