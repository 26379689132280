/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffConfigureCallRouteRequestBody
 */
export interface StaffConfigureCallRouteRequestBody {
    /**
     * The phone number to buy and connect to the call route
     * @type {string}
     * @memberof StaffConfigureCallRouteRequestBody
     */
    phone_number: string;
}

export function StaffConfigureCallRouteRequestBodyFromJSON(json: any): StaffConfigureCallRouteRequestBody {
    return StaffConfigureCallRouteRequestBodyFromJSONTyped(json, false);
}

export function StaffConfigureCallRouteRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffConfigureCallRouteRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone_number': json['phone_number'],
    };
}

export function StaffConfigureCallRouteRequestBodyToJSON(value?: StaffConfigureCallRouteRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_number': value.phone_number,
    };
}

