/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationLeaderboard,
    EvaluationLeaderboardFromJSON,
    EvaluationLeaderboardFromJSONTyped,
    EvaluationLeaderboardToJSON,
} from './EvaluationLeaderboard';

/**
 * 
 * @export
 * @interface AIStaffCreateEvaluationLeaderboardResponseBody
 */
export interface AIStaffCreateEvaluationLeaderboardResponseBody {
    /**
     * 
     * @type {EvaluationLeaderboard}
     * @memberof AIStaffCreateEvaluationLeaderboardResponseBody
     */
    leaderboard: EvaluationLeaderboard;
}

export function AIStaffCreateEvaluationLeaderboardResponseBodyFromJSON(json: any): AIStaffCreateEvaluationLeaderboardResponseBody {
    return AIStaffCreateEvaluationLeaderboardResponseBodyFromJSONTyped(json, false);
}

export function AIStaffCreateEvaluationLeaderboardResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateEvaluationLeaderboardResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboard': EvaluationLeaderboardFromJSON(json['leaderboard']),
    };
}

export function AIStaffCreateEvaluationLeaderboardResponseBodyToJSON(value?: AIStaffCreateEvaluationLeaderboardResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboard': EvaluationLeaderboardToJSON(value.leaderboard),
    };
}

