/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNodeIfElsePayloadV2,
    EscalationPathNodeIfElsePayloadV2FromJSON,
    EscalationPathNodeIfElsePayloadV2FromJSONTyped,
    EscalationPathNodeIfElsePayloadV2ToJSON,
} from './EscalationPathNodeIfElsePayloadV2';
import {
    EscalationPathNodeLevelV2,
    EscalationPathNodeLevelV2FromJSON,
    EscalationPathNodeLevelV2FromJSONTyped,
    EscalationPathNodeLevelV2ToJSON,
} from './EscalationPathNodeLevelV2';
import {
    EscalationPathNodeNotifyChannelV2,
    EscalationPathNodeNotifyChannelV2FromJSON,
    EscalationPathNodeNotifyChannelV2FromJSONTyped,
    EscalationPathNodeNotifyChannelV2ToJSON,
} from './EscalationPathNodeNotifyChannelV2';
import {
    EscalationPathNodeRepeatV2,
    EscalationPathNodeRepeatV2FromJSON,
    EscalationPathNodeRepeatV2FromJSONTyped,
    EscalationPathNodeRepeatV2ToJSON,
} from './EscalationPathNodeRepeatV2';

/**
 * 
 * @export
 * @interface EscalationPathNodePayloadV2
 */
export interface EscalationPathNodePayloadV2 {
    /**
     * An ID for this node, unique within the escalation path.
     * 
     * This allows you to reference the node in other nodes, such as when configuring a 'repeat' node.
     * @type {string}
     * @memberof EscalationPathNodePayloadV2
     */
    id: string;
    /**
     * 
     * @type {EscalationPathNodeIfElsePayloadV2}
     * @memberof EscalationPathNodePayloadV2
     */
    if_else?: EscalationPathNodeIfElsePayloadV2;
    /**
     * 
     * @type {EscalationPathNodeLevelV2}
     * @memberof EscalationPathNodePayloadV2
     */
    level?: EscalationPathNodeLevelV2;
    /**
     * 
     * @type {EscalationPathNodeNotifyChannelV2}
     * @memberof EscalationPathNodePayloadV2
     */
    notify_channel?: EscalationPathNodeNotifyChannelV2;
    /**
     * 
     * @type {EscalationPathNodeRepeatV2}
     * @memberof EscalationPathNodePayloadV2
     */
    repeat?: EscalationPathNodeRepeatV2;
    /**
     * The type of this node. Available types are:
     * * level: A set of targets (users or schedules) that should be paged, either all at once, or with a round-robin configuration.
     * * notify_channel: Send the escalation to a Slack channel, where it can be acked by anyone in the channel.
     * * if_else: Branch the escalation based on a set of conditions.
     * * repeat: Go back to a previous node and repeat the logic from there.
     * @type {string}
     * @memberof EscalationPathNodePayloadV2
     */
    type: EscalationPathNodePayloadV2TypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathNodePayloadV2TypeEnum {
    IfElse = 'if_else',
    Repeat = 'repeat',
    Level = 'level',
    NotifyChannel = 'notify_channel'
}

export function EscalationPathNodePayloadV2FromJSON(json: any): EscalationPathNodePayloadV2 {
    return EscalationPathNodePayloadV2FromJSONTyped(json, false);
}

export function EscalationPathNodePayloadV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodePayloadV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'if_else': !exists(json, 'if_else') ? undefined : EscalationPathNodeIfElsePayloadV2FromJSON(json['if_else']),
        'level': !exists(json, 'level') ? undefined : EscalationPathNodeLevelV2FromJSON(json['level']),
        'notify_channel': !exists(json, 'notify_channel') ? undefined : EscalationPathNodeNotifyChannelV2FromJSON(json['notify_channel']),
        'repeat': !exists(json, 'repeat') ? undefined : EscalationPathNodeRepeatV2FromJSON(json['repeat']),
        'type': json['type'],
    };
}

export function EscalationPathNodePayloadV2ToJSON(value?: EscalationPathNodePayloadV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'if_else': EscalationPathNodeIfElsePayloadV2ToJSON(value.if_else),
        'level': EscalationPathNodeLevelV2ToJSON(value.level),
        'notify_channel': EscalationPathNodeNotifyChannelV2ToJSON(value.notify_channel),
        'repeat': EscalationPathNodeRepeatV2ToJSON(value.repeat),
        'type': value.type,
    };
}

