import { isExpression } from "@incident-shared/engine";
import { DragHandle } from "@incident-shared/settings";
import { AddNewButton, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import {
  ArrayPath,
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { EngineParamBindingValue, Resource } from "src/contexts/ClientContext";
import { v4 as uuidv4 } from "uuid";

import { InputOrVariable } from "./InputOrVariable";
import { ScopeAndIsAlert } from "./MultiValueEngineFormElement";

type TextEditorRow = EngineParamBindingValue & {
  key: string;
};

export const EngineMultiInput = <
  FormType extends FieldValues,
  TPath extends ArrayPath<FormType> & Path<FormType>,
>({
  name,
  label,
  scopeAndIsAlert,
  resources,
  resource,
  disabled,
  includeExpressions,
  includeVariables,
  renderLiteralInput,
}: {
  name: TPath;
  label: string;
  scopeAndIsAlert: ScopeAndIsAlert;
  resources: Resource[];
  resource: Resource;
  disabled?: boolean;
  includeExpressions: boolean;
  includeVariables: boolean;
  renderLiteralInput: (props: {
    name: string;
    renderLightningButton: () => React.ReactNode;
  }) => React.ReactElement;
}): React.ReactElement => {
  const formMethods = useFormContext<FormType>();

  // We do something a bit weird here: we fake the type that we
  // pass to useFieldArray so that it knows that fields is an array
  // of EngineParamBinding objects.
  type dummyFormType = {
    bindings: TextEditorRow[];
  };
  const { append, fields, remove, move } = useFieldArray<
    dummyFormType,
    "bindings",
    "key"
  >({
    // @ts-expect-error this doesn't match our fake type, of course
    control: formMethods.control,
    // @ts-expect-error this doesn't match our fake type, of course
    name: name,
    keyName: "key",
  });

  const allBindingsAreExpressions = useMemo(
    () => fields.every((f) => isExpression(f.reference)),
    [fields],
  );

  const onAdd = useCallback(() => {
    append({ key: uuidv4(), label: "", sort_key: "", literal: "" });
  }, [append]);

  useEffect(() => {
    // If you've ever got an empty list, add a new row (this stops the list looking
    // really broken at the start). Ideally I think we'd handle this by defining a 'default'
    // binding depending on the param type and injecting that into the form state, but
    // that would take a bit of thinking to figure out I think.
    //
    // NOTE: It is important to wrap this in a useEffect, otherwise we'll reliably add more
    // than one row, which we don't want to do.
    if (fields.length === 0) {
      onAdd();
    }
  }, [fields, onAdd]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      move(fromIndex, toIndex);
    },
    [move],
  );

  if (allBindingsAreExpressions && scopeAndIsAlert.isAlertElement) {
    // If all our bindings are expressions, in all likelihood there is single binding which sets multiple values
    // through an expression. In this case, we should render a single input field, and not a weird draggable handle
    // and remove button.
    return (
      <div>
        {fields.map((bindingValue, index) => {
          return (
            <div key={index} className="flex flex-row items-center">
              <Row
                name={`${name}.${index}`}
                label={label}
                scopeAndIsAlert={scopeAndIsAlert}
                resources={resources}
                resource={resource}
                disabled={disabled}
                renderLiteralInput={renderLiteralInput}
                includeExpressions={includeExpressions}
                includeVariables={includeVariables}
                // These are all expressions, so deletion would be done in ViewEditableExpressions.
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="customFields">
        {(provided) => (
          <>
            <ul
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="space-y-2 mb-4"
            >
              {fields.map((bindingValue, index) => {
                return (
                  <Draggable
                    key={bindingValue.key}
                    draggableId={bindingValue.key}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      const result = (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="flex flex-row items-center">
                            <DragHandle
                              className="mr-2 flex-0"
                              {...provided.dragHandleProps}
                            />
                            <Row
                              name={`${name}.${index}`}
                              label={label}
                              scopeAndIsAlert={scopeAndIsAlert}
                              resources={resources}
                              resource={resource}
                              disabled={disabled}
                              renderLiteralInput={renderLiteralInput}
                              includeExpressions={includeExpressions}
                              includeVariables={includeVariables}
                              onDelete={() => {
                                remove(index);
                              }}
                            />
                          </div>
                        </div>
                      );

                      if (snapshot.isDragging) {
                        return ReactDOM.createPortal(result, document.body);
                      }

                      return result;
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
            <div className="space-x-4">
              <AddNewButton
                analyticsTrackingId="engine-multi-text-input-add-new-button"
                onClick={onAdd}
                title="Add another"
              />
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Row = ({
  name,
  label,
  scopeAndIsAlert,
  resource,
  onDelete,
  disabled,
  includeExpressions,
  includeVariables,
  renderLiteralInput,
}: {
  name: string;
  label: string;
  scopeAndIsAlert: ScopeAndIsAlert;
  resources: Resource[];
  resource: Resource;
  onDelete?: () => void;
  disabled?: boolean;
  includeExpressions: boolean;
  includeVariables: boolean;
  renderLiteralInput: (props: {
    renderLightningButton: () => React.ReactNode;
    name: string;
  }) => React.ReactElement;
}): React.ReactElement => {
  return (
    <div className="flex space-x-2 min-w-0 grow text-sm">
      <InputOrVariable
        name={name}
        label={label}
        scopeAndIsAlert={scopeAndIsAlert}
        resource={resource}
        required={false}
        // Although we are in an array type, each individual row behaves like a single binding
        array={false}
        disabled={disabled}
        includeExpressions={includeExpressions}
        includeVariables={includeVariables}
        includeStatic={true}
        renderChildren={(renderLightningButton) =>
          renderLiteralInput({ name, renderLightningButton })
        }
      />

      {onDelete && (
        <Button
          analyticsTrackingId={null}
          title="Remove"
          onClick={onDelete}
          icon={IconEnum.Delete}
          theme={ButtonTheme.Naked}
          className=" ml-1 shrink-0 grow-0"
        />
      )}
    </div>
  );
};
