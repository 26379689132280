/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteCustomFieldBindingPayload
 */
export interface AlertRouteCustomFieldBindingPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteCustomFieldBindingPayload
     */
    binding: EngineParamBindingPayload;
    /**
     * ID of the custom field
     * @type {string}
     * @memberof AlertRouteCustomFieldBindingPayload
     */
    custom_field_id: string;
    /**
     * The strategy to use when multiple alerts match this route
     * @type {string}
     * @memberof AlertRouteCustomFieldBindingPayload
     */
    merge_strategy: AlertRouteCustomFieldBindingPayloadMergeStrategyEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertRouteCustomFieldBindingPayloadMergeStrategyEnum {
    FirstWins = 'first-wins',
    LastWins = 'last-wins',
    Append = 'append'
}

export function AlertRouteCustomFieldBindingPayloadFromJSON(json: any): AlertRouteCustomFieldBindingPayload {
    return AlertRouteCustomFieldBindingPayloadFromJSONTyped(json, false);
}

export function AlertRouteCustomFieldBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteCustomFieldBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': EngineParamBindingPayloadFromJSON(json['binding']),
        'custom_field_id': json['custom_field_id'],
        'merge_strategy': json['merge_strategy'],
    };
}

export function AlertRouteCustomFieldBindingPayloadToJSON(value?: AlertRouteCustomFieldBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingPayloadToJSON(value.binding),
        'custom_field_id': value.custom_field_id,
        'merge_strategy': value.merge_strategy,
    };
}

