/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelemetryFetchDashboardDetailsRequestBody
 */
export interface TelemetryFetchDashboardDetailsRequestBody {
    /**
     * The URL of the dashboard to fetch
     * @type {string}
     * @memberof TelemetryFetchDashboardDetailsRequestBody
     */
    dashboard_url: string;
    /**
     * The provider of the dashboard
     * @type {string}
     * @memberof TelemetryFetchDashboardDetailsRequestBody
     */
    provider: TelemetryFetchDashboardDetailsRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum TelemetryFetchDashboardDetailsRequestBodyProviderEnum {
    Grafana = 'grafana'
}

export function TelemetryFetchDashboardDetailsRequestBodyFromJSON(json: any): TelemetryFetchDashboardDetailsRequestBody {
    return TelemetryFetchDashboardDetailsRequestBodyFromJSONTyped(json, false);
}

export function TelemetryFetchDashboardDetailsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryFetchDashboardDetailsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard_url': json['dashboard_url'],
        'provider': json['provider'],
    };
}

export function TelemetryFetchDashboardDetailsRequestBodyToJSON(value?: TelemetryFetchDashboardDetailsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard_url': value.dashboard_url,
        'provider': value.provider,
    };
}

