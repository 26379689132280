import { AnnouncementRule } from "@incident-io/api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  SlackTeamAvatar,
} from "@incident-ui";

import { useIdentity } from "../../../../contexts/IdentityContext";

export const AnnouncementRuleDetails = ({
  rule,
  displayIcon = false,
  boxlessConditionGroupsList = true,
}: {
  rule: AnnouncementRule;
  displayIcon?: boolean;
  boxlessConditionGroupsList?: boolean;
}) => {
  const { identity } = useIdentity();
  const isTeamsCustomer = identity.ms_teams_info !== undefined;

  return (
    <div className="space-y-2 bg-surface-primary">
      <div className="text-content-primary">
        Applies to
        {rule.condition_groups.length > 0 ? (
          <>
            <span>&nbsp;incidents where...</span>
            <div className="pt-2">
              <ConditionGroupsList
                groups={rule.condition_groups}
                boxless={boxlessConditionGroupsList}
              />
            </div>
          </>
        ) : (
          <span className="text-sm-med">&nbsp;all incidents</span>
        )}
      </div>
      <div className="flex flex-row gap-1 items-center flex-wrap">
        <div className="text-content-primary">Incidents announced in</div>
        {isTeamsCustomer ? (
          <div className="flex flex-row gap-1 flex-wrap">
            {rule.microsoft_teams_channels.map((channel, _) => (
              <>
                <Badge
                  key={channel.value}
                  size={BadgeSize.Medium}
                  theme={BadgeTheme.Tertiary}
                  icon={IconEnum.MicrosoftTeamsGreyscale}
                >
                  <Link
                    openInNewTab
                    href={channel.url}
                    analyticsTrackingId={""}
                    className="flex items-center gap-1 !no-underline"
                  >
                    {channel.label}
                  </Link>
                </Badge>
              </>
            ))}
          </div>
        ) : (
          <div className="flex flex-row gap-1 flex-wrap">
            {rule.slack_channels.map((channel, _) => (
              <Badge
                key={channel.value}
                size={BadgeSize.Medium}
                theme={BadgeTheme.Tertiary}
              >
                <div className="flex items-center gap-1">
                  {displayIcon && channel.icon_url && (
                    <SlackTeamAvatar
                      url={channel.icon_url}
                      size={IconSize.Small}
                    />
                  )}
                  {channel.is_private ? (
                    <Icon
                      id={IconEnum.LockClosed}
                      className="w-4 h-4 inline mb-0.5"
                    />
                  ) : (
                    "#"
                  )}
                  <Link
                    openInNewTab
                    href={channel.url}
                    className="!no-underline"
                    analyticsTrackingId={""}
                  >
                    {channel.label}
                  </Link>
                </div>
              </Badge>
            ))}
          </div>
        )}
      </div>
      {/* We don't support sharing updates to channel for Microsoft Teams */}
      {!isTeamsCustomer && (
        <div className="flex flex-row gap-1 items-center flex-wrap pt-0.5">
          <span className="text-content-tertiary text-sm">
            {rule.share_updates_to_channel
              ? "Share incident updates to channel and thread"
              : "Share incident updates to thread only"}
          </span>
        </div>
      )}
    </div>
  );
};
