import { AISpan } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { useState } from "react";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-debounce";

import { TraceLink } from "../common/utils";
import { AISpanDrawer } from "./AISpanDrawer";

export const AISpanTrace = ({ spans }: { spans: AISpan[] }) => {
  const [selectedSpan, setSelectedSpan] = useState<AISpan | null>(null);

  return (
    <SearchProvider>
      {selectedSpan && (
        <AISpanDrawer
          onClose={() => setSelectedSpan(null)}
          span={selectedSpan}
        />
      )}
      <div className="w-full pt-2">
        <div className="flex w-full gap-2">
          <AISpanTimeline spans={spans} setSelectedSpan={setSelectedSpan} />
        </div>
      </div>
    </SearchProvider>
  );
};

type SpanWithDepth = AISpan & {
  depth: number;
  hasIgnoredParent?: boolean;
};

const getSpanIcon = (type: AISpan["type"]): IconEnum => {
  switch (type) {
    case "tool_call":
      return IconEnum.Call;
    case "completion_request":
      return IconEnum.Message;
    case "prompt":
      return IconEnum.Box;
    case "processor":
      return IconEnum.Cog;
    case "scorecard":
      return IconEnum.Checklist;
    case "grader":
      return IconEnum.NumberBox1Filled;
    case "copilot_interaction":
      return IconEnum.User;
    case "investigation_check":
      return IconEnum.Checklist;
    case "backfill":
      return IconEnum.FolderOpen;
    case "search":
      return IconEnum.Search;

    default:
      return IconEnum.QuestionMark;
  }
};

const getSpanColor = (span: SpanWithDepth, shouldBeBackgrounded: boolean) => {
  if (span.type === "prompt" || span.type === "copilot_interaction") {
    return "";
  }

  // Base color classes - will be combined with opacity if needed
  let baseColor = "";
  if (span.result_type === "error") {
    if (JSON.stringify(span.result).includes("context canceled")) {
      baseColor = "bg-slate-200 hover:bg-slate-300";
    } else {
      baseColor = "bg-red-200 hover:bg-red-300";
    }
  } else if (span.result_type === "ignored") {
    baseColor = "bg-slate-200 hover:bg-slate-300";
  } else if (span.type === "tool_call") {
    baseColor = "bg-blue-200 hover:bg-blue-300";
  } else if (span.type === "processor") {
    baseColor = "bg-orange-200 hover:bg-orange-300";
  } else if (
    span.type === "grader" ||
    span.type === "scorecard" ||
    span.type === "investigation_check"
  ) {
    baseColor = "bg-purple-200 hover:bg-purple-300";
  } else {
    baseColor = "bg-green-200 hover:bg-green-300";
  }

  // Add opacity if parent is ignored
  const isIgnored =
    span.hasIgnoredParent ||
    span.result_type === "ignored" ||
    shouldBeBackgrounded;
  return tcx(baseColor, isIgnored && "opacity-60");
};

const AISpanTimeline = ({
  spans,
  setSelectedSpan,
}: {
  spans: AISpan[];
  setSelectedSpan: (span: AISpan) => void;
}) => {
  const searchBarProps = useSearchContext();
  const [debouncedSearch] = useDebounce(searchBarProps.value, 500);

  const { data: searchResults } = useAPI(
    "aIStaffSearchAISpans",
    {
      spanIds: spans.map((s) => s.id),
      query: debouncedSearch,
    },
    {
      revalidateIfStale: debouncedSearch !== "",
      fallbackData: { matching_span_ids: [] },
    },
  );
  const matchingSpanIDs = searchResults.matching_span_ids;

  // Build a map of parent spans for quick lookup
  const spansById = new Map(spans.map((span) => [span.id, span]));

  // Check if a span has an ignored parent
  const hasIgnoredParent = (span: AISpan): boolean => {
    let currentSpan = span;
    while (currentSpan.parent_id) {
      const parent = spansById.get(currentSpan.parent_id);
      if (!parent) break;
      if (parent.result_type === "ignored") return true;
      currentSpan = parent;
    }
    return false;
  };

  const filteredSpans = spans.filter(
    (span) => span.type !== "copilot_interaction",
  );

  const hierarchicalSpans = getSpanHierarchy(filteredSpans).map((span) => ({
    ...span,
    hasIgnoredParent: hasIgnoredParent(span),
  }));

  // Calculate timeline parameters
  const startTime = Math.min(...filteredSpans.map((s) => s.start.getTime()));
  const endTime = Math.max(
    ...filteredSpans.map((s) => s.start.getTime() + s.duration_seconds * 1000),
  );
  const totalDuration = endTime - startTime;

  const getBarStyles = (span: AISpan) => {
    const spanStart = span.start.getTime();
    const left = ((spanStart - startTime) / totalDuration) * 100;
    const width = ((span.duration_seconds * 1000) / totalDuration) * 100;

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  };

  const BAR_HEIGHT = 28;
  const ROW_HEIGHT = 40;
  const INDENT_WIDTH = 20;

  // Find a common trace ID for all spans (most spans should have the same trace ID)
  const traceId = spans.length > 0 ? spans[0].trace_id : undefined;

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between items-center gap-4">
        <SearchBar
          {...searchBarProps}
          placeholder="Search trace"
          className="flex-grow text-content-tertiary"
          autoFocus
        />
        {traceId && <TraceLink traceID={traceId} />}
      </div>
      <div className="flex w-full gap-2">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0 pr-4 border-r border-slate-100">
          {hierarchicalSpans.map((span) => {
            if (span.type === "prompt" || span.type === "copilot_interaction") {
              return (
                <div
                  key={span.id}
                  className="text-sm"
                  style={{
                    height: `${ROW_HEIGHT}px`,
                    paddingLeft: `${span.depth * INDENT_WIDTH}px`,
                  }}
                />
              );
            }

            return (
              <div
                key={span.id}
                className={tcx(
                  "text-sm flex flex-col justify-center",
                  span.hasIgnoredParent && "opacity-60",
                )}
                style={{
                  height: `${ROW_HEIGHT}px`,
                  paddingLeft: `${span.depth * INDENT_WIDTH}px`,
                }}
              >
                <div className="font-medium flex items-center gap-2">
                  <Icon
                    id={getSpanIcon(span.type)}
                    size={IconSize.Small}
                    className="flex-shrink-0"
                  />
                  <Tooltip content={span.name}>
                    <span className="truncate">{span.name}</span>
                  </Tooltip>
                </div>
                <div className="text-xs text-content-tertiary">
                  {span.duration_seconds.toFixed(2)}s
                  {span.ai_request_cost_cents &&
                    ` | $${(span.ai_request_cost_cents / 100).toFixed(4)}`}
                </div>
              </div>
            );
          })}
        </div>

        {/* Timeline */}
        <div className="flex-grow">
          <div
            className="relative"
            style={{ height: `${hierarchicalSpans.length * ROW_HEIGHT}px` }}
          >
            {hierarchicalSpans.map((span, index) => {
              const styles = getBarStyles(span);
              const isPrompt = span.type === "prompt";
              const matchesSearch = matchingSpanIDs.includes(span.id);
              const isSearching = searchBarProps.value !== "";
              const shouldBeBackgrounded = isSearching && !matchesSearch;

              return (
                <div
                  key={span.id}
                  className="absolute"
                  style={{
                    ...styles,
                    height: `${BAR_HEIGHT}px`,
                    top: `${
                      index * ROW_HEIGHT + (ROW_HEIGHT - BAR_HEIGHT) / 2
                    }px`,
                  }}
                >
                  <div
                    className={tcx(
                      "h-full transition relative flex items-center px-2 text-content-primary gap-2 text-xs",
                      getSpanColor(span, shouldBeBackgrounded),
                      isPrompt
                        ? "border-t border-x border-slate-400 text-content-tertiary"
                        : "rounded cursor-pointer group",
                    )}
                    onClick={() => !isPrompt && setSelectedSpan(span)}
                  >
                    <Icon
                      id={getSpanIcon(span.type)}
                      size={IconSize.Small}
                      className="flex-shrink-0"
                    />
                    <span
                      className={
                        isPrompt || shouldBeBackgrounded ? "" : "font-medium"
                      }
                    >
                      {span.name}
                    </span>
                    {!isPrompt && (
                      <>
                        {span.execution_type !== "normal" && (
                          <Badge
                            theme={BadgeTheme.Secondary}
                            size={BadgeSize.ExtraSmall}
                          >
                            Speculative
                          </Badge>
                        )}
                        <Icon
                          id={IconEnum.View}
                          size={IconSize.Small}
                          className="opacity-0 group-hover:opacity-100 transition ml-1"
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const getSpanHierarchy = (spans: AISpan[]): SpanWithDepth[] => {
  // Create map of parent IDs to child spans
  const childMap = new Map<string, AISpan[]>();
  // Create set of all span IDs for quick lookup
  const spanIds = new Set(spans.map((span) => span.id));

  spans.forEach((span) => {
    if (span.parent_id) {
      const children = childMap.get(span.parent_id) || [];
      children.push(span);
      childMap.set(span.parent_id, children);
    }
  });

  const shouldIncludeSpan = (span: AISpan): boolean => {
    const children = childMap.get(span.id) || [];
    // If this is a prompt with exactly one completion_request child, skip it
    if (span.type === "prompt" && children.length === 1) {
      return !children[0].type.includes("completion_request");
    }
    return true;
  };

  // Helper function to sort spans with same timestamp
  const sortSpans = (a: AISpan, b: AISpan): number => {
    // First sort by timestamp
    const timeA = a.start.getTime();
    const timeB = b.start.getTime();
    if (timeA !== timeB) return timeA - timeB;

    // If timestamps are equal, sort by type
    if (a.type !== b.type) {
      // Put completion_request above tool_call
      if (a.type === "completion_request") return -1;
      if (b.type === "completion_request") return 1;
      return a.type.localeCompare(b.type);
    }

    // If types are equal, sort by name
    return a.name.localeCompare(b.name);
  };

  const assignDepth = (span: AISpan, depth: number): SpanWithDepth[] => {
    const children = childMap.get(span.id) || [];
    const currentSpan = shouldIncludeSpan(span) ? [{ ...span, depth }] : [];

    // Sort children before processing
    const sortedChildren = [...children].sort(sortSpans);

    return [
      ...currentSpan,
      ...sortedChildren.flatMap((child) =>
        assignDepth(child, depth + (currentSpan.length ? 1 : 0)),
      ),
    ];
  };

  // Find root spans - those whose parent is not in our set of spans
  const rootSpans = spans
    .filter((span) => !span.parent_id || !spanIds.has(span.parent_id))
    .sort(sortSpans);

  return rootSpans.flatMap((span) => assignDepth(span, 0));
};

export const formatSpanResult = (result: unknown): string => {
  if (typeof result === "string") {
    return result;
  }

  if (result == null) {
    return "";
  }

  // Handle common result structures we know about
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultObj = result as any;
  if (resultObj.content && typeof resultObj.content === "string") {
    return resultObj.content;
  }

  // For other objects, return a formatted JSON string
  return JSON.stringify(result, null, 2);
};
