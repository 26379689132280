/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecard,
    EvaluationScorecardFromJSON,
    EvaluationScorecardFromJSONTyped,
    EvaluationScorecardToJSON,
} from './EvaluationScorecard';

/**
 * 
 * @export
 * @interface AIStaffListEvaluationScorecardsResponseBody
 */
export interface AIStaffListEvaluationScorecardsResponseBody {
    /**
     * Collection of scorecards, ordered by graded_at descending
     * @type {Array<EvaluationScorecard>}
     * @memberof AIStaffListEvaluationScorecardsResponseBody
     */
    scorecards: Array<EvaluationScorecard>;
}

export function AIStaffListEvaluationScorecardsResponseBodyFromJSON(json: any): AIStaffListEvaluationScorecardsResponseBody {
    return AIStaffListEvaluationScorecardsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListEvaluationScorecardsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListEvaluationScorecardsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scorecards': ((json['scorecards'] as Array<any>).map(EvaluationScorecardFromJSON)),
    };
}

export function AIStaffListEvaluationScorecardsResponseBodyToJSON(value?: AIStaffListEvaluationScorecardsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scorecards': ((value.scorecards as Array<any>).map(EvaluationScorecardToJSON)),
    };
}

