import { tcx } from "src/utils/tailwind-classes";

export const OnCallNotificationBox = ({
  children,
  description,
  title,
  className,
  button,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
  className?: string;
  button?: React.ReactNode;
}) => {
  return (
    <div className="w-full bg-slate-50 rounded-2 border border-stroke flex-col inline-flex">
      <div className={"flex flex-row w-full p-4 items-center"}>
        <div className="flex-1 flex flex-col gap-2">
          <div className="text-content-primary text-base font-semibold">
            {title}
          </div>
          <div className="text-slate-600 text-sm font-normal">
            {description}
          </div>
        </div>
        {button}
      </div>
      <div
        className={tcx(
          "w-full px-4 pb-4 rounded-b-lg flex-col flex",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
