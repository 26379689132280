import {
  Badge,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";

export const ActivityBadge = ({
  icon,
  value,
  previousValue,
  tooltipContent,
  forceShowDiff,
}: {
  icon: IconEnum;
  value: string;
  previousValue?: string;
  tooltipContent?: string;
  forceShowDiff?: boolean;
}): React.ReactElement => {
  let content: React.ReactNode;
  let fallbackTooltip: string;

  // If there is no previous value, assume the caller just wants to show the current value.
  if (!previousValue || (value === previousValue && !forceShowDiff)) {
    content = (
      <>
        <Icon id={icon} size={IconSize.Small} />
        <span className="truncate">{value}</span>{" "}
      </>
    );
    fallbackTooltip = value;
  } else {
    content = (
      <>
        <span className="line-through truncate text-content-secondary">
          {previousValue}
        </span>
        <span className="text-content-secondary"> → </span>
        <Icon id={icon} size={IconSize.Small} />
        <span className="truncate">{value}</span>
      </>
    );
    fallbackTooltip = `${previousValue} → ${value}`;
  }

  const tooltip = tooltipContent ?? fallbackTooltip;

  return (
    <Tooltip content={tooltip}>
      <Badge theme={BadgeTheme.Tertiary} className="max-w-full">
        {content}
      </Badge>
    </Tooltip>
  );
};
