import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { Actor, IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemSlackInferSentry = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.slack_infer_sentry) {
    throw new Error(
      "malformed timeline item: slack_infer_sentry was missing slack_infer_sentry field",
    );
  }

  const actor: Actor = {
    user: item.content.slack_infer_sentry.user,
  };

  return {
    actor: actor,
    icon: IconEnum.Sentry,
    colour: ColorPaletteEnum.Purple,
    title: "Sentry link mentioned",
    quotedContent: (
      <Markdown>{item.content.slack_infer_sentry.slack_message.text}</Markdown>
    ),
  };
};
