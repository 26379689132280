import { StatusPageCreateMigrationRequestBodyTypeEnum } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GenericErrorMessage, Link, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { PollMigrationModal } from "src/components/status-pages/atlassian-import/PollMigrationModal";
import { useMutationV2 } from "src/utils/swr";

import { FormProps } from "./DemoMagicRoute";

export const ImportStatuspageForm = ({ onClose, appendLogs }: FormProps) => {
  const [migrationId, setMigrationId] = useState<string | null>(null);
  const formMethods = useForm<{ page_url: string }>();
  const {
    trigger,
    isMutating: loading,
    genericError,
  } = useMutationV2(
    async (apiClient, payload) => {
      const res = await apiClient.statusPageCreateMigration({
        createMigrationRequestBody: {
          type: StatusPageCreateMigrationRequestBodyTypeEnum.DemoImport,
          ...payload,
        },
      });
      setMigrationId(res.migration.id);
    },
    { setError: formMethods.setError, invalidate: [] },
  );

  if (migrationId != null) {
    return (
      <PollMigrationModal
        migrationId={migrationId}
        verb="importing"
        onClose={onClose}
        onSuccess={(page) => {
          appendLogs([
            "Imported page!",
            <Link
              key="configure"
              to={`/status-pages/${page.id}`}
              analyticsTrackingId={null}
            >
              Configure page
            </Link>,
            <Link
              key="open"
              href={page.public_url}
              openInNewTab
              analyticsTrackingId={null}
            >
              View page
            </Link>,
          ]);

          onClose();
        }}
      />
    );
  }

  return (
    <Form.Modal
      title="Import Statuspage"
      analyticsTrackingId={null}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={trigger}
      loading={loading}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText="Import"
        />
      }
    >
      {genericError && <GenericErrorMessage />}
      <InputV2
        required
        formMethods={formMethods}
        name={"page_url"}
        type={InputType.Url}
        label="Page URL"
        placeholder="https://status.example.com/"
        helptext={
          "The URL where you can find the public Atlassian Statuspage. E.g. https://status.incident.io/, or https://figma-status.com/"
        }
      />
    </Form.Modal>
  );
};
