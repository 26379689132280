/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamSettings,
    TeamSettingsFromJSON,
    TeamSettingsFromJSONTyped,
    TeamSettingsToJSON,
} from './TeamSettings';

/**
 * 
 * @export
 * @interface TeamsGetSettingsResponseBody
 */
export interface TeamsGetSettingsResponseBody {
    /**
     * 
     * @type {TeamSettings}
     * @memberof TeamsGetSettingsResponseBody
     */
    team_settings: TeamSettings;
}

export function TeamsGetSettingsResponseBodyFromJSON(json: any): TeamsGetSettingsResponseBody {
    return TeamsGetSettingsResponseBodyFromJSONTyped(json, false);
}

export function TeamsGetSettingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsGetSettingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team_settings': TeamSettingsFromJSON(json['team_settings']),
    };
}

export function TeamsGetSettingsResponseBodyToJSON(value?: TeamsGetSettingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team_settings': TeamSettingsToJSON(value.team_settings),
    };
}

