/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestigationConfig
 */
export interface InvestigationConfig {
    /**
     * Mode used to auto-run investigations
     * @type {string}
     * @memberof InvestigationConfig
     */
    auto_run_mode: InvestigationConfigAutoRunModeEnum;
    /**
     * Global kill switch for all investigations
     * @type {boolean}
     * @memberof InvestigationConfig
     */
    enabled: boolean;
}

/**
* @export
* @enum {string}
*/
export enum InvestigationConfigAutoRunModeEnum {
    Off = 'off',
    Silent = 'silent',
    On = 'on'
}

export function InvestigationConfigFromJSON(json: any): InvestigationConfig {
    return InvestigationConfigFromJSONTyped(json, false);
}

export function InvestigationConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_run_mode': json['auto_run_mode'],
        'enabled': json['enabled'],
    };
}

export function InvestigationConfigToJSON(value?: InvestigationConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_run_mode': value.auto_run_mode,
        'enabled': value.enabled,
    };
}

