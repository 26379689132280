import { usePylon } from "@bolasim/react-use-pylon";
import { IntercomConfig, StatusPagePageTypeEnum } from "@incident-io/api";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  Link,
} from "@incident-ui";
import { StaticSingleSelectWithObj } from "@incident-ui/Select/StaticSingleSelect";
import React from "react";
import { Form } from "src/components/@shared/forms";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

const intercomAppLink = (config: IntercomConfig) => {
  const hasDataStorageRegion = config.intercom_data_storage_region !== "US";

  return hasDataStorageRegion
    ? `https://app.${config.intercom_data_storage_region.toLowerCase()}.intercom.com/a/apps/${
        config.intercom_workspace_id
      }`
    : `https://app.intercom.com/a/apps/${config.intercom_workspace_id}`;
};

export const IntercomConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const { data, isLoading, error } = useAPI(
    "integrationsIntercomGetConfig",
    undefined,
  );

  const { trigger: setSelectedStatusPage, isMutating: saving } = useAPIMutation(
    "integrationsIntercomGetConfig",
    undefined,
    async (apiClient, { statusPageID }) => {
      await apiClient.integrationsIntercomSetStatusPage({
        setStatusPageRequestBody: {
          selected_status_page: statusPageID,
        },
      });
    },
    {
      showErrorToast: "Updating status page selection",
    },
  );

  const {
    data: statusPageData,
    isLoading: statusPageIsLoading,
    error: statusPageError,
  } = useAPI("statusPageList", undefined);

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  if (error || integrationsError || statusPageError) {
    return <GenericErrorMessage error={error} />;
  }

  if (
    !data ||
    isLoading ||
    integrationsLoading ||
    !integrations ||
    !statusPageData ||
    statusPageIsLoading
  ) {
    return <IntegrationDrawerContentLoader />;
  }

  const hasStatusPages = statusPageData.status_pages.length > 0;

  const statusPageOptions = statusPageData.status_pages
    .filter((sp) => sp.page_type !== StatusPagePageTypeEnum.Customer)
    .map((statusPage) => ({
      value: statusPage.id,
      label: statusPage.name,
    }));

  const selectedStatusPageSelectOpt = statusPageOptions.find(
    (opt) => opt.value === data.config?.selected_status_page,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      {data.config ? (
        <Callout theme={CalloutTheme.Info}>
          You&rsquo;re connected to the{" "}
          <Link
            href={intercomAppLink(data.config)}
            openInNewTab
            analyticsTrackingId={"intercom_app_link"}
          >
            <span className="font-semibold hover:underline">
              {data.config.intercom_workspace_name}
            </span>
          </Link>{" "}
          workspace.
        </Callout>
      ) : null}

      {hasStatusPages ? (
        <ContentBox className="p-4">
          <Form.Label
            htmlFor="status_page_select"
            className="flex items-center gap-2"
          >
            Status page
            {saving && <span className="text-xs text-gray-500">Saving...</span>}
          </Form.Label>
          <Form.Helptext>
            <span className="mb-1">
              Choose which status page to share with your customers and support
              staff in Intercom. For more information, see our{" "}
              <Button
                onClick={() => showArticle("intercom-status-page")}
                analyticsTrackingId="configure-intercom-help"
                theme={ButtonTheme.Link}
                className="underline"
              >
                help center
              </Button>
              .
            </span>
          </Form.Helptext>
          <StaticSingleSelectWithObj
            id="status_page_select"
            value={selectedStatusPageSelectOpt}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedStatusPage({
                  statusPageID: selectedOption.value,
                });
              } else {
                setSelectedStatusPage({
                  statusPageID: null,
                });
              }
            }}
            options={statusPageOptions}
            placeholder={"Select status page..."}
            isClearable={true}
            className="grow"
            disabled={
              saving ||
              (selectedStatusPageSelectOpt !== undefined &&
                statusPageOptions.length === 1)
            }
          />
        </ContentBox>
      ) : (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.Info}
          title="No status pages available"
          subtitle={
            <>
              You need to create a status page before you can share it with
              Intercom. You can create one in the{" "}
              <OrgAwareNavLink
                className="underline"
                to="/status-pages"
                target="_blank"
              >
                status pages
              </OrgAwareNavLink>{" "}
              section.
            </>
          }
        />
      )}
    </GenericConfigureDrawerContents>
  );
};
