import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useMutationV2 } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

export const DeleteOverrideModal = ({
  onClose,
  overrideID,
}: {
  overrideID: string;
  onClose: () => void;
}) => {
  const refetchSchedules = useRevalidate([
    "schedulesList",
    "schedulesListEntries",
    "schedulesListForUser",
    "schedulesShow",
  ]);

  const { trigger: deleteOverride, isMutating: isDeleting } = useMutationV2(
    async (apiClient, { overrideID }: { overrideID: string }) => {
      await apiClient.schedulesArchiveOverride({
        id: overrideID,
      });
      await refetchSchedules();
    },
    {
      invalidate: [],
      showSuccessToast: "Override deleted",
    },
  );

  return (
    <DeletionConfirmationModal
      onClose={onClose}
      onDelete={async () => {
        await deleteOverride({
          overrideID,
        });
        onClose();
      }}
      resourceTitle="Override"
      isOpen={true}
      title="Delete override"
      analyticsTrackingId="delete-override-modal"
      deleteConfirmationContent="Are you sure you want to delete this override?"
      isDeleting={isDeleting}
    />
  );
};
