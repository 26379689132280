import { Heading } from "@incident-ui";
import { createContext, useContext } from "react";
import { tcx } from "src/utils/tailwind-classes";

interface SharedProps {
  variant?: "default" | "compact";
}

const TableContext = createContext<SharedProps & { hasHeader: boolean }>({
  hasHeader: false,
});

export interface TableProps<T> extends SharedProps {
  data: T[];
  title?: string;
  header?:
    | React.ReactElement<StaffRoomCellSharedProps>
    | React.ReactElement<StaffRoomCellSharedProps>[];
  widths?: string[];
  renderRow: (
    row: T,
    idx: number,
  ) => React.ReactElement<StaffRoomTableRowProps>;
  emptyState?: React.ReactNode;
}

export const StaffRoomTable = <T,>({
  data,
  title,
  header,
  widths,
  renderRow,
  emptyState,
  variant,
}: TableProps<T>) => {
  return (
    <div className="flex flex-col gap-y-2">
      {title && (
        <Heading level={2} size="medium">
          {title}
        </Heading>
      )}
      <div className="border border-stroke-primary rounded-md shadow-sm overflow-hidden">
        {data.length === 0 && emptyState ? (
          <div className="p-8 flex flex-row items-center justify-center text-content-tertiary">
            {emptyState}
          </div>
        ) : (
          <TableContext.Provider value={{ variant, hasHeader: !!header }}>
            <table className="w-full">
              {widths && (
                <colgroup>
                  {widths.map((w, idx) => (
                    <col key={idx} className={w} />
                  ))}
                </colgroup>
              )}
              {header && (
                <thead>
                  <tr>{header}</tr>
                </thead>
              )}
              <tbody>{data.map((d, idx) => renderRow(d, idx))}</tbody>
            </table>
          </TableContext.Provider>
        )}
      </div>
    </div>
  );
};

type StaffRoomCellSharedProps = {
  children: React.ReactNode;
  className?: string;
};

const StaffRoomTableCell = ({
  children,
  className,
}: StaffRoomCellSharedProps) => {
  const { variant } = useContext(TableContext);

  return (
    <td
      className={tcx(
        {
          "px-4 py-2": true,
          "px-2 py-2": variant === "compact",
        },
        className,
      )}
    >
      {children}
    </td>
  );
};

const StaffRoomTableHeader = ({
  children,
  className,
}: StaffRoomCellSharedProps) => {
  const { variant } = useContext(TableContext);

  return (
    <th
      className={tcx(
        {
          "px-4 py-2": true,
          "px-2 py-2": variant === "compact",
        },
        "border-surface-secondary",
        "text-content-tertiary text-left font-normal",
        className,
      )}
    >
      {children}
    </th>
  );
};

type StaffRoomTableRowProps = {
  children:
    | React.ReactElement<StaffRoomCellSharedProps>
    | React.ReactElement<StaffRoomCellSharedProps>[];
};

const StaffRoomTableRow = ({ children }: StaffRoomTableRowProps) => {
  const { hasHeader } = useContext(TableContext);

  return (
    <tr
      className={tcx("border-1px border-t border-stroke-secondary", {
        "first:border-none": !hasHeader,
      })}
    >
      {children}
    </tr>
  );
};

StaffRoomTable.Row = StaffRoomTableRow;
StaffRoomTable.Cell = StaffRoomTableCell;
StaffRoomTable.Header = StaffRoomTableHeader;
