import {
  AIStaffAggregateEvaluationScorecardsResponseBody,
  EvaluationAggregateScorecard,
  EvaluationBacktestEntry,
} from "@incident-io/api";
import {
  BadgeSize,
  Button,
  ContentBox,
  EmptyState,
  IconEnum,
} from "@incident-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutationV2 } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useFilterEntries } from "../common/EvaluationFilterContext";
import { EvaluationScorecardGrades } from "../scorecards/EvaluationScorecardGrades";

type EvaluationAggregateScorecardControllerProps = {
  scorecardIDs: string[];
  compareWithScorecardIDs?: string[];
  allEntries: EvaluationBacktestEntry[];
  showFilterBar?: boolean;
  compact?: boolean;
  horizontal?: boolean;
  className?: string;
};

export const EvaluationAggregateScorecardController = ({
  scorecardIDs,
  compareWithScorecardIDs = [],
  allEntries,
  className,
  compact = false,
  horizontal = false,
  showFilterBar = true,
}: EvaluationAggregateScorecardControllerProps) => {
  const [data, setData] =
    useState<AIStaffAggregateEvaluationScorecardsResponseBody | null>(null);
  const [comparison, setComparisonData] =
    useState<AIStaffAggregateEvaluationScorecardsResponseBody | null>(null);

  // Silly hacks to stop the scorecard repeatedly re-rendering.
  const scorecardsString = JSON.stringify(scorecardIDs);
  const memoizedScorecardIDs = useMemo(
    () => scorecardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scorecardsString],
  );

  const compareWithScorecardString = JSON.stringify(compareWithScorecardIDs);
  const memoizedCompareWithIDs = useMemo(
    () => compareWithScorecardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [compareWithScorecardString],
  );

  const { trigger: aggregateScorecard, isMutating: isLoading } = useMutationV2(
    async (apiClient, scorecard_ids: string[]) => {
      return await apiClient.aIStaffAggregateEvaluationScorecards({
        aggregateEvaluationScorecardsRequestBody: {
          evaluation_scorecard_ids: scorecard_ids,
        },
      });
    },
    {
      showErrorToast: "Failed to aggregate scorecards",
      invalidate: [],
    },
  );

  // Trigger the aggregation when the component mounts or IDs change
  const aggregate = useCallback(async () => {
    const result = await aggregateScorecard(memoizedScorecardIDs);
    if (result) {
      setData(result);
    }

    if (memoizedCompareWithIDs.length > 0) {
      const comparisonResult = await aggregateScorecard(memoizedCompareWithIDs);
      if (comparisonResult) {
        setComparisonData(comparisonResult);
      }
    }
  }, [
    aggregateScorecard,
    memoizedScorecardIDs,
    memoizedCompareWithIDs,
    setData,
    setComparisonData,
  ]);

  useEffect(() => {
    if (memoizedScorecardIDs.length === 0) {
      return;
    }
    aggregate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    memoizedScorecardIDs,
    memoizedCompareWithIDs,
    setData,
    setComparisonData,
  ]);

  return (
    <EvaluationAggregateScorecardSection
      scorecard={data?.aggregate_scorecard}
      comparison={comparison?.aggregate_scorecard}
      isLoading={isLoading}
      onRefresh={() => aggregateScorecard(scorecardIDs)}
      totalScorecards={scorecardIDs.length}
      className={className}
      allEntries={allEntries}
      showFilterBar={showFilterBar}
      compact={compact}
      horizontal={horizontal}
    />
  );
};

export type EvaluationAggregateScorecardSectionProps = {
  scorecard?: EvaluationAggregateScorecard;
  comparison?: EvaluationAggregateScorecard;
  allEntries: EvaluationBacktestEntry[];
  isLoading: boolean;
  totalScorecards: number;
  onRefresh: () => void;
  showFilterBar: boolean;
  compact?: boolean;
  horizontal?: boolean;
  className?: string;
};

export const EvaluationAggregateScorecardSection = ({
  scorecard,
  comparison,
  isLoading,
  allEntries,
  totalScorecards,
  showFilterBar,
  onRefresh,
  compact = false,
  horizontal = false,
  className,
}: EvaluationAggregateScorecardSectionProps) => {
  const filterEntries = useFilterEntries();

  // We want to find all the events that are in our 'superset' -> i.e.
  // excluding our non-grade filters, but ignoring our grade filters.
  // This allows us to display the distribution of the 'whole set' of events
  // so you can choose the right part of the distribution to filter on.
  const allEntriesWithNonGradeFilters = filterEntries(allEntries, {
    gradeFilters: {},
  });
  const allEvents = allEntriesWithNonGradeFilters.flatMap(
    (e) => e.evaluation_scorecard?.grades.flatMap((g) => g.events) || [],
  );

  if (isLoading && !scorecard) {
    return (
      <div className={className}>
        <EmptyState
          title="Loading aggregate scorecard"
          content={`Aggregating ${totalScorecards} scorecards`}
          icon={IconEnum.Chart}
          className={compact ? "p-0 border-none shadow-none" : ""}
        />
      </div>
    );
  }

  const hasAnyScorecards = totalScorecards > 0;

  if (!scorecard || !hasAnyScorecards) {
    return (
      <div className={className}>
        <EmptyState
          title="No scorecards available"
          content="No scorecards are available to be aggregated"
          icon={IconEnum.Chart}
          className={compact ? "p-0 border-none shadow-none" : ""}
        />
      </div>
    );
  }

  return (
    <ContentBox
      className={tcx(compact ? "border-none shadow-none p-0" : "", className)}
    >
      {!compact && (
        <div className="flex justify-between items-center p-4 border-b rounded-t-2 border-stroke text-xs-bold bg-surface-secondary">
          <div>
            Aggregating {totalScorecards} scorecards
            {comparison && (
              <>
                , comparing against {comparison.evaluation_scorecard_ids.length}{" "}
                scorecards
              </>
            )}
          </div>
          <Button
            analyticsTrackingId={null}
            loading={isLoading}
            onClick={onRefresh}
            size={BadgeSize.ExtraSmall}
            icon={IconEnum.Refresh}
          >
            Refresh
          </Button>
        </div>
      )}
      <EvaluationScorecardGrades
        grades={scorecard.grades}
        comparison={comparison?.grades}
        warnings={scorecard.warnings}
        showFilterBar={showFilterBar}
        allEvents={allEvents}
        compact={compact}
        horizontal={horizontal}
        showGradeNames
      />
    </ContentBox>
  );
};
