/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteChannelTargetPayload,
    AlertRouteChannelTargetPayloadFromJSON,
    AlertRouteChannelTargetPayloadFromJSONTyped,
    AlertRouteChannelTargetPayloadToJSON,
} from './AlertRouteChannelTargetPayload';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface AlertRouteChannelConfigPayload
 */
export interface AlertRouteChannelConfigPayload {
    /**
     * The conditions that must be met for this channel config to be used
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRouteChannelConfigPayload
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {AlertRouteChannelTargetPayload}
     * @memberof AlertRouteChannelConfigPayload
     */
    ms_teams_targets?: AlertRouteChannelTargetPayload;
    /**
     * 
     * @type {AlertRouteChannelTargetPayload}
     * @memberof AlertRouteChannelConfigPayload
     */
    slack_targets?: AlertRouteChannelTargetPayload;
}

export function AlertRouteChannelConfigPayloadFromJSON(json: any): AlertRouteChannelConfigPayload {
    return AlertRouteChannelConfigPayloadFromJSONTyped(json, false);
}

export function AlertRouteChannelConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteChannelConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'ms_teams_targets': !exists(json, 'ms_teams_targets') ? undefined : AlertRouteChannelTargetPayloadFromJSON(json['ms_teams_targets']),
        'slack_targets': !exists(json, 'slack_targets') ? undefined : AlertRouteChannelTargetPayloadFromJSON(json['slack_targets']),
    };
}

export function AlertRouteChannelConfigPayloadToJSON(value?: AlertRouteChannelConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'ms_teams_targets': AlertRouteChannelTargetPayloadToJSON(value.ms_teams_targets),
        'slack_targets': AlertRouteChannelTargetPayloadToJSON(value.slack_targets),
    };
}

