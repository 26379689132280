import {
  CallSession,
  IncidentCallSessionWithSummary,
  SelectOption,
} from "@incident-io/api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  PopoverSingleSelect,
  Tooltip,
} from "@incident-ui";

import { formatTimestampLocale } from "../../utils/datetime";

export const CallSessionSelector = ({
  callSessions,
  selectedSession,
  onSelectSession,
}: {
  callSessions: IncidentCallSessionWithSummary[];
  selectedSession: CallSession;
  onSelectSession?: (sessionId: string) => void;
}) => {
  // Build a list of call sessions into the popover format
  const callSessionOptions = callSessions.map((s, index): SelectOption => {
    // for each one build the label of the right dates
    const label = durationLabelForSession({ callSession: s.call_session });
    return {
      label,
      value: s.call_session.id,
      sort_key: `${index}`,
    };
  });

  const sessionDefinition =
    "A session is a distinct period when the incident call was active.";

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="flex gap-1 text-content-secondary text-xs-med">
        Session
        <Tooltip
          content={
            callSessions.length > 1 ? (
              <div className="flex flex-col gap-2">
                <div>{sessionDefinition}</div>
                <div>
                  This incident had multiple call sessions. Select a session to
                  view its notes.
                </div>
              </div>
            ) : (
              <>{sessionDefinition}</>
            )
          }
        />
      </div>
      <div className="flex items-start gap-1">
        {callSessions.length > 1 && onSelectSession ? (
          <PopoverSingleSelect
            options={callSessionOptions}
            value={selectedSession.id}
            isClearable={false}
            onChange={(sessionId) => onSelectSession(sessionId ?? "")}
            triggerClassName={"p-0 m-0"}
            renderTriggerNode={({ onClick, selectedOption }) => {
              return (
                <Button
                  onClick={onClick}
                  theme={ButtonTheme.Secondary}
                  analyticsTrackingId={null}
                  title={selectedOption?.label || "Choose"}
                  size={BadgeSize.Medium}
                >
                  {selectedOption?.label || "Choose"}
                  <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
                </Button>
              );
            }}
          />
        ) : (
          <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Medium}>
            {durationLabelForSession({ callSession: selectedSession })}
          </Badge>
        )}
        {selectedSession.started_at && !selectedSession.ended_at && (
          <Badge theme={BadgeTheme.Error} size={BadgeSize.Medium}>
            <AlertFiringIndicator firing={true} />
            Live
          </Badge>
        )}
      </div>
    </div>
  );
};

const durationLabelForSession = ({
  callSession,
}: {
  callSession: CallSession;
}): string => {
  const localCallStartDate = formatTimestampLocale({
    timestamp: callSession.started_at,
    dateStyle: "short",
  });

  const localCallStartTime = formatTimestampLocale({
    timestamp: callSession.started_at,
    timeStyle: "short",
  });

  if (callSession.ended_at) {
    const localCallEndDate = formatTimestampLocale({
      timestamp: callSession.ended_at,
      dateStyle: "short",
    });

    const localCallEndTime = formatTimestampLocale({
      timestamp: callSession.ended_at,
      timeStyle: "short",
    });

    if (localCallStartDate === localCallEndDate) {
      return `${localCallStartDate} from ${localCallStartTime} to ${localCallEndTime}`;
    } else {
      return `${localCallStartDate} at ${localCallStartTime} until ${localCallEndDate} at ${localCallEndTime}`;
    }
  } else {
    return `${localCallStartDate} at ${localCallStartTime}`;
  }
};
