import {
  EvaluationBacktestBacktestTypeEnum,
  EvaluationDatasetDatasetTypeEnum,
} from "@incident-io/api";
import { SearchGroundTruth } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Input,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BacktestCreateModal } from "../backtests/BacktestCreateModal";
import { CurateDatasetModal } from "../datasets/CurateDatasetModal";
import { VerifiedButton } from "../ground-truths/VerifiedButton";

type SearchGroundTruthFilterValues = {
  incidentId: string;
  questionType: string;
  verified: string;
};

export const SearchGroundTruthsListPage: React.FC = () => {
  return (
    <WorkbenchSubPageWrapper>
      <SearchGroundTruthsList />
    </WorkbenchSubPageWrapper>
  );
};

export const SearchGroundTruthsList = ({
  datasetId,
}: {
  datasetId?: string;
}) => {
  const formMethods = useForm<SearchGroundTruthFilterValues>({
    defaultValues: {},
  });

  const [showFilters, setShowFilters] = useState(false);
  const [selectedGroundTruths, setSelectedGroundTruths] = useState<string[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filters = formMethods.watch();

  const { data, isLoading } = useAPI("aIStaffListSearchGroundTruths", {
    incidentId: filters.incidentId ?? undefined,
    questionType: filters.questionType ?? undefined,
    datasetId: datasetId,
    verified:
      filters.verified === "true"
        ? true
        : filters.verified === "false"
        ? false
        : undefined,
  });

  const toggleGroundTruth = (groundTruthId: string) => {
    if (selectedGroundTruths.includes(groundTruthId)) {
      setSelectedGroundTruths(
        selectedGroundTruths.filter((id) => id !== groundTruthId),
      );
    } else {
      setSelectedGroundTruths([...selectedGroundTruths, groundTruthId]);
    }
  };

  const invalidateListSearches = useRevalidate([
    "aIStaffListSearchGroundTruths",
  ]);

  const [showAddToDatasetMode, setShowAddToDatasetMode] = useState<
    "add" | "remove" | null
  >(null);

  const [showCreateBacktest, setShowCreateBacktest] = useState<boolean>(false);

  // Apply frontend search filter
  const filteredGroundTruths = useMemo(() => {
    const allGroundTruths = data?.ground_truths || [];

    if (!searchTerm.trim()) {
      return allGroundTruths;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();

    return allGroundTruths.filter((groundTruth) =>
      groundTruth.question.toLowerCase().includes(lowerSearchTerm),
    );
  }, [data?.ground_truths, searchTerm]);

  return (
    <>
      {showAddToDatasetMode && (
        <CurateDatasetModal
          datasetType={EvaluationDatasetDatasetTypeEnum.SearchCodeChanges}
          resourceIDs={selectedGroundTruths}
          overrideDatasetID={
            showAddToDatasetMode === "remove" ? datasetId : undefined
          }
          mode={showAddToDatasetMode}
          onClose={() => setShowAddToDatasetMode(null)}
          onSuccess={() => {
            invalidateListSearches();
            setShowAddToDatasetMode(null);
          }}
        />
      )}

      {showCreateBacktest && (
        <BacktestCreateModal
          // Set the backtest type based on the search ground truth question type
          overrideBacktestType={
            filteredGroundTruths.find((gt) =>
              selectedGroundTruths.includes(gt.id),
            )?.question_type === "code-changes"
              ? EvaluationBacktestBacktestTypeEnum.SearchCodeChanges
              : EvaluationBacktestBacktestTypeEnum.SearchIncidents
          }
          resourceIDs={selectedGroundTruths}
          onClose={() => setShowCreateBacktest(false)}
        />
      )}
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex flex-row items-center gap-4 mb-4">
          <div className="flex-center-y w-full justify-between">
            <div className="flex-center-y gap-4">
              <h2 className="text-lg font-semibold">Search Ground Truths</h2>
            </div>
            <div className="flex items-center gap-2">
              <div className="relative">
                <Input
                  id="search"
                  placeholder="Search by question..."
                  value={searchTerm}
                  onChange={(e) =>
                    setSearchTerm((e.target as HTMLInputElement).value)
                  }
                  className="w-64"
                />
              </div>
              <Button
                theme={ButtonTheme.Naked}
                onClick={() => setShowFilters(!showFilters)}
                analyticsTrackingId={null}
                icon={IconEnum.Filter}
              >
                <>Filters</>
                <Icon
                  id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                  size={IconSize.Small}
                />
              </Button>
            </div>
          </div>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false} className="mb-4">
            <div className="w-full space-y-2">
              <InputV2
                formMethods={formMethods}
                name="incidentId"
                label="Incident ID"
                placeholder="(e.g. 01FCNDV6P870EA6S7TK1DSYDG0)"
              />
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="questionType"
                placeholder="Select question type"
                options={[
                  { label: "Incident", value: "incident" },
                  { label: "Code Changes", value: "code-changes" },
                ]}
                label={"Question Type"}
                isClearable={true}
              />
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="verified"
                placeholder="Verification status"
                options={[
                  { label: "Verified", value: "true" },
                  { label: "Unverified", value: "false" },
                ]}
                label={"Verification Status"}
                isClearable={true}
              />
            </div>
          </Callout>
        )}
        <div className="flex items-center justify-between gap-4 mb-2">
          <div className="flex items-center gap-4">
            <Button
              icon={
                selectedGroundTruths.length === filteredGroundTruths.length &&
                filteredGroundTruths.length > 0
                  ? IconEnum.FakeCheckboxChecked
                  : IconEnum.FakeCheckbox
              }
              className={"hover:bg-surface-tertiary"}
              analyticsTrackingId={null}
              theme={ButtonTheme.Ghost}
              onClick={() =>
                setSelectedGroundTruths(
                  selectedGroundTruths.length === filteredGroundTruths.length
                    ? []
                    : filteredGroundTruths.map((gt) => gt.id),
                )
              }
            >
              Select all
            </Button>
            {selectedGroundTruths.length > 0 && (
              <div className="text-xs text-content-secondary">
                {selectedGroundTruths.length} item
                {selectedGroundTruths.length !== 1 ? "s" : ""} selected
              </div>
            )}
            {searchTerm && (
              <div className="text-xs text-content-secondary">
                Showing {filteredGroundTruths.length} of{" "}
                {data?.ground_truths?.length || 0} entries
              </div>
            )}
          </div>
          <DropdownMenu
            align="end"
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="ground-truth-bulk-action"
              >
                Apply bulk actions
              </Button>
            }
            disabled={selectedGroundTruths.length === 0}
          >
            <DropdownMenuItem
              label="Add to dataset"
              icon={IconEnum.Database}
              onSelect={() => setShowAddToDatasetMode("add")}
              analyticsTrackingId={null}
            />
            <DropdownMenuItem
              label="Remove from dataset"
              icon={IconEnum.Database}
              onSelect={() => setShowAddToDatasetMode("remove")}
              analyticsTrackingId={null}
            />
            <DropdownMenuItem
              label="Create backtest"
              icon={IconEnum.Test}
              onSelect={() => setShowCreateBacktest(true)}
              analyticsTrackingId={null}
            />
          </DropdownMenu>
        </div>
        <LoadingWrapper loading={isLoading}>
          {!filteredGroundTruths || filteredGroundTruths.length === 0 ? (
            <EmptyState
              content={
                searchTerm
                  ? "No matching ground truths found"
                  : "No search ground truths found"
              }
            />
          ) : (
            <StackedList>
              {filteredGroundTruths.map((groundTruth) => (
                <SearchGroundTruthRow
                  key={groundTruth.id}
                  groundTruth={groundTruth}
                  isSelected={selectedGroundTruths.includes(groundTruth.id)}
                  onToggleSelect={toggleGroundTruth}
                />
              ))}
            </StackedList>
          )}
        </LoadingWrapper>
      </Form.Root>
    </>
  );
};

const SearchGroundTruthRow = ({
  groundTruth,
  isSelected,
  onToggleSelect,
}: {
  groundTruth: SearchGroundTruth;
  isSelected: boolean;
  onToggleSelect: (groundTruthId: string) => void;
}) => {
  const viewHref = `/workbench/search-ground-truths/${groundTruth.id}`;

  const getQuestionTypeBadgeTheme = (type: string): BadgeTheme => {
    switch (type) {
      case "incident":
        return BadgeTheme.Primary;
      case "code-changes":
        return BadgeTheme.Info;
      default:
        return BadgeTheme.Tertiary;
    }
  };

  return (
    <StackedListItem
      key={groundTruth.id}
      className="pl-1"
      rowHref={viewHref}
      iconNode={
        <Button
          icon={
            isSelected ? IconEnum.FakeCheckboxChecked : IconEnum.FakeCheckbox
          }
          className="px-2 rounded hover:bg-surface-tertiary py-5 z-50"
          title=""
          analyticsTrackingId={null}
          theme={ButtonTheme.Unstyled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onToggleSelect(groundTruth.id);
          }}
        />
      }
      title={
        <div className="flex items-center gap-2">
          <LocalDateTime
            timestamp={groundTruth.cutoff}
            className="font-medium"
            format="dd MMM yy"
          />
          <div className="truncate">{groundTruth.question}</div>
        </div>
      }
      accessory={
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={viewHref}
          analyticsTrackingId={null}
          title="View ground truth"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      }
      description={
        <div className="flex items-center gap-2 mt-2">
          <VerifiedButton
            isVerified={!!groundTruth.verified_at}
            verifiedAt={
              groundTruth.verified_at
                ? new Date(groundTruth.verified_at)
                : undefined
            }
            setIsVerified={() => null}
            isEditing={false}
          />
          <Badge
            theme={getQuestionTypeBadgeTheme(groundTruth.question_type)}
            size={BadgeSize.Small}
          >
            {groundTruth.question_type}
          </Badge>
          <div className="text-content-tertiary shrink-0 text-xs">
            {groundTruth.answer?.length || 0} item(s)
          </div>
          {groundTruth.incident_id && (
            <Button
              theme={ButtonTheme.Link}
              href={`/incidents/${groundTruth.incident_id}`}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId={null}
              openInNewTab
              size={BadgeSize.ExtraSmall}
            >
              View Incident
            </Button>
          )}
        </div>
      }
    />
  );
};
