/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSource,
    AlertRouteAlertSourceFromJSON,
    AlertRouteAlertSourceFromJSONTyped,
    AlertRouteAlertSourceToJSON,
} from './AlertRouteAlertSource';
import {
    AlertRouteChannelConfig,
    AlertRouteChannelConfigFromJSON,
    AlertRouteChannelConfigFromJSONTyped,
    AlertRouteChannelConfigToJSON,
} from './AlertRouteChannelConfig';
import {
    AlertRouteEscalationConfig,
    AlertRouteEscalationConfigFromJSON,
    AlertRouteEscalationConfigFromJSONTyped,
    AlertRouteEscalationConfigToJSON,
} from './AlertRouteEscalationConfig';
import {
    AlertRouteIncidentConfig,
    AlertRouteIncidentConfigFromJSON,
    AlertRouteIncidentConfigFromJSONTyped,
    AlertRouteIncidentConfigToJSON,
} from './AlertRouteIncidentConfig';
import {
    AlertRouteIncidentTemplate,
    AlertRouteIncidentTemplateFromJSON,
    AlertRouteIncidentTemplateFromJSONTyped,
    AlertRouteIncidentTemplateToJSON,
} from './AlertRouteIncidentTemplate';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface AlertRoute
 */
export interface AlertRoute {
    /**
     * Which alert sources should this alert route match?
     * @type {Array<AlertRouteAlertSource>}
     * @memberof AlertRoute
     */
    alert_sources: Array<AlertRouteAlertSource>;
    /**
     * When did this alert route last create an escalation?
     * @type {Date}
     * @memberof AlertRoute
     */
    approx_last_escalation_created_at?: Date;
    /**
     * When did this alert route last create an incident?
     * @type {Date}
     * @memberof AlertRoute
     */
    approx_last_incident_created_at?: Date;
    /**
     * The channel configuration for this alert route
     * @type {Array<AlertRouteChannelConfig>}
     * @memberof AlertRoute
     */
    channel_config?: Array<AlertRouteChannelConfig>;
    /**
     * What condition groups must be true for this alert route to fire?
     * @type {Array<ConditionGroup>}
     * @memberof AlertRoute
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * The time of creation of this alert route
     * @type {Date}
     * @memberof AlertRoute
     */
    created_at?: Date;
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRoute
     */
    enabled: boolean;
    /**
     * If set, there was an error serializing some properties of the alert route. Only the name, ID, and other simple details will be valid.
     * @type {string}
     * @memberof AlertRoute
     */
    error_string?: string;
    /**
     * 
     * @type {AlertRouteEscalationConfig}
     * @memberof AlertRoute
     */
    escalation_config: AlertRouteEscalationConfig;
    /**
     * The expressions used in this template
     * @type {Array<Expression>}
     * @memberof AlertRoute
     */
    expressions?: Array<Expression>;
    /**
     * Unique identifier for this alert route config
     * @type {string}
     * @memberof AlertRoute
     */
    id: string;
    /**
     * 
     * @type {AlertRouteIncidentConfig}
     * @memberof AlertRoute
     */
    incident_config: AlertRouteIncidentConfig;
    /**
     * Whether incident creation is enabled for this alert route
     * @type {boolean}
     * @memberof AlertRoute
     */
    incident_enabled: boolean;
    /**
     * 
     * @type {AlertRouteIncidentTemplate}
     * @memberof AlertRoute
     */
    incident_template?: AlertRouteIncidentTemplate;
    /**
     * Whether this alert route is private. Private alert routes will only create private incidents from alerts.
     * @type {boolean}
     * @memberof AlertRoute
     */
    is_private: boolean;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRoute
     */
    name: string;
    /**
     * The time of last update of this alert route
     * @type {Date}
     * @memberof AlertRoute
     */
    updated_at?: Date;
    /**
     * The version of this alert route config
     * @type {number}
     * @memberof AlertRoute
     */
    version: number;
}

export function AlertRouteFromJSON(json: any): AlertRoute {
    return AlertRouteFromJSONTyped(json, false);
}

export function AlertRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourceFromJSON)),
        'approx_last_escalation_created_at': !exists(json, 'approx_last_escalation_created_at') ? undefined : (new Date(json['approx_last_escalation_created_at'])),
        'approx_last_incident_created_at': !exists(json, 'approx_last_incident_created_at') ? undefined : (new Date(json['approx_last_incident_created_at'])),
        'channel_config': !exists(json, 'channel_config') ? undefined : ((json['channel_config'] as Array<any>).map(AlertRouteChannelConfigFromJSON)),
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'enabled': json['enabled'],
        'error_string': !exists(json, 'error_string') ? undefined : json['error_string'],
        'escalation_config': AlertRouteEscalationConfigFromJSON(json['escalation_config']),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'id': json['id'],
        'incident_config': AlertRouteIncidentConfigFromJSON(json['incident_config']),
        'incident_enabled': json['incident_enabled'],
        'incident_template': !exists(json, 'incident_template') ? undefined : AlertRouteIncidentTemplateFromJSON(json['incident_template']),
        'is_private': json['is_private'],
        'name': json['name'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'version': json['version'],
    };
}

export function AlertRouteToJSON(value?: AlertRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourceToJSON)),
        'approx_last_escalation_created_at': value.approx_last_escalation_created_at === undefined ? undefined : (value.approx_last_escalation_created_at.toISOString()),
        'approx_last_incident_created_at': value.approx_last_incident_created_at === undefined ? undefined : (value.approx_last_incident_created_at.toISOString()),
        'channel_config': value.channel_config === undefined ? undefined : ((value.channel_config as Array<any>).map(AlertRouteChannelConfigToJSON)),
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString()),
        'enabled': value.enabled,
        'error_string': value.error_string,
        'escalation_config': AlertRouteEscalationConfigToJSON(value.escalation_config),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'id': value.id,
        'incident_config': AlertRouteIncidentConfigToJSON(value.incident_config),
        'incident_enabled': value.incident_enabled,
        'incident_template': AlertRouteIncidentTemplateToJSON(value.incident_template),
        'is_private': value.is_private,
        'name': value.name,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'version': value.version,
    };
}

