import { IconEnum } from "@incident-ui";
import { ExternalResource } from "src/contexts/ClientContext";
import { formatTimestampLocale } from "src/utils/datetime";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const SlackFile = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  const renderProps: AttachmentRenderProps = {
    icon: IconEnum.File,
    title: resource.title,
    safeBadges: [
      {
        icon: IconEnum.Slack,
        text: `Uploaded on ${formatTimestampLocale({
          timestamp: resource.created_at,
        })}`,
      },
    ],
  };
  return renderProps;
};
