/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SequenceBillingSchedule,
    SequenceBillingScheduleFromJSON,
    SequenceBillingScheduleFromJSONTyped,
    SequenceBillingScheduleToJSON,
} from './SequenceBillingSchedule';

/**
 * 
 * @export
 * @interface SequenceDetails
 */
export interface SequenceDetails {
    /**
     * 
     * @type {Array<SequenceBillingSchedule>}
     * @memberof SequenceDetails
     */
    billing_schedules: Array<SequenceBillingSchedule>;
    /**
     * Sequence customer ID
     * @type {string}
     * @memberof SequenceDetails
     */
    id: string;
    /**
     * Legal name
     * @type {string}
     * @memberof SequenceDetails
     */
    legal_name: string;
    /**
     * Tax status
     * @type {string}
     * @memberof SequenceDetails
     */
    tax_status?: string;
}

export function SequenceDetailsFromJSON(json: any): SequenceDetails {
    return SequenceDetailsFromJSONTyped(json, false);
}

export function SequenceDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SequenceDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billing_schedules': ((json['billing_schedules'] as Array<any>).map(SequenceBillingScheduleFromJSON)),
        'id': json['id'],
        'legal_name': json['legal_name'],
        'tax_status': !exists(json, 'tax_status') ? undefined : json['tax_status'],
    };
}

export function SequenceDetailsToJSON(value?: SequenceDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_schedules': ((value.billing_schedules as Array<any>).map(SequenceBillingScheduleToJSON)),
        'id': value.id,
        'legal_name': value.legal_name,
        'tax_status': value.tax_status,
    };
}

