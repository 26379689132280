import { usePylon } from "@bolasim/react-use-pylon";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { SecondaryNavDesktop } from "@incident-shared/layout/SecondaryNav";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  GatedDropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  TabSection,
} from "@incident-ui";
import { TabPane, TabSectionProps } from "@incident-ui/TabSection/TabSection";
import { captureException } from "@sentry/react";
import { differenceInDays } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { Route, Routes, useParams } from "react-router";
import {
  DependentResource,
  ScopeNameEnum,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageSubPageSlim,
  StatusPageTemplate,
  StatusPageTemplateStatusPageIncidentTypeEnum as IncidentTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useLocalStorage } from "use-hooks";

import { buildDependentsForComponent } from "../common/ComponentsEditor/utils";
import { PublishRetrospectiveIncident } from "../common/modals/PublishRetrospectiveIncident";
import { PAGE_TYPE_TO_ICON } from "../create/ChoosePageTypeDrawer";
import { GoLiveCTA } from "../create/GoLiveCTA";
import { StatusPagesEditStructurePage } from "../edit/StatusPagesEditStructurePage";
import { StatusPageIncidentCreatePage } from "../incidents/create/StatusPageIncidentCreatePage";
import { StatusPageScheduledMaintenanceCreatePage } from "../incidents/create/StatusPageScheduledMaintenanceCreatePage";
import { StatusPageIncidentListPage } from "../incidents/list/StatusPageIncidentListPage";
import { BasicSettings } from "../settings/edit/BasicSettings";
import { CustomDomainSettingsEditPage } from "../settings/edit/CustomDomainSettingsEditPage";
import { PageSetupSettings } from "../settings/edit/PageSetupSettings";
import { PageViewSettingsEditPage } from "../settings/edit/PageViewSettingsEditPage";
import { SubscriptionSettingsEditPage } from "../settings/edit/SubscriptionSettingsEditPage";
import { TemplateSettingsPage } from "../settings/edit/TemplateSettingsPage";
import { WidgetAPISettings } from "../settings/edit/WidgetAPISettings";
import { ComponentsList } from "../settings/list/ComponentsList";
import { SubPageSettings } from "../sub-pages/edit/SubPageEditSettings";
import { SubPagesListPage } from "../sub-pages/list/SubPagesListPage";
import { ParentStructureViewPage } from "./ParentStructureViewPage";

enum TabId {
  Overview = "overview",
  Settings = "settings",
}

const TABS: TabSectionProps["tabs"] = [
  {
    id: TabId.Overview,
    label: "Overview",
  },
  {
    id: TabId.Settings,
    label: "Settings",
  },
];

export enum OverviewTabIds {
  Now = "now",
  Past = "past",
  Maintenance = "maintenance",
}

const OVERVIEW_TABS = [
  {
    label: "Happening now",
    slug: OverviewTabIds.Now,
    icon: IconEnum.Bolt,
  },
  {
    label: "Past events",
    slug: OverviewTabIds.Past,
    icon: IconEnum.Clock,
  },
  {
    label: "Maintenance",
    slug: OverviewTabIds.Maintenance,
    icon: IconEnum.Maintenance,
  },
];

export enum SettingsTabIds {
  BasicSettings = "basic",
  SubPages = "sub_pages",
  CustomerPages = "customer_pages",
  PageSetup = "page_setup",
  Components = "components",
  CustomDomain = "custom_domain",
  PageViews = "page_views",
  Subscriptions = "subscriptions",
  Templates = "templates",
  WidgetAPI = "widget_api",
}

export enum ParentSettingsTabIds {}

const SETTINGS_TAB_INFO: {
  [key in SettingsTabIds]: {
    label: string;
    onlyOn?: StatusPagePageTypeEnum;
    exclude?: StatusPagePageTypeEnum;
    onlyIf?: boolean;
    standaloneOnly?: boolean;
    parentPageOnly?: boolean;
    customerPageOnly?: boolean;
  };
} = {
  [SettingsTabIds.BasicSettings]: { label: "Basic settings" },
  [SettingsTabIds.SubPages]: {
    label: "Sub-pages",
    onlyOn: StatusPagePageTypeEnum.Parent,
  },
  [SettingsTabIds.CustomerPages]: {
    label: "Customer pages",
    onlyOn: StatusPagePageTypeEnum.Customer,
  },
  [SettingsTabIds.Components]: { label: "Components" },
  [SettingsTabIds.PageSetup]: { label: "Page setup" },
  [SettingsTabIds.CustomDomain]: { label: "Custom domain" },
  [SettingsTabIds.PageViews]: { label: "Page views" },
  [SettingsTabIds.Subscriptions]: {
    label: "Subscriptions",
  },
  [SettingsTabIds.Templates]: { label: "Templates" },
  [SettingsTabIds.WidgetAPI]: {
    label: "Widget API",
    exclude: StatusPagePageTypeEnum.Customer,
  },
};

const STANDALONE_SETTINGS_TABS = Object.values(SettingsTabIds).flatMap(
  (slug) =>
    (SETTINGS_TAB_INFO[slug].onlyOn !== undefined &&
      SETTINGS_TAB_INFO[slug].onlyOn !== StatusPagePageTypeEnum.Standalone) ||
    SETTINGS_TAB_INFO[slug].exclude === StatusPagePageTypeEnum.Standalone
      ? []
      : {
          slug,
          label: SETTINGS_TAB_INFO[slug].label,
        },
);

const PARENT_SETTINGS_TABS = Object.values(SettingsTabIds).flatMap((slug) =>
  (SETTINGS_TAB_INFO[slug].onlyOn !== undefined &&
    SETTINGS_TAB_INFO[slug].onlyOn !== StatusPagePageTypeEnum.Parent) ||
  SETTINGS_TAB_INFO[slug].exclude === StatusPagePageTypeEnum.Parent
    ? []
    : {
        slug,
        label: SETTINGS_TAB_INFO[slug].label,
      },
);

const CUSTOMER_SETTINGS_TABS = Object.values(SettingsTabIds).flatMap((slug) =>
  (SETTINGS_TAB_INFO[slug].onlyOn !== undefined &&
    SETTINGS_TAB_INFO[slug].onlyOn !== StatusPagePageTypeEnum.Customer) ||
  SETTINGS_TAB_INFO[slug].exclude === StatusPagePageTypeEnum.Customer
    ? []
    : {
        slug,
        label: SETTINGS_TAB_INFO[slug].label,
      },
);

const ALL_SETTINGS_TABS = Object.values(SettingsTabIds).map((slug) => ({
  slug,
  label: SETTINGS_TAB_INFO[slug].label,
}));

type DetailsPageProps =
  | { overviewTab: OverviewTabIds; settingsTab?: never }
  | { overviewTab?: never; settingsTab?: SettingsTabIds };

export const StatusPageDetailsPage = (): React.ReactElement => {
  const { disableAlertSourceStatusPageViewSpikes } = useFlags();

  return (
    <Routes>
      <Route path="overview">
        {OVERVIEW_TABS.map((tab) => (
          <Route
            key={tab.slug}
            path={tab.slug}
            element={<DetailsPage overviewTab={tab.slug} />}
          />
        ))}
        {/* Default tab should be 'Now' */}
        <Route
          path=""
          element={<OrgAwareNavigate to={OverviewTabIds.Now} replace />}
        />
      </Route>
      <Route path="settings">
        {ALL_SETTINGS_TABS.filter((tab) => {
          if (!disableAlertSourceStatusPageViewSpikes) {
            return true;
          }
          return tab.slug !== SettingsTabIds.PageViews;
        }).map((tab) => (
          <Route
            key={tab.slug}
            path={tab.slug}
            element={<DetailsPage settingsTab={tab.slug} />}
          />
        ))}
        {/* Default tab should be 'Basic Settings' */}
        <Route
          path=""
          element={
            <OrgAwareNavigate to={SettingsTabIds.BasicSettings} replace />
          }
        />
      </Route>
      <Route
        path="*"
        element={<OrgAwareNavigate to="overview/now" replace />}
      />
    </Routes>
  );
};

// If this page looks like it should probably go live, pop the article open to
// help people do that.
//
// The criteria are:
// - none of the go-live things done (ToS, privacy policy not set, no custom
//   domain, not indexed)
// - created more than 4 days ago
// - not imported from Atlassian (we'll have a separate checklist for that)
const usePromptToGoLive = (pageId: string, page?: StatusPage) => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const pageLoaded = page !== undefined;
  const [havePrompted, setHavePrompted] = useLocalStorage(
    `prompted-to-go-live-${pageId}`,
    false,
  );
  const isProbablyLive =
    page &&
    (page.custom_domain_verified_at !== undefined ||
      page.allow_search_engine_indexing ||
      page.terms_of_service_url !== undefined ||
      page.privacy_policy_url !== undefined);

  const isOldEnough =
    page && differenceInDays(new Date(), new Date(page.created_at)) >= 4;

  const importedFromAtlassian = page && page.imported_from_atlassian_page_id;

  useEffect(() => {
    if (!pageLoaded) return;
    if (havePrompted) return;
    if (isProbablyLive) return;
    if (!isOldEnough) return;
    if (importedFromAtlassian) return;

    setHavePrompted(true);
    showArticle("5284372258");
  }, [
    havePrompted,
    pageLoaded,
    isProbablyLive,
    isOldEnough,
    importedFromAtlassian,
    setHavePrompted,
    showArticle,
  ]);
};

enum AvailableModal {
  PublishIncident = "publish-incident",
  ScheduleMaintenance = "schedule-maintenance",
  PublishRetrospectiveIncident = "retrospective-incident",
}

type AvailableModalConfig = {
  action: AvailableModal;
  Component: (props: {
    page: StatusPage;
    structure: StatusPageStructure;
    templates: StatusPageTemplate[];
  }) => React.ReactElement;
  incidentType: IncidentTypeEnum;
};

const availableModals: AvailableModalConfig[] = [
  {
    action: AvailableModal.PublishIncident,
    Component: StatusPageIncidentCreatePage,
    incidentType: IncidentTypeEnum.Incident,
  },
  {
    action: AvailableModal.ScheduleMaintenance,
    Component: StatusPageScheduledMaintenanceCreatePage,
    incidentType: IncidentTypeEnum.Maintenance,
  },
  {
    action: AvailableModal.PublishRetrospectiveIncident,
    Component: PublishRetrospectiveIncident,
    incidentType: IncidentTypeEnum.Incident,
  },
];

const DetailsPage = ({ overviewTab, settingsTab }: DetailsPageProps) => {
  const { disableAlertSourceStatusPageViewSpikes } = useFlags();
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const { pageId } = useParams() as { pageId: string };
  const action = useQueryParams().get("action");
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const {
    data: pageData,
    error: pageError,
    isLoading: pageLoading,
  } = useAPI("statusPageShow", { id: pageId });
  const page = pageData?.status_page;
  const structure = pageData?.current_structure;
  const subpages = pageData?.subpages;
  const dependentResources: DependentResource[] | undefined =
    pageData?.dependent_resources;
  const dependentsForComponent = buildDependentsForComponent(
    pageData?.component_dependent_resources ?? [],
  );

  // This is for non-imported pages - we just pop open a help article
  usePromptToGoLive(pageId, page);

  const isParentPage = !!page?.split_by_catalog_type_id;

  const { data: templatesData, error: templatesError } = useAPI(
    "statusPageListTemplates",
    { statusPageId: pageId },
  );
  const templates = templatesData?.status_page_templates;

  const {
    data: { status_page_incidents: allIncidents },
    error: allIncidentsError,
    isLoading: allIncidentsLoading,
  } = useAPI(
    "statusPageListIncidents",
    {
      statusPageId: pageId,
    },
    { fallbackData: { status_page_incidents: [] } },
  );
  const {
    data: { status_page_incidents: incidents },
    error: incidentsError,
    isLoading: incidentsLoading,
  } = useAPI(
    "statusPageListIncidents",
    {
      statusPageId: pageId,
    },
    { fallbackData: { status_page_incidents: [] } },
  );

  const error =
    pageError || allIncidentsError || incidentsError || templatesError;
  if (error) {
    captureException(error, { extra: { status_page_id: pageId } });
    console.error(error);
    return <GenericErrorMessage error={error} />;
  }

  let modal: AvailableModalConfig | undefined = undefined;
  if (action != null) {
    modal = availableModals.find((m) => m.action === action);
  }

  const filteredTemplates =
    templates?.filter(
      (t) => t.status_page_incident_type === modal?.incidentType,
    ) || [];

  let tabGroups = page
    ? page.page_type === StatusPagePageTypeEnum.Parent
      ? PARENT_SETTINGS_TABS
      : page.page_type === StatusPagePageTypeEnum.Customer
      ? CUSTOMER_SETTINGS_TABS
      : STANDALONE_SETTINGS_TABS
    : [];

  if (disableAlertSourceStatusPageViewSpikes) {
    tabGroups = tabGroups.filter(
      ({ slug }) => slug !== SettingsTabIds.PageViews,
    );
  }

  return (
    <>
      {modal && page && structure && templates ? (
        <modal.Component
          page={page}
          structure={structure}
          templates={filteredTemplates}
        />
      ) : undefined}
      <PageWrapper
        width={PageWidth.Wide}
        icon={
          page?.page_type === StatusPagePageTypeEnum.Customer
            ? PAGE_TYPE_TO_ICON.customer
            : PAGE_TYPE_TO_ICON.public
        }
        loading={pageLoading}
        title={page?.name ? `${page.name} Status` : ""}
        subtitle={
          page?.page_type !== StatusPagePageTypeEnum.Customer && (
            <Button
              href={page?.public_url}
              openInNewTab
              theme={ButtonTheme.Naked}
              analyticsTrackingId="status-page-subtitle"
            >
              {page && page.public_url}
              <Icon
                id={IconEnum.ExternalLink}
                size={IconSize.Small}
                className="group-hover:text-content-primary transition"
              />
            </Button>
          )
        }
        backHref="/status-pages"
        banner={
          page?.page_type === StatusPagePageTypeEnum.Customer ? (
            <HeaderBanner className="text-content-primary">
              <Icon id={IconEnum.Users} className="text-slate-600 mr-2" /> This
              is a private customer status page.{" "}
              <Button
                className="ml-1"
                onClick={() => showArticle("2223511837")}
                theme={ButtonTheme.Naked}
                analyticsTrackingId={
                  "status-pages-customer-pages--intercom-allowlist"
                }
              >
                Control access by configuring the allowlist in Catalog
              </Button>
              .
            </HeaderBanner>
          ) : page ? (
            <GoLiveCTA page={page} />
          ) : undefined
        }
        accessory={
          page && (
            <div className="gap-2 flex items-center flex-wrap">
              <DropdownMenu
                side="bottom"
                triggerButton={
                  <Button
                    title="More options"
                    analyticsTrackingId="public-sp-config--more-options"
                    icon={IconEnum.DotsHorizontalNopad}
                    theme={ButtonTheme.Secondary}
                    className="h-[38px]" // Fix the height, because we pass a small icon
                    iconProps={{
                      size: IconSize.Small,
                    }}
                  />
                }
              >
                {overviewTab === OverviewTabIds.Maintenance ? (
                  <GatedDropdownMenuItem
                    analyticsTrackingId="status-page-publish-incident"
                    onSelect={() => navigate(`?action=publish-incident`)}
                    label={"Publish incident"}
                    icon={IconEnum.Incident}
                    iconProps={{ size: IconSize.Medium }}
                    disabled={
                      !hasScope(ScopeNameEnum.StatusPagesPublishUpdates)
                    }
                  >
                    Publish incident
                  </GatedDropdownMenuItem>
                ) : (
                  <GatedDropdownMenuItem
                    analyticsTrackingId="status-page-schedule-maintenance"
                    onSelect={() => navigate(`?action=schedule-maintenance`)}
                    label={"Schedule maintenance"}
                    icon={IconEnum.Maintenance}
                    iconProps={{ size: IconSize.Medium }}
                    disabled={
                      !hasScope(ScopeNameEnum.StatusPagesPublishUpdates)
                    }
                  >
                    Schedule maintenance
                  </GatedDropdownMenuItem>
                )}

                <GatedDropdownMenuItem
                  onSelect={() => navigate(`?action=retrospective-incident`)}
                  analyticsTrackingId={null}
                  label={"Publish retrospective incident"}
                  icon={IconEnum.Edit}
                  iconProps={{ size: IconSize.Medium }}
                />
              </DropdownMenu>

              {page.page_type !== StatusPagePageTypeEnum.Customer && (
                <Button
                  theme={ButtonTheme.Secondary}
                  analyticsTrackingId="status-page-visit"
                  href={page?.public_url}
                  icon={IconEnum.ExternalLink}
                  openInNewTab
                >
                  {isParentPage ? "Visit parent page" : "Visit status page"}
                </Button>
              )}

              {overviewTab === OverviewTabIds.Maintenance ? (
                <GatedButton
                  theme={ButtonTheme.Primary}
                  analyticsTrackingId="status-page-schedule-maintenance"
                  href={`?action=schedule-maintenance`}
                  requiredScope={ScopeNameEnum.StatusPagesPublishUpdates}
                  disabledTooltipContent={
                    "You do not have permission to schedule maintenances on this public status page"
                  }
                >
                  Schedule maintenance
                </GatedButton>
              ) : (
                <GatedButton
                  theme={ButtonTheme.Primary}
                  analyticsTrackingId="status-page-publish-incident"
                  href={`?action=publish-incident`}
                  requiredScope={ScopeNameEnum.StatusPagesPublishUpdates}
                  disabledTooltipContent={
                    "You do not have permission to publish incidents to this public status page"
                  }
                >
                  Publish incident
                </GatedButton>
              )}
            </div>
          )
        }
      >
        <TabSection
          value={overviewTab != null ? TabId.Overview : TabId.Settings}
          tabs={TABS}
          withIndicator
          tabBarClassName={tcx(
            "mb-2 border-b border-1 border-stroke sticky top-0 bg-white z-10",
          )}
          onTabChange={(tabId) => {
            navigate(
              tabId === TabId.Settings
                ? `/status-pages/${pageId}/settings`
                : `/status-pages/${pageId}/${OverviewTabIds.Now}`,
              { replace: true },
            );
          }}
        >
          <TabPane tabId={TabId.Overview} className="relative">
            {page && structure && (
              <div className="flex gap-6 pt-6">
                <SecondaryNavDesktop
                  applyPadding={false}
                  className="min-w-40"
                  pathPrefix={`status-pages/${pageId}/overview`}
                  itemGroups={[{ items: OVERVIEW_TABS }]}
                />
                <div className="lg:grid lg:grid-cols-[1fr_250px] lg:w-[calc(100%-12rem)] w-full gap-6">
                  <div className="h-full overflow-y-auto">
                    <h3 className="text-sm font-medium mb-2">
                      {overviewTab === OverviewTabIds.Maintenance
                        ? "Maintenance windows"
                        : "Incidents"}
                    </h3>

                    <StatusPageIncidentListPage
                      page={page}
                      subpages={subpages}
                      structure={structure}
                      allIncidents={allIncidents}
                      incidents={incidents}
                      loading={allIncidentsLoading || incidentsLoading}
                      overviewTab={overviewTab || OverviewTabIds.Now}
                    />
                  </div>

                  <div className="group/edit-components overflow-y-auto h-full">
                    {isParentPage ? (
                      <SubPagesListPage page={page} subpages={subpages || []} />
                    ) : (
                      <ComponentsList
                        page={page}
                        structure={structure}
                        incidents={incidents}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tabId={TabId.Settings} className="relative">
            {page && structure && (
              <div className="flex gap-6 pt-6">
                <SecondaryNavDesktop
                  applyPadding={false}
                  className="min-w-40"
                  pathPrefix={`status-pages/${pageId}/settings`}
                  itemGroups={[
                    {
                      items: tabGroups,
                    },
                  ]}
                />
                <div
                  className={tcx(
                    "w-full flex flex-col gap-8",
                    // Once the non-mobile menu is visible, make space for it
                    "md:w-[calc(100%-12rem)]",
                    // On very wide screens, create an empty gap on the right as
                    // well, where the component list sits on the overview tab
                    "2xl:w-[calc(100%-12rem-250px)]",
                  )}
                >
                  <SettingsTabContent
                    tab={settingsTab || SettingsTabIds.BasicSettings}
                    page={page}
                    subPages={subpages ?? []}
                    structure={structure}
                    dependentResources={dependentResources ?? []}
                    dependentsForComponent={dependentsForComponent}
                  />
                </div>
              </div>
            )}
          </TabPane>
        </TabSection>
      </PageWrapper>
    </>
  );
};

const SettingsTabContent = ({
  tab,
  page,
  structure,
  subPages,
  dependentResources,
  dependentsForComponent,
}: {
  tab: SettingsTabIds;
  page: StatusPage;
  subPages: StatusPageSubPageSlim[];
  structure: StatusPageStructure;
  dependentResources: DependentResource[];
  dependentsForComponent: Record<string, DependentResource[] | undefined>;
}): React.ReactElement | null => {
  const navigate = useOrgAwareNavigate();
  if (SETTINGS_TAB_INFO[tab].parentPageOnly && !page.split_by_catalog_type_id) {
    navigate(`/status-pages/${page.id}/settings/basic`);
  }

  const isParentPage = !!page.split_by_catalog_type_id;

  switch (tab) {
    case SettingsTabIds.BasicSettings:
      return (
        <BasicSettings dependentResources={dependentResources} page={page} />
      );
    case SettingsTabIds.SubPages:
    case SettingsTabIds.CustomerPages:
      return <SubPageSettings page={page} subPages={subPages} />;
    case SettingsTabIds.PageSetup:
      return (
        <PageSetupSettings
          page={page}
          subpages={subPages}
          structure={structure}
        />
      );
    case SettingsTabIds.CustomDomain:
      return <CustomDomainSettingsEditPage page={page} />;
    case SettingsTabIds.Components:
      return isParentPage ? (
        <ParentStructureViewPage page={page} structure={structure} />
      ) : (
        <StatusPagesEditStructurePage
          page={page}
          structure={structure}
          dependentsForComponent={dependentsForComponent}
        />
      );
    case SettingsTabIds.PageViews:
      return <PageViewSettingsEditPage page={page} />;
    case SettingsTabIds.Subscriptions:
      return <SubscriptionSettingsEditPage page={page} structure={structure} />;
    case SettingsTabIds.Templates:
      return <TemplateSettingsPage page={page} />;
    case SettingsTabIds.WidgetAPI:
      return <WidgetAPISettings page={page} />;
    default:
      return null;
  }
};
