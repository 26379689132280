import { EvaluationDatasetDatasetTypeEnum } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Link, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

type CurateDatasetModalFormData = {
  dataset_id: string;
};

export const CurateDatasetModal = ({
  resourceIDs,
  overrideDatasetID,
  datasetType,
  mode,
  onClose,
  onSuccess,
}: {
  resourceIDs: string[];
  overrideDatasetID?: string;
  datasetType: EvaluationDatasetDatasetTypeEnum;
  mode: "add" | "remove";
  onClose: () => void;
  onSuccess: () => void;
}): React.ReactElement => {
  const formMethods = useForm<CurateDatasetModalFormData>({
    defaultValues: {
      dataset_id: overrideDatasetID,
    },
  });

  const showToast = useToast();
  const { data, isLoading } = useAPI(
    "aIStaffListEvaluationDatasets",
    {},
    {
      fallbackData: { datasets: [] },
    },
  );
  const datasets = data.datasets;

  const selectedDatasetId = formMethods.watch("dataset_id");

  const selectedDataset = datasets.find(
    (dataset) => dataset.id === selectedDatasetId,
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useMutationV2(
    async (apiClient, data: CurateDatasetModalFormData) => {
      await apiClient.aIStaffCurateEvaluationDataset({
        id: data.dataset_id || "",
        curateEvaluationDatasetRequestBody: {
          to_add: mode === "add" ? resourceIDs : [],
          to_remove: mode === "remove" ? resourceIDs : [],
        },
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationDataset", {
          id: selectedDatasetId || "",
        }),
        cacheKey.all("aIStaffListEvaluationDatasets"),
      ],

      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Dataset updated",
          description: (
            <Link
              analyticsTrackingId={null}
              to={`/workbench/datasets/${selectedDatasetId}`}
            >
              View dataset
            </Link>
          ),
        });
        onSuccess();
      },

      setError: formMethods.setError,
      showErrorToast: "Curating dataset failed",
    },
  );

  const modeConfig = {
    add: {
      title: "Add to dataset",
      buttonText: "Add",
      helpText: "Select the dataset to add these entries to.",
      actionText: "add",
      preposition: "to",
    },
    remove: {
      title: "Remove from dataset",
      buttonText: "Remove",
      helpText: "Select the dataset to remove these entries from.",
      actionText: "remove",
      preposition: "from",
    },
  }[mode];

  const datasetOptions = data.datasets
    .filter((dataset) => dataset.dataset_type === datasetType)
    .map((dataset) => ({
      value: dataset.id,
      label: dataset.name,
    }));

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={modeConfig.title}
      loading={isLoading}
      analyticsTrackingId={`bulk-${mode}-dataset`}
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          hideBorder
          confirmButtonText={modeConfig.buttonText}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="dataset_id"
        label="Dataset"
        helptext={modeConfig.helpText}
        required
        options={datasetOptions}
        disabled={!!overrideDatasetID}
      />
      {selectedDataset && (
        <p className="text-sm text-content-secondary">
          This will {modeConfig.actionText} {resourceIDs.length}{" "}
          {resourceIDs.length !== 1 ? "entries" : "entry"}{" "}
          {modeConfig.preposition}{" "}
          <span className="font-medium text-content-primary">
            {selectedDataset.name}
          </span>
        </p>
      )}
    </Form.Modal>
  );
};
