// we want to show the user our empty state if they've never created an alert
// source, or if they only have the  status page views alert source (since we
// create that one automatically). and if they've never created an alert route.
import {
  AlertRouteSlim,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
} from "@incident-io/api";

export const shouldShowAlertSourcesEmptyState = (
  alertSourceConfigs: AlertSourceConfig[],
  alertRoutes: AlertRouteSlim[],
) =>
  (alertSourceConfigs.length === 0 ||
    alertSourceConfigs.every(
      (source) =>
        (source.source_type as string) ===
        AlertSourceSourceTypeEnum.StatusPageViews,
    )) &&
  alertRoutes.length === 0;
