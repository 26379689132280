import { captureException } from "@sentry/react";
import { useIdentity } from "src/contexts/IdentityContext";
import { assertUnreachable } from "src/utils/utils";

export const usePrimaryCommsPlatform = () => {
  const { identity } = useIdentity();

  if (
    identity.slack_info !== undefined &&
    identity.ms_teams_info !== undefined
  ) {
    captureException(
      new Error(
        `User has both Slack and Microsoft Teams identities: ${identity.user_id}`,
      ),
    );
    return CommsPlatform.Slack;
  }
  if (
    identity.slack_info === undefined &&
    identity.ms_teams_info === undefined
  ) {
    captureException(
      new Error(
        `User has neither Slack nor Microsoft Teams identity: ${identity.user_id}`,
      ),
    );
    return CommsPlatform.Slack;
  }

  if (identity.slack_info !== undefined) {
    return CommsPlatform.Slack;
  } else {
    return CommsPlatform.MSTeams;
  }
};

export const prettyPrintCommsPlatform = (platform: CommsPlatform) => {
  switch (platform) {
    case CommsPlatform.Slack:
      return "Slack";
    case CommsPlatform.MSTeams:
      return "Microsoft Teams";
    default:
      assertUnreachable(platform);
      return "Slack";
  }
};

export const usePrimaryCommsPlatformFeatures = (): {
  platformHasReminders: boolean;
} => {
  const platform = usePrimaryCommsPlatform();

  return {
    platformHasReminders: platform === CommsPlatform.Slack,
  };
};

export enum CommsPlatform {
  Slack = "slack",
  MSTeams = "msteams",
}
