/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecardDimensionValue,
    EvaluationScorecardDimensionValueFromJSON,
    EvaluationScorecardDimensionValueFromJSONTyped,
    EvaluationScorecardDimensionValueToJSON,
} from './EvaluationScorecardDimensionValue';

/**
 * An event with dimension values recorded against it
 * @export
 * @interface EvaluationScorecardEvent
 */
export interface EvaluationScorecardEvent {
    /**
     * Unique identifier for this event
     * @type {string}
     * @memberof EvaluationScorecardEvent
     */
    id: string;
    /**
     * Values recorded for each dimension, keyed by dimension ID
     * @type {{ [key: string]: EvaluationScorecardDimensionValue; }}
     * @memberof EvaluationScorecardEvent
     */
    values: { [key: string]: EvaluationScorecardDimensionValue; };
}

export function EvaluationScorecardEventFromJSON(json: any): EvaluationScorecardEvent {
    return EvaluationScorecardEventFromJSONTyped(json, false);
}

export function EvaluationScorecardEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecardEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'values': (mapValues(json['values'], EvaluationScorecardDimensionValueFromJSON)),
    };
}

export function EvaluationScorecardEventToJSON(value?: EvaluationScorecardEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'values': (mapValues(value.values, EvaluationScorecardDimensionValueToJSON)),
    };
}

