import cx from "classnames";

export const MaintenanceIcon = ({ className }: { className?: string; }): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx("w-[16px] h-[16px] mr-2 text-icon-maintenance", className)}
  >
    <path
      d="M6.2595 5.95811L0.6105 11.6071C-0.2035 12.4221 -0.2035 13.7491 0.6105 14.5641C1.0055 14.9591 1.5305 15.1761 2.0895 15.1761C2.6485 15.1761 3.1735 14.9591 3.5685 14.5641L9.2175 8.91511L6.2595 5.95811Z"
      fill="currentColor"
    />
    <path
      d="M15.1615 5.74911L10.4115 0.999106C10.1345 0.722106 9.7675 0.544106 9.3785 0.499106L5.1885 0.00610595C4.9605 -0.023894 4.7325 0.0581059 4.5705 0.220106L4.1365 0.654106C3.8435 0.947106 3.8435 1.42211 4.1365 1.71511L11.6665 9.24611C12.0075 9.58711 12.4555 9.75811 12.9035 9.75811C13.3515 9.75811 13.7995 9.58811 14.1405 9.24611L15.1615 8.22511C15.4915 7.89411 15.6745 7.45511 15.6745 6.98711C15.6745 6.51911 15.4925 6.07911 15.1615 5.74911Z"
      fill="currentColor"
    />
  </svg>
);
