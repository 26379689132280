/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationScorecardMetricSample
 */
export interface EvaluationScorecardMetricSample {
    /**
     * Total number of samples for this metric
     * @type {number}
     * @memberof EvaluationScorecardMetricSample
     */
    count: number;
    /**
     * Number of null/empty samples for this metric
     * @type {number}
     * @memberof EvaluationScorecardMetricSample
     */
    null_count: number;
}

export function EvaluationScorecardMetricSampleFromJSON(json: any): EvaluationScorecardMetricSample {
    return EvaluationScorecardMetricSampleFromJSONTyped(json, false);
}

export function EvaluationScorecardMetricSampleFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationScorecardMetricSample {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'null_count': json['null_count'],
    };
}

export function EvaluationScorecardMetricSampleToJSON(value?: EvaluationScorecardMetricSample | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'null_count': value.null_count,
    };
}

