import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  LocalRelativeDateTime,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const VerifiedButton = ({
  isVerified,
  verifiedAt,
  setIsVerified,
  isEditing,
}: {
  isVerified: boolean;
  verifiedAt: Date | undefined;
  setIsVerified: (isVerified: boolean) => void;
  isEditing: boolean;
}) => {
  // If we're editing, show a button.
  if (isEditing) {
    return (
      <Button
        onClick={() => setIsVerified(!isVerified)}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={isVerified ? IconEnum.TickCircle : IconEnum.Circle}
        analyticsTrackingId={null}
        className={tcx(isVerified ? "text-green-500" : "text-amber-500")}
      >
        {isVerified ? "Verified" : "Mark as verified"}
      </Button>
    );
  }

  // Otherwise, show a badge!
  return (
    <Badge theme={BadgeTheme.Secondary} className={"text-xs"}>
      {verifiedAt ? (
        <>
          <Icon id={IconEnum.TickCircle} className="text-green-500" />
          <span>
            Verified{" "}
            <LocalRelativeDateTime
              className={"text-xs"}
              date={new Date(verifiedAt)}
            />
          </span>
        </>
      ) : (
        <>
          <Icon id={IconEnum.Circle} className="text-amber-500" />
          <span>Unverified</span>
        </>
      )}
    </Badge>
  );
};
