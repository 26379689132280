/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TelemetryDashboard,
    TelemetryDashboardFromJSON,
    TelemetryDashboardFromJSONTyped,
    TelemetryDashboardToJSON,
} from './TelemetryDashboard';

/**
 * 
 * @export
 * @interface TelemetryListResponseBody
 */
export interface TelemetryListResponseBody {
    /**
     * A list of the telemetry dashboards for the organisation
     * @type {Array<TelemetryDashboard>}
     * @memberof TelemetryListResponseBody
     */
    telemetry_dashboards?: Array<TelemetryDashboard>;
}

export function TelemetryListResponseBodyFromJSON(json: any): TelemetryListResponseBody {
    return TelemetryListResponseBodyFromJSONTyped(json, false);
}

export function TelemetryListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'telemetry_dashboards': !exists(json, 'telemetry_dashboards') ? undefined : ((json['telemetry_dashboards'] as Array<any>).map(TelemetryDashboardFromJSON)),
    };
}

export function TelemetryListResponseBodyToJSON(value?: TelemetryListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'telemetry_dashboards': value.telemetry_dashboards === undefined ? undefined : ((value.telemetry_dashboards as Array<any>).map(TelemetryDashboardToJSON)),
    };
}

