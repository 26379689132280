import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import { keyBy } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentForm,
  IncidentFormFormTypeEnum as FormTypeEnum,
  IncidentFormFormTypeEnum,
  IncidentFormsCreateFormRequestBody,
  IncidentFormsCreateFormRequestBodyFormTypeEnum as CreateReqFormTypeEnum,
  IncidentType,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { FORM_TYPE_CONFIG, formEditPath } from "../common/config";
import { IncidentFormTypeCard } from "./IncidentFormCard";

type FormData = IncidentFormsCreateFormRequestBody & {
  create_mode: "start_from_scratch" | "copy_from_existing";
};

export const IncidentFormsCreateModal = ({
  allForms,
  allIncidentTypes,
  incidentTypeId,
  onClose,
}: {
  allForms: IncidentForm[];
  allIncidentTypes: IncidentType[];
  incidentTypeId: string;
  onClose: () => void;
}): React.ReactElement => {
  const { settings } = useSettings();

  const formMethods = useForm<FormData>({
    defaultValues: { create_mode: "start_from_scratch" },
  });

  const {
    data: {
      pagination_meta: { total_record_count: epCount },
    },
    isLoading: escalationPathsLoading,
  } = useAPI(
    "escalationPathsList",
    { pageSize: 1 },
    {
      fallbackData: {
        escalation_paths: [],
        first_level_users: {},
        pagination_meta: { page_size: 1 },
      },
    },
  );

  const inUseFormTypes = allForms
    .filter((x) => x.incident_type_id === incidentTypeId)
    .map((x) => x.form_type);

  const availableFormTypes = Object.entries(FORM_TYPE_CONFIG)
    .filter(([formType, config]) => {
      // Filter out accept if triage incidents are turned off
      if (
        !settings?.misc?.manual_triage_incidents_enabled &&
        formType === "accept"
      ) {
        return false;
      }
      if (epCount === 0 && formType === IncidentFormFormTypeEnum.Escalate) {
        return false;
      }

      if (config.secret) return false;

      return !inUseFormTypes.includes(formType as FormTypeEnum);
    })
    .map(([formType]) => formType);
  const navigate = useOrgAwareNavigate();

  const [selectedFormType, selectedCreateMode, selectedCopyFromId] =
    formMethods.watch(["form_type", "create_mode", "copy_from_form_id"]);

  const availableCopyFromForms = allForms.filter(
    (x) => x.form_type === (selectedFormType as unknown as FormTypeEnum),
  );

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "incidentFormsListForms",
    undefined,
    async (apiClient, data: FormData) => {
      const { form } = await apiClient.incidentFormsCreateForm({
        createFormRequestBody: {
          ...data,
          incident_type_id: incidentTypeId,
          copy_from_form_id:
            data.create_mode === "copy_from_existing"
              ? data.copy_from_form_id
              : undefined,
        },
      });
      navigate(formEditPath(form));
    },
    {
      onSuccess: onClose,
    },
  );

  const incidentTypesByID = keyBy(allIncidentTypes, "id");

  const availableFormOptions = availableCopyFromForms.map((f) => {
    if (!f.incident_type_id) {
      return { value: f.id, label: "All incidents", sort_key: "0" };
    }
    const incidentType = incidentTypesByID[f.incident_type_id];
    return {
      value: f.id,
      label: incidentType.name,
      sort_key: incidentType.name,
    };
  });

  const formIsIncomplete =
    !selectedFormType ||
    (selectedCreateMode === "copy_from_existing" && !selectedCopyFromId);

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      isExtraLarge={true}
      onClose={onClose}
      analyticsTrackingId="incident-forms-create-form"
      title="Override form"
      loading={saving || escalationPathsLoading}
      footer={
        <ModalFooter
          onClose={onClose}
          disabled={formIsIncomplete}
          confirmButtonType="submit"
          confirmButtonText="Create"
        />
      }
    >
      <Form.ErrorMessage message={genericError} />
      <div className="grid gap-4 xl:grid-cols-2 lg:grid-cols-1">
        {availableFormTypes.map((formType) => (
          <IncidentFormTypeCard
            key={formType}
            isSelected={formType === selectedFormType}
            formType={formType as FormTypeEnum}
            onClick={() =>
              formMethods.reset({
                form_type: formType as CreateReqFormTypeEnum,
                create_mode: "start_from_scratch",
              })
            }
          />
        ))}
      </div>
      {selectedFormType ? (
        <>
          <hr />
          <RadioButtonGroupV2
            key={selectedFormType}
            formMethods={formMethods}
            srLabel="Create mode"
            name="create_mode"
            boxed
            options={[
              {
                value: "start_from_scratch",
                label: "Start from scratch",
              },
              {
                value: "copy_from_existing",
                label: "Copy from an existing form",
                renderWhenSelectedNode: () => (
                  <StaticSingleSelectV2
                    formMethods={formMethods}
                    name="copy_from_form_id"
                    className="w-[250px]"
                    options={availableFormOptions}
                  />
                ),
              },
            ]}
          />
        </>
      ) : null}
    </Form.Modal>
  );
};
