/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StaffPermission,
    StaffPermissionFromJSON,
    StaffPermissionFromJSONTyped,
    StaffPermissionToJSON,
} from './StaffPermission';

/**
 * 
 * @export
 * @interface UserWithStaffPermissions
 */
export interface UserWithStaffPermissions {
    /**
     * URL of user avatar image
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    avatar_url?: string;
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    email: string;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    id: string;
    /**
     * Name of the user
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    name: string;
    /**
     * The staff permissions this user has.
     * @type {Array<StaffPermission>}
     * @memberof UserWithStaffPermissions
     */
    permissions: Array<StaffPermission>;
}

export function UserWithStaffPermissionsFromJSON(json: any): UserWithStaffPermissions {
    return UserWithStaffPermissionsFromJSONTyped(json, false);
}

export function UserWithStaffPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithStaffPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'email': json['email'],
        'id': json['id'],
        'name': json['name'],
        'permissions': ((json['permissions'] as Array<any>).map(StaffPermissionFromJSON)),
    };
}

export function UserWithStaffPermissionsToJSON(value?: UserWithStaffPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'permissions': ((value.permissions as Array<any>).map(StaffPermissionToJSON)),
    };
}

