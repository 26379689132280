import { useResize } from "@incident-io/status-page-ui/use-resize";
import { Badge, BadgeTheme } from "@incident-ui/Badge/Badge";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Bar,
  CartesianGrid,
  CartesianGridProps,
  Cell,
  ComposedChart,
  LabelProps,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { ReferenceLinePosition } from "recharts/types/cartesian/ReferenceLine";
import { CategoricalChartState } from "recharts/types/chart/types";
import { ImplicitLabelType } from "recharts/types/component/Label";
import { tcx } from "src/utils/tailwind-classes";

import { Chart } from "./Chart";

export type ChartDatapoint = {
  id: string;
  label: string;
  [key: string]: unknown;
};

type TooltipData<T extends ChartDatapoint> = {
  x: number;
} & T;

type CustomChartConfig = {
  [key: string]: {
    label: string;
    color: string;
  };
};

export type BarProps = {
  minPointSize: number;
  defaultFill: string;
};

type ChartHeader = {
  title: string;
  value: string | number;
};

type ReferenceLineConfig = {
  getLabelConfig: (key: string) => {
    content: string;
    backgroundColor?: string;
    textColor?: string;
  };
  getLineColor: (key: string) => string;
};
export const BarChart = <T extends ChartDatapoint>({
  data,
  dataKeys = ["value"],
  referenceLineDataKeys,
  referenceLineConfig,
  chartConfig,
  cartesianGridProps,
  barProps,
  showXAxis = false,
  showYAxis = false,
  renderTooltipContent,
  className,
  emptyStateMessage,
  cursor,
  header,
}: {
  data: T[];
  dataKeys?: string[];
  // referenceLineDataKeys are keys in the data object to draw a reference line across the
  // chart for
  referenceLineDataKeys?: string[];
  // referenceLineConfig determines the colour, and label content of that reference line
  referenceLineConfig?: ReferenceLineConfig;
  chartConfig: CustomChartConfig;
  cartesianGridProps?: CartesianGridProps;
  barProps?: BarProps;
  showXAxis?: boolean;
  showYAxis?: boolean;

  renderTooltipContent?: (value: T) => React.ReactNode;
  className?: string;
  cursor?: string;

  emptyStateMessage?: string;
  header?: ChartHeader;
}) => {
  const defaultCartesianGridProps: CartesianGridProps = {
    vertical: false,
    horizontal: false,
  };

  const chartRef = useRef<HTMLDivElement>(null);
  const [activeBar, setActiveBar] = useState<TooltipData<T> | null>(null);

  // Set active bar as whatever is currently hovered on
  const handleMouseMove = (state: CategoricalChartState) => {
    if (state.activePayload) {
      const barProps = state.activePayload[0].payload;
      const barX = state.activeCoordinate?.x;

      setActiveBar({
        x: barX,
        ...barProps,
      });
    }
  };

  // Unset active bar when we aren't hovering on anything
  const handleMouseLeave = useCallback(() => {
    if (activeBar) {
      setActiveBar(null);
    }
  }, [activeBar, setActiveBar]);

  const allValuesZero = data.every((entry) =>
    dataKeys.every((key) => !hasValue(entry, key)),
  );
  const { width: chartWidth, height: chartHeight } = useResize(chartRef);

  return (
    <div ref={chartRef} className={tcx("relative")}>
      {header && (
        <div className="flex justify-between items-baseline mb-2">
          <span className="text-xs-bold text-content-primayr">
            {header.title}
          </span>
          <span className="text-xs-med text-content-secondary">
            {header.value}
          </span>
        </div>
      )}
      <Chart.ChartContainer config={chartConfig} className={className}>
        <ComposedChart
          accessibilityLayer
          data={data}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          margin={{ top: 2, right: 2, bottom: 8, left: 2 }} // Added margin for x-axis
          style={{ cursor }}
        >
          <CartesianGrid
            {...defaultCartesianGridProps}
            {...cartesianGridProps}
          />
          <XAxis
            hide={!showXAxis}
            dataKey="label"
            tickLine={false}
            tickMargin={2}
            axisLine={false}
            interval={0}
            angle={-45}
            textAnchor={"end"}
            fontSize={10}
            tickFormatter={(value) => value}
          />
          <YAxis hide={!showYAxis} />
          {dataKeys.map((key, index) => (
            <Bar
              key={key}
              stackId="stacked"
              dataKey={key}
              minPointSize={barProps?.minPointSize || undefined}
            >
              {data.map((entry, entryIndex) => (
                <Cell
                  key={`cell-${key}-${entryIndex}`}
                  // @ts-expect-error for some reason recharts doesn't accept this even though it works
                  // apparently fixed in 2.13.1 but we're on that?
                  // https://github.com/recharts/recharts/issues/3325
                  radius={getRadius(dataKeys, index, entry)}
                  // If we have no value for this key, but another key has a value, then don't fill.
                  fillOpacity={
                    !hasValue(entry, key) &&
                    dataKeys.some((key) => hasValue(entry, key))
                      ? 0
                      : 1
                  }
                  fill={
                    barProps && !hasValue(entry, key)
                      ? barProps.defaultFill
                      : `var(--color-${key})`
                  }
                />
              ))}
            </Bar>
          ))}

          {referenceLineDataKeys?.map((key) => {
            const stroke =
              referenceLineConfig?.getLineColor(key) || chartConfig[key]?.color;

            // This is a bit strange, but it allows us to render two average lines next to
            // each other.
            // By default, reference lines go from the middle of a bar to the middle of a
            // bar.
            // This looks weird, we want them to go from the start of one bar, to the end
            // of another bar.
            // So, if there is more than one line, we position the first one at the start
            // of each bar, and mark the end point as the start of the next section
            // For the second line, we position it at the end of each bar, and switch the
            // first x value to the end of the previous section.
            let firstXIndex = _.findIndex(data, (entry) => !!entry[key]);
            let lastXIndex = _.findLastIndex(data, (entry) => !!entry[key]);
            const y = Number(data[firstXIndex]?.[key]);
            let position: ReferenceLinePosition = "start";

            if (firstXIndex !== 0) {
              firstXIndex = firstXIndex - 1;
              position = "end";
            }

            if (lastXIndex !== data.length - 1) {
              lastXIndex = lastXIndex + 1;
            }

            if (
              !data ||
              data.length === 0 ||
              !data[firstXIndex] ||
              !data[lastXIndex]
            ) {
              return null;
            }

            const segment = [
              { x: data[firstXIndex].label, y },
              { x: data[lastXIndex].label, y },
            ];

            const labelConfig = referenceLineConfig?.getLabelConfig(key);

            // If our value is 0, don't render a line at all
            if (y === 0) {
              return null;
            }

            return (
              <ReferenceLine
                position={position}
                key={key}
                label={getCustomLabelWithContent(
                  labelConfig?.content || (y as number).toString(),
                  labelConfig?.backgroundColor,
                  labelConfig?.textColor,
                )}
                stroke={stroke}
                strokeWidth={2}
                strokeDasharray={"4"}
                segment={segment}
                ifOverflow="visible"
              />
            );
          })}
        </ComposedChart>
      </Chart.ChartContainer>
      {renderTooltipContent ? (
        <BarTooltip<T>
          activeBar={activeBar}
          dataKeys={dataKeys}
          renderContent={renderTooltipContent}
          containerRef={chartRef}
        />
      ) : null}
      {chartWidth && chartHeight && allValuesZero && emptyStateMessage ? (
        <Badge
          theme={BadgeTheme.Tertiary}
          className="absolute"
          icon={IconEnum.Chart}
          style={{ top: chartHeight / 2 - 14, left: chartWidth / 2 - 120 }}
        >
          {emptyStateMessage}
        </Badge>
      ) : null}
    </div>
  );
};

// cache for how wide each of our labels are
const measurementCache = new Map();

// getCustomLabelWithContent returns a label component that matches the type that recharts
// expects
// The label has to be an svg, so we can't use divs etc or any normal react components, as
// labels are rendered with the rest of the chart all at once as a svg.
const getCustomLabelWithContent = (
  content: string,
  backgroundColor = "#aaa",
  textColor = "#111",
) => {
  const CustomLabel: ImplicitLabelType = (props: LabelProps) => {
    const textRef = useRef<SVGTextElement>(null);
    const [width, setWidth] = useState(20); // Start with minimum width
    const [hasMeasured, setHasMeasured] = useState(false);

    // We have to calculate the width of our label in a special way here.
    // We want it to appear as a pill, but it's an svg, so we need to give it a specific
    // width, rather than padding.
    // So, we measure the width of our text once it's mounted, and add our desired padding
    // size to it.
    // We also cache this measurement, so we don't get flashing on rerenders.
    useLayoutEffect(() => {
      if (textRef.current && !hasMeasured) {
        // Check cache first
        if (measurementCache.has(content)) {
          setWidth(measurementCache.get(content));
        } else {
          // Measure and cache
          const bbox = textRef.current.getBBox();
          const textWidth = bbox.width + 16; // Add padding
          measurementCache.set(content, textWidth);
          setWidth(textWidth);
        }
        setHasMeasured(true);
      }
    }, [hasMeasured]);

    if (!props.viewBox) return <></>;

    const height = 20;
    const x =
      "x" in props.viewBox
        ? (props.viewBox.x || 0) + (props.viewBox.width || 0) / 2 - width / 2
        : 0;
    const y = "y" in props.viewBox ? (props.viewBox.y || 0) - height / 2 : 0;

    const textX = x + width / 2;
    const textY = y + height / 2;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          rx={height / 2}
          fill={backgroundColor}
        />
        <text
          ref={textRef}
          x={textX}
          y={textY}
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: "12px" }}
        >
          {content}
        </text>
      </g>
    );
  };

  return CustomLabel;
};

const BarTooltip = <T extends ChartDatapoint>({
  activeBar,
  dataKeys,
  renderContent,
  containerRef,
}: {
  activeBar: TooltipData<T> | null;
  dataKeys: string[];
  renderContent?: (value: T) => React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [data, setData] = useState<TooltipData<T> | null>(activeBar);
  const [isInitiallyHidden, setIsInitiallyHidden] = useState(true);

  // Set tooltip data as whatever our active bar is, but preserve it when we're hovering
  // on the tooltip, even if the active bar changes or is unset
  useEffect(() => {
    const activeBarHasValue = Boolean(
      activeBar &&
        dataKeys.some(
          (key) =>
            activeBar[key] && activeBar[key] !== 0 && activeBar[key] !== "0",
        ),
    );

    if (activeBarHasValue) {
      // Always set data if we don't have any yet
      if (!data) {
        setData(activeBar);
      }
      // Otherwise, only update it if we're not hovering on the tooltip
      if (!isHovered) {
        setData(activeBar);
      }
    } else {
      if (!isHovered) {
        if (!data) {
          setIsInitiallyHidden(true);
        }
        setData(null);
      }
    }
  }, [activeBar, isHovered, dataKeys, data, setIsInitiallyHidden]);

  // Only show tooltip when the data isn't all 0 values
  const isVisible = Boolean(
    data &&
      data.x &&
      dataKeys.some((key) => data[key] && data[key] !== 0 && data[key] !== "0"),
  );

  const tooltipProps:
    | { active: false; centerOfTarget?: number }
    | { active: true; centerOfTarget: number } =
    isVisible && data
      ? {
          active: true,
          centerOfTarget: data?.x,
        }
      : {
          active: false,
        };

  return (
    <TooltipBubble
      {...tooltipProps}
      containerClassName={tcx()}
      yOffset={110}
      hovered={isHovered}
      setHovered={setIsHovered}
      containerRef={containerRef}
      isInitiallyHidden={isInitiallyHidden}
      setIsInitiallyHidden={setIsInitiallyHidden}
    >
      {data && renderContent?.(data)}
    </TooltipBubble>
  );
};

const PADDING = 2; // pixels

// This is helpful for debugging animations: slow them all down by increasing
// this value.
const ANIMATION_MODIFIER = 1;

const TooltipBubble = ({
  active,
  centerOfTarget,
  children,
  containerClassName,
  yOffset = 0,
  setHovered,
  containerRef,
  isInitiallyHidden,
  setIsInitiallyHidden,
}: {
  active: boolean;
  containerClassName?: string;
  children: React.ReactNode;
  yOffset?: number;
  centerOfTarget?: number;
  hovered: boolean;
  setHovered: (hovered: boolean) => void;
  isInitiallyHidden: boolean;
  setIsInitiallyHidden: (isInitiallyHidden: boolean) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width: tooltipWidth } = useResize(ref);
  const [containerWidth, setContainerWidth] = useState(0);

  // Grab width of our chart container, and update this if our window changes
  useEffect(() => {
    const updateWidth = () => {
      setContainerWidth(
        containerRef.current?.getBoundingClientRect().width ?? 0,
      );
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [containerRef]);
  // Calculate position on every render based on current centerOfTarget
  // By default, go right under the bar, but if we're too close to the edge of the
  // container, move inwards
  // Our tooltip transitions work by initially transitioning x coordinate and opacity.
  // We want our initial first transition to fade in, but our transition between bars
  // should transition x position only.
  // This is tricky to get right, as our tooltip first appears with position 0 or
  // undefined,
  // So, the way we handle this is
  // 1. Tooltip is at position 0, and is hidden
  // 2. Tooltip is set to our desired position, but still hidden
  // 3. Tooltip quickly transitions to that position
  // 4. When animation ends, we set hidden to false
  // 5. Tooltip is now visible and at the correct position
  const position = useMemo(() => {
    if (!centerOfTarget || !tooltipWidth || !containerWidth) {
      return {};
    }

    const perfectLeft = centerOfTarget - tooltipWidth / 2;

    if (perfectLeft < PADDING) {
      return { x: PADDING };
    }
    if (perfectLeft + tooltipWidth > containerWidth - PADDING) {
      return { x: containerWidth - tooltipWidth - PADDING };
    }

    return { x: perfectLeft };
  }, [centerOfTarget, tooltipWidth, containerWidth]);

  return (
    <AnimatePresence>
      {active && (
        <>
          <motion.div
            ref={ref}
            className={tcx(
              "absolute z-[90] max-w-[60vw]",
              "pt-1",
              containerClassName,
            )}
            style={{
              top: yOffset,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            initial={{ opacity: 0, scale: 0.98, x: position.x }}
            animate={{
              opacity: isInitiallyHidden ? 0 : 1,
              scale: 1,
              x: position.x,
              transition: {
                opacity: {
                  duration: 0.2,
                },
                x: {
                  duration: isInitiallyHidden ? 0.01 : 0.2 * ANIMATION_MODIFIER,
                },
              },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.2 * ANIMATION_MODIFIER },
            }}
            // When our initial animation finishes, show our tooltip
            onAnimationComplete={() => {
              if (position.x && isInitiallyHidden) {
                setTimeout(() => {
                  setIsInitiallyHidden(false);
                }, 10);
              }
            }}
          >
            <div
              className={tcx(
                "border cursor-auto text-sm rounded-md w-full transition",
                "bg-white border-stroke text-content-primary shadow-md",
              )}
            >
              {children}
            </div>
          </motion.div>

          {/* Safe zone that means we can move our mouse vertically to the tooltip without it disappearing */}
          <motion.div
            className="absolute pointer-events-auto"
            style={{
              width: "20px",
              height: `${yOffset}px`,
              top: 0,
              zIndex: 89,
            }}
            initial={{ opacity: 0, x: position.x }}
            animate={{
              opacity: isInitiallyHidden ? 0 : 1,
              x: position.x,
              transition: {
                duration: 0.2 * ANIMATION_MODIFIER,
                ease: "easeOut",
                x: {
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                },
              },
            }}
            exit={{ opacity: 0 }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </>
      )}
    </AnimatePresence>
  );
};

// Helper to check if value exists and is non-zero
const hasValue = (entry: ChartDatapoint, key: string) => {
  const value = entry[key];
  return value !== undefined && value !== 0 && value !== "0";
};

const getRadius = (
  dataKeys: string[],
  currentKeyIndex: number,
  entry: ChartDatapoint,
): number[] => {
  const isFirst = currentKeyIndex === 0;
  const isLast = currentKeyIndex === dataKeys.length - 1;

  if (isFirst) {
    // For first bar, check if next has value
    const nextKeyHasValue =
      dataKeys[currentKeyIndex + 1] &&
      hasValue(entry, dataKeys[currentKeyIndex + 1]);
    return nextKeyHasValue ? [0, 0, 2, 2] : [2, 2, 2, 2];
  }

  if (isLast) {
    // For last bar, check if previous has value
    const prevKeyHasValue = hasValue(entry, dataKeys[currentKeyIndex - 1]);
    return prevKeyHasValue ? [2, 2, 0, 0] : [2, 2, 2, 2];
  }

  // Middle bars don't get rounded
  return [0, 0, 0, 0];
};
