/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationBacktestEntryPayload,
    EvaluationBacktestEntryPayloadFromJSON,
    EvaluationBacktestEntryPayloadFromJSONTyped,
    EvaluationBacktestEntryPayloadToJSON,
} from './EvaluationBacktestEntryPayload';

/**
 * 
 * @export
 * @interface EvaluationBacktestPayload
 */
export interface EvaluationBacktestPayload {
    /**
     * Type of backtest to run
     * @type {string}
     * @memberof EvaluationBacktestPayload
     */
    backtest_type?: EvaluationBacktestPayloadBacktestTypeEnum;
    /**
     * ID of another backtest to compare against
     * @type {string}
     * @memberof EvaluationBacktestPayload
     */
    compare_with_backtest_id?: string;
    /**
     * Number of investigations that can be run before requiring human intervention
     * @type {number}
     * @memberof EvaluationBacktestPayload
     */
    credit?: number;
    /**
     * Optional ID of the evaluation dataset this backtest was created from
     * @type {string}
     * @memberof EvaluationBacktestPayload
     */
    dataset_id?: string;
    /**
     * Entries to add to this backtest
     * @type {Array<EvaluationBacktestEntryPayload>}
     * @memberof EvaluationBacktestPayload
     */
    entries: Array<EvaluationBacktestEntryPayload>;
    /**
     * Type of investigation plan this backtest is running
     * @type {string}
     * @memberof EvaluationBacktestPayload
     */
    investigation_plan_type?: string;
    /**
     * Overrides for the model configuration (with a key:value map of prompt -> model)
     * @type {{ [key: string]: string; }}
     * @memberof EvaluationBacktestPayload
     */
    model_overrides?: { [key: string]: string; };
    /**
     * Optional notes about the rationale for this backtest
     * @type {string}
     * @memberof EvaluationBacktestPayload
     */
    notes?: string;
    /**
     * Overrides for the investigation tuneables to use for this backtest
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof EvaluationBacktestPayload
     */
    tuneables_overrides?: { [key: string]: { [key: string]: string; }; };
    /**
     * Whether to use existing investigations for this backtest, or always create new ones
     * @type {boolean}
     * @memberof EvaluationBacktestPayload
     */
    use_existing_investigations: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationBacktestPayloadBacktestTypeEnum {
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    SearchCodeChanges = 'search_code_changes',
    SearchIncidents = 'search_incidents'
}

export function EvaluationBacktestPayloadFromJSON(json: any): EvaluationBacktestPayload {
    return EvaluationBacktestPayloadFromJSONTyped(json, false);
}

export function EvaluationBacktestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationBacktestPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_type': !exists(json, 'backtest_type') ? undefined : json['backtest_type'],
        'compare_with_backtest_id': !exists(json, 'compare_with_backtest_id') ? undefined : json['compare_with_backtest_id'],
        'credit': !exists(json, 'credit') ? undefined : json['credit'],
        'dataset_id': !exists(json, 'dataset_id') ? undefined : json['dataset_id'],
        'entries': ((json['entries'] as Array<any>).map(EvaluationBacktestEntryPayloadFromJSON)),
        'investigation_plan_type': !exists(json, 'investigation_plan_type') ? undefined : json['investigation_plan_type'],
        'model_overrides': !exists(json, 'model_overrides') ? undefined : json['model_overrides'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'tuneables_overrides': !exists(json, 'tuneables_overrides') ? undefined : json['tuneables_overrides'],
        'use_existing_investigations': json['use_existing_investigations'],
    };
}

export function EvaluationBacktestPayloadToJSON(value?: EvaluationBacktestPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_type': value.backtest_type,
        'compare_with_backtest_id': value.compare_with_backtest_id,
        'credit': value.credit,
        'dataset_id': value.dataset_id,
        'entries': ((value.entries as Array<any>).map(EvaluationBacktestEntryPayloadToJSON)),
        'investigation_plan_type': value.investigation_plan_type,
        'model_overrides': value.model_overrides,
        'notes': value.notes,
        'tuneables_overrides': value.tuneables_overrides,
        'use_existing_investigations': value.use_existing_investigations,
    };
}

