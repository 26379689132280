import {
  CustomField,
  InternalStatusPageCreatePageRequestBody,
  InternalStatusPageThemeEnum,
} from "@incident-io/api";
import { UpgradeRequiredModal } from "@incident-shared/billing";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { LoadingModal } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CreateStatusPageProps } from "src/components/status-pages/view/StatusPagesHomepage";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import { BrandingFormType } from "../BrandingForm";
import { BasicSettingsModal } from "./BasicSettingsModal";
import { BrandingModal } from "./BrandingModal";
import { ChooseCustomField } from "./ChooseCustomField";

export enum CreatePageSteps {
  BasicSettings = "basic-settings",
  Components = "components",
  Branding = "branding",
}

export const stepsConfig: StepConfig<CreatePageSteps>[] = [
  {
    id: CreatePageSteps.BasicSettings,
    name: "Basic settings",
  },
  {
    id: CreatePageSteps.Components,
    name: "Components",
  },
  {
    id: CreatePageSteps.Branding,
    name: "Branding",
  },
];

export type InternalStatusPageFormType = Omit<
  InternalStatusPageCreatePageRequestBody,
  | "theme"
  | "darkmode_logo_key"
  | "lightmode_logo_key"
  | "auto_expand_groups"
  | "use_currently_affected_components_over_groups"
> &
  BrandingFormType & {
    component_source_expression_payload?: ExpressionFormData;
    groups_over_components: boolean;
  };

export const CreateInternalStatusPageModal = (props: CreateStatusPageProps) => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/status-pages");

  const { data, isLoading: pagesLoading } = useAPI(
    "internalStatusPageListPages",
    undefined,
  );
  const {
    data: { custom_fields: allCustomFields },
    isLoading: fieldsLoading,
    error,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  if (error) {
    return <ErrorModal onClose={onClose} error={error} />;
  }

  if (!props.canCreateInternalPages) {
    return (
      <UpgradeRequiredModal
        title="Create internal status page"
        gate={{
          type: "boolean",
        }}
        featureName="creating more internal status pages"
        onClose={onClose}
        analyticsTrackingId="status-pages-create-internal-page-upgrade-required"
      />
    );
  }

  if (pagesLoading || fieldsLoading) {
    return <LoadingModal onClose={onClose} isExtraLarge />;
  }

  return (
    <CreateInternalStatusPageModalInner
      numPages={data?.internal_status_pages.length ?? 0}
      allCustomFields={allCustomFields}
      onClose={onClose}
    />
  );
};

const CreateInternalStatusPageModalInner = ({
  numPages,
  allCustomFields,
  onClose,
}: {
  numPages: number;
  allCustomFields: CustomField[];
  onClose: () => void;
}) => {
  const [step, setStep] = useState<CreatePageSteps>(
    CreatePageSteps.BasicSettings,
  );

  const orgName = useIdentity().identity?.organisation_name;
  const formMethods = useForm<InternalStatusPageFormType>({
    defaultValues: {
      name: numPages === 0 ? `${orgName} Internal Status` : "",
      subpath: numPages === 0 ? "default" : "",
      display_custom_field_ids: [],
      theme: InternalStatusPageThemeEnum.Light,
      hidden_custom_field_group_ids: [],
      hidden_custom_field_option_ids: [],
      hidden_catalog_entry_ids: [],
      hidden_catalog_group_values: [],
      setting_display_summary: false,
    },
  });

  if (step === CreatePageSteps.BasicSettings) {
    return (
      <BasicSettingsModal
        numPages={numPages}
        formMethods={formMethods}
        onClose={onClose}
        onSuccess={() => setStep(CreatePageSteps.Components)}
      />
    );
  }

  if (step === CreatePageSteps.Components) {
    return (
      <ChooseCustomField
        formMethods={formMethods}
        allCustomFields={allCustomFields}
        onClose={onClose}
        onBack={() => setStep(CreatePageSteps.BasicSettings)}
        onSuccess={() => {
          setStep(CreatePageSteps.Branding);
        }}
      />
    );
  }

  if (step === CreatePageSteps.Branding) {
    return (
      <BrandingModal
        formMethods={formMethods}
        onClose={onClose}
        onBack={() => setStep(CreatePageSteps.Components)}
      />
    );
  }

  return assertUnreachable(step);
};
