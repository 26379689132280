import {
  EvaluationBacktestBacktestTypeEnum,
  EvaluationBacktestEntryPayload,
  EvaluationBacktestPayload,
  EvaluationBacktestPayloadBacktestTypeEnum,
  EvaluationDatasetDatasetTypeEnum,
  InvestigationPlanTypeEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import {
  TuneableParameterEditorV2,
  TuneablesOverrideFormValue,
  tuneablesToPayload,
} from "../investigations/TuneableParameterEditorV2";
import {
  ModelOverridesFormValue,
  PromptModelOverrideEditorV2,
} from "./PromptModelOverrideEditorV2";

// Configuration for different backtest types
interface BacktestTypeConfig {
  value: EvaluationBacktestBacktestTypeEnum;
  label: string;
  evaluationEntryKey: keyof EvaluationBacktestEntryPayload;
  allowedDatasetTypes: EvaluationDatasetDatasetTypeEnum[];
}

const BACKTEST_TYPE_CONFIGS: BacktestTypeConfig[] = [
  {
    value: EvaluationBacktestBacktestTypeEnum.Investigation,
    label: "Investigation",
    evaluationEntryKey: "incident_id",
    allowedDatasetTypes: [EvaluationDatasetDatasetTypeEnum.Incident],
  },
  {
    value: EvaluationBacktestBacktestTypeEnum.SearchCodeChanges,
    label: "Search Code Changes",
    evaluationEntryKey: "search_ground_truth_id",
    allowedDatasetTypes: [EvaluationDatasetDatasetTypeEnum.SearchCodeChanges],
  },
  {
    value: EvaluationBacktestBacktestTypeEnum.SearchIncidents,
    label: "Search Incidents",
    evaluationEntryKey: "search_ground_truth_id",
    allowedDatasetTypes: [EvaluationDatasetDatasetTypeEnum.SearchIncidents],
  },
];

type FormData = {
  backtest_type: EvaluationBacktestBacktestTypeEnum;
  investigation_plan_type?: InvestigationPlanTypeEnum;
  limit_runs_using_credit: boolean;
  credit: number;
  notes: string;
  // We either have a whole dataset or a list of incidents and segments
  dataset_id?: string;
  use_existing_investigations: boolean;
  model_overrides: ModelOverridesFormValue;
  compare_with_backtest_id?: string;
  tuneables_overrides?: TuneablesOverrideFormValue;
};

export const BacktestCreateModal = ({
  overrideBacktestType,
  resourceIDs,
  defaultCompareWithBacktestID,
  segments,
  onClose,
  defaultPlanType = InvestigationPlanTypeEnum.Default,
}: {
  overrideBacktestType?: EvaluationBacktestBacktestTypeEnum;
  resourceIDs?: string[];
  defaultCompareWithBacktestID?: string;
  segments?: { [segmentName: string]: string[] };
  defaultPlanType?: InvestigationPlanTypeEnum;
  onClose: () => void;
}) => {
  const investigationPlans = Object.values(InvestigationPlanTypeEnum);

  const { data: datasetData, isLoading: isDataLoading } = useAPI(
    "aIStaffListEvaluationDatasets",
    {},
    {
      fallbackData: { datasets: [] },
    },
  );

  // Fetch existing backtests for comparison
  const { data: backtestsData, isLoading: isBacktestsLoading } = useAPI(
    "aIStaffListEvaluationBacktests",
    {},
    {
      fallbackData: { backtests: [] },
    },
  );

  const allowChooseDataset = !resourceIDs || resourceIDs.length === 0;

  const formMethods = useForm<FormData>({
    defaultValues: {
      backtest_type:
        overrideBacktestType ||
        EvaluationBacktestBacktestTypeEnum.Investigation,
      investigation_plan_type: defaultPlanType,
      limit_runs_using_credit: true,
      credit: 5,
      use_existing_investigations: false,
      model_overrides: {},
      compare_with_backtest_id: defaultCompareWithBacktestID,
      tuneables_overrides: [],
    },
  });

  // Get the current backtest type for conditional rendering
  const currentBacktestType = formMethods.watch("backtest_type");
  const isInvestigation =
    currentBacktestType === EvaluationBacktestBacktestTypeEnum.Investigation;

  // Get the current backtest type config
  const currentConfig = useMemo(() => {
    return (
      BACKTEST_TYPE_CONFIGS.find(
        (config) => config.value === currentBacktestType,
      ) || BACKTEST_TYPE_CONFIGS[0]
    );
  }, [currentBacktestType]);

  // Filter datasets by the current backtest type's allowed dataset types
  const filteredDatasets = useMemo(() => {
    if (
      !datasetData?.datasets ||
      currentConfig.allowedDatasetTypes.length === 0
    ) {
      return [];
    }

    return datasetData.datasets.filter((dataset) =>
      currentConfig.allowedDatasetTypes.includes(
        dataset.dataset_type as EvaluationDatasetDatasetTypeEnum,
      ),
    );
  }, [datasetData?.datasets, currentConfig.allowedDatasetTypes]);

  // Filter backtests by the current backtest type for comparison
  const filteredBacktests = useMemo(() => {
    if (!backtestsData?.backtests) {
      return [];
    }

    return backtestsData.backtests.filter(
      (backtest) => backtest.backtest_type === currentBacktestType,
    );
  }, [backtestsData?.backtests, currentBacktestType]);

  const { data: chosenDatasetData, isLoading: isChosenDatasetLoading } = useAPI(
    "aIStaffShowEvaluationDataset",
    {
      id: formMethods.watch("dataset_id") || "",
    },
  );

  const shouldUseCredit = formMethods.watch("limit_runs_using_credit");
  const apiClient = useClient();
  const navigate = useOrgAwareNavigate();

  const { trigger: createBacktest, isMutating: isCreating } = useMutationV2(
    async (apiClient, data: EvaluationBacktestPayload) => {
      return await apiClient.aIStaffCreateEvaluationBacktest({
        createEvaluationBacktestRequestBody: data,
      });
    },
    {
      invalidate: [cacheKey.exactly("aIStaffListEvaluationBacktests", {})],
      showErrorToast: "Failed to create backtest",
      showSuccessToast: "Backtest created",
      onSuccess: (data) => {
        navigate(`/workbench/backtests/${data.backtest.id}`);
      },
    },
  );

  const onSubmit = async (data: FormData) => {
    const requestBody: EvaluationBacktestPayload = {
      dataset_id: data.dataset_id,
      investigation_plan_type: isInvestigation
        ? data.investigation_plan_type
        : undefined,
      credit: data.limit_runs_using_credit ? Number(data.credit) : undefined,
      notes: data.notes,
      entries: [],
      backtest_type:
        data.backtest_type as unknown as EvaluationBacktestPayloadBacktestTypeEnum,
      use_existing_investigations: isInvestigation
        ? data.use_existing_investigations
        : false,
      model_overrides: data.model_overrides,
      tuneables_overrides: tuneablesToPayload(data.tuneables_overrides || []),
      compare_with_backtest_id: data.compare_with_backtest_id,
    };

    const getTags = (
      resourceID: string,
      segments: { [name: string]: string[] },
    ) => {
      return Object.entries(segments).reduce(
        (acc, [segmentName, segmentIDs]) => {
          if (segmentIDs.includes(resourceID)) {
            acc.push(segmentName);
          }
          return acc;
        },
        [] as string[],
      );
    };

    if (data.dataset_id) {
      // We need to grab the dataset and the grouped entries, so we know what incidentIDs
      // to pass down.
      const dataset = datasetData?.datasets?.find(
        (d) => d.id === data.dataset_id,
      );
      if (!dataset) {
        throw new Error("Unreachable: selected dataset not found");
      }
      const groupedEntries =
        await apiClient.aIStaffGroupDatasetEntriesBySegment({
          id: data.dataset_id,
        });

      requestBody.entries =
        chosenDatasetData?.entries.map((entry) => ({
          [currentConfig.evaluationEntryKey]: entry.resource_id,
          tags: getTags(entry.id, groupedEntries.entries_by_segment),
        })) || [];
    } else if (resourceIDs) {
      requestBody.entries = resourceIDs.map((id) => ({
        [currentConfig.evaluationEntryKey]: id,
        tags: segments ? getTags(id, segments) : [],
      }));
    }
    return createBacktest(requestBody);
  };

  // Use the config for display name
  const backTestTypeDisplay = currentConfig.label;

  return (
    <Form.Modal
      formMethods={formMethods}
      title={`Create ${backTestTypeDisplay} Backtest`}
      onClose={onClose}
      analyticsTrackingId="create-backtest"
      disableQuickClose
      onSubmit={(data: FormData) => {
        onSubmit(data);
      }}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={`Create backtest`}
          analyticsTrackingId="create-backtest-submit"
          saving={isCreating}
          disabled={allowChooseDataset && filteredDatasets.length === 0}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="backtest_type"
        label="Backtest type"
        disabled={!!overrideBacktestType}
        helptext={"Choose what type of evaluations you want to run."}
        options={BACKTEST_TYPE_CONFIGS.filter(
          (config) =>
            config.allowedDatasetTypes.length > 0 ||
            config.value === EvaluationBacktestBacktestTypeEnum.Investigation,
        ).map((config) => ({
          label: config.label,
          value: config.value,
        }))}
      />

      {/* Only show investigation plan for investigation backtests */}
      {isInvestigation && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="investigation_plan_type"
          label="Investigation plan"
          helptext={"Choose what parts of an investigation you want to run."}
          options={investigationPlans.map((p) => ({
            label: p,
            value: p,
          }))}
        />
      )}

      {/* If we haven't been passed incident IDs, we need to choose from our list of datasets */}
      {allowChooseDataset && (
        <>
          {filteredDatasets.length === 0 && !isDataLoading ? (
            <Callout
              theme={CalloutTheme.Warning}
              title={`No ${currentConfig.label} datasets available`}
              subtitle={
                <>
                  There are no datasets available for {currentConfig.label}{" "}
                  backtests. Please create a dataset with the type{" "}
                  {currentConfig.allowedDatasetTypes.join(" or ")} first.
                </>
              }
            />
          ) : (
            <StaticSingleSelectV2
              formMethods={formMethods}
              name="dataset_id"
              label="Dataset"
              isLoading={isDataLoading || isChosenDatasetLoading}
              helptext={"Which dataset should this backtest run against."}
              required
              options={filteredDatasets.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
            />
          )}
        </>
      )}

      <ToggleV2
        formMethods={formMethods}
        name="limit_runs_using_credit"
        label="Test against a subset of entries first (by setting a 'credit')"
      />

      {shouldUseCredit && (
        <InputV2
          type={InputType.Number}
          formMethods={formMethods}
          name="credit"
          label="Credit"
          helptext={
            "How many entries should be processed before pausing and asking a human to review."
          }
        />
      )}

      <TextareaV2
        formMethods={formMethods}
        name="notes"
        label="Notes"
        helptext="Any notes you want to attach to this backtest."
      />

      {/* Only show this option for investigation backtests */}
      {isInvestigation && (
        <CheckboxRowV2
          formMethods={formMethods}
          name="use_existing_investigations"
          label="Use existing investigations?"
          description="If you toggle this off, we will create new investigations for this backtest. This might cause unexpected results as we're having to 'fake' what data would have been available to the investigation."
        />
      )}

      {/* Compare with backtest dropdown */}
      {filteredBacktests.length > 0 && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="compare_with_backtest_id"
          label="Compare with backtest"
          helptext="Optionally select another backtest with the same type to compare results."
          isLoading={isBacktestsLoading}
          options={[
            { label: "None", value: "" },
            ...filteredBacktests.map((backtest) => ({
              label: `${new Date(backtest.created_at).toLocaleString()} (${
                backtest.notes || "No notes..."
              })`,
              value: backtest.id,
            })),
          ]}
        />
      )}

      <PromptModelOverrideEditorV2<FormData>
        formMethods={formMethods}
        name="model_overrides"
        label="Model Overrides"
        helptext="Configure which AI models to use for specific prompts in this backtest."
      />
      {isInvestigation && (
        <TuneableParameterEditorV2
          formMethods={formMethods}
          name="tuneables_overrides"
        />
      )}
    </Form.Modal>
  );
};

export const BacktestCreateFromBulkActionModal = ({
  onClose,
  incidentIDs,
}: {
  onClose: () => void;
  incidentIDs: string[];
}) => {
  return (
    <BacktestCreateModal
      onClose={onClose}
      resourceIDs={incidentIDs}
      overrideBacktestType={EvaluationBacktestBacktestTypeEnum.Investigation}
    />
  );
};
