import {
  OnCallNotificationMethod,
  OnCallNotificationMethodPhoneNumberStatusEnum,
  OnCallNotificationMethodV2StatusEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  LocalRelativeDateTime,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";

import {
  makeMethodDisplayItem,
  MethodDisplayItem,
  MethodUnavailableReasons,
} from "./ContactMethods";
import { Method } from "./OnCallNotificationsPage";

interface Props {
  method: Method;
  onVerify?: (method: OnCallNotificationMethod) => void;
  onDelete?: (methodId: string) => void;
  onRemoveMobileApp?: (mobileKeyId: string) => void;
  onTest?: (method: MethodDisplayItem) => void;
  onInstall?: () => void;
}

export const ContactMethod = ({
  method,
  onVerify,
  onDelete,
  onRemoveMobileApp,
  onTest,
  onInstall,
}: Props): React.ReactElement => {
  const { identity } = useIdentity();
  const methodDisplayItem = makeMethodDisplayItem(identity, method);
  const methodId = methodDisplayItem.methodId;
  const phoneMethod = method.phone;
  const mobileKeyId = methodDisplayItem.mobileKeyID;

  const numberIsPendingVerification =
    method.status ===
      OnCallNotificationMethodV2StatusEnum.PendingVerification &&
    method.phone &&
    method.phone.phone_number_status !==
      OnCallNotificationMethodPhoneNumberStatusEnum.Blocked;

  return (
    <div className="w-full p-4 border-b last border-stroke justify-between items-center inline-flex last:border-b-0 !max-h-12">
      <div className="justify-start items-center gap-3 flex">
        <div className="h-5 justify-start gap-2 flex w-[100px]">
          <Icon
            id={methodDisplayItem.icon}
            size={IconSize.Medium}
            className={"text-slate-600"}
          />
          <span className="text-sm text-pretty text-content-primary font-semibold">
            {methodDisplayItem.title}
          </span>
        </div>
        {methodDisplayItem.unavailable_reason ? (
          <Badge theme={BadgeTheme.Warning}>
            {MethodUnavailableReasons[methodDisplayItem.unavailable_reason]}
          </Badge>
        ) : (
          <span className="text-sm text-pretty text-content-primary">
            {methodDisplayItem.value}
          </span>
        )}
        {methodDisplayItem.lastUsedAt && (
          <span className="text-sm text-pretty text-slate-600">
            Last used{" "}
            <LocalRelativeDateTime
              className={"hover:!no-underline"}
              date={methodDisplayItem.lastUsedAt}
            />
          </span>
        )}
        {numberIsPendingVerification && (
          <Badge theme={BadgeTheme.Warning}>Unverified</Badge>
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        {numberIsPendingVerification && phoneMethod && onVerify && (
          <Button
            title={"Verify"}
            analyticsTrackingId={"verify-on-call-notification-method"}
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Medium}
            onClick={() => onVerify(phoneMethod)}
          >
            Verify
          </Button>
        )}
        {method.phone &&
          method.phone.phone_number_status ===
            OnCallNotificationMethodPhoneNumberStatusEnum.Blocked && (
            <Tooltip
              content={`This number has been unsubscribed. To reactivate this number, send START to ${method.phone.expected_switchboard_sender} or any other number you have received incident.io on-call notifications from.`}
            >
              <div>
                <Badge theme={BadgeTheme.Error}>Unsubscribed</Badge>
              </div>
            </Tooltip>
          )}
        {methodDisplayItem.unavailable_reason === "not-installed" &&
          onInstall && (
            <Button
              title={"Install"}
              analyticsTrackingId={"install-on-call-app"}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Medium}
              onClick={onInstall}
            >
              Install
            </Button>
          )}
        {(methodDisplayItem.methodId || methodDisplayItem.mobileKeyID) && (
          <DropdownMenu
            menuClassName={"w-[140px] !py-1"}
            side={"bottom"}
            triggerButton={
              <Button
                theme={ButtonTheme.Naked}
                type="button"
                className="-ml-2"
                analyticsTrackingId="on-call-notification-method-options"
                icon={IconEnum.DotsVerticalNopad}
                iconProps={{ size: IconSize.Medium }}
                title="On call notification method options"
              />
            }
          >
            {methodId && onDelete ? (
              <DropdownMenuItem
                onSelect={() => onDelete(methodId)}
                analyticsTrackingId={"on-call-notification-method-delete"}
                label="Delete"
                destructive
              >
                Delete
              </DropdownMenuItem>
            ) : null}
            {mobileKeyId && onRemoveMobileApp ? (
              <DropdownMenuItem
                onSelect={() => onRemoveMobileApp(mobileKeyId)}
                analyticsTrackingId={"on-call-notification-method-delete"}
                label="Delete"
                destructive
              >
                Delete
              </DropdownMenuItem>
            ) : null}
          </DropdownMenu>
        )}
        {!methodDisplayItem.unavailable_reason &&
          method.status === OnCallNotificationMethodV2StatusEnum.Verified &&
          onTest && (
            <Tooltip content={"Send a test notification"}>
              <Button
                analyticsTrackingId={"test-on-call-notification-method"}
                theme={ButtonTheme.Naked}
                onClick={() => onTest(methodDisplayItem)}
                icon={IconEnum.BellRinging}
                iconProps={{ size: IconSize.Medium }}
                title={"Test notification method"}
              />
            </Tooltip>
          )}
      </div>
    </div>
  );
};
