import { usePylon } from "@bolasim/react-use-pylon";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  IntegrationConfig,
  IntegrationConfigFor,
  IntegrationsListObject,
  reconnectModalUrlFor,
} from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Link,
  Modal,
  ModalContent,
} from "@incident-ui";
import React from "react";
import { useLocation } from "react-router";
import {
  IntegrationSettingsReconnectionReasonEnum as ReconnectionReasonEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";

type ReconnectionConfig = {
  message: (
    label: string,
    showArticle: (articleId: string) => void,
  ) => React.ReactNode | undefined;
  verb?: string;
};

const RECONNECTION_CONFIGS: {
  [key in ReconnectionReasonEnum]: ReconnectionConfig;
} = {
  [ReconnectionReasonEnum.Empty]: {
    message: () => undefined,
  },
  [ReconnectionReasonEnum.MissingScheduleWriteScope]: {
    message: () => (
      <>
        To{" "}
        <Link
          analyticsTrackingId={"linear-mirroring-docs"}
          href={"https://linear.app/docs/triage#triage-responsibility"}
          openInNewTab
        >
          mirror your on-call schedules into Linear
        </Link>
        , incident.io needs permission there. Please disconnect and reconnect
        the Linear integration and enable write access.
      </>
    ),
  },
  [ReconnectionReasonEnum.GithubMissingScopes]: {
    message(_, showArticle) {
      return (
        <>
          {
            "To use GitHub attachments, you'll need to grant the incident.io app additional permissions. Understand more about why we're asking in "
          }
          <Button
            onClick={() => showArticle("5465313741")}
            theme={ButtonTheme.Link}
            analyticsTrackingId="github-missing-scopes-help"
          >
            this help article.
          </Button>
        </>
      );
    },
    verb: "Reconnect",
  },

  [ReconnectionReasonEnum.PagerdutyLimitedUser]: {
    message() {
      return (
        <>
          {
            "The connected PagerDuty user is a 'limited user', which means we can't auto-create incidents."
          }{" "}
          You can still use <code>/incident escalate</code>.
        </>
      );
    },
    verb: "Reconnect",
  },

  [ReconnectionReasonEnum.TokenExpired]: {
    message: (label) =>
      `There's a problem with the connection to ${label}. Please reconnect the integration.`,
    verb: "Reconnect",
  },

  [ReconnectionReasonEnum.MultipleAccounts]: {
    message: (label) =>
      `We're unable to connect to your ${label} account because it is being used by another incident.io account.`,
    verb: "Reconnect",
  },

  [ReconnectionReasonEnum.InsufficientPermissions]: {
    message: (label) =>
      `Our connection to ${label} doesn't have sufficient permissions. Please check that we have all required permissions.`,
  },

  [ReconnectionReasonEnum.InvalidConfig]: {
    message: () => "There's an issue with your configuration.",
  },

  [ReconnectionReasonEnum.IncompleteConfig]: {
    message: () => "Your configuration is incomplete.",
  },
  [ReconnectionReasonEnum.SalesforceTooManyAccounts]: {
    message: () =>
      "The account list you've selected to sync is too large. Please select a list with fewer than 50,000 accounts.",
  },
  [ReconnectionReasonEnum.NotACalendarUser]: {
    message: () =>
      "The connected Google account is not signed up for Google Calendar. Please reconnect with a Google account that has Google Calendar enabled.",
  },
  [ReconnectionReasonEnum.GmeetUnavailableOnPrimaryCalendar]: {
    message: () =>
      "Google Meet is not available on connected user's primary calendar. Please reconnect with a Google account that has Google Meet enabled.",
  },
  [ReconnectionReasonEnum.InvalidConnectedUser]: {
    message: (label) =>
      `${label} is connected with an invalid user. Please reconnect with a user that has access to ${label}'s API.`,
  },
  [ReconnectionReasonEnum.InvalidGrafanaPermissions]: {
    message: () => (
      <div>
        Due to an issue with Grafana&rsquo;s permissions system, we require the{" "}
        <span className="font-mono">dashboards:write</span> permission to render
        dashboard screenshots from alerts. Please reconnect the integration and
        grant this permission.
      </div>
    ),
  },
  [ReconnectionReasonEnum.DeletedPagerdutyBotUser]: {
    message: () => (
      <div>
        The PagerDuty user that incident.io was connected to has been deleted.
        Please reconnect the integration with a valid PagerDuty user.
      </div>
    ),
  },
};

const reconnectionMessage = (
  label: string,
  showArticle: (articleId: string) => void,
  reason: ReconnectionReasonEnum,
): React.ReactNode | undefined => {
  return (
    RECONNECTION_CONFIGS[reason]?.message(label, showArticle) ??
    "There's an issue with your configuration."
  );
};

const reconnectionVerb = (
  reason: ReconnectionReasonEnum,
): string | undefined => {
  return RECONNECTION_CONFIGS[reason]?.verb ?? "Reconnect";
};

export const IntegrationErrorCallout = ({
  config,
  integration,
  onOpenReconnectModal,
}: {
  integration: IntegrationsListObject;
  config: IntegrationConfig;
  onOpenReconnectModal: () => void;
}): React.ReactElement | null => {
  const { showKnowledgeBaseArticle } = usePylon();
  const needsReinstall =
    integration.reconnection_reason !== ReconnectionReasonEnum.Empty &&
    integration.installed;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const errorKey = `${integration.provider}_error`;
  const error = params.get(errorKey);

  // first: if there's an error, show that
  if (error != null) {
    return (
      <Callout
        theme={CalloutTheme.Danger}
        title="Connection failed"
        subtitle={`We couldn't connect to ${config.label} because ${error}`}
        cta={
          <Button
            analyticsTrackingId={null}
            href={`mailto:help@incident.io?subject=${encodeURIComponent(
              "Issue connecting to " + config.label,
            )}`}
            openInNewTab
          >
            Contact support
          </Button>
        }
      />
    );
  }

  // second: if there's a reconnection message, show that
  if (needsReinstall) {
    return (
      <Callout theme={CalloutTheme.Warning} className="!mt-2">
        <div>
          {reconnectionMessage(
            config.label,
            showKnowledgeBaseArticle,
            integration.reconnection_reason,
          )}
        </div>
        {/* Not all reconnection reasons mean 'reconnect', some just mean 'reconfigure', for which the CTA is right above */}
        {RECONNECTION_CONFIGS[integration.reconnection_reason]?.verb && (
          <GatedButton
            analyticsTrackingId="reconnect-integration"
            analyticsTrackingMetadata={{
              provider: integration.provider,
            }}
            className="mt-2"
            onClick={onOpenReconnectModal}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          >
            {reconnectionVerb(integration.reconnection_reason)}
          </GatedButton>
        )}
      </Callout>
    );
  }

  return null;
};

export const ReconnectIntegrationModal = ({
  integration,
  onClose,
  onSubmit,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement => {
  const config = IntegrationConfigFor(integration.provider);

  return (
    <Modal
      title={`Reconnect to ${config.label}`}
      isOpen
      analyticsTrackingId="reconnect-integration"
      analyticsTrackingMetadata={{ integration: integration.provider }}
      onClose={onClose}
    >
      <ModalContent>
        <p className="text-sm text-slate-700">
          In order to reconnect to {config.label}, we need you to step through
          the install flow again.
        </p>
        <div className="text-right mt-4">
          <GatedButton
            analyticsTrackingId={`settings-integrations-reconnect-${integration.provider}`}
            href={reconnectModalUrlFor(integration.provider)}
            className="mt-2"
            onClick={onSubmit}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          >
            Next
          </GatedButton>
        </div>
      </ModalContent>
    </Modal>
  );
};
