/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateExternalRequestBody
 */
export interface SchedulesCreateExternalRequestBody {
    /**
     * IDs of schedules to import. Note that this is the internal ID, not the external ID.
     * @type {Array<string>}
     * @memberof SchedulesCreateExternalRequestBody
     */
    schedule_ids: Array<string>;
    /**
     * User IDs that should be promoted to be on-call responders before making changes to the schedule
     * @type {Array<string>}
     * @memberof SchedulesCreateExternalRequestBody
     */
    user_ids_to_promote?: Array<string>;
}

export function SchedulesCreateExternalRequestBodyFromJSON(json: any): SchedulesCreateExternalRequestBody {
    return SchedulesCreateExternalRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateExternalRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateExternalRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_ids': json['schedule_ids'],
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
    };
}

export function SchedulesCreateExternalRequestBodyToJSON(value?: SchedulesCreateExternalRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_ids': value.schedule_ids,
        'user_ids_to_promote': value.user_ids_to_promote,
    };
}

