import { AlertRouteSlim, AlertSourceConfig } from "@incident-io/api";
import { Edge } from "reactflow";

import { AlertsConfigurationEdgeData } from "../AlertsConfigurationEdge";
import { orderAlertSources } from "./groupSourcesAndRoutes";

export const drawEdges = ({
  filteredAlertSources,
  filteredAlertRoutes,
  hasNoAlertRoutes,
}: {
  filteredAlertRoutes: AlertRouteSlim[];
  filteredAlertSources: AlertSourceConfig[];
  hasNoAlertRoutes: boolean;
}) => {
  const edges: Edge<AlertsConfigurationEdgeData>[] = [];

  for (const route of filteredAlertRoutes) {
    // Get the sources that are linked to this route, and sort them first by the
    // time at which they last fired, and then by name.
    const routeSources = orderAlertSources(
      filteredAlertSources.filter((source) => {
        return route.alert_sources
          .map((src) => src.alert_source_id)
          .includes(source.id);
      }),
    );

    for (const source of routeSources) {
      edges.push({
        id: `${source.id}-${route.id}`,
        type: "alertsConfigurationEdge",
        source: source.id,
        target: route.id,
        data: {
          alertSource: filteredAlertSources.find((src) => src.id === source.id),
          alertRoute: route,
          colorBucket: bucketNumberFromLastFiredAt({
            lastFiredAt: source.alert_last_fired_at || new Date(),
          }),
        },
      });
    }
  }

  if (hasNoAlertRoutes) {
    for (const source of filteredAlertSources.slice(0, 3).reverse()) {
      edges.push({
        id: `${source.id}-empty-state`,
        type: "alertsConfigurationEdge",
        source: source.id,
        target: `no-alert-routes`,
        data: {
          alertSource: source,
        },
      });
    }
  }

  return edges;
};

const bucketNumberFromLastFiredAt = ({
  lastFiredAt,
}: {
  lastFiredAt: Date;
}) => {
  const diffInHours = (Date.now() - lastFiredAt.getTime()) / (1000 * 60 * 60);

  if (diffInHours <= 1) {
    return 0;
  } else if (diffInHours <= 24) {
    return 1;
  } else if (diffInHours <= 24 * 7) {
    return 2;
  } else if (diffInHours <= 24 * 30) {
    return 3;
  } else {
    return 4;
  }
};
