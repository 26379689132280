/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotInteraction
 */
export interface CopilotInteraction {
    /**
     * When the interaction was created.
     * @type {Date}
     * @memberof CopilotInteraction
     */
    completed_at?: Date;
    /**
     * When the interaction was created.
     * @type {Date}
     * @memberof CopilotInteraction
     */
    created_at: Date;
    /**
     * 
     * @type {User}
     * @memberof CopilotInteraction
     */
    from_user: User;
    /**
     * Unique ID of the interaction
     * @type {string}
     * @memberof CopilotInteraction
     */
    id: string;
    /**
     * The time it took to generate the response
     * @type {number}
     * @memberof CopilotInteraction
     */
    latency_seconds?: number;
    /**
     * Total cost of generating the response (based on AI tokens)
     * @type {number}
     * @memberof CopilotInteraction
     */
    overall_cost_cents?: number;
    /**
     * The message that was generated by Copilot
     * @type {string}
     * @memberof CopilotInteraction
     */
    response_message?: string;
    /**
     * The message that triggered the copilot interaction
     * @type {string}
     * @memberof CopilotInteraction
     */
    source_message: string;
    /**
     * An ID for grouping interactions into a thread
     * @type {string}
     * @memberof CopilotInteraction
     */
    source_thread_id: string;
    /**
     * The ID of the trace for this interaction
     * @type {string}
     * @memberof CopilotInteraction
     */
    trace_id: string;
}

export function CopilotInteractionFromJSON(json: any): CopilotInteraction {
    return CopilotInteractionFromJSONTyped(json, false);
}

export function CopilotInteractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotInteraction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': (new Date(json['created_at'])),
        'from_user': UserFromJSON(json['from_user']),
        'id': json['id'],
        'latency_seconds': !exists(json, 'latency_seconds') ? undefined : json['latency_seconds'],
        'overall_cost_cents': !exists(json, 'overall_cost_cents') ? undefined : json['overall_cost_cents'],
        'response_message': !exists(json, 'response_message') ? undefined : json['response_message'],
        'source_message': json['source_message'],
        'source_thread_id': json['source_thread_id'],
        'trace_id': json['trace_id'],
    };
}

export function CopilotInteractionToJSON(value?: CopilotInteraction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'from_user': UserToJSON(value.from_user),
        'id': value.id,
        'latency_seconds': value.latency_seconds,
        'overall_cost_cents': value.overall_cost_cents,
        'response_message': value.response_message,
        'source_message': value.source_message,
        'source_thread_id': value.source_thread_id,
        'trace_id': value.trace_id,
    };
}

