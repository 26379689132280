import { IncidentSuggestion } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
} from "@incident-ui";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { DebugSuggestionsList } from "src/components/legacy/incident/body/DebugViewSuggestions";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { useDebounce } from "use-debounce";

type Conviction = IncidentSuggestion["conviction"];
type SuggestionType = IncidentSuggestion["suggestion_type"];

type SuggestionsFilters = {
  convictions: Conviction[];
  types: SuggestionType[];
  incidentId: string;
  resourceId: string;
  nudged: boolean;
};

export const WorkbenchSuggestionsListPage = () => {
  const [showFilters, setShowFilters] = useState(false);
  const params = useQueryParams();

  const incident = params.get("incident") ?? "";

  const formMethods = useForm<SuggestionsFilters>({
    defaultValues: {
      convictions: [],
      incidentId: incident,
      resourceId: "",
      types: [],
      nudged: false,
    },
  });

  const filters = formMethods.watch();

  const [debouncedIncidentID] = useDebounce(filters.incidentId, 500);
  const [debouncedResourceID] = useDebounce(filters.resourceId, 500);

  const {
    data: { incident_suggestions },
    isLoading,
  } = useAPI(
    "incidentSuggestionsListSuggestions",
    {
      includeArchived: true,
      convictions: filters.convictions,
      suggestionType: filters.types,
      nudged: filters.nudged,
      incidentId: debouncedIncidentID || undefined,
      resourceId: debouncedResourceID || undefined,
    },
    {
      fallbackData: { incident_suggestions: [] },
    },
  );

  return (
    <WorkbenchSubPageWrapper>
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex flex-row items-center gap-4">
          <div className="flex-center-y w-full justify-between">
            <div className="flex-center-y gap-4">
              <h2 className="text-lg font-semibold">Recent suggestions</h2>
            </div>
            <Button
              theme={ButtonTheme.Naked}
              onClick={() => setShowFilters(!showFilters)}
              analyticsTrackingId={null}
              icon={IconEnum.Filter}
            >
              <>Filters</>
              <Icon
                id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                size={IconSize.Small}
              />
            </Button>
          </div>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false}>
            <div className="flex flex-col gap-2">
              <ConvictionSelect formMethods={formMethods} />
              <TypeSelect formMethods={formMethods} />
              <IncidentInput formMethods={formMethods} />
              <ResourceInput formMethods={formMethods} />
              <NudgedInput formMethods={formMethods} />
            </div>
          </Callout>
        )}
        <LoadingWrapper loading={isLoading}>
          <DebugSuggestionsList
            linkIncident
            suggestions={incident_suggestions}
            onViewForIncident={(incidentId) => {
              formMethods.setValue("incidentId", incidentId);
            }}
          />
        </LoadingWrapper>
      </Form.Root>
    </WorkbenchSubPageWrapper>
  );
};

const ConvictionSelect = ({
  formMethods,
}: {
  formMethods: UseFormReturn<SuggestionsFilters>;
}) => {
  const options = [
    { label: "High", value: "30_high" },
    { label: "Medium", value: "20_medium" },
    { label: "Low", value: "10_low" },
    { label: "No change", value: "00_no_change" },
  ];

  return (
    <StaticMultiSelectV2
      formMethods={formMethods}
      name="convictions"
      options={options}
      label="Convictions"
      isClearable
    />
  );
};

const TypeSelect = ({
  formMethods,
}: {
  formMethods: UseFormReturn<SuggestionsFilters>;
}) => {
  const options = [
    { label: "Role", value: "incident_role" },
    { label: "Name", value: "name" },
    { label: "Update", value: "update" },
  ];

  return (
    <StaticMultiSelectV2
      formMethods={formMethods}
      name="types"
      options={options}
      label="Suggestion types"
      isClearable
    />
  );
};

const IncidentInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<SuggestionsFilters>;
}) => {
  return (
    <InputV2
      formMethods={formMethods}
      name="incidentId"
      label="Incident ID"
      placeholder="01FCNDV6P870EA6S7TK1DSYDG0"
    />
  );
};

const ResourceInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<SuggestionsFilters>;
}) => {
  return (
    <InputV2
      formMethods={formMethods}
      name="resourceId"
      label="Resource ID"
      placeholder="01FCNDV6P870EA6S7TK1DSYDG0"
    />
  );
};

const NudgedInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<SuggestionsFilters>;
}) => {
  return (
    <ToggleV2
      formMethods={formMethods}
      name="nudged"
      label="Nudged"
      description="Only include suggestions which nudged"
    />
  );
};
