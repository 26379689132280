/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationScorecardGrade,
    EvaluationScorecardGradeFromJSON,
    EvaluationScorecardGradeFromJSONTyped,
    EvaluationScorecardGradeToJSON,
} from './EvaluationScorecardGrade';

/**
 * Aggregate scorecard measuring performance across multiple entries
 * @export
 * @interface EvaluationAggregateScorecard
 */
export interface EvaluationAggregateScorecard {
    /**
     * IDs of the scorecards that were aggregated
     * @type {Array<string>}
     * @memberof EvaluationAggregateScorecard
     */
    evaluation_scorecard_ids: Array<string>;
    /**
     * Collection of aggregated grades across all scorecards
     * @type {Array<EvaluationScorecardGrade>}
     * @memberof EvaluationAggregateScorecard
     */
    grades: Array<EvaluationScorecardGrade>;
    /**
     * Warnings generated during the aggregation process
     * @type {Array<string>}
     * @memberof EvaluationAggregateScorecard
     */
    warnings: Array<string>;
}

export function EvaluationAggregateScorecardFromJSON(json: any): EvaluationAggregateScorecard {
    return EvaluationAggregateScorecardFromJSONTyped(json, false);
}

export function EvaluationAggregateScorecardFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationAggregateScorecard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluation_scorecard_ids': json['evaluation_scorecard_ids'],
        'grades': ((json['grades'] as Array<any>).map(EvaluationScorecardGradeFromJSON)),
        'warnings': json['warnings'],
    };
}

export function EvaluationAggregateScorecardToJSON(value?: EvaluationAggregateScorecard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluation_scorecard_ids': value.evaluation_scorecard_ids,
        'grades': ((value.grades as Array<any>).map(EvaluationScorecardGradeToJSON)),
        'warnings': value.warnings,
    };
}

