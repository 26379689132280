import {
  AIStaffListEvaluationNotesResourceTypeEnum,
  SearchGroundTruth,
} from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  LocalDateTime,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { LabeledValue } from "../common/LabeledValue";
import { EvaluationNotesWidget } from "../evaluation-notes/EvaluationNotesWidget";
import { VerifiedButton } from "../ground-truths/VerifiedButton";
import { SearchGroundTruthsEditForm } from "./SearchGroundTruthsEditForm";
import { SearchCodeChangeItem } from "./SearchShowPage";

export const SearchGroundTruthsShowPage = () => {
  const { id } = useParams<{ id: string }>() as { id: string };

  const { data, isLoading } = useAPI("aIStaffShowSearchGroundTruth", {
    id,
  });

  const groundTruth = data?.ground_truth;

  if (isLoading) {
    return <FullPageLoader />;
  }
  if (!groundTruth) {
    return <EmptyState content="Ground truth not found" className="m-6" />;
  }

  return <SearchGroundTruthsShowPageContents groundTruth={groundTruth} />;
};

export const SearchGroundTruthsShowPageContents = ({
  groundTruth,
}: {
  groundTruth: SearchGroundTruth;
}) => {
  const showToast = useToast();
  const [isEditing, setIsEditing] = useState(false);

  const getQuestionTypeBadgeTheme = (type: string): BadgeTheme => {
    switch (type) {
      case "incident":
        return BadgeTheme.Primary;
      case "code-changes":
        return BadgeTheme.Info;
      default:
        return BadgeTheme.Tertiary;
    }
  };

  const { trigger: updateVerification } = useMutationV2(
    async (apiClient, data) => {
      await apiClient.aIStaffUpdateSearchGroundTruth({
        id: groundTruth.id,
        updateSearchGroundTruthRequestBody: {
          answer: groundTruth.answer,
          question: groundTruth.question,
          cutoff: groundTruth.cutoff,
          verified_at: data.isVerified ? new Date() : undefined,
        },
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowSearchGroundTruth", {
          id: groundTruth.id,
        }),
      ],
      showErrorToast: "Updating verification status",

      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Verification status updated",
        });
      },
    },
  );

  const handleVerificationChange = (isVerified: boolean) => {
    updateVerification({ isVerified });
  };

  const handleEditSuccess = () => {
    setIsEditing(false);
  };

  return (
    <WorkbenchSubPageWrapper
      title={groundTruth?.question || "Search Ground Truth"}
      backHref="/workbench/search-ground-truths"
      accessory={
        <div className="flex items-center gap-2">
          <VerifiedButton
            isVerified={!!groundTruth.verified_at}
            verifiedAt={
              groundTruth.verified_at
                ? new Date(groundTruth.verified_at)
                : undefined
            }
            setIsVerified={handleVerificationChange}
            isEditing={true}
          />
        </div>
      }
    >
      <EvaluationNotesWidget
        resourceId={groundTruth.id}
        resourceType={AIStaffListEvaluationNotesResourceTypeEnum.CopilotSearch}
      />
      {isEditing ? (
        <SearchGroundTruthsEditForm
          groundTruth={groundTruth}
          onCancel={() => setIsEditing(false)}
          onSuccess={handleEditSuccess}
        />
      ) : (
        <ContentBox className="p-4 flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <LabeledValue label="Question" value={groundTruth.question} />
            <LabeledValue
              label="Question type"
              value={
                <Badge
                  theme={getQuestionTypeBadgeTheme(groundTruth.question_type)}
                >
                  {groundTruth.question_type}
                </Badge>
              }
            />
            <LabeledValue
              label="Created at"
              value={<LocalDateTime timestamp={groundTruth.created_at} />}
            />
            <LabeledValue
              label="Cutoff"
              value={<LocalDateTime timestamp={groundTruth.cutoff} />}
            />
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold mb-4">Answers</h3>
            {groundTruth.answer && groundTruth.answer.length > 0 ? (
              <div className="flex flex-col gap-2">
                {groundTruth.answer.map((item, index) => (
                  <SearchCodeChangeItem key={index} item={item} />
                ))}
              </div>
            ) : (
              <EmptyState content="No answer items" />
            )}
          </div>
          <div className="flex justify-end gap-2 w-full">
            <Button
              icon={IconEnum.Edit}
              theme={ButtonTheme.Primary}
              onClick={() => setIsEditing(true)}
              analyticsTrackingId="edit-ground-truth"
            >
              Edit
            </Button>
          </div>
        </ContentBox>
      )}
    </WorkbenchSubPageWrapper>
  );
};
