import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useFormContext } from "react-hook-form";

import { PanelWrapper } from "./PanelWrapper";
import { DashboardContext } from "./types";
import { EditDashboardFormData } from "./useInsightsContext";
import { DashboardViewMode, useInsightsContext } from "./useInsightsContext";

export const TextPanel = ({
  panelIdx,
  dashboardContext,
}: {
  panelIdx: number;
  dashboardContext: DashboardContext;
}) => {
  const formMethods = useFormContext<EditDashboardFormData>();
  const panel = formMethods.watch(`panels.${panelIdx}`);

  const { viewMode } = useInsightsContext();

  if (viewMode === DashboardViewMode.EditDashboard) {
    return (
      <PanelWrapper
        title={""}
        description={""}
        panelIdx={panelIdx}
        variables={[]}
        trends={null}
        noTitleAndDescription
        dashboardContext={dashboardContext}
      >
        <TemplatedTextInputV2
          formMethods={formMethods}
          name={`panels.${panelIdx}.text.text`}
          autoFocus
          includeVariables={false}
          format="slack_rich_text_with_headings"
          style={TemplatedTextDisplayStyle.Naked}
          includeExpressions={false}
          placeholder="Add your text here"
          required
        />
      </PanelWrapper>
    );
  }

  return (
    <TemplatedTextDisplay
      value={panel.text?.text || ""}
      style={TemplatedTextDisplayStyle.Compact}
    />
  );
};
