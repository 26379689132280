import {
  AIStaffListEvaluationNotesResourceTypeEnum,
  Alert,
  AlertSchema,
  AlertSourceConfig,
  AlertSourceTypeEnum,
  AlertStatusEnum,
  CatalogResource,
  EscalationPreview,
  Priority,
} from "@incident-io/api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { PageLayoutWithSidebar } from "@incident-ui/PageLayoutWithSidebar/PageLayoutWithSidebar";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/core";
import { ErrorBoundary } from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAlertSource } from "src/components/alerts/useAlertSource";
import { SidebarDivider } from "src/components/legacy/incident/sidebar/IncidentSidebar";
import { EvaluationNotesWidget } from "src/components/workbench/evaluation-notes/EvaluationNotesWidget";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  AlertDebugDrawer,
  AlertEvaluationFailuresCallout,
} from "./AlertDebugDrawer";
import { AlertDetailsInsights } from "./AlertDetailsInsights";
import { AlertTimeline } from "./AlertTimeline";
import { AttributesSection } from "./AttributesSection";
import { DetailsSection, ThemesSection } from "./DetailsSection";
import { EscalationSection } from "./EscalationSection";
import { IncidentSection } from "./IncidentSection";
import { LinksSection } from "./LinksSection";

export const AlertDetailsPage = () => {
  const { id: alertId } = useParams() as {
    id: string;
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const showResolveModal = searchParams.get("resolving") === "true";

  useEffect(() => {
    return () => {
      if (showResolveModal) {
        searchParams.delete("resolving");
        setSearchParams(searchParams);
      }
    };
  }, [showResolveModal, searchParams, setSearchParams]);

  const closeResolveModal = () => {
    searchParams.delete("resolving");
    setSearchParams(searchParams);
  };

  const {
    data: alertResp,
    isLoading: alertIsLoading,
    error: alertError,
  } = useAPI("alertsShowAlert", {
    id: alertId,
  });

  const {
    data: schemaResponse,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: escalationsListResponse,
    isLoading: escalationsListLoading,
    error: escalationsListError,
  } = useAPI("escalationsList", {
    alert: {
      one_of: [alertId],
    },
  });

  const {
    data: resourcesListResp,
    isLoading: resourcesListIsLoading,
    error: resourcesListErr,
  } = useAPI("catalogListResources", undefined, {
    fallbackData: { resources: [] },
  });

  const {
    data: prioritiesResp,
    isLoading: prioritiesLoading,
    error: prioritiesError,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const err =
    alertError ||
    schemaError ||
    resourcesListErr ||
    prioritiesError ||
    escalationsListError;
  if (err) {
    captureException(err);
    return <GenericErrorMessage error={err} />;
  }

  if (
    alertIsLoading ||
    !alertResp ||
    schemaIsLoading ||
    !schemaResponse ||
    resourcesListIsLoading ||
    !escalationsListResponse ||
    escalationsListLoading ||
    !prioritiesResp ||
    prioritiesLoading ||
    !resourcesListResp
  ) {
    return <FullPageLoader />;
  }

  return (
    <AlertDetailsInner
      alert={alertResp.alert}
      alertPriorities={prioritiesResp.priorities}
      resources={resourcesListResp.resources}
      escalations={escalationsListResponse.escalations || []}
      alertSchema={schemaResponse.alert_schema}
      showResolveModal={showResolveModal}
      closeResolveModal={closeResolveModal}
    />
  );
};

const AlertDetailsInner = ({
  alert,
  alertSchema,
  alertPriorities,
  escalations,
  resources,
  showResolveModal,
  closeResolveModal,
}: {
  alert: Alert;
  alertSchema: AlertSchema;
  alertPriorities: Priority[];
  escalations: EscalationPreview[];
  resources: CatalogResource[];
  showResolveModal: boolean;
  closeResolveModal: () => void;
}) => {
  const [showDebugDrawer, setShowDebugDrawer] = useState(false);
  const [showDetailSidebar, setShowDetailSidebar] = useState(false);
  const showToast = useToast();

  const { data: configResp, error: configError } = useAPI(
    "alertsShowSourceConfig",
    {
      id: alert.alert_source_config_id,
    },
  );

  const { trigger: resolveAlert } = useAPIMutation(
    "alertsShowAlert",
    {
      id: alert.id,
    },
    async (apiClient, _) => {
      await apiClient.alertsResolve({
        id: alert.id,
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Alert resolved",
        });
        closeResolveModal();
      },
    },
  );

  if (configError) {
    captureException(configError);
    return <GenericErrorMessage error={configError} />;
  }

  if (!configResp) {
    return <FullPageLoader />;
  }

  const alertSourceConfig = configResp.alert_source_config;

  const title = alert.priority?.name
    ? `${alert.priority.name} alert from ${alertSourceConfig?.name}`
    : `Alert from ${alertSourceConfig?.name}`;
  return (
    <PageWrapper
      width={PageWidth.Full}
      icon={
        <div
          className={tcx(
            "h-10 w-10 flex flex-col items-center justify-center rounded-2 transition-transform",
            alert.status === AlertStatusEnum.Firing
              ? "bg-red-surface group-hover:bg-red-surface"
              : "bg-green-surface group-hover:bg-green-surface",
          )}
        >
          <AlertFiringIndicator
            className="group-hover:-translate-x-10"
            firing={alert.status === AlertStatusEnum.Firing}
          />
        </div>
      }
      color={
        alert.status === AlertStatusEnum.Firing
          ? ColorPaletteEnum.Red
          : ColorPaletteEnum.Green
      }
      title={title}
      overflowY={false}
      overflowX={false}
      noPadding
      className="h-full"
      backHref="/alerts/recent"
      crumbs={[
        // These point to the same but it gives a nice information architecture moment.
        { title: "Alerts", to: "/alerts" },
        { title: "All alerts", to: "/alerts/recent" },
      ]}
      accessory={
        <AlertHeaderAccessory
          alert={alert}
          alertSourceConfig={alertSourceConfig}
          showDetailSidebar={showDetailSidebar}
          setShowDetailSidebar={setShowDetailSidebar}
          setShowDebugDrawer={setShowDebugDrawer}
        />
      }
    >
      <EvaluationNotesWidget
        resourceId={alert.id}
        resourceType={AIStaffListEvaluationNotesResourceTypeEnum.Alert}
      />
      <PageLayoutWithSidebar
        drawerTitle={"Details"}
        isSidebarOpen={showDetailSidebar}
        setIsSidebarOpen={setShowDetailSidebar}
        sidebarContent={
          <div className="px-6 pt-6 overflow-y-auto">
            <AlertSidebar
              alert={alert}
              alertSchema={alertSchema}
              resources={resources}
              alertPriorities={alertPriorities}
            />
          </div>
        }
      >
        <AlertBody
          alert={alert}
          escalations={escalations}
          showDebugDrawer={() => setShowDebugDrawer(true)}
        />
      </PageLayoutWithSidebar>

      <AnimatePresence>
        {showDebugDrawer && (
          <AlertDebugDrawer
            onClose={() => setShowDebugDrawer(false)}
            alert={alert}
            schema={alertSchema}
            sourceConfig={alertSourceConfig}
          />
        )}
      </AnimatePresence>

      {alert.status === AlertStatusEnum.Firing && (
        <ConfirmationDialog
          isOpen={showResolveModal}
          onCancel={closeResolveModal}
          title={`Resolve ${alert.title}`}
          onConfirm={() => resolveAlert({})}
          confirmButtonText="Resolve"
        >
          Are you sure you want to resolve this alert?
        </ConfirmationDialog>
      )}
    </PageWrapper>
  );
};

const AlertHeaderAccessory = ({
  alert,
  alertSourceConfig,
  setShowDebugDrawer,
  showDetailSidebar,
  setShowDetailSidebar,
}: {
  alert: Alert;
  alertSourceConfig: AlertSourceConfig;
  setShowDebugDrawer: (value: boolean) => void;
  setShowDetailSidebar: (value: boolean) => void;
  showDetailSidebar: boolean;
}) => {
  const externalLink = alert.dashboard_url || alert.source_url;
  return (
    <div className={"flex items-center space-x-2"}>
      <DropdownMenu
        align="end"
        triggerButton={
          <Button
            title="More options"
            className="!py-1.5"
            analyticsTrackingId="incident-more-options"
            theme={ButtonTheme.Secondary}
            icon={IconEnum.DotsVertical}
            iconProps={{ size: IconSize.XL }}
          />
        }
      >
        <DropdownMenuItem
          label="Inspect"
          icon={IconEnum.View}
          onSelect={() => setShowDebugDrawer(true)}
          analyticsTrackingId="debug-alert"
        />
        {alert.silence_url ? (
          <DropdownMenuItem
            label="Silence"
            icon={IconEnum.ExternalLink}
            to={alert.silence_url}
            openInNewTab
            analyticsTrackingId="silence-alert"
          />
        ) : null}
      </DropdownMenu>
      {alert.status === AlertStatusEnum.Firing && (
        <ResolveAlertButton
          alert={alert}
          externallyResolved={
            alertSourceConfig.alert_source.externally_resolved || false
          }
        />
      )}
      {externalLink ? (
        <Button
          theme={ButtonTheme.Primary}
          analyticsTrackingId="alert-view-in-external-provider"
          href={externalLink}
        >
          View in{" "}
          {(alertSourceConfig.source_type as string) ===
          AlertSourceTypeEnum.Http
            ? alertSourceConfig.name
            : (alertSourceConfig.source_type as string) ===
              AlertSourceTypeEnum.PagerDuty
            ? "source"
            : alertSourceConfig.alert_source.name}
        </Button>
      ) : null}
      <Button
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="alert-sidebar-toggle"
        onClick={() => setShowDetailSidebar(true)}
        className={"xl:hidden"}
        icon={
          showDetailSidebar
            ? IconEnum.SidebarLeftShow
            : IconEnum.SidebarLeftHide
        }
        title={showDetailSidebar ? "Hide details" : "Show details"}
      />
    </div>
  );
};

const AlertBody = ({
  alert,
  escalations,
  showDebugDrawer,
}: {
  alert: Alert;
  escalations: EscalationPreview[];
  showDebugDrawer: () => void;
}) => {
  return (
    <div className="py-6 flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <div className="text-2xl-bold break-all">{alert.title}</div>
        {alert.description ? (
          <TemplatedTextDisplay
            className="font-normal text-sm flex flex-col gap-3 leading-6 grow"
            value={alert.description.text_node}
            style={TemplatedTextDisplayStyle.Compact}
          />
        ) : null}
        {alert.evaluation_failures &&
          Object.keys(alert.evaluation_failures).length > 0 && (
            <AlertEvaluationFailuresCallout
              showDebug={true}
              onClick={showDebugDrawer}
            />
          )}
      </div>
      <div className="flex flex-col gap-6">
        <IncidentSection alert={alert} />
        <EscalationSection escalations={escalations} />
      </div>

      <ErrorBoundary fallback={<></>}>
        <div className="w-full h-[1px] border-stroke-secondary border" />
        <AlertDetailsInsights alert={alert} />
      </ErrorBoundary>
      <div className="w-full h-[1px] border-stroke-secondary border" />
      <AlertTimeline alertId={alert.id} />
    </div>
  );
};

const AlertSidebar = ({
  alert,
  alertSchema,
  alertPriorities,
  resources,
  className,
}: {
  alert: Alert;
  alertSchema: AlertSchema;
  resources: CatalogResource[];
  alertPriorities: Priority[];
  className?: string;
}) => {
  const { featureAlertThemes } = useFlags();

  return (
    <div className={tcx(className, "space-y-5")}>
      {/* Details */}
      <DetailsSection alert={alert} priorities={alertPriorities} />
      {(alert.dashboard_url || alert.source_url) && (
        <>
          <SidebarDivider />
          <LinksSection alert={alert} />
        </>
      )}
      <SidebarDivider /> {/* Attributes */}
      <AttributesSection
        resources={resources}
        alert={alert}
        schema={alertSchema}
      />
      {
        // Themes
        featureAlertThemes && (
          <>
            <SidebarDivider />
            <ThemesSection alert={alert} />
          </>
        )
      }
    </div>
  );
};

const ResolveAlertButton = ({
  alert,
  externallyResolved,
}: {
  alert: Alert;
  externallyResolved: boolean;
}) => {
  const showToast = useToast();
  const { alertSource } = useAlertSource(alert?.source_type);
  const { trigger, isMutating } = useAPIMutation(
    "alertsShowAlert",
    {
      id: alert.id,
    },
    async (apiClient, _) => {
      await apiClient.alertsResolve({
        id: alert.id,
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Alert resolved",
        });
      },
    },
  );

  return (
    <GatedButton
      analyticsTrackingId={"resolve-alert"}
      onClick={() => trigger({})}
      loading={isMutating}
      theme={ButtonTheme.Secondary}
      disabled={externallyResolved}
      disabledTooltipContent={`This alert must be resolved in ${
        alertSource?.name ?? ""
      }.`}
    >
      Resolve
    </GatedButton>
  );
};
