import {
  Session,
  StaffPermission,
  StaffPermissionIdEnum,
} from "@incident-io/api";

import { useIdentity } from "../contexts/IdentityContext";

export const sessionCanImpersonate = (session: Session) => {
  if (!session.organisation_is_staff) {
    return false;
  }

  const permissions = session.staff_permissions || [];

  return permissionsCanImpersonate(permissions);
};

export const sessionCanSeeStaffRoom = (session: Session) => {
  if (!session.organisation_is_staff) {
    return false;
  }

  const permissions = session.staff_permissions || [];

  return permissions.length > 0;
};

export const permissionsCanImpersonate = (permissions: StaffPermission[]) => {
  const permissionIds = permissions.map(({ id }) => id);
  return (
    permissionIds.includes(StaffPermissionIdEnum.Impersonate) ||
    permissionIds.includes(StaffPermissionIdEnum.ImpersonateTrialAndDemo)
  );
};

export const useCanBeginImpersonating = () => {
  const { identity } = useIdentity();

  // Show the impersonate link if:
  const canImpersonate =
    // 1. You're looking at the staff org right now
    !!identity.organisation_is_staff &&
    // 2. It's not also a demo org (for paranoia only)
    !identity.organisation_is_demo &&
    // 3. You have the impersonate permission
    permissionsCanImpersonate(identity.staff_permissions || []);

  return canImpersonate;
};
