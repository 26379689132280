import { AIRequest, AISpan } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  CheckboxGroup,
  EmptyState,
  IconEnum,
  Input,
  TabPane,
  TabSection,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { isBefore } from "date-fns";
import yaml from "js-yaml";
import { trim } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useClient } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { useCounter } from "usehooks-ts";

import { CodeViewer } from "../common/CodeViewer";
import {
  addAIRequestToBatch,
  getAIRequestBatch,
  getEvalOverride,
  removeEvalOverride,
  removeRequestFromBatch,
  upsertEvalOverride,
} from "../common/evals";
import { ImproveEvalResultModal } from "../common/ImproveEvalResultModal";
import { LabeledValue } from "../common/LabeledValue";
import { MessageViewer } from "../common/MessageViewer";
import { ShowAIRequestBatchModal } from "../common/ShowAIRequestBatchModal";
import { snakeCasePreserveULID, TraceLink } from "../common/utils";
import { formatSpanResult } from "./AISpanTrace";
import { RerunAIRequestDrawer } from "./RerunAIRequestDrawer";

export const AISpanDrawer = ({
  span,
  onClose,
}: {
  span: AISpan;
  onClose: () => void;
}) => {
  const searchBarProps = useSearchContext();
  const { data: requestData, isLoading: requestLoading } = useAPI(
    "aIStaffShowAIRequest",
    {
      id: span.ai_request_id || "",
    },
    {
      revalidateIfStale: !!span.ai_request_id,
    },
  );

  const [currentTab, setCurrentTab] = useState("response");
  const [isEditingResponse, setIsEditingResponse] = useState(false);
  const [showRerunDrawer, setShowRerunDrawer] = useState(false);

  if (requestLoading) {
    return (
      <Drawer onClose={onClose} width="large">
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  const request = requestData?.request;
  const isEmptyResponse = request && trim(request.response) === "{}";

  return (
    <>
      {showRerunDrawer && request && (
        <RerunAIRequestDrawer
          request={request}
          onClose={() => setShowRerunDrawer(false)}
        />
      )}
      <Drawer onClose={onClose} width="large" isInBackground={showRerunDrawer}>
        <DrawerContents>
          <DrawerTitle
            title={span.name}
            onClose={onClose}
            secondaryAccessory={
              <div className="flex gap-2 items-center">
                {request && (
                  <Button
                    onClick={() => setShowRerunDrawer(true)}
                    analyticsTrackingId={null}
                    size={BadgeSize.Small}
                    icon={IconEnum.Refresh}
                  >
                    Re-run prompt
                  </Button>
                )}
                {span.type === "search" && span.resource_id && (
                  <Button
                    href={`/workbench/searches/${span.resource_id}`}
                    analyticsTrackingId={null}
                    size={BadgeSize.Small}
                    icon={IconEnum.Search}
                  >
                    View search
                  </Button>
                )}
                {span.trace_id && <TraceLink traceID={span.trace_id} />}
              </div>
            }
          />
          <DrawerBody className="overflow-y-auto">
            <TabSection
              withIndicator
              tabBarClassName="w-full border-b"
              value={currentTab}
              onTabChange={setCurrentTab}
              tabs={[
                {
                  id: "response",
                  label: "Response",
                },
                {
                  id: "eval",
                  label: "Eval",
                  hidden: !request,
                },
              ]}
            >
              <TabPane tabId="response">
                <div className="py-6 flex flex-col gap-6">
                  {!!span.input && (
                    <CodeViewer
                      mode="text"
                      title="Input"
                      content={formatSpanResult(span.input)}
                      highlightSearchTerm={searchBarProps.value}
                    />
                  )}
                  {/* If there's no input AND the span is over 14 days old, we've probably aged it out */}
                  {!span.input &&
                    isBefore(
                      span.created_at,
                      new Date(Date.now() - 14 * 24 * 60 * 60 * 1000),
                    ) && (
                      <EmptyState
                        title="No input to display"
                        content="This request has probably been aged out"
                        icon={IconEnum.Timer}
                      />
                    )}
                  {request && !isEmptyResponse ? (
                    <CodeViewer
                      mode="yaml"
                      title={
                        <div className="flex gap-2 items-center">
                          <span>Response</span>
                          <Button
                            size={BadgeSize.Small}
                            icon={IconEnum.Edit}
                            onClick={() => {
                              setIsEditingResponse(true);
                              setCurrentTab("eval");
                            }}
                            title=""
                            analyticsTrackingId={null}
                          />
                        </div>
                      }
                      content={request.response}
                      highlightSearchTerm={searchBarProps.value}
                    />
                  ) : (
                    <CodeViewer
                      mode="text"
                      title="Response"
                      content={formatSpanResult(span.result)}
                      highlightSearchTerm={searchBarProps.value}
                    />
                  )}
                  {request && (
                    <>
                      <div className="flex items-center gap-6 border-y border-stroke py-2">
                        <LabeledValue
                          inline
                          label="Model"
                          value={request.model}
                        />
                        {request.input_tokens && (
                          <LabeledValue
                            inline
                            label="Input tokens"
                            value={request.input_tokens}
                          />
                        )}
                        {request.output_tokens && (
                          <LabeledValue
                            inline
                            label="Output tokens"
                            value={request.output_tokens}
                          />
                        )}
                      </div>

                      <MessageViewer
                        messages={request.raw_messages || []}
                        highlightSearchTerm={searchBarProps.value}
                      />
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="eval">
                {request && (
                  <div className="py-6 flex flex-col gap-6">
                    <ExportEvalForRegression
                      request={request}
                      isEditing={isEditingResponse}
                      setIsEditing={setIsEditingResponse}
                    />
                    <hr />
                    <CodeViewer
                      mode="yaml"
                      title="Full eval case"
                      content={request.eval}
                      highlightSearchTerm={searchBarProps.value}
                    />
                  </div>
                )}
              </TabPane>
            </TabSection>
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    </>
  );
};

const ExportEvalForRegression = ({
  request,
  isEditing,
  setIsEditing,
}: {
  request: AIRequest;
  isEditing: boolean;
  setIsEditing: (v: boolean) => void;
}) => {
  const inputKeys = Object.keys(request.input || {}).map((key) => {
    const inputLen = String(request.input[key]).length;
    return {
      label: `${key} (${inputLen})`,
      value: key,
      len: inputLen,
    };
  });
  const defaultInputKeys = inputKeys
    .filter((key) => key.len > 1000)
    .map((key) => key.value);

  const [caseName, setCaseName] = useState(
    request.incident_external_id
      ? `INC-${request.incident_external_id} (${request.id})`
      : request.id,
  );
  const [fixtureKeys, setFixtureKeys] = useState<string[]>(defaultInputKeys);

  const caseNameForFilepath = snakeCasePreserveULID(caseName);

  // This will cause a re-render when the value changes
  const { count, increment: reRender } = useCounter();
  const {
    hasOverride,
    result,
    id: overrideID,
  } = useMemo(
    () => getEvalOverride(request),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, count],
  );

  const apiClient = useClient();

  const [isGeneratingEval, setIsGeneratingEval] = useState(false);
  const onGenerateEval = async () => {
    setIsGeneratingEval(true);
    const parsedResult = yaml.load(result);
    const override = await apiClient.aIStaffCreateAIRequestEvalOverride({
      createAIRequestEvalOverrideRequestBody: {
        ai_request_id: request.id,
        result: parsedResult as unknown as object,
      },
    });

    upsertEvalOverride({
      requestId: request.id,
      result,
      id: override.override.id,
    });

    setIsGeneratingEval(false);

    setTimeout(() => {
      reRender();
    }, 500);
  };
  const needToCreateOverride = hasOverride && !overrideID;

  const url = useMemo(() => {
    const url = new URL(
      `/api/ai_staff/ai_requests/${request.id}/eval`,
      window.location.origin,
    );

    if (overrideID) {
      url.searchParams.append("result_override_id", overrideID);
    }

    url.searchParams.append("fixture_path", `${caseNameForFilepath}.yaml`);
    url.searchParams.append("case_name", caseName);

    fixtureKeys.forEach((key) => {
      url.searchParams.append("fixture_keys", key);
    });

    return url;
  }, [caseName, caseNameForFilepath, fixtureKeys, request.id, overrideID]);

  const command = useMemo(
    () =>
      `go run cmd/copilot/main.go download --url '${url}' --token '${request.signed_jwt}'`,
    [url, request.signed_jwt],
  );

  const batch = getAIRequestBatch(request.prompt);
  const isAlreadyInBatch = batch.map((ea) => ea.requestId).includes(request.id);
  const onToggleIsInBatch = () => {
    if (isAlreadyInBatch) {
      removeRequestFromBatch(request.prompt, request.id);
    } else {
      addAIRequestToBatch(request.prompt, request.id, caseName);
    }
    reRender();
  };

  const [showBatchModal, setShowBatchModal] = useState(false);

  let copyDisabled = false;
  let copyTitle: React.ReactNode = (
    <div className="flex items-center gap-2">
      <span>Import eval locally</span>
      <Button
        theme={ButtonTheme.Primary}
        icon={IconEnum.AddIncidentType}
        onClick={onToggleIsInBatch}
        analyticsTrackingId={null}
        size={BadgeSize.Small}
      >
        {isAlreadyInBatch ? "Remove from batch" : "Or add to batch"}
      </Button>
      <Button
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Eye}
        onClick={() => setShowBatchModal(true)}
        analyticsTrackingId={null}
        size={BadgeSize.Small}
      >
        View batch ({batch.length})
      </Button>
    </div>
  );
  if (!caseName) {
    copyDisabled = true;
    copyTitle = (
      <div className="text-red-content text-sm">
        Input a case name to copy the command
      </div>
    );
  }
  if (needToCreateOverride) {
    copyDisabled = true;
    copyTitle = (
      <div className="text-red-content text-sm flex gap-2">
        You need to save the eval before copying the command
        <Button
          theme={ButtonTheme.Primary}
          icon={IconEnum.Download}
          onClick={onGenerateEval}
          analyticsTrackingId={null}
          size={BadgeSize.Small}
          loading={isGeneratingEval}
        >
          Save
        </Button>
      </div>
    );
  }

  const [showImproveResultModal, setShowImproveResultModal] = useState(false);
  const onUpdateResult = (res) => {
    upsertEvalOverride({
      requestId: request.id,
      result: res,
    });
    setTimeout(() => {
      reRender();
    }, 500);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-base-bold">Import eval into your local suite</div>
      <div className="text-sm text-content-tertiary">
        {`You can use our CLI to import this eval into your local suite. This is useful for running regression tests against productionised data, or seeding your eval suite with realistic examples. If you want to commit this into our repo, make sure that you anonymise the data (Claude is great for this).`}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm-bold">Case name</div>
        <Input
          id="case-name"
          value={caseName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCaseName(e.target.value as string)
          }
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm-bold">
          Which params should be extracted to a fixture?
        </div>
        <CheckboxGroup
          options={inputKeys}
          value={fixtureKeys}
          onChange={(vals) => setFixtureKeys(vals)}
        />
      </div>
      <CodeViewer
        mode="yaml"
        key={count}
        title={
          <div className="flex items-center gap-2">
            <span>Expected result</span>
            {hasOverride && !isEditing && (
              <div className="flex">
                <Badge
                  size={BadgeSize.Small}
                  theme={BadgeTheme.Info}
                  className="rounded-r-none border-r-0"
                >
                  Edited
                </Badge>
                <Button
                  size={BadgeSize.Small}
                  icon={IconEnum.Close}
                  theme={ButtonTheme.Secondary}
                  className="rounded-l-none"
                  analyticsTrackingId={null}
                  title="discard"
                  onClick={() => {
                    removeEvalOverride(request.id);
                    setTimeout(() => {
                      reRender();
                    }, 500);
                  }}
                />
              </div>
            )}
            <Button
              theme={ButtonTheme.Secondary}
              icon={IconEnum.MagicWand}
              onClick={() => setShowImproveResultModal(true)}
              analyticsTrackingId={null}
              size={BadgeSize.Small}
            >
              Improve result
            </Button>
          </div>
        }
        content={result}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onEdit={onUpdateResult}
      />
      <ExpectedResultWarning result={result} />

      <CodeViewer
        mode="text"
        title={copyTitle}
        content={command}
        disabled={copyDisabled}
      />
      {showBatchModal && (
        <ShowAIRequestBatchModal
          onClose={() => setShowBatchModal(false)}
          promptName={request.prompt}
          inputKeys={inputKeys}
          defaultInputKeys={fixtureKeys}
        />
      )}
      {showImproveResultModal && (
        <ImproveEvalResultModal
          onClose={() => setShowImproveResultModal(false)}
          requestId={request.id}
          actualResult={request.response}
          onSetResult={onUpdateResult}
        />
      )}
    </div>
  );
};

export const ExpectedResultWarning = ({ result }: { result: string }) => {
  // Parse our result as YAMl
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [parsedData, setParsedData] = useState<any>({});
  const [keys, setKeys] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try {
      const parsed = yaml.load(result);
      setParsedData(parsed);
      setError(null);
    } catch (err) {
      setError((err as Error).message);
      setParsedData(null);
    }
  }, [result, setParsedData, setError]);

  useEffect(() => {
    // Best-effort to get the keys
    try {
      setKeys(Object.keys(parsedData));
    } catch (err) {
      setKeys([]);
    }
  }, [parsedData, setKeys]);

  const expectedKeys = ["expected", "expected_tool_calls"];
  const hasRightKeys = keys.every((key) => expectedKeys.includes(key));

  return (
    <>
      {error ? (
        <Callout
          theme={CalloutTheme.Danger}
          title="Could not parse YAML"
          subtitle={error}
        />
      ) : (
        !hasRightKeys && (
          <Callout
            theme={CalloutTheme.Danger}
            title="Incorrect structure for YAML"
            subtitle="YAML should have top level keys 'expected' or 'expected_tool_calls'"
          />
        )
      )}
    </>
  );
};
