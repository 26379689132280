import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Toggle,
} from "@incident-ui";
import React, { useState } from "react";
import graphic from "src/components/settings/banners/banner-announcements.svg";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useIdentity } from "../../../contexts/IdentityContext";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { AnnouncementPostPreview } from "./common/AnnouncementPostPreview";
import { AnnouncementRuleList } from "./rules/AnnouncementRuleList";

export const AnnouncementSettingsPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const isTeamsCustomer = identity.ms_teams_info !== undefined;
  const channelType = isTeamsCustomer ? "Teams" : "Slack";
  const articleId = isTeamsCustomer ? "3129915986" : "4958923853";

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Keep everyone informed"
        subtitle={`Share incidents in a ${channelType} channel to help the team stay up-to-date.`}
        articleId={articleId}
        graphic={<img src={graphic} className="h-40" />}
      />
      <div className="flex flex-col gap-8">
        {!isTeamsCustomer && <AnnouncementPostSettings />}

        <AnnouncementRulesSettings />
      </div>
    </SettingsSubPageWrapper>
  );
};

const AnnouncementRulesSettings = () => {
  const [expandAll, setExpandAll] = useState(false);
  return (
    <div className="space-y-4">
      <SettingsSubHeading
        title={"Announcement rules"}
        titleHeadingLevel={2}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={`Use rules to announce relevant incidents in the right channel`}
        accessory={
          <div className="flex flex-row justify-end gap-4 -mt-4">
            <Toggle
              id="expand-all"
              onToggle={() => setExpandAll(!expandAll)}
              on={expandAll}
              label={expandAll ? "Collapse all" : "Expand all"}
            />
            <GatedButton
              href="/settings/announcements/create"
              analyticsTrackingId="create-announcement-rule"
              requiredScope={ScopeNameEnum.AnnouncementRulesCreate}
              icon={IconEnum.Add}
              theme={ButtonTheme.Secondary}
            >
              Create announcement rule
            </GatedButton>
          </div>
        }
      />
      <AnnouncementRuleList expandAll={expandAll} />
    </div>
  );
};

const AnnouncementPostSettings = () => {
  const {
    data: postData,
    isLoading: loadingPostData,
    error: errorPostData,
  } = useAPI("announcementPostsShow", undefined);

  const postFields = postData?.post.post_fields || [];
  const postActions = postData?.post.post_actions || [];

  if (loadingPostData) {
    return <Loader />;
  }
  if (errorPostData) {
    return <GenericErrorMessage error={errorPostData} />;
  }

  return (
    <div>
      <SettingsSubHeading
        title={"Announcement post"}
        titleHeadingLevel={2}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={`Configure what fields you want to display in your incident announcement posts.`}
        accessory={
          <div className="flex flex-row justify-end gap-4 -mt-4">
            <GatedButton
              href="/settings/announcements/post"
              analyticsTrackingId="update-announcement-post"
              requiredScope={ScopeNameEnum.AnnouncementRulesCreate}
              requiredProduct={Product.Response}
              icon={IconEnum.Edit}
              theme={ButtonTheme.Secondary}
            >
              Edit announcement post
            </GatedButton>
          </div>
        }
      />
      <AnnouncementPostPreview
        postFields={postFields}
        postActions={postActions}
      />
    </div>
  );
};
