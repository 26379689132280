/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReplica,
    ScheduleReplicaFromJSON,
    ScheduleReplicaFromJSONTyped,
    ScheduleReplicaToJSON,
} from './ScheduleReplica';

/**
 * 
 * @export
 * @interface SchedulesShowReplicaResponseBody
 */
export interface SchedulesShowReplicaResponseBody {
    /**
     * 
     * @type {ScheduleReplica}
     * @memberof SchedulesShowReplicaResponseBody
     */
    schedule_replica: ScheduleReplica;
}

export function SchedulesShowReplicaResponseBodyFromJSON(json: any): SchedulesShowReplicaResponseBody {
    return SchedulesShowReplicaResponseBodyFromJSONTyped(json, false);
}

export function SchedulesShowReplicaResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesShowReplicaResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_replica': ScheduleReplicaFromJSON(json['schedule_replica']),
    };
}

export function SchedulesShowReplicaResponseBodyToJSON(value?: SchedulesShowReplicaResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_replica': ScheduleReplicaToJSON(value.schedule_replica),
    };
}

