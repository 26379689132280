/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EscalationPathsBuildScopeRequestBody,
    EscalationPathsBuildScopeRequestBodyFromJSON,
    EscalationPathsBuildScopeRequestBodyToJSON,
    EscalationPathsBuildScopeResponseBody,
    EscalationPathsBuildScopeResponseBodyFromJSON,
    EscalationPathsBuildScopeResponseBodyToJSON,
    EscalationPathsCreateExternalRequestBody,
    EscalationPathsCreateExternalRequestBodyFromJSON,
    EscalationPathsCreateExternalRequestBodyToJSON,
    EscalationPathsCreateExternalResponseBody,
    EscalationPathsCreateExternalResponseBodyFromJSON,
    EscalationPathsCreateExternalResponseBodyToJSON,
    EscalationPathsCreateRequestBody,
    EscalationPathsCreateRequestBodyFromJSON,
    EscalationPathsCreateRequestBodyToJSON,
    EscalationPathsCreateResponseBody,
    EscalationPathsCreateResponseBodyFromJSON,
    EscalationPathsCreateResponseBodyToJSON,
    EscalationPathsGenerateExportRequestBody,
    EscalationPathsGenerateExportRequestBodyFromJSON,
    EscalationPathsGenerateExportRequestBodyToJSON,
    EscalationPathsGenerateExportResponseBody,
    EscalationPathsGenerateExportResponseBodyFromJSON,
    EscalationPathsGenerateExportResponseBodyToJSON,
    EscalationPathsListExternalResponseBody,
    EscalationPathsListExternalResponseBodyFromJSON,
    EscalationPathsListExternalResponseBodyToJSON,
    EscalationPathsListForUserResponseBody,
    EscalationPathsListForUserResponseBodyFromJSON,
    EscalationPathsListForUserResponseBodyToJSON,
    EscalationPathsListForUserV2ResponseBody,
    EscalationPathsListForUserV2ResponseBodyFromJSON,
    EscalationPathsListForUserV2ResponseBodyToJSON,
    EscalationPathsListForUserV3ResponseBody,
    EscalationPathsListForUserV3ResponseBodyFromJSON,
    EscalationPathsListForUserV3ResponseBodyToJSON,
    EscalationPathsListResponseBody,
    EscalationPathsListResponseBodyFromJSON,
    EscalationPathsListResponseBodyToJSON,
    EscalationPathsShowResponseBody,
    EscalationPathsShowResponseBodyFromJSON,
    EscalationPathsShowResponseBodyToJSON,
    EscalationPathsUpdateRequestBody,
    EscalationPathsUpdateRequestBodyFromJSON,
    EscalationPathsUpdateRequestBodyToJSON,
    EscalationPathsUpdateResponseBody,
    EscalationPathsUpdateResponseBodyFromJSON,
    EscalationPathsUpdateResponseBodyToJSON,
} from '../models';

export interface EscalationPathsBuildScopeRequest {
    buildScopeRequestBody: EscalationPathsBuildScopeRequestBody;
}

export interface EscalationPathsCreateRequest {
    createRequestBody: EscalationPathsCreateRequestBody;
}

export interface EscalationPathsCreateExternalRequest {
    createExternalRequestBody: EscalationPathsCreateExternalRequestBody;
}

export interface EscalationPathsDestroyRequest {
    id: string;
}

export interface EscalationPathsGenerateExportRequest {
    generateExportRequestBody: EscalationPathsGenerateExportRequestBody;
}

export interface EscalationPathsListRequest {
    pageSize?: number;
    after?: string;
    sortBy?: EscalationPathsListSortByEnum;
    teamIds?: Array<string>;
}

export interface EscalationPathsListExternalRequest {
    ids?: Array<string>;
    pageSize?: number;
    after?: string;
    search?: string;
}

export interface EscalationPathsListForUserRequest {
    user: string;
}

export interface EscalationPathsListForUserV2Request {
    userId: string;
}

export interface EscalationPathsListForUserV3Request {
    userId: string;
}

export interface EscalationPathsShowRequest {
    id: string;
}

export interface EscalationPathsUpdateRequest {
    updateRequestBody: EscalationPathsUpdateRequestBody;
}

/**
 * 
 */
export class EscalationPathsApi extends runtime.BaseAPI {

    /**
     * Build an escalation scope
     * BuildScope EscalationPaths
     */
    async escalationPathsBuildScopeRaw(requestParameters: EscalationPathsBuildScopeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsBuildScopeResponseBody>> {
        if (requestParameters.buildScopeRequestBody === null || requestParameters.buildScopeRequestBody === undefined) {
            throw new runtime.RequiredError('buildScopeRequestBody','Required parameter requestParameters.buildScopeRequestBody was null or undefined when calling escalationPathsBuildScope.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_paths/build_scope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationPathsBuildScopeRequestBodyToJSON(requestParameters.buildScopeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build an escalation scope
     * BuildScope EscalationPaths
     */
    async escalationPathsBuildScope(requestParameters: EscalationPathsBuildScopeRequest, initOverrides?: RequestInit): Promise<EscalationPathsBuildScopeResponseBody> {
        const response = await this.escalationPathsBuildScopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new escalation path
     * Create EscalationPaths
     */
    async escalationPathsCreateRaw(requestParameters: EscalationPathsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling escalationPathsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_paths`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationPathsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new escalation path
     * Create EscalationPaths
     */
    async escalationPathsCreate(requestParameters: EscalationPathsCreateRequest, initOverrides?: RequestInit): Promise<EscalationPathsCreateResponseBody> {
        const response = await this.escalationPathsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create native escalation paths from the provided external ones
     * CreateExternal EscalationPaths
     */
    async escalationPathsCreateExternalRaw(requestParameters: EscalationPathsCreateExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsCreateExternalResponseBody>> {
        if (requestParameters.createExternalRequestBody === null || requestParameters.createExternalRequestBody === undefined) {
            throw new runtime.RequiredError('createExternalRequestBody','Required parameter requestParameters.createExternalRequestBody was null or undefined when calling escalationPathsCreateExternal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external_escalation_paths`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationPathsCreateExternalRequestBodyToJSON(requestParameters.createExternalRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsCreateExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create native escalation paths from the provided external ones
     * CreateExternal EscalationPaths
     */
    async escalationPathsCreateExternal(requestParameters: EscalationPathsCreateExternalRequest, initOverrides?: RequestInit): Promise<EscalationPathsCreateExternalResponseBody> {
        const response = await this.escalationPathsCreateExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a particular escalation path
     * Destroy EscalationPaths
     */
    async escalationPathsDestroyRaw(requestParameters: EscalationPathsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling escalationPathsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a particular escalation path
     * Destroy EscalationPaths
     */
    async escalationPathsDestroy(requestParameters: EscalationPathsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.escalationPathsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * For a given escalation path, export the configuration in a recognisable format
     * GenerateExport EscalationPaths
     */
    async escalationPathsGenerateExportRaw(requestParameters: EscalationPathsGenerateExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsGenerateExportResponseBody>> {
        if (requestParameters.generateExportRequestBody === null || requestParameters.generateExportRequestBody === undefined) {
            throw new runtime.RequiredError('generateExportRequestBody','Required parameter requestParameters.generateExportRequestBody was null or undefined when calling escalationPathsGenerateExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_path_exports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationPathsGenerateExportRequestBodyToJSON(requestParameters.generateExportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsGenerateExportResponseBodyFromJSON(jsonValue));
    }

    /**
     * For a given escalation path, export the configuration in a recognisable format
     * GenerateExport EscalationPaths
     */
    async escalationPathsGenerateExport(requestParameters: EscalationPathsGenerateExportRequest, initOverrides?: RequestInit): Promise<EscalationPathsGenerateExportResponseBody> {
        const response = await this.escalationPathsGenerateExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List active escalation paths
     * List EscalationPaths
     */
    async escalationPathsListRaw(requestParameters: EscalationPathsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.teamIds) {
            queryParameters['team_ids'] = requestParameters.teamIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List active escalation paths
     * List EscalationPaths
     */
    async escalationPathsList(requestParameters: EscalationPathsListRequest, initOverrides?: RequestInit): Promise<EscalationPathsListResponseBody> {
        const response = await this.escalationPathsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List external escalation paths using the default provider
     * ListExternal EscalationPaths
     */
    async escalationPathsListExternalRaw(requestParameters: EscalationPathsListExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsListExternalResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external_escalation_paths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsListExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * List external escalation paths using the default provider
     * ListExternal EscalationPaths
     */
    async escalationPathsListExternal(requestParameters: EscalationPathsListExternalRequest, initOverrides?: RequestInit): Promise<EscalationPathsListExternalResponseBody> {
        const response = await this.escalationPathsListExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalation paths that are relevant to this user
     * ListForUser EscalationPaths
     */
    async escalationPathsListForUserRaw(requestParameters: EscalationPathsListForUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsListForUserResponseBody>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling escalationPathsListForUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths_for_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsListForUserResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalation paths that are relevant to this user
     * ListForUser EscalationPaths
     */
    async escalationPathsListForUser(requestParameters: EscalationPathsListForUserRequest, initOverrides?: RequestInit): Promise<EscalationPathsListForUserResponseBody> {
        const response = await this.escalationPathsListForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalation paths that are relevant to this user along with the current shifts for each one.
     * ListForUserV2 EscalationPaths
     */
    async escalationPathsListForUserV2Raw(requestParameters: EscalationPathsListForUserV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsListForUserV2ResponseBody>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling escalationPathsListForUserV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths_for_user_v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsListForUserV2ResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalation paths that are relevant to this user along with the current shifts for each one.
     * ListForUserV2 EscalationPaths
     */
    async escalationPathsListForUserV2(requestParameters: EscalationPathsListForUserV2Request, initOverrides?: RequestInit): Promise<EscalationPathsListForUserV2ResponseBody> {
        const response = await this.escalationPathsListForUserV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalation paths and their status for a given user
     * ListForUserV3 EscalationPaths
     */
    async escalationPathsListForUserV3Raw(requestParameters: EscalationPathsListForUserV3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsListForUserV3ResponseBody>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling escalationPathsListForUserV3.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths_for_user_v3`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsListForUserV3ResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalation paths and their status for a given user
     * ListForUserV3 EscalationPaths
     */
    async escalationPathsListForUserV3(requestParameters: EscalationPathsListForUserV3Request, initOverrides?: RequestInit): Promise<EscalationPathsListForUserV3ResponseBody> {
        const response = await this.escalationPathsListForUserV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a particular escalation path
     * Show EscalationPaths
     */
    async escalationPathsShowRaw(requestParameters: EscalationPathsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling escalationPathsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_paths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a particular escalation path
     * Show EscalationPaths
     */
    async escalationPathsShow(requestParameters: EscalationPathsShowRequest, initOverrides?: RequestInit): Promise<EscalationPathsShowResponseBody> {
        const response = await this.escalationPathsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a particular escalation path
     * Update EscalationPaths
     */
    async escalationPathsUpdateRaw(requestParameters: EscalationPathsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationPathsUpdateResponseBody>> {
        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling escalationPathsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_paths`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationPathsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationPathsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a particular escalation path
     * Update EscalationPaths
     */
    async escalationPathsUpdate(requestParameters: EscalationPathsUpdateRequest, initOverrides?: RequestInit): Promise<EscalationPathsUpdateResponseBody> {
        const response = await this.escalationPathsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum EscalationPathsListSortByEnum {
    IdDesc = 'id_desc',
    NameAsc = 'name_asc'
}
