import {
  IncidentGroundTruthRelatedIncident,
  IncidentGroundTruthRelatedIncidentPayload,
} from "@incident-io/api";
import { TypeaheadTypeEnum } from "@incident-shared/forms/Typeahead";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  Textarea,
} from "@incident-ui";
import { CopyButton } from "@incident-ui/CopyButton/CopyButton";
import { DynamicSingleSelect } from "@incident-ui/Select/DynamicSingleSelect";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { useState } from "react";
import { useParams } from "react-router";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "src/components/status-pages/incidents/view/IncidentLinkCreateModal";
import { useClient } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { VerifiedButton } from "./VerifiedButton";

// Modified to make next_steps optional since we're handling it at the section level
type EditableRelatedIncident = Omit<
  IncidentGroundTruthRelatedIncidentPayload,
  "next_steps"
> & {
  id: string;
  next_steps?: string;
};

export const GroundTruthRelatedIncidentsSection = ({
  relatedIncidents = [],
  nextSteps = "",
  verifiedAt,
  onSave,
  className,
}: {
  relatedIncidents?: IncidentGroundTruthRelatedIncident[];
  nextSteps?: string;
  verifiedAt?: Date;
  onSave?: (
    relatedIncidents: IncidentGroundTruthRelatedIncidentPayload[],
    nextSteps: string,
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableRelatedIncidents, setEditableRelatedIncidents] = useState<
    EditableRelatedIncident[]
  >([]);
  const [editableNextSteps, setEditableNextSteps] = useState(nextSteps || "");
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableRelatedIncidents(
      relatedIncidents.map((incident, idx) => ({
        incident_id: incident.incident.id,
        reason: incident.reason,
        relevance: incident.relevance,
        id: `incident-${idx}`,
      })),
    );
    setEditableNextSteps(nextSteps || "");
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditableRelatedIncidents([]);
    setEditableNextSteps(nextSteps || "");
    setIsVerified(!!verifiedAt);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      // Strip out the temporary ids and ensure we only send the fields we need
      const cleanRelatedIncidents = editableRelatedIncidents.map(
        ({ id: _, ...incident }) => ({
          incident_id: incident.incident_id,
          reason: incident.reason,
          relevance: incident.relevance,
        }),
      );
      await onSave(cleanRelatedIncidents, editableNextSteps, isVerified);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const addNewRelatedIncident = () => {
    setEditableRelatedIncidents([
      ...editableRelatedIncidents,
      {
        id: `new-${Date.now()}`,
        incident_id: "",
        reason: "",
        relevance: "",
        next_steps: undefined,
      },
    ]);
  };

  const updateRelatedIncident = (
    id: string,
    field: "incident_id" | "relevance" | "reason",
    value: string,
  ) => {
    setEditableRelatedIncidents((incidents) =>
      incidents.map((incident) =>
        incident.id === id ? { ...incident, [field]: value } : incident,
      ),
    );
  };

  const removeRelatedIncident = (id: string) => {
    setEditableRelatedIncidents((incidents) =>
      incidents.filter((incident) => incident.id !== id),
    );
  };

  const isEmpty = !relatedIncidents?.length;

  return (
    <div
      className={tcx(
        "border-t border-stroke first:border-t-0",
        isEditing ? "bg-surface-secondary" : "",
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Related incidents</h4>
            <VerifiedButton
              isVerified={isVerified}
              verifiedAt={verifiedAt}
              setIsVerified={setIsVerified}
              isEditing={isEditing}
            />
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            {editableRelatedIncidents.map((incident) => (
              <EditableRelatedIncidentItem
                key={incident.id}
                relatedIncident={incident}
                onUpdate={(field, value) =>
                  updateRelatedIncident(incident.id, field, value)
                }
                onRemove={() => removeRelatedIncident(incident.id)}
              />
            ))}

            <div className="flex gap-2 mt-4">
              <Button
                onClick={addNewRelatedIncident}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
              >
                Add related incident
              </Button>
            </div>

            <div className="mt-4 pt-4 border-t border-stroke">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Next steps
                </label>
                <Textarea
                  id="next_steps"
                  value={editableNextSteps}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setEditableNextSteps(e.target.value)
                  }
                  placeholder="Describe the next steps you'd expect our agent to find from these related incidents"
                  className="w-full p-2 text-sm border rounded bg-white resize-none"
                  rows={3}
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  onClick={cancelEditing}
                  theme={ButtonTheme.Secondary}
                  size={BadgeSize.Small}
                  analyticsTrackingId={null}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  theme={ButtonTheme.Primary}
                  size={BadgeSize.Small}
                  analyticsTrackingId={null}
                  loading={isSaving}
                >
                  Save changes
                </Button>
              </div>
            </div>
          </div>
        ) : isEmpty ? (
          <p className="text-sm text-content-secondary">
            No related incidents have been identified yet.
          </p>
        ) : (
          <div className="space-y-4">
            {relatedIncidents.map((incident, index) => (
              <RelatedIncidentItem key={index} relatedIncident={incident} />
            ))}
            {nextSteps && (
              <div className="mt-4 pl-6 pt-4 border-t border-stroke">
                <h5 className="text-sm font-medium mb-1">
                  Next steps for related incidents
                </h5>
                <p className="text-sm text-content-secondary">{nextSteps}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const RelatedIncidentItem = ({
  relatedIncident,
}: {
  relatedIncident: IncidentGroundTruthRelatedIncident;
}) => {
  const permalink = `/incidents/${relatedIncident.incident.id}`;
  const currentURL = window.location.host;
  const { slug } = useParams();
  const urlToCopy = "https://" + currentURL + "/" + slug + permalink;
  return (
    <div className="flex items-start gap-2">
      <Icon
        id={IconEnum.Incident}
        className="mt-1 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <Button
            theme={ButtonTheme.Link}
            analyticsTrackingId={null}
            href={permalink}
          >
            INC-{relatedIncident.incident.external_id}
          </Button>
          <Badge size={BadgeSize.Small} theme={BadgeTheme.Info}>
            {relatedIncident.relevance}
          </Badge>
          <CopyButton value={urlToCopy} />
        </div>
        <p className="text-sm text-content-secondary mt-1">
          <span className="font-medium">Reason:</span> {relatedIncident.reason}
        </p>
      </div>
    </div>
  );
};

const EditableRelatedIncidentItem = ({
  relatedIncident,
  onUpdate,
  onRemove,
}: {
  relatedIncident: EditableRelatedIncident;
  onUpdate: (
    field: "incident_id" | "relevance" | "reason",
    value: string,
  ) => void;
  onRemove: () => void;
}) => {
  const apiClient = useClient();

  return (
    <div className="flex items-start gap-2 p-3 rounded border border-stroke bg-white">
      <Icon
        id={IconEnum.Incident}
        className="mt-2 text-content-secondary shrink-0"
      />
      <div className="flex-1 flex flex-col gap-2">
        <DynamicSingleSelect
          value={relatedIncident.incident_id}
          onChange={(value) => onUpdate("incident_id", value as string)}
          closeMenuOnSelect={true}
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.Incident,
          )}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.Incident,
          )}
          placeholder={"Start typing or select from the dropdown"}
        />

        <StaticSingleSelect
          id="relevance"
          value={relatedIncident.relevance}
          onChange={(value) => onUpdate("relevance", value || "")}
          placeholder="Relevance"
          options={[
            { value: "unlikely", label: "Unlikely" },
            { value: "possible", label: "Possible" },
            { value: "likely", label: "Likely" },
            { value: "certain", label: "Certain" },
          ]}
        />

        <Textarea
          id="reason"
          value={relatedIncident.reason}
          // @ts-expect-error this is ok
          onChange={(e) => onUpdate("reason", e.target.value)}
          placeholder="Describe how this incident is related"
          className="w-full p-2 text-sm border rounded bg-white resize-none"
          rows={2}
        />
      </div>
      <Button
        onClick={onRemove}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={IconEnum.Close}
        analyticsTrackingId={null}
        className="text-content-tertiary hover:text-content-primary"
      >
        Remove
      </Button>
    </div>
  );
};
