import { useAPI } from "src/utils/swr";

export const useRestrictedCountries = () => {
  const { data } = useAPI(
    "onCallNotificationsListRestrictedCountries",
    {},
    {
      refreshInterval: 30000,
    },
  );

  if (!data) {
    return undefined;
  }

  return data.countries;
};
