import { EngineScope, Trigger } from "@incident-io/api";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { Icon, IconEnum, IconSize, LoadingBar, Tooltip } from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import { useFormContext } from "react-hook-form";

import { WorkflowFormData } from "../../common/types";
import { noConditionsOperationText } from "../../list/WorkflowsListItemAccordion";

export const WorkflowsCreateEditConditions = ({
  loading,
  scopeWithExpressions,
  trigger,
}: {
  loading: boolean;
  scopeWithExpressions: EngineScope;
  trigger?: Trigger;
}) => {
  const formMethods = useFormContext<WorkflowFormData>();

  const conditionGroups = formMethods.watch("condition_groups");

  const triggerName = trigger?.name;

  return (
    <FlowCards.Card
      icon={IconEnum.Filter}
      className="flex flex-col gap-3 items-start"
      title={
        conditionGroups.length === 0 ? (
          <p>
            Applies to{" "}
            <span className="font-medium">
              {triggerName
                ? noConditionsOperationText(triggerName)
                : "all incidents"}
            </span>
          </p>
        ) : (
          <p>Then checks the following conditions...</p>
        )
      }
      accessory={
        <Tooltip
          side={"bottom"}
          content={
            <>
              If you want this to run on private, test or retrospective
              incidents, you&rsquo;ll have to enable this in &quot;Advanced
              settings&quot;
            </>
          }
        >
          <div>
            <Icon
              id={IconEnum.Info}
              size={IconSize.Medium}
              className="text-content-secondary"
            />
          </div>
        </Tooltip>
      }
    >
      {loading ? (
        <LoadingBar />
      ) : (
        <ConditionGroupsEditorV2
          formMethods={formMethods}
          name="condition_groups"
          scope={scopeWithExpressions}
          className="w-full"
          allowFilteringByExpression
          hideIntroSentence
        />
      )}
    </FlowCards.Card>
  );
};
