import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
} from "@incident-ui";
import { StaffShowOrganisationResponseBody } from "src/contexts/ClientContext";

export const PRICING_DASHBOARD_ID = "MTM1RMrs";

export const LinksDropdown = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <DropdownMenu
      triggerButton={
        <Button
          theme={ButtonTheme.Secondary}
          type="button"
          analyticsTrackingId={null}
        >
          <div className="flex">
            <div>Open</div>
            <Icon id={IconEnum.DotsHorizontal} className="ml-1" />
          </div>
        </Button>
      }
      align={"end"}
    >
      <DropdownSubgroup title="Dashboards" />
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Omni: Customer health"
        to={omniLink("2ccb80cc", data)}
        openInNewTab
      />
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Omni: On-call pricing"
        to={omniLink(PRICING_DASHBOARD_ID, data)}
        openInNewTab
      />
      <DropdownSubgroup title="External systems" />
      {data.stripe_customer_id && (
        <DropdownMenuItem
          analyticsTrackingId={null}
          label="Stripe"
          to={`https://dashboard.stripe.com/customers/${data.stripe_customer_id}`}
          openInNewTab
        />
      )}
      {data.sequence_customer_id && (
        <DropdownMenuItem
          analyticsTrackingId={null}
          label="Sequence"
          to={`https://dashboard.sequencehq.com/customers/${data.sequence_customer_id}`}
          openInNewTab
        />
      )}
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Salesforce"
        to={salesforceLink(data.organisation_name)}
        openInNewTab
      />
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Intercom"
        to={intercomLink(data.organisation_name)}
        openInNewTab
      />
      <DropdownSubgroup title="Engineering" />
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Logs"
        to={`https://pineapples.grafana.net/d/fb01107f-ba4a-4144-a8d2-e33a7433f0ae?var-organisation=${data.organisation_id}`}
        openInNewTab
      />
      <DropdownMenuItem
        analyticsTrackingId={null}
        label="Sentry"
        to={`https://incident-io.sentry.io/issues/?query=is%3Aunresolved%20organisation_id%3A${data.organisation_id}`}
        openInNewTab
      />
    </DropdownMenu>
  );
};

const DropdownSubgroup = ({ title }: { title: string }) => {
  return (
    <div className="px-3 py-2 text-content-tertiary text-xs uppercase">
      {title}
    </div>
  );
};

export const omniLink = (
  dashboardId: string,
  org: StaffShowOrganisationResponseBody,
): string => {
  const queryOrg = {
    kind: "EQUALS",
    type: "string",
    values: [`${org.organisation_name} (${org.organisation_id})`],
    is_negative: false,
  };

  const queryPaying = {
    type: "boolean",
    treat_nulls_as_false: false,
    is_negative: null,
  };

  const encodedQueryOrg = encodeURIComponent(JSON.stringify(queryOrg));
  const encodedQueryPaying = encodeURIComponent(JSON.stringify(queryPaying));

  return `https://incident.omniapp.co/dashboards/${dashboardId}?f--organisations.organisation_name_with_id=${encodedQueryOrg}&f--organisations.is_paying=${encodedQueryPaying}`;
};

const salesforceLink = (orgName: string): string => {
  const query = {
    componentDef: "forceSearch:searchPageDesktop",
    attributes: {
      term: stripNonAlphaNumeric(orgName),
      scopeMap: {
        type: "TOP_RESULTS",
      },
      context: {
        FILTERS: {},
        searchSource: "ASSISTANT_DIALOG",
        disableIntentQuery: false,
        disableSpellCorrection: false,
        searchDialogSessionId: "d860b4ce-e444-49e3-b104-a78983d6c577",
        debugInfo: {
          appName: "LightningSales",
          appType: "Standard",
          appNamespace: "standard",
          location: "forceSearch:searchPageDesktop",
        },
      },
      groupId: "DEFAULT",
    },
    state: {},
  };

  return `https://incident-io.lightning.force.com/one/one.app#${btoa(
    JSON.stringify(query),
  )}`;
};

const intercomLink = (organisationId: string): string => {
  const searchData = {
    predicates: [
      {
        comparison: "eq",
        value: organisationId,
        attribute: "company.remote_company_id",
        type: "string",
      },
    ],
  };

  const encodedSearch = btoa(JSON.stringify(searchData));

  return `"https://app.intercom.com/a/apps/e2bwytl0/companies/segments/all:${encodedSearch}`;
};

const stripNonAlphaNumeric = (str: string) => str.replace(/\W/g, "");
