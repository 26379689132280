/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffRerunAIRequestRequestBody
 */
export interface AIStaffRerunAIRequestRequestBody {
    /**
     * The expected result of the rerun (for use with eval checks)
     * @type {string}
     * @memberof AIStaffRerunAIRequestRequestBody
     */
    expected: string;
    /**
     * List of models to use for the rerun
     * @type {Array<string>}
     * @memberof AIStaffRerunAIRequestRequestBody
     */
    models: Array<string>;
    /**
     * Number of times to repeat each model
     * @type {number}
     * @memberof AIStaffRerunAIRequestRequestBody
     */
    repeat?: number;
}

export function AIStaffRerunAIRequestRequestBodyFromJSON(json: any): AIStaffRerunAIRequestRequestBody {
    return AIStaffRerunAIRequestRequestBodyFromJSONTyped(json, false);
}

export function AIStaffRerunAIRequestRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffRerunAIRequestRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expected': json['expected'],
        'models': json['models'],
        'repeat': !exists(json, 'repeat') ? undefined : json['repeat'],
    };
}

export function AIStaffRerunAIRequestRequestBodyToJSON(value?: AIStaffRerunAIRequestRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expected': value.expected,
        'models': value.models,
        'repeat': value.repeat,
    };
}

