import { EvaluationEntryPayload, EvaluationScorecard } from "@incident-io/api";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  LocalRelativeDateTime,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { EvaluationFilterContextProvider } from "../common/EvaluationFilterContext";
import { EvaluationScorecardGrades } from "./EvaluationScorecardGrades";
import { EvaluationScorecardTrace } from "./EvaluationScorecardTrace";

export const EvaluationScorecardSectionController = ({
  investigationId,
  copilotThreadId,
  copilotSearchId,
  incidentId,
  showGradeNames,
  onGenerateScorecard,
  className,
}: {
  investigationId?: string;
  copilotThreadId?: string;
  copilotSearchId?: string;
  incidentId?: string;
  showGradeNames?: boolean;
  onGenerateScorecard?: () => void;
  className?: string;
}) => {
  const toast = useToast();
  const [isGenerating, setIsGenerating] = useState(false);

  const { data, isLoading } = useAPI(
    "aIStaffListEvaluationScorecards",
    {
      investigationId: investigationId,
      copilotThreadId: copilotThreadId,
      copilotSearchId: copilotSearchId,
    },
    {
      fallbackData: { scorecards: [] },
      refreshInterval: (d) => {
        if ((d?.scorecards.length ?? 0) > 0) {
          return 0;
        }

        if (isGenerating) {
          return 1000;
        }

        return 0;
      },
      onSuccess: () => {
        if (isGenerating) {
          setIsGenerating(false);
        }
      },
    },
  );
  const scorecard = data?.scorecards[0];

  const { trigger: generateScorecard } = useMutationV2(
    async (apiClient, data: EvaluationEntryPayload) => {
      await apiClient.aIStaffCreateEvaluationScorecard({
        createEvaluationScorecardRequestBody: data,
      });

      // Also invalidate ground truths if we have an incident ID
      if (incidentId) {
        // We directly use the useAPIRefetch pattern in the code, but this happens
        // in the background through our useAPIMutation implementation
      }

      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffListEvaluationScorecards", {
          investigationId: investigationId,
          copilotThreadId: copilotThreadId,
          copilotSearchId: copilotSearchId,
        }),
      ],

      onSuccess: () => {
        setIsGenerating(true);
        if (onGenerateScorecard) {
          onGenerateScorecard();
        }
      },

      onError: () => {
        toast({
          title: "Failed to generate scorecard",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const handle = () => {
    generateScorecard({
      investigation_id: investigationId,
      copilot_thread_id: copilotThreadId,
      copilot_search_id: copilotSearchId,
    });
  };

  return (
    <EvaluationScorecardSection
      scorecard={scorecard}
      onGenerateScorecard={handle}
      isGenerating={isGenerating || isLoading}
      className={className}
      showGradeNames={showGradeNames}
    />
  );
};

export type EvaluationScorecardSectionProps = {
  scorecard?: EvaluationScorecard;
  onGenerateScorecard: () => void;
  isGenerating?: boolean;
  showGradeNames?: boolean;
  className?: string;
};

export const EvaluationScorecardSection = ({
  scorecard,
  onGenerateScorecard,
  isGenerating,
  showGradeNames = true,
  className,
}: EvaluationScorecardSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!scorecard || scorecard.grades.length === 0) {
    const attrs = scorecard
      ? {
          title: "Last scorecard included no grades",
          content:
            "Generate a new scorecard to evaluate the investigation's performance with the latest graders",
        }
      : {
          title: "No scorecard available",
          content:
            "Generate a scorecard to evaluate the investigation's performance",
        };

    return (
      <div className={className}>
        <EmptyState
          {...attrs}
          icon={IconEnum.Chart}
          cta={
            <Button
              onClick={onGenerateScorecard}
              disabled={isGenerating}
              analyticsTrackingId={null}
              icon={IconEnum.Chart}
              loading={isGenerating}
            >
              {isGenerating ? "Generating scorecard..." : "Generate scorecard"}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <ContentBox>
        <div className="flex justify-between items-center p-4 border-b border-stroke">
          <div className="text-sm text-content-secondary">
            Scored{" "}
            <LocalRelativeDateTime date={new Date(scorecard.graded_at)} />
          </div>

          <div className="flex items-center gap-2">
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              analyticsTrackingId={null}
              icon={IconEnum.BarChart}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isExpanded ? "Hide trace" : "Show trace"}
            </Button>
            <Button
              onClick={onGenerateScorecard}
              disabled={isGenerating}
              analyticsTrackingId={null}
              icon={IconEnum.Chart}
              loading={isGenerating}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isGenerating ? "Generating..." : "Refresh"}
            </Button>
          </div>
        </div>
        <EvaluationFilterContextProvider enableSelectingMetrics={false}>
          <EvaluationScorecardGrades
            allEvents={scorecard.grades.flatMap((g) => g.events)}
            grades={scorecard.grades}
            warnings={scorecard.warnings}
            showFilterBar={true}
            showGradeNames={showGradeNames}
          />
        </EvaluationFilterContextProvider>

        {isExpanded && (
          <div className="p-4">
            <EvaluationScorecardTrace scorecard={scorecard} />{" "}
          </div>
        )}
      </ContentBox>
    </div>
  );
};
