import { SelectOption } from "@incident-ui/Select/types";
import {
  Tooltip,
  TooltipAlign,
  TooltipSide,
} from "@incident-ui/Tooltip/Tooltip";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { sortBy } from "lodash";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type RadioButtonGroupOption = SelectOption<React.ReactNode> & {
  description?: React.ReactNode;
  tooltip?: string;
  tooltipProps?: RadioButtonGroupOptionTooltipProps;
  isDisabled?: boolean;
  isDisabledTooltipContent?: React.ReactNode;
  renderWhenSelectedNode?: () => React.ReactNode | null;
  suffixNode?: React.ReactNode;
  prefixNode?: React.ReactNode;
};

export type RadioButtonGroupOptionTooltipProps = {
  side?: TooltipSide;
  align?: TooltipAlign;
};

export type RadioButtonGroupProps = {
  options: RadioButtonGroupOption[];
  name: string;
  srLabel: string;
  onChange: (e: string) => void;
  value: string | undefined;
  horizontal?: boolean;
  boxed?: boolean;
  radioClassName?: string;
  groupClassName?: string;
  renderWhenSelectedMode?: "inline-with-item" | "below-group";
};

export const RadioButtonGroup = React.forwardRef<
  HTMLInputElement,
  RadioButtonGroupProps
>(
  (
    {
      options,
      value,
      name,
      onChange,
      horizontal,
      boxed,
      srLabel,
      radioClassName,
      groupClassName,
      renderWhenSelectedMode = "inline-with-item",
    }: RadioButtonGroupProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const sortedOptions = sortBy(options, "sort_key");

    const selectedNodeAdditionalContent = sortedOptions
      .find((opt) => opt.value === value)
      ?.renderWhenSelectedNode?.();
    return (
      <div className={""}>
        <RadioGroup.Root
          aria-label={srLabel}
          ref={ref}
          value={value}
          onValueChange={(val) => onChange(val)}
          className={tcx(
            "flex",
            horizontal ? "gap-4 items-center" : "flex-col",
            {
              "gap-2": !horizontal && !boxed,
              "items-stretch": horizontal && boxed,
            },
            groupClassName,
          )}
        >
          {sortedOptions.map((opt) => (
            <RadioButtonItem
              key={opt.value}
              value={opt.value}
              label={opt.label}
              tooltip={opt.tooltip}
              tooltipProps={opt.tooltipProps}
              disabled={opt.isDisabled}
              disabledTooltip={opt.isDisabledTooltipContent}
              checked={value === opt.value}
              id={`${name}-${opt.value}`}
              containerClassName={tcx({
                "bg-white border-stroke shadow-sm": boxed,
                "first-of-type:rounded-t-lg last-of-type:rounded-b-lg border-x border-t last-of-type:border-b":
                  boxed && !horizontal,
                "rounded-2 border hover:border-slate-400 [&:has(:checked)]:border-slate-500 flex-grow grow-1 basis-0":
                  boxed && horizontal,
              })}
              labelClassName={tcx(
                {
                  "p-3": boxed,
                  // We need this so that each item doesn't grow to the full width of the page, otherwise
                  // the disabled tooltip is left floating hilariously in mid-air.
                  "self-baseline": !horizontal && !boxed,
                  "flex items-center": horizontal && boxed,
                },
                radioClassName,
              )}
              description={opt.description}
              renderWhenSelectedNode={
                renderWhenSelectedMode === "inline-with-item"
                  ? opt.renderWhenSelectedNode
                  : undefined
              }
              suffixNode={opt.suffixNode}
              prefixNode={opt.prefixNode}
            />
          ))}
        </RadioGroup.Root>
        {renderWhenSelectedMode === "below-group" &&
          selectedNodeAdditionalContent && (
            <div className="-mt-4 pt-8 pb-4 px-4 bg-slate-50 border border-slate-100 rounded-b-lg">
              {selectedNodeAdditionalContent}
            </div>
          )}
      </div>
    );
  },
);

export type RadioButtonItemProps = {
  id: string;
  label: React.ReactNode;
  labelClassName?: string;
  tooltip?: string;
  tooltipProps?: RadioButtonGroupOptionTooltipProps;
  containerClassName?: string;
  value: string;
  description?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  disabledTooltip?: React.ReactNode;
  renderWhenSelectedNode?: () => React.ReactNode | null;
  suffixNode?: React.ReactNode;
  prefixNode?: React.ReactNode;
};

export const RadioButtonItem = React.forwardRef<
  HTMLButtonElement,
  RadioButtonItemProps
>(
  (
    {
      label,
      description,
      id,
      checked,
      tooltip,
      tooltipProps,
      labelClassName,
      containerClassName,
      disabled = false,
      disabledTooltip,
      renderWhenSelectedNode,
      suffixNode,
      prefixNode,
      value,
    }: RadioButtonItemProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Tooltip content={disabled ? undefined : tooltip} {...tooltipProps}>
        <div
          className={tcx(
            "flex flex-col gap-2",
            {
              "text-content-tertiary": disabled,
            },
            containerClassName,
          )}
        >
          <label
            htmlFor={id}
            className={tcx(
              "w-full h-full",
              {
                "cursor-pointer": !disabled,
              },
              labelClassName,
            )}
          >
            <div className="flex flex-col gap-1 w-full">
              <Tooltip
                key={value}
                content={disabled ? disabledTooltip : undefined}
              >
                <div className="flex text-sm-med justify-between">
                  <div className="flex items-center gap-2 shrink">
                    <RadioGroup.Item
                      ref={ref}
                      value={value}
                      disabled={disabled}
                      id={id}
                      className={tcx(
                        "bg-white w-4 h-4 rounded-full border border-stroke outline-none flex items-center shrink-0",
                        {
                          // When you're about to select this item, show a different background color
                          "hover:border-slate-500": !checked,
                          // When you've selected this item, show the right border color (depending on whether we're
                          // disabled or not)
                          "border-slate-900 border-[1.2px]":
                            checked && !disabled,
                          "border-slate-400": checked && disabled,
                          // When we're disabled, look disabled.
                          "bg-surface-secondary": disabled,
                          "cursor-pointer": !disabled,
                        },
                      )}
                    >
                      <RadioGroup.Indicator
                        className={tcx(
                          "flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[6px] after:h-[6px] after:rounded-[50%]",
                          disabled
                            ? "after:bg-slate-400"
                            : "after:bg-surface-invert",
                        )}
                      />
                    </RadioGroup.Item>
                    {prefixNode}
                    <div className="flex flex-col">
                      {label}
                      {description ? (
                        <div
                          className={tcx("text-xs-med text-content-secondary", {
                            "!text-content-tertiary": disabled,
                          })}
                        >
                          {description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {suffixNode}
                </div>
              </Tooltip>
            </div>
          </label>
          {renderWhenSelectedNode && checked && (
            <div className="pl-10 pb-4 pr-4 -mt-2">
              {renderWhenSelectedNode()}
            </div>
          )}
        </div>
      </Tooltip>
    );
  },
);

RadioButtonGroup.displayName = "RadioButtonGroup";
RadioButtonItem.displayName = "RadioButtonItem";
