/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianWidgetStatusComponent,
    AtlassianWidgetStatusComponentFromJSON,
    AtlassianWidgetStatusComponentFromJSONTyped,
    AtlassianWidgetStatusComponentToJSON,
} from './AtlassianWidgetStatusComponent';
import {
    AtlassianWidgetStatusIncident,
    AtlassianWidgetStatusIncidentFromJSON,
    AtlassianWidgetStatusIncidentFromJSONTyped,
    AtlassianWidgetStatusIncidentToJSON,
} from './AtlassianWidgetStatusIncident';
import {
    AtlassianWidgetStatusPage,
    AtlassianWidgetStatusPageFromJSON,
    AtlassianWidgetStatusPageFromJSONTyped,
    AtlassianWidgetStatusPageToJSON,
} from './AtlassianWidgetStatusPage';
import {
    AtlassianWidgetStatusScheduledMaintenance,
    AtlassianWidgetStatusScheduledMaintenanceFromJSON,
    AtlassianWidgetStatusScheduledMaintenanceFromJSONTyped,
    AtlassianWidgetStatusScheduledMaintenanceToJSON,
} from './AtlassianWidgetStatusScheduledMaintenance';
import {
    AtlassianWidgetStatusStatus,
    AtlassianWidgetStatusStatusFromJSON,
    AtlassianWidgetStatusStatusFromJSONTyped,
    AtlassianWidgetStatusStatusToJSON,
} from './AtlassianWidgetStatusStatus';

/**
 * 
 * @export
 * @interface StatusPageContentAtlassianWidgetSummaryResponseBody
 */
export interface StatusPageContentAtlassianWidgetSummaryResponseBody {
    /**
     * List of components and their current status
     * @type {Array<AtlassianWidgetStatusComponent>}
     * @memberof StatusPageContentAtlassianWidgetSummaryResponseBody
     */
    components: Array<AtlassianWidgetStatusComponent>;
    /**
     * List of unresolved incidents
     * @type {Array<AtlassianWidgetStatusIncident>}
     * @memberof StatusPageContentAtlassianWidgetSummaryResponseBody
     */
    incidents?: Array<AtlassianWidgetStatusIncident>;
    /**
     * 
     * @type {AtlassianWidgetStatusPage}
     * @memberof StatusPageContentAtlassianWidgetSummaryResponseBody
     */
    page: AtlassianWidgetStatusPage;
    /**
     * List of upcoming or in-progress scheduled maintenances
     * @type {Array<AtlassianWidgetStatusScheduledMaintenance>}
     * @memberof StatusPageContentAtlassianWidgetSummaryResponseBody
     */
    scheduled_maintenances?: Array<AtlassianWidgetStatusScheduledMaintenance>;
    /**
     * 
     * @type {AtlassianWidgetStatusStatus}
     * @memberof StatusPageContentAtlassianWidgetSummaryResponseBody
     */
    status: AtlassianWidgetStatusStatus;
}

export function StatusPageContentAtlassianWidgetSummaryResponseBodyFromJSON(json: any): StatusPageContentAtlassianWidgetSummaryResponseBody {
    return StatusPageContentAtlassianWidgetSummaryResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentAtlassianWidgetSummaryResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentAtlassianWidgetSummaryResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(AtlassianWidgetStatusComponentFromJSON)),
        'incidents': !exists(json, 'incidents') ? undefined : ((json['incidents'] as Array<any>).map(AtlassianWidgetStatusIncidentFromJSON)),
        'page': AtlassianWidgetStatusPageFromJSON(json['page']),
        'scheduled_maintenances': !exists(json, 'scheduled_maintenances') ? undefined : ((json['scheduled_maintenances'] as Array<any>).map(AtlassianWidgetStatusScheduledMaintenanceFromJSON)),
        'status': AtlassianWidgetStatusStatusFromJSON(json['status']),
    };
}

export function StatusPageContentAtlassianWidgetSummaryResponseBodyToJSON(value?: StatusPageContentAtlassianWidgetSummaryResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(AtlassianWidgetStatusComponentToJSON)),
        'incidents': value.incidents === undefined ? undefined : ((value.incidents as Array<any>).map(AtlassianWidgetStatusIncidentToJSON)),
        'page': AtlassianWidgetStatusPageToJSON(value.page),
        'scheduled_maintenances': value.scheduled_maintenances === undefined ? undefined : ((value.scheduled_maintenances as Array<any>).map(AtlassianWidgetStatusScheduledMaintenanceToJSON)),
        'status': AtlassianWidgetStatusStatusToJSON(value.status),
    };
}

