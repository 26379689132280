/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopilotShortlistItem
 */
export interface CopilotShortlistItem {
    /**
     * Reference to the item
     * @type {string}
     * @memberof CopilotShortlistItem
     */
    reference: string;
    /**
     * Relevance of the item
     * @type {string}
     * @memberof CopilotShortlistItem
     */
    relevance: string;
    /**
     * Rank of the relevance
     * @type {number}
     * @memberof CopilotShortlistItem
     */
    relevance_rank: number;
}

export function CopilotShortlistItemFromJSON(json: any): CopilotShortlistItem {
    return CopilotShortlistItemFromJSONTyped(json, false);
}

export function CopilotShortlistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotShortlistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': json['reference'],
        'relevance': json['relevance'],
        'relevance_rank': json['relevance_rank'],
    };
}

export function CopilotShortlistItemToJSON(value?: CopilotShortlistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'relevance': value.relevance,
        'relevance_rank': value.relevance_rank,
    };
}

