"use client";

import {
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncidentTypeEnum,
} from "@incident-io/api";
import {
  ComponentStatusIcon,
  GenericAffectedComponent,
  GenericStructureItem,
} from "@incident-io/status-page-ui";
import _ from "lodash";
import React, { useState } from "react";

import { STATUS_SEVERITY, THEME_FOR_STATUS } from "../../helpers";
import { useTranslations } from "../../use-translations";
import { ContentBox, ContentBoxTheme } from "../ContentBox";
import { FullyOperationalIcon } from "../Icons/FullyOperationalIcon";
import { ComponentBadge } from "./ComponentBadge";
import { NoImpactIcon } from "./NoImpactIcon";

type OngoingIncident = {
  id: string;
  name: string;
  type: StatusPageContentIncidentTypeEnum;
  worstImpacts: {
    component_id: string;
    status: StatusPageContentComponentImpactStatusEnum;
  }[];
};

type HeadsUpProps = {
  ongoingIncidents: OngoingIncident[];
  affectedComponents: GenericAffectedComponent[];
  structure: GenericStructureItem[];
  supportUrl: string | null;
  theme?: ContentBoxTheme;
  DetailOngoingIncidents: (props: {
    setCurrentIncident: (id: string | null) => void;
  }) => React.ReactElement;
  noHeaderIcon?: boolean;
  useComponentsOverGroups?: boolean;
};

// Split into two, provide:
//
// 1. Banner that summarises current health
// 2. Detail of the on-going incidents and maintenances
// We don't want an error boundary here, the page wouldn't show much
// without this section
export const HeadsUp = ({
  ongoingIncidents,
  affectedComponents,
  structure,
  supportUrl,
  DetailOngoingIncidents,
  theme,
  noHeaderIcon = false,
  useComponentsOverGroups = false,
}: HeadsUpProps): React.ReactElement => {
  const incidentCount = ongoingIncidents.length;

  // A map of component ids and "enriched" components, e.g. affected
  // components with information about their groups and incidents
  const enrichedComponents = getEnrichedAffectedComponents({
    ongoingIncidents,
    affectedComponents,
    structure,
  });

  // Flatten the map so we can search it for the worst component status
  // which allows us to colour the banner accordingly
  const flattenedComponents: EnrichedComponent[] =
    Object.values(enrichedComponents).flat();
  const worstStatus =
    _.maxBy(
      flattenedComponents.map(({ status }) => status),
      (status) => STATUS_SEVERITY[status],
    ) || (incidentCount === 0 ? ComponentStatusEnum.Operational : undefined);

  return (
    <ContentBox
      theme={theme ? theme : worstStatus && THEME_FOR_STATUS[worstStatus]}
      title={
        <Banner
          status={worstStatus}
          incidentCount={incidentCount}
          noHeaderIcon={noHeaderIcon}
        />
      }
      padded={false}
    >
      <div className={"text-sm"}>
        <Detail
          affectedComponents={enrichedComponents}
          ongoingIncidents={ongoingIncidents}
          supportUrl={supportUrl}
          structure={structure}
          DetailOngoingIncidents={DetailOngoingIncidents}
          useComponentsOverGroups={useComponentsOverGroups}
        />
      </div>
    </ContentBox>
  );
};

// Summary of current health, simple statement of "we're up/down".
const Banner = ({
  incidentCount,
  status,
  noHeaderIcon = false,
}: {
  incidentCount: number;
  status?: ComponentStatusEnum;
  noHeaderIcon?: boolean;
}): React.ReactElement => {
  const t = useTranslations("HeadsUp");

  if (status === ComponentStatusEnum.UnderMaintenance) {
    return (
      <li className="flex items-center text-content-primary dark:text-slate-50">
        {t("under_maintenance")}
      </li>
    );
  }

  return (
    <li className="flex items-center text-content-primary dark:text-slate-50 py-0.5">
      {noHeaderIcon ? null : status === undefined ? (
        <NoImpactIcon className="mr-2 text-slate-500 dark:text-slate-300" />
      ) : <>
        <ComponentStatusIcon
          componentStatus={status}
        />
      </>}
      {incidentCount === 0 ? t("fully_operational") : t("experiencing_issues")}
    </li>
  );
};

// Either restate things are fine, or give details of on-going incidents.
const Detail = ({
  ongoingIncidents,
  affectedComponents,
  structure,
  DetailOngoingIncidents,
  useComponentsOverGroups = false,
}: {
  ongoingIncidents: OngoingIncident[];
  supportUrl: string | null;
  structure: GenericStructureItem[];
  affectedComponents: { [key: string]: EnrichedComponent };
  DetailOngoingIncidents: (props: {
    setCurrentIncident: (id: string | null) => void;
  }) => React.ReactElement;
  useComponentsOverGroups?: boolean;
}): React.ReactElement => {
  const t = useTranslations("HeadsUp");

  // Represents the current incident that is being hovered over
  const [currentIncident, setCurrentIncident] = useState<string | null>(null);

  if (ongoingIncidents.length === 0) {
    return (
      <div className="text-content-primary dark:text-slate-50 p-4">
        {t("not_aware_of_issues")}
      </div>
    );
  }

  return (
    <>
      {Object.keys(affectedComponents).length > 0 ? (
        <>
          <div className="p-4">
            <DetailAffectedComponents
              currentIncident={currentIncident}
              affectedComponents={affectedComponents}
              structure={structure}
              useComponentsOverGroups={useComponentsOverGroups}
            />
          </div>
          {/* Border */}
          <div className="w-full h-px bg-surface-secondary dark:bg-slate-800"></div>
        </>
      ) : null}
      <DetailOngoingIncidents setCurrentIncident={setCurrentIncident} />
    </>
  );
};

// Itemise the affected components, using the summary roll-up
// and the summary structure
const DetailAffectedComponents = ({
  structure,
  affectedComponents,
  currentIncident,
  useComponentsOverGroups = false,
}: {
  structure: GenericStructureItem[];
  affectedComponents: { [key: string]: EnrichedComponent };
  currentIncident: string | null;
  useComponentsOverGroups?: boolean;
}): React.ReactElement => {
  const t = useTranslations("HeadsUp");
  // Get the components as an array and deduplicate components belonging
  // to the same group, choosing the one with the highest severity status.
  const flattenedComponents: EnrichedComponent[] =
    Object.values(affectedComponents).flat();

  const uniqueGroupComponents = useComponentsOverGroups
    ? pickUniqueComponetsFromGroups(structure)
    : null;

  function pickUniqueComponetsFromGroups(
    structureComponents: GenericStructureItem[],
  ) {
    const uniqueComponents: EnrichedComponent[] = [];
    const uniqueComponentIds: {
      componentId: string;
      groupId?: string;
    }[] = [];
    for (const item of structureComponents) {
      if (item.component) {
        if (
          !uniqueComponentIds.includes({
            componentId: item.component.id,
          })
        ) {
          uniqueComponentIds.push({ componentId: item.component.id });
        }
      } else if (item.group) {
        item.group.components.forEach((comp) => {
          if (!uniqueComponentIds.includes({ componentId: comp.id })) {
            uniqueComponentIds.push({
              componentId: comp.id,
              groupId: item.group.id,
            });
          }
        });
      }
    }

    for (const ids of uniqueComponentIds) {
      const enrichedComponent = affectedComponents[ids.componentId];
      const group = structureComponents.find(
        (item) => item.group?.id === ids.groupId,
      )?.group;
      if (!enrichedComponent) {
        continue;
      }
      uniqueComponents.push({
        id: enrichedComponent.id,
        name: enrichedComponent.name,
        status: enrichedComponent.status,
        incidents: enrichedComponent.incidents,
        groupId: group?.id,
        groupName: group?.name,
      });
    }
    return uniqueComponents;
  }

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-wrap gap-x-2 gap-y-3">
        {useComponentsOverGroups &&
          uniqueGroupComponents &&
          uniqueGroupComponents.map((enrichedComponent) => {
            return (
              <ComponentBadge
                key={enrichedComponent.id}
                name={
                  enrichedComponent.groupName
                    ? `${enrichedComponent.groupName} - ${enrichedComponent.name}`
                    : enrichedComponent.name
                }
                status={enrichedComponent.status}
                incidents={enrichedComponent.incidents}
                isHighlighted={currentIncident != null}
              />
            );
          })}
        {!useComponentsOverGroups &&
          structure.map(({ group, component }) => {
            if (component) {
              const enrichedComponent = affectedComponents[component.id];
              if (!enrichedComponent) {
                return null;
              }

              const found = _.find(
                enrichedComponent.incidents,
                (inc) => inc.id === currentIncident,
              );
              return (
                <ComponentBadge
                  key={enrichedComponent.id}
                  name={enrichedComponent.name}
                  status={enrichedComponent.status}
                  incidents={enrichedComponent.incidents}
                  isHighlighted={currentIncident != null && !!found}
                />
              );
            }

            if (group) {
              // For groups we have to do a little extra logic,
              // choosing the component with the highest severity
              const children = _.filter(
                flattenedComponents,
                (item) => item.groupName === group.name,
              );
              const worstComponent = _.maxBy(
                children,
                (child) => STATUS_SEVERITY[child.status],
              );
              if (worstComponent === undefined) {
                return null;
              }

              return (
                <ComponentBadge
                  key={group.name}
                  name={group.name}
                  status={worstComponent.status as ComponentStatusEnum}
                  isHighlighted={
                    currentIncident != null &&
                    children.some((component) => {
                      const found = _.find(
                        component.incidents,
                        (inc) => inc.id === currentIncident,
                      );
                      return !!found;
                    })
                  }
                  incidents={_.uniqBy(
                    children.flatMap((component) => component.incidents),
                    "id",
                  )}
                />
              );
            }
            return <></>;
          })}
      </div>
    </div>
  );
};

export type Incident = {
  id: string;
  name: string;
  type: StatusPageContentIncidentTypeEnum;
  currentWorstStatus?: StatusPageContentComponentImpactStatusEnum;
};

type EnrichedComponent = {
  id: string;
  name: string;
  groupName?: string;
  groupId?: string;
  status: ComponentStatusEnum;
  incidents: Incident[];
};

const getEnrichedAffectedComponents = ({
  ongoingIncidents,
  affectedComponents,
  structure,
}: Pick<
  HeadsUpProps,
  "ongoingIncidents" | "affectedComponents" | "structure"
>): { [key: string]: EnrichedComponent } => {
  // Initial selection of enriched components
  const enrichedComponents: { [key: string]: EnrichedComponent } = {};

  const componentIdToName: Record<string, string | undefined> = {};
  structure.forEach((item) => {
    if (item.component) {
      componentIdToName[item.component.id] = item.component.name;
    }

    if (item.group) {
      item.group.components.forEach((comp) => {
        componentIdToName[comp.id] = comp.name;
      });
    }
  });

  // Construct a map of affected components and their statuses
  affectedComponents.forEach((component) => {
    const compName = componentIdToName[component.component_id];
    if (!compName) return;

    enrichedComponents[component.component_id] = {
      id: component.component_id,
      name: compName,
      status: component.status,
      incidents: [],
    };
  });

  // Enrich components belonging to a group with group metadata
  structure.forEach(({ group }) => {
    if (group) {
      group.components.forEach((comp) => {
        const exists = enrichedComponents[comp.id];
        if (exists) {
          enrichedComponents[comp.id].groupId = group.id;
          enrichedComponents[comp.id].groupName = group.name;
        }
      });
    }
  });

  // Enrich components with the incidents they are currently affected by
  ongoingIncidents.forEach(({ id, worstImpacts, type, name }) => {
    const incidentStatus = _.maxBy(
      worstImpacts.map(({ status }) => status),
      (status) => STATUS_SEVERITY[status],
    );

    worstImpacts.forEach(({ component_id }) => {
      const enrichedComponent = enrichedComponents[component_id];
      if (enrichedComponent) {
        enrichedComponent.incidents.push({
          id,
          type,
          name,
          currentWorstStatus: incidentStatus,
        });
      }
    });
  });

  return enrichedComponents;
};
