import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { IconEnum } from "@incident-ui";
import React from "react";

import { FilterPopover } from "./FilterPopover";

export const AddFilterButton = ({
  disabled,
  disabledTooltipContent,
}: {
  disabled?: boolean;
  disabledTooltipContent?: string;
}): React.ReactElement => {
  return (
    <FilterPopover
      renderTriggerButton={({ onClick }) => (
        <GatedButton
          title="Filter"
          disabled={disabled}
          disabledTooltipContent={disabledTooltipContent}
          analyticsTrackingId="incident-filter-control-add-filter"
          onClick={() => onClick()}
          icon={IconEnum.FilterBars}
        >
          <span className="mobile-hidden">Filter</span>
        </GatedButton>
      )}
    />
  );
};
