import {
  ExternalSchedule,
  Schedule,
  ScheduleReplica,
  ScheduleReplicaPayload,
  ScheduleReplicaPayloadReplicaProviderEnum,
  ScheduleRotation,
  SchedulesSetReplicasRequestBody,
} from "@incident-io/api";
import _ from "lodash";
import { DateTime } from "luxon";
import { UseFormReturn } from "react-hook-form";

import { getCurrentlyActiveRotas } from "../common/util";
import {
  MultipleDestinationsFormData,
  ScheduleReplicaFormData,
} from "./ScheduleReplicaDrawer";

export const replicasToDefaultFormValues = ({
  now,
  replicas,
  schedule,
  externalSchedule,
}: {
  now: DateTime;
  schedule: Schedule;
  externalSchedule: ExternalSchedule | undefined;
  replicas: ScheduleReplica[];
}) => {
  const uniqueRotas: ScheduleRotation[] = _.chain(
    getCurrentlyActiveRotas({
      rotas: schedule.config?.rotations ?? [],
      now,
    }),
  )
    .uniqBy((r) => r.id)
    .value();

  const externalProviderIdOccurrences: Record<string, number> = {};
  let initialData: MultipleDestinationsFormData = {};
  for (const rota of uniqueRotas) {
    for (const layer of rota.layers) {
      const replicaProviderId = replicas.find(
        (r) =>
          r.sources.length > 0 &&
          r.sources.some(
            (s) => s.rotation_id === rota.id && s.layer_id === layer.id,
          ),
      )?.replica_provider_id;

      const idOrUnknown = replicaProviderId ?? "unknown";
      externalProviderIdOccurrences[idOrUnknown] =
        (externalProviderIdOccurrences[idOrUnknown] ?? 0) + 1;

      initialData = {
        ...initialData,
        [rota.id]: {
          ...initialData[rota.id],
          [layer.id]: {
            replica_provider_id: replicaProviderId,
          },
        },
      };
    }
  }

  const validReplicas = replicas.filter((r) => r.sources.length > 0);

  // We're only mirroring to many if there's only one external_provider_id and
  // every rota/layer is using it
  const isMirroringToMany =
    validReplicas.length > 0 &&
    Object.keys(externalProviderIdOccurrences).length > 1;

  return {
    enabled: validReplicas.length > 0,
    mirror_to_many: isMirroringToMany,
    single_destination_schedule_external_provider_id:
      replicas[0]?.replica_provider_id ??
      externalSchedule?.external_provider_id,
    multiple_destinations: initialData,
    replica_fallback_user_id: validReplicas[0]?.replica_fallback_user_id,
  };
};

export const formDataToPayload = (
  data: ScheduleReplicaFormData,
  schedule: Schedule,
  now: DateTime,
  formMethods: UseFormReturn<ScheduleReplicaFormData>,
): SchedulesSetReplicasRequestBody | undefined => {
  let replicas: ScheduleReplicaPayload[] = [];

  if (!data.enabled) {
    // If mirroring is disabled, set empty replicas array to remove all existing replicas
    replicas = [];
  } else if (data.mirror_to_many) {
    // If you're mirroring to many, then rejig the structure to key by external schedule ID
    replicas = _.chain(data.multiple_destinations)
      .map((layers, rotaId) =>
        _.map(layers, (layer, layerId) => ({
          rotation_id: rotaId,
          layer_id: layerId,
          replica_provider_id: layer.replica_provider_id,
        })),
      )
      .flatten()
      .groupBy((r) => r.replica_provider_id)
      .map(
        (replicas, replica_provider_id): ScheduleReplicaPayload => ({
          replica_provider_id: replica_provider_id,
          sources: replicas,
          replica_provider: ScheduleReplicaPayloadReplicaProviderEnum.Pagerduty,
          replica_fallback_user_id: data.replica_fallback_user_id,
        }),
      )
      .value();
  } else {
    // If you're mirroring to one, set all rotas/layers to go to one schedule
    const uniqueRotas: ScheduleRotation[] = _.chain(
      getCurrentlyActiveRotas({
        rotas: schedule.config?.rotations ?? [],
        now,
      }),
    )
      .uniqBy((r) => r.id)
      .value();

    if (!data.single_destination_schedule_external_provider_id) {
      formMethods.setError("single_destination_schedule_external_provider_id", {
        type: "manual",
        message: "Please select a destination schedule",
      });
      return undefined;
    }

    replicas = [
      {
        replica_provider_id:
          data.single_destination_schedule_external_provider_id,
        sources: uniqueRotas.flatMap((rota) =>
          rota.layers.map((layer) => ({
            rotation_id: rota.id,
            layer_id: layer.id,
          })),
        ),
        replica_provider: ScheduleReplicaPayloadReplicaProviderEnum.Pagerduty,
        replica_fallback_user_id: data.replica_fallback_user_id,
      },
    ];
  }

  return {
    replicas,
  };
};
