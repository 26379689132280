import { AIStaffRunPromptHealthReportRequestBody } from "@incident-io/api";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { cacheKey, useMutationV2 } from "src/utils/swr";

import { PromptNamesSelect } from "../common/PromptNamesSelect";

type FormData = AIStaffRunPromptHealthReportRequestBody;

export const RunPromptHealthReportDrawer = ({
  periodStart,
  periodEnd,
  filterPromptNames,
  onClose,
}: {
  periodStart?: Date;
  periodEnd?: Date;
  filterPromptNames?: string[];
  onClose: () => void;
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      // If no provided values, use the last 24 hours
      period_start: periodStart ?? new Date(Date.now() - 24 * 60 * 60 * 1000),
      period_end: periodEnd ?? new Date(),
      filter_prompt_names: filterPromptNames,
    },
  });

  const showToast = useToast();

  const { trigger: mutate, isMutating: isPending } = useMutationV2(
    async (apiClient, data: AIStaffRunPromptHealthReportRequestBody) => {
      await apiClient.aIStaffRunPromptHealthReport({
        runPromptHealthReportRequestBody: data,
      });
      // Return void to trigger a refetch
      return;
    },
    {
      invalidate: [cacheKey.all("aIStaffListPromptHealthReports")],
      onSuccess: () => {
        showToast({
          title:
            "Report has been submitted. Refresh the list in a couple of minutes to see the new report.",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast({
          title: "Failed to run report",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const selectedFilterPromptNames = formMethods.watch("filter_prompt_names");

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Run prompt health report"
          icon={IconEnum.Doc}
          onClose={onClose}
          color={ColorPaletteEnum.Purple}
        />
        <DrawerBody className="flex grow">
          <Form.Root
            id="create-prompt-health-report"
            onSubmit={(data) => {
              mutate({
                period_start: data.period_start,
                period_end: data.period_end,
                filter_prompt_names: data.filter_prompt_names,
              });
              onClose();
            }}
            formMethods={formMethods}
            saving={isPending}
          >
            <DateTimeInputV2
              formMethods={formMethods}
              name="period_start"
              label="Period start"
              helptext={
                periodStart
                  ? "This is defaulted to the time window used in the report."
                  : "This is defaulted to the last 24 hours."
              }
            />
            <DateTimeInputV2
              formMethods={formMethods}
              name="period_end"
              label="Period end"
            />
            <PromptNamesSelect
              formMethods={formMethods}
              name="filter_prompt_names"
              label="Prompts"
              isMulti={true}
              helptext="Prompts to include in the report. If left empty, all prompts will be included."
            />
            {filterPromptNames && (
              <div className="text-sm text-slate-500">
                <p>
                  We&apos;ve defaulted this to the prompts which failed the
                  report last time.
                </p>
              </div>
            )}
            {!selectedFilterPromptNames?.length && (
              <Callout theme={CalloutTheme.Warning}>
                This report will cost considerably more than if you filtered to
                some prompts. Please only use if you really need to test all
                prompts!
              </Callout>
            )}
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex justify-end">
          <Button
            type="submit"
            form="create-prompt-health-report"
            analyticsTrackingId={"create-prompt-health-report"}
            theme={ButtonTheme.Primary}
            loading={isPending}
          >
            Submit
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
