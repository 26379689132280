/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationLeaderboardEntry,
    EvaluationLeaderboardEntryFromJSON,
    EvaluationLeaderboardEntryFromJSONTyped,
    EvaluationLeaderboardEntryToJSON,
} from './EvaluationLeaderboardEntry';

/**
 * 
 * @export
 * @interface AIStaffCreateEvaluationLeaderboardEntryResponseBody
 */
export interface AIStaffCreateEvaluationLeaderboardEntryResponseBody {
    /**
     * 
     * @type {EvaluationLeaderboardEntry}
     * @memberof AIStaffCreateEvaluationLeaderboardEntryResponseBody
     */
    entry: EvaluationLeaderboardEntry;
}

export function AIStaffCreateEvaluationLeaderboardEntryResponseBodyFromJSON(json: any): AIStaffCreateEvaluationLeaderboardEntryResponseBody {
    return AIStaffCreateEvaluationLeaderboardEntryResponseBodyFromJSONTyped(json, false);
}

export function AIStaffCreateEvaluationLeaderboardEntryResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateEvaluationLeaderboardEntryResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': EvaluationLeaderboardEntryFromJSON(json['entry']),
    };
}

export function AIStaffCreateEvaluationLeaderboardEntryResponseBodyToJSON(value?: AIStaffCreateEvaluationLeaderboardEntryResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': EvaluationLeaderboardEntryToJSON(value.entry),
    };
}

