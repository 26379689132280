import { Mode } from "@incident-shared/forms/v2/formsv2";
import React from "react";
import { useQueryParams } from "src/utils/query-params";

import { AlertRouteCreateEditForm } from "./AlertRouteCreateEditForm";
import { AlertRouteCreationSource } from "./common/types";

export const AlertRouteCreatePage = (): React.ReactElement => {
  const params = useQueryParams();
  const source = params.get("source") || undefined;

  return (
    <AlertRouteCreateEditForm
      mode={Mode.Create}
      source={source as AlertRouteCreationSource}
    />
  );
};
