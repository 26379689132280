import React from "react";
import { SettingsSubPageWrapper } from "src/components/settings/SettingsRoute";

import { ReactComponent as Graphic } from "./subscription-being-set-up.svg";

export const SettingsBillingSuccessRoute = (): React.ReactElement => {
  return (
    <SettingsSubPageWrapper>
      <div
        className={
          "flex flex-col grow justify-center items-center self-stretch rounded-3 gap-10 bg-gradient-to-b from-[#F7F7F8] to-[#FFFFFF]"
        }
      >
        <div className="flex flex-col gap-6 pt-10 items-center justify-center max-w-[428px]  ">
          <Graphic />
          <div className="flex flex-col gap-2 pt-6 pb-6 items-center">
            <div className="text-2xl-bold text-center">
              Your subscription is now being set up
            </div>
            <div className="pt-1 text-base text-center text-sm-normal text-content-secondary">
              <p>
                This can take a few minutes and we may need to contact you for
                further information. If you have any questions email{" "}
                <a href="mailto:support@incident.io">
                  <strong>support@incident.io</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </SettingsSubPageWrapper>
  );
};
