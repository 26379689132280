import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { Path, UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";

export const TeamMultiSelect = <TFormType extends FieldValues>({
  formMethods,
  label,
  formKey,
}: {
  formMethods: UseFormReturn<TFormType>;
  label: string;
  formKey: Path<TFormType>;
}) => {
  const apiClient = useClient();

  return (
    <DynamicMultiSelectV2
      label={label}
      loadOptions={getTypeaheadOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.Team,
      )}
      hydrateOptions={hydrateInitialSelectOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.Team,
      )}
      name={formKey}
      formMethods={formMethods}
      optionIcon={IconEnum.Team}
      optionColor={ColorPaletteEnum.Purple}
    />
  );
};
