import { HolidayUserFeed, ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  LocalRelativeDateTime,
  StackedList,
  StackedListItem,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { DeletionConfirmationModal } from "../../../DeletionConfirmationModal";
import { CalendarFeedCreateEditModal } from "./CalendarFeedCreateEditModal";

export const CalendarFeedsConfigureDrawer = ({
  aboutThis,
}: {
  aboutThis: React.ReactNode;
}) => {
  const toast = useToast();

  const {
    data: feedsResp = { holiday_user_feeds: [] },
    isLoading,
    mutate: refetchHolidays,
  } = useAPI(
    "holidaysListFeeds",
    {},
    {
      fallbackData: { holiday_user_feeds: [] },
    },
  );

  const { trigger: onDelete, isMutating: isDestroying } = useAPIMutation(
    "holidaysListFeeds",
    {},
    async (apiClient, data: { id: string }) => {
      await apiClient.holidaysDestroyFeed(data);
    },
    {
      onSuccess() {
        refetchHolidays();
        toast({
          title: "Deleted calendar feed",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        toast({
          title: "Failed to delete calendar feed, please try again",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  if (isLoading || !feedsResp) {
    return <Loader />;
  }

  if (feedsResp.holiday_user_feeds.length === 0) {
    return (
      <div className={"p-6 flex flex-col gap-6"}>
        {aboutThis}
        <CalendarFeedsEmptyState />
      </div>
    );
  }

  return (
    <div className={"p-6 flex flex-col gap-6"}>
      {aboutThis}
      <StackedList className={"w-full"}>
        {feedsResp.holiday_user_feeds.map((feed) => (
          <CalendarFeedRow
            key={feed.id}
            feed={feed}
            onDelete={(id) => onDelete({ id })}
            isLoading={isDestroying}
          />
        ))}
      </StackedList>
    </div>
  );
};

const CalendarFeedRow = ({
  feed,
  onDelete,
  isLoading,
}: {
  feed: HolidayUserFeed;
  onDelete: (id: string) => void;
  isLoading: boolean;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <>
      <StackedListItem
        key={feed.id}
        title={feed.name}
        iconNode={
          <div className={"p-2.5 bg-surface-secondary rounded-lg"}>
            <Icon
              id={IconEnum.Calendar}
              size={IconSize.Medium}
              className={"text-content-primary"}
            />
          </div>
        }
        descriptionClassName={"overflow-hidden"}
        accessory={
          <div className={"flex flex-row gap-2"}>
            <GatedButton
              icon={IconEnum.Edit}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Tertiary}
              requiredScope={ScopeNameEnum.HolidayUserFeedsUpdate}
              className={"px-3 py-2"}
              onClick={() => setShowEditModal(true)}
              analyticsTrackingId="edit-calendar-feed"
              title={""}
            />
            <GatedButton
              icon={IconEnum.Delete}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Tertiary}
              requiredScope={ScopeNameEnum.HolidayUserFeedsDestroy}
              className={"px-3 py-2"}
              onClick={() => setShowDeleteConfirmation(true)}
              analyticsTrackingId="delete-calendar-feed"
              title={""}
            />
          </div>
        }
        description={
          <span className="mt-1 text-content-tertiary text-xs font-medium leading-none">
            {feed.creator?.user && `Added by ${feed.creator.user.name} ∙ `}
            {feed.last_synced_at && (
              <span
                className={
                  "text-content-tertiary text-xs font-medium leading-none"
                }
              >
                Last synced{" "}
                <LocalRelativeDateTime
                  date={feed.last_synced_at}
                  className={
                    "text-content-tertiary text-xs font-medium leading-none"
                  }
                />
              </span>
            )}
          </span>
        }
        badgeNode={
          feed.last_error_at && (
            <Tooltip
              content={
                "We failed to parse events from this calendar feed. Please contact our support team if this persists."
              }
            >
              <Badge
                size={BadgeSize.ExtraSmall}
                theme={BadgeTheme.Warning}
                icon={IconEnum.Warning}
              >
                Connection problem
              </Badge>
            </Tooltip>
          )
        }
      />
      <DeletionConfirmationModal
        resourceTitle={feed.name}
        title={`Are you sure you want to delete ${feed.name}?`}
        onDelete={() => {
          onDelete(feed.id);
          setShowDeleteConfirmation(false);
        }}
        isDeleting={isLoading}
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        deleteConfirmationContent={
          "Deleting this calendar feed will mean none of its events will show up in your On-call schedules."
        }
        analyticsTrackingId="delete-calendar-feed"
        confirmButtonText={"Delete"}
      />
      {showEditModal && (
        <CalendarFeedCreateEditModal
          initialData={feed}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

const CalendarFeedsEmptyState = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <div>
      <div className="p-8 w-full h-[202px] bg-white rounded-lg border border-stroke-primary flex-col justify-center items-center gap-4 inline-flex">
        <div
          className={"border border-dashed border-stroke-primary rounded-lg"}
        >
          <Icon
            id={IconEnum.CalendarPlus}
            className={"p-2 w-[45px] h-[45px] text-content-tertiary"}
          />
        </div>
        <div className="text-center text-content-tertiary text-sm font-normal leading-tight">
          You haven’t added any calendar feeds yet
        </div>
        <GatedButton
          requiredScope={ScopeNameEnum.HolidayUserFeedsCreate}
          size={BadgeSize.Large}
          className={"px-3 py-2"}
          onClick={() => setShowAddModal(true)}
          analyticsTrackingId="add-calendar-feed"
        >
          Add calendar feed
        </GatedButton>
      </div>
      {showAddModal && (
        <CalendarFeedCreateEditModal onClose={() => setShowAddModal(false)} />
      )}
    </div>
  );
};
