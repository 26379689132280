/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteEscalationTargetPayload,
    AlertRouteEscalationTargetPayloadFromJSON,
    AlertRouteEscalationTargetPayloadFromJSONTyped,
    AlertRouteEscalationTargetPayloadToJSON,
} from './AlertRouteEscalationTargetPayload';

/**
 * Configuration for alert escalation
 * @export
 * @interface AlertRouteEscalationConfigPayload
 */
export interface AlertRouteEscalationConfigPayload {
    /**
     * Should we auto cancel escalations when all alerts are resolved?
     * @type {boolean}
     * @memberof AlertRouteEscalationConfigPayload
     */
    auto_cancel_escalations: boolean;
    /**
     * Targets for escalation
     * @type {Array<AlertRouteEscalationTargetPayload>}
     * @memberof AlertRouteEscalationConfigPayload
     */
    escalation_targets: Array<AlertRouteEscalationTargetPayload>;
}

export function AlertRouteEscalationConfigPayloadFromJSON(json: any): AlertRouteEscalationConfigPayload {
    return AlertRouteEscalationConfigPayloadFromJSONTyped(json, false);
}

export function AlertRouteEscalationConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteEscalationConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_cancel_escalations': json['auto_cancel_escalations'],
        'escalation_targets': ((json['escalation_targets'] as Array<any>).map(AlertRouteEscalationTargetPayloadFromJSON)),
    };
}

export function AlertRouteEscalationConfigPayloadToJSON(value?: AlertRouteEscalationConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_cancel_escalations': value.auto_cancel_escalations,
        'escalation_targets': ((value.escalation_targets as Array<any>).map(AlertRouteEscalationTargetPayloadToJSON)),
    };
}

