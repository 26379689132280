import { CompletionMessage } from "@incident-io/api";
import { ContentBox, EmptyState, IconEnum } from "@incident-ui";

import { CodeViewer } from "./CodeViewer";

type MessageViewerProps = {
  messages: CompletionMessage[];
  highlightSearchTerm?: string;
};

export const MessageViewer = ({
  messages,
  highlightSearchTerm,
}: MessageViewerProps) => {
  if (!messages || messages.length === 0) {
    return (
      <EmptyState
        title="No messages to display"
        content="This request has probably been aged out"
        icon={IconEnum.Timer}
      />
    );
  }
  return (
    <div className="relative flex gap-4">
      {/* Table of Contents */}
      <ContentBox className="sticky top-0 h-fit w-fit flex-shrink-0 space-y-2 p-4">
        <h3 className="text-sm font-semibold mb-4">Messages</h3>
        {messages.map((msg, idx) => (
          <a
            key={idx}
            href={`#message-${idx}`}
            className="block px-2 py-1 text-sm rounded-md hover:bg-surface-secondary text-content-secondary"
          >
            {msg.role}
          </a>
        ))}
      </ContentBox>

      {/* Messages Content */}
      <div className="flex-1 overflow-x-none">
        <div className="flex flex-col gap-6">
          {messages.map((msg, idx) => (
            <div key={idx} id={`message-${idx}`}>
              {msg.role === "tool" && msg.tool_call ? (
                <CodeViewer
                  mode="json"
                  title={msg.role}
                  content={msg.tool_call}
                  highlightSearchTerm={highlightSearchTerm}
                  unlimitedHeight
                />
              ) : (
                <CodeViewer
                  mode="text"
                  title={msg.role}
                  content={msg.content}
                  highlightSearchTerm={highlightSearchTerm}
                  unlimitedHeight
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
