import {
  EvaluationDataset,
  EvaluationDatasetDatasetTypeEnum,
  EvaluationDatasetEntry,
  ScopeNameEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";

import { IncidentDatasetShowPageContent } from "./IncidentDatasetShowPageContent";
import { SearchDatasetShowPageContent } from "./SearchDatasetShowPageContent";

// Show a dataset, rolled-up statistics in its topsheet, and all the entries.
export const DatasetShowPage = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const navigate = useOrgAwareNavigate();

  const { data, isLoading } = useAPI("aIStaffShowEvaluationDataset", {
    id: datasetId || "",
  });
  const dataset = data?.dataset;
  const entries = data?.entries;

  const [renameModalOpen, setRenameModalOpen] = useState(false);

  if (!datasetId) {
    navigate("/workbench");
    return null;
  }

  const config = configForDatasetType(
    dataset?.dataset_type || EvaluationDatasetDatasetTypeEnum.Incident,
  );

  return (
    <WorkbenchSubPageWrapper
      loading={isLoading}
      crumbs={[
        { title: "Workbench", to: `/workbench` },
        { title: "Datasets", to: `/workbench/datasets` },
      ]}
      backHref="/workbench/datasets"
      title={dataset?.name ?? ""}
      onEditTitle={() => setRenameModalOpen(true)}
      accessory={
        <div className={"flex flex-center gap-2"}>
          <Button
            analyticsTrackingId="dataset-add-entries"
            theme={ButtonTheme.Primary}
            href={`${config.addEntryPath}?dataset%5Bnot_in%5D=${datasetId}`}
          >
            Add entries
          </Button>
        </div>
      }
    >
      {dataset && entries && (
        <config.PageContent dataset={dataset} entries={entries} />
      )}

      {renameModalOpen && dataset && (
        <UpdateDatasetNameModal
          dataset={dataset}
          onClose={() => setRenameModalOpen(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const UpdateDatasetNameModal = ({
  dataset,
  onClose,
}: {
  dataset: EvaluationDataset;
  onClose: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.IncidentsUpdate);

  const toast = useToast();

  const formMethods = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: dataset.name,
    },
  });

  const { trigger: onSubmit, isMutating: saving } = useMutationV2(
    async (apiClient, { name }: { name: string }) => {
      await apiClient.aIStaffUpdateEvaluationDataset({
        id: dataset.id,
        updateEvaluationDatasetRequestBody: {
          name: name,
          description: dataset.description,
          segments: dataset.segments,
        },
      });
    },
    {
      invalidate: [
        cacheKey.exactly("aIStaffShowEvaluationDataset", { id: dataset.id }),
        cacheKey.all("aIStaffListEvaluationDatasets"),
      ],

      setError: formMethods.setError,
      onSuccess: () => {
        toast({
          title: "Dataset renamed",
          theme: ToastTheme.Success,
        });
        onClose();
      },

      showErrorToast: "Could not rename dataset",
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename dataset"
      analyticsTrackingId="rename-incident-dataset"
      formMethods={formMethods}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
          disabled={!canEdit}
          disabledTooltipContent={
            "You do not have permission to update this dataset."
          }
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
};

type DatasetConfig = {
  addEntryPath: string;
  PageContent: React.FC<{
    dataset: EvaluationDataset;
    entries: EvaluationDatasetEntry[];
  }>;
};
const configForDatasetType = (
  type: EvaluationDatasetDatasetTypeEnum,
): DatasetConfig => {
  switch (type) {
    case EvaluationDatasetDatasetTypeEnum.Incident:
      return {
        addEntryPath: "/incidents",
        PageContent: IncidentDatasetShowPageContent,
      };
    case EvaluationDatasetDatasetTypeEnum.SearchCodeChanges:
    case EvaluationDatasetDatasetTypeEnum.SearchIncidents:
      return {
        addEntryPath: "/workbench/searches/ground-truths",
        PageContent: SearchDatasetShowPageContent,
      };
    default:
      assertUnreachable(type);
  }

  throw new Error("Unreachable");
};
