/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppleAppSiteAssociationApplinkDetail,
    AppleAppSiteAssociationApplinkDetailFromJSON,
    AppleAppSiteAssociationApplinkDetailFromJSONTyped,
    AppleAppSiteAssociationApplinkDetailToJSON,
} from './AppleAppSiteAssociationApplinkDetail';

/**
 * 
 * @export
 * @interface AppleAppSiteAssociationApplinks
 */
export interface AppleAppSiteAssociationApplinks {
    /**
     * Unused, details is used instead
     * @type {Array<string>}
     * @memberof AppleAppSiteAssociationApplinks
     */
    apps: Array<string>;
    /**
     * 
     * @type {Array<AppleAppSiteAssociationApplinkDetail>}
     * @memberof AppleAppSiteAssociationApplinks
     */
    details: Array<AppleAppSiteAssociationApplinkDetail>;
}

export function AppleAppSiteAssociationApplinksFromJSON(json: any): AppleAppSiteAssociationApplinks {
    return AppleAppSiteAssociationApplinksFromJSONTyped(json, false);
}

export function AppleAppSiteAssociationApplinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppleAppSiteAssociationApplinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': json['apps'],
        'details': ((json['details'] as Array<any>).map(AppleAppSiteAssociationApplinkDetailFromJSON)),
    };
}

export function AppleAppSiteAssociationApplinksToJSON(value?: AppleAppSiteAssociationApplinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': value.apps,
        'details': ((value.details as Array<any>).map(AppleAppSiteAssociationApplinkDetailToJSON)),
    };
}

