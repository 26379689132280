import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
  StaticSingleSelect,
} from "@incident-ui";
import { Input, InputType } from "@incident-ui/Input/Input";
import { StaticMultiSelect } from "@incident-ui/Select/StaticMultiSelect";
import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";
import { Table, TableHeaderCell, TableProps } from "@incident-ui/Table/Table";
import { Searcher } from "fast-fuzzy";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useOutletContext } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  Identity,
  RBACRoleWithAvailability,
  SeatDescription,
  SeatDescriptionTypeEnum,
  UserWithRoles,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIInfinite } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import {
  useArrayStateQueryParams,
  useQueryParams,
  useStateQueryParams,
} from "../../../../utils/query-params";
import { cleanDirectoryName } from "../scim/ScimShowPageInner";
import { UserEditModal } from "./UserEditModal";
import { UserInviteModal } from "./UserInviteModal";
import { UserRow } from "./UserRow";
import { isOnCallUser, isResponseUser } from "./utils";

const roleSlugsForUser = (user: UserWithRoles): string[] => [
  user.base_role.slug,
  ...user.custom_roles.map((r) => r.slug),
  user.state,
];

type FormData = {
  search: string;
  roleSlugs: string[];
  seatType: SeatDescriptionTypeEnum | undefined;
};

export type UserInviteContext = {
  showInviteModal: boolean;
  closeInviteModal: () => void;
};

export const UserListPage = () => {
  const { identity } = useIdentity();
  const { usersListPerformanceImprovements } = useFlags();

  if (!identity) {
    return <Loader />;
  } else if (usersListPerformanceImprovements) {
    return <UserListPageTableV2 identity={identity} />;
  } else {
    return <UserListPageTable identity={identity} />;
  }
};

export const UserListPageTable = ({ identity }: { identity: Identity }) => {
  const {
    responses: usersResp,
    isLoading: loadingUsers,
    isFullyLoaded: allUsersLoaded,
    loadMore: loadMoreUsers,
    refetch: refetchUsers,
  } = useAPIInfinite("usersList", {
    pageSize: 100,
  });
  const users = usersResp.flatMap((response) => response.users);

  const {
    enabled: scimEnabled,
    directoryName: scimDirectoryName,
    isLoading: scimConfigLoading,
    error: scimConfigError,
  } = useSCIMConfigState();

  const { data: rolesResp, error: rolesError } = useAPI(
    "usersListAvailableSeatsAndRoles",
    {}, // we don't care about _assignability_ here
  );

  const [userToEdit, setUserToEdit] = useState<UserWithRoles | null>(null);

  const { showInviteModal, closeInviteModal } =
    useOutletContext<UserInviteContext>();

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: loadingUsers,
    hasNextPage: !allUsersLoaded,
    onLoadMore: loadMoreUsers,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const roleStr = useQueryParams().get("roles") || "";
  const initialRoles = roleStr.length > 0 ? roleStr.split(",") : [];
  const formMethods = useForm<FormData>({
    defaultValues: {
      search: "",
      roleSlugs: initialRoles,
      seatType: undefined,
    },
  });
  const { watch } = formMethods;

  const searchNameTerm = watch("search");
  const seatType = watch("seatType");
  const searchRoleSlugsTerm = watch("roleSlugs");

  let filteredUsers = users;

  const userSearcher = new Searcher(users, {
    keySelector: (obj) => obj.name,
  });

  if (searchNameTerm !== "") {
    filteredUsers = userSearcher.search(searchNameTerm);
  }
  if (seatType && seatType !== ("" as unknown as SeatDescriptionTypeEnum)) {
    filteredUsers = filteredUsers.filter((u) => {
      if (seatType === SeatDescriptionTypeEnum.OnCall) {
        return isOnCallUser(u.state);
      } else if (seatType === SeatDescriptionTypeEnum.Responder) {
        return isResponseUser(u.state);
      }
      return true;
    });
  }

  if (searchRoleSlugsTerm.length > 0) {
    filteredUsers = filteredUsers.filter((user) => {
      return roleSlugsForUser(user).some((hasSlug) =>
        searchRoleSlugsTerm.includes(hasSlug),
      );
    });
  }

  const { onCallOnlyBillingSeats } = useFlags();

  const error = scimConfigError || rolesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (scimConfigLoading || !rolesResp || !usersResp) {
    return <Loader />;
  }

  return (
    <>
      <CalloutForSCIM />
      <UsersListFilters
        formMethods={formMethods}
        seats={rolesResp.seats}
        roles={rolesResp.roles}
      />
      <Table
        className={"mt-4"}
        gridTemplateColumns="3fr repeat(3, 2fr) 2fr"
        data={filteredUsers}
        loading={loadingUsers}
        infiniteScroll={{
          ref: infiniteScrollRef,
          isFullyLoaded: allUsersLoaded,
          isLoading: loadingUsers,
        }}
        header={
          <>
            {[
              "Name",
              onCallOnlyBillingSeats ? "On-call seat" : "On-call",
              onCallOnlyBillingSeats ? "Response seat" : "Seat",
              "Roles",
              "",
            ].map((h) => (
              <TableHeaderCell key={h} title={h} />
            ))}
          </>
        }
        renderRow={(user, i) => (
          <UserRow
            isLastRow={i === filteredUsers.length - 1}
            scimEnabled={scimEnabled}
            scimDirectoryName={scimDirectoryName}
            key={user.id}
            user={user}
            actorRole={identity.user_base_role_slug}
            availableRoles={rolesResp.roles.map(({ role }) => role)}
            onSelect={() => setUserToEdit(user)}
            searchRoleSlugsTerm={searchRoleSlugsTerm}
            refetchUsers={refetchUsers}
          />
        )}
      />
      {showInviteModal ? (
        <UserInviteModal
          onClose={closeInviteModal}
          refetchUsers={refetchUsers}
        />
      ) : userToEdit != null ? (
        <UserEditModal
          user={userToEdit}
          onClose={() => setUserToEdit(null)}
          refetchUsers={refetchUsers}
        />
      ) : null}
    </>
  );
};

const useFetchUsers = () => {
  // Search users by name
  const [searchNameTerm] = useStateQueryParams("search");
  const [debouncedSearchNameTerm] = useDebounce(searchNameTerm, 300);

  // Filter by seat type (On-call vs Responder)
  const [seatType] = useStateQueryParams<SeatDescriptionTypeEnum>("seat_type");
  // Filter by role slug
  const [roleSlugs] =
    useArrayStateQueryParams<SeatDescriptionTypeEnum>("roles");
  const {
    responses: usersResp,
    isLoading,
    isFullyLoaded,
    loadMore: loadMoreUsers,
    refetch,
  } = useAPIInfinite("usersList", {
    pageSize: 100,
    searchNameTerm: debouncedSearchNameTerm ?? undefined,
    seatType: seatType ? (seatType as SeatDescriptionTypeEnum) : undefined,
  });

  const users = usersResp.flatMap((response) => response.users);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore: loadMoreUsers,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  // HACK: Filter by role slug.  This should be done in the backend, however
  // The SQL was non-trivial and after some customer testing they did not
  // experience performance issues with this approach.
  const filteredUsers =
    roleSlugs.length > 0
      ? users.filter((user) => {
          return roleSlugsForUser(user).some((hasSlug) =>
            roleSlugs.includes(hasSlug),
          );
        })
      : users;

  return {
    users: filteredUsers,
    isLoading,
    isFullyLoaded,
    refetch,
    infiniteScrollRef,
  };
};

export const UserListPageTableV2 = ({ identity }: { identity: Identity }) => {
  const { data: rolesResp, error: rolesError } = useAPI(
    "usersListAvailableSeatsAndRoles",
    {}, // we don't care about _assignability_ here
  );

  const fetchUsers = useFetchUsers();

  const { showInviteModal, closeInviteModal } =
    useOutletContext<UserInviteContext>();
  const [userToEdit, setUserToEdit] = useState<UserWithRoles | null>(null);

  if (rolesError) {
    return <GenericErrorMessage error={rolesError} />;
  }

  if (!rolesResp) {
    return <Loader />;
  }

  return (
    <>
      <CalloutForSCIM />
      <UsersListFiltersV2 seats={rolesResp.seats} roles={rolesResp.roles} />
      <UsersTable
        identity={identity}
        setUserToEdit={setUserToEdit}
        roles={rolesResp.roles}
        users={fetchUsers.users}
        isLoading={fetchUsers.isLoading}
        refetchUsers={fetchUsers.refetch}
        infiniteScroll={{
          ref: fetchUsers.infiniteScrollRef,
          isFullyLoaded: fetchUsers.isFullyLoaded,
          isLoading: fetchUsers.isLoading,
        }}
      />
      {showInviteModal ? (
        <UserInviteModal
          onClose={closeInviteModal}
          refetchUsers={fetchUsers.refetch}
        />
      ) : userToEdit != null ? (
        <UserEditModal
          user={userToEdit}
          onClose={() => setUserToEdit(null)}
          refetchUsers={fetchUsers.refetch}
        />
      ) : null}
    </>
  );
};

const UsersListFilters = ({
  formMethods,
  seats,
  roles,
}: {
  formMethods: UseFormReturn<FormData>;
  seats: SeatDescription[];
  roles: RBACRoleWithAvailability[];
}) => {
  return (
    <Form.Root
      innerClassName={"mb-3"}
      formMethods={formMethods}
      onSubmit={() => null}
    >
      <div className="flex-center-y space-x-2 items-start">
        <InputV2
          type={InputType.Search}
          formMethods={formMethods}
          placeholder="Search by name"
          className={"grow bg-white min-w-[200px]"}
          name="search"
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="seatType"
          options={seats.map((s, i) => {
            return {
              label: s.name,
              value: s.type,
              sort_key: (1 / i).toString(),
            };
          })}
          isClearable
          placeholder="Filter by seat"
          className="min-w-[200px]"
        />
        <StaticMultiSelectV2
          formMethods={formMethods}
          name="roleSlugs"
          options={buildRoleOptions(roles)}
          isClearable
          placeholder="Filter by role"
          className="min-w-[200px]"
        />
      </div>
    </Form.Root>
  );
};

const UsersListFiltersV2 = ({
  seats,
  roles,
}: {
  seats: SeatDescription[];
  roles: RBACRoleWithAvailability[];
}) => {
  const [search, setSearch] = useStateQueryParams("search");
  const [selectedRoles, setSelectedRoles] = useArrayStateQueryParams("roles");
  const [seatType, setSeatTypes] =
    useStateQueryParams<SeatDescriptionTypeEnum>("seat_type");

  return (
    <div className="flex-center-y space-x-2 items-start">
      <Input
        id="search"
        type={InputType.Search}
        placeholder="Search by name"
        className={"grow bg-white min-w-[200px]"}
        value={search ?? undefined}
        onChange={(e) => setSearch(e.currentTarget.value)}
      />
      <StaticSingleSelect
        id="seatType"
        options={seats.map((s, i) => {
          return {
            label: s.name,
            value: s.type,
            sort_key: (1 / i).toString(),
          };
        })}
        isClearable
        placeholder="Filter by seat"
        className="min-w-[200px]"
        value={seatType}
        onChange={(val) =>
          setSeatTypes(val ? (val as SeatDescriptionTypeEnum) : undefined)
        }
      />
      <StaticMultiSelect
        id="roleSlugs"
        options={buildRoleOptions(roles)}
        isClearable
        placeholder="Filter by role"
        className="min-w-[200px]"
        value={selectedRoles}
        onChange={setSelectedRoles}
      />
    </div>
  );
};

const CalloutForSCIM = () => {
  const { enabled, directoryName } = useSCIMConfigState();
  return enabled ? (
    <Callout theme={CalloutTheme.Info} className={"mb-3"}>
      You&apos;re using SCIM to manage users. If you&apos;d like to add or
      remove users, you can do that from within {directoryName}.
    </Callout>
  ) : null;
};

const useSCIMConfigState = () => {
  const { data, isLoading, error } = useAPI("sCIMShowSettings", undefined);

  return {
    enabled:
      data?.enabled && data?.scim_config?.has_completed_initial_role_mapping,
    directoryName: cleanDirectoryName(data?.scim_config?.directory_type),
    isLoading,
    error,
  };
};

// buildRoleOptions builds a list of SelectOptions for the role filter dropdown.
const buildRoleOptions = (roles: RBACRoleWithAvailability[]) => {
  const roleOptions: SelectOption[] = [];
  const customRoleOptions: SelectOption[] = [];

  roles.forEach(({ role }) => {
    if (role.is_base_role) {
      roleOptions.push({
        label: role.name,
        value: role.slug,
        sort_key: (1 / role.rank).toString(),
      });
    } else {
      customRoleOptions.push({
        label: role.name,
        value: role.slug,
        sort_key: role.name.toLowerCase(),
      });
    }
  });

  const groups: SelectOptionGroup[] = [
    {
      label: "Roles",
      options: roleOptions,
    },
  ];

  // If there are no custom roles, we don't need to group the options at all
  if (customRoleOptions.length !== 0) {
    groups.push({
      label: "Custom Roles",
      options: customRoleOptions,
    });
  }

  return groups;
};

const UsersTable = ({
  identity,
  setUserToEdit,
  roles,
  users,
  isLoading,
  infiniteScroll,
  refetchUsers,
}: {
  identity: Identity;
  setUserToEdit: (user: UserWithRoles) => void;
  roles: RBACRoleWithAvailability[];
  users: UserWithRoles[];
  isLoading: boolean;
  infiniteScroll: TableProps<UserWithRoles>["infiniteScroll"];
  refetchUsers: () => Promise<void>;
}) => {
  const {
    enabled: scimEnabled,
    directoryName: scimDirectoryName,
    isLoading: scimConfigLoading,
    error: scimConfigError,
  } = useSCIMConfigState();

  const [selectedRoles] = useArrayStateQueryParams("roles");

  const { onCallOnlyBillingSeats } = useFlags();

  if (scimConfigLoading) {
    return <Loader />;
  }

  const error = scimConfigError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Table
      className={"mt-4"}
      gridTemplateColumns="3fr repeat(3, 2fr) 2fr"
      data={users}
      loading={isLoading}
      infiniteScroll={infiniteScroll}
      header={
        <>
          {[
            "Name",
            onCallOnlyBillingSeats ? "On-call seat" : "On-call",
            onCallOnlyBillingSeats ? "Response seat" : "Seat",
            "Roles",
            "",
          ].map((h) => (
            <TableHeaderCell key={h} title={h} />
          ))}
        </>
      }
      renderRow={(user, i) => (
        <UserRow
          isLastRow={i === users.length - 1}
          scimEnabled={scimEnabled}
          scimDirectoryName={scimDirectoryName}
          key={user.id}
          user={user}
          actorRole={identity.user_base_role_slug}
          availableRoles={roles.map(({ role }) => role)}
          onSelect={() => setUserToEdit(user)}
          searchRoleSlugsTerm={selectedRoles}
          refetchUsers={refetchUsers}
        />
      )}
    />
  );
};
