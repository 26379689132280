/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmbeddedCatalogEntry,
    EmbeddedCatalogEntryFromJSON,
    EmbeddedCatalogEntryFromJSONTyped,
    EmbeddedCatalogEntryToJSON,
} from './EmbeddedCatalogEntry';
import {
    EscalationPathConfigPayload,
    EscalationPathConfigPayloadFromJSON,
    EscalationPathConfigPayloadFromJSONTyped,
    EscalationPathConfigPayloadToJSON,
} from './EscalationPathConfigPayload';
import {
    ExternalSchedule,
    ExternalScheduleFromJSON,
    ExternalScheduleFromJSONTyped,
    ExternalScheduleToJSON,
} from './ExternalSchedule';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ExternalEscalationPath
 */
export interface ExternalEscalationPath {
    /**
     * If we've created a native EP from this external one, this is the ID of the native EP
     * @type {string}
     * @memberof ExternalEscalationPath
     */
    created_native_escalation_path_id?: string;
    /**
     * In type of the external provider
     * @type {string}
     * @memberof ExternalEscalationPath
     */
    external_provider: string;
    /**
     * In the external provider, this uniquely identifies the escalation path
     * @type {string}
     * @memberof ExternalEscalationPath
     */
    external_provider_id: string;
    /**
     * ID of the external escalation policy
     * @type {string}
     * @memberof ExternalEscalationPath
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEscalationPath
     */
    name: string;
    /**
     * 
     * @type {EscalationPathConfigPayload}
     * @memberof ExternalEscalationPath
     */
    native_config?: EscalationPathConfigPayload;
    /**
     * If we can't create a native escalation path from this external one, why not?
     * @type {Array<string>}
     * @memberof ExternalEscalationPath
     */
    native_config_unavailable_reasons: Array<ExternalEscalationPathNativeConfigUnavailableReasonsEnum>;
    /**
     * All referenced schedules including information about their import status
     * @type {Array<ExternalSchedule>}
     * @memberof ExternalEscalationPath
     */
    referenced_external_schedules?: Array<ExternalSchedule>;
    /**
     * Users this EP references that we couldn't resolve in our system
     * @type {Array<EmbeddedCatalogEntry>}
     * @memberof ExternalEscalationPath
     */
    unresolvable_directly_referenced_external_users: Array<EmbeddedCatalogEntry>;
    /**
     * Users that need an on-call seat to be placed on this path
     * @type {Array<User>}
     * @memberof ExternalEscalationPath
     */
    users_needing_upgrades?: Array<User>;
}

/**
* @export
* @enum {string}
*/
export enum ExternalEscalationPathNativeConfigUnavailableReasonsEnum {
    TooManyRepeats = 'too_many_repeats',
    UnsupportedFeature = 'unsupported_feature',
    MissingScheduleIds = 'missing_schedule_ids',
    DirectUsersMissing = 'direct_users_missing',
    ScheduleWithAllUserIdsMissing = 'schedule_with_all_user_ids_missing'
}

export function ExternalEscalationPathFromJSON(json: any): ExternalEscalationPath {
    return ExternalEscalationPathFromJSONTyped(json, false);
}

export function ExternalEscalationPathFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEscalationPath {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_native_escalation_path_id': !exists(json, 'created_native_escalation_path_id') ? undefined : json['created_native_escalation_path_id'],
        'external_provider': json['external_provider'],
        'external_provider_id': json['external_provider_id'],
        'id': json['id'],
        'name': json['name'],
        'native_config': !exists(json, 'native_config') ? undefined : EscalationPathConfigPayloadFromJSON(json['native_config']),
        'native_config_unavailable_reasons': json['native_config_unavailable_reasons'],
        'referenced_external_schedules': !exists(json, 'referenced_external_schedules') ? undefined : ((json['referenced_external_schedules'] as Array<any>).map(ExternalScheduleFromJSON)),
        'unresolvable_directly_referenced_external_users': ((json['unresolvable_directly_referenced_external_users'] as Array<any>).map(EmbeddedCatalogEntryFromJSON)),
        'users_needing_upgrades': !exists(json, 'users_needing_upgrades') ? undefined : ((json['users_needing_upgrades'] as Array<any>).map(UserFromJSON)),
    };
}

export function ExternalEscalationPathToJSON(value?: ExternalEscalationPath | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_native_escalation_path_id': value.created_native_escalation_path_id,
        'external_provider': value.external_provider,
        'external_provider_id': value.external_provider_id,
        'id': value.id,
        'name': value.name,
        'native_config': EscalationPathConfigPayloadToJSON(value.native_config),
        'native_config_unavailable_reasons': value.native_config_unavailable_reasons,
        'referenced_external_schedules': value.referenced_external_schedules === undefined ? undefined : ((value.referenced_external_schedules as Array<any>).map(ExternalScheduleToJSON)),
        'unresolvable_directly_referenced_external_users': ((value.unresolvable_directly_referenced_external_users as Array<any>).map(EmbeddedCatalogEntryToJSON)),
        'users_needing_upgrades': value.users_needing_upgrades === undefined ? undefined : ((value.users_needing_upgrades as Array<any>).map(UserToJSON)),
    };
}

