import { withSentryErrorBoundary } from "@incident-io/status-page-ui";
import { TruncatingReferenceLabel } from "@incident-shared/engine";
import { FilterControls } from "@incident-shared/filters/FilterControls";
import { BadgeSize, Button, ButtonTheme, Toggle } from "@incident-ui";
import {
  Popover,
  PopoverBody,
  PopoverTitleBar,
} from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import { captureException } from "@sentry/react";
import { Searcher, sortKind } from "fast-fuzzy";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
  isSpecialBoolFilter,
  isViewableFor,
  useFiltersContext,
  validateIncidentFormFieldValue,
} from "src/components/@shared/filters";
import { Form } from "src/components/@shared/forms";
import {
  CreateEditFormProps,
  Mode,
} from "src/components/@shared/forms/v2/formsv2";
import {
  FormField as IncidentFormField,
  FormFieldCategoryEnum,
  FormFieldIconEnum,
  FormFieldOperatorFieldTypeEnum as FormFieldType,
} from "src/contexts/ClientContext";
import { usePostmortemName } from "src/utils/postmortem-name";
import { usePrevious } from "use-hooks";

type MenuItem = {
  label: string;
  value: string;
  sort_key: string;
  icon: FormFieldIconEnum | undefined;
  category?: FormFieldCategoryEnum;
  category_order_rank: number;
  isDisabled?: boolean;
  disabledReason?: string;
} & MenuItemWithToggle;

type MenuItemWithToggle =
  | {
      isToggle: true;
      onToggle: () => void;
      toggleValue: boolean;
    }
  | {
      isToggle?: never;
      onToggle?: never;
      toggleValue?: never;
    };

interface MenuItemGroup {
  sortOrder: number;
  options: MenuItem[];
  label?: string;
}

export type FilterProps = {
  availableFilterFields: AvailableFilter[];
  appliedFilters: ExtendedFormFieldValue[];
  onAddFilter: (filters: ExtendedFormFieldValue) => void;
  onEditFilter: (filters: ExtendedFormFieldValue) => void;
  renderTriggerButton: (props: {
    onClick: (editFilterId?: string) => void;
  }) => React.ReactElement;
  alignPopover?: "end" | "start" | "center" | undefined;
};

export function FilterPopoverWithoutContext({
  availableFilterFields,
  appliedFilters,
  onAddFilter,
  onEditFilter,
  renderTriggerButton,
  alignPopover = "end",
}: FilterProps): React.ReactElement {
  const [editingState, setEditingState] =
    useState<CreateEditFormProps<string> | null>(null);
  const [search, setSearch] = useState("");

  const formMethods = useForm<ExtendedFormFieldValue>();
  const { watch, setError, setValue, clearErrors, reset } = formMethods;

  // `filter_id` is either the key of the field, e.g. `severity`
  // or the ID of the field if it's a customField/Role, e.g. `01GA24TTXGPW5XHFBS48NQ39HB`
  // or the ID of the field and a catalog type ID if it's a backlink filter
  // We use it to determine what type of field the user has selected to filter by
  const selectedFilterID = watch("filter_id");
  const resetSelectedFilterField = () =>
    setValue("filter_id", undefined as unknown as string);

  const selectedFilterField = availableFilterFields.find(
    (field) => selectedFilterID && field.filter_id === selectedFilterID,
  );
  const prevSelectedFilterField = usePrevious(selectedFilterField);

  const { postmortemName } = usePostmortemName(null);

  const selectedOperatorID = watch("operator");

  const clearValues = useCallback(() => {
    setValue<"multiple_options_value">("multiple_options_value", undefined);
    setValue<"single_option_value">("single_option_value", undefined);
    setValue<"string_value">("string_value", undefined);
    setValue<"bool_value">("bool_value", undefined);
  }, [setValue]);

  // Clear values when the selected filter field changes. Except when we initially load
  // the form, where we want to maintain the values given in initialValues.
  useEffect(() => {
    if (selectedFilterField) {
      const defaultOperator = selectedFilterField.operators[0];
      if (!defaultOperator) {
        captureException(new Error("selected filter field has no operators"), {
          extra: { selectedFilterField },
        });
      }

      if (selectedFilterField.key) {
        setValue<"field_key">("field_key", selectedFilterField.key);
      }

      // if we've gone from blank -> filter field, or if nothing has changed, no need to clear anything.
      // we should check if we need to set an operator
      if (
        !prevSelectedFilterField ||
        prevSelectedFilterField?.key === selectedFilterField.key
      ) {
        if (!selectedOperatorID) {
          setValue<"operator">("operator", defaultOperator.key);
        }
        // The boolean input component can't render a null value - it needs a default.
        if (defaultOperator.field_type === FormFieldType.BooleanInput) {
          setValue<"bool_value">("bool_value", true);
        }
        return;
      }

      setValue<"operator">("operator", defaultOperator.key);
      // The boolean input component can't render a null value - it needs a default.
      if (defaultOperator.field_type === FormFieldType.BooleanInput) {
        setValue<"bool_value">("bool_value", true);
      }

      clearValues();
      clearErrors();
    }
  }, [
    selectedFilterField,
    prevSelectedFilterField,
    selectedOperatorID,
    setValue,
    clearErrors,
    clearValues,
  ]);

  const onOperatorSelect = (key: string) => {
    setValue<"operator">("operator", key);

    const selectedOperator = selectedFilterField?.operators.find(
      (op) => op.key === key,
    );

    // FormFieldType.DateRangeInput needs to be cleared as it will take an existing value from
    // FormFieldType.DateInput which is not in the correct format.
    if (
      selectedOperator?.field_type === FormFieldType.None ||
      selectedOperator?.field_type === FormFieldType.DateRangeInput
    ) {
      clearValues();
    }
  };

  const onSubmit = (
    f: ExtendedFormFieldValue & {
      // Boolean toggles apply the filter immediately, rather than setting filter_field_id
      // and then letting the user submit in a separate step.
      booleanFilterField?: AvailableFilter;
      mode?: Mode;
    },
  ) => {
    clearErrors();
    const mode = f.mode ?? editingState?.mode;
    const filterField = f.booleanFilterField ?? selectedFilterField;
    if (!filterField) {
      setError("filter_id", { type: "manual", message: "is required" });
      return;
    }
    const err = validateIncidentFormFieldValue(f, filterField);

    if (err) {
      setError(err.key, { type: "manual", message: err.message });
      return;
    }

    // Add in the field_id and key from the AvailableFilter
    if (filterField.field_id) {
      f.field_id = filterField.field_id;
      f.typeahead_lookup_id = filterField.typeahead_lookup_id;
    }
    f.key = filterField.key;

    // We need to set the catalog_type_id on the ExtendedFormFieldValue
    // so that we know we're dealing with a synthetic catalog filter when
    // we're writing it to the query params.
    if (filterField.catalog_backed_opts) {
      f.catalog_type_id = filterField.catalog_backed_opts.catalog_type_id;
    }

    if (mode === Mode.Edit) {
      onEditFilter(f);
    } else {
      onAddFilter(f);
    }

    resetSelectedFilterField();
    // Only close the popover if we've not got a boolean field
    if (!isSpecialBoolFilter(filterField.key)) {
      setEditingState(null);
    }
    clearValues();
  };

  const onToggleBool = (f: AvailableFilter) => {
    const existingFilter = appliedFilters.filter(
      (filter) => filter.filter_id === f.filter_id,
    )?.[0];
    const booleanStatus = existingFilter?.bool_value ?? false;

    const updatedFilter = {
      filter_id: f.filter_id,
      field_key: f.key,
      operator: "is",
      bool_value: !booleanStatus,
      field_id: f.key,
      key: f.key,
    };
    if (existingFilter) {
      onEditFilter(updatedFilter);
    } else {
      onAddFilter(updatedFilter);
    }
  };

  const selectedFieldIds = appliedFilters.map(
    (appliedFilter) => appliedFilter.field_id || appliedFilter.key,
  );

  const getDisabledProps = (
    f: AvailableFilter,
  ): { isDisabled: boolean; disabledReason?: string } | object => {
    const appliedFilter = appliedFilters.find(
      (filter) => filter.filter_id === f.filter_id,
    );
    if (appliedFilter) {
      return {
        isDisabled: appliedFilter.is_disabled,
        disabledReason: appliedFilter.disabled_reason,
      };
    }
    return {};
  };

  const selectOptions = availableFilterFields
    .filter((f) => {
      return (
        f.show_in_ui &&
        // If filter has already been applied, don't show it in the list of available filters
        !selectedFieldIds.includes(f.field_id || f.key) &&
        !isSpecialBoolFilter(f.key)
      );
    })
    .map((f): MenuItem => {
      return {
        label: f.label,
        value: f.filter_id,
        sort_key: f.label,
        icon: f.icon,
        category: f.category,
        category_order_rank: f.category_order_rank || 999,
        ...getDisabledProps(f),
      };
    });

  const requiredFields = availableFilterFields.filter(
    (f) => isSpecialBoolFilter(f.key) && f.show_in_ui,
  );
  if (requiredFields.length > 0) {
    selectOptions.unshift(
      ...requiredFields.map((f): MenuItem => {
        const existingFilter = appliedFilters.filter(
          (filter) => filter.filter_id === f.filter_id,
        )?.[0];
        const booleanStatus = existingFilter?.bool_value ?? false;

        return {
          label: f.label,
          value: f.filter_id,
          sort_key: f.label,
          icon: f.icon,
          category: undefined,
          category_order_rank: 0,
          isToggle: true,
          toggleValue: booleanStatus,
          onToggle: () => onToggleBool(f),
          ...getDisabledProps(f),
        };
      }),
    );
  }

  const searcher = new Searcher(selectOptions, {
    keySelector: (s) => s.label,
    threshold: 0.8,
    sortBy: sortKind.insertOrder,
  });

  const visibleSelectOptions = search ? searcher.search(search) : selectOptions;

  const groupedSelectOptions = _.chain(visibleSelectOptions)
    .groupBy((x: MenuItem) => x.category?.replace(/_/g, " "))
    .entries()
    .map(([category, options]): MenuItemGroup => {
      if (category.toLowerCase() === "post mortems") {
        category = `${postmortemName}s`;
      }
      return {
        label: category === "undefined" ? undefined : category,
        options: options,
        sortOrder: options[0].category_order_rank,
      };
    })
    .sortBy((x) => x.sortOrder)
    .value();

  const handleClose = () => {
    setSearch("");
    resetSelectedFilterField();
    setEditingState(null);
  };

  const handleSelectMenuItem = (entry: MenuItem) => {
    setSearch("");
    setValue<"filter_id">("filter_id", entry.value);
  };

  const onClickPopoverTrigger = (editingId: string | undefined) => {
    if (editingId) {
      const relatedFilter = appliedFilters.find(
        (f) => f.filter_id === editingId,
      );
      if (relatedFilter) {
        setEditingState({
          mode: Mode.Edit,
          initialData: relatedFilter.filter_id,
        });
        reset(relatedFilter);
        setValue("filter_id", relatedFilter.filter_id);
      } else {
        setEditingState({ mode: Mode.Create });
      }
    } else {
      setEditingState({ mode: Mode.Create });
    }
  };

  return (
    <Popover
      trigger={renderTriggerButton({
        onClick: onClickPopoverTrigger,
      })}
      align={alignPopover}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
      open={!!editingState}
    >
      {/* Menu */}
      {!selectedFilterField ? (
        <>
          <FilterSearchInput search={search} setSearch={setSearch} />
          <PopoverBody className="w-[350px] max-h-[350px]">
            <FilterOptionsList
              menuEntries={groupedSelectOptions}
              handleSelect={handleSelectMenuItem}
            />
          </PopoverBody>
        </>
      ) : (
        <Form.Root
          formMethods={formMethods}
          onSubmit={onSubmit}
          innerClassName="!space-y-0"
        >
          <EditingFilterTitle
            canGoBack={editingState?.mode !== Mode.Edit}
            field={selectedFilterField}
            handleBack={() => resetSelectedFilterField()}
          />
          <PopoverBody className="w-[350px]">
            <FilterControls
              filter={selectedFilterField}
              selectedOperator={selectedOperatorID}
              onOperatorSelect={onOperatorSelect}
            />
          </PopoverBody>
        </Form.Root>
      )}
    </Popover>
  );
}

export const EditingFilterTitle = ({
  canGoBack,
  field,
  handleBack,
}: {
  canGoBack: boolean;
  field: IncidentFormField;
  handleBack?: () => void;
}) => {
  return (
    <PopoverTitleBar
      title={field.label}
      handleBack={canGoBack ? handleBack : undefined}
      submitButton={
        <Button
          type="submit"
          analyticsTrackingId={`add-filter-submit`}
          theme={ButtonTheme.Primary}
          size={BadgeSize.Medium}
        >
          Done
        </Button>
      }
      className="pb-1"
    />
  );
};

const FilterSearchInput = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return <PopoverSearch value={search} onChange={setSearch} />;
};

export const FilterPopover = withSentryErrorBoundary(
  ({
    renderTriggerButton,
    alignPopover,
  }: {
    renderTriggerButton: (props: {
      onClick: (editFilterID?: string) => void;
    }) => React.ReactElement;
    alignPopover?: "end" | "start" | "center" | undefined;
  }) => {
    const { filters, addFilter, editFilter, availableFilterFields, kind } =
      useFiltersContext();

    return (
      <FilterPopoverWithoutContext
        renderTriggerButton={renderTriggerButton}
        appliedFilters={filters}
        availableFilterFields={availableFilterFields.filter(
          isViewableFor(kind, { popover: true }),
        )}
        onAddFilter={addFilter}
        onEditFilter={editFilter}
        alignPopover={alignPopover}
      />
    );
  },
  "FilterPopover",
);

const FilterOptionsList = ({
  menuEntries,
  handleSelect,
}: {
  menuEntries: MenuItemGroup[];
  handleSelect: (entry: MenuItem) => void;
}) => {
  if (menuEntries.length === 0) {
    return (
      <PopoverItem noHover className="text-content-tertiary">
        No matching filters
      </PopoverItem>
    );
  }
  return (
    <>
      {menuEntries.map((group) => (
        <PopoverItemGroup label={_.startCase(group.label)} key={group.label}>
          {group.options.map((menuItem) => (
            <PopoverItem
              key={menuItem.value}
              icon={menuItem.icon}
              disabled={menuItem.isDisabled}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (menuItem.isToggle) {
                  menuItem.onToggle();
                } else {
                  handleSelect(menuItem);
                }
              }}
              tooltipContent={
                menuItem.isDisabled ? menuItem.disabledReason : menuItem.label
              }
              tooltipDelayDuration={500}
              tooltipAlign="start"
              suffix={
                menuItem.isToggle ? (
                  <Toggle
                    // We need the ID override as our Toggle component is sad if the IDs aren't unique. This is
                    // a little fudge so that when we have a load of 'enabled' toggles on a settings page, it all
                    // works as expected.
                    id={`toggle-${menuItem.value}`}
                    disabled={menuItem.isDisabled}
                    on={menuItem.toggleValue}
                    labelledById={`toggle-${menuItem.value}`}
                    onToggle={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      menuItem.onToggle();
                    }}
                  />
                ) : null
              }
            >
              {/* Set our own gap so it doesn't end up spaced with gap-2 like the rest of the popover */}
              <div className="flex gap-0 truncate">
                <TruncatingReferenceLabel
                  label={menuItem.label}
                  separatorStyle="arrows"
                />
              </div>
            </PopoverItem>
          ))}
        </PopoverItemGroup>
      ))}
    </>
  );
};
