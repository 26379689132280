/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentLearnings,
    IncidentLearningsFromJSON,
    IncidentLearningsFromJSONTyped,
    IncidentLearningsToJSON,
} from './IncidentLearnings';

/**
 * 
 * @export
 * @interface IncidentFact
 */
export interface IncidentFact {
    /**
     * 
     * @type {Date}
     * @memberof IncidentFact
     */
    created_at: Date;
    /**
     * Unique identifier
     * @type {string}
     * @memberof IncidentFact
     */
    id: string;
    /**
     * ID of the associated incident
     * @type {string}
     * @memberof IncidentFact
     */
    incident_id: string;
    /**
     * 
     * @type {IncidentLearnings}
     * @memberof IncidentFact
     */
    learnings?: IncidentLearnings;
    /**
     * Timestamp when learnings were generated
     * @type {Date}
     * @memberof IncidentFact
     */
    learnings_cache_until?: Date;
    /**
     * Text describing what happened in the incident
     * @type {string}
     * @memberof IncidentFact
     */
    symptoms?: string;
    /**
     * Timestamp when symptoms were generated
     * @type {Date}
     * @memberof IncidentFact
     */
    symptoms_cache_until?: Date;
    /**
     * Tags generated by an LLM representing the incident
     * @type {Array<string>}
     * @memberof IncidentFact
     */
    tags?: Array<string>;
    /**
     * Timestamp when tags were generated
     * @type {Date}
     * @memberof IncidentFact
     */
    tags_cache_until?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFact
     */
    updated_at: Date;
}

export function IncidentFactFromJSON(json: any): IncidentFact {
    return IncidentFactFromJSONTyped(json, false);
}

export function IncidentFactFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'learnings': !exists(json, 'learnings') ? undefined : IncidentLearningsFromJSON(json['learnings']),
        'learnings_cache_until': !exists(json, 'learnings_cache_until') ? undefined : (new Date(json['learnings_cache_until'])),
        'symptoms': !exists(json, 'symptoms') ? undefined : json['symptoms'],
        'symptoms_cache_until': !exists(json, 'symptoms_cache_until') ? undefined : (new Date(json['symptoms_cache_until'])),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'tags_cache_until': !exists(json, 'tags_cache_until') ? undefined : (new Date(json['tags_cache_until'])),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentFactToJSON(value?: IncidentFact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'learnings': IncidentLearningsToJSON(value.learnings),
        'learnings_cache_until': value.learnings_cache_until === undefined ? undefined : (value.learnings_cache_until.toISOString()),
        'symptoms': value.symptoms,
        'symptoms_cache_until': value.symptoms_cache_until === undefined ? undefined : (value.symptoms_cache_until.toISOString()),
        'tags': value.tags,
        'tags_cache_until': value.tags_cache_until === undefined ? undefined : (value.tags_cache_until.toISOString()),
        'updated_at': (value.updated_at.toISOString()),
    };
}

