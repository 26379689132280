/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydratedMicrosoftTeamsChannel
 */
export interface HydratedMicrosoftTeamsChannel {
    /**
     * Whether this channel is archived
     * @type {boolean}
     * @memberof HydratedMicrosoftTeamsChannel
     */
    is_archived: boolean;
    /**
     * Whether this channel is deleted
     * @type {boolean}
     * @memberof HydratedMicrosoftTeamsChannel
     */
    is_deleted: boolean;
    /**
     * Human readable name for the channel
     * @type {string}
     * @memberof HydratedMicrosoftTeamsChannel
     */
    label: string;
    /**
     * URL that links to the channel in Microsoft Teams
     * @type {string}
     * @memberof HydratedMicrosoftTeamsChannel
     */
    url: string;
    /**
     * Unique identifier for this channel in Microsoft Teams
     * @type {string}
     * @memberof HydratedMicrosoftTeamsChannel
     */
    value: string;
}

export function HydratedMicrosoftTeamsChannelFromJSON(json: any): HydratedMicrosoftTeamsChannel {
    return HydratedMicrosoftTeamsChannelFromJSONTyped(json, false);
}

export function HydratedMicrosoftTeamsChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedMicrosoftTeamsChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_archived': json['is_archived'],
        'is_deleted': json['is_deleted'],
        'label': json['label'],
        'url': json['url'],
        'value': json['value'],
    };
}

export function HydratedMicrosoftTeamsChannelToJSON(value?: HydratedMicrosoftTeamsChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_archived': value.is_archived,
        'is_deleted': value.is_deleted,
        'label': value.label,
        'url': value.url,
        'value': value.value,
    };
}

