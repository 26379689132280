import { AlertRouteSlim, AlertSourceConfig } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import React from "react";

import { AlertSourceConfigBadge } from "../common/AlertSourceConfigBadge";

export const AlertRouteCreateConnectConfirmationModal = ({
  onClose,
  alertRoutes,
  alertSources,
  fromAlertSource,
}: {
  onClose: () => void;
  alertRoutes: AlertRouteSlim[];
  alertSources: AlertSourceConfig[];
  fromAlertSource?: AlertSourceConfig;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const opts = {
    connect: {
      analyticsTrackingId: "connect-alert-source",
      title: "Connect to an alert route",
      description: (
        <p>
          You&rsquo;re already handling alerts with alert routes. If you&rsquo;d
          like to group your {fromAlertSource?.name} alerts with any other
          sources below, connect to an existing route. Otherwise, you can
          connect {fromAlertSource?.name} to a new alert route.
        </p>
      ),
      buttonTitle: "Connect to a new alert route",
    },
    create: {
      analyticsTrackingId: "create-alert-route-confirm",
      title: "Create alert route",
      description: (
        <p>
          You&rsquo;re already handling alerts with alert routes. If you&rsquo;d
          like to group your alerts with any sources below, modify an existing
          route. Otherwise, create one from scratch.
        </p>
      ),
      buttonTitle: "Create new alert route",
    },
  };

  const selectedOpts = fromAlertSource ? opts.connect : opts.create;

  return (
    <Modal
      analyticsTrackingId={selectedOpts.analyticsTrackingId}
      isOpen={true}
      onClose={onClose}
      title={selectedOpts.title}
    >
      <ModalContent>
        <div className="flex flex-col gap-4">
          {selectedOpts.description}

          <div className="flex flex-col gap-4 w-full">
            <div className="max-h-[300px] overflow-y-auto space-y-4">
              {alertRoutes.map((route) => (
                <AlertRouteModalItem
                  key={route.id}
                  route={route}
                  onClick={() => {
                    navigate(
                      `/alerts/routes/${route.id}/edit${
                        fromAlertSource
                          ? `?initial_source_id=${fromAlertSource.id}`
                          : ""
                      }`,
                    );
                  }}
                  alertSources={alertSources}
                />
              ))}
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          analyticsTrackingId="create-alert-route-create-fresh"
          icon={IconEnum.Add}
          onClick={() =>
            navigate(
              `/alerts/routes/create${
                fromAlertSource
                  ? `?initial_source_id=${fromAlertSource.id}`
                  : ""
              }`,
            )
          }
        >
          {selectedOpts.buttonTitle}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const AlertRouteModalItem = ({
  route,
  onClick,
  alertSources,
}: {
  route: AlertRouteSlim;
  onClick: () => void;
  alertSources: AlertSourceConfig[];
}) => {
  return (
    <Button
      analyticsTrackingId="alert-routes-create-edit-existing-route"
      theme={ButtonTheme.Unstyled}
      className="flex flex-col p-4 gap-6 border border-stroke rounded-2 w-full items-start hover:shadow-md hover:border-stroke-hover transition duration-150"
      onClick={onClick}
    >
      <div className="text-left text-sm-bold text-content-primary truncate w-full">
        {route.name}
      </div>
      <div className="flex gap-2 flex-wrap items-start">
        {route.alert_sources.map((source) => {
          const sourceConfig = alertSources.find(
            (s) => s.id === source.alert_source_id,
          );

          if (!sourceConfig) {
            return null;
          }

          return (
            <AlertSourceConfigBadge
              key={sourceConfig?.id}
              sourceConfig={sourceConfig}
              hasFilters={false}
              compact
            />
          );
        })}
      </div>
    </Button>
  );
};
