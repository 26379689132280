import { AIStaffListEvaluationNotesResourceTypeEnum } from "@incident-io/api";
import { Button, IconEnum } from "@incident-ui";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { Suggestion } from "../../legacy/incident/body/DebugViewSuggestions";
import { useIncident } from "../../legacy/incident/hooks";
import { IncidentCard } from "../../legacy/incident-grid/card/IncidentCard";
import { EvaluationNotesWidget } from "../evaluation-notes/EvaluationNotesWidget";

export const WorkbenchSuggestionShowPage = () => {
  const { suggestionId } = useParams<{
    suggestionId: string;
  }>();

  const { data, isLoading } = useAPI(
    suggestionId ? "incidentSuggestionsShowSuggestion" : null,
    {
      id: suggestionId ?? "",
    },
  );

  const suggestion = data?.incident_suggestion;

  const { incident } = useIncident(suggestion?.incident_id ?? null);

  return (
    <WorkbenchSubPageWrapper
      loading={isLoading}
      accessory={
        <>
          {data?.trace_id && (
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.Cog}
              href={`/workbench/processors/${data.span_id}`}
            >
              View processor
            </Button>
          )}
        </>
      }
    >
      {suggestion && (
        <EvaluationNotesWidget
          resourceId={suggestionId || ""}
          resourceType={AIStaffListEvaluationNotesResourceTypeEnum.Suggestion}
          parentId={suggestion?.incident_id}
        />
      )}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          {suggestion && <Suggestion suggestion={suggestion} />}
        </div>
        {incident && (
          <IncidentCard
            incident={incident}
            isGridFirstUpdate={false}
            slackTeamConfig={undefined}
          />
        )}
      </div>
    </WorkbenchSubPageWrapper>
  );
};
