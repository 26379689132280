/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArchiveInfo,
    ArchiveInfoFromJSON,
    ArchiveInfoFromJSONTyped,
    ArchiveInfoToJSON,
} from './ArchiveInfo';
import {
    BillingSetting,
    BillingSettingFromJSON,
    BillingSettingFromJSONTyped,
    BillingSettingToJSON,
} from './BillingSetting';
import {
    FeatureGates,
    FeatureGatesFromJSON,
    FeatureGatesFromJSONTyped,
    FeatureGatesToJSON,
} from './FeatureGates';
import {
    TrialStatus,
    TrialStatusFromJSON,
    TrialStatusFromJSONTyped,
    TrialStatusToJSON,
} from './TrialStatus';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface StaffShowOrganisationResponseBody
 */
export interface StaffShowOrganisationResponseBody {
    /**
     * 
     * @type {User}
     * @memberof StaffShowOrganisationResponseBody
     */
    account_executive?: User;
    /**
     * 
     * @type {ArchiveInfo}
     * @memberof StaffShowOrganisationResponseBody
     */
    archive_info: ArchiveInfo;
    /**
     * The ARR in dollars for this organisation, if known.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    arr_dollars?: number;
    /**
     * 
     * @type {BillingSetting}
     * @memberof StaffShowOrganisationResponseBody
     */
    billing_setting: BillingSetting;
    /**
     * The minimum number of on-call addons we will bill this organisation for.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    committed_minimum_oncall_addons?: number;
    /**
     * The minimum number of responder seats we will bill this organisation for.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    committed_minimum_seats?: number;
    /**
     * 
     * @type {User}
     * @memberof StaffShowOrganisationResponseBody
     */
    customer_success_manager?: User;
    /**
     * 
     * @type {FeatureGates}
     * @memberof StaffShowOrganisationResponseBody
     */
    feature_gates: FeatureGates;
    /**
     * Is this a demo account?
     * @type {boolean}
     * @memberof StaffShowOrganisationResponseBody
     */
    is_demo: boolean;
    /**
     * Whether the organisation is currently paying for the service.
     * @type {boolean}
     * @memberof StaffShowOrganisationResponseBody
     */
    is_paying: boolean;
    /**
     * Is this a sandbox account?
     * @type {boolean}
     * @memberof StaffShowOrganisationResponseBody
     */
    is_sandbox: boolean;
    /**
     * The number of On-call users in the organisation.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    on_call_count: number;
    /**
     * List of products available to the organisation
     * @type {Array<string>}
     * @memberof StaffShowOrganisationResponseBody
     */
    organisation_available_products: Array<StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum>;
    /**
     * When the organisation first signed up
     * @type {Date}
     * @memberof StaffShowOrganisationResponseBody
     */
    organisation_created_at: Date;
    /**
     * The ID of the organisation.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    organisation_id: string;
    /**
     * The name of the organisation.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    organisation_name: string;
    /**
     * The slug of the organisation.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    organisation_slug: string;
    /**
     * The primary communications platform (e.g. Slack or Teams) this organisation uses
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    primary_comms_platform: string;
    /**
     * The number of responders in the organisation.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    responder_count: number;
    /**
     * The ID of the Sequence customer.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    sequence_customer_id?: string;
    /**
     * The Slack owners for this organisation.
     * @type {Array<User>}
     * @memberof StaffShowOrganisationResponseBody
     */
    slack_owners?: Array<User>;
    /**
     * The ID of the Stripe customer currently billed for this org's usage.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    stripe_customer_id?: string;
    /**
     * The ID of the Stripe subscription currently billed for this org's usage.
     * @type {string}
     * @memberof StaffShowOrganisationResponseBody
     */
    stripe_subscription_id?: string;
    /**
     * When the trial should end.
     * @type {Date}
     * @memberof StaffShowOrganisationResponseBody
     */
    trial_end_at?: Date;
    /**
     * 
     * @type {TrialStatus}
     * @memberof StaffShowOrganisationResponseBody
     */
    trial_status: TrialStatus;
    /**
     * The number of viewers in the organisation.
     * @type {number}
     * @memberof StaffShowOrganisationResponseBody
     */
    viewer_count: number;
}

/**
* @export
* @enum {string}
*/
export enum StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum {
    Response = 'response',
    OnCall = 'on_call'
}

export function StaffShowOrganisationResponseBodyFromJSON(json: any): StaffShowOrganisationResponseBody {
    return StaffShowOrganisationResponseBodyFromJSONTyped(json, false);
}

export function StaffShowOrganisationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffShowOrganisationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_executive': !exists(json, 'account_executive') ? undefined : UserFromJSON(json['account_executive']),
        'archive_info': ArchiveInfoFromJSON(json['archive_info']),
        'arr_dollars': !exists(json, 'arr_dollars') ? undefined : json['arr_dollars'],
        'billing_setting': BillingSettingFromJSON(json['billing_setting']),
        'committed_minimum_oncall_addons': !exists(json, 'committed_minimum_oncall_addons') ? undefined : json['committed_minimum_oncall_addons'],
        'committed_minimum_seats': !exists(json, 'committed_minimum_seats') ? undefined : json['committed_minimum_seats'],
        'customer_success_manager': !exists(json, 'customer_success_manager') ? undefined : UserFromJSON(json['customer_success_manager']),
        'feature_gates': FeatureGatesFromJSON(json['feature_gates']),
        'is_demo': json['is_demo'],
        'is_paying': json['is_paying'],
        'is_sandbox': json['is_sandbox'],
        'on_call_count': json['on_call_count'],
        'organisation_available_products': json['organisation_available_products'],
        'organisation_created_at': (new Date(json['organisation_created_at'])),
        'organisation_id': json['organisation_id'],
        'organisation_name': json['organisation_name'],
        'organisation_slug': json['organisation_slug'],
        'primary_comms_platform': json['primary_comms_platform'],
        'responder_count': json['responder_count'],
        'sequence_customer_id': !exists(json, 'sequence_customer_id') ? undefined : json['sequence_customer_id'],
        'slack_owners': !exists(json, 'slack_owners') ? undefined : ((json['slack_owners'] as Array<any>).map(UserFromJSON)),
        'stripe_customer_id': !exists(json, 'stripe_customer_id') ? undefined : json['stripe_customer_id'],
        'stripe_subscription_id': !exists(json, 'stripe_subscription_id') ? undefined : json['stripe_subscription_id'],
        'trial_end_at': !exists(json, 'trial_end_at') ? undefined : (new Date(json['trial_end_at'])),
        'trial_status': TrialStatusFromJSON(json['trial_status']),
        'viewer_count': json['viewer_count'],
    };
}

export function StaffShowOrganisationResponseBodyToJSON(value?: StaffShowOrganisationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_executive': UserToJSON(value.account_executive),
        'archive_info': ArchiveInfoToJSON(value.archive_info),
        'arr_dollars': value.arr_dollars,
        'billing_setting': BillingSettingToJSON(value.billing_setting),
        'committed_minimum_oncall_addons': value.committed_minimum_oncall_addons,
        'committed_minimum_seats': value.committed_minimum_seats,
        'customer_success_manager': UserToJSON(value.customer_success_manager),
        'feature_gates': FeatureGatesToJSON(value.feature_gates),
        'is_demo': value.is_demo,
        'is_paying': value.is_paying,
        'is_sandbox': value.is_sandbox,
        'on_call_count': value.on_call_count,
        'organisation_available_products': value.organisation_available_products,
        'organisation_created_at': (value.organisation_created_at.toISOString()),
        'organisation_id': value.organisation_id,
        'organisation_name': value.organisation_name,
        'organisation_slug': value.organisation_slug,
        'primary_comms_platform': value.primary_comms_platform,
        'responder_count': value.responder_count,
        'sequence_customer_id': value.sequence_customer_id,
        'slack_owners': value.slack_owners === undefined ? undefined : ((value.slack_owners as Array<any>).map(UserToJSON)),
        'stripe_customer_id': value.stripe_customer_id,
        'stripe_subscription_id': value.stripe_subscription_id,
        'trial_end_at': value.trial_end_at === undefined ? undefined : (value.trial_end_at.toISOString()),
        'trial_status': TrialStatusToJSON(value.trial_status),
        'viewer_count': value.viewer_count,
    };
}

