/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotSearchLonglist,
    CopilotSearchLonglistFromJSON,
    CopilotSearchLonglistFromJSONTyped,
    CopilotSearchLonglistToJSON,
} from './CopilotSearchLonglist';
import {
    CopilotShortlistItem,
    CopilotShortlistItemFromJSON,
    CopilotShortlistItemFromJSONTyped,
    CopilotShortlistItemToJSON,
} from './CopilotShortlistItem';

/**
 * 
 * @export
 * @interface CopilotSearchWorking
 */
export interface CopilotSearchWorking {
    /**
     * Lists of candidates used in the initial search phase
     * @type {Array<CopilotSearchLonglist>}
     * @memberof CopilotSearchWorking
     */
    longlists: Array<CopilotSearchLonglist>;
    /**
     * Parameters used to construct the search query
     * @type {string}
     * @memberof CopilotSearchWorking
     */
    query_params: string;
    /**
     * Shortlisted candidates after filtering for relevance
     * @type {Array<CopilotShortlistItem>}
     * @memberof CopilotSearchWorking
     */
    shortlist: Array<CopilotShortlistItem>;
}

export function CopilotSearchWorkingFromJSON(json: any): CopilotSearchWorking {
    return CopilotSearchWorkingFromJSONTyped(json, false);
}

export function CopilotSearchWorkingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotSearchWorking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longlists': ((json['longlists'] as Array<any>).map(CopilotSearchLonglistFromJSON)),
        'query_params': json['query_params'],
        'shortlist': ((json['shortlist'] as Array<any>).map(CopilotShortlistItemFromJSON)),
    };
}

export function CopilotSearchWorkingToJSON(value?: CopilotSearchWorking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longlists': ((value.longlists as Array<any>).map(CopilotSearchLonglistToJSON)),
        'query_params': value.query_params,
        'shortlist': ((value.shortlist as Array<any>).map(CopilotShortlistItemToJSON)),
    };
}

