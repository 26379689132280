import {
  EngineScope,
  Reference,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { EngineFormElement, isEmptyBinding } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { Form } from "@incident-shared/forms";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Prompt } from "@incident-shared/utils/Prompt";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import {
  CommsPlatform,
  prettyPrintCommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../../hooks/usePrimaryCommsPlatform";
import { tcx } from "../../../../utils/tailwind-classes";
import { AlertRouteConditionsEditor } from "../common/AlertRouteConditionsEditor";
import { AlertRouteFormData, ChannelMessagesFormData } from "../common/types";

export const AlertChannelMessageDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
}: {
  onSubmit: (data: ChannelMessagesFormData) => void;
  onClose: () => void;
  initialData?: AlertRouteFormData;
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useForm<ChannelMessagesFormData>({
    defaultValues: initialData,
  });

  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope<Reference>(
    scope,
    expressionMethods.fields,
  );

  const {
    fields: channelConfigs,
    append: appendChannelBinding,
    remove: removeChannelBinding,
  } = useFieldArray({
    name: "channelConfigs",
    control: formMethods.control,
  });

  const handleAddChannel = useCallback(() => {
    appendChannelBinding(
      primaryCommsPlatform === CommsPlatform.Slack
        ? {
            condition_groups: [],
            slack_targets: {
              binding: {
                array_value: [],
              },
              channel_visibility: "public",
            },
            primary_comms_platform: "slack",
          }
        : {
            condition_groups: [],
            ms_teams_targets: {
              binding: {
                array_value: [],
              },
              // Teams only supports public channels
              channel_visibility: "public",
            },
            primary_comms_platform: "msteams",
          },
    );
  }, [primaryCommsPlatform, appendChannelBinding]);

  // Ensure default empty channelConfigs if none exist on mount
  useEffect(() => {
    if (channelConfigs.length === 0) {
      handleAddChannel();
    }
  }, [channelConfigs.length, handleAddChannel]);

  const handleRemoveChannel = useCallback(
    (index: number) => {
      removeChannelBinding(index);
    },
    [removeChannelBinding],
  );

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const enableAlertChannelMessages =
    formMethods.watch<"enableAlertChannelMessages">(
      "enableAlertChannelMessages",
    );

  const validateAndSubmit = useCallback(() => {
    const data = formMethods.getValues();

    // Clear any existing channel errors when disabling alert channel messages
    if (!data.enableAlertChannelMessages) {
      formMethods.clearErrors("enableAlertChannelMessages");
      onSubmit(data);
      return;
    }

    if (
      !data.channelConfigs.some((binding) => {
        if (
          binding.primary_comms_platform === CommsPlatform.Slack &&
          !isEmptyBinding(binding.slack_targets.binding)
        ) {
          return true;
        }
        if (
          binding.primary_comms_platform === CommsPlatform.MSTeams &&
          !isEmptyBinding(binding.ms_teams_targets.binding)
        ) {
          return true;
        }
        return false;
      })
    ) {
      formMethods.setError("enableAlertChannelMessages", {
        type: "manual",
        message:
          "Please select a channel to send alerts to, or turn off alert channel messages",
      });
      return;
    }

    onSubmit(data);
  }, [onSubmit, formMethods]);

  const prettyPrimaryCommsPlatform =
    prettyPrintCommsPlatform(primaryCommsPlatform);

  return (
    <ExpressionsMethodsProvider<ChannelMessagesFormData, "expressions">
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
      showExpressionNames={false}
    >
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <DrawerTitle
            title={`Send alerts to ${prettyPrimaryCommsPlatform}`}
            onClose={onClose}
            icon={
              primaryCommsPlatform === CommsPlatform.Slack
                ? IconEnum.Slack
                : IconEnum.MicrosoftTeams
            }
            iconClassName={"bg-slate-surface"}
            compact
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerBody className="grow">
            <Form.Root
              id="alert-routes-channel-messages"
              fullHeight
              formMethods={formMethods}
              onSubmit={validateAndSubmit}
              innerClassName={"space-y-6"}
            >
              <Prompt
                when={formMethods.formState.isDirty}
                message={
                  "Your changes have not been saved. Are you sure you want to navigate away?"
                }
              />
              <div className="text-sm text-content-secondary">
                Use this route to send alerts to {prettyPrimaryCommsPlatform}.
                You can apply filters or use expressions to direct specific
                alerts to the appropriate destinations.
              </div>
              <BooleanRadioButtonGroupV2
                trueOption={{
                  label: "Yes",
                }}
                falseOption={{
                  label: "No",
                }}
                name="enableAlertChannelMessages"
                label={`Send alerts to ${prettyPrimaryCommsPlatform}`}
                formMethods={formMethods}
                srLabel="Enable alerts channel messages"
                boxed
              />
              <AnimatePresence>
                {enableAlertChannelMessages && (
                  <motion.div
                    className="space-y-6 rounded-b-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {channelConfigs.map((_, index) => {
                      // For Teams, we always use "public" for channel_visibility
                      const visibility =
                        primaryCommsPlatform === CommsPlatform.Slack
                          ? formMethods.watch<`channelConfigs.${number}.slack_targets.channel_visibility`>(
                              `channelConfigs.${index}.slack_targets.channel_visibility`,
                            )
                          : "public";

                      const conditionGroups =
                        formMethods.watch<`channelConfigs.${number}.condition_groups`>(
                          `channelConfigs.${index}.condition_groups`,
                        );

                      return (
                        <div key={index} className={"flex flex-col gap-6"}>
                          <div className={"flex flex-col gap-4"}>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm-bold">Destination</span>
                              <div className="flex flex-row relative shadow-sm hover:shadow-none rounded-lg">
                                {primaryCommsPlatform ===
                                  CommsPlatform.Slack && (
                                  <StaticSingleSelectV2<ChannelMessagesFormData>
                                    isSearchable={false}
                                    name={`channelConfigs.${index}.slack_targets.channel_visibility`}
                                    options={[
                                      {
                                        label: "Public",
                                        value: "public",
                                        icon: IconEnum.SlackChannel,
                                      },
                                      {
                                        label: "Private",
                                        value: "private",
                                        icon: IconEnum.LockClosed,
                                      },
                                    ]}
                                    onValueChange={() => {
                                      if (visibility === "public") {
                                        formMethods.setValue<`channelConfigs.${number}.slack_targets.binding`>(
                                          `channelConfigs.${index}.slack_targets.binding`,
                                          { array_value: [] },
                                        );
                                      } else {
                                        formMethods.setValue<`channelConfigs.${number}.slack_targets.binding`>(
                                          `channelConfigs.${index}.slack_targets.binding`,
                                          { value: undefined },
                                        );
                                      }
                                    }}
                                    formMethods={formMethods}
                                    className="group relative [&_div]:shadow-none [&_div]:rounded-r-none [&>div]:relative [&>div]:z-[1] focus-within:[&>div]:z-[2]"
                                  />
                                )}
                                {/* For Teams, visibility is always public regardless of what's in the form state.
                                      For Slack, we respect the public/private toggle */}
                                {primaryCommsPlatform ===
                                  CommsPlatform.MSTeams ||
                                visibility === "public" ? (
                                  <EngineFormElement
                                    name={
                                      primaryCommsPlatform ===
                                      CommsPlatform.Slack
                                        ? `channelConfigs.${index}.slack_targets.binding`
                                        : `channelConfigs.${index}.ms_teams_targets.binding`
                                    }
                                    resourceType={
                                      primaryCommsPlatform ===
                                      CommsPlatform.Slack
                                        ? 'CatalogEntry["SlackChannel"]'
                                        : 'CatalogEntry["MicrosoftTeamsChannel"]'
                                    }
                                    array={true} // If visibility is public, the backend should always return us True.
                                    key={"channel-binding-input"}
                                    expressionLabelOverride={`Destination ${
                                      index + 1
                                    }`}
                                    resources={resources}
                                    scope={scopeWithExpressions}
                                    showPlaceholder
                                    mode="variables_and_expressions"
                                    className={tcx(
                                      "group relative [&_div]:shadow-none flex-1 [&>div]:relative [&>div]:z-[1] focus-within:[&>div]:z-[2]",
                                      primaryCommsPlatform ===
                                        CommsPlatform.Slack
                                        ? "[&_div]:rounded-l-none ml-[-1px]"
                                        : "",
                                    )}
                                    required={false}
                                  />
                                ) : (
                                  <InputV2
                                    formMethods={formMethods}
                                    placeholder={"Private channel ID"}
                                    name={
                                      primaryCommsPlatform ===
                                      CommsPlatform.Slack
                                        ? `channelConfigs.${index}.slack_targets.binding.value.literal`
                                        : `channelConfigs.${index}.ms_teams_targets.binding.value.literal`
                                    }
                                    className={
                                      "grow group relative ml-[-1px] flex-1 hover:shadow-xs"
                                    }
                                    inputClassName={
                                      "relative rounded-l-none z-[1] focus-within:z-[2]"
                                    }
                                  />
                                )}
                              </div>
                              {visibility === "private" &&
                                primaryCommsPlatform ===
                                  CommsPlatform.Slack && (
                                  <span
                                    className={
                                      "text-xs-med text-content-tertiary"
                                    }
                                  >
                                    The channel ID can be found at the bottom of
                                    channel settings in Slack.
                                  </span>
                                )}
                            </div>
                            {visibility === "private" &&
                              primaryCommsPlatform === CommsPlatform.Slack && (
                                <Callout theme={CalloutTheme.Warning}>
                                  Anyone viewing this alert route will be able
                                  to see the name of this private channel.
                                </Callout>
                              )}
                          </div>

                          <AlertRouteConditionsEditor
                            name={`channelConfigs.${index}.condition_groups`}
                            conditionGroups={conditionGroups || []}
                            scope={scope}
                            introText={`Apply to all alerts`}
                            formMethods={formMethods}
                            expressions={expressionMethods.fields}
                            allowFilteringByExpression={false}
                          />
                          <div className={"flex flex-row"}>
                            {index === channelConfigs.length - 1 && (
                              <Button
                                theme={ButtonTheme.Unstyled}
                                icon={IconEnum.Add}
                                onClick={handleAddChannel}
                                analyticsTrackingId={
                                  "add-another-pulse-channel"
                                }
                                className={"text-content-secondary text-sm-med"}
                              >
                                Add another destination
                              </Button>
                            )}
                            <div className={"grow"} />
                            {channelConfigs.length > 1 && (
                              <Button
                                onClick={() => handleRemoveChannel(index)}
                                analyticsTrackingId={"delete-pulse-channel"}
                                theme={ButtonTheme.Unstyled}
                                className={"text-content-destroy text-sm-med"}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                          {channelConfigs.length > 1 &&
                            index < channelConfigs.length - 1 && (
                              <div className="h-px border-t border-stroke-secondary" />
                            )}
                        </div>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </Form.Root>
          </DrawerBody>
          <DrawerFooter className="flex gap-2 justify-end">
            <Button onClick={() => onClose()} analyticsTrackingId={null}>
              Back
            </Button>
            <GatedButton
              form="alert-routes-channel-messages"
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              type="submit"
              theme={ButtonTheme.Primary}
              analyticsTrackingId="alert-routes-pulse-save"
            >
              Apply
            </GatedButton>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};
