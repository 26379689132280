import { AlertRouteSlim, AlertSourceConfig } from "@incident-io/api";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  Position,
} from "reactflow";
import { tcx } from "src/utils/tailwind-classes";

import { getColor } from "../../../utils/twConfig";
import { useReduceOpacity } from "./AlertsConfigurationPage";

export interface AlertsConfigurationEdgeData {
  alertSource?: AlertSourceConfig;
  alertRoute?: AlertRouteSlim;
  colorBucket?: number;
}

export const AlertsConfigurationEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: AlertsConfigurationEdgeData;
}) => {
  const reduceOpacity = useReduceOpacity({ edgeId: id });

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    // We add a small offset to the sourceY to create an invisible curvature to
    // the line that prevents it from disappearing when the source and target
    // nodes are aligned vertically
    //
    // This stackoverflow post explains the issue, but essentially linear
    // gradient uses an Object Bounding Box which does not support zero height
    // elements (like a line with the same source and target Y).
    //
    // https://stackoverflow.com/questions/73043945/why-does-a-svg-line-disappear-when-i-apply-a-svg-lineargradient
    //
    sourceY: sourceY === targetY ? sourceY + 0.001 : sourceY,
    targetX,
    targetY,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    curvature: 0.1,
  });

  const edgeColor = {
    healthy: [
      getColor("green", "600"),
      getColor("green", "500"),
      getColor("green", "400"),
      getColor("green", "300"),
      getColor("green", "200"),
    ],
    never_fired: getColor("slate", "100"),
    fade: getColor("slate", "50"),
  };

  const alertSourceHasFired = data?.alertSource?.alert_last_fired_at;

  // Determine the stroke color based on your condition
  const strokeColor = !alertSourceHasFired
    ? edgeColor.never_fired
    : edgeColor.healthy[data?.colorBucket || 0];

  // Generate a unique gradient ID for each edge
  const gradientId = `gradient-${id}`;

  return (
    <>
      <g className="relative">
        <svg>
          <defs>
            {/* Define the gradient for unconnected sources */}
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={strokeColor} />
              <stop offset="100%" stopColor={edgeColor.fade} />
            </linearGradient>
          </defs>

          <BaseEdge
            id={id}
            path={edgePath}
            style={{
              stroke: data?.alertRoute ? strokeColor : `url(#${gradientId})`,
              strokeWidth: "4px",
              strokeOpacity: reduceOpacity ? 0.2 : 1,
            }}
          />
        </svg>
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              left: labelX,
              top: labelY,
              // Center the div on this point
              transform: "translate(-50%, -50%)",
              pointerEvents: "all",
              zIndex: 100,
            }}
            className={tcx("rounded py-0.5 px-1", "nodrag nopan")}
          />
        </EdgeLabelRenderer>
      </g>
    </>
  );
};
