import { usePylon } from "@bolasim/react-use-pylon";
import {
  CatalogEntry,
  CatalogTypeColorEnum,
  CatalogTypeIconEnum,
  StatusPagePageTypeEnum,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { Form } from "@incident-shared/forms";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { FadeInOut } from "@incident-shared/utils/FadeInOut";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  SearchBar,
  Tooltip,
  Txt,
} from "@incident-ui";
import { SelectOptions } from "@incident-ui/Select/types";
import _ from "lodash";
import pluralize, { singular } from "pluralize";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getCatalogTypeOptionGroups } from "src/components/settings/custom-fields/common/getCatalogTypeOptionGroups";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { usePageType } from "../../common/PageTypeContext";
import { statusPageDefaultDomain } from "../../common/utils/utils";
import { SubPageComponentsForm } from "../edit/SubPageEditComponentsForm";

export type AutoCreateComponentForm = {
  mode: "auto_create";
  auto_create_components: { name: string; id: string }[];
  auto_create_sub_pages: {
    [id: string]: {
      name: string;
      subpath: string;
      components: string[];
      allowed_email_domains: string[];
      enabled: true;
    };
  };
};

export type CatalogImportComponentForm = {
  mode: "catalog_import";
  split_by_catalog_type_id: string;
  components_defined_by_catalog_attribute_id: string;
  group_by_defined_by_catalog_attribute_id?: string;
  email_whitelist_defined_by_attribute_id?: string;
  catalog_import_sub_pages: {
    [catalogEntryId: string]: {
      name: string;
      subpath: string;
      enabled: boolean;
      entry: CatalogEntry;
    };
  };
};

export type PageWithSubPageCreationForm = {
  name: string;
  subpath: string;
  parent_page_type: StatusPagePageTypeEnum;
  reason_for_split: string;
} & (AutoCreateComponentForm | CatalogImportComponentForm);

export const MAX_SUB_PAGES = 100;

export const SubPageCreationForm = (): React.ReactElement => {
  const type = usePageType();
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const parentFormMethods = useFormContext<PageWithSubPageCreationForm>();
  const catalogImportFormMethods = useFormContext<CatalogImportComponentForm>();

  const {
    data: { catalog_types: catalogTypes },
    isLoading: isLoadingTypes,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const splitByCatalogTypeId = catalogImportFormMethods.watch(
    "split_by_catalog_type_id",
  );

  const pageType = parentFormMethods.watch("parent_page_type");

  const { data } = useAPI(splitByCatalogTypeId ? "catalogListEntries" : null, {
    catalogTypeId: splitByCatalogTypeId as string,
    pageSize: 20,
    search: "",
    includeReferences: true,
  });
  const [componentsDefinedByCatalogAttributeId, catalogImportSubPages] =
    catalogImportFormMethods.watch([
      "components_defined_by_catalog_attribute_id",
      "catalog_import_sub_pages",
    ]);

  const [catalogTypeOptions, setCatalogTypeOptions] = useState<SelectOptions>(
    type === StatusPagePageTypeEnum.Parent
      ? [
          {
            value: "Region",
            label: "Regions",
          },
          {
            value: "Product",
            label: "Products",
          },
        ]
      : [
          {
            value: "Customer",
            label: "Customers",
          },
        ],
  );

  const addNewReasonForSplitting = (inputValue: string) => {
    // convert the value to singular, as we want to create a singular name catalog type
    const catalogTypeName = singular(inputValue);
    const newOption = {
      value: catalogTypeName,
      label: inputValue,
    };
    setCatalogTypeOptions([...catalogTypeOptions, newOption]);
    parentFormMethods.setValue<"reason_for_split">(
      "reason_for_split",
      catalogTypeName,
    );
  };

  const first20Entries = data?.catalog_entries;

  const { setValue } = catalogImportFormMethods;
  const totalNumberOfEntries = data?.pagination_meta.total_record_count;

  // we do this because we need to set some default values for the sub-pages
  // in an async way, we can't do that in onValueChange because it's not async
  // and we can't await values there
  useEffect(() => {
    // We only want to pre-populate the form state with entries if there are
    // 20 or fewer
    if (totalNumberOfEntries && totalNumberOfEntries <= 20) {
      first20Entries?.forEach((entry: CatalogEntry) => {
        setValue<`catalog_import_sub_pages.${typeof entry.id}.name`>(
          `catalog_import_sub_pages.${entry.id}.name`,
          entry.name,
        );
        setValue<`catalog_import_sub_pages.${typeof entry.id}.subpath`>(
          `catalog_import_sub_pages.${entry.id}.subpath`,
          _.kebabCase(entry.name.toLowerCase()),
        );
        setValue<`catalog_import_sub_pages.${typeof entry.id}.enabled`>(
          `catalog_import_sub_pages.${entry.id}.enabled`,
          true,
        );
        setValue<`catalog_import_sub_pages.${typeof entry.id}.entry`>(
          `catalog_import_sub_pages.${entry.id}.entry`,
          entry,
        );
      });
    }
  }, [totalNumberOfEntries, first20Entries, setValue]);

  const [mode, reasonForSplit] = parentFormMethods.watch([
    "mode",
    "reason_for_split",
  ]);

  const catalogTypeOptionGroups = getCatalogTypeOptionGroups(catalogTypes);

  const noEntries = first20Entries?.length === 0;

  const isCustomerPage = pageType === StatusPagePageTypeEnum.Customer;
  const isParentPage = pageType === StatusPagePageTypeEnum.Parent;
  const isAutoCreateMode = mode === "auto_create";
  const isCatalogImportMode = mode === "catalog_import";

  const numberOfSelectedCatalogEntries = Object.values(
    catalogImportSubPages || {},
  ).filter((page) => page.enabled).length;

  return (
    <div className="space-y-4">
      <div className="space-y-6">
        <Txt grey>
          {isCustomerPage ? (
            <>
              Create multiple secured status pages with shared components, to
              keep your customers informed about issues relevant to them.
              Customer pages are powered by{" "}
              <Button
                onClick={() => showArticle("2606187127")}
                theme={ButtonTheme.Link}
                analyticsTrackingId={"settings-using-catalog"}
              >
                Catalog
              </Button>
            </>
          ) : (
            <>
              Create multiple public status pages with shared components, to
              keep your customers informed about issues relevant to them.
              Sub-pages are powered by{" "}
              <Button
                onClick={() => showArticle("2606187127")}
                theme={ButtonTheme.Link}
                analyticsTrackingId={"settings-using-catalog"}
              >
                Catalog
              </Button>
            </>
          )}
          .
        </Txt>
      </div>
      <>
        {isParentPage && (
          <StaticSingleSelectV2
            label="What type of pages do you want to create?"
            options={catalogTypeOptions}
            name={"reason_for_split"}
            allowAdding={true}
            onCreateOption={addNewReasonForSplitting}
            formMethods={parentFormMethods}
          />
        )}
        <RadioButtonGroupV2
          formMethods={parentFormMethods}
          name="mode"
          srLabel="Create a catalog type?"
          required
          horizontal
          boxed
          options={[
            {
              label: `Create a new ${
                type === StatusPagePageTypeEnum.Parent
                  ? `${reasonForSplit.toLowerCase()} `
                  : ""
              }catalog type`,
              value: "auto_create",
            },
            {
              label: `Use an existing ${
                type === StatusPagePageTypeEnum.Parent
                  ? `${reasonForSplit.toLowerCase()} `
                  : ""
              }catalog type`,
              value: "catalog_import",
            },
          ]}
        />
      </>
      {isCatalogImportMode && (
        <>
          <StaticSingleSelectV2
            formMethods={catalogImportFormMethods}
            label={`Which catalog type defines the ${pluralize(
              reasonForSplit.toLowerCase(),
            )} you'd like to create?`}
            inputClassName="!mt-1"
            helptext={
              <div>
                See our{" "}
                <Button
                  onClick={() => showArticle("2059210486")}
                  analyticsTrackingId="configure-catalog-type-help-center-link"
                  theme={ButtonTheme.Link}
                >
                  help center
                </Button>{" "}
                for more information on catalog types.
              </div>
            }
            placeholder={"Select a type"}
            isLoading={isLoadingTypes}
            options={catalogTypeOptionGroups}
            name="split_by_catalog_type_id"
            required={"You must select a catalog type"}
            onValueChange={() => {
              if (componentsDefinedByCatalogAttributeId !== undefined) {
                catalogImportFormMethods.setValue(
                  `components_defined_by_catalog_attribute_id`,
                  undefined as unknown as string,
                ); // you can always set undefined, but rhf doesn't seem to be typed quite right
              }

              // We also need to reset the `sub_pages` to be based on the new catalog type
              if (catalogImportSubPages !== undefined) {
                catalogImportFormMethods.setValue(
                  "catalog_import_sub_pages",
                  {},
                );
              }
            }}
          />
          {noEntries ? (
            <Callout theme={CalloutTheme.Danger}>
              This catalog type has no entries. Please choose a type with one or
              more entries.
            </Callout>
          ) : null}
          <SubPageComponentsForm
            numberOfCatalogEntries={numberOfSelectedCatalogEntries}
          />
          <CatalogImportSubPageEditor
            totalNumberOfEntries={totalNumberOfEntries}
          />
        </>
      )}
      {isAutoCreateMode && (
        <>
          <SubPageComponentsForm />
          <AutoCreateSubPageEditor />
        </>
      )}
    </div>
  );
};

const CatalogImportSubPageEditor = ({
  totalNumberOfEntries,
}: {
  totalNumberOfEntries?: number;
}): React.ReactElement => {
  const componentFormMethods = useFormContext<CatalogImportComponentForm>();

  const { register, watch } = componentFormMethods;
  const pageType =
    usePageType() === StatusPagePageTypeEnum.Parent
      ? "sub-page"
      : "customer page";

  useEffect(() => {
    register("catalog_import_sub_pages", {
      validate: (subPages) => {
        // validate that one sub page is created
        if (
          !(
            subPages &&
            Object.values(subPages).some((page) => page && page.enabled)
          )
        ) {
          return `You must create at least one ${pageType}.`;
        }

        // validate that sub-pages have distinct subpaths
        if (
          _.uniq(Object.values(subPages).map((page) => page?.subpath))
            .length !== Object.values(subPages).length
        ) {
          return `Each ${pageType} must have a unique URL.`;
        }

        // validate that sub-pages have distinct names
        if (
          _.uniq(Object.values(subPages).map((page) => page?.name)).length !==
          Object.values(subPages).length
        ) {
          return `Each ${pageType} must have a unique name.`;
        }

        return true;
      },
    });
  }, [register, pageType]);

  const [splitByCatalogTypeId, catalogImportSubPages] = watch([
    "split_by_catalog_type_id",
    "catalog_import_sub_pages",
  ]);

  const catalogEntries = Object.values(catalogImportSubPages || {}).map(
    (value) => value.entry,
  );

  const noEntries = catalogEntries.length === 0;

  const { data: typeData } = useAPI(
    splitByCatalogTypeId ? "catalogShowType" : null,
    {
      id: splitByCatalogTypeId as string,
    },
  );

  const catalogType = typeData?.catalog_type;
  const lotsOfEntries = totalNumberOfEntries && totalNumberOfEntries > 20;

  const alreadyAddedEntryIds = Object.keys(catalogImportSubPages || {});
  const numberOfSelectedCatalogEntries = Object.values(
    catalogImportSubPages || {},
  ).filter((page) => page.enabled).length;
  const disableAddingSubPages = numberOfSelectedCatalogEntries >= MAX_SUB_PAGES;
  const addEntry = (entry: CatalogEntry) => {
    componentFormMethods.setValue<`catalog_import_sub_pages.${typeof entry.id}.name`>(
      `catalog_import_sub_pages.${entry.id}.name`,
      entry.name,
    );
    componentFormMethods.setValue<`catalog_import_sub_pages.${typeof entry.id}.subpath`>(
      `catalog_import_sub_pages.${entry.id}.subpath`,
      _.kebabCase(entry.name.toLowerCase()),
    );
    componentFormMethods.setValue<`catalog_import_sub_pages.${typeof entry.id}.enabled`>(
      `catalog_import_sub_pages.${entry.id}.enabled`,
      true,
    );
    componentFormMethods.setValue<`catalog_import_sub_pages.${typeof entry.id}.entry`>(
      `catalog_import_sub_pages.${entry.id}.entry`,
      entry,
    );
  };

  return !noEntries || lotsOfEntries ? (
    <>
      <Form.InputWrapper name="catalog_import_sub_pages">
        <h3 className="font-medium mb-4">Pages</h3>
        {lotsOfEntries && (
          <CatalogImportEntrySearch
            catalogTypeId={splitByCatalogTypeId}
            alreadyAddedEntryIds={alreadyAddedEntryIds}
            disableAddingSubPages={disableAddingSubPages}
            addEntry={addEntry}
          />
        )}
        <div className="space-y-4">
          {catalogEntries.map((entry) => (
            <SubPageRow
              subPageId={entry.id}
              key={entry.id}
              catalogType={
                catalogType || {
                  color: CatalogTypeColorEnum.Green,
                  icon: CatalogTypeIconEnum.Doc,
                }
              }
              catalogEntry={entry}
            />
          ))}
        </div>
      </Form.InputWrapper>
    </>
  ) : (
    <></>
  );
};

export const CatalogImportEntrySearch = ({
  catalogTypeId,
  alreadyAddedEntryIds,
  disableAddingSubPages,
  addEntry,
}: {
  catalogTypeId: string;
  alreadyAddedEntryIds: string[];
  disableAddingSubPages: boolean;
  addEntry: (entry: CatalogEntry) => void;
}) => {
  const [search, setSearch] = useState("");

  const { data, error, isLoading } = useAPI("catalogListEntries", {
    catalogTypeId: catalogTypeId,
    pageSize: 10,
    search: search,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className="flex flex-col rounded-2 outline outline-slate-200 mb-4 w-full bg-surface-primary">
      <SearchBar
        id="search"
        value={search}
        onChange={setSearch}
        className="w-full"
        placeholder="Search for catalog entries to create pages for"
      />
      {isLoading && <Loader />}
      {!isLoading && search !== "" && (
        <div className="flex flex-col gap-2 p-2">
          {data?.catalog_entries.map((entry) => {
            return (
              <CatalogEntrySearchResultRow
                key={`entry-search-result-row-${entry.id}`}
                entry={entry}
                alreadyAdded={alreadyAddedEntryIds.includes(entry.id)}
                disableAddingSubPages={disableAddingSubPages}
                addEntry={addEntry}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const CatalogEntrySearchResultRow = ({
  entry,
  alreadyAdded,
  disableAddingSubPages,
  addEntry,
}: {
  entry: CatalogEntry;
  alreadyAdded: boolean;
  disableAddingSubPages: boolean;
  addEntry: (CatalogEntry) => void;
}) => {
  const { data } = useAPI("catalogShowType", {
    id: entry.catalog_type_id,
  });

  return (
    <div className="flex gap-2">
      <CatalogEntryBadge
        color={data?.catalog_type?.color}
        icon={data?.catalog_type?.icon}
        label={entry.name}
        size={BadgeSize.Small}
      />
      {alreadyAdded ? (
        <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
          Added
        </Badge>
      ) : (
        <GatedButton
          onClick={() => addEntry(entry)}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={null}
          size={BadgeSize.Small}
          icon={IconEnum.Add}
          disabled={disableAddingSubPages}
          disabledTooltipContent={`You can only select up to ${MAX_SUB_PAGES} sub-pages`}
        >
          Add
        </GatedButton>
      )}
    </div>
  );
};

const AutoCreateSubPageEditor = () => {
  const formMethods = useFormContext<AutoCreateComponentForm>();
  const { watch, setValue } = formMethods;
  const subPagesWatch = watch("auto_create_sub_pages");
  const subPages = subPagesWatch || {};

  const createNewSubPage = () => {
    const id = `${Date.now()}`;
    setValue("auto_create_sub_pages", {
      ...subPages,
      [id]: {
        name: "",
        subpath: "",
        components: [],
        allowed_email_domains: [],
        enabled: true,
      },
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="font-medium">Pages</h3>
      {Object.entries(subPages) &&
        Object.entries(subPages).map((subPage, idx) => (
          <SubPageRow
            subPageId={subPage[0]}
            key={idx}
            catalogType={{
              color: CatalogTypeColorEnum.Green,
              icon: CatalogTypeIconEnum.Doc,
            }}
          />
        ))}
      <div className="flex justify-start">
        <Button
          onClick={createNewSubPage}
          icon={IconEnum.Add}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={"add-another-page"}
        >
          Add page
        </Button>
      </div>
    </div>
  );
};

const SubPageRowCatalogBadge = ({
  setExpanded,
  subPageId,
  catalogType,
  catalogEntry,
}: {
  setExpanded: (expanded: boolean) => void;
  subPageId: string;
  catalogType: {
    color: CatalogTypeColorEnum;
    icon: CatalogTypeIconEnum;
  };
  catalogEntry?: CatalogEntry;
}) => {
  const parentFormMethods = useFormContext<PageWithSubPageCreationForm>();
  const mode = parentFormMethods.watch("mode");
  const subPage =
    mode === "auto_create"
      ? parentFormMethods.watch(`auto_create_sub_pages.${subPageId}`)
      : parentFormMethods.watch(`catalog_import_sub_pages.${subPageId}`);
  const isCatalogImportMode = mode === "catalog_import";
  const isAutoCreateMode = mode === "auto_create";

  return (
    <>
      {isCatalogImportMode && (
        <CheckboxV2
          formMethods={parentFormMethods}
          onValueChange={(checked) => {
            if (checked) {
              setExpanded(true);
            } else {
              setExpanded(false);
            }
          }}
          label={
            <CatalogEntryBadge
              color={catalogType?.color}
              icon={catalogType?.icon}
              label={catalogEntry?.name}
              size={BadgeSize.Small}
            />
          }
          name={`catalog_import_sub_pages.${catalogEntry?.id}.enabled`}
        />
      )}
      {isAutoCreateMode && (
        <CatalogEntryBadge
          color={catalogType?.color}
          icon={catalogType?.icon}
          label={subPage.name}
          size={BadgeSize.Small}
        />
      )}
    </>
  );
};

const SubPageDetailsConfiguration = ({
  subPageId,
  catalogEntry,
}: {
  subPageId: string;
  catalogEntry?: {
    name: string;
    id: string;
  };
}) => {
  const formMethods = useFormContext<PageWithSubPageCreationForm>();
  const [mode, parentName, parentSubpath] = formMethods.watch([
    "mode",
    "name",
    "subpath",
  ]);
  const catalogImportFormMethods = useFormContext<CatalogImportComponentForm>();
  const autoCreateFormMethods = useFormContext<AutoCreateComponentForm>();
  const isCatalogImportMode = mode === "catalog_import";
  const isAutoCreateMode = mode === "auto_create";
  return (
    <>
      {isCatalogImportMode && (
        <>
          <InputV2
            inputClassName="bg-white"
            onValueChange={(value) => {
              if (!value) {
                catalogImportFormMethods.setValue(
                  `catalog_import_sub_pages.${subPageId}.name`,
                  "",
                );
              } else {
                const subpathTouched =
                  catalogImportFormMethods.formState?.touchedFields
                    ?.catalog_import_sub_pages?.[subPageId].subpath;

                if (!subpathTouched) {
                  const subpath = _.kebabCase(value.toLowerCase());
                  catalogImportFormMethods.setValue(
                    `catalog_import_sub_pages.${subPageId}.subpath`,
                    subpath,
                  );
                }
              }
            }}
            label="Page name"
            formMethods={catalogImportFormMethods}
            name={`catalog_import_sub_pages.${subPageId}.name`}
            defaultValue={catalogEntry?.name}
            inputPrefixNode={
              <label
                htmlFor="name"
                className="text-slate-400 mr-[4px] mt-[2px] whitespace-nowrap"
              >
                {parentName} /{" "}
              </label>
            }
          />
          <InputV2
            className="flex-1"
            inputClassName="bg-white"
            label="URL"
            onValueChange={(value) => {
              if (!value) {
                catalogImportFormMethods.setValue(
                  `catalog_import_sub_pages.${subPageId}.subpath`,
                  "",
                );
              }
            }}
            formMethods={catalogImportFormMethods}
            name={`catalog_import_sub_pages.${subPageId}.subpath`}
            defaultValue={_.kebabCase(catalogEntry?.name.toLowerCase())}
            inputPrefixNode={
              <label
                htmlFor="subpath"
                className="text-slate-400 mt-[2px] whitespace-nowrap"
              >
                https://{statusPageDefaultDomain()}/{parentSubpath}/
              </label>
            }
          />
        </>
      )}
      {isAutoCreateMode && (
        <>
          <InputV2
            inputClassName="bg-white"
            label="Page name"
            formMethods={autoCreateFormMethods}
            name={`auto_create_sub_pages.${subPageId}.name`}
            required
            onValueChange={(value) => {
              const subpathTouched =
                autoCreateFormMethods.formState?.touchedFields
                  ?.auto_create_sub_pages?.[subPageId]?.subpath;

              if (value && !subpathTouched) {
                const subpath = _.kebabCase(value.toLowerCase());
                autoCreateFormMethods.setValue(
                  `auto_create_sub_pages.${subPageId}.subpath`,
                  subpath,
                );
              }
            }}
            defaultValue={catalogEntry?.name}
            inputPrefixNode={
              <label
                htmlFor="name"
                className="text-slate-400 mr-[4px] mt-[2px] whitespace-nowrap"
              >
                {parentName} /{" "}
              </label>
            }
          />
          <InputV2
            className="flex-1"
            inputClassName="bg-white"
            label="URL"
            formMethods={autoCreateFormMethods}
            name={`auto_create_sub_pages.${subPageId}.subpath`}
            defaultValue={_.kebabCase(catalogEntry?.name.toLowerCase())}
            required
            rules={{
              validate: (value) => {
                // validate that sub-pages have valid subpaths
                if (!/^[a-z]+([:-]?[a-z0-9]+)*$/i.test(value || "")) {
                  return "Only lowercase letters, numbers, and hyphens are allowed.";
                }
                return undefined;
              },
            }}
            inputPrefixNode={
              <label
                htmlFor="subpath"
                className="text-slate-400 mt-[2px] whitespace-nowrap"
              >
                https://{statusPageDefaultDomain()}/{parentSubpath}/
              </label>
            }
          />
        </>
      )}
    </>
  );
};

const SubPageComponentConfiguration = ({
  subPageId,
  catalogType,
}: {
  subPageId: string;
  catalogType: {
    color: CatalogTypeColorEnum;
    icon: CatalogTypeIconEnum;
  };
}) => {
  const formMethods = useFormContext<PageWithSubPageCreationForm>();
  const autoCreateFormMethods = useFormContext<AutoCreateComponentForm>();

  const [mode, splitByCatalogTypeId, splitByAttributeId] = formMethods.watch([
    "mode",
    "split_by_catalog_type_id",
    "components_defined_by_catalog_attribute_id",
  ]);

  const { data: structureLayoutData } = useAPI(
    splitByCatalogTypeId && splitByAttributeId
      ? "statusPageBuildSubPageStructure"
      : null,
    {
      parentSplitByCatalogTypeId: splitByCatalogTypeId as string,
      parentSplitByComponentAttributeId: splitByAttributeId as string,
      definedByCatalogEntryIds: [subPageId],
    },
  );

  const structure = structureLayoutData?.sub_page_structures?.[subPageId];

  const autoCreateComponents = autoCreateFormMethods.watch(
    "auto_create_components",
  );

  const isCatalogImportMode = mode === "catalog_import";
  const isAutoCreateMode = mode === "auto_create";

  return (
    <>
      {isCatalogImportMode && (
        <>
          <p className="font-medium text-content-primary text-sm">Components</p>
          <div className="w-full p-2 rounded-2 bg-white border border-stroke inline-flex items-center flex-wrap gap-2">
            {!structure?.items || structure?.items.length === 0 ? (
              <span className="text-slate-400 text-sm mt-[2px]">
                Select a component attribute
              </span>
            ) : null}
            {structure?.items.map(({ component, group }) => {
              if (component) {
                return (
                  <CatalogEntryBadge
                    className="max-w-[200px] truncate ..."
                    key={component.component_id}
                    color={catalogType?.color}
                    icon={catalogType?.icon}
                    label={component?.name}
                    size={BadgeSize.Small}
                  />
                );
              }

              if (group) {
                return (
                  <div key={group.id}>
                    <Tooltip
                      side={"right"}
                      content={
                        <div
                          className={
                            "flex flex-col gap-y-1 text-content-primary"
                          }
                        >
                          {group.components.map((component) => (
                            <CatalogEntryBadge
                              key={component.component_id}
                              color={catalogType?.color}
                              icon={catalogType?.icon}
                              label={component.name}
                              size={BadgeSize.Small}
                            />
                          ))}
                        </div>
                      }
                      bubbleProps={{
                        className:
                          "!bg-white !border !border-stroke !shadow-none",
                      }}
                    >
                      <div className="cursor-pointer truncate">
                        <CatalogEntryBadge
                          color={catalogType?.color}
                          icon={catalogType?.icon}
                          label={group.name}
                          groupIcon={true}
                          size={BadgeSize.Small}
                        />
                      </div>
                    </Tooltip>
                  </div>
                );
              }
              return undefined;
            })}
          </div>
        </>
      )}
      {isAutoCreateMode && autoCreateComponents && (
        <StaticMultiSelectV2
          formMethods={autoCreateFormMethods}
          label={"Components"}
          options={(autoCreateComponents || [])
            .filter((component) => component.id && component.name !== "")
            .map((component) => ({
              label: component.name,
              value: component.id,
            }))}
          name={`auto_create_sub_pages.${subPageId}.components`}
          required
        />
      )}
    </>
  );
};

const SubPageAllowedEmailDomainConfiguration = ({
  subPageId,
}: {
  subPageId: string;
}) => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const formMethods = useFormContext<PageWithSubPageCreationForm>();
  const mode = formMethods.watch("mode");
  const autoCreateFormMethods = useFormContext<AutoCreateComponentForm>();
  const catalogImportFormMethods = useFormContext<CatalogImportComponentForm>();
  const subPage =
    mode === "auto_create"
      ? formMethods.watch(`auto_create_sub_pages.${subPageId}`)
      : formMethods.watch(`catalog_import_sub_pages.${subPageId}`);

  const { data: catalogEntryData } = useAPI(
    mode === "catalog_import" ? "catalogShowEntry" : null,
    {
      id: subPageId,
    },
  );

  const whitelistDefinedByAttributeId = catalogImportFormMethods.watch(
    "email_whitelist_defined_by_attribute_id",
  );

  const allowedDomainsForPage = (): string[] => {
    const entry = catalogEntryData?.catalog_entry;
    if (!entry || !whitelistDefinedByAttributeId) {
      return [];
    }

    if (entry?.attribute_values?.[whitelistDefinedByAttributeId]?.value) {
      if (
        !entry.attribute_values[whitelistDefinedByAttributeId].value?.literal
      ) {
        return [];
      }
      return [
        entry.attribute_values[whitelistDefinedByAttributeId].value?.literal ||
          "",
      ];
    } else if (
      entry?.attribute_values?.[whitelistDefinedByAttributeId]?.array_value
    ) {
      return (
        entry.attribute_values[whitelistDefinedByAttributeId].array_value?.map(
          (value) => value.literal || "",
        ) || []
      );
    } else {
      return [];
    }
  };

  const allowedEmailDomainsForPage =
    mode === "catalog_import"
      ? allowedDomainsForPage()
      : autoCreateFormMethods.watch(
          `auto_create_sub_pages.${subPageId}.allowed_email_domains`,
        );

  const isCatalogImportMode = mode === "catalog_import";
  const isAutoCreateMode = mode === "auto_create";

  return (
    <>
      {isCatalogImportMode && (
        <>
          <p className="font-medium text-content-primary text-sm">
            Email Domains
          </p>
          {allowedDomainsForPage()?.length > 0 && (
            <div className="w-full p-2 rounded-2 bg-white border border-stroke inline-flex items-center flex-wrap gap-2">
              {allowedDomainsForPage()?.map((domain) => (
                <Badge theme={BadgeTheme.Primary} key={domain}>
                  <span>{`@${domain}`}</span>
                </Badge>
              ))}
            </div>
          )}
          {allowedDomainsForPage()?.length === 0 && (
            <div className="w-full p-2 rounded-2 bg-white border border-stroke inline-flex items-center flex-wrap gap-2">
              <span className="text-slate-400 text-sm">
                Select an email domain catalog attribute.
              </span>
            </div>
          )}
        </>
      )}
      {isAutoCreateMode && (
        <>
          <DynamicMultiSelectV2
            formMethods={autoCreateFormMethods}
            helptext={`Set the email domains that will give ${
              subPage?.name || "your customer"
            } access to this customer status page. If left empty, no one will be able to access the page.`}
            label={<span className="flex items-center">Domain allowlist</span>}
            placeholder=""
            loadOptions={async (input) => {
              return [
                {
                  label: "Add a new domain",
                  options: [
                    {
                      value: input,
                      label: `@${input}`,
                    },
                  ],
                },
              ];
            }}
            hydrateOptions={async () => {
              return [];
            }}
            name={`auto_create_sub_pages.${subPageId}.allowed_email_domains`}
          />
        </>
      )}
      {publicDomainsIncludedInAllowlist(allowedEmailDomainsForPage || [])
        .length ? (
        <Callout theme={CalloutTheme.Warning}>
          You&apos;ve included a public email domain in your allowlist. This
          means anyone with an email ending in{" "}
          <span className="text-semibold">
            {publicDomainsIncludedInAllowlist(
              allowedEmailDomainsForPage || [],
            ).join(", ")}
          </span>{" "}
          will be allowed access to this status page.{" "}
          <Button
            theme={ButtonTheme.Link}
            analyticsTrackingId="retrospective-incidents-announcements-help"
            onClick={() => showArticle("2223511837")}
          >
            Learn more
          </Button>
        </Callout>
      ) : null}
    </>
  );
};

const SubPageRow = ({
  subPageId,
  catalogType,
  catalogEntry,
}: {
  subPageId: string;
  catalogType: {
    color: CatalogTypeColorEnum;
    icon: CatalogTypeIconEnum;
  };
  catalogEntry?: CatalogEntry;
}) => {
  const parentFormMethods = useFormContext<PageWithSubPageCreationForm>();
  const { watch } = parentFormMethods;

  const [mode, parentName, parentType, componentsDefinedByCatalogAttributeId] =
    watch([
      "mode",
      "name",
      "parent_page_type",
      "components_defined_by_catalog_attribute_id",
    ]);

  const subPage =
    mode === "auto_create"
      ? watch(`auto_create_sub_pages.${subPageId}`)
      : watch(`catalog_import_sub_pages.${subPageId}`);

  const [expanded, setExpanded] = useState(true);

  const deleteSubPage = () => {
    if (mode === "auto_create") {
      const autoCreateSubPages = watch("auto_create_sub_pages");
      const newSubPages = _.omit(autoCreateSubPages, subPageId);
      parentFormMethods.setValue<"auto_create_sub_pages">(
        "auto_create_sub_pages",
        newSubPages,
      );
    } else {
      parentFormMethods.setValue(
        `catalog_import_sub_pages.${subPageId}.enabled`,
        false,
      );
    }
  };

  const invalidSuffix =
    subPage?.name && subPage.name.trim().toLowerCase().endsWith("status");

  const enabled =
    mode === "auto_create"
      ? true
      : watch(`catalog_import_sub_pages.${subPageId}.enabled`);

  useEffect(() => {
    if (componentsDefinedByCatalogAttributeId !== undefined) {
      setExpanded(true);
    }
  }, [componentsDefinedByCatalogAttributeId]);

  const autoCreateSubPages = watch("auto_create_sub_pages");

  return (
    <div
      className={tcx(
        "rounded-2 bg-surface-secondary outline",
        !enabled ? "outline-dashed outline-slate-300" : "outline-slate-200",
      )}
    >
      <div
        className={tcx(
          "flex w-full px-4 py-2 gap-2 items-center rounded-2 bg-white drop-shadow-sm",
          expanded ? "outline outline-slate-300" : "outline-slate-200",
        )}
      >
        <SubPageRowCatalogBadge
          subPageId={subPageId}
          setExpanded={setExpanded}
          {...{ catalogType }}
          catalogEntry={catalogEntry}
        />
        <Button
          onClick={() => setExpanded(!expanded)}
          analyticsTrackingId={"create-parent-status-page-expand"}
          theme={ButtonTheme.Naked}
          className="ml-auto !text-slate-400 hover:!text-content-tertiary"
          icon={expanded ? IconEnum.Collapse : IconEnum.Expand}
          title=""
        />
      </div>
      <FadeInOut show={expanded}>
        <div className="min-h-0">
          <div className="flex flex-col gap-4 p-4">
            <SubPageDetailsConfiguration
              subPageId={subPageId}
              catalogEntry={catalogEntry}
            />
            <SubPageComponentConfiguration
              subPageId={subPageId}
              {...{ catalogType }}
            />
            <div className="space-y-4">
              {parentType === StatusPagePageTypeEnum.Customer && (
                <SubPageAllowedEmailDomainConfiguration subPageId={subPageId} />
              )}
              {mode === "auto_create" &&
                Object.keys(autoCreateSubPages).length > 1 && (
                  <Button
                    analyticsTrackingId={"delete-page"}
                    theme={ButtonTheme.DestroySecondary}
                    onClick={() => deleteSubPage()}
                  >
                    Delete page
                  </Button>
                )}
            </div>
          </div>
          {invalidSuffix && (
            <Callout theme={CalloutTheme.Warning}>
              Your page title includes the word &quot;status&quot;. We&apos;ll
              automatically add this for you when we display it, so this page
              will appear as{" "}
              <span className="font-semibold">
                &quot;{parentName}{" "}
                {parentType === StatusPagePageTypeEnum.Customer && `for `}
                {subPage.name} Status&quot;
              </span>
              .
            </Callout>
          )}
        </div>
      </FadeInOut>
    </div>
  );
};

const publicDomainsIncludedInAllowlist = (domains: string[]): string[] => {
  if (!domains) {
    return [];
  }
  const commonDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "hotmail.co.uk",
    "outlook.com",
    "googlemail.com",
  ];

  return commonDomains.filter((commonDomain) =>
    domains.some((domain) => domain.includes(commonDomain)),
  );
};
