import { IncidentGroundTruthRelatedMessage } from "@incident-io/api";
import { BadgeSize, Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { CopyButton } from "@incident-ui/CopyButton/CopyButton";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { GroundTruthMetadata } from "./GroundTruthMetadata";
import { VerifiedButton } from "./VerifiedButton";

type EditableMessage = IncidentGroundTruthRelatedMessage & { id: string };

export const GroundTruthRelatedMessagesSection = ({
  messages = [],
  verifiedAt,
  onSave,
  className,
}: {
  messages?: IncidentGroundTruthRelatedMessage[];
  verifiedAt?: Date;
  onSave?: (
    messages: IncidentGroundTruthRelatedMessage[],
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableMessages, setEditableMessages] = useState<EditableMessage[]>(
    [],
  );
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableMessages(
      messages.map((message, idx) => ({ ...message, id: `message-${idx}` })),
    );
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditableMessages([]);
    setIsVerified(!!verifiedAt);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      // Strip out the temporary ids before saving
      const cleanMessages = editableMessages.map(
        ({ id: _, ...message }) => message,
      );
      await onSave(cleanMessages, isVerified);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const addNewMessage = () => {
    setEditableMessages([
      ...editableMessages,
      { id: `new-${Date.now()}`, permalink: "", detail: "" },
    ]);
  };

  const updateMessage = (
    id: string,
    field: keyof IncidentGroundTruthRelatedMessage,
    // This is either a string or a string[] depending on the field.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ) => {
    setEditableMessages((messages) =>
      messages.map((message) =>
        message.id === id ? { ...message, [field]: value } : message,
      ),
    );
  };

  const removeMessage = (id: string) => {
    setEditableMessages((messages) =>
      messages.filter((message) => message.id !== id),
    );
  };

  const isEmpty = !messages?.length;

  return (
    <div
      className={tcx(
        "border-t border-stroke first:border-t-0",
        isEditing ? "bg-surface-secondary" : "",
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Related messages</h4>
            <VerifiedButton
              isVerified={isVerified}
              verifiedAt={verifiedAt}
              setIsVerified={setIsVerified}
              isEditing={isEditing}
            />
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            {editableMessages.map((message) => (
              <EditableMessageItem
                key={message.id}
                message={message}
                onUpdate={(field, value) =>
                  updateMessage(message.id, field, value)
                }
                onRemove={() => removeMessage(message.id)}
              />
            ))}

            <div className="flex gap-2 mt-4">
              <Button
                onClick={addNewMessage}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
              >
                Add message
              </Button>
            </div>

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : isEmpty ? (
          <p className="text-sm text-content-secondary">
            No related messages have been identified yet.
          </p>
        ) : (
          <div className="space-y-4">
            {messages.map((message, index) => (
              <MessageItem key={index} message={message} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const MessageItem = ({
  message,
}: {
  message: IncidentGroundTruthRelatedMessage;
}) => {
  return (
    <div className="flex items-start gap-2">
      <Icon
        id={IconEnum.Message}
        className="mt-1 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <a
            href={message.permalink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-content-primary hover:underline break-all"
          >
            {message.permalink}
          </a>
          <CopyButton value={message.permalink} />
        </div>
        <p className="text-sm text-content-secondary mt-1">{message.detail}</p>
        <GroundTruthMetadata metadata={message.metadata} />
      </div>
    </div>
  );
};

const EditableMessageItem = ({
  message,
  onUpdate,
  onRemove,
}: {
  message: EditableMessage;
  onUpdate: (
    field: keyof IncidentGroundTruthRelatedMessage,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ) => void;
  onRemove: () => void;
}) => {
  return (
    <div className="flex items-start gap-2 p-3 rounded border border-stroke bg-white">
      <Icon
        id={IconEnum.Message}
        className="mt-2 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0 space-y-2">
        <input
          type="url"
          value={message.permalink}
          onChange={(e) => onUpdate("permalink", e.target.value)}
          placeholder="Enter message URL"
          className="w-full p-2 text-sm border-[0.25px] rounded bg-white"
        />
        <textarea
          value={message.detail}
          onChange={(e) => onUpdate("detail", e.target.value)}
          placeholder="Describe why this message is relevant to resolving the incident"
          className="w-full p-2 text-sm border-[0.25px] rounded bg-white resize-none"
          rows={2}
        />
      </div>
      <Button
        onClick={onRemove}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={IconEnum.Close}
        analyticsTrackingId={null}
        className="text-content-tertiary hover:text-content-primary"
      >
        Remove
      </Button>
    </div>
  );
};
