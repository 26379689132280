/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationEntryPayload
 */
export interface EvaluationEntryPayload {
    /**
     * ID of the Copilot search
     * @type {string}
     * @memberof EvaluationEntryPayload
     */
    copilot_search_id?: string;
    /**
     * ID of the Copilot thread
     * @type {string}
     * @memberof EvaluationEntryPayload
     */
    copilot_thread_id?: string;
    /**
     * ID of the investigation
     * @type {string}
     * @memberof EvaluationEntryPayload
     */
    investigation_id?: string;
}

export function EvaluationEntryPayloadFromJSON(json: any): EvaluationEntryPayload {
    return EvaluationEntryPayloadFromJSONTyped(json, false);
}

export function EvaluationEntryPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationEntryPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copilot_search_id': !exists(json, 'copilot_search_id') ? undefined : json['copilot_search_id'],
        'copilot_thread_id': !exists(json, 'copilot_thread_id') ? undefined : json['copilot_thread_id'],
        'investigation_id': !exists(json, 'investigation_id') ? undefined : json['investigation_id'],
    };
}

export function EvaluationEntryPayloadToJSON(value?: EvaluationEntryPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copilot_search_id': value.copilot_search_id,
        'copilot_thread_id': value.copilot_thread_id,
        'investigation_id': value.investigation_id,
    };
}

