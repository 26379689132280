/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopilotSearchLonglist
 */
export interface CopilotSearchLonglist {
    /**
     * List of candidate references in this group
     * @type {Array<string>}
     * @memberof CopilotSearchLonglist
     */
    candidates: Array<string>;
    /**
     * Name of this longlist group
     * @type {string}
     * @memberof CopilotSearchLonglist
     */
    name: string;
}

export function CopilotSearchLonglistFromJSON(json: any): CopilotSearchLonglist {
    return CopilotSearchLonglistFromJSONTyped(json, false);
}

export function CopilotSearchLonglistFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotSearchLonglist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidates': json['candidates'],
        'name': json['name'],
    };
}

export function CopilotSearchLonglistToJSON(value?: CopilotSearchLonglist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidates': value.candidates,
        'name': value.name,
    };
}

