// For when you don't have the right auth scopes and need to reinstall.

import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
} from "@incident-ui";
import React from "react";
import { useLocation } from "react-router";
import { tcx } from "src/utils/tailwind-classes";

import { AddToSlackButton } from "../../components/slack/AddToSlackButton";
import {
  SlackInfoReconnectionReasonEnum as ReconnectionReason,
  SlackTokenScope,
} from "../../contexts/ClientContext";
import { useIdentity } from "../../contexts/IdentityContext";
import { HomeRoute } from "./HomeRoute";
import graphic from "./reinstall-slack-graphic.svg";

export const ReinstallSlackRoute = (): React.ReactElement => {
  const { search } = useLocation();
  const { identity } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const onClose = () => navigate("/dashboard");

  if (!identity.slack_info) {
    // If you don't have Slack installed, you can't re-install!
    return <OrgAwareNavigate to="/dashboard" />;
  }

  const {
    install_url: addToSlackUrl,
    missing_token_scopes: missingTokenScopes,
    reconnection_reason: globalReinstallReason,
  } = identity.slack_info;
  const returnPath = new URLSearchParams(search).get("return_to");

  return (
    <>
      <HomeRoute />
      <Modal
        isOpen
        title={""} // hidden
        hideHeader
        onClose={onClose}
        analyticsTrackingId={null}
        className="!w-[600px] !rounded-[20px]"
        isExtraLarge
      >
        <ModalContent className="!p-0 !m-0">
          <img className="w-full" src={graphic} />
          <div className="flex flex-col items-center gap-2 text-center p-10">
            <div className="text-2xl-bold">
              Reinstall incident.io to your Slack workspace
            </div>
            <div className="flex flex-col gap-6 text-sm-normal">
              <div className="flex flex-col gap-2">
                {globalReinstallReason !== ReconnectionReason.Empty ? (
                  <ReinstallReasonExplanation
                    reinstallReason={globalReinstallReason}
                  />
                ) : (
                  <ScopesNeededReinstallMessage
                    missingTokenScopes={missingTokenScopes}
                  />
                )}
              </div>
              <div className="w-fit mx-auto">
                <AddToSlackButton
                  url={addToSlackUrl}
                  returnPath={returnPath || undefined}
                />
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const ReinstallReasonExplanation = ({
  reinstallReason,
}: {
  reinstallReason: ReconnectionReason;
}): React.ReactElement => {
  switch (reinstallReason) {
    case ReconnectionReason.NoWorkspaces:
      return (
        <>
          <div className="mb-2">
            incident.io is installed into your Slack Enterprise Grid
            organization, however you don&apos;t have any Slack workspaces
            connected.
          </div>
          <div className="text-center mb-4">
            Click &quot;Add to Slack&quot; below to install the app into a
            workspace.
          </div>
          <Callout theme={CalloutTheme.Info}>
            Only Slack admins will be able to install incident.io into an
            Enterprise Grid workspace.
          </Callout>
        </>
      );
    case ReconnectionReason.MissingChannelCreatePermissions:
      return (
        <>
          <div className="mb-2">
            incident.io needs permission to create channels in your Slack
            workspace.
          </div>
          <div className="text-center mb-4">
            Follow the steps in{" "}
            <Button
              href="https://help.incident.io/articles/4258864431-troubleshooting%3A-unable-to-install-due-to-channel-restrictions"
              theme={ButtonTheme.Link}
              openInNewTab
              className="!underline font-semibold"
              analyticsTrackingId={"install-channel-create-permissions"}
            >
              this article
            </Button>{" "}
            to enable this permission and reinstall the app.
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="mb-2">
            Our connection with your Slack workspace has expired.
            <br />
            To fix this, you&apos;ll need to reinstall incident.io to Slack.
          </div>
          <div className="text-center mb-4">
            Click &quot;Add to Slack&quot; below to reinstall the app.
          </div>
        </>
      );
  }
};

export function ScopesNeededReinstallMessage({
  feature,
  missingTokenScopes,
  className,
}: {
  feature?: string;
  missingTokenScopes: SlackTokenScope[];
  className?: string;
}): React.ReactElement {
  const featureText = feature ? feature : "our newest features";

  return (
    <div className={tcx("text-slate-700 prose mx-auto", className)}>
      <div className="text-sm">
        To get access to {featureText}, you&apos;ll need to reinstall
        incident.io to your Slack workspace. We need the following permissions:
      </div>
      <div className="py-6">
        <div className="divide-y border-y py-4 border-stroke flex flex-col items-center">
          {missingTokenScopes.map((scope) => (
            <div
              key={scope.scope}
              className="flex flex-col items-center gap-2 p-2 text-content-tertiary text-sm-normal"
            >
              <Badge
                theme={BadgeTheme.Tertiary}
                className="font-mono font-semibold w-fit"
              >
                {scope.scope}
              </Badge>
              To {scope.reason}
            </div>
          ))}
        </div>
      </div>
      <div className="text-sm">
        Not to worry - you won&apos;t experience any interruption in service,
        and our Slack app will continue working as before until you grant the
        new permissions.
      </div>
    </div>
  );
}
