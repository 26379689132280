import { tcx } from "src/utils/tailwind-classes";

export const Section = ({
  title,
  description,
  children,
  className,
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={tcx(
        "flex flex-col gap-2",
        "p-4 rounded-2",
        "bg-surface-secondary",
        className,
      )}
    >
      <div className="text-base-bold">{title}</div>
      {description && <div>{description}</div>}
      <div className="flex flex-wrap gap-2">{children}</div>
    </div>
  );
};
