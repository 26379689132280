import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useClipboard } from "src/utils/useClipboard";

// CopyButton renders a button with a copy icon on it, which copies the given value to the clipboard
// when clicked.
export const CopyButton = ({ value }: { value: string }) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const showToast = useToast();

  return (
    <Button
      icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
      title="Copy to clipboard"
      analyticsTrackingId={"copy-button"}
      iconProps={{
        className: hasCopied ? "text-green-content" : "text-slate-500",
        size: IconSize.Small,
      }}
      onClick={() => {
        copyTextToClipboard(value);
        showToast({
          title: "Copied to clipboard",
          theme: ToastTheme.Success,
        });
      }}
      theme={ButtonTheme.Naked}
    />
  );
};
