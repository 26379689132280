/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface IncidentGroundTruthRelatedIncident
 */
export interface IncidentGroundTruthRelatedIncident {
    /**
     * 
     * @type {IncidentSlim}
     * @memberof IncidentGroundTruthRelatedIncident
     */
    incident: IncidentSlim;
    /**
     * Explanation of how this incident is related to the current one
     * @type {string}
     * @memberof IncidentGroundTruthRelatedIncident
     */
    reason: string;
    /**
     * How relevant this incident is to the current one
     * @type {string}
     * @memberof IncidentGroundTruthRelatedIncident
     */
    relevance: string;
}

export function IncidentGroundTruthRelatedIncidentFromJSON(json: any): IncidentGroundTruthRelatedIncident {
    return IncidentGroundTruthRelatedIncidentFromJSONTyped(json, false);
}

export function IncidentGroundTruthRelatedIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruthRelatedIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': IncidentSlimFromJSON(json['incident']),
        'reason': json['reason'],
        'relevance': json['relevance'],
    };
}

export function IncidentGroundTruthRelatedIncidentToJSON(value?: IncidentGroundTruthRelatedIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': IncidentSlimToJSON(value.incident),
        'reason': value.reason,
        'relevance': value.relevance,
    };
}

