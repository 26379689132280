import { tcx } from "src/utils/tailwind-classes";

export const LabeledValue = ({
  label,
  value,
  labelClassName,
  inline = false,
}: {
  label: string;
  value: React.ReactNode;
  labelClassName?: string;
  inline?: boolean;
}) => {
  if (inline) {
    return (
      <div className="flex items-center gap-2">
        <div
          className={tcx(
            "text-content-tertiary shrink-0 font-medium",
            labelClassName,
          )}
        >
          {label}
        </div>
        <div className="text-content-primary">{value}</div>
      </div>
    );
  }
  return (
    <div className="w-full flex items-start gap-4">
      <div
        className={tcx(
          "text-content-tertiary w-32 shrink-0 font-medium",
          labelClassName,
        )}
      >
        {label}
      </div>
      <div className="text-content-primary">{value}</div>
    </div>
  );
};
