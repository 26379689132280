import {
  IssueTrackersGetIssueTrackerSyncConfigContextEnum,
  IssueTrackerSyncConfig,
  IssueTrackerSyncConfigPayloadContextEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { branchesOnlyExpressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { IssueTemplateExpressionEditor } from "@incident-shared/issue-templates/IssueTemplateExpressionEditor";
import { useEnabledExportIssueTrackers } from "@incident-shared/issue-trackers/useEnabledExportIssueTrackers";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Link,
  LoadingBar,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFieldArray, useForm } from "react-hook-form";
import { SettingsSection } from "src/components/settings/SettingsSection";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import {
  InvalidReasonEnum,
  IssueTemplateContextEnum,
  UnifiedIssueTemplate,
  useAllIssueTemplates,
} from "../issue-trackers/useAllTemplates";
import { useIssueSyncConfig } from "./useIssueSyncConfig";

type FormType = IssueTrackerSyncConfig;

export const IssueSyncConfigEditor = ({
  context,
  ...rest
}: {
  context: IssueTemplateContextEnum;
  title: string;
  description: React.ReactNode;
  helpLink: string;
  privateLabel: string;
}) => {
  const { syncConfig, syncConfigLoading, syncConfigError } =
    useIssueSyncConfig(context);

  const {
    allTemplates,
    isLoading: templatesLoading,
    error: templatesError,
  } = useAllIssueTemplates(context);

  const error = syncConfigError || templatesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (syncConfigLoading || templatesLoading) {
    return <LoadingBar className="h-24" />;
  }

  return (
    <IssueSyncConfigEditorInner
      syncConfig={syncConfig}
      issueTemplates={allTemplates}
      context={context}
      {...rest}
    />
  );
};

const IssueSyncConfigEditorInner = ({
  syncConfig,
  issueTemplates,
  context,
  title,
  description,
  helpLink,
  privateLabel,
}: {
  syncConfig: IssueTrackerSyncConfig | undefined;
  issueTemplates: UnifiedIssueTemplate[];
  context: IssueTemplateContextEnum;
  title: string;
  helpLink: string;
  description: React.ReactNode;
  privateLabel: string;
}) => {
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const { allTrackers } = useEnabledExportIssueTrackers(context);

  const validIssueTemplateExists = issueTemplates.some(
    (tmpl) => tmpl.invalid_reason === InvalidReasonEnum.Empty,
  );

  const alreadyEnabled = syncConfig?.enabled || false;

  // Form setup
  const formMethods = useForm<FormType>({
    defaultValues: {
      enabled: alreadyEnabled,
      include_private_incidents: syncConfig?.include_private_incidents || false,
      issue_template_expression: syncConfig?.issue_template_expression || {
        branches: [],
      },
    },
    mode: "onChange",
  });

  const branchesFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "issue_template_expression.branches",
  });

  const { setError, watch, reset } = formMethods;

  const includePrivateIncidents = watch("include_private_incidents");

  // Mutation setup
  const mutation = useAPIMutation(
    "issueTrackersGetIssueTrackerSyncConfig",
    {
      context:
        context as unknown as IssueTrackersGetIssueTrackerSyncConfigContextEnum,
    },
    async (apiClient, data: FormType) => {
      return await apiClient.issueTrackersUpdateIssueTrackerSyncConfig({
        updateIssueTrackerSyncConfigRequestBody: {
          sync_config: {
            ...data,
            context:
              context as unknown as IssueTrackerSyncConfigPayloadContextEnum,
            issue_template_expression: data.issue_template_expression?.branches
              ?.length
              ? branchesOnlyExpressionToPayload(
                  data.issue_template_expression,
                  false,
                )
              : undefined,
          },
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Auto-export configuration updated.",
          theme: ToastTheme.Success,
        });
        reset({}, { keepValues: true });
      },
      setError,
    },
  );

  return (
    <>
      <SettingsSection
        formMethods={formMethods}
        mutation={mutation}
        enabledPath="enabled"
        title={title}
        titleHeadingLevel={3}
        // If private incidents are exported, only owners can modify the auto-export settings
        requiredScope={
          includePrivateIncidents
            ? ScopeNameEnum.IncidentsGlobalAccess
            : ScopeNameEnum.OrganisationSettingsUpdate
        }
        explanation={description}
        isDirty={formMethods.formState.isDirty}
        warnWhenDirty
        // We cannot enable auto-export if there are no valid issue templates.
        // However we _can_ disable it.
        disabled={!validIssueTemplateExists && !alreadyEnabled}
      >
        {/* There are no valid export templates, so lets show a warning. */}
        {!validIssueTemplateExists && (
          <Callout theme={CalloutTheme.Info}>
            An installed issue tracker integration and at least one{" "}
            {joinSpansWithCommasAndConnectorWord(
              allTrackers.map((provider) => (
                <span key={provider} className="font-medium">
                  {INTEGRATION_CONFIGS[provider].label}
                </span>
              )),
              "or",
            )}{" "}
            export template is required to enable auto-export. Read more in this{" "}
            <Link
              analyticsTrackingId={null}
              href={helpLink}
              className="font-medium"
            >
              help article
            </Link>
            .
          </Callout>
        )}

        {/* There are valid export templates, lets allow the user to enable auto-export if they like. */}
        {validIssueTemplateExists && (
          <div className="space-y-4">
            <div className="space-y-4 mt-4">
              <CheckboxV2
                formMethods={formMethods}
                name="include_private_incidents"
                label={privateLabel}
                className="w-full"
                disabled={!hasScope(ScopeNameEnum.IncidentsGlobalAccess)}
                disabledTooltipContent={"Only Owners can modify this setting."}
              />
              {includePrivateIncidents && (
                <Callout theme={CalloutTheme.Warning}>
                  Tickets created for private incidents will be visible to
                  anyone with access to your issue tracker. Only Owners will be
                  able to change these settings.
                </Callout>
              )}
              <IssueTemplateExpressionEditor
                context={context}
                issueTemplates={issueTemplates}
                branches={branchesFieldMethods.fields}
                onMoveBranch={branchesFieldMethods.move}
                onAddBranch={branchesFieldMethods.append}
                onEditBranch={branchesFieldMethods.update}
                onRemoveBranch={branchesFieldMethods.remove}
                resetBranches={() => branchesFieldMethods.replace([])}
                privateIncidentsEnabled={includePrivateIncidents}
                copyVerb="export"
              />
            </div>
          </div>
        )}
      </SettingsSection>
    </>
  );
};
