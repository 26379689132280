/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationLeaderboardPayload
 */
export interface EvaluationLeaderboardPayload {
    /**
     * Type of backtest this leaderboard compares
     * @type {string}
     * @memberof EvaluationLeaderboardPayload
     */
    backtest_type: EvaluationLeaderboardPayloadBacktestTypeEnum;
    /**
     * Description for this leaderboard
     * @type {string}
     * @memberof EvaluationLeaderboardPayload
     */
    description?: string;
    /**
     * Optional type of investigation plan this leaderboard compares
     * @type {string}
     * @memberof EvaluationLeaderboardPayload
     */
    investigation_plan_type?: string;
    /**
     * Display name for this leaderboard
     * @type {string}
     * @memberof EvaluationLeaderboardPayload
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationLeaderboardPayloadBacktestTypeEnum {
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    SearchCodeChanges = 'search_code_changes',
    SearchIncidents = 'search_incidents'
}

export function EvaluationLeaderboardPayloadFromJSON(json: any): EvaluationLeaderboardPayload {
    return EvaluationLeaderboardPayloadFromJSONTyped(json, false);
}

export function EvaluationLeaderboardPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationLeaderboardPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_type': json['backtest_type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'investigation_plan_type': !exists(json, 'investigation_plan_type') ? undefined : json['investigation_plan_type'],
        'name': json['name'],
    };
}

export function EvaluationLeaderboardPayloadToJSON(value?: EvaluationLeaderboardPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_type': value.backtest_type,
        'description': value.description,
        'investigation_plan_type': value.investigation_plan_type,
        'name': value.name,
    };
}

