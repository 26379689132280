import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { BadgeSize, Callout, CalloutTheme, IconEnum } from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import React, { ReactNode } from "react";

import { tcx } from "../../../../utils/tailwind-classes";

export const AlertRouteFormSection = ({
  title,
  color,
  icon,
  accessory,
  disabled,
  children,
  isCurrentFirstTimeStep,
  firstTimeContent,
  stacked,
}: {
  title: string;
  color?: ColorPaletteEnum;
  icon: IconEnum;
  accessory: React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  isCurrentFirstTimeStep?: boolean;
  firstTimeContent?: React.ReactNode;
  stacked?: boolean;
}) => {
  const showContent = (firstTimeContent && isCurrentFirstTimeStep) || children;
  return (
    <FlowCards.Card
      title={title}
      color={color}
      icon={icon}
      accessory={accessory}
      disabled={disabled}
      isHighlighted={isCurrentFirstTimeStep}
      className={tcx({
        "border-none border-0 shadow-none rounded-none": stacked,
      })}
    >
      {showContent && (
        <div className={"pl-7 w-full"}>
          {firstTimeContent && isCurrentFirstTimeStep && (
            <Callout iconOverride={IconEnum.Bulb} theme={CalloutTheme.Info}>
              {firstTimeContent}
            </Callout>
          )}
          {children}
        </div>
      )}
    </FlowCards.Card>
  );
};

export const AlertRouteSplitDrawerSection = ({
  left,
  right,
  className,
}: {
  left: ReactNode;
  right?: ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("flex flex-col lg:flex-row", className)}>
      <div className="flex flex-col p-6 gap-6 lg:w-[50%] overflow-auto">
        {left}
      </div>
      <div
        className={tcx(
          "px-8 py-6 bg-slate-50 border-l border-stroke gap-4 lg:w-[50%] overflow-auto",
          // If we don't have a right side, we only want to show an empty grey box if
          // we're in side-by side mode (so when screen is large)
          right ? "flex flex-col" : "hidden lg:flex flex-col",
        )}
      >
        {right}
      </div>
    </div>
  );
};

export const AlertRouteYesNoButtonGroup = ({
  onChange,
  disabled,
}: {
  onChange: (boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="flex gap-2">
      <GatedButton
        disabled={disabled}
        disabledTooltipContent="Complete previous steps"
        size={BadgeSize.Medium}
        onClick={() => onChange(true)}
        analyticsTrackingId={null}
      >
        Yes
      </GatedButton>
      <GatedButton
        disabled={disabled}
        disabledTooltipContent="Complete previous steps"
        size={BadgeSize.Medium}
        onClick={() => onChange(false)}
        analyticsTrackingId={null}
      >
        No
      </GatedButton>
    </div>
  );
};
