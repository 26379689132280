import { PopoverProps } from "@incident-ui/Popover/Popover";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import {
  MultiRenderSelectedFn,
  OptionWithOnSelectRender,
  PopoverSelectOption,
  PopoverSelectOptionGroup,
  PopoverSelectOptions,
  SingleRenderSelectedFn,
  SyncOrAsyncSelectProps,
} from "./types";

export const isGroupedOptions = <TOption extends PopoverSelectOption>(
  options: PopoverSelectOptions<TOption>,
): options is PopoverSelectOptionGroup<TOption>[] => {
  if (options?.length === 0) {
    return false;
  }

  return "options" in options[0];
};

export const isGroupedRenderSelectedFn = <TOption extends PopoverSelectOption>(
  renderSelected:
    | SingleRenderSelectedFn<TOption>
    | MultiRenderSelectedFn<TOption>,
  options: PopoverSelectOptions<TOption>,
): renderSelected is MultiRenderSelectedFn<TOption> => {
  return isGroupedOptions(options);
};

export const useAsyncOptions = <
  TSync extends boolean,
  TOption extends PopoverSelectOption,
>({
  loadOptions,
  hydrateOptions,
  value,
  search,
}: Pick<
  SyncOrAsyncSelectProps<TSync, TOption>,
  "loadOptions" | "hydrateOptions"
> & {
  search: string;
  value: TOption | TOption[] | string | string[] | undefined;
}) => {
  const [hydratedValues, setHydratedValues] = useState<TOption[] | undefined>(
    undefined,
  );
  const [options, setOptions] = useState<PopoverSelectOptions<TOption>>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingValues, setIsLoadingValues] = useState(false);

  const [debouncedSearch] = useDebounce(search, 250);

  useEffect(() => {
    if (!loadOptions) return;

    setIsLoadingOptions(true);
    loadOptions(debouncedSearch)
      .then((searchedOptions) => {
        setOptions(searchedOptions);
      })
      .finally(() => setIsLoadingOptions(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    if (!hydrateOptions) return;

    const valuesOrObjects = Array.isArray(value)
      ? value
      : [value].filter(Boolean);
    const values = valuesOrObjects
      .map((val: string | TOption | undefined) =>
        typeof val === "string" ? val : val?.value,
      )
      .filter(Boolean) as string[];

    setIsLoadingValues(true);
    hydrateOptions(values)
      .then((values) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setHydratedValues(values);
      })
      .finally(() => setIsLoadingValues(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  return {
    options,
    hydratedValues,
    isLoading: isLoadingOptions || isLoadingValues,
  };
};

export const isSearchableWithDefault = ({
  isSearchable,
  flattenedOptions,
}: {
  isSearchable?: boolean;
  flattenedOptions?: PopoverSelectOption[];
}) => {
  if (isSearchable !== undefined) {
    // User input wins
    return isSearchable;
  }

  return flattenedOptions && flattenedOptions.length > 7;
};

export const useManageSelectState = (): {
  showSecondaryForm: OptionWithOnSelectRender | null;
  setShowSecondaryForm: (opt: OptionWithOnSelectRender | null) => void;
  search: string;
  setSearch: (search: string) => void;
  handleClose: () => void;
  openControl: Pick<
    PopoverProps,
    "open" | "onInteractOutside" | "onOpenChange"
  > & {
    setIsOpen: (val: boolean) => void;
  };
} => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showSecondaryForm, setShowSecondaryForm] =
    useState<OptionWithOnSelectRender | null>(null);

  const handleClose = () => {
    setSearch("");
    setIsOpen(false);
    setShowSecondaryForm(null);
  };

  return {
    openControl: {
      open: isOpen,
      setIsOpen,
      onInteractOutside: () => {
        setIsOpen(false);
      },
      onOpenChange: (open) => {
        if (!open) {
          handleClose();
        }
      },
    },
    search,
    setSearch,
    showSecondaryForm,
    setShowSecondaryForm,
    handleClose,
  };
};
