import { IncidentGroundTruthTelemetryDashboard } from "@incident-io/api";
import { BadgeSize, Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { CopyButton } from "@incident-ui/CopyButton/CopyButton";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { VerifiedButton } from "./VerifiedButton";

type GroundTruthTelemetryDashboardsSectionProps = {
  relatedDashboards?: IncidentGroundTruthTelemetryDashboard[];
  className?: string;
  verifiedAt?: Date;
  onSave?: (
    relatedDashboards: IncidentGroundTruthTelemetryDashboard[],
    isVerified: boolean,
  ) => Promise<void>;
};

type EditableRelatedDashboard = IncidentGroundTruthTelemetryDashboard & {
  id: string;
};

export const GroundTruthTelemetryDashboardsSection = ({
  relatedDashboards,
  className,
  verifiedAt,
  onSave,
}: GroundTruthTelemetryDashboardsSectionProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableRelatedDashboards, setEditableRelatedDashboards] = useState<
    EditableRelatedDashboard[]
  >([]);
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableRelatedDashboards(
      relatedDashboards?.map((dashboard, idx) => ({
        ...dashboard,
        id: `dashboard-${idx}`,
      })) || [],
    );
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditableRelatedDashboards([]);
    setIsVerified(!!verifiedAt);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      // Strip out the temporary ids before saving
      const cleanDashboards = editableRelatedDashboards.map(
        ({ id: _, ...dashboard }) => dashboard,
      );
      await onSave(cleanDashboards, isVerified);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const addNewDashboard = () => {
    setEditableRelatedDashboards([
      ...editableRelatedDashboards,
      {
        id: `new-${Date.now()}`,
        provider_dashboard_id: "",
        dashboard_url: "",
        detail: "",
      },
    ]);
  };

  const updateDashboard = (
    id: string,
    field: keyof IncidentGroundTruthTelemetryDashboard,
    value: string,
  ) => {
    setEditableRelatedDashboards((dashboards) =>
      dashboards.map((dashboard) =>
        dashboard.id === id ? { ...dashboard, [field]: value } : dashboard,
      ),
    );
  };

  const removeDashboard = (id: string) => {
    setEditableRelatedDashboards((dashboards) =>
      dashboards.filter((dashboard) => dashboard.id !== id),
    );
  };

  return (
    <div
      className={tcx(
        "border-t border-stroke first:border-t-0",
        isEditing ? "bg-surface-secondary" : "",
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Related dashboards</h4>
            <VerifiedButton
              isVerified={isVerified}
              verifiedAt={verifiedAt}
              setIsVerified={setIsVerified}
              isEditing={isEditing}
            />
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            {editableRelatedDashboards.map((dashboard) => (
              <EditableDashboardItem
                key={dashboard.id}
                dashboard={dashboard}
                onUpdate={(field, value) =>
                  updateDashboard(dashboard.id, field, value)
                }
                onRemove={() => removeDashboard(dashboard.id)}
              />
            ))}

            <div className="flex gap-2 mt-4">
              <Button
                onClick={addNewDashboard}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
              >
                Add dashboard
              </Button>
            </div>

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : !relatedDashboards?.length ? (
          <p className="text-sm text-content-secondary">
            No related dashboards have been identified yet.
          </p>
        ) : (
          <div className="flex flex-col gap-4">
            {relatedDashboards.map((dashboard, idx) => (
              <RelatedDashboardItem key={idx} dashboard={dashboard} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

type RelatedDashboardItemProps = {
  dashboard: IncidentGroundTruthTelemetryDashboard;
};

const RelatedDashboardItem = ({ dashboard }: RelatedDashboardItemProps) => {
  return (
    <div className="flex items-start gap-2">
      <Icon
        id={IconEnum.Dashboard}
        className="mt-1 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <Button
            theme={ButtonTheme.Link}
            analyticsTrackingId={null}
            href={dashboard.dashboard_url}
          >
            {dashboard.provider_dashboard_id}
          </Button>
          <CopyButton value={dashboard.dashboard_url} />
        </div>
        <p className="text-sm text-content-secondary mt-1">
          {dashboard.detail}
        </p>
      </div>
    </div>
  );
};

type EditableDashboardItemProps = {
  dashboard: EditableRelatedDashboard;
  onUpdate: (
    field: keyof IncidentGroundTruthTelemetryDashboard,
    value: string,
  ) => void;
  onRemove: () => void;
};

const EditableDashboardItem = ({
  dashboard,
  onUpdate,
  onRemove,
}: EditableDashboardItemProps) => {
  return (
    <div className="flex items-start gap-2 p-3 rounded border border-stroke bg-white">
      <Icon
        id={IconEnum.Dashboard}
        className="mt-2 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0 space-y-2">
        <input
          type="text"
          value={dashboard.provider_dashboard_id}
          onChange={(e) => onUpdate("provider_dashboard_id", e.target.value)}
          placeholder="Dashboard ID"
          className="w-full p-2 text-sm border rounded bg-white"
        />
        <input
          type="url"
          value={dashboard.dashboard_url}
          onChange={(e) => onUpdate("dashboard_url", e.target.value)}
          placeholder="Dashboard URL"
          className="w-full p-2 text-sm border rounded bg-white"
        />
        <textarea
          value={dashboard.detail}
          onChange={(e) => onUpdate("detail", e.target.value)}
          placeholder="Describe the dashboard"
          className="w-full p-2 text-sm border rounded bg-white resize-none"
          rows={2}
        />
      </div>
      <Button
        onClick={onRemove}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={IconEnum.Close}
        analyticsTrackingId={null}
        className="text-content-tertiary hover:text-content-primary"
      >
        Remove
      </Button>
    </div>
  );
};
