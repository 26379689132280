/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailablePhoneNumber
 */
export interface AvailablePhoneNumber {
    /**
     * The locality of the phone number
     * @type {string}
     * @memberof AvailablePhoneNumber
     */
    locality?: string;
    /**
     * The phone number
     * @type {string}
     * @memberof AvailablePhoneNumber
     */
    phone_number: string;
    /**
     * The price of the phone number
     * @type {string}
     * @memberof AvailablePhoneNumber
     */
    price?: string;
}

export function AvailablePhoneNumberFromJSON(json: any): AvailablePhoneNumber {
    return AvailablePhoneNumberFromJSONTyped(json, false);
}

export function AvailablePhoneNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailablePhoneNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'phone_number': json['phone_number'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function AvailablePhoneNumberToJSON(value?: AvailablePhoneNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality': value.locality,
        'phone_number': value.phone_number,
        'price': value.price,
    };
}

