import { SettingsDeleteButton } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
} from "@incident-ui";
import React from "react";
import {
  DecisionFlowsUpdateRequestBody,
  DependentResource,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { DecisionTreeComponent } from "./DecisionTreeComponent";

const DisplayFlowMetadata = ({
  onDelete,
  decisionFlow,
  dependentResources,
  onEdit,
}: {
  decisionFlow: DecisionFlowsUpdateRequestBody;
  dependentResources: DependentResource[];
  onEdit: () => void;
  onDelete?: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  return (
    <div className="relative mb-2">
      <div className="flex">
        <h3 className="text-lg leading-6 font-medium text-content-primary">
          {decisionFlow.name}
          {!decisionFlow.is_live && (
            <span className="ml-2">
              <Badge theme={BadgeTheme.Success} size={BadgeSize.Small}>
                <Icon id={IconEnum.Test} />
                Test Mode
              </Badge>
            </span>
          )}
        </h3>
        {canEditSettings && (
          <div className="ml-auto flex gap-2 shrink-0">
            <Button
              icon={IconEnum.Edit}
              onClick={onEdit}
              title="Edit"
              theme={ButtonTheme.Tertiary}
              analyticsTrackingId="decision-flow-summary-edit"
            />
            {onDelete && (
              <SettingsDeleteButton
                dependentResources={dependentResources}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                resourceTitle={"Decision flow"}
                onDelete={onDelete}
                buttonSize={BadgeSize.Large}
                deleteConfirmationTitle={"Delete decision flow"}
                deleteConfirmationContent={
                  <>
                    Are you sure you want to delete the{" "}
                    <strong>{decisionFlow.name}</strong> decision flow?
                  </>
                }
              />
            )}
          </div>
        )}
      </div>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-slate-700">
          <p className="whitespace-pre-wrap">{decisionFlow.description}</p>
        </div>
      </div>
    </div>
  );
};

// DecisionFlow renders a grey box with all the information about a decision flow inside it.
export const EditDecisionFlowRenderer = ({
  onEditNode,
  decisionFlow,
  dependentResources,
  onEditMetadata,
  onDelete,
}: {
  decisionFlow: DecisionFlowsUpdateRequestBody;
  dependentResources: DependentResource[];
  onEditNode: (nodeId: number) => void;
  onEditMetadata: () => void;
  onDelete?: () => void;
}): React.ReactElement => (
  <div>
    <DisplayFlowMetadata
      onDelete={onDelete}
      decisionFlow={decisionFlow}
      dependentResources={dependentResources}
      onEdit={onEditMetadata}
    />
    <ContentBox className="bg-surface-tertiary rounded-[6px]">
      <div style={{ height: "25em" }}>
        <DecisionTreeComponent tree={decisionFlow.tree} onEdit={onEditNode} />
      </div>
    </ContentBox>
  </div>
);
