/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIEvalResult,
    AIEvalResultFromJSON,
    AIEvalResultFromJSONTyped,
    AIEvalResultToJSON,
} from './AIEvalResult';

/**
 * 
 * @export
 * @interface AIModelResult
 */
export interface AIModelResult {
    /**
     * Array of evaluation results for this model
     * @type {Array<AIEvalResult>}
     * @memberof AIModelResult
     */
    cases: Array<AIEvalResult>;
    /**
     * Name of the model used for these results
     * @type {string}
     * @memberof AIModelResult
     */
    model_name: string;
}

export function AIModelResultFromJSON(json: any): AIModelResult {
    return AIModelResultFromJSONTyped(json, false);
}

export function AIModelResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIModelResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cases': ((json['cases'] as Array<any>).map(AIEvalResultFromJSON)),
        'model_name': json['model_name'],
    };
}

export function AIModelResultToJSON(value?: AIModelResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cases': ((value.cases as Array<any>).map(AIEvalResultToJSON)),
        'model_name': value.model_name,
    };
}

