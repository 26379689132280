/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AISpan
 */
export interface AISpan {
    /**
     * Cost in cents of the AI request, if there is one
     * @type {number}
     * @memberof AISpan
     */
    ai_request_cost_cents?: number;
    /**
     * ID of the matching AI request for completion_request types
     * @type {string}
     * @memberof AISpan
     */
    ai_request_id?: string;
    /**
     * When this resource was created
     * @type {Date}
     * @memberof AISpan
     */
    created_at: Date;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof AISpan
     */
    duration_seconds: number;
    /**
     * Type of execution
     * @type {string}
     * @memberof AISpan
     */
    execution_type: AISpanExecutionTypeEnum;
    /**
     * Unique identifier
     * @type {string}
     * @memberof AISpan
     */
    id: string;
    /**
     * Input for the span
     * @type {object}
     * @memberof AISpan
     */
    input: object;
    /**
     * Name of the specific prompt or tool
     * @type {string}
     * @memberof AISpan
     */
    name: string;
    /**
     * ID of the owning organisation
     * @type {string}
     * @memberof AISpan
     */
    organisation_id: string;
    /**
     * ID of the parent span, if any
     * @type {string}
     * @memberof AISpan
     */
    parent_id?: string;
    /**
     * ID of the resource this span is associated with
     * @type {string}
     * @memberof AISpan
     */
    resource_id?: string;
    /**
     * Result of the completion_request or tool call
     * @type {object}
     * @memberof AISpan
     */
    result: object;
    /**
     * Type of result (success or error)
     * @type {string}
     * @memberof AISpan
     */
    result_type: AISpanResultTypeEnum;
    /**
     * When the span started
     * @type {Date}
     * @memberof AISpan
     */
    start: Date;
    /**
     * Trace ID this request was made as part of
     * @type {string}
     * @memberof AISpan
     */
    trace_id?: string;
    /**
     * Type of span (completion_request or tool_call)
     * @type {string}
     * @memberof AISpan
     */
    type: AISpanTypeEnum;
    /**
     * When this resource was last updated
     * @type {Date}
     * @memberof AISpan
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AISpanExecutionTypeEnum {
    Normal = 'normal',
    SpeculativeCall = 'speculative_call',
    SpeculativeResult = 'speculative_result'
}/**
* @export
* @enum {string}
*/
export enum AISpanResultTypeEnum {
    Empty = '',
    Success = 'success',
    Error = 'error',
    Ignored = 'ignored'
}/**
* @export
* @enum {string}
*/
export enum AISpanTypeEnum {
    Backfill = 'backfill',
    CompletionRequest = 'completion_request',
    CopilotInteraction = 'copilot_interaction',
    Grader = 'grader',
    InvestigationCheck = 'investigation_check',
    Processor = 'processor',
    Prompt = 'prompt',
    Scorecard = 'scorecard',
    Search = 'search',
    ToolCall = 'tool_call'
}

export function AISpanFromJSON(json: any): AISpan {
    return AISpanFromJSONTyped(json, false);
}

export function AISpanFromJSONTyped(json: any, ignoreDiscriminator: boolean): AISpan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ai_request_cost_cents': !exists(json, 'ai_request_cost_cents') ? undefined : json['ai_request_cost_cents'],
        'ai_request_id': !exists(json, 'ai_request_id') ? undefined : json['ai_request_id'],
        'created_at': (new Date(json['created_at'])),
        'duration_seconds': json['duration_seconds'],
        'execution_type': json['execution_type'],
        'id': json['id'],
        'input': json['input'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'parent_id': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'resource_id': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'result': json['result'],
        'result_type': json['result_type'],
        'start': (new Date(json['start'])),
        'trace_id': !exists(json, 'trace_id') ? undefined : json['trace_id'],
        'type': json['type'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AISpanToJSON(value?: AISpan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ai_request_cost_cents': value.ai_request_cost_cents,
        'ai_request_id': value.ai_request_id,
        'created_at': (value.created_at.toISOString()),
        'duration_seconds': value.duration_seconds,
        'execution_type': value.execution_type,
        'id': value.id,
        'input': value.input,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'parent_id': value.parent_id,
        'resource_id': value.resource_id,
        'result': value.result,
        'result_type': value.result_type,
        'start': (value.start.toISOString()),
        'trace_id': value.trace_id,
        'type': value.type,
        'updated_at': (value.updated_at.toISOString()),
    };
}

