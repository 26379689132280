import {
  PolicyReportSchedule,
  PolicyReportScheduleIntervalEnum,
} from "@incident-io/api/models";
import { Txt } from "@incident-ui";
import { formatTimeOnlyTimestamp } from "src/utils/datetime";
import { toSentenceCase } from "src/utils/formatting";
import { formatDayOfMonth } from "src/utils/frequencies";
import { assertUnreachable } from "src/utils/utils";

import { policyChannelsDescription } from "../policy-report/view/PolicyReportList";
import { findTimeZone } from "./timezone";

export const createPolicyReportScheduleDescription = (
  report: PolicyReportSchedule,
): string => {
  const reportTimezone = findTimeZone(report.time_zone);
  const hourString = String(report.hour).padStart(2, "0");
  // Use a random day, we're only displaying the hour from it
  const hourTimestamp = new Date(`2000-01-01T${hourString}:00:00.000Z`);
  const formattedHour = formatTimeOnlyTimestamp({
    timestamp: hourTimestamp,
    timeStyle: "short",
    timeZone: "Etc/UTC",
  });

  switch (report.interval) {
    case PolicyReportScheduleIntervalEnum.Daily:
      return `day at ${formattedHour} ${reportTimezone?.abbreviation}`;
    case PolicyReportScheduleIntervalEnum.DailyOnWeekdays:
      return `weekday at ${formattedHour} ${reportTimezone?.abbreviation}`;
    case PolicyReportScheduleIntervalEnum.Weekly:
      return `${toSentenceCase(
        report.day_of_week ?? "",
      )} at ${formattedHour} ${reportTimezone?.abbreviation}`;
    case PolicyReportScheduleIntervalEnum.Monthly:
    case PolicyReportScheduleIntervalEnum.MonthlyOnWeekdays:
      return `month on the ${formatDayOfMonth(
        report.day_of_month,
      )} at ${formattedHour} ${reportTimezone?.abbreviation ?? ""}`;
    default:
      return assertUnreachable(report.interval);
  }
};

export const PolicyReportDescription = ({
  schedule,
  className,
}: {
  schedule: PolicyReportSchedule;
  className?: string;
}) => {
  // If this is the list view, the text should be small
  const classNames = className ? `${className} ` : "";
  return (
    <Txt grey inline className={`flex gap-1 ${classNames}`}>
      Reporting to {policyChannelsDescription(schedule, classNames)} about{" "}
      <Txt grey inline bold className={classNames}>
        {schedule.policy_ids.length}{" "}
        {schedule.policy_ids.length === 1 ? "policy" : "policies"}
      </Txt>{" "}
      every{" "}
      <Txt grey inline bold className={classNames}>
        {createPolicyReportScheduleDescription(schedule)}
      </Txt>
    </Txt>
  );
};
