import { useOrgAwareNavigate } from "@incident-shared/org-aware/OrgAware";
import React from "react";
import { useLocation } from "react-router-dom";

export const useSafeUpdateQueryString = (): ((
  queryString: string,
  replace?: boolean,
) => void) => {
  const navigate = useOrgAwareNavigate();
  const location = useLocation();

  const updateHistory = (queryString: string, replace?: boolean) => {
    const currentQueryString = location.search.slice(1);
    // if we don't do this check, we end up infinite looping because
    // react-router forces a re-render every time we update history.
    if (currentQueryString !== queryString) {
      navigate(
        {
          pathname: location.pathname,
          search: queryString,
        },
        {
          replace,
        },
      );
    }
  };

  return updateHistory;
};

export function useQueryParams(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function paramsAsObj(params: URLSearchParams): Record<string, string> {
  const paramsObj = {};

  for (const [key, value] of params.entries()) {
    paramsObj[key] = value;
  }
  return paramsObj;
}

export function useNavigateToModal() {
  const location = useLocation();
  const navigate = useOrgAwareNavigate();

  return (newURL: string): void => {
    navigate(
      {
        pathname: `${
          location.pathname.endsWith("/")
            ? location.pathname
            : location.pathname + "/"
        }${newURL}`,
        search: location.search,
      },
      { replace: true },
    );
  };
}

export const useStateQueryParams = <T extends string>(key: string) => {
  const params = useQueryParams();

  const updateQueryParams = useSafeUpdateQueryString();

  return [
    params.get(key),
    (value: T | undefined) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
      updateQueryParams(params.toString());
    },
  ] as const;
};

export const useArrayStateQueryParams = <T extends string>(key: string) => {
  const params = useQueryParams();

  const updateQueryParams = useSafeUpdateQueryString();

  return [
    params.getAll(key),
    (values: T[]) => {
      params.delete(key);
      values.forEach((value) => {
        params.append(key, value);
      });
      updateQueryParams(params.toString());
    },
  ] as const;
};
