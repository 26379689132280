import { EvaluationDataset, EvaluationDatasetEntry } from "@incident-io/api";

import { SearchGroundTruthsList } from "../searches/SearchGroundTruthsListPage";

export const SearchDatasetShowPageContent = ({
  dataset,
}: {
  dataset: EvaluationDataset;
  entries: EvaluationDatasetEntry[];
}) => {
  // At some point we can build segments and dimensions, but for now we don't NEED it so I'm not gonna
  // bother.
  return <SearchGroundTruthsList datasetId={dataset.id} />;
};
