/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReplicaUserLink
 */
export interface ScheduleReplicaUserLink {
    /**
     * External user ID if resolved
     * @type {string}
     * @memberof ScheduleReplicaUserLink
     */
    external_user_id?: string;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof ScheduleReplicaUserLink
     */
    id: string;
    /**
     * Name of the user
     * @type {string}
     * @memberof ScheduleReplicaUserLink
     */
    name: string;
}

export function ScheduleReplicaUserLinkFromJSON(json: any): ScheduleReplicaUserLink {
    return ScheduleReplicaUserLinkFromJSONTyped(json, false);
}

export function ScheduleReplicaUserLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplicaUserLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_user_id': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function ScheduleReplicaUserLinkToJSON(value?: ScheduleReplicaUserLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user_id': value.external_user_id,
        'id': value.id,
        'name': value.name,
    };
}

