import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { diffLines } from "diff";
import React, { useEffect, useRef, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { YAMLEditor } from "./YamlEditor";

type Mode = "yaml" | "json" | "text";

interface CodeViewerProps {
  mode: Mode;
  content: string;
  diffWith?: string;
  showDiffByDefault?: boolean;
  title?: React.ReactNode;
  highlightSearchTerm?: string;
  isEditing?: boolean;
  setIsEditing?: (v: boolean) => void;
  onEdit?: (v: string) => void;
  unlimitedHeight?: boolean;
  disabled?: boolean;
  className?: string;
  codeClassName?: string;
  hideHeader?: boolean;
}

export const CodeViewer = ({
  mode,
  content,
  diffWith,
  showDiffByDefault = false,
  title,
  highlightSearchTerm,
  disabled = false,
  isEditing,
  setIsEditing,
  unlimitedHeight = false,
  onEdit,
  className,
  codeClassName,
  hideHeader = false,
}: CodeViewerProps) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const containerRef = useRef<HTMLDivElement>(null);
  const [editedContent, setEditedContent] = useState(content);
  const [showDiff, setShowDiff] = useState(showDiffByDefault);

  const onSave = () => {
    if (onEdit) {
      onEdit(editedContent);
    }
    if (setIsEditing) {
      setIsEditing(false);
    }
  };

  const onCancel = () => {
    if (setIsEditing) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setEditedContent(content);
  }, [setEditedContent, content]);

  // Process content based on mode
  const processedContent = React.useMemo(() => {
    switch (mode) {
      case "yaml":
        return content.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
      case "json":
        try {
          return JSON.stringify(JSON.parse(content), null, 2);
        } catch {
          return content;
        }
      default:
        return content;
    }
  }, [content, mode]);

  const processedDiffContent = React.useMemo(() => {
    if (!diffWith) return "";
    switch (mode) {
      case "yaml":
        return diffWith.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
      case "json":
        try {
          return JSON.stringify(JSON.parse(diffWith), null, 2);
        } catch {
          return diffWith;
        }
      default:
        return diffWith;
    }
  }, [diffWith, mode]);

  // Scroll to first highlight when mounted or when search term changes
  useEffect(() => {
    if (!highlightSearchTerm) return;

    const timeoutId = setTimeout(() => {
      const highlightedElement =
        containerRef.current?.querySelector(".highlighted-text");
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [highlightSearchTerm, processedContent]);

  const defaultTitle = mode.toUpperCase();

  const renderContent = () => {
    if (showDiff && diffWith) {
      return (
        <DiffView oldText={processedContent} newText={processedDiffContent} />
      );
    }

    if (mode === "json") {
      return processedContent.split("\n").map((line, index) => (
        <div
          key={index}
          style={{
            paddingLeft: `${(line.match(/^\s*/)?.[0]?.length ?? 0) * 3}px`,
          }}
        >
          <HighlightedText text={line} searchTerm={highlightSearchTerm} />
        </div>
      ));
    }

    return (
      <HighlightedText
        text={processedContent}
        searchTerm={highlightSearchTerm}
      />
    );
  };

  if (!!onEdit && mode !== "yaml") {
    return <div>Editing is only supported for YAML right now!</div>;
  }

  return (
    <div
      className={tcx(
        "w-full bg-white rounded-2 shadow-sm border font-mono",
        className,
      )}
    >
      {!hideHeader && (
        <div className="flex justify-between items-center p-2 border-b">
          <div className="flex items-center gap-2">
            <span className={tcx("text-sm font-medium text-content-primary")}>
              {title ?? defaultTitle}
            </span>
            {!!setIsEditing &&
              (isEditing ? (
                <>
                  <Button
                    analyticsTrackingId={null}
                    onClick={onSave}
                    size={BadgeSize.Small}
                    icon={IconEnum.Tick}
                    theme={ButtonTheme.Primary}
                    title=""
                  />
                  <Button
                    analyticsTrackingId={null}
                    onClick={onCancel}
                    size={BadgeSize.Small}
                    icon={IconEnum.Close}
                    title=""
                  />
                </>
              ) : (
                <Button
                  onClick={() => setIsEditing(true)}
                  analyticsTrackingId={null}
                  size={BadgeSize.Small}
                  theme={ButtonTheme.Secondary}
                  icon={IconEnum.Edit}
                  disabled={disabled}
                  title=""
                />
              ))}
          </div>
          <div className="flex items-center gap-4">
            {diffWith && !isEditing && (
              <Button
                theme={ButtonTheme.Naked}
                onClick={() => setShowDiff(!showDiff)}
                icon={IconEnum.GitBranch}
                disabled={disabled}
                analyticsTrackingId={null}
              >
                {showDiff ? "Hide Diff" : "Show Diff"}
              </Button>
            )}
            {!isEditing && (
              <Button
                theme={ButtonTheme.Naked}
                onClick={() => copyTextToClipboard(processedContent)}
                icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
                disabled={disabled}
                analyticsTrackingId={null}
              >
                Copy
              </Button>
            )}
          </div>
        </div>
      )}
      {isEditing ? (
        <YAMLEditor value={editedContent} onChange={setEditedContent} />
      ) : (
        <div
          ref={containerRef}
          className={tcx(
            "p-4 bg-surface-secondary rounded-b-2 font-mono overflow-y-auto text-xs",
            !unlimitedHeight && "max-h-96",
            disabled && "opacity-50",
            codeClassName,
          )}
        >
          <pre className="whitespace-pre-wrap">{renderContent()}</pre>
        </div>
      )}
    </div>
  );
};

const HighlightedText = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm?: string;
}) => {
  if (!searchTerm) return <>{text}</>;

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === searchTerm?.toLowerCase() ? (
          <span
            key={i}
            className="highlighted-text bg-yellow-200 border-yellow-800 border-2 font-semibold px-1 rounded"
          >
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
    </>
  );
};

const DiffView = ({
  oldText,
  newText,
}: {
  oldText: string;
  newText: string;
}) => {
  // Use the diff library to get a more intelligent diff
  const differences = diffLines(oldText, newText);

  return (
    <div className="font-mono text-xs">
      {differences.map((part, idx) => {
        const type = part.added
          ? "added"
          : part.removed
          ? "removed"
          : "unchanged";

        // Split the content into lines to display
        const lines = part.value.split("\n");
        // Remove the last empty line that comes from splitting a string that ends with \n
        if (lines[lines.length - 1] === "") lines.pop();

        return lines.map((line, lineIdx) => (
          <div
            key={`${idx}-${lineIdx}`}
            className={tcx(
              "whitespace-pre-wrap font-mono",
              type === "added" && "bg-green-50 text-green-700",
              type === "removed" && "bg-red-50 text-red-700",
            )}
          >
            <span className="inline-block font-mono w-4 mr-2">
              {type === "added" && "+"}
              {type === "removed" && "-"}
            </span>
            {line}
          </div>
        ));
      })}
    </div>
  );
};
