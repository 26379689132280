import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Spinner } from "@incident-ui";
import React from "react";
import { Route, Routes, useParams } from "react-router";
import { IncidentSubscriptionsPage } from "src/components/user-preferences/incident-subscriptions/IncidentSubscriptionsPage";
import { NotificationsPreferencePage } from "src/components/user-preferences/notifications/NotificationsPage";
import { OnCallNotificationsPage } from "src/components/user-preferences/on-call-notifications/OnCallNotificationsPage";

import { useAPI } from "../../utils/swr";
import { ConnectedAccountsPage } from "./connected-accounts/ConnectedAccountsPage";
import { UserPreferencesPage } from "./UserPreferencesPage";

export const UserPreferencesRoute = (): React.ReactElement => {
  const { data: escalationProvidersResponse, isLoading } = useAPI(
    "userPreferencesListManualEscalationProviderOptions",
    undefined,
  );

  const hasAnEscalationProvider =
    (escalationProvidersResponse?.escalation_providers ?? []).length > 0;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route element={<UserPreferencesPage />}>
        <Route
          path=""
          element={
            <OrgAwareNavigate
              replace
              to={
                hasAnEscalationProvider
                  ? `on-call-notifications`
                  : "incident-subscriptions"
              }
            />
          }
        />
        <Route
          path="unsubscribe/monthly-insights-email"
          element={
            <OrgAwareNavigate
              replace
              to={`/user-preferences/notifications/unsubscribe/monthly-insights-email`}
            />
          }
        />
        <Route
          path="unsubscribe/:incidentId"
          element={<RedirectToIncidentUnsubscribe />}
        />
        <Route
          path={"on-call-notifications/*"}
          element={<OnCallNotificationsPage />}
        />
        <Route
          path={"incident-subscriptions/*"}
          element={<IncidentSubscriptionsPage />}
        />
        <Route
          path={"notifications/*"}
          element={<NotificationsPreferencePage />}
        />
        <Route
          path={"connected-accounts/*"}
          element={<ConnectedAccountsPage />}
        />
        <Route path="*" element={<OrgAwareNavigate replace to="" />} />
      </Route>
    </Routes>
  );
};

const RedirectToIncidentUnsubscribe = () => {
  const { incidentId } = useParams();
  return (
    <OrgAwareNavigate
      replace
      to={`/user-preferences/incident-subscriptions/unsubscribe/${incidentId}`}
    />
  );
};
