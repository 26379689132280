import cx from "classnames";
import React, {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
} from "react";

export const Input = React.forwardRef(
  (
    {
      className,
      label,
      title,
      id,
      // children breaks since our react versions aren't the same, but inputs shouldn't have children anyway
      children: _children,
      ...props
    }: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & { className?: string; title?: string } & (
        | { label?: never; id?: never }
        // If you have a label, we require an ID to link the two up
        | { label: React.ReactNode; id: string }
      ),
    ref: ForwardedRef<HTMLInputElement>,
  ): React.ReactElement => {
    return (
      <div className="flex flex-col space-y-3">
        {label && (
          <label
            className="text-content-primary dark:text-slate-50 text-sm font-medium"
            htmlFor={id}
          >
            <>{label}</>
          </label>
        )}
        <input
          ref={ref}
          {...props}
          title={title ? title : typeof label === "string" ? label : undefined}
          className={cx(
            "px-3 py-2",
            "rounded-lg border text-sm w-full",
            // Suppress the default highlights when pressed on mobile
            "!ring-0 focus:!ring-0 focus:!ring-slate-200 focus:!border-slate-200 focus:!outline-none",
            "hover:!border-slate-200 hover:!ring-slate-200",
            "dark:hover:!border-slate-600 dark:hover:!ring-slate-600",
            "dark:focus:!border-slate-600 dark:focus:!ring-slate-600",
            "bg-white border-stroke text-content-primary placeholder:text-slate-300",
            "dark:bg-slate-900 dark:border-slate-700 dark:text-slate-200 dark:placeholder:text-slate-600",
            className,
          )}
        />
      </div>
    );
  },
);

Input.displayName = "Input";
