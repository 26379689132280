/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReplica,
    ScheduleReplicaFromJSON,
    ScheduleReplicaFromJSONTyped,
    ScheduleReplicaToJSON,
} from './ScheduleReplica';
import {
    ScheduleReplicaUserLink,
    ScheduleReplicaUserLinkFromJSON,
    ScheduleReplicaUserLinkFromJSONTyped,
    ScheduleReplicaUserLinkToJSON,
} from './ScheduleReplicaUserLink';

/**
 * 
 * @export
 * @interface SchedulesSetReplicasResponseBody
 */
export interface SchedulesSetReplicasResponseBody {
    /**
     * 
     * @type {Array<ScheduleReplica>}
     * @memberof SchedulesSetReplicasResponseBody
     */
    schedule_replicas: Array<ScheduleReplica>;
    /**
     * 
     * @type {Array<ScheduleReplicaUserLink>}
     * @memberof SchedulesSetReplicasResponseBody
     */
    user_links: Array<ScheduleReplicaUserLink>;
}

export function SchedulesSetReplicasResponseBodyFromJSON(json: any): SchedulesSetReplicasResponseBody {
    return SchedulesSetReplicasResponseBodyFromJSONTyped(json, false);
}

export function SchedulesSetReplicasResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesSetReplicasResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_replicas': ((json['schedule_replicas'] as Array<any>).map(ScheduleReplicaFromJSON)),
        'user_links': ((json['user_links'] as Array<any>).map(ScheduleReplicaUserLinkFromJSON)),
    };
}

export function SchedulesSetReplicasResponseBodyToJSON(value?: SchedulesSetReplicasResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_replicas': ((value.schedule_replicas as Array<any>).map(ScheduleReplicaToJSON)),
        'user_links': ((value.user_links as Array<any>).map(ScheduleReplicaUserLinkToJSON)),
    };
}

