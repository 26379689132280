/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StaffCallRoute,
    StaffCallRouteFromJSON,
    StaffCallRouteFromJSONTyped,
    StaffCallRouteToJSON,
} from './StaffCallRoute';

/**
 * 
 * @export
 * @interface StaffListCallRoutesResponseBody
 */
export interface StaffListCallRoutesResponseBody {
    /**
     * The pending call routes for the organisation.
     * @type {Array<StaffCallRoute>}
     * @memberof StaffListCallRoutesResponseBody
     */
    call_routes: Array<StaffCallRoute>;
}

export function StaffListCallRoutesResponseBodyFromJSON(json: any): StaffListCallRoutesResponseBody {
    return StaffListCallRoutesResponseBodyFromJSONTyped(json, false);
}

export function StaffListCallRoutesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffListCallRoutesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_routes': ((json['call_routes'] as Array<any>).map(StaffCallRouteFromJSON)),
    };
}

export function StaffListCallRoutesResponseBodyToJSON(value?: StaffListCallRoutesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_routes': ((value.call_routes as Array<any>).map(StaffCallRouteToJSON)),
    };
}

