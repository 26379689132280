/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedImage,
    EnrichedImageFromJSON,
    EnrichedImageFromJSONTyped,
    EnrichedImageToJSON,
} from './EnrichedImage';
import {
    ExternalAtlassianStatuspageIncident,
    ExternalAtlassianStatuspageIncidentFromJSON,
    ExternalAtlassianStatuspageIncidentFromJSONTyped,
    ExternalAtlassianStatuspageIncidentToJSON,
} from './ExternalAtlassianStatuspageIncident';
import {
    ExternalDatadogMonitorAlert,
    ExternalDatadogMonitorAlertFromJSON,
    ExternalDatadogMonitorAlertFromJSONTyped,
    ExternalDatadogMonitorAlertToJSON,
} from './ExternalDatadogMonitorAlert';
import {
    ExternalGitHubPullRequest,
    ExternalGitHubPullRequestFromJSON,
    ExternalGitHubPullRequestFromJSONTyped,
    ExternalGitHubPullRequestToJSON,
} from './ExternalGitHubPullRequest';
import {
    ExternalGitLabMergeRequest,
    ExternalGitLabMergeRequestFromJSON,
    ExternalGitLabMergeRequestFromJSONTyped,
    ExternalGitLabMergeRequestToJSON,
} from './ExternalGitLabMergeRequest';
import {
    ExternalGoogleCalendarEvent,
    ExternalGoogleCalendarEventFromJSON,
    ExternalGoogleCalendarEventFromJSONTyped,
    ExternalGoogleCalendarEventToJSON,
} from './ExternalGoogleCalendarEvent';
import {
    ExternalOpsgenieAlert,
    ExternalOpsgenieAlertFromJSON,
    ExternalOpsgenieAlertFromJSONTyped,
    ExternalOpsgenieAlertToJSON,
} from './ExternalOpsgenieAlert';
import {
    ExternalPagerDutyIncident,
    ExternalPagerDutyIncidentFromJSON,
    ExternalPagerDutyIncidentFromJSONTyped,
    ExternalPagerDutyIncidentToJSON,
} from './ExternalPagerDutyIncident';
import {
    ExternalSentryIssue,
    ExternalSentryIssueFromJSON,
    ExternalSentryIssueFromJSONTyped,
    ExternalSentryIssueToJSON,
} from './ExternalSentryIssue';
import {
    ExternalZendeskTicket,
    ExternalZendeskTicketFromJSON,
    ExternalZendeskTicketFromJSONTyped,
    ExternalZendeskTicketToJSON,
} from './ExternalZendeskTicket';

/**
 * 
 * @export
 * @interface ExternalResource
 */
export interface ExternalResource {
    /**
     * 
     * @type {ExternalAtlassianStatuspageIncident}
     * @memberof ExternalResource
     */
    atlassian_statuspage_incident?: ExternalAtlassianStatuspageIncident;
    /**
     * 
     * @type {Date}
     * @memberof ExternalResource
     */
    created_at: Date;
    /**
     * 
     * @type {ExternalDatadogMonitorAlert}
     * @memberof ExternalResource
     */
    datadog_monitor_alert?: ExternalDatadogMonitorAlert;
    /**
     * ID of the resource in the external system
     * @type {string}
     * @memberof ExternalResource
     */
    external_id: string;
    /**
     * 
     * @type {ExternalGitHubPullRequest}
     * @memberof ExternalResource
     */
    github_pull_request?: ExternalGitHubPullRequest;
    /**
     * 
     * @type {ExternalGitLabMergeRequest}
     * @memberof ExternalResource
     */
    gitlab_merge_request?: ExternalGitLabMergeRequest;
    /**
     * 
     * @type {ExternalGoogleCalendarEvent}
     * @memberof ExternalResource
     */
    google_calendar_event?: ExternalGoogleCalendarEvent;
    /**
     * Unique internal ID of the external resource
     * @type {string}
     * @memberof ExternalResource
     */
    id: string;
    /**
     * 
     * @type {EnrichedImage}
     * @memberof ExternalResource
     */
    image?: EnrichedImage;
    /**
     * 
     * @type {ExternalOpsgenieAlert}
     * @memberof ExternalResource
     */
    opsgenie_alert?: ExternalOpsgenieAlert;
    /**
     * 
     * @type {ExternalPagerDutyIncident}
     * @memberof ExternalResource
     */
    pager_duty_incident?: ExternalPagerDutyIncident;
    /**
     * URL of the resource
     * @type {string}
     * @memberof ExternalResource
     */
    permalink: string;
    /**
     * E.g. PagerDuty: the external system that holds the resource
     * @type {string}
     * @memberof ExternalResource
     */
    resource_type: ExternalResourceResourceTypeEnum;
    /**
     * the human readable label for this resource type
     * @type {string}
     * @memberof ExternalResource
     */
    resource_type_label: string;
    /**
     * 
     * @type {ExternalSentryIssue}
     * @memberof ExternalResource
     */
    sentry_issue?: ExternalSentryIssue;
    /**
     * Title of resource
     * @type {string}
     * @memberof ExternalResource
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalResource
     */
    updated_at: Date;
    /**
     * 
     * @type {ExternalZendeskTicket}
     * @memberof ExternalResource
     */
    zendesk_ticket?: ExternalZendeskTicket;
}

/**
* @export
* @enum {string}
*/
export enum ExternalResourceResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert',
    DatadogMonitorAlert = 'datadog_monitor_alert',
    GithubPullRequest = 'github_pull_request',
    GitlabMergeRequest = 'gitlab_merge_request',
    SentryIssue = 'sentry_issue',
    JiraIssue = 'jira_issue',
    AtlassianStatuspageIncident = 'atlassian_statuspage_incident',
    ZendeskTicket = 'zendesk_ticket',
    GoogleCalendarEvent = 'google_calendar_event',
    OutlookCalendarEvent = 'outlook_calendar_event',
    SlackFile = 'slack_file',
    Scrubbed = 'scrubbed',
    StatuspageIncident = 'statuspage_incident'
}

export function ExternalResourceFromJSON(json: any): ExternalResource {
    return ExternalResourceFromJSONTyped(json, false);
}

export function ExternalResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_statuspage_incident': !exists(json, 'atlassian_statuspage_incident') ? undefined : ExternalAtlassianStatuspageIncidentFromJSON(json['atlassian_statuspage_incident']),
        'created_at': (new Date(json['created_at'])),
        'datadog_monitor_alert': !exists(json, 'datadog_monitor_alert') ? undefined : ExternalDatadogMonitorAlertFromJSON(json['datadog_monitor_alert']),
        'external_id': json['external_id'],
        'github_pull_request': !exists(json, 'github_pull_request') ? undefined : ExternalGitHubPullRequestFromJSON(json['github_pull_request']),
        'gitlab_merge_request': !exists(json, 'gitlab_merge_request') ? undefined : ExternalGitLabMergeRequestFromJSON(json['gitlab_merge_request']),
        'google_calendar_event': !exists(json, 'google_calendar_event') ? undefined : ExternalGoogleCalendarEventFromJSON(json['google_calendar_event']),
        'id': json['id'],
        'image': !exists(json, 'image') ? undefined : EnrichedImageFromJSON(json['image']),
        'opsgenie_alert': !exists(json, 'opsgenie_alert') ? undefined : ExternalOpsgenieAlertFromJSON(json['opsgenie_alert']),
        'pager_duty_incident': !exists(json, 'pager_duty_incident') ? undefined : ExternalPagerDutyIncidentFromJSON(json['pager_duty_incident']),
        'permalink': json['permalink'],
        'resource_type': json['resource_type'],
        'resource_type_label': json['resource_type_label'],
        'sentry_issue': !exists(json, 'sentry_issue') ? undefined : ExternalSentryIssueFromJSON(json['sentry_issue']),
        'title': json['title'],
        'updated_at': (new Date(json['updated_at'])),
        'zendesk_ticket': !exists(json, 'zendesk_ticket') ? undefined : ExternalZendeskTicketFromJSON(json['zendesk_ticket']),
    };
}

export function ExternalResourceToJSON(value?: ExternalResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_statuspage_incident': ExternalAtlassianStatuspageIncidentToJSON(value.atlassian_statuspage_incident),
        'created_at': (value.created_at.toISOString()),
        'datadog_monitor_alert': ExternalDatadogMonitorAlertToJSON(value.datadog_monitor_alert),
        'external_id': value.external_id,
        'github_pull_request': ExternalGitHubPullRequestToJSON(value.github_pull_request),
        'gitlab_merge_request': ExternalGitLabMergeRequestToJSON(value.gitlab_merge_request),
        'google_calendar_event': ExternalGoogleCalendarEventToJSON(value.google_calendar_event),
        'id': value.id,
        'image': EnrichedImageToJSON(value.image),
        'opsgenie_alert': ExternalOpsgenieAlertToJSON(value.opsgenie_alert),
        'pager_duty_incident': ExternalPagerDutyIncidentToJSON(value.pager_duty_incident),
        'permalink': value.permalink,
        'resource_type': value.resource_type,
        'resource_type_label': value.resource_type_label,
        'sentry_issue': ExternalSentryIssueToJSON(value.sentry_issue),
        'title': value.title,
        'updated_at': (value.updated_at.toISOString()),
        'zendesk_ticket': ExternalZendeskTicketToJSON(value.zendesk_ticket),
    };
}

