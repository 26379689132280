/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentFormsCreateLifecycleElementRequestBody
 */
export interface IncidentFormsCreateLifecycleElementRequestBody {
    /**
     * Whether the user can select no value for this element
     * @type {boolean}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    can_select_no_value: boolean;
    /**
     * ID of the custom field (if required)
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    custom_field_id?: string;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    default_value?: EngineParamBindingPayload;
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    description?: TextNode;
    /**
     * The type of element
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    element_type: IncidentFormsCreateLifecycleElementRequestBodyElementTypeEnum;
    /**
     * ID of the associated incident form
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    incident_form_id: string;
    /**
     * ID of the incident role (if required)
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    incident_role_id?: string;
    /**
     * ID of the incident timestamp (if required)
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    incident_timestamp_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    required_if: IncidentFormsCreateLifecycleElementRequestBodyRequiredIfEnum;
    /**
     * 
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    required_if_condition_groups: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentFormsCreateLifecycleElementRequestBody
     */
    show_if_condition_groups: Array<ConditionGroupPayload>;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormsCreateLifecycleElementRequestBodyElementTypeEnum {
    Name = 'name',
    Status = 'status',
    Severity = 'severity',
    IncidentRole = 'incident_role',
    IncidentType = 'incident_type',
    Summary = 'summary',
    CustomField = 'custom_field',
    Timestamp = 'timestamp',
    UpdateMessage = 'update_message',
    NextUpdateIn = 'next_update_in',
    Triage = 'triage',
    Visibility = 'visibility',
    Divider = 'divider',
    Text = 'text',
    IncidentAttachments = 'incident_attachments',
    SlackChannel = 'slack_channel',
    AnnounceRetroIncident = 'announce_retro_incident',
    EnterPostIncidentFlow = 'enter_post_incident_flow'
}/**
* @export
* @enum {string}
*/
export enum IncidentFormsCreateLifecycleElementRequestBodyRequiredIfEnum {
    CheckEngineConfig = 'check_engine_config',
    AlwaysRequire = 'always_require',
    NeverRequire = 'never_require'
}

export function IncidentFormsCreateLifecycleElementRequestBodyFromJSON(json: any): IncidentFormsCreateLifecycleElementRequestBody {
    return IncidentFormsCreateLifecycleElementRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsCreateLifecycleElementRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsCreateLifecycleElementRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_select_no_value': json['can_select_no_value'],
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingPayloadFromJSON(json['default_value']),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'element_type': json['element_type'],
        'incident_form_id': json['incident_form_id'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'incident_timestamp_id': !exists(json, 'incident_timestamp_id') ? undefined : json['incident_timestamp_id'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'required_if': json['required_if'],
        'required_if_condition_groups': ((json['required_if_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'show_if_condition_groups': ((json['show_if_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
    };
}

export function IncidentFormsCreateLifecycleElementRequestBodyToJSON(value?: IncidentFormsCreateLifecycleElementRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_select_no_value': value.can_select_no_value,
        'custom_field_id': value.custom_field_id,
        'default_value': EngineParamBindingPayloadToJSON(value.default_value),
        'description': TextNodeToJSON(value.description),
        'element_type': value.element_type,
        'incident_form_id': value.incident_form_id,
        'incident_role_id': value.incident_role_id,
        'incident_timestamp_id': value.incident_timestamp_id,
        'placeholder': value.placeholder,
        'required_if': value.required_if,
        'required_if_condition_groups': ((value.required_if_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'show_if_condition_groups': ((value.show_if_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
    };
}

