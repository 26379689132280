/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterestingChannel,
    InterestingChannelFromJSON,
    InterestingChannelFromJSONTyped,
    InterestingChannelToJSON,
} from './InterestingChannel';

/**
 * 
 * @export
 * @interface AICreateInterestingChannelResponseBody
 */
export interface AICreateInterestingChannelResponseBody {
    /**
     * 
     * @type {InterestingChannel}
     * @memberof AICreateInterestingChannelResponseBody
     */
    channel: InterestingChannel;
}

export function AICreateInterestingChannelResponseBodyFromJSON(json: any): AICreateInterestingChannelResponseBody {
    return AICreateInterestingChannelResponseBodyFromJSONTyped(json, false);
}

export function AICreateInterestingChannelResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AICreateInterestingChannelResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': InterestingChannelFromJSON(json['channel']),
    };
}

export function AICreateInterestingChannelResponseBodyToJSON(value?: AICreateInterestingChannelResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': InterestingChannelToJSON(value.channel),
    };
}

