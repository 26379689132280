import {
  CatalogConfig,
  CatalogRelation,
  CatalogType,
  CatalogTypeAttributeModeEnum,
} from "@incident-io/api";
import { CatalogSetupWidgetProps } from "@incident-shared/catalog/CatalogSetupWidget";
import { captureException } from "@sentry/react";

import { useProductAccess } from "../../../hooks/useProductAccess";
import { useAPI } from "../../../utils/swr";
import { CatalogSetupRoutes } from "../../catalog/CatalogRoute";

export type CatalogSetup = {
  props?: CatalogSetupWidgetProps;
  catalogTypes: CatalogType[];
  catalogRelations?: CatalogRelation[];
  catalogConfig: CatalogConfig;
  dashboardEscalationCatalogTypes?: CatalogType[];
};

export const useCatalogSetup = ({
  escalationPathId,
}: {
  escalationPathId?: string;
}): CatalogSetup | null => {
  const {
    data: catalogConfigData,
    isLoading: catalogConfigLoading,
    error: catalogConfigError,
  } = useAPI(
    "catalogGetCatalogConfig",
    {},
    {
      revalidateOnMount: true,
    },
  );

  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const {
    data: catalogRelationsData,
    isLoading: catalogRelationsLoading,
    error: catalogRelationsError,
  } = useAPI(
    escalationPathId ? "catalogListRelations" : null,
    { id: escalationPathId || "" },
    { fallbackData: { catalog_relations: [] } },
  );
  const { hasOnCall } = useProductAccess();

  if (
    !catalogConfigData ||
    catalogConfigLoading ||
    !catalogTypesData ||
    catalogTypesLoading ||
    catalogRelationsLoading
  ) {
    return null;
  }

  if (catalogTypesError || catalogConfigError || catalogRelationsError) {
    captureException(
      catalogTypesError || catalogConfigError || catalogRelationsError,
      {
        tags: { component: "EscalationPathCatalogSetupWidget" },
      },
    );
    return null;
  }

  if (catalogConfigData.config.team_types.length === 0 && hasOnCall) {
    return {
      props: {
        title: "Add teams to Catalog",
        description:
          "It looks like you don’t have teams in your Catalog. We suggest adding teams so you can automatically route escalations to this path from your alerts.",
        fixButton: {
          href: `/catalog/${CatalogSetupRoutes.TeamWizard}`,
          title: "Set up teams",
        },
      },
      catalogTypes: catalogTypesData.catalog_types,
      catalogConfig: catalogConfigData.config,
    };
  }

  if (catalogConfigData.config.escalation_types.length === 0 && hasOnCall) {
    return {
      props: {
        title: "Connect teams to escalation paths",
        description:
          "Your Catalog has teams but they don’t link to escalation paths. We recommend tagging each team with an escalation path so that you can get ahold of the people you need, easily.",
        fixButton: {
          href: `/catalog/${catalogConfigData.config.team_types[0].catalog_type_id}/edit`,
          title: "Link escalation paths",
        },
      },
      catalogTypes: catalogTypesData.catalog_types,
      catalogConfig: catalogConfigData.config,
    };
  }

  const dashboardEscalationCatalogTypes =
    catalogTypesData.catalog_types
      .filter((catalogType) =>
        catalogConfigData.config.escalation_types.some(
          (escalationType) => escalationType.catalog_type_id === catalogType.id,
        ),
      )
      .filter((catalogType) =>
        catalogType.schema.attributes.some(
          (attr) =>
            attr.type === "EscalationPath" &&
            attr.mode === CatalogTypeAttributeModeEnum.Dashboard,
        ),
      ) || [];

  return {
    catalogTypes: catalogTypesData.catalog_types,
    catalogRelations: catalogRelationsData.catalog_relations,
    catalogConfig: catalogConfigData.config,
    dashboardEscalationCatalogTypes: dashboardEscalationCatalogTypes,
  };
};
