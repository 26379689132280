/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReplicaSource,
    ScheduleReplicaSourceFromJSON,
    ScheduleReplicaSourceFromJSONTyped,
    ScheduleReplicaSourceToJSON,
} from './ScheduleReplicaSource';
import {
    ScheduleReplicaUserStatus,
    ScheduleReplicaUserStatusFromJSON,
    ScheduleReplicaUserStatusFromJSONTyped,
    ScheduleReplicaUserStatusToJSON,
} from './ScheduleReplicaUserStatus';

/**
 * 
 * @export
 * @interface ScheduleReplica
 */
export interface ScheduleReplica {
    /**
     * 
     * @type {Date}
     * @memberof ScheduleReplica
     */
    created_at: Date;
    /**
     * Unique internal ID of the schedule replica
     * @type {string}
     * @memberof ScheduleReplica
     */
    id: string;
    /**
     * The last error encountered during syncing
     * @type {string}
     * @memberof ScheduleReplica
     */
    last_sync_error?: string;
    /**
     * When the replica was last successfully synced
     * @type {Date}
     * @memberof ScheduleReplica
     */
    last_synced_at?: Date;
    /**
     * The user that will be used in the replica system whenever NOBODY is scheduled
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_fallback_user_id: string;
    /**
     * Name of replica external provider, e.g. pagerduty
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_provider: ScheduleReplicaReplicaProviderEnum;
    /**
     * ID of replica schedule in external system
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_provider_id: string;
    /**
     * Source schedule
     * @type {string}
     * @memberof ScheduleReplica
     */
    schedule_id: string;
    /**
     * The rotation/layer combinations being replicated
     * @type {Array<ScheduleReplicaSource>}
     * @memberof ScheduleReplica
     */
    sources: Array<ScheduleReplicaSource>;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleReplica
     */
    updated_at: Date;
    /**
     * Status of user mapping for replication
     * @type {Array<ScheduleReplicaUserStatus>}
     * @memberof ScheduleReplica
     */
    user_statuses: Array<ScheduleReplicaUserStatus>;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleReplicaReplicaProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function ScheduleReplicaFromJSON(json: any): ScheduleReplica {
    return ScheduleReplicaFromJSONTyped(json, false);
}

export function ScheduleReplicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'last_sync_error': !exists(json, 'last_sync_error') ? undefined : json['last_sync_error'],
        'last_synced_at': !exists(json, 'last_synced_at') ? undefined : (new Date(json['last_synced_at'])),
        'replica_fallback_user_id': json['replica_fallback_user_id'],
        'replica_provider': json['replica_provider'],
        'replica_provider_id': json['replica_provider_id'],
        'schedule_id': json['schedule_id'],
        'sources': ((json['sources'] as Array<any>).map(ScheduleReplicaSourceFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
        'user_statuses': ((json['user_statuses'] as Array<any>).map(ScheduleReplicaUserStatusFromJSON)),
    };
}

export function ScheduleReplicaToJSON(value?: ScheduleReplica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'last_sync_error': value.last_sync_error,
        'last_synced_at': value.last_synced_at === undefined ? undefined : (value.last_synced_at.toISOString()),
        'replica_fallback_user_id': value.replica_fallback_user_id,
        'replica_provider': value.replica_provider,
        'replica_provider_id': value.replica_provider_id,
        'schedule_id': value.schedule_id,
        'sources': ((value.sources as Array<any>).map(ScheduleReplicaSourceToJSON)),
        'updated_at': (value.updated_at.toISOString()),
        'user_statuses': ((value.user_statuses as Array<any>).map(ScheduleReplicaUserStatusToJSON)),
    };
}

