import { Schedule, WeekdayIntervalWeekdayEnum } from "@incident-io/api";
import { DateTime } from "luxon";
import { ulid } from "ulid";

import { ScheduleUser } from "../schedule-create-edit/ScheduleCreateFormUsersList";
import { getVersionId, scheduleToFormData } from "./marshall";
import { timezoneToCountries } from "./timezoneToCountries";
import {
  CustomHandoverRuleType,
  IntervalData,
  RotaFormData,
  RotaHandoverType,
  RotaId,
  RotationVersions,
  ScheduleFormData,
  WorkingInterval,
} from "./types";

export const getDefaultValues = ({
  initialData,
  userTimezone,
  now,
  isDuplicating,
}: {
  initialData?: Schedule;
  userTimezone: string;
  now: DateTime;
  isDuplicating: boolean;
}): ScheduleFormData => {
  if (initialData) {
    return scheduleToFormData({
      schedule: initialData,
      now,
      isDuplicating,
    });
  }

  const newRota = getNewRota(-1);
  const rotaId = newRota.id as string;
  const rotaVersionId = newRota.version_id;
  const rotations: Record<RotaId, RotationVersions> = {
    [rotaId]: {
      [rotaVersionId]: newRota,
    },
  };

  return {
    name: "",
    timezone: userTimezone,
    rotations: rotations,
    selected_rotation_versions: {
      [rotaId]: rotaVersionId,
    },
    holidays_public_config: {
      country_codes: timezoneToCountries[userTimezone] || [],
    },
    team_ids: [],
  };
};

export const getDefaultWorkingInterval = (): WorkingInterval => ({
  days: Object.values(
    WeekdayIntervalWeekdayEnum,
    // We want the default to be Mon-Fri, so we filter the weekend here
  )
    .filter(
      (day) =>
        ![
          WeekdayIntervalWeekdayEnum.Saturday,
          WeekdayIntervalWeekdayEnum.Sunday,
        ].includes(day),
    )
    .reduce(
      (acc, cur) => {
        acc[cur] = true;
        return acc;
      },
      {} as WorkingInterval["days"],
    ),
  start_time: "09:00",
  end_time: "17:00",
});

export const getNewRota = (index: number): RotaFormData => {
  const defaultHandoverTime = new Date();
  defaultHandoverTime.setHours(9, 0, 0, 0);

  return {
    id: ulid(),
    version_id: getVersionId({ effectiveFrom: null, isCurrentVersion: true }),
    name: index === -1 ? `Rotation` : `Rotation ${index}`,
    users: Array<ScheduleUser>(),
    handover_start_at: defaultHandoverTime,
    layer_count: 1,
    has_working_intervals: "all_day" as const,
    working_intervals: [getDefaultWorkingInterval()] as IntervalData,
    custom_handovers: [
      {
        handover_interval: "1",
        handover_interval_type: CustomHandoverRuleType.Weekly,
      },
    ],
    rota_handover_type: RotaHandoverType.Weekly,
    is_deferred: "false", // Default to immediate changes
  };
};
