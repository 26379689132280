import {
  AIStaffListAISpansTypeEnum,
  AIStaffListEvaluationNotesResourceTypeEnum,
  Investigation,
  InvestigationArtifact,
  InvestigationCheck,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  LoadingWrapper,
  LocalDateTime,
  Markdown,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  TabPane,
  TabSection,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { Mrkdwn } from "@incident-ui/Markdown/Mrkdwn";
import { groupBy } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { CommsChannelButton } from "src/components/legacy/incident/header/CommsChannelButton";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";

import { AISpanTrace } from "../ai-requests/AISpanTrace";
import { CodeViewer } from "../common/CodeViewer";
import { LabeledValue } from "../common/LabeledValue";
import { TraceLink } from "../common/utils";
import { EvaluationNotesWidget } from "../evaluation-notes/EvaluationNotesWidget";
import { GroundTruthSectionController } from "../ground-truths/GroundTruthSection";
import { EvaluationScorecardSectionController } from "../scorecards/EvaluationScorecardSection";
import { InvestigationTrace } from "./InvestigationTrace";
import { TuneableParameters } from "./TuneableParameterEditorV2";

export const WorkbenchInvestigationPage = () => {
  const { investigationId } = useParams<{ investigationId: string }>();
  const navigate = useOrgAwareNavigate();

  const { data, isLoading } = useAPI(
    "aIShowInvestigation",
    {
      id: investigationId || "",
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      // Poll continually while the investigation is pending or working.
      refreshInterval: (currentData) =>
        currentData?.investigation?.state === "pending" ||
        currentData?.investigation?.state === "working"
          ? 3000
          : 0,
    },
  );

  if (!investigationId) {
    navigate("/workbench");
    return null;
  }

  return (
    <WorkbenchSubPageWrapper
      backHref="/workbench/investigations"
      accessory={
        <>
          <Button
            href={`/incidents/${data?.investigation.incident_external_id}`}
            icon={IconEnum.ExternalLink}
            analyticsTrackingId={null}
            openInNewTab
          >
            INC{data ? `-${data.investigation.incident_external_id}` : ""}
          </Button>
          <CommsChannelButton
            incidentId={data?.investigation.incident_id ?? null}
          />
        </>
      }
    >
      <EvaluationNotesWidget
        resourceId={investigationId}
        resourceType={AIStaffListEvaluationNotesResourceTypeEnum.Investigation}
        parentId={data?.investigation.incident_id}
      />
      <LoadingWrapper loading={isLoading}>
        <div className="flex flex-col gap-6">
          {data?.investigation && (
            <>
              <TopLevelInfo investigation={data?.investigation} />

              <div className="text-2xl-bold">Ground truth</div>
              <GroundTruthSectionController
                incidentId={data?.investigation.incident_id}
                includeMetadata={true} // We want to be able to show metadata for each ground truth.
              />

              <div className="text-2xl-bold">Scorecard</div>
              <EvaluationScorecardSectionController
                incidentId={data?.investigation.incident_id}
                investigationId={investigationId}
              />

              <div className="text-2xl-bold">Trace</div>
              <InvestigationTrace
                investigation={data?.investigation}
                checks={data.checks}
              />

              <div className="text-2xl-bold">Checks</div>
              <InvestigationChecks checks={data?.checks || []} />
            </>
          )}
        </div>
      </LoadingWrapper>
    </WorkbenchSubPageWrapper>
  );
};

const TopLevelInfo = ({ investigation }: { investigation: Investigation }) => {
  if (!investigation) {
    return null;
  }

  return (
    <ContentBox className="p-4 grid grid-cols-2 w-full gap-4">
      <div className="flex flex flex-col gap-2">
        <LabeledValue
          label="ID"
          value={<SingleLineCodeBlock code={investigation.id} />}
        />
        <LabeledValue
          label="Cost"
          value={"$" + (investigation.total_cost_in_cents / 100).toFixed(2)}
        />
        <LabeledValue
          label="Incident"
          value={`INC-${investigation.incident_external_id}: ${investigation.incident_name}`}
        />
        <LabeledValue label="State" value={investigation.state} />
        <LabeledValue
          label="Summary"
          value={
            <div className="whitespace-pre-wrap">
              <Mrkdwn text={investigation.summary} />
            </div>
          }
        />
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-base-bold">Tuneables</span>
        <TuneableParameters parameters={investigation.tuneables} />
      </div>
    </ContentBox>
  );
};

const InvestigationChecks = ({ checks }: { checks: InvestigationCheck[] }) => {
  return (
    <StackedList>
      {checks.map((check) => (
        <SingleCheck key={check.id} check={check} />
      ))}
    </StackedList>
  );
};

const SingleCheck = ({ check }: { check: InvestigationCheck }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded((expanded) => !expanded);

  return (
    <div className="flex flex-col px-4" key={check.id}>
      <div
        className="flex flex-between gap-2 w-full cursor-pointer group"
        onClick={toggle}
      >
        <div className="flex gap-6 py-4">
          <LocalDateTime timestamp={check.created_at} className="font-medium" />
          <div className="flex">
            <Icon
              id={IconEnum.Robot}
              className="w-6 h-6 p-1 mr-1 rounded-full bg-slate-50  "
            />
            <div className="whitespace-pre-wrap">{check.title}</div>
          </div>
        </div>
        <div className="grow" />
        {!!check.trace_id && <TraceLink traceID={check.trace_id} />}
        {!!check.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />
            {(check.total_cost_in_cents / 100).toFixed(2)}$
          </div>
        )}
        {!!check.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {check.duration_seconds.toFixed(2)}s
          </div>
        )}

        <Icon
          id={expanded ? IconEnum.Collapse : IconEnum.Expand}
          className="text-slate-600 group-hover:text-slate-900 transition"
        />
      </div>
      {/* Expandable Section */}
      {expanded && <SingleCheckDetails check={check} />}
    </div>
  );
};

const SingleCheckDetails = ({ check }: { check: InvestigationCheck }) => {
  const { data, isLoading } = useAPI(
    "aIStaffListAISpans",
    {
      resourceId: check.id,
      type: AIStaffListAISpansTypeEnum.InvestigationCheck,
      childSpanDepth: 50,
    },
    {
      fallbackData: { spans: [] },
    },
  );

  if (isLoading || !data?.spans) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-4">
      <AISpanTrace spans={data?.spans} />
      <hr />
      <Artifacts artifacts={check.artifacts ?? []} />
      <hr />
      {check.error_message && (
        <Callout
          theme={CalloutTheme.Danger}
          title="Error"
          subtitle={check.error_message}
        />
      )}
      <TabSection
        withIndicator
        defaultTab="summary"
        tabs={[
          {
            id: "summary",
            label: "Summary",
          },
          {
            id: "json",
            label: "JSON Result (internal)",
          },
        ]}
      >
        <TabPane tabId="summary">
          <div className="py-6">
            {check.summary ? (
              <Markdown>{check.summary}</Markdown>
            ) : (
              <EmptyState content="No result" />
            )}
          </div>
        </TabPane>
        <TabPane tabId="json">
          {check.result ? (
            <CodeViewer mode="json" content={check.result} />
          ) : (
            <EmptyState content="No result" />
          )}
        </TabPane>
      </TabSection>
    </div>
  );
};

export const Artifacts = ({
  artifacts,
}: {
  artifacts: InvestigationArtifact[];
}) => {
  const [selectedArtifactId, setSelectedArtifactId] = useState<string>();

  if (artifacts.length === 0) {
    return <div>No artifacts</div>;
  }

  const artifactsByLevel = groupBy(artifacts, (artifact) => artifact.level);
  const selectedArtifact = artifacts.find(
    (artifact) => artifact.id === selectedArtifactId,
  );

  return (
    <>
      <div className="flex flex-row gap-4 flex-wrap">
        {Object.entries(artifactsByLevel).map(([level, levelArtifacts]) => (
          <ArtifactList
            key={level}
            level={level}
            artifacts={levelArtifacts}
            onArtifactSelect={setSelectedArtifactId}
          />
        ))}
      </div>
      <Modal
        title={`Artifact: ${selectedArtifact?.name}`}
        isOpen={!!selectedArtifact}
        analyticsTrackingId={null}
        onClose={() => setSelectedArtifactId(undefined)}
        maximised
      >
        <ModalContent>
          <div className="flex flex-col gap-4">
            <CodeViewer
              mode="text"
              title="Content"
              content={selectedArtifact?.content ?? ""}
            />
            {selectedArtifact?.image_url && (
              <img src={selectedArtifact.image_url} alt="" />
            )}
          </div>
        </ModalContent>
        <ModalFooter
          hideConfirmButton={true}
          confirmButtonType="button"
          onConfirm={() => setSelectedArtifactId(undefined)}
          onClose={() => setSelectedArtifactId(undefined)}
          cancelButtonText={"Close"}
          gadgetPosition="left"
          gadget={
            selectedArtifact?.source_url ? (
              <Button
                theme={ButtonTheme.Primary}
                analyticsTrackingId={null}
                href={selectedArtifact.source_url}
                openInNewTab
                className="w-fit"
                icon={IconEnum.ExternalLink}
              >
                View source
              </Button>
            ) : undefined
          }
        />
      </Modal>
    </>
  );
};

export const ArtifactList = ({
  level,
  artifacts,
  onArtifactSelect,
}: {
  level: string;
  artifacts: InvestigationArtifact[];
  onArtifactSelect: (id: string) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(level !== "debug");
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <Badge theme={BadgeTheme.Info} size={BadgeSize.Small}>
          level:{level} ({artifacts.length})
        </Badge>
        <Button
          analyticsTrackingId={null}
          icon={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
          onClick={() => setIsExpanded((expanded) => !expanded)}
          theme={ButtonTheme.Naked}
        >
          {isExpanded ? "Collapse" : "See all"}
        </Button>
      </div>

      <div className="flex flex-row gap-4 flex-wrap">
        {isExpanded && (
          <>
            {artifacts.map((artifact) => (
              <div
                className="cursor-pointer rounded-lg shadow-md p-4 bg-white border border-stroke-primary max-w-80"
                onClick={() => onArtifactSelect(artifact.id)}
                key={artifact.id}
              >
                <div className="text-sm-bold">{artifact.name}</div>
                <div className="text-content-primary truncate">
                  {artifact.content}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
