/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationBacktest
 */
export interface EvaluationBacktest {
    /**
     * Type of backtest to run
     * @type {string}
     * @memberof EvaluationBacktest
     */
    backtest_type: EvaluationBacktestBacktestTypeEnum;
    /**
     * ID of another backtest to compare against
     * @type {string}
     * @memberof EvaluationBacktest
     */
    compare_with_backtest_id?: string;
    /**
     * When the backtest was completed
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    completed_at?: Date;
    /**
     * When the backtest was created
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    created_at: Date;
    /**
     * Number of investigations that can be run before requiring human intervention
     * @type {number}
     * @memberof EvaluationBacktest
     */
    credit?: number;
    /**
     * Optional ID of the evaluation dataset this backtest was created from
     * @type {string}
     * @memberof EvaluationBacktest
     */
    dataset_id?: string;
    /**
     * Unique identifier of the backtest
     * @type {string}
     * @memberof EvaluationBacktest
     */
    id: string;
    /**
     * Type of investigation plan this backtest is running
     * @type {string}
     * @memberof EvaluationBacktest
     */
    investigation_plan_type?: string;
    /**
     * Overrides for the model configuration (with a key:value map of prompt -> model)
     * @type {{ [key: string]: string; }}
     * @memberof EvaluationBacktest
     */
    model_overrides: { [key: string]: string; };
    /**
     * Optional notes about the rationale for this backtest
     * @type {string}
     * @memberof EvaluationBacktest
     */
    notes?: string;
    /**
     * Number of processed entries in this backtest
     * @type {number}
     * @memberof EvaluationBacktest
     */
    processed_entries: number;
    /**
     * Current state of the backtest
     * @type {string}
     * @memberof EvaluationBacktest
     */
    state: EvaluationBacktestStateEnum;
    /**
     * Total number of entries in this backtest
     * @type {number}
     * @memberof EvaluationBacktest
     */
    total_entries: number;
    /**
     * Overrides for the investigation tuneables to use for this backtest
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof EvaluationBacktest
     */
    tuneables_overrides: { [key: string]: { [key: string]: string; }; };
    /**
     * When the backtest was last updated
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationBacktestBacktestTypeEnum {
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    SearchCodeChanges = 'search_code_changes',
    SearchIncidents = 'search_incidents'
}/**
* @export
* @enum {string}
*/
export enum EvaluationBacktestStateEnum {
    Pending = 'pending',
    Running = 'running',
    Paused = 'paused',
    Complete = 'complete',
    Error = 'error'
}

export function EvaluationBacktestFromJSON(json: any): EvaluationBacktest {
    return EvaluationBacktestFromJSONTyped(json, false);
}

export function EvaluationBacktestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationBacktest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_type': json['backtest_type'],
        'compare_with_backtest_id': !exists(json, 'compare_with_backtest_id') ? undefined : json['compare_with_backtest_id'],
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': (new Date(json['created_at'])),
        'credit': !exists(json, 'credit') ? undefined : json['credit'],
        'dataset_id': !exists(json, 'dataset_id') ? undefined : json['dataset_id'],
        'id': json['id'],
        'investigation_plan_type': !exists(json, 'investigation_plan_type') ? undefined : json['investigation_plan_type'],
        'model_overrides': json['model_overrides'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'processed_entries': json['processed_entries'],
        'state': json['state'],
        'total_entries': json['total_entries'],
        'tuneables_overrides': json['tuneables_overrides'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationBacktestToJSON(value?: EvaluationBacktest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_type': value.backtest_type,
        'compare_with_backtest_id': value.compare_with_backtest_id,
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'credit': value.credit,
        'dataset_id': value.dataset_id,
        'id': value.id,
        'investigation_plan_type': value.investigation_plan_type,
        'model_overrides': value.model_overrides,
        'notes': value.notes,
        'processed_entries': value.processed_entries,
        'state': value.state,
        'total_entries': value.total_entries,
        'tuneables_overrides': value.tuneables_overrides,
        'updated_at': (value.updated_at.toISOString()),
    };
}

