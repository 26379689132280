import { AIConfig, ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { AIPrivacyButton } from "./AIFeatureCard";
import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
  ToggleAIFeatureButton,
} from "./AIFeatureConfigurationDrawer";
import { AISuggestionFeatureDrawerProps } from "./useAIFeatures";

export const CopilotDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling copilot",
    },
  );
  const enabled = aiConfigResp?.config.copilot_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp || !aiConfigResp.config}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleAIFeatureButton
                enabled={!!enabled}
                disableModalTitle="Disable copilot"
                disableModalContent="This means you'll no longer be able to use @incident in your incident channels"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      copilot_enabled: true,
                    },
                  });
                }}
                onDisable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp?.config,
                      copilot_enabled: false,
                    },
                  });
                }}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Decline and follow-up"
              description={`If you're woken up by an alert that's a false alarm, try asking "@incident can you decline this and create a follow up to stop it paging?"`}
              icon={IconEnum.FollowUps}
            />
            <AIProductMarketingCard
              title="Draft updates and summaries"
              description={`When things go wrong they move fast. You can update your team by asking "@incident can you update this incident?"`}
              icon={IconEnum.QuoteAI}
            />
            <AIProductMarketingCard
              title="Pause incidents"
              description={`Need to put something down for a few hours or days as it's not pressing? Try asking "@incident pause this til monday"`}
              icon={IconEnum.Pause}
            />
            <AIProductMarketingCard
              title="Get answers without leaving Slack"
              description={`Ask technical questions, from debugging issues to understanding system behaviour. Try asking "@incident, what does 'skip locked' mean?"`}
              icon={IconEnum.QuestionMark}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
