/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntercomConfig
 */
export interface IntercomConfig {
    /**
     * The region where Intercom data is stored
     * @type {string}
     * @memberof IntercomConfig
     */
    intercom_data_storage_region: string;
    /**
     * The ID of the Intercom workspace
     * @type {string}
     * @memberof IntercomConfig
     */
    intercom_workspace_id: string;
    /**
     * The name of the Intercom workspace
     * @type {string}
     * @memberof IntercomConfig
     */
    intercom_workspace_name: string;
    /**
     * The status page to display in Intercom
     * @type {string}
     * @memberof IntercomConfig
     */
    selected_status_page?: string;
}

export function IntercomConfigFromJSON(json: any): IntercomConfig {
    return IntercomConfigFromJSONTyped(json, false);
}

export function IntercomConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntercomConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intercom_data_storage_region': json['intercom_data_storage_region'],
        'intercom_workspace_id': json['intercom_workspace_id'],
        'intercom_workspace_name': json['intercom_workspace_name'],
        'selected_status_page': !exists(json, 'selected_status_page') ? undefined : json['selected_status_page'],
    };
}

export function IntercomConfigToJSON(value?: IntercomConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intercom_data_storage_region': value.intercom_data_storage_region,
        'intercom_workspace_id': value.intercom_workspace_id,
        'intercom_workspace_name': value.intercom_workspace_name,
        'selected_status_page': value.selected_status_page,
    };
}

