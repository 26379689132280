import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentStatusSummaryWorstComponentStatusEnum,
  StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum,
} from "@incident-io/api";
import cx from "classnames";

import { assertUnreachable } from "../../helpers";
import { useTranslations } from "../../use-translations";
import { Tooltip } from "../Tooltip";

import { FullyOperationalIcon } from "../Icons/FullyOperationalIcon";
import { DegradedPerformanceIcon } from "../Icons/DegradedPerformanceIcon";
import { PartialOutageIcon } from "../Icons/PartialOutageIcon";
import { MaintenanceIcon } from "../Icons/MaintenanceIcon";
import { FullOutageIcon } from "../Icons/FullOutageIcon";


type ComponentStatusIconProps = {
  className?: string;
  flavour?: "ok" | "yellow" | "orange" | "alarmalade" | "blue";
  componentStatus?:
    | StatusPageContentComponentImpactStatusEnum
    | StatusPageAffectedComponentStatusEnum
    | StatusPageContentStatusSummaryWorstComponentStatusEnum
    | StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum
    | "ok";
  tooltip?: boolean;
};

// Contextual icon depending on the state:
// - ok: green tick in circle
// - yellow/orange: exclaimation point in triangle
// - alarmalade: same as above, but alarmalade
// - blue: wrench icon for maintenance
export const ComponentStatusIcon = ({
  className,
  flavour = "ok",
  componentStatus,
  tooltip = false,
}: ComponentStatusIconProps): React.ReactElement => {
  if (componentStatus) {
    switch (componentStatus) {
      case StatusPageContentComponentImpactStatusEnum.PartialOutage:
      case StatusPageAffectedComponentStatusEnum.PartialOutage:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.PartialOutage:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.PartialOutage:
        flavour = "orange";
        break;

      case StatusPageContentComponentImpactStatusEnum.DegradedPerformance:
      case StatusPageAffectedComponentStatusEnum.DegradedPerformance:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.DegradedPerformance:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.DegradedPerformance:
        flavour = "yellow";
        break;

      case StatusPageContentComponentImpactStatusEnum.FullOutage:
      case StatusPageAffectedComponentStatusEnum.FullOutage:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.FullOutage:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.FullOutage:
        flavour = "alarmalade";
        break;

      case StatusPageContentComponentImpactStatusEnum.UnderMaintenance:
      case StatusPageAffectedComponentStatusEnum.UnderMaintenance:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.UnderMaintenance:
        flavour = "blue";
        break;

      case StatusPageAffectedComponentStatusEnum.Operational:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.Operational:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.Operational:
      case "ok":
        flavour = "ok";
        break;

      default:
        assertUnreachable(componentStatus);
    }
  }

  let Icon: React.FC<{ className?: string }> | undefined = undefined;

  if (flavour === "ok") {
    Icon = FullyOperational;
  }
  if (flavour === "yellow") {
    Icon = DegradedPerformance;
  }
  if (flavour === "orange") {
    Icon = PartialOutage;
  }
  if (flavour === "alarmalade") {
    Icon = FullOutage;
  }
  if (flavour === "blue") {
    Icon = Maintenance;
  }

  const t = useTranslations("Icons");
  if (!Icon) {
    throw new Error(`unreachable: ${componentStatus}`);
  }

  return tooltip ? (
    <Tooltip content={t(flavour)}>
      <Icon className={className} />
    </Tooltip>
  ) : (
    <Icon className={className} />
  );
};

export const FullyOperational = ({ className }: { className?: string }) => (
  <FullyOperationalIcon className={className} />
);

export const DegradedPerformance = ({ className }: { className?: string }) => (
  <DegradedPerformanceIcon className={className} />
);

export const Maintenance = ({ className }: { className?: string }) => (
  <MaintenanceIcon className={className} />
);

export const PartialOutage = ({ className }: { className?: string }) => (
  <PartialOutageIcon className={className} />
);

export const FullOutage = ({ className }: { className?: string }) => (
  <FullOutageIcon className={className} />
);
