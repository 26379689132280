import { hydrateInitialCatalogOptions } from "@incident-shared/catalog";
import { CatalogEntryBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import { CatalogSetupWidget } from "@incident-shared/catalog/CatalogSetupWidget";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Badge, BadgeSize, BadgeTheme, OrgAwareLink } from "@incident-ui";
import { Mode as DrawerMode } from "@incident-ui/Drawer/Drawer";
import { SelectOptionOrGroup } from "@incident-ui/Select/types";
import { FormProvider, UseFormReturn } from "react-hook-form";

import {
  CatalogType,
  ClientType,
  useClient,
} from "../../../contexts/ClientContext";
import { tcx } from "../../../utils/tailwind-classes";
import { EscalationPathCatalogBindingData } from "../common/types";
import { CatalogSetup } from "./useCatalogSetup";

export const EscalationPathCatalogSetupWidget = ({
  mode,
  initialData,
  formMethods,
  catalogSetup,
}: {
  mode: DrawerMode;
  initialData: EscalationPathCatalogBindingData;
  formMethods: UseFormReturn<EscalationPathCatalogBindingData>;
  catalogSetup: CatalogSetup;
}) => {
  if (catalogSetup.props) {
    return <CatalogSetupWidget {...catalogSetup.props} />;
  }

  const anEscalationPathHasBeenLinked = Object.values(initialData).some(
    (val) => val,
  );

  const hasDashboardEscalationCatalogTypes =
    !!catalogSetup.dashboardEscalationCatalogTypes?.length;

  if (!hasDashboardEscalationCatalogTypes) {
    return <></>;
  }

  const showSetupCallout =
    mode === DrawerMode.Create || !anEscalationPathHasBeenLinked;

  return (
    <FormProvider<EscalationPathCatalogBindingData> {...formMethods}>
      {!showSetupCallout ? (
        <EscalationCatalogTypeSelect
          mode={mode}
          initialCatalogData={initialData}
          formMethods={formMethods}
          catalogSetup={catalogSetup}
        />
      ) : (
        <CatalogSetupWidget
          title={"Link this escalation path to Catalog"}
          description={
            "Dynamically route your alerts to the right people by linking escalation paths in your Catalog"
          }
          extraNode={
            <EscalationCatalogTypeSelect
              mode={mode}
              formMethods={formMethods}
              catalogSetup={catalogSetup}
            />
          }
        />
      )}
    </FormProvider>
  );
};

const EscalationCatalogTypeSelect = ({
  mode,
  initialCatalogData,
  formMethods,
  catalogSetup,
}: {
  mode: DrawerMode;
  initialCatalogData?: EscalationPathCatalogBindingData;
  formMethods: UseFormReturn<EscalationPathCatalogBindingData>;
  catalogSetup: CatalogSetup;
}) => {
  const apiClient = useClient();

  return (
    <>
      {catalogSetup.dashboardEscalationCatalogTypes?.map((type) => {
        const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
          apiClient,
          catalogTypeID: type.id,
        });
        const hasBeenSet = initialCatalogData?.[type.id] !== undefined;
        return (
          <DynamicMultiSelectV2
            key={type.id}
            label={type.name}
            loadOptions={async (inputValue: string) =>
              loadEscalationPathCatalogEntries(apiClient, type, inputValue)
            }
            hydrateOptions={hydrateDefaultValueOptions}
            name={`${type.id}`}
            formMethods={formMethods}
            optionColor={type.color as unknown as ColorPaletteEnum}
            optionIcon={type.icon}
            ignoreDisabledStyling={true}
            isDisabled={mode === DrawerMode.Edit ? hasBeenSet : false}
            disabled={mode === DrawerMode.Edit ? hasBeenSet : false}
            // This is a temporary restriction, because of the odd-case of being able to link a Team to multiple
            // escalation paths - if you assign a new Team to one of those escalation paths, we'll overwrite one
            // of the links for the other Team. Given we're about to change a lot here, we're not going to change
            // this behaviour just yet!
            disabledTooltipContent={
              <div>
                To edit the {type.name} this escalation path is linked to, head
                to your{" "}
                <OrgAwareLink
                  to={`/catalog/${type.id}`}
                  className={"underline"}
                >
                  Catalog
                </OrgAwareLink>
                .
              </div>
            }
            placeholder={`Link to a ${type.name}`}
          />
        );
      })}
    </>
  );
};

const loadEscalationPathCatalogEntries = async (
  apiClient: ClientType,
  catalogType: CatalogType,
  inputValue: string,
): Promise<SelectOptionOrGroup[]> => {
  const catalogEntryResponse = await apiClient.catalogListEntries({
    catalogTypeId: catalogType.id,
    pageSize: 250,
    search: inputValue,
  });

  const escalationPathAttribute = catalogType.schema.attributes.find(
    (attribute) => attribute.type === "EscalationPath",
  );

  return catalogEntryResponse.catalog_entries.map((entry) => {
    // If this catalog entry is already attached to an escalation path, don't let you edit it.
    const isDisabled =
      escalationPathAttribute &&
      entry.attribute_values !== undefined &&
      (!!entry.attribute_values?.[escalationPathAttribute.id]?.value ||
        !!entry.attribute_values?.[escalationPathAttribute.id]?.array_value);

    return {
      value: entry.id,
      label: entry.name,
      icon: catalogType.icon,
      color: catalogType.color as unknown as ColorPaletteEnum,
      disabled: isDisabled,
      renderFn: () => (
        <div className={tcx("flex w-full items-center")}>
          <div>
            <CatalogEntryBadge
              icon={catalogType.icon}
              label={entry.name}
              color={catalogType.color as unknown as ColorPaletteEnum}
              size={BadgeSize.Medium}
              className={tcx({
                "opacity-50": isDisabled,
              })}
            />
          </div>
          <div className={"grow"} />
          {isDisabled && (
            <Badge theme={BadgeTheme.Unstyled} size={BadgeSize.ExtraSmall}>
              Linked to another path
            </Badge>
          )}
        </div>
      ),
    };
  });
};
