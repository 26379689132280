import { AISpan, AIStaffRerunProcessorRequestBody } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
} from "@incident-ui";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { cacheKey, useAPI, useMutationV2 } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { AISpanTrace, formatSpanResult } from "../ai-requests/AISpanTrace";
import { CodeViewer } from "../common/CodeViewer";

export const BackfillShowPage = () => {
  const { spanId } = useParams<{ spanId: string }>();
  const navigate = useOrgAwareNavigate();
  const [pollingCount, setPollingCount] = useState(0);
  const [shouldPoll, setShouldPoll] = useState(true);
  const pollTimerRef = useRef<NodeJS.Timeout | null>(null);

  const {
    data: { spans },
    isLoading: spanDataLoading,
    mutate: refetch,
  } = useAPI(
    "aIStaffListAISpans",
    {
      id: spanId,
      childSpanDepth: 1,
    },
    {
      fallbackData: { spans: [] },
      revalidateOnFocus: false,
    },
  );

  const topSpan = spans.find((span) => span.id === spanId);

  // Set up polling for the first 2 minutes
  useEffect(() => {
    // Clear any existing timer when component unmounts or spanId changes
    return () => {
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
      }
    };
  }, [spanId]);

  // Poll for span if not found yet
  useEffect(() => {
    // Only poll if we should poll and we don't have the span yet
    if (shouldPoll && !topSpan && !spanDataLoading) {
      // Stop polling after 2 minutes (24 attempts at 5 second intervals)
      if (pollingCount >= 24) {
        setShouldPoll(false);
        return;
      }

      // Poll every 5 seconds
      pollTimerRef.current = setTimeout(() => {
        refetch();
        setPollingCount((prev) => prev + 1);
      }, 5000);
    } else if (topSpan) {
      // Stop polling once we have the span
      setShouldPoll(false);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
      }
    };
  }, [topSpan, spanDataLoading, shouldPoll, pollingCount, refetch]);

  if (!spanId) {
    navigate("/workbench");
    return null;
  }

  // Show special messaging if we're still polling
  if (!topSpan && !spanDataLoading && shouldPoll) {
    return (
      <WorkbenchSubPageWrapper backHref="/workbench/backfills">
        <div className="flex flex-col items-center justify-center h-64">
          <Icon
            id={IconEnum.Clock}
            size={IconSize.Large}
            className="mb-4 text-slate-400"
          />
          <h3 className="text-lg font-medium mb-2">
            Waiting for backfill to start...
          </h3>
          <p className="text-content-secondary">
            The backfill job is being created. Please wait.
          </p>
        </div>
      </WorkbenchSubPageWrapper>
    );
  }

  // Show error if we're not polling anymore and still can't find the span
  if (!topSpan && !spanDataLoading && !shouldPoll) {
    return (
      <WorkbenchSubPageWrapper backHref="/workbench/backfills">
        <EmptyState
          icon={IconEnum.Filter}
          content="Couldn't find backfill span. The backfill may have failed to start."
        />
      </WorkbenchSubPageWrapper>
    );
  }

  // Group immediate child processor spans
  const processorSpans = spans.filter(
    (span) => span.parent_id === spanId && span.type === "processor",
  );

  return (
    <WorkbenchSubPageWrapper
      backHref="/workbench/backfills"
      title={`Backfill: ${topSpan?.name}`}
      titleAccessory={
        topSpan ? <LocalDateTime timestamp={topSpan.created_at} /> : undefined
      }
    >
      <LoadingWrapper loading={spanDataLoading} className="pb-32">
        {spans.length === 0 ? (
          <EmptyState icon={IconEnum.Filter} content="No spans found" />
        ) : (
          <div className="flex flex-col gap-4">
            <CodeViewer
              content={formatSpanResult(topSpan?.input)}
              title="Backfill input"
              mode="json"
            />
            <h3 className="text-lg font-semibold mt-4">Processor Runs</h3>
            {processorSpans.length === 0 ? (
              <EmptyState
                icon={IconEnum.Filter}
                content="No processor runs found"
              />
            ) : (
              <StackedList>
                {processorSpans.map((processorSpan) => (
                  <ProcessorSpanRow
                    key={processorSpan.id}
                    processorSpan={processorSpan}
                  />
                ))}
              </StackedList>
            )}
          </div>
        )}
      </LoadingWrapper>
    </WorkbenchSubPageWrapper>
  );
};

const ProcessorSpanRow = ({ processorSpan }: { processorSpan: AISpan }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useOrgAwareNavigate();

  const {
    data: { spans },
    isLoading: spanDataLoading,
  } = useAPI(
    expanded ? "aIStaffListAISpans" : null,
    {
      id: processorSpan.id,
      childSpanDepth: 50,
    },
    {
      fallbackData: { spans: [] },
      revalidateOnFocus: false,
    },
  );

  const { trigger: rerunProcessor, isMutating: isRerunning } = useMutationV2(
    async (apiClient, data: AIStaffRerunProcessorRequestBody) => {
      const resp = await apiClient.aIStaffRerunProcessor({
        rerunProcessorRequestBody: data,
      });
      navigate(`/workbench/processors/${resp.span_id}`);
    },
    {
      invalidate: [cacheKey.all("aIStaffListAISpans")],

      showErrorToast: "Re-running processor",
    },
  );

  // Calculate duration in a readable format
  const durationText = `${processorSpan.duration_seconds.toFixed(2)}s`;

  // Determine status icons and classes based on result_type
  let statusIcon = IconEnum.TickCircle;
  let statusClass = "text-green-600";

  if (processorSpan.result_type === "error") {
    statusIcon = IconEnum.CloseCircle;
    statusClass = "text-red-600";
  } else if (processorSpan.result_type === "ignored") {
    statusIcon = IconEnum.Warning;
    statusClass = "text-amber-500";
  }

  return (
    <div className="flex flex-col px-4">
      <div
        className={tcx(
          "flex items-start gap-2 w-full py-4",
          expanded && "border-b border-slate-200",
        )}
      >
        <Button
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId={null}
          className="flex flex-grow items-start gap-2 cursor-pointer group"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex gap-6">
            <LocalDateTime
              timestamp={processorSpan.created_at}
              className="font-medium"
            />
            <div className="flex items-center">
              <Icon
                id={IconEnum.Components}
                size={IconSize.Small}
                className="mr-2"
              />
              <div className="font-medium">{processorSpan.name}</div>
            </div>
          </div>
          <div className="grow" />
          {processorSpan.resource_id && (
            <div className="flex items-center gap-0.5 mono text-content-tertiary">
              {processorSpan.resource_id}
            </div>
          )}
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {durationText}
          </div>
          <div className="flex items-center gap-0.5">
            <Icon
              id={statusIcon}
              size={IconSize.Small}
              className={statusClass}
            />
          </div>
          <Icon
            id={expanded ? IconEnum.Collapse : IconEnum.Expand}
            className="text-slate-600 group-hover:text-slate-900 transition"
          />
        </Button>

        {/* Re-run button */}
        {processorSpan.resource_id && (
          <Button
            theme={ButtonTheme.Secondary}
            icon={IconEnum.Refresh}
            onClick={() =>
              rerunProcessor({
                processor: processorSpan.name,
                resource_id: processorSpan.resource_id as string,
              })
            }
            loading={isRerunning}
            disabled={processorSpan.resource_id == null}
            analyticsTrackingId={null}
            title=""
            size={BadgeSize.Small}
          >
            Re-run
          </Button>
        )}
      </div>

      {/* Expandable trace view section */}
      {expanded && (
        <div className="pb-4">
          <LoadingWrapper loading={spanDataLoading}>
            <AISpanTrace spans={spans} />
          </LoadingWrapper>
        </div>
      )}
    </div>
  );
};
