/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterestingChannel,
    InterestingChannelFromJSON,
    InterestingChannelFromJSONTyped,
    InterestingChannelToJSON,
} from './InterestingChannel';

/**
 * 
 * @export
 * @interface AIListInterestingChannelsResponseBody
 */
export interface AIListInterestingChannelsResponseBody {
    /**
     * 
     * @type {Array<InterestingChannel>}
     * @memberof AIListInterestingChannelsResponseBody
     */
    channels: Array<InterestingChannel>;
}

export function AIListInterestingChannelsResponseBodyFromJSON(json: any): AIListInterestingChannelsResponseBody {
    return AIListInterestingChannelsResponseBodyFromJSONTyped(json, false);
}

export function AIListInterestingChannelsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListInterestingChannelsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(InterestingChannelFromJSON)),
    };
}

export function AIListInterestingChannelsResponseBodyToJSON(value?: AIListInterestingChannelsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(InterestingChannelToJSON)),
    };
}

