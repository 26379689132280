import {
  ExternalSchedule,
  Schedule,
  ScheduleReplica,
  ScheduleReplicaUserLink,
  ScopeNameEnum,
} from "@incident-io/api";
import { getEngineTypeaheadOptions } from "@incident-shared/engine";
import { Form } from "@incident-shared/forms";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  PopoverSingleSelect,
  Spinner,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { ConfirmationDialog } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { useClient } from "../../../../../contexts/ClientContext";
import { useIdentity } from "../../../../../contexts/IdentityContext";
import { useAPI, useAPIMutation } from "../../../../../utils/swr";
import { useNow } from "../../../../../utils/use-now";
import { joinSpansWithCommasAndConnectorWord } from "../../../../../utils/utils";
import { getCurrentlyActiveRotas } from "../common/util";
import { formDataToPayload, replicasToDefaultFormValues } from "./marshall";
import { useExternalScheduleTypeahead } from "./useExternalScheduleTypeahead";

export type ScheduleReplicaFormData = {
  enabled: boolean;
  mirror_to_many: boolean;
  single_destination_schedule_external_provider_id: string | undefined;
  multiple_destinations: MultipleDestinationsFormData;
  replica_fallback_user_id: string;
};

export type MultipleDestinationsFormData = {
  [rotaId: string]: {
    [layerId: string]: {
      replica_provider_id: string | undefined;
    };
  };
};

export const ScheduleReplicaDrawer = ({
  onClose,
  schedule,
}: {
  isOpen: boolean;
  onClose: () => void;
  schedule: Schedule;
}): React.ReactElement => {
  const { data: replicasData, isLoading: isLoadingReplicas } = useAPI(
    "schedulesListReplicas",
    { scheduleId: schedule.id },
    { fallbackData: { schedule_replicas: [], user_links: [] } },
  );

  const {
    data: externalScheduleResponse,
    isLoading: isLoadingExternalSchedule,
  } = useAPI(
    schedule.created_from_external_schedule_id ? "schedulesListExternal" : null,
    {
      ids: [schedule.created_from_external_schedule_id ?? ""],
    },
  );

  return (
    <Drawer onClose={onClose} width={"medium"}>
      <DrawerContents>
        <DrawerTitle
          title={
            <span className={"inline-flex items-center"}>
              Mirror to PagerDuty{" "}
              <Badge theme={BadgeTheme.Info} className="ml-2">
                Beta <Icon size={IconSize.Small} id={IconEnum.Test} />
              </Badge>
            </span>
          }
          icon={IconEnum.Pagerduty}
          iconClassName="text-content-primary bg-white"
          onClose={onClose}
          sticky
          theme={DrawerTitleTheme.Default}
          color={ColorPaletteEnum.Slate}
          footer={
            <div className={"text-xs-normal"}>
              Automatically mirror the {schedule.name} schedule to one or more
              schedules in PagerDuty. Learn more in our{" "}
              <Button
                href="https://help.incident.io/articles/9431189719-mirroring-schedules-to-pagerduty"
                analyticsTrackingId="mirroring-learn-more"
                openInNewTab
                theme={ButtonTheme.Naked}
              >
                help center
              </Button>
              .
            </div>
          }
        />
        {isLoadingReplicas || isLoadingExternalSchedule || !replicasData ? (
          <Loader />
        ) : (
          <CreateReplicaForm
            schedule={schedule}
            replicas={replicasData.schedule_replicas}
            userLinks={replicasData.user_links}
            onClose={onClose}
            externalSchedule={externalScheduleResponse?.external_schedules[0]}
          />
        )}
      </DrawerContents>
    </Drawer>
  );
};

// Component for creating new replicas
const CreateReplicaForm = ({
  schedule,
  onClose,
  replicas,
  userLinks,
  externalSchedule,
}: {
  schedule: Schedule;
  externalSchedule: ExternalSchedule | undefined;
  replicas: ScheduleReplica[];
  userLinks: ScheduleReplicaUserLink[];
  onClose: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const apiClient = useClient();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const now = useNow(schedule.timezone);

  const formMethods = useForm<ScheduleReplicaFormData>({
    defaultValues: replicasToDefaultFormValues({
      replicas,
      schedule,
      now,
      externalSchedule,
    }),
  });

  const {
    trigger: onSubmit,
    isMutating: isSaving,
    genericError,
  } = useAPIMutation(
    "schedulesListReplicas",
    { scheduleId: schedule.id },
    async (apiClient, data: ScheduleReplicaFormData) => {
      const payload = formDataToPayload(data, schedule, now, formMethods);

      if (!payload) {
        return;
      }

      await apiClient.schedulesSetReplicas({
        scheduleId: schedule.id,
        setReplicasRequestBody: payload,
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  const handleSubmit = () => {
    if (formMethods.getValues().enabled) {
      setShowConfirmationDialog(true);
    } else {
      onSubmit(formMethods.getValues());
    }
  };

  const [enabled] = formMethods.watch(["enabled"]);
  const { loadOptions, hydrateOptions } = useExternalScheduleTypeahead();

  const unlinkedUsers = userLinks.filter((link) => !link.external_user_id);

  const pluralisedUsers = `${pluralize("User", unlinkedUsers.length)}`;
  return (
    <>
      <ConfirmationDialog
        isOpen={showConfirmationDialog}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirm={() => {
          setShowConfirmationDialog(false);
          onSubmit(formMethods.getValues());
        }}
        loading={isSaving}
        title="Are you sure?"
        confirmButtonText="Enable mirroring"
        analyticsTrackingId="schedule-replica-confirmation-dialog"
      >
        Once this is enabled you&apos;ll see up to two weeks of your incident.io
        schedule in PagerDuty:
        <ul className="list-disc pl-5 pt-2 space-y-1">
          <li>
            Any existing overrides in PagerDuty that aren&apos;t already in
            incident.io will be overwritten
          </li>
          <li>
            Users should edit the schedule and create overrides in incident.io
          </li>
          <li>Any changes made in PagerDuty will be overwritten</li>
        </ul>
      </ConfirmationDialog>
      <DrawerBody className="space-y-6 flex-1 overflow-y-auto">
        <Form.Root
          formMethods={formMethods}
          onSubmit={handleSubmit}
          genericError={genericError}
          outerClassName={"h-full"}
          fullHeight
          id={"replica-edit"}
          innerClassName={"flex flex-col"}
        >
          <BooleanRadioButtonGroupV2
            trueOption={{
              label: "Yes",
            }}
            falseOption={{
              label: "No",
            }}
            name="enabled"
            label="Enable mirroring"
            srLabel={"Enable mirroring"}
            formMethods={formMethods}
            boxed
          />
          <AnimatePresence>
            {enabled ? (
              <motion.div
                className="space-y-6 rounded-b-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <BooleanRadioButtonGroupV2
                  label={"Destination"}
                  name="mirror_to_many"
                  boxed
                  formMethods={formMethods}
                  srLabel="Destination"
                  renderWhenSelectedMode={"below-group"}
                  falseOption={{
                    label: "One schedule",
                    renderWhenSelectedNode: () => {
                      return (
                        <DynamicSingleSelectV2
                          formMethods={formMethods}
                          name={
                            "single_destination_schedule_external_provider_id"
                          }
                          hydrateOptions={hydrateOptions}
                          loadOptions={loadOptions}
                        />
                      );
                    },
                  }}
                  trueOption={{
                    label: "Many schedules",
                    renderWhenSelectedNode: () => (
                      <ScheduleLayersToExternalSchedulesList
                        formMethods={formMethods}
                        schedule={schedule}
                      />
                    ),
                  }}
                />
                <div className={"flex flex-col w-full gap-2"}>
                  <DynamicSingleSelectV2
                    name="replica_fallback_user_id"
                    label="Fallback user"
                    required
                    formMethods={formMethods}
                    loadOptions={getEngineTypeaheadOptions(
                      apiClient,
                      'CatalogEntry["PagerDutyUser"]',
                    )}
                    placeholder={"Select a PagerDuty user"}
                    hydrateOptions={getEngineTypeaheadOptions(
                      apiClient,
                      'CatalogEntry["PagerDutyUser"]',
                    )}
                  />
                  <span className={"text-xs-med text-content-secondary"}>
                    {
                      "If there's nobody on call in this schedule, which bot user or fallback user should we use?"
                    }
                  </span>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>
          <div className={"flex-1 flex flex-col justify-end space-y-4 pb-4"}>
            {enabled && unlinkedUsers.length > 0 ? (
              <Callout
                theme={CalloutTheme.Danger}
                iconOverride={IconEnum.User}
                title={`${unlinkedUsers.length} unlinked ${pluralisedUsers}`}
                subtitle={
                  <>
                    {pluralisedUsers}{" "}
                    {joinSpansWithCommasAndConnectorWord(
                      unlinkedUsers
                        .filter((u) => u.name)
                        .map((u) => (
                          <span key={u.id} className={"text-sm-med"}>
                            {u.name}
                          </span>
                        )),
                    )}{" "}
                    on this schedule couldn&apos;t be related to{" "}
                    {unlinkedUsers.length === 1 ? "a " : ""}PagerDuty{" "}
                    {pluralisedUsers} and will be replaced by the fallback user
                  </>
                }
              />
            ) : null}
            <Callout
              theme={CalloutTheme.Warning}
              title={"PagerDuty limits layers to a single responder"}
              subtitle={
                "If your incident.io schedule has multiple concurrent responders we’ll pick one to be the PagerDuty responder."
              }
            />
            <Callout
              theme={CalloutTheme.Plain}
              title={"Mirroring is one-way"}
              subtitle={
                "Overrides created in PagerDuty won’t be reflected in your incident.io schedule."
              }
            />
          </div>
        </Form.Root>
      </DrawerBody>
      <DrawerFooter>
        <div className="flex justify-end gap-2 pt-4">
          <Button
            theme={ButtonTheme.Secondary}
            onClick={onClose}
            type="button"
            analyticsTrackingId={null}
          >
            Back
          </Button>
          <Button
            theme={ButtonTheme.Primary}
            type="submit"
            form={"replica-edit"}
            loading={isSaving}
            disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
            analyticsTrackingId={null}
          >
            {enabled ? "Mirror overrides" : "Save"}
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

export const ScheduleLayersToExternalSchedulesList = ({
  formMethods,
  schedule,
}: {
  schedule: Schedule;
  formMethods: UseFormReturn<ScheduleReplicaFormData>;
}) => {
  const multipleDestinations = formMethods.watch("multiple_destinations");
  const rotas = schedule.config?.rotations || [];
  const now = useNow(schedule.timezone);
  const { loadOptions, hydrateOptions } = useExternalScheduleTypeahead();

  return (
    <StackedList>
      {Object.entries(multipleDestinations).map(([rotaId, layers]) => {
        return Object.entries(layers).map(([layerId, layerOption]) => {
          const rota = getCurrentlyActiveRotas({ rotas, now }).find(
            (r) => r.id === rotaId,
          );
          const layer = rota?.layers.find((l) => l.id === layerId);

          if (!rota || !layer) {
            return null;
          }

          const title =
            rota.layers.length > 1 ? `${rota.name} - ${layer.name}` : rota.name;

          return (
            <StackedListItem
              key={layerId}
              className="flex items-center gap-3"
              title={title}
              accessory={
                <div className="w-1/2 flex flex-row justify-end">
                  <PopoverSingleSelect<false, false>
                    isSearchable
                    align="end"
                    value={layerOption.replica_provider_id}
                    fullWidth
                    renderTriggerNode={({
                      onClick,
                      selectedOption,
                      isLoadingOptions,
                    }) => (
                      <Button
                        size={BadgeSize.Medium}
                        theme={ButtonTheme.Secondary}
                        analyticsTrackingId={null}
                        onClick={onClick}
                        className={"w-full"}
                      >
                        {isLoadingOptions ? (
                          <Spinner />
                        ) : (
                          <div className="w-full flex justify-start items-center gap-1">
                            <Icon
                              {...(selectedOption
                                ? {
                                    id: IconEnum.Pagerduty,
                                  }
                                : {
                                    id: IconEnum.DottedCircle,
                                    className: "text-slate-900",
                                  })}
                            />
                            <span className={"flex-1 text-start"}>
                              {selectedOption
                                ? selectedOption.label
                                : "Not mirrored"}
                            </span>
                            <Icon
                              id={IconEnum.Expand}
                              className="!text-slate-600 !fill-slate-600"
                            />
                          </div>
                        )}
                      </Button>
                    )}
                    onChange={(val) =>
                      formMethods.setValue(
                        `multiple_destinations.${rotaId}.${layerId}.replica_provider_id`,
                        val,
                      )
                    }
                    loadOptions={loadOptions}
                    hydrateOptions={hydrateOptions}
                  />
                </div>
              }
            ></StackedListItem>
          );
        });
      })}
    </StackedList>
  );
};
