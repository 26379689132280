import { SelectOption } from "@incident-io/api";
import { TeamsFilter } from "@incident-shared/teams/TeamsFilter";
import { IconSize } from "@incident-ui";
import { InputSize } from "@incident-ui/Input/Input";
import { SearchBar, useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { useFlags } from "launchdarkly-react-client-sdk";

export const SchedulesListFilter = ({
  selectedTeams,
  setSelectedTeams,
}: {
  selectedTeams: SelectOption[];
  setSelectedTeams: (newValue: SelectOption[]) => void;
}) => {
  const searchBarProps = useSearchContext();
  const { featureNativeTeams } = useFlags();

  return (
    <>
      {featureNativeTeams ? (
        <div className={"flex justify-between"}>
          <TeamsFilter
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            defaultToCurrentUsersTeams
          />
          <SearchBar
            {...searchBarProps}
            placeholder="Search"
            inputClassName={"bg-surface-secondary rounded border-none"}
            size={InputSize.Medium}
            iconProps={{
              size: IconSize.Small,
              className: "text-content-tertiary",
            }}
            autoFocus
          />
        </div>
      ) : (
        <div className="flex justify-between">
          <div />
          <SearchBar
            {...searchBarProps}
            placeholder="Search"
            inputClassName={"bg-surface-secondary rounded border-none"}
            size={InputSize.Medium}
            iconProps={{
              size: IconSize.Small,
              className: "text-content-tertiary",
            }}
            autoFocus
          />
        </div>
      )}
    </>
  );
};
