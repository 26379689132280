import { Loader } from "@incident-ui/Loader/Loader";
import React, { ElementType, ForwardedRef } from "react";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import styles from "src/components/@ui/StackedList/StackedList.module.scss";
import { tcx } from "src/utils/tailwind-classes";

export type StackedListProps = {
  className?: string;
  is?: ElementType;
  /** Controls whether the list takes up the whole screen on smaller viewports */
  /** Hide the horizontal border on items*/
  noSeparator?: boolean;
  infiniteScroll?: {
    ref: UseInfiniteScrollHookRefCallback;
    isLoading: boolean;
    isFullyLoaded: boolean;
  };
} & (
  | {
      values: (string | undefined)[];
      children?: never;
    }
  | { children: React.ReactNode; values?: never; className?: string }
);

/**
 * The StackedList can either take a list of string values, or a children (which should be a list of `<li>` elements with whatever you want in them.) The `li` elements you pass in will automatically be styled appropriately.
 */
export const StackedList = React.forwardRef<HTMLUListElement, StackedListProps>(
  (props: StackedListProps, ref: ForwardedRef<HTMLUListElement>) => {
    const {
      values,
      children,
      className,
      noSeparator,
      is = "ul",
      infiniteScroll,
    } = props;

    const Tag = is;
    return (
      <Tag
        ref={ref}
        className={tcx(
          styles.stackedList,
          { [styles.noSeparator]: noSeparator },
          "shadow-sm",
          className,
        )}
      >
        {values
          ? values
              .filter((v) => !!v)
              .map((v) => (
                <li className="p-3" key={v}>
                  {v}
                </li>
              ))
          : children}

        {infiniteScroll?.isFullyLoaded === false && (
          <div ref={infiniteScroll?.ref}>
            <Loader />
          </div>
        )}
      </Tag>
    );
  },
);

StackedList.displayName = "StackedList";
