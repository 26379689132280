import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Callout,
  CalloutTheme,
  Checkbox,
  Heading,
  Loader,
  Modal,
  StackedList,
  StepsPageContent,
  StepsPageFooter,
  Tab,
  TabSection,
} from "@incident-ui";
import React, { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  ExternalSchedule,
  IntegrationsListResponseBody,
  Schedule,
  SchedulePayConfig,
  SchedulesCreatePayConfigRequestBody,
  SchedulesListSortByEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIInfinite, useAPIMutation } from "src/utils/swr";

import { useProductAccess } from "../../../hooks/useProductAccess";
import { PayConfigCreateEditForm } from "./pay-configurations/PayConfigCreateEditForm";
import { SelectableSchedule } from "./SelectableSchedule";

enum ScheduleProvider {
  NATIVE = "incident.io",
  PAGERDUTY = "PagerDuty",
  OPSGENIE = "Opsgenie",
  THIRD_PARTY = "Third party escalators",
}

const useProviderSchedules = (provider: ScheduleProvider | null) => {
  const isNative = provider === ScheduleProvider.NATIVE;

  const {
    responses: nativeResponses,
    isLoading: nativeLoading,
    isFullyLoaded: nativeFullyLoaded,
    error: nativeError,
    loadMore: loadMoreNative,
  } = useAPIInfinite(
    isNative ? "schedulesList" : null,
    {
      sortBy: SchedulesListSortByEnum.Name,
    },
    {
      revalidateFirstPage: true,
    },
  );

  const {
    responses: externalResponses,
    isLoading: externalLoading,
    isFullyLoaded: externalFullyLoaded,
    error: externalError,
    loadMore: loadMoreExternal,
  } = useAPIInfinite(
    !isNative ? "schedulesListExternal" : null,
    {},
    {
      revalidateFirstPage: true,
    },
  );

  if (isNative) {
    return {
      schedules: nativeResponses.flatMap(({ schedules }) => schedules),
      isLoading: nativeLoading,
      isFullyLoaded: nativeFullyLoaded,
      error: nativeError,
      loadMore: loadMoreNative,
    };
  }

  return {
    schedules: externalResponses.flatMap(
      ({ external_schedules }) => external_schedules,
    ),
    isLoading: externalLoading,
    isFullyLoaded: externalFullyLoaded,
    error: externalError,
    loadMore: loadMoreExternal,
  };
};

// ReportGeneratorChooseSchedules allows you to select which
// schedules you want to include. From here, you can also add
// pay configs.
export const ReportGeneratorChooseSchedules = ({
  onAddSchedule,
  onRemoveSchedule,
  onDeSelectAllSchedules,
  onSelectAllSchedules,
  onUpdatePayConfigOnSchedule,
  onContinue: onContinueCallback,
  onBack,
  selectedSchedules,
  payConfigs,
}: {
  onAddSchedule: (schedule: Schedule | ExternalSchedule) => void;
  onRemoveSchedule: (scheduleID: string) => void;
  onDeSelectAllSchedules: () => void;
  onSelectAllSchedules: (
    schedules: Record<string, SchedulePayConfig | undefined>,
  ) => void;
  onUpdatePayConfigOnSchedule: (
    schedule: Schedule | ExternalSchedule,
    pay_config: SchedulePayConfig,
  ) => void;
  onContinue: () => void;
  onBack: () => void;
  selectedSchedules: Record<string, SchedulePayConfig | undefined>;
  payConfigs: SchedulePayConfig[];
}): React.ReactElement => {
  const [missingPayConfigErr, setMissingPayConfigErr] = useState(false);

  // This stores the schedule that we came from when we
  // decided to add a pay config, so we can set it
  const [showAddPayConfigModalSchedule, setShowAddPayConfigModalSchedule] =
    useState<Schedule | ExternalSchedule | null>(null);

  const onContinue = () => {
    // check that all schedules have a config
    if (Object.keys(selectedSchedules).some((key) => !selectedSchedules[key])) {
      setMissingPayConfigErr(true);
    } else {
      onContinueCallback();
    }
  };

  const {
    trigger: createPayConfig,
    isMutating: savingPayConfig,
    genericError: payConfigError,
  } = useAPIMutation(
    "schedulesListPayConfig",
    undefined,
    async (apiClient, data: SchedulesCreatePayConfigRequestBody) => {
      const { schedule_pay_config: config } =
        await apiClient.schedulesCreatePayConfig({
          createPayConfigRequestBody: data,
        });

      if (showAddPayConfigModalSchedule == null) {
        throw new Error(
          "unreachable: creating pay config without a chosen external schedule",
        );
      }

      onUpdatePayConfigOnSchedule(showAddPayConfigModalSchedule, config);
      setShowAddPayConfigModalSchedule(null);
    },
  );
  if (payConfigError) {
    throw payConfigError;
  }

  return (
    <>
      {showAddPayConfigModalSchedule ? (
        <Modal
          analyticsTrackingId="create-pay-configuration"
          title="Add pay configuration"
          onClose={() => setShowAddPayConfigModalSchedule(null)}
          disableQuickClose
          isOpen
          isExtraLarge
        >
          <PayConfigCreateEditForm
            mode={Mode.Create}
            continueButtonText="Add pay configuration"
            onSubmit={createPayConfig}
            onClose={() => setShowAddPayConfigModalSchedule(null)}
            saving={savingPayConfig}
          />
        </Modal>
      ) : undefined}
      <ChooseSchedulesScreen
        payConfigs={payConfigs}
        selectedSchedules={selectedSchedules}
        onRemoveSchedule={onRemoveSchedule}
        onAddSchedule={onAddSchedule}
        onDeSelectAllSchedules={onDeSelectAllSchedules}
        onSelectAllSchedules={onSelectAllSchedules}
        onUpdatePayConfigOnSchedule={onUpdatePayConfigOnSchedule}
        onAddPayConfig={(schedule) =>
          setShowAddPayConfigModalSchedule(schedule)
        }
      />
      <StepsPageFooter
        onBack={onBack}
        onContinue={onContinue}
        continueDisabled={Object.keys(selectedSchedules).length === 0}
        genericError={
          missingPayConfigErr
            ? "Please choose a pay configuration for each schedule"
            : null
        }
      />
    </>
  );
};

export const hasPagerDuty = (i: IntegrationsListResponseBody | undefined) =>
  i?.integrations?.some((i) => i.provider === "pagerduty" && i.installed);
const hasOpsgenie = (i: IntegrationsListResponseBody | undefined) =>
  i?.integrations?.some((i) => i.provider === "opsgenie" && i.installed);

export const ChooseSchedulesScreen = ({
  payConfigs,
  selectedSchedules,
  onRemoveSchedule,
  onAddSchedule,
  onDeSelectAllSchedules,
  onSelectAllSchedules,
  onUpdatePayConfigOnSchedule,
  onAddPayConfig,
}: {
  payConfigs: SchedulePayConfig[];
  selectedSchedules: Record<string, SchedulePayConfig | undefined>;
  onAddSchedule: (schedule: Schedule | ExternalSchedule) => void;
  onRemoveSchedule: (scheduleID: string) => void;
  onDeSelectAllSchedules: () => void;
  onSelectAllSchedules: (
    schedules: Record<string, SchedulePayConfig | undefined>,
  ) => void;
  onUpdatePayConfigOnSchedule: (
    schedule: Schedule | ExternalSchedule,
    pay_config: SchedulePayConfig,
  ) => void;
  onAddPayConfig: (schedule: Schedule | ExternalSchedule) => void;
}): React.ReactElement => {
  const { hasOnCall: orgHasOnCall } = useProductAccess();
  const [activeProvider, setActiveProvider] = useState<ScheduleProvider | null>(
    orgHasOnCall ? ScheduleProvider.NATIVE : null,
  );

  // Get available providers
  const { data: integrationData } = useAPI("integrationsList", undefined, {
    onSuccess: (i) => {
      if (!activeProvider) {
        setActiveProvider(
          hasPagerDuty(i)
            ? ScheduleProvider.PAGERDUTY
            : hasOpsgenie(i)
            ? ScheduleProvider.OPSGENIE
            : ScheduleProvider.THIRD_PARTY,
        );
      }
    },
  });

  // Determine if we need third party grouping
  const hasMultipleThirdParty =
    hasPagerDuty(integrationData) && hasOpsgenie(integrationData);

  // Get schedules for current provider
  const { schedules, isLoading, isFullyLoaded, error, loadMore } =
    useProviderSchedules(activeProvider);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 100px 0px",
  });

  if (error) throw error;

  // Build available tabs
  const tabs: Tab[] = [];
  if (orgHasOnCall) {
    tabs.push({ id: ScheduleProvider.NATIVE, label: "incident.io" });
  }

  if (hasMultipleThirdParty) {
    tabs.push({
      id: ScheduleProvider.THIRD_PARTY,
      label: "Third party escalators",
    });
  } else {
    if (hasPagerDuty(integrationData)) {
      tabs.push({ id: ScheduleProvider.PAGERDUTY, label: "PagerDuty" });
    }
    if (hasOpsgenie(integrationData)) {
      tabs.push({ id: ScheduleProvider.OPSGENIE, label: "Opsgenie" });
    }
  }

  // Only show tabs if we have multiple providers
  const showTabs = tabs.length > 1;

  const allItemsSelected =
    schedules.length === Object.keys(selectedSchedules).length;

  if (!integrationData) return <Loader />;

  const checkboxComponent = (
    <>
      <Checkbox
        className="mb-4 font-md"
        id="select_all"
        onChange={
          allItemsSelected
            ? onDeSelectAllSchedules
            : () =>
                onSelectAllSchedules(
                  schedules.reduce(
                    (acc, schedule) => ({
                      ...acc,
                      [schedule.id]: payConfigs.find(
                        (config) =>
                          config.id === schedule.default_pay_config_id,
                      ),
                    }),
                    {},
                  ),
                )
        }
        checked={allItemsSelected}
        label="Select All"
      />
    </>
  );
  return (
    <StepsPageContent className="flex justify-center">
      <div className="max-w-4xl w-full">
        <div className="w-full">
          <div className="flex w-full">
            <Heading level={2} size="medium" className="mb-4 grow">
              Which schedules should be included in the report?
            </Heading>
            {!showTabs && schedules.length > 1 ? checkboxComponent : null}
          </div>

          {showTabs && (
            <div
              className={"w-full flex flex-row items-center justify-between"}
            >
              <TabSection
                value={activeProvider ?? tabs[0]?.id}
                onTabChange={(provider) =>
                  setActiveProvider(provider as ScheduleProvider)
                }
                tabs={tabs}
                withIndicator
              />
              {schedules.length > 1 ? checkboxComponent : null}
            </div>
          )}

          {/* List of schedules */}
          {schedules.length === 0 && !isLoading ? (
            <div className="text-slate-600 text-sm">
              <p>We haven&apos;t imported any of your schedules yet.</p>
              <p>
                We synchronise schedules on a daily basis, as well as
                immediately after you connect PagerDuty or Opsgenie.
              </p>
            </div>
          ) : (
            <>
              <StackedList
                infiniteScroll={{
                  isLoading,
                  isFullyLoaded,
                  ref: infiniteScrollRef,
                }}
              >
                {schedules.map((schedule) => {
                  const selectedConfig = selectedSchedules[schedule.id];

                  const isSelected = schedule.id in selectedSchedules;
                  return (
                    <SelectableSchedule
                      allPayConfigs={payConfigs}
                      key={schedule.id}
                      schedule={schedule}
                      payConfig={selectedConfig}
                      isSelected={isSelected}
                      onChangeSelected={() =>
                        isSelected
                          ? onRemoveSchedule(schedule.id)
                          : onAddSchedule(schedule)
                      }
                      onSelectPayConfig={(config) =>
                        onUpdatePayConfigOnSchedule(schedule, config)
                      }
                      onAddPayConfig={() => onAddPayConfig(schedule)}
                    />
                  );
                })}
              </StackedList>
              <Callout className="mt-4" theme={CalloutTheme.Plain}>
                <p className="!m-0 font-semibold">Schedule missing?</p>
                <p>
                  Opsgenie and PagerDuty schedules are synced on a daily basis.
                </p>
              </Callout>
            </>
          )}
        </div>
      </div>
    </StepsPageContent>
  );
};
