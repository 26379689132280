import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Avatar, GenericErrorMessage, IconSize, Loader } from "@incident-ui";
import { SearchBar } from "@incident-ui/SearchBar/SearchBar";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { Searcher, sortKind } from "fast-fuzzy";
import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useAPIInfinite } from "src/utils/swr";
import { useDebounce } from "use-debounce";

interface TeamsListProps {
  pageSize?: number;
}

const MAX_AVATARS_TO_SHOW = 5;

export const TeamsList: React.FC<TeamsListProps> = ({ pageSize = 50 }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [debouncedSearch] = useDebounce(searchValue, 250);

  const {
    responses: teamsData,
    error,
    isLoading,
    isFullyLoaded,
    loadMore,
  } = useAPIInfinite("teamsList", {
    pageSize,
  });

  const navigate = useOrgAwareNavigate();
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 100px 0px",
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading || !teamsData) {
    return <Loader />;
  }

  const teams = teamsData.flatMap((response) => response.teams) || [];

  const searcher = new Searcher(teams, {
    keySelector: (team) => team.name,
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  let filteredOptions = teams;

  if (debouncedSearch) {
    filteredOptions = searcher.search(debouncedSearch);
  }

  return (
    <div className={"flex flex-col gap-6"}>
      <SearchBar
        onChange={(e) => setSearchValue(e)}
        value={searchValue}
        placeholder="Search"
        className={"grow text-content-tertiary w-full"}
        inputClassName={"bg-surface-secondary border-none"}
        iconProps={{
          className: "text-content-tertiary",
        }}
        autoFocus
      />
      <Table
        gridTemplateColumns="2fr 1fr"
        data={filteredOptions}
        infiniteScroll={{
          ref: infiniteScrollRef,
          isLoading,
          isFullyLoaded,
        }}
        header={
          <>
            <TableHeaderCell title="Name" />
            <TableHeaderCell title="Members" className={"justify-end"} />
          </>
        }
        renderRow={(team, idx) => (
          <TableRow
            key={team.catalog_entry.id}
            isLastRow={idx === teams.length - 1}
            onClick={() => navigate(`/settings/teams/${team.id}`)}
          >
            <TableCell>{team.catalog_entry.name}</TableCell>
            <TableCell>
              {team.members.length > 0 ? (
                <div className="flex items-center">
                  <div className="flex -space-x-2">
                    {team.members
                      .slice(0, MAX_AVATARS_TO_SHOW)
                      .map((member) => (
                        <Avatar
                          key={member.id}
                          name={member.name || member.email}
                          url={member.avatar_url}
                          size={IconSize.Medium}
                          className={
                            "border-2 border-surface-primary group-hover/tableRow:border-surface-secondary"
                          }
                        />
                      ))}
                  </div>
                  {team.members.length > MAX_AVATARS_TO_SHOW && (
                    <div className="ml-2 text-content-secondary text-sm">
                      +{team.members.length - MAX_AVATARS_TO_SHOW} more
                    </div>
                  )}
                </div>
              ) : (
                <span className="text-content-tertiary">No members</span>
              )}
            </TableCell>
          </TableRow>
        )}
        emptyStateRow={
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <p className="text-content-secondary mb-2">No teams found</p>
            <p className="text-content-tertiary text-sm">
              Teams will appear here once they&rsquo;re created.
            </p>
          </div>
        }
      />
    </div>
  );
};
