import { EngineScope, Resource } from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  IconEnum,
  PopoverSingleSelect,
} from "@incident-ui";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { tcx } from "../../../../utils/tailwind-classes";
import { AlertsCatalogSetupWidget } from "../../alert-source-create-edit-v2/configure/AlertsCatalogSetupWidget";
import {
  EscalateBindingTarget,
  EscalateBindingType,
  EscalateFormData,
  EscalationBindingFormType,
} from "../common/types";
import { EscalationBindingSuggestions } from "./EscalationBindingSuggestions";

const ESCALATION_TYPE_OPTIONS = [
  { label: "using the alert's attributes", value: "dynamic" },
  { label: "directly", value: "static" },
];

const ESCALATION_TARGET_OPTIONS: {
  label: string;
  value: EscalateBindingTarget;
  type: "EscalationPath" | "User";
}[] = [
  {
    label: "escalation path",
    value: "escalation_paths",
    type: "EscalationPath",
  },
  { label: "user", value: "users", type: "User" },
];

const defaultBinding: EscalationBindingFormType = {
  mode: "dynamic",
  target: "escalation_paths",
  binding: undefined,
};

export const EnhancedEscalationBinding = ({
  resources,
  scopeWithExpressions,
}: {
  resources: Resource[];
  scopeWithExpressions: EngineScope;
}) => {
  const { fields, append, remove } = useFieldArray<EscalateFormData>({
    name: "escalationBindings",
  });

  const handleAddAnother = useCallback(() => {
    append(defaultBinding);
  }, [append]);

  const [allBindingsAreValid, setAllBindingsAreValid] = useState(false);

  const formMethods = useFormContext<EscalateFormData>();
  useEffect(() => {
    const { unsubscribe } = formMethods.watch((values) => {
      const allBindingsAreValid = values.escalationBindings?.every(
        (e) =>
          !_.isNil(e?.binding?.value?.reference) ||
          !_.isNil(e?.binding?.value?.literal) ||
          (e?.binding?.array_value ?? []).filter(
            (b) => !_.isNil(b?.reference) || !_.isNil(b?.literal),
          ).length > 0,
      );
      setAllBindingsAreValid(allBindingsAreValid ?? false);
    });

    return () => unsubscribe();
  }, [formMethods]);

  // If this ever opens and you don't have a binding, set at least one
  useEffect(() => {
    if ((fields ?? []).length === 0) {
      formMethods.setValue("escalationBindings", [defaultBinding]);
    }
  }, [fields, formMethods]);

  return (
    <div className="flex flex-col gap-2">
      <div>
        <span className={"text-content-primary text-sm-bold"}>
          How should alerts be escalated?
        </span>
      </div>

      <AlertsCatalogSetupWidget />

      <ContentBox className={"flex flex-col"}>
        {fields.map((field, index) => (
          <EscalationBindingItem
            key={field.id}
            index={index}
            onRemove={() => remove(index)}
            resources={resources}
            scopeWithExpressions={scopeWithExpressions}
            showRemove={fields.length > 1}
          />
        ))}

        {((fields ?? []).length === 0 || allBindingsAreValid) && (
          <div className={"flex flex-row p-4"}>
            <Button
              onClick={handleAddAnother}
              theme={ButtonTheme.Secondary}
              icon={IconEnum.Add}
              size={BadgeSize.Medium}
              analyticsTrackingId="add-escalation-binding"
            >
              Add another rule
            </Button>
          </div>
        )}
      </ContentBox>
    </div>
  );
};

const EscalationBindingItem = ({
  index,
  onRemove,
  resources,
  scopeWithExpressions,
  showRemove,
}: {
  index: number;
  onRemove: () => void;
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  showRemove: boolean;
}) => {
  const formMethods = useFormContext<EscalateFormData>();

  const [mode, target, binding] = formMethods.watch([
    `escalationBindings.${index}.mode`,
    `escalationBindings.${index}.target`,
    `escalationBindings.${index}.binding`,
  ]);

  const targetOption =
    ESCALATION_TARGET_OPTIONS.find((opt) => opt.value === target) ??
    ESCALATION_TARGET_OPTIONS[0];

  const catalogType = `CatalogEntry["${targetOption.type}"]`;
  return (
    <div
      className={tcx("flex flex-col gap-2 p-4", {
        "border-t border-dotted border-stroke": index > 0,
      })}
    >
      <div className="flex justify-between items-center gap-2">
        <div className={"flex items-center gap-2"}>
          <span className="text-sm text-content-secondary">
            {index > 0 ? "And escalate" : "Escalate"}
          </span>

          <PopoverSingleSelect
            value={mode}
            options={ESCALATION_TYPE_OPTIONS}
            onChange={(value) => {
              formMethods.setValue(
                `escalationBindings.${index}.mode`,
                value as EscalateBindingType,
              );
              formMethods.setValue(
                `escalationBindings.${index}.binding`,
                undefined,
              );
            }}
            triggerStyle="inline-button"
          />

          <span className="text-sm text-content-secondary">
            {target === "escalation_paths" ? "to an" : "to a"}
          </span>

          <PopoverSingleSelect
            value={target}
            options={ESCALATION_TARGET_OPTIONS}
            onChange={(value) => {
              formMethods.setValue(
                `escalationBindings.${index}.target`,
                value as EscalateBindingTarget,
              );
              formMethods.setValue(
                `escalationBindings.${index}.binding`,
                undefined,
              );
            }}
            triggerStyle="inline-button"
          />
        </div>

        {showRemove && (
          <Button
            onClick={onRemove}
            theme={ButtonTheme.Unstyled}
            className={"text-content-destroy text-sm-med"}
            analyticsTrackingId="remove-escalation-binding"
          >
            Remove
          </Button>
        )}
      </div>

      <div className="">
        {mode === "dynamic" ? (
          <>
            {binding?.value || (binding?.array_value ?? []).length > 0 ? (
              <EngineFormElement
                name={`escalationBindings.${index}.binding`}
                resourceType={catalogType}
                array
                label=""
                resources={resources}
                scope={scopeWithExpressions}
                showPlaceholder
                mode="variables_and_expressions"
                required={false}
                optionIconOverride={IconEnum.Close}
              />
            ) : (
              <EscalationBindingSuggestions
                resources={resources}
                scopeWithExpressions={scopeWithExpressions}
                targetType={targetOption.type}
                index={index}
              />
            )}
          </>
        ) : (
          <EngineFormElement
            mode="plain_input"
            name={`escalationBindings.${index}.binding`}
            resourceType={targetOption?.type ?? ""}
            array
            expressionLabelOverride={targetOption.label}
            resources={resources}
            showPlaceholder
            required={false}
          />
        )}
        <FormErrorMessage
          errors={formMethods.formState.errors}
          name={`escalationBindings.${index}.binding`}
          className="text-xs"
        />
      </div>
    </div>
  );
};
