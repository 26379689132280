/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianWidgetStatusIncidentUpdate,
    AtlassianWidgetStatusIncidentUpdateFromJSON,
    AtlassianWidgetStatusIncidentUpdateFromJSONTyped,
    AtlassianWidgetStatusIncidentUpdateToJSON,
} from './AtlassianWidgetStatusIncidentUpdate';

/**
 * 
 * @export
 * @interface AtlassianWidgetStatusIncident
 */
export interface AtlassianWidgetStatusIncident {
    /**
     * Time the incident was created
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncident
     */
    created_at: Date;
    /**
     * Unique identifier of the incident
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    id: string;
    /**
     * Impact level of the incident
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    impact: AtlassianWidgetStatusIncidentImpactEnum;
    /**
     * Updates for this incident
     * @type {Array<AtlassianWidgetStatusIncidentUpdate>}
     * @memberof AtlassianWidgetStatusIncident
     */
    incident_updates: Array<AtlassianWidgetStatusIncidentUpdate>;
    /**
     * Time the incident entered monitoring state
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncident
     */
    monitoring_at?: Date;
    /**
     * Title of the incident
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    name: string;
    /**
     * ID of the status page this incident belongs to
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    page_id: string;
    /**
     * Time the incident was resolved
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncident
     */
    resolved_at?: Date;
    /**
     * Short URL for the incident
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    shortlink?: string;
    /**
     * Current status of the incident
     * @type {string}
     * @memberof AtlassianWidgetStatusIncident
     */
    status: string;
    /**
     * Time the incident was last updated
     * @type {Date}
     * @memberof AtlassianWidgetStatusIncident
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AtlassianWidgetStatusIncidentImpactEnum {
    None = 'none',
    Minor = 'minor',
    Major = 'major',
    Critical = 'critical'
}

export function AtlassianWidgetStatusIncidentFromJSON(json: any): AtlassianWidgetStatusIncident {
    return AtlassianWidgetStatusIncidentFromJSONTyped(json, false);
}

export function AtlassianWidgetStatusIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianWidgetStatusIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'impact': json['impact'],
        'incident_updates': ((json['incident_updates'] as Array<any>).map(AtlassianWidgetStatusIncidentUpdateFromJSON)),
        'monitoring_at': !exists(json, 'monitoring_at') ? undefined : (new Date(json['monitoring_at'])),
        'name': json['name'],
        'page_id': json['page_id'],
        'resolved_at': !exists(json, 'resolved_at') ? undefined : (new Date(json['resolved_at'])),
        'shortlink': !exists(json, 'shortlink') ? undefined : json['shortlink'],
        'status': json['status'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AtlassianWidgetStatusIncidentToJSON(value?: AtlassianWidgetStatusIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'impact': value.impact,
        'incident_updates': ((value.incident_updates as Array<any>).map(AtlassianWidgetStatusIncidentUpdateToJSON)),
        'monitoring_at': value.monitoring_at === undefined ? undefined : (value.monitoring_at.toISOString()),
        'name': value.name,
        'page_id': value.page_id,
        'resolved_at': value.resolved_at === undefined ? undefined : (value.resolved_at.toISOString()),
        'shortlink': value.shortlink,
        'status': value.status,
        'updated_at': (value.updated_at.toISOString()),
    };
}

