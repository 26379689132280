import {
  CatalogType,
  EngineScope,
  ExpressionSuggestion,
  Resource,
} from "@incident-io/api";
import {
  AddExpressionButton,
  isExpression,
  MenuPathItem,
  TruncatingReferenceLabel,
} from "@incident-shared/engine";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionElseBranchInput } from "@incident-shared/engine/expressions/ExpressionElseBranchInput";
import { useExpressionsMethods } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  ModalFooter,
  Spinner,
} from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import _ from "lodash";
import React, { useState } from "react";
import { useController, useForm, useFormContext } from "react-hook-form";

import { useAPI } from "../../../../utils/swr";
import { tcx } from "../../../../utils/tailwind-classes";
import { AlertRouteFormData } from "../common/types";

type ReferenceSuggestion = {
  type: "reference";
  key: string;
  label: string;
};

// We'll display either ExpressionSuggestions from the API
// or we'll display any applicable references already in the scope
type BindingSuggestion = ExpressionSuggestion | ReferenceSuggestion;

const isReferenceSuggestion = (
  suggestion: BindingSuggestion | undefined,
): suggestion is ReferenceSuggestion => {
  if (!suggestion) {
    return false;
  }

  return "type" in suggestion && suggestion.type === "reference";
};

export const EscalationBindingSuggestions = ({
  resources,
  scopeWithExpressions,
  suffixNode,
  targetType,
  index,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  suffixNode?: React.ReactNode;
  targetType: string;
  index: number;
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();
  const controller = useController({
    name: `escalationBindings.${index}.binding`,
    control: formMethods.control,
  });

  const { expressionsMethods: expressionsMethods } = useExpressionsMethods();
  const [confirmExpressionModal, setConfirmExpressionModal] =
    useState<ExpressionSuggestion | null>(null);

  const {
    data: suggestionsData,
    isLoading: isLoadingSuggestions,
    error: suggestionsError,
  } = useAPI("catalogListExpressionSuggestions", {
    listExpressionSuggestionsRequestBody: {
      scope: scopeWithExpressions.references,
      target_type: targetType,
    },
  });

  const {
    data: { catalog_types: catalogTypes },
    isLoading: isLoadingCatalogTypes,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  // If you've not, rather than show a regular form element, we show some suggestions
  const resource = resources.find(
    (r) => r.type === `CatalogEntry["${targetType}"]`,
  );

  if (!resource) {
    throw new Error("Resource not found");
  }

  const onConfirmSuggestion = (
    refKey: string,
    refLabel = "Escalation path",
  ) => {
    controller.field.onChange({
      value: null,
      array_value: [
        {
          reference: refKey,
          value: refKey,
          label: refLabel,
          sort_key: refLabel,
        },
      ],
    });
  };

  if (!expressionsMethods) {
    throw new Error("Expressions methods not found");
  }

  if (isLoadingSuggestions || isLoadingCatalogTypes) {
    return (
      <Spinner
        containerClassName={"w-full flex justify-center items-center py-4"}
      />
    );
  }

  const error = suggestionsError ?? catalogTypesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const referenceSuggestions: ReferenceSuggestion[] = _.chain(
    scopeWithExpressions.references,
  )
    .filter(
      (ref) =>
        ref.type === `CatalogEntry["${targetType}"]` && !isExpression(ref.key),
    )
    .map(
      (ref): ReferenceSuggestion => ({
        type: "reference",
        key: ref.key,
        label: ref.label,
      }),
    )
    .value();

  const expressionSuggestions = _.chain(
    suggestionsData?.expression_suggestions ?? [],
  )
    .sortBy((e) => e.expression?.operations.length)
    .take(3)
    .value();

  const suggestions: BindingSuggestion[] = [
    ...referenceSuggestions,
    ...expressionSuggestions,
  ];

  const fixedResult = {
    array: true,
    label: resource.type_label,
    type: resource.type,
    typeLabel: resource.type_label,
    typeIsAutocompletable: resource.autocompletable,
  };

  const onConfirmExpression = (expression: ExpressionFormData) => {
    if (!confirmExpressionModal) {
      return;
    }

    setConfirmExpressionModal(null);
    expressionsMethods.append(expression);
    onConfirmSuggestion(
      makeExpressionReference(confirmExpressionModal?.expression),
    );
  };

  return (
    <div className={"flex flex-col w-full gap-4"}>
      <EmptyState
        fullWidthContent
        className={"!px-4 py-8"}
        content={
          <div>
            <div className={"text-content-secondary mb-4"}>
              Select a suggestion or create one from scratch
            </div>
            <div
              className={tcx(
                "flex gap-2 flex-wrap items-center justify-center",
              )}
            >
              {suggestions.map((bindingSuggestion: BindingSuggestion, idx) => (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (isReferenceSuggestion(bindingSuggestion)) {
                      onConfirmSuggestion(
                        bindingSuggestion.key,
                        bindingSuggestion.label,
                      );
                      return;
                    } else {
                      setConfirmExpressionModal(bindingSuggestion);
                    }
                  }}
                  key={idx}
                >
                  <EscalationBindingSuggestionBadge
                    bindingSuggestion={bindingSuggestion}
                    catalogTypes={catalogTypes}
                    scope={scopeWithExpressions}
                  />
                </button>
              ))}
              <AddExpressionButton
                onAdd={(ref) => onConfirmSuggestion(ref.key)}
                scopeAndIsAlert={{
                  scope: scopeWithExpressions,
                  isAlertElement: false,
                }}
                elseBranchRequired={false}
                fixedResult={fixedResult}
                buttonIcon={IconEnum.Add}
                iconOnlyButton={suggestions.length !== 0}
              />
            </div>
          </div>
        }
      />
      {suffixNode}
      {confirmExpressionModal && (
        <ConfirmExpressionSuggestionModal
          suggestion={confirmExpressionModal}
          resources={resources}
          onConfirmExpression={onConfirmExpression}
          onClose={() => setConfirmExpressionModal(null)}
          scopeWithExpressions={scopeWithExpressions}
          catalogTypes={catalogTypes}
        />
      )}
    </div>
  );
};

const EscalationBindingSuggestionBadge = ({
  bindingSuggestion,
  scope,
  catalogTypes,
}: {
  bindingSuggestion: BindingSuggestion;
  scope: EngineScope;
  catalogTypes: CatalogType[];
}) => {
  const path = pathFromSuggestion({
    bindingSuggestion,
    scope,
    catalogTypes,
  });

  if (!path) {
    return null;
  }

  return (
    <Button
      analyticsTrackingId={"suggested-escalation-binding"}
      icon={IconEnum.EscalationPath}
      iconProps={{
        className: "text-alarmalade-content",
      }}
      theme={ButtonTheme.Dashed}
      size={BadgeSize.Medium}
    >
      <div className="flex items-center truncate">
        <TruncatingReferenceLabel path={path} />
      </div>
    </Button>
  );
};

interface ConfirmExpressionSuggestionModalProps {
  suggestion: ExpressionSuggestion;
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  onClose: () => void;
  onConfirmExpression: (expression: ExpressionFormData) => void;
  catalogTypes: CatalogType[];
}

const ConfirmExpressionSuggestionModal = ({
  suggestion,
  resources,
  scopeWithExpressions,
  onClose,
  onConfirmExpression,
  catalogTypes,
}: ConfirmExpressionSuggestionModalProps) => {
  const formMethods = useForm<
    ExpressionFormData & {
      confirmation_mode: "with_fallback" | "no_fallback";
    }
  >({
    defaultValues: {
      ...suggestion.expression,
      confirmation_mode: "no_fallback",
    },
    shouldUnregister: false,
  });

  const path = pathFromSuggestion({
    bindingSuggestion: suggestion,
    scope: scopeWithExpressions,
    catalogTypes: catalogTypes,
  });

  const [confirmationMode] = formMethods.watch(["confirmation_mode"]);

  const radioOptions: RadioButtonGroupOption[] = [
    {
      value: "with_fallback",
      label: "Use a fallback value",
      renderWhenSelectedNode: () => (
        <div className="mt-4">
          <ExpressionElseBranchInput
            label={<div className={"-mt-2"} />}
            className="w-full"
            scope={scopeWithExpressions}
            resourceType={suggestion.expression.returns.type}
            isArray={suggestion.expression.returns.array}
            required={confirmationMode === "with_fallback"}
            availableReturnTypeResources={resources}
          />
        </div>
      ),
    },
    {
      value: "no_fallback",
      label: "Do nothing",
    },
  ];

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Do you want a fallback?"
      analyticsTrackingId="fallback-expression-suggestion"
      onClose={onClose}
      onSubmit={(data) => {
        // If no fallback is selected, ensure the else branch is cleared
        if (formMethods.getValues("confirmation_mode") === "no_fallback") {
          data.else_branch = undefined;
        }
        onConfirmExpression(data);
      }}
      contentClassName="flex flex-col space-y-6"
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Confirm"
        />
      }
    >
      <RadioButtonGroupV2
        formMethods={formMethods}
        options={radioOptions}
        name="confirmation_mode"
        label={
          <div className={"mb-2"}>
            When{" "}
            {path ? (
              <Badge
                theme={BadgeTheme.Tertiary}
                className="inline-flex items-center truncate"
              >
                <TruncatingReferenceLabel path={path} />
              </Badge>
            ) : (
              "the result"
            )}{" "}
            is not set, what should we do?
          </div>
        }
        srLabel="Select fallback option"
        boxed
      />
    </Form.Modal>
  );
};

const pathFromSuggestion = ({
  bindingSuggestion,
  scope,
  catalogTypes,
}: {
  bindingSuggestion: BindingSuggestion;
  scope: EngineScope;
  catalogTypes: CatalogType[];
}): (MenuPathItem & { label: string | undefined })[] | null => {
  let path: (MenuPathItem & { label: string | undefined })[];
  let rootCatalogType: CatalogType | undefined;

  // If it's just a reference, we can display a simpler badge
  if (isReferenceSuggestion(bindingSuggestion)) {
    const rootReference = scope.references.find(
      (r) => bindingSuggestion.key === r.key,
    );

    rootCatalogType = catalogTypes.find(
      (t) => t.engine_resource_type === rootReference?.type,
    );

    if (!rootCatalogType) {
      return null;
    }

    path = [
      {
        key: rootCatalogType.name,
        label: rootReference?.label,
      },
    ];
  } else {
    // If it's an expression suggestion, then we need to build the path
    const rootReference = scope.references.find(
      (r) => bindingSuggestion.expression?.root_reference === r.key,
    );

    rootCatalogType = catalogTypes.find(
      (t) => t.engine_resource_type === rootReference?.type,
    );

    if (!rootCatalogType) {
      return null;
    }

    path = [
      {
        key: rootCatalogType.name,
        label: rootReference?.label,
      },
      ...bindingSuggestion.path.map((pathItem) => ({
        key: pathItem.attribute_id,
        label: pathItem.attribute_name,
      })),
    ];
  }

  // We replace the initial 'Alert → Attributes' with just 'Alert' so it looks
  // a little less noisy when repeated for a few attributes.
  return path.map((p) => ({
    ...p,
    label: p.label?.replaceAll("Alert → Attributes", "Alert"),
  }));
};
