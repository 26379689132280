import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Callout, CalloutTheme, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { cacheKey, useMutationV2 } from "src/utils/swr";

export const GrantOwnerButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.GrantOwnerRole,
  );

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => {
          setModalOpen(true);
        }}
        {...disabledProps}
      >
        🔒 Assign a new owner
      </GatedButton>
      {modalOpen && (
        <GrantOwnerModal
          onClose={() => setModalOpen(false)}
          orgSlug={data.organisation_slug}
        />
      )}
    </>
  );
};

type GrantOwnerFormData = {
  email: string;
};
const GrantOwnerModal = ({
  onClose,
  orgSlug,
}: {
  onClose: () => void;
  orgSlug: string;
}) => {
  const formMethods = useForm<GrantOwnerFormData>();
  const showToast = useToast();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useMutationV2(
    async (apiClient, formData: GrantOwnerFormData) => {
      await apiClient.staffGrantOwnerRole({
        organisationSlug: orgSlug,
        grantOwnerRoleRequestBody: { email: formData.email },
      });
    },
    {
      invalidate: [
        cacheKey.exactly("staffShowOrganisation", {
          organisationSlug: orgSlug,
        }),
      ],
      setError: formMethods.setError,

      onSuccess: () => {
        showToast({
          title: "Owner role granted",
          theme: ToastTheme.Success,
        });
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      title="Assign a new owner"
      analyticsTrackingId={null}
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      genericError={genericError}
      saving={saving}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          saving={saving}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="email"
        label="Email"
        required
        helptext="You can find all Slack owners in the 'key information' tab"
      />
      <Callout
        theme={CalloutTheme.Warning}
        title="Must be a Slack workspace owner"
        subtitle="Granting someone owner permissions will allow them to see all private incidents in their workspace. We want to ensure we don't leak any data we shouldn't, so you won't be able to make this person an incident.io owner unless they are also a Slack workspace owner."
      />
    </Form.Modal>
  );
};
