import { ErrorResponse } from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { SimilarIncidentsDrawer } from "../nudges/ai/SimilarIncidentsDrawer";
import { SuggestedFollowUpsDrawer } from "../nudges/ai/SuggestedFollowUpsDrawer";
import { SuggestedSummariesDrawer } from "../nudges/ai/SuggestedSummariesDrawer";
import { AssistantDrawer } from "./AssistantDrawer";
import { CopilotDrawer } from "./CopilotDrawer";
import { InvestigationsDrawer } from "./InvestigationsDrawer";
import { ScribeDrawer } from "./ScribeDrawer";

export interface AIFeatureConfig {
  slug: string;
  enabled: boolean;
  title: string;
  subtitle: string;
  icon: IconEnum;
  Drawer: React.ComponentType<AISuggestionFeatureDrawerProps>;
}

export interface AISuggestionFeatureDrawerProps {
  onClose: () => void;
  feature: Omit<AIFeatureConfig, "Drawer">;
  shouldShowNudges: boolean;
}

export const useAIFeatures = (): {
  features: AIFeatureConfig[];
  loading: boolean;
  error?: ErrorResponse;
} => {
  const { data: aiConfigResp, error: aiConfigError } = useAPI(
    "aIShowConfig",
    undefined,
  );

  const { data: investigationConfigResp, error: investigationConfigError } =
    useAPI("aIShowInvestigationConfig", undefined);

  const {
    featureLiveCallTranscription,
    disableAiAssistant,
    copilotToggleVisibility,
  } = useFlags();
  const { identity } = useIdentity();

  if (!aiConfigResp || !investigationConfigResp) {
    return {
      loading: true,
      features: [],
    };
  }

  const error = aiConfigError || investigationConfigError;
  if (error) {
    return {
      loading: false,
      error: error as ErrorResponse,
      features: [],
    };
  }

  const aiConfig = aiConfigResp.config;

  const showCopilot =
    identity.organisation_is_demo ||
    identity.organisation_is_staff ||
    copilotToggleVisibility;

  const showInvestigations = investigationConfigResp.config.enabled;

  return {
    loading: false,
    features: compact([
      {
        slug: "summaries",
        enabled: aiConfig.incident_summaries_enabled,
        title: "Suggested summaries",
        subtitle: `Help responders get up to speed quickly. We'll draft summaries for you, and share them after each incident update.`,
        icon: IconEnum.QuoteAIGradient,
        Drawer: SuggestedSummariesDrawer,
      },
      {
        slug: "related-incidents",
        enabled: aiConfig.incident_similarity_enabled,
        title: "Related incidents",
        subtitle: `Learn from previous incidents to resolve new ones faster. We’ll also suggest inviting the related incident’s lead so they can share context.`,
        icon: IconEnum.LinkAIGradient,
        Drawer: SimilarIncidentsDrawer,
      },
      {
        slug: "follow-ups",
        enabled: aiConfig.suggest_followups_enabled,
        title: "Suggested follow-ups",
        subtitle: `Become more resilient in the future. We'll suggest follow-ups once the incident is resolved so they're never forgotten.`,
        icon: IconEnum.FollowUpsAIGradient,
        Drawer: SuggestedFollowUpsDrawer,
      },
      featureLiveCallTranscription
        ? {
            slug: "scribe",
            enabled: aiConfig.scribe_enabled,
            title: "Scribe",
            subtitle: `Transcribe Incident calls. Scribe joins your calls to share detailed notes and summaries in real-time.`,
            icon: IconEnum.Scribe,
            Drawer: ScribeDrawer,
          }
        : undefined,
      showCopilot
        ? {
            slug: "copilot",
            enabled: aiConfig.copilot_enabled,
            title: "Copilot",
            subtitle: `Tag @incident to update the incident, assign roles, create follow-ups, and more.`,
            icon: IconEnum.TranscriptAIGradient,
            Drawer: CopilotDrawer,
          }
        : undefined,
      !disableAiAssistant
        ? {
            slug: "assistant",
            enabled: aiConfig.assistant_enabled,
            title: "Assistant",
            subtitle:
              "Ask Assistant about your incidents to understand historical trends, identify patterns, and build your own charts.",
            icon: IconEnum.AssistantAIGradient,
            Drawer: AssistantDrawer,
          }
        : undefined,
      showInvestigations
        ? {
            slug: "investigations",
            enabled: investigationConfigResp.config.enabled,
            title: "Investigations",
            subtitle: `We analyze your code, logs, monitoring tools and thousands of past incidents to identify a root cause, letting you resolve incidents faster.`,
            icon: IconEnum.SparklesAiGradient,
            Drawer: InvestigationsDrawer,
          }
        : undefined,
    ]),
  };
};
