/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathLevel,
    EscalationPathLevelFromJSON,
    EscalationPathLevelFromJSONTyped,
    EscalationPathLevelToJSON,
} from './EscalationPathLevel';
import {
    EscalationPathNode,
    EscalationPathNodeFromJSON,
    EscalationPathNodeFromJSONTyped,
    EscalationPathNodeToJSON,
} from './EscalationPathNode';
import {
    WeekdayIntervalConfig,
    WeekdayIntervalConfigFromJSON,
    WeekdayIntervalConfigFromJSONTyped,
    WeekdayIntervalConfigToJSON,
} from './WeekdayIntervalConfig';

/**
 * 
 * @export
 * @interface EscalationPath
 */
export interface EscalationPath {
    /**
     * Unique identifier for this escalation path.
     * @type {string}
     * @memberof EscalationPath
     */
    id: string;
    /**
     * (Legacy field maintaned for mobile backwards compatibility) The levels of escalation for this path.
     * @type {Array<EscalationPathLevel>}
     * @memberof EscalationPath
     */
    levels: Array<EscalationPathLevel>;
    /**
     * The name of this escalation path, for the user's reference.
     * @type {string}
     * @memberof EscalationPath
     */
    name: string;
    /**
     * The nodes that form the levels and branches of this escalation path.
     * @type {Array<EscalationPathNode>}
     * @memberof EscalationPath
     */
    path: Array<EscalationPathNode>;
    /**
     * (Legacy field maintaned for mobile backwards compatibility) How many times the entire escalation path should be repeated? 0 for none.
     * @type {number}
     * @memberof EscalationPath
     */
    repeat_times: number;
    /**
     * IDs of teams that own this escalation path
     * @type {Array<string>}
     * @memberof EscalationPath
     */
    team_ids: Array<string>;
    /**
     * The working hours for this escalation path.
     * @type {Array<WeekdayIntervalConfig>}
     * @memberof EscalationPath
     */
    working_hours?: Array<WeekdayIntervalConfig>;
}

export function EscalationPathFromJSON(json: any): EscalationPath {
    return EscalationPathFromJSONTyped(json, false);
}

export function EscalationPathFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPath {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'levels': ((json['levels'] as Array<any>).map(EscalationPathLevelFromJSON)),
        'name': json['name'],
        'path': ((json['path'] as Array<any>).map(EscalationPathNodeFromJSON)),
        'repeat_times': json['repeat_times'],
        'team_ids': json['team_ids'],
        'working_hours': !exists(json, 'working_hours') ? undefined : ((json['working_hours'] as Array<any>).map(WeekdayIntervalConfigFromJSON)),
    };
}

export function EscalationPathToJSON(value?: EscalationPath | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'levels': ((value.levels as Array<any>).map(EscalationPathLevelToJSON)),
        'name': value.name,
        'path': ((value.path as Array<any>).map(EscalationPathNodeToJSON)),
        'repeat_times': value.repeat_times,
        'team_ids': value.team_ids,
        'working_hours': value.working_hours === undefined ? undefined : ((value.working_hours as Array<any>).map(WeekdayIntervalConfigToJSON)),
    };
}

