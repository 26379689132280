import {
  StaffImportStatusPageSubscribersRequestBody,
  StaffImportStatusPageSubscribersResponseBody,
} from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import {
  Button,
  Callout,
  CalloutTheme,
  Heading,
  Icon,
  IconEnum,
  Loader,
  StackedList,
} from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { StaffPermissionEnum } from "src/hooks/useCheckStaffPermissions";
import { useAPI, useMutationV2 } from "src/utils/swr";

import { StaffRoomOperation, StaffRoomOperationProps } from "../Operation";

const ImportStatusPageSubscribersForm = (props: StaffRoomOperationProps) => {
  const formMethods = useForm<StaffImportStatusPageSubscribersRequestBody>({
    defaultValues: {
      status_page_id: undefined,
      status_page_subpage_id: undefined,
      email_addresses: "",
    },
  });

  const { data = { status_page_sub_pages: [], status_pages: [] }, isLoading } =
    useAPI(
      "staffListStatusPageImportOptions",
      {
        organisationSlug: props.organisation.organisation_slug,
      },
      {
        fallbackData: { status_page_sub_pages: [], status_pages: [] },
      },
    );

  const {
    trigger: mutate,
    response: result,
    isMutating: isImporting,
    genericError: importError,
  } = useMutationV2(
    async (apiClient, data: StaffImportStatusPageSubscribersRequestBody) => {
      return await apiClient.staffImportStatusPageSubscribers({
        organisationSlug: props.organisation.organisation_slug,
        importStatusPageSubscribersRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      invalidate: [],
    },
  );

  const handleSubmit = (data: StaffImportStatusPageSubscribersRequestBody) => {
    mutate(data);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-6">
      <Form.Root
        formMethods={formMethods}
        onSubmit={handleSubmit}
        saving={isImporting}
        genericError={importError}
      >
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="status_page_id"
          label="Status page"
          options={data.status_pages}
          isClearable={true}
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="status_page_subpage_id"
          label="Sub-page"
          options={data.status_page_sub_pages}
          isClearable={true}
        />
        <TextareaV2
          formMethods={formMethods}
          label="E-mail addresses"
          required={false}
          name="email_addresses"
          helptext="These can be separated by new lines, or commas."
        />
        <Callout theme={CalloutTheme.Plain}>
          By importing these subscribers, you confirm that the customer has
          given you permission for these users to receive subscription emails.
        </Callout>
        <Button
          type="submit"
          analyticsTrackingId={null}
          disabled={!formMethods.formState.isDirty}
        >
          Import
        </Button>
      </Form.Root>
      {result && (
        <>
          <Heading level={2}>Results</Heading>
          <ResultsList result={result} />
        </>
      )}
    </div>
  );
};

export const ImportStatusPageSubscribers: StaffRoomOperation = {
  id: "import-status-page-subscribers",
  title: "Import subscribers",
  emoji: "📥",
  description:
    "Add a list of emails to a status page, or a sub-page. Any emails here won't be asked for confirmation, and will be subscribed immediately.",
  requiredPermission: StaffPermissionEnum.ImportStatusPageSubscribers,
  Component: ImportStatusPageSubscribersForm,
};

const ResultsList = ({
  result,
}: {
  result: StaffImportStatusPageSubscribersResponseBody;
}) => {
  return (
    <StackedList>
      {result.results.map((r, idx) => (
        <div key={idx} className="flex gap-1.5 p-2">
          <div className="content-center">
            <Icon id={r.successful ? IconEnum.Tick : IconEnum.Close} />
          </div>
          <div>
            <div>{r.email}</div>
            {r.description && (
              <div className="text-xs text-content-secondary">
                {r.description}
              </div>
            )}
          </div>
        </div>
      ))}
    </StackedList>
  );
};
