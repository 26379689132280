/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AlertRoutesApplyGroupingRequestBody,
    AlertRoutesApplyGroupingRequestBodyFromJSON,
    AlertRoutesApplyGroupingRequestBodyToJSON,
    AlertRoutesApplyGroupingResponseBody,
    AlertRoutesApplyGroupingResponseBodyFromJSON,
    AlertRoutesApplyGroupingResponseBodyToJSON,
    AlertRoutesBuildScopeResponseBody,
    AlertRoutesBuildScopeResponseBodyFromJSON,
    AlertRoutesBuildScopeResponseBodyToJSON,
    AlertRoutesCreateAlertRouteRequestBody,
    AlertRoutesCreateAlertRouteRequestBodyFromJSON,
    AlertRoutesCreateAlertRouteRequestBodyToJSON,
    AlertRoutesCreateAlertRouteResponseBody,
    AlertRoutesCreateAlertRouteResponseBodyFromJSON,
    AlertRoutesCreateAlertRouteResponseBodyToJSON,
    AlertRoutesCreateIncidentRequestBody,
    AlertRoutesCreateIncidentRequestBodyFromJSON,
    AlertRoutesCreateIncidentRequestBodyToJSON,
    AlertRoutesCreateIncidentResponseBody,
    AlertRoutesCreateIncidentResponseBodyFromJSON,
    AlertRoutesCreateIncidentResponseBodyToJSON,
    AlertRoutesFindMatchingAlertRoutesRequestBody,
    AlertRoutesFindMatchingAlertRoutesRequestBodyFromJSON,
    AlertRoutesFindMatchingAlertRoutesRequestBodyToJSON,
    AlertRoutesFindMatchingAlertRoutesResponseBody,
    AlertRoutesFindMatchingAlertRoutesResponseBodyFromJSON,
    AlertRoutesFindMatchingAlertRoutesResponseBodyToJSON,
    AlertRoutesListAlertRoutesResponseBody,
    AlertRoutesListAlertRoutesResponseBodyFromJSON,
    AlertRoutesListAlertRoutesResponseBodyToJSON,
    AlertRoutesMarkAllUnrelatedRequestBody,
    AlertRoutesMarkAllUnrelatedRequestBodyFromJSON,
    AlertRoutesMarkAllUnrelatedRequestBodyToJSON,
    AlertRoutesMarkAllUnrelatedResponseBody,
    AlertRoutesMarkAllUnrelatedResponseBodyFromJSON,
    AlertRoutesMarkAllUnrelatedResponseBodyToJSON,
    AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody,
    AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyFromJSON,
    AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyToJSON,
    AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody,
    AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyFromJSON,
    AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyToJSON,
    AlertRoutesPreviewFindMatchingAlertsRequestBody,
    AlertRoutesPreviewFindMatchingAlertsRequestBodyFromJSON,
    AlertRoutesPreviewFindMatchingAlertsRequestBodyToJSON,
    AlertRoutesPreviewFindMatchingAlertsResponseBody,
    AlertRoutesPreviewFindMatchingAlertsResponseBodyFromJSON,
    AlertRoutesPreviewFindMatchingAlertsResponseBodyToJSON,
    AlertRoutesPreviewIncidentRequestBody,
    AlertRoutesPreviewIncidentRequestBodyFromJSON,
    AlertRoutesPreviewIncidentRequestBodyToJSON,
    AlertRoutesPreviewIncidentResponseBody,
    AlertRoutesPreviewIncidentResponseBodyFromJSON,
    AlertRoutesPreviewIncidentResponseBodyToJSON,
    AlertRoutesShowAlertRouteResponseBody,
    AlertRoutesShowAlertRouteResponseBodyFromJSON,
    AlertRoutesShowAlertRouteResponseBodyToJSON,
    AlertRoutesUpdateAlertRouteEnabledRequestBody,
    AlertRoutesUpdateAlertRouteEnabledRequestBodyFromJSON,
    AlertRoutesUpdateAlertRouteEnabledRequestBodyToJSON,
    AlertRoutesUpdateAlertRouteEnabledResponseBody,
    AlertRoutesUpdateAlertRouteEnabledResponseBodyFromJSON,
    AlertRoutesUpdateAlertRouteEnabledResponseBodyToJSON,
    AlertRoutesUpdateAlertRouteNameRequestBody,
    AlertRoutesUpdateAlertRouteNameRequestBodyFromJSON,
    AlertRoutesUpdateAlertRouteNameRequestBodyToJSON,
    AlertRoutesUpdateAlertRouteNameResponseBody,
    AlertRoutesUpdateAlertRouteNameResponseBodyFromJSON,
    AlertRoutesUpdateAlertRouteNameResponseBodyToJSON,
    AlertRoutesUpdateAlertRouteRequestBody,
    AlertRoutesUpdateAlertRouteRequestBodyFromJSON,
    AlertRoutesUpdateAlertRouteRequestBodyToJSON,
    AlertRoutesUpdateAlertRouteResponseBody,
    AlertRoutesUpdateAlertRouteResponseBodyFromJSON,
    AlertRoutesUpdateAlertRouteResponseBodyToJSON,
} from '../models';

export interface AlertRoutesApplyGroupingRequest {
    id: string;
    applyGroupingRequestBody: AlertRoutesApplyGroupingRequestBody;
}

export interface AlertRoutesCreateAlertRouteRequest {
    createAlertRouteRequestBody: AlertRoutesCreateAlertRouteRequestBody;
}

export interface AlertRoutesCreateIncidentRequest {
    alertId: string;
    createIncidentRequestBody: AlertRoutesCreateIncidentRequestBody;
}

export interface AlertRoutesDestroyAlertRouteRequest {
    id: string;
}

export interface AlertRoutesFindMatchingAlertRoutesRequest {
    findMatchingAlertRoutesRequestBody: AlertRoutesFindMatchingAlertRoutesRequestBody;
}

export interface AlertRoutesListAlertRoutesRequest {
    pageSize?: number;
    after?: string;
}

export interface AlertRoutesMarkAllUnrelatedRequest {
    alertId: string;
    markAllUnrelatedRequestBody: AlertRoutesMarkAllUnrelatedRequestBody;
}

export interface AlertRoutesPreviewFindAndGroupMatchingAlertsRequest {
    previewFindAndGroupMatchingAlertsRequestBody: AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody;
}

export interface AlertRoutesPreviewFindMatchingAlertsRequest {
    previewFindMatchingAlertsRequestBody: AlertRoutesPreviewFindMatchingAlertsRequestBody;
}

export interface AlertRoutesPreviewIncidentRequest {
    previewIncidentRequestBody: AlertRoutesPreviewIncidentRequestBody;
}

export interface AlertRoutesShowAlertRouteRequest {
    id: string;
}

export interface AlertRoutesUpdateAlertRouteRequest {
    id: string;
    updateAlertRouteRequestBody: AlertRoutesUpdateAlertRouteRequestBody;
}

export interface AlertRoutesUpdateAlertRouteEnabledRequest {
    id: string;
    updateAlertRouteEnabledRequestBody: AlertRoutesUpdateAlertRouteEnabledRequestBody;
}

export interface AlertRoutesUpdateAlertRouteNameRequest {
    id: string;
    updateAlertRouteNameRequestBody: AlertRoutesUpdateAlertRouteNameRequestBody;
}

/**
 * 
 */
export class AlertRoutesApi extends runtime.BaseAPI {

    /**
     * Applies the alert route rules to find which incidents match against this alert
     * ApplyGrouping Alert Routes
     */
    async alertRoutesApplyGroupingRaw(requestParameters: AlertRoutesApplyGroupingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesApplyGroupingResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesApplyGrouping.');
        }

        if (requestParameters.applyGroupingRequestBody === null || requestParameters.applyGroupingRequestBody === undefined) {
            throw new runtime.RequiredError('applyGroupingRequestBody','Required parameter requestParameters.applyGroupingRequestBody was null or undefined when calling alertRoutesApplyGrouping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_routes/{id}/actions/apply_grouping`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesApplyGroupingRequestBodyToJSON(requestParameters.applyGroupingRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesApplyGroupingResponseBodyFromJSON(jsonValue));
    }

    /**
     * Applies the alert route rules to find which incidents match against this alert
     * ApplyGrouping Alert Routes
     */
    async alertRoutesApplyGrouping(requestParameters: AlertRoutesApplyGroupingRequest, initOverrides?: RequestInit): Promise<AlertRoutesApplyGroupingResponseBody> {
        const response = await this.alertRoutesApplyGroupingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Build an alert scope, containing a reference to each of the resources in the alert schema
     * BuildScope Alert Routes
     */
    async alertRoutesBuildScopeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesBuildScopeResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_route_alert_scope`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build an alert scope, containing a reference to each of the resources in the alert schema
     * BuildScope Alert Routes
     */
    async alertRoutesBuildScope(initOverrides?: RequestInit): Promise<AlertRoutesBuildScopeResponseBody> {
        const response = await this.alertRoutesBuildScopeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new alert route config
     * CreateAlertRoute Alert Routes
     */
    async alertRoutesCreateAlertRouteRaw(requestParameters: AlertRoutesCreateAlertRouteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesCreateAlertRouteResponseBody>> {
        if (requestParameters.createAlertRouteRequestBody === null || requestParameters.createAlertRouteRequestBody === undefined) {
            throw new runtime.RequiredError('createAlertRouteRequestBody','Required parameter requestParameters.createAlertRouteRequestBody was null or undefined when calling alertRoutesCreateAlertRoute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesCreateAlertRouteRequestBodyToJSON(requestParameters.createAlertRouteRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesCreateAlertRouteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new alert route config
     * CreateAlertRoute Alert Routes
     */
    async alertRoutesCreateAlertRoute(requestParameters: AlertRoutesCreateAlertRouteRequest, initOverrides?: RequestInit): Promise<AlertRoutesCreateAlertRouteResponseBody> {
        const response = await this.alertRoutesCreateAlertRouteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transitions all incident alerts for this pending alert to unrelated and creates a new, related incident
     * CreateIncident Alert Routes
     */
    async alertRoutesCreateIncidentRaw(requestParameters: AlertRoutesCreateIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesCreateIncidentResponseBody>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling alertRoutesCreateIncident.');
        }

        if (requestParameters.createIncidentRequestBody === null || requestParameters.createIncidentRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentRequestBody','Required parameter requestParameters.createIncidentRequestBody was null or undefined when calling alertRoutesCreateIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alerts/{alert_id}/actions/create_incident`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesCreateIncidentRequestBodyToJSON(requestParameters.createIncidentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesCreateIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Transitions all incident alerts for this pending alert to unrelated and creates a new, related incident
     * CreateIncident Alert Routes
     */
    async alertRoutesCreateIncident(requestParameters: AlertRoutesCreateIncidentRequest, initOverrides?: RequestInit): Promise<AlertRoutesCreateIncidentResponseBody> {
        const response = await this.alertRoutesCreateIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a particular alert route config
     * DestroyAlertRoute Alert Routes
     */
    async alertRoutesDestroyAlertRouteRaw(requestParameters: AlertRoutesDestroyAlertRouteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesDestroyAlertRoute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a particular alert route config
     * DestroyAlertRoute Alert Routes
     */
    async alertRoutesDestroyAlertRoute(requestParameters: AlertRoutesDestroyAlertRouteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.alertRoutesDestroyAlertRouteRaw(requestParameters, initOverrides);
    }

    /**
     * Finds alert routes that would match against a hypothetical alert scope to return a preview to the user
     * FindMatchingAlertRoutes Alert Routes
     */
    async alertRoutesFindMatchingAlertRoutesRaw(requestParameters: AlertRoutesFindMatchingAlertRoutesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesFindMatchingAlertRoutesResponseBody>> {
        if (requestParameters.findMatchingAlertRoutesRequestBody === null || requestParameters.findMatchingAlertRoutesRequestBody === undefined) {
            throw new runtime.RequiredError('findMatchingAlertRoutesRequestBody','Required parameter requestParameters.findMatchingAlertRoutesRequestBody was null or undefined when calling alertRoutesFindMatchingAlertRoutes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_route_matches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesFindMatchingAlertRoutesRequestBodyToJSON(requestParameters.findMatchingAlertRoutesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesFindMatchingAlertRoutesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Finds alert routes that would match against a hypothetical alert scope to return a preview to the user
     * FindMatchingAlertRoutes Alert Routes
     */
    async alertRoutesFindMatchingAlertRoutes(requestParameters: AlertRoutesFindMatchingAlertRoutesRequest, initOverrides?: RequestInit): Promise<AlertRoutesFindMatchingAlertRoutesResponseBody> {
        const response = await this.alertRoutesFindMatchingAlertRoutesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List active alert route configs
     * ListAlertRoutes Alert Routes
     */
    async alertRoutesListAlertRoutesRaw(requestParameters: AlertRoutesListAlertRoutesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesListAlertRoutesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_routes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesListAlertRoutesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List active alert route configs
     * ListAlertRoutes Alert Routes
     */
    async alertRoutesListAlertRoutes(requestParameters: AlertRoutesListAlertRoutesRequest, initOverrides?: RequestInit): Promise<AlertRoutesListAlertRoutesResponseBody> {
        const response = await this.alertRoutesListAlertRoutesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transitions all incident alerts for this pending alert to unrelated and (if applicable) creates a new, related incident
     * MarkAllUnrelated Alert Routes
     */
    async alertRoutesMarkAllUnrelatedRaw(requestParameters: AlertRoutesMarkAllUnrelatedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesMarkAllUnrelatedResponseBody>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling alertRoutesMarkAllUnrelated.');
        }

        if (requestParameters.markAllUnrelatedRequestBody === null || requestParameters.markAllUnrelatedRequestBody === undefined) {
            throw new runtime.RequiredError('markAllUnrelatedRequestBody','Required parameter requestParameters.markAllUnrelatedRequestBody was null or undefined when calling alertRoutesMarkAllUnrelated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alerts/{alert_id}/actions/mark_all_unrelated`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesMarkAllUnrelatedRequestBodyToJSON(requestParameters.markAllUnrelatedRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesMarkAllUnrelatedResponseBodyFromJSON(jsonValue));
    }

    /**
     * Transitions all incident alerts for this pending alert to unrelated and (if applicable) creates a new, related incident
     * MarkAllUnrelated Alert Routes
     */
    async alertRoutesMarkAllUnrelated(requestParameters: AlertRoutesMarkAllUnrelatedRequest, initOverrides?: RequestInit): Promise<AlertRoutesMarkAllUnrelatedResponseBody> {
        const response = await this.alertRoutesMarkAllUnrelatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find and group alerts that would meet given conditions and match for grouping keys to return a preview to the user
     * PreviewFindAndGroupMatchingAlerts Alert Routes
     */
    async alertRoutesPreviewFindAndGroupMatchingAlertsRaw(requestParameters: AlertRoutesPreviewFindAndGroupMatchingAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody>> {
        if (requestParameters.previewFindAndGroupMatchingAlertsRequestBody === null || requestParameters.previewFindAndGroupMatchingAlertsRequestBody === undefined) {
            throw new runtime.RequiredError('previewFindAndGroupMatchingAlertsRequestBody','Required parameter requestParameters.previewFindAndGroupMatchingAlertsRequestBody was null or undefined when calling alertRoutesPreviewFindAndGroupMatchingAlerts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/preview_alert_route_grouped_alert_matches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyToJSON(requestParameters.previewFindAndGroupMatchingAlertsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Find and group alerts that would meet given conditions and match for grouping keys to return a preview to the user
     * PreviewFindAndGroupMatchingAlerts Alert Routes
     */
    async alertRoutesPreviewFindAndGroupMatchingAlerts(requestParameters: AlertRoutesPreviewFindAndGroupMatchingAlertsRequest, initOverrides?: RequestInit): Promise<AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody> {
        const response = await this.alertRoutesPreviewFindAndGroupMatchingAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finds alerts that would meet given conditions for given alert sources to return a preview to the user
     * PreviewFindMatchingAlerts Alert Routes
     */
    async alertRoutesPreviewFindMatchingAlertsRaw(requestParameters: AlertRoutesPreviewFindMatchingAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesPreviewFindMatchingAlertsResponseBody>> {
        if (requestParameters.previewFindMatchingAlertsRequestBody === null || requestParameters.previewFindMatchingAlertsRequestBody === undefined) {
            throw new runtime.RequiredError('previewFindMatchingAlertsRequestBody','Required parameter requestParameters.previewFindMatchingAlertsRequestBody was null or undefined when calling alertRoutesPreviewFindMatchingAlerts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/preview_alert_route_alert_matches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesPreviewFindMatchingAlertsRequestBodyToJSON(requestParameters.previewFindMatchingAlertsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesPreviewFindMatchingAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Finds alerts that would meet given conditions for given alert sources to return a preview to the user
     * PreviewFindMatchingAlerts Alert Routes
     */
    async alertRoutesPreviewFindMatchingAlerts(requestParameters: AlertRoutesPreviewFindMatchingAlertsRequest, initOverrides?: RequestInit): Promise<AlertRoutesPreviewFindMatchingAlertsResponseBody> {
        const response = await this.alertRoutesPreviewFindMatchingAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview incident as how it would be templated from an alert
     * PreviewIncident Alert Routes
     */
    async alertRoutesPreviewIncidentRaw(requestParameters: AlertRoutesPreviewIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesPreviewIncidentResponseBody>> {
        if (requestParameters.previewIncidentRequestBody === null || requestParameters.previewIncidentRequestBody === undefined) {
            throw new runtime.RequiredError('previewIncidentRequestBody','Required parameter requestParameters.previewIncidentRequestBody was null or undefined when calling alertRoutesPreviewIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_route_preview_incident`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesPreviewIncidentRequestBodyToJSON(requestParameters.previewIncidentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesPreviewIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview incident as how it would be templated from an alert
     * PreviewIncident Alert Routes
     */
    async alertRoutesPreviewIncident(requestParameters: AlertRoutesPreviewIncidentRequest, initOverrides?: RequestInit): Promise<AlertRoutesPreviewIncidentResponseBody> {
        const response = await this.alertRoutesPreviewIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a particular alert route config
     * ShowAlertRoute Alert Routes
     */
    async alertRoutesShowAlertRouteRaw(requestParameters: AlertRoutesShowAlertRouteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesShowAlertRouteResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesShowAlertRoute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesShowAlertRouteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a particular alert route config
     * ShowAlertRoute Alert Routes
     */
    async alertRoutesShowAlertRoute(requestParameters: AlertRoutesShowAlertRouteRequest, initOverrides?: RequestInit): Promise<AlertRoutesShowAlertRouteResponseBody> {
        const response = await this.alertRoutesShowAlertRouteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a particular alert route config
     * UpdateAlertRoute Alert Routes
     */
    async alertRoutesUpdateAlertRouteRaw(requestParameters: AlertRoutesUpdateAlertRouteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesUpdateAlertRouteResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesUpdateAlertRoute.');
        }

        if (requestParameters.updateAlertRouteRequestBody === null || requestParameters.updateAlertRouteRequestBody === undefined) {
            throw new runtime.RequiredError('updateAlertRouteRequestBody','Required parameter requestParameters.updateAlertRouteRequestBody was null or undefined when calling alertRoutesUpdateAlertRoute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesUpdateAlertRouteRequestBodyToJSON(requestParameters.updateAlertRouteRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesUpdateAlertRouteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a particular alert route config
     * UpdateAlertRoute Alert Routes
     */
    async alertRoutesUpdateAlertRoute(requestParameters: AlertRoutesUpdateAlertRouteRequest, initOverrides?: RequestInit): Promise<AlertRoutesUpdateAlertRouteResponseBody> {
        const response = await this.alertRoutesUpdateAlertRouteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable or disable an alert route
     * UpdateAlertRouteEnabled Alert Routes
     */
    async alertRoutesUpdateAlertRouteEnabledRaw(requestParameters: AlertRoutesUpdateAlertRouteEnabledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesUpdateAlertRouteEnabledResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesUpdateAlertRouteEnabled.');
        }

        if (requestParameters.updateAlertRouteEnabledRequestBody === null || requestParameters.updateAlertRouteEnabledRequestBody === undefined) {
            throw new runtime.RequiredError('updateAlertRouteEnabledRequestBody','Required parameter requestParameters.updateAlertRouteEnabledRequestBody was null or undefined when calling alertRoutesUpdateAlertRouteEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_routes/{id}/actions/update_enabled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesUpdateAlertRouteEnabledRequestBodyToJSON(requestParameters.updateAlertRouteEnabledRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesUpdateAlertRouteEnabledResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enable or disable an alert route
     * UpdateAlertRouteEnabled Alert Routes
     */
    async alertRoutesUpdateAlertRouteEnabled(requestParameters: AlertRoutesUpdateAlertRouteEnabledRequest, initOverrides?: RequestInit): Promise<AlertRoutesUpdateAlertRouteEnabledResponseBody> {
        const response = await this.alertRoutesUpdateAlertRouteEnabledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rename an alert route
     * UpdateAlertRouteName Alert Routes
     */
    async alertRoutesUpdateAlertRouteNameRaw(requestParameters: AlertRoutesUpdateAlertRouteNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertRoutesUpdateAlertRouteNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertRoutesUpdateAlertRouteName.');
        }

        if (requestParameters.updateAlertRouteNameRequestBody === null || requestParameters.updateAlertRouteNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateAlertRouteNameRequestBody','Required parameter requestParameters.updateAlertRouteNameRequestBody was null or undefined when calling alertRoutesUpdateAlertRouteName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_routes/{id}/actions/update_name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertRoutesUpdateAlertRouteNameRequestBodyToJSON(requestParameters.updateAlertRouteNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertRoutesUpdateAlertRouteNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Rename an alert route
     * UpdateAlertRouteName Alert Routes
     */
    async alertRoutesUpdateAlertRouteName(requestParameters: AlertRoutesUpdateAlertRouteNameRequest, initOverrides?: RequestInit): Promise<AlertRoutesUpdateAlertRouteNameResponseBody> {
        const response = await this.alertRoutesUpdateAlertRouteNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
