import { Investigation, InvestigationCheck } from "@incident-io/api";
import { Tooltip } from "@incident-ui";
import { useMemo } from "react";

const STATES = [
  { name: "Pending", color: "bg-slate-300" },
  { name: "Running", color: "bg-blue-500" },
  { name: "Success", color: "bg-green-500" },
  { name: "Error", color: "bg-red-500" },
] as const;

const getStatusColor = (check: InvestigationCheck) => {
  if (!check.started_at) {
    return "bg-slate-300";
  }
  if (!check.completed_at) {
    return "bg-blue-500";
  }
  if (check.error_message) {
    return "bg-red-500";
  }
  return "bg-green-500";
};

const getStatusText = (check: InvestigationCheck) => {
  if (!check.started_at) return "Pending";
  if (!check.completed_at) return "Running";
  return check.error_message ? "Error" : "Complete";
};

const getRelevanceIndicator = (relevance: string) => {
  switch (relevance) {
    case "highly_relevant":
      return "⭐⭐";
    case "relevant":
      return "⭐";
    case "not_relevant":
      return "○";
    default:
      return "";
  }
};

const getRelevanceText = (relevance: string) => {
  switch (relevance) {
    case "highly_relevant":
      return "Highly Relevant";
    case "relevant":
      return "Relevant";
    case "not_relevant":
      return "Not Relevant";
    default:
      return "Unknown Relevance";
  }
};

export const InvestigationTrace = ({
  investigation,
  checks: checksProp,
}: {
  investigation: Investigation;
  checks: InvestigationCheck[];
}) => {
  const BAR_HEIGHT = 32;
  const ROW_HEIGHT = 48;
  const INCOMPLETE_DURATION_MS = 250;

  // Sort the checks so they appear in a sensible order.
  const checks = useMemo(() => {
    return [...checksProp].sort((a, b) => {
      // First priority: completed_at
      if (a.completed_at && b.completed_at) {
        return a.completed_at.getTime() - b.completed_at.getTime();
      }
      if (a.completed_at) return -1;
      if (b.completed_at) return 1;

      // Second priority: started_at for incomplete checks
      if (a.started_at && b.started_at) {
        return a.started_at.getTime() - b.started_at.getTime();
      }
      if (a.started_at) return -1;
      if (b.started_at) return 1;

      // Last priority: created_at
      return a.created_at.getTime() - b.created_at.getTime();
    });
  }, [checksProp]);

  // Calculate timeline bounds
  const startTime = Math.min(
    ...checks.map((c) => c.started_at?.getTime() ?? c.created_at.getTime()),
  );
  const endTime = Math.max(
    ...checks.map((c) => {
      if (c.completed_at) {
        return c.completed_at.getTime();
      }
      if (c.started_at) {
        // For running checks, show a minimal duration
        return c.started_at.getTime() + INCOMPLETE_DURATION_MS;
      }
      // For pending checks, show a minimal duration from created_at
      return c.created_at.getTime() + INCOMPLETE_DURATION_MS;
    }),
  );
  const totalDuration = endTime - startTime;

  const getBarStyles = (check: InvestigationCheck) => {
    // For pending checks (no started_at), use created_at
    const checkStart =
      check.started_at?.getTime() ?? check.created_at.getTime();

    let checkEnd: number;
    if (check.completed_at) {
      checkEnd = check.completed_at.getTime();
    } else {
      // For running or pending checks, show minimal duration
      checkEnd = checkStart + INCOMPLETE_DURATION_MS;
    }

    const left = ((checkStart - startTime) / totalDuration) * 100;
    const width = ((checkEnd - checkStart) / totalDuration) * 100;

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  };

  // Calculate total runtime in seconds
  const totalRuntime = (endTime - startTime) / 1000;

  return (
    <div className="w-full border-t border-slate-100">
      <div className="flex w-full gap-2">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0 pr-4 border-r border-slate-100">
          {checks.map((check) => (
            <div
              key={check.id}
              className="text-sm"
              style={{
                height: `${ROW_HEIGHT}px`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="font-medium truncate" title={check.title}>
                {check.title}
              </div>
              <div className="text-xs text-slate-500">
                {check.duration_seconds.toFixed(2)}s | $
                {(check.total_cost_in_cents / 100).toFixed(4)}
              </div>
            </div>
          ))}
        </div>

        {/* Timeline */}
        <div className="flex-grow">
          <div
            className="relative"
            style={{ height: `${checks.length * ROW_HEIGHT}px` }}
          >
            {checks.map((check, index) => {
              const styles = getBarStyles(check);
              const verticalCenter = (ROW_HEIGHT - BAR_HEIGHT) / 2;

              return (
                <div
                  key={check.id}
                  className="absolute"
                  style={{
                    ...styles,
                    height: `${BAR_HEIGHT}px`,
                    top: `${index * ROW_HEIGHT + verticalCenter}px`,
                  }}
                >
                  <Tooltip
                    content={
                      <div className="text-sm">
                        <div className="font-semibold border-b pb-1 mb-1">
                          {check.title}
                        </div>
                        <div>
                          <strong>Status:</strong> {getStatusText(check)}
                        </div>
                        <div>
                          <strong>Relevance:</strong>{" "}
                          {getRelevanceText(check.finding_relevance)}
                        </div>
                        {check.started_at && (
                          <div>
                            <strong>Started:</strong>{" "}
                            {check.started_at.toLocaleTimeString()}
                          </div>
                        )}
                        {check.completed_at && (
                          <div>
                            <strong>Completed:</strong>{" "}
                            {check.completed_at.toLocaleTimeString()}
                          </div>
                        )}
                      </div>
                    }
                  >
                    <div
                      className={`h-full ${getStatusColor(
                        check,
                      )} rounded flex items-center px-2 text-white justify-between`}
                    >
                      <span className="text-xs font-medium truncate">
                        {check.title}
                      </span>
                      <span className="text-xs ml-2">
                        {getRelevanceIndicator(check.finding_relevance)}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Footer with legend and totals */}
      <div className="flex justify-between items-center p-4 text-sm text-slate-600 border-t mt-4">
        <div className="flex items-center gap-6">
          {/* Status legend */}
          <div className="flex items-center gap-4">
            {STATES.map((state) => (
              <div key={state.name} className="flex items-center gap-2">
                <div className={`w-3 h-3 rounded ${state.color}`} />
                <span>{state.name}</span>
              </div>
            ))}
          </div>

          {/* Relevance legend */}
          <div className="flex items-center gap-4 border-l pl-6">
            <div className="flex items-center gap-2">
              <span>⭐⭐</span>
              <span>Highly Relevant</span>
            </div>
            <div className="flex items-center gap-2">
              <span>⭐</span>
              <span>Relevant</span>
            </div>
            <div className="flex items-center gap-2">
              <span>○</span>
              <span>Not Relevant</span>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <span>Total runtime: {totalRuntime.toFixed(2)}s</span>
          <span>
            Total cost: ${(investigation.total_cost_in_cents / 100).toFixed(4)}
          </span>
        </div>
      </div>
    </div>
  );
};
