import { Button, ButtonProps, ButtonTheme } from "@incident-ui/Button/Button";
import { tcx } from "src/utils/tailwind-classes";

export const ButtonGroup = ({
  value,
  onChange,
  options,
  analyticsTrackingId,
  className,
  buttonClassName,
  buttonProps,
}: {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  options: { label: string | React.ReactNode; value: string }[];
  analyticsTrackingId: string;
  buttonClassName?: string;
  buttonProps?: Omit<ButtonProps, "analyticsTrackingId">;
}) => {
  return (
    <div
      className={tcx(
        "rounded-2 bg-surface-secondary flex items-center h-fit",
        className,
      )}
    >
      {options.map((o) => {
        const isSelected = o.value === value;
        return (
          <Button
            className={tcx(
              "px-3 py-2",
              {
                "text-slate-600 font-medium text-sm": !isSelected,
              },
              "inline-flex",
              buttonClassName,
            )}
            theme={isSelected ? ButtonTheme.Secondary : ButtonTheme.Naked}
            key={o.value}
            analyticsTrackingId={`${analyticsTrackingId}-${o.value}`}
            onClick={() => onChange(o.value)}
            {...buttonProps}
          >
            {o.label}
          </Button>
        );
      })}
    </div>
  );
};
