import { Txt } from "@incident-ui";
import { Helmet } from "react-helmet";
import { tcx } from "src/utils/tailwind-classes";

export const UserPreferencesHeading = ({
  title,
  description,
  className,
}: {
  title: string;
  description?: string;
  className?: string;
}) => {
  return (
    <div className={tcx("flex flex-col space-y-2", className)}>
      <Helmet title={`${title} - incident.io`} />
      {description && <Txt grey>{description}</Txt>}
    </div>
  );
};
