import {
  CatalogResource,
  Resource,
  TeamsCreateRequestBody,
  TeamSettings,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useTeamsRevalidate } from "../convert/hooks";

type FormData = TeamsCreateRequestBody;

export const TeamsCreateDrawer = () => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const onClose = () => navigate("/settings/teams");

  const formMethods = useForm<FormData>();

  const revalidateTeams = useTeamsRevalidate();

  const { data: teamSettingsData, error: teamSettingsError } = useAPI(
    "teamsGetSettings",
    undefined,
  );

  const { data: resourcesData, error: resourcesError } = useAPI(
    "catalogListResources",
    undefined,
  );

  const { trigger, isMutating } = useAPIMutation(
    "teamsList",
    {},
    async (apiClient, body: FormData) => {
      const response = await apiClient.teamsCreate({ createRequestBody: body });
      if (response?.team) {
        navigate(`/settings/teams/${response.team.id}`);
      }
    },
    {
      onSuccess: () => {
        // We need to explicitly refetch because the team list uses infinite scroll
        revalidateTeams();
        showToast({
          title: "Team created",
          theme: ToastTheme.Success,
        });
      },
      showErrorToast: "Team creation failed",
    },
  );

  if (teamSettingsError || resourcesError) {
    return (
      <Drawer onClose={onClose} width={"small"}>
        <DrawerTitle title="Error" onClose={onClose} />
        <DrawerContents>
          <DrawerBody>
            <GenericErrorMessage error={teamSettingsError || resourcesError} />
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    );
  }

  if (!teamSettingsData || !resourcesData) {
    return (
      <Drawer onClose={onClose} width={"small"}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  return (
    <Drawer onClose={onClose} width={"small"}>
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.Team}
          color={ColorPaletteEnum.Slate}
          title="Create team"
          subtitle="Teams allow you to scope your incident dashboard to information more relevant to you."
          onClose={onClose}
        />
        <DrawerBody className="flex grow">
          <TeamsCreateForm
            formMethods={formMethods}
            trigger={trigger}
            isMutating={isMutating}
            teamSettings={teamSettingsData.team_settings}
            resources={resourcesData.resources}
          />
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button
            type={"button"}
            onClick={onClose}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={"cancel-create-team"}
          >
            Back
          </Button>
          <Button
            type="submit"
            form="create-team"
            analyticsTrackingId={"create-team"}
            theme={ButtonTheme.Primary}
            loading={isMutating}
            disabled={!formMethods.formState.isValid}
          >
            Create team
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const TeamsCreateForm = ({
  trigger,
  formMethods,
  isMutating,
  teamSettings,
  resources,
}: {
  trigger: (data: FormData) => void;
  formMethods: UseFormReturn<FormData>;
  isMutating: boolean;
  teamSettings: TeamSettings;
  resources: CatalogResource[];
}) => {
  const attr = teamSettings.catalog_type?.schema.attributes.find(
    (attribute) =>
      attribute.id === teamSettings.members_derived_from_attribute_id,
  );
  const engineResources: Resource[] = resources.map((res) => res.config);
  const resource = resources.find((res) => res.type === attr?.type);

  const canSetMembersFromAttribute = !!attr && !!resource;

  return (
    <Form.Root
      id="create-team"
      onSubmit={trigger}
      formMethods={formMethods}
      saving={isMutating}
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required={true}
        autoFocus={true}
        placeholder="Choose a descriptive name for this team"
      />
      {canSetMembersFromAttribute && (
        <div className="bg-surface-secondary p-4 rounded-b">
          <EngineFormElement
            mode="plain_input"
            key={attr.id}
            name={"member_attribute_value"}
            label={attr.name}
            resources={engineResources}
            required={false}
            array={attr.array}
            resourceType={resource.config.type}
            className="w-full"
            showPlaceholder
          />
        </div>
      )}
    </Form.Root>
  );
};
