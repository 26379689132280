import { useFlags } from "launchdarkly-react-client-sdk";

import { useAPI } from "../../../../utils/swr";

export const useUserTeams = () => {
  const { featureNativeTeams } = useFlags();

  const {
    data: userTeamsData,
    isLoading,
    error,
  } = useAPI("teamsListMyTeams", {});

  return {
    userTeams: featureNativeTeams ? userTeamsData?.team_ids || [] : [],
    error,
    loading: isLoading,
  };
};
