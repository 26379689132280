import { Badge, BadgeSize, BadgeTheme, IconEnum } from "@incident-ui";
import { useAPI } from "src/utils/swr";

export const TeamBadge = ({ teamId }: { teamId: string }) => {
  const { data, isLoading, error } = useAPI("teamsShow", {
    id: teamId,
  });

  // Don't error a whole page if we can't show a badge
  if (error) {
    return null;
  }

  return (
    <Badge
      theme={BadgeTheme.Tertiary}
      size={BadgeSize.Medium}
      icon={IconEnum.Team}
    >
      {isLoading || !data ? (
        <span>Loading team</span>
      ) : (
        <span className="text-sm font-medium text-content-primary inline-block truncate">
          {data.team.name}
        </span>
      )}
    </Badge>
  );
};
