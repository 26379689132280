/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageAffectedComponentPayload
 */
export interface StatusPageAffectedComponentPayload {
    /**
     * The ID of the status page component that has been affected
     * @type {string}
     * @memberof StatusPageAffectedComponentPayload
     */
    component_id: string;
    /**
     * The worst impact on the component during the incident
     * @type {string}
     * @memberof StatusPageAffectedComponentPayload
     */
    status: StatusPageAffectedComponentPayloadStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageAffectedComponentPayloadStatusEnum {
    Operational = 'operational',
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageAffectedComponentPayloadFromJSON(json: any): StatusPageAffectedComponentPayload {
    return StatusPageAffectedComponentPayloadFromJSONTyped(json, false);
}

export function StatusPageAffectedComponentPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageAffectedComponentPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'status': json['status'],
    };
}

export function StatusPageAffectedComponentPayloadToJSON(value?: StatusPageAffectedComponentPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'status': value.status,
    };
}

