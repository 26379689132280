import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { UserPreferenceManualEscalationProviderOptionProviderEnum } from "src/contexts/ClientContext";

export interface EscalationProviderConfig {
  icon: IconEnum;
  label: string;
  color: ColorPaletteEnum;
}

export const escalationProviderConfig: Record<
  string,
  EscalationProviderConfig
> = {
  [UserPreferenceManualEscalationProviderOptionProviderEnum.Native]: {
    icon: IconEnum.IncidentFlame,
    label: "incident.io",
    color: ColorPaletteEnum.Red,
  },
  [UserPreferenceManualEscalationProviderOptionProviderEnum.Pagerduty]: {
    icon: IconEnum.Pagerduty,
    label: "PagerDuty",
    color: ColorPaletteEnum.Green,
  },
  [UserPreferenceManualEscalationProviderOptionProviderEnum.Opsgenie]: {
    icon: IconEnum.Opsgenie,
    label: "Opsgenie",
    color: ColorPaletteEnum.Blue,
  },
  [UserPreferenceManualEscalationProviderOptionProviderEnum.SplunkOnCall]: {
    icon: IconEnum.Splunk,
    label: "Splunk On-Call",
    color: ColorPaletteEnum.Green,
  },
};

// Helper to format provider names using the config above or fallback to a generic approach
export function formatProviderName(provider: string): string {
  const config = escalationProviderConfig[provider];
  if (config) {
    return config.label;
  }

  // Fallback
  return provider === "native"
    ? "incident.io"
    : provider.charAt(0).toUpperCase() + provider.slice(1).replace(/_/g, " ");
}
