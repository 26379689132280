import { StaffShowOrganisationResponseBody } from "@incident-io/api";

import { InfoTable } from "../common/InfoTable";
import { Value } from "../common/Value";

export const OverviewPanel = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <InfoTable
      rows={[
        {
          id: "id",
          name: "ID",
          value: <Value.IDValue value={data.organisation_id} />,
        },
        {
          id: "signup_date",
          name: "Signed up",
          value: (
            <Value.DateTimeValue
              value={data.organisation_created_at}
              format={"dd LLL yyyy"}
            />
          ),
        },
        {
          id: "comms_platform",
          name: "Comms platform",
          value: <Value.StringValue value={data.primary_comms_platform} />,
        },
        {
          id: "available_products",
          name: "Available products",
          value: (
            <Value.ListValue value={data.organisation_available_products} />
          ),
        },
      ]}
    />
  );
};
