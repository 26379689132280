/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationLeaderboardEntryPayload
 */
export interface EvaluationLeaderboardEntryPayload {
    /**
     * ID of the backtest this entry represents
     * @type {string}
     * @memberof EvaluationLeaderboardEntryPayload
     */
    backtest_id: string;
    /**
     * ID of the leaderboard this entry belongs to
     * @type {string}
     * @memberof EvaluationLeaderboardEntryPayload
     */
    leaderboard_id: string;
    /**
     * Optional notes about this leaderboard entry
     * @type {string}
     * @memberof EvaluationLeaderboardEntryPayload
     */
    notes: string;
}

export function EvaluationLeaderboardEntryPayloadFromJSON(json: any): EvaluationLeaderboardEntryPayload {
    return EvaluationLeaderboardEntryPayloadFromJSONTyped(json, false);
}

export function EvaluationLeaderboardEntryPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationLeaderboardEntryPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backtest_id': json['backtest_id'],
        'leaderboard_id': json['leaderboard_id'],
        'notes': json['notes'],
    };
}

export function EvaluationLeaderboardEntryPayloadToJSON(value?: EvaluationLeaderboardEntryPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backtest_id': value.backtest_id,
        'leaderboard_id': value.leaderboard_id,
        'notes': value.notes,
    };
}

