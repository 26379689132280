import {
  Investigation,
  InvestigationPlanTypeEnum,
  InvestigationStateEnum,
} from "@incident-io/api";
import { OrgAwareLink } from "@incident-shared/org-aware/OrgAware";
import { LocalDateTime } from "@incident-ui";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui/Badge/Badge";
import { Card } from "@incident-ui/Card/Card";
import { ContentBox } from "@incident-ui/ContentBox/ContentBox";
import { EmptyState } from "@incident-ui/EmptyState/EmptyState";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { useAPI } from "src/utils/swr";

import { IncidentFacts } from "../common/IncidentFacts";
import { GroundTruthSectionController } from "./GroundTruthSection";

type GroundTruthsTabProps = {
  incidentId?: string;
};

export const GroundTruthsTab = ({ incidentId }: GroundTruthsTabProps) => {
  const { data: investigations, isLoading } = useAPI("aIListInvestigations", {
    incidentId,
  });

  // Sort investigations by created_at in ascending order if they exist
  const sortedInvestigations = investigations?.investigations?.sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  return (
    <div className={"flex flex-col space-y-6 pt-3"}>
      <div className="flex flex-col space-y-2">
        <h2 className={"text-2xl-bold"}>Ground truth</h2>
        <div className={"text-content-secondary"}>
          We use ground truth data for evaluating investigations. Please set
          these values after closing an incident to help us evaluate
          investigation accuracy!
        </div>
        {incidentId ? (
          <GroundTruthSectionController incidentId={incidentId} />
        ) : null}
      </div>

      {incidentId && (
        <div className="flex flex-col space-y-2">
          <h2 className="text-2xl-bold">Incident facts</h2>
          <div className="text-content-secondary">
            AI-generated information about this incident which has been
            pre-processed.
          </div>
          <IncidentFacts incidentId={incidentId} />
        </div>
      )}

      <div className="flex flex-col space-y-2">
        <h2 className="text-2xl-bold">Investigations</h2>
        <div className="text-content-secondary">
          This is the list of all investigations that have run against this
          incident.
        </div>

        <InvestigationsList
          investigations={sortedInvestigations}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

type InvestigationsListProps = {
  investigations?: Investigation[];
  isLoading: boolean;
};

const InvestigationsList = ({
  investigations,
  isLoading,
}: InvestigationsListProps) => {
  if (isLoading) {
    return (
      <ContentBox className="p-4">
        <div className="flex justify-center items-center h-24">
          <Icon
            id={IconEnum.Loader}
            className="animate-spin text-content-secondary"
          />
        </div>
      </ContentBox>
    );
  }

  if (!investigations || investigations.length === 0) {
    return (
      <ContentBox>
        <EmptyState
          title="No investigations found"
          content="There haven't been any investigations run for this incident yet."
          icon={IconEnum.Search}
        />
      </ContentBox>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      {investigations.map((investigation) => (
        <InvestigationListItem
          key={investigation.id}
          investigation={investigation}
        />
      ))}
    </div>
  );
};

type InvestigationListItemProps = {
  investigation: Investigation;
};

const InvestigationListItem = ({
  investigation,
}: InvestigationListItemProps) => {
  const getStatusBadge = () => {
    switch (investigation.state) {
      case InvestigationStateEnum.Complete:
        return <Badge theme={BadgeTheme.Success}>Complete</Badge>;
      case InvestigationStateEnum.Error:
        return <Badge theme={BadgeTheme.Error}>Error</Badge>;
      case InvestigationStateEnum.Working:
        return <Badge theme={BadgeTheme.Warning}>Working</Badge>;
      case InvestigationStateEnum.Pending:
        return <Badge theme={BadgeTheme.Info}>Pending</Badge>;
      default:
        return (
          <Badge theme={BadgeTheme.Secondary}>{investigation.state}</Badge>
        );
    }
  };

  const getPlanTypeBadge = () => {
    switch (investigation.plan_type) {
      case InvestigationPlanTypeEnum.CodeChanges:
        return (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
            Code changes
          </Badge>
        );
      case InvestigationPlanTypeEnum.Incidents:
        return (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
            Related incidents
          </Badge>
        );
      case InvestigationPlanTypeEnum.Messages:
        return (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
            Messages
          </Badge>
        );
      case InvestigationPlanTypeEnum.Default:
        return (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
            Default
          </Badge>
        );
      default:
        return null;
    }
  };

  const getInvestigationTitle = () => {
    const planType =
      investigation.plan_type !== InvestigationPlanTypeEnum.Default &&
      investigation.plan_type !== InvestigationPlanTypeEnum.Empty
        ? ` (${investigation.plan_type})`
        : "";

    return `Investigation${planType}`;
  };

  return (
    <OrgAwareLink
      to={`/workbench/investigations/${investigation.id}`}
      className="no-underline"
      analyticsTrackingId="ground_truths.investigation_item"
    >
      <Card
        title={getInvestigationTitle()}
        description={
          <div className="flex flex-col gap-1.5">
            {investigation.summary && (
              <div className="text-sm-normal text-content-secondary line-clamp-2">
                {investigation.summary}
              </div>
            )}
            <div className="flex items-center gap-2 flex-wrap">
              {getPlanTypeBadge()}
              {investigation.runtime_seconds > 0 && (
                <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
                  <Icon
                    id={IconEnum.Clock}
                    size={IconSize.XS}
                    className="mr-1"
                  />
                  {Math.floor(investigation.runtime_seconds / 60)}m{" "}
                  {(investigation.runtime_seconds % 60).toFixed(2)}s
                </Badge>
              )}
              {investigation.total_cost_in_cents > 0 && (
                <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Small}>
                  <Icon
                    id={IconEnum.PiggyBank}
                    size={IconSize.XS}
                    className="mr-1"
                  />
                  ${(investigation.total_cost_in_cents / 100).toFixed(2)}
                </Badge>
              )}
            </div>
          </div>
        }
        compact={true}
        clickable={true}
        icon={IconEnum.Search}
        footerNode={
          <div className="flex items-center gap-2 text-xs text-content-secondary">
            <LocalDateTime
              className="text-xs"
              timestamp={investigation.created_at}
            />
            {investigation.cutoff_at && (
              <span className="text-content-tertiary">
                · Data cutoff{" "}
                <LocalDateTime
                  className="text-xs"
                  timestamp={investigation.cutoff_at}
                />
              </span>
            )}
          </div>
        }
        headerAccessory={getStatusBadge()}
      />
    </OrgAwareLink>
  );
};
