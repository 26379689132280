import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import {
  Actor,
  ExternalResourceResourceTypeEnum as ResourceType,
  IntegrationActorNameEnum as IntegrationType,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { Avatar } from "./Avatar";

const RESOURCE_TYPE_TO_ICON: {
  [key in ResourceType]: IconEnum;
} = {
  [ResourceType.GithubPullRequest]: IconEnum.Github,
  [ResourceType.GitlabMergeRequest]: IconEnum.Gitlab,
  [ResourceType.PagerDutyIncident]: IconEnum.Pagerduty,
  [ResourceType.OpsgenieAlert]: IconEnum.Opsgenie,
  [ResourceType.JiraIssue]: IconEnum.Jira,
  [ResourceType.SentryIssue]: IconEnum.Sentry,
  [ResourceType.AtlassianStatuspageIncident]: IconEnum.AtlassianStatuspage,
  // This enum exists here for backwards compatibility, it shouldn't be sent in new updates
  [ResourceType.StatuspageIncident]: IconEnum.AtlassianStatuspage,
  [ResourceType.ZendeskTicket]: IconEnum.Zendesk,
  [ResourceType.DatadogMonitorAlert]: IconEnum.Datadog,
  [ResourceType.GoogleCalendarEvent]: IconEnum.GoogleCalendar,
  [ResourceType.OutlookCalendarEvent]: IconEnum.Outlook,
  [ResourceType.SlackFile]: IconEnum.Slack,
  [ResourceType.Scrubbed]: IconEnum.Box,
};

const INTEGRATION_TYPE_TO_ICON: {
  [key in IntegrationType]: [IconEnum, boolean];
} = {
  [IntegrationType.IncidentAnnouncements]: [IconEnum.Github, false],
  [IntegrationType.IncidentAutoClose]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentAutoDecline]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.AiSuggestion]: [IconEnum.New, true],
  [IntegrationType.IncidentAutoSubscribeRule]: [
    IconEnum.IncidentFlameWithFill,
    true,
  ],
  [IntegrationType.IncidentBackfill]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.GithubSecretScanning]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.IncidentCatalog]: [IconEnum.IncidentFlameWithFill, false],
  // We should change this to be the shiny AI icon eventually
  [IntegrationType.IncidentCopilot]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentDefaultCallUrl]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.IncidentDefault]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentEscalator]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentLifecycle]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentSchedule]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentTicketSync]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentStaleOrgArchiver]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.AlertSource]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentSchedules]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentSetup]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentStatusPage]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.IncidentStatusPageImport]: [
    IconEnum.IncidentFlameWithFill,
    true,
  ],
  [IntegrationType.IncidentTutorial]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.AlertRoute]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.LiveCall]: [IconEnum.IncomingPhoneCall, false],
  [IntegrationType.Jira]: [IconEnum.Jira, false],
  [IntegrationType.Policy]: [IconEnum.ShieldExclamation, true],
  [IntegrationType.Saml]: [IconEnum.Cog, true],
  [IntegrationType.Scim]: [IconEnum.Cog, true],
  [IntegrationType.Sentry]: [IconEnum.Sentry, false],
  [IntegrationType.Slack]: [IconEnum.Slack, false],
  [IntegrationType.Statuspage]: [IconEnum.AtlassianStatuspage, false],
  [IntegrationType.Zendesk]: [IconEnum.Zendesk, false],
  [IntegrationType.GoogleCalendar]: [IconEnum.Calendar, true],
  [IntegrationType.Gitlab]: [IconEnum.Gitlab, false],
  [IntegrationType.IncidentCustomFieldEvaluator]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.InternalStatusPageAutomationRule]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.IncidentStatusPagePublicViewer]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.PostIncidentFlow]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.MicrosoftTeams]: [IconEnum.MicrosoftTeams, false],
  [IntegrationType.GoogleMeet]: [IconEnum.GoogleMeet, false],
  [IntegrationType.Zoom]: [IconEnum.Zoom, false],
  [IntegrationType.IncidentInsightReports]: [
    IconEnum.IncidentFlameWithFill,
    false,
  ],
  [IntegrationType.OutlookCalendar]: [IconEnum.Outlook, false],
  [IntegrationType.BillingSequence]: [IconEnum.IncidentFlameWithFill, false],
  [IntegrationType.Confluence]: [IconEnum.Confluence, false],
  [IntegrationType.GoogleDocs]: [IconEnum.GoogleDocs, false],
  [IntegrationType.Notion]: [IconEnum.Notion, false],
  [IntegrationType.MicrosoftTeamsOnlineMeetings]: [
    IconEnum.MicrosoftTeams,
    false,
  ],
};

export type ActorAvatarProps = {
  actor: Actor;
  className?: string;
  size: IconSize.Small | IconSize.Medium | IconSize.Large;
};

export const ActorAvatar = ({
  actor,
  className,
  size,
}: ActorAvatarProps): React.ReactElement => {
  if (actor.user) {
    return (
      <Avatar
        size={size}
        className={tcx(className)}
        url={actor.user.avatar_url}
      />
    );
  }

  const [icon, paddingAndBorder] = getIconForActor(actor);
  if (icon) {
    return (
      <Icon
        id={icon}
        size={size}
        className={tcx(
          "rounded-full",
          paddingAndBorder ? "p-0.5 border border-stroke" : "p-0",
          className,
        )}
      />
    );
  }

  return <Avatar size={size} />;
};

const getIconForActor = (actor: Actor): [IconEnum | null, boolean] => {
  if (actor.api_key) {
    return [IconEnum.Key, true];
  }
  if (actor.external_resource) {
    return [
      RESOURCE_TYPE_TO_ICON[actor.external_resource.resource_type],
      false,
    ];
  }
  if (actor.integration) {
    return INTEGRATION_TYPE_TO_ICON[actor.integration.name];
  }
  if (actor.workflow) {
    return [IconEnum.Workflows, true];
  }
  if (actor.alert) {
    return [IconEnum.Alert, false];
  }
  return [null, false];
};
