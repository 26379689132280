import { IncidentFilterFields } from "@incident-io/api";
import { PostIncidentTaskConfigSelectOption } from "@incident-io/api/models/PostIncidentTaskConfigSelectOption";
import { useUserBacklinks } from "@incident-shared/catalog/useUserBacklinks";
import { SelectOption } from "@incident-ui/Select/types";
import { isBefore, isValid, parse } from "date-fns";
import _ from "lodash";
import pluralize from "pluralize";
import {
  CoreDashboardFilterContextsEnum,
  ErrorResponse,
  FormField,
  FormFieldOperatorFieldTypeEnum,
  FormFieldOperatorFieldTypeEnum as FormFieldType,
  IncidentDurationMetric,
  IncidentFilterFieldsListContextEnum,
  IncidentFilterFieldsListInsightsResponseBody,
  IncidentFilterFieldsListRequest,
  IncidentTimestamp,
  PanelFilterContextsEnum,
  SelectConfigTypeaheadTypeEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { SWRResponse } from "swr";

import { ExtendedFormFieldValue, FormFieldValue } from ".";

const parseDate = (str: string): Date => {
  return parse(str, "yyyy-MM-dd", new Date("2000-01-01"));
};
const isValidDate = (str: string): boolean => {
  return isValid(parseDate(str));
};

type ValidationError = {
  key: keyof ExtendedFormFieldValue;
  message: string;
};

export type AvailableFilter = FormField & {
  typeahead_lookup_id?: string;
  filter_id: string;
  catalog_backed_opts?: CatalogBackedOpts;
};

export type CatalogBackedOpts = {
  catalog_type_id: string;
  backlink_attribute_id: string;
};

// checks that it's valid (and sets errors where required).
export const validateIncidentFormFieldValue = (
  fieldValue: ExtendedFormFieldValue,
  fieldConfig: AvailableFilter,
): ValidationError | null => {
  // pre-flight checks
  if (!fieldValue.field_key) {
    return { key: "field_key", message: "is required" };
  }
  if (!fieldValue.operator) {
    return { key: "operator", message: "is required" };
  }
  // depending on the fieldConfig, we check different values
  const operatorConfig = fieldConfig.operators.find(
    (x) => x.key === fieldValue.operator,
  );
  if (!operatorConfig) {
    throw new Error(
      `unreachable: we've been provided an operator which we can't find in our fieldConfig. Value: ${fieldValue}. Config: ${fieldConfig}`,
    );
  }

  switch (operatorConfig.field_type) {
    case FormFieldType.None:
      break;
    case FormFieldType.NumberInput:
    case FormFieldType.TextInput:
      if (!fieldValue.string_value) {
        return {
          key: "string_value",
          message: "please provide a value",
        };
      }
      break;
    case FormFieldType.DateInput:
      if (!fieldValue.string_value) {
        return {
          key: "string_value",
          message: "please provide a value",
        };
      }
      if (!isValidDate(fieldValue.string_value)) {
        return {
          key: "string_value",
          message: "please provide a valid date",
        };
      }
      break;
    case FormFieldType.DateRangeInput: {
      if (!fieldValue.string_value) {
        return {
          key: "string_value",
          message: "please provide a value",
        };
      }
      const isRelative = fieldValue.string_value.startsWith("past");
      if (!isRelative) {
        const dates = fieldValue.string_value.split("~");
        const from = parse(dates[0], "yyyy-MM-dd", new Date());
        const to = parse(dates[1], "yyyy-MM-dd", new Date());
        if (isBefore(to, from)) {
          return {
            key: "string_value",
            message: "'From' date cannot be after 'to' date",
          };
        }
      }
      break;
    }
    case FormFieldType.BooleanInput:
      if (fieldValue.bool_value !== true && fieldValue.bool_value !== false) {
        return { key: "bool_value", message: "please select a value" };
      }
      break;
    case FormFieldType.MultiExternalSelect:
    case FormFieldType.MultiExternalUserSelect:
    case FormFieldType.MultiStaticSelect: {
      const multiVal = fieldValue.multiple_options_value;
      if (!multiVal || multiVal.length === 0) {
        return {
          key: "multiple_options_value",
          message: "please select at least one value",
        };
      }
      break;
    }
    case FormFieldType.SingleExternalSelect:
    case FormFieldType.SingleExternalUserSelect:
    case FormFieldType.SingleStaticSelect:
      if (!fieldValue.single_option_value) {
        return {
          key: "single_option_value",
          message: "please select a value",
        };
      }
      break;
    default:
      throw new Error(`unsupported FormFieldType ${operatorConfig.field_type}`);
  }

  return null;
};

export const queryParamsToFilters = (
  availableFilterFields: AvailableFilter[],
  queryString: string,
): ExtendedFormFieldValue[] => {
  const parsedQueryParams = new URLSearchParams(queryString);

  // we want our arrays of params to be dealt with all at once,
  // when we parse them we get each array value separately,
  // so we're appending them all together here before handling
  const params: { [key: string]: string[] } = {};
  parsedQueryParams.forEach((value: string, key: string) => {
    if (params[key]) {
      params[key].push(value);
    } else {
      params[key] = [value];
    }
  });
  // in order to get our array constructed we need to loop through each value

  const initialFilters: ExtendedFormFieldValue[] = [];
  Object.entries(params).forEach(([key, values]) => {
    // split our string on [] brackets since that's how our params are constructed
    // eslint-disable-next-line no-useless-escape
    const keys = key.split(/\[|\]/).filter((str) => str !== "");
    // For the different shapes of filter, this is what we're looking for:
    // status filter: ["status", [<operator>]]
    // custom field filter: ["custom_field", [<custom_field_id>, <operator>]]
    // synthetic catalog filter: ["synthetic", [<filter_id>, <operator>]]
    const [fieldKeyRaw, ...filterArgs] = keys;
    let fieldKey = fieldKeyRaw;
    const isSyntheticCatalog = fieldKey === "synthetic";
    const isNested = isNestedFilter(fieldKey);

    let fieldConfig = availableFilterFields.find((x) => x.key === fieldKey);
    if (isSyntheticCatalog) {
      fieldConfig = availableFilterFields.find(
        (x) => x.filter_id === filterArgs[0],
      );
    }
    if (isNested) {
      fieldConfig = availableFilterFields.find(
        (x) => x.key === fieldKey && x.field_id === filterArgs[0],
      );
    }

    // if there's no filter field config, we bail on the query params.
    if (!fieldConfig) {
      return;
    }

    let fieldId: string | undefined;
    let operatorId: string;

    if (isSyntheticCatalog) {
      operatorId = filterArgs[1];
      fieldId = fieldConfig.field_id;
      fieldKey = fieldConfig.key;
    } else if (isNested) {
      [fieldId, operatorId] = filterArgs;
    } else {
      [operatorId] = filterArgs;
    }

    const formValue = constructFormFieldValue(fieldConfig, operatorId, values);

    if (!formValue) {
      return;
    }

    formValue.field_id = fieldId || fieldKey;

    initialFilters.push(formValue);
  });

  return initialFilters;
};

const constructFormFieldValue = (
  fieldConfig: AvailableFilter,
  operatorId: string,
  values: string[],
): ExtendedFormFieldValue | undefined => {
  const operator = fieldConfig.operators.find((x) => x.key === operatorId);
  // if we can't find the operator, lets skip it
  if (!operator) {
    return undefined;
  }

  const formValue: ExtendedFormFieldValue = {
    field_key: fieldConfig.key,
    field_id: fieldConfig.field_id || fieldConfig.key,
    key: fieldConfig.key + (fieldConfig.field_id || "") + operatorId,
    operator: operatorId,
    typeahead_lookup_id: fieldConfig.typeahead_lookup_id || null,
    filter_id: fieldConfig.filter_id,
    catalog_type_id: fieldConfig.catalog_backed_opts?.catalog_type_id,
  };

  switch (operator.field_type) {
    case FormFieldType.TextInput:
    case FormFieldType.NumberInput:
    case FormFieldType.DateInput:
    case FormFieldType.DateRangeInput:
      formValue.string_value = values[0];
      break;
    case FormFieldType.BooleanInput:
      formValue.bool_value = values[0] === "true";
      break;
    case FormFieldType.SingleStaticSelect:
    case FormFieldType.SingleExternalSelect:
      formValue.single_option_value = values[0];
      break;
    case FormFieldType.MultiStaticSelect:
    case FormFieldType.MultiExternalSelect:
    case FormFieldType.MultiExternalUserSelect:
      formValue.multiple_options_value = values;
      break;
    case FormFieldType.None:
      break;
    default:
      throw new Error("unhandled filterField type");
  }

  return formValue;
};

type UseInitialiseFiltersReturn = {
  loading: boolean;
  error: ErrorResponse | null;
  availableIncidentFilterFields: AvailableFilter[];
  availableUserFilterFields: AvailableFilter[];
  availableEscalationTargetFilterFields: AvailableFilter[];
  availableReadinessFilterFields: AvailableFilter[];
  timestamps: IncidentTimestamp[];
  durationMetrics: IncidentDurationMetric[];
  initialFilters: ExtendedFormFieldValue[];
  escalationExternalUsers: SelectOption[];
  escalationTargets: SelectOption[];
  escalationPaths: SelectOption[];
  schedules: SelectOption[];
  postIncidentFlows: SelectOption[];
  postIncidentTaskConfigs: PostIncidentTaskConfigSelectOption[];
};

export const enrichAvailableFilterFields = (
  fields: FormField[],
): AvailableFilter[] => {
  return fields.map((field) => {
    return {
      ...field,
      typeahead_lookup_id: field.typeahead_lookup_id || field.field_id,
      filter_id: field.field_id || field.key,
    };
  });
};

export const useInitialiseFilters = (
  searchQuery: string,
  filterContext = IncidentFilterFieldsListContextEnum.Incidents,
): UseInitialiseFiltersReturn => {
  const listFilterFieldsRequestBody: IncidentFilterFieldsListRequest = {
    context: filterContext,
  };

  const {
    data: { fields },
    isLoading,
    error,
  } = useAPI("incidentFilterFieldsList", listFilterFieldsRequestBody, {
    fallbackData: { fields: [] },
  });

  const enrichedFields = enrichAvailableFilterFields(fields);

  const {
    data: fieldsWithBacklinkFilters,
    isLoading: blFiltersLoading,
    error: blFiltersError,
  } = useUserBacklinkFilters(enrichedFields);

  return {
    loading: isLoading || blFiltersLoading,
    error: (error || blFiltersError) ?? null,
    availableIncidentFilterFields: fieldsWithBacklinkFilters,
    availableEscalationTargetFilterFields: [],
    availableReadinessFilterFields: [],
    availableUserFilterFields: [],
    timestamps: [],
    durationMetrics: [],
    initialFilters: queryParamsToFilters(
      fieldsWithBacklinkFilters,
      searchQuery,
    ),
    escalationExternalUsers: [],
    escalationTargets: [],
    escalationPaths: [],
    schedules: [],
    postIncidentFlows: [],
    postIncidentTaskConfigs: [],
  };
};

const FIELD_KEYS_TO_SYNTHESISE_FILTERS_FOR = [
  "participants",
  "incident_role",
  "follow_up_creator",
  "follow_up_owner",
];

const useUserBacklinkFilters = (fields: AvailableFilter[]) => {
  const { data: backlinks, isLoading, error } = useUserBacklinks();

  if (isLoading || error) {
    return {
      data: fields,
      isLoading,
      error,
    };
  }

  const filtersWithUserBacklinks = fields.flatMap((field) => {
    if (!FIELD_KEYS_TO_SYNTHESISE_FILTERS_FOR.includes(field.key)) {
      return [field];
    }

    const newFilters: AvailableFilter[] = [field];

    for (const backlink of backlinks ?? []) {
      newFilters.push({
        ...field,
        // The filter ID needs to be unique across all filters as we
        // use it to identify the options in the filter dropdown.
        // For synthetic catalog filters, we concatenate the filter ID
        // of the parent field with the catalog type ID to ensure uniqueness.
        filter_id: `${field.filter_id}_${backlink.id}`,
        icon: backlink.catalog_type_icon,
        label: `${field.label} → ${backlink.backlink_name}`,
        typeahead_lookup_id: backlink.catalog_type_id,
        catalog_backed_opts: {
          catalog_type_id: backlink.catalog_type_id,
          backlink_attribute_id: backlink.backlink_attribute,
        },
        operators: makeOperators(backlink.backlink_name, field),
      });
    }

    return [...newFilters];
  });

  return {
    data: filtersWithUserBacklinks,
    isLoading,
    error,
  };
};

const makeOperators = (catalogTypeName: string, field: AvailableFilter) => {
  const operators = [
    {
      field_type: FormFieldOperatorFieldTypeEnum.MultiExternalSelect,
      key: "one_of",
      label: "includes",
      select_config: {
        placeholder: `Select ${pluralize.plural(catalogTypeName)}`,
        typeahead_type: SelectConfigTypeaheadTypeEnum.CatalogEntry,
      },
    },
  ];

  if (
    ["participants", "follow_up_creator", "follow_up_owner"].includes(field.key)
  ) {
    operators.push({
      field_type: FormFieldOperatorFieldTypeEnum.MultiExternalSelect,
      key: "not_in",
      label: "does not include",
      select_config: {
        placeholder: `Select ${pluralize.plural(catalogTypeName)}`,
        typeahead_type: SelectConfigTypeaheadTypeEnum.CatalogEntry,
      },
    });
  }

  return operators;
};

export const useInitialiseInsightsFilters = (
  searchQuery: string,
): UseInitialiseFiltersReturn & {
  refetchFilters: () => Promise<void>;
  insightsV3FilterFields: Record<
    CoreDashboardFilterContextsEnum,
    AvailableFilter[]
  >;
  insightsV3CustomFilterFields: Record<
    PanelFilterContextsEnum,
    AvailableFilter[]
  >;
} => {
  const response: SWRResponse<IncidentFilterFieldsListInsightsResponseBody> =
    useAPI("incidentFilterFieldsListInsights", undefined);

  const { data, error, isLoading, mutate } = response;

  const fieldsWithExtraIds = enrichAvailableFilterFields(
    data?.fields?.incident || [],
  );

  const availableUserFilterFields = enrichAvailableFilterFields(
    data?.fields?.user || [],
  );

  const availablePagerLoadFields = enrichAvailableFilterFields(
    data?.fields?.pager_load || [],
  );

  const availableReadinessFilterFields = enrichAvailableFilterFields(
    data?.fields?.readiness || [],
  );

  return {
    loading: isLoading,
    error: error ?? null,
    availableIncidentFilterFields: fieldsWithExtraIds,
    availableEscalationTargetFilterFields: availablePagerLoadFields,
    availableUserFilterFields,
    availableReadinessFilterFields,
    insightsV3FilterFields: Object.values(
      CoreDashboardFilterContextsEnum,
    ).reduce(
      (acc, context: CoreDashboardFilterContextsEnum) => ({
        ...acc,
        [context]: enrichAvailableFilterFields(data?.fields[context] || []),
      }),
      {} as Record<CoreDashboardFilterContextsEnum, AvailableFilter[]>,
    ),
    insightsV3CustomFilterFields: Object.values(PanelFilterContextsEnum).reduce(
      (acc, context: PanelFilterContextsEnum) => ({
        ...acc,
        [context]: enrichAvailableFilterFields(data?.fields[context] || []),
      }),
      {} as Record<PanelFilterContextsEnum, AvailableFilter[]>,
    ),
    timestamps: data?.timestamps || [],
    durationMetrics: data?.duration_metrics || [],
    initialFilters: queryParamsToFilters(fieldsWithExtraIds, searchQuery),
    escalationExternalUsers: data?.escalation_external_users || [],
    escalationTargets: data?.escalation_targets || [],
    schedules: data?.schedules || [],
    escalationPaths: data?.escalation_paths || [],
    postIncidentFlows: data?.post_incident_flows || [],
    postIncidentTaskConfigs: data?.post_incident_task_configs || [],
    refetchFilters: async () => {
      await mutate();
    },
  };
};

export const useGetContextForFilter = () => {
  const { data, error, isLoading } = useAPI(
    "incidentFilterFieldsListInsights",
    undefined,
  );

  if (isLoading || error) {
    return { isLoading, error, getContextForFilter: undefined };
  }

  const getContextForFilter = (filter_id: string): PanelFilterContextsEnum => {
    const context = Object.values(PanelFilterContextsEnum).find(
      (context: PanelFilterContextsEnum) => {
        const fields = enrichAvailableFilterFields(data?.fields[context] ?? []);
        return fields.find((field) => field.filter_id === filter_id);
      },
    );

    if (!context) {
      throw new Error(`unreachable: could not find filter with filter_id`);
    }

    return context;
  };

  return {
    isLoading,
    error,
    getContextForFilter,
  };
};

export const filterToQueryKeyValues = (
  filter: ExtendedFormFieldValue,
): { key: string; value: string }[] => {
  const values = getFilterValues(filter);

  let key = `${filter.field_key}[${filter.operator}]`;

  if (isSyntheticCatalogFilter(filter.catalog_type_id)) {
    // We store the composite filter_id we create for synthetic catalog filters
    // in the query params, and use that to look up the data we
    // need from availableFilterFields when parsing the query params.
    key = `synthetic[${filter.filter_id}][${filter.operator}]`;
  } else if (isNestedFilter(filter.field_key)) {
    key = `${filter.field_key}[${filter.field_id}][${filter.operator}]`;
  }

  return values.map((value) => ({ key, value }));
};

export function filtersToListParams<T extends Record<string, never>>(
  filters: FormFieldValue[] = [],
): T {
  const queryParams = {};

  filters.forEach((filter) => {
    const fieldKey = _.camelCase(filter.field_key);
    const values = getFilterValues(filter);

    if (isNestedFilter(filter.field_key)) {
      if (queryParams[fieldKey]) {
        queryParams[fieldKey][filter.field_id] = {
          [filter.operator]: values,
        };
      } else {
        queryParams[fieldKey] = {
          [filter.field_id]: {
            [filter.operator]: values,
          },
        };
      }
    } else {
      queryParams[fieldKey] = {
        [filter.operator]: values,
      };
    }
  });

  return queryParams as T;
}

const getFilterValues = (filter: FormFieldValue) => {
  let values: string[] = [];
  if (filter.string_value) {
    values = [filter.string_value];
  } else if (filter.bool_value != null) {
    values = [filter.bool_value.toString()];
  } else if (filter.single_option_value) {
    values = [filter.single_option_value];
  } else if (filter.multiple_options_value) {
    values = filter.multiple_options_value;
  }

  if (!values.length) {
    values = ["true"];
  }

  return values;
};

const isNestedFilter = (fieldKey: string): boolean => {
  return (
    fieldKey === "custom_field" ||
    fieldKey === "incident_role" ||
    fieldKey === "incident_timestamp" ||
    fieldKey === "attributes" ||
    fieldKey === "user_backlink"
  );
};

const isSyntheticCatalogFilter = (catalogTypeId?: string): boolean => {
  return !!catalogTypeId;
};

/**
 * Converts IncidentFilterFields object to an array of ExtendedFormFieldValue
 * @param filters The IncidentFilterFields object to convert
 * @param availableFilterFields The available filter configurations
 * @returns Array of ExtendedFormFieldValue
 */
export const incidentFiltersToExtendedFormValues = (
  filters: Partial<IncidentFilterFields>,
  availableFilterFields: AvailableFilter[],
): ExtendedFormFieldValue[] => {
  const formValues: ExtendedFormFieldValue[] = [];

  // Process each filter field
  Object.entries(filters).forEach(([fieldKey, operatorValues]) => {
    // Skip if no operator values
    if (!operatorValues) return;

    // Find the field configuration
    const fieldConfig = availableFilterFields.find((x) => x.key === fieldKey);
    if (!fieldConfig) return;

    // Handle each operator and its values
    Object.entries(operatorValues).forEach(([operator, values]) => {
      // Handle nested filters (custom_field, incident_role, incident_timestamp)
      if (isNestedFilter(fieldKey)) {
        Object.entries(
          values as Record<string, { [key: string]: string[] }>,
        ).forEach(([fieldId, nestedOperatorValues]) => {
          Object.entries(nestedOperatorValues).forEach(
            ([nestedOperator, nestedValues]) => {
              const formValue = constructFormFieldValue(
                fieldConfig,
                nestedOperator,
                nestedValues,
              );

              if (formValue !== undefined) {
                formValue.field_id = fieldId;
                formValues.push(formValue);
              }
            },
          );
        });
      } else {
        // Handle regular filters
        const formValue = constructFormFieldValue(
          fieldConfig,
          operator,
          values as string[],
        );

        if (formValue !== undefined) {
          formValues.push(formValue);
        }
      }
    });
  });

  return formValues;
};
