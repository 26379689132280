import { captureException } from "@sentry/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AIAssistantThreadMessage,
  AIAssistantThreadMessageAuthorEnum,
  Identity,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AIConfigEnabledFeaturesEnum,
  useAIFeatureForOrg,
} from "src/hooks/useAI";
import { useAPI, useMutationV2 } from "src/utils/swr";
import { usePrevious } from "use-hooks";

import {
  addErrorMessageToMessageLog,
  assistantErrorHandler,
} from "./AssistantError";
import { useAssistantOverlay } from "./AssistantOverlayContext";

interface IAssistantThreadContext {
  assistantId: string | null;
  threadId: string | null;

  messageLog: AIAssistantThreadMessage[];

  submitQuestion: (question: string) => Promise<void>;
  resetThread: () => Promise<void>;

  // isLoading will be true when we are:
  // 1. initialising the thread
  // 2. or submitting a question.
  // 3. or polling for new messages
  isLoading: boolean;

  aiAvailable: boolean;
}

const AssistantThreadContext = createContext<IAssistantThreadContext | null>(
  null,
);

type ErrorHandler = (humanFriendlyError: string, err) => void;

const useAssistantThread = (isActive: boolean) => {
  const apiClient = useClient();
  const {
    trigger: createThread,
    response: assistantThread,
    isMutating: saving,
    genericError: genericError,
  } = useMutationV2(() => apiClient.aICreateAssistantThread(), {
    invalidate: [],
  });

  const doCreateThread = useCallback(() => {
    if (!isActive) return;

    createThread({});
  }, [isActive, createThread]);

  // If the assistant is active, make sure we're trying to initialise a thread.
  useEffect(() => {
    if (assistantThread || saving) return;

    doCreateThread();
  }, [assistantThread, doCreateThread, saving]);

  return {
    assistantId: assistantThread?.assistant_id ?? null,
    threadId: assistantThread?.thread_id ?? null,
    initialiseThread: doCreateThread,
    isInitialisingThread: saving,
    error: genericError,
  };
};

// Instantiates a thread that will be used to communicate with Assistant API, also responsible for
// polling the API for new messages
export const AssistantThreadContextProvider = ({
  children,
}: {
  children: React.ReactChild[] | React.ReactChild;
}): React.ReactElement => {
  return (
    <AssistantThreadContextProviderInner>
      {children}
    </AssistantThreadContextProviderInner>
  );
};

const usePreviousOrganisationID = (identity: Identity | null) => {
  const ref = useRef(identity?.organisation_id);
  useEffect(() => {
    // lets ignore falsy org ids (e.g. null)
    if (identity?.organisation_id) {
      ref.current = identity?.organisation_id;
    }
  }, [identity?.organisation_id]);
  return ref.current;
};

const AssistantThreadContextProviderInner = ({
  children,
}: {
  children: React.ReactChild[] | React.ReactChild;
}) => {
  const { identity } = useIdentity();
  const { isOverlayOpen, resetView, setShouldShowIntro, shouldShowIntro } =
    useAssistantOverlay();

  const [messageLog, setMessageLog] = useState<AIAssistantThreadMessage[]>([]);

  const canUseAI = useAIFeatureForOrg();
  const canUseAssistant = canUseAI(AIConfigEnabledFeaturesEnum.Assistant);

  const [runId, setRunId] = useState<string | null>(null);

  const {
    assistantId,
    threadId,
    initialiseThread,
    isInitialisingThread,
    error,
  } = useAssistantThread(canUseAssistant && isOverlayOpen);

  const handleError = useMemo(() => {
    const addMessageToMessageLog = (message: AIAssistantThreadMessage) =>
      setMessageLog((prev) => [...prev, message]);

    return assistantErrorHandler({ assistantId, threadId, runId }, (message) =>
      addErrorMessageToMessageLog(message, addMessageToMessageLog),
    );
  }, [assistantId, threadId, runId]);

  useEffect(() => {
    if (error) {
      handleError(
        "There was an error initialising the assistant. Please try again.",
        error,
      );
    }
  }, [error, handleError]);

  const { stopPolling, isPolling } = usePollThreadRunStatus(
    assistantId,
    threadId,
    runId,
    setMessageLog,
    handleError,
  );

  const resetThread = useCallback(async () => {
    stopPolling();
    resetView();
    setMessageLog([]);
    await initialiseThread();
  }, [resetView, setMessageLog, initialiseThread, stopPolling]);

  // reset thread if organisation has changed (i.e. user has switched orgs or switching to/from impersonation mode)
  const previousOrgID = usePreviousOrganisationID(identity);
  const previousShouldShowIntro = usePrevious(shouldShowIntro);
  useEffect(() => {
    // Don't do anything if the assistant isn't open
    if (!isOverlayOpen) return;

    if (
      identity?.organisation_id &&
      identity?.organisation_id !== previousOrgID
    ) {
      resetThread();
    } else if (shouldShowIntro && !previousShouldShowIntro) {
      resetThread();
    }
  }, [
    isOverlayOpen,
    identity?.organisation_id,
    previousOrgID,
    resetThread,
    previousShouldShowIntro,
    shouldShowIntro,
  ]);

  const { trigger: submitQuestion, isMutating: submittingQuestion } =
    useSubmitQuestion(
      assistantId,
      threadId,
      (newMessage) => setMessageLog((prev) => [...prev, newMessage]),
      setRunId,
      handleError,
    );

  return (
    <AssistantThreadContext.Provider
      value={{
        assistantId,
        threadId,
        submitQuestion: async (question: string) => {
          setShouldShowIntro(false);
          submitQuestion({ question });
        },
        messageLog,
        resetThread,
        isLoading: submittingQuestion || isPolling || isInitialisingThread,
        aiAvailable: canUseAssistant,
      }}
    >
      {children}
    </AssistantThreadContext.Provider>
  );
};

export const useAssistantThreadContext = () => {
  const context = useContext(AssistantThreadContext);

  // We don't throw an error here, because we don't want to break the page if the context is not
  // available. Instead, we return null, and the caller can handle the null case.
  if (!context || !context.aiAvailable) {
    return null;
  }

  return context;
};

const useShouldPoll = (
  runId: string | null,
): { shouldPoll: boolean; stopPolling: () => void } => {
  const [shouldPoll, setShouldPoll] = useState(false);
  const previousRunID = usePrevious(runId);
  useEffect(() => {
    if (runId && runId !== previousRunID) {
      setShouldPoll(true);
    }
  }, [runId, previousRunID]);
  return {
    shouldPoll,
    stopPolling: () => {
      setShouldPoll(false);
    },
  };
};

const usePollThreadRunStatus = (
  assistantId: string | null,
  threadId: string | null,
  runId: string | null,
  setMessageLog,
  handleError: ErrorHandler,
) => {
  const { shouldPoll, stopPolling } = useShouldPoll(runId);

  const validInputs = assistantId && threadId && runId;
  const { isLoading } = useAPI(
    validInputs && shouldPoll ? "aIShowAssistantThreadRun" : null,
    {
      assistantId: assistantId ?? "",
      threadId: threadId ?? "",
      runId: runId ?? "",
    },
    {
      onSuccess: (resp) => {
        setMessageLog(resp.thread_messages);
        if (resp.status === "completed") {
          stopPolling();
        }
        if (resp.status === "error") {
          stopPolling();
          handleError(
            "There was an error processing your question. Please try again.",
            new Error(
              "error status encountered when polling thread run status",
            ),
          );
        }
      },
      onError: (err) => {
        stopPolling();
        handleError(
          "There was an error processing your question. Please try again.",
          err,
        );
      },
      // poll every second
      refreshInterval: 1000,
    },
  );
  return { stopPolling, isPolling: isLoading || shouldPoll };
};

const useSubmitQuestion = (
  assistantId: string | null,
  threadId: string | null,
  addMessageToMessageLog: (message: AIAssistantThreadMessage) => void,
  setRunId: (runId: string) => void,
  handleError: ErrorHandler,
) => {
  return useMutationV2(
    (apiClient, data: { question: string }) => {
      // Hack this question into the log so we can render the
      // thread whilst we're submitting the question.
      const questionMessage: AIAssistantThreadMessage = {
        author: AIAssistantThreadMessageAuthorEnum.User,
        content: [`<p>${data.question.replace(/\n/g, "<br>")}</p>`],
        created_at: new Date(),
        id: `ask-${Date.now()}-${Math.random().toString(36).slice(2, 11)}`,
        content_type: "text_message",
      };
      addMessageToMessageLog(questionMessage);

      // Submit the question, and start processing the thread.
      return apiClient.aICreateAssistantThreadQuestion({
        assistantId: assistantId ?? "",
        threadId: threadId ?? "",
        createAssistantThreadQuestionRequestBody: {
          text: data.question,
        },
      });
    },
    {
      onSuccess: (resp) => {
        setRunId(resp.run_id);
      },
      onError: (err) => {
        handleError(
          "There was an error processing your question. Please try again.",
          new Error(
            "error status encountered when posting question to thread run",
          ),
        );
        captureException(err);
      },
      invalidate: [],
    },
  );
};
